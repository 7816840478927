import './assets/style.css'
import L from 'leaflet'
import markerClusterGroup from 'leaflet.markercluster'
import {buffer, point, bboxPolygon} from '@turf/turf';
import franceBorders from './preprocessed/france.js'
import francedep from './preprocessed/franceTiles.js'
import excludedArea30 from './preprocessed/excluded_area30s.js'
import excludedArea15 from './preprocessed/excluded_area15s.js'
import excludedArea50 from './preprocessed/excluded_area50s.js'
import franceTiles from './preprocessed/franceTiles'
import mosquees from './scripts/points.json'
import iconMosquee from './assets/mark.png'
import iconTacos from './assets/mark.svg'


const key = 'CZQWGeD6a3rNpGDhywkT';
window.mobileCheck = function () {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};
let check = mobileCheck();
const center = check ? [47.028950, 2.527133] : [47.192190, 1.388280];
const zoom = check ? 6 : 7;
const map = L.map('map').setView(center, zoom);
L.tileLayer(`https://api.maptiler.com/maps/dataviz/{z}/{x}/{y}.png?key=${key}`, {id: 0}).addTo(map);

const franceStyle = {
    color: 'rgb(0,100,255)', // Blue border color
    weight: 1,        // Border thickness
    opacity: 1,       // Border opacity
    fillOpacity: 0  // Fill transparency
};
const franceLayer = L.geoJSON(franceBorders, {style: franceStyle, id: 1}).addTo(map);
function removeLayers() {
    map.eachLayer(function (layer) {
        if (layer.id > 1){
            map.removeLayer(layer)
        }
    });
}
var array_layer = []
departements()


function removeMarkers(){
    for (var i = 0; i<array_layer.length; i++){
        map.removeLayer(array_layer[i])
    }
}

function tacos(){
    removeLayers()
    removeMarkers()
    
    fetch('http://127.0.0.1:5000/tacos', {
        headers: {"Content-Type": "application/json"}
    }).then(response=>response.json())
    .then(data=>{ 
        let tacos = data
        var markers = L.markerClusterGroup();
    
        for (var i=0; i<tacos.length; i++){
            let lat = parseFloat(tacos[i].lat)
            let long = parseFloat(tacos[i].lng)
            let tacosicon = L.icon({iconUrl: 'assets/markermap.svg', iconSize: [38,95]})
            var marker = L.marker(new L.LatLng(lat, long), {icon: tacosicon})

            marker.bindPopup("<b>O'Tacos "+tacos[i].ville+"</b><br>"+tacos[i].zip+"").openPopup();
            markers.addLayer(marker)
        }       
        console.log(markers)
        map.addLayer(markers)
        array_layer.push(markers)
    })
}


function mosquePoints(){
    removeLayers()
    removeMarkers()
    
    if (!array_layer.length){
        fetch('http://127.0.0.1:5000/', {
        headers: {"Content-Type": "application/json"}
    }).then(response=>response.json())
    .then(data=>{ 
        let mosqs = data.markers.marker
        var markers = L.markerClusterGroup({
        maxClusterRadius: 100,
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: true,
        disableClusteringAtZoom: 8,
    });
    
        for (var i=0; i<mosqs.length; i++){
            let lat = parseFloat(mosqs[i].latitude)
            let long = parseFloat(mosqs[i].longitude)
            let mosquicon = L.icon({iconUrl: 'assets/mark.svg', iconSize: [25,50]})
            var marker = L.marker(new L.LatLng(lat, long), {icon: mosquicon})
            marker.bindPopup("<b>"+mosqs[i].mosquita_name+"</b><br>"+mosqs[i].city+"").openPopup();
            markers.addLayer(marker)
        }       
        console.log(markers)
        map.addLayer(markers)
        array_layer.push(markers)

    })
} else {
    map.addLayer(array_layer[0])
}
    
    // let mosquicon = L.icon({
    //     iconUrl: 'assets/mark.svg',
    //     iconSize: [38,95]
    // })

}


function departements(){

    function highlightFeature(e) {
    var layer = e.target;
    // info.update(layer.feature.properties);
    layer.setStyle({
        weight: 5,
        color: '#666',
        dashArray: '',
        fillOpacity: 0.7
    });

    layer.bringToFront();
    }
    function resetHighlight(e) {
        depLayer.resetStyle(e.target);
        // info.update();
    }
    removeLayers()
    removeMarkers()
    

    let style = {
        color: 'rgb(0,100,255)', // Blue border color
    weight: 1,        // Border thickness
    opacity: .2,       // Border opacity
    fillOpacity: 0  // Fill transparency
    }
    const depLayer = L.geoJSON(francedep, {style: style, onEachFeature: function (feature, layer){
        // layer.bindPopup('<h1>'+feature.properties.nom+'</h1>');
        
        layer.bindPopup('<h2>'+feature.properties.nom+'</h2><p>Nombre de mosquées: '+feature.properties.nombremosque+'<br>Nombre de bénéficiaires du RSA: '+feature.properties.benefRsa+'</p>'
            , {closeButton: false, offset: L.point(0, -20)});
        layer.on({mouseover: (e)=>{
            layer.openPopup();
            layer.style = {fillColor:'red'}
            highlightFeature(e)
            
        }})
        layer.on({mouseout: (e)=> {
            resetHighlight(e)
            layer.closePopup();
            
        }})
    }}).addTo(map)
    


} 

document.addEventListener('distance', function (e) {
    map.removeLayer(layers[e.detail.cr]);
    layers[e.detail.distance].addTo(map)
})

function setDistance(distance) {
    toggleDropdown(); // Close the dropdown menu
}

function testev(){
    mosquePoints()
}




document.addEventListener('change_map', function (e) {
    switch (e.detail) {
        case "mosquees":
            mosquePoints()
            break;
        case "tacos":
            tacos()
            break;
        case "dep":
            departements()
            break;
    }
})