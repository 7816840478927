export default {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3.172704445659,
              50.011996744997
            ],
            [
              3.1821975358417,
              50.012335988197
            ],
            [
              3.2174797791605,
              50.023182479597
            ],
            [
              3.230541907493,
              50.031311385584
            ],
            [
              3.2579143291896,
              50.024436966985
            ],
            [
              3.2581174425539,
              50.031770179151
            ],
            [
              3.2707157754254,
              50.029466123914
            ],
            [
              3.2801699122715,
              50.015181662188
            ],
            [
              3.2870975810209,
              50.014184796787
            ],
            [
              3.3105782894546,
              50.019566104726
            ],
            [
              3.3293480229819,
              50.015985397797
            ],
            [
              3.3371076812771,
              50.017403207904
            ],
            [
              3.3529876206925,
              50.035860226771
            ],
            [
              3.3748529135519,
              50.03640787562
            ],
            [
              3.3915828503565,
              50.031901508452
            ],
            [
              3.39890478116,
              50.034755671377
            ],
            [
              3.4135732928197,
              50.027585361882
            ],
            [
              3.447055807483,
              50.022439728958
            ],
            [
              3.491357082465,
              50.018859654652
            ],
            [
              3.5071388293519,
              50.035778426858
            ],
            [
              3.5286124821151,
              50.038180178229
            ],
            [
              3.530720808825,
              50.044704432129
            ],
            [
              3.5448478994272,
              50.053794847201
            ],
            [
              3.5615293457173,
              50.053934264068
            ],
            [
              3.5919692496516,
              50.044080924344
            ],
            [
              3.6064945304366,
              50.034889729152
            ],
            [
              3.6136426641355,
              50.025414972618
            ],
            [
              3.634228731506,
              50.035845179765
            ],
            [
              3.6435742036855,
              50.037676397171
            ],
            [
              3.6559364272446,
              50.032766895838
            ],
            [
              3.6623149163568,
              50.039113396425
            ],
            [
              3.6581930744086,
              50.049288710212
            ],
            [
              3.6657534268377,
              50.054728589348
            ],
            [
              3.7097065568657,
              50.066002995981
            ],
            [
              3.715179065814,
              50.069271974234
            ],
            [
              3.746377151928,
              50.05360784438
            ],
            [
              3.7748221566437,
              50.049621297844
            ],
            [
              3.7779259836508,
              50.04598461089
            ],
            [
              3.7975218734852,
              50.048397031901
            ],
            [
              3.817954914493,
              50.039777091767
            ],
            [
              3.8355345612274,
              50.04827145407
            ],
            [
              3.8472336977049,
              50.040835753367
            ],
            [
              3.875028793388,
              50.036565310409
            ],
            [
              3.8841725748413,
              50.031969058907
            ],
            [
              3.8872950947152,
              50.009688102532
            ],
            [
              3.9235013844547,
              50.026426186034
            ],
            [
              3.9476783843585,
              50.029332476806
            ],
            [
              3.9720781548097,
              50.036886901329
            ],
            [
              3.9823165297208,
              50.044427671748
            ],
            [
              3.9895513362491,
              50.041342464177
            ],
            [
              3.9947358775819,
              50.025531899045
            ],
            [
              3.9826510699201,
              50.007823221699
            ],
            [
              3.9803763291718,
              49.995376985087
            ],
            [
              4.0053732887086,
              49.996060963365
            ],
            [
              4.0130394041535,
              49.985655767091
            ],
            [
              4.0311215546918,
              49.982065248058
            ],
            [
              4.0341643738028,
              49.985781916263
            ],
            [
              4.056692226036,
              49.985425206851
            ],
            [
              4.0799276782823,
              49.979949416328
            ],
            [
              4.0871312705912,
              49.969186662527
            ],
            [
              4.1058130261731,
              49.970940857631
            ],
            [
              4.1179287973935,
              49.974919108478
            ],
            [
              4.1408937784816,
              49.978756019427
            ],
            [
              4.1539790946564,
              49.974840643025
            ],
            [
              4.1723910913291,
              49.97708133227
            ],
            [
              4.1971143649951,
              49.967723922794
            ],
            [
              4.1973605165259,
              49.954734901799
            ],
            [
              4.2259812346668,
              49.959064626187
            ],
            [
              4.2330628764399,
              49.957824711709
            ],
            [
              4.2303959475263,
              49.947204968202
            ],
            [
              4.2216024803296,
              49.937902313306
            ],
            [
              4.2187089162197,
              49.920686498287
            ],
            [
              4.2221391301616,
              49.910422069675
            ],
            [
              4.2332446058305,
              49.904304609803
            ],
            [
              4.2540638814402,
              49.904004764573
            ],
            [
              4.2530532522095,
              49.872272130792
            ],
            [
              4.2485056451169,
              49.856518172527
            ],
            [
              4.2366221851606,
              49.849699207378
            ],
            [
              4.2234884321126,
              49.83376467914
            ],
            [
              4.21989152014,
              49.815134132001
            ],
            [
              4.2147724033566,
              49.806110108269
            ],
            [
              4.2270460920968,
              49.793183108192
            ],
            [
              4.2224308204953,
              49.786967474101
            ],
            [
              4.2095884562322,
              49.781777002902
            ],
            [
              4.2142637924854,
              49.771267691897
            ],
            [
              4.2266193641282,
              49.773087602827
            ],
            [
              4.2382185523689,
              49.767642716303
            ],
            [
              4.2500943395752,
              49.757098850356
            ],
            [
              4.2290201625555,
              49.745744739695
            ],
            [
              4.2247072679536,
              49.727198714419
            ],
            [
              4.2064629076853,
              49.723538323397
            ],
            [
              4.1926293949408,
              49.715800885171
            ],
            [
              4.1920358906093,
              49.710905599545
            ],
            [
              4.1783005290521,
              49.698036101434
            ],
            [
              4.1441873561823,
              49.688543920373
            ],
            [
              4.1475377596226,
              49.678019756234
            ],
            [
              4.1270270054568,
              49.677917606181
            ],
            [
              4.1223323319077,
              49.659520506862
            ],
            [
              4.1254660330069,
              49.650608155401
            ],
            [
              4.1147910874013,
              49.635304817786
            ],
            [
              4.0993133133426,
              49.628479985907
            ],
            [
              4.0695561865706,
              49.636751879497
            ],
            [
              4.0511107039532,
              49.634644480527
            ],
            [
              4.0432501448672,
              49.6361455878
            ],
            [
              4.0379111248697,
              49.622918577627
            ],
            [
              4.0252899216328,
              49.62252962525
            ],
            [
              4.0313962718463,
              49.614287229711
            ],
            [
              4.0541912133221,
              49.595288700911
            ],
            [
              4.065110143114,
              49.594908552638
            ],
            [
              4.0595605382919,
              49.578709772836
            ],
            [
              4.0627821408209,
              49.573556972194
            ],
            [
              4.0765722336699,
              49.57112568919
            ],
            [
              4.0647940705926,
              49.562212345669
            ],
            [
              4.0582914969504,
              49.552548182169
            ],
            [
              4.0487683371061,
              49.545272613584
            ],
            [
              4.0738006912951,
              49.541617642486
            ],
            [
              4.0767490566918,
              49.531314775054
            ],
            [
              4.0758354322042,
              49.518345118497
            ],
            [
              4.0650976973335,
              49.523284436368
            ],
            [
              4.0566240283661,
              49.521046840185
            ],
            [
              4.0618580428951,
              49.512563145954
            ],
            [
              4.053158610381,
              49.50864470004
            ],
            [
              4.0407061703752,
              49.50853200863
            ],
            [
              4.0423746855064,
              49.468690509147
            ],
            [
              4.0529144654974,
              49.460257089671
            ],
            [
              4.0602468457773,
              49.447151466107
            ],
            [
              4.0500241066599,
              49.445792451369
            ],
            [
              4.0376689955921,
              49.438263004999
            ],
            [
              4.0431448752397,
              49.429325986342
            ],
            [
              4.0422957368009,
              49.416650795955
            ],
            [
              4.0502747365168,
              49.412260783575
            ],
            [
              4.047974391829,
              49.405644080344
            ],
            [
              4.0399063933123,
              49.397393331324
            ],
            [
              4.0354977852679,
              49.359905945249
            ],
            [
              4.0127326189721,
              49.358302701884
            ],
            [
              4.0051087823761,
              49.369208523497
            ],
            [
              3.9913652648133,
              49.378222344205
            ],
            [
              3.9613104389204,
              49.377346727516
            ],
            [
              3.9424137621592,
              49.391461051265
            ],
            [
              3.9318314213659,
              49.403084958448
            ],
            [
              3.9244391664348,
              49.406179032892
            ],
            [
              3.9150740342564,
              49.399385348956
            ],
            [
              3.8787822154646,
              49.385857165921
            ],
            [
              3.8591446800636,
              49.381351797144
            ],
            [
              3.8573468410854,
              49.369945638107
            ],
            [
              3.847475808218,
              49.364589335554
            ],
            [
              3.858709482185,
              49.353737278746
            ],
            [
              3.852156449301,
              49.344654924149
            ],
            [
              3.832778633819,
              49.350261851859
            ],
            [
              3.8237576514434,
              49.356987454563
            ],
            [
              3.8015158122099,
              49.358931233427
            ],
            [
              3.7824442652216,
              49.352774492617
            ],
            [
              3.7744622374234,
              49.354639933222
            ],
            [
              3.757818074306,
              49.347604354229
            ],
            [
              3.7410811711412,
              49.347585887018
            ],
            [
              3.7422206958499,
              49.336511221485
            ],
            [
              3.7216167324835,
              49.335726303503
            ],
            [
              3.7012926310034,
              49.332508221298
            ],
            [
              3.6891002697559,
              49.326851228803
            ],
            [
              3.6703327730544,
              49.325093317382
            ],
            [
              3.6601616875884,
              49.318370386543
            ],
            [
              3.646957318637,
              49.315152582535
            ],
            [
              3.6383343630554,
              49.301354552133
            ],
            [
              3.642801871342,
              49.296041342828
            ],
            [
              3.65731758003,
              49.291021541771
            ],
            [
              3.6516094181931,
              49.278360707849
            ],
            [
              3.665132135016,
              49.269657535247
            ],
            [
              3.6552490233788,
              49.262847772673
            ],
            [
              3.6637955993399,
              49.253525125811
            ],
            [
              3.6707785326799,
              49.239600299061
            ],
            [
              3.6763725147129,
              49.23836076616
            ],
            [
              3.6650221926501,
              49.223221302369
            ],
            [
              3.6516085786612,
              49.221427253663
            ],
            [
              3.6584058277856,
              49.211000985544
            ],
            [
              3.6817942876508,
              49.205339977618
            ],
            [
              3.6839084510227,
              49.197534060825
            ],
            [
              3.6971034744514,
              49.205472659609
            ],
            [
              3.7048747423107,
              49.198590103722
            ],
            [
              3.7005485386662,
              49.187713241443
            ],
            [
              3.7043892552675,
              49.181369443488
            ],
            [
              3.7254385553209,
              49.173515978138
            ],
            [
              3.7372236091373,
              49.177961424911
            ],
            [
              3.7511423125744,
              49.177699202357
            ],
            [
              3.7487678456403,
              49.157085133246
            ],
            [
              3.7395699991233,
              49.156933396134
            ],
            [
              3.717012841182,
              49.147040324959
            ],
            [
              3.7016210668145,
              49.143083232127
            ],
            [
              3.6980322212385,
              49.149827639227
            ],
            [
              3.6838376358892,
              49.154490943481
            ],
            [
              3.6718043158638,
              49.150635019402
            ],
            [
              3.6468735208387,
              49.149122623512
            ],
            [
              3.6222521277141,
              49.151105463129
            ],
            [
              3.6103949951775,
              49.127543924136
            ],
            [
              3.6000114843908,
              49.120690012831
            ],
            [
              3.6200761430148,
              49.107193914382
            ],
            [
              3.6323412082709,
              49.086509017267
            ],
            [
              3.6390507423115,
              49.081304273735
            ],
            [
              3.6328719662821,
              49.072789507462
            ],
            [
              3.6237954864751,
              49.07086508435
            ],
            [
              3.6121535124357,
              49.073560405492
            ],
            [
              3.5877081614204,
              49.059394624683
            ],
            [
              3.5866997575834,
              49.035795925017
            ],
            [
              3.6150194069679,
              49.033500653248
            ],
            [
              3.6168408971943,
              49.03648384631
            ],
            [
              3.6463115589695,
              49.040590166906
            ],
            [
              3.6639547486924,
              49.037308063462
            ],
            [
              3.6774822917619,
              49.019164672527
            ],
            [
              3.6749656325958,
              49.01172340253
            ],
            [
              3.6651641207485,
              49.00563190452
            ],
            [
              3.6398926774554,
              49.00408594583
            ],
            [
              3.6245368960791,
              48.983751500727
            ],
            [
              3.6206544521613,
              48.965944000018
            ],
            [
              3.5991756397826,
              48.964609029368
            ],
            [
              3.5915529898914,
              48.960375393399
            ],
            [
              3.599065082592,
              48.953591820396
            ],
            [
              3.6015957850168,
              48.944073853473
            ],
            [
              3.5888878773911,
              48.943911238057
            ],
            [
              3.5744445323994,
              48.939019123403
            ],
            [
              3.5738483617762,
              48.921481440361
            ],
            [
              3.5698140355022,
              48.914705628852
            ],
            [
              3.5600217013966,
              48.917012631384
            ],
            [
              3.5285709402165,
              48.912136556763
            ],
            [
              3.5130610888281,
              48.894727857657
            ],
            [
              3.5021501743028,
              48.871150830362
            ],
            [
              3.4824053162481,
              48.864936735752
            ],
            [
              3.4851833721947,
              48.851910309574
            ],
            [
              3.4704665824993,
              48.850999590899
            ],
            [
              3.4619058634763,
              48.837795469902
            ],
            [
              3.4453621334286,
              48.843227318178
            ],
            [
              3.4522351731222,
              48.856295042236
            ],
            [
              3.4209735309142,
              48.863949992871
            ],
            [
              3.4032208419263,
              48.864690111949
            ],
            [
              3.4058488876005,
              48.875883179755
            ],
            [
              3.3897420595655,
              48.871208088242
            ],
            [
              3.3804785922907,
              48.874760520568
            ],
            [
              3.3828118491401,
              48.888743663179
            ],
            [
              3.3690076856012,
              48.893887842681
            ],
            [
              3.3755045418202,
              48.907223979388
            ],
            [
              3.3722055688435,
              48.921684863018
            ],
            [
              3.3663458595763,
              48.922834212302
            ],
            [
              3.3535717935992,
              48.915243105496
            ],
            [
              3.3441215555428,
              48.915424399696
            ],
            [
              3.3302940034825,
              48.908707002968
            ],
            [
              3.3130772332366,
              48.921215462331
            ],
            [
              3.3128748032908,
              48.933619620679
            ],
            [
              3.3047535824542,
              48.948766966256
            ],
            [
              3.2845265117579,
              48.940530948472
            ],
            [
              3.2678473977393,
              48.938330226813
            ],
            [
              3.2571425774493,
              48.95706258101
            ],
            [
              3.2518324140274,
              48.972363565032
            ],
            [
              3.2441973984858,
              48.976835025435
            ],
            [
              3.2312127851282,
              48.976752849115
            ],
            [
              3.2291741613167,
              48.988415153607
            ],
            [
              3.209314063913,
              48.993783774365
            ],
            [
              3.2071647717724,
              49.000687121653
            ],
            [
              3.1836516708558,
              49.011250532749
            ],
            [
              3.17167852308,
              49.014125889695
            ],
            [
              3.1610292917102,
              49.024224133711
            ],
            [
              3.1765330296567,
              49.030276306398
            ],
            [
              3.181201109614,
              49.042312878135
            ],
            [
              3.1902026447151,
              49.046494471584
            ],
            [
              3.1821650688322,
              49.052356876125
            ],
            [
              3.1818447950388,
              49.062073630984
            ],
            [
              3.1686703252618,
              49.076194127358
            ],
            [
              3.1586170058803,
              49.080495794822
            ],
            [
              3.1568687392365,
              49.086717381412
            ],
            [
              3.1652303376546,
              49.099653555336
            ],
            [
              3.1495286898189,
              49.100548046274
            ],
            [
              3.1288977237115,
              49.106712873662
            ],
            [
              3.1019450875262,
              49.108665530931
            ],
            [
              3.0824299640326,
              49.112379413059
            ],
            [
              3.0718801833502,
              49.11755332218
            ],
            [
              3.0885774087327,
              49.124772726183
            ],
            [
              3.0948631029742,
              49.133233769039
            ],
            [
              3.1142073015392,
              49.138321068061
            ],
            [
              3.1110332982117,
              49.145808870649
            ],
            [
              3.124211701736,
              49.14683712186
            ],
            [
              3.1396565141513,
              49.152575157098
            ],
            [
              3.1444075970975,
              49.16203222082
            ],
            [
              3.1213544037116,
              49.16156497702
            ],
            [
              3.1138989977692,
              49.175101247493
            ],
            [
              3.1168545661605,
              49.189287113624
            ],
            [
              3.1027163729533,
              49.197259135143
            ],
            [
              3.0868718810072,
              49.184455859741
            ],
            [
              3.0811795124662,
              49.193710914115
            ],
            [
              3.0640834030942,
              49.192737678861
            ],
            [
              3.0520068685133,
              49.206619970092
            ],
            [
              3.0355815792129,
              49.204875545425
            ],
            [
              3.0295260439912,
              49.213365706523
            ],
            [
              3.0187891123212,
              49.216625879159
            ],
            [
              3.0004940244253,
              49.213628237896
            ],
            [
              3.0021369671079,
              49.187948463381
            ],
            [
              3.0167739909071,
              49.189744487659
            ],
            [
              3.0350745943294,
              49.183610008704
            ],
            [
              3.0377737043933,
              49.170466926044
            ],
            [
              3.0229815457334,
              49.169940216174
            ],
            [
              3.0170006922748,
              49.178367998523
            ],
            [
              2.998243025829,
              49.186263672994
            ],
            [
              2.9921221288327,
              49.192676248441
            ],
            [
              2.9837256661752,
              49.184192997915
            ],
            [
              2.9677470468385,
              49.19164819014
            ],
            [
              2.9706449654112,
              49.214292631137
            ],
            [
              2.9624508927558,
              49.219995477274
            ],
            [
              2.964764519701,
              49.232581305692
            ],
            [
              3.0027814460118,
              49.235691549882
            ],
            [
              3.0154745880308,
              49.22954684904
            ],
            [
              3.0131790827414,
              49.222865738436
            ],
            [
              3.0216640503059,
              49.216204659573
            ],
            [
              3.0297209221315,
              49.225332359297
            ],
            [
              3.0399323397122,
              49.22616188589
            ],
            [
              3.0334825568838,
              49.241267868854
            ],
            [
              3.0268892663569,
              49.275528461066
            ],
            [
              3.0361477494116,
              49.278798239715
            ],
            [
              3.0376248562025,
              49.286391219561
            ],
            [
              3.0275713264634,
              49.289504284364
            ],
            [
              3.0145702351452,
              49.288949208489
            ],
            [
              3.0055759130959,
              49.292782149864
            ],
            [
              2.9945965377278,
              49.286553379039
            ],
            [
              2.9845022912805,
              49.297533071562
            ],
            [
              2.9743958158612,
              49.302336152604
            ],
            [
              2.9775408677882,
              49.312143172732
            ],
            [
              2.9658317712153,
              49.321018144109
            ],
            [
              2.9733141470747,
              49.325928985608
            ],
            [
              2.9930869272272,
              49.332919830663
            ],
            [
              3.0015090894942,
              49.340015930567
            ],
            [
              3.0290880762466,
              49.332568651576
            ],
            [
              3.0429149637046,
              49.33357220157
            ],
            [
              3.0574496739207,
              49.339763177491
            ],
            [
              3.0631119002401,
              49.345444957541
            ],
            [
              3.0747273511678,
              49.348968273056
            ],
            [
              3.0767803399143,
              49.37126184005
            ],
            [
              3.0953847473833,
              49.37712087468
            ],
            [
              3.0909145961187,
              49.390282654605
            ],
            [
              3.0975424327567,
              49.40939400907
            ],
            [
              3.0937848436209,
              49.433744170658
            ],
            [
              3.1002115987113,
              49.438914335705
            ],
            [
              3.1106058774191,
              49.438533183311
            ],
            [
              3.1242876947876,
              49.431509228
            ],
            [
              3.1318803099746,
              49.431774433431
            ],
            [
              3.162641421643,
              49.440640583775
            ],
            [
              3.1605791009846,
              49.451587071796
            ],
            [
              3.1537737685829,
              49.454687735882
            ],
            [
              3.1070840899002,
              49.4682268944
            ],
            [
              3.111507578536,
              49.478133619658
            ],
            [
              3.1223668133939,
              49.479725634739
            ],
            [
              3.1212851485729,
              49.493673600223
            ],
            [
              3.1157135714634,
              49.495470249313
            ],
            [
              3.0957755908947,
              49.513401263746
            ],
            [
              3.0963250899051,
              49.517898051892
            ],
            [
              3.1211645703147,
              49.520972777532
            ],
            [
              3.1265659892961,
              49.525832923061
            ],
            [
              3.1314748157048,
              49.543379455176
            ],
            [
              3.1308815178414,
              49.560954870117
            ],
            [
              3.1269766852551,
              49.573040797596
            ],
            [
              3.1398162780798,
              49.57623493299
            ],
            [
              3.1241709272179,
              49.596259591906
            ],
            [
              3.1220070868004,
              49.603737577403
            ],
            [
              3.0935808403797,
              49.622546795198
            ],
            [
              3.1127647616219,
              49.631326135242
            ],
            [
              3.105375825455,
              49.650252757435
            ],
            [
              3.0977803822632,
              49.656685783641
            ],
            [
              3.1273514963453,
              49.669560167436
            ],
            [
              3.1187864527405,
              49.679479989177
            ],
            [
              3.1255335157844,
              49.696069433869
            ],
            [
              3.1184045303955,
              49.705964066166
            ],
            [
              3.1199591291385,
              49.713690772364
            ],
            [
              3.1106774189989,
              49.728269876831
            ],
            [
              3.1005489122622,
              49.735129745503
            ],
            [
              3.0937478217821,
              49.757050185226
            ],
            [
              3.097868494334,
              49.766630038579
            ],
            [
              3.0741875690948,
              49.766667414361
            ],
            [
              3.0613242130166,
              49.781248445384
            ],
            [
              3.0859735751004,
              49.792013991644
            ],
            [
              3.0801432084513,
              49.808726951807
            ],
            [
              3.0849494837127,
              49.817971090003
            ],
            [
              3.078691331267,
              49.822411623962
            ],
            [
              3.0607265435498,
              49.820095909989
            ],
            [
              3.0570207765314,
              49.832841513803
            ],
            [
              3.0775195238849,
              49.839932891306
            ],
            [
              3.0844607531572,
              49.845704891547
            ],
            [
              3.0738011805401,
              49.875716243718
            ],
            [
              3.084777965137,
              49.878613471836
            ],
            [
              3.0926931837466,
              49.870741785027
            ],
            [
              3.111125884803,
              49.878003935064
            ],
            [
              3.1201986642742,
              49.884180430147
            ],
            [
              3.1166840949989,
              49.895801064082
            ],
            [
              3.1239474460408,
              49.900973643485
            ],
            [
              3.1179658743974,
              49.914273685764
            ],
            [
              3.1361255836602,
              49.935545270461
            ],
            [
              3.1646455871314,
              49.947433021908
            ],
            [
              3.1594430924158,
              49.952194432987
            ],
            [
              3.1681121612169,
              49.961469923767
            ],
            [
              3.1650294679034,
              49.965384204344
            ],
            [
              3.1926711034973,
              49.977463804614
            ],
            [
              3.2030417908111,
              49.983944858799
            ],
            [
              3.1909782312477,
              49.987719749243
            ],
            [
              3.1763602229291,
              49.98539730495
            ],
            [
              3.1702006684636,
              49.991848969299
            ],
            [
              3.1556881495132,
              49.991750609531
            ],
            [
              3.1653855582686,
              50.000427219052
            ],
            [
              3.172704445659,
              50.011996744997
            ]
          ],
          [
            [
              2.9902257358935,
              49.210674578642
            ],
            [
              3.0027558359081,
              49.218836179133
            ],
            [
              2.9977421484175,
              49.223581146472
            ],
            [
              2.986692959255,
              49.218744676249
            ],
            [
              2.9902257358935,
              49.210674578642
            ]
          ]
        ]
      },
      "properties": {
        "code": "2",
        "nom": "Aisne",
        "population": 529374,
        "delitspour1000": "5,112",
        "nombremosque": 1,
        "benefRsa": 37515,
        "eoliennes": 386
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3.4147888224632,
              48.390268550005
            ],
            [
              3.4220792964886,
              48.413341016525
            ],
            [
              3.4136232739227,
              48.414339744235
            ],
            [
              3.4118359218741,
              48.421315638566
            ],
            [
              3.3919585015834,
              48.424317659764
            ],
            [
              3.3969885214937,
              48.434567766893
            ],
            [
              3.40356672054,
              48.438738834692
            ],
            [
              3.4064837519344,
              48.45245201333
            ],
            [
              3.3964392758484,
              48.463728690957
            ],
            [
              3.3883584814447,
              48.480409204068
            ],
            [
              3.4203545971629,
              48.491547248944
            ],
            [
              3.4346387383078,
              48.490251682592
            ],
            [
              3.4239142947726,
              48.514360214193
            ],
            [
              3.4053967325678,
              48.528014902933
            ],
            [
              3.4142391071512,
              48.533468273095
            ],
            [
              3.4234477695307,
              48.533559074894
            ],
            [
              3.4382278519026,
              48.52833381057
            ],
            [
              3.4591848626137,
              48.530740265696
            ],
            [
              3.4796007670225,
              48.544709319952
            ],
            [
              3.4797700428722,
              48.550623337178
            ],
            [
              3.4722961348839,
              48.564913176785
            ],
            [
              3.4655215727904,
              48.570484758136
            ],
            [
              3.4733827141903,
              48.576873863934
            ],
            [
              3.4852747034173,
              48.580426868353
            ],
            [
              3.4975057114927,
              48.589954752513
            ],
            [
              3.5158639136582,
              48.589782150903
            ],
            [
              3.5038548704771,
              48.604807329745
            ],
            [
              3.5346181421224,
              48.61035282902
            ],
            [
              3.555613758785,
              48.620285576065
            ],
            [
              3.5632103097074,
              48.613224566217
            ],
            [
              3.5636022180892,
              48.606783841249
            ],
            [
              3.579891852417,
              48.60486066415
            ],
            [
              3.5825908612546,
              48.585460442144
            ],
            [
              3.592707193337,
              48.587635090318
            ],
            [
              3.6042499806083,
              48.572452740303
            ],
            [
              3.6157265911113,
              48.578622489749
            ],
            [
              3.6277119530337,
              48.575651578852
            ],
            [
              3.6324727467146,
              48.568227415825
            ],
            [
              3.6296285027063,
              48.559945728765
            ],
            [
              3.6340228482888,
              48.541297392553
            ],
            [
              3.6436337985267,
              48.536259310367
            ],
            [
              3.6504099319879,
              48.539240577921
            ],
            [
              3.6640934922177,
              48.536345606252
            ],
            [
              3.6843293088108,
              48.538886474309
            ],
            [
              3.7037465439415,
              48.533583335371
            ],
            [
              3.7308793763501,
              48.537466056167
            ],
            [
              3.7551991529879,
              48.533121903662
            ],
            [
              3.7671672471403,
              48.527708777816
            ],
            [
              3.7729993342911,
              48.529364971622
            ],
            [
              3.7978791431203,
              48.526691558139
            ],
            [
              3.8227434690573,
              48.516108006569
            ],
            [
              3.8530877821454,
              48.52551470793
            ],
            [
              3.8607919136421,
              48.533881448292
            ],
            [
              3.8586785303501,
              48.560338100981
            ],
            [
              3.8633324584851,
              48.56683550947
            ],
            [
              3.8542522481108,
              48.577217582885
            ],
            [
              3.872571886765,
              48.58047880831
            ],
            [
              3.9000598866521,
              48.574841427631
            ],
            [
              3.906019097288,
              48.587843887488
            ],
            [
              3.9084175961476,
              48.601748446727
            ],
            [
              3.9224268020071,
              48.606188091008
            ],
            [
              3.9492334056715,
              48.603429775124
            ],
            [
              3.9566174890079,
              48.619832027888
            ],
            [
              3.9684589928355,
              48.631668191665
            ],
            [
              3.9772253692371,
              48.632212799757
            ],
            [
              3.9864667097568,
              48.655065441589
            ],
            [
              4.0016901213002,
              48.663879061457
            ],
            [
              4.031910392606,
              48.659949305712
            ],
            [
              4.0559095644888,
              48.667079455181
            ],
            [
              4.069033575877,
              48.679219709831
            ],
            [
              4.062814690952,
              48.682083671037
            ],
            [
              4.0711835399108,
              48.694054472144
            ],
            [
              4.0802597224157,
              48.701069005389
            ],
            [
              4.1310882168837,
              48.686150324648
            ],
            [
              4.1489989828731,
              48.700578702023
            ],
            [
              4.1663812775746,
              48.707450693316
            ],
            [
              4.2330460828895,
              48.702313111437
            ],
            [
              4.243107416189,
              48.716672523486
            ],
            [
              4.2522555833693,
              48.708649150455
            ],
            [
              4.2635447030767,
              48.706272237985
            ],
            [
              4.2746079757227,
              48.710223232536
            ],
            [
              4.2954502821302,
              48.71316787475
            ],
            [
              4.3168987605976,
              48.706788155574
            ],
            [
              4.325595348106,
              48.700276115124
            ],
            [
              4.3268934225152,
              48.692095347687
            ],
            [
              4.3228942536686,
              48.683655839321
            ],
            [
              4.3337403788619,
              48.677131749929
            ],
            [
              4.3313089768531,
              48.669347763471
            ],
            [
              4.3228237057942,
              48.663846740382
            ],
            [
              4.328384590545,
              48.656293909625
            ],
            [
              4.3213683561975,
              48.646557197418
            ],
            [
              4.3317535766786,
              48.630271825369
            ],
            [
              4.3159081092834,
              48.615575295825
            ],
            [
              4.3306292643128,
              48.601957578075
            ],
            [
              4.3466748581423,
              48.597900553214
            ],
            [
              4.3901744592,
              48.568873706499
            ],
            [
              4.4030698243769,
              48.561257615364
            ],
            [
              4.4397307460444,
              48.549838664093
            ],
            [
              4.4681334829337,
              48.547190841194
            ],
            [
              4.495278072804,
              48.538721815695
            ],
            [
              4.5128701947233,
              48.546205419913
            ],
            [
              4.5271682681994,
              48.539952175108
            ],
            [
              4.527473253111,
              48.52851621731
            ],
            [
              4.5454110284704,
              48.525251319435
            ],
            [
              4.5483407438051,
              48.536257532883
            ],
            [
              4.558952067243,
              48.543423837159
            ],
            [
              4.5764888695536,
              48.549380112108
            ],
            [
              4.5819165021596,
              48.548308425178
            ],
            [
              4.614026636402,
              48.553807130722
            ],
            [
              4.6433629775436,
              48.553661794112
            ],
            [
              4.635006842873,
              48.544471761508
            ],
            [
              4.6501058876602,
              48.539851665814
            ],
            [
              4.6664723305305,
              48.537848153413
            ],
            [
              4.6701871837802,
              48.531884547152
            ],
            [
              4.6764590707999,
              48.51915350384
            ],
            [
              4.6688521451916,
              48.510496690653
            ],
            [
              4.6613583252217,
              48.509394194514
            ],
            [
              4.6569743792742,
              48.499860087897
            ],
            [
              4.6587946227005,
              48.492212538939
            ],
            [
              4.6526058839457,
              48.48113680994
            ],
            [
              4.6534621243174,
              48.470552171278
            ],
            [
              4.6268310932286,
              48.468076675502
            ],
            [
              4.6357265412862,
              48.456922766059
            ],
            [
              4.6703471289995,
              48.445806459269
            ],
            [
              4.6772465666729,
              48.433105127596
            ],
            [
              4.7083518456451,
              48.401178797061
            ],
            [
              4.7161010006502,
              48.394852352273
            ],
            [
              4.745028000658,
              48.392439532205
            ],
            [
              4.7601875463212,
              48.387613359286
            ],
            [
              4.7547237556079,
              48.366678135197
            ],
            [
              4.7786528009155,
              48.356182880224
            ],
            [
              4.7943304441028,
              48.356648380458
            ],
            [
              4.8097376980768,
              48.347358004429
            ],
            [
              4.8302192144231,
              48.34533428475
            ],
            [
              4.8413463624966,
              48.33955694951
            ],
            [
              4.8299689053064,
              48.33468012692
            ],
            [
              4.8142122475715,
              48.323012708298
            ],
            [
              4.8230501940522,
              48.315038323492
            ],
            [
              4.8265149985509,
              48.306013188598
            ],
            [
              4.8418181838439,
              48.297799554181
            ],
            [
              4.8508437809167,
              48.296248449436
            ],
            [
              4.842391580845,
              48.283573662617
            ],
            [
              4.858914512777,
              48.263637676715
            ],
            [
              4.8432103541774,
              48.247114695633
            ],
            [
              4.8538598270306,
              48.228760631994
            ],
            [
              4.8352494219065,
              48.221060786024
            ],
            [
              4.8417279551891,
              48.214355583043
            ],
            [
              4.8578182606197,
              48.211650129465
            ],
            [
              4.863174195777,
              48.201977664765
            ],
            [
              4.8470897748332,
              48.180100963639
            ],
            [
              4.8496405146309,
              48.175773175663
            ],
            [
              4.8379503188033,
              48.168960575543
            ],
            [
              4.8354546176062,
              48.161726961473
            ],
            [
              4.8400698771028,
              48.14993356126
            ],
            [
              4.8500985100978,
              48.141757077316
            ],
            [
              4.8292931885425,
              48.129605655891
            ],
            [
              4.8356166412878,
              48.108554335428
            ],
            [
              4.8193111695308,
              48.103187863222
            ],
            [
              4.7901089375058,
              48.107760048044
            ],
            [
              4.7700375513937,
              48.113905506245
            ],
            [
              4.7480573376326,
              48.114659190057
            ],
            [
              4.7306077491836,
              48.118209937253
            ],
            [
              4.7279191203142,
              48.110798678754
            ],
            [
              4.7173906235138,
              48.102240831629
            ],
            [
              4.7084978779355,
              48.100127110314
            ],
            [
              4.70448915836,
              48.088648110098
            ],
            [
              4.6775039000903,
              48.081183188145
            ],
            [
              4.6698729996872,
              48.083179022032
            ],
            [
              4.6630026548561,
              48.076568913128
            ],
            [
              4.6888239532498,
              48.073129641608
            ],
            [
              4.7049737222143,
              48.059501984835
            ],
            [
              4.7104233134929,
              48.050518372019
            ],
            [
              4.7231559379207,
              48.045761135825
            ],
            [
              4.7081090656877,
              48.033381710979
            ],
            [
              4.7042329730873,
              48.020235152969
            ],
            [
              4.6985527278028,
              48.023678273472
            ],
            [
              4.6732270680042,
              48.015055346449
            ],
            [
              4.6633228351061,
              48.020020302718
            ],
            [
              4.6396252873332,
              48.025481582141
            ],
            [
              4.624062923698,
              48.025381425202
            ],
            [
              4.612404633886,
              48.030241950581
            ],
            [
              4.5826732520974,
              48.029462774305
            ],
            [
              4.5718848731394,
              48.025129476681
            ],
            [
              4.5673230090597,
              48.018739473733
            ],
            [
              4.554850985404,
              48.012319062721
            ],
            [
              4.5494258066747,
              48.004460028844
            ],
            [
              4.5358174351871,
              48.001208262
            ],
            [
              4.5455985119462,
              47.98829443758
            ],
            [
              4.5552078832713,
              47.985773515235
            ],
            [
              4.5599635426905,
              47.971424167131
            ],
            [
              4.5533088051577,
              47.967682340931
            ],
            [
              4.5315674205603,
              47.969936101313
            ],
            [
              4.515002575681,
              47.966197509296
            ],
            [
              4.4908013666212,
              47.967274674022
            ],
            [
              4.482135195437,
              47.963341049725
            ],
            [
              4.4490036624777,
              47.957151982179
            ],
            [
              4.4394035883612,
              47.958224025114
            ],
            [
              4.4275882888072,
              47.965925181567
            ],
            [
              4.414350314367,
              47.968207562341
            ],
            [
              4.3984909856202,
              47.963428889937
            ],
            [
              4.3512833685724,
              47.956581571066
            ],
            [
              4.3090008707807,
              47.961170613198
            ],
            [
              4.2989694743195,
              47.948924996538
            ],
            [
              4.3019415557721,
              47.939722254759
            ],
            [
              4.293421363566,
              47.925673564836
            ],
            [
              4.265592811732,
              47.924112631788
            ],
            [
              4.2481126500816,
              47.929257212053
            ],
            [
              4.2362857449326,
              47.940458371541
            ],
            [
              4.2221403072235,
              47.949693241055
            ],
            [
              4.2284618067273,
              47.969156456849
            ],
            [
              4.2127809206222,
              47.971725942982
            ],
            [
              4.1991540335482,
              47.96994144352
            ],
            [
              4.2078363901654,
              47.946594650302
            ],
            [
              4.201887052952,
              47.94115816132
            ],
            [
              4.185389386493,
              47.940250187972
            ],
            [
              4.185174863551,
              47.953252557601
            ],
            [
              4.1814937489139,
              47.957133674703
            ],
            [
              4.1663202417186,
              47.959799899508
            ],
            [
              4.1459926719811,
              47.947950206748
            ],
            [
              4.1418393873722,
              47.937107385043
            ],
            [
              4.129354902453,
              47.936039992343
            ],
            [
              4.111781787339,
              47.926998395128
            ],
            [
              4.091392975551,
              47.929644880288
            ],
            [
              4.0929746686253,
              47.942978558738
            ],
            [
              4.0779088672194,
              47.942844108199
            ],
            [
              4.0612633895691,
              47.945564277098
            ],
            [
              4.0563117031209,
              47.941500284277
            ],
            [
              4.0540462390611,
              47.92948006618
            ],
            [
              4.0435079889664,
              47.928107993449
            ],
            [
              4.0315462267821,
              47.933180877458
            ],
            [
              4.0256492301283,
              47.928321965076
            ],
            [
              4.0122015583229,
              47.933099817884
            ],
            [
              4.0055897042792,
              47.942010075901
            ],
            [
              3.9859571275878,
              47.930640025592
            ],
            [
              3.9565980726161,
              47.934513395738
            ],
            [
              3.9403268061141,
              47.933140821871
            ],
            [
              3.9264502525997,
              47.934566873068
            ],
            [
              3.9124676793978,
              47.930259049768
            ],
            [
              3.8940363766278,
              47.929291041133
            ],
            [
              3.9020918728463,
              47.939168233154
            ],
            [
              3.9056151344907,
              47.956390517208
            ],
            [
              3.9146958839434,
              47.975696995879
            ],
            [
              3.9001846571879,
              47.997906103474
            ],
            [
              3.885413743137,
              48.00032841816
            ],
            [
              3.8783021550775,
              47.979419574124
            ],
            [
              3.8618055931881,
              47.976443001861
            ],
            [
              3.8643090405558,
              47.98423551782
            ],
            [
              3.8500279607807,
              47.983791517043
            ],
            [
              3.8398152772889,
              48.003890008543
            ],
            [
              3.8619707432349,
              48.004325555172
            ],
            [
              3.8700007142194,
              48.002599720655
            ],
            [
              3.8706144760895,
              48.015633804416
            ],
            [
              3.850085900943,
              48.02786301681
            ],
            [
              3.8425469054526,
              48.036189501683
            ],
            [
              3.8321550263001,
              48.036232814814
            ],
            [
              3.8219783557759,
              48.043916868834
            ],
            [
              3.827059329799,
              48.047441428512
            ],
            [
              3.82635868236,
              48.063603655356
            ],
            [
              3.8201608894357,
              48.067459938552
            ],
            [
              3.8067979988969,
              48.083861354137
            ],
            [
              3.7985814686078,
              48.086366869837
            ],
            [
              3.7999733080105,
              48.096695524271
            ],
            [
              3.8049696872895,
              48.102546851574
            ],
            [
              3.7768827441699,
              48.124996720039
            ],
            [
              3.7680208404533,
              48.1337610774
            ],
            [
              3.7569100878728,
              48.125307470037
            ],
            [
              3.7397131881194,
              48.13275416124
            ],
            [
              3.7398043096241,
              48.138688236138
            ],
            [
              3.7548293235379,
              48.150244530897
            ],
            [
              3.7519004546533,
              48.161296410098
            ],
            [
              3.74029665141,
              48.169709598744
            ],
            [
              3.7184640557735,
              48.175364157847
            ],
            [
              3.7141712164177,
              48.170603029762
            ],
            [
              3.7223449458636,
              48.156837755739
            ],
            [
              3.7050157631116,
              48.144314849936
            ],
            [
              3.6936218940592,
              48.14799982015
            ],
            [
              3.6887554871535,
              48.144278615274
            ],
            [
              3.6678669028091,
              48.139211019047
            ],
            [
              3.6595795108361,
              48.15965193897
            ],
            [
              3.6506538178638,
              48.168228410946
            ],
            [
              3.6414996909345,
              48.183932963824
            ],
            [
              3.6318850111199,
              48.189390079748
            ],
            [
              3.6195451473546,
              48.190784507919
            ],
            [
              3.5941772424762,
              48.178873395497
            ],
            [
              3.5751823496995,
              48.188742267274
            ],
            [
              3.6009970264951,
              48.20380086628
            ],
            [
              3.6111516430878,
              48.21192986819
            ],
            [
              3.6140713717985,
              48.220115584573
            ],
            [
              3.6216106602921,
              48.225744034142
            ],
            [
              3.6136268888051,
              48.232260725333
            ],
            [
              3.6046865808402,
              48.229884548214
            ],
            [
              3.6000799784034,
              48.237038015917
            ],
            [
              3.6138531799397,
              48.24927733618
            ],
            [
              3.6243226631674,
              48.254526756748
            ],
            [
              3.6168016799253,
              48.271343511852
            ],
            [
              3.6117709302505,
              48.274410870011
            ],
            [
              3.5881978504359,
              48.280068510017
            ],
            [
              3.5777963307254,
              48.284593393925
            ],
            [
              3.5857412014087,
              48.29008425533
            ],
            [
              3.5879641370453,
              48.300806415009
            ],
            [
              3.566569257901,
              48.307425850859
            ],
            [
              3.5634067310877,
              48.321440919656
            ],
            [
              3.5440725252239,
              48.319671593536
            ],
            [
              3.5451019683919,
              48.334543016882
            ],
            [
              3.530465379844,
              48.342613415975
            ],
            [
              3.512139360519,
              48.360812483948
            ],
            [
              3.4981541228456,
              48.369098088724
            ],
            [
              3.4748808608132,
              48.369266510477
            ],
            [
              3.4704759274371,
              48.374667861079
            ],
            [
              3.4525885512991,
              48.374388436099
            ],
            [
              3.4432494908564,
              48.367382718502
            ],
            [
              3.4275090718968,
              48.35997574793
            ],
            [
              3.4216806196631,
              48.371727783356
            ],
            [
              3.4132728603068,
              48.376300840268
            ],
            [
              3.4147888224632,
              48.390268550005
            ]
          ]
        ]
      },
      "properties": {
        "code": "10",
        "nom": "Aube",
        "population": 311435,
        "delitspour1000": "5,677",
        "nombremosque": 25,
        "parentsEtrangers": "73,0",
        "benefRsa": 18943,
        "eoliennes": 404
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1.1196198636379,
              49.355568981262
            ],
            [
              -1.1150349421423,
              49.362395930026
            ],
            [
              -1.0921583687035,
              49.379887497495
            ],
            [
              -1.0782159992234,
              49.388493551822
            ],
            [
              -1.0581039796328,
              49.390752810392
            ],
            [
              -1.030230861026,
              49.389584817643
            ],
            [
              -1.0217757695616,
              49.392985914906
            ],
            [
              -0.98940345902679,
              49.39743637948
            ],
            [
              -0.93938187284516,
              49.39504140049
            ],
            [
              -0.91939336367386,
              49.39002775011
            ],
            [
              -0.90740489073828,
              49.381675557637
            ],
            [
              -0.88137714562161,
              49.371558349237
            ],
            [
              -0.85116019900865,
              49.362545824867
            ],
            [
              -0.8268800178819,
              49.357899826541
            ],
            [
              -0.75879493262363,
              49.349989308157
            ],
            [
              -0.72224467845688,
              49.347004293019
            ],
            [
              -0.69550391989566,
              49.347281971172
            ],
            [
              -0.64201902855212,
              49.345054424205
            ],
            [
              -0.62418682110984,
              49.340513964595
            ],
            [
              -0.59417938730252,
              49.34074582701
            ],
            [
              -0.55324813273511,
              49.346097088235
            ],
            [
              -0.51530983971648,
              49.345015391903
            ],
            [
              -0.45195840154088,
              49.335498540224
            ],
            [
              -0.41484997241071,
              49.335581004388
            ],
            [
              -0.39661087301599,
              49.333003019991
            ],
            [
              -0.36103882127829,
              49.322880511612
            ],
            [
              -0.30257140893328,
              49.298438445237
            ],
            [
              -0.28214642940768,
              49.293321218346
            ],
            [
              -0.24699588025273,
              49.28822175334
            ],
            [
              -0.22569484520744,
              49.281819588113
            ],
            [
              -0.21207245145114,
              49.28603196545
            ],
            [
              -0.16966058718734,
              49.286245922952
            ],
            [
              -0.14189309791495,
              49.289141316114
            ],
            [
              -0.099599544001567,
              49.2976014263
            ],
            [
              -0.087163331120361,
              49.297906717769
            ],
            [
              -0.077762308941497,
              49.304037533131
            ],
            [
              -0.013370009201545,
              49.321204345512
            ],
            [
              0.0040210484147169,
              49.327780217617
            ],
            [
              0.049806426845953,
              49.350858557413
            ],
            [
              0.075462774884584,
              49.366163898954
            ],
            [
              0.087042570312236,
              49.376676027467
            ],
            [
              0.11038536206668,
              49.394265661988
            ],
            [
              0.12974714895673,
              49.402984487336
            ],
            [
              0.15940920759597,
              49.410111989975
            ],
            [
              0.17578564453162,
              49.412357210559
            ],
            [
              0.21994143692835,
              49.426760938177
            ],
            [
              0.29722451460974,
              49.429859840723
            ],
            [
              0.29740204185691,
              49.412268122221
            ],
            [
              0.30882071105454,
              49.387865806216
            ],
            [
              0.30171495288459,
              49.371306288931
            ],
            [
              0.30645720862332,
              49.359942076992
            ],
            [
              0.32342530221557,
              49.34064105785
            ],
            [
              0.31207228970972,
              49.322257454681
            ],
            [
              0.29815375911751,
              49.31441039187
            ],
            [
              0.31602271740194,
              49.311306530306
            ],
            [
              0.32301639746411,
              49.296990615639
            ],
            [
              0.30899424244078,
              49.283211083763
            ],
            [
              0.3262861426226,
              49.279107167667
            ],
            [
              0.34456630039903,
              49.295323299626
            ],
            [
              0.36621277377577,
              49.294993537578
            ],
            [
              0.38391220613638,
              49.28554046475
            ],
            [
              0.3827798797512,
              49.264016325866
            ],
            [
              0.36543435307866,
              49.262117681971
            ],
            [
              0.34434385294882,
              49.25409228301
            ],
            [
              0.32308269291004,
              49.25234848553
            ],
            [
              0.32602069862327,
              49.243848657902
            ],
            [
              0.33742355295386,
              49.231481905904
            ],
            [
              0.37255025076833,
              49.216891062992
            ],
            [
              0.37941345944254,
              49.217183544081
            ],
            [
              0.3947248164373,
              49.208444970872
            ],
            [
              0.38811391643554,
              49.189939951148
            ],
            [
              0.39191820887398,
              49.178533591286
            ],
            [
              0.39107966545639,
              49.161772214101
            ],
            [
              0.38589506777489,
              49.154052169189
            ],
            [
              0.39468951424446,
              49.150114808846
            ],
            [
              0.42370133418047,
              49.147889663477
            ],
            [
              0.42680057879681,
              49.142505589649
            ],
            [
              0.42487324481257,
              49.13019793323
            ],
            [
              0.40827854245832,
              49.12857401721
            ],
            [
              0.40969717633938,
              49.122161806987
            ],
            [
              0.39971762035569,
              49.10053703168
            ],
            [
              0.41621951470531,
              49.088228255484
            ],
            [
              0.41801317491927,
              49.073857705229
            ],
            [
              0.40781033259879,
              49.077391230915
            ],
            [
              0.38687315179178,
              49.074428646402
            ],
            [
              0.37938602804341,
              49.068890250096
            ],
            [
              0.38078167365394,
              49.061317823192
            ],
            [
              0.36710189915104,
              49.052400965803
            ],
            [
              0.37767292836076,
              49.049451079594
            ],
            [
              0.38300892869358,
              49.037875522311
            ],
            [
              0.39019662246369,
              49.034374854588
            ],
            [
              0.42589199846499,
              49.031958064681
            ],
            [
              0.44627431224134,
              49.014965324445
            ],
            [
              0.43675139756937,
              49.008189336971
            ],
            [
              0.42982494445022,
              48.987431294262
            ],
            [
              0.43289040253185,
              48.979120818423
            ],
            [
              0.4288088818384,
              48.966115453596
            ],
            [
              0.4128104390395,
              48.950626279328
            ],
            [
              0.40015949515572,
              48.952749393468
            ],
            [
              0.40616729538503,
              48.963328041914
            ],
            [
              0.39159849875493,
              48.966458764864
            ],
            [
              0.37731127535648,
              48.972557592954
            ],
            [
              0.36842611384642,
              48.95917916006
            ],
            [
              0.35810990456247,
              48.950503640503
            ],
            [
              0.33376875208518,
              48.942878978029
            ],
            [
              0.32155224282505,
              48.94608052606
            ],
            [
              0.30948162829212,
              48.953876529082
            ],
            [
              0.28263483305723,
              48.948994209987
            ],
            [
              0.27563343073182,
              48.964740550229
            ],
            [
              0.26305866812457,
              48.964738440602
            ],
            [
              0.24466378024092,
              48.95878068996
            ],
            [
              0.24219788957427,
              48.944479382252
            ],
            [
              0.22796851349044,
              48.940344875299
            ],
            [
              0.21060672919486,
              48.945376802287
            ],
            [
              0.18731022751836,
              48.941075480981
            ],
            [
              0.17973679234316,
              48.930608802457
            ],
            [
              0.15647058216308,
              48.924791081169
            ],
            [
              0.14795902541451,
              48.92895028625
            ],
            [
              0.14545992515113,
              48.942210249006
            ],
            [
              0.1365871992517,
              48.949981008586
            ],
            [
              0.12760483049428,
              48.952219904035
            ],
            [
              0.094885684485896,
              48.935795271881
            ],
            [
              0.08072156246746,
              48.937982294807
            ],
            [
              0.070946248746482,
              48.932123979564
            ],
            [
              0.056397941661387,
              48.902464937324
            ],
            [
              0.050262531991726,
              48.899327115259
            ],
            [
              0.029400966736207,
              48.896209575661
            ],
            [
              -0.0098551045630034,
              48.883799593808
            ],
            [
              -0.011506033170249,
              48.876767201176
            ],
            [
              -0.030670007144589,
              48.873875022828
            ],
            [
              -0.038037636202188,
              48.863918022503
            ],
            [
              -0.058807358031453,
              48.853585090542
            ],
            [
              -0.064951500120938,
              48.843876035026
            ],
            [
              -0.07542259650144,
              48.840552525635
            ],
            [
              -0.085065363659073,
              48.84465210024
            ],
            [
              -0.12302979454988,
              48.83989361083
            ],
            [
              -0.13116138889035,
              48.836075357038
            ],
            [
              -0.14634831603259,
              48.834445361701
            ],
            [
              -0.13795704824172,
              48.819284867311
            ],
            [
              -0.15176886176769,
              48.820241817199
            ],
            [
              -0.17011128627476,
              48.824748495738
            ],
            [
              -0.17672674490573,
              48.834230614364
            ],
            [
              -0.19285228241601,
              48.839222155052
            ],
            [
              -0.20440370993563,
              48.846439520008
            ],
            [
              -0.21488514798809,
              48.840711804879
            ],
            [
              -0.24245301992163,
              48.849361569995
            ],
            [
              -0.25731737381123,
              48.84933562977
            ],
            [
              -0.27739831098258,
              48.853877904894
            ],
            [
              -0.2991201076076,
              48.849595577636
            ],
            [
              -0.3059276101914,
              48.858302472146
            ],
            [
              -0.3285658327644,
              48.857284809069
            ],
            [
              -0.33573513698092,
              48.844606574919
            ],
            [
              -0.32739870471583,
              48.839083419227
            ],
            [
              -0.34624167639838,
              48.821996521333
            ],
            [
              -0.3692517151007,
              48.834820966697
            ],
            [
              -0.36104176431882,
              48.842345104073
            ],
            [
              -0.38620858191094,
              48.845261692646
            ],
            [
              -0.40268285580141,
              48.859713233718
            ],
            [
              -0.41152165194749,
              48.870143563618
            ],
            [
              -0.42421989698548,
              48.867966028804
            ],
            [
              -0.43283305716342,
              48.874555864698
            ],
            [
              -0.45091393587934,
              48.869789573007
            ],
            [
              -0.46375829628735,
              48.87136811503
            ],
            [
              -0.47833860425553,
              48.858946444986
            ],
            [
              -0.49658169612073,
              48.85831632901
            ],
            [
              -0.49404523940415,
              48.848573731858
            ],
            [
              -0.50676720578069,
              48.845734692966
            ],
            [
              -0.51485562488587,
              48.850082898968
            ],
            [
              -0.54452262601951,
              48.847893677572
            ],
            [
              -0.57109680421828,
              48.830168192928
            ],
            [
              -0.58076109720042,
              48.838012034487
            ],
            [
              -0.60542466758523,
              48.834234951977
            ],
            [
              -0.62644490688407,
              48.826806344567
            ],
            [
              -0.64541589959203,
              48.827214099453
            ],
            [
              -0.64321988620605,
              48.840678388106
            ],
            [
              -0.65724264527302,
              48.839050095879
            ],
            [
              -0.69808599922195,
              48.843600554779
            ],
            [
              -0.70533295842158,
              48.838459480586
            ],
            [
              -0.69644558592608,
              48.830231973246
            ],
            [
              -0.68216886558739,
              48.821835101496
            ],
            [
              -0.70569111434171,
              48.808679576837
            ],
            [
              -0.71468547539624,
              48.799868429628
            ],
            [
              -0.72328764272428,
              48.799269249018
            ],
            [
              -0.74000486268258,
              48.792883672969
            ],
            [
              -0.7642699948279,
              48.787729137391
            ],
            [
              -0.78062135391124,
              48.786653395386
            ],
            [
              -0.79437873595195,
              48.776865247492
            ],
            [
              -0.80083378227683,
              48.765352280557
            ],
            [
              -0.81863751879145,
              48.754801587094
            ],
            [
              -0.84094105476412,
              48.752223582274
            ],
            [
              -0.83586390210947,
              48.75907014808
            ],
            [
              -0.85387405368382,
              48.763877386403
            ],
            [
              -0.87084306832372,
              48.755747083772
            ],
            [
              -0.88440205541888,
              48.756990387555
            ],
            [
              -0.89348419949129,
              48.766124342405
            ],
            [
              -0.91919344396431,
              48.770846161014
            ],
            [
              -0.94033611373863,
              48.778571609871
            ],
            [
              -0.94544441248664,
              48.784659596649
            ],
            [
              -0.96705625172907,
              48.786081268022
            ],
            [
              -0.97100455303881,
              48.772828521949
            ],
            [
              -0.99144565445194,
              48.778122680489
            ],
            [
              -1.0102372535173,
              48.774014961547
            ],
            [
              -1.0239195312287,
              48.7759589758
            ],
            [
              -1.0455588935307,
              48.782604961546
            ],
            [
              -1.0587396460307,
              48.773348161227
            ],
            [
              -1.0894717560428,
              48.781518523516
            ],
            [
              -1.1003652053547,
              48.789674356188
            ],
            [
              -1.0946891771954,
              48.800719344414
            ],
            [
              -1.1023003567829,
              48.814263957252
            ],
            [
              -1.1305025921914,
              48.820021269129
            ],
            [
              -1.1535636085327,
              48.82219908382
            ],
            [
              -1.1595014604014,
              48.82620729012
            ],
            [
              -1.1553830397846,
              48.836458793132
            ],
            [
              -1.141691137149,
              48.837551886611
            ],
            [
              -1.1326244563768,
              48.848849041978
            ],
            [
              -1.121155943427,
              48.848217447571
            ],
            [
              -1.1217118082419,
              48.855308135365
            ],
            [
              -1.1028797262918,
              48.865101716154
            ],
            [
              -1.0985223309161,
              48.876085328616
            ],
            [
              -1.0711778612927,
              48.870286148434
            ],
            [
              -1.0591714116763,
              48.877258889935
            ],
            [
              -1.049194357089,
              48.89403173962
            ],
            [
              -1.0285110025673,
              48.901166391516
            ],
            [
              -1.0137364005261,
              48.915956453501
            ],
            [
              -1.0137636502161,
              48.92248637784
            ],
            [
              -1.0226211199025,
              48.927085647197
            ],
            [
              -1.035972876941,
              48.923542402676
            ],
            [
              -1.0755194629225,
              48.921508411991
            ],
            [
              -1.0664754230573,
              48.930105576078
            ],
            [
              -1.0635567972721,
              48.946352358841
            ],
            [
              -1.0553210379068,
              48.950810391987
            ],
            [
              -1.0565254473082,
              48.958444860029
            ],
            [
              -1.0284088946,
              48.962309074946
            ],
            [
              -1.0136550949671,
              48.954124357442
            ],
            [
              -0.99989884101366,
              48.949851065815
            ],
            [
              -0.97080529201629,
              48.957263015587
            ],
            [
              -0.95573198276557,
              48.966231366504
            ],
            [
              -0.94272995383286,
              48.967706984878
            ],
            [
              -0.91364777549745,
              48.990960439638
            ],
            [
              -0.90511414763453,
              49.011393751389
            ],
            [
              -0.89542446041669,
              49.01170524167
            ],
            [
              -0.88557803424434,
              49.022391313255
            ],
            [
              -0.8694559276149,
              49.027025688892
            ],
            [
              -0.86415044765934,
              49.034443715562
            ],
            [
              -0.87366526623988,
              49.04018664407
            ],
            [
              -0.88451826383679,
              49.038716825208
            ],
            [
              -0.89165406184456,
              49.046294477679
            ],
            [
              -0.87639081528478,
              49.054815856126
            ],
            [
              -0.86909257922002,
              49.067901172753
            ],
            [
              -0.87038334838631,
              49.078308430052
            ],
            [
              -0.8799621463578,
              49.088797464537
            ],
            [
              -0.87686430714386,
              49.097973354467
            ],
            [
              -0.87845403715793,
              49.117103390716
            ],
            [
              -0.8940645097255,
              49.116755302973
            ],
            [
              -0.91831266671293,
              49.104996360271
            ],
            [
              -0.91341262847177,
              49.11485491887
            ],
            [
              -0.89145000590453,
              49.121820735408
            ],
            [
              -0.88659533008778,
              49.129429507194
            ],
            [
              -0.9082130769048,
              49.139124090468
            ],
            [
              -0.93145227164953,
              49.145575300849
            ],
            [
              -0.93840915692818,
              49.153796870908
            ],
            [
              -0.95853549196422,
              49.161275765491
            ],
            [
              -0.94993136874348,
              49.167723933697
            ],
            [
              -0.93733782641062,
              49.161524007485
            ],
            [
              -0.9265375261167,
              49.164211141303
            ],
            [
              -0.92159698525926,
              49.177808099261
            ],
            [
              -0.90789248124883,
              49.187581183412
            ],
            [
              -0.91014418669767,
              49.193477773729
            ],
            [
              -0.90087557977357,
              49.202972349148
            ],
            [
              -0.90623307934633,
              49.210246263522
            ],
            [
              -0.92058243337368,
              49.221066573082
            ],
            [
              -0.93236757629277,
              49.218052232902
            ],
            [
              -0.94860370794048,
              49.209215453018
            ],
            [
              -0.95553394445393,
              49.199703849463
            ],
            [
              -0.97546102084932,
              49.192158499386
            ],
            [
              -1.008331107792,
              49.204438040158
            ],
            [
              -1.0226746076439,
              49.203388961893
            ],
            [
              -1.0397982304764,
              49.221748483848
            ],
            [
              -1.0465002285724,
              49.221145430593
            ],
            [
              -1.0705285280238,
              49.231351483459
            ],
            [
              -1.0929054280943,
              49.244207033565
            ],
            [
              -1.1134205603101,
              49.26132609411
            ],
            [
              -1.1359174141965,
              49.270327053301
            ],
            [
              -1.1287283915965,
              49.284366097517
            ],
            [
              -1.1400992472453,
              49.297391637898
            ],
            [
              -1.139541028092,
              49.309870906862
            ],
            [
              -1.114799377366,
              49.325241838469
            ],
            [
              -1.1134683084454,
              49.32794259286
            ],
            [
              -1.1196198636379,
              49.355568981262
            ]
          ]
        ]
      },
      "properties": {
        "code": "14",
        "nom": "Calvados",
        "population": 697547,
        "delitspour1000": "4,84",
        "nombremosque": 11,
        "parentsEtrangers": "84,4",
        "benefRsa": 25143,
        "eoliennes": 49
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.5084125130653,
              45.478501516921
            ],
            [
              2.5244372055202,
              45.480702895801
            ],
            [
              2.5439644302012,
              45.478981170304
            ],
            [
              2.5358970743575,
              45.458079509752
            ],
            [
              2.5518965086317,
              45.463448356213
            ],
            [
              2.5672426391961,
              45.464362241387
            ],
            [
              2.5740484247647,
              45.456284905861
            ],
            [
              2.5903809064644,
              45.450053215627
            ],
            [
              2.6048958880008,
              45.451728826462
            ],
            [
              2.6223003491009,
              45.468508742321
            ],
            [
              2.6246720210042,
              45.444977466593
            ],
            [
              2.6514324889273,
              45.442655959982
            ],
            [
              2.6633954828406,
              45.434165070918
            ],
            [
              2.6782771810773,
              45.438428333547
            ],
            [
              2.6884626875256,
              45.437688088975
            ],
            [
              2.6896910706442,
              45.430037537145
            ],
            [
              2.6799359294086,
              45.422690240334
            ],
            [
              2.6807334465205,
              45.407169426605
            ],
            [
              2.6939228094584,
              45.400311384007
            ],
            [
              2.7013819605454,
              45.391090987905
            ],
            [
              2.7148508522915,
              45.381555103256
            ],
            [
              2.725880283048,
              45.388849317837
            ],
            [
              2.7421913474358,
              45.393334488142
            ],
            [
              2.7623393691285,
              45.38616186717
            ],
            [
              2.785884313405,
              45.388714396394
            ],
            [
              2.8012326053351,
              45.392862254268
            ],
            [
              2.814891870243,
              45.40096363738
            ],
            [
              2.8226067013152,
              45.397871340292
            ],
            [
              2.8287310946056,
              45.390108137913
            ],
            [
              2.8523722940921,
              45.392340683984
            ],
            [
              2.8562660550707,
              45.385530211352
            ],
            [
              2.8677290396958,
              45.376075172391
            ],
            [
              2.873531045267,
              45.375373216731
            ],
            [
              2.8819725221573,
              45.383653077697
            ],
            [
              2.8929713508093,
              45.379235700569
            ],
            [
              2.9062723283924,
              45.369273324711
            ],
            [
              2.9215506466252,
              45.365775113903
            ],
            [
              2.9257598423702,
              45.342632884539
            ],
            [
              2.9486247554478,
              45.309121333078
            ],
            [
              2.9668089592388,
              45.307031080426
            ],
            [
              2.9979830556833,
              45.293972428423
            ],
            [
              3.0020295632398,
              45.290169884137
            ],
            [
              3.0167126860657,
              45.287121578381
            ],
            [
              3.0217850748584,
              45.295366903543
            ],
            [
              3.0406307985252,
              45.298901267002
            ],
            [
              3.0585633104717,
              45.306568657606
            ],
            [
              3.0651711253653,
              45.314667164704
            ],
            [
              3.0602659894023,
              45.330587028732
            ],
            [
              3.0704296209841,
              45.33726779338
            ],
            [
              3.0817054326028,
              45.336203010406
            ],
            [
              3.0907015039255,
              45.341144929817
            ],
            [
              3.0908481025946,
              45.347261540297
            ],
            [
              3.1035006854512,
              45.3543721973
            ],
            [
              3.1132110011179,
              45.340720132847
            ],
            [
              3.1134170784877,
              45.327179037948
            ],
            [
              3.1021782492329,
              45.32829200366
            ],
            [
              3.0906259135448,
              45.322941615477
            ],
            [
              3.0986731324196,
              45.316890142915
            ],
            [
              3.1118263845109,
              45.313740635219
            ],
            [
              3.1138141353708,
              45.309094157134
            ],
            [
              3.1034918787719,
              45.300169117338
            ],
            [
              3.0873008074715,
              45.300090176966
            ],
            [
              3.0822533822787,
              45.289688544009
            ],
            [
              3.105148500604,
              45.290981303514
            ],
            [
              3.1180031054796,
              45.284189993561
            ],
            [
              3.1400139343437,
              45.288451588095
            ],
            [
              3.1597095041841,
              45.295152377879
            ],
            [
              3.1691531773693,
              45.282263132859
            ],
            [
              3.1677609638906,
              45.270173275741
            ],
            [
              3.1863759697233,
              45.277320274896
            ],
            [
              3.204971349739,
              45.281991626537
            ],
            [
              3.212582002963,
              45.280633967095
            ],
            [
              3.2224601549298,
              45.27197325761
            ],
            [
              3.2269226623347,
              45.255474618741
            ],
            [
              3.2235618386219,
              45.248407587484
            ],
            [
              3.2344165982605,
              45.237646801741
            ],
            [
              3.2353557133346,
              45.220087359774
            ],
            [
              3.2395682874116,
              45.216949421531
            ],
            [
              3.2724807359217,
              45.209183446862
            ],
            [
              3.27095870007,
              45.189800103738
            ],
            [
              3.2609061148723,
              45.182035396308
            ],
            [
              3.2624392836968,
              45.168271591934
            ],
            [
              3.2689188872567,
              45.153868408758
            ],
            [
              3.2801638710759,
              45.152748540438
            ],
            [
              3.2771175354501,
              45.14173327527
            ],
            [
              3.2880281118685,
              45.120420821148
            ],
            [
              3.3013698906648,
              45.124093455888
            ],
            [
              3.3317703613755,
              45.110834170392
            ],
            [
              3.3458478444637,
              45.112462558565
            ],
            [
              3.3507814869451,
              45.109389356926
            ],
            [
              3.3481183205186,
              45.100925761005
            ],
            [
              3.3257877003029,
              45.095136110288
            ],
            [
              3.3052413548281,
              45.101245881124
            ],
            [
              3.3009895867502,
              45.106847159996
            ],
            [
              3.2833105792233,
              45.103764571827
            ],
            [
              3.2874533238806,
              45.089020664877
            ],
            [
              3.2973357212079,
              45.091373605878
            ],
            [
              3.3142746628813,
              45.085018914722
            ],
            [
              3.3104512763056,
              45.074436414647
            ],
            [
              3.2994724846322,
              45.060984807099
            ],
            [
              3.3029161653541,
              45.049161419106
            ],
            [
              3.2972465044248,
              45.036076897709
            ],
            [
              3.3105771648058,
              45.021342574102
            ],
            [
              3.3288068281374,
              45.030050111422
            ],
            [
              3.3371714464095,
              45.025342171262
            ],
            [
              3.3249714515026,
              45.009790053743
            ],
            [
              3.3304042236218,
              45.007897658864
            ],
            [
              3.3471935905944,
              45.014231193671
            ],
            [
              3.3499123069278,
              45.002602305465
            ],
            [
              3.3699091459722,
              45.000582005192
            ],
            [
              3.3647931807115,
              44.990591133341
            ],
            [
              3.3467980934789,
              44.987464025416
            ],
            [
              3.3479469309243,
              44.973870126914
            ],
            [
              3.3613476310625,
              44.971408091786
            ],
            [
              3.3545315434466,
              44.9548199209
            ],
            [
              3.3379420512705,
              44.955906998959
            ],
            [
              3.2973059946128,
              44.935604538605
            ],
            [
              3.2860179111885,
              44.926382028761
            ],
            [
              3.2713373103277,
              44.927600109493
            ],
            [
              3.2615217326405,
              44.932067820131
            ],
            [
              3.2595403854401,
              44.942429341236
            ],
            [
              3.245167534381,
              44.93480347612
            ],
            [
              3.2496887102206,
              44.916235200562
            ],
            [
              3.2278135177439,
              44.909928387989
            ],
            [
              3.2269844428022,
              44.897602452938
            ],
            [
              3.2323433112231,
              44.891622050737
            ],
            [
              3.2252942367053,
              44.882045162686
            ],
            [
              3.2159619013053,
              44.875041006609
            ],
            [
              3.1991804637825,
              44.874642009283
            ],
            [
              3.1823170380273,
              44.863735423357
            ],
            [
              3.1690213470506,
              44.874091478783
            ],
            [
              3.1531732734567,
              44.893017216949
            ],
            [
              3.1410977876348,
              44.903315197313
            ],
            [
              3.1283803710941,
              44.903578216134
            ],
            [
              3.1229128915544,
              44.892733396595
            ],
            [
              3.1054945101096,
              44.886774806731
            ],
            [
              3.1050371687709,
              44.86639509115
            ],
            [
              3.0953252337809,
              44.85208399024
            ],
            [
              3.1001211680904,
              44.844373808687
            ],
            [
              3.0994806863618,
              44.833584813393
            ],
            [
              3.0714754944234,
              44.834124974928
            ],
            [
              3.0768587089093,
              44.824410436955
            ],
            [
              3.0615217992797,
              44.817172380452
            ],
            [
              3.0457487033386,
              44.799052030765
            ],
            [
              3.0503924394573,
              44.779809366102
            ],
            [
              3.0422427573327,
              44.75938114179
            ],
            [
              3.0310731845345,
              44.749413959606
            ],
            [
              3.028416597051,
              44.73255744411
            ],
            [
              3.0353535096078,
              44.727206782516
            ],
            [
              3.0391236076168,
              44.714995724501
            ],
            [
              3.0251405593834,
              44.716097238134
            ],
            [
              3.0160122019676,
              44.713692453341
            ],
            [
              2.9985742640224,
              44.674442966678
            ],
            [
              2.981760369824,
              44.651994919411
            ],
            [
              2.981675726654,
              44.644673011362
            ],
            [
              2.9729967000843,
              44.645719145774
            ],
            [
              2.9637576145748,
              44.654316787673
            ],
            [
              2.9487265645702,
              44.672978048006
            ],
            [
              2.9392865668235,
              44.677756455026
            ],
            [
              2.9408965571355,
              44.683818918865
            ],
            [
              2.9338731506521,
              44.697402037726
            ],
            [
              2.9350900117718,
              44.705004788991
            ],
            [
              2.9232670003911,
              44.728642526112
            ],
            [
              2.9322008334785,
              44.738182515263
            ],
            [
              2.9335256868255,
              44.750101514437
            ],
            [
              2.9284555057549,
              44.755160769806
            ],
            [
              2.9172495539886,
              44.756714098966
            ],
            [
              2.9208013627531,
              44.765729671884
            ],
            [
              2.9348220891073,
              44.766338409064
            ],
            [
              2.9345177106412,
              44.779201871895
            ],
            [
              2.920189595566,
              44.794308778558
            ],
            [
              2.9040726320859,
              44.783702886621
            ],
            [
              2.8897062556822,
              44.788417083385
            ],
            [
              2.8795747424774,
              44.803006111318
            ],
            [
              2.8705082792207,
              44.827398299766
            ],
            [
              2.8559650223393,
              44.855179951014
            ],
            [
              2.8596736604077,
              44.874465473337
            ],
            [
              2.8496519747355,
              44.871490231757
            ],
            [
              2.8220333243708,
              44.87157004877
            ],
            [
              2.801957650579,
              44.873625756087
            ],
            [
              2.772707056045,
              44.855545795389
            ],
            [
              2.7684108734637,
              44.863858763976
            ],
            [
              2.77049124514,
              44.875537836385
            ],
            [
              2.7783381480633,
              44.887718357993
            ],
            [
              2.7718590507401,
              44.902271109449
            ],
            [
              2.776087665079,
              44.908963934931
            ],
            [
              2.7735403561236,
              44.915091091551
            ],
            [
              2.7603927919273,
              44.927510072233
            ],
            [
              2.738258109046,
              44.941219492647
            ],
            [
              2.731006631595,
              44.936748083927
            ],
            [
              2.7240210839286,
              44.924576074703
            ],
            [
              2.7135929831216,
              44.92713012749
            ],
            [
              2.7064336715881,
              44.907221617118
            ],
            [
              2.6938913636929,
              44.902938090171
            ],
            [
              2.6812975408382,
              44.907392758521
            ],
            [
              2.6581063096255,
              44.882979115397
            ],
            [
              2.6562270538803,
              44.87241580597
            ],
            [
              2.6490646425252,
              44.869728369056
            ],
            [
              2.6366080646644,
              44.872551413571
            ],
            [
              2.6239295606463,
              44.866918027702
            ],
            [
              2.6180527448461,
              44.854603721987
            ],
            [
              2.6026823497168,
              44.843163111324
            ],
            [
              2.6117393110443,
              44.83131246301
            ],
            [
              2.6074714184,
              44.824096540945
            ],
            [
              2.5971356193221,
              44.819510989142
            ],
            [
              2.6013884128515,
              44.807574409416
            ],
            [
              2.5993613568997,
              44.792806185507
            ],
            [
              2.5865648469617,
              44.783990021983
            ],
            [
              2.5735477024072,
              44.784962589937
            ],
            [
              2.5627129322914,
              44.775756861827
            ],
            [
              2.5585893921439,
              44.757924931793
            ],
            [
              2.5516388576146,
              44.750719684187
            ],
            [
              2.5544909688881,
              44.73964379349
            ],
            [
              2.5519407104745,
              44.732804630794
            ],
            [
              2.556122557476,
              44.721283990134
            ],
            [
              2.5184594908124,
              44.703480601801
            ],
            [
              2.5188863490069,
              44.699027751647
            ],
            [
              2.4989152190022,
              44.687020949858
            ],
            [
              2.4875777637021,
              44.666984218285
            ],
            [
              2.4901340080947,
              44.656773198157
            ],
            [
              2.4831874702259,
              44.650342597738
            ],
            [
              2.4689992507151,
              44.649641593651
            ],
            [
              2.4680389011889,
              44.642888409527
            ],
            [
              2.4521635947204,
              44.648220759561
            ],
            [
              2.435000853128,
              44.638874930003
            ],
            [
              2.4055217700154,
              44.647179554811
            ],
            [
              2.397445965387,
              44.646186044198
            ],
            [
              2.3782230201151,
              44.650161602045
            ],
            [
              2.3659976814652,
              44.641342211431
            ],
            [
              2.3485386057121,
              44.641480228035
            ],
            [
              2.3327786804308,
              44.650617960529
            ],
            [
              2.3365579816568,
              44.661275985276
            ],
            [
              2.3267908312388,
              44.669693386939
            ],
            [
              2.3045868091932,
              44.662449015977
            ],
            [
              2.2910368223518,
              44.666581877606
            ],
            [
              2.270930098105,
              44.660401110765
            ],
            [
              2.264931023707,
              44.660757552111
            ],
            [
              2.2508940527358,
              44.651888047018
            ],
            [
              2.2286252203003,
              44.655106676519
            ],
            [
              2.2084152778134,
              44.643842310283
            ],
            [
              2.214541909482,
              44.627080806224
            ],
            [
              2.2198705673093,
              44.623646052351
            ],
            [
              2.2074728028173,
              44.61552895784
            ],
            [
              2.1908059572226,
              44.628252004845
            ],
            [
              2.1694164789558,
              44.638069782843
            ],
            [
              2.1687595856936,
              44.647386736712
            ],
            [
              2.1740442827509,
              44.653644834741
            ],
            [
              2.1655660362222,
              44.661375303382
            ],
            [
              2.1791523780579,
              44.674446124105
            ],
            [
              2.1630322730753,
              44.690215331586
            ],
            [
              2.1552964923022,
              44.70023944349
            ],
            [
              2.1386637746726,
              44.692880659597
            ],
            [
              2.1301318075949,
              44.698486086307
            ],
            [
              2.1336852541707,
              44.70956475715
            ],
            [
              2.1479681938401,
              44.723035803571
            ],
            [
              2.1522238006118,
              44.736723151663
            ],
            [
              2.1534957604021,
              44.753104006989
            ],
            [
              2.1493997719958,
              44.769790512407
            ],
            [
              2.1667022088341,
              44.772651831571
            ],
            [
              2.1716374993124,
              44.790027108976
            ],
            [
              2.1652929430823,
              44.799560342182
            ],
            [
              2.1658208187006,
              44.812904212621
            ],
            [
              2.1398031019064,
              44.823820670465
            ],
            [
              2.1227770243941,
              44.847633102732
            ],
            [
              2.1165704984568,
              44.850184202716
            ],
            [
              2.0942097640089,
              44.872012369152
            ],
            [
              2.0837359232769,
              44.887424173789
            ],
            [
              2.0864901589829,
              44.901079919432
            ],
            [
              2.1081047030139,
              44.910638688547
            ],
            [
              2.0892278644094,
              44.928560463933
            ],
            [
              2.0786238601329,
              44.932494333995
            ],
            [
              2.0763195910268,
              44.940561879615
            ],
            [
              2.0806940542158,
              44.953285983552
            ],
            [
              2.0629079799591,
              44.9765045515
            ],
            [
              2.0766169639286,
              44.978785623857
            ],
            [
              2.0905743960003,
              44.984665148518
            ],
            [
              2.1003714972007,
              44.983427469854
            ],
            [
              2.1329815746739,
              44.985516038406
            ],
            [
              2.1381513494782,
              44.992811832821
            ],
            [
              2.1406414310588,
              45.005858479694
            ],
            [
              2.1168066989252,
              45.02111434735
            ],
            [
              2.1094104611066,
              45.041521518867
            ],
            [
              2.0993181256264,
              45.047622806571
            ],
            [
              2.0951568732001,
              45.056033465741
            ],
            [
              2.1037456940938,
              45.065812421906
            ],
            [
              2.1183646353816,
              45.070379067781
            ],
            [
              2.1298193059745,
              45.078932414297
            ],
            [
              2.1456535641311,
              45.085592171697
            ],
            [
              2.1717594845165,
              45.081496802672
            ],
            [
              2.1803618094732,
              45.09734068898
            ],
            [
              2.1794393252059,
              45.109177492216
            ],
            [
              2.1852692693185,
              45.113998024195
            ],
            [
              2.1787905007908,
              45.136297935946
            ],
            [
              2.1948369367108,
              45.136027708871
            ],
            [
              2.2115654221992,
              45.14844827392
            ],
            [
              2.2137008320653,
              45.160189965969
            ],
            [
              2.225397887065,
              45.160336595328
            ],
            [
              2.2335857511761,
              45.167181650239
            ],
            [
              2.2195874303098,
              45.172114610573
            ],
            [
              2.2011879889027,
              45.181494229499
            ],
            [
              2.1985478420586,
              45.194408666334
            ],
            [
              2.1905131897891,
              45.20213434701
            ],
            [
              2.1953636990438,
              45.220851418291
            ],
            [
              2.2249287044688,
              45.241854962029
            ],
            [
              2.2413408281325,
              45.24942616212
            ],
            [
              2.2387747025234,
              45.260353952142
            ],
            [
              2.245004874828,
              45.267106520498
            ],
            [
              2.2585683903144,
              45.270220164439
            ],
            [
              2.2617348470443,
              45.28322143744
            ],
            [
              2.2714111009736,
              45.290154603854
            ],
            [
              2.2800803441611,
              45.287325615297
            ],
            [
              2.2920696943683,
              45.290569998093
            ],
            [
              2.3073512042479,
              45.312659714856
            ],
            [
              2.3177397642658,
              45.322963729764
            ],
            [
              2.3504806858312,
              45.327560923525
            ],
            [
              2.3593793404283,
              45.335790299537
            ],
            [
              2.3513461420151,
              45.348610937353
            ],
            [
              2.3647790516305,
              45.357919621969
            ],
            [
              2.3629697029865,
              45.378412337982
            ],
            [
              2.3682726042671,
              45.388621392102
            ],
            [
              2.3644462766137,
              45.395234506367
            ],
            [
              2.3545525332951,
              45.401398504646
            ],
            [
              2.3500842175271,
              45.409676058097
            ],
            [
              2.3551307955913,
              45.415027972839
            ],
            [
              2.3782501191323,
              45.414302210343
            ],
            [
              2.3954865345645,
              45.407322290365
            ],
            [
              2.3982881363717,
              45.400169538674
            ],
            [
              2.4129045453324,
              45.396651442056
            ],
            [
              2.4226736731266,
              45.397229870143
            ],
            [
              2.441865995091,
              45.384259395763
            ],
            [
              2.4580813755781,
              45.384827047932
            ],
            [
              2.4734557788383,
              45.38152628147
            ],
            [
              2.4778316883062,
              45.370410647653
            ],
            [
              2.4857679741129,
              45.378946887778
            ],
            [
              2.5227321270438,
              45.382084686424
            ],
            [
              2.5262353366501,
              45.389343818253
            ],
            [
              2.5220338520841,
              45.402237812322
            ],
            [
              2.5159713869265,
              45.403236533013
            ],
            [
              2.4927584232596,
              45.413842323437
            ],
            [
              2.487471709413,
              45.418842030111
            ],
            [
              2.4955946696815,
              45.44550416324
            ],
            [
              2.5067704533992,
              45.464065004968
            ],
            [
              2.5084125130653,
              45.478501516921
            ]
          ]
        ]
      },
      "properties": {
        "code": "15",
        "nom": "Cantal",
        "population": 144379,
        "delitspour1000": "3,505",
        "nombremosque": 3,
        "parentsEtrangers": "89,8",
        "benefRsa": 3557,
        "eoliennes": 47
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              0.8148247207739,
              48.670163564137
            ],
            [
              0.82767132893029,
              48.680720042791
            ],
            [
              0.85036287028898,
              48.682948048025
            ],
            [
              0.86149783759132,
              48.691007914902
            ],
            [
              0.87671116761022,
              48.715496114803
            ],
            [
              0.88836808739528,
              48.718920532577
            ],
            [
              0.90239998073196,
              48.710649225086
            ],
            [
              0.92119872414556,
              48.709175212814
            ],
            [
              0.95524192597313,
              48.716786068922
            ],
            [
              0.96144420763056,
              48.725722137279
            ],
            [
              0.9772943914531,
              48.730138669646
            ],
            [
              0.98441493173502,
              48.725995395669
            ],
            [
              1.0140639866541,
              48.72810030179
            ],
            [
              1.0206075605327,
              48.734789446779
            ],
            [
              1.0316499412576,
              48.730581545643
            ],
            [
              1.0398026909049,
              48.742155339
            ],
            [
              1.0590712054157,
              48.756765184439
            ],
            [
              1.0686653703661,
              48.756913559557
            ],
            [
              1.0757811283562,
              48.749437927852
            ],
            [
              1.086000043818,
              48.756926389494
            ],
            [
              1.0957264674684,
              48.748713222693
            ],
            [
              1.1126577960236,
              48.751863710692
            ],
            [
              1.1209842348348,
              48.767249558527
            ],
            [
              1.1189842149957,
              48.782375233568
            ],
            [
              1.1213959532999,
              48.789195509733
            ],
            [
              1.1521150110915,
              48.78555968408
            ],
            [
              1.1533037151721,
              48.774016041266
            ],
            [
              1.161542788993,
              48.769293220083
            ],
            [
              1.1847360438338,
              48.772630245963
            ],
            [
              1.1981446610666,
              48.769568542277
            ],
            [
              1.223526519249,
              48.758056658402
            ],
            [
              1.2226160798083,
              48.767292140423
            ],
            [
              1.246023632927,
              48.769675823998
            ],
            [
              1.2562637586379,
              48.765103705723
            ],
            [
              1.2545465453882,
              48.758629272206
            ],
            [
              1.2702130511038,
              48.757482633762
            ],
            [
              1.2799854142834,
              48.762674216595
            ],
            [
              1.3002968590985,
              48.767536812046
            ],
            [
              1.3195444496148,
              48.76096144137
            ],
            [
              1.3329054445301,
              48.762612355286
            ],
            [
              1.3422322124976,
              48.772542324536
            ],
            [
              1.3626643019013,
              48.781664300017
            ],
            [
              1.3744255946955,
              48.782613836125
            ],
            [
              1.3769627320869,
              48.791766336256
            ],
            [
              1.3592476591605,
              48.813608561344
            ],
            [
              1.3621823366519,
              48.834179921179
            ],
            [
              1.3905601143901,
              48.8466061658
            ],
            [
              1.3968565494996,
              48.855383585466
            ],
            [
              1.4093657222488,
              48.861152641723
            ],
            [
              1.4238934051882,
              48.861268418973
            ],
            [
              1.4343110180818,
              48.866140106761
            ],
            [
              1.4549936915991,
              48.870326164498
            ],
            [
              1.4653628424762,
              48.877601417126
            ],
            [
              1.4683545148033,
              48.892215598584
            ],
            [
              1.4606700524523,
              48.900494617753
            ],
            [
              1.4593261044838,
              48.914649076535
            ],
            [
              1.4479593034805,
              48.924639965044
            ],
            [
              1.4594110528379,
              48.928926959252
            ],
            [
              1.4613112437714,
              48.937555310548
            ],
            [
              1.4806477281429,
              48.940490376837
            ],
            [
              1.5015266013113,
              48.941051842112
            ],
            [
              1.5090139983632,
              48.936627464834
            ],
            [
              1.5128954188837,
              48.922585369837
            ],
            [
              1.5241021875366,
              48.924752145617
            ],
            [
              1.5386177797921,
              48.921794692396
            ],
            [
              1.5417973746939,
              48.91547990669
            ],
            [
              1.5385235335514,
              48.906685854091
            ],
            [
              1.5572530066459,
              48.891575743208
            ],
            [
              1.5595079054893,
              48.882055273516
            ],
            [
              1.5462328761118,
              48.872431616391
            ],
            [
              1.5552126063723,
              48.865075717505
            ],
            [
              1.5666395741734,
              48.866183647932
            ],
            [
              1.5780393102494,
              48.860864409827
            ],
            [
              1.5815974879282,
              48.854999853184
            ],
            [
              1.5772727483959,
              48.844358116549
            ],
            [
              1.5951919881928,
              48.839707334891
            ],
            [
              1.5941817842602,
              48.834218536506
            ],
            [
              1.5837280523171,
              48.832130726577
            ],
            [
              1.5912058811372,
              48.814866973865
            ],
            [
              1.5834240899801,
              48.807168828475
            ],
            [
              1.5756742997058,
              48.790504096814
            ],
            [
              1.5803992974877,
              48.777559499888
            ],
            [
              1.5875973267999,
              48.77360593191
            ],
            [
              1.5849045861802,
              48.763296867942
            ],
            [
              1.6026875153092,
              48.760486506237
            ],
            [
              1.6264035004424,
              48.748091646614
            ],
            [
              1.617804540369,
              48.735942039093
            ],
            [
              1.5895382120501,
              48.712468154974
            ],
            [
              1.5948810579854,
              48.709310708948
            ],
            [
              1.5795403954022,
              48.701811808781
            ],
            [
              1.5823151457709,
              48.696368116243
            ],
            [
              1.611774872468,
              48.689473861848
            ],
            [
              1.6058633277913,
              48.678762670696
            ],
            [
              1.6024917313048,
              48.663765432374
            ],
            [
              1.6156160327829,
              48.652789464959
            ],
            [
              1.6298476379809,
              48.648875085504
            ],
            [
              1.6431906740267,
              48.651291198129
            ],
            [
              1.64099001993,
              48.643389970225
            ],
            [
              1.6515719070139,
              48.638125076138
            ],
            [
              1.6495606586096,
              48.63227092599
            ],
            [
              1.6582325680054,
              48.627645882726
            ],
            [
              1.656339110342,
              48.622053121145
            ],
            [
              1.6662229993646,
              48.613704063337
            ],
            [
              1.6793591385148,
              48.618488989615
            ],
            [
              1.689422105099,
              48.611536250362
            ],
            [
              1.7148984609516,
              48.614392276576
            ],
            [
              1.7178604312421,
              48.606851120102
            ],
            [
              1.701983609829,
              48.584999462088
            ],
            [
              1.7092486767776,
              48.578026100992
            ],
            [
              1.7368302606076,
              48.572280930629
            ],
            [
              1.7458629248393,
              48.576137031624
            ],
            [
              1.765322855183,
              48.569374568412
            ],
            [
              1.7674104287362,
              48.559405546059
            ],
            [
              1.7872423006556,
              48.553746336139
            ],
            [
              1.7757543018107,
              48.527702827198
            ],
            [
              1.7786170432038,
              48.508131705459
            ],
            [
              1.7906352036641,
              48.497377315443
            ],
            [
              1.7857092863217,
              48.489936911612
            ],
            [
              1.7963309289573,
              48.484187938277
            ],
            [
              1.8014512893542,
              48.466086215218
            ],
            [
              1.8363839553347,
              48.466477806902
            ],
            [
              1.8448630603718,
              48.449360706547
            ],
            [
              1.8774887068591,
              48.440613561663
            ],
            [
              1.9064076592587,
              48.440146719021
            ],
            [
              1.9065549813045,
              48.445672344539
            ],
            [
              1.9207376717625,
              48.447750046159
            ],
            [
              1.9221462784913,
              48.457599361977
            ],
            [
              1.9304234130097,
              48.453403326926
            ],
            [
              1.9331248410711,
              48.442264032481
            ],
            [
              1.9428970138616,
              48.441083787001
            ],
            [
              1.9378068315128,
              48.426390003183
            ],
            [
              1.925889401548,
              48.412741750651
            ],
            [
              1.9305611178719,
              48.404126689728
            ],
            [
              1.9427703207411,
              48.405651458209
            ],
            [
              1.9619541453882,
              48.404321075988
            ],
            [
              1.9765710225943,
              48.399385315775
            ],
            [
              1.966381938603,
              48.38158815854
            ],
            [
              1.9763108383484,
              48.380270715947
            ],
            [
              1.9868237281778,
              48.362141015776
            ],
            [
              1.9733953665973,
              48.352974683353
            ],
            [
              1.9749473035511,
              48.335258065972
            ],
            [
              1.9823432207772,
              48.328312555088
            ],
            [
              1.9744919508382,
              48.323163720082
            ],
            [
              1.9694413393402,
              48.314231718094
            ],
            [
              1.959225976988,
              48.308685064463
            ],
            [
              1.9666102219583,
              48.295582043441
            ],
            [
              1.9752915866555,
              48.287202034779
            ],
            [
              1.9940901445311,
              48.286584124472
            ],
            [
              1.9878769347764,
              48.268708475186
            ],
            [
              1.9750911067358,
              48.258135172452
            ],
            [
              1.9654789698809,
              48.255468044666
            ],
            [
              1.9620649748347,
              48.239631547157
            ],
            [
              1.9664071067243,
              48.234790636014
            ],
            [
              1.9632503662396,
              48.224349916739
            ],
            [
              1.9776716043689,
              48.201286202611
            ],
            [
              1.9657744773308,
              48.200350247033
            ],
            [
              1.9572597779247,
              48.203598595549
            ],
            [
              1.9514590226977,
              48.189583913554
            ],
            [
              1.9709879585943,
              48.183076826836
            ],
            [
              1.9709649986332,
              48.171066799092
            ],
            [
              1.9571488079852,
              48.174876250944
            ],
            [
              1.9370288170292,
              48.168964491691
            ],
            [
              1.9250430961114,
              48.174489271685
            ],
            [
              1.9142642906099,
              48.167458737772
            ],
            [
              1.9061750572175,
              48.156498842196
            ],
            [
              1.9054240606761,
              48.149642686632
            ],
            [
              1.9204042319889,
              48.146381753315
            ],
            [
              1.913003919191,
              48.130978360693
            ],
            [
              1.8996002710496,
              48.127602651294
            ],
            [
              1.8899999941212,
              48.105703747053
            ],
            [
              1.8843812211988,
              48.097658329045
            ],
            [
              1.8733269247989,
              48.098681714322
            ],
            [
              1.8649017038729,
              48.095185580523
            ],
            [
              1.865673925296,
              48.083858124348
            ],
            [
              1.8375940015432,
              48.087070014585
            ],
            [
              1.8321031832628,
              48.080672803607
            ],
            [
              1.8127875257203,
              48.084803197423
            ],
            [
              1.8015345857878,
              48.089428497495
            ],
            [
              1.7979715398442,
              48.076867371023
            ],
            [
              1.7839234552259,
              48.075206756036
            ],
            [
              1.7448338369731,
              48.06614524936
            ],
            [
              1.7288895219128,
              48.068931636276
            ],
            [
              1.7089899355992,
              48.066937747591
            ],
            [
              1.6889292579448,
              48.077953491387
            ],
            [
              1.6789927970749,
              48.065831167459
            ],
            [
              1.6647510484441,
              48.074651678328
            ],
            [
              1.6556744513311,
              48.076172953254
            ],
            [
              1.6380695078541,
              48.067976032039
            ],
            [
              1.6245969691677,
              48.067220090739
            ],
            [
              1.6209568366597,
              48.048718232089
            ],
            [
              1.6138330250117,
              48.044986795647
            ],
            [
              1.5967058847939,
              48.042227836769
            ],
            [
              1.594119689523,
              48.030699968818
            ],
            [
              1.5596657274016,
              48.039559238527
            ],
            [
              1.545309570089,
              48.044589454599
            ],
            [
              1.5407827949442,
              48.037948741421
            ],
            [
              1.5470770086942,
              48.029583279667
            ],
            [
              1.5277102496428,
              48.033498158287
            ],
            [
              1.5129691249084,
              48.02871899692
            ],
            [
              1.5185544224894,
              48.011906147946
            ],
            [
              1.5266726944869,
              48.007268352834
            ],
            [
              1.5229905388051,
              48.001748886761
            ],
            [
              1.5201239288128,
              47.982274877947
            ],
            [
              1.4985767885221,
              47.985269949845
            ],
            [
              1.4817241132483,
              47.996075097984
            ],
            [
              1.4551865518073,
              48.008603442779
            ],
            [
              1.4396507125902,
              48.011948352447
            ],
            [
              1.4326050370739,
              48.008521704213
            ],
            [
              1.4343282577157,
              47.993965039742
            ],
            [
              1.4400648998212,
              47.983577682058
            ],
            [
              1.4372396921872,
              47.976496003088
            ],
            [
              1.4155113167124,
              47.970526434738
            ],
            [
              1.4068814381118,
              47.970098373982
            ],
            [
              1.3869437457769,
              47.981278643463
            ],
            [
              1.3654467424096,
              47.954742350646
            ],
            [
              1.3461734269522,
              47.96510327364
            ],
            [
              1.3374782196309,
              47.957609442464
            ],
            [
              1.3086295485933,
              47.953852019595
            ],
            [
              1.2995800371183,
              47.968162406715
            ],
            [
              1.2808769164174,
              47.965881275401
            ],
            [
              1.2682429066838,
              47.961539855732
            ],
            [
              1.2555641114657,
              47.975813257954
            ],
            [
              1.23681790535,
              47.978786770626
            ],
            [
              1.2214243846787,
              47.975155961261
            ],
            [
              1.2096457784621,
              47.968499657803
            ],
            [
              1.1914910887426,
              47.97196645317
            ],
            [
              1.2026239491606,
              47.977380600309
            ],
            [
              1.1953377175618,
              47.982930982211
            ],
            [
              1.1887835728432,
              47.996402727816
            ],
            [
              1.1713894713748,
              48.008912137747
            ],
            [
              1.1617657870615,
              48.019743054486
            ],
            [
              1.1666237189768,
              48.02739081669
            ],
            [
              1.1439345576956,
              48.028735559399
            ],
            [
              1.1212375728931,
              48.039115084086
            ],
            [
              1.1160430723906,
              48.052056204007
            ],
            [
              1.1078389363408,
              48.060200512892
            ],
            [
              1.1122373595415,
              48.080729190483
            ],
            [
              1.0789221885919,
              48.077372354362
            ],
            [
              1.062762876896,
              48.086519538865
            ],
            [
              1.0391700983591,
              48.085226440702
            ],
            [
              1.0260040377603,
              48.088407330285
            ],
            [
              1.0085530094424,
              48.083129568505
            ],
            [
              0.99582229412804,
              48.088965411212
            ],
            [
              0.99095370477199,
              48.096913623013
            ],
            [
              1.0054939100059,
              48.105937851842
            ],
            [
              1.0456237157271,
              48.117628433242
            ],
            [
              1.0480934527364,
              48.123771227771
            ],
            [
              1.0387317998475,
              48.131649399394
            ],
            [
              1.0125453389282,
              48.132548568904
            ],
            [
              0.99732449699185,
              48.127497016586
            ],
            [
              0.97663931936622,
              48.115724784112
            ],
            [
              0.96380313397324,
              48.105817316238
            ],
            [
              0.95027967602923,
              48.099272840487
            ],
            [
              0.9440254583075,
              48.112374564256
            ],
            [
              0.92827598961367,
              48.111241883119
            ],
            [
              0.9134263403037,
              48.104182844216
            ],
            [
              0.89092552763253,
              48.1020639314
            ],
            [
              0.87953340865691,
              48.103766091457
            ],
            [
              0.87588406994719,
              48.109431789526
            ],
            [
              0.84121734187884,
              48.103059710788
            ],
            [
              0.8553701454218,
              48.122620579792
            ],
            [
              0.852575219489,
              48.133602174445
            ],
            [
              0.87293551592102,
              48.133408559058
            ],
            [
              0.89396954177329,
              48.135535055964
            ],
            [
              0.91379809767445,
              48.135125048448
            ],
            [
              0.91161206421963,
              48.148858532659
            ],
            [
              0.88249645680993,
              48.161766033434
            ],
            [
              0.86198925121346,
              48.166816904566
            ],
            [
              0.83683452952269,
              48.167352245345
            ],
            [
              0.82688955262783,
              48.175387000045
            ],
            [
              0.80835901939965,
              48.18611890903
            ],
            [
              0.79562670501545,
              48.188043105362
            ],
            [
              0.79765841643139,
              48.19445496608
            ],
            [
              0.80626900151784,
              48.203016266265
            ],
            [
              0.83048365574563,
              48.210708729507
            ],
            [
              0.82588953248827,
              48.220721830264
            ],
            [
              0.80608653469826,
              48.239891017509
            ],
            [
              0.80241465108887,
              48.248072322927
            ],
            [
              0.78758977714986,
              48.261313612339
            ],
            [
              0.78587084890053,
              48.271138465423
            ],
            [
              0.79426147145466,
              48.284950588985
            ],
            [
              0.79341825616719,
              48.293008635712
            ],
            [
              0.77758221552843,
              48.302840856139
            ],
            [
              0.76023175104941,
              48.298171659555
            ],
            [
              0.76219430247254,
              48.306876639515
            ],
            [
              0.77313762601396,
              48.325511221527
            ],
            [
              0.78418514184216,
              48.334478837721
            ],
            [
              0.78542500970172,
              48.340430711536
            ],
            [
              0.81840111266411,
              48.349420439199
            ],
            [
              0.82785590745519,
              48.342447024282
            ],
            [
              0.84159508492141,
              48.350229728169
            ],
            [
              0.86181253481946,
              48.350897961325
            ],
            [
              0.87015714346989,
              48.35834027017
            ],
            [
              0.88337252111541,
              48.356669121421
            ],
            [
              0.90184278255778,
              48.373449772268
            ],
            [
              0.9149193773197,
              48.374324097325
            ],
            [
              0.93117784919562,
              48.391265934635
            ],
            [
              0.94224603424783,
              48.399004844657
            ],
            [
              0.9482686361643,
              48.417831978233
            ],
            [
              0.9762713097259,
              48.439081867809
            ],
            [
              0.95840880937073,
              48.44259696324
            ],
            [
              0.94226766472979,
              48.457733974776
            ],
            [
              0.93571270291242,
              48.475553807957
            ],
            [
              0.95629262450835,
              48.482279378048
            ],
            [
              0.95436935096035,
              48.490573966149
            ],
            [
              0.94155894503993,
              48.498694713572
            ],
            [
              0.9540037271624,
              48.503840229688
            ],
            [
              0.96655146260943,
              48.522741687979
            ],
            [
              0.95341814083771,
              48.526244433476
            ],
            [
              0.922603461355,
              48.537716017396
            ],
            [
              0.93876307137649,
              48.550558547099
            ],
            [
              0.92638981194521,
              48.559394233279
            ],
            [
              0.89126612131175,
              48.572177428167
            ],
            [
              0.86767573670944,
              48.573488250275
            ],
            [
              0.85063536439017,
              48.582625709205
            ],
            [
              0.8465873876382,
              48.604782622076
            ],
            [
              0.83304661425976,
              48.61077016626
            ],
            [
              0.81963618816719,
              48.609871113214
            ],
            [
              0.81775390390063,
              48.616320066598
            ],
            [
              0.83140562696703,
              48.633774446005
            ],
            [
              0.8226824397504,
              48.640053608418
            ],
            [
              0.82392074530028,
              48.650041826767
            ],
            [
              0.81294886563427,
              48.660510127486
            ],
            [
              0.8148247207739,
              48.670163564137
            ]
          ]
        ]
      },
      "properties": {
        "code": "28",
        "nom": "Eure-et-Loir",
        "population": 431443,
        "delitspour1000": "4,596",
        "nombremosque": 22,
        "parentsEtrangers": "75,2",
        "benefRsa": 17217,
        "eoliennes": 282
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -2.0068973832739,
                48.566110094782
              ],
              [
                -2.0462102621812,
                48.54401497855
              ],
              [
                -2.0528368137268,
                48.552178456034
              ],
              [
                -2.0448005646409,
                48.564199345405
              ],
              [
                -2.0584396027327,
                48.570382553093
              ],
              [
                -2.0759836927373,
                48.567677401376
              ],
              [
                -2.0953428420334,
                48.574061870811
              ],
              [
                -2.1233206778661,
                48.589010661057
              ],
              [
                -2.123706231573,
                48.604405598781
              ],
              [
                -2.1344816510593,
                48.611643369666
              ],
              [
                -2.1387092211901,
                48.623233227338
              ],
              [
                -2.1503248031597,
                48.627053106726
              ],
              [
                -2.1334957716008,
                48.637088444356
              ],
              [
                -2.1265123781579,
                48.634929665212
              ],
              [
                -2.1151461030982,
                48.640574814888
              ],
              [
                -2.0947895329223,
                48.633600009628
              ],
              [
                -2.0812159935305,
                48.636113938227
              ],
              [
                -2.0780785794745,
                48.640606840627
              ],
              [
                -2.0486923175112,
                48.636979345527
              ],
              [
                -2.0550978778787,
                48.627308162871
              ],
              [
                -2.0479009976935,
                48.624252682515
              ],
              [
                -2.0302658489221,
                48.6230402789
              ],
              [
                -2.033552700515,
                48.609450561797
              ],
              [
                -2.0174903342468,
                48.594980622161
              ],
              [
                -2.0135140412299,
                48.587519422472
              ],
              [
                -2.0052387594747,
                48.588372025047
              ],
              [
                -1.9946685652283,
                48.578424268262
              ],
              [
                -2.0117662463935,
                48.571458977418
              ],
              [
                -2.0068973832739,
                48.566110094782
              ]
            ]
          ],
          [
            [
              [
                -1.9480341971128,
                48.538808017557
              ],
              [
                -1.9591400244487,
                48.536361793933
              ],
              [
                -1.9689408101185,
                48.539138345101
              ],
              [
                -1.9611555626668,
                48.550288017551
              ],
              [
                -1.9630715571236,
                48.555935046598
              ],
              [
                -1.9742078804092,
                48.557792898054
              ],
              [
                -1.9725748468616,
                48.565573079381
              ],
              [
                -1.9819961816246,
                48.573998865405
              ],
              [
                -1.9812825658863,
                48.586178667101
              ],
              [
                -1.9592506615667,
                48.576213011519
              ],
              [
                -1.9541860424982,
                48.578971353192
              ],
              [
                -1.9678110801245,
                48.592143118152
              ],
              [
                -1.9919484014969,
                48.594107232092
              ],
              [
                -2.0110006809827,
                48.604725563563
              ],
              [
                -2.0023941362904,
                48.613055635525
              ],
              [
                -2.0151419114746,
                48.613981579065
              ],
              [
                -2.0183123229782,
                48.620076905126
              ],
              [
                -2.0136236164094,
                48.627010189175
              ],
              [
                -2.0259859464428,
                48.634438167728
              ],
              [
                -2.0202576088523,
                48.639910339273
              ],
              [
                -2.028955958438,
                48.646918475376
              ],
              [
                -1.995234996379,
                48.660138273521
              ],
              [
                -1.9823378822778,
                48.672879184591
              ],
              [
                -1.9849669305136,
                48.681290529268
              ],
              [
                -1.9698310104514,
                48.686323909863
              ],
              [
                -1.9562928614112,
                48.680756983693
              ],
              [
                -1.9425512881469,
                48.685719750246
              ],
              [
                -1.9380749435582,
                48.694458847226
              ],
              [
                -1.905837502586,
                48.690484641205
              ],
              [
                -1.8905081889989,
                48.698944421746
              ],
              [
                -1.8745830467838,
                48.694893912876
              ],
              [
                -1.8561321209073,
                48.704854504824
              ],
              [
                -1.8371380899061,
                48.679159712785
              ],
              [
                -1.8627254313798,
                48.667274741628
              ],
              [
                -1.8633547109875,
                48.659420480639
              ],
              [
                -1.8716628360703,
                48.64740579596
              ],
              [
                -1.8666223954374,
                48.635297119972
              ],
              [
                -1.8569581590835,
                48.624576587136
              ],
              [
                -1.8450690325377,
                48.616364824796
              ],
              [
                -1.8221188983634,
                48.610007750763
              ],
              [
                -1.7641502834765,
                48.60355862709
              ],
              [
                -1.7017734211771,
                48.607233355175
              ],
              [
                -1.6751542146205,
                48.6114230382
              ],
              [
                -1.6611083246856,
                48.61074613166
              ],
              [
                -1.5710894380361,
                48.626441149011
              ],
              [
                -1.5651218255326,
                48.614397614273
              ],
              [
                -1.5484855009897,
                48.607366445795
              ],
              [
                -1.5396520439432,
                48.599920146805
              ],
              [
                -1.544011006159,
                48.587389031303
              ],
              [
                -1.5429927154595,
                48.580446827686
              ],
              [
                -1.5286423474284,
                48.579179736848
              ],
              [
                -1.5189218496777,
                48.56659037005
              ],
              [
                -1.5291705857729,
                48.560522846907
              ],
              [
                -1.5332855375208,
                48.548003700235
              ],
              [
                -1.519564216371,
                48.540018024898
              ],
              [
                -1.512277768964,
                48.524834117063
              ],
              [
                -1.4956406657121,
                48.508898642535
              ],
              [
                -1.4899417997599,
                48.489372357681
              ],
              [
                -1.4731862558468,
                48.485386403307
              ],
              [
                -1.4670861806686,
                48.48806705044
              ],
              [
                -1.4492603238973,
                48.486102161685
              ],
              [
                -1.4362528107594,
                48.478368227471
              ],
              [
                -1.4403529299447,
                48.471140825579
              ],
              [
                -1.429135838213,
                48.462552562467
              ],
              [
                -1.4064842226881,
                48.460604032661
              ],
              [
                -1.3966756078064,
                48.462052879811
              ],
              [
                -1.3772457352191,
                48.458282754255
              ],
              [
                -1.3463506846745,
                48.471670546758
              ],
              [
                -1.3450398581682,
                48.484912379925
              ],
              [
                -1.3393694052252,
                48.490940085021
              ],
              [
                -1.3302853407871,
                48.489791631794
              ],
              [
                -1.3263369028651,
                48.498742404871
              ],
              [
                -1.3025661285534,
                48.498856875187
              ],
              [
                -1.2882493476535,
                48.506990431124
              ],
              [
                -1.2792034941015,
                48.509166405754
              ],
              [
                -1.2695738148732,
                48.524058079717
              ],
              [
                -1.2722481273192,
                48.533920331809
              ],
              [
                -1.254274261097,
                48.543267861594
              ],
              [
                -1.2363206998987,
                48.538079953318
              ],
              [
                -1.2158216927154,
                48.538229063528
              ],
              [
                -1.2065516679372,
                48.542206088249
              ],
              [
                -1.1889294040247,
                48.52889285086
              ],
              [
                -1.1699074343327,
                48.531104413062
              ],
              [
                -1.156893190635,
                48.521285821117
              ],
              [
                -1.1470767776101,
                48.517498765375
              ],
              [
                -1.1305430206604,
                48.52170348713
              ],
              [
                -1.1160901264748,
                48.5212333284
              ],
              [
                -1.0969039995416,
                48.512091635194
              ],
              [
                -1.0701643748629,
                48.508492017418
              ],
              [
                -1.075717285974,
                48.499392729686
              ],
              [
                -1.0782957929126,
                48.481154857966
              ],
              [
                -1.0741061961301,
                48.473896847289
              ],
              [
                -1.0639645498148,
                48.466954435587
              ],
              [
                -1.0654330620157,
                48.451695678779
              ],
              [
                -1.0793641401546,
                48.443271982049
              ],
              [
                -1.0827926455739,
                48.433057379747
              ],
              [
                -1.0779200339792,
                48.421477186884
              ],
              [
                -1.0783677064971,
                48.413230711968
              ],
              [
                -1.0681208970941,
                48.404716742704
              ],
              [
                -1.053937234376,
                48.383986913354
              ],
              [
                -1.0646292178107,
                48.368281337083
              ],
              [
                -1.0590029395387,
                48.358994435228
              ],
              [
                -1.0598919207817,
                48.350532594956
              ],
              [
                -1.0558182376068,
                48.340683235403
              ],
              [
                -1.0450195990474,
                48.32772872854
              ],
              [
                -1.0592143517314,
                48.312093827381
              ],
              [
                -1.0820920155689,
                48.298391604095
              ],
              [
                -1.0930408735111,
                48.281855553692
              ],
              [
                -1.1000554557338,
                48.25927761239
              ],
              [
                -1.0865989301563,
                48.227529635817
              ],
              [
                -1.0806036422976,
                48.21949752801
              ],
              [
                -1.0873107692067,
                48.209806916083
              ],
              [
                -1.0747741638529,
                48.198201373069
              ],
              [
                -1.079605768256,
                48.183480590178
              ],
              [
                -1.0738876778536,
                48.159661823604
              ],
              [
                -1.0602880762288,
                48.15011075649
              ],
              [
                -1.0591416106668,
                48.125077547289
              ],
              [
                -1.0527563302629,
                48.10735152844
              ],
              [
                -1.0496111794277,
                48.089801151393
              ],
              [
                -1.0406036968359,
                48.078179883231
              ],
              [
                -1.0232901182126,
                48.068911872714
              ],
              [
                -1.033830641773,
                48.05209341916
              ],
              [
                -1.0277969380856,
                48.044863699713
              ],
              [
                -1.0330632921231,
                48.031188955766
              ],
              [
                -1.0182078693094,
                48.012308274749
              ],
              [
                -1.0168893967587,
                48.003728266054
              ],
              [
                -1.021259621245,
                47.994939309553
              ],
              [
                -1.045096271371,
                47.987097320485
              ],
              [
                -1.0709659537463,
                47.981801155317
              ],
              [
                -1.0908100123801,
                47.98774338815
              ],
              [
                -1.1026780017247,
                47.989064346633
              ],
              [
                -1.1228021186321,
                47.986671335599
              ],
              [
                -1.1260752876073,
                47.973307719181
              ],
              [
                -1.1343829543823,
                47.969309177864
              ],
              [
                -1.1539896711831,
                47.96581664419
              ],
              [
                -1.1613811233462,
                47.952310307885
              ],
              [
                -1.1595152529454,
                47.939219922042
              ],
              [
                -1.1671197731246,
                47.93471641408
              ],
              [
                -1.1663141666181,
                47.923578184002
              ],
              [
                -1.1754814134505,
                47.910385737305
              ],
              [
                -1.1762242926663,
                47.897399695909
              ],
              [
                -1.1966306275523,
                47.889267731968
              ],
              [
                -1.1969660077971,
                47.8789391525
              ],
              [
                -1.1891865993477,
                47.867976952617
              ],
              [
                -1.2030225271774,
                47.856844148885
              ],
              [
                -1.2164918382318,
                47.857201283697
              ],
              [
                -1.222737283795,
                47.852599843541
              ],
              [
                -1.2139544800221,
                47.844315066059
              ],
              [
                -1.2206362424472,
                47.820389620636
              ],
              [
                -1.2327236903989,
                47.820244561296
              ],
              [
                -1.238247803597,
                47.809992506553
              ],
              [
                -1.2458850128779,
                47.776717450948
              ],
              [
                -1.3184870728098,
                47.792334376846
              ],
              [
                -1.3528652871869,
                47.797688995885
              ],
              [
                -1.3631664145966,
                47.801683607753
              ],
              [
                -1.3772932944092,
                47.812713141041
              ],
              [
                -1.3813446142742,
                47.822668998285
              ],
              [
                -1.3904289319245,
                47.828276258082
              ],
              [
                -1.4178500987788,
                47.827486856246
              ],
              [
                -1.4249168477477,
                47.832841359769
              ],
              [
                -1.435426799044,
                47.83115216305
              ],
              [
                -1.4628972269562,
                47.833557723029
              ],
              [
                -1.4818132504128,
                47.831893536154
              ],
              [
                -1.4669179596494,
                47.809780189045
              ],
              [
                -1.4684440000216,
                47.8059033883
              ],
              [
                -1.4928971911208,
                47.798439989341
              ],
              [
                -1.5042386939766,
                47.800947509052
              ],
              [
                -1.5202764448571,
                47.793620421696
              ],
              [
                -1.5280610367888,
                47.785843354761
              ],
              [
                -1.5519088415196,
                47.784014922086
              ],
              [
                -1.5934046637562,
                47.776049297939
              ],
              [
                -1.5981151893439,
                47.766615164584
              ],
              [
                -1.6163542814852,
                47.764155016463
              ],
              [
                -1.6260806089052,
                47.756571625148
              ],
              [
                -1.6355680463584,
                47.74265760999
              ],
              [
                -1.6390681088186,
                47.731229794449
              ],
              [
                -1.6381811285931,
                47.72231109575
              ],
              [
                -1.6454761757831,
                47.721464189516
              ],
              [
                -1.6548173400684,
                47.712589314425
              ],
              [
                -1.6641158381935,
                47.711144721547
              ],
              [
                -1.6863422821157,
                47.713034371357
              ],
              [
                -1.7051414585604,
                47.709321517725
              ],
              [
                -1.7133831145586,
                47.699308113335
              ],
              [
                -1.7292404629403,
                47.699070332406
              ],
              [
                -1.7354918450583,
                47.704030670166
              ],
              [
                -1.754669062014,
                47.70617243813
              ],
              [
                -1.7724177137179,
                47.698454240157
              ],
              [
                -1.8028599988222,
                47.702303252055
              ],
              [
                -1.8250533703276,
                47.708269117018
              ],
              [
                -1.8415465142207,
                47.705493872656
              ],
              [
                -1.8640156728505,
                47.706980966313
              ],
              [
                -1.880073341802,
                47.695308629028
              ],
              [
                -1.8918360415668,
                47.696328031406
              ],
              [
                -1.9363036351526,
                47.686649217665
              ],
              [
                -1.9538162196423,
                47.672268062701
              ],
              [
                -1.9691960862159,
                47.677526213073
              ],
              [
                -1.9690592974,
                47.688368806165
              ],
              [
                -1.9743811536912,
                47.693936715593
              ],
              [
                -1.9852234827543,
                47.683232805983
              ],
              [
                -2.0093555248025,
                47.671376463587
              ],
              [
                -2.013316284318,
                47.666003385464
              ],
              [
                -2.0357277498302,
                47.668540889438
              ],
              [
                -2.0430946586878,
                47.665696588636
              ],
              [
                -2.050624831954,
                47.651134018014
              ],
              [
                -2.0579097595869,
                47.649483633439
              ],
              [
                -2.0747327428802,
                47.651662965349
              ],
              [
                -2.0898837230131,
                47.642709543195
              ],
              [
                -2.0970339249479,
                47.631356309182
              ],
              [
                -2.0947855127512,
                47.645134778838
              ],
              [
                -2.1116920444994,
                47.64565993511
              ],
              [
                -2.1143461676916,
                47.649477188598
              ],
              [
                -2.1031817950263,
                47.669812920473
              ],
              [
                -2.104366334765,
                47.67679465737
              ],
              [
                -2.1226672706492,
                47.682670445653
              ],
              [
                -2.1241280958969,
                47.689731038422
              ],
              [
                -2.1193465009818,
                47.710633585881
              ],
              [
                -2.1314707538757,
                47.722240320619
              ],
              [
                -2.1086460549724,
                47.731041275814
              ],
              [
                -2.1086098878283,
                47.736177595411
              ],
              [
                -2.0897138561506,
                47.738564040617
              ],
              [
                -2.0587066183582,
                47.738252293297
              ],
              [
                -2.0607591270552,
                47.74930009798
              ],
              [
                -2.079533401352,
                47.750210413121
              ],
              [
                -2.0847183154253,
                47.762715505001
              ],
              [
                -2.1281904903395,
                47.761376495367
              ],
              [
                -2.1156184419733,
                47.770321364228
              ],
              [
                -2.1088453868798,
                47.778777053192
              ],
              [
                -2.0941380218382,
                47.779900928589
              ],
              [
                -2.0722637386177,
                47.790436610757
              ],
              [
                -2.0675461006082,
                47.8132690401
              ],
              [
                -2.0495290789647,
                47.816920875592
              ],
              [
                -2.0357552590146,
                47.832300691003
              ],
              [
                -2.0479926743089,
                47.83629928796
              ],
              [
                -2.0530803268714,
                47.849236872553
              ],
              [
                -2.0509255834937,
                47.857131573978
              ],
              [
                -2.0559308187502,
                47.868247240806
              ],
              [
                -2.0742831600581,
                47.851162116274
              ],
              [
                -2.0901567411003,
                47.843759080606
              ],
              [
                -2.1087497149234,
                47.848456505838
              ],
              [
                -2.0973437025111,
                47.864834365215
              ],
              [
                -2.116305330268,
                47.880956896621
              ],
              [
                -2.1120117364518,
                47.8918547475
              ],
              [
                -2.1049849771455,
                47.898412563328
              ],
              [
                -2.0820218168079,
                47.908921655539
              ],
              [
                -2.0790902252501,
                47.919526032935
              ],
              [
                -2.0998204082893,
                47.937385608143
              ],
              [
                -2.1046668060842,
                47.951972939517
              ],
              [
                -2.1197720456499,
                47.960945697028
              ],
              [
                -2.1368695680738,
                47.979118421446
              ],
              [
                -2.1534958198305,
                47.983345608933
              ],
              [
                -2.1741360719446,
                47.979763196453
              ],
              [
                -2.1843552422385,
                47.982080329852
              ],
              [
                -2.1934375448781,
                47.990558846207
              ],
              [
                -2.2039507169895,
                47.993962577836
              ],
              [
                -2.2154773943836,
                47.993019365044
              ],
              [
                -2.2395885283037,
                47.99946545839
              ],
              [
                -2.2817928808654,
                47.990960728676
              ],
              [
                -2.289084836122,
                48.001031912939
              ],
              [
                -2.2755284368087,
                48.009854335432
              ],
              [
                -2.2808005622425,
                48.015636920079
              ],
              [
                -2.2795921573725,
                48.026359055809
              ],
              [
                -2.2686124960674,
                48.035169047477
              ],
              [
                -2.2418773650688,
                48.052836855884
              ],
              [
                -2.221628555571,
                48.050885101825
              ],
              [
                -2.1937224368739,
                48.05137685593
              ],
              [
                -2.1732539089072,
                48.062569615512
              ],
              [
                -2.1684835856701,
                48.076205340153
              ],
              [
                -2.1809330551359,
                48.083170741696
              ],
              [
                -2.1873992697308,
                48.080984858316
              ],
              [
                -2.220490188337,
                48.079702639699
              ],
              [
                -2.2332416975112,
                48.075625301872
              ],
              [
                -2.2343109537123,
                48.063175476394
              ],
              [
                -2.2523228001164,
                48.063237453261
              ],
              [
                -2.2579421965793,
                48.072970095118
              ],
              [
                -2.2524855674325,
                48.080525225239
              ],
              [
                -2.2555468160419,
                48.109432687524
              ],
              [
                -2.2841739522363,
                48.132920802061
              ],
              [
                -2.2872742268268,
                48.133743948459
              ],
              [
                -2.2837366746174,
                48.144782578012
              ],
              [
                -2.2711081340086,
                48.150412972357
              ],
              [
                -2.2579503686718,
                48.150028711573
              ],
              [
                -2.2525330938788,
                48.144250696486
              ],
              [
                -2.2358933261238,
                48.144810125141
              ],
              [
                -2.2354077873267,
                48.149287524255
              ],
              [
                -2.2482315410287,
                48.152795906922
              ],
              [
                -2.248403953552,
                48.159026949652
              ],
              [
                -2.2285031385077,
                48.165094160943
              ],
              [
                -2.224740280576,
                48.170599593318
              ],
              [
                -2.2204704380944,
                48.201704162968
              ],
              [
                -2.2280775916469,
                48.210549869785
              ],
              [
                -2.2172535424236,
                48.213154504986
              ],
              [
                -2.2028106696694,
                48.208197035535
              ],
              [
                -2.1902347220064,
                48.208457068018
              ],
              [
                -2.1844290106289,
                48.214579906463
              ],
              [
                -2.1894258950763,
                48.227987083108
              ],
              [
                -2.1869669777642,
                48.24638192508
              ],
              [
                -2.1664849227144,
                48.254229395416
              ],
              [
                -2.1501322546014,
                48.25839360564
              ],
              [
                -2.1268494196463,
                48.259766532987
              ],
              [
                -2.1101233130599,
                48.252906899023
              ],
              [
                -2.0955430005434,
                48.266588123333
              ],
              [
                -2.107449085419,
                48.267651698764
              ],
              [
                -2.1104651300822,
                48.27505708951
              ],
              [
                -2.0871418879077,
                48.275252371878
              ],
              [
                -2.0762648328249,
                48.283708501337
              ],
              [
                -2.0775568823449,
                48.289903770528
              ],
              [
                -2.0532895985844,
                48.29832528273
              ],
              [
                -2.0420704689088,
                48.288087509142
              ],
              [
                -2.0140966645446,
                48.279533876954
              ],
              [
                -2.0035236920698,
                48.282334281806
              ],
              [
                -2.0051168549311,
                48.291815081212
              ],
              [
                -1.9986323527079,
                48.296453899109
              ],
              [
                -1.9781518094212,
                48.301587065464
              ],
              [
                -1.964626738859,
                48.290393925442
              ],
              [
                -1.9580493030671,
                48.293119482067
              ],
              [
                -1.962374670748,
                48.301567176544
              ],
              [
                -1.9548324683895,
                48.317107151464
              ],
              [
                -1.9668942885573,
                48.342815503446
              ],
              [
                -1.947726745655,
                48.342145767609
              ],
              [
                -1.9306142175588,
                48.350129614504
              ],
              [
                -1.9325891625895,
                48.356417844204
              ],
              [
                -1.9429726923482,
                48.360974979856
              ],
              [
                -1.9475264159554,
                48.36840917384
              ],
              [
                -1.9459073144679,
                48.377079358432
              ],
              [
                -1.9384706143467,
                48.391387318738
              ],
              [
                -1.9299746133222,
                48.394787269034
              ],
              [
                -1.9413160061344,
                48.40170624218
              ],
              [
                -1.9367865533207,
                48.420066760934
              ],
              [
                -1.9519128567945,
                48.429178882978
              ],
              [
                -1.9486321533248,
                48.446457052298
              ],
              [
                -1.9388950796074,
                48.447010086104
              ],
              [
                -1.9271965635311,
                48.454811633545
              ],
              [
                -1.92389559645,
                48.460904149144
              ],
              [
                -1.9300385128498,
                48.471049819847
              ],
              [
                -1.9268777278221,
                48.476866978678
              ],
              [
                -1.9089921410274,
                48.481553778436
              ],
              [
                -1.9210223490043,
                48.494067191737
              ],
              [
                -1.9229159215127,
                48.502821809282
              ],
              [
                -1.9129126542219,
                48.506261238999
              ],
              [
                -1.9124876200229,
                48.518844225268
              ],
              [
                -1.9248689155491,
                48.542337018042
              ],
              [
                -1.9318608413625,
                48.550784221308
              ],
              [
                -1.9480341971128,
                48.538808017557
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "35",
        "nom": "Ille-et-Vilaine",
        "population": 1088855,
        "delitspour1000": "4,216",
        "nombremosque": 12,
        "parentsEtrangers": "78,3",
        "benefRsa": 37336,
        "eoliennes": 78
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              5.5185372800929,
              47.304183948833
            ],
            [
              5.5232748679603,
              47.305475313171
            ],
            [
              5.5325465734396,
              47.290563051661
            ],
            [
              5.5341715527281,
              47.283450701838
            ],
            [
              5.5547357528367,
              47.27999843245
            ],
            [
              5.572094259787,
              47.268439485834
            ],
            [
              5.5744646217084,
              47.254058031035
            ],
            [
              5.5815498545659,
              47.253139353829
            ],
            [
              5.5911669138029,
              47.259080466452
            ],
            [
              5.6021061150272,
              47.260527974194
            ],
            [
              5.6231633057099,
              47.255208344134
            ],
            [
              5.6423010293029,
              47.256186141259
            ],
            [
              5.6526839162171,
              47.264756696834
            ],
            [
              5.6656763893789,
              47.266318822653
            ],
            [
              5.6759872373979,
              47.275744771312
            ],
            [
              5.6987272452696,
              47.265000343118
            ],
            [
              5.7196574167089,
              47.240393529584
            ],
            [
              5.7171076564753,
              47.23299564261
            ],
            [
              5.7204115628525,
              47.219933082046
            ],
            [
              5.7378824016408,
              47.208883832309
            ],
            [
              5.7398278721209,
              47.201038563789
            ],
            [
              5.7543562182361,
              47.20614572363
            ],
            [
              5.7607076259684,
              47.20217591141
            ],
            [
              5.7615854800525,
              47.193348494233
            ],
            [
              5.7807437061122,
              47.192980985477
            ],
            [
              5.7863565246694,
              47.188084219493
            ],
            [
              5.7860744752117,
              47.177558359026
            ],
            [
              5.811472582346,
              47.169050988739
            ],
            [
              5.8137909740309,
              47.149846839884
            ],
            [
              5.827306328055,
              47.14254911162
            ],
            [
              5.8160829426973,
              47.134334882294
            ],
            [
              5.8054098436194,
              47.119258761879
            ],
            [
              5.8021492858263,
              47.11066783822
            ],
            [
              5.7832195551564,
              47.09624366995
            ],
            [
              5.7722519645683,
              47.09486136967
            ],
            [
              5.7685434263991,
              47.089434266457
            ],
            [
              5.7786114797683,
              47.074770571137
            ],
            [
              5.7799376281429,
              47.063610139342
            ],
            [
              5.7847160202443,
              47.054913241204
            ],
            [
              5.763387093616,
              47.044835754153
            ],
            [
              5.7456009113306,
              47.057249694456
            ],
            [
              5.7369607236255,
              47.049807327539
            ],
            [
              5.7527262522254,
              47.039795055844
            ],
            [
              5.7501649678587,
              47.033703118269
            ],
            [
              5.740849651432,
              47.031142758696
            ],
            [
              5.7495148138703,
              47.017244251927
            ],
            [
              5.7631634923394,
              47.0113904386
            ],
            [
              5.7702296182778,
              47.017932649609
            ],
            [
              5.7876490641526,
              47.020606883184
            ],
            [
              5.7986375638838,
              47.029686865519
            ],
            [
              5.7971469497964,
              47.042338209885
            ],
            [
              5.8158126764714,
              47.045781800815
            ],
            [
              5.8150520653853,
              47.020342660386
            ],
            [
              5.8286441496403,
              47.012164289689
            ],
            [
              5.851748315587,
              47.006597305776
            ],
            [
              5.860805619626,
              47.007715477722
            ],
            [
              5.8817587510691,
              47.005044433844
            ],
            [
              5.8866227813731,
              46.996969338727
            ],
            [
              5.9110757735042,
              46.999497387461
            ],
            [
              5.9150032244474,
              46.987308302623
            ],
            [
              5.9435872836268,
              46.98054854349
            ],
            [
              5.968563083074,
              46.980447122058
            ],
            [
              5.975687606681,
              46.975854084501
            ],
            [
              5.9729390451722,
              46.969276607259
            ],
            [
              5.9797832488435,
              46.963475945672
            ],
            [
              5.9664518538116,
              46.954037738921
            ],
            [
              5.9640762528681,
              46.948078452327
            ],
            [
              5.9791301258507,
              46.939766948061
            ],
            [
              5.9967921639228,
              46.933125372159
            ],
            [
              5.9826064111012,
              46.919753175451
            ],
            [
              5.9856936195365,
              46.906145471864
            ],
            [
              6.0000629144227,
              46.896186112826
            ],
            [
              6.0084286313536,
              46.895557865571
            ],
            [
              6.0117200011004,
              46.888923174244
            ],
            [
              6.0057824486729,
              46.873054533872
            ],
            [
              6.0206735851666,
              46.868100918927
            ],
            [
              6.0327205442291,
              46.870907067247
            ],
            [
              6.0242086777367,
              46.855468333834
            ],
            [
              6.0340130724844,
              46.849595790775
            ],
            [
              6.047894270997,
              46.848624239765
            ],
            [
              6.0680701595708,
              46.858460884974
            ],
            [
              6.083730222322,
              46.851419391489
            ],
            [
              6.0910071683215,
              46.841859266961
            ],
            [
              6.1056795866935,
              46.845204396114
            ],
            [
              6.1171190503892,
              46.834244144073
            ],
            [
              6.1341958499901,
              46.826068437822
            ],
            [
              6.1517911997737,
              46.820664742672
            ],
            [
              6.1492882107225,
              46.815883590897
            ],
            [
              6.1632378106454,
              46.8035284185
            ],
            [
              6.1757183241917,
              46.800243889843
            ],
            [
              6.2033299339615,
              46.772681384866
            ],
            [
              6.2026525525133,
              46.762301114569
            ],
            [
              6.185266031767,
              46.755141237425
            ],
            [
              6.1802695893085,
              46.741573475312
            ],
            [
              6.1399453541345,
              46.725268446673
            ],
            [
              6.1129539816939,
              46.707747239466
            ],
            [
              6.0698260174554,
              46.68952765456
            ],
            [
              6.0868868292495,
              46.672260499977
            ],
            [
              6.1037735049255,
              46.649817333346
            ],
            [
              6.1000606874326,
              46.643454999445
            ],
            [
              6.0868627501584,
              46.638891197306
            ],
            [
              6.059175829941,
              46.626107264362
            ],
            [
              6.0583329740831,
              46.617540436267
            ],
            [
              6.048568006745,
              46.607719222344
            ],
            [
              6.0684058243104,
              46.595948015094
            ],
            [
              6.0999852394696,
              46.573743234661
            ],
            [
              6.1304724861572,
              46.553996454277
            ],
            [
              6.138105886557,
              46.557659570871
            ],
            [
              6.1564422357855,
              46.545471592105
            ],
            [
              6.1528479944668,
              46.536134657174
            ],
            [
              6.1446024516564,
              46.528393629454
            ],
            [
              6.1378590985632,
              46.530871419262
            ],
            [
              6.1126938080715,
              46.509641497594
            ],
            [
              6.0968277794178,
              46.481208813072
            ],
            [
              6.0739298928675,
              46.463949535302
            ],
            [
              6.0744598322778,
              46.453664090185
            ],
            [
              6.0843910990454,
              46.447437001059
            ],
            [
              6.0858348949625,
              46.440994445768
            ],
            [
              6.0747681070246,
              46.431630995903
            ],
            [
              6.064005627331,
              46.416222559892
            ],
            [
              6.0551820813125,
              46.414801045991
            ],
            [
              6.0480752195777,
              46.405125490757
            ],
            [
              6.0295137846375,
              46.38681604791
            ],
            [
              6.0106565375389,
              46.374508650286
            ],
            [
              5.9868463201736,
              46.364869353266
            ],
            [
              5.9729173069373,
              46.346856233903
            ],
            [
              5.9535602724875,
              46.326531457174
            ],
            [
              5.9414094008927,
              46.309446510031
            ],
            [
              5.9259040835744,
              46.313765646046
            ],
            [
              5.9185184907402,
              46.307199659115
            ],
            [
              5.9089357776791,
              46.283950786541
            ],
            [
              5.8946251190123,
              46.286607913138
            ],
            [
              5.8791206347196,
              46.269936490689
            ],
            [
              5.8702488945181,
              46.265305590806
            ],
            [
              5.8643332109856,
              46.271208875005
            ],
            [
              5.8498475590074,
              46.262067170418
            ],
            [
              5.8212837267024,
              46.262094109034
            ],
            [
              5.7656471814788,
              46.268294754652
            ],
            [
              5.7459179055539,
              46.266368533117
            ],
            [
              5.7251818400017,
              46.260731935709
            ],
            [
              5.720112401844,
              46.265870062788
            ],
            [
              5.7160491956243,
              46.279915646538
            ],
            [
              5.7192939761881,
              46.293462363342
            ],
            [
              5.714721806193,
              46.308772354673
            ],
            [
              5.6845768021754,
              46.310927537001
            ],
            [
              5.6833466019044,
              46.316276892818
            ],
            [
              5.6684418272398,
              46.324369189279
            ],
            [
              5.6529045876259,
              46.323214774673
            ],
            [
              5.6493445080392,
              46.339494512081
            ],
            [
              5.6419584041921,
              46.342028332374
            ],
            [
              5.6305151249665,
              46.330204693001
            ],
            [
              5.6176441912887,
              46.329091983869
            ],
            [
              5.610460815624,
              46.324211378495
            ],
            [
              5.59838931795,
              46.298445925032
            ],
            [
              5.5855011829326,
              46.292557783319
            ],
            [
              5.5663641424008,
              46.294050213862
            ],
            [
              5.5581274576637,
              46.282173642648
            ],
            [
              5.5420340189851,
              46.270203795698
            ],
            [
              5.5129432911698,
              46.264539612518
            ],
            [
              5.4995891133957,
              46.268200455579
            ],
            [
              5.4730515403718,
              46.265066548043
            ],
            [
              5.4568426743662,
              46.274473574235
            ],
            [
              5.4595129521644,
              46.290471994825
            ],
            [
              5.4674037740958,
              46.295566233017
            ],
            [
              5.4753005499335,
              46.315382606424
            ],
            [
              5.4669175767252,
              46.323267248222
            ],
            [
              5.4377949533958,
              46.315109371064
            ],
            [
              5.4278984240109,
              46.342210714821
            ],
            [
              5.4170861726833,
              46.339534237431
            ],
            [
              5.4102335548466,
              46.30910668308
            ],
            [
              5.4046508439018,
              46.310338173994
            ],
            [
              5.4042931339536,
              46.332813029221
            ],
            [
              5.3992759574473,
              46.339521371463
            ],
            [
              5.3819165667023,
              46.345255630996
            ],
            [
              5.3734648101425,
              46.352234450451
            ],
            [
              5.3770544086974,
              46.364113395825
            ],
            [
              5.362996992744,
              46.370926983475
            ],
            [
              5.3770431289975,
              46.381355482485
            ],
            [
              5.3552221284773,
              46.39375452396
            ],
            [
              5.3525084400185,
              46.397586033829
            ],
            [
              5.331272401231,
              46.399499315198
            ],
            [
              5.3147703161373,
              46.40947618293
            ],
            [
              5.3076872001929,
              46.416819197974
            ],
            [
              5.308978572395,
              46.424532251303
            ],
            [
              5.3194882695109,
              46.430803499916
            ],
            [
              5.3105633704742,
              46.446769968334
            ],
            [
              5.3231451264737,
              46.456478982676
            ],
            [
              5.3233470518352,
              46.462506037791
            ],
            [
              5.3733086058294,
              46.460102440011
            ],
            [
              5.388506199686,
              46.468564619365
            ],
            [
              5.3975202395847,
              46.466656047847
            ],
            [
              5.4157407818816,
              46.473142287102
            ],
            [
              5.4209987260656,
              46.485402729497
            ],
            [
              5.4195457769452,
              46.502301459279
            ],
            [
              5.405972822918,
              46.501480763488
            ],
            [
              5.3946761409067,
              46.505960131498
            ],
            [
              5.3848165687083,
              46.51562164374
            ],
            [
              5.3688458099145,
              46.516135590158
            ],
            [
              5.359197282296,
              46.522558128915
            ],
            [
              5.362532641349,
              46.551955983714
            ],
            [
              5.3602943181469,
              46.562706895384
            ],
            [
              5.3676455411539,
              46.56753131305
            ],
            [
              5.3621183709734,
              46.577266498184
            ],
            [
              5.3752066392179,
              46.580159450218
            ],
            [
              5.3976477370739,
              46.579829483685
            ],
            [
              5.4134417713639,
              46.60042718918
            ],
            [
              5.3985819483749,
              46.612532369876
            ],
            [
              5.4171049356193,
              46.615454549879
            ],
            [
              5.4375286482801,
              46.629847215753
            ],
            [
              5.4411065155678,
              46.637538242564
            ],
            [
              5.4154976516957,
              46.654978931388
            ],
            [
              5.4266257666834,
              46.659810286536
            ],
            [
              5.4220848047125,
              46.673033412338
            ],
            [
              5.3953606617938,
              46.685052412753
            ],
            [
              5.4077513434675,
              46.695884065976
            ],
            [
              5.4063157263802,
              46.704025277789
            ],
            [
              5.394582119336,
              46.710598146417
            ],
            [
              5.3965438211421,
              46.716352209475
            ],
            [
              5.3910888917579,
              46.728698245923
            ],
            [
              5.3612646423948,
              46.731562182096
            ],
            [
              5.3663650761211,
              46.751279477853
            ],
            [
              5.385508776198,
              46.762069728324
            ],
            [
              5.3902478807842,
              46.770586580904
            ],
            [
              5.3804825411024,
              46.776338304427
            ],
            [
              5.3693431489209,
              46.778660208918
            ],
            [
              5.3566637043011,
              46.789799874683
            ],
            [
              5.3401395358873,
              46.790820312845
            ],
            [
              5.33292755524,
              46.796095104705
            ],
            [
              5.3285848429923,
              46.812954827447
            ],
            [
              5.3403852843483,
              46.82115377823
            ],
            [
              5.3515685001072,
              46.813567110147
            ],
            [
              5.3702436483657,
              46.826173086426
            ],
            [
              5.3888320313816,
              46.826669468844
            ],
            [
              5.4098269851133,
              46.832545203525
            ],
            [
              5.4169768776707,
              46.826815586408
            ],
            [
              5.4351093990058,
              46.826754678964
            ],
            [
              5.4567694196974,
              46.83096747796
            ],
            [
              5.4622983197648,
              46.838687292645
            ],
            [
              5.4592877768562,
              46.855245657553
            ],
            [
              5.4467832086153,
              46.860312646063
            ],
            [
              5.4376109899408,
              46.85601497291
            ],
            [
              5.4146973689037,
              46.862363178473
            ],
            [
              5.4018235507818,
              46.868321978157
            ],
            [
              5.4042545538339,
              46.877575497225
            ],
            [
              5.3998079308442,
              46.881340792386
            ],
            [
              5.4038429343802,
              46.8895159374
            ],
            [
              5.3907640809284,
              46.894366911749
            ],
            [
              5.3633736848055,
              46.88325738824
            ],
            [
              5.3519717132024,
              46.892332830917
            ],
            [
              5.3392796011353,
              46.889650370665
            ],
            [
              5.328668893399,
              46.89427196578
            ],
            [
              5.3169631943363,
              46.904052477048
            ],
            [
              5.3070069943341,
              46.923576065792
            ],
            [
              5.3084905287746,
              46.937356553152
            ],
            [
              5.2892527667604,
              46.941274764889
            ],
            [
              5.2747709784723,
              46.934849244786
            ],
            [
              5.2632632275105,
              46.935908986811
            ],
            [
              5.2601642133516,
              46.945577835787
            ],
            [
              5.2642267089187,
              46.959043180772
            ],
            [
              5.2548827302617,
              46.971636367009
            ],
            [
              5.2552323566327,
              46.97988727181
            ],
            [
              5.2719597499439,
              46.989340780889
            ],
            [
              5.2788707280445,
              46.999021373722
            ],
            [
              5.297162784202,
              47.001432869677
            ],
            [
              5.3100551650812,
              47.018024391224
            ],
            [
              5.2753306362771,
              47.026929459444
            ],
            [
              5.2829162271068,
              47.045235150189
            ],
            [
              5.3024269639937,
              47.061073135449
            ],
            [
              5.3121920596159,
              47.059984591599
            ],
            [
              5.3221915911089,
              47.072984795346
            ],
            [
              5.3325351466998,
              47.076541416218
            ],
            [
              5.3451278583998,
              47.076663031205
            ],
            [
              5.3594637559913,
              47.080823441413
            ],
            [
              5.3725869511884,
              47.077870600995
            ],
            [
              5.3860185078759,
              47.081977874128
            ],
            [
              5.3978169308403,
              47.100525534369
            ],
            [
              5.3982339482394,
              47.105510493744
            ],
            [
              5.4106582397347,
              47.11409272206
            ],
            [
              5.4087066418684,
              47.126073355091
            ],
            [
              5.4153451965153,
              47.132557909226
            ],
            [
              5.4283101123348,
              47.136621644761
            ],
            [
              5.4399772978447,
              47.144398007823
            ],
            [
              5.4382559628791,
              47.152333633289
            ],
            [
              5.4486451687776,
              47.159661416687
            ],
            [
              5.4592047704778,
              47.183435455006
            ],
            [
              5.4566619914314,
              47.190796043521
            ],
            [
              5.4457479059859,
              47.199576602968
            ],
            [
              5.4643347179423,
              47.206861190005
            ],
            [
              5.4766297124338,
              47.216024943562
            ],
            [
              5.4740109657345,
              47.231084915051
            ],
            [
              5.4825740827176,
              47.239584618039
            ],
            [
              5.4859533367952,
              47.250970971888
            ],
            [
              5.482780235717,
              47.259970325137
            ],
            [
              5.4878159593397,
              47.273607055705
            ],
            [
              5.4882803585045,
              47.289348426854
            ],
            [
              5.5047334725938,
              47.285657287521
            ],
            [
              5.5185372800929,
              47.304183948833
            ]
          ]
        ]
      },
      "properties": {
        "code": "39",
        "nom": "Jura",
        "population": 258798,
        "delitspour1000": "3,845",
        "nombremosque": 16,
        "parentsEtrangers": "81,9",
        "benefRsa": 6672,
        "eoliennes": 6
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1.253891222702,
              44.4676023661
            ],
            [
              -1.1924838562811,
              44.481212260344
            ],
            [
              -1.1064937386386,
              44.502776393766
            ],
            [
              -1.0851653037793,
              44.532195517275
            ],
            [
              -1.0266322776735,
              44.507125804732
            ],
            [
              -1.0110724710714,
              44.51028357283
            ],
            [
              -0.99115222944795,
              44.511852855415
            ],
            [
              -0.98773451899223,
              44.50830163679
            ],
            [
              -0.98069956650209,
              44.482879743899
            ],
            [
              -0.99245903926265,
              44.461505914268
            ],
            [
              -1.0074549340123,
              44.437655941822
            ],
            [
              -1.0291608887845,
              44.422698057254
            ],
            [
              -0.96958186494299,
              44.429381693784
            ],
            [
              -0.91916781947707,
              44.44334265377
            ],
            [
              -0.91201620353572,
              44.436936326703
            ],
            [
              -0.84527368904245,
              44.418975448762
            ],
            [
              -0.81732088301594,
              44.420355607201
            ],
            [
              -0.79305700578868,
              44.42869424449
            ],
            [
              -0.7802786587589,
              44.428106299184
            ],
            [
              -0.77242389124687,
              44.440065390557
            ],
            [
              -0.73069769860506,
              44.446371235394
            ],
            [
              -0.7297300625887,
              44.44819829522
            ],
            [
              -0.69742786925964,
              44.442347313001
            ],
            [
              -0.68193304268744,
              44.44410640147
            ],
            [
              -0.67602939990072,
              44.457335453756
            ],
            [
              -0.64059457790927,
              44.449412230615
            ],
            [
              -0.62877226637636,
              44.443190607827
            ],
            [
              -0.62546864287445,
              44.412430951205
            ],
            [
              -0.62764253659841,
              44.398144928692
            ],
            [
              -0.57096912038279,
              44.382529698119
            ],
            [
              -0.56093019811086,
              44.374948937011
            ],
            [
              -0.52810065622233,
              44.36465502296
            ],
            [
              -0.51763677862963,
              44.33911198566
            ],
            [
              -0.47887406741702,
              44.324890920135
            ],
            [
              -0.43184427971363,
              44.322572401547
            ],
            [
              -0.43055425810778,
              44.303197247509
            ],
            [
              -0.40138354559817,
              44.28661085634
            ],
            [
              -0.38338244369272,
              44.28631351989
            ],
            [
              -0.39624532160833,
              44.237695069388
            ],
            [
              -0.38961480789413,
              44.209483805167
            ],
            [
              -0.36355937684689,
              44.206118084951
            ],
            [
              -0.31133583014507,
              44.203267204497
            ],
            [
              -0.27211613275808,
              44.193811119459
            ],
            [
              -0.22344689401412,
              44.205894666457
            ],
            [
              -0.22831083561736,
              44.256035609777
            ],
            [
              -0.22600423316361,
              44.264780559078
            ],
            [
              -0.21034767075068,
              44.264363706221
            ],
            [
              -0.19411725898218,
              44.269043048465
            ],
            [
              -0.17860760723789,
              44.2611943855
            ],
            [
              -0.16643741766591,
              44.241645590082
            ],
            [
              -0.14068987994571,
              44.226407410087
            ],
            [
              -0.12947117877613,
              44.203865483968
            ],
            [
              -0.13125049106927,
              44.189332630715
            ],
            [
              -0.12915186861195,
              44.152407302012
            ],
            [
              -0.11369288849198,
              44.152333171325
            ],
            [
              -0.09964106827123,
              44.154887837188
            ],
            [
              -0.083343161175917,
              44.154647573209
            ],
            [
              -0.059853659558417,
              44.150820943359
            ],
            [
              -0.0042360907976178,
              44.149994948769
            ],
            [
              0.025171514105427,
              44.138433935069
            ],
            [
              0.03459374998392,
              44.130654202294
            ],
            [
              0.13510329225982,
              44.124220572908
            ],
            [
              0.13260134887534,
              44.117279604997
            ],
            [
              0.13672631290526,
              44.109868945373
            ],
            [
              0.12751009632433,
              44.106227747222
            ],
            [
              0.10081938187221,
              44.086770408654
            ],
            [
              0.096757199990099,
              44.070218011948
            ],
            [
              0.07964864049181,
              44.045606981442
            ],
            [
              0.081976138741446,
              44.040393196883
            ],
            [
              0.071876331257881,
              44.025424244342
            ],
            [
              0.060601939796586,
              44.024928226919
            ],
            [
              0.062703136168861,
              44.000839934979
            ],
            [
              0.076043349292038,
              43.983138573142
            ],
            [
              0.067532020801164,
              43.974192750661
            ],
            [
              0.067025223808929,
              43.967876773509
            ],
            [
              0.055307282518792,
              43.957963073751
            ],
            [
              0.057457862003849,
              43.949050217484
            ],
            [
              0.068507868767624,
              43.937809939581
            ],
            [
              0.07664444687609,
              43.916470177201
            ],
            [
              0.059162622123353,
              43.897893022976
            ],
            [
              0.045526933200978,
              43.902166309486
            ],
            [
              0.032551602220287,
              43.900191508039
            ],
            [
              0.017439868557199,
              43.910847797935
            ],
            [
              -0.001843455443284,
              43.921420036587
            ],
            [
              -0.013582447289478,
              43.923649897266
            ],
            [
              -0.015361195667702,
              43.934152340481
            ],
            [
              0.0042089573126749,
              43.943424094056
            ],
            [
              0.0067349635456505,
              43.953740495039
            ],
            [
              0.0014399806732066,
              43.95988700889
            ],
            [
              -0.021769333384946,
              43.973056989329
            ],
            [
              -0.036164948567139,
              43.983735934778
            ],
            [
              -0.040802721725543,
              43.968964305724
            ],
            [
              -0.046527042815464,
              43.96103952555
            ],
            [
              -0.059361902039375,
              43.960939386464
            ],
            [
              -0.073597153405665,
              43.94504601184
            ],
            [
              -0.087028531609323,
              43.947486286421
            ],
            [
              -0.098329558338237,
              43.942427984825
            ],
            [
              -0.094989077566145,
              43.933381091475
            ],
            [
              -0.10225702569033,
              43.927416125055
            ],
            [
              -0.12594278448243,
              43.944421563926
            ],
            [
              -0.13513697119614,
              43.936297912997
            ],
            [
              -0.15333166658385,
              43.939048046681
            ],
            [
              -0.15391959185065,
              43.93232464077
            ],
            [
              -0.16562386692845,
              43.927613195924
            ],
            [
              -0.17911005329495,
              43.937915232082
            ],
            [
              -0.18485270811369,
              43.928769857675
            ],
            [
              -0.19202409075549,
              43.92603943497
            ],
            [
              -0.19978663649385,
              43.915110256293
            ],
            [
              -0.21627342564629,
              43.907357211722
            ],
            [
              -0.22763125359284,
              43.91098862727
            ],
            [
              -0.23443742350753,
              43.899009673945
            ],
            [
              -0.23393612239966,
              43.89106512629
            ],
            [
              -0.22061963081894,
              43.890378123713
            ],
            [
              -0.20122569807121,
              43.885467279461
            ],
            [
              -0.19099207269451,
              43.875181537803
            ],
            [
              -0.19855770088986,
              43.862412899782
            ],
            [
              -0.20890830935508,
              43.857673133295
            ],
            [
              -0.19596777254111,
              43.845942065138
            ],
            [
              -0.18812879425044,
              43.832561081629
            ],
            [
              -0.19725593392987,
              43.830783419213
            ],
            [
              -0.1924617341874,
              43.810177903112
            ],
            [
              -0.21750272546627,
              43.809928705523
            ],
            [
              -0.22713966573848,
              43.808271178562
            ],
            [
              -0.2188522220883,
              43.796306415322
            ],
            [
              -0.2221263729722,
              43.7870916829
            ],
            [
              -0.21332509113417,
              43.779092079667
            ],
            [
              -0.21546661332355,
              43.76954000666
            ],
            [
              -0.2079131727813,
              43.760637827854
            ],
            [
              -0.21894685864266,
              43.750867558794
            ],
            [
              -0.20605854570527,
              43.750073485568
            ],
            [
              -0.19556855881059,
              43.7457681747
            ],
            [
              -0.19413824181548,
              43.737015033058
            ],
            [
              -0.20579650925303,
              43.727838442023
            ],
            [
              -0.23258474697029,
              43.714085612502
            ],
            [
              -0.2466519035345,
              43.710665978321
            ],
            [
              -0.24762242324933,
              43.705844967214
            ],
            [
              -0.23910090516212,
              43.693946573483
            ],
            [
              -0.2558968586296,
              43.679785947966
            ],
            [
              -0.25205576868858,
              43.672170366192
            ],
            [
              -0.23973638497448,
              43.671241337499
            ],
            [
              -0.24428632111807,
              43.663120354346
            ],
            [
              -0.24316590125334,
              43.654503914277
            ],
            [
              -0.26183976696696,
              43.650333852662
            ],
            [
              -0.26375883992318,
              43.635925825532
            ],
            [
              -0.28211623210758,
              43.643073606308
            ],
            [
              -0.27959903054407,
              43.618177993348
            ],
            [
              -0.27771465346131,
              43.616200527152
            ],
            [
              -0.24720389403099,
              43.615953419221
            ],
            [
              -0.25464414089565,
              43.597079809878
            ],
            [
              -0.24817456316599,
              43.596658468765
            ],
            [
              -0.24283300101136,
              43.584979016847
            ],
            [
              -0.2541382782442,
              43.582747851696
            ],
            [
              -0.25922900106539,
              43.588885282933
            ],
            [
              -0.27639878289495,
              43.584757375032
            ],
            [
              -0.28421867458898,
              43.579397069588
            ],
            [
              -0.29076337158252,
              43.568931929625
            ],
            [
              -0.30119925309298,
              43.571304000549
            ],
            [
              -0.30369299211166,
              43.560277673965
            ],
            [
              -0.32523012233832,
              43.555876337268
            ],
            [
              -0.33596232349939,
              43.550854218394
            ],
            [
              -0.34831088646202,
              43.555511486368
            ],
            [
              -0.35315441281094,
              43.561479352225
            ],
            [
              -0.37547824639828,
              43.566100585769
            ],
            [
              -0.40603700249402,
              43.568225793838
            ],
            [
              -0.4377177064614,
              43.552811121858
            ],
            [
              -0.45203283532481,
              43.550437447846
            ],
            [
              -0.45481179766081,
              43.557808637311
            ],
            [
              -0.46343337473017,
              43.566591187727
            ],
            [
              -0.44766172416032,
              43.566800679225
            ],
            [
              -0.4473305213702,
              43.57320368242
            ],
            [
              -0.43573841309122,
              43.578719987038
            ],
            [
              -0.43177477127389,
              43.586329386204
            ],
            [
              -0.44828752670584,
              43.596092979229
            ],
            [
              -0.46643781655372,
              43.589527662306
            ],
            [
              -0.5076573284959,
              43.569230833001
            ],
            [
              -0.51059854784715,
              43.563208667442
            ],
            [
              -0.52567004034342,
              43.557198237539
            ],
            [
              -0.53325372555522,
              43.551600869729
            ],
            [
              -0.55716948921661,
              43.542797337202
            ],
            [
              -0.56707553935391,
              43.558215434064
            ],
            [
              -0.58356996740704,
              43.545809317732
            ],
            [
              -0.59633617275098,
              43.541110501269
            ],
            [
              -0.61540683919424,
              43.538593241598
            ],
            [
              -0.61569432209824,
              43.544395690015
            ],
            [
              -0.63469699256641,
              43.553174601583
            ],
            [
              -0.65350576643198,
              43.556459978666
            ],
            [
              -0.65828413899537,
              43.560563803045
            ],
            [
              -0.64318640735324,
              43.574382351643
            ],
            [
              -0.67080359304887,
              43.566718895185
            ],
            [
              -0.6880133200732,
              43.556757948034
            ],
            [
              -0.69899646958725,
              43.560965144779
            ],
            [
              -0.70754926487636,
              43.557056799782
            ],
            [
              -0.71538850529851,
              43.541111448682
            ],
            [
              -0.72886235487275,
              43.545433771178
            ],
            [
              -0.73409021010799,
              43.555745751845
            ],
            [
              -0.7500250589555,
              43.564537857863
            ],
            [
              -0.76827845568514,
              43.579485229847
            ],
            [
              -0.78244276358796,
              43.576711739301
            ],
            [
              -0.78178287297118,
              43.56416527552
            ],
            [
              -0.79416546191616,
              43.563959990597
            ],
            [
              -0.80403569022774,
              43.556297334381
            ],
            [
              -0.81602499311976,
              43.560967548653
            ],
            [
              -0.82804656881185,
              43.559963954351
            ],
            [
              -0.84267007937447,
              43.553556657096
            ],
            [
              -0.8428108491379,
              43.543414990067
            ],
            [
              -0.85563676804624,
              43.541908586996
            ],
            [
              -0.89669293487472,
              43.550486431111
            ],
            [
              -0.91145313787906,
              43.548621966575
            ],
            [
              -0.92119880363379,
              43.543435292616
            ],
            [
              -0.92658256065212,
              43.532019680462
            ],
            [
              -0.9356342065974,
              43.537880329289
            ],
            [
              -0.9462865006441,
              43.532393540517
            ],
            [
              -0.95842887322335,
              43.533480633229
            ],
            [
              -0.97582774719898,
              43.539280811782
            ],
            [
              -0.9933158275928,
              43.53975988063
            ],
            [
              -0.99913834875978,
              43.534227442337
            ],
            [
              -0.9891692817436,
              43.529704697224
            ],
            [
              -0.99338967006359,
              43.505385487649
            ],
            [
              -1.0128682325145,
              43.50521471428
            ],
            [
              -1.0234014341645,
              43.502116209812
            ],
            [
              -1.0345255535077,
              43.509488088986
            ],
            [
              -1.0524449935051,
              43.512192578437
            ],
            [
              -1.0689958539289,
              43.508450037933
            ],
            [
              -1.0726086009342,
              43.517800005846
            ],
            [
              -1.0808322166668,
              43.522864721293
            ],
            [
              -1.0887252051976,
              43.511932891362
            ],
            [
              -1.1092463381421,
              43.514126348508
            ],
            [
              -1.1195961100638,
              43.501506974386
            ],
            [
              -1.139591150382,
              43.497427824295
            ],
            [
              -1.1387523187543,
              43.490338882334
            ],
            [
              -1.1475771656484,
              43.487949116697
            ],
            [
              -1.1501264316056,
              43.495522100096
            ],
            [
              -1.1625734868298,
              43.491539100586
            ],
            [
              -1.1700585048391,
              43.493509086399
            ],
            [
              -1.1652255750741,
              43.508212943175
            ],
            [
              -1.1315717835651,
              43.510693826661
            ],
            [
              -1.1330248441299,
              43.520785769377
            ],
            [
              -1.1560556323662,
              43.530479015428
            ],
            [
              -1.1595115855818,
              43.538661567429
            ],
            [
              -1.1708199002333,
              43.545608985466
            ],
            [
              -1.195611423068,
              43.546096138824
            ],
            [
              -1.2173154229473,
              43.53073725789
            ],
            [
              -1.266594497459,
              43.510210224082
            ],
            [
              -1.291185807586,
              43.498202631439
            ],
            [
              -1.2990049402612,
              43.501646422756
            ],
            [
              -1.3274938217375,
              43.504919561322
            ],
            [
              -1.3679063796964,
              43.49490201152
            ],
            [
              -1.3865353810935,
              43.496860806076
            ],
            [
              -1.4177765658159,
              43.497766825248
            ],
            [
              -1.4237603104542,
              43.506861858957
            ],
            [
              -1.437498587448,
              43.513057753307
            ],
            [
              -1.464106404649,
              43.519781645046
            ],
            [
              -1.4610306381761,
              43.53267584155
            ],
            [
              -1.4766158822802,
              43.53918061625
            ],
            [
              -1.4864407317263,
              43.535917796619
            ],
            [
              -1.5014092597602,
              43.526946087265
            ],
            [
              -1.524870110434,
              43.52969939564
            ],
            [
              -1.5070304054817,
              43.554964967747
            ],
            [
              -1.4919223792491,
              43.572318908712
            ],
            [
              -1.4600147168142,
              43.620300326042
            ],
            [
              -1.4482076265277,
              43.642275007226
            ],
            [
              -1.4464791056108,
              43.656202078657
            ],
            [
              -1.4417112828647,
              43.669164802754
            ],
            [
              -1.4404279792059,
              43.690015095526
            ],
            [
              -1.4365047882215,
              43.710561485871
            ],
            [
              -1.4221209140089,
              43.763113080873
            ],
            [
              -1.4102233294571,
              43.80324757496
            ],
            [
              -1.3953961014978,
              43.849121637244
            ],
            [
              -1.3912095627914,
              43.858859549202
            ],
            [
              -1.3749902540486,
              43.913786973148
            ],
            [
              -1.3370648081025,
              44.055900444664
            ],
            [
              -1.3259993612279,
              44.089757299216
            ],
            [
              -1.3253321090174,
              44.096044723029
            ],
            [
              -1.3123263437187,
              44.144797833947
            ],
            [
              -1.3066211766807,
              44.171603926833
            ],
            [
              -1.2946846942803,
              44.234574277628
            ],
            [
              -1.2866796559521,
              44.272650495294
            ],
            [
              -1.2721032403671,
              44.350132816331
            ],
            [
              -1.2603597808298,
              44.421266516235
            ],
            [
              -1.253891222702,
              44.4676023661
            ]
          ]
        ]
      },
      "properties": {
        "code": "40",
        "nom": "Landes",
        "population": 418122,
        "delitspour1000": "4,425",
        "nombremosque": 4,
        "parentsEtrangers": "85,7",
        "benefRsa": 14959
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3.899538631706,
              46.275908099459
            ],
            [
              3.9055127902449,
              46.271596707683
            ],
            [
              3.908821904601,
              46.260346940334
            ],
            [
              3.9076581109201,
              46.242456213516
            ],
            [
              3.8966112073781,
              46.2371189424
            ],
            [
              3.8981917464837,
              46.226659321125
            ],
            [
              3.890130972164,
              46.214487049905
            ],
            [
              3.9135693350409,
              46.206918558033
            ],
            [
              3.9179891232598,
              46.202853033545
            ],
            [
              3.933514918779,
              46.206414377073
            ],
            [
              3.9725466394127,
              46.202707514639
            ],
            [
              3.9728332574833,
              46.193862543845
            ],
            [
              3.9817823975359,
              46.17634098728
            ],
            [
              3.9887880883959,
              46.169805261207
            ],
            [
              4.0308840440337,
              46.171882539683
            ],
            [
              4.051960198465,
              46.181690186415
            ],
            [
              4.0614051962048,
              46.188789802443
            ],
            [
              4.0716672927165,
              46.18807123789
            ],
            [
              4.0908839436933,
              46.192837097346
            ],
            [
              4.1040867377072,
              46.198391359055
            ],
            [
              4.1324158349304,
              46.177826358374
            ],
            [
              4.1660889760454,
              46.172928033288
            ],
            [
              4.1884438149961,
              46.175128955988
            ],
            [
              4.1854551738783,
              46.190162130834
            ],
            [
              4.207903434232,
              46.194833150686
            ],
            [
              4.2246872961743,
              46.177995338767
            ],
            [
              4.2335294499164,
              46.180026926884
            ],
            [
              4.2423584224526,
              46.188830459357
            ],
            [
              4.2525000565082,
              46.187922148658
            ],
            [
              4.2610250986037,
              46.178754364444
            ],
            [
              4.2518988744775,
              46.167097935374
            ],
            [
              4.2520302327293,
              46.157916681311
            ],
            [
              4.2824944900944,
              46.156946471815
            ],
            [
              4.2952828837852,
              46.172250410112
            ],
            [
              4.3035807058325,
              46.17426655994
            ],
            [
              4.3157416420975,
              46.172029804364
            ],
            [
              4.3270887031684,
              46.184790131282
            ],
            [
              4.3357117653558,
              46.181398292861
            ],
            [
              4.3450682105084,
              46.187441862916
            ],
            [
              4.3648481141165,
              46.183579311067
            ],
            [
              4.3706868166882,
              46.191620039558
            ],
            [
              4.3631781812533,
              46.200537276046
            ],
            [
              4.3774743279046,
              46.21020246009
            ],
            [
              4.3893979878582,
              46.213601360996
            ],
            [
              4.3880744564659,
              46.219790380732
            ],
            [
              4.4085006257224,
              46.217963185953
            ],
            [
              4.4218122058978,
              46.208599447092
            ],
            [
              4.422617863137,
              46.202554255239
            ],
            [
              4.3962361504561,
              46.195567283506
            ],
            [
              4.3959933191533,
              46.189137102955
            ],
            [
              4.406360379933,
              46.182287943556
            ],
            [
              4.4161119485317,
              46.179768496894
            ],
            [
              4.4385869916472,
              46.16787797859
            ],
            [
              4.433065036007,
              46.157315762201
            ],
            [
              4.4264818352348,
              46.152802675294
            ],
            [
              4.421942123828,
              46.142446470167
            ],
            [
              4.4107792053657,
              46.136621688891
            ],
            [
              4.3802731178293,
              46.150002449909
            ],
            [
              4.3703360612818,
              46.147436195393
            ],
            [
              4.3615871317803,
              46.138458636435
            ],
            [
              4.3426667635704,
              46.134431163894
            ],
            [
              4.3349408525378,
              46.135557948536
            ],
            [
              4.3215705432747,
              46.129223594176
            ],
            [
              4.3209316069531,
              46.121673075435
            ],
            [
              4.309496394554,
              46.110310117939
            ],
            [
              4.3109599658762,
              46.079190537296
            ],
            [
              4.2970729797104,
              46.063367890941
            ],
            [
              4.2804122607347,
              46.052946216253
            ],
            [
              4.25428598242,
              46.052728453285
            ],
            [
              4.2621612945195,
              46.035394464095
            ],
            [
              4.2779133191973,
              46.022570733364
            ],
            [
              4.2931321166524,
              46.020034909779
            ],
            [
              4.3060931313608,
              46.021227299478
            ],
            [
              4.3069346878253,
              46.012302407533
            ],
            [
              4.3125045374594,
              46.005590580996
            ],
            [
              4.3005636506591,
              45.991309437399
            ],
            [
              4.291857109907,
              45.995195243694
            ],
            [
              4.2762354842316,
              45.993440504556
            ],
            [
              4.243469905983,
              45.997155157363
            ],
            [
              4.2480467270692,
              45.986721755059
            ],
            [
              4.2534923621873,
              45.983659900377
            ],
            [
              4.2751720909979,
              45.979779420897
            ],
            [
              4.288248100938,
              45.973613541516
            ],
            [
              4.2980954426863,
              45.965658535725
            ],
            [
              4.3008529759177,
              45.957960852692
            ],
            [
              4.2939148881952,
              45.953401305103
            ],
            [
              4.3068244792521,
              45.945360003465
            ],
            [
              4.3225089205335,
              45.940414442089
            ],
            [
              4.3437483577496,
              45.931115680498
            ],
            [
              4.3380440076381,
              45.921621376175
            ],
            [
              4.3454237850592,
              45.915370885136
            ],
            [
              4.3239325275487,
              45.900497659693
            ],
            [
              4.3383805732565,
              45.886651647406
            ],
            [
              4.3421582115897,
              45.877180049661
            ],
            [
              4.3573105997855,
              45.867819950961
            ],
            [
              4.3830283320289,
              45.863990304997
            ],
            [
              4.3937657765842,
              45.865177816896
            ],
            [
              4.39318791688,
              45.85490602736
            ],
            [
              4.3833781565992,
              45.847457660114
            ],
            [
              4.3906037050232,
              45.837471017303
            ],
            [
              4.3835765208093,
              45.826844321257
            ],
            [
              4.3762210046271,
              45.824638493705
            ],
            [
              4.3884958699132,
              45.806032530629
            ],
            [
              4.3736933831237,
              45.805064418906
            ],
            [
              4.3779742385557,
              45.790373451489
            ],
            [
              4.3758660518349,
              45.78207558241
            ],
            [
              4.3591428768023,
              45.780984046301
            ],
            [
              4.3493594526122,
              45.771303606196
            ],
            [
              4.3502759881234,
              45.765572799369
            ],
            [
              4.3648245203449,
              45.749517040539
            ],
            [
              4.3912519410447,
              45.754931122336
            ],
            [
              4.402874533303,
              45.744322736901
            ],
            [
              4.3930262500122,
              45.73962196206
            ],
            [
              4.384861183026,
              45.721388505488
            ],
            [
              4.3655162451168,
              45.698520895445
            ],
            [
              4.3732834826114,
              45.691495316239
            ],
            [
              4.3666896828173,
              45.685045108392
            ],
            [
              4.3659250305716,
              45.67124520038
            ],
            [
              4.3916724723063,
              45.662900921407
            ],
            [
              4.3926780038356,
              45.653703042225
            ],
            [
              4.4102790829383,
              45.632049970336
            ],
            [
              4.4404819320115,
              45.623477104497
            ],
            [
              4.454234347968,
              45.604183406335
            ],
            [
              4.4744464613895,
              45.594709488515
            ],
            [
              4.4679838181681,
              45.586688341581
            ],
            [
              4.4838595815624,
              45.58624940097
            ],
            [
              4.5051531322954,
              45.581744190937
            ],
            [
              4.5047759790565,
              45.576543545812
            ],
            [
              4.5197664342597,
              45.573253603438
            ],
            [
              4.5428213574395,
              45.579838520111
            ],
            [
              4.5528567342845,
              45.578486287879
            ],
            [
              4.5686010114212,
              45.583468098473
            ],
            [
              4.5877499129831,
              45.570573911416
            ],
            [
              4.6028640886661,
              45.570404449666
            ],
            [
              4.614861970331,
              45.575470989539
            ],
            [
              4.6251877380181,
              45.569055361339
            ],
            [
              4.636944001114,
              45.545633161464
            ],
            [
              4.6447024494625,
              45.540521160907
            ],
            [
              4.6589149161667,
              45.542658451096
            ],
            [
              4.6478824204468,
              45.556792876108
            ],
            [
              4.6492789007153,
              45.56490904439
            ],
            [
              4.6585848075301,
              45.56377146269
            ],
            [
              4.6699440512117,
              45.570285378667
            ],
            [
              4.6831387073324,
              45.565644636168
            ],
            [
              4.6873997607785,
              45.558523925089
            ],
            [
              4.6837344760466,
              45.55253624936
            ],
            [
              4.674488312844,
              45.550479461372
            ],
            [
              4.6613903387752,
              45.536037070395
            ],
            [
              4.650497265707,
              45.528252850314
            ],
            [
              4.6494323830973,
              45.51712905461
            ],
            [
              4.6543426246071,
              45.499750539461
            ],
            [
              4.6506024356174,
              45.49710900433
            ],
            [
              4.6551392825647,
              45.486304493343
            ],
            [
              4.6617482375007,
              45.486752644296
            ],
            [
              4.6819265003418,
              45.479805150655
            ],
            [
              4.7002191965255,
              45.478249654895
            ],
            [
              4.7223405808585,
              45.494295691224
            ],
            [
              4.7394098424085,
              45.486593747758
            ],
            [
              4.7433101731851,
              45.474764896895
            ],
            [
              4.7399125598478,
              45.472150707566
            ],
            [
              4.7569316195483,
              45.455703808946
            ],
            [
              4.7555294013359,
              45.447048576794
            ],
            [
              4.7604638818845,
              45.437086232502
            ],
            [
              4.7586674371295,
              45.431094261452
            ],
            [
              4.7447605342399,
              45.421331112612
            ],
            [
              4.7441167394752,
              45.40888118257
            ],
            [
              4.7558093145859,
              45.396415196441
            ],
            [
              4.7590198368357,
              45.381472173695
            ],
            [
              4.75599963206,
              45.365674921417
            ],
            [
              4.7258207341748,
              45.361809127681
            ],
            [
              4.7235165531567,
              45.355268877755
            ],
            [
              4.7035926308706,
              45.350231070265
            ],
            [
              4.7004142506589,
              45.345743686427
            ],
            [
              4.6795095377771,
              45.346108764264
            ],
            [
              4.659973826611,
              45.338548296066
            ],
            [
              4.6385582894087,
              45.32619453663
            ],
            [
              4.6431793868041,
              45.318899176946
            ],
            [
              4.6144998827011,
              45.309832990607
            ],
            [
              4.6226711105485,
              45.291337466369
            ],
            [
              4.5985944793052,
              45.289484840554
            ],
            [
              4.5917734340486,
              45.271300484031
            ],
            [
              4.6049363248724,
              45.264031661375
            ],
            [
              4.6024189574118,
              45.251699611327
            ],
            [
              4.5842858795804,
              45.244084964738
            ],
            [
              4.5581567502396,
              45.246830803085
            ],
            [
              4.526544923769,
              45.240102794417
            ],
            [
              4.5132151489564,
              45.243118249066
            ],
            [
              4.5131557337675,
              45.234772362931
            ],
            [
              4.4879001066584,
              45.232177394436
            ],
            [
              4.4831320440493,
              45.236445615502
            ],
            [
              4.4685891000785,
              45.247320406429
            ],
            [
              4.4488366631474,
              45.257555737167
            ],
            [
              4.4366003162274,
              45.272738948048
            ],
            [
              4.4185710642575,
              45.275422301103
            ],
            [
              4.391529499389,
              45.264207721996
            ],
            [
              4.3864148938233,
              45.255609582426
            ],
            [
              4.377876082312,
              45.261966899556
            ],
            [
              4.3625476382457,
              45.264397172969
            ],
            [
              4.3497179288925,
              45.280278452779
            ],
            [
              4.3587598303903,
              45.28390361935
            ],
            [
              4.3612845231222,
              45.292316661278
            ],
            [
              4.3690682180887,
              45.300293499679
            ],
            [
              4.3512148784724,
              45.304143454091
            ],
            [
              4.3318936969792,
              45.318070065193
            ],
            [
              4.3540593156553,
              45.332022340962
            ],
            [
              4.3665395777096,
              45.336998573027
            ],
            [
              4.359714005021,
              45.349582617041
            ],
            [
              4.3504424731325,
              45.358204385933
            ],
            [
              4.3317565838798,
              45.368289462149
            ],
            [
              4.3082004946916,
              45.370982390408
            ],
            [
              4.2931596416647,
              45.366252532086
            ],
            [
              4.2954570761374,
              45.359956219084
            ],
            [
              4.2847457415048,
              45.356573698914
            ],
            [
              4.2716927610221,
              45.359824226654
            ],
            [
              4.271304361305,
              45.374642941524
            ],
            [
              4.2570898487259,
              45.372515018116
            ],
            [
              4.2445262165178,
              45.384912858435
            ],
            [
              4.2264286102871,
              45.389719679931
            ],
            [
              4.2031703045307,
              45.383587778452
            ],
            [
              4.1933063287861,
              45.384562302303
            ],
            [
              4.1779313879208,
              45.392600323214
            ],
            [
              4.1444492305075,
              45.384149823118
            ],
            [
              4.1582492082396,
              45.372767383985
            ],
            [
              4.1540232523863,
              45.364602527186
            ],
            [
              4.1213626119659,
              45.369089362178
            ],
            [
              4.0992156423607,
              45.359516453522
            ],
            [
              4.0778214653417,
              45.354983989389
            ],
            [
              4.077685114194,
              45.346159204789
            ],
            [
              4.0708092191247,
              45.336053711997
            ],
            [
              4.062387978027,
              45.339100276272
            ],
            [
              4.053660453716,
              45.352664525343
            ],
            [
              4.0432641754966,
              45.355941617091
            ],
            [
              4.0248202212879,
              45.342887748714
            ],
            [
              4.0160566045972,
              45.328623259374
            ],
            [
              4.0026131235678,
              45.355899588451
            ],
            [
              3.9926142993354,
              45.359099048768
            ],
            [
              3.9796493264091,
              45.375136167919
            ],
            [
              3.9622933080873,
              45.369902404517
            ],
            [
              3.9487771439236,
              45.375106507164
            ],
            [
              3.9373971009283,
              45.37201719016
            ],
            [
              3.9353040641977,
              45.358999775526
            ],
            [
              3.9229587665269,
              45.357997266653
            ],
            [
              3.9169353286998,
              45.339403551718
            ],
            [
              3.9083091415165,
              45.333317123265
            ],
            [
              3.9024783688026,
              45.339149111199
            ],
            [
              3.8974083668223,
              45.357080180177
            ],
            [
              3.8928560302218,
              45.367901022685
            ],
            [
              3.8939015032397,
              45.381293140859
            ],
            [
              3.8892289954528,
              45.394517377924
            ],
            [
              3.8991889962651,
              45.410304937867
            ],
            [
              3.9092989645158,
              45.413177077287
            ],
            [
              3.9176994123003,
              45.421021469893
            ],
            [
              3.9379616198049,
              45.430225517975
            ],
            [
              3.9630379885044,
              45.435531390096
            ],
            [
              3.9601381718507,
              45.443447561939
            ],
            [
              3.9751082776962,
              45.448267726135
            ],
            [
              3.9673930485222,
              45.467344700943
            ],
            [
              3.968623430075,
              45.47544697307
            ],
            [
              3.9806922289344,
              45.481299193399
            ],
            [
              3.9844000097893,
              45.494111974154
            ],
            [
              3.9648369463598,
              45.503216903255
            ],
            [
              3.958560843992,
              45.511766295791
            ],
            [
              3.9521751857414,
              45.534129573924
            ],
            [
              3.945947387476,
              45.544478011248
            ],
            [
              3.9543510024832,
              45.555964462402
            ],
            [
              3.9460908601758,
              45.561534784139
            ],
            [
              3.9322884496016,
              45.579271825147
            ],
            [
              3.9091712372871,
              45.593142063809
            ],
            [
              3.9036013502365,
              45.599021524707
            ],
            [
              3.8846838349771,
              45.602754230839
            ],
            [
              3.8667443466401,
              45.614381981479
            ],
            [
              3.8617323667916,
              45.622726993642
            ],
            [
              3.8487628555111,
              45.626772595715
            ],
            [
              3.8395128061617,
              45.626177388842
            ],
            [
              3.8234583720899,
              45.632419606167
            ],
            [
              3.8179380073842,
              45.646530627733
            ],
            [
              3.8018106393062,
              45.660014089594
            ],
            [
              3.7948460493393,
              45.673242700876
            ],
            [
              3.7802451010076,
              45.689132849044
            ],
            [
              3.7761246864543,
              45.698498886956
            ],
            [
              3.7823901700362,
              45.709711277588
            ],
            [
              3.780847427756,
              45.724352891461
            ],
            [
              3.7674361843451,
              45.729833752913
            ],
            [
              3.7586673779066,
              45.746007164306
            ],
            [
              3.7459113537954,
              45.742253302458
            ],
            [
              3.7270987560963,
              45.755535555022
            ],
            [
              3.7388919147354,
              45.766816379012
            ],
            [
              3.7227382484835,
              45.778894420986
            ],
            [
              3.6997618488845,
              45.78317894952
            ],
            [
              3.6906909501902,
              45.792174332964
            ],
            [
              3.711616964199,
              45.799546630779
            ],
            [
              3.7217494092138,
              45.813638605233
            ],
            [
              3.7208168799623,
              45.822603018185
            ],
            [
              3.7269243731372,
              45.832561547866
            ],
            [
              3.7190248244993,
              45.85114949578
            ],
            [
              3.7245781350935,
              45.85432080766
            ],
            [
              3.7163746493407,
              45.871248126479
            ],
            [
              3.7315476579701,
              45.870161263522
            ],
            [
              3.7385585560632,
              45.873290945813
            ],
            [
              3.7543694315582,
              45.886028143379
            ],
            [
              3.7481167335366,
              45.898388978743
            ],
            [
              3.7302305003589,
              45.912154335422
            ],
            [
              3.7234494736526,
              45.921866002231
            ],
            [
              3.6940151632959,
              45.930727869968
            ],
            [
              3.6938345940228,
              45.949490567902
            ],
            [
              3.705814131782,
              45.956589216318
            ],
            [
              3.7018783842183,
              45.966895016744
            ],
            [
              3.7088901699693,
              45.97378360778
            ],
            [
              3.7244244240682,
              45.971884240348
            ],
            [
              3.7412768921209,
              45.966006368026
            ],
            [
              3.7543128815764,
              45.972389709109
            ],
            [
              3.7560344687643,
              45.98282892353
            ],
            [
              3.7716341928381,
              45.977142201771
            ],
            [
              3.7780103611486,
              45.971797121415
            ],
            [
              3.788905406334,
              45.97386142098
            ],
            [
              3.7999111245532,
              45.983663126279
            ],
            [
              3.8094533891965,
              45.984528293129
            ],
            [
              3.8254484135633,
              45.995971307468
            ],
            [
              3.8248068228184,
              46.007992803975
            ],
            [
              3.8133908047707,
              46.027624240033
            ],
            [
              3.8140768170665,
              46.035278403322
            ],
            [
              3.8067691666001,
              46.045618391287
            ],
            [
              3.8069950481339,
              46.061272636728
            ],
            [
              3.8201222967616,
              46.080582391707
            ],
            [
              3.8218751675633,
              46.087982942483
            ],
            [
              3.8025575098561,
              46.1103266156
            ],
            [
              3.7934862152259,
              46.11219865371
            ],
            [
              3.8008429256847,
              46.132058715551
            ],
            [
              3.7925477636674,
              46.144965705297
            ],
            [
              3.7897672156925,
              46.154853997372
            ],
            [
              3.8078653732897,
              46.161593321821
            ],
            [
              3.8068202458538,
              46.165432514608
            ],
            [
              3.7909760971753,
              46.184421716016
            ],
            [
              3.7893076176261,
              46.194791199175
            ],
            [
              3.7931434307041,
              46.209403501526
            ],
            [
              3.7811487813348,
              46.223127689157
            ],
            [
              3.773158056228,
              46.227368816678
            ],
            [
              3.7685491775545,
              46.23948507045
            ],
            [
              3.7795833492069,
              46.243233638723
            ],
            [
              3.802694767872,
              46.238508201577
            ],
            [
              3.8064067390994,
              46.243904931901
            ],
            [
              3.8048855350295,
              46.254857096313
            ],
            [
              3.820453152188,
              46.259693449536
            ],
            [
              3.8676266829896,
              46.262530668465
            ],
            [
              3.8778014339102,
              46.275936357353
            ],
            [
              3.8880579460788,
              46.27381621963
            ],
            [
              3.899538631706,
              46.275908099459
            ]
          ]
        ]
      },
      "properties": {
        "code": "42",
        "nom": "Loire",
        "population": 768508,
        "delitspour1000": "4,634",
        "nombremosque": 46,
        "parentsEtrangers": "69,7",
        "benefRsa": 36550,
        "eoliennes": 1
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1.9940901445311,
              48.286584124472
            ],
            [
              2.007237571012,
              48.284688606385
            ],
            [
              2.0228447716486,
              48.288080340461
            ],
            [
              2.0493770399482,
              48.289753273514
            ],
            [
              2.0527138226636,
              48.295470353999
            ],
            [
              2.0815050149817,
              48.293603520661
            ],
            [
              2.1061680366894,
              48.307635584746
            ],
            [
              2.1137168991141,
              48.307245161467
            ],
            [
              2.1105944678389,
              48.29694441258
            ],
            [
              2.1361199958338,
              48.299247999325
            ],
            [
              2.1637483268763,
              48.298436908006
            ],
            [
              2.1557084788588,
              48.304493813388
            ],
            [
              2.1543896262247,
              48.315910610694
            ],
            [
              2.1690253806915,
              48.312695907539
            ],
            [
              2.1813553737363,
              48.313746373001
            ],
            [
              2.185025102223,
              48.32642865382
            ],
            [
              2.1982097691131,
              48.337062877906
            ],
            [
              2.2022520441218,
              48.344598562828
            ],
            [
              2.2239137900476,
              48.336416239958
            ],
            [
              2.2295923211201,
              48.329229232055
            ],
            [
              2.2463103911083,
              48.329962148865
            ],
            [
              2.2380428382036,
              48.316370632568
            ],
            [
              2.2494360700816,
              48.314580917036
            ],
            [
              2.2451518848313,
              48.298393903395
            ],
            [
              2.2535700132149,
              48.300243238173
            ],
            [
              2.2668923386489,
              48.314682212687
            ],
            [
              2.2954192911964,
              48.30822244035
            ],
            [
              2.312593571187,
              48.330803657009
            ],
            [
              2.3276948002521,
              48.333053330653
            ],
            [
              2.3401550939779,
              48.320230117587
            ],
            [
              2.3698159145392,
              48.308674310979
            ],
            [
              2.3968534009104,
              48.314755298076
            ],
            [
              2.4026629385658,
              48.320717653248
            ],
            [
              2.4048123966273,
              48.314593139765
            ],
            [
              2.4207604700518,
              48.299253278858
            ],
            [
              2.4231698173532,
              48.289315551296
            ],
            [
              2.4178314142956,
              48.278395078542
            ],
            [
              2.4237621901031,
              48.260296420297
            ],
            [
              2.4319323869166,
              48.255142430662
            ],
            [
              2.4501643446939,
              48.250036886561
            ],
            [
              2.4690928288345,
              48.255276432625
            ],
            [
              2.4866525872998,
              48.240104494021
            ],
            [
              2.5061863934974,
              48.238532108957
            ],
            [
              2.5141133764865,
              48.214377652405
            ],
            [
              2.5229439829919,
              48.200517077101
            ],
            [
              2.5129645377339,
              48.192979126229
            ],
            [
              2.5097551291606,
              48.177877699501
            ],
            [
              2.5168430223759,
              48.166807571121
            ],
            [
              2.5063243383023,
              48.156437563961
            ],
            [
              2.4832322969696,
              48.164516406945
            ],
            [
              2.4783726996912,
              48.156743847
            ],
            [
              2.4560832798883,
              48.13581808329
            ],
            [
              2.4442532323658,
              48.131467936811
            ],
            [
              2.4510870610062,
              48.123458807719
            ],
            [
              2.4648664700171,
              48.129109351924
            ],
            [
              2.4903125823631,
              48.126606179474
            ],
            [
              2.5215119811805,
              48.127298388836
            ],
            [
              2.5381145999136,
              48.140650823762
            ],
            [
              2.570559954027,
              48.140815681604
            ],
            [
              2.5775724874953,
              48.132078323329
            ],
            [
              2.6026896280919,
              48.131484417095
            ],
            [
              2.6397379446435,
              48.138858805822
            ],
            [
              2.6463713950461,
              48.136008390021
            ],
            [
              2.6634137701321,
              48.122204261229
            ],
            [
              2.6722231385896,
              48.124202437622
            ],
            [
              2.706543037587,
              48.124819235783
            ],
            [
              2.7291489796899,
              48.139089867843
            ],
            [
              2.7551942761197,
              48.145652570311
            ],
            [
              2.7535738017345,
              48.15321134405
            ],
            [
              2.7414632024764,
              48.159773038474
            ],
            [
              2.7462653547317,
              48.163802955575
            ],
            [
              2.7686108994112,
              48.163611325806
            ],
            [
              2.7801683123181,
              48.167546718776
            ],
            [
              2.7989459318842,
              48.168273053171
            ],
            [
              2.8099458960087,
              48.161299706681
            ],
            [
              2.7986329461926,
              48.150165448323
            ],
            [
              2.800901925208,
              48.133251589253
            ],
            [
              2.8208840113898,
              48.129664483454
            ],
            [
              2.8252530440464,
              48.133557427837
            ],
            [
              2.8411949308256,
              48.137851473794
            ],
            [
              2.8590064128033,
              48.147350198136
            ],
            [
              2.8680328112418,
              48.156437148324
            ],
            [
              2.9363157566366,
              48.163391744884
            ],
            [
              2.9532706975673,
              48.165012141792
            ],
            [
              2.9636115856187,
              48.152952836191
            ],
            [
              2.9908854951697,
              48.152489288025
            ],
            [
              2.9939835469666,
              48.142265686487
            ],
            [
              3.0067845221623,
              48.145250368304
            ],
            [
              3.0294681734995,
              48.133204471839
            ],
            [
              3.0158768007648,
              48.115703437492
            ],
            [
              3.035285259367,
              48.115994375619
            ],
            [
              3.0383144426538,
              48.101542780839
            ],
            [
              3.0498894899541,
              48.088838788782
            ],
            [
              3.0504714242752,
              48.072334131135
            ],
            [
              3.0668426687001,
              48.062700948945
            ],
            [
              3.0883164489307,
              48.053931896432
            ],
            [
              3.0973362166793,
              48.039371853532
            ],
            [
              3.1242571374888,
              48.031123310866
            ],
            [
              3.120444753158,
              48.027237026667
            ],
            [
              3.1031098748357,
              48.024050754331
            ],
            [
              3.1040912941224,
              48.013541749122
            ],
            [
              3.1154271480689,
              48.012966036779
            ],
            [
              3.1245523220345,
              48.006031052733
            ],
            [
              3.1215086124269,
              47.99508051279
            ],
            [
              3.1284487900515,
              47.970976841524
            ],
            [
              3.1177801945629,
              47.964978280792
            ],
            [
              3.1052652915151,
              47.946939230434
            ],
            [
              3.0959562152001,
              47.94648282966
            ],
            [
              3.0813886854655,
              47.938303435109
            ],
            [
              3.0784762596905,
              47.93141901745
            ],
            [
              3.0646126208988,
              47.930516710244
            ],
            [
              3.049851715745,
              47.917612271733
            ],
            [
              3.0501029428736,
              47.911450149596
            ],
            [
              3.0369085970173,
              47.910046599044
            ],
            [
              3.0251429746145,
              47.905395103831
            ],
            [
              3.0102886119028,
              47.904716972369
            ],
            [
              3.0072263096695,
              47.895290955742
            ],
            [
              3.0113128689564,
              47.874942218413
            ],
            [
              3.0020156817175,
              47.86910456894
            ],
            [
              3.0053590366439,
              47.864049253797
            ],
            [
              3.0241574870815,
              47.860432544378
            ],
            [
              3.0338278605646,
              47.843872107669
            ],
            [
              3.0310783842835,
              47.837952684286
            ],
            [
              3.0125313015817,
              47.834373232486
            ],
            [
              3.0154699273932,
              47.813535934414
            ],
            [
              3.0229373322912,
              47.812772400995
            ],
            [
              3.0281204473987,
              47.800645366032
            ],
            [
              3.0237994278176,
              47.786550178612
            ],
            [
              2.988226602321,
              47.78603448946
            ],
            [
              2.9350564314568,
              47.763249875579
            ],
            [
              2.9093762293739,
              47.769314365231
            ],
            [
              2.893986958474,
              47.764757164259
            ],
            [
              2.8701769520741,
              47.764868196886
            ],
            [
              2.8566700654285,
              47.760929175798
            ],
            [
              2.8581457165416,
              47.745650029901
            ],
            [
              2.8487899744432,
              47.725884864654
            ],
            [
              2.8489690050712,
              47.716844895991
            ],
            [
              2.8585167014012,
              47.711991090287
            ],
            [
              2.8765799552305,
              47.719081526808
            ],
            [
              2.8841930930896,
              47.714211876689
            ],
            [
              2.8829409210413,
              47.702615088236
            ],
            [
              2.9037123946566,
              47.695095946597
            ],
            [
              2.9231151407358,
              47.680558801908
            ],
            [
              2.9181562549963,
              47.669794968987
            ],
            [
              2.926238261078,
              47.660085723292
            ],
            [
              2.9369444627222,
              47.659131595543
            ],
            [
              2.9542289513333,
              47.64577418642
            ],
            [
              2.9362905168585,
              47.636648702788
            ],
            [
              2.9357687458876,
              47.619831796752
            ],
            [
              2.9452166853722,
              47.608449329011
            ],
            [
              2.9379937136807,
              47.598885605034
            ],
            [
              2.9480691732296,
              47.590534725553
            ],
            [
              2.954982923175,
              47.590408879602
            ],
            [
              2.9644517577136,
              47.581632358212
            ],
            [
              2.9622680864494,
              47.576787199846
            ],
            [
              2.9765353538906,
              47.569424295927
            ],
            [
              2.9643142632036,
              47.564408371297
            ],
            [
              2.9586515837228,
              47.55737826151
            ],
            [
              2.9142961197082,
              47.565971982014
            ],
            [
              2.9093721909559,
              47.559160555497
            ],
            [
              2.8907714985755,
              47.553102008094
            ],
            [
              2.8738950692802,
              47.55280954087
            ],
            [
              2.8661248696402,
              47.546368484983
            ],
            [
              2.8574859883322,
              47.552839253284
            ],
            [
              2.8451871650071,
              47.544932948997
            ],
            [
              2.8489012329621,
              47.53754134151
            ],
            [
              2.8746305174837,
              47.520424918568
            ],
            [
              2.8470793076985,
              47.513667879934
            ],
            [
              2.8346869746123,
              47.508909835805
            ],
            [
              2.8253522908075,
              47.497211236062
            ],
            [
              2.8064811348488,
              47.494455723983
            ],
            [
              2.7980078225933,
              47.496783569863
            ],
            [
              2.7912248331378,
              47.506907437916
            ],
            [
              2.7680289965473,
              47.523523680368
            ],
            [
              2.7501051267783,
              47.52399432216
            ],
            [
              2.7418945281053,
              47.526856023445
            ],
            [
              2.7326153907893,
              47.538041988135
            ],
            [
              2.7272199325343,
              47.535946546253
            ],
            [
              2.7222672045739,
              47.521369480718
            ],
            [
              2.7220501940056,
              47.505337122249
            ],
            [
              2.7274128318664,
              47.49888751122
            ],
            [
              2.7193587311998,
              47.492167545335
            ],
            [
              2.6844365639414,
              47.482968445146
            ],
            [
              2.6584604288768,
              47.487901045881
            ],
            [
              2.6558735526076,
              47.510394947544
            ],
            [
              2.6120137313394,
              47.526383600079
            ],
            [
              2.6167379402622,
              47.540898035379
            ],
            [
              2.5998977203117,
              47.553688422022
            ],
            [
              2.5729212924557,
              47.560019824163
            ],
            [
              2.5632062660237,
              47.572136704018
            ],
            [
              2.5410842063882,
              47.574066312921
            ],
            [
              2.5079023998009,
              47.568183660166
            ],
            [
              2.4913556166844,
              47.568297226273
            ],
            [
              2.4525445186872,
              47.596764185392
            ],
            [
              2.4484422329071,
              47.606257520017
            ],
            [
              2.4373445977447,
              47.609775401769
            ],
            [
              2.4026835806557,
              47.599501873767
            ],
            [
              2.393101394459,
              47.591906545009
            ],
            [
              2.3730196534104,
              47.585528165617
            ],
            [
              2.3560053519371,
              47.593806918478
            ],
            [
              2.3424454034671,
              47.605276604314
            ],
            [
              2.3284801497791,
              47.607120727401
            ],
            [
              2.2862326072553,
              47.628965936616
            ],
            [
              2.2392136694694,
              47.620975607683
            ],
            [
              2.2409806461446,
              47.637347215529
            ],
            [
              2.2325658218639,
              47.653034738696
            ],
            [
              2.2199543001822,
              47.667862812619
            ],
            [
              2.2131772586443,
              47.661784720609
            ],
            [
              2.2054546574629,
              47.662316663494
            ],
            [
              2.2060845860018,
              47.676392655536
            ],
            [
              2.2025518712273,
              47.678142759644
            ],
            [
              2.1746024427554,
              47.672463395154
            ],
            [
              2.148508982167,
              47.670422872397
            ],
            [
              2.1395626868162,
              47.677378413927
            ],
            [
              2.1271060591147,
              47.678525559507
            ],
            [
              2.1129574971142,
              47.670135104119
            ],
            [
              2.1076708613189,
              47.676510157534
            ],
            [
              2.0907502031475,
              47.677960430287
            ],
            [
              2.0735518824881,
              47.681834637553
            ],
            [
              2.0664644343611,
              47.674308887146
            ],
            [
              2.0386823095057,
              47.677164038675
            ],
            [
              2.0248434521895,
              47.675418359736
            ],
            [
              2.0080511541807,
              47.677306025655
            ],
            [
              1.9977468648774,
              47.674758027403
            ],
            [
              2.0015805166183,
              47.668676084697
            ],
            [
              1.9877778685346,
              47.66168736219
            ],
            [
              1.983093163548,
              47.66545077466
            ],
            [
              1.9608339734141,
              47.659444437434
            ],
            [
              1.9434347157105,
              47.660085085099
            ],
            [
              1.939467766209,
              47.668143455788
            ],
            [
              1.9424243352344,
              47.675629891174
            ],
            [
              1.9341226017816,
              47.679412588312
            ],
            [
              1.9139173358433,
              47.674618695582
            ],
            [
              1.8873127958992,
              47.678926059608
            ],
            [
              1.8701081524394,
              47.674181880335
            ],
            [
              1.8612058217535,
              47.679452429884
            ],
            [
              1.8645999396589,
              47.68706666871
            ],
            [
              1.8555867935147,
              47.689547886166
            ],
            [
              1.8436948478365,
              47.686349616092
            ],
            [
              1.8447911540901,
              47.665038871909
            ],
            [
              1.8429034319191,
              47.65684472991
            ],
            [
              1.8095352075606,
              47.652202157028
            ],
            [
              1.7985929573362,
              47.638435053478
            ],
            [
              1.7905220094023,
              47.637003267191
            ],
            [
              1.778817037647,
              47.642839048521
            ],
            [
              1.7798792408489,
              47.649454925502
            ],
            [
              1.7723174416126,
              47.653286190839
            ],
            [
              1.7488611522012,
              47.655778522219
            ],
            [
              1.7399435814608,
              47.661534026638
            ],
            [
              1.7376379018467,
              47.671236702621
            ],
            [
              1.7291985316751,
              47.687247949392
            ],
            [
              1.7193741272844,
              47.695921227771
            ],
            [
              1.7280581298649,
              47.699564531009
            ],
            [
              1.7128707755202,
              47.722059583197
            ],
            [
              1.7133188398603,
              47.729302822049
            ],
            [
              1.6973462178645,
              47.738389810232
            ],
            [
              1.6811364365915,
              47.739154365803
            ],
            [
              1.6554331078549,
              47.747191269103
            ],
            [
              1.6266315647697,
              47.760974414138
            ],
            [
              1.6217876830374,
              47.743816785167
            ],
            [
              1.6085971255235,
              47.733932392641
            ],
            [
              1.5928848485256,
              47.728483789961
            ],
            [
              1.5984630147815,
              47.739649217972
            ],
            [
              1.5478792778272,
              47.769763406546
            ],
            [
              1.5712455140145,
              47.78696332111
            ],
            [
              1.5705394566163,
              47.796589231895
            ],
            [
              1.5532872216846,
              47.810093357413
            ],
            [
              1.5337021961131,
              47.82052774558
            ],
            [
              1.5313766719936,
              47.828606391634
            ],
            [
              1.5350922901417,
              47.839542960878
            ],
            [
              1.5540228244566,
              47.845851197251
            ],
            [
              1.5657838326052,
              47.855000613144
            ],
            [
              1.5881310268697,
              47.876427415582
            ],
            [
              1.582706838203,
              47.887113978677
            ],
            [
              1.5814172497993,
              47.901925983899
            ],
            [
              1.5571642493219,
              47.918539042734
            ],
            [
              1.541140496152,
              47.925446558629
            ],
            [
              1.5254275303694,
              47.929115430213
            ],
            [
              1.5252355885738,
              47.941659567787
            ],
            [
              1.5350827818869,
              47.949867405352
            ],
            [
              1.5519690270563,
              47.953083873543
            ],
            [
              1.5467703394398,
              47.960513836986
            ],
            [
              1.5489488432055,
              47.967093353804
            ],
            [
              1.5604532940332,
              47.967890043333
            ],
            [
              1.566720736488,
              47.974271645772
            ],
            [
              1.564819969277,
              47.989771960096
            ],
            [
              1.5480894335486,
              47.986536085028
            ],
            [
              1.5392433825958,
              47.989061054814
            ],
            [
              1.5278386618546,
              47.98142842725
            ],
            [
              1.5201239288128,
              47.982274877947
            ],
            [
              1.5229905388051,
              48.001748886761
            ],
            [
              1.5266726944869,
              48.007268352834
            ],
            [
              1.5185544224894,
              48.011906147946
            ],
            [
              1.5129691249084,
              48.02871899692
            ],
            [
              1.5277102496428,
              48.033498158287
            ],
            [
              1.5470770086942,
              48.029583279667
            ],
            [
              1.5407827949442,
              48.037948741421
            ],
            [
              1.545309570089,
              48.044589454599
            ],
            [
              1.5596657274016,
              48.039559238527
            ],
            [
              1.594119689523,
              48.030699968818
            ],
            [
              1.5967058847939,
              48.042227836769
            ],
            [
              1.6138330250117,
              48.044986795647
            ],
            [
              1.6209568366597,
              48.048718232089
            ],
            [
              1.6245969691677,
              48.067220090739
            ],
            [
              1.6380695078541,
              48.067976032039
            ],
            [
              1.6556744513311,
              48.076172953254
            ],
            [
              1.6647510484441,
              48.074651678328
            ],
            [
              1.6789927970749,
              48.065831167459
            ],
            [
              1.6889292579448,
              48.077953491387
            ],
            [
              1.7089899355992,
              48.066937747591
            ],
            [
              1.7288895219128,
              48.068931636276
            ],
            [
              1.7448338369731,
              48.06614524936
            ],
            [
              1.7839234552259,
              48.075206756036
            ],
            [
              1.7979715398442,
              48.076867371023
            ],
            [
              1.8015345857878,
              48.089428497495
            ],
            [
              1.8127875257203,
              48.084803197423
            ],
            [
              1.8321031832628,
              48.080672803607
            ],
            [
              1.8375940015432,
              48.087070014585
            ],
            [
              1.865673925296,
              48.083858124348
            ],
            [
              1.8649017038729,
              48.095185580523
            ],
            [
              1.8733269247989,
              48.098681714322
            ],
            [
              1.8843812211988,
              48.097658329045
            ],
            [
              1.8899999941212,
              48.105703747053
            ],
            [
              1.8996002710496,
              48.127602651294
            ],
            [
              1.913003919191,
              48.130978360693
            ],
            [
              1.9204042319889,
              48.146381753315
            ],
            [
              1.9054240606761,
              48.149642686632
            ],
            [
              1.9061750572175,
              48.156498842196
            ],
            [
              1.9142642906099,
              48.167458737772
            ],
            [
              1.9250430961114,
              48.174489271685
            ],
            [
              1.9370288170292,
              48.168964491691
            ],
            [
              1.9571488079852,
              48.174876250944
            ],
            [
              1.9709649986332,
              48.171066799092
            ],
            [
              1.9709879585943,
              48.183076826836
            ],
            [
              1.9514590226977,
              48.189583913554
            ],
            [
              1.9572597779247,
              48.203598595549
            ],
            [
              1.9657744773308,
              48.200350247033
            ],
            [
              1.9776716043689,
              48.201286202611
            ],
            [
              1.9632503662396,
              48.224349916739
            ],
            [
              1.9664071067243,
              48.234790636014
            ],
            [
              1.9620649748347,
              48.239631547157
            ],
            [
              1.9654789698809,
              48.255468044666
            ],
            [
              1.9750911067358,
              48.258135172452
            ],
            [
              1.9878769347764,
              48.268708475186
            ],
            [
              1.9940901445311,
              48.286584124472
            ]
          ]
        ]
      },
      "properties": {
        "code": "45",
        "nom": "Loiret",
        "population": 682304,
        "delitspour1000": "4,519",
        "nombremosque": 36,
        "parentsEtrangers": "67,2",
        "benefRsa": 34430,
        "eoliennes": 64
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              0.2973266430349,
              44.762287404122
            ],
            [
              0.30916614899776,
              44.757357085356
            ],
            [
              0.33544412179638,
              44.737433837779
            ],
            [
              0.34618532398135,
              44.72254110551
            ],
            [
              0.34799385726824,
              44.710868627082
            ],
            [
              0.3408274770455,
              44.703595690519
            ],
            [
              0.3596155447401,
              44.690997557697
            ],
            [
              0.35032434126437,
              44.674861231998
            ],
            [
              0.34962257641711,
              44.660130119601
            ],
            [
              0.35458874373803,
              44.654812823774
            ],
            [
              0.36557177083639,
              44.662207347472
            ],
            [
              0.38167715195904,
              44.654918852838
            ],
            [
              0.39443306244771,
              44.653282597181
            ],
            [
              0.41471046209978,
              44.646333606438
            ],
            [
              0.44999811697044,
              44.654887700413
            ],
            [
              0.47053267175137,
              44.669578320195
            ],
            [
              0.48751696358911,
              44.666675018711
            ],
            [
              0.5030043961045,
              44.674065809945
            ],
            [
              0.52178035716357,
              44.678540368601
            ],
            [
              0.53612162212023,
              44.674035536056
            ],
            [
              0.54124786531433,
              44.665158664905
            ],
            [
              0.570416745932,
              44.674897613312
            ],
            [
              0.57715032653657,
              44.694022262337
            ],
            [
              0.58857834129558,
              44.698027811587
            ],
            [
              0.60972459944616,
              44.695374617448
            ],
            [
              0.62001412394403,
              44.691298261574
            ],
            [
              0.62200094378824,
              44.701000019877
            ],
            [
              0.63048461600039,
              44.70652938729
            ],
            [
              0.64156011376223,
              44.704261998438
            ],
            [
              0.65589506955682,
              44.694208640971
            ],
            [
              0.65743174445133,
              44.677850624615
            ],
            [
              0.6849985568551,
              44.675550535442
            ],
            [
              0.71646914776409,
              44.678082439569
            ],
            [
              0.72913529059336,
              44.676077994737
            ],
            [
              0.74480854311439,
              44.681792562832
            ],
            [
              0.76265233144161,
              44.682144002173
            ],
            [
              0.76447481571943,
              44.689532790514
            ],
            [
              0.7798310395793,
              44.683389762054
            ],
            [
              0.79265719596499,
              44.699718223349
            ],
            [
              0.79772154851158,
              44.701768085135
            ],
            [
              0.82699837154341,
              44.69189132944
            ],
            [
              0.83155336202802,
              44.684262335678
            ],
            [
              0.82608946558714,
              44.675992791318
            ],
            [
              0.84339892561011,
              44.663482499488
            ],
            [
              0.84255446514478,
              44.656139571349
            ],
            [
              0.83505655242676,
              44.653293928743
            ],
            [
              0.83636330139759,
              44.638582868907
            ],
            [
              0.81700413459438,
              44.627005076133
            ],
            [
              0.82825800061689,
              44.61369341488
            ],
            [
              0.83738728356167,
              44.616103207266
            ],
            [
              0.83752764271031,
              44.605670766802
            ],
            [
              0.84250074564053,
              44.600962425481
            ],
            [
              0.86999224882068,
              44.597310797789
            ],
            [
              0.87785522131399,
              44.615460197735
            ],
            [
              0.89074979357294,
              44.614991070286
            ],
            [
              0.92136204024362,
              44.627560358651
            ],
            [
              0.92747540107031,
              44.635428181755
            ],
            [
              0.94856309175281,
              44.640612493856
            ],
            [
              0.96159103444349,
              44.637461894801
            ],
            [
              0.97165086674602,
              44.638207048166
            ],
            [
              0.9791235230928,
              44.643607663491
            ],
            [
              0.99682491105238,
              44.63179745302
            ],
            [
              1.0147514182424,
              44.614096385206
            ],
            [
              1.0454184639247,
              44.605754716834
            ],
            [
              1.0642117552636,
              44.596511145972
            ],
            [
              1.0733130611041,
              44.595578793709
            ],
            [
              1.0779367166615,
              44.584607289506
            ],
            [
              1.0751420531924,
              44.577325705506
            ],
            [
              1.0716939661813,
              44.567841711875
            ],
            [
              1.0463221422077,
              44.562091748647
            ],
            [
              1.0347007735952,
              44.555410813413
            ],
            [
              1.0131652927005,
              44.53612981948
            ],
            [
              1.0102474849887,
              44.545187788418
            ],
            [
              0.99391928143422,
              44.549540779914
            ],
            [
              0.98177646477517,
              44.543949619625
            ],
            [
              0.99607013748509,
              44.526838106147
            ],
            [
              1.0162275856839,
              44.505873805304
            ],
            [
              1.0168410499751,
              44.492627682886
            ],
            [
              1.009020244264,
              44.480044617987
            ],
            [
              1.0230093439874,
              44.475437273235
            ],
            [
              1.0238896841162,
              44.464106031022
            ],
            [
              1.0209838507254,
              44.456237710915
            ],
            [
              1.0247167778907,
              44.442988405687
            ],
            [
              1.0333318174167,
              44.432217983065
            ],
            [
              1.0452829749205,
              44.434328842652
            ],
            [
              1.0574845114814,
              44.427673212448
            ],
            [
              1.060811266939,
              44.416585968472
            ],
            [
              1.0613076444275,
              44.401878633002
            ],
            [
              1.0514192493927,
              44.392094511982
            ],
            [
              1.060916410464,
              44.388148956247
            ],
            [
              1.0640814762214,
              44.378508721439
            ],
            [
              1.0590342432563,
              44.369063397724
            ],
            [
              1.0497968480895,
              44.362639367374
            ],
            [
              1.004557393851,
              44.365587714321
            ],
            [
              0.9973306063079,
              44.368970904934
            ],
            [
              0.98063192933013,
              44.358423098349
            ],
            [
              0.97103609486138,
              44.361280217267
            ],
            [
              0.95004981400894,
              44.35975578355
            ],
            [
              0.94141859483151,
              44.345347538009
            ],
            [
              0.9372881287013,
              44.368840749909
            ],
            [
              0.92589588135671,
              44.375628735075
            ],
            [
              0.91991430872905,
              44.384149659695
            ],
            [
              0.89821258712664,
              44.381368271621
            ],
            [
              0.88733582833582,
              44.366374054101
            ],
            [
              0.89356714925376,
              44.358037988638
            ],
            [
              0.89611323540437,
              44.346226535055
            ],
            [
              0.87347140241339,
              44.323307233
            ],
            [
              0.86962451276277,
              44.309387172973
            ],
            [
              0.88231719491829,
              44.308198855394
            ],
            [
              0.89449665699205,
              44.296740377136
            ],
            [
              0.91642356261815,
              44.302198002519
            ],
            [
              0.92396339608302,
              44.288698042192
            ],
            [
              0.94992392368958,
              44.276443376947
            ],
            [
              0.94078442423987,
              44.263651200087
            ],
            [
              0.9277876570183,
              44.26740561543
            ],
            [
              0.93302754076927,
              44.253148853876
            ],
            [
              0.91909475906904,
              44.238271923817
            ],
            [
              0.9292406197485,
              44.230246542717
            ],
            [
              0.92476807979815,
              44.226051714552
            ],
            [
              0.91176049272424,
              44.204739962363
            ],
            [
              0.90628814214223,
              44.190306668919
            ],
            [
              0.86348768257448,
              44.193325437089
            ],
            [
              0.85633796923916,
              44.18904677194
            ],
            [
              0.85357025770147,
              44.174962426044
            ],
            [
              0.86504004908695,
              44.173534926921
            ],
            [
              0.8722469128314,
              44.167867373919
            ],
            [
              0.88301819652021,
              44.17546628714
            ],
            [
              0.89034197833787,
              44.169672870511
            ],
            [
              0.88663373024812,
              44.16278908945
            ],
            [
              0.88847935271951,
              44.148766853903
            ],
            [
              0.88180706484803,
              44.140904730858
            ],
            [
              0.87944269844653,
              44.129608737841
            ],
            [
              0.86877875307642,
              44.126327092678
            ],
            [
              0.83543363467659,
              44.136365774298
            ],
            [
              0.82440975793604,
              44.141179876681
            ],
            [
              0.79610189105916,
              44.145621618679
            ],
            [
              0.7885669186743,
              44.144391188204
            ],
            [
              0.79756644493954,
              44.130381902776
            ],
            [
              0.79623991760453,
              44.11512942813
            ],
            [
              0.78623555942697,
              44.11191141984
            ],
            [
              0.77191199947865,
              44.113420095124
            ],
            [
              0.75287865013836,
              44.102267910932
            ],
            [
              0.73810974125492,
              44.073027677
            ],
            [
              0.741884791205,
              44.065199235038
            ],
            [
              0.73670154232703,
              44.061550959213
            ],
            [
              0.71736742842423,
              44.057565048253
            ],
            [
              0.7078014441272,
              44.058016144097
            ],
            [
              0.69448178843623,
              44.045585512479
            ],
            [
              0.68766619417171,
              44.045887421074
            ],
            [
              0.6797359030614,
              44.03717928063
            ],
            [
              0.6795846041912,
              44.029344422988
            ],
            [
              0.66659683472462,
              44.025144272763
            ],
            [
              0.65507583677567,
              44.031189052143
            ],
            [
              0.65460048123679,
              44.04130753634
            ],
            [
              0.63165143580682,
              44.049501421006
            ],
            [
              0.62788687147415,
              44.060631087503
            ],
            [
              0.60470426269225,
              44.071956240981
            ],
            [
              0.59797769402997,
              44.078224550392
            ],
            [
              0.57588134690602,
              44.075909688882
            ],
            [
              0.5650766725315,
              44.05922317624
            ],
            [
              0.53902463781127,
              44.053724078487
            ],
            [
              0.52207386686065,
              44.05696870158
            ],
            [
              0.51214276226031,
              44.063296138178
            ],
            [
              0.5060519145443,
              44.056124874168
            ],
            [
              0.48581828489735,
              44.058598437153
            ],
            [
              0.47876888458351,
              44.054521013697
            ],
            [
              0.4595157527797,
              44.055234750337
            ],
            [
              0.44887562692766,
              44.042594787048
            ],
            [
              0.44244380213487,
              44.028759139019
            ],
            [
              0.41750846361679,
              44.026970327067
            ],
            [
              0.39446082314246,
              44.019984410354
            ],
            [
              0.38151930823563,
              44.006373305954
            ],
            [
              0.3713725918443,
              44.008155096613
            ],
            [
              0.36491171606437,
              44.015152271393
            ],
            [
              0.35756641014151,
              44.01637714562
            ],
            [
              0.32938687327154,
              44.008324743136
            ],
            [
              0.31653852185538,
              44.01011924115
            ],
            [
              0.31793784234407,
              43.994973643102
            ],
            [
              0.30409114391986,
              43.993060809869
            ],
            [
              0.27345979290764,
              43.998815616074
            ],
            [
              0.2658680614134,
              44.003716751224
            ],
            [
              0.24748889809396,
              44.00473345675
            ],
            [
              0.2352015335306,
              44.008471438234
            ],
            [
              0.22470969223381,
              44.019169028731
            ],
            [
              0.20507206540087,
              44.0190292233
            ],
            [
              0.20117969184465,
              44.013668593318
            ],
            [
              0.18957139217537,
              44.014641613964
            ],
            [
              0.17916093410135,
              44.000666040326
            ],
            [
              0.16676088451462,
              43.996830517368
            ],
            [
              0.1664085585548,
              43.984223372919
            ],
            [
              0.1591211137839,
              43.973860568873
            ],
            [
              0.13863539397759,
              43.974770669617
            ],
            [
              0.14095408748334,
              43.994676380228
            ],
            [
              0.12640607961248,
              44.000336105676
            ],
            [
              0.11437379986584,
              43.988072909373
            ],
            [
              0.076043349292038,
              43.983138573142
            ],
            [
              0.062703136168861,
              44.000839934979
            ],
            [
              0.060601939796586,
              44.024928226919
            ],
            [
              0.071876331257881,
              44.025424244342
            ],
            [
              0.081976138741446,
              44.040393196883
            ],
            [
              0.07964864049181,
              44.045606981442
            ],
            [
              0.096757199990099,
              44.070218011948
            ],
            [
              0.10081938187221,
              44.086770408654
            ],
            [
              0.12751009632433,
              44.106227747222
            ],
            [
              0.13672631290526,
              44.109868945373
            ],
            [
              0.13260134887534,
              44.117279604997
            ],
            [
              0.13510329225982,
              44.124220572908
            ],
            [
              0.03459374998392,
              44.130654202294
            ],
            [
              0.025171514105427,
              44.138433935069
            ],
            [
              -0.0042360907976178,
              44.149994948769
            ],
            [
              -0.059853659558417,
              44.150820943359
            ],
            [
              -0.083343161175917,
              44.154647573209
            ],
            [
              -0.09964106827123,
              44.154887837188
            ],
            [
              -0.11369288849198,
              44.152333171325
            ],
            [
              -0.12915186861195,
              44.152407302012
            ],
            [
              -0.13125049106927,
              44.189332630715
            ],
            [
              -0.12947117877613,
              44.203865483968
            ],
            [
              -0.14068987994571,
              44.226407410087
            ],
            [
              -0.12013349102654,
              44.23674944888
            ],
            [
              -0.10806059061507,
              44.231019627038
            ],
            [
              -0.088056000728359,
              44.238856352832
            ],
            [
              -0.074379548540375,
              44.252405291475
            ],
            [
              -0.066629618842389,
              44.24675515416
            ],
            [
              -0.053367001946939,
              44.265767485647
            ],
            [
              -0.036178032292543,
              44.27143072373
            ],
            [
              -0.037144435788299,
              44.286361634851
            ],
            [
              -0.043397497716851,
              44.293567041696
            ],
            [
              -0.034812310736151,
              44.296722165589
            ],
            [
              -0.059508198107722,
              44.321373644807
            ],
            [
              -0.066571544598853,
              44.322441599368
            ],
            [
              -0.085757092678548,
              44.337609909237
            ],
            [
              -0.078824287140477,
              44.353695916086
            ],
            [
              -0.069564374036526,
              44.352946676151
            ],
            [
              -0.05445031981898,
              44.359340828755
            ],
            [
              -0.029972201019216,
              44.360520411846
            ],
            [
              -0.015317067489138,
              44.369328607193
            ],
            [
              -0.0028509397317395,
              44.372960212166
            ],
            [
              0.0061041022665394,
              44.367204827263
            ],
            [
              0.014719662755562,
              44.366614690633
            ],
            [
              0.023592604670749,
              44.377462953453
            ],
            [
              0.010450408812354,
              44.381757604422
            ],
            [
              0.018236013333469,
              44.389881558425
            ],
            [
              -0.0022393758929779,
              44.405073205548
            ],
            [
              -0.010556161472033,
              44.418543300826
            ],
            [
              -0.0039997054231598,
              44.439884875461
            ],
            [
              0.0075287126228942,
              44.447142316513
            ],
            [
              0.0048804193883298,
              44.456825604584
            ],
            [
              -0.012959450871031,
              44.461397567566
            ],
            [
              -0.013787425872077,
              44.480606498118
            ],
            [
              -0.017062403595954,
              44.494903785526
            ],
            [
              -0.015451041784187,
              44.505060157212
            ],
            [
              0.0013468507521223,
              44.520759088806
            ],
            [
              0.0096113783907688,
              44.521914927246
            ],
            [
              0.016481929542729,
              44.528534052288
            ],
            [
              0.01334553500528,
              44.537163334695
            ],
            [
              -0.00079249578691194,
              44.547722784938
            ],
            [
              0.0037569284895941,
              44.550564348949
            ],
            [
              0.020979912029289,
              44.54145425276
            ],
            [
              0.024614259705386,
              44.547446705923
            ],
            [
              0.040571405832228,
              44.55305031743
            ],
            [
              0.070718098981858,
              44.549329474287
            ],
            [
              0.076411026162087,
              44.563581490059
            ],
            [
              0.08613970807219,
              44.575792139257
            ],
            [
              0.08223436773711,
              44.58404269552
            ],
            [
              0.1128359692313,
              44.590802448417
            ],
            [
              0.1373372611031,
              44.607840943333
            ],
            [
              0.15085689358311,
              44.60864082157
            ],
            [
              0.15360179170808,
              44.616118295385
            ],
            [
              0.13926344006112,
              44.627142000076
            ],
            [
              0.13735197971124,
              44.636065032992
            ],
            [
              0.15503219600441,
              44.631810031274
            ],
            [
              0.16521090547081,
              44.632857082377
            ],
            [
              0.1671456092699,
              44.643736652511
            ],
            [
              0.18267131110117,
              44.661072863157
            ],
            [
              0.17725174403444,
              44.667337011292
            ],
            [
              0.16217516115648,
              44.670522207234
            ],
            [
              0.14155378137428,
              44.665533720013
            ],
            [
              0.12997902491486,
              44.672922900417
            ],
            [
              0.13372887707313,
              44.682445068839
            ],
            [
              0.10990509355722,
              44.684237827918
            ],
            [
              0.10058285885421,
              44.701166710208
            ],
            [
              0.10938058387031,
              44.710567046765
            ],
            [
              0.11781050325666,
              44.712797670467
            ],
            [
              0.1333985499063,
              44.704635060611
            ],
            [
              0.13939526267633,
              44.723198863501
            ],
            [
              0.14132400855573,
              44.73741784111
            ],
            [
              0.15300650688512,
              44.730478405576
            ],
            [
              0.16437464319642,
              44.735787120442
            ],
            [
              0.1761170331154,
              44.737358325159
            ],
            [
              0.18453078260015,
              44.749030776434
            ],
            [
              0.19416213999304,
              44.743135745847
            ],
            [
              0.20173077932943,
              44.722436212695
            ],
            [
              0.21679531034635,
              44.724068141039
            ],
            [
              0.2124165944403,
              44.7378483172
            ],
            [
              0.21552946146511,
              44.751053988796
            ],
            [
              0.22648867551785,
              44.764390535693
            ],
            [
              0.23847417869066,
              44.763350350246
            ],
            [
              0.24497879080163,
              44.752533581344
            ],
            [
              0.2557380828744,
              44.750109827852
            ],
            [
              0.28997766936396,
              44.757436143357
            ],
            [
              0.2973266430349,
              44.762287404122
            ]
          ]
        ]
      },
      "properties": {
        "code": "47",
        "nom": "Lot-et-Garonne",
        "population": 330844,
        "delitspour1000": "5,293",
        "nombremosque": 19,
        "parentsEtrangers": "72,1",
        "benefRsa": 18254
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.9509906730069,
              49.236867621535
            ],
            [
              4.9640346881277,
              49.245257319983
            ],
            [
              4.9981270480935,
              49.258879541555
            ],
            [
              4.9975627005068,
              49.264254757061
            ],
            [
              5.0237837017273,
              49.272448785206
            ],
            [
              5.0335958045544,
              49.27069210926
            ],
            [
              5.0511796603743,
              49.274052634115
            ],
            [
              5.0490397469327,
              49.285311702768
            ],
            [
              5.0592653036142,
              49.289745412169
            ],
            [
              5.0542494360715,
              49.294946695441
            ],
            [
              5.0398173770104,
              49.295200441657
            ],
            [
              5.038198718801,
              49.303898195861
            ],
            [
              5.0298290721899,
              49.311264470087
            ],
            [
              5.0374895768142,
              49.316800532558
            ],
            [
              5.029262636228,
              49.326172152775
            ],
            [
              5.026431605262,
              49.335916662789
            ],
            [
              5.0342909616956,
              49.339955943956
            ],
            [
              5.0534051064651,
              49.356963616151
            ],
            [
              5.0602044821857,
              49.371516208419
            ],
            [
              5.0899234459891,
              49.370324513714
            ],
            [
              5.1038247267133,
              49.388839332529
            ],
            [
              5.0857766473372,
              49.398789131919
            ],
            [
              5.0863593822614,
              49.40263284656
            ],
            [
              5.1018091739591,
              49.41426113807
            ],
            [
              5.1159039694465,
              49.421927048206
            ],
            [
              5.1053230916308,
              49.42524507231
            ],
            [
              5.1037090270263,
              49.43567245539
            ],
            [
              5.1094498519858,
              49.455422316847
            ],
            [
              5.091409768056,
              49.463503402532
            ],
            [
              5.0829515970446,
              49.470365328924
            ],
            [
              5.0814821332705,
              49.484887274721
            ],
            [
              5.0735014192619,
              49.48656060331
            ],
            [
              5.0623905513409,
              49.499152667162
            ],
            [
              5.0646387680349,
              49.508458158654
            ],
            [
              5.0796339849566,
              49.509861490249
            ],
            [
              5.100580497377,
              49.535816055819
            ],
            [
              5.0894349846201,
              49.541542898558
            ],
            [
              5.0960769630609,
              49.557784865303
            ],
            [
              5.1083902446796,
              49.555291686083
            ],
            [
              5.1131131503391,
              49.560620642841
            ],
            [
              5.1101795101092,
              49.573512920945
            ],
            [
              5.104571639078,
              49.575852340422
            ],
            [
              5.1122681641473,
              49.589569241117
            ],
            [
              5.1182786920926,
              49.593071219071
            ],
            [
              5.1448486924557,
              49.589886341917
            ],
            [
              5.162979025373,
              49.563530589026
            ],
            [
              5.1686760691919,
              49.563231140439
            ],
            [
              5.1889911476903,
              49.569978574101
            ],
            [
              5.1975144860259,
              49.566712158664
            ],
            [
              5.2130538883545,
              49.57347906066
            ],
            [
              5.2327553218453,
              49.569476323033
            ],
            [
              5.2346807330309,
              49.562230633403
            ],
            [
              5.2522425024193,
              49.556433491746
            ],
            [
              5.262707136888,
              49.541817880646
            ],
            [
              5.2763043921001,
              49.544296675228
            ],
            [
              5.2851084066964,
              49.553331327694
            ],
            [
              5.3117634211943,
              49.559287129916
            ],
            [
              5.3229962135866,
              49.576769750456
            ],
            [
              5.3432352693932,
              49.59419815719
            ],
            [
              5.3544440189109,
              49.588507953644
            ],
            [
              5.3615908063527,
              49.592230592456
            ],
            [
              5.3793732756841,
              49.594336083817
            ],
            [
              5.3808929253706,
              49.604283946566
            ],
            [
              5.3935393812988,
              49.617086785829
            ],
            [
              5.4269132757795,
              49.597344909551
            ],
            [
              5.4379714887151,
              49.569737286799
            ],
            [
              5.4535526093259,
              49.564921488307
            ],
            [
              5.4415639023972,
              49.552225052944
            ],
            [
              5.4650619164038,
              49.538862640494
            ],
            [
              5.4663281735759,
              49.528430189479
            ],
            [
              5.447578521257,
              49.517372874597
            ],
            [
              5.4647324749065,
              49.505819945807
            ],
            [
              5.4709047865596,
              49.497208726991
            ],
            [
              5.4824184576199,
              49.493663519084
            ],
            [
              5.4671925095702,
              49.476925743962
            ],
            [
              5.4644145982443,
              49.470301196738
            ],
            [
              5.4712052072361,
              49.460891938103
            ],
            [
              5.4617806899826,
              49.452686968431
            ],
            [
              5.4813250792623,
              49.444230871282
            ],
            [
              5.4711378140755,
              49.428494758719
            ],
            [
              5.4790716129114,
              49.425819978762
            ],
            [
              5.5004857413601,
              49.424540384288
            ],
            [
              5.5056637654641,
              49.419355999431
            ],
            [
              5.4941915581904,
              49.407161457028
            ],
            [
              5.4763732125581,
              49.403509568043
            ],
            [
              5.4731806585299,
              49.410749283903
            ],
            [
              5.4486679523763,
              49.401649163761
            ],
            [
              5.4635049812044,
              49.39647290549
            ],
            [
              5.4745327306186,
              49.397525657215
            ],
            [
              5.473993913648,
              49.388944897178
            ],
            [
              5.4803245524719,
              49.382342078555
            ],
            [
              5.494008073344,
              49.390335958227
            ],
            [
              5.5011451781038,
              49.408543065874
            ],
            [
              5.5291033545043,
              49.407372744181
            ],
            [
              5.5548450982669,
              49.419300559145
            ],
            [
              5.5703578501466,
              49.423371359284
            ],
            [
              5.5894271257641,
              49.419594210679
            ],
            [
              5.5988818064063,
              49.423734244404
            ],
            [
              5.6057310949754,
              49.420033783651
            ],
            [
              5.6164257051012,
              49.431453523025
            ],
            [
              5.6345659385699,
              49.437435167221
            ],
            [
              5.6462557146746,
              49.4308589046
            ],
            [
              5.6750941710188,
              49.421510379665
            ],
            [
              5.6881476509551,
              49.415106452884
            ],
            [
              5.6922657101867,
              49.398717675598
            ],
            [
              5.6873585119582,
              49.393882117734
            ],
            [
              5.707046906808,
              49.384304406361
            ],
            [
              5.7120154235208,
              49.375470790236
            ],
            [
              5.7126486382194,
              49.363529657584
            ],
            [
              5.7317020867514,
              49.361033630233
            ],
            [
              5.7383532097012,
              49.352038191977
            ],
            [
              5.7364841101137,
              49.338481141248
            ],
            [
              5.7215151564884,
              49.331720093152
            ],
            [
              5.7214060430502,
              49.324564797482
            ],
            [
              5.7317845374066,
              49.311341425415
            ],
            [
              5.7480554425091,
              49.30969399658
            ],
            [
              5.7625541109014,
              49.316331285314
            ],
            [
              5.7682837886189,
              49.299774662553
            ],
            [
              5.7605243714721,
              49.280113175962
            ],
            [
              5.7476673712908,
              49.27734118484
            ],
            [
              5.7307030579638,
              49.26979101327
            ],
            [
              5.7275416658093,
              49.257326151379
            ],
            [
              5.7209344315189,
              49.247702217971
            ],
            [
              5.7396882874913,
              49.230544433819
            ],
            [
              5.7287202865672,
              49.224974142792
            ],
            [
              5.7190401384103,
              49.215984343847
            ],
            [
              5.7207710048867,
              49.209316499461
            ],
            [
              5.7328471971944,
              49.195144019762
            ],
            [
              5.759610377382,
              49.201595438988
            ],
            [
              5.7655387967261,
              49.19808965245
            ],
            [
              5.7523664479466,
              49.169587511064
            ],
            [
              5.7444704661452,
              49.156592214671
            ],
            [
              5.7598050647708,
              49.150056653954
            ],
            [
              5.7559778074416,
              49.142386932173
            ],
            [
              5.7586163242507,
              49.13379436445
            ],
            [
              5.7485084008696,
              49.125097780709
            ],
            [
              5.7514861304145,
              49.118034072539
            ],
            [
              5.763355475447,
              49.107883685671
            ],
            [
              5.77129837631,
              49.1060985541
            ],
            [
              5.787536478922,
              49.11190925136
            ],
            [
              5.8178531085916,
              49.111039703115
            ],
            [
              5.8186397920456,
              49.086174532975
            ],
            [
              5.808384531367,
              49.07581346302
            ],
            [
              5.8069799653918,
              49.062668225081
            ],
            [
              5.8359263040642,
              49.066061327622
            ],
            [
              5.8339740565779,
              49.061281270018
            ],
            [
              5.8405856637237,
              49.049806402229
            ],
            [
              5.8541770017029,
              49.042417479249
            ],
            [
              5.8512804641196,
              49.038747826624
            ],
            [
              5.8329572889288,
              49.032939140591
            ],
            [
              5.823066886852,
              49.023109313982
            ],
            [
              5.8152285781504,
              48.996792653954
            ],
            [
              5.8048442530907,
              48.983422282743
            ],
            [
              5.8314671206296,
              48.975953669332
            ],
            [
              5.8341235480692,
              48.969974695101
            ],
            [
              5.8491607138617,
              48.961230178453
            ],
            [
              5.847786801066,
              48.953264300995
            ],
            [
              5.8244645880921,
              48.943442178987
            ],
            [
              5.8170917504069,
              48.94245227407
            ],
            [
              5.8002412939446,
              48.946747950612
            ],
            [
              5.7884138282863,
              48.938958746993
            ],
            [
              5.7834460663889,
              48.928343945749
            ],
            [
              5.7722355093631,
              48.923368769939
            ],
            [
              5.7548704959194,
              48.923130556578
            ],
            [
              5.7471576051222,
              48.915256169028
            ],
            [
              5.756763722368,
              48.909025501035
            ],
            [
              5.7658511937734,
              48.898087211088
            ],
            [
              5.7910638167022,
              48.886617414305
            ],
            [
              5.7865879005287,
              48.878200931864
            ],
            [
              5.7651257923273,
              48.872901896096
            ],
            [
              5.7693983140107,
              48.859591245064
            ],
            [
              5.777382891632,
              48.854799655253
            ],
            [
              5.7764540952381,
              48.845996974224
            ],
            [
              5.7809715229168,
              48.826744937358
            ],
            [
              5.7688497312157,
              48.804380155142
            ],
            [
              5.7582107493249,
              48.801231737647
            ],
            [
              5.7624107009942,
              48.793904761305
            ],
            [
              5.7760724568851,
              48.799304400849
            ],
            [
              5.7764535835103,
              48.790063196124
            ],
            [
              5.7677316301665,
              48.790144107477
            ],
            [
              5.7402526900702,
              48.772653171357
            ],
            [
              5.7345765159204,
              48.762470074618
            ],
            [
              5.7454508518575,
              48.7591715078
            ],
            [
              5.7418899317187,
              48.752796251199
            ],
            [
              5.7225320793816,
              48.75054311343
            ],
            [
              5.7140322252736,
              48.74453250271
            ],
            [
              5.7190304851466,
              48.732910201575
            ],
            [
              5.7433964151471,
              48.719132845609
            ],
            [
              5.7532860425682,
              48.707629127448
            ],
            [
              5.7645998533316,
              48.701077345045
            ],
            [
              5.7577079817005,
              48.695698365644
            ],
            [
              5.7449546309017,
              48.671376744383
            ],
            [
              5.7489234552466,
              48.666845498402
            ],
            [
              5.7453411751335,
              48.652188473858
            ],
            [
              5.7587943125677,
              48.633333318632
            ],
            [
              5.7589073121033,
              48.623064755659
            ],
            [
              5.7650555278122,
              48.618738666337
            ],
            [
              5.7928425418669,
              48.628881800661
            ],
            [
              5.8034464649874,
              48.625044943187
            ],
            [
              5.7978629491703,
              48.614140527407
            ],
            [
              5.7899108688634,
              48.607183289583
            ],
            [
              5.7617668080948,
              48.609385983796
            ],
            [
              5.7508939363362,
              48.60435531676
            ],
            [
              5.7449446353949,
              48.59760810301
            ],
            [
              5.7186586267961,
              48.590336037196
            ],
            [
              5.7133909280655,
              48.580633914406
            ],
            [
              5.7160927170236,
              48.561427704017
            ],
            [
              5.7289813936323,
              48.553482766838
            ],
            [
              5.7487980070214,
              48.550794013931
            ],
            [
              5.7762186102909,
              48.540358194465
            ],
            [
              5.7658870483069,
              48.533064172251
            ],
            [
              5.7609662294293,
              48.52146440042
            ],
            [
              5.7651504539389,
              48.49648910684
            ],
            [
              5.7553353805387,
              48.491743938643
            ],
            [
              5.7385802629757,
              48.466074051844
            ],
            [
              5.7132282239238,
              48.460339963131
            ],
            [
              5.6944133336967,
              48.463688687607
            ],
            [
              5.6790220293959,
              48.471386688917
            ],
            [
              5.6687063748658,
              48.471957503272
            ],
            [
              5.6429950335545,
              48.468547039375
            ],
            [
              5.651580017191,
              48.457519861905
            ],
            [
              5.6303138674697,
              48.451078379337
            ],
            [
              5.6159691492695,
              48.439744295634
            ],
            [
              5.609413716223,
              48.442255097997
            ],
            [
              5.5907915774722,
              48.442394094149
            ],
            [
              5.5802627009573,
              48.438796271493
            ],
            [
              5.5766485772267,
              48.43196733573
            ],
            [
              5.5677816804592,
              48.43289411391
            ],
            [
              5.5646513919581,
              48.44108713367
            ],
            [
              5.5505100471078,
              48.439825818053
            ],
            [
              5.5457010951856,
              48.435503302948
            ],
            [
              5.5202802354993,
              48.4323944293
            ],
            [
              5.514747882228,
              48.429448820319
            ],
            [
              5.5109905177343,
              48.418212368928
            ],
            [
              5.5138988120989,
              48.410801373756
            ],
            [
              5.5013062188735,
              48.410687855212
            ],
            [
              5.4700548229834,
              48.420926283443
            ],
            [
              5.445940857304,
              48.424569152476
            ],
            [
              5.4396656658458,
              48.43454967262
            ],
            [
              5.425033919482,
              48.436346493291
            ],
            [
              5.4084984885085,
              48.445792781768
            ],
            [
              5.403697425014,
              48.453393807796
            ],
            [
              5.406292964183,
              48.464887796388
            ],
            [
              5.4008849824238,
              48.470480292577
            ],
            [
              5.3760194684462,
              48.472138911611
            ],
            [
              5.3679128362749,
              48.476989959693
            ],
            [
              5.349473975465,
              48.481986800328
            ],
            [
              5.3441897585249,
              48.491037218931
            ],
            [
              5.3307631034545,
              48.501876323935
            ],
            [
              5.3285067550225,
              48.507151257772
            ],
            [
              5.3142188294347,
              48.510272559977
            ],
            [
              5.3056222693629,
              48.515916893633
            ],
            [
              5.2962066847075,
              48.508405436941
            ],
            [
              5.2584002030652,
              48.521467536863
            ],
            [
              5.2321658494928,
              48.528593921213
            ],
            [
              5.2122544924731,
              48.528791624388
            ],
            [
              5.2084203909164,
              48.538609056785
            ],
            [
              5.1964658539815,
              48.538008021362
            ],
            [
              5.1968197773229,
              48.546680209836
            ],
            [
              5.1773515414927,
              48.554640660839
            ],
            [
              5.1650732858833,
              48.565344025751
            ],
            [
              5.1456350510637,
              48.562002271713
            ],
            [
              5.1343988410875,
              48.570822530063
            ],
            [
              5.1253365040036,
              48.582072896761
            ],
            [
              5.1178988434688,
              48.584955479137
            ],
            [
              5.1183529904094,
              48.595513437496
            ],
            [
              5.0956903941282,
              48.5928776962
            ],
            [
              5.0708911117702,
              48.595728770674
            ],
            [
              5.0595301156357,
              48.616290659601
            ],
            [
              5.057748743685,
              48.629697103631
            ],
            [
              5.0496885465717,
              48.631263178487
            ],
            [
              5.032285807066,
              48.613298149197
            ],
            [
              5.0141406619653,
              48.610210642074
            ],
            [
              4.9993220353425,
              48.614834346517
            ],
            [
              4.9946733915383,
              48.621828125073
            ],
            [
              5.0059214536855,
              48.634032872105
            ],
            [
              4.9973040687938,
              48.645702938649
            ],
            [
              4.9928144614844,
              48.658268690283
            ],
            [
              5.0107948326909,
              48.66815377214
            ],
            [
              5.009009914557,
              48.678478877095
            ],
            [
              5.00253386288,
              48.682649301611
            ],
            [
              4.9884305305447,
              48.684422113631
            ],
            [
              5.0064063680002,
              48.699074659527
            ],
            [
              4.9972572781159,
              48.709595801806
            ],
            [
              5.0138967480706,
              48.710777200681
            ],
            [
              5.0073174936125,
              48.718017504748
            ],
            [
              5.0092185303043,
              48.741388830169
            ],
            [
              4.99012310123,
              48.741834690164
            ],
            [
              4.9820099984545,
              48.745127607624
            ],
            [
              4.9781495668786,
              48.75318087664
            ],
            [
              4.949699806049,
              48.769988673569
            ],
            [
              4.9379720566238,
              48.782494039612
            ],
            [
              4.9345688695301,
              48.790356335664
            ],
            [
              4.9022122643085,
              48.795455877483
            ],
            [
              4.8885820531146,
              48.80061371657
            ],
            [
              4.896834961025,
              48.808710054979
            ],
            [
              4.8887582394372,
              48.817210656662
            ],
            [
              4.9175085829673,
              48.835656479322
            ],
            [
              4.9356977655096,
              48.84062704651
            ],
            [
              4.9374937134933,
              48.847656242069
            ],
            [
              4.9280074084913,
              48.850029718286
            ],
            [
              4.9235108698405,
              48.861781460145
            ],
            [
              4.9130004169396,
              48.872143960831
            ],
            [
              4.9247005594818,
              48.891358244298
            ],
            [
              4.9157932199583,
              48.899796792981
            ],
            [
              4.9306078351393,
              48.91156065039
            ],
            [
              4.9362133507501,
              48.922138732999
            ],
            [
              4.9500350999827,
              48.930504233541
            ],
            [
              4.9583091196936,
              48.92707697787
            ],
            [
              4.9864012071849,
              48.931900243092
            ],
            [
              5.0002863602302,
              48.938258416319
            ],
            [
              5.0125842536926,
              48.936930774568
            ],
            [
              5.0152565779293,
              48.946097194214
            ],
            [
              5.0221528907788,
              48.947843989911
            ],
            [
              5.0301259805396,
              48.956903400655
            ],
            [
              5.0379027924329,
              48.97301158464
            ],
            [
              5.0277871563726,
              48.979273703253
            ],
            [
              5.0185199565787,
              48.977850483841
            ],
            [
              5.0099740972008,
              48.981850212238
            ],
            [
              4.9953582630028,
              48.982598875386
            ],
            [
              5.0035249328583,
              48.990778639869
            ],
            [
              5.0138368996792,
              48.995057601112
            ],
            [
              5.0326726595298,
              48.993738798801
            ],
            [
              5.0314289970476,
              49.006049726631
            ],
            [
              5.037405311049,
              49.013961987093
            ],
            [
              5.0363201841563,
              49.024335269381
            ],
            [
              5.0168141501855,
              49.03311869177
            ],
            [
              5.0076786336645,
              49.034951097618
            ],
            [
              4.9879031118833,
              49.027173298772
            ],
            [
              4.9686781571867,
              49.024739554079
            ],
            [
              4.9989434268935,
              49.049325301102
            ],
            [
              5.0059210382911,
              49.059419471656
            ],
            [
              4.9987054204657,
              49.076825011639
            ],
            [
              4.9964435329322,
              49.088167396547
            ],
            [
              4.9971847406275,
              49.104388466914
            ],
            [
              4.9921245963475,
              49.11490357014
            ],
            [
              4.9744041550883,
              49.133401842133
            ],
            [
              4.9641467046697,
              49.155815258864
            ],
            [
              4.9518030048523,
              49.17410342245
            ],
            [
              4.9397607283221,
              49.185070922974
            ],
            [
              4.9612735061957,
              49.197357841175
            ],
            [
              4.9800989235827,
              49.201778447188
            ],
            [
              4.9933759888652,
              49.209623589511
            ],
            [
              4.9780197007677,
              49.218934349355
            ],
            [
              4.9707577303293,
              49.236057819576
            ],
            [
              4.9509906730069,
              49.236867621535
            ]
          ],
          [
            [
              5.4555586843759,
              49.480931549678
            ],
            [
              5.4644956014283,
              49.490309403279
            ],
            [
              5.4420070555476,
              49.503619271582
            ],
            [
              5.429907860027,
              49.48819079003
            ],
            [
              5.449526774142,
              49.484736738817
            ],
            [
              5.4555586843759,
              49.480931549678
            ]
          ]
        ]
      },
      "properties": {
        "code": "55",
        "nom": "Meuse",
        "population": 183001,
        "delitspour1000": "4,836",
        "nombremosque": 19,
        "parentsEtrangers": "89,2",
        "benefRsa": 8882,
        "eoliennes": 244
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -0.84094105476412,
              48.752223582274
            ],
            [
              -0.81863751879145,
              48.754801587094
            ],
            [
              -0.80083378227683,
              48.765352280557
            ],
            [
              -0.79437873595195,
              48.776865247492
            ],
            [
              -0.78062135391124,
              48.786653395386
            ],
            [
              -0.7642699948279,
              48.787729137391
            ],
            [
              -0.74000486268258,
              48.792883672969
            ],
            [
              -0.72328764272428,
              48.799269249018
            ],
            [
              -0.71468547539624,
              48.799868429628
            ],
            [
              -0.70569111434171,
              48.808679576837
            ],
            [
              -0.68216886558739,
              48.821835101496
            ],
            [
              -0.69644558592608,
              48.830231973246
            ],
            [
              -0.70533295842158,
              48.838459480586
            ],
            [
              -0.69808599922195,
              48.843600554779
            ],
            [
              -0.65724264527302,
              48.839050095879
            ],
            [
              -0.64321988620605,
              48.840678388106
            ],
            [
              -0.64541589959203,
              48.827214099453
            ],
            [
              -0.62644490688407,
              48.826806344567
            ],
            [
              -0.60542466758523,
              48.834234951977
            ],
            [
              -0.58076109720042,
              48.838012034487
            ],
            [
              -0.57109680421828,
              48.830168192928
            ],
            [
              -0.54452262601951,
              48.847893677572
            ],
            [
              -0.51485562488587,
              48.850082898968
            ],
            [
              -0.50676720578069,
              48.845734692966
            ],
            [
              -0.49404523940415,
              48.848573731858
            ],
            [
              -0.49658169612073,
              48.85831632901
            ],
            [
              -0.47833860425553,
              48.858946444986
            ],
            [
              -0.46375829628735,
              48.87136811503
            ],
            [
              -0.45091393587934,
              48.869789573007
            ],
            [
              -0.43283305716342,
              48.874555864698
            ],
            [
              -0.42421989698548,
              48.867966028804
            ],
            [
              -0.41152165194749,
              48.870143563618
            ],
            [
              -0.40268285580141,
              48.859713233718
            ],
            [
              -0.38620858191094,
              48.845261692646
            ],
            [
              -0.36104176431882,
              48.842345104073
            ],
            [
              -0.3692517151007,
              48.834820966697
            ],
            [
              -0.34624167639838,
              48.821996521333
            ],
            [
              -0.32739870471583,
              48.839083419227
            ],
            [
              -0.33573513698092,
              48.844606574919
            ],
            [
              -0.3285658327644,
              48.857284809069
            ],
            [
              -0.3059276101914,
              48.858302472146
            ],
            [
              -0.2991201076076,
              48.849595577636
            ],
            [
              -0.27739831098258,
              48.853877904894
            ],
            [
              -0.25731737381123,
              48.84933562977
            ],
            [
              -0.24245301992163,
              48.849361569995
            ],
            [
              -0.21488514798809,
              48.840711804879
            ],
            [
              -0.20440370993563,
              48.846439520008
            ],
            [
              -0.19285228241601,
              48.839222155052
            ],
            [
              -0.17672674490573,
              48.834230614364
            ],
            [
              -0.17011128627476,
              48.824748495738
            ],
            [
              -0.15176886176769,
              48.820241817199
            ],
            [
              -0.13795704824172,
              48.819284867311
            ],
            [
              -0.14634831603259,
              48.834445361701
            ],
            [
              -0.13116138889035,
              48.836075357038
            ],
            [
              -0.12302979454988,
              48.83989361083
            ],
            [
              -0.085065363659073,
              48.84465210024
            ],
            [
              -0.07542259650144,
              48.840552525635
            ],
            [
              -0.064951500120938,
              48.843876035026
            ],
            [
              -0.058807358031453,
              48.853585090542
            ],
            [
              -0.038037636202188,
              48.863918022503
            ],
            [
              -0.030670007144589,
              48.873875022828
            ],
            [
              -0.011506033170249,
              48.876767201176
            ],
            [
              -0.0098551045630034,
              48.883799593808
            ],
            [
              0.029400966736207,
              48.896209575661
            ],
            [
              0.050262531991726,
              48.899327115259
            ],
            [
              0.056397941661387,
              48.902464937324
            ],
            [
              0.070946248746482,
              48.932123979564
            ],
            [
              0.08072156246746,
              48.937982294807
            ],
            [
              0.094885684485896,
              48.935795271881
            ],
            [
              0.12760483049428,
              48.952219904035
            ],
            [
              0.1365871992517,
              48.949981008586
            ],
            [
              0.14545992515113,
              48.942210249006
            ],
            [
              0.14795902541451,
              48.92895028625
            ],
            [
              0.15647058216308,
              48.924791081169
            ],
            [
              0.17973679234316,
              48.930608802457
            ],
            [
              0.18731022751836,
              48.941075480981
            ],
            [
              0.21060672919486,
              48.945376802287
            ],
            [
              0.22796851349044,
              48.940344875299
            ],
            [
              0.24219788957427,
              48.944479382252
            ],
            [
              0.24466378024092,
              48.95878068996
            ],
            [
              0.26305866812457,
              48.964738440602
            ],
            [
              0.27563343073182,
              48.964740550229
            ],
            [
              0.28263483305723,
              48.948994209987
            ],
            [
              0.30948162829212,
              48.953876529082
            ],
            [
              0.32155224282505,
              48.94608052606
            ],
            [
              0.33376875208518,
              48.942878978029
            ],
            [
              0.35810990456247,
              48.950503640503
            ],
            [
              0.36842611384642,
              48.95917916006
            ],
            [
              0.37731127535648,
              48.972557592954
            ],
            [
              0.39159849875493,
              48.966458764864
            ],
            [
              0.40616729538503,
              48.963328041914
            ],
            [
              0.40015949515572,
              48.952749393468
            ],
            [
              0.4128104390395,
              48.950626279328
            ],
            [
              0.40843769354036,
              48.943444622419
            ],
            [
              0.390777135105,
              48.927129545247
            ],
            [
              0.38618253122967,
              48.910414357049
            ],
            [
              0.39679657312957,
              48.907778004029
            ],
            [
              0.41770661476048,
              48.897680118218
            ],
            [
              0.42006471195552,
              48.888184382023
            ],
            [
              0.43330826845583,
              48.886157583779
            ],
            [
              0.44311076213435,
              48.88128008313
            ],
            [
              0.45735531457632,
              48.879761525082
            ],
            [
              0.45936577011338,
              48.887848115553
            ],
            [
              0.47611395078483,
              48.886860891837
            ],
            [
              0.48961055807985,
              48.879924191983
            ],
            [
              0.50214522955794,
              48.878149419079
            ],
            [
              0.5249763956739,
              48.880449344675
            ],
            [
              0.54323381288612,
              48.875520650963
            ],
            [
              0.5525004519299,
              48.875534180346
            ],
            [
              0.56705847132496,
              48.887859486472
            ],
            [
              0.57803949069757,
              48.893649615021
            ],
            [
              0.59717255366436,
              48.884985872618
            ],
            [
              0.5865922049446,
              48.881406259078
            ],
            [
              0.58962884714758,
              48.872596179204
            ],
            [
              0.60439903682452,
              48.875113286031
            ],
            [
              0.60400928665889,
              48.864760454015
            ],
            [
              0.61096181241547,
              48.853220075098
            ],
            [
              0.62935408701068,
              48.85358418848
            ],
            [
              0.61687547597895,
              48.843731542228
            ],
            [
              0.61028848587188,
              48.831311343399
            ],
            [
              0.62821327737912,
              48.825367303778
            ],
            [
              0.64581875070678,
              48.816142840762
            ],
            [
              0.65632888501885,
              48.813559248276
            ],
            [
              0.69048615114254,
              48.793683771174
            ],
            [
              0.70123022325656,
              48.79212385317
            ],
            [
              0.73350204762147,
              48.783366854626
            ],
            [
              0.74018708351363,
              48.776579822882
            ],
            [
              0.75843020314215,
              48.767968371666
            ],
            [
              0.75091726492355,
              48.76179962791
            ],
            [
              0.77495753063022,
              48.737530835487
            ],
            [
              0.76276071401677,
              48.729629501028
            ],
            [
              0.75349996622665,
              48.719599220802
            ],
            [
              0.74339168877981,
              48.716901616421
            ],
            [
              0.73058883988464,
              48.705203303323
            ],
            [
              0.75269475803882,
              48.704115602005
            ],
            [
              0.76605034714122,
              48.696795557534
            ],
            [
              0.76268935054531,
              48.686942596812
            ],
            [
              0.76408006596892,
              48.675272833699
            ],
            [
              0.78220679339237,
              48.666521479531
            ],
            [
              0.79538719721568,
              48.675207207316
            ],
            [
              0.8148247207739,
              48.670163564137
            ],
            [
              0.81294886563427,
              48.660510127486
            ],
            [
              0.82392074530028,
              48.650041826767
            ],
            [
              0.8226824397504,
              48.640053608418
            ],
            [
              0.83140562696703,
              48.633774446005
            ],
            [
              0.81775390390063,
              48.616320066598
            ],
            [
              0.81963618816719,
              48.609871113214
            ],
            [
              0.83304661425976,
              48.61077016626
            ],
            [
              0.8465873876382,
              48.604782622076
            ],
            [
              0.85063536439017,
              48.582625709205
            ],
            [
              0.86767573670944,
              48.573488250275
            ],
            [
              0.89126612131175,
              48.572177428167
            ],
            [
              0.92638981194521,
              48.559394233279
            ],
            [
              0.93876307137649,
              48.550558547099
            ],
            [
              0.922603461355,
              48.537716017396
            ],
            [
              0.95341814083771,
              48.526244433476
            ],
            [
              0.96655146260943,
              48.522741687979
            ],
            [
              0.9540037271624,
              48.503840229688
            ],
            [
              0.94155894503993,
              48.498694713572
            ],
            [
              0.95436935096035,
              48.490573966149
            ],
            [
              0.95629262450835,
              48.482279378048
            ],
            [
              0.93571270291242,
              48.475553807957
            ],
            [
              0.94226766472979,
              48.457733974776
            ],
            [
              0.95840880937073,
              48.44259696324
            ],
            [
              0.9762713097259,
              48.439081867809
            ],
            [
              0.9482686361643,
              48.417831978233
            ],
            [
              0.94224603424783,
              48.399004844657
            ],
            [
              0.93117784919562,
              48.391265934635
            ],
            [
              0.9149193773197,
              48.374324097325
            ],
            [
              0.90184278255778,
              48.373449772268
            ],
            [
              0.88337252111541,
              48.356669121421
            ],
            [
              0.87015714346989,
              48.35834027017
            ],
            [
              0.86181253481946,
              48.350897961325
            ],
            [
              0.84159508492141,
              48.350229728169
            ],
            [
              0.82785590745519,
              48.342447024282
            ],
            [
              0.81840111266411,
              48.349420439199
            ],
            [
              0.78542500970172,
              48.340430711536
            ],
            [
              0.78418514184216,
              48.334478837721
            ],
            [
              0.77313762601396,
              48.325511221527
            ],
            [
              0.76219430247254,
              48.306876639515
            ],
            [
              0.76023175104941,
              48.298171659555
            ],
            [
              0.77758221552843,
              48.302840856139
            ],
            [
              0.79341825616719,
              48.293008635712
            ],
            [
              0.79426147145466,
              48.284950588985
            ],
            [
              0.78587084890053,
              48.271138465423
            ],
            [
              0.78758977714986,
              48.261313612339
            ],
            [
              0.80241465108887,
              48.248072322927
            ],
            [
              0.80608653469826,
              48.239891017509
            ],
            [
              0.82588953248827,
              48.220721830264
            ],
            [
              0.83048365574563,
              48.210708729507
            ],
            [
              0.80626900151784,
              48.203016266265
            ],
            [
              0.79765841643139,
              48.19445496608
            ],
            [
              0.76407918474979,
              48.181599665308
            ],
            [
              0.75566254080812,
              48.181981836049
            ],
            [
              0.73782963836386,
              48.189069627691
            ],
            [
              0.73014979083029,
              48.200521766169
            ],
            [
              0.72363045806996,
              48.19813955141
            ],
            [
              0.7165758658899,
              48.212094515686
            ],
            [
              0.68321966914477,
              48.2485882228
            ],
            [
              0.67547108490982,
              48.254740726504
            ],
            [
              0.65315095543918,
              48.263702677988
            ],
            [
              0.64070530481206,
              48.261221689671
            ],
            [
              0.63190429889268,
              48.254754506701
            ],
            [
              0.63315984470535,
              48.245553870078
            ],
            [
              0.57919146406902,
              48.24436440048
            ],
            [
              0.56099429972633,
              48.245949063769
            ],
            [
              0.55013843367508,
              48.249395520335
            ],
            [
              0.53597049486367,
              48.249844560134
            ],
            [
              0.53848634108307,
              48.256987820339
            ],
            [
              0.5303000454288,
              48.265496730429
            ],
            [
              0.51293004449183,
              48.266874483366
            ],
            [
              0.49455647283795,
              48.28681567575
            ],
            [
              0.50702990447062,
              48.295832610533
            ],
            [
              0.48757654033176,
              48.307795859172
            ],
            [
              0.48050090924161,
              48.298592258919
            ],
            [
              0.46361198113469,
              48.305016107657
            ],
            [
              0.44279870606142,
              48.304629310064
            ],
            [
              0.43133408375288,
              48.306638667446
            ],
            [
              0.42688901989368,
              48.315425242308
            ],
            [
              0.41599578567133,
              48.321625198799
            ],
            [
              0.4062203560807,
              48.314621149851
            ],
            [
              0.3954029644226,
              48.320549965535
            ],
            [
              0.38260989677575,
              48.333828412247
            ],
            [
              0.38047838823167,
              48.341797530871
            ],
            [
              0.38828549911199,
              48.349122009475
            ],
            [
              0.38255236381737,
              48.359498801305
            ],
            [
              0.37865893857906,
              48.383227765197
            ],
            [
              0.37386118731698,
              48.386969757082
            ],
            [
              0.37537215739256,
              48.395740224588
            ],
            [
              0.37172370405574,
              48.410451667862
            ],
            [
              0.38150787714381,
              48.417547978808
            ],
            [
              0.38066015166482,
              48.425411796164
            ],
            [
              0.36771793204951,
              48.438272682172
            ],
            [
              0.36395632992898,
              48.451631721658
            ],
            [
              0.35578465090337,
              48.458217063582
            ],
            [
              0.33874148490671,
              48.461599536909
            ],
            [
              0.32727632603609,
              48.471072305832
            ],
            [
              0.31789727296834,
              48.471938210212
            ],
            [
              0.29585588821524,
              48.480174860693
            ],
            [
              0.27593434536664,
              48.479055127239
            ],
            [
              0.26286221529839,
              48.482954540393
            ],
            [
              0.25857807031538,
              48.476710383834
            ],
            [
              0.22939338887665,
              48.472578001816
            ],
            [
              0.21823241324651,
              48.473790546424
            ],
            [
              0.18981304225187,
              48.461891344828
            ],
            [
              0.18125494041244,
              48.464965078387
            ],
            [
              0.16972375424173,
              48.461776714307
            ],
            [
              0.16965670000587,
              48.449364042801
            ],
            [
              0.15610127663516,
              48.454794932825
            ],
            [
              0.15811789380158,
              48.4440164218
            ],
            [
              0.15131906064063,
              48.437226845695
            ],
            [
              0.11624768586472,
              48.435555660302
            ],
            [
              0.09917046478078,
              48.41034986941
            ],
            [
              0.083580132468228,
              48.411137710408
            ],
            [
              0.067826622948661,
              48.406115400716
            ],
            [
              0.056684859958794,
              48.393974400454
            ],
            [
              0.062489872264419,
              48.382213868151
            ],
            [
              0.020992722816365,
              48.380200925309
            ],
            [
              0.0065863250934992,
              48.388521385965
            ],
            [
              -0.0025641291874286,
              48.397311951396
            ],
            [
              -0.020363541559243,
              48.393656323667
            ],
            [
              -0.022054721991416,
              48.388059994444
            ],
            [
              -0.035753099663116,
              48.384874683334
            ],
            [
              -0.050692623162423,
              48.375201195715
            ],
            [
              -0.054527208218665,
              48.382004461206
            ],
            [
              -0.052691042298523,
              48.392979240085
            ],
            [
              -0.05669039654505,
              48.398915618731
            ],
            [
              -0.053012801512314,
              48.412716132961
            ],
            [
              -0.057355677768335,
              48.42850299559
            ],
            [
              -0.049909790963035,
              48.447628170085
            ],
            [
              -0.051890589271637,
              48.453255414135
            ],
            [
              -0.072707535476378,
              48.456927943118
            ],
            [
              -0.073006901765828,
              48.450527118516
            ],
            [
              -0.10641182796282,
              48.447519773766
            ],
            [
              -0.12454179598033,
              48.449239552758
            ],
            [
              -0.14871763477939,
              48.458069224847
            ],
            [
              -0.15336586853021,
              48.476724917968
            ],
            [
              -0.14958860992305,
              48.479781866844
            ],
            [
              -0.15856042497356,
              48.496817021301
            ],
            [
              -0.1720909995602,
              48.502134649047
            ],
            [
              -0.16634047065468,
              48.51558387484
            ],
            [
              -0.15568415360731,
              48.520496772763
            ],
            [
              -0.14501210976342,
              48.521000343601
            ],
            [
              -0.14460271813847,
              48.527754338423
            ],
            [
              -0.16937878261294,
              48.536973156988
            ],
            [
              -0.1899589987284,
              48.548884398226
            ],
            [
              -0.19398633186269,
              48.554824404734
            ],
            [
              -0.20694246592994,
              48.562946447212
            ],
            [
              -0.22107424204732,
              48.560317301834
            ],
            [
              -0.23435242732491,
              48.562336320193
            ],
            [
              -0.24264015511442,
              48.567994064435
            ],
            [
              -0.26180425334438,
              48.54789519181
            ],
            [
              -0.24635660161985,
              48.542620878313
            ],
            [
              -0.24176789393774,
              48.536388956089
            ],
            [
              -0.25395512234333,
              48.525985631213
            ],
            [
              -0.26580141522268,
              48.522782191648
            ],
            [
              -0.27155353070113,
              48.507447568195
            ],
            [
              -0.27823048075492,
              48.506986142113
            ],
            [
              -0.30280889062491,
              48.517340998248
            ],
            [
              -0.32023332020671,
              48.522923755915
            ],
            [
              -0.34337759223335,
              48.500849863584
            ],
            [
              -0.35582138041634,
              48.495673571366
            ],
            [
              -0.35349558279054,
              48.483897081468
            ],
            [
              -0.36723974711241,
              48.487748985001
            ],
            [
              -0.3676233888649,
              48.492944315138
            ],
            [
              -0.39345664138171,
              48.501835194106
            ],
            [
              -0.3991855056689,
              48.510158714286
            ],
            [
              -0.41273449116925,
              48.506498004612
            ],
            [
              -0.42497181740742,
              48.507282953736
            ],
            [
              -0.43075652123879,
              48.51181625112
            ],
            [
              -0.46226332328429,
              48.512709151203
            ],
            [
              -0.47060334996255,
              48.509716651644
            ],
            [
              -0.47820460128897,
              48.501565713987
            ],
            [
              -0.4884950789804,
              48.501617721865
            ],
            [
              -0.50506155077299,
              48.505798828433
            ],
            [
              -0.5304424347088,
              48.495164970256
            ],
            [
              -0.54510290980273,
              48.482691035747
            ],
            [
              -0.55171796610154,
              48.473119783464
            ],
            [
              -0.57152013702789,
              48.469152972446
            ],
            [
              -0.59533674901115,
              48.472630277201
            ],
            [
              -0.6175695455761,
              48.458960402325
            ],
            [
              -0.6540003356242,
              48.444278312957
            ],
            [
              -0.65363074848858,
              48.459545681615
            ],
            [
              -0.66371844747552,
              48.484471551488
            ],
            [
              -0.66895705284659,
              48.486137900646
            ],
            [
              -0.68585273590642,
              48.475468439014
            ],
            [
              -0.68799234091834,
              48.469431032111
            ],
            [
              -0.70206934477695,
              48.467207668633
            ],
            [
              -0.71121688547931,
              48.470742279355
            ],
            [
              -0.73034187148783,
              48.472703026439
            ],
            [
              -0.73585755427354,
              48.461124917636
            ],
            [
              -0.7197695922356,
              48.454578580623
            ],
            [
              -0.71509947351088,
              48.448950147648
            ],
            [
              -0.73527798039621,
              48.445048872201
            ],
            [
              -0.75727715268855,
              48.436552496914
            ],
            [
              -0.77453904965747,
              48.44327891903
            ],
            [
              -0.7785859196383,
              48.453255439492
            ],
            [
              -0.77787285065978,
              48.465413522216
            ],
            [
              -0.79756269441243,
              48.465280274577
            ],
            [
              -0.79918376742683,
              48.458939062295
            ],
            [
              -0.81322359143068,
              48.455083144137
            ],
            [
              -0.8184585148882,
              48.474291742186
            ],
            [
              -0.82728981063172,
              48.476292220178
            ],
            [
              -0.83778925434372,
              48.485178672867
            ],
            [
              -0.84610706675308,
              48.498284307396
            ],
            [
              -0.86036021134895,
              48.501458584456
            ],
            [
              -0.84766933110894,
              48.52112650275
            ],
            [
              -0.82695764917946,
              48.532235760061
            ],
            [
              -0.80570967878668,
              48.549877678589
            ],
            [
              -0.78883096952577,
              48.553140378287
            ],
            [
              -0.77416870927632,
              48.562676617257
            ],
            [
              -0.77410365336681,
              48.575687422281
            ],
            [
              -0.76572444871462,
              48.592514292438
            ],
            [
              -0.75174820294264,
              48.601317444612
            ],
            [
              -0.75261576309569,
              48.6176193763
            ],
            [
              -0.74221354045865,
              48.627267255804
            ],
            [
              -0.76290626959504,
              48.631905426948
            ],
            [
              -0.77335752529712,
              48.65747247994
            ],
            [
              -0.75377161787902,
              48.674518089003
            ],
            [
              -0.73736197894019,
              48.679558842566
            ],
            [
              -0.73732101904671,
              48.686666601324
            ],
            [
              -0.76471221845477,
              48.693078451377
            ],
            [
              -0.77233757952528,
              48.698807486761
            ],
            [
              -0.79895320122087,
              48.710188025931
            ],
            [
              -0.80858726177931,
              48.725531855933
            ],
            [
              -0.81789729577365,
              48.731176402389
            ],
            [
              -0.8371029642378,
              48.728366490518
            ],
            [
              -0.84922855934838,
              48.730753766159
            ],
            [
              -0.85184627231567,
              48.746156709397
            ],
            [
              -0.84094105476412,
              48.752223582274
            ]
          ]
        ]
      },
      "properties": {
        "code": "61",
        "nom": "Orne",
        "population": 278475,
        "delitspour1000": "4,079",
        "nombremosque": 14,
        "parentsEtrangers": "85,7",
        "benefRsa": 13090,
        "eoliennes": 27
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.0677049871716,
              51.006501514321
            ],
            [
              2.0802392516851,
              51.006169093773
            ],
            [
              2.0975976295242,
              50.998430767335
            ],
            [
              2.12132145655,
              50.979602196075
            ],
            [
              2.1257142237163,
              50.968466052869
            ],
            [
              2.1353308750233,
              50.9642594986
            ],
            [
              2.1378094970506,
              50.951370068775
            ],
            [
              2.149731162093,
              50.940557944834
            ],
            [
              2.1514006960928,
              50.930635192021
            ],
            [
              2.1643359527502,
              50.91568908854
            ],
            [
              2.1705678194396,
              50.901136583485
            ],
            [
              2.183908800499,
              50.88345339411
            ],
            [
              2.202416332208,
              50.843383830402
            ],
            [
              2.2081216691324,
              50.83808638546
            ],
            [
              2.2134332677342,
              50.810547736996
            ],
            [
              2.2291945320425,
              50.806876941585
            ],
            [
              2.2524934267759,
              50.788116598458
            ],
            [
              2.2685135062806,
              50.7836778486
            ],
            [
              2.2879341077768,
              50.787014241693
            ],
            [
              2.2885798648188,
              50.777033183243
            ],
            [
              2.3122515475191,
              50.787201126855
            ],
            [
              2.3143201736514,
              50.782709198866
            ],
            [
              2.3360059042222,
              50.781401849549
            ],
            [
              2.365339332959,
              50.774994005899
            ],
            [
              2.3640699861255,
              50.780008610544
            ],
            [
              2.3759825201851,
              50.784049988105
            ],
            [
              2.3815803265761,
              50.778425196679
            ],
            [
              2.39943967738,
              50.774785379947
            ],
            [
              2.4114789545178,
              50.766734253763
            ],
            [
              2.3757116439919,
              50.752657506667
            ],
            [
              2.3637001788546,
              50.751426607185
            ],
            [
              2.340896635375,
              50.740909944404
            ],
            [
              2.3417443842668,
              50.728371653952
            ],
            [
              2.3510123214671,
              50.72493600472
            ],
            [
              2.3532149006029,
              50.718576368559
            ],
            [
              2.3646807333256,
              50.709688003623
            ],
            [
              2.3835589594468,
              50.700594961815
            ],
            [
              2.3635246352107,
              50.684644535221
            ],
            [
              2.3734967590549,
              50.672686259803
            ],
            [
              2.3831088136149,
              50.671830667341
            ],
            [
              2.403207137341,
              50.681661074344
            ],
            [
              2.4077967160894,
              50.672761136245
            ],
            [
              2.4216447837905,
              50.665648032069
            ],
            [
              2.4289823077906,
              50.657014753058
            ],
            [
              2.450393683766,
              50.653848317696
            ],
            [
              2.4731826335051,
              50.638594729172
            ],
            [
              2.4959475460851,
              50.631241346564
            ],
            [
              2.5178793853475,
              50.636196188234
            ],
            [
              2.5336259405944,
              50.643088503328
            ],
            [
              2.5370464089913,
              50.630452808867
            ],
            [
              2.5446909385658,
              50.627052828456
            ],
            [
              2.5646474035872,
              50.624520915665
            ],
            [
              2.5675793518958,
              50.63350383388
            ],
            [
              2.5771123247595,
              50.630515881201
            ],
            [
              2.5866136171855,
              50.63809533857
            ],
            [
              2.6014509843383,
              50.630982301962
            ],
            [
              2.6146029991412,
              50.63483165872
            ],
            [
              2.6187020079043,
              50.625387070701
            ],
            [
              2.6305174773984,
              50.617950612122
            ],
            [
              2.6432637984507,
              50.622538891146
            ],
            [
              2.6713252680731,
              50.624897679981
            ],
            [
              2.6796086872197,
              50.635881548523
            ],
            [
              2.6951714253322,
              50.628777147169
            ],
            [
              2.7138477071241,
              50.631287759925
            ],
            [
              2.7256457425012,
              50.614117517935
            ],
            [
              2.7389944409639,
              50.609312586306
            ],
            [
              2.7574019374071,
              50.618239253538
            ],
            [
              2.7525470598349,
              50.627871821503
            ],
            [
              2.7662368278023,
              50.633978473132
            ],
            [
              2.7836385564225,
              50.628974520703
            ],
            [
              2.792453026888,
              50.633660709096
            ],
            [
              2.7660140488084,
              50.649084330586
            ],
            [
              2.7606231977666,
              50.65566909504
            ],
            [
              2.7690451498338,
              50.663677249792
            ],
            [
              2.7843303812521,
              50.665336178523
            ],
            [
              2.7944016461523,
              50.662281487826
            ],
            [
              2.8091936432834,
              50.66937021299
            ],
            [
              2.8176900288707,
              50.663542784253
            ],
            [
              2.8404536962986,
              50.656563664654
            ],
            [
              2.8697307651834,
              50.631091501486
            ],
            [
              2.8554088044265,
              50.626344370403
            ],
            [
              2.8424345578212,
              50.629053197628
            ],
            [
              2.8315295482256,
              50.620198187701
            ],
            [
              2.8051589952281,
              50.603274806983
            ],
            [
              2.789077205407,
              50.583061414779
            ],
            [
              2.8081602790831,
              50.579168536917
            ],
            [
              2.8156564700094,
              50.566015235103
            ],
            [
              2.8134410941335,
              50.549346599603
            ],
            [
              2.7944735844558,
              50.549202608036
            ],
            [
              2.8003840748793,
              50.541133459245
            ],
            [
              2.8001884917574,
              50.527606020054
            ],
            [
              2.8123436718256,
              50.528965098217
            ],
            [
              2.8259782428075,
              50.525388445491
            ],
            [
              2.8626192429586,
              50.530038123241
            ],
            [
              2.88696882249,
              50.537656078324
            ],
            [
              2.8856728826002,
              50.523504811946
            ],
            [
              2.8807796671222,
              50.514806261782
            ],
            [
              2.8989518656364,
              50.50155422527
            ],
            [
              2.9104248018046,
              50.499877363101
            ],
            [
              2.9263708206209,
              50.507715815336
            ],
            [
              2.9500479192408,
              50.512310718123
            ],
            [
              2.9647153709588,
              50.512774432069
            ],
            [
              2.9952182848207,
              50.496520325135
            ],
            [
              3.0083469740982,
              50.492761850628
            ],
            [
              3.0207995748428,
              50.481765465725
            ],
            [
              3.0163918982129,
              50.468288163807
            ],
            [
              3.0067353137984,
              50.457303639032
            ],
            [
              3.0172647358513,
              50.450251494975
            ],
            [
              3.0163412126251,
              50.442095390207
            ],
            [
              3.0376708990112,
              50.441638303122
            ],
            [
              3.054363830499,
              50.45508812622
            ],
            [
              3.0671591865506,
              50.458422919396
            ],
            [
              3.067602845566,
              50.446378740401
            ],
            [
              3.0763846740602,
              50.440611459145
            ],
            [
              3.0685193795963,
              50.425646240885
            ],
            [
              3.0516833830057,
              50.425798956162
            ],
            [
              3.0311802013236,
              50.41556573757
            ],
            [
              3.0277906377309,
              50.408997082838
            ],
            [
              3.0040858569527,
              50.401221379948
            ],
            [
              2.9823221459456,
              50.401497580279
            ],
            [
              2.9948606981276,
              50.387693367205
            ],
            [
              2.9930742529593,
              50.379842467514
            ],
            [
              3.0134557467176,
              50.359406970324
            ],
            [
              3.0387289093833,
              50.344018915107
            ],
            [
              3.0466910035393,
              50.345812651487
            ],
            [
              3.0624992429842,
              50.331425511861
            ],
            [
              3.060360059225,
              50.328916284683
            ],
            [
              3.0843056557162,
              50.311188090006
            ],
            [
              3.0657667023368,
              50.30506171807
            ],
            [
              3.0477515546427,
              50.293712166205
            ],
            [
              3.0555494146184,
              50.289439559934
            ],
            [
              3.051092647048,
              50.282358192083
            ],
            [
              3.0346104900049,
              50.28767572777
            ],
            [
              3.0178023561002,
              50.284584345639
            ],
            [
              3.0121734874327,
              50.268066705162
            ],
            [
              3.0185656735087,
              50.264396215861
            ],
            [
              3.0361767491925,
              50.26932263362
            ],
            [
              3.0515016064575,
              50.27664751931
            ],
            [
              3.0635760360422,
              50.27199084082
            ],
            [
              3.0805007705843,
              50.273504657872
            ],
            [
              3.1079119709181,
              50.268425891288
            ],
            [
              3.1336836466157,
              50.266233105789
            ],
            [
              3.1473350950287,
              50.260157614658
            ],
            [
              3.1509313322274,
              50.244917489418
            ],
            [
              3.1733764823825,
              50.241107671262
            ],
            [
              3.1883563131291,
              50.228672622554
            ],
            [
              3.1788781200699,
              50.219071541378
            ],
            [
              3.1423441660024,
              50.217665434614
            ],
            [
              3.1313977241108,
              50.19940043586
            ],
            [
              3.1431797234806,
              50.196397021207
            ],
            [
              3.1439103476899,
              50.189642434468
            ],
            [
              3.1561306993598,
              50.183717847228
            ],
            [
              3.1459010655896,
              50.178593862371
            ],
            [
              3.1289515731297,
              50.165559358488
            ],
            [
              3.1160318036349,
              50.167760693437
            ],
            [
              3.1021859277944,
              50.163315233096
            ],
            [
              3.1171760626407,
              50.146848185602
            ],
            [
              3.1344677195638,
              50.143226643999
            ],
            [
              3.1167908296377,
              50.131878113225
            ],
            [
              3.0957569190573,
              50.124200836276
            ],
            [
              3.1048504324589,
              50.115284559105
            ],
            [
              3.1046050204131,
              50.104630832207
            ],
            [
              3.1122784696822,
              50.103585864572
            ],
            [
              3.1141266539474,
              50.092155896514
            ],
            [
              3.103357990306,
              50.078515963926
            ],
            [
              3.0947639429408,
              50.08315717515
            ],
            [
              3.0837597873036,
              50.057784115124
            ],
            [
              3.0902522256658,
              50.053740556167
            ],
            [
              3.0744852263827,
              50.045299545153
            ],
            [
              3.0320823088719,
              50.05773936258
            ],
            [
              3.0144351371487,
              50.059149196751
            ],
            [
              2.9958629004892,
              50.049591917627
            ],
            [
              2.9922542514471,
              50.05086801359
            ],
            [
              2.9715289204271,
              50.041363376189
            ],
            [
              2.961934435566,
              50.050048626864
            ],
            [
              2.9484148787346,
              50.05341982418
            ],
            [
              2.9328926262549,
              50.043366480523
            ],
            [
              2.919242314665,
              50.047297561448
            ],
            [
              2.9171578680801,
              50.035709004905
            ],
            [
              2.8963079801904,
              50.03702692
            ],
            [
              2.8781360015419,
              50.028993132265
            ],
            [
              2.8724995109934,
              50.020975633738
            ],
            [
              2.8565849315278,
              50.023449604731
            ],
            [
              2.8521925480239,
              50.031430981058
            ],
            [
              2.8640766024256,
              50.032456974449
            ],
            [
              2.8765326366049,
              50.042182018727
            ],
            [
              2.8639425759373,
              50.060666985732
            ],
            [
              2.8553253050783,
              50.062432743603
            ],
            [
              2.8578492252845,
              50.077395819826
            ],
            [
              2.8381219175582,
              50.071826365585
            ],
            [
              2.8315161794119,
              50.063304080954
            ],
            [
              2.8223390134434,
              50.067524040363
            ],
            [
              2.8075496584953,
              50.062411099279
            ],
            [
              2.7926868513373,
              50.048670383144
            ],
            [
              2.785654805565,
              50.051165039384
            ],
            [
              2.7759503707983,
              50.042977159111
            ],
            [
              2.7631257218207,
              50.038635576415
            ],
            [
              2.7523973247116,
              50.040097252225
            ],
            [
              2.74255220997,
              50.04822779049
            ],
            [
              2.7637488651508,
              50.060596655646
            ],
            [
              2.7606613237401,
              50.069501982709
            ],
            [
              2.7746080431577,
              50.079625122566
            ],
            [
              2.7818371709104,
              50.09110708434
            ],
            [
              2.7778733322316,
              50.100510270846
            ],
            [
              2.7808963608043,
              50.111154461184
            ],
            [
              2.7633418535672,
              50.109960659774
            ],
            [
              2.7498103547084,
              50.112664006365
            ],
            [
              2.7295757423117,
              50.125952130979
            ],
            [
              2.7088192697755,
              50.128494700802
            ],
            [
              2.7101893900381,
              50.12023749817
            ],
            [
              2.7067890881945,
              50.108716923871
            ],
            [
              2.6972338002842,
              50.103665319899
            ],
            [
              2.7030514584422,
              50.095695432002
            ],
            [
              2.7187063567642,
              50.088088516
            ],
            [
              2.7046211552392,
              50.081210881091
            ],
            [
              2.6918326163969,
              50.091724194798
            ],
            [
              2.6720751553542,
              50.093629975857
            ],
            [
              2.657169930789,
              50.099962196692
            ],
            [
              2.6511272240456,
              50.095256173844
            ],
            [
              2.6334093914058,
              50.100839241322
            ],
            [
              2.6201126526627,
              50.111121522698
            ],
            [
              2.5980877105058,
              50.108648110659
            ],
            [
              2.5760156090129,
              50.113414591723
            ],
            [
              2.5704043870206,
              50.122779169444
            ],
            [
              2.5792419784879,
              50.128560135785
            ],
            [
              2.5781796823809,
              50.139890067967
            ],
            [
              2.5683716243263,
              50.135959316203
            ],
            [
              2.5513298839509,
              50.14160902898
            ],
            [
              2.5442437625709,
              50.119819097471
            ],
            [
              2.5323309219873,
              50.115072822921
            ],
            [
              2.5162474690159,
              50.134072265494
            ],
            [
              2.5149437196775,
              50.140815184745
            ],
            [
              2.4766795936249,
              50.136996746156
            ],
            [
              2.4501309494095,
              50.124395709785
            ],
            [
              2.4296522410241,
              50.088185800536
            ],
            [
              2.4209183396901,
              50.087653623551
            ],
            [
              2.4145064882364,
              50.10064599248
            ],
            [
              2.3931345350154,
              50.108161793497
            ],
            [
              2.3754526661687,
              50.109255035264
            ],
            [
              2.3830005723033,
              50.129048635249
            ],
            [
              2.3924780109113,
              50.144711608067
            ],
            [
              2.3897770350831,
              50.155486617449
            ],
            [
              2.3993505082333,
              50.157022748395
            ],
            [
              2.4032721760692,
              50.164802764181
            ],
            [
              2.4145338031959,
              50.164167221732
            ],
            [
              2.4148416899699,
              50.173106837647
            ],
            [
              2.4388530632566,
              50.181050438517
            ],
            [
              2.4508250196615,
              50.178517294258
            ],
            [
              2.4678564124169,
              50.186775790139
            ],
            [
              2.4956847502342,
              50.194560205258
            ],
            [
              2.4949893147919,
              50.199775603505
            ],
            [
              2.4852099859931,
              50.20779361481
            ],
            [
              2.4733136300465,
              50.209287796303
            ],
            [
              2.4611364848371,
              50.216723315306
            ],
            [
              2.451782406941,
              50.230302364541
            ],
            [
              2.4390306462363,
              50.227441475995
            ],
            [
              2.4194026288195,
              50.228084991282
            ],
            [
              2.4054763326709,
              50.234025879167
            ],
            [
              2.3921124689493,
              50.226080977662
            ],
            [
              2.3813992863809,
              50.225585799382
            ],
            [
              2.370200687702,
              50.209772132708
            ],
            [
              2.3611650521748,
              50.207027979791
            ],
            [
              2.35507097303,
              50.216740269224
            ],
            [
              2.3340601777872,
              50.238585583732
            ],
            [
              2.3175919368313,
              50.235075063165
            ],
            [
              2.3312494867596,
              50.222628702737
            ],
            [
              2.3012089116446,
              50.212867703297
            ],
            [
              2.2738268657578,
              50.226839891366
            ],
            [
              2.2532892806224,
              50.21468395736
            ],
            [
              2.2260581644339,
              50.212066850615
            ],
            [
              2.2225947536365,
              50.217809274907
            ],
            [
              2.1933281148673,
              50.209773250719
            ],
            [
              2.1741422745707,
              50.208896128118
            ],
            [
              2.1602481759659,
              50.195073119583
            ],
            [
              2.1497656380182,
              50.190426518694
            ],
            [
              2.1462496087203,
              50.198450332057
            ],
            [
              2.1366725730598,
              50.206542939153
            ],
            [
              2.1225857198719,
              50.210701687542
            ],
            [
              2.114564443101,
              50.202861068434
            ],
            [
              2.0957428065948,
              50.205543068689
            ],
            [
              2.0857123807737,
              50.20101021638
            ],
            [
              2.0845158657118,
              50.216516470398
            ],
            [
              2.0684863184493,
              50.22713547454
            ],
            [
              2.064084929272,
              50.236244609931
            ],
            [
              2.0778174949883,
              50.248821238775
            ],
            [
              2.0651693499299,
              50.255847696405
            ],
            [
              2.0471265348839,
              50.258004632192
            ],
            [
              2.0374720057313,
              50.266749373432
            ],
            [
              2.0264488530055,
              50.269549689324
            ],
            [
              2.0146735240532,
              50.26745451465
            ],
            [
              1.9942735539682,
              50.275664927673
            ],
            [
              1.9714273752663,
              50.282203794604
            ],
            [
              1.9643650275496,
              50.28847427511
            ],
            [
              1.9461358093263,
              50.287429874266
            ],
            [
              1.9364013540805,
              50.301469956543
            ],
            [
              1.9587947410604,
              50.312264690911
            ],
            [
              1.9638648247932,
              50.318095334956
            ],
            [
              1.958008929329,
              50.326104677818
            ],
            [
              1.9465507617787,
              50.331439621554
            ],
            [
              1.938135743335,
              50.330102333677
            ],
            [
              1.9180034741311,
              50.311782390973
            ],
            [
              1.8992385043566,
              50.313422021051
            ],
            [
              1.892312392029,
              50.318431455473
            ],
            [
              1.8759260198078,
              50.321745552866
            ],
            [
              1.8725567657952,
              50.332205089553
            ],
            [
              1.8540618817278,
              50.339097567191
            ],
            [
              1.8509931504967,
              50.343311587216
            ],
            [
              1.8335611982716,
              50.34494650473
            ],
            [
              1.8050936385045,
              50.359561131664
            ],
            [
              1.7787814318994,
              50.361867050627
            ],
            [
              1.7617125620618,
              50.361608996652
            ],
            [
              1.7592988596181,
              50.358044379758
            ],
            [
              1.7394496425691,
              50.354575794945
            ],
            [
              1.7281096719156,
              50.345887124721
            ],
            [
              1.6923870872348,
              50.342950152932
            ],
            [
              1.6707113970685,
              50.331006763813
            ],
            [
              1.6625434941435,
              50.337979860428
            ],
            [
              1.6451677016651,
              50.339220671372
            ],
            [
              1.6415398327353,
              50.352148667835
            ],
            [
              1.6313872914825,
              50.36008632241
            ],
            [
              1.6252312966713,
              50.371832567005
            ],
            [
              1.609294107413,
              50.370743587316
            ],
            [
              1.6001281982125,
              50.3777199452
            ],
            [
              1.5846837792851,
              50.376382884213
            ],
            [
              1.5703000307947,
              50.392158393497
            ],
            [
              1.563294790003,
              50.394950334151
            ],
            [
              1.5577948179294,
              50.404714412055
            ],
            [
              1.5634107613097,
              50.418460574304
            ],
            [
              1.5691068767694,
              50.440882836463
            ],
            [
              1.5770036622248,
              50.493788201491
            ],
            [
              1.577419530273,
              50.516533642344
            ],
            [
              1.5854092273894,
              50.537352408548
            ],
            [
              1.6267556408457,
              50.521386133981
            ],
            [
              1.6192946160483,
              50.535275013384
            ],
            [
              1.6084280327721,
              50.540109583469
            ],
            [
              1.6000968934514,
              50.549488993972
            ],
            [
              1.5812683008538,
              50.56276958757
            ],
            [
              1.5766037238144,
              50.572223225221
            ],
            [
              1.5776855393668,
              50.579872832517
            ],
            [
              1.578417453812,
              50.640285448716
            ],
            [
              1.5720971666289,
              50.664196329528
            ],
            [
              1.5624650705923,
              50.687457544808
            ],
            [
              1.5607073853506,
              50.699672766378
            ],
            [
              1.5736037455337,
              50.716166202188
            ],
            [
              1.5627006546685,
              50.724987440831
            ],
            [
              1.5841273301918,
              50.731155421742
            ],
            [
              1.5905216325015,
              50.729795719211
            ],
            [
              1.5960008708105,
              50.74619627021
            ],
            [
              1.6043933167393,
              50.7628298425
            ],
            [
              1.6042092031199,
              50.791871277165
            ],
            [
              1.6106406418335,
              50.803762099322
            ],
            [
              1.59968410858,
              50.805849583644
            ],
            [
              1.590153572682,
              50.825395303048
            ],
            [
              1.5833136380965,
              50.8457034659
            ],
            [
              1.5779419504031,
              50.853333808739
            ],
            [
              1.580632768506,
              50.867343830504
            ],
            [
              1.5887300407704,
              50.871669803792
            ],
            [
              1.6016621961931,
              50.871163999157
            ],
            [
              1.635804198092,
              50.876984561631
            ],
            [
              1.6641002886514,
              50.890134303575
            ],
            [
              1.6925371850157,
              50.915373472941
            ],
            [
              1.7122611515718,
              50.929408873362
            ],
            [
              1.7288559802086,
              50.937892541353
            ],
            [
              1.7807166589725,
              50.954865814239
            ],
            [
              1.851150647352,
              50.965664502203
            ],
            [
              1.8527009274535,
              50.970956150804
            ],
            [
              1.871165789111,
              50.974935769432
            ],
            [
              1.8896938932734,
              50.972314506334
            ],
            [
              1.915779044375,
              50.984087355938
            ],
            [
              1.939240821017,
              50.987858664569
            ],
            [
              1.9598449752902,
              50.987202834418
            ],
            [
              1.9927371149402,
              50.993728633123
            ],
            [
              2.0395672744245,
              50.997310813057
            ],
            [
              2.0477184398862,
              51.002860879522
            ],
            [
              2.0677049871716,
              51.006501514321
            ]
          ],
          [
            [
              2.9997373566796,
              50.129567254562
            ],
            [
              2.9993197857067,
              50.118846169655
            ],
            [
              3.0106307501246,
              50.112806167451
            ],
            [
              3.0272922164531,
              50.11854767383
            ],
            [
              3.0370332545299,
              50.130314244079
            ],
            [
              3.0576280219398,
              50.132169774021
            ],
            [
              3.072792245694,
              50.131181945966
            ],
            [
              3.068139305836,
              50.147348548695
            ],
            [
              3.0736181108485,
              50.148650234735
            ],
            [
              3.0956630380692,
              50.162593087522
            ],
            [
              3.0924970770113,
              50.170475585351
            ],
            [
              3.0610482264708,
              50.174049535934
            ],
            [
              3.0601901808976,
              50.16739301914
            ],
            [
              3.0496571002838,
              50.166029152388
            ],
            [
              3.0402613840731,
              50.159656660224
            ],
            [
              3.0403136602665,
              50.153319276217
            ],
            [
              3.0307228794651,
              50.151323930137
            ],
            [
              3.0038797607882,
              50.150247376424
            ],
            [
              2.9981750084232,
              50.142325834593
            ],
            [
              2.9997373566796,
              50.129567254562
            ]
          ]
        ]
      },
      "properties": {
        "code": "62",
        "nom": "Pas-de-Calais",
        "population": 1462167,
        "delitspour1000": "6,934",
        "nombremosque": 44,
        "parentsEtrangers": "91,6",
        "benefRsa": 105791,
        "eoliennes": 380
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.5653725529015,
              46.143035759957
            ],
            [
              2.5819676480582,
              46.134724292657
            ],
            [
              2.5915076247582,
              46.126875128538
            ],
            [
              2.6013604101779,
              46.130856129746
            ],
            [
              2.6218275727406,
              46.122529879748
            ],
            [
              2.6406964171362,
              46.118476777646
            ],
            [
              2.6528235484183,
              46.123751794785
            ],
            [
              2.6712851135172,
              46.15323761723
            ],
            [
              2.6774533498992,
              46.172759081776
            ],
            [
              2.7026972724062,
              46.181002577227
            ],
            [
              2.7224523871244,
              46.181341584244
            ],
            [
              2.7305575391219,
              46.196091290973
            ],
            [
              2.7209140750474,
              46.211307002875
            ],
            [
              2.7330534561794,
              46.223321627796
            ],
            [
              2.7423030105892,
              46.220724340564
            ],
            [
              2.7574594037315,
              46.211087339564
            ],
            [
              2.7720925093441,
              46.209683127276
            ],
            [
              2.7903423928364,
              46.199084389819
            ],
            [
              2.8102712209548,
              46.196213517628
            ],
            [
              2.8229834010983,
              46.21982222559
            ],
            [
              2.8186251178751,
              46.232571614587
            ],
            [
              2.8191591437729,
              46.241791609208
            ],
            [
              2.8393736457481,
              46.250723197702
            ],
            [
              2.8557278827609,
              46.255486133208
            ],
            [
              2.8674848695894,
              46.250753093513
            ],
            [
              2.8938256672135,
              46.252089110009
            ],
            [
              2.9033902209214,
              46.248869408373
            ],
            [
              2.921432650671,
              46.250307852218
            ],
            [
              2.9371418861435,
              46.242915665181
            ],
            [
              2.9322690545529,
              46.230926567436
            ],
            [
              2.9364864054072,
              46.221708066486
            ],
            [
              2.9261575114081,
              46.219422710207
            ],
            [
              2.913326209903,
              46.210334088438
            ],
            [
              2.9095928633152,
              46.203492910244
            ],
            [
              2.9201095289038,
              46.190983155
            ],
            [
              2.9184707210425,
              46.182191458572
            ],
            [
              2.9111330198118,
              46.177437699807
            ],
            [
              2.9427678272194,
              46.168545924839
            ],
            [
              2.9532834742817,
              46.170918110503
            ],
            [
              2.9552280775446,
              46.148189720109
            ],
            [
              2.9636876604981,
              46.129608480581
            ],
            [
              2.971750160512,
              46.121781028793
            ],
            [
              2.9813552292011,
              46.124850975135
            ],
            [
              2.9937696637853,
              46.123724023278
            ],
            [
              3.008738722988,
              46.115179124518
            ],
            [
              3.0164727327153,
              46.102446254469
            ],
            [
              3.0400119843362,
              46.107430908448
            ],
            [
              3.0480700398036,
              46.100317519725
            ],
            [
              3.0687190786824,
              46.104211419129
            ],
            [
              3.0873696406714,
              46.113064814944
            ],
            [
              3.0981067932511,
              46.099091251655
            ],
            [
              3.1007827299491,
              46.0823388867
            ],
            [
              3.113053611115,
              46.081444843541
            ],
            [
              3.1281003938417,
              46.071398281325
            ],
            [
              3.1665370397662,
              46.06567976991
            ],
            [
              3.1793471377517,
              46.06657424048
            ],
            [
              3.1799729538988,
              46.073587187232
            ],
            [
              3.2062653675586,
              46.072623029145
            ],
            [
              3.2145121735371,
              46.074943616392
            ],
            [
              3.2415132669656,
              46.063283777745
            ],
            [
              3.2563742222901,
              46.065793261654
            ],
            [
              3.2659770500953,
              46.064185379521
            ],
            [
              3.3023909460534,
              46.065769077557
            ],
            [
              3.31621925685,
              46.062006013015
            ],
            [
              3.3171686018834,
              46.057273946875
            ],
            [
              3.3327642338523,
              46.054978015735
            ],
            [
              3.3320492235943,
              46.044097580518
            ],
            [
              3.3589678841642,
              46.053259195739
            ],
            [
              3.3716020587659,
              46.055056424918
            ],
            [
              3.3861917265904,
              46.064436835237
            ],
            [
              3.4045994258891,
              46.067143517788
            ],
            [
              3.4190243995629,
              46.074294275891
            ],
            [
              3.4336861041744,
              46.060791050488
            ],
            [
              3.441645443805,
              46.059514040098
            ],
            [
              3.4535876455667,
              46.063790238523
            ],
            [
              3.459684718269,
              46.05221310179
            ],
            [
              3.4714881553342,
              46.041837627611
            ],
            [
              3.4635021580888,
              46.017937775932
            ],
            [
              3.4641409824748,
              46.008169157186
            ],
            [
              3.481365707913,
              46.013709267662
            ],
            [
              3.4997046841734,
              46.013746955353
            ],
            [
              3.5207051439429,
              46.02036662552
            ],
            [
              3.5346332381264,
              46.022379265254
            ],
            [
              3.5476572029407,
              46.015536218752
            ],
            [
              3.5583822511587,
              46.018705024732
            ],
            [
              3.5702426376894,
              46.017676117086
            ],
            [
              3.5884708591387,
              46.024134197777
            ],
            [
              3.6014373069602,
              46.015081671296
            ],
            [
              3.6169361630777,
              45.992995963943
            ],
            [
              3.6224109331286,
              45.989739452473
            ],
            [
              3.6280248857031,
              45.978697797748
            ],
            [
              3.6319957322686,
              45.961061509138
            ],
            [
              3.6463106445608,
              45.963504863505
            ],
            [
              3.669739069879,
              45.961205643752
            ],
            [
              3.6767583931205,
              45.958063393878
            ],
            [
              3.6769360542791,
              45.949512739521
            ],
            [
              3.6827781689172,
              45.940490397824
            ],
            [
              3.6940151632959,
              45.930727869968
            ],
            [
              3.7234494736526,
              45.921866002231
            ],
            [
              3.7302305003589,
              45.912154335422
            ],
            [
              3.7481167335366,
              45.898388978743
            ],
            [
              3.7543694315582,
              45.886028143379
            ],
            [
              3.7385585560632,
              45.873290945813
            ],
            [
              3.7315476579701,
              45.870161263522
            ],
            [
              3.7163746493407,
              45.871248126479
            ],
            [
              3.7245781350935,
              45.85432080766
            ],
            [
              3.7190248244993,
              45.85114949578
            ],
            [
              3.7269243731372,
              45.832561547866
            ],
            [
              3.7208168799623,
              45.822603018185
            ],
            [
              3.7217494092138,
              45.813638605233
            ],
            [
              3.711616964199,
              45.799546630779
            ],
            [
              3.6906909501902,
              45.792174332964
            ],
            [
              3.6997618488845,
              45.78317894952
            ],
            [
              3.7227382484835,
              45.778894420986
            ],
            [
              3.7388919147354,
              45.766816379012
            ],
            [
              3.7270987560963,
              45.755535555022
            ],
            [
              3.7459113537954,
              45.742253302458
            ],
            [
              3.7586673779066,
              45.746007164306
            ],
            [
              3.7674361843451,
              45.729833752913
            ],
            [
              3.780847427756,
              45.724352891461
            ],
            [
              3.7823901700362,
              45.709711277588
            ],
            [
              3.7761246864543,
              45.698498886956
            ],
            [
              3.7802451010076,
              45.689132849044
            ],
            [
              3.7948460493393,
              45.673242700876
            ],
            [
              3.8018106393062,
              45.660014089594
            ],
            [
              3.8179380073842,
              45.646530627733
            ],
            [
              3.8234583720899,
              45.632419606167
            ],
            [
              3.8395128061617,
              45.626177388842
            ],
            [
              3.8487628555111,
              45.626772595715
            ],
            [
              3.8617323667916,
              45.622726993642
            ],
            [
              3.8667443466401,
              45.614381981479
            ],
            [
              3.8846838349771,
              45.602754230839
            ],
            [
              3.9036013502365,
              45.599021524707
            ],
            [
              3.9091712372871,
              45.593142063809
            ],
            [
              3.9322884496016,
              45.579271825147
            ],
            [
              3.9460908601758,
              45.561534784139
            ],
            [
              3.9543510024832,
              45.555964462402
            ],
            [
              3.945947387476,
              45.544478011248
            ],
            [
              3.9521751857414,
              45.534129573924
            ],
            [
              3.958560843992,
              45.511766295791
            ],
            [
              3.9648369463598,
              45.503216903255
            ],
            [
              3.9844000097893,
              45.494111974154
            ],
            [
              3.9806922289344,
              45.481299193399
            ],
            [
              3.968623430075,
              45.47544697307
            ],
            [
              3.9673930485222,
              45.467344700943
            ],
            [
              3.9751082776962,
              45.448267726135
            ],
            [
              3.9601381718507,
              45.443447561939
            ],
            [
              3.9630379885044,
              45.435531390096
            ],
            [
              3.9379616198049,
              45.430225517975
            ],
            [
              3.9176994123003,
              45.421021469893
            ],
            [
              3.9092989645158,
              45.413177077287
            ],
            [
              3.8991889962651,
              45.410304937867
            ],
            [
              3.8892289954528,
              45.394517377924
            ],
            [
              3.8939015032397,
              45.381293140859
            ],
            [
              3.8928560302218,
              45.367901022685
            ],
            [
              3.8974083668223,
              45.357080180177
            ],
            [
              3.8921536549569,
              45.354049640821
            ],
            [
              3.8783000989899,
              45.354952490372
            ],
            [
              3.8569484852367,
              45.359322343572
            ],
            [
              3.8504123377727,
              45.364998489678
            ],
            [
              3.8376831375527,
              45.36571035068
            ],
            [
              3.8352820447204,
              45.376045308773
            ],
            [
              3.8217289223052,
              45.38101993984
            ],
            [
              3.8083112095965,
              45.391610156832
            ],
            [
              3.799851297461,
              45.390417363783
            ],
            [
              3.7855713393306,
              45.369279292494
            ],
            [
              3.7899914752805,
              45.353880391851
            ],
            [
              3.7826175271352,
              45.354912800951
            ],
            [
              3.7722592400978,
              45.349189155916
            ],
            [
              3.7605343425153,
              45.354693042752
            ],
            [
              3.7408397385734,
              45.353034889768
            ],
            [
              3.7196851785893,
              45.360848568164
            ],
            [
              3.6998935179885,
              45.35946388081
            ],
            [
              3.7046866335921,
              45.368500802746
            ],
            [
              3.6905091154115,
              45.373352652913
            ],
            [
              3.6798572026821,
              45.38418849136
            ],
            [
              3.667215400603,
              45.376197053107
            ],
            [
              3.6631634885681,
              45.361580190594
            ],
            [
              3.6442124864011,
              45.356129442181
            ],
            [
              3.6408772711819,
              45.348436898172
            ],
            [
              3.6258417624803,
              45.338345687886
            ],
            [
              3.618619523185,
              45.337709571841
            ],
            [
              3.6077869899808,
              45.34376244024
            ],
            [
              3.6032776845893,
              45.350787096604
            ],
            [
              3.5903439892848,
              45.357222362784
            ],
            [
              3.5853554957634,
              45.369159103591
            ],
            [
              3.5880448340552,
              45.375224123083
            ],
            [
              3.5790230806783,
              45.385627478313
            ],
            [
              3.5705803547836,
              45.402095477198
            ],
            [
              3.5630784498473,
              45.394913393775
            ],
            [
              3.5482813829238,
              45.398606670195
            ],
            [
              3.5209053900855,
              45.402409376819
            ],
            [
              3.5051637048024,
              45.412966539276
            ],
            [
              3.5013193341285,
              45.427582294546
            ],
            [
              3.4769133999367,
              45.418251783397
            ],
            [
              3.4567491182322,
              45.400282212799
            ],
            [
              3.4307265352749,
              45.401799869217
            ],
            [
              3.4200366594148,
              45.397225547231
            ],
            [
              3.4061079085888,
              45.403388343793
            ],
            [
              3.3895002199315,
              45.402409047019
            ],
            [
              3.3759878802138,
              45.404482132477
            ],
            [
              3.3589872444879,
              45.421013675007
            ],
            [
              3.3408514694448,
              45.421358421192
            ],
            [
              3.3409855357626,
              45.414654053148
            ],
            [
              3.333673054382,
              45.405812794289
            ],
            [
              3.3243232056899,
              45.40642823764
            ],
            [
              3.3106626355534,
              45.417352619698
            ],
            [
              3.2956970631794,
              45.418111657039
            ],
            [
              3.2906906080245,
              45.401824597103
            ],
            [
              3.2718909747067,
              45.392762734491
            ],
            [
              3.2500841563678,
              45.389044204681
            ],
            [
              3.2365752879669,
              45.394473576099
            ],
            [
              3.2209136476108,
              45.378918658473
            ],
            [
              3.2186572489279,
              45.36948993409
            ],
            [
              3.2018988030737,
              45.371105286733
            ],
            [
              3.1944913481393,
              45.362570404448
            ],
            [
              3.184746858378,
              45.363770092966
            ],
            [
              3.1680632131655,
              45.352256205748
            ],
            [
              3.1509333921975,
              45.35117485202
            ],
            [
              3.1418440537677,
              45.353463066252
            ],
            [
              3.1243903879717,
              45.350839197184
            ],
            [
              3.1035006854512,
              45.3543721973
            ],
            [
              3.0908481025946,
              45.347261540297
            ],
            [
              3.0907015039255,
              45.341144929817
            ],
            [
              3.0817054326028,
              45.336203010406
            ],
            [
              3.0704296209841,
              45.33726779338
            ],
            [
              3.0602659894023,
              45.330587028732
            ],
            [
              3.0651711253653,
              45.314667164704
            ],
            [
              3.0585633104717,
              45.306568657606
            ],
            [
              3.0406307985252,
              45.298901267002
            ],
            [
              3.0217850748584,
              45.295366903543
            ],
            [
              3.0167126860657,
              45.287121578381
            ],
            [
              3.0020295632398,
              45.290169884137
            ],
            [
              2.9979830556833,
              45.293972428423
            ],
            [
              2.9668089592388,
              45.307031080426
            ],
            [
              2.9486247554478,
              45.309121333078
            ],
            [
              2.9257598423702,
              45.342632884539
            ],
            [
              2.9215506466252,
              45.365775113903
            ],
            [
              2.9062723283924,
              45.369273324711
            ],
            [
              2.8929713508093,
              45.379235700569
            ],
            [
              2.8819725221573,
              45.383653077697
            ],
            [
              2.873531045267,
              45.375373216731
            ],
            [
              2.8677290396958,
              45.376075172391
            ],
            [
              2.8562660550707,
              45.385530211352
            ],
            [
              2.8523722940921,
              45.392340683984
            ],
            [
              2.8287310946056,
              45.390108137913
            ],
            [
              2.8226067013152,
              45.397871340292
            ],
            [
              2.814891870243,
              45.40096363738
            ],
            [
              2.8012326053351,
              45.392862254268
            ],
            [
              2.785884313405,
              45.388714396394
            ],
            [
              2.7623393691285,
              45.38616186717
            ],
            [
              2.7421913474358,
              45.393334488142
            ],
            [
              2.725880283048,
              45.388849317837
            ],
            [
              2.7148508522915,
              45.381555103256
            ],
            [
              2.7013819605454,
              45.391090987905
            ],
            [
              2.6939228094584,
              45.400311384007
            ],
            [
              2.6807334465205,
              45.407169426605
            ],
            [
              2.6799359294086,
              45.422690240334
            ],
            [
              2.6896910706442,
              45.430037537145
            ],
            [
              2.6884626875256,
              45.437688088975
            ],
            [
              2.6782771810773,
              45.438428333547
            ],
            [
              2.6633954828406,
              45.434165070918
            ],
            [
              2.6514324889273,
              45.442655959982
            ],
            [
              2.6246720210042,
              45.444977466593
            ],
            [
              2.6223003491009,
              45.468508742321
            ],
            [
              2.6048958880008,
              45.451728826462
            ],
            [
              2.5903809064644,
              45.450053215627
            ],
            [
              2.5740484247647,
              45.456284905861
            ],
            [
              2.5672426391961,
              45.464362241387
            ],
            [
              2.5518965086317,
              45.463448356213
            ],
            [
              2.5358970743575,
              45.458079509752
            ],
            [
              2.5439644302012,
              45.478981170304
            ],
            [
              2.5244372055202,
              45.480702895801
            ],
            [
              2.5084125130653,
              45.478501516921
            ],
            [
              2.5138913543334,
              45.492347782226
            ],
            [
              2.5089910453543,
              45.500226403127
            ],
            [
              2.5115199537568,
              45.511153110817
            ],
            [
              2.5203805740984,
              45.520450992238
            ],
            [
              2.5165205610225,
              45.524032787608
            ],
            [
              2.5163271748739,
              45.55342839279
            ],
            [
              2.5064679621685,
              45.553444063405
            ],
            [
              2.4909285469766,
              45.560394087282
            ],
            [
              2.4874305070125,
              45.569384021272
            ],
            [
              2.4725367701592,
              45.580929716396
            ],
            [
              2.4646251782029,
              45.594410586781
            ],
            [
              2.4653445128497,
              45.600820150088
            ],
            [
              2.4784344946797,
              45.607911789419
            ],
            [
              2.4790816386943,
              45.62246233864
            ],
            [
              2.4833880353068,
              45.639303519147
            ],
            [
              2.4897451626044,
              45.642353692
            ],
            [
              2.5011495345088,
              45.638738876646
            ],
            [
              2.5142980219448,
              45.639528483879
            ],
            [
              2.5249384159848,
              45.657234455338
            ],
            [
              2.5151301172176,
              45.664284079976
            ],
            [
              2.5129233228036,
              45.6713335771
            ],
            [
              2.5283596411119,
              45.681924314977
            ],
            [
              2.5268700649094,
              45.694919495794
            ],
            [
              2.5198739656449,
              45.697052906827
            ],
            [
              2.5183220391127,
              45.712799669432
            ],
            [
              2.4921294160933,
              45.7376701128
            ],
            [
              2.4846414659033,
              45.748521466442
            ],
            [
              2.4751529321011,
              45.747688866007
            ],
            [
              2.4547914690758,
              45.761151249494
            ],
            [
              2.4414259075117,
              45.762378997013
            ],
            [
              2.4341123429169,
              45.769852817869
            ],
            [
              2.4329800874586,
              45.783888824912
            ],
            [
              2.4274035046023,
              45.794285013202
            ],
            [
              2.4185991219253,
              45.798475127769
            ],
            [
              2.4006773662183,
              45.817289546629
            ],
            [
              2.388014020679,
              45.827373168854
            ],
            [
              2.4013447310476,
              45.837577232244
            ],
            [
              2.4265364620605,
              45.834771343876
            ],
            [
              2.436436952669,
              45.846998827989
            ],
            [
              2.4478280089908,
              45.84581866569
            ],
            [
              2.4425827259717,
              45.863008040763
            ],
            [
              2.4706137918202,
              45.872335245229
            ],
            [
              2.4797383951775,
              45.864541357011
            ],
            [
              2.4922279879729,
              45.864029936278
            ],
            [
              2.5099613389201,
              45.88666598071
            ],
            [
              2.5262086816167,
              45.896727274022
            ],
            [
              2.5376912527467,
              45.900845994537
            ],
            [
              2.5550056008106,
              45.912509471984
            ],
            [
              2.5415060022687,
              45.921531781083
            ],
            [
              2.5509536719711,
              45.935761668247
            ],
            [
              2.5516381183225,
              45.941261078502
            ],
            [
              2.5657173898648,
              45.946856793309
            ],
            [
              2.5651781859373,
              45.95661981464
            ],
            [
              2.5891875589674,
              45.960437573506
            ],
            [
              2.6107853057918,
              45.971228071516
            ],
            [
              2.6074695080176,
              45.979108641029
            ],
            [
              2.5944202186196,
              45.989440975795
            ],
            [
              2.6025059601172,
              46.033461930485
            ],
            [
              2.5716988273144,
              46.048689825423
            ],
            [
              2.5631828961231,
              46.064609781776
            ],
            [
              2.5570843667475,
              46.069300275416
            ],
            [
              2.5518715312783,
              46.083457348801
            ],
            [
              2.5518219731636,
              46.103970633498
            ],
            [
              2.5490488887945,
              46.114075006608
            ],
            [
              2.5582364865104,
              46.130701979674
            ],
            [
              2.5586220340193,
              46.138326224613
            ],
            [
              2.5653725529015,
              46.143035759957
            ]
          ]
        ]
      },
      "properties": {
        "code": "63",
        "nom": "Puy-de-Dôme",
        "population": 661852,
        "delitspour1000": "3,812",
        "nombremosque": 30,
        "parentsEtrangers": "78,9",
        "benefRsa": 30296,
        "eoliennes": 48
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              7.6352815933424,
              49.054164206261
            ],
            [
              7.6744885818061,
              49.045042026719
            ],
            [
              7.7073172414616,
              49.054054167764
            ],
            [
              7.7235168959792,
              49.052567266134
            ],
            [
              7.7322236434991,
              49.044355921307
            ],
            [
              7.7464402457806,
              49.047183991579
            ],
            [
              7.7564971746319,
              49.04559846504
            ],
            [
              7.7699412681687,
              49.048752604766
            ],
            [
              7.7761140800325,
              49.056208919534
            ],
            [
              7.7930299497337,
              49.063494910346
            ],
            [
              7.8004419678935,
              49.064114797244
            ],
            [
              7.8203561465381,
              49.053762416992
            ],
            [
              7.8445208331331,
              49.044968895008
            ],
            [
              7.8554925156838,
              49.035415738102
            ],
            [
              7.8687912381001,
              49.034158646571
            ],
            [
              7.8816859515844,
              49.045192019191
            ],
            [
              7.8916182565803,
              49.047594615956
            ],
            [
              7.9166981594872,
              49.04096573662
            ],
            [
              7.9346229019277,
              49.057811113571
            ],
            [
              7.9450664235081,
              49.055272126791
            ],
            [
              7.9473992180973,
              49.049180307495
            ],
            [
              7.9673895507266,
              49.038891633851
            ],
            [
              7.9748521290202,
              49.027746491985
            ],
            [
              7.9819000059942,
              49.025893418758
            ],
            [
              7.9991263159241,
              49.028129921868
            ],
            [
              8.0227035606617,
              49.018324772576
            ],
            [
              8.0480839535656,
              49.014172777638
            ],
            [
              8.0671689833513,
              48.999497718891
            ],
            [
              8.0913803067987,
              48.989259837789
            ],
            [
              8.1213785685874,
              48.98536003174
            ],
            [
              8.1414937386022,
              48.978017561341
            ],
            [
              8.170147720846,
              48.979348008223
            ],
            [
              8.1944015411941,
              48.97743000256
            ],
            [
              8.1990893418219,
              48.967223546641
            ],
            [
              8.2143424400211,
              48.975096459051
            ],
            [
              8.2268223344758,
              48.974092891976
            ],
            [
              8.2303986615424,
              48.965607843829
            ],
            [
              8.2030347428027,
              48.959937315879
            ],
            [
              8.1958012960897,
              48.95621138585
            ],
            [
              8.1767812617108,
              48.932012053804
            ],
            [
              8.1479387393441,
              48.903810890927
            ],
            [
              8.1245755473601,
              48.870687777488
            ],
            [
              8.1087697047883,
              48.836985966255
            ],
            [
              8.1060606073282,
              48.823689761204
            ],
            [
              8.0963232810048,
              48.810383594146
            ],
            [
              8.0771060061123,
              48.79529933543
            ],
            [
              8.0589448227056,
              48.788812962432
            ],
            [
              8.0432668189723,
              48.790730127013
            ],
            [
              8.0291352605611,
              48.786478892711
            ],
            [
              8.022824712781,
              48.768352845058
            ],
            [
              8.0147046028691,
              48.761181073583
            ],
            [
              8.0049554561251,
              48.758781494258
            ],
            [
              7.9817555144595,
              48.761013532829
            ],
            [
              7.9717522881571,
              48.757631517371
            ],
            [
              7.967222806413,
              48.745410899662
            ],
            [
              7.9672574265548,
              48.727017790766
            ],
            [
              7.9631443712693,
              48.721355620767
            ],
            [
              7.8908881835121,
              48.663098534076
            ],
            [
              7.8398216436445,
              48.64137741102
            ],
            [
              7.8287156830025,
              48.617707542634
            ],
            [
              7.8041684261793,
              48.59233381119
            ],
            [
              7.8002369638938,
              48.578683608778
            ],
            [
              7.8052303653608,
              48.562215004891
            ],
            [
              7.8032686065062,
              48.53382105272
            ],
            [
              7.8069547274248,
              48.522278404185
            ],
            [
              7.8052011366022,
              48.513573048614
            ],
            [
              7.7947080252984,
              48.502090987414
            ],
            [
              7.7681925743495,
              48.489661466269
            ],
            [
              7.7648837539549,
              48.456687845258
            ],
            [
              7.7618878776799,
              48.448985237501
            ],
            [
              7.735972029784,
              48.404161681857
            ],
            [
              7.7318458832381,
              48.39290556989
            ],
            [
              7.7320016795341,
              48.375407113123
            ],
            [
              7.7450295673245,
              48.335662070435
            ],
            [
              7.7398754620749,
              48.321749343082
            ],
            [
              7.7332390210278,
              48.317791507494
            ],
            [
              7.7031470724726,
              48.308812723211
            ],
            [
              7.6960088664325,
              48.304010337634
            ],
            [
              7.6893329145202,
              48.293283776555
            ],
            [
              7.6889755135447,
              48.279872718204
            ],
            [
              7.6678077852565,
              48.223906110814
            ],
            [
              7.6645588474966,
              48.219142022716
            ],
            [
              7.6440693964521,
              48.207227606933
            ],
            [
              7.6311500892776,
              48.185415410472
            ],
            [
              7.6011481776184,
              48.158377720127
            ],
            [
              7.6006039800313,
              48.138845916373
            ],
            [
              7.5970709855475,
              48.13314660244
            ],
            [
              7.5828806945248,
              48.126528231256
            ],
            [
              7.5773157720816,
              48.120371112534
            ],
            [
              7.5528674428158,
              48.121828718961
            ],
            [
              7.5193723560282,
              48.126108118767
            ],
            [
              7.5149853866599,
              48.139639080259
            ],
            [
              7.5203336674043,
              48.149495287589
            ],
            [
              7.5125316146576,
              48.152317863686
            ],
            [
              7.4774630193911,
              48.156563518085
            ],
            [
              7.4705578806378,
              48.161010134
            ],
            [
              7.4730897372503,
              48.196537826413
            ],
            [
              7.4769430706019,
              48.203533090919
            ],
            [
              7.455513612234,
              48.209784876976
            ],
            [
              7.4446629261788,
              48.217420847786
            ],
            [
              7.435000853779,
              48.217885231493
            ],
            [
              7.4089071495429,
              48.224105878071
            ],
            [
              7.3979867918269,
              48.232505718051
            ],
            [
              7.3823367547394,
              48.238043999056
            ],
            [
              7.3485690445532,
              48.247191676747
            ],
            [
              7.3292781377806,
              48.245543962665
            ],
            [
              7.3183027579228,
              48.249144714543
            ],
            [
              7.3134866356341,
              48.256385343496
            ],
            [
              7.3126558596123,
              48.268538116429
            ],
            [
              7.2997802529041,
              48.268658649324
            ],
            [
              7.2918721296159,
              48.275361828732
            ],
            [
              7.3007048987286,
              48.280692265256
            ],
            [
              7.290798196671,
              48.293839376705
            ],
            [
              7.2824675936494,
              48.296405805217
            ],
            [
              7.2751834298867,
              48.305231193436
            ],
            [
              7.2484948814022,
              48.304198238868
            ],
            [
              7.230714124293,
              48.308487586014
            ],
            [
              7.1982872111206,
              48.310471263573
            ],
            [
              7.1914734958352,
              48.322481635859
            ],
            [
              7.1772138617934,
              48.325302455967
            ],
            [
              7.1845441585974,
              48.336049661823
            ],
            [
              7.169530852204,
              48.342300751899
            ],
            [
              7.1648351203912,
              48.337682945748
            ],
            [
              7.1442977094519,
              48.332073998048
            ],
            [
              7.1219031917914,
              48.333292363952
            ],
            [
              7.1094055216992,
              48.343193984031
            ],
            [
              7.0785285569966,
              48.351070234059
            ],
            [
              7.0903768777228,
              48.36561626561
            ],
            [
              7.10802631494,
              48.37699074362
            ],
            [
              7.1040995144891,
              48.388336667304
            ],
            [
              7.0855246844242,
              48.398669480184
            ],
            [
              7.0974259260427,
              48.405647416542
            ],
            [
              7.0995878866837,
              48.434180387294
            ],
            [
              7.1092965939078,
              48.438619698319
            ],
            [
              7.1037570732695,
              48.44948479864
            ],
            [
              7.1172385701417,
              48.477545363165
            ],
            [
              7.1168879614082,
              48.484885772315
            ],
            [
              7.093659756546,
              48.48928173768
            ],
            [
              7.0936784257816,
              48.505231627961
            ],
            [
              7.1032227316966,
              48.499823397675
            ],
            [
              7.1220633124076,
              48.500699348096
            ],
            [
              7.1277243419227,
              48.509520733673
            ],
            [
              7.1231636635608,
              48.513587820739
            ],
            [
              7.0995889522962,
              48.516674991314
            ],
            [
              7.0828568146092,
              48.514699536554
            ],
            [
              7.064491666109,
              48.523396874848
            ],
            [
              7.079355782655,
              48.536418650433
            ],
            [
              7.0921481404601,
              48.529042689021
            ],
            [
              7.1143777486324,
              48.537386238774
            ],
            [
              7.1275722617052,
              48.53133186834
            ],
            [
              7.1470879758322,
              48.52694525177
            ],
            [
              7.16790822618,
              48.527591999423
            ],
            [
              7.1718405152913,
              48.532420201995
            ],
            [
              7.1871006559345,
              48.537271133109
            ],
            [
              7.1924857120791,
              48.548794699192
            ],
            [
              7.2013562980103,
              48.552699958758
            ],
            [
              7.2193103198617,
              48.553769990292
            ],
            [
              7.2562785812957,
              48.588671113482
            ],
            [
              7.262812241024,
              48.590281985422
            ],
            [
              7.2583999271862,
              48.602642740722
            ],
            [
              7.2707781932556,
              48.612499029537
            ],
            [
              7.2784275025088,
              48.625198796078
            ],
            [
              7.2732932655448,
              48.633307766643
            ],
            [
              7.2830506425965,
              48.638110598789
            ],
            [
              7.3043497385752,
              48.660204189323
            ],
            [
              7.2827944470062,
              48.660285985831
            ],
            [
              7.2646008192286,
              48.662701785192
            ],
            [
              7.2619865507141,
              48.669979110411
            ],
            [
              7.2424838370011,
              48.691546869433
            ],
            [
              7.2601696827824,
              48.694299105119
            ],
            [
              7.2639424681146,
              48.698645442814
            ],
            [
              7.2669990444847,
              48.714369494768
            ],
            [
              7.2868804652201,
              48.73134428749
            ],
            [
              7.2913587067135,
              48.749108901893
            ],
            [
              7.2959177661537,
              48.755313013071
            ],
            [
              7.310850516241,
              48.763203502383
            ],
            [
              7.3119029263128,
              48.767297153936
            ],
            [
              7.2915436633852,
              48.784503341757
            ],
            [
              7.2912262536697,
              48.79399286624
            ],
            [
              7.2704862686317,
              48.80253459505
            ],
            [
              7.2620309927567,
              48.79776134254
            ],
            [
              7.2476583699836,
              48.809648890138
            ],
            [
              7.2429785271147,
              48.817621212373
            ],
            [
              7.2286555719709,
              48.828037920731
            ],
            [
              7.2184018592488,
              48.819517440754
            ],
            [
              7.1878318748607,
              48.833929235992
            ],
            [
              7.1958804788997,
              48.840394252637
            ],
            [
              7.1846205353403,
              48.850245274866
            ],
            [
              7.176453943453,
              48.842122985818
            ],
            [
              7.1494099127444,
              48.844705787321
            ],
            [
              7.1412706412176,
              48.833893909532
            ],
            [
              7.1466649693409,
              48.821305779987
            ],
            [
              7.1237748421701,
              48.811766606831
            ],
            [
              7.1267966008006,
              48.801145146585
            ],
            [
              7.1119119024702,
              48.799016252531
            ],
            [
              7.088164004341,
              48.792490993201
            ],
            [
              7.0778230489578,
              48.785578755501
            ],
            [
              7.0671939837244,
              48.799480443377
            ],
            [
              7.0891249837893,
              48.806773609574
            ],
            [
              7.0793070341102,
              48.814035537355
            ],
            [
              7.0620072040144,
              48.815696701106
            ],
            [
              7.0545188301304,
              48.813706942265
            ],
            [
              7.047118425674,
              48.820636260338
            ],
            [
              7.0545137622898,
              48.833284733109
            ],
            [
              7.0513316660602,
              48.846282688184
            ],
            [
              7.0632994003369,
              48.848333990496
            ],
            [
              7.086621157518,
              48.847742486374
            ],
            [
              7.0981114372419,
              48.852855904629
            ],
            [
              7.0957199498063,
              48.86214489256
            ],
            [
              7.0811617503575,
              48.864104213578
            ],
            [
              7.0502424744293,
              48.859345451322
            ],
            [
              7.0453494187227,
              48.871545317597
            ],
            [
              7.027124762811,
              48.87980451616
            ],
            [
              7.0075363595826,
              48.884346968873
            ],
            [
              6.988917207927,
              48.89118951936
            ],
            [
              6.975494884565,
              48.890110019382
            ],
            [
              6.9546718652654,
              48.893890344954
            ],
            [
              6.9646057074582,
              48.904524234117
            ],
            [
              6.9555419328142,
              48.922322591857
            ],
            [
              6.9403717864006,
              48.923927611506
            ],
            [
              6.9443993368701,
              48.934799489837
            ],
            [
              6.9608225491544,
              48.925350724448
            ],
            [
              6.9788871720834,
              48.933339809497
            ],
            [
              6.9870095302313,
              48.94802708526
            ],
            [
              6.9892133372901,
              48.959924389065
            ],
            [
              7.0322221462463,
              48.956048112426
            ],
            [
              7.0272582662636,
              48.966569743904
            ],
            [
              7.0386891228621,
              48.978013606454
            ],
            [
              7.0308791462263,
              48.985879416935
            ],
            [
              7.0383544772138,
              48.996876342628
            ],
            [
              7.0530006492118,
              49.007294818857
            ],
            [
              7.0486721401747,
              49.018354464191
            ],
            [
              7.0549121231097,
              49.030621881695
            ],
            [
              7.078657018389,
              49.050358924621
            ],
            [
              7.0691514510543,
              49.059900238733
            ],
            [
              7.0681059794001,
              49.066882709203
            ],
            [
              7.075924326097,
              49.073990440304
            ],
            [
              7.0836427264995,
              49.068670075954
            ],
            [
              7.099344679949,
              49.077884925649
            ],
            [
              7.1109699086532,
              49.071857597144
            ],
            [
              7.1133119786809,
              49.064743743706
            ],
            [
              7.1016822948106,
              49.063729853332
            ],
            [
              7.1076635795824,
              49.044108799815
            ],
            [
              7.1194733208385,
              49.037217568319
            ],
            [
              7.1217983031735,
              49.022563807628
            ],
            [
              7.1305056457022,
              49.004589914922
            ],
            [
              7.1742389780393,
              49.006366626799
            ],
            [
              7.1820536883691,
              49.001652364499
            ],
            [
              7.1837071637212,
              48.994778146642
            ],
            [
              7.1959774900033,
              48.993604617056
            ],
            [
              7.2093166954507,
              48.980085251586
            ],
            [
              7.2360269811716,
              48.987815168101
            ],
            [
              7.2930505096429,
              48.973419993633
            ],
            [
              7.2912378631909,
              48.956648934867
            ],
            [
              7.3024327595623,
              48.956470709066
            ],
            [
              7.3268481155836,
              48.943375583481
            ],
            [
              7.3481359942558,
              48.950943288743
            ],
            [
              7.3588755232568,
              48.957605614769
            ],
            [
              7.3692017508039,
              48.952248088014
            ],
            [
              7.3923145989853,
              48.952710432441
            ],
            [
              7.3974785888727,
              48.956445019387
            ],
            [
              7.4328807123666,
              48.961903080414
            ],
            [
              7.4468936659225,
              48.965782537324
            ],
            [
              7.4598257062034,
              48.962370134773
            ],
            [
              7.4795692942305,
              48.961387563595
            ],
            [
              7.4851638896843,
              48.950681558293
            ],
            [
              7.4970804391444,
              48.946222036435
            ],
            [
              7.5264265489155,
              48.941454002925
            ],
            [
              7.5382559219775,
              48.932957199392
            ],
            [
              7.5561387855791,
              48.935832330647
            ],
            [
              7.5515367574775,
              48.941156060195
            ],
            [
              7.5638233195762,
              48.950986666617
            ],
            [
              7.5783945474788,
              48.957398722989
            ],
            [
              7.5845194350486,
              48.966802481603
            ],
            [
              7.5814073737185,
              48.971055051207
            ],
            [
              7.5923311699453,
              48.984840647736
            ],
            [
              7.5912105405094,
              48.993725181781
            ],
            [
              7.6070069675937,
              49.009582660743
            ],
            [
              7.6095521813444,
              49.021232064924
            ],
            [
              7.6347067798308,
              49.042968049126
            ],
            [
              7.6352815933424,
              49.054164206261
            ]
          ]
        ]
      },
      "properties": {
        "code": "67",
        "nom": "Bas-Rhin",
        "population": 1148073,
        "delitspour1000": "4,501",
        "nombremosque": 49,
        "parentsEtrangers": "66,4",
        "benefRsa": 52434,
        "eoliennes": 13
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              7.1982872111206,
              48.310471263573
            ],
            [
              7.230714124293,
              48.308487586014
            ],
            [
              7.2484948814022,
              48.304198238868
            ],
            [
              7.2751834298867,
              48.305231193436
            ],
            [
              7.2824675936494,
              48.296405805217
            ],
            [
              7.290798196671,
              48.293839376705
            ],
            [
              7.3007048987286,
              48.280692265256
            ],
            [
              7.2918721296159,
              48.275361828732
            ],
            [
              7.2997802529041,
              48.268658649324
            ],
            [
              7.3126558596123,
              48.268538116429
            ],
            [
              7.3134866356341,
              48.256385343496
            ],
            [
              7.3183027579228,
              48.249144714543
            ],
            [
              7.3292781377806,
              48.245543962665
            ],
            [
              7.3485690445532,
              48.247191676747
            ],
            [
              7.3823367547394,
              48.238043999056
            ],
            [
              7.3979867918269,
              48.232505718051
            ],
            [
              7.4089071495429,
              48.224105878071
            ],
            [
              7.435000853779,
              48.217885231493
            ],
            [
              7.4446629261788,
              48.217420847786
            ],
            [
              7.455513612234,
              48.209784876976
            ],
            [
              7.4769430706019,
              48.203533090919
            ],
            [
              7.4730897372503,
              48.196537826413
            ],
            [
              7.4705578806378,
              48.161010134
            ],
            [
              7.4774630193911,
              48.156563518085
            ],
            [
              7.5125316146576,
              48.152317863686
            ],
            [
              7.5203336674043,
              48.149495287589
            ],
            [
              7.5149853866599,
              48.139639080259
            ],
            [
              7.5193723560282,
              48.126108118767
            ],
            [
              7.5528674428158,
              48.121828718961
            ],
            [
              7.5773157720816,
              48.120371112534
            ],
            [
              7.5799590690256,
              48.10602146786
            ],
            [
              7.5774082960332,
              48.095951430851
            ],
            [
              7.5692340186038,
              48.081401774521
            ],
            [
              7.5738062890481,
              48.05303432392
            ],
            [
              7.5684900054594,
              48.03538248569
            ],
            [
              7.5716202896502,
              48.030376143149
            ],
            [
              7.6054362344578,
              48.003757828011
            ],
            [
              7.6186203571286,
              47.985903661407
            ],
            [
              7.6220859200517,
              47.972272417851
            ],
            [
              7.6088349991317,
              47.95317720329
            ],
            [
              7.5936684690443,
              47.94169373068
            ],
            [
              7.5832939822001,
              47.931118516785
            ],
            [
              7.5805114095847,
              47.924033439912
            ],
            [
              7.5835051671331,
              47.903574715879
            ],
            [
              7.5769428779391,
              47.891476151582
            ],
            [
              7.5567072919531,
              47.879941001941
            ],
            [
              7.5571893871238,
              47.863835316778
            ],
            [
              7.5635516323114,
              47.852137004105
            ],
            [
              7.5614656894138,
              47.838677705698
            ],
            [
              7.5497020250415,
              47.822574606584
            ],
            [
              7.5453101034285,
              47.811279821706
            ],
            [
              7.5312612420762,
              47.78675983822
            ],
            [
              7.5310556236986,
              47.770364762321
            ],
            [
              7.5480799502254,
              47.739589288712
            ],
            [
              7.5434728959465,
              47.721949347317
            ],
            [
              7.5347268810595,
              47.714498714804
            ],
            [
              7.513751772485,
              47.702815362123
            ],
            [
              7.5125727857792,
              47.694845299819
            ],
            [
              7.5199928711004,
              47.682662502885
            ],
            [
              7.5196030195489,
              47.667762233129
            ],
            [
              7.5223404693959,
              47.662321695948
            ],
            [
              7.5384262027619,
              47.648803157063
            ],
            [
              7.5664726424667,
              47.632895936057
            ],
            [
              7.5736049822678,
              47.616665207802
            ],
            [
              7.5927957926469,
              47.601779854527
            ],
            [
              7.5847073872051,
              47.577266068409
            ],
            [
              7.5664182305498,
              47.577551201666
            ],
            [
              7.5586696424832,
              47.573257991504
            ],
            [
              7.5571945026924,
              47.565031495571
            ],
            [
              7.503411179747,
              47.54197564763
            ],
            [
              7.4990407869528,
              47.535364446849
            ],
            [
              7.5013916497461,
              47.528725219131
            ],
            [
              7.5110723875321,
              47.529059304058
            ],
            [
              7.5192608270666,
              47.534552788408
            ],
            [
              7.5314262789643,
              47.528284428742
            ],
            [
              7.5277089588457,
              47.521554981262
            ],
            [
              7.5054524965593,
              47.514369511354
            ],
            [
              7.5105092147616,
              47.504115173213
            ],
            [
              7.5067572708125,
              47.495629213014
            ],
            [
              7.48685795056,
              47.481732320167
            ],
            [
              7.4709073051131,
              47.480413251158
            ],
            [
              7.4613662600979,
              47.489038177399
            ],
            [
              7.450258499575,
              47.490385814784
            ],
            [
              7.4345574218437,
              47.497928259116
            ],
            [
              7.4226892243323,
              47.484388248594
            ],
            [
              7.4556328646187,
              47.472976694654
            ],
            [
              7.4450127609649,
              47.461705406933
            ],
            [
              7.4300598912161,
              47.459277177538
            ],
            [
              7.4225922859548,
              47.447887544888
            ],
            [
              7.403410043504,
              47.435524491145
            ],
            [
              7.3809450743678,
              47.43186353224
            ],
            [
              7.3559606165998,
              47.433851805177
            ],
            [
              7.3424777485662,
              47.438085917762
            ],
            [
              7.3259229750377,
              47.439342239117
            ],
            [
              7.3017355889987,
              47.437813631568
            ],
            [
              7.2777859777968,
              47.431673598095
            ],
            [
              7.2688212417913,
              47.426648175648
            ],
            [
              7.2462981009916,
              47.422198455938
            ],
            [
              7.2357678622845,
              47.436929813322
            ],
            [
              7.2184754685329,
              47.438111100818
            ],
            [
              7.2066841689176,
              47.434823362792
            ],
            [
              7.1811906725339,
              47.441480675914
            ],
            [
              7.1730742135766,
              47.447181789836
            ],
            [
              7.1782210667095,
              47.458502082925
            ],
            [
              7.1792412182665,
              47.469724384789
            ],
            [
              7.1966955177699,
              47.493482758971
            ],
            [
              7.1675377459529,
              47.48940851862
            ],
            [
              7.1589517583051,
              47.490757673188
            ],
            [
              7.1491338904597,
              47.497609012469
            ],
            [
              7.1303408019013,
              47.503028568514
            ],
            [
              7.1378734301853,
              47.510602312684
            ],
            [
              7.1398015507652,
              47.526066534747
            ],
            [
              7.132779554549,
              47.539650016902
            ],
            [
              7.1192027781559,
              47.547271468
            ],
            [
              7.1062174347916,
              47.551377585032
            ],
            [
              7.1084182862429,
              47.563026634543
            ],
            [
              7.1002437787202,
              47.572348664104
            ],
            [
              7.0941662771964,
              47.57363395328
            ],
            [
              7.0863359637946,
              47.585555682513
            ],
            [
              7.0862717367469,
              47.592564849851
            ],
            [
              7.0772390032257,
              47.598070781665
            ],
            [
              7.0647156944354,
              47.601059487848
            ],
            [
              7.0565070741684,
              47.598940564806
            ],
            [
              7.0405190481699,
              47.600960102238
            ],
            [
              7.0254214022991,
              47.592680119451
            ],
            [
              7.005954748644,
              47.602446062879
            ],
            [
              7.0105070572794,
              47.605019604987
            ],
            [
              7.0049789727138,
              47.619379272859
            ],
            [
              7.0180362046747,
              47.642567039669
            ],
            [
              7.0188657902769,
              47.650671462441
            ],
            [
              7.0391920612816,
              47.650571191522
            ],
            [
              7.034430907222,
              47.661170659864
            ],
            [
              7.0463174145898,
              47.671501618284
            ],
            [
              7.0389466543978,
              47.67798172185
            ],
            [
              7.0480463579808,
              47.682334383614
            ],
            [
              7.0388002853848,
              47.687529274973
            ],
            [
              7.037634862268,
              47.694693941352
            ],
            [
              7.0264858913282,
              47.701000683113
            ],
            [
              7.0374215531951,
              47.721642148751
            ],
            [
              7.0194654399739,
              47.736016178013
            ],
            [
              7.01596189708,
              47.743229997088
            ],
            [
              6.9881803418573,
              47.747811458115
            ],
            [
              6.9695414653395,
              47.753879554684
            ],
            [
              6.9425641239541,
              47.768619181766
            ],
            [
              6.9239978247236,
              47.770469646258
            ],
            [
              6.9093369154314,
              47.77760076122
            ],
            [
              6.9020863665368,
              47.776444142208
            ],
            [
              6.8634725871955,
              47.785157366739
            ],
            [
              6.8428287756472,
              47.812907241333
            ],
            [
              6.8461756187967,
              47.822942631655
            ],
            [
              6.8612316827823,
              47.825591254937
            ],
            [
              6.8975967260823,
              47.83756300421
            ],
            [
              6.9067918762762,
              47.845648756197
            ],
            [
              6.9200409082836,
              47.850285895261
            ],
            [
              6.9069365309395,
              47.868996625385
            ],
            [
              6.9063144640838,
              47.879797898356
            ],
            [
              6.8978126882661,
              47.884262639149
            ],
            [
              6.900218945286,
              47.891194790477
            ],
            [
              6.9184576050125,
              47.898407433189
            ],
            [
              6.9278167769039,
              47.908213591002
            ],
            [
              6.9259269609826,
              47.917301975801
            ],
            [
              6.9131760329106,
              47.919378547089
            ],
            [
              6.9241258314971,
              47.927416308913
            ],
            [
              6.9254227078109,
              47.933851589775
            ],
            [
              6.9194216385109,
              47.944342124554
            ],
            [
              6.9264902251397,
              47.957681232146
            ],
            [
              6.9306819263176,
              47.971928947916
            ],
            [
              6.9437614605246,
              47.980377674244
            ],
            [
              6.9433524693585,
              47.99795898043
            ],
            [
              6.9529150495872,
              48.002942220653
            ],
            [
              6.9622340751476,
              48.002888003739
            ],
            [
              6.9815394627032,
              48.009420740178
            ],
            [
              6.9894507346272,
              48.018430704581
            ],
            [
              7.0018860834717,
              48.022394510938
            ],
            [
              7.015626999402,
              48.038015419147
            ],
            [
              7.0103277742221,
              48.04187607679
            ],
            [
              7.0297963112116,
              48.071905133897
            ],
            [
              7.0374044731857,
              48.078086004732
            ],
            [
              7.0512918183322,
              48.082302500157
            ],
            [
              7.0713124724572,
              48.107574840418
            ],
            [
              7.0767075069295,
              48.108483024102
            ],
            [
              7.0839309358493,
              48.122199063245
            ],
            [
              7.0841613064662,
              48.129216503745
            ],
            [
              7.0698542106814,
              48.12947900779
            ],
            [
              7.0589137960953,
              48.137261832109
            ],
            [
              7.0776389366913,
              48.163575922596
            ],
            [
              7.0778429184518,
              48.171044574039
            ],
            [
              7.0853295403333,
              48.180875920481
            ],
            [
              7.1062830120448,
              48.194304277893
            ],
            [
              7.1077697063835,
              48.201766066981
            ],
            [
              7.1192521757392,
              48.212023004119
            ],
            [
              7.1341012954683,
              48.237762905718
            ],
            [
              7.1335541813458,
              48.245937002976
            ],
            [
              7.1469013034333,
              48.261679029333
            ],
            [
              7.1594844662169,
              48.268757347093
            ],
            [
              7.1595062808046,
              48.275287896649
            ],
            [
              7.1753401866571,
              48.29113941135
            ],
            [
              7.1724433560818,
              48.29748374247
            ],
            [
              7.1936148623215,
              48.302025342222
            ],
            [
              7.1982872111206,
              48.310471263573
            ]
          ]
        ]
      },
      "properties": {
        "code": "68",
        "nom": "Haut-Rhin",
        "population": 767842,
        "delitspour1000": "5,008",
        "nombremosque": 38,
        "parentsEtrangers": "67,1",
        "benefRsa": 31576,
        "eoliennes": 3
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1.3796981484469,
              50.065011890414
            ],
            [
              1.3924400221587,
              50.060056426893
            ],
            [
              1.4088347782602,
              50.057247377816
            ],
            [
              1.4235940727692,
              50.070851596042
            ],
            [
              1.4464534693489,
              50.069324171563
            ],
            [
              1.4591516246417,
              50.062494643478
            ],
            [
              1.455286116297,
              50.056298876577
            ],
            [
              1.4564566095824,
              50.037832374442
            ],
            [
              1.4734926140971,
              50.033599846345
            ],
            [
              1.4933105925107,
              50.017740486409
            ],
            [
              1.50103897683,
              50.018915984928
            ],
            [
              1.5180154200133,
              50.00910228757
            ],
            [
              1.5270192186573,
              49.996530516122
            ],
            [
              1.5465597760538,
              49.987581734127
            ],
            [
              1.5582578959792,
              49.979389381286
            ],
            [
              1.5738903148936,
              49.973921649667
            ],
            [
              1.5940092903756,
              49.949050560009
            ],
            [
              1.6182113660007,
              49.937822139755
            ],
            [
              1.6784507673607,
              49.918130568802
            ],
            [
              1.6932946158985,
              49.895601909585
            ],
            [
              1.7124562091314,
              49.886444322648
            ],
            [
              1.7117443909555,
              49.87371820092
            ],
            [
              1.7187325614081,
              49.865708141834
            ],
            [
              1.7195139243408,
              49.85406661499
            ],
            [
              1.724683271591,
              49.845733284166
            ],
            [
              1.7270527964865,
              49.828882279702
            ],
            [
              1.7373867941575,
              49.808209452072
            ],
            [
              1.7544750364007,
              49.790629917937
            ],
            [
              1.7579284899296,
              49.780795399895
            ],
            [
              1.7728042654107,
              49.77686925316
            ],
            [
              1.7844715649337,
              49.763592975395
            ],
            [
              1.7838342426767,
              49.758309270134
            ],
            [
              1.7471451565537,
              49.757054329335
            ],
            [
              1.7416291636029,
              49.751631106006
            ],
            [
              1.7409502199257,
              49.738588067086
            ],
            [
              1.7232343899458,
              49.730085190671
            ],
            [
              1.7121688003106,
              49.731959147918
            ],
            [
              1.7151877298233,
              49.712819627841
            ],
            [
              1.7119859825186,
              49.707713850498
            ],
            [
              1.6895744511517,
              49.694787428191
            ],
            [
              1.7045184506731,
              49.68092815534
            ],
            [
              1.7177073271231,
              49.684373173475
            ],
            [
              1.7214282927506,
              49.691450681445
            ],
            [
              1.7375343076769,
              49.700350145116
            ],
            [
              1.7506387449271,
              49.693804769865
            ],
            [
              1.7521845944048,
              49.680960782507
            ],
            [
              1.7251990095943,
              49.672636996929
            ],
            [
              1.72256553641,
              49.661514170983
            ],
            [
              1.7034537142049,
              49.645178379124
            ],
            [
              1.7046286111408,
              49.639706031745
            ],
            [
              1.7172522158626,
              49.633071609694
            ],
            [
              1.7216033847573,
              49.621992077342
            ],
            [
              1.7045833964597,
              49.60539714358
            ],
            [
              1.6951880268931,
              49.599725188693
            ],
            [
              1.7079026298891,
              49.59512101877
            ],
            [
              1.7094013142782,
              49.586713688098
            ],
            [
              1.7216170199559,
              49.588805803542
            ],
            [
              1.7147772726003,
              49.576403299976
            ],
            [
              1.7296224523484,
              49.561514722426
            ],
            [
              1.727181881084,
              49.541625548343
            ],
            [
              1.7446099134771,
              49.539601433398
            ],
            [
              1.7451293471854,
              49.531735853612
            ],
            [
              1.7309415306325,
              49.516127433413
            ],
            [
              1.7192339956449,
              49.508224831919
            ],
            [
              1.7254532581616,
              49.499781817922
            ],
            [
              1.7387428193977,
              49.499646659524
            ],
            [
              1.7437037722862,
              49.495260515275
            ],
            [
              1.7579593630441,
              49.508955782237
            ],
            [
              1.7718616459246,
              49.512848245546
            ],
            [
              1.79022549105,
              49.503468309638
            ],
            [
              1.7874800747665,
              49.493410636962
            ],
            [
              1.7741026603547,
              49.484110404638
            ],
            [
              1.7758155194349,
              49.475733185704
            ],
            [
              1.7663409121222,
              49.466149422943
            ],
            [
              1.7477144178281,
              49.459538435118
            ],
            [
              1.7512558538526,
              49.452315507953
            ],
            [
              1.7375744776703,
              49.448744348943
            ],
            [
              1.7325062090143,
              49.440346816415
            ],
            [
              1.7227989638177,
              49.433057939568
            ],
            [
              1.7207550257388,
              49.421790744656
            ],
            [
              1.7139306567281,
              49.409224915677
            ],
            [
              1.7045500554634,
              49.410702508293
            ],
            [
              1.698642071408,
              49.398715360789
            ],
            [
              1.6893918934249,
              49.399833597052
            ],
            [
              1.6796639751039,
              49.405979198472
            ],
            [
              1.6663794284045,
              49.404872512906
            ],
            [
              1.6592989506899,
              49.398985140911
            ],
            [
              1.63126059829,
              49.4101644841
            ],
            [
              1.6246392090745,
              49.414499717653
            ],
            [
              1.6069621918063,
              49.410851784225
            ],
            [
              1.5915230861532,
              49.426823837925
            ],
            [
              1.5754240690858,
              49.440139502679
            ],
            [
              1.5480395067027,
              49.440906427628
            ],
            [
              1.537389344223,
              49.43039797237
            ],
            [
              1.5283099451368,
              49.441990586983
            ],
            [
              1.5041681217892,
              49.438033275865
            ],
            [
              1.4947848961028,
              49.446555996217
            ],
            [
              1.4771804721454,
              49.451327801496
            ],
            [
              1.447264105952,
              49.45064317033
            ],
            [
              1.4357335585164,
              49.455147813822
            ],
            [
              1.412127551696,
              49.456496515022
            ],
            [
              1.3955347915735,
              49.454552714669
            ],
            [
              1.3934959752114,
              49.449228032324
            ],
            [
              1.3736977969193,
              49.452867133483
            ],
            [
              1.3609573928556,
              49.448195962709
            ],
            [
              1.3452388768398,
              49.448058599484
            ],
            [
              1.3380485043698,
              49.43878228156
            ],
            [
              1.3367318187267,
              49.42769114546
            ],
            [
              1.3096373647687,
              49.428733548322
            ],
            [
              1.3134720284489,
              49.419302373451
            ],
            [
              1.3080614529965,
              49.395354697053
            ],
            [
              1.2931901169732,
              49.394992930411
            ],
            [
              1.2854841472719,
              49.38817876933
            ],
            [
              1.2899210153359,
              49.375704998826
            ],
            [
              1.2817525580978,
              49.356800138676
            ],
            [
              1.272174418433,
              49.357075379049
            ],
            [
              1.2721367892739,
              49.346284740023
            ],
            [
              1.2186078643559,
              49.34861032434
            ],
            [
              1.1990258520385,
              49.350805240884
            ],
            [
              1.2007170029988,
              49.33921553952
            ],
            [
              1.1848482018314,
              49.338067349594
            ],
            [
              1.1827115617536,
              49.343841832128
            ],
            [
              1.1602570896098,
              49.341997772279
            ],
            [
              1.1541323790907,
              49.33549257124
            ],
            [
              1.135956149247,
              49.32510877111
            ],
            [
              1.1339907890446,
              49.309842015717
            ],
            [
              1.0929172750222,
              49.309969974333
            ],
            [
              1.0806844645154,
              49.30878433789
            ],
            [
              1.0481433809466,
              49.297882244778
            ],
            [
              1.0644196875088,
              49.281936004992
            ],
            [
              1.0520047369193,
              49.273124324455
            ],
            [
              1.0540655714671,
              49.268105682449
            ],
            [
              1.0411806449182,
              49.258523433694
            ],
            [
              1.0330368002648,
              49.260707371328
            ],
            [
              1.0133221517563,
              49.252262168305
            ],
            [
              0.99276212065971,
              49.253192391205
            ],
            [
              0.98616817899411,
              49.269508388285
            ],
            [
              0.97769464632566,
              49.27070383999
            ],
            [
              0.97079868730402,
              49.280532893554
            ],
            [
              0.9557712480057,
              49.271462930815
            ],
            [
              0.95773612006249,
              49.289339180562
            ],
            [
              0.93650102423199,
              49.31921212392
            ],
            [
              0.92569914870168,
              49.313416779579
            ],
            [
              0.90729313280231,
              49.307619218086
            ],
            [
              0.90921325896871,
              49.298377660408
            ],
            [
              0.90075665085202,
              49.297063659707
            ],
            [
              0.89249816086963,
              49.303066153881
            ],
            [
              0.89481428802281,
              49.316006224831
            ],
            [
              0.86283554220531,
              49.325694960317
            ],
            [
              0.85246768535533,
              49.326488531003
            ],
            [
              0.85233182532436,
              49.333091020365
            ],
            [
              0.86622900849809,
              49.342309783711
            ],
            [
              0.88759304231232,
              49.345911579885
            ],
            [
              0.90743826773733,
              49.340304135725
            ],
            [
              0.92140513379514,
              49.338551694977
            ],
            [
              0.93202913089827,
              49.344749683067
            ],
            [
              0.92321044150132,
              49.349902808708
            ],
            [
              0.92432525872208,
              49.358864437542
            ],
            [
              0.91829936141772,
              49.369854047652
            ],
            [
              0.91811535035589,
              49.385457817834
            ],
            [
              0.89994552150279,
              49.380147831204
            ],
            [
              0.89254570310317,
              49.372233966288
            ],
            [
              0.88351561750447,
              49.374775572952
            ],
            [
              0.88531880916794,
              49.383521989565
            ],
            [
              0.87033041806675,
              49.394166851087
            ],
            [
              0.86085285029658,
              49.395353751197
            ],
            [
              0.84531248738351,
              49.39200810179
            ],
            [
              0.81888027155234,
              49.398182878344
            ],
            [
              0.80423965947656,
              49.413719286552
            ],
            [
              0.79786974362259,
              49.417557183588
            ],
            [
              0.78382800946091,
              49.410574961906
            ],
            [
              0.77365720426929,
              49.418080731908
            ],
            [
              0.76486607576678,
              49.419583047737
            ],
            [
              0.73828625948595,
              49.408065043899
            ],
            [
              0.71643782065779,
              49.411320665886
            ],
            [
              0.7056003899825,
              49.402343816365
            ],
            [
              0.69045539241571,
              49.40650403573
            ],
            [
              0.66528473510118,
              49.401997722838
            ],
            [
              0.64947713542043,
              49.409189372744
            ],
            [
              0.65978237100252,
              49.416171083057
            ],
            [
              0.64090608427488,
              49.427676901532
            ],
            [
              0.63412440818502,
              49.434463954235
            ],
            [
              0.60625554588534,
              49.428693332289
            ],
            [
              0.58188184458619,
              49.433632944846
            ],
            [
              0.56492476734485,
              49.444750945735
            ],
            [
              0.52493488178974,
              49.477867578886
            ],
            [
              0.50251520739814,
              49.485111873749
            ],
            [
              0.49007537526958,
              49.483610306923
            ],
            [
              0.43152748913211,
              49.458951611128
            ],
            [
              0.40431210614422,
              49.451034141968
            ],
            [
              0.36879204470888,
              49.443962475083
            ],
            [
              0.33897883098918,
              49.440928520352
            ],
            [
              0.3393150124405,
              49.449872303637
            ],
            [
              0.27313585922231,
              49.453416220586
            ],
            [
              0.27142113093512,
              49.447328719148
            ],
            [
              0.25679707643476,
              49.446323719841
            ],
            [
              0.23825592404046,
              49.451159696807
            ],
            [
              0.21769119265823,
              49.452963282619
            ],
            [
              0.19246153744669,
              49.451459914922
            ],
            [
              0.1738896350055,
              49.456501326126
            ],
            [
              0.12123966998691,
              49.463194369813
            ],
            [
              0.11663179558052,
              49.468668951933
            ],
            [
              0.090696994632536,
              49.482114939562
            ],
            [
              0.093998128223838,
              49.493022491933
            ],
            [
              0.086909848300058,
              49.501426737567
            ],
            [
              0.069553018028324,
              49.506394515928
            ],
            [
              0.065609431053556,
              49.512574780191
            ],
            [
              0.074935648312722,
              49.536327644008
            ],
            [
              0.096662241910689,
              49.566865543968
            ],
            [
              0.10673209691871,
              49.583498812584
            ],
            [
              0.13691446853437,
              49.620054067093
            ],
            [
              0.1545404481146,
              49.648638840156
            ],
            [
              0.15041940420529,
              49.654089298171
            ],
            [
              0.15745336474727,
              49.662409366742
            ],
            [
              0.16435786084442,
              49.683595262249
            ],
            [
              0.16902493112731,
              49.690718055481
            ],
            [
              0.18958482856705,
              49.704795514512
            ],
            [
              0.21166902744322,
              49.714464279709
            ],
            [
              0.23734601222366,
              49.719026316153
            ],
            [
              0.28325599543364,
              49.73657572055
            ],
            [
              0.3054297000562,
              49.741647940405
            ],
            [
              0.32055583850936,
              49.741550113943
            ],
            [
              0.35492089191875,
              49.755006661783
            ],
            [
              0.36447312419198,
              49.764320270499
            ],
            [
              0.3742717461751,
              49.768687702238
            ],
            [
              0.40291800519761,
              49.776954241768
            ],
            [
              0.42846422682965,
              49.786277467203
            ],
            [
              0.48354180865604,
              49.808256679169
            ],
            [
              0.50625084522888,
              49.819729645976
            ],
            [
              0.52275149356329,
              49.824531278036
            ],
            [
              0.53443430957787,
              49.833749077935
            ],
            [
              0.57293876242844,
              49.849717051188
            ],
            [
              0.63455572059055,
              49.86165150765
            ],
            [
              0.64204209247477,
              49.864190596157
            ],
            [
              0.67449107487386,
              49.867359871226
            ],
            [
              0.70196726010642,
              49.871881124641
            ],
            [
              0.73355874170037,
              49.870614655047
            ],
            [
              0.76972675735512,
              49.872091970828
            ],
            [
              0.79097157704249,
              49.875749307356
            ],
            [
              0.83667159834277,
              49.889522498512
            ],
            [
              0.88434934728582,
              49.896715247569
            ],
            [
              0.92979339254159,
              49.906736769842
            ],
            [
              0.95467186229888,
              49.91831807638
            ],
            [
              0.96318320357221,
              49.919860176009
            ],
            [
              1.0200494254731,
              49.916107227482
            ],
            [
              1.065897535547,
              49.925609301563
            ],
            [
              1.0809062558413,
              49.932292873025
            ],
            [
              1.1109703384695,
              49.939082116247
            ],
            [
              1.1629209730147,
              49.957022281774
            ],
            [
              1.1845869056976,
              49.966360150471
            ],
            [
              1.1943755832405,
              49.968033261783
            ],
            [
              1.2080962672336,
              49.979831426538
            ],
            [
              1.2332915838912,
              49.988728375641
            ],
            [
              1.2997853981801,
              50.029578582167
            ],
            [
              1.3170167914747,
              50.03625056502
            ],
            [
              1.3324268900416,
              50.047124707032
            ],
            [
              1.3672027614548,
              50.062608452248
            ],
            [
              1.3796981484469,
              50.065011890414
            ]
          ]
        ]
      },
      "properties": {
        "code": "76",
        "nom": "Seine-Maritime",
        "population": 1254739,
        "delitspour1000": "5,426",
        "nombremosque": 36,
        "parentsEtrangers": "76,4",
        "benefRsa": 81435,
        "eoliennes": 200
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.9363157566366,
              48.163391744884
            ],
            [
              2.9347445709406,
              48.178820824383
            ],
            [
              2.9507427471906,
              48.190202971569
            ],
            [
              2.9695465243882,
              48.193835497978
            ],
            [
              2.9744704228122,
              48.205550154363
            ],
            [
              2.9889655342085,
              48.209014778073
            ],
            [
              3.0051596957479,
              48.207665501246
            ],
            [
              3.0189243879447,
              48.231774581507
            ],
            [
              3.0185660113854,
              48.235192866522
            ],
            [
              3.0314619630164,
              48.248879164003
            ],
            [
              3.047565503762,
              48.249696163103
            ],
            [
              3.0436326434308,
              48.272020556142
            ],
            [
              3.0249639004196,
              48.27600913901
            ],
            [
              3.0297705668286,
              48.285841287403
            ],
            [
              3.0204148507047,
              48.293439730003
            ],
            [
              3.0272557445779,
              48.300368070976
            ],
            [
              3.0158983096866,
              48.307897241414
            ],
            [
              3.0365741164856,
              48.326028248436
            ],
            [
              3.0374902381586,
              48.343151149375
            ],
            [
              3.0495229427782,
              48.360116755131
            ],
            [
              3.0605482162395,
              48.357442256262
            ],
            [
              3.0878411046476,
              48.358744110403
            ],
            [
              3.1034153439925,
              48.349510589018
            ],
            [
              3.1182555596676,
              48.366787852825
            ],
            [
              3.1398647131279,
              48.372599662445
            ],
            [
              3.1677335139977,
              48.371858146235
            ],
            [
              3.1796714598679,
              48.375720974057
            ],
            [
              3.184946555144,
              48.368130335436
            ],
            [
              3.2018505500023,
              48.364015470529
            ],
            [
              3.2328491246009,
              48.370332895515
            ],
            [
              3.254386262298,
              48.36502005161
            ],
            [
              3.2647767822592,
              48.374621237382
            ],
            [
              3.2823921646024,
              48.377519839785
            ],
            [
              3.3051586355523,
              48.372876819681
            ],
            [
              3.3093216217612,
              48.376980286994
            ],
            [
              3.3301099431753,
              48.372120787109
            ],
            [
              3.3651606301927,
              48.372282482296
            ],
            [
              3.3627847824483,
              48.382576787133
            ],
            [
              3.3672130286667,
              48.394317453742
            ],
            [
              3.3833174045242,
              48.39970411104
            ],
            [
              3.4020328168494,
              48.389706108674
            ],
            [
              3.4147888224632,
              48.390268550005
            ],
            [
              3.4132728603068,
              48.376300840268
            ],
            [
              3.4216806196631,
              48.371727783356
            ],
            [
              3.4275090718968,
              48.35997574793
            ],
            [
              3.4432494908564,
              48.367382718502
            ],
            [
              3.4525885512991,
              48.374388436099
            ],
            [
              3.4704759274371,
              48.374667861079
            ],
            [
              3.4748808608132,
              48.369266510477
            ],
            [
              3.4981541228456,
              48.369098088724
            ],
            [
              3.512139360519,
              48.360812483948
            ],
            [
              3.530465379844,
              48.342613415975
            ],
            [
              3.5451019683919,
              48.334543016882
            ],
            [
              3.5440725252239,
              48.319671593536
            ],
            [
              3.5634067310877,
              48.321440919656
            ],
            [
              3.566569257901,
              48.307425850859
            ],
            [
              3.5879641370453,
              48.300806415009
            ],
            [
              3.5857412014087,
              48.29008425533
            ],
            [
              3.5777963307254,
              48.284593393925
            ],
            [
              3.5881978504359,
              48.280068510017
            ],
            [
              3.6117709302505,
              48.274410870011
            ],
            [
              3.6168016799253,
              48.271343511852
            ],
            [
              3.6243226631674,
              48.254526756748
            ],
            [
              3.6138531799397,
              48.24927733618
            ],
            [
              3.6000799784034,
              48.237038015917
            ],
            [
              3.6046865808402,
              48.229884548214
            ],
            [
              3.6136268888051,
              48.232260725333
            ],
            [
              3.6216106602921,
              48.225744034142
            ],
            [
              3.6140713717985,
              48.220115584573
            ],
            [
              3.6111516430878,
              48.21192986819
            ],
            [
              3.6009970264951,
              48.20380086628
            ],
            [
              3.5751823496995,
              48.188742267274
            ],
            [
              3.5941772424762,
              48.178873395497
            ],
            [
              3.6195451473546,
              48.190784507919
            ],
            [
              3.6318850111199,
              48.189390079748
            ],
            [
              3.6414996909345,
              48.183932963824
            ],
            [
              3.6506538178638,
              48.168228410946
            ],
            [
              3.6595795108361,
              48.15965193897
            ],
            [
              3.6678669028091,
              48.139211019047
            ],
            [
              3.6887554871535,
              48.144278615274
            ],
            [
              3.6936218940592,
              48.14799982015
            ],
            [
              3.7050157631116,
              48.144314849936
            ],
            [
              3.7223449458636,
              48.156837755739
            ],
            [
              3.7141712164177,
              48.170603029762
            ],
            [
              3.7184640557735,
              48.175364157847
            ],
            [
              3.74029665141,
              48.169709598744
            ],
            [
              3.7519004546533,
              48.161296410098
            ],
            [
              3.7548293235379,
              48.150244530897
            ],
            [
              3.7398043096241,
              48.138688236138
            ],
            [
              3.7397131881194,
              48.13275416124
            ],
            [
              3.7569100878728,
              48.125307470037
            ],
            [
              3.7680208404533,
              48.1337610774
            ],
            [
              3.7768827441699,
              48.124996720039
            ],
            [
              3.8049696872895,
              48.102546851574
            ],
            [
              3.7999733080105,
              48.096695524271
            ],
            [
              3.7985814686078,
              48.086366869837
            ],
            [
              3.8067979988969,
              48.083861354137
            ],
            [
              3.8201608894357,
              48.067459938552
            ],
            [
              3.82635868236,
              48.063603655356
            ],
            [
              3.827059329799,
              48.047441428512
            ],
            [
              3.8219783557759,
              48.043916868834
            ],
            [
              3.8321550263001,
              48.036232814814
            ],
            [
              3.8425469054526,
              48.036189501683
            ],
            [
              3.850085900943,
              48.02786301681
            ],
            [
              3.8706144760895,
              48.015633804416
            ],
            [
              3.8700007142194,
              48.002599720655
            ],
            [
              3.8619707432349,
              48.004325555172
            ],
            [
              3.8398152772889,
              48.003890008543
            ],
            [
              3.8500279607807,
              47.983791517043
            ],
            [
              3.8643090405558,
              47.98423551782
            ],
            [
              3.8618055931881,
              47.976443001861
            ],
            [
              3.8783021550775,
              47.979419574124
            ],
            [
              3.885413743137,
              48.00032841816
            ],
            [
              3.9001846571879,
              47.997906103474
            ],
            [
              3.9146958839434,
              47.975696995879
            ],
            [
              3.9056151344907,
              47.956390517208
            ],
            [
              3.9020918728463,
              47.939168233154
            ],
            [
              3.8940363766278,
              47.929291041133
            ],
            [
              3.9124676793978,
              47.930259049768
            ],
            [
              3.9264502525997,
              47.934566873068
            ],
            [
              3.9403268061141,
              47.933140821871
            ],
            [
              3.9565980726161,
              47.934513395738
            ],
            [
              3.9859571275878,
              47.930640025592
            ],
            [
              4.0055897042792,
              47.942010075901
            ],
            [
              4.0122015583229,
              47.933099817884
            ],
            [
              4.0256492301283,
              47.928321965076
            ],
            [
              4.0315462267821,
              47.933180877458
            ],
            [
              4.0435079889664,
              47.928107993449
            ],
            [
              4.0540462390611,
              47.92948006618
            ],
            [
              4.0563117031209,
              47.941500284277
            ],
            [
              4.0612633895691,
              47.945564277098
            ],
            [
              4.0779088672194,
              47.942844108199
            ],
            [
              4.0929746686253,
              47.942978558738
            ],
            [
              4.091392975551,
              47.929644880288
            ],
            [
              4.111781787339,
              47.926998395128
            ],
            [
              4.129354902453,
              47.936039992343
            ],
            [
              4.1418393873722,
              47.937107385043
            ],
            [
              4.1459926719811,
              47.947950206748
            ],
            [
              4.1663202417186,
              47.959799899508
            ],
            [
              4.1814937489139,
              47.957133674703
            ],
            [
              4.185174863551,
              47.953252557601
            ],
            [
              4.185389386493,
              47.940250187972
            ],
            [
              4.201887052952,
              47.94115816132
            ],
            [
              4.2078363901654,
              47.946594650302
            ],
            [
              4.1991540335482,
              47.96994144352
            ],
            [
              4.2127809206222,
              47.971725942982
            ],
            [
              4.2284618067273,
              47.969156456849
            ],
            [
              4.2221403072235,
              47.949693241055
            ],
            [
              4.2362857449326,
              47.940458371541
            ],
            [
              4.2481126500816,
              47.929257212053
            ],
            [
              4.265592811732,
              47.924112631788
            ],
            [
              4.293421363566,
              47.925673564836
            ],
            [
              4.3033132488763,
              47.92172991059
            ],
            [
              4.3111459638235,
              47.903921001729
            ],
            [
              4.2989793260024,
              47.900526985379
            ],
            [
              4.2773336988144,
              47.8994494369
            ],
            [
              4.2685683333715,
              47.888372607038
            ],
            [
              4.2470482432436,
              47.877638329975
            ],
            [
              4.2473760643948,
              47.873765353106
            ],
            [
              4.2645564699717,
              47.873707495084
            ],
            [
              4.2609955625806,
              47.843854295691
            ],
            [
              4.2730539993782,
              47.839246861583
            ],
            [
              4.2886856732776,
              47.846408481652
            ],
            [
              4.3008005675725,
              47.848768970558
            ],
            [
              4.324395709202,
              47.847155648804
            ],
            [
              4.3287631926776,
              47.840693773361
            ],
            [
              4.3232258596381,
              47.832017055085
            ],
            [
              4.3250090174552,
              47.819031413937
            ],
            [
              4.3206382167009,
              47.811099221491
            ],
            [
              4.3403007872795,
              47.799562997605
            ],
            [
              4.3272273544323,
              47.785662261899
            ],
            [
              4.3346307974616,
              47.778473219282
            ],
            [
              4.3303640093148,
              47.773237825007
            ],
            [
              4.3324690785962,
              47.756513140603
            ],
            [
              4.3272829796155,
              47.753407951941
            ],
            [
              4.3103438146779,
              47.751717410306
            ],
            [
              4.3021591344662,
              47.741463998395
            ],
            [
              4.28965009284,
              47.733173292755
            ],
            [
              4.28109760531,
              47.738129038962
            ],
            [
              4.2634454695839,
              47.742335646049
            ],
            [
              4.2581695167841,
              47.727399313731
            ],
            [
              4.2394621052188,
              47.72442226378
            ],
            [
              4.2404739373478,
              47.717343411719
            ],
            [
              4.2496038685001,
              47.715439608463
            ],
            [
              4.2675045906304,
              47.705472895566
            ],
            [
              4.2618319572728,
              47.694230622506
            ],
            [
              4.2722767556566,
              47.686500050892
            ],
            [
              4.2684282857929,
              47.681413465978
            ],
            [
              4.2515508653348,
              47.6821426642
            ],
            [
              4.2430575387781,
              47.677941167167
            ],
            [
              4.2266142169595,
              47.678209364711
            ],
            [
              4.2261470792391,
              47.670704885971
            ],
            [
              4.2491192448708,
              47.659488252158
            ],
            [
              4.2273012263527,
              47.64140122463
            ],
            [
              4.2239953304579,
              47.632638188244
            ],
            [
              4.2123839956801,
              47.627967476689
            ],
            [
              4.2130600290023,
              47.607557117092
            ],
            [
              4.1971614419031,
              47.598027677294
            ],
            [
              4.1871532602349,
              47.581813369291
            ],
            [
              4.1864656579161,
              47.56972399776
            ],
            [
              4.17512941132,
              47.553259149829
            ],
            [
              4.1626364264347,
              47.553276326624
            ],
            [
              4.1459574437946,
              47.539592193245
            ],
            [
              4.1498240051344,
              47.532199170987
            ],
            [
              4.1382576488316,
              47.519937045083
            ],
            [
              4.115506867574,
              47.515006715898
            ],
            [
              4.1123998387742,
              47.503493470873
            ],
            [
              4.1194715157186,
              47.488629204748
            ],
            [
              4.1172300361255,
              47.479171921881
            ],
            [
              4.1302384089683,
              47.47136897141
            ],
            [
              4.119422572118,
              47.4435543317
            ],
            [
              4.098632645676,
              47.430467101809
            ],
            [
              4.0868811850708,
              47.440047425464
            ],
            [
              4.0837377590715,
              47.433167159236
            ],
            [
              4.0840420381257,
              47.420205763943
            ],
            [
              4.0660574486622,
              47.407250411398
            ],
            [
              4.0669171960011,
              47.4010746983
            ],
            [
              4.0883019049265,
              47.396524552433
            ],
            [
              4.0893960395146,
              47.391514100923
            ],
            [
              4.076217185536,
              47.383480206705
            ],
            [
              4.0800982417729,
              47.376100519928
            ],
            [
              4.0879063679202,
              47.372327483396
            ],
            [
              4.1066314424005,
              47.375866245463
            ],
            [
              4.1059989277452,
              47.362739565985
            ],
            [
              4.0979411189686,
              47.35534975233
            ],
            [
              4.1076656259598,
              47.345914946222
            ],
            [
              4.1060805347332,
              47.339255135806
            ],
            [
              4.0926517884381,
              47.337435759593
            ],
            [
              4.0769427450309,
              47.340731753228
            ],
            [
              4.0501742581832,
              47.340237695425
            ],
            [
              4.0398534030515,
              47.327428274054
            ],
            [
              4.0310437574521,
              47.327153002575
            ],
            [
              4.0287589499002,
              47.315579997017
            ],
            [
              3.9992678352806,
              47.312769315752
            ],
            [
              3.9730885348498,
              47.33271185193
            ],
            [
              3.9653450094109,
              47.365233671604
            ],
            [
              3.9734249529811,
              47.372878988697
            ],
            [
              3.9706766676387,
              47.378316249635
            ],
            [
              3.9482193285937,
              47.393475824008
            ],
            [
              3.9442431429877,
              47.380215683342
            ],
            [
              3.9328934573278,
              47.379383639236
            ],
            [
              3.9087974428289,
              47.382052052892
            ],
            [
              3.895717835648,
              47.374132179853
            ],
            [
              3.8867653218202,
              47.364580537895
            ],
            [
              3.8714185455276,
              47.367349720507
            ],
            [
              3.8656326118794,
              47.382675860165
            ],
            [
              3.8599101177175,
              47.388688854649
            ],
            [
              3.8635262780677,
              47.395838910921
            ],
            [
              3.8767842133428,
              47.397282743871
            ],
            [
              3.8933359684125,
              47.410556196782
            ],
            [
              3.8923281117228,
              47.4191622459
            ],
            [
              3.8799100996303,
              47.429809529421
            ],
            [
              3.8515038925646,
              47.435582552834
            ],
            [
              3.8517283415933,
              47.427167259745
            ],
            [
              3.8456032412138,
              47.411769134174
            ],
            [
              3.8217810028721,
              47.402799666341
            ],
            [
              3.8298201650838,
              47.391646077629
            ],
            [
              3.8283342971606,
              47.382308415441
            ],
            [
              3.8184735032465,
              47.380186131386
            ],
            [
              3.8031761531432,
              47.387487658725
            ],
            [
              3.7856408128509,
              47.390542286733
            ],
            [
              3.782475190451,
              47.399359950142
            ],
            [
              3.7742634274104,
              47.405933574958
            ],
            [
              3.7405548374236,
              47.396496212065
            ],
            [
              3.7262902213831,
              47.398242621047
            ],
            [
              3.7147863473095,
              47.406836442513
            ],
            [
              3.7062094210682,
              47.421838077847
            ],
            [
              3.6837300861118,
              47.433876012809
            ],
            [
              3.6733668814352,
              47.435660360739
            ],
            [
              3.6789862107814,
              47.445536409296
            ],
            [
              3.6745779225073,
              47.449545490805
            ],
            [
              3.6604883896309,
              47.448290372222
            ],
            [
              3.6156845029023,
              47.457496632488
            ],
            [
              3.6178481856464,
              47.46475971622
            ],
            [
              3.6002843064236,
              47.46535750082
            ],
            [
              3.595311510798,
              47.457000352478
            ],
            [
              3.5870681198871,
              47.456189719915
            ],
            [
              3.5801093743194,
              47.464900736341
            ],
            [
              3.5800968902369,
              47.478574775509
            ],
            [
              3.5758753501346,
              47.482488154729
            ],
            [
              3.5806141829017,
              47.497865949973
            ],
            [
              3.5550836765038,
              47.50178014282
            ],
            [
              3.5399989778474,
              47.515656140061
            ],
            [
              3.5218208657468,
              47.522230076619
            ],
            [
              3.5135076422025,
              47.527667101917
            ],
            [
              3.509300758364,
              47.552178039366
            ],
            [
              3.5046096251729,
              47.557936911485
            ],
            [
              3.4923652081816,
              47.560128896705
            ],
            [
              3.4949537927697,
              47.526459212987
            ],
            [
              3.4901941094846,
              47.496821356122
            ],
            [
              3.4878750791397,
              47.493920811494
            ],
            [
              3.4590177645189,
              47.500770495683
            ],
            [
              3.4456297303673,
              47.511198743003
            ],
            [
              3.4269630402639,
              47.507417701239
            ],
            [
              3.3972879668438,
              47.508862995228
            ],
            [
              3.3818604374665,
              47.488897471453
            ],
            [
              3.3619513810285,
              47.485342328201
            ],
            [
              3.3463120491845,
              47.472435421254
            ],
            [
              3.3070499116063,
              47.494719529456
            ],
            [
              3.2971098501411,
              47.491879163894
            ],
            [
              3.2852475606666,
              47.503940436698
            ],
            [
              3.2786194274233,
              47.496933384766
            ],
            [
              3.251061319522,
              47.48824819872
            ],
            [
              3.2354836014321,
              47.489179997681
            ],
            [
              3.227258607608,
              47.501297257437
            ],
            [
              3.2046478507551,
              47.522967246062
            ],
            [
              3.1872116825743,
              47.52341602306
            ],
            [
              3.1651894180107,
              47.517498399368
            ],
            [
              3.1610555018698,
              47.524949345327
            ],
            [
              3.1388983089897,
              47.537834657581
            ],
            [
              3.1220506256694,
              47.541050819435
            ],
            [
              3.1162202729539,
              47.569244554269
            ],
            [
              3.120738729187,
              47.578209745666
            ],
            [
              3.1020032714546,
              47.587958865747
            ],
            [
              3.0805624202337,
              47.585489497365
            ],
            [
              3.0591969906863,
              47.56884905721
            ],
            [
              3.03461087264,
              47.563962229793
            ],
            [
              3.0256394695906,
              47.558753676789
            ],
            [
              3.0099789475349,
              47.560284494731
            ],
            [
              2.9875296099181,
              47.568963322468
            ],
            [
              2.9765353538906,
              47.569424295927
            ],
            [
              2.9622680864494,
              47.576787199846
            ],
            [
              2.9644517577136,
              47.581632358212
            ],
            [
              2.954982923175,
              47.590408879602
            ],
            [
              2.9480691732296,
              47.590534725553
            ],
            [
              2.9379937136807,
              47.598885605034
            ],
            [
              2.9452166853722,
              47.608449329011
            ],
            [
              2.9357687458876,
              47.619831796752
            ],
            [
              2.9362905168585,
              47.636648702788
            ],
            [
              2.9542289513333,
              47.64577418642
            ],
            [
              2.9369444627222,
              47.659131595543
            ],
            [
              2.926238261078,
              47.660085723292
            ],
            [
              2.9181562549963,
              47.669794968987
            ],
            [
              2.9231151407358,
              47.680558801908
            ],
            [
              2.9037123946566,
              47.695095946597
            ],
            [
              2.8829409210413,
              47.702615088236
            ],
            [
              2.8841930930896,
              47.714211876689
            ],
            [
              2.8765799552305,
              47.719081526808
            ],
            [
              2.8585167014012,
              47.711991090287
            ],
            [
              2.8489690050712,
              47.716844895991
            ],
            [
              2.8487899744432,
              47.725884864654
            ],
            [
              2.8581457165416,
              47.745650029901
            ],
            [
              2.8566700654285,
              47.760929175798
            ],
            [
              2.8701769520741,
              47.764868196886
            ],
            [
              2.893986958474,
              47.764757164259
            ],
            [
              2.9093762293739,
              47.769314365231
            ],
            [
              2.9350564314568,
              47.763249875579
            ],
            [
              2.988226602321,
              47.78603448946
            ],
            [
              3.0237994278176,
              47.786550178612
            ],
            [
              3.0281204473987,
              47.800645366032
            ],
            [
              3.0229373322912,
              47.812772400995
            ],
            [
              3.0154699273932,
              47.813535934414
            ],
            [
              3.0125313015817,
              47.834373232486
            ],
            [
              3.0310783842835,
              47.837952684286
            ],
            [
              3.0338278605646,
              47.843872107669
            ],
            [
              3.0241574870815,
              47.860432544378
            ],
            [
              3.0053590366439,
              47.864049253797
            ],
            [
              3.0020156817175,
              47.86910456894
            ],
            [
              3.0113128689564,
              47.874942218413
            ],
            [
              3.0072263096695,
              47.895290955742
            ],
            [
              3.0102886119028,
              47.904716972369
            ],
            [
              3.0251429746145,
              47.905395103831
            ],
            [
              3.0369085970173,
              47.910046599044
            ],
            [
              3.0501029428736,
              47.911450149596
            ],
            [
              3.049851715745,
              47.917612271733
            ],
            [
              3.0646126208988,
              47.930516710244
            ],
            [
              3.0784762596905,
              47.93141901745
            ],
            [
              3.0813886854655,
              47.938303435109
            ],
            [
              3.0959562152001,
              47.94648282966
            ],
            [
              3.1052652915151,
              47.946939230434
            ],
            [
              3.1177801945629,
              47.964978280792
            ],
            [
              3.1284487900515,
              47.970976841524
            ],
            [
              3.1215086124269,
              47.99508051279
            ],
            [
              3.1245523220345,
              48.006031052733
            ],
            [
              3.1154271480689,
              48.012966036779
            ],
            [
              3.1040912941224,
              48.013541749122
            ],
            [
              3.1031098748357,
              48.024050754331
            ],
            [
              3.120444753158,
              48.027237026667
            ],
            [
              3.1242571374888,
              48.031123310866
            ],
            [
              3.0973362166793,
              48.039371853532
            ],
            [
              3.0883164489307,
              48.053931896432
            ],
            [
              3.0668426687001,
              48.062700948945
            ],
            [
              3.0504714242752,
              48.072334131135
            ],
            [
              3.0498894899541,
              48.088838788782
            ],
            [
              3.0383144426538,
              48.101542780839
            ],
            [
              3.035285259367,
              48.115994375619
            ],
            [
              3.0158768007648,
              48.115703437492
            ],
            [
              3.0294681734995,
              48.133204471839
            ],
            [
              3.0067845221623,
              48.145250368304
            ],
            [
              2.9939835469666,
              48.142265686487
            ],
            [
              2.9908854951697,
              48.152489288025
            ],
            [
              2.9636115856187,
              48.152952836191
            ],
            [
              2.9532706975673,
              48.165012141792
            ],
            [
              2.9363157566366,
              48.163391744884
            ]
          ]
        ]
      },
      "properties": {
        "code": "89",
        "nom": "Yonne",
        "population": 334156,
        "delitspour1000": "4,875",
        "nombremosque": 16,
        "parentsEtrangers": "76,2",
        "benefRsa": 17496,
        "eoliennes": 170
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.5530565519414,
              49.009816202999
            ],
            [
              2.5657856607901,
              49.012397786393
            ],
            [
              2.5716715859606,
              48.999841685023
            ],
            [
              2.5803194899487,
              48.991593137352
            ],
            [
              2.5797962900593,
              48.984984703781
            ],
            [
              2.5634346213721,
              48.977732430334
            ],
            [
              2.5893050070876,
              48.953758253881
            ],
            [
              2.6025997962059,
              48.929356607315
            ],
            [
              2.5906912334031,
              48.923361520931
            ],
            [
              2.5912646006622,
              48.906830121884
            ],
            [
              2.5838172328399,
              48.895410384217
            ],
            [
              2.5626276286343,
              48.888883251177
            ],
            [
              2.5594064582352,
              48.885336254164
            ],
            [
              2.5702348571309,
              48.876463404921
            ],
            [
              2.568109258403,
              48.865942675094
            ],
            [
              2.5873261857605,
              48.865005030869
            ],
            [
              2.5831356251086,
              48.857208607635
            ],
            [
              2.5738417937528,
              48.853385173417
            ],
            [
              2.5925149932073,
              48.825870860417
            ],
            [
              2.5964557305467,
              48.810889137262
            ],
            [
              2.5922736733656,
              48.807437551952
            ],
            [
              2.574124871997,
              48.8129126037
            ],
            [
              2.5709791871159,
              48.8226801571
            ],
            [
              2.5313232159076,
              48.844429712931
            ],
            [
              2.4956483361361,
              48.860848724102
            ],
            [
              2.4815382130314,
              48.861405371284
            ],
            [
              2.4476549109644,
              48.852094875028
            ],
            [
              2.4163411302989,
              48.849233783552
            ],
            [
              2.4132702557262,
              48.872892145992
            ],
            [
              2.3988455271816,
              48.887109095072
            ],
            [
              2.394906293421,
              48.898444039523
            ],
            [
              2.3851496429397,
              48.902007785215
            ],
            [
              2.3198871747441,
              48.90045978209
            ],
            [
              2.3137528853936,
              48.914015216252
            ],
            [
              2.3198186224138,
              48.915937453257
            ],
            [
              2.3363529889891,
              48.932347933816
            ],
            [
              2.3355682105858,
              48.940679976956
            ],
            [
              2.3269728001661,
              48.945361349484
            ],
            [
              2.2909694895753,
              48.950965864655
            ],
            [
              2.2882536989787,
              48.958347222268
            ],
            [
              2.2989606605183,
              48.966304083093
            ],
            [
              2.3061147975372,
              48.96262002844
            ],
            [
              2.3277204556376,
              48.959659512979
            ],
            [
              2.3536647251325,
              48.965815537603
            ],
            [
              2.3664463375656,
              48.974178481395
            ],
            [
              2.3845399502938,
              48.970995811739
            ],
            [
              2.3943172032158,
              48.962558403369
            ],
            [
              2.4073892851778,
              48.956124752826
            ],
            [
              2.427263253996,
              48.959221267075
            ],
            [
              2.4594885661379,
              48.955049018342
            ],
            [
              2.4673269472292,
              48.964338344645
            ],
            [
              2.4960054328348,
              48.972719183153
            ],
            [
              2.5004037138928,
              48.97770409094
            ],
            [
              2.514763879623,
              48.982255621303
            ],
            [
              2.5183558101117,
              48.9894603436
            ],
            [
              2.5322372193717,
              49.005223735412
            ],
            [
              2.5477767707755,
              49.004873251062
            ],
            [
              2.5530565519414,
              49.009816202999
            ]
          ]
        ]
      },
      "properties": {
        "code": "93",
        "nom": "Seine-Saint-Denis",
        "population": 1655422,
        "delitspour1000": "8,212",
        "nombremosque": 162,
        "parentsEtrangers": "32,7",
        "benefRsa": 175661,
        "eoliennes": 3
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              5.6760358633349,
              44.191428664539
            ],
            [
              5.6920872371888,
              44.186478874057
            ],
            [
              5.722094579116,
              44.199560152655
            ],
            [
              5.744337767698,
              44.203375914623
            ],
            [
              5.7554992864755,
              44.210209438736
            ],
            [
              5.7765108337571,
              44.20552693091
            ],
            [
              5.7953897143885,
              44.21346243946
            ],
            [
              5.8139240713372,
              44.210246228921
            ],
            [
              5.8285743446632,
              44.200661135331
            ],
            [
              5.8467148104843,
              44.199242648613
            ],
            [
              5.8586940194181,
              44.195319454249
            ],
            [
              5.8898898815662,
              44.191451480656
            ],
            [
              5.9093039189673,
              44.190461844015
            ],
            [
              5.9147426495977,
              44.203428965386
            ],
            [
              5.8817960847873,
              44.21099859688
            ],
            [
              5.8737737739287,
              44.218189343417
            ],
            [
              5.8714504926955,
              44.228660386187
            ],
            [
              5.854458650885,
              44.247291288816
            ],
            [
              5.8266503999502,
              44.262511788492
            ],
            [
              5.8239167631135,
              44.278899127951
            ],
            [
              5.8363884533266,
              44.296763169247
            ],
            [
              5.8484778051433,
              44.300723494092
            ],
            [
              5.8659965238806,
              44.295857192535
            ],
            [
              5.8755473547052,
              44.290877072573
            ],
            [
              5.8753816539895,
              44.279846634673
            ],
            [
              5.8801270223393,
              44.271006950179
            ],
            [
              5.9131897370529,
              44.248714851428
            ],
            [
              5.9248667421793,
              44.262409907281
            ],
            [
              5.9123186108861,
              44.271534499407
            ],
            [
              5.9146781317968,
              44.284661417406
            ],
            [
              5.9235244631716,
              44.294973224853
            ],
            [
              5.9251384328329,
              44.313393919085
            ],
            [
              5.9100725115807,
              44.31106902048
            ],
            [
              5.896094546729,
              44.318618029191
            ],
            [
              5.9030174087507,
              44.326376469128
            ],
            [
              5.9043489213716,
              44.337908823679
            ],
            [
              5.913684572882,
              44.337306973462
            ],
            [
              5.9242265128312,
              44.348916418386
            ],
            [
              5.9373503305366,
              44.359359874149
            ],
            [
              5.9413633419932,
              44.372658160572
            ],
            [
              5.9536693161486,
              44.395062898561
            ],
            [
              5.9849458842619,
              44.410194372666
            ],
            [
              6.0203792716938,
              44.420054377583
            ],
            [
              6.03035635846,
              44.435995593419
            ],
            [
              6.0549026489061,
              44.447319826395
            ],
            [
              6.0711403448688,
              44.450531355717
            ],
            [
              6.0828339951506,
              44.463258615049
            ],
            [
              6.076862835064,
              44.46929656714
            ],
            [
              6.0837487097247,
              44.4777643977
            ],
            [
              6.1160128123577,
              44.475463362429
            ],
            [
              6.1310810638128,
              44.468253386373
            ],
            [
              6.1556177618275,
              44.461963349004
            ],
            [
              6.1562499637895,
              44.442107060358
            ],
            [
              6.1624195263067,
              44.434509293338
            ],
            [
              6.173568101118,
              44.439540095238
            ],
            [
              6.1836028270528,
              44.425647526244
            ],
            [
              6.1964228473607,
              44.418248076521
            ],
            [
              6.2161963558392,
              44.397583449977
            ],
            [
              6.2282178019629,
              44.381697804143
            ],
            [
              6.241683183773,
              44.384850715965
            ],
            [
              6.2624883921892,
              44.412252550915
            ],
            [
              6.2596161179826,
              44.422043564794
            ],
            [
              6.2478821548931,
              44.43936508314
            ],
            [
              6.23942535388,
              44.448497495416
            ],
            [
              6.2337130063544,
              44.463827467982
            ],
            [
              6.2587550795739,
              44.465770156331
            ],
            [
              6.2933053965921,
              44.480935613986
            ],
            [
              6.3010264149081,
              44.473464275112
            ],
            [
              6.3161255152156,
              44.465730040549
            ],
            [
              6.3271143294108,
              44.463592307467
            ],
            [
              6.3352370867768,
              44.470568324179
            ],
            [
              6.3410343897081,
              44.487983224431
            ],
            [
              6.3482718280404,
              44.498092069019
            ],
            [
              6.3323970972832,
              44.509998821823
            ],
            [
              6.3445673277167,
              44.513895871869
            ],
            [
              6.3573122377822,
              44.522641551752
            ],
            [
              6.362003906427,
              44.522073363521
            ],
            [
              6.3737532882619,
              44.50860443735
            ],
            [
              6.3966445929901,
              44.494907703977
            ],
            [
              6.4079655732065,
              44.481845426092
            ],
            [
              6.4138848958919,
              44.469289060287
            ],
            [
              6.4306402872327,
              44.470402340283
            ],
            [
              6.4620149690479,
              44.462474832141
            ],
            [
              6.4693178929148,
              44.451310671269
            ],
            [
              6.4820246931632,
              44.454009468671
            ],
            [
              6.4942314264736,
              44.450999945894
            ],
            [
              6.5195954724085,
              44.447963212892
            ],
            [
              6.54313110208,
              44.448143157343
            ],
            [
              6.5624431910941,
              44.445491895053
            ],
            [
              6.5805404880617,
              44.447080417028
            ],
            [
              6.6323790693337,
              44.447132290606
            ],
            [
              6.6417083846459,
              44.485268665398
            ],
            [
              6.6515697313917,
              44.4927799278
            ],
            [
              6.6682304306729,
              44.50013019
            ],
            [
              6.6742518931077,
              44.516852308013
            ],
            [
              6.6797613699205,
              44.522497583182
            ],
            [
              6.6814654999086,
              44.539163505557
            ],
            [
              6.6843012342403,
              44.541295736187
            ],
            [
              6.7041834288,
              44.539146580611
            ],
            [
              6.7235776846407,
              44.549234602321
            ],
            [
              6.7371836360731,
              44.552538210605
            ],
            [
              6.7473134665879,
              44.56016624247
            ],
            [
              6.7566358338637,
              44.572248845658
            ],
            [
              6.7718334204218,
              44.575761972307
            ],
            [
              6.7722044960859,
              44.588907001363
            ],
            [
              6.7843038003932,
              44.592724507707
            ],
            [
              6.8046311221149,
              44.604025869547
            ],
            [
              6.8279296932556,
              44.609326293352
            ],
            [
              6.8397990886682,
              44.608588017414
            ],
            [
              6.8536672472669,
              44.617064240653
            ],
            [
              6.858141446661,
              44.628059398515
            ],
            [
              6.8801217217746,
              44.629991009807
            ],
            [
              6.9015952691896,
              44.647217973291
            ],
            [
              6.9082015554403,
              44.656736535105
            ],
            [
              6.9144858075332,
              44.659501345682
            ],
            [
              6.9354710134292,
              44.653414589945
            ],
            [
              6.9483326980915,
              44.654818187455
            ],
            [
              6.9545132491635,
              44.636224928214
            ],
            [
              6.9668199032047,
              44.625347388492
            ],
            [
              6.9507674204422,
              44.619841837282
            ],
            [
              6.9449335028933,
              44.605789563959
            ],
            [
              6.9383975462678,
              44.603747763937
            ],
            [
              6.9327380313786,
              44.592705761063
            ],
            [
              6.9335092809034,
              44.5759534554
            ],
            [
              6.9219454035638,
              44.570243826413
            ],
            [
              6.9132069817362,
              44.558829270084
            ],
            [
              6.876030406824,
              44.55015646598
            ],
            [
              6.8698392887424,
              44.536836939535
            ],
            [
              6.8584936249176,
              44.534359207877
            ],
            [
              6.854014437248,
              44.52912511427
            ],
            [
              6.8612281786649,
              44.503421026182
            ],
            [
              6.8819639305182,
              44.477256328072
            ],
            [
              6.9063376727978,
              44.466843845236
            ],
            [
              6.9110962932443,
              44.452367703384
            ],
            [
              6.9371328757365,
              44.438869491063
            ],
            [
              6.9445579172439,
              44.432057527562
            ],
            [
              6.9239709413721,
              44.422877906287
            ],
            [
              6.9179670246858,
              44.427399028125
            ],
            [
              6.9028825840578,
              44.420453193274
            ],
            [
              6.8928683040571,
              44.420779824329
            ],
            [
              6.8956192908931,
              44.40850443666
            ],
            [
              6.8943641862255,
              44.397964299149
            ],
            [
              6.8965054422432,
              44.374301434879
            ],
            [
              6.8874351607458,
              44.361051257141
            ],
            [
              6.8623162326859,
              44.346410702484
            ],
            [
              6.8396913528484,
              44.346173034543
            ],
            [
              6.8223981071578,
              44.33265277476
            ],
            [
              6.8146208258179,
              44.332052374921
            ],
            [
              6.8036231667248,
              44.320270996667
            ],
            [
              6.7960920038012,
              44.316102067874
            ],
            [
              6.7933368571689,
              44.296720083266
            ],
            [
              6.7863492911521,
              44.288497379247
            ],
            [
              6.7903396839453,
              44.272339782321
            ],
            [
              6.7629245576522,
              44.279037327194
            ],
            [
              6.7583202569976,
              44.274351334174
            ],
            [
              6.7584360313867,
              44.265106172074
            ],
            [
              6.7513229949306,
              44.260535556946
            ],
            [
              6.7239888814711,
              44.249843474444
            ],
            [
              6.7250111259566,
              44.226278142994
            ],
            [
              6.7140803603433,
              44.218225221217
            ],
            [
              6.7183432031881,
              44.21071438795
            ],
            [
              6.706825122292,
              44.201490519651
            ],
            [
              6.6988119126829,
              44.188028656227
            ],
            [
              6.7012407441864,
              44.17766549216
            ],
            [
              6.6865415403433,
              44.169255561043
            ],
            [
              6.705493472047,
              44.144463669693
            ],
            [
              6.7070689268616,
              44.125841526264
            ],
            [
              6.7185962917297,
              44.11939935968
            ],
            [
              6.7256652646801,
              44.109983735237
            ],
            [
              6.7366003752038,
              44.102257679953
            ],
            [
              6.7552862363418,
              44.078601877976
            ],
            [
              6.7490919968038,
              44.063171230737
            ],
            [
              6.7457111620123,
              44.043230952418
            ],
            [
              6.7725735140535,
              44.025507983052
            ],
            [
              6.7892786360536,
              44.022566063867
            ],
            [
              6.797979688934,
              44.005325542478
            ],
            [
              6.8360089492776,
              43.99059808047
            ],
            [
              6.8347064061942,
              43.98328134261
            ],
            [
              6.8420892292777,
              43.978955578863
            ],
            [
              6.8518928028242,
              43.954789473614
            ],
            [
              6.8710752593745,
              43.953876988543
            ],
            [
              6.8810110369509,
              43.93812271054
            ],
            [
              6.8951249345413,
              43.927824787229
            ],
            [
              6.9130909664835,
              43.92796078166
            ],
            [
              6.9262561668761,
              43.913794624111
            ],
            [
              6.9413897545047,
              43.904151092346
            ],
            [
              6.9417509022289,
              43.896969207908
            ],
            [
              6.9204750421499,
              43.893501745813
            ],
            [
              6.9072439984892,
              43.893267267097
            ],
            [
              6.8846101855508,
              43.889120756916
            ],
            [
              6.8766137882973,
              43.898595600573
            ],
            [
              6.8425804223119,
              43.915860800081
            ],
            [
              6.8307045795788,
              43.918366781633
            ],
            [
              6.8042888891849,
              43.910787975021
            ],
            [
              6.7820616049909,
              43.884511555013
            ],
            [
              6.7624119369307,
              43.876176098297
            ],
            [
              6.7455614971153,
              43.872111681853
            ],
            [
              6.713400273635,
              43.880336747948
            ],
            [
              6.6726434197403,
              43.888382285729
            ],
            [
              6.672786517564,
              43.883154023665
            ],
            [
              6.6973270734627,
              43.873654675908
            ],
            [
              6.6987237488398,
              43.85521351124
            ],
            [
              6.6779199825238,
              43.850960494829
            ],
            [
              6.6714829784168,
              43.84209744137
            ],
            [
              6.667932367866,
              43.830922690832
            ],
            [
              6.683397147817,
              43.829205713256
            ],
            [
              6.7036941981974,
              43.822822478033
            ],
            [
              6.7120036485147,
              43.814856991509
            ],
            [
              6.7078877655875,
              43.809522459717
            ],
            [
              6.6918092836481,
              43.804596539395
            ],
            [
              6.6757060249605,
              43.802450144872
            ],
            [
              6.6415189172738,
              43.795468116229
            ],
            [
              6.6363906079569,
              43.788947137136
            ],
            [
              6.6279270938768,
              43.791568812159
            ],
            [
              6.6199664600085,
              43.805500121194
            ],
            [
              6.5994543566539,
              43.803567314158
            ],
            [
              6.5869124838076,
              43.805319550919
            ],
            [
              6.573625361215,
              43.792480110013
            ],
            [
              6.5548615750879,
              43.784119497484
            ],
            [
              6.5456804964732,
              43.78223076642
            ],
            [
              6.5413527450184,
              43.792871075899
            ],
            [
              6.5247646877683,
              43.806369493557
            ],
            [
              6.5173862912704,
              43.806770970879
            ],
            [
              6.5094345210308,
              43.800712946523
            ],
            [
              6.4820726945377,
              43.791077242351
            ],
            [
              6.4692907507952,
              43.798667641521
            ],
            [
              6.4565443095075,
              43.793409815159
            ],
            [
              6.4388022466454,
              43.797746288454
            ],
            [
              6.4339963811669,
              43.792103469968
            ],
            [
              6.414425301941,
              43.791590041073
            ],
            [
              6.4134803171307,
              43.760356001175
            ],
            [
              6.3944177782975,
              43.746256332737
            ],
            [
              6.3835455827762,
              43.734431200864
            ],
            [
              6.3720062958543,
              43.742907961785
            ],
            [
              6.3569569098109,
              43.736365981576
            ],
            [
              6.3262750948765,
              43.746870168479
            ],
            [
              6.3190052476717,
              43.754524225417
            ],
            [
              6.2628893399061,
              43.781883089329
            ],
            [
              6.2599275582753,
              43.792619169018
            ],
            [
              6.2542153063873,
              43.800431020995
            ],
            [
              6.2456394199293,
              43.802388130505
            ],
            [
              6.2167124047513,
              43.799511004589
            ],
            [
              6.1981790085091,
              43.788067549065
            ],
            [
              6.1885229282029,
              43.776270763771
            ],
            [
              6.1828886896128,
              43.762179115381
            ],
            [
              6.1542900886483,
              43.743634530344
            ],
            [
              6.1406045701782,
              43.737609222261
            ],
            [
              6.1297015416015,
              43.736218513297
            ],
            [
              6.1068224665182,
              43.744774442046
            ],
            [
              6.1028648287186,
              43.73634476066
            ],
            [
              6.0883249691755,
              43.718536956452
            ],
            [
              6.0773213370383,
              43.707880410642
            ],
            [
              6.0515573709742,
              43.69771276033
            ],
            [
              6.036134340046,
              43.694164170854
            ],
            [
              6.0379674665934,
              43.681720531593
            ],
            [
              6.0217364024135,
              43.668282105491
            ],
            [
              6.0113923155853,
              43.675012807317
            ],
            [
              6.0050060172337,
              43.687791494952
            ],
            [
              5.9879367874284,
              43.695053963247
            ],
            [
              5.9854389290259,
              43.713962195587
            ],
            [
              5.9689233683598,
              43.719718753178
            ],
            [
              5.9564452967405,
              43.719398427174
            ],
            [
              5.9404721367951,
              43.727336566191
            ],
            [
              5.9416167520954,
              43.739484477929
            ],
            [
              5.9387607480826,
              43.748781569852
            ],
            [
              5.9274624705326,
              43.750175516058
            ],
            [
              5.9254717898681,
              43.756915940863
            ],
            [
              5.9102713384672,
              43.753171194098
            ],
            [
              5.9045048630389,
              43.748205132796
            ],
            [
              5.9056553842608,
              43.73943006866
            ],
            [
              5.9012062080264,
              43.732585527498
            ],
            [
              5.8818420325261,
              43.723912733964
            ],
            [
              5.8562015702536,
              43.723583782294
            ],
            [
              5.8491411431165,
              43.720249991855
            ],
            [
              5.8373883741505,
              43.741475701408
            ],
            [
              5.8236689891547,
              43.748281261866
            ],
            [
              5.7994711414233,
              43.7510094975
            ],
            [
              5.7813443482122,
              43.75565465876
            ],
            [
              5.7573377215236,
              43.729404491649
            ],
            [
              5.7491578816916,
              43.740549346376
            ],
            [
              5.7273375065728,
              43.750258725263
            ],
            [
              5.7160627756459,
              43.757395207135
            ],
            [
              5.7135531559885,
              43.781117492473
            ],
            [
              5.6899129939668,
              43.785198190799
            ],
            [
              5.6845562685945,
              43.788504206527
            ],
            [
              5.6744832277441,
              43.807357748245
            ],
            [
              5.6671662984008,
              43.809913206473
            ],
            [
              5.6564369559568,
              43.819373716522
            ],
            [
              5.6544489060016,
              43.825111794533
            ],
            [
              5.6194025670079,
              43.829836525507
            ],
            [
              5.6035105078995,
              43.827530390778
            ],
            [
              5.5781958409725,
              43.829917377655
            ],
            [
              5.5673334240775,
              43.827074591963
            ],
            [
              5.560920757582,
              43.821128997597
            ],
            [
              5.5445261679646,
              43.818200447294
            ],
            [
              5.5497272595744,
              43.851223396544
            ],
            [
              5.5737718992297,
              43.866506510483
            ],
            [
              5.5856680158538,
              43.880240950982
            ],
            [
              5.5872056049016,
              43.885602478647
            ],
            [
              5.6071265063482,
              43.913769245956
            ],
            [
              5.5943330349272,
              43.916834690324
            ],
            [
              5.5815816010178,
              43.914993881433
            ],
            [
              5.5815171504923,
              43.923012828568
            ],
            [
              5.5676329697344,
              43.942636398699
            ],
            [
              5.544991757257,
              43.939050342721
            ],
            [
              5.5300236696842,
              43.945681187106
            ],
            [
              5.5120856887653,
              43.945999673026
            ],
            [
              5.5086491450188,
              43.959838865745
            ],
            [
              5.5122177562468,
              43.967076459311
            ],
            [
              5.5180561246763,
              43.990111257336
            ],
            [
              5.5286721373202,
              44.007561924547
            ],
            [
              5.5359794964897,
              44.013564481821
            ],
            [
              5.5436554275499,
              44.028707716695
            ],
            [
              5.5445930536793,
              44.069889333019
            ],
            [
              5.5244779560573,
              44.065033134176
            ],
            [
              5.5026418941828,
              44.063441793308
            ],
            [
              5.4980104773442,
              44.075202443076
            ],
            [
              5.5004101782252,
              44.100309962271
            ],
            [
              5.4987864391759,
              44.115716677493
            ],
            [
              5.5048243383524,
              44.116270397321
            ],
            [
              5.5197573760784,
              44.126616149895
            ],
            [
              5.5329588653579,
              44.130053463575
            ],
            [
              5.5439848356077,
              44.136389912148
            ],
            [
              5.5513311325106,
              44.149791971653
            ],
            [
              5.5695065039633,
              44.148099520416
            ],
            [
              5.5829754941585,
              44.157625987959
            ],
            [
              5.5643706424911,
              44.170901794193
            ],
            [
              5.5761919645305,
              44.188037035508
            ],
            [
              5.5962058311559,
              44.187648959302
            ],
            [
              5.6020555143928,
              44.191496296599
            ],
            [
              5.6162160024561,
              44.181069460057
            ],
            [
              5.6395945851702,
              44.167581524
            ],
            [
              5.6311399737751,
              44.150576623162
            ],
            [
              5.65770809672,
              44.147529418679
            ],
            [
              5.6679496679388,
              44.148873764468
            ],
            [
              5.6786089032088,
              44.146091300526
            ],
            [
              5.6827091460673,
              44.163217461898
            ],
            [
              5.6626325073952,
              44.16686426058
            ],
            [
              5.6469586945897,
              44.166287300205
            ],
            [
              5.6436911140627,
              44.172641360479
            ],
            [
              5.6522929653271,
              44.185411124162
            ],
            [
              5.6515861240321,
              44.189572730485
            ],
            [
              5.6760358633349,
              44.191428664539
            ]
          ]
        ]
      },
      "properties": {
        "code": "4",
        "nom": "Alpes-de-Haute-Provence",
        "population": 165451,
        "delitspour1000": "5,023",
        "nombremosque": 2,
        "benefRsa": 7718
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              6.2605698000566,
              45.12684420383
            ],
            [
              6.275502630793,
              45.115299423706
            ],
            [
              6.2862810761543,
              45.110160822134
            ],
            [
              6.3019155994585,
              45.108954317068
            ],
            [
              6.3312951655489,
              45.118123866818
            ],
            [
              6.3344836229796,
              45.122836461323
            ],
            [
              6.3629265157708,
              45.104492035764
            ],
            [
              6.3735310876429,
              45.084151804143
            ],
            [
              6.365073385182,
              45.071290490956
            ],
            [
              6.3939107242634,
              45.0618177275
            ],
            [
              6.4002434258614,
              45.063261148419
            ],
            [
              6.4385899722107,
              45.06258734764
            ],
            [
              6.4433986588263,
              45.055077367768
            ],
            [
              6.4535295918669,
              45.051837207667
            ],
            [
              6.4728883939578,
              45.056011644618
            ],
            [
              6.4862360357216,
              45.056075754404
            ],
            [
              6.4893378703322,
              45.068353912844
            ],
            [
              6.4812555476638,
              45.094138953543
            ],
            [
              6.4995275838537,
              45.10159781596
            ],
            [
              6.5104239586159,
              45.108998029314
            ],
            [
              6.5297109874994,
              45.0985691169
            ],
            [
              6.556730767951,
              45.104079390462
            ],
            [
              6.5630788445458,
              45.11303936929
            ],
            [
              6.5765291374542,
              45.123092743765
            ],
            [
              6.5906851893034,
              45.119010630972
            ],
            [
              6.6155462835465,
              45.121477875934
            ],
            [
              6.6299923020059,
              45.109324963809
            ],
            [
              6.6274839517535,
              45.101215174371
            ],
            [
              6.6454471568466,
              45.085321118769
            ],
            [
              6.6452449110874,
              45.075644489098
            ],
            [
              6.6621102280893,
              45.071636240322
            ],
            [
              6.6619665564343,
              45.054254651103
            ],
            [
              6.6697329651003,
              45.041528589974
            ],
            [
              6.6642057859606,
              45.033852692689
            ],
            [
              6.6738842245217,
              45.019618440205
            ],
            [
              6.7208749671212,
              45.021947970161
            ],
            [
              6.7408121549644,
              45.016732773834
            ],
            [
              6.7455184581155,
              45.012636232743
            ],
            [
              6.7511816747145,
              44.997554388257
            ],
            [
              6.7376368487818,
              44.991489847333
            ],
            [
              6.7487248532957,
              44.985300837089
            ],
            [
              6.7500991466522,
              44.979004278299
            ],
            [
              6.7634620292171,
              44.971216274205
            ],
            [
              6.7649436361829,
              44.959669769983
            ],
            [
              6.7530762964187,
              44.942964787789
            ],
            [
              6.760454851589,
              44.933557259798
            ],
            [
              6.7509575667282,
              44.924736641884
            ],
            [
              6.7497507150819,
              44.907359345497
            ],
            [
              6.7707561827724,
              44.903356119918
            ],
            [
              6.778831730714,
              44.895247653895
            ],
            [
              6.7997873639988,
              44.885790632096
            ],
            [
              6.8040834784844,
              44.877494910069
            ],
            [
              6.8286189375326,
              44.867936372769
            ],
            [
              6.8365561972179,
              44.862113162368
            ],
            [
              6.8598658043828,
              44.852903429339
            ],
            [
              6.8837432066014,
              44.847958551328
            ],
            [
              6.9137650533034,
              44.845420317518
            ],
            [
              6.9277645236266,
              44.859966074683
            ],
            [
              6.9336859786803,
              44.862026116862
            ],
            [
              6.9536880142387,
              44.855488865035
            ],
            [
              6.9726101112827,
              44.846235246901
            ],
            [
              6.9838519381402,
              44.845869843048
            ],
            [
              7.0067725151035,
              44.839316013319
            ],
            [
              7.0093160771169,
              44.826729413865
            ],
            [
              7.0217860677102,
              44.821493498314
            ],
            [
              7.0182872220085,
              44.812302571705
            ],
            [
              6.9995699130659,
              44.789443859689
            ],
            [
              7.014173059144,
              44.780240783682
            ],
            [
              7.0247448800861,
              44.762381967485
            ],
            [
              7.0217840296699,
              44.753151161226
            ],
            [
              7.0237266250912,
              44.741017074034
            ],
            [
              7.032722682298,
              44.727849112916
            ],
            [
              7.043680201538,
              44.71814154062
            ],
            [
              7.0657832187637,
              44.71360393024
            ],
            [
              7.0663991317632,
              44.707135672178
            ],
            [
              7.0741932187631,
              44.69248281361
            ],
            [
              7.0771048243018,
              44.680914951343
            ],
            [
              7.0597229739436,
              44.679959761356
            ],
            [
              7.0370906551502,
              44.689271101061
            ],
            [
              7.0237344527351,
              44.691300223227
            ],
            [
              7.0063373453242,
              44.68821962595
            ],
            [
              6.9870611832936,
              44.690137664959
            ],
            [
              6.9763421453374,
              44.681204625623
            ],
            [
              6.9626468064561,
              44.677766961935
            ],
            [
              6.9483326980915,
              44.654818187455
            ],
            [
              6.9354710134292,
              44.653414589945
            ],
            [
              6.9144858075332,
              44.659501345682
            ],
            [
              6.9082015554403,
              44.656736535105
            ],
            [
              6.9015952691896,
              44.647217973291
            ],
            [
              6.8801217217746,
              44.629991009807
            ],
            [
              6.858141446661,
              44.628059398515
            ],
            [
              6.8536672472669,
              44.617064240653
            ],
            [
              6.8397990886682,
              44.608588017414
            ],
            [
              6.8279296932556,
              44.609326293352
            ],
            [
              6.8046311221149,
              44.604025869547
            ],
            [
              6.7843038003932,
              44.592724507707
            ],
            [
              6.7722044960859,
              44.588907001363
            ],
            [
              6.7718334204218,
              44.575761972307
            ],
            [
              6.7566358338637,
              44.572248845658
            ],
            [
              6.7473134665879,
              44.56016624247
            ],
            [
              6.7371836360731,
              44.552538210605
            ],
            [
              6.7235776846407,
              44.549234602321
            ],
            [
              6.7041834288,
              44.539146580611
            ],
            [
              6.6843012342403,
              44.541295736187
            ],
            [
              6.6814654999086,
              44.539163505557
            ],
            [
              6.6797613699205,
              44.522497583182
            ],
            [
              6.6742518931077,
              44.516852308013
            ],
            [
              6.6682304306729,
              44.50013019
            ],
            [
              6.6515697313917,
              44.4927799278
            ],
            [
              6.6417083846459,
              44.485268665398
            ],
            [
              6.6323790693337,
              44.447132290606
            ],
            [
              6.5805404880617,
              44.447080417028
            ],
            [
              6.5624431910941,
              44.445491895053
            ],
            [
              6.54313110208,
              44.448143157343
            ],
            [
              6.5195954724085,
              44.447963212892
            ],
            [
              6.4942314264736,
              44.450999945894
            ],
            [
              6.4820246931632,
              44.454009468671
            ],
            [
              6.4693178929148,
              44.451310671269
            ],
            [
              6.4620149690479,
              44.462474832141
            ],
            [
              6.4306402872327,
              44.470402340283
            ],
            [
              6.4138848958919,
              44.469289060287
            ],
            [
              6.4079655732065,
              44.481845426092
            ],
            [
              6.3966445929901,
              44.494907703977
            ],
            [
              6.3737532882619,
              44.50860443735
            ],
            [
              6.362003906427,
              44.522073363521
            ],
            [
              6.3573122377822,
              44.522641551752
            ],
            [
              6.3445673277167,
              44.513895871869
            ],
            [
              6.3323970972832,
              44.509998821823
            ],
            [
              6.3482718280404,
              44.498092069019
            ],
            [
              6.3410343897081,
              44.487983224431
            ],
            [
              6.3352370867768,
              44.470568324179
            ],
            [
              6.3271143294108,
              44.463592307467
            ],
            [
              6.3161255152156,
              44.465730040549
            ],
            [
              6.3010264149081,
              44.473464275112
            ],
            [
              6.2933053965921,
              44.480935613986
            ],
            [
              6.2587550795739,
              44.465770156331
            ],
            [
              6.2337130063544,
              44.463827467982
            ],
            [
              6.23942535388,
              44.448497495416
            ],
            [
              6.2478821548931,
              44.43936508314
            ],
            [
              6.2596161179826,
              44.422043564794
            ],
            [
              6.2624883921892,
              44.412252550915
            ],
            [
              6.241683183773,
              44.384850715965
            ],
            [
              6.2282178019629,
              44.381697804143
            ],
            [
              6.2161963558392,
              44.397583449977
            ],
            [
              6.1964228473607,
              44.418248076521
            ],
            [
              6.1836028270528,
              44.425647526244
            ],
            [
              6.173568101118,
              44.439540095238
            ],
            [
              6.1624195263067,
              44.434509293338
            ],
            [
              6.1562499637895,
              44.442107060358
            ],
            [
              6.1556177618275,
              44.461963349004
            ],
            [
              6.1310810638128,
              44.468253386373
            ],
            [
              6.1160128123577,
              44.475463362429
            ],
            [
              6.0837487097247,
              44.4777643977
            ],
            [
              6.076862835064,
              44.46929656714
            ],
            [
              6.0828339951506,
              44.463258615049
            ],
            [
              6.0711403448688,
              44.450531355717
            ],
            [
              6.0549026489061,
              44.447319826395
            ],
            [
              6.03035635846,
              44.435995593419
            ],
            [
              6.0203792716938,
              44.420054377583
            ],
            [
              5.9849458842619,
              44.410194372666
            ],
            [
              5.9536693161486,
              44.395062898561
            ],
            [
              5.9413633419932,
              44.372658160572
            ],
            [
              5.9373503305366,
              44.359359874149
            ],
            [
              5.9242265128312,
              44.348916418386
            ],
            [
              5.913684572882,
              44.337306973462
            ],
            [
              5.9043489213716,
              44.337908823679
            ],
            [
              5.9030174087507,
              44.326376469128
            ],
            [
              5.896094546729,
              44.318618029191
            ],
            [
              5.9100725115807,
              44.31106902048
            ],
            [
              5.9251384328329,
              44.313393919085
            ],
            [
              5.9235244631716,
              44.294973224853
            ],
            [
              5.9146781317968,
              44.284661417406
            ],
            [
              5.9123186108861,
              44.271534499407
            ],
            [
              5.9248667421793,
              44.262409907281
            ],
            [
              5.9131897370529,
              44.248714851428
            ],
            [
              5.8801270223393,
              44.271006950179
            ],
            [
              5.8753816539895,
              44.279846634673
            ],
            [
              5.8755473547052,
              44.290877072573
            ],
            [
              5.8659965238806,
              44.295857192535
            ],
            [
              5.8484778051433,
              44.300723494092
            ],
            [
              5.8363884533266,
              44.296763169247
            ],
            [
              5.8239167631135,
              44.278899127951
            ],
            [
              5.8266503999502,
              44.262511788492
            ],
            [
              5.854458650885,
              44.247291288816
            ],
            [
              5.8714504926955,
              44.228660386187
            ],
            [
              5.8737737739287,
              44.218189343417
            ],
            [
              5.8817960847873,
              44.21099859688
            ],
            [
              5.9147426495977,
              44.203428965386
            ],
            [
              5.9093039189673,
              44.190461844015
            ],
            [
              5.8898898815662,
              44.191451480656
            ],
            [
              5.8586940194181,
              44.195319454249
            ],
            [
              5.8467148104843,
              44.199242648613
            ],
            [
              5.8285743446632,
              44.200661135331
            ],
            [
              5.8139240713372,
              44.210246228921
            ],
            [
              5.7953897143885,
              44.21346243946
            ],
            [
              5.7765108337571,
              44.20552693091
            ],
            [
              5.7554992864755,
              44.210209438736
            ],
            [
              5.744337767698,
              44.203375914623
            ],
            [
              5.722094579116,
              44.199560152655
            ],
            [
              5.6920872371888,
              44.186478874057
            ],
            [
              5.6760358633349,
              44.191428664539
            ],
            [
              5.6864432188187,
              44.197157935534
            ],
            [
              5.6760207339785,
              44.212146588276
            ],
            [
              5.6813154432669,
              44.232890979586
            ],
            [
              5.6726438758953,
              44.245665214395
            ],
            [
              5.6753443584242,
              44.258110993228
            ],
            [
              5.6865615009091,
              44.266921017987
            ],
            [
              5.6752912853484,
              44.275852112257
            ],
            [
              5.6467811994158,
              44.267088776629
            ],
            [
              5.6332659808825,
              44.282119871541
            ],
            [
              5.6377527896773,
              44.29968895608
            ],
            [
              5.6079060792589,
              44.306669558882
            ],
            [
              5.6138324838557,
              44.316139439714
            ],
            [
              5.6315982014425,
              44.32830571643
            ],
            [
              5.6269152221035,
              44.334764537726
            ],
            [
              5.6171385399181,
              44.332478533509
            ],
            [
              5.5868917748447,
              44.332226010325
            ],
            [
              5.5738598675994,
              44.333940368466
            ],
            [
              5.5491114786402,
              44.330396465555
            ],
            [
              5.5373655887575,
              44.333521248655
            ],
            [
              5.5398634524253,
              44.342637263297
            ],
            [
              5.5135447821727,
              44.347485508397
            ],
            [
              5.4930701487957,
              44.337173950444
            ],
            [
              5.4824720338071,
              44.349605960068
            ],
            [
              5.4679488171996,
              44.352676849629
            ],
            [
              5.4629658844687,
              44.367052180352
            ],
            [
              5.4344154288602,
              44.36962646376
            ],
            [
              5.4351137570212,
              44.37707693084
            ],
            [
              5.4423608910239,
              44.381659799255
            ],
            [
              5.4430201938352,
              44.391233871018
            ],
            [
              5.434309527365,
              44.409479438419
            ],
            [
              5.422756262532,
              44.416770650479
            ],
            [
              5.4185330627929,
              44.424944615913
            ],
            [
              5.4338571063313,
              44.433224179441
            ],
            [
              5.4507590745848,
              44.430873511581
            ],
            [
              5.4764846044694,
              44.419722107544
            ],
            [
              5.4865672289608,
              44.429310933518
            ],
            [
              5.4936153322299,
              44.428217653577
            ],
            [
              5.496803836369,
              44.438493779503
            ],
            [
              5.4644276220352,
              44.447890408205
            ],
            [
              5.4640494490072,
              44.457360043051
            ],
            [
              5.4587312484193,
              44.466142946772
            ],
            [
              5.463575747607,
              44.480512627606
            ],
            [
              5.4569314822479,
              44.496235778443
            ],
            [
              5.4653894088793,
              44.500459334817
            ],
            [
              5.4735960069771,
              44.498568843239
            ],
            [
              5.479877306738,
              44.491243239804
            ],
            [
              5.5133416154223,
              44.491125991349
            ],
            [
              5.5447008857046,
              44.482537575762
            ],
            [
              5.5624010016641,
              44.474853738869
            ],
            [
              5.5703041972283,
              44.476754841678
            ],
            [
              5.5792007067241,
              44.471686064395
            ],
            [
              5.6036448321495,
              44.465542446118
            ],
            [
              5.618123153083,
              44.474812657295
            ],
            [
              5.6297876074114,
              44.501187043038
            ],
            [
              5.6526558596497,
              44.499962952183
            ],
            [
              5.6645041823511,
              44.501894932126
            ],
            [
              5.6531427146706,
              44.514831250068
            ],
            [
              5.630802337654,
              44.531597332459
            ],
            [
              5.6149927369414,
              44.532817987531
            ],
            [
              5.5972525723721,
              44.543273639184
            ],
            [
              5.5997980521304,
              44.553941311612
            ],
            [
              5.6070190339977,
              44.568348424227
            ],
            [
              5.6257986898421,
              44.586275880643
            ],
            [
              5.6274790368545,
              44.594965971046
            ],
            [
              5.6475161192881,
              44.612808143065
            ],
            [
              5.6496311179712,
              44.617884606434
            ],
            [
              5.6403729488497,
              44.633825447345
            ],
            [
              5.6417177024073,
              44.65107732796
            ],
            [
              5.6544567435058,
              44.655393257217
            ],
            [
              5.6593597358465,
              44.650663453626
            ],
            [
              5.6853621391431,
              44.649596345809
            ],
            [
              5.7260329075738,
              44.639393999475
            ],
            [
              5.7358025109287,
              44.640415596891
            ],
            [
              5.7360131774513,
              44.649510358823
            ],
            [
              5.7511047252626,
              44.648611621404
            ],
            [
              5.748257168346,
              44.655090844363
            ],
            [
              5.7539428619931,
              44.662710867987
            ],
            [
              5.7666311555846,
              44.655266851559
            ],
            [
              5.790623681681,
              44.653292905762
            ],
            [
              5.8064797515589,
              44.677181975541
            ],
            [
              5.8246351320469,
              44.685278024621
            ],
            [
              5.8294720463131,
              44.692425284611
            ],
            [
              5.8271099006445,
              44.700288039663
            ],
            [
              5.8014699513145,
              44.706777613167
            ],
            [
              5.8087945811456,
              44.712101632921
            ],
            [
              5.8177724444737,
              44.730405199263
            ],
            [
              5.8277714297865,
              44.740086051546
            ],
            [
              5.8270981940509,
              44.759688387455
            ],
            [
              5.8371577512142,
              44.757677183301
            ],
            [
              5.8503937497009,
              44.750747373326
            ],
            [
              5.8652264931802,
              44.75155204943
            ],
            [
              5.8794954997212,
              44.747015345674
            ],
            [
              5.8888310649581,
              44.748803778249
            ],
            [
              5.900149637893,
              44.758315310611
            ],
            [
              5.9152344231519,
              44.754703072251
            ],
            [
              5.926812494728,
              44.757135657325
            ],
            [
              5.937984096823,
              44.763046424986
            ],
            [
              5.952471613503,
              44.76214052281
            ],
            [
              5.9555147438847,
              44.772449015839
            ],
            [
              5.9801493330969,
              44.781181602373
            ],
            [
              5.9777793189449,
              44.790983738721
            ],
            [
              5.9537639530916,
              44.799525853781
            ],
            [
              5.9495216336669,
              44.804527356596
            ],
            [
              5.9781993713916,
              44.818035522208
            ],
            [
              5.9961657258664,
              44.81787407617
            ],
            [
              6.0048600553234,
              44.820439050514
            ],
            [
              6.0159331963205,
              44.835485814051
            ],
            [
              6.0302157011626,
              44.838097758941
            ],
            [
              6.0402065129253,
              44.827867468677
            ],
            [
              6.0563403817425,
              44.815907431783
            ],
            [
              6.0653153781591,
              44.82268480473
            ],
            [
              6.0965156572897,
              44.837489421771
            ],
            [
              6.1007058869493,
              44.84257849233
            ],
            [
              6.1166725017074,
              44.849313192704
            ],
            [
              6.1283556561161,
              44.861901713563
            ],
            [
              6.1362266704196,
              44.864071820118
            ],
            [
              6.1490188906075,
              44.858169876067
            ],
            [
              6.1684510585274,
              44.852242761941
            ],
            [
              6.1852192319643,
              44.853991864943
            ],
            [
              6.1963759402105,
              44.858978334449
            ],
            [
              6.2243745547304,
              44.852494101643
            ],
            [
              6.2505425682344,
              44.852650516632
            ],
            [
              6.2581706057376,
              44.86248397325
            ],
            [
              6.2677561761734,
              44.86950109295
            ],
            [
              6.2882129459689,
              44.874030533685
            ],
            [
              6.302850434726,
              44.873257969643
            ],
            [
              6.3192302042703,
              44.85684625453
            ],
            [
              6.3363165518362,
              44.848370410548
            ],
            [
              6.3553625022057,
              44.854775353601
            ],
            [
              6.3507779137165,
              44.881204072575
            ],
            [
              6.3581697523493,
              44.893778946012
            ],
            [
              6.3546140951868,
              44.923593300625
            ],
            [
              6.3588423781754,
              44.941280796729
            ],
            [
              6.3290046114892,
              44.947315364095
            ],
            [
              6.3229180108318,
              44.953019839313
            ],
            [
              6.3285012874077,
              44.969714464603
            ],
            [
              6.3148100239742,
              44.980185922577
            ],
            [
              6.3196573396763,
              44.994552811249
            ],
            [
              6.3182021615637,
              45.003859362058
            ],
            [
              6.2970615457571,
              45.003365126963
            ],
            [
              6.2696990840472,
              44.998351365091
            ],
            [
              6.2517609476002,
              44.996700081455
            ],
            [
              6.2039233205269,
              45.012471073947
            ],
            [
              6.2063284689408,
              45.026782796498
            ],
            [
              6.2200802599652,
              45.065369735518
            ],
            [
              6.2401205372094,
              45.06771808518
            ],
            [
              6.2358324302027,
              45.087232804381
            ],
            [
              6.2296723977867,
              45.100589085615
            ],
            [
              6.2293919345611,
              45.108749945384
            ],
            [
              6.2438305270389,
              45.117298075856
            ],
            [
              6.2544936755586,
              45.120790168264
            ],
            [
              6.2605698000566,
              45.12684420383
            ]
          ]
        ]
      },
      "properties": {
        "code": "5",
        "nom": "Hautes-Alpes",
        "population": 140605,
        "delitspour1000": "5,121",
        "benefRsa": 5289,
        "eoliennes": 2
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.4831320440493,
              45.236445615502
            ],
            [
              4.4879001066584,
              45.232177394436
            ],
            [
              4.5131557337675,
              45.234772362931
            ],
            [
              4.5132151489564,
              45.243118249066
            ],
            [
              4.526544923769,
              45.240102794417
            ],
            [
              4.5581567502396,
              45.246830803085
            ],
            [
              4.5842858795804,
              45.244084964738
            ],
            [
              4.6024189574118,
              45.251699611327
            ],
            [
              4.6049363248724,
              45.264031661375
            ],
            [
              4.5917734340486,
              45.271300484031
            ],
            [
              4.5985944793052,
              45.289484840554
            ],
            [
              4.6226711105485,
              45.291337466369
            ],
            [
              4.6144998827011,
              45.309832990607
            ],
            [
              4.6431793868041,
              45.318899176946
            ],
            [
              4.6385582894087,
              45.32619453663
            ],
            [
              4.659973826611,
              45.338548296066
            ],
            [
              4.6795095377771,
              45.346108764264
            ],
            [
              4.7004142506589,
              45.345743686427
            ],
            [
              4.7035926308706,
              45.350231070265
            ],
            [
              4.7235165531567,
              45.355268877755
            ],
            [
              4.7258207341748,
              45.361809127681
            ],
            [
              4.75599963206,
              45.365674921417
            ],
            [
              4.7725568146969,
              45.35486629718
            ],
            [
              4.7736174113958,
              45.347659425308
            ],
            [
              4.7613883954348,
              45.34062730957
            ],
            [
              4.7605327238034,
              45.327460400164
            ],
            [
              4.7699315539545,
              45.31601418313
            ],
            [
              4.7889343017658,
              45.306705287082
            ],
            [
              4.8004899840029,
              45.298357121937
            ],
            [
              4.8094568553149,
              45.289844279254
            ],
            [
              4.8102641176881,
              45.263906311762
            ],
            [
              4.8022063840458,
              45.248326201056
            ],
            [
              4.8025808522447,
              45.226933954961
            ],
            [
              4.8125634562269,
              45.204310887013
            ],
            [
              4.8048124767787,
              45.194033858288
            ],
            [
              4.8120267158556,
              45.179930757264
            ],
            [
              4.8090522119215,
              45.170900439855
            ],
            [
              4.8124687887878,
              45.164479213676
            ],
            [
              4.8291002542524,
              45.156686506903
            ],
            [
              4.8244322155718,
              45.148380577457
            ],
            [
              4.8044647656159,
              45.133193548442
            ],
            [
              4.8044870212281,
              45.121943298284
            ],
            [
              4.8178949574706,
              45.108198810951
            ],
            [
              4.8273592772733,
              45.102145788995
            ],
            [
              4.8318453392336,
              45.089670350352
            ],
            [
              4.8276625497628,
              45.077965908196
            ],
            [
              4.8316486153953,
              45.070271179144
            ],
            [
              4.8526119061642,
              45.064235228454
            ],
            [
              4.8607510889061,
              45.055427319327
            ],
            [
              4.8540950735802,
              45.046251864428
            ],
            [
              4.8403350665335,
              45.035786029276
            ],
            [
              4.8452075327059,
              45.022221191798
            ],
            [
              4.8374888293491,
              45.01172602474
            ],
            [
              4.8376738035274,
              45.004788192487
            ],
            [
              4.8512610496739,
              44.994680465822
            ],
            [
              4.8525687570978,
              44.97953099948
            ],
            [
              4.8601662520968,
              44.965858918892
            ],
            [
              4.8756803593057,
              44.955208796322
            ],
            [
              4.8865943991285,
              44.936652444616
            ],
            [
              4.8715218455763,
              44.917803545397
            ],
            [
              4.8681634355011,
              44.909105291004
            ],
            [
              4.8579025066681,
              44.903157789874
            ],
            [
              4.8547290164414,
              44.889766730461
            ],
            [
              4.8606144550499,
              44.880768615779
            ],
            [
              4.859459415011,
              44.871750531479
            ],
            [
              4.8447690858272,
              44.858253583993
            ],
            [
              4.8413382097893,
              44.84259184781
            ],
            [
              4.8216552765179,
              44.838672317135
            ],
            [
              4.8198499279442,
              44.834752945224
            ],
            [
              4.8225169914307,
              44.8170913827
            ],
            [
              4.8105773806198,
              44.816126997605
            ],
            [
              4.7972592039065,
              44.804483283812
            ],
            [
              4.7930647721326,
              44.791879535102
            ],
            [
              4.7793490242908,
              44.783077055445
            ],
            [
              4.7662167518672,
              44.778624979068
            ],
            [
              4.7618527499152,
              44.769443231368
            ],
            [
              4.7693074766224,
              44.760200259528
            ],
            [
              4.7606865181698,
              44.744264950792
            ],
            [
              4.7642764416701,
              44.730364078236
            ],
            [
              4.7628468154066,
              44.720092999967
            ],
            [
              4.770855857691,
              44.707768588968
            ],
            [
              4.7735508584443,
              44.696632002958
            ],
            [
              4.7811112562781,
              44.682832714353
            ],
            [
              4.7790828544927,
              44.679075999709
            ],
            [
              4.7790663353968,
              44.654696002617
            ],
            [
              4.7740878762465,
              44.644269863496
            ],
            [
              4.7580893573914,
              44.630738870999
            ],
            [
              4.7577811710486,
              44.626516889843
            ],
            [
              4.7404056572708,
              44.602387973218
            ],
            [
              4.7443818072321,
              44.600825092206
            ],
            [
              4.7411702191089,
              44.588775228937
            ],
            [
              4.7102689640623,
              44.581547275665
            ],
            [
              4.704913461242,
              44.573681746966
            ],
            [
              4.7070086082734,
              44.566482686877
            ],
            [
              4.7024424953059,
              44.559506257526
            ],
            [
              4.692360716096,
              44.555893769347
            ],
            [
              4.6936369285978,
              44.542226212664
            ],
            [
              4.7007051593196,
              44.539464850247
            ],
            [
              4.7070188782854,
              44.530840235114
            ],
            [
              4.6920539362507,
              44.51524108867
            ],
            [
              4.6882016564414,
              44.505507967849
            ],
            [
              4.6899681580394,
              44.491246162058
            ],
            [
              4.6989569043439,
              44.481695990615
            ],
            [
              4.7008111306551,
              44.465407726847
            ],
            [
              4.698652294125,
              44.452309460148
            ],
            [
              4.6913719125076,
              44.441606696117
            ],
            [
              4.6717207236289,
              44.435575882037
            ],
            [
              4.6673737105298,
              44.430666189382
            ],
            [
              4.6631054744838,
              44.408193195403
            ],
            [
              4.6643671500128,
              44.398945267903
            ],
            [
              4.6489499333936,
              44.372462429925
            ],
            [
              4.6477668446587,
              44.345435285153
            ],
            [
              4.6506111734968,
              44.329803148128
            ],
            [
              4.653662160064,
              44.297280366126
            ],
            [
              4.649227423465,
              44.270359808636
            ],
            [
              4.641962943496,
              44.273967232879
            ],
            [
              4.6380600403344,
              44.282964739036
            ],
            [
              4.6288231783395,
              44.284004141308
            ],
            [
              4.6181459353598,
              44.278542099268
            ],
            [
              4.6068181251102,
              44.290471804776
            ],
            [
              4.5869814679806,
              44.29459622458
            ],
            [
              4.574187229155,
              44.300751054654
            ],
            [
              4.5586960480886,
              44.302442886009
            ],
            [
              4.5444682300368,
              44.320766940178
            ],
            [
              4.5326208269687,
              44.32252533633
            ],
            [
              4.5178637257565,
              44.329927083074
            ],
            [
              4.503538697272,
              44.340187516986
            ],
            [
              4.484186331178,
              44.337581914669
            ],
            [
              4.4761161664256,
              44.34074524217
            ],
            [
              4.4576163712243,
              44.341635623893
            ],
            [
              4.4508857895897,
              44.33261714008
            ],
            [
              4.4492717103226,
              44.304914782152
            ],
            [
              4.4506900513602,
              44.297286859459
            ],
            [
              4.440502356745,
              44.284053476816
            ],
            [
              4.4218910835178,
              44.287324346637
            ],
            [
              4.3989780395897,
              44.288943564372
            ],
            [
              4.3934072251798,
              44.293647114857
            ],
            [
              4.3907812808888,
              44.303051306396
            ],
            [
              4.4032085466386,
              44.333895573125
            ],
            [
              4.3941986915176,
              44.345264673242
            ],
            [
              4.3865260888309,
              44.346613937013
            ],
            [
              4.3667775642003,
              44.339497579716
            ],
            [
              4.3360706324228,
              44.339519174094
            ],
            [
              4.3217239441113,
              44.323989000128
            ],
            [
              4.3044280048901,
              44.315234251029
            ],
            [
              4.2884228053033,
              44.31465771441
            ],
            [
              4.2894096266193,
              44.293214668623
            ],
            [
              4.2766179866697,
              44.274252680812
            ],
            [
              4.2588499153827,
              44.264783733394
            ],
            [
              4.2413235022379,
              44.270096446791
            ],
            [
              4.2164426737394,
              44.288852848267
            ],
            [
              4.1867071328058,
              44.29968541211
            ],
            [
              4.1777450676527,
              44.317879259785
            ],
            [
              4.1548869300058,
              44.31262212801
            ],
            [
              4.1428714788751,
              44.313351716383
            ],
            [
              4.1403038447089,
              44.327883881292
            ],
            [
              4.1267455749949,
              44.337730884113
            ],
            [
              4.1125125628157,
              44.329490022147
            ],
            [
              4.1039911697566,
              44.333899706452
            ],
            [
              4.0716270663138,
              44.327298770679
            ],
            [
              4.0514566440182,
              44.317321809072
            ],
            [
              4.0368667860025,
              44.330771008666
            ],
            [
              4.0450038312264,
              44.333082309228
            ],
            [
              4.0531535999561,
              44.340968920772
            ],
            [
              4.055527118005,
              44.365626171449
            ],
            [
              4.0529853320038,
              44.378694159984
            ],
            [
              4.0437442732688,
              44.384550041392
            ],
            [
              4.042468868612,
              44.39464402593
            ],
            [
              4.0572129135807,
              44.393348439495
            ],
            [
              4.0684449498584,
              44.405111736232
            ],
            [
              4.0561040289114,
              44.414335993335
            ],
            [
              4.0354823981467,
              44.420090870159
            ],
            [
              4.0464583808932,
              44.432710617884
            ],
            [
              4.0375991394785,
              44.445719563488
            ],
            [
              4.0232058055546,
              44.445573236432
            ],
            [
              4.0149839677909,
              44.454514219975
            ],
            [
              3.9981617468281,
              44.459798467391
            ],
            [
              3.9875441966947,
              44.473361271665
            ],
            [
              3.985747412107,
              44.487924045953
            ],
            [
              3.9873321701777,
              44.499162516354
            ],
            [
              3.9837035025138,
              44.510167633816
            ],
            [
              3.9766536412959,
              44.516689836275
            ],
            [
              3.9753657955514,
              44.523650580786
            ],
            [
              3.9657451606381,
              44.537497086144
            ],
            [
              3.9608749832371,
              44.554803732187
            ],
            [
              3.9451514993013,
              44.573790000095
            ],
            [
              3.9284831974258,
              44.569998826159
            ],
            [
              3.9207367872473,
              44.575746388916
            ],
            [
              3.9191671077331,
              44.585245092622
            ],
            [
              3.90517074571,
              44.592708958301
            ],
            [
              3.9083038945979,
              44.606615580322
            ],
            [
              3.8985315708048,
              44.613411802763
            ],
            [
              3.8926148288923,
              44.623030105672
            ],
            [
              3.8947423692172,
              44.651129115275
            ],
            [
              3.882909471786,
              44.660088831897
            ],
            [
              3.8732699495096,
              44.678201691122
            ],
            [
              3.8845476207292,
              44.697347879776
            ],
            [
              3.8696426954409,
              44.696962680173
            ],
            [
              3.8718913936808,
              44.705396507123
            ],
            [
              3.8615128126047,
              44.71201694644
            ],
            [
              3.8677916785686,
              44.728142672276
            ],
            [
              3.8762993159981,
              44.731800448649
            ],
            [
              3.8754615107331,
              44.740626944691
            ],
            [
              3.8625310211191,
              44.743866105932
            ],
            [
              3.8694563032288,
              44.754798141303
            ],
            [
              3.8965835164906,
              44.75601085378
            ],
            [
              3.8998742192715,
              44.763530139601
            ],
            [
              3.924795349749,
              44.769623984123
            ],
            [
              3.9181323077203,
              44.775611923372
            ],
            [
              3.9277704016304,
              44.792980070287
            ],
            [
              3.9369998892174,
              44.795419028511
            ],
            [
              3.9455779540045,
              44.824415193964
            ],
            [
              3.96317818398,
              44.829753498309
            ],
            [
              3.9668397720926,
              44.820478207899
            ],
            [
              3.9817003652052,
              44.811765152168
            ],
            [
              3.9885657294166,
              44.815261634325
            ],
            [
              3.9800025103294,
              44.830097794485
            ],
            [
              3.9897119503368,
              44.833515968039
            ],
            [
              3.9956955079053,
              44.821280942908
            ],
            [
              4.0059233163163,
              44.827253766785
            ],
            [
              4.0042371196017,
              44.836053356709
            ],
            [
              4.0134026694452,
              44.836820914772
            ],
            [
              4.0165879015814,
              44.83087972993
            ],
            [
              4.0283799355707,
              44.834081856378
            ],
            [
              4.0201700534284,
              44.84572335446
            ],
            [
              4.0250473774012,
              44.850201171515
            ],
            [
              4.0287308003672,
              44.866167427344
            ],
            [
              4.0396337860339,
              44.873161160486
            ],
            [
              4.0801862657178,
              44.868327782609
            ],
            [
              4.1036755693091,
              44.876969598315
            ],
            [
              4.1177586522935,
              44.878155973537
            ],
            [
              4.1350704806238,
              44.874641562484
            ],
            [
              4.1561572312146,
              44.873329080776
            ],
            [
              4.1682848434293,
              44.882764822524
            ],
            [
              4.179950040218,
              44.887303097557
            ],
            [
              4.1828755040663,
              44.905432217467
            ],
            [
              4.1899363021143,
              44.91419296439
            ],
            [
              4.2158514034226,
              44.932720359535
            ],
            [
              4.220666560271,
              44.944801368362
            ],
            [
              4.2217077816979,
              44.961339674715
            ],
            [
              4.2359884866829,
              44.970400050285
            ],
            [
              4.2499552025599,
              44.960567429204
            ],
            [
              4.2579311130587,
              44.959979531121
            ],
            [
              4.2745372987383,
              44.96596145392
            ],
            [
              4.2915838185243,
              44.967389275782
            ],
            [
              4.3116688879015,
              44.971778239838
            ],
            [
              4.3132330668007,
              44.979575161458
            ],
            [
              4.2982695452624,
              44.989167246444
            ],
            [
              4.2898240145874,
              44.997302372073
            ],
            [
              4.2972444541478,
              45.017019865124
            ],
            [
              4.3146755888177,
              45.030787606494
            ],
            [
              4.3519275289554,
              45.027736342466
            ],
            [
              4.35676149337,
              45.033641652456
            ],
            [
              4.3788076747538,
              45.036179566224
            ],
            [
              4.3817210801496,
              45.041578104264
            ],
            [
              4.3780184377404,
              45.054268232044
            ],
            [
              4.3647163026964,
              45.054514848088
            ],
            [
              4.3492762702278,
              45.065000396792
            ],
            [
              4.3464253885296,
              45.074635356183
            ],
            [
              4.3587771551575,
              45.080795413456
            ],
            [
              4.3576519784305,
              45.086911103409
            ],
            [
              4.3642255307444,
              45.092650063289
            ],
            [
              4.3598684623641,
              45.099648200079
            ],
            [
              4.3753034115222,
              45.10482784163
            ],
            [
              4.3842146497902,
              45.101478325023
            ],
            [
              4.3995705528059,
              45.104758184855
            ],
            [
              4.400228531564,
              45.116002199184
            ],
            [
              4.3888401749693,
              45.122863564048
            ],
            [
              4.3793754194623,
              45.122983730837
            ],
            [
              4.3702243365423,
              45.130296355428
            ],
            [
              4.3765615334121,
              45.144361359671
            ],
            [
              4.3991767622718,
              45.140092398517
            ],
            [
              4.4115999169283,
              45.140814831397
            ],
            [
              4.4227050210625,
              45.137284079421
            ],
            [
              4.4316895547309,
              45.128307683233
            ],
            [
              4.4270606178391,
              45.116418502371
            ],
            [
              4.4370065836344,
              45.109530343216
            ],
            [
              4.4377435075056,
              45.121669278229
            ],
            [
              4.457292871748,
              45.119021852513
            ],
            [
              4.4459864977865,
              45.133580616944
            ],
            [
              4.4432601992355,
              45.14083118833
            ],
            [
              4.4473475059375,
              45.157049559665
            ],
            [
              4.4580688071034,
              45.168373285647
            ],
            [
              4.4592634434439,
              45.175018271087
            ],
            [
              4.4725368542422,
              45.179407156759
            ],
            [
              4.4803647181332,
              45.195328463561
            ],
            [
              4.4811266509979,
              45.215740791502
            ],
            [
              4.4877709310323,
              45.216341441865
            ],
            [
              4.489606977621,
              45.224787864759
            ],
            [
              4.4831320440493,
              45.236445615502
            ]
          ]
        ]
      },
      "properties": {
        "code": "7",
        "nom": "Ardèche",
        "population": 329325,
        "delitspour1000": "3,616",
        "nombremosque": 1,
        "benefRsa": 11597,
        "eoliennes": 98
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.2330628764399,
              49.957824711709
            ],
            [
              4.2543844268894,
              49.963631572174
            ],
            [
              4.2912940079087,
              49.964058971117
            ],
            [
              4.3014826951044,
              49.966176318126
            ],
            [
              4.3336522040504,
              49.963560746982
            ],
            [
              4.3497118423918,
              49.952178234237
            ],
            [
              4.3768351947882,
              49.952426776681
            ],
            [
              4.3893549519505,
              49.948471550376
            ],
            [
              4.4040767168602,
              49.949066249233
            ],
            [
              4.4384321997593,
              49.940983017693
            ],
            [
              4.4469381022162,
              49.937508219405
            ],
            [
              4.4816075926688,
              49.947850133409
            ],
            [
              4.5104090412086,
              49.94668310443
            ],
            [
              4.5268882654673,
              49.955655131433
            ],
            [
              4.5379600686649,
              49.966578753488
            ],
            [
              4.562760480326,
              49.969878068755
            ],
            [
              4.5737037707612,
              49.980303220057
            ],
            [
              4.5930582012037,
              49.985505370138
            ],
            [
              4.6234910262232,
              49.986514367297
            ],
            [
              4.6718734438468,
              49.997042353417
            ],
            [
              4.6888729975296,
              49.99550012591
            ],
            [
              4.6851735062023,
              50.008022811301
            ],
            [
              4.6855679524785,
              50.027630172966
            ],
            [
              4.6964968238826,
              50.048065555594
            ],
            [
              4.6959001788656,
              50.059078634975
            ],
            [
              4.6831845410969,
              50.064918750085
            ],
            [
              4.6930262279408,
              50.084818698216
            ],
            [
              4.702082951479,
              50.095527943315
            ],
            [
              4.7516371956114,
              50.112096179533
            ],
            [
              4.7621611459353,
              50.136395161183
            ],
            [
              4.7797569187354,
              50.141423325848
            ],
            [
              4.8060299652994,
              50.152928502564
            ],
            [
              4.8225821967951,
              50.168317417174
            ],
            [
              4.8242908682916,
              50.160729654086
            ],
            [
              4.8319939129987,
              50.153954420888
            ],
            [
              4.8795586056388,
              50.152031455312
            ],
            [
              4.8823453724974,
              50.14107993006
            ],
            [
              4.8949695160522,
              50.140358533811
            ],
            [
              4.8948873702986,
              50.134603452171
            ],
            [
              4.8777264037364,
              50.129652324419
            ],
            [
              4.8706357641482,
              50.124646439548
            ],
            [
              4.8684744101123,
              50.116644777365
            ],
            [
              4.8732123209519,
              50.106964035761
            ],
            [
              4.8685258721021,
              50.097622646189
            ],
            [
              4.8512334054671,
              50.101365762653
            ],
            [
              4.8435535797063,
              50.093372937943
            ],
            [
              4.8428707035034,
              50.077477843885
            ],
            [
              4.8379348636099,
              50.067107469075
            ],
            [
              4.8193112936247,
              50.065876763524
            ],
            [
              4.8269925484463,
              50.052897786762
            ],
            [
              4.8408180239435,
              50.045906327693
            ],
            [
              4.8403927713466,
              50.037589003447
            ],
            [
              4.8219880629777,
              50.027412758579
            ],
            [
              4.8159415189027,
              49.997896526955
            ],
            [
              4.8110027580392,
              49.987971684423
            ],
            [
              4.794698989736,
              49.978299033895
            ],
            [
              4.7901082811856,
              49.970018583443
            ],
            [
              4.7909101015257,
              49.958397607379
            ],
            [
              4.8149651624191,
              49.95406557034
            ],
            [
              4.8294146767334,
              49.949216811425
            ],
            [
              4.8393316997629,
              49.950755525407
            ],
            [
              4.8501867463093,
              49.946472183139
            ],
            [
              4.8581281971305,
              49.93289732385
            ],
            [
              4.8813667911683,
              49.921507940837
            ],
            [
              4.8876674649878,
              49.90625840031
            ],
            [
              4.8833077168313,
              49.896180747091
            ],
            [
              4.8692226992226,
              49.883673952197
            ],
            [
              4.8621352372787,
              49.869183589271
            ],
            [
              4.8541885068293,
              49.864788994801
            ],
            [
              4.8517209454791,
              49.856206959432
            ],
            [
              4.8554596773055,
              49.848133613298
            ],
            [
              4.8675765193083,
              49.840075948112
            ],
            [
              4.8738789972466,
              49.820795895287
            ],
            [
              4.8670886259725,
              49.814194172037
            ],
            [
              4.8553769519334,
              49.792361619124
            ],
            [
              4.8622979513047,
              49.789221252237
            ],
            [
              4.8809506209952,
              49.792325737532
            ],
            [
              4.8875425422306,
              49.788171662915
            ],
            [
              4.9102925994326,
              49.786100854737
            ],
            [
              4.9309281605517,
              49.786852475693
            ],
            [
              4.9561280722759,
              49.801228329118
            ],
            [
              4.9988354545104,
              49.799310967803
            ],
            [
              5.0089296334883,
              49.781937129687
            ],
            [
              5.038248102836,
              49.771525570008
            ],
            [
              5.0568010186231,
              49.767535269907
            ],
            [
              5.063178302851,
              49.761924291203
            ],
            [
              5.093500464756,
              49.763726941381
            ],
            [
              5.1150746404809,
              49.741436226312
            ],
            [
              5.1246896996546,
              49.727395536304
            ],
            [
              5.1230703404202,
              49.717305567328
            ],
            [
              5.143638993168,
              49.709300418645
            ],
            [
              5.1533130431568,
              49.718376209895
            ],
            [
              5.1615689619341,
              49.714805281588
            ],
            [
              5.1658552238469,
              49.707139109792
            ],
            [
              5.1662014036773,
              49.692914558036
            ],
            [
              5.2066035044019,
              49.693600820549
            ],
            [
              5.224566521714,
              49.689429173903
            ],
            [
              5.2324451768337,
              49.690737513629
            ],
            [
              5.2474131522102,
              49.686990389293
            ],
            [
              5.2580461992444,
              49.694260798854
            ],
            [
              5.2691845901837,
              49.696071670408
            ],
            [
              5.2807611147651,
              49.689032466702
            ],
            [
              5.2865633840877,
              49.681818441276
            ],
            [
              5.3087401102806,
              49.67122355633
            ],
            [
              5.3327639081107,
              49.652787613834
            ],
            [
              5.3169728483899,
              49.643830993309
            ],
            [
              5.3046743423384,
              49.629430201402
            ],
            [
              5.3117321993999,
              49.613546516808
            ],
            [
              5.3397669126158,
              49.616560947097
            ],
            [
              5.3429882085979,
              49.62674042769
            ],
            [
              5.3503363217496,
              49.630862526305
            ],
            [
              5.3699196598296,
              49.622570862025
            ],
            [
              5.3935393812988,
              49.617086785829
            ],
            [
              5.3808929253706,
              49.604283946566
            ],
            [
              5.3793732756841,
              49.594336083817
            ],
            [
              5.3615908063527,
              49.592230592456
            ],
            [
              5.3544440189109,
              49.588507953644
            ],
            [
              5.3432352693932,
              49.59419815719
            ],
            [
              5.3229962135866,
              49.576769750456
            ],
            [
              5.3117634211943,
              49.559287129916
            ],
            [
              5.2851084066964,
              49.553331327694
            ],
            [
              5.2763043921001,
              49.544296675228
            ],
            [
              5.262707136888,
              49.541817880646
            ],
            [
              5.2522425024193,
              49.556433491746
            ],
            [
              5.2346807330309,
              49.562230633403
            ],
            [
              5.2327553218453,
              49.569476323033
            ],
            [
              5.2130538883545,
              49.57347906066
            ],
            [
              5.1975144860259,
              49.566712158664
            ],
            [
              5.1889911476903,
              49.569978574101
            ],
            [
              5.1686760691919,
              49.563231140439
            ],
            [
              5.162979025373,
              49.563530589026
            ],
            [
              5.1448486924557,
              49.589886341917
            ],
            [
              5.1182786920926,
              49.593071219071
            ],
            [
              5.1122681641473,
              49.589569241117
            ],
            [
              5.104571639078,
              49.575852340422
            ],
            [
              5.1101795101092,
              49.573512920945
            ],
            [
              5.1131131503391,
              49.560620642841
            ],
            [
              5.1083902446796,
              49.555291686083
            ],
            [
              5.0960769630609,
              49.557784865303
            ],
            [
              5.0894349846201,
              49.541542898558
            ],
            [
              5.100580497377,
              49.535816055819
            ],
            [
              5.0796339849566,
              49.509861490249
            ],
            [
              5.0646387680349,
              49.508458158654
            ],
            [
              5.0623905513409,
              49.499152667162
            ],
            [
              5.0735014192619,
              49.48656060331
            ],
            [
              5.0814821332705,
              49.484887274721
            ],
            [
              5.0829515970446,
              49.470365328924
            ],
            [
              5.091409768056,
              49.463503402532
            ],
            [
              5.1094498519858,
              49.455422316847
            ],
            [
              5.1037090270263,
              49.43567245539
            ],
            [
              5.1053230916308,
              49.42524507231
            ],
            [
              5.1159039694465,
              49.421927048206
            ],
            [
              5.1018091739591,
              49.41426113807
            ],
            [
              5.0863593822614,
              49.40263284656
            ],
            [
              5.0857766473372,
              49.398789131919
            ],
            [
              5.1038247267133,
              49.388839332529
            ],
            [
              5.0899234459891,
              49.370324513714
            ],
            [
              5.0602044821857,
              49.371516208419
            ],
            [
              5.0534051064651,
              49.356963616151
            ],
            [
              5.0342909616956,
              49.339955943956
            ],
            [
              5.026431605262,
              49.335916662789
            ],
            [
              5.029262636228,
              49.326172152775
            ],
            [
              5.0374895768142,
              49.316800532558
            ],
            [
              5.0298290721899,
              49.311264470087
            ],
            [
              5.038198718801,
              49.303898195861
            ],
            [
              5.0398173770104,
              49.295200441657
            ],
            [
              5.0542494360715,
              49.294946695441
            ],
            [
              5.0592653036142,
              49.289745412169
            ],
            [
              5.0490397469327,
              49.285311702768
            ],
            [
              5.0511796603743,
              49.274052634115
            ],
            [
              5.0335958045544,
              49.27069210926
            ],
            [
              5.0237837017273,
              49.272448785206
            ],
            [
              4.9975627005068,
              49.264254757061
            ],
            [
              4.9981270480935,
              49.258879541555
            ],
            [
              4.9640346881277,
              49.245257319983
            ],
            [
              4.9509906730069,
              49.236867621535
            ],
            [
              4.9480408332939,
              49.24200784976
            ],
            [
              4.9215805432385,
              49.252112852003
            ],
            [
              4.9150358909471,
              49.265080531868
            ],
            [
              4.8942089005946,
              49.259072498398
            ],
            [
              4.8809209460769,
              49.261836038124
            ],
            [
              4.8782338575919,
              49.251565556752
            ],
            [
              4.8627552830681,
              49.241544470381
            ],
            [
              4.868279158254,
              49.228510768771
            ],
            [
              4.8269452277158,
              49.235758179516
            ],
            [
              4.817715778612,
              49.242205770543
            ],
            [
              4.8154203920087,
              49.249858555376
            ],
            [
              4.7888855791891,
              49.241342953304
            ],
            [
              4.7705584082231,
              49.244565572813
            ],
            [
              4.7442321422207,
              49.24147380968
            ],
            [
              4.7268218559424,
              49.252321445059
            ],
            [
              4.7151852750396,
              49.250404067194
            ],
            [
              4.7038118989835,
              49.255402005625
            ],
            [
              4.686077487107,
              49.256757207882
            ],
            [
              4.6703773904455,
              49.241513314897
            ],
            [
              4.6529134947007,
              49.238445863889
            ],
            [
              4.6455930103559,
              49.233149066031
            ],
            [
              4.6355140198289,
              49.232137951902
            ],
            [
              4.6251026499282,
              49.235653385532
            ],
            [
              4.6197163924279,
              49.246982665539
            ],
            [
              4.610977783894,
              49.257053476907
            ],
            [
              4.6058897640524,
              49.269704979896
            ],
            [
              4.5949756349039,
              49.270730016157
            ],
            [
              4.5897027741099,
              49.280136261725
            ],
            [
              4.5999803719844,
              49.288620424164
            ],
            [
              4.5836875668693,
              49.290902386143
            ],
            [
              4.5758736299471,
              49.29664842129
            ],
            [
              4.5558067039812,
              49.295114977855
            ],
            [
              4.5439876891761,
              49.288338157289
            ],
            [
              4.5154382721205,
              49.281908082456
            ],
            [
              4.483839660863,
              49.280776737133
            ],
            [
              4.4595009291629,
              49.277578709194
            ],
            [
              4.4472224785571,
              49.272534237553
            ],
            [
              4.4395351977871,
              49.280104950333
            ],
            [
              4.4225790423555,
              49.28064407894
            ],
            [
              4.4087339424872,
              49.28682410881
            ],
            [
              4.407821521981,
              49.291118004752
            ],
            [
              4.3934273749267,
              49.297089478883
            ],
            [
              4.3798375537257,
              49.313524957456
            ],
            [
              4.3756202848697,
              49.323806086041
            ],
            [
              4.3541597048245,
              49.316159444899
            ],
            [
              4.346164956886,
              49.322252652692
            ],
            [
              4.3308898833462,
              49.327153563894
            ],
            [
              4.306756434583,
              49.327793732979
            ],
            [
              4.2909328608358,
              49.346996088671
            ],
            [
              4.2771414688738,
              49.351460543702
            ],
            [
              4.256035403346,
              49.367327772537
            ],
            [
              4.2483151328731,
              49.382258801676
            ],
            [
              4.2342646077827,
              49.382310421792
            ],
            [
              4.2136709405145,
              49.387863154745
            ],
            [
              4.2013129683942,
              49.383787503175
            ],
            [
              4.1889969306987,
              49.398696021682
            ],
            [
              4.1763877307951,
              49.402223567335
            ],
            [
              4.1602201761661,
              49.401354902869
            ],
            [
              4.1431643234921,
              49.404190679456
            ],
            [
              4.127096507032,
              49.401834316204
            ],
            [
              4.0769173085895,
              49.401608188468
            ],
            [
              4.067790651363,
              49.404938668274
            ],
            [
              4.047974391829,
              49.405644080344
            ],
            [
              4.0502747365168,
              49.412260783575
            ],
            [
              4.0422957368009,
              49.416650795955
            ],
            [
              4.0431448752397,
              49.429325986342
            ],
            [
              4.0376689955921,
              49.438263004999
            ],
            [
              4.0500241066599,
              49.445792451369
            ],
            [
              4.0602468457773,
              49.447151466107
            ],
            [
              4.0529144654974,
              49.460257089671
            ],
            [
              4.0423746855064,
              49.468690509147
            ],
            [
              4.0407061703752,
              49.50853200863
            ],
            [
              4.053158610381,
              49.50864470004
            ],
            [
              4.0618580428951,
              49.512563145954
            ],
            [
              4.0566240283661,
              49.521046840185
            ],
            [
              4.0650976973335,
              49.523284436368
            ],
            [
              4.0758354322042,
              49.518345118497
            ],
            [
              4.0767490566918,
              49.531314775054
            ],
            [
              4.0738006912951,
              49.541617642486
            ],
            [
              4.0487683371061,
              49.545272613584
            ],
            [
              4.0582914969504,
              49.552548182169
            ],
            [
              4.0647940705926,
              49.562212345669
            ],
            [
              4.0765722336699,
              49.57112568919
            ],
            [
              4.0627821408209,
              49.573556972194
            ],
            [
              4.0595605382919,
              49.578709772836
            ],
            [
              4.065110143114,
              49.594908552638
            ],
            [
              4.0541912133221,
              49.595288700911
            ],
            [
              4.0313962718463,
              49.614287229711
            ],
            [
              4.0252899216328,
              49.62252962525
            ],
            [
              4.0379111248697,
              49.622918577627
            ],
            [
              4.0432501448672,
              49.6361455878
            ],
            [
              4.0511107039532,
              49.634644480527
            ],
            [
              4.0695561865706,
              49.636751879497
            ],
            [
              4.0993133133426,
              49.628479985907
            ],
            [
              4.1147910874013,
              49.635304817786
            ],
            [
              4.1254660330069,
              49.650608155401
            ],
            [
              4.1223323319077,
              49.659520506862
            ],
            [
              4.1270270054568,
              49.677917606181
            ],
            [
              4.1475377596226,
              49.678019756234
            ],
            [
              4.1441873561823,
              49.688543920373
            ],
            [
              4.1783005290521,
              49.698036101434
            ],
            [
              4.1920358906093,
              49.710905599545
            ],
            [
              4.1926293949408,
              49.715800885171
            ],
            [
              4.2064629076853,
              49.723538323397
            ],
            [
              4.2247072679536,
              49.727198714419
            ],
            [
              4.2290201625555,
              49.745744739695
            ],
            [
              4.2500943395752,
              49.757098850356
            ],
            [
              4.2382185523689,
              49.767642716303
            ],
            [
              4.2266193641282,
              49.773087602827
            ],
            [
              4.2142637924854,
              49.771267691897
            ],
            [
              4.2095884562322,
              49.781777002902
            ],
            [
              4.2224308204953,
              49.786967474101
            ],
            [
              4.2270460920968,
              49.793183108192
            ],
            [
              4.2147724033566,
              49.806110108269
            ],
            [
              4.21989152014,
              49.815134132001
            ],
            [
              4.2234884321126,
              49.83376467914
            ],
            [
              4.2366221851606,
              49.849699207378
            ],
            [
              4.2485056451169,
              49.856518172527
            ],
            [
              4.2530532522095,
              49.872272130792
            ],
            [
              4.2540638814402,
              49.904004764573
            ],
            [
              4.2332446058305,
              49.904304609803
            ],
            [
              4.2221391301616,
              49.910422069675
            ],
            [
              4.2187089162197,
              49.920686498287
            ],
            [
              4.2216024803296,
              49.937902313306
            ],
            [
              4.2303959475263,
              49.947204968202
            ],
            [
              4.2330628764399,
              49.957824711709
            ]
          ]
        ]
      },
      "properties": {
        "code": "8",
        "nom": "Ardennes",
        "population": 269701,
        "delitspour1000": "6,092",
        "benefRsa": 18332,
        "eoliennes": 231
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1.6884233932357,
              43.273554672371
            ],
            [
              1.7007546159588,
              43.260536148347
            ],
            [
              1.6997831731081,
              43.243827772182
            ],
            [
              1.7238477981704,
              43.224522495613
            ],
            [
              1.7208867336366,
              43.218745125508
            ],
            [
              1.7255156257731,
              43.211942040675
            ],
            [
              1.7099424228066,
              43.200585571526
            ],
            [
              1.7100614045293,
              43.189696294172
            ],
            [
              1.7354358287353,
              43.185789204059
            ],
            [
              1.7435831558346,
              43.182725893946
            ],
            [
              1.7478461517093,
              43.175363704104
            ],
            [
              1.7778084523523,
              43.160753591375
            ],
            [
              1.8069320952074,
              43.155501460795
            ],
            [
              1.8247771300982,
              43.145813043369
            ],
            [
              1.8482001028058,
              43.145922753518
            ],
            [
              1.8436090403584,
              43.157599334299
            ],
            [
              1.8520774929174,
              43.160513521664
            ],
            [
              1.8561936135101,
              43.145320584408
            ],
            [
              1.8804018658747,
              43.14153210392
            ],
            [
              1.8809180435515,
              43.126794944692
            ],
            [
              1.9018860600783,
              43.124357874809
            ],
            [
              1.9023329243618,
              43.130253440677
            ],
            [
              1.9177421166021,
              43.133234660517
            ],
            [
              1.9494734751292,
              43.11949081683
            ],
            [
              1.9506935999843,
              43.102254614249
            ],
            [
              1.9557697173495,
              43.085445578423
            ],
            [
              1.9497271826879,
              43.080024986538
            ],
            [
              1.9638549172992,
              43.06917736292
            ],
            [
              1.9452104401249,
              43.066549790409
            ],
            [
              1.9403303636833,
              43.061716671606
            ],
            [
              1.9411266925152,
              43.052718110265
            ],
            [
              1.9508584341989,
              43.046590894868
            ],
            [
              1.9586368389642,
              43.050369497515
            ],
            [
              1.9720976511514,
              43.046611393848
            ],
            [
              1.9849967014251,
              43.032613312256
            ],
            [
              1.9850967867381,
              43.026271388398
            ],
            [
              1.9695836475611,
              43.007942793079
            ],
            [
              1.9851357344142,
              43.004594554874
            ],
            [
              1.9853242058519,
              42.966955184292
            ],
            [
              2.0036244299195,
              42.960482549519
            ],
            [
              1.9991183826026,
              42.955003012434
            ],
            [
              1.9885860585978,
              42.953374578941
            ],
            [
              1.9802189219425,
              42.959688363682
            ],
            [
              1.9622253344282,
              42.961140470724
            ],
            [
              1.936037298041,
              42.951628828023
            ],
            [
              1.9338478606359,
              42.939320639064
            ],
            [
              1.9805767739427,
              42.929578816015
            ],
            [
              1.9875408762769,
              42.917157059247
            ],
            [
              1.9910671704996,
              42.904641621976
            ],
            [
              1.9810016845404,
              42.87841643035
            ],
            [
              1.9869222151088,
              42.871007203276
            ],
            [
              1.9456608008971,
              42.856195612969
            ],
            [
              1.8804691523907,
              42.852666520642
            ],
            [
              1.8750171229238,
              42.84606877232
            ],
            [
              1.8729010044834,
              42.83589155119
            ],
            [
              1.8602403431469,
              42.827608292584
            ],
            [
              1.8593817847995,
              42.820804962781
            ],
            [
              1.8692014277833,
              42.815082423983
            ],
            [
              1.8960901541122,
              42.808667398867
            ],
            [
              1.9169252616661,
              42.78350434537
            ],
            [
              1.9288394992032,
              42.774948091707
            ],
            [
              1.9081785403601,
              42.767670627363
            ],
            [
              1.9182615505133,
              42.757409155918
            ],
            [
              1.9424553009298,
              42.746581205068
            ],
            [
              1.9554004775238,
              42.736704442421
            ],
            [
              2.0079257545377,
              42.73412313884
            ],
            [
              2.0132744900391,
              42.738369124152
            ],
            [
              2.0254188897268,
              42.738989255526
            ],
            [
              2.0323055427977,
              42.746594223398
            ],
            [
              2.0423514687936,
              42.746734851366
            ],
            [
              2.0572091234374,
              42.756222904226
            ],
            [
              2.0862958088794,
              42.751796313706
            ],
            [
              2.0873246405121,
              42.739333179139
            ],
            [
              2.1270176793049,
              42.716509230656
            ],
            [
              2.1395519109111,
              42.71229378372
            ],
            [
              2.1406094709408,
              42.70582111524
            ],
            [
              2.1606300279024,
              42.701123613621
            ],
            [
              2.1678528137192,
              42.696187020903
            ],
            [
              2.1758766074869,
              42.682791646952
            ],
            [
              2.1674816196022,
              42.673376591782
            ],
            [
              2.1660488223224,
              42.663917432789
            ],
            [
              2.1512958319248,
              42.661961816254
            ],
            [
              2.1326197747187,
              42.668366655895
            ],
            [
              2.1198625508162,
              42.669463714715
            ],
            [
              2.1114677613897,
              42.66602438992
            ],
            [
              2.0958625941193,
              42.664104385792
            ],
            [
              2.0828721186904,
              42.665789382917
            ],
            [
              2.0545205344694,
              42.663059183043
            ],
            [
              2.02903411415,
              42.652957529531
            ],
            [
              2.0187871252783,
              42.653420586628
            ],
            [
              2.0005161638086,
              42.661563048952
            ],
            [
              1.971753608035,
              42.616917738425
            ],
            [
              1.9521185022163,
              42.618306812711
            ],
            [
              1.9361237389211,
              42.605411191977
            ],
            [
              1.9259284809876,
              42.609220331127
            ],
            [
              1.9072909860169,
              42.608629529051
            ],
            [
              1.9001799629623,
              42.614904331366
            ],
            [
              1.8919813333501,
              42.594979956731
            ],
            [
              1.8870814211318,
              42.590550434192
            ],
            [
              1.8742279234244,
              42.590001308736
            ],
            [
              1.8744788516217,
              42.581409515841
            ],
            [
              1.8557145165105,
              42.58322866123
            ],
            [
              1.8334569182939,
              42.582739817452
            ],
            [
              1.8192693018026,
              42.579501861534
            ],
            [
              1.800999700616,
              42.572397819345
            ],
            [
              1.7861298758203,
              42.573623487381
            ],
            [
              1.7809401403226,
              42.582603070692
            ],
            [
              1.7731587094773,
              42.580676227298
            ],
            [
              1.7536481560277,
              42.582645134018
            ],
            [
              1.7419674623208,
              42.587752779596
            ],
            [
              1.7259218933308,
              42.59036432389
            ],
            [
              1.7264445544535,
              42.599644154949
            ],
            [
              1.7379681342676,
              42.611311358201
            ],
            [
              1.7349772402613,
              42.617150103375
            ],
            [
              1.7179447596001,
              42.614762264528
            ],
            [
              1.7000214431602,
              42.621543905731
            ],
            [
              1.6873563216807,
              42.623900502482
            ],
            [
              1.6620231363069,
              42.619477292944
            ],
            [
              1.6537229363056,
              42.626311949339
            ],
            [
              1.6413815633821,
              42.628126820518
            ],
            [
              1.6190091695864,
              42.626912244592
            ],
            [
              1.6014688979593,
              42.628040210076
            ],
            [
              1.5842076238912,
              42.634095648993
            ],
            [
              1.5687902887144,
              42.648434939425
            ],
            [
              1.5492508690021,
              42.655772723263
            ],
            [
              1.5221497444791,
              42.649182732256
            ],
            [
              1.5189050579628,
              42.645476248608
            ],
            [
              1.5014171161218,
              42.645160693912
            ],
            [
              1.4937449489606,
              42.653208850036
            ],
            [
              1.4801092686891,
              42.651393752761
            ],
            [
              1.4778976589457,
              42.643814220979
            ],
            [
              1.4683365082247,
              42.630822512454
            ],
            [
              1.4772664705807,
              42.614964046337
            ],
            [
              1.4694604333944,
              42.606890205337
            ],
            [
              1.4589391989978,
              42.603637712724
            ],
            [
              1.4401541878412,
              42.60350366823
            ],
            [
              1.4350570021221,
              42.60607368638
            ],
            [
              1.4200859104601,
              42.627197957489
            ],
            [
              1.4135896331553,
              42.651807228313
            ],
            [
              1.3942419945616,
              42.668386695134
            ],
            [
              1.3877372500693,
              42.670986654888
            ],
            [
              1.3895995518927,
              42.685085573888
            ],
            [
              1.3786606854809,
              42.693636239811
            ],
            [
              1.3544057158196,
              42.699495465984
            ],
            [
              1.3523647298671,
              42.708889639069
            ],
            [
              1.3568943438009,
              42.71932288287
            ],
            [
              1.342365880439,
              42.719196972787
            ],
            [
              1.3352658325545,
              42.724033974941
            ],
            [
              1.3250225479117,
              42.723901216656
            ],
            [
              1.310831490396,
              42.718075501434
            ],
            [
              1.2980552717348,
              42.718971253687
            ],
            [
              1.2775594332565,
              42.714750302311
            ],
            [
              1.251508843902,
              42.717851623956
            ],
            [
              1.2474713412106,
              42.722040389267
            ],
            [
              1.2302143018174,
              42.72758342439
            ],
            [
              1.2171573089305,
              42.720482304172
            ],
            [
              1.1848025015663,
              42.715102850609
            ],
            [
              1.1727736952394,
              42.708100928642
            ],
            [
              1.1618506762641,
              42.711043675126
            ],
            [
              1.1333056678708,
              42.72901199858
            ],
            [
              1.1300970414747,
              42.752068486433
            ],
            [
              1.1221505221208,
              42.76017431013
            ],
            [
              1.1092203049835,
              42.768379798114
            ],
            [
              1.0751085868396,
              42.785348263934
            ],
            [
              1.0543403754335,
              42.782370278653
            ],
            [
              1.0356210186709,
              42.786428027141
            ],
            [
              1.0123628046297,
              42.788067996977
            ],
            [
              1.0055793118079,
              42.790800484118
            ],
            [
              0.99648511466901,
              42.78745217576
            ],
            [
              0.98310308426902,
              42.787068077252
            ],
            [
              0.9732840226901,
              42.797457087229
            ],
            [
              0.95989089975521,
              42.805643824863
            ],
            [
              0.93973887204259,
              42.796973247404
            ],
            [
              0.93391854092192,
              42.789522309625
            ],
            [
              0.92732709328685,
              42.789255631926
            ],
            [
              0.91105922430916,
              42.796342655042
            ],
            [
              0.88384668496068,
              42.813501413999
            ],
            [
              0.87039192320778,
              42.816245743581
            ],
            [
              0.85830081521383,
              42.825718092689
            ],
            [
              0.85988322052693,
              42.838298291523
            ],
            [
              0.85047744231586,
              42.855146931618
            ],
            [
              0.84821359551544,
              42.865329650272
            ],
            [
              0.83982835962524,
              42.866771431936
            ],
            [
              0.8357013230124,
              42.876375875541
            ],
            [
              0.84292190702011,
              42.88857840842
            ],
            [
              0.83343292351349,
              42.90746882166
            ],
            [
              0.82612266137771,
              42.914278721023
            ],
            [
              0.83241568886004,
              42.919991647217
            ],
            [
              0.85692609397602,
              42.92549163353
            ],
            [
              0.87603944818218,
              42.926912994605
            ],
            [
              0.88530949146611,
              42.936032646016
            ],
            [
              0.87641147743677,
              42.949423501558
            ],
            [
              0.87491501083315,
              42.957776717926
            ],
            [
              0.90858243386613,
              42.958371438839
            ],
            [
              0.92766324080075,
              42.966687423421
            ],
            [
              0.95022483505411,
              42.966074638893
            ],
            [
              0.97909520433636,
              42.97443264493
            ],
            [
              0.98119899892685,
              42.979140304836
            ],
            [
              0.99509676944565,
              42.990833670804
            ],
            [
              1.0070475401309,
              43.007586533836
            ],
            [
              1.0189442034638,
              43.010383740373
            ],
            [
              1.0087764976587,
              43.018093076585
            ],
            [
              0.9867359607193,
              43.027502633538
            ],
            [
              0.98289171246668,
              43.054014096139
            ],
            [
              0.98517130914337,
              43.067052731075
            ],
            [
              1.0052486980698,
              43.080665473937
            ],
            [
              0.99463979120919,
              43.08723599851
            ],
            [
              0.99233974680337,
              43.098016624836
            ],
            [
              1.0116824594973,
              43.10949847032
            ],
            [
              1.0286490780477,
              43.10064941233
            ],
            [
              1.0388407127951,
              43.100549312823
            ],
            [
              1.0525728726409,
              43.125370252262
            ],
            [
              1.0495535976405,
              43.139648199291
            ],
            [
              1.0589174294916,
              43.141827188986
            ],
            [
              1.0879769348772,
              43.133821692876
            ],
            [
              1.0898394045755,
              43.141813573354
            ],
            [
              1.1092766083352,
              43.144140969511
            ],
            [
              1.1240759431443,
              43.156907297953
            ],
            [
              1.1407937490565,
              43.144479439169
            ],
            [
              1.1454230371574,
              43.137827610533
            ],
            [
              1.162309627544,
              43.132290389403
            ],
            [
              1.1751638222406,
              43.140929852583
            ],
            [
              1.2087319295319,
              43.117953707641
            ],
            [
              1.2105886400872,
              43.107083348603
            ],
            [
              1.2236270656509,
              43.086060227758
            ],
            [
              1.2458835539875,
              43.088211645242
            ],
            [
              1.2618548870607,
              43.091652541667
            ],
            [
              1.2596485927641,
              43.104685971583
            ],
            [
              1.2657154335863,
              43.109508722946
            ],
            [
              1.2808504202672,
              43.111411090679
            ],
            [
              1.2914480700483,
              43.124111701013
            ],
            [
              1.3020119564183,
              43.123980856737
            ],
            [
              1.2689269278789,
              43.146541731541
            ],
            [
              1.242570863495,
              43.148989839584
            ],
            [
              1.2189521888506,
              43.153166413007
            ],
            [
              1.2139839741893,
              43.162432965623
            ],
            [
              1.2273716929066,
              43.173498807023
            ],
            [
              1.227478061976,
              43.183896662483
            ],
            [
              1.2321332146768,
              43.188266462242
            ],
            [
              1.243676771125,
              43.189552631539
            ],
            [
              1.249008854834,
              43.183613057961
            ],
            [
              1.2598525778452,
              43.186193409625
            ],
            [
              1.2740710371613,
              43.196228567239
            ],
            [
              1.2962794920389,
              43.189284921512
            ],
            [
              1.3057212109254,
              43.194910946327
            ],
            [
              1.3146017705234,
              43.189991031341
            ],
            [
              1.3296086619392,
              43.197016168606
            ],
            [
              1.3319533192479,
              43.204100657505
            ],
            [
              1.3501665928962,
              43.211422546187
            ],
            [
              1.3597470188554,
              43.212992778605
            ],
            [
              1.3683616310962,
              43.209188972055
            ],
            [
              1.3743783770211,
              43.213744940483
            ],
            [
              1.3832321285424,
              43.229485899343
            ],
            [
              1.3751545701023,
              43.237788626735
            ],
            [
              1.3526468627747,
              43.244477126922
            ],
            [
              1.3425518883338,
              43.243934888934
            ],
            [
              1.3318823817822,
              43.248841488087
            ],
            [
              1.3306707599026,
              43.26255993939
            ],
            [
              1.308342979857,
              43.26452945905
            ],
            [
              1.297105154879,
              43.262429239143
            ],
            [
              1.3002670800627,
              43.275247427926
            ],
            [
              1.2933090414018,
              43.285338357056
            ],
            [
              1.3031093227975,
              43.289392265627
            ],
            [
              1.3049961387762,
              43.298199122183
            ],
            [
              1.3180293057672,
              43.295887855993
            ],
            [
              1.3239671313317,
              43.309397473097
            ],
            [
              1.3436471719609,
              43.315601482419
            ],
            [
              1.3730180033309,
              43.310144051632
            ],
            [
              1.3654495190749,
              43.305648938837
            ],
            [
              1.3821820496301,
              43.288209689415
            ],
            [
              1.3991452364303,
              43.279219254827
            ],
            [
              1.4114785641312,
              43.270268095135
            ],
            [
              1.4253583153188,
              43.256567003232
            ],
            [
              1.426740704298,
              43.246137007285
            ],
            [
              1.4211403100532,
              43.239540512069
            ],
            [
              1.4176056708049,
              43.227835861321
            ],
            [
              1.4320931895049,
              43.217733181789
            ],
            [
              1.4479262267221,
              43.212886885879
            ],
            [
              1.4637155449058,
              43.210599069418
            ],
            [
              1.4754984723227,
              43.216993665989
            ],
            [
              1.4845403775247,
              43.226159128131
            ],
            [
              1.5046030382736,
              43.221311626744
            ],
            [
              1.5042641108015,
              43.232828183557
            ],
            [
              1.4924444217221,
              43.23683384611
            ],
            [
              1.5044650994084,
              43.249435574042
            ],
            [
              1.4894344483981,
              43.258421325801
            ],
            [
              1.4902005691709,
              43.271813366627
            ],
            [
              1.4948363091727,
              43.277383380686
            ],
            [
              1.4945235196899,
              43.28616026132
            ],
            [
              1.5175886435955,
              43.28941666797
            ],
            [
              1.515842696407,
              43.277334746321
            ],
            [
              1.5187269099082,
              43.269170803809
            ],
            [
              1.5408341500542,
              43.278241916645
            ],
            [
              1.5475224661578,
              43.275676101165
            ],
            [
              1.5650041733714,
              43.253338249235
            ],
            [
              1.5733236732311,
              43.261898277797
            ],
            [
              1.5675664926871,
              43.26946856203
            ],
            [
              1.5706398172062,
              43.275025288273
            ],
            [
              1.582534553172,
              43.269206223464
            ],
            [
              1.6027224834357,
              43.265697706589
            ],
            [
              1.6077158113675,
              43.259782896611
            ],
            [
              1.6372059284418,
              43.255118083766
            ],
            [
              1.6350793089726,
              43.237097080522
            ],
            [
              1.645770021445,
              43.238244686015
            ],
            [
              1.6544432045959,
              43.243397466774
            ],
            [
              1.6511699036493,
              43.261453377454
            ],
            [
              1.6747945841516,
              43.281217375945
            ],
            [
              1.6884233932357,
              43.273554672371
            ]
          ]
        ]
      },
      "properties": {
        "code": "9",
        "nom": "Ariège",
        "population": 153954,
        "delitspour1000": "5,086",
        "benefRsa": 10987
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -1.4809035280913,
                46.210026584191
              ],
              [
                -1.4670590519219,
                46.217438107669
              ],
              [
                -1.4581989257469,
                46.228670423688
              ],
              [
                -1.4129379411055,
                46.230133712834
              ],
              [
                -1.4220417034491,
                46.218167984195
              ],
              [
                -1.4439097820914,
                46.213520554457
              ],
              [
                -1.4220989041249,
                46.204879920432
              ],
              [
                -1.4042066055981,
                46.203130836583
              ],
              [
                -1.3638972806052,
                46.207474893999
              ],
              [
                -1.3476852125515,
                46.202734923599
              ],
              [
                -1.3222125679058,
                46.187529767805
              ],
              [
                -1.3079558339781,
                46.190556844426
              ],
              [
                -1.2910690463149,
                46.186236068993
              ],
              [
                -1.2742343920981,
                46.160161159723
              ],
              [
                -1.2567915781519,
                46.160986989161
              ],
              [
                -1.2761678172975,
                46.147264631398
              ],
              [
                -1.3047717732064,
                46.142879010637
              ],
              [
                -1.3216316554218,
                46.14503661833
              ],
              [
                -1.3274184383871,
                46.14822687302
              ],
              [
                -1.3549550613137,
                46.15588643192
              ],
              [
                -1.3709015663159,
                46.167389183114
              ],
              [
                -1.3895694731475,
                46.175712081226
              ],
              [
                -1.4616048806387,
                46.202118585061
              ],
              [
                -1.4746222599077,
                46.202761956375
              ],
              [
                -1.5059249533038,
                46.194090752981
              ],
              [
                -1.5353231579785,
                46.204392826596
              ],
              [
                -1.5438132194944,
                46.219775891607
              ],
              [
                -1.5614107739691,
                46.237187235682
              ],
              [
                -1.5614800452621,
                46.245426793509
              ],
              [
                -1.5410961547552,
                46.243605472781
              ],
              [
                -1.5235573630959,
                46.251304814865
              ],
              [
                -1.5143738942926,
                46.25771379421
              ],
              [
                -1.500577180767,
                46.258182208227
              ],
              [
                -1.4820147319539,
                46.247190230382
              ],
              [
                -1.4745480329933,
                46.23325461767
              ],
              [
                -1.4820134280193,
                46.228634812137
              ],
              [
                -1.4915224496683,
                46.22912437982
              ],
              [
                -1.4963233614182,
                46.234948705937
              ],
              [
                -1.5127175658592,
                46.222324670773
              ],
              [
                -1.5067150732904,
                46.214570690055
              ],
              [
                -1.4995344178725,
                46.216647439156
              ],
              [
                -1.4876936351453,
                46.206896731453
              ],
              [
                -1.4809035280913,
                46.210026584191
              ]
            ]
          ],
          [
            [
              [
                -1.1294064331873,
                46.310272328818
              ],
              [
                -1.1236420717667,
                46.321795835911
              ],
              [
                -1.113284059211,
                46.316302232447
              ],
              [
                -1.0997364979128,
                46.314471710528
              ],
              [
                -1.0782951920661,
                46.316911662518
              ],
              [
                -1.0807238252872,
                46.321431313321
              ],
              [
                -1.0645117099073,
                46.335517206168
              ],
              [
                -1.0525331368763,
                46.342540560637
              ],
              [
                -1.0290641764217,
                46.348867660129
              ],
              [
                -1.013809705621,
                46.35562286986
              ],
              [
                -0.99521192821645,
                46.350317645688
              ],
              [
                -0.97744453847283,
                46.351108789901
              ],
              [
                -0.96451829359621,
                46.365398033698
              ],
              [
                -0.95084003340185,
                46.360617622608
              ],
              [
                -0.94112049397563,
                46.367862165606
              ],
              [
                -0.92857576719505,
                46.371051399922
              ],
              [
                -0.93790474501528,
                46.353157499224
              ],
              [
                -0.9441551264402,
                46.336048129241
              ],
              [
                -0.9612310671154,
                46.323395683014
              ],
              [
                -0.93489398189823,
                46.312857623927
              ],
              [
                -0.90480272235534,
                46.313812430336
              ],
              [
                -0.89270136944125,
                46.320101412175
              ],
              [
                -0.88767597392192,
                46.326322589931
              ],
              [
                -0.8509424448661,
                46.317185675508
              ],
              [
                -0.84487598314026,
                46.3245597282
              ],
              [
                -0.84865539686859,
                46.332512597898
              ],
              [
                -0.83988457052554,
                46.340367099668
              ],
              [
                -0.83052754444062,
                46.341529547924
              ],
              [
                -0.82415169186325,
                46.335854967946
              ],
              [
                -0.80732128185426,
                46.339293680039
              ],
              [
                -0.80247564445275,
                46.325156566217
              ],
              [
                -0.78424343720168,
                46.318861879535
              ],
              [
                -0.77570312778264,
                46.318376375981
              ],
              [
                -0.75794915819339,
                46.311254056955
              ],
              [
                -0.75047134037184,
                46.304254425344
              ],
              [
                -0.75535362968417,
                46.302140849356
              ],
              [
                -0.74980855251367,
                46.286849521089
              ],
              [
                -0.73679404300403,
                46.271401492755
              ],
              [
                -0.73588991828554,
                46.266879576358
              ],
              [
                -0.75300754445052,
                46.253380618964
              ],
              [
                -0.75146594839162,
                46.24507099292
              ],
              [
                -0.69072219675464,
                46.219205893342
              ],
              [
                -0.68633055847296,
                46.213695310884
              ],
              [
                -0.68488269799084,
                46.200453773272
              ],
              [
                -0.69496204815513,
                46.186993853879
              ],
              [
                -0.69112782600009,
                46.179803971686
              ],
              [
                -0.6685811095351,
                46.184773926666
              ],
              [
                -0.65512973407564,
                46.169822454406
              ],
              [
                -0.62840807624552,
                46.160982525804
              ],
              [
                -0.61301775941075,
                46.161520115387
              ],
              [
                -0.6068472739828,
                46.152812882032
              ],
              [
                -0.62172307334118,
                46.153595038548
              ],
              [
                -0.63340377004308,
                46.148021432495
              ],
              [
                -0.61995852602441,
                46.137750014696
              ],
              [
                -0.61141326190191,
                46.141394464113
              ],
              [
                -0.59663977568508,
                46.142454838947
              ],
              [
                -0.58301947933376,
                46.13965578451
              ],
              [
                -0.57854191376581,
                46.147964728014
              ],
              [
                -0.56576607178003,
                46.14277979352
              ],
              [
                -0.5469966913401,
                46.144227728675
              ],
              [
                -0.52689484335871,
                46.136320589162
              ],
              [
                -0.52814542553638,
                46.129919733784
              ],
              [
                -0.50956456180604,
                46.11753489863
              ],
              [
                -0.51639254125142,
                46.112455143164
              ],
              [
                -0.50608855949266,
                46.106705947745
              ],
              [
                -0.48252680272727,
                46.105971450103
              ],
              [
                -0.44523811078555,
                46.09937460024
              ],
              [
                -0.42245553906339,
                46.11330667026
              ],
              [
                -0.42251050183753,
                46.093904665982
              ],
              [
                -0.40050701973614,
                46.084717679031
              ],
              [
                -0.3827817863336,
                46.097704874713
              ],
              [
                -0.37196093059879,
                46.09346371161
              ],
              [
                -0.36384259880349,
                46.085330716338
              ],
              [
                -0.36272909605538,
                46.075544897813
              ],
              [
                -0.33810658635017,
                46.081216936149
              ],
              [
                -0.30554820744046,
                46.07802294449
              ],
              [
                -0.29309092784249,
                46.086641055838
              ],
              [
                -0.28173533346848,
                46.079749241708
              ],
              [
                -0.27383258474697,
                46.063997336294
              ],
              [
                -0.27303437704336,
                46.057314973181
              ],
              [
                -0.24701672265606,
                46.050662124407
              ],
              [
                -0.21296746528856,
                46.044099888449
              ],
              [
                -0.19397032130938,
                46.052259455885
              ],
              [
                -0.19462397530732,
                46.044377815765
              ],
              [
                -0.18915119266407,
                46.026632693161
              ],
              [
                -0.17199310246057,
                46.032911911506
              ],
              [
                -0.17165503581654,
                46.022702457579
              ],
              [
                -0.16351612159351,
                46.0156815515
              ],
              [
                -0.15864227863317,
                46.005122868308
              ],
              [
                -0.14484799981657,
                46.004301838334
              ],
              [
                -0.1381655475646,
                45.996522364247
              ],
              [
                -0.13674372413356,
                45.979321738143
              ],
              [
                -0.11825537562169,
                45.977295666275
              ],
              [
                -0.10293827139676,
                45.969661749473
              ],
              [
                -0.10317443011582,
                45.960579405475
              ],
              [
                -0.09327322578952,
                45.959833948001
              ],
              [
                -0.08644912706636,
                45.949990132787
              ],
              [
                -0.098302168337653,
                45.935062439996
              ],
              [
                -0.097039255426737,
                45.928384092976
              ],
              [
                -0.11167871901616,
                45.929821591238
              ],
              [
                -0.12981041355537,
                45.927130462589
              ],
              [
                -0.14047966196185,
                45.931516453162
              ],
              [
                -0.14900550606153,
                45.925178208408
              ],
              [
                -0.14315134669297,
                45.916935514246
              ],
              [
                -0.13166455296459,
                45.911022429568
              ],
              [
                -0.14666764833709,
                45.897775359622
              ],
              [
                -0.13095839648048,
                45.891010092778
              ],
              [
                -0.1305981319385,
                45.877988712202
              ],
              [
                -0.12652415818975,
                45.872654668721
              ],
              [
                -0.1153965183234,
                45.870664008952
              ],
              [
                -0.12209653110803,
                45.860104482401
              ],
              [
                -0.1250616653059,
                45.848652979567
              ],
              [
                -0.1357548963919,
                45.847293151618
              ],
              [
                -0.14468370051164,
                45.839670298572
              ],
              [
                -0.14022670568372,
                45.836483315707
              ],
              [
                -0.1358753369401,
                45.819926614425
              ],
              [
                -0.15309093676867,
                45.795738007974
              ],
              [
                -0.14831865256012,
                45.789092405305
              ],
              [
                -0.15605561945411,
                45.779515353962
              ],
              [
                -0.17074859900197,
                45.788593968326
              ],
              [
                -0.19100869192932,
                45.789894531994
              ],
              [
                -0.19917945642312,
                45.780124503215
              ],
              [
                -0.22174636217252,
                45.775742008413
              ],
              [
                -0.24280682786681,
                45.796908094859
              ],
              [
                -0.23871849150901,
                45.80303325017
              ],
              [
                -0.27312050329822,
                45.806560389612
              ],
              [
                -0.29481326175584,
                45.805289163403
              ],
              [
                -0.29853199953722,
                45.79426425579
              ],
              [
                -0.31027375308555,
                45.786921880303
              ],
              [
                -0.3212870680561,
                45.784501745786
              ],
              [
                -0.34034402768082,
                45.78695742909
              ],
              [
                -0.3658914331052,
                45.780312439395
              ],
              [
                -0.40038791129214,
                45.78666760956
              ],
              [
                -0.40217606349986,
                45.77301538502
              ],
              [
                -0.41365840203171,
                45.77142904832
              ],
              [
                -0.4273102965247,
                45.764072837255
              ],
              [
                -0.44882045148802,
                45.765926269772
              ],
              [
                -0.46177341555077,
                45.751290622612
              ],
              [
                -0.46000475927654,
                45.73927130291
              ],
              [
                -0.415834780924,
                45.741668774387
              ],
              [
                -0.40109121552996,
                45.734759859379
              ],
              [
                -0.41958834610446,
                45.721636409401
              ],
              [
                -0.42212652174802,
                45.709466163286
              ],
              [
                -0.41583934649791,
                45.698098364271
              ],
              [
                -0.42292034518264,
                45.685591002989
              ],
              [
                -0.41054507661905,
                45.683374341793
              ],
              [
                -0.38253774771373,
                45.659797375177
              ],
              [
                -0.39473380554576,
                45.653333645217
              ],
              [
                -0.40893222648837,
                45.656182506299
              ],
              [
                -0.42511918847294,
                45.630848552406
              ],
              [
                -0.43199190568977,
                45.624602844354
              ],
              [
                -0.41648532750286,
                45.617243376201
              ],
              [
                -0.39958220145148,
                45.620391094216
              ],
              [
                -0.3870866140304,
                45.625883962975
              ],
              [
                -0.37592461589022,
                45.608916349769
              ],
              [
                -0.35877316216011,
                45.597858313948
              ],
              [
                -0.3446188253208,
                45.594188494078
              ],
              [
                -0.34144303284896,
                45.586978040849
              ],
              [
                -0.32864794524113,
                45.584757523734
              ],
              [
                -0.31592174831236,
                45.578714867447
              ],
              [
                -0.2967204538074,
                45.564495522853
              ],
              [
                -0.30810278413318,
                45.549318031389
              ],
              [
                -0.32115948505163,
                45.542266927364
              ],
              [
                -0.32161606249361,
                45.534964864877
              ],
              [
                -0.29790634677759,
                45.528118388403
              ],
              [
                -0.2901454607883,
                45.530634360623
              ],
              [
                -0.28127750697165,
                45.525568387243
              ],
              [
                -0.26615421676893,
                45.525064860454
              ],
              [
                -0.25502307893398,
                45.521182965101
              ],
              [
                -0.25602828746258,
                45.51530840403
              ],
              [
                -0.26738468810567,
                45.509080707575
              ],
              [
                -0.25319354734665,
                45.501287202981
              ],
              [
                -0.24199917926696,
                45.502762015032
              ],
              [
                -0.24615075997527,
                45.491638915068
              ],
              [
                -0.25810562061945,
                45.487311671981
              ],
              [
                -0.26474489084668,
                45.470684661678
              ],
              [
                -0.28090368498526,
                45.474222779726
              ],
              [
                -0.28619795426743,
                45.456366484089
              ],
              [
                -0.27731043139055,
                45.459095081138
              ],
              [
                -0.26742796692728,
                45.455680296664
              ],
              [
                -0.26396058504239,
                45.448560617344
              ],
              [
                -0.24858219466218,
                45.43829622206
              ],
              [
                -0.25296301436591,
                45.422623088805
              ],
              [
                -0.24985313103838,
                45.417654768777
              ],
              [
                -0.23454234837219,
                45.419089093003
              ],
              [
                -0.23406024586768,
                45.409198691634
              ],
              [
                -0.24975703940313,
                45.408745402272
              ],
              [
                -0.26053747331488,
                45.401563971678
              ],
              [
                -0.27816376234535,
                45.398722581495
              ],
              [
                -0.27987455471692,
                45.391660929625
              ],
              [
                -0.29160703012116,
                45.387199902774
              ],
              [
                -0.31039530848299,
                45.386080139957
              ],
              [
                -0.30980400438703,
                45.37371724884
              ],
              [
                -0.29260206864406,
                45.36916309663
              ],
              [
                -0.28089985115869,
                45.359872524401
              ],
              [
                -0.25204550211244,
                45.357692731336
              ],
              [
                -0.24626907786219,
                45.353992412797
              ],
              [
                -0.25029908206598,
                45.341342342169
              ],
              [
                -0.2638385171848,
                45.340186739415
              ],
              [
                -0.27790150964647,
                45.347755096327
              ],
              [
                -0.28873639979921,
                45.329102689082
              ],
              [
                -0.28265983370323,
                45.317445416359
              ],
              [
                -0.2838487723318,
                45.309946617502
              ],
              [
                -0.27426295791554,
                45.302511468662
              ],
              [
                -0.26063823207321,
                45.298627031974
              ],
              [
                -0.25647479995809,
                45.309492525237
              ],
              [
                -0.23775101076552,
                45.314120359398
              ],
              [
                -0.22669700728525,
                45.321429446775
              ],
              [
                -0.21266841634205,
                45.31348461391
              ],
              [
                -0.19242059438335,
                45.306448509013
              ],
              [
                -0.17904526078995,
                45.308204691711
              ],
              [
                -0.1570027661118,
                45.304647680565
              ],
              [
                -0.13795225280646,
                45.292880437681
              ],
              [
                -0.12410073848607,
                45.293943265834
              ],
              [
                -0.10878357122157,
                45.28895254882
              ],
              [
                -0.11315820930109,
                45.269505992776
              ],
              [
                -0.11274164369573,
                45.254439864332
              ],
              [
                -0.11538026933891,
                45.248074633391
              ],
              [
                -0.088508317571876,
                45.246349835321
              ],
              [
                -0.044174427296423,
                45.247891898888
              ],
              [
                -0.036468365384134,
                45.238687865732
              ],
              [
                -0.025962209154688,
                45.232355357021
              ],
              [
                -0.0095281142937434,
                45.227892977577
              ],
              [
                0.0034276727151617,
                45.228718895198
              ],
              [
                0.0058232248211972,
                45.221412369619
              ],
              [
                -0.0016531463017523,
                45.206316027453
              ],
              [
                -0.0022343196868311,
                45.195323554796
              ],
              [
                0.0043360783093975,
                45.191628193392
              ],
              [
                0.0016786787652185,
                45.183382364904
              ],
              [
                -0.017852544026991,
                45.169219084458
              ],
              [
                -0.018373027267724,
                45.161693678976
              ],
              [
                -0.00065675566783935,
                45.159349483615
              ],
              [
                0.0047181608691669,
                45.153738868895
              ],
              [
                -0.021366349602208,
                45.145880874789
              ],
              [
                -0.037046275435503,
                45.139030327167
              ],
              [
                -0.02922928973293,
                45.12982948041
              ],
              [
                -0.041998525054412,
                45.11242092649
              ],
              [
                -0.040197554246423,
                45.102379906641
              ],
              [
                -0.047688949706731,
                45.097503236881
              ],
              [
                -0.058364016937835,
                45.09903723166
              ],
              [
                -0.083962269829361,
                45.115833537322
              ],
              [
                -0.087698819507144,
                45.121385343046
              ],
              [
                -0.10116314238,
                45.119704095829
              ],
              [
                -0.11323589772113,
                45.115011646159
              ],
              [
                -0.12497497516228,
                45.106821892717
              ],
              [
                -0.13530078778524,
                45.094092068749
              ],
              [
                -0.15295984730846,
                45.088810634907
              ],
              [
                -0.16192349951658,
                45.092487275945
              ],
              [
                -0.18920334297908,
                45.094460899135
              ],
              [
                -0.20972108355095,
                45.100229274355
              ],
              [
                -0.21825321623573,
                45.106431791317
              ],
              [
                -0.23115054546009,
                45.109122514539
              ],
              [
                -0.24567378372971,
                45.10820936272
              ],
              [
                -0.25508555252633,
                45.114082615598
              ],
              [
                -0.27248948660547,
                45.135305765658
              ],
              [
                -0.27536296309773,
                45.141404399887
              ],
              [
                -0.30793572307717,
                45.14957651168
              ],
              [
                -0.31346140209586,
                45.138238479526
              ],
              [
                -0.32010139495923,
                45.148665259477
              ],
              [
                -0.32063917436396,
                45.156167113292
              ],
              [
                -0.34234235630155,
                45.167553726185
              ],
              [
                -0.36356477709128,
                45.170262130456
              ],
              [
                -0.37970141341998,
                45.155350139515
              ],
              [
                -0.3834007878771,
                45.143333546122
              ],
              [
                -0.39406943883391,
                45.164782442033
              ],
              [
                -0.39736284181851,
                45.177932331672
              ],
              [
                -0.40530728794629,
                45.192687924297
              ],
              [
                -0.41750318373793,
                45.204015328884
              ],
              [
                -0.41307417339743,
                45.224872704015
              ],
              [
                -0.4051131393976,
                45.239822566668
              ],
              [
                -0.40743510510465,
                45.247110601421
              ],
              [
                -0.42378065552637,
                45.24780411835
              ],
              [
                -0.42407240046137,
                45.252657562754
              ],
              [
                -0.4160766862435,
                45.266934268035
              ],
              [
                -0.41865787206374,
                45.273412127304
              ],
              [
                -0.42920073566456,
                45.279476537055
              ],
              [
                -0.47115623286141,
                45.292085013202
              ],
              [
                -0.48621599817858,
                45.293567761177
              ],
              [
                -0.51359037103072,
                45.286335703137
              ],
              [
                -0.53198594402906,
                45.291776015129
              ],
              [
                -0.55951096673761,
                45.297707318107
              ],
              [
                -0.56766890429358,
                45.297602332116
              ],
              [
                -0.57387553346356,
                45.310853327057
              ],
              [
                -0.56896376359337,
                45.331518881016
              ],
              [
                -0.58715999202323,
                45.34284134679
              ],
              [
                -0.59384140912013,
                45.334752513163
              ],
              [
                -0.59448704800865,
                45.321142619731
              ],
              [
                -0.61254068812542,
                45.323483267446
              ],
              [
                -0.63524007842555,
                45.318180463174
              ],
              [
                -0.64430238229481,
                45.322043706272
              ],
              [
                -0.70605381890013,
                45.326431930108
              ],
              [
                -0.72330389155964,
                45.327001191386
              ],
              [
                -0.75267677056313,
                45.321765614981
              ],
              [
                -0.77402172439234,
                45.369205086916
              ],
              [
                -0.84106252140245,
                45.435085891321
              ],
              [
                -0.86478824719039,
                45.45340715699
              ],
              [
                -0.9265369418124,
                45.496125598327
              ],
              [
                -1.0257391846007,
                45.574691325999
              ],
              [
                -0.99300406059586,
                45.577175103049
              ],
              [
                -0.98915191244273,
                45.581341317039
              ],
              [
                -0.99448166466087,
                45.598471363385
              ],
              [
                -1.0091096377528,
                45.597495172168
              ],
              [
                -1.0149548718972,
                45.602385291749
              ],
              [
                -1.0096347332233,
                45.611436531328
              ],
              [
                -1.0203205505127,
                45.62151668447
              ],
              [
                -1.030734415261,
                45.616566131118
              ],
              [
                -1.0488627706461,
                45.620296785838
              ],
              [
                -1.0728559725266,
                45.634224904397
              ],
              [
                -1.1158529629831,
                45.646976690887
              ],
              [
                -1.1615633042487,
                45.674286390956
              ],
              [
                -1.1764544979496,
                45.679631218564
              ],
              [
                -1.1960486136478,
                45.691215860779
              ],
              [
                -1.2093162908787,
                45.696695600645
              ],
              [
                -1.2303338364371,
                45.693221363131
              ],
              [
                -1.237223219004,
                45.705895619461
              ],
              [
                -1.241444700502,
                45.758155786245
              ],
              [
                -1.2437947470471,
                45.772440059531
              ],
              [
                -1.2425689958526,
                45.781575345749
              ],
              [
                -1.2310317959574,
                45.788930983319
              ],
              [
                -1.2210183884462,
                45.789634505334
              ],
              [
                -1.2089657293798,
                45.795749040068
              ],
              [
                -1.1919967968082,
                45.789370772875
              ],
              [
                -1.1749504479715,
                45.793552459949
              ],
              [
                -1.1595510306588,
                45.801417483779
              ],
              [
                -1.1409697147076,
                45.798212034993
              ],
              [
                -1.132892019615,
                45.807422776222
              ],
              [
                -1.137157980964,
                45.819898709185
              ],
              [
                -1.1502088102145,
                45.830706445563
              ],
              [
                -1.1545872388857,
                45.840814345567
              ],
              [
                -1.1680717875645,
                45.845145151766
              ],
              [
                -1.157329540581,
                45.85452985161
              ],
              [
                -1.153187170193,
                45.862358814767
              ],
              [
                -1.1210426718188,
                45.856462545265
              ],
              [
                -1.1008913277365,
                45.872209699286
              ],
              [
                -1.0952763372146,
                45.885483654804
              ],
              [
                -1.081212277475,
                45.898789509328
              ],
              [
                -1.0737883386215,
                45.914234695342
              ],
              [
                -1.076864753109,
                45.936699490286
              ],
              [
                -1.0959775740275,
                45.942016808278
              ],
              [
                -1.094399319351,
                45.948773629702
              ],
              [
                -1.0643262929189,
                45.949911547118
              ],
              [
                -1.0650836422026,
                45.955876942711
              ],
              [
                -1.0760315150124,
                45.958641591561
              ],
              [
                -1.0824929437006,
                45.965886353981
              ],
              [
                -1.0833944490579,
                45.978241345779
              ],
              [
                -1.0942868235613,
                45.98407853197
              ],
              [
                -1.0989532154989,
                45.990511008295
              ],
              [
                -1.0842912197077,
                45.995595529343
              ],
              [
                -1.0630867195678,
                45.994435355467
              ],
              [
                -1.0556386696802,
                45.999342515679
              ],
              [
                -1.0526895091436,
                46.011182592646
              ],
              [
                -1.0611535121644,
                46.028848986934
              ],
              [
                -1.0567431675806,
                46.035915721056
              ],
              [
                -1.0670030997899,
                46.050149738322
              ],
              [
                -1.0888412421854,
                46.053947103542
              ],
              [
                -1.0938493810902,
                46.077074374166
              ],
              [
                -1.1007240096268,
                46.093795091913
              ],
              [
                -1.1150482792811,
                46.102319452799
              ],
              [
                -1.1300954807717,
                46.101767963634
              ],
              [
                -1.1431683463329,
                46.10884040725
              ],
              [
                -1.1253028796809,
                46.110920815275
              ],
              [
                -1.1264071986579,
                46.124260358299
              ],
              [
                -1.1483371896137,
                46.132006346959
              ],
              [
                -1.1554222855991,
                46.137461403915
              ],
              [
                -1.1729310087073,
                46.139283394105
              ],
              [
                -1.1712255083601,
                46.146518573114
              ],
              [
                -1.1618314872923,
                46.148520817666
              ],
              [
                -1.1607229685204,
                46.1554895116
              ],
              [
                -1.1893147393064,
                46.150729023324
              ],
              [
                -1.206939036394,
                46.145764027266
              ],
              [
                -1.2284491051228,
                46.1497260682
              ],
              [
                -1.2420434969372,
                46.157446715501
              ],
              [
                -1.2361325026972,
                46.166145111409
              ],
              [
                -1.2238703709795,
                46.165946361191
              ],
              [
                -1.2184421668953,
                46.180122028855
              ],
              [
                -1.2066150416523,
                46.184680886611
              ],
              [
                -1.1993303516663,
                46.194320636545
              ],
              [
                -1.2065329820075,
                46.204362200351
              ],
              [
                -1.1990017582044,
                46.213243547272
              ],
              [
                -1.1713508437289,
                46.224055692443
              ],
              [
                -1.1484576572781,
                46.238406807482
              ],
              [
                -1.1411322001501,
                46.249709112106
              ],
              [
                -1.1182950608301,
                46.261125374832
              ],
              [
                -1.1111664091524,
                46.261342685351
              ],
              [
                -1.1219630552981,
                46.290281706357
              ],
              [
                -1.1207134213387,
                46.296691182666
              ],
              [
                -1.1294064331873,
                46.310272328818
              ]
            ]
          ],
          [
            [
              [
                -1.2502595149646,
                45.845963455069
              ],
              [
                -1.2592163284786,
                45.868298982277
              ],
              [
                -1.2661547831538,
                45.878168876396
              ],
              [
                -1.2875743906736,
                45.894605091082
              ],
              [
                -1.3380521527432,
                45.917870413489
              ],
              [
                -1.3509545085702,
                45.925036655573
              ],
              [
                -1.3839170490528,
                45.951445390606
              ],
              [
                -1.3917174561272,
                45.974042547349
              ],
              [
                -1.387253309976,
                45.993870516375
              ],
              [
                -1.3890812144965,
                46.002682308229
              ],
              [
                -1.4005330150939,
                46.016128617682
              ],
              [
                -1.4134350122266,
                46.04686461584
              ],
              [
                -1.4070525832078,
                46.048113296884
              ],
              [
                -1.3861064565982,
                46.041600222214
              ],
              [
                -1.3712729567201,
                46.039341747981
              ],
              [
                -1.3720788977654,
                46.032155177051
              ],
              [
                -1.3659284854243,
                46.027268018796
              ],
              [
                -1.3358134208014,
                46.012041108959
              ],
              [
                -1.3127603503815,
                45.992975701298
              ],
              [
                -1.2957631118509,
                45.989021011393
              ],
              [
                -1.2811865787963,
                45.988409134288
              ],
              [
                -1.2479197959851,
                45.990418426217
              ],
              [
                -1.236032979657,
                45.98151962032
              ],
              [
                -1.2354604124334,
                45.968794257852
              ],
              [
                -1.2420820613428,
                45.957461306685
              ],
              [
                -1.2297931152534,
                45.944251359832
              ],
              [
                -1.2290798225525,
                45.933287072883
              ],
              [
                -1.2337729337151,
                45.927058615789
              ],
              [
                -1.2235741052757,
                45.913107194719
              ],
              [
                -1.2064336016984,
                45.901445384945
              ],
              [
                -1.188429054638,
                45.886745497166
              ],
              [
                -1.1883892570962,
                45.882604284253
              ],
              [
                -1.2000379808165,
                45.867288972841
              ],
              [
                -1.2077101197588,
                45.85034131532
              ],
              [
                -1.1956190365959,
                45.82969276273
              ],
              [
                -1.2052089250964,
                45.824572656016
              ],
              [
                -1.217429755495,
                45.813047230038
              ],
              [
                -1.2364238849087,
                45.80453236122
              ],
              [
                -1.2456662479746,
                45.821355001166
              ],
              [
                -1.2502595149646,
                45.845963455069
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "17",
        "nom": "Charente-Maritime",
        "population": 655709,
        "delitspour1000": "4,51",
        "nombremosque": 5,
        "parentsEtrangers": "85,9",
        "benefRsa": 27161,
        "eoliennes": 132
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1.8987320820414,
              45.698276663383
            ],
            [
              1.9084767843547,
              45.70333633169
            ],
            [
              1.9155154483473,
              45.711251861462
            ],
            [
              1.941713024542,
              45.711346901084
            ],
            [
              1.9483824754413,
              45.721672179566
            ],
            [
              1.9565811212045,
              45.724758955489
            ],
            [
              1.9778905956463,
              45.724334588008
            ],
            [
              1.9885167886234,
              45.721351415511
            ],
            [
              1.995966765675,
              45.729461814793
            ],
            [
              2.0016539166162,
              45.748070146272
            ],
            [
              2.0176099912597,
              45.755493973695
            ],
            [
              2.0356697732675,
              45.755049147734
            ],
            [
              2.0469321372785,
              45.763895555756
            ],
            [
              2.0552938288374,
              45.762792466152
            ],
            [
              2.0584205289931,
              45.754477689431
            ],
            [
              2.0813955940415,
              45.747110632445
            ],
            [
              2.0846954513364,
              45.72804494641
            ],
            [
              2.0970008847403,
              45.734189542977
            ],
            [
              2.1135480150858,
              45.72477688612
            ],
            [
              2.121289380837,
              45.732394845856
            ],
            [
              2.1338040836422,
              45.735431951224
            ],
            [
              2.1470164213187,
              45.722680175219
            ],
            [
              2.1579966688387,
              45.723417414039
            ],
            [
              2.1609106642829,
              45.735837745361
            ],
            [
              2.1743482380562,
              45.732214123162
            ],
            [
              2.1829873189316,
              45.720620530116
            ],
            [
              2.2023233791081,
              45.718576123482
            ],
            [
              2.1939610907282,
              45.699043520529
            ],
            [
              2.2053248238341,
              45.698652355768
            ],
            [
              2.2152594548892,
              45.70559122122
            ],
            [
              2.22185607331,
              45.698063513429
            ],
            [
              2.2381374375847,
              45.693288512748
            ],
            [
              2.2727104741081,
              45.68771311379
            ],
            [
              2.269031167198,
              45.678331971197
            ],
            [
              2.2733468046127,
              45.664008285608
            ],
            [
              2.2894491157149,
              45.665747280433
            ],
            [
              2.3088188102277,
              45.676010357627
            ],
            [
              2.3209490355497,
              45.668590940988
            ],
            [
              2.3264208053504,
              45.677235435121
            ],
            [
              2.3377583954679,
              45.680602150879
            ],
            [
              2.3482860389568,
              45.695041172912
            ],
            [
              2.3446927322808,
              45.70516284581
            ],
            [
              2.3491879213954,
              45.70948075492
            ],
            [
              2.3663330806295,
              45.712986903093
            ],
            [
              2.3864265340174,
              45.707941738844
            ],
            [
              2.4102499185539,
              45.709637609084
            ],
            [
              2.4358494505048,
              45.699272327908
            ],
            [
              2.4444684061256,
              45.707496260407
            ],
            [
              2.4514675970093,
              45.708074449863
            ],
            [
              2.4647133674467,
              45.716651528694
            ],
            [
              2.4820705176845,
              45.735861168354
            ],
            [
              2.4921294160933,
              45.7376701128
            ],
            [
              2.5183220391127,
              45.712799669432
            ],
            [
              2.5198739656449,
              45.697052906827
            ],
            [
              2.5268700649094,
              45.694919495794
            ],
            [
              2.5283596411119,
              45.681924314977
            ],
            [
              2.5129233228036,
              45.6713335771
            ],
            [
              2.5151301172176,
              45.664284079976
            ],
            [
              2.5249384159848,
              45.657234455338
            ],
            [
              2.5142980219448,
              45.639528483879
            ],
            [
              2.5011495345088,
              45.638738876646
            ],
            [
              2.4897451626044,
              45.642353692
            ],
            [
              2.4833880353068,
              45.639303519147
            ],
            [
              2.4790816386943,
              45.62246233864
            ],
            [
              2.4784344946797,
              45.607911789419
            ],
            [
              2.4653445128497,
              45.600820150088
            ],
            [
              2.4646251782029,
              45.594410586781
            ],
            [
              2.4725367701592,
              45.580929716396
            ],
            [
              2.4874305070125,
              45.569384021272
            ],
            [
              2.4909285469766,
              45.560394087282
            ],
            [
              2.5064679621685,
              45.553444063405
            ],
            [
              2.5163271748739,
              45.55342839279
            ],
            [
              2.5165205610225,
              45.524032787608
            ],
            [
              2.5203805740984,
              45.520450992238
            ],
            [
              2.5115199537568,
              45.511153110817
            ],
            [
              2.5089910453543,
              45.500226403127
            ],
            [
              2.5138913543334,
              45.492347782226
            ],
            [
              2.5084125130653,
              45.478501516921
            ],
            [
              2.5067704533992,
              45.464065004968
            ],
            [
              2.4955946696815,
              45.44550416324
            ],
            [
              2.487471709413,
              45.418842030111
            ],
            [
              2.4927584232596,
              45.413842323437
            ],
            [
              2.5159713869265,
              45.403236533013
            ],
            [
              2.5220338520841,
              45.402237812322
            ],
            [
              2.5262353366501,
              45.389343818253
            ],
            [
              2.5227321270438,
              45.382084686424
            ],
            [
              2.4857679741129,
              45.378946887778
            ],
            [
              2.4778316883062,
              45.370410647653
            ],
            [
              2.4734557788383,
              45.38152628147
            ],
            [
              2.4580813755781,
              45.384827047932
            ],
            [
              2.441865995091,
              45.384259395763
            ],
            [
              2.4226736731266,
              45.397229870143
            ],
            [
              2.4129045453324,
              45.396651442056
            ],
            [
              2.3982881363717,
              45.400169538674
            ],
            [
              2.3954865345645,
              45.407322290365
            ],
            [
              2.3782501191323,
              45.414302210343
            ],
            [
              2.3551307955913,
              45.415027972839
            ],
            [
              2.3500842175271,
              45.409676058097
            ],
            [
              2.3545525332951,
              45.401398504646
            ],
            [
              2.3644462766137,
              45.395234506367
            ],
            [
              2.3682726042671,
              45.388621392102
            ],
            [
              2.3629697029865,
              45.378412337982
            ],
            [
              2.3647790516305,
              45.357919621969
            ],
            [
              2.3513461420151,
              45.348610937353
            ],
            [
              2.3593793404283,
              45.335790299537
            ],
            [
              2.3504806858312,
              45.327560923525
            ],
            [
              2.3177397642658,
              45.322963729764
            ],
            [
              2.3073512042479,
              45.312659714856
            ],
            [
              2.2920696943683,
              45.290569998093
            ],
            [
              2.2800803441611,
              45.287325615297
            ],
            [
              2.2714111009736,
              45.290154603854
            ],
            [
              2.2617348470443,
              45.28322143744
            ],
            [
              2.2585683903144,
              45.270220164439
            ],
            [
              2.245004874828,
              45.267106520498
            ],
            [
              2.2387747025234,
              45.260353952142
            ],
            [
              2.2413408281325,
              45.24942616212
            ],
            [
              2.2249287044688,
              45.241854962029
            ],
            [
              2.1953636990438,
              45.220851418291
            ],
            [
              2.1905131897891,
              45.20213434701
            ],
            [
              2.1985478420586,
              45.194408666334
            ],
            [
              2.2011879889027,
              45.181494229499
            ],
            [
              2.2195874303098,
              45.172114610573
            ],
            [
              2.2335857511761,
              45.167181650239
            ],
            [
              2.225397887065,
              45.160336595328
            ],
            [
              2.2137008320653,
              45.160189965969
            ],
            [
              2.2115654221992,
              45.14844827392
            ],
            [
              2.1948369367108,
              45.136027708871
            ],
            [
              2.1787905007908,
              45.136297935946
            ],
            [
              2.1852692693185,
              45.113998024195
            ],
            [
              2.1794393252059,
              45.109177492216
            ],
            [
              2.1803618094732,
              45.09734068898
            ],
            [
              2.1717594845165,
              45.081496802672
            ],
            [
              2.1456535641311,
              45.085592171697
            ],
            [
              2.1298193059745,
              45.078932414297
            ],
            [
              2.1183646353816,
              45.070379067781
            ],
            [
              2.1037456940938,
              45.065812421906
            ],
            [
              2.0951568732001,
              45.056033465741
            ],
            [
              2.0993181256264,
              45.047622806571
            ],
            [
              2.1094104611066,
              45.041521518867
            ],
            [
              2.1168066989252,
              45.02111434735
            ],
            [
              2.1406414310588,
              45.005858479694
            ],
            [
              2.1381513494782,
              44.992811832821
            ],
            [
              2.1329815746739,
              44.985516038406
            ],
            [
              2.1003714972007,
              44.983427469854
            ],
            [
              2.0905743960003,
              44.984665148518
            ],
            [
              2.0766169639286,
              44.978785623857
            ],
            [
              2.0629079799591,
              44.9765045515
            ],
            [
              2.0525667664657,
              44.976478188068
            ],
            [
              2.0454327798436,
              44.983664804729
            ],
            [
              2.0068687915517,
              44.976629769782
            ],
            [
              1.9853569537575,
              44.974450164818
            ],
            [
              1.9740981161193,
              44.966632803845
            ],
            [
              1.9557698918802,
              44.958318624648
            ],
            [
              1.9509915581584,
              44.953151935667
            ],
            [
              1.9407166400253,
              44.95513995704
            ],
            [
              1.9390669995572,
              44.973222308505
            ],
            [
              1.9280650601996,
              44.97871493763
            ],
            [
              1.9081575006047,
              44.978423183181
            ],
            [
              1.8926655050781,
              44.964882977079
            ],
            [
              1.8874095971943,
              44.956563455989
            ],
            [
              1.8670322636063,
              44.952926075187
            ],
            [
              1.8510527260352,
              44.946245504656
            ],
            [
              1.8443484053735,
              44.938030495187
            ],
            [
              1.836439246631,
              44.937455442607
            ],
            [
              1.8239145074647,
              44.927683348732
            ],
            [
              1.8085816314758,
              44.927710042504
            ],
            [
              1.8008914101993,
              44.924210099081
            ],
            [
              1.78487170617,
              44.937317933441
            ],
            [
              1.7828574905171,
              44.929652757246
            ],
            [
              1.7749276020491,
              44.923721627249
            ],
            [
              1.7685216398013,
              44.93111838651
            ],
            [
              1.7536701932124,
              44.940576173829
            ],
            [
              1.7506187292807,
              44.954961208829
            ],
            [
              1.7428395473269,
              44.959830662128
            ],
            [
              1.7215459564475,
              44.968065955731
            ],
            [
              1.7110326508746,
              44.967296262803
            ],
            [
              1.702662490825,
              44.987825577102
            ],
            [
              1.6871611264702,
              44.996380368243
            ],
            [
              1.6843750926429,
              45.002660865737
            ],
            [
              1.6714097923126,
              45.004301541515
            ],
            [
              1.6544545546621,
              45.017019229934
            ],
            [
              1.6509774862668,
              45.025013308842
            ],
            [
              1.629956153879,
              45.033567269253
            ],
            [
              1.614739866204,
              45.033019699506
            ],
            [
              1.5893103965749,
              45.036412402334
            ],
            [
              1.5763101796546,
              45.040696470827
            ],
            [
              1.5690421523205,
              45.038712199205
            ],
            [
              1.5520446044451,
              45.028473138039
            ],
            [
              1.5437040262063,
              45.030761580224
            ],
            [
              1.5410691079459,
              45.042887041934
            ],
            [
              1.5357229531152,
              45.046275852749
            ],
            [
              1.519580065039,
              45.041030205266
            ],
            [
              1.502854716064,
              45.038440870886
            ],
            [
              1.4800566881166,
              45.026797710108
            ],
            [
              1.4735839270693,
              45.017999050409
            ],
            [
              1.4619826792135,
              45.01370224029
            ],
            [
              1.4482602497483,
              45.019314041206
            ],
            [
              1.4385963777472,
              45.025733158494
            ],
            [
              1.4217014869627,
              45.041650121731
            ],
            [
              1.4162109061434,
              45.050200502051
            ],
            [
              1.3995614571492,
              45.061184544578
            ],
            [
              1.4063561964977,
              45.069292559474
            ],
            [
              1.3915778775111,
              45.087303436846
            ],
            [
              1.394928381744,
              45.092684987797
            ],
            [
              1.3864362517441,
              45.102876785498
            ],
            [
              1.4094676341512,
              45.112643165427
            ],
            [
              1.4128943596301,
              45.125085838348
            ],
            [
              1.3870029147721,
              45.134367826235
            ],
            [
              1.3588871682785,
              45.132296998015
            ],
            [
              1.3519854604388,
              45.141637154796
            ],
            [
              1.3379427676437,
              45.137921856753
            ],
            [
              1.3173886309875,
              45.142106860552
            ],
            [
              1.307404186823,
              45.137314954018
            ],
            [
              1.2965146282997,
              45.140190567774
            ],
            [
              1.2741676331289,
              45.151771329184
            ],
            [
              1.2547676106054,
              45.159308650493
            ],
            [
              1.2549224728883,
              45.165067910911
            ],
            [
              1.2693614439749,
              45.164757781728
            ],
            [
              1.2853453858257,
              45.175945310037
            ],
            [
              1.2921236447883,
              45.186511803411
            ],
            [
              1.2752166490499,
              45.199090409414
            ],
            [
              1.2322989918746,
              45.197699651744
            ],
            [
              1.2278052507949,
              45.206031457487
            ],
            [
              1.2336066661974,
              45.222192721421
            ],
            [
              1.2551055781893,
              45.226893309734
            ],
            [
              1.2774900011493,
              45.242079620298
            ],
            [
              1.2758370646328,
              45.2559186387
            ],
            [
              1.2545859923098,
              45.25486740913
            ],
            [
              1.2396375460236,
              45.260353044847
            ],
            [
              1.2422156707612,
              45.265911024006
            ],
            [
              1.2271245972559,
              45.271978251465
            ],
            [
              1.2323773668524,
              45.288832610689
            ],
            [
              1.2400866778708,
              45.294365351244
            ],
            [
              1.2394220228384,
              45.322752807172
            ],
            [
              1.2606945710672,
              45.32079145568
            ],
            [
              1.2726036516046,
              45.32822981062
            ],
            [
              1.2807179300268,
              45.338113368241
            ],
            [
              1.2891030872449,
              45.354071785139
            ],
            [
              1.3157868610203,
              45.36091417114
            ],
            [
              1.3205078307796,
              45.36792999935
            ],
            [
              1.3227905309582,
              45.382661066256
            ],
            [
              1.2988199548234,
              45.394630598699
            ],
            [
              1.2876241003188,
              45.385664184422
            ],
            [
              1.279794082695,
              45.384510274768
            ],
            [
              1.2718893557557,
              45.397210538989
            ],
            [
              1.2596076806333,
              45.398352563284
            ],
            [
              1.2564045190685,
              45.405428901312
            ],
            [
              1.2591268452081,
              45.419760420828
            ],
            [
              1.2716123611824,
              45.418261246085
            ],
            [
              1.2764306882186,
              45.413493424718
            ],
            [
              1.2892570010192,
              45.417438486306
            ],
            [
              1.2881763535005,
              45.427324841631
            ],
            [
              1.2755961256754,
              45.433683561495
            ],
            [
              1.2621096929858,
              45.443766495435
            ],
            [
              1.2531543680864,
              45.44422001812
            ],
            [
              1.2561930840569,
              45.452256338425
            ],
            [
              1.2644068304182,
              45.457149219751
            ],
            [
              1.2648159227727,
              45.469006694164
            ],
            [
              1.2703383373609,
              45.47767453679
            ],
            [
              1.2788551402607,
              45.477080434887
            ],
            [
              1.2870517483656,
              45.489934212885
            ],
            [
              1.3500777990719,
              45.466891005823
            ],
            [
              1.3682873148575,
              45.489362984249
            ],
            [
              1.3788636254268,
              45.495437141641
            ],
            [
              1.3895961885146,
              45.496159027417
            ],
            [
              1.3995865948151,
              45.518105798589
            ],
            [
              1.412057555905,
              45.528083714351
            ],
            [
              1.4283204410392,
              45.52963459404
            ],
            [
              1.4390800916948,
              45.521264513501
            ],
            [
              1.4502612360555,
              45.530395162772
            ],
            [
              1.4551430293037,
              45.553286526913
            ],
            [
              1.4743785744561,
              45.553660989104
            ],
            [
              1.4803672315751,
              45.565943103669
            ],
            [
              1.4922288654159,
              45.560860224865
            ],
            [
              1.4926610127604,
              45.549346872252
            ],
            [
              1.5062738329569,
              45.55097866922
            ],
            [
              1.5175108232266,
              45.557403975837
            ],
            [
              1.5220862628879,
              45.564095585467
            ],
            [
              1.5571983675006,
              45.549366357639
            ],
            [
              1.5699809777709,
              45.55557201525
            ],
            [
              1.5848926742786,
              45.556078684475
            ],
            [
              1.5929603823554,
              45.575070422688
            ],
            [
              1.6104620981456,
              45.57882931792
            ],
            [
              1.6183044806183,
              45.576721266196
            ],
            [
              1.637667297551,
              45.580351955747
            ],
            [
              1.6432456995656,
              45.587607167454
            ],
            [
              1.6581740723246,
              45.595323469002
            ],
            [
              1.660197916058,
              45.607975911156
            ],
            [
              1.6820375995624,
              45.625436348701
            ],
            [
              1.7122470870333,
              45.641342180794
            ],
            [
              1.719240328502,
              45.638187017497
            ],
            [
              1.7480492439205,
              45.645696988818
            ],
            [
              1.7577141778869,
              45.653641924326
            ],
            [
              1.7690073620902,
              45.656757732937
            ],
            [
              1.7776207954444,
              45.664089620082
            ],
            [
              1.7838730173434,
              45.680359832055
            ],
            [
              1.7921210312102,
              45.681672480893
            ],
            [
              1.8008873621314,
              45.674617277698
            ],
            [
              1.8159593866988,
              45.680423401703
            ],
            [
              1.8264334724666,
              45.665132148261
            ],
            [
              1.8500372576264,
              45.665822347467
            ],
            [
              1.8546009705446,
              45.672274668279
            ],
            [
              1.8713481385443,
              45.664653768147
            ],
            [
              1.8808451243487,
              45.668336436958
            ],
            [
              1.8799612362096,
              45.678184431517
            ],
            [
              1.9056316168489,
              45.677971170951
            ],
            [
              1.9094484810021,
              45.690490991257
            ],
            [
              1.8987320820414,
              45.698276663383
            ]
          ]
        ]
      },
      "properties": {
        "code": "19",
        "nom": "Corrèze",
        "population": 239190,
        "delitspour1000": "4,285",
        "nombremosque": 10,
        "parentsEtrangers": "82,0",
        "benefRsa": 6197,
        "eoliennes": 6
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              0.62974117909144,
              45.714569962764
            ],
            [
              0.64014526086376,
              45.697900282075
            ],
            [
              0.64758562647999,
              45.698191932838
            ],
            [
              0.65423210218119,
              45.688708205904
            ],
            [
              0.67131772964592,
              45.686263722598
            ],
            [
              0.70591091538187,
              45.68844025106
            ],
            [
              0.71649925017518,
              45.694931712962
            ],
            [
              0.72647929320381,
              45.694448925843
            ],
            [
              0.74588386989544,
              45.687257323177
            ],
            [
              0.7580960309786,
              45.673325335181
            ],
            [
              0.77584324344145,
              45.667566871982
            ],
            [
              0.77159487028606,
              45.649073284221
            ],
            [
              0.76408057719108,
              45.642085068126
            ],
            [
              0.7630396822677,
              45.628335552708
            ],
            [
              0.75152732990991,
              45.618319302192
            ],
            [
              0.75651312392777,
              45.611033740597
            ],
            [
              0.76580279197506,
              45.605608223481
            ],
            [
              0.77547181041453,
              45.592381049291
            ],
            [
              0.78037610975744,
              45.591189777647
            ],
            [
              0.8035616443645,
              45.59623388566
            ],
            [
              0.80715430183036,
              45.593808718707
            ],
            [
              0.81149516980134,
              45.575871443793
            ],
            [
              0.81738737137326,
              45.575951773802
            ],
            [
              0.84458421559202,
              45.58414605626
            ],
            [
              0.85034850719997,
              45.598529760541
            ],
            [
              0.86044192004144,
              45.614095447968
            ],
            [
              0.87111784672118,
              45.622469215895
            ],
            [
              0.89219082184256,
              45.601282841669
            ],
            [
              0.90694897832677,
              45.606159731018
            ],
            [
              0.91358891725371,
              45.604121198541
            ],
            [
              0.9345497361253,
              45.611583763759
            ],
            [
              0.94946947335678,
              45.611605412695
            ],
            [
              0.98639082241995,
              45.604483433474
            ],
            [
              0.99550297448107,
              45.612623406595
            ],
            [
              1.0233311222277,
              45.608927311851
            ],
            [
              1.0272245291926,
              45.601549089948
            ],
            [
              1.0358438821254,
              45.600210100883
            ],
            [
              1.0345567591098,
              45.581434783883
            ],
            [
              1.0378037277977,
              45.568741339867
            ],
            [
              1.0499960231824,
              45.556779905163
            ],
            [
              1.0626570130683,
              45.55481098184
            ],
            [
              1.0717211244419,
              45.547670879839
            ],
            [
              1.0803563779529,
              45.534001427359
            ],
            [
              1.102063750574,
              45.536538346811
            ],
            [
              1.1103275669005,
              45.544906724066
            ],
            [
              1.1189576898653,
              45.545495600033
            ],
            [
              1.1376820616141,
              45.539770878656
            ],
            [
              1.1672854866086,
              45.524823083995
            ],
            [
              1.1457588091326,
              45.51402413419
            ],
            [
              1.1359422598032,
              45.506405455775
            ],
            [
              1.1281904669439,
              45.493658303977
            ],
            [
              1.1197817974064,
              45.487786484259
            ],
            [
              1.1222297120746,
              45.477235903494
            ],
            [
              1.1358330938261,
              45.470862267361
            ],
            [
              1.1528400990405,
              45.473206153758
            ],
            [
              1.1619322997805,
              45.478655222263
            ],
            [
              1.1698569128168,
              45.47284548287
            ],
            [
              1.176821674264,
              45.460816483773
            ],
            [
              1.1865896978248,
              45.455294990995
            ],
            [
              1.2095051560814,
              45.463085709124
            ],
            [
              1.2359397841126,
              45.455027204041
            ],
            [
              1.2340044582991,
              45.439938189475
            ],
            [
              1.241404347379,
              45.437356928582
            ],
            [
              1.2531543680864,
              45.44422001812
            ],
            [
              1.2621096929858,
              45.443766495435
            ],
            [
              1.2755961256754,
              45.433683561495
            ],
            [
              1.2881763535005,
              45.427324841631
            ],
            [
              1.2892570010192,
              45.417438486306
            ],
            [
              1.2764306882186,
              45.413493424718
            ],
            [
              1.2716123611824,
              45.418261246085
            ],
            [
              1.2591268452081,
              45.419760420828
            ],
            [
              1.2564045190685,
              45.405428901312
            ],
            [
              1.2596076806333,
              45.398352563284
            ],
            [
              1.2718893557557,
              45.397210538989
            ],
            [
              1.279794082695,
              45.384510274768
            ],
            [
              1.2876241003188,
              45.385664184422
            ],
            [
              1.2988199548234,
              45.394630598699
            ],
            [
              1.3227905309582,
              45.382661066256
            ],
            [
              1.3205078307796,
              45.36792999935
            ],
            [
              1.3157868610203,
              45.36091417114
            ],
            [
              1.2891030872449,
              45.354071785139
            ],
            [
              1.2807179300268,
              45.338113368241
            ],
            [
              1.2726036516046,
              45.32822981062
            ],
            [
              1.2606945710672,
              45.32079145568
            ],
            [
              1.2394220228384,
              45.322752807172
            ],
            [
              1.2400866778708,
              45.294365351244
            ],
            [
              1.2323773668524,
              45.288832610689
            ],
            [
              1.2271245972559,
              45.271978251465
            ],
            [
              1.2422156707612,
              45.265911024006
            ],
            [
              1.2396375460236,
              45.260353044847
            ],
            [
              1.2545859923098,
              45.25486740913
            ],
            [
              1.2758370646328,
              45.2559186387
            ],
            [
              1.2774900011493,
              45.242079620298
            ],
            [
              1.2551055781893,
              45.226893309734
            ],
            [
              1.2336066661974,
              45.222192721421
            ],
            [
              1.2278052507949,
              45.206031457487
            ],
            [
              1.2322989918746,
              45.197699651744
            ],
            [
              1.2752166490499,
              45.199090409414
            ],
            [
              1.2921236447883,
              45.186511803411
            ],
            [
              1.2853453858257,
              45.175945310037
            ],
            [
              1.2693614439749,
              45.164757781728
            ],
            [
              1.2549224728883,
              45.165067910911
            ],
            [
              1.2547676106054,
              45.159308650493
            ],
            [
              1.2741676331289,
              45.151771329184
            ],
            [
              1.2965146282997,
              45.140190567774
            ],
            [
              1.307404186823,
              45.137314954018
            ],
            [
              1.3173886309875,
              45.142106860552
            ],
            [
              1.3379427676437,
              45.137921856753
            ],
            [
              1.3519854604388,
              45.141637154796
            ],
            [
              1.3588871682785,
              45.132296998015
            ],
            [
              1.3870029147721,
              45.134367826235
            ],
            [
              1.4128943596301,
              45.125085838348
            ],
            [
              1.4094676341512,
              45.112643165427
            ],
            [
              1.3864362517441,
              45.102876785498
            ],
            [
              1.394928381744,
              45.092684987797
            ],
            [
              1.3915778775111,
              45.087303436846
            ],
            [
              1.4063561964977,
              45.069292559474
            ],
            [
              1.3995614571492,
              45.061184544578
            ],
            [
              1.4162109061434,
              45.050200502051
            ],
            [
              1.4217014869627,
              45.041650121731
            ],
            [
              1.4385963777472,
              45.025733158494
            ],
            [
              1.4482602497483,
              45.019314041206
            ],
            [
              1.4281821749303,
              45.009219883472
            ],
            [
              1.4092638730924,
              45.006004469319
            ],
            [
              1.4133042325955,
              44.999381814036
            ],
            [
              1.414587951063,
              44.977794038016
            ],
            [
              1.4207336396897,
              44.955116554897
            ],
            [
              1.4364044047008,
              44.940615131261
            ],
            [
              1.4365133670875,
              44.93225069741
            ],
            [
              1.4423501795316,
              44.916547257696
            ],
            [
              1.4246258313763,
              44.919694190356
            ],
            [
              1.4135387322774,
              44.911821580701
            ],
            [
              1.4216345306166,
              44.896767467267
            ],
            [
              1.4398567374008,
              44.888947217313
            ],
            [
              1.4419256468077,
              44.877575693392
            ],
            [
              1.4310840691639,
              44.871280603185
            ],
            [
              1.4181797898114,
              44.870598618527
            ],
            [
              1.4048297845553,
              44.862526244834
            ],
            [
              1.4019376918366,
              44.849449622068
            ],
            [
              1.3861021387482,
              44.847434745656
            ],
            [
              1.3771449543322,
              44.84182444963
            ],
            [
              1.3699846242068,
              44.846449057072
            ],
            [
              1.3614088604034,
              44.840796241728
            ],
            [
              1.3606303391154,
              44.826748288149
            ],
            [
              1.3641055003826,
              44.811568223737
            ],
            [
              1.3368655384896,
              44.806071554052
            ],
            [
              1.3281059944688,
              44.806531488239
            ],
            [
              1.2996402457488,
              44.796921415066
            ],
            [
              1.3042790767232,
              44.788545534304
            ],
            [
              1.2962430489699,
              44.777811462978
            ],
            [
              1.313412866091,
              44.766040449212
            ],
            [
              1.322816677032,
              44.765133167519
            ],
            [
              1.3160471856435,
              44.740370070289
            ],
            [
              1.3005234409238,
              44.743067753612
            ],
            [
              1.2997473700748,
              44.733876961929
            ],
            [
              1.2877769806729,
              44.714784618791
            ],
            [
              1.2704127081354,
              44.722361774434
            ],
            [
              1.2637980717141,
              44.710685806718
            ],
            [
              1.2482656593079,
              44.707708989465
            ],
            [
              1.2433609148288,
              44.703747365953
            ],
            [
              1.2404413959773,
              44.692803925964
            ],
            [
              1.2245513454404,
              44.684265469136
            ],
            [
              1.1922298707031,
              44.682144673083
            ],
            [
              1.1814922279959,
              44.68312050249
            ],
            [
              1.1691224492748,
              44.680201458326
            ],
            [
              1.1631824196253,
              44.674246913408
            ],
            [
              1.1466756285759,
              44.670346129862
            ],
            [
              1.1467257654642,
              44.651942998392
            ],
            [
              1.1537948801342,
              44.639408990845
            ],
            [
              1.137389330558,
              44.623916068209
            ],
            [
              1.1076423221767,
              44.604047948788
            ],
            [
              1.0954254371464,
              44.590239410789
            ],
            [
              1.1023480014998,
              44.583112143598
            ],
            [
              1.10321434571,
              44.571734741055
            ],
            [
              1.0915723340934,
              44.57129825478
            ],
            [
              1.0751420531924,
              44.577325705506
            ],
            [
              1.0779367166615,
              44.584607289506
            ],
            [
              1.0733130611041,
              44.595578793709
            ],
            [
              1.0642117552636,
              44.596511145972
            ],
            [
              1.0454184639247,
              44.605754716834
            ],
            [
              1.0147514182424,
              44.614096385206
            ],
            [
              0.99682491105238,
              44.63179745302
            ],
            [
              0.9791235230928,
              44.643607663491
            ],
            [
              0.97165086674602,
              44.638207048166
            ],
            [
              0.96159103444349,
              44.637461894801
            ],
            [
              0.94856309175281,
              44.640612493856
            ],
            [
              0.92747540107031,
              44.635428181755
            ],
            [
              0.92136204024362,
              44.627560358651
            ],
            [
              0.89074979357294,
              44.614991070286
            ],
            [
              0.87785522131399,
              44.615460197735
            ],
            [
              0.86999224882068,
              44.597310797789
            ],
            [
              0.84250074564053,
              44.600962425481
            ],
            [
              0.83752764271031,
              44.605670766802
            ],
            [
              0.83738728356167,
              44.616103207266
            ],
            [
              0.82825800061689,
              44.61369341488
            ],
            [
              0.81700413459438,
              44.627005076133
            ],
            [
              0.83636330139759,
              44.638582868907
            ],
            [
              0.83505655242676,
              44.653293928743
            ],
            [
              0.84255446514478,
              44.656139571349
            ],
            [
              0.84339892561011,
              44.663482499488
            ],
            [
              0.82608946558714,
              44.675992791318
            ],
            [
              0.83155336202802,
              44.684262335678
            ],
            [
              0.82699837154341,
              44.69189132944
            ],
            [
              0.79772154851158,
              44.701768085135
            ],
            [
              0.79265719596499,
              44.699718223349
            ],
            [
              0.7798310395793,
              44.683389762054
            ],
            [
              0.76447481571943,
              44.689532790514
            ],
            [
              0.76265233144161,
              44.682144002173
            ],
            [
              0.74480854311439,
              44.681792562832
            ],
            [
              0.72913529059336,
              44.676077994737
            ],
            [
              0.71646914776409,
              44.678082439569
            ],
            [
              0.6849985568551,
              44.675550535442
            ],
            [
              0.65743174445133,
              44.677850624615
            ],
            [
              0.65589506955682,
              44.694208640971
            ],
            [
              0.64156011376223,
              44.704261998438
            ],
            [
              0.63048461600039,
              44.70652938729
            ],
            [
              0.62200094378824,
              44.701000019877
            ],
            [
              0.62001412394403,
              44.691298261574
            ],
            [
              0.60972459944616,
              44.695374617448
            ],
            [
              0.58857834129558,
              44.698027811587
            ],
            [
              0.57715032653657,
              44.694022262337
            ],
            [
              0.570416745932,
              44.674897613312
            ],
            [
              0.54124786531433,
              44.665158664905
            ],
            [
              0.53612162212023,
              44.674035536056
            ],
            [
              0.52178035716357,
              44.678540368601
            ],
            [
              0.5030043961045,
              44.674065809945
            ],
            [
              0.48751696358911,
              44.666675018711
            ],
            [
              0.47053267175137,
              44.669578320195
            ],
            [
              0.44999811697044,
              44.654887700413
            ],
            [
              0.41471046209978,
              44.646333606438
            ],
            [
              0.39443306244771,
              44.653282597181
            ],
            [
              0.38167715195904,
              44.654918852838
            ],
            [
              0.36557177083639,
              44.662207347472
            ],
            [
              0.35458874373803,
              44.654812823774
            ],
            [
              0.34962257641711,
              44.660130119601
            ],
            [
              0.35032434126437,
              44.674861231998
            ],
            [
              0.3596155447401,
              44.690997557697
            ],
            [
              0.3408274770455,
              44.703595690519
            ],
            [
              0.34799385726824,
              44.710868627082
            ],
            [
              0.34618532398135,
              44.72254110551
            ],
            [
              0.33544412179638,
              44.737433837779
            ],
            [
              0.30916614899776,
              44.757357085356
            ],
            [
              0.2973266430349,
              44.762287404122
            ],
            [
              0.28204980793737,
              44.773092230688
            ],
            [
              0.27422143591466,
              44.783396630933
            ],
            [
              0.27439801350506,
              44.796085877199
            ],
            [
              0.26597731108046,
              44.81259127317
            ],
            [
              0.26372618640876,
              44.826279352937
            ],
            [
              0.28405377486658,
              44.823658404053
            ],
            [
              0.30072874268204,
              44.829865184548
            ],
            [
              0.30028764362365,
              44.83780450023
            ],
            [
              0.31506020240148,
              44.845415778491
            ],
            [
              0.30800194052489,
              44.851728215429
            ],
            [
              0.28515427203214,
              44.864559374292
            ],
            [
              0.27498244856928,
              44.867663151563
            ],
            [
              0.25542730507554,
              44.867493927629
            ],
            [
              0.24408021243543,
              44.871832513417
            ],
            [
              0.23764871759625,
              44.869244743449
            ],
            [
              0.24175586953546,
              44.856896621192
            ],
            [
              0.22239699370187,
              44.844614779064
            ],
            [
              0.20930247571485,
              44.841088001383
            ],
            [
              0.19289670858098,
              44.821030025695
            ],
            [
              0.17042739802804,
              44.826682447294
            ],
            [
              0.12621182714146,
              44.828280775462
            ],
            [
              0.1126443072455,
              44.819064166843
            ],
            [
              0.10851421483567,
              44.828158653021
            ],
            [
              0.098021195945006,
              44.83286857884
            ],
            [
              0.083027055631579,
              44.831222961027
            ],
            [
              0.075753601678977,
              44.819691250591
            ],
            [
              0.061980823617039,
              44.825724847157
            ],
            [
              0.046918911914787,
              44.824577015785
            ],
            [
              0.039465724707109,
              44.827491118364
            ],
            [
              0.03739904504781,
              44.839909766296
            ],
            [
              0.021068730009597,
              44.849764251415
            ],
            [
              0.0088805210557711,
              44.845963057545
            ],
            [
              -0.0038738929006231,
              44.846691521098
            ],
            [
              -0.034209540087703,
              44.852109918912
            ],
            [
              -0.033078198207228,
              44.855598888537
            ],
            [
              -0.015443810938192,
              44.857712121687
            ],
            [
              -0.007339802937758,
              44.86841238029
            ],
            [
              0.0000049343835534158,
              44.870853719864
            ],
            [
              0.0022608102109646,
              44.878505514648
            ],
            [
              0.013321330144365,
              44.885523222829
            ],
            [
              0.034364629036925,
              44.915470221895
            ],
            [
              0.01821427303543,
              44.928027514889
            ],
            [
              0.0073841338995166,
              44.947297148555
            ],
            [
              0.0092278596756798,
              44.962772057093
            ],
            [
              0.01765322000624,
              44.980541068271
            ],
            [
              0.027060227717503,
              44.975375195215
            ],
            [
              0.033385476085785,
              44.980626698129
            ],
            [
              0.040107702552529,
              44.993500073196
            ],
            [
              0.035853106594622,
              45.011148382915
            ],
            [
              0.04364304777729,
              45.032727630944
            ],
            [
              0.054236225192005,
              45.039326370258
            ],
            [
              0.065721176012138,
              45.060521385337
            ],
            [
              0.073291450340814,
              45.070124430762
            ],
            [
              0.064755925305126,
              45.082017574721
            ],
            [
              0.060262257593117,
              45.098088622342
            ],
            [
              0.046062765840233,
              45.113225257483
            ],
            [
              0.021023704339745,
              45.118020097877
            ],
            [
              -0.0035550868294509,
              45.119565426221
            ],
            [
              -0.020622596045142,
              45.115154002497
            ],
            [
              -0.040197554246423,
              45.102379906641
            ],
            [
              -0.041998525054412,
              45.11242092649
            ],
            [
              -0.02922928973293,
              45.12982948041
            ],
            [
              -0.037046275435503,
              45.139030327167
            ],
            [
              -0.021366349602208,
              45.145880874789
            ],
            [
              0.0047181608691669,
              45.153738868895
            ],
            [
              -0.00065675566783935,
              45.159349483615
            ],
            [
              -0.018373027267724,
              45.161693678976
            ],
            [
              -0.017852544026991,
              45.169219084458
            ],
            [
              0.0016786787652185,
              45.183382364904
            ],
            [
              0.0043360783093975,
              45.191628193392
            ],
            [
              0.0077522704233095,
              45.202019009121
            ],
            [
              0.033349583596485,
              45.208000365146
            ],
            [
              0.038446114457259,
              45.216205521194
            ],
            [
              0.053427894812278,
              45.226854908396
            ],
            [
              0.062925519022323,
              45.221997931334
            ],
            [
              0.075727933465365,
              45.220263293742
            ],
            [
              0.096190225093676,
              45.227859522857
            ],
            [
              0.11486582285595,
              45.214042420393
            ],
            [
              0.12772944996689,
              45.209114796347
            ],
            [
              0.14548278729734,
              45.214442736387
            ],
            [
              0.14699338969177,
              45.224911449741
            ],
            [
              0.15657015052375,
              45.225855753722
            ],
            [
              0.17460141923617,
              45.239293180311
            ],
            [
              0.1722959209832,
              45.251711979864
            ],
            [
              0.17509716606769,
              45.260270315446
            ],
            [
              0.18817622900968,
              45.259794647126
            ],
            [
              0.20484689543031,
              45.264012329551
            ],
            [
              0.22003424892432,
              45.289805400589
            ],
            [
              0.23643629318698,
              45.29266355845
            ],
            [
              0.24739239545183,
              45.289485714699
            ],
            [
              0.26660296978794,
              45.297745901318
            ],
            [
              0.26921890468579,
              45.315814949459
            ],
            [
              0.26490932583048,
              45.33452939729
            ],
            [
              0.25696762585075,
              45.353063874184
            ],
            [
              0.24932846319757,
              45.363045050054
            ],
            [
              0.25031149908812,
              45.373412622458
            ],
            [
              0.26780372409671,
              45.408430729454
            ],
            [
              0.27061444567416,
              45.420406763477
            ],
            [
              0.27814648421058,
              45.427300520629
            ],
            [
              0.29440078145291,
              45.434184730704
            ],
            [
              0.31181848118158,
              45.432816179763
            ],
            [
              0.31787770256526,
              45.436660724831
            ],
            [
              0.30194134811497,
              45.445955831374
            ],
            [
              0.30267332511178,
              45.459062975769
            ],
            [
              0.30785039372442,
              45.460866796551
            ],
            [
              0.33082431658907,
              45.444453188001
            ],
            [
              0.33286092152216,
              45.459406476728
            ],
            [
              0.35653476026362,
              45.465475438899
            ],
            [
              0.36357698312745,
              45.475994167559
            ],
            [
              0.3766229628371,
              45.476044095153
            ],
            [
              0.38169806860726,
              45.486257002114
            ],
            [
              0.39094372803835,
              45.485293893562
            ],
            [
              0.40982218818341,
              45.49237033937
            ],
            [
              0.42370088241242,
              45.483249687319
            ],
            [
              0.43174334891146,
              45.485591802493
            ],
            [
              0.43279367905213,
              45.502460755433
            ],
            [
              0.45880881752177,
              45.525848169906
            ],
            [
              0.46573556287174,
              45.539620525876
            ],
            [
              0.4795284728018,
              45.53881551596
            ],
            [
              0.49626305489216,
              45.544487211484
            ],
            [
              0.50649651854355,
              45.553883504146
            ],
            [
              0.49857645241005,
              45.564777127559
            ],
            [
              0.51292546811965,
              45.58606906763
            ],
            [
              0.51559326392688,
              45.600376147073
            ],
            [
              0.49964719571994,
              45.61725840045
            ],
            [
              0.50682033461012,
              45.619445608837
            ],
            [
              0.51575470053391,
              45.634304936504
            ],
            [
              0.52922550873209,
              45.644017077379
            ],
            [
              0.53542184055499,
              45.642857423245
            ],
            [
              0.53553993475933,
              45.633365219553
            ],
            [
              0.54683683001201,
              45.635076073339
            ],
            [
              0.56121500762338,
              45.63273994743
            ],
            [
              0.57544701362335,
              45.640928641003
            ],
            [
              0.56781134452759,
              45.651022517126
            ],
            [
              0.58895693225232,
              45.666618927718
            ],
            [
              0.60069801255267,
              45.672144513707
            ],
            [
              0.60343704553547,
              45.693199195309
            ],
            [
              0.61532184885031,
              45.695046916735
            ],
            [
              0.62850817361747,
              45.706676529807
            ],
            [
              0.62974117909144,
              45.714569962764
            ]
          ]
        ]
      },
      "properties": {
        "code": "24",
        "nom": "Dordogne",
        "population": 412807,
        "delitspour1000": "3,924",
        "nombremosque": 8,
        "parentsEtrangers": "82,6",
        "benefRsa": 19118,
        "eoliennes": 1
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              0.29722451460974,
              49.429859840723
            ],
            [
              0.34059327041276,
              49.434318983746
            ],
            [
              0.33897883098918,
              49.440928520352
            ],
            [
              0.36879204470888,
              49.443962475083
            ],
            [
              0.40431210614422,
              49.451034141968
            ],
            [
              0.43152748913211,
              49.458951611128
            ],
            [
              0.49007537526958,
              49.483610306923
            ],
            [
              0.50251520739814,
              49.485111873749
            ],
            [
              0.52493488178974,
              49.477867578886
            ],
            [
              0.56492476734485,
              49.444750945735
            ],
            [
              0.58188184458619,
              49.433632944846
            ],
            [
              0.60625554588534,
              49.428693332289
            ],
            [
              0.63412440818502,
              49.434463954235
            ],
            [
              0.64090608427488,
              49.427676901532
            ],
            [
              0.65978237100252,
              49.416171083057
            ],
            [
              0.64947713542043,
              49.409189372744
            ],
            [
              0.66528473510118,
              49.401997722838
            ],
            [
              0.69045539241571,
              49.40650403573
            ],
            [
              0.7056003899825,
              49.402343816365
            ],
            [
              0.71643782065779,
              49.411320665886
            ],
            [
              0.73828625948595,
              49.408065043899
            ],
            [
              0.76486607576678,
              49.419583047737
            ],
            [
              0.77365720426929,
              49.418080731908
            ],
            [
              0.78382800946091,
              49.410574961906
            ],
            [
              0.79786974362259,
              49.417557183588
            ],
            [
              0.80423965947656,
              49.413719286552
            ],
            [
              0.81888027155234,
              49.398182878344
            ],
            [
              0.84531248738351,
              49.39200810179
            ],
            [
              0.86085285029658,
              49.395353751197
            ],
            [
              0.87033041806675,
              49.394166851087
            ],
            [
              0.88531880916794,
              49.383521989565
            ],
            [
              0.88351561750447,
              49.374775572952
            ],
            [
              0.89254570310317,
              49.372233966288
            ],
            [
              0.89994552150279,
              49.380147831204
            ],
            [
              0.91811535035589,
              49.385457817834
            ],
            [
              0.91829936141772,
              49.369854047652
            ],
            [
              0.92432525872208,
              49.358864437542
            ],
            [
              0.92321044150132,
              49.349902808708
            ],
            [
              0.93202913089827,
              49.344749683067
            ],
            [
              0.92140513379514,
              49.338551694977
            ],
            [
              0.90743826773733,
              49.340304135725
            ],
            [
              0.88759304231232,
              49.345911579885
            ],
            [
              0.86622900849809,
              49.342309783711
            ],
            [
              0.85233182532436,
              49.333091020365
            ],
            [
              0.85246768535533,
              49.326488531003
            ],
            [
              0.86283554220531,
              49.325694960317
            ],
            [
              0.89481428802281,
              49.316006224831
            ],
            [
              0.89249816086963,
              49.303066153881
            ],
            [
              0.90075665085202,
              49.297063659707
            ],
            [
              0.90921325896871,
              49.298377660408
            ],
            [
              0.90729313280231,
              49.307619218086
            ],
            [
              0.92569914870168,
              49.313416779579
            ],
            [
              0.93650102423199,
              49.31921212392
            ],
            [
              0.95773612006249,
              49.289339180562
            ],
            [
              0.9557712480057,
              49.271462930815
            ],
            [
              0.97079868730402,
              49.280532893554
            ],
            [
              0.97769464632566,
              49.27070383999
            ],
            [
              0.98616817899411,
              49.269508388285
            ],
            [
              0.99276212065971,
              49.253192391205
            ],
            [
              1.0133221517563,
              49.252262168305
            ],
            [
              1.0330368002648,
              49.260707371328
            ],
            [
              1.0411806449182,
              49.258523433694
            ],
            [
              1.0540655714671,
              49.268105682449
            ],
            [
              1.0520047369193,
              49.273124324455
            ],
            [
              1.0644196875088,
              49.281936004992
            ],
            [
              1.0481433809466,
              49.297882244778
            ],
            [
              1.0806844645154,
              49.30878433789
            ],
            [
              1.0929172750222,
              49.309969974333
            ],
            [
              1.1339907890446,
              49.309842015717
            ],
            [
              1.135956149247,
              49.32510877111
            ],
            [
              1.1541323790907,
              49.33549257124
            ],
            [
              1.1602570896098,
              49.341997772279
            ],
            [
              1.1827115617536,
              49.343841832128
            ],
            [
              1.1848482018314,
              49.338067349594
            ],
            [
              1.2007170029988,
              49.33921553952
            ],
            [
              1.1990258520385,
              49.350805240884
            ],
            [
              1.2186078643559,
              49.34861032434
            ],
            [
              1.2721367892739,
              49.346284740023
            ],
            [
              1.272174418433,
              49.357075379049
            ],
            [
              1.2817525580978,
              49.356800138676
            ],
            [
              1.2899210153359,
              49.375704998826
            ],
            [
              1.2854841472719,
              49.38817876933
            ],
            [
              1.2931901169732,
              49.394992930411
            ],
            [
              1.3080614529965,
              49.395354697053
            ],
            [
              1.3134720284489,
              49.419302373451
            ],
            [
              1.3096373647687,
              49.428733548322
            ],
            [
              1.3367318187267,
              49.42769114546
            ],
            [
              1.3380485043698,
              49.43878228156
            ],
            [
              1.3452388768398,
              49.448058599484
            ],
            [
              1.3609573928556,
              49.448195962709
            ],
            [
              1.3736977969193,
              49.452867133483
            ],
            [
              1.3934959752114,
              49.449228032324
            ],
            [
              1.3955347915735,
              49.454552714669
            ],
            [
              1.412127551696,
              49.456496515022
            ],
            [
              1.4357335585164,
              49.455147813822
            ],
            [
              1.447264105952,
              49.45064317033
            ],
            [
              1.4771804721454,
              49.451327801496
            ],
            [
              1.4947848961028,
              49.446555996217
            ],
            [
              1.5041681217892,
              49.438033275865
            ],
            [
              1.5283099451368,
              49.441990586983
            ],
            [
              1.537389344223,
              49.43039797237
            ],
            [
              1.5480395067027,
              49.440906427628
            ],
            [
              1.5754240690858,
              49.440139502679
            ],
            [
              1.5915230861532,
              49.426823837925
            ],
            [
              1.6069621918063,
              49.410851784225
            ],
            [
              1.6246392090745,
              49.414499717653
            ],
            [
              1.63126059829,
              49.4101644841
            ],
            [
              1.6592989506899,
              49.398985140911
            ],
            [
              1.6663794284045,
              49.404872512906
            ],
            [
              1.6796639751039,
              49.405979198472
            ],
            [
              1.6893918934249,
              49.399833597052
            ],
            [
              1.698642071408,
              49.398715360789
            ],
            [
              1.7045500554634,
              49.410702508293
            ],
            [
              1.7139306567281,
              49.409224915677
            ],
            [
              1.7401057248725,
              49.405314790651
            ],
            [
              1.7203128039946,
              49.39483019677
            ],
            [
              1.74121389808,
              49.381720558637
            ],
            [
              1.747580532548,
              49.37311965741
            ],
            [
              1.7594132403395,
              49.36814533573
            ],
            [
              1.7576982127616,
              49.356710604078
            ],
            [
              1.7727704938425,
              49.333136370328
            ],
            [
              1.7676000740408,
              49.315889978292
            ],
            [
              1.7756247174004,
              49.29969367954
            ],
            [
              1.796488015906,
              49.284111717893
            ],
            [
              1.7932298788283,
              49.274330698204
            ],
            [
              1.8026740663848,
              49.271947858898
            ],
            [
              1.7918059477748,
              49.256427629819
            ],
            [
              1.7895339086741,
              49.247870747839
            ],
            [
              1.7668935671793,
              49.252034345086
            ],
            [
              1.7643549601555,
              49.263082374151
            ],
            [
              1.7547213954529,
              49.270007807429
            ],
            [
              1.7104820360249,
              49.264465989242
            ],
            [
              1.7014183926808,
              49.252460272045
            ],
            [
              1.699042864415,
              49.234864303276
            ],
            [
              1.7043588288241,
              49.232197221792
            ],
            [
              1.7040643483737,
              49.229320472612
            ],
            [
              1.6757423007191,
              49.21186711807
            ],
            [
              1.6769552220057,
              49.202542812736
            ],
            [
              1.6670168601861,
              49.178613559023
            ],
            [
              1.6643792614171,
              49.153368483471
            ],
            [
              1.6539225302112,
              49.145440634177
            ],
            [
              1.655781502711,
              49.130164014177
            ],
            [
              1.6470910019222,
              49.124713530921
            ],
            [
              1.6175344667245,
              49.093996514155
            ],
            [
              1.6233233619235,
              49.086078404348
            ],
            [
              1.608798807603,
              49.077894185227
            ],
            [
              1.6044866350314,
              49.08303659502
            ],
            [
              1.5747375500157,
              49.078437534581
            ],
            [
              1.5570743762242,
              49.069620586462
            ],
            [
              1.5494725491086,
              49.072557881924
            ],
            [
              1.5217716034178,
              49.068546693163
            ],
            [
              1.5116006058281,
              49.074136193201
            ],
            [
              1.5032944474047,
              49.059098256736
            ],
            [
              1.4848766880401,
              49.0515451317
            ],
            [
              1.4608012364168,
              49.062739598254
            ],
            [
              1.4472851104304,
              49.053509472828
            ],
            [
              1.4474286969633,
              49.04518889808
            ],
            [
              1.4579533584639,
              49.03483279821
            ],
            [
              1.4577570076778,
              49.026294572016
            ],
            [
              1.4721822976397,
              49.018490972378
            ],
            [
              1.4787512905862,
              48.998884682872
            ],
            [
              1.4609202371105,
              48.986433838647
            ],
            [
              1.4706099672547,
              48.975306435494
            ],
            [
              1.4770931932159,
              48.978791319268
            ],
            [
              1.497416022081,
              48.979551284823
            ],
            [
              1.5079088220123,
              48.98376724437
            ],
            [
              1.5155485568584,
              48.976406187821
            ],
            [
              1.4966058957223,
              48.969344788938
            ],
            [
              1.5010590902862,
              48.952678048634
            ],
            [
              1.5015266013113,
              48.941051842112
            ],
            [
              1.4806477281429,
              48.940490376837
            ],
            [
              1.4613112437714,
              48.937555310548
            ],
            [
              1.4594110528379,
              48.928926959252
            ],
            [
              1.4479593034805,
              48.924639965044
            ],
            [
              1.4593261044838,
              48.914649076535
            ],
            [
              1.4606700524523,
              48.900494617753
            ],
            [
              1.4683545148033,
              48.892215598584
            ],
            [
              1.4653628424762,
              48.877601417126
            ],
            [
              1.4549936915991,
              48.870326164498
            ],
            [
              1.4343110180818,
              48.866140106761
            ],
            [
              1.4238934051882,
              48.861268418973
            ],
            [
              1.4093657222488,
              48.861152641723
            ],
            [
              1.3968565494996,
              48.855383585466
            ],
            [
              1.3905601143901,
              48.8466061658
            ],
            [
              1.3621823366519,
              48.834179921179
            ],
            [
              1.3592476591605,
              48.813608561344
            ],
            [
              1.3769627320869,
              48.791766336256
            ],
            [
              1.3744255946955,
              48.782613836125
            ],
            [
              1.3626643019013,
              48.781664300017
            ],
            [
              1.3422322124976,
              48.772542324536
            ],
            [
              1.3329054445301,
              48.762612355286
            ],
            [
              1.3195444496148,
              48.76096144137
            ],
            [
              1.3002968590985,
              48.767536812046
            ],
            [
              1.2799854142834,
              48.762674216595
            ],
            [
              1.2702130511038,
              48.757482633762
            ],
            [
              1.2545465453882,
              48.758629272206
            ],
            [
              1.2562637586379,
              48.765103705723
            ],
            [
              1.246023632927,
              48.769675823998
            ],
            [
              1.2226160798083,
              48.767292140423
            ],
            [
              1.223526519249,
              48.758056658402
            ],
            [
              1.1981446610666,
              48.769568542277
            ],
            [
              1.1847360438338,
              48.772630245963
            ],
            [
              1.161542788993,
              48.769293220083
            ],
            [
              1.1533037151721,
              48.774016041266
            ],
            [
              1.1521150110915,
              48.78555968408
            ],
            [
              1.1213959532999,
              48.789195509733
            ],
            [
              1.1189842149957,
              48.782375233568
            ],
            [
              1.1209842348348,
              48.767249558527
            ],
            [
              1.1126577960236,
              48.751863710692
            ],
            [
              1.0957264674684,
              48.748713222693
            ],
            [
              1.086000043818,
              48.756926389494
            ],
            [
              1.0757811283562,
              48.749437927852
            ],
            [
              1.0686653703661,
              48.756913559557
            ],
            [
              1.0590712054157,
              48.756765184439
            ],
            [
              1.0398026909049,
              48.742155339
            ],
            [
              1.0316499412576,
              48.730581545643
            ],
            [
              1.0206075605327,
              48.734789446779
            ],
            [
              1.0140639866541,
              48.72810030179
            ],
            [
              0.98441493173502,
              48.725995395669
            ],
            [
              0.9772943914531,
              48.730138669646
            ],
            [
              0.96144420763056,
              48.725722137279
            ],
            [
              0.95524192597313,
              48.716786068922
            ],
            [
              0.92119872414556,
              48.709175212814
            ],
            [
              0.90239998073196,
              48.710649225086
            ],
            [
              0.88836808739528,
              48.718920532577
            ],
            [
              0.87671116761022,
              48.715496114803
            ],
            [
              0.86149783759132,
              48.691007914902
            ],
            [
              0.85036287028898,
              48.682948048025
            ],
            [
              0.82767132893029,
              48.680720042791
            ],
            [
              0.8148247207739,
              48.670163564137
            ],
            [
              0.79538719721568,
              48.675207207316
            ],
            [
              0.78220679339237,
              48.666521479531
            ],
            [
              0.76408006596892,
              48.675272833699
            ],
            [
              0.76268935054531,
              48.686942596812
            ],
            [
              0.76605034714122,
              48.696795557534
            ],
            [
              0.75269475803882,
              48.704115602005
            ],
            [
              0.73058883988464,
              48.705203303323
            ],
            [
              0.74339168877981,
              48.716901616421
            ],
            [
              0.75349996622665,
              48.719599220802
            ],
            [
              0.76276071401677,
              48.729629501028
            ],
            [
              0.77495753063022,
              48.737530835487
            ],
            [
              0.75091726492355,
              48.76179962791
            ],
            [
              0.75843020314215,
              48.767968371666
            ],
            [
              0.74018708351363,
              48.776579822882
            ],
            [
              0.73350204762147,
              48.783366854626
            ],
            [
              0.70123022325656,
              48.79212385317
            ],
            [
              0.69048615114254,
              48.793683771174
            ],
            [
              0.65632888501885,
              48.813559248276
            ],
            [
              0.64581875070678,
              48.816142840762
            ],
            [
              0.62821327737912,
              48.825367303778
            ],
            [
              0.61028848587188,
              48.831311343399
            ],
            [
              0.61687547597895,
              48.843731542228
            ],
            [
              0.62935408701068,
              48.85358418848
            ],
            [
              0.61096181241547,
              48.853220075098
            ],
            [
              0.60400928665889,
              48.864760454015
            ],
            [
              0.60439903682452,
              48.875113286031
            ],
            [
              0.58962884714758,
              48.872596179204
            ],
            [
              0.5865922049446,
              48.881406259078
            ],
            [
              0.59717255366436,
              48.884985872618
            ],
            [
              0.57803949069757,
              48.893649615021
            ],
            [
              0.56705847132496,
              48.887859486472
            ],
            [
              0.5525004519299,
              48.875534180346
            ],
            [
              0.54323381288612,
              48.875520650963
            ],
            [
              0.5249763956739,
              48.880449344675
            ],
            [
              0.50214522955794,
              48.878149419079
            ],
            [
              0.48961055807985,
              48.879924191983
            ],
            [
              0.47611395078483,
              48.886860891837
            ],
            [
              0.45936577011338,
              48.887848115553
            ],
            [
              0.45735531457632,
              48.879761525082
            ],
            [
              0.44311076213435,
              48.88128008313
            ],
            [
              0.43330826845583,
              48.886157583779
            ],
            [
              0.42006471195552,
              48.888184382023
            ],
            [
              0.41770661476048,
              48.897680118218
            ],
            [
              0.39679657312957,
              48.907778004029
            ],
            [
              0.38618253122967,
              48.910414357049
            ],
            [
              0.390777135105,
              48.927129545247
            ],
            [
              0.40843769354036,
              48.943444622419
            ],
            [
              0.4128104390395,
              48.950626279328
            ],
            [
              0.4288088818384,
              48.966115453596
            ],
            [
              0.43289040253185,
              48.979120818423
            ],
            [
              0.42982494445022,
              48.987431294262
            ],
            [
              0.43675139756937,
              49.008189336971
            ],
            [
              0.44627431224134,
              49.014965324445
            ],
            [
              0.42589199846499,
              49.031958064681
            ],
            [
              0.39019662246369,
              49.034374854588
            ],
            [
              0.38300892869358,
              49.037875522311
            ],
            [
              0.37767292836076,
              49.049451079594
            ],
            [
              0.36710189915104,
              49.052400965803
            ],
            [
              0.38078167365394,
              49.061317823192
            ],
            [
              0.37938602804341,
              49.068890250096
            ],
            [
              0.38687315179178,
              49.074428646402
            ],
            [
              0.40781033259879,
              49.077391230915
            ],
            [
              0.41801317491927,
              49.073857705229
            ],
            [
              0.41621951470531,
              49.088228255484
            ],
            [
              0.39971762035569,
              49.10053703168
            ],
            [
              0.40969717633938,
              49.122161806987
            ],
            [
              0.40827854245832,
              49.12857401721
            ],
            [
              0.42487324481257,
              49.13019793323
            ],
            [
              0.42680057879681,
              49.142505589649
            ],
            [
              0.42370133418047,
              49.147889663477
            ],
            [
              0.39468951424446,
              49.150114808846
            ],
            [
              0.38589506777489,
              49.154052169189
            ],
            [
              0.39107966545639,
              49.161772214101
            ],
            [
              0.39191820887398,
              49.178533591286
            ],
            [
              0.38811391643554,
              49.189939951148
            ],
            [
              0.3947248164373,
              49.208444970872
            ],
            [
              0.37941345944254,
              49.217183544081
            ],
            [
              0.37255025076833,
              49.216891062992
            ],
            [
              0.33742355295386,
              49.231481905904
            ],
            [
              0.32602069862327,
              49.243848657902
            ],
            [
              0.32308269291004,
              49.25234848553
            ],
            [
              0.34434385294882,
              49.25409228301
            ],
            [
              0.36543435307866,
              49.262117681971
            ],
            [
              0.3827798797512,
              49.264016325866
            ],
            [
              0.38391220613638,
              49.28554046475
            ],
            [
              0.36621277377577,
              49.294993537578
            ],
            [
              0.34456630039903,
              49.295323299626
            ],
            [
              0.3262861426226,
              49.279107167667
            ],
            [
              0.30899424244078,
              49.283211083763
            ],
            [
              0.32301639746411,
              49.296990615639
            ],
            [
              0.31602271740194,
              49.311306530306
            ],
            [
              0.29815375911751,
              49.31441039187
            ],
            [
              0.31207228970972,
              49.322257454681
            ],
            [
              0.32342530221557,
              49.34064105785
            ],
            [
              0.30645720862332,
              49.359942076992
            ],
            [
              0.30171495288459,
              49.371306288931
            ],
            [
              0.30882071105454,
              49.387865806216
            ],
            [
              0.29740204185691,
              49.412268122221
            ],
            [
              0.29722451460974,
              49.429859840723
            ]
          ]
        ]
      },
      "properties": {
        "code": "27",
        "nom": "Eure",
        "population": 599668,
        "delitspour1000": "4,339",
        "nombremosque": 28,
        "parentsEtrangers": "81,6",
        "benefRsa": 28064,
        "eoliennes": 46
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              0.23000044283917,
              47.608397360802
            ],
            [
              0.23768269638563,
              47.610966183961
            ],
            [
              0.25925309972834,
              47.612253732911
            ],
            [
              0.26784211805055,
              47.608672360211
            ],
            [
              0.27799234011686,
              47.597381208395
            ],
            [
              0.29001704748145,
              47.597728628188
            ],
            [
              0.32325488038268,
              47.592888415747
            ],
            [
              0.33844630799321,
              47.585030259946
            ],
            [
              0.33958580258874,
              47.579472315073
            ],
            [
              0.36673611607068,
              47.573457923574
            ],
            [
              0.3789542711163,
              47.569104805534
            ],
            [
              0.40216586206278,
              47.579002216555
            ],
            [
              0.39442325665444,
              47.594393009892
            ],
            [
              0.37905609046241,
              47.610779501442
            ],
            [
              0.36480446047317,
              47.620165400372
            ],
            [
              0.36465430735157,
              47.626011449659
            ],
            [
              0.38107503178189,
              47.639064909321
            ],
            [
              0.39702255940301,
              47.638927008339
            ],
            [
              0.42390524684004,
              47.617824451176
            ],
            [
              0.44993291932188,
              47.619329777154
            ],
            [
              0.45518198840301,
              47.627017225989
            ],
            [
              0.45662804145159,
              47.638826353606
            ],
            [
              0.47607646347987,
              47.648011563852
            ],
            [
              0.4797670863672,
              47.64329220016
            ],
            [
              0.4996666538331,
              47.645272415067
            ],
            [
              0.51325174134335,
              47.652863992861
            ],
            [
              0.54289746214182,
              47.656203651505
            ],
            [
              0.55947922009731,
              47.665994923777
            ],
            [
              0.58772733155021,
              47.669617061038
            ],
            [
              0.60463738045699,
              47.679968482062
            ],
            [
              0.61480416648326,
              47.68275087954
            ],
            [
              0.61443245110168,
              47.694215472574
            ],
            [
              0.63086800399034,
              47.709346222795
            ],
            [
              0.64137067498854,
              47.70843381755
            ],
            [
              0.64301435028574,
              47.702551786936
            ],
            [
              0.65412732285932,
              47.698799689193
            ],
            [
              0.64390482458794,
              47.685067251567
            ],
            [
              0.65425850667632,
              47.684183192207
            ],
            [
              0.67477571238337,
              47.699006801159
            ],
            [
              0.7010053464739,
              47.684579696017
            ],
            [
              0.71214799110974,
              47.681812388507
            ],
            [
              0.73531585422423,
              47.695402804796
            ],
            [
              0.75180535085054,
              47.69184823689
            ],
            [
              0.78320042893704,
              47.68235568446
            ],
            [
              0.80507281346721,
              47.679159503352
            ],
            [
              0.81177915779572,
              47.682216116035
            ],
            [
              0.83496748767096,
              47.677165233877
            ],
            [
              0.86060587060998,
              47.688018990953
            ],
            [
              0.85949720357351,
              47.669203548914
            ],
            [
              0.84591633650506,
              47.647754456871
            ],
            [
              0.86215461167367,
              47.631049074596
            ],
            [
              0.85204426207512,
              47.619034516955
            ],
            [
              0.86389183389428,
              47.599630367943
            ],
            [
              0.89893247520029,
              47.604701943576
            ],
            [
              0.89698859328434,
              47.615113730164
            ],
            [
              0.90650366666187,
              47.617479969654
            ],
            [
              0.91900574324429,
              47.632611664588
            ],
            [
              0.93161832908705,
              47.628716338549
            ],
            [
              0.95623413460239,
              47.626222507135
            ],
            [
              0.96427671081529,
              47.629106510599
            ],
            [
              0.97098286285028,
              47.621898454134
            ],
            [
              0.99178650747969,
              47.620027809173
            ],
            [
              0.98550979500274,
              47.613118059669
            ],
            [
              0.98714887279053,
              47.59440347313
            ],
            [
              0.99702027543332,
              47.583970079496
            ],
            [
              1.0147866969862,
              47.604706536619
            ],
            [
              1.0239945252392,
              47.60883620849
            ],
            [
              1.0333513708963,
              47.60700704889
            ],
            [
              1.0520622429768,
              47.586840480214
            ],
            [
              1.0609100314345,
              47.57272466662
            ],
            [
              1.0698195534437,
              47.570506173528
            ],
            [
              1.0763324091565,
              47.563220588685
            ],
            [
              1.047676643153,
              47.537405035214
            ],
            [
              1.0452962339787,
              47.531486300724
            ],
            [
              1.0675584935427,
              47.522555271866
            ],
            [
              1.071355851475,
              47.513646383207
            ],
            [
              1.0677367798519,
              47.503935667418
            ],
            [
              1.0787858151265,
              47.494205595711
            ],
            [
              1.086392690701,
              47.478320264631
            ],
            [
              1.0944974842895,
              47.469320976705
            ],
            [
              1.0890903403387,
              47.462245859685
            ],
            [
              1.1128845724069,
              47.465918778816
            ],
            [
              1.1165370699599,
              47.459929727006
            ],
            [
              1.1323602092461,
              47.446466593613
            ],
            [
              1.112710681243,
              47.428740433958
            ],
            [
              1.0851384560263,
              47.429997142026
            ],
            [
              1.1074768932722,
              47.411321717533
            ],
            [
              1.1141829636491,
              47.39769599266
            ],
            [
              1.1235227723843,
              47.391947304044
            ],
            [
              1.1215527616821,
              47.38243543645
            ],
            [
              1.1132042812047,
              47.37976090763
            ],
            [
              1.1063733834643,
              47.371523980706
            ],
            [
              1.1228285926711,
              47.354020163768
            ],
            [
              1.1134093236717,
              47.347668695458
            ],
            [
              1.1058529658238,
              47.330900276906
            ],
            [
              1.094858720305,
              47.328533130173
            ],
            [
              1.0989538173396,
              47.311708795136
            ],
            [
              1.1081216639749,
              47.298401557587
            ],
            [
              1.135251812267,
              47.291670039949
            ],
            [
              1.1397902849331,
              47.286814484138
            ],
            [
              1.1620591370279,
              47.272198707934
            ],
            [
              1.173207188077,
              47.285311232893
            ],
            [
              1.194477009301,
              47.283004865615
            ],
            [
              1.2235301310757,
              47.294084329417
            ],
            [
              1.2390417763896,
              47.288850417214
            ],
            [
              1.24364362042,
              47.283660533825
            ],
            [
              1.2433159275112,
              47.273852150481
            ],
            [
              1.2578196138819,
              47.265801868366
            ],
            [
              1.267990228328,
              47.256180486566
            ],
            [
              1.276905457535,
              47.254710464825
            ],
            [
              1.2993170053838,
              47.228397320295
            ],
            [
              1.3035444606594,
              47.21808940042
            ],
            [
              1.3207067619298,
              47.197369985665
            ],
            [
              1.3266590555808,
              47.18622172903
            ],
            [
              1.3403616834348,
              47.176714426828
            ],
            [
              1.3467098477255,
              47.168763035526
            ],
            [
              1.3513009666821,
              47.152248772091
            ],
            [
              1.3608270853598,
              47.139644881154
            ],
            [
              1.3653663291974,
              47.12204955205
            ],
            [
              1.3557232970181,
              47.108787198703
            ],
            [
              1.3445235375283,
              47.101628681302
            ],
            [
              1.3324391688613,
              47.099590072851
            ],
            [
              1.3188337884266,
              47.104913182924
            ],
            [
              1.3116728530869,
              47.096977073662
            ],
            [
              1.3167792884814,
              47.084924278833
            ],
            [
              1.3126429957289,
              47.073774055065
            ],
            [
              1.2937745970093,
              47.072255183314
            ],
            [
              1.2912050782264,
              47.065574010099
            ],
            [
              1.2745356677731,
              47.049592096735
            ],
            [
              1.2730582525963,
              47.03783145755
            ],
            [
              1.2578242876804,
              47.026398912616
            ],
            [
              1.2320899400978,
              47.014142864447
            ],
            [
              1.2230663921608,
              47.024312137086
            ],
            [
              1.1955888714496,
              47.029530708071
            ],
            [
              1.1818586160475,
              47.040820071391
            ],
            [
              1.1614195391536,
              47.03962293091
            ],
            [
              1.13826224584,
              47.030521714593
            ],
            [
              1.1158296252981,
              47.024572387317
            ],
            [
              1.1102627168966,
              47.029236511374
            ],
            [
              1.08506655771,
              47.020688086638
            ],
            [
              1.0776156285907,
              47.015837897863
            ],
            [
              1.070212174847,
              47.000595894054
            ],
            [
              1.0559227392471,
              46.995938541629
            ],
            [
              1.055781061832,
              46.985319706164
            ],
            [
              1.0498710967753,
              46.980639407512
            ],
            [
              1.058208899214,
              46.971670307482
            ],
            [
              1.0635391807284,
              46.957306774009
            ],
            [
              1.0586238621418,
              46.951476334354
            ],
            [
              1.0375841279898,
              46.941252795031
            ],
            [
              1.0331387373787,
              46.9051217043
            ],
            [
              1.0252142173309,
              46.880107454131
            ],
            [
              1.0243995096079,
              46.870470878361
            ],
            [
              1.0192412205018,
              46.858760128319
            ],
            [
              1.0194707995534,
              46.849359367962
            ],
            [
              1.0105192309502,
              46.837379188645
            ],
            [
              1.00670501168,
              46.814944965284
            ],
            [
              0.98747609997385,
              46.804582753115
            ],
            [
              0.98724249153202,
              46.798191913732
            ],
            [
              1.0025317272091,
              46.794546933361
            ],
            [
              1.0003489879518,
              46.7821498087
            ],
            [
              1.0095223234417,
              46.768669105136
            ],
            [
              1.0116874509333,
              46.757807396767
            ],
            [
              0.98881316105931,
              46.761544268841
            ],
            [
              0.96794806660049,
              46.749171655021
            ],
            [
              0.95637654222944,
              46.737086924375
            ],
            [
              0.94370834746443,
              46.738598233751
            ],
            [
              0.93489233835098,
              46.750480745419
            ],
            [
              0.92470385464529,
              46.757126310086
            ],
            [
              0.88463522122144,
              46.754869575055
            ],
            [
              0.86746898682573,
              46.748216404838
            ],
            [
              0.85444929067001,
              46.759970797231
            ],
            [
              0.84293807311317,
              46.764275112746
            ],
            [
              0.83064773545714,
              46.775361466403
            ],
            [
              0.82943649435874,
              46.783579432834
            ],
            [
              0.81628357550664,
              46.787769101729
            ],
            [
              0.81190192936381,
              46.794504364542
            ],
            [
              0.8153195131751,
              46.805707876783
            ],
            [
              0.81032866524034,
              46.813064718291
            ],
            [
              0.80932023680023,
              46.827853568236
            ],
            [
              0.79509333742744,
              46.832522249054
            ],
            [
              0.78680224354972,
              46.840463346525
            ],
            [
              0.79645898941075,
              46.848962468561
            ],
            [
              0.79032856900623,
              46.852389283147
            ],
            [
              0.76879944678165,
              46.85074613749
            ],
            [
              0.77198838768839,
              46.860643073187
            ],
            [
              0.75088169303492,
              46.863524734354
            ],
            [
              0.74733084634723,
              46.869392079962
            ],
            [
              0.73368489987152,
              46.876040130575
            ],
            [
              0.72673737636298,
              46.886740909711
            ],
            [
              0.7043177119069,
              46.903295922263
            ],
            [
              0.70357222720342,
              46.930055655547
            ],
            [
              0.70624788531743,
              46.937157219193
            ],
            [
              0.6961893847553,
              46.956809748541
            ],
            [
              0.69256676799124,
              46.974304310533
            ],
            [
              0.68216214670066,
              46.977079806439
            ],
            [
              0.66162129594461,
              46.978086547342
            ],
            [
              0.65646206458496,
              46.985434564914
            ],
            [
              0.64769454368947,
              46.98827539877
            ],
            [
              0.63621078823085,
              46.985451013839
            ],
            [
              0.62124367303806,
              46.997106205666
            ],
            [
              0.6188740376389,
              47.007464569411
            ],
            [
              0.59055093530507,
              47.006722651596
            ],
            [
              0.56695306996355,
              47.002266858023
            ],
            [
              0.57368515665648,
              46.995531274591
            ],
            [
              0.57382203251682,
              46.983340464589
            ],
            [
              0.59353374575945,
              46.977612888014
            ],
            [
              0.60117289544287,
              46.973094369377
            ],
            [
              0.60155821661783,
              46.959107207652
            ],
            [
              0.59834824957594,
              46.956764146875
            ],
            [
              0.57084220800147,
              46.95593925543
            ],
            [
              0.53928916139484,
              46.960219024665
            ],
            [
              0.52812896085369,
              46.955971768907
            ],
            [
              0.5061349705044,
              46.959245419792
            ],
            [
              0.47662392866394,
              46.949861808585
            ],
            [
              0.44480103455716,
              46.941152434129
            ],
            [
              0.43870457627727,
              46.929578354733
            ],
            [
              0.41852515647745,
              46.937386922114
            ],
            [
              0.40666063754734,
              46.936647597652
            ],
            [
              0.38814626546636,
              46.943624533306
            ],
            [
              0.36651145250788,
              46.949554418002
            ],
            [
              0.35524351132264,
              46.94418585304
            ],
            [
              0.34773843894922,
              46.936585764264
            ],
            [
              0.33952563735758,
              46.936623468454
            ],
            [
              0.32483667041576,
              46.930652041087
            ],
            [
              0.31123243685649,
              46.937837015115
            ],
            [
              0.3052147303369,
              46.952805098497
            ],
            [
              0.30507546032998,
              46.965176268239
            ],
            [
              0.30073879657193,
              46.973829519846
            ],
            [
              0.3083994154951,
              46.978149875297
            ],
            [
              0.30178521378569,
              46.984422127588
            ],
            [
              0.30884587377182,
              46.999441441774
            ],
            [
              0.30543065376719,
              47.012362068122
            ],
            [
              0.29867667498614,
              47.019599341054
            ],
            [
              0.30969866315285,
              47.025643877461
            ],
            [
              0.30698465850517,
              47.048723481399
            ],
            [
              0.29822992579073,
              47.053922293608
            ],
            [
              0.26770726634947,
              47.04388297477
            ],
            [
              0.26201563438582,
              47.057459330927
            ],
            [
              0.26761937550861,
              47.067504197075
            ],
            [
              0.26165981464917,
              47.070051249456
            ],
            [
              0.24245135321948,
              47.0711879788
            ],
            [
              0.23175079195616,
              47.064054806793
            ],
            [
              0.20799449312514,
              47.053230964732
            ],
            [
              0.19167202519639,
              47.064672009871
            ],
            [
              0.17997285846393,
              47.059187917933
            ],
            [
              0.17421802926902,
              47.071274231914
            ],
            [
              0.20095307158529,
              47.091257976623
            ],
            [
              0.18811779784912,
              47.100828164294
            ],
            [
              0.18145709966147,
              47.1143899519
            ],
            [
              0.16598416316105,
              47.107162285602
            ],
            [
              0.16126718554822,
              47.100796179607
            ],
            [
              0.13471650901781,
              47.107872599064
            ],
            [
              0.13613099833731,
              47.121578754741
            ],
            [
              0.12716663727095,
              47.119967203103
            ],
            [
              0.12372359489648,
              47.128315822721
            ],
            [
              0.11111857810383,
              47.129423259303
            ],
            [
              0.10471937462849,
              47.120793812241
            ],
            [
              0.084648249403316,
              47.118377760157
            ],
            [
              0.076371249669326,
              47.123931049218
            ],
            [
              0.08087053131906,
              47.137653029292
            ],
            [
              0.078395363975684,
              47.146334786534
            ],
            [
              0.066259497472021,
              47.143215902043
            ],
            [
              0.053830055961677,
              47.16373374848
            ],
            [
              0.063052886223431,
              47.175281799504
            ],
            [
              0.066596690426524,
              47.189796025762
            ],
            [
              0.053277684947378,
              47.197182170708
            ],
            [
              0.072492820219795,
              47.220509854465
            ],
            [
              0.074829460019472,
              47.248048474897
            ],
            [
              0.082848635358157,
              47.274168568836
            ],
            [
              0.078978960154079,
              47.282822156056
            ],
            [
              0.099012801986365,
              47.308139754432
            ],
            [
              0.10924461524906,
              47.313421774727
            ],
            [
              0.11786940351405,
              47.325601141531
            ],
            [
              0.11745694898776,
              47.332342613969
            ],
            [
              0.13125388952305,
              47.33409233868
            ],
            [
              0.13898837615883,
              47.33824318552
            ],
            [
              0.14796868770319,
              47.348447803202
            ],
            [
              0.14165395029201,
              47.361961427199
            ],
            [
              0.15845843664067,
              47.366157059648
            ],
            [
              0.18296214025622,
              47.38033044936
            ],
            [
              0.16798792612309,
              47.386934066036
            ],
            [
              0.16948959021545,
              47.395646092388
            ],
            [
              0.15385793095636,
              47.398727021572
            ],
            [
              0.18138204745888,
              47.417824739063
            ],
            [
              0.18527899163678,
              47.424736116162
            ],
            [
              0.18093772230519,
              47.453404824236
            ],
            [
              0.18979781155354,
              47.460723327297
            ],
            [
              0.2007181726014,
              47.484545277708
            ],
            [
              0.22010664406864,
              47.50195233416
            ],
            [
              0.22008941253351,
              47.511490494119
            ],
            [
              0.22491484141757,
              47.52709951065
            ],
            [
              0.20807030184987,
              47.526432351568
            ],
            [
              0.2034912870347,
              47.533330991541
            ],
            [
              0.19334553772128,
              47.539118278038
            ],
            [
              0.20150198535452,
              47.544324126228
            ],
            [
              0.21510267043528,
              47.569975576615
            ],
            [
              0.23453049018557,
              47.57797744555
            ],
            [
              0.23000044283917,
              47.608397360802
            ]
          ]
        ]
      },
      "properties": {
        "code": "37",
        "nom": "Indre-et-Loire",
        "population": 612119,
        "delitspour1000": "4,328",
        "nombremosque": 9,
        "parentsEtrangers": "75,8",
        "benefRsa": 28581,
        "eoliennes": 16
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3.3613476310625,
              44.971408091786
            ],
            [
              3.3703185777492,
              44.96998390468
            ],
            [
              3.3713550433617,
              44.960250089988
            ],
            [
              3.3863677767162,
              44.952742049715
            ],
            [
              3.3969930219391,
              44.958577768116
            ],
            [
              3.4045530849223,
              44.956400427592
            ],
            [
              3.412832200087,
              44.944841611062
            ],
            [
              3.4149529202982,
              44.932631887178
            ],
            [
              3.413833246774,
              44.919859843574
            ],
            [
              3.4192590880448,
              44.908428284947
            ],
            [
              3.417976841379,
              44.900767433551
            ],
            [
              3.4357050101121,
              44.88024496334
            ],
            [
              3.4386254407679,
              44.86082184192
            ],
            [
              3.4416742987403,
              44.854449680958
            ],
            [
              3.4541989644633,
              44.844563734203
            ],
            [
              3.4570624789322,
              44.833777540962
            ],
            [
              3.4757711698307,
              44.815370629667
            ],
            [
              3.478809589921,
              44.809446829457
            ],
            [
              3.4909959832186,
              44.808474309867
            ],
            [
              3.4948664240388,
              44.815405935965
            ],
            [
              3.5056459727137,
              44.823846663279
            ],
            [
              3.5397515164373,
              44.828176022752
            ],
            [
              3.5615758039452,
              44.824976812591
            ],
            [
              3.5638205108562,
              44.832831791958
            ],
            [
              3.5745418907117,
              44.83460991769
            ],
            [
              3.578465058473,
              44.825696757681
            ],
            [
              3.5893552432085,
              44.832194014444
            ],
            [
              3.5920659249124,
              44.84796463101
            ],
            [
              3.5980348128056,
              44.859504969144
            ],
            [
              3.5943335024029,
              44.875433384901
            ],
            [
              3.6082755428246,
              44.879331218549
            ],
            [
              3.6147496711134,
              44.874174272057
            ],
            [
              3.6264517258551,
              44.880253899231
            ],
            [
              3.6423461696965,
              44.878382169756
            ],
            [
              3.6595224161061,
              44.86994134545
            ],
            [
              3.6704785324924,
              44.850864431429
            ],
            [
              3.6718167758914,
              44.843382404889
            ],
            [
              3.6574209674758,
              44.836124356852
            ],
            [
              3.6663623630222,
              44.828776303359
            ],
            [
              3.6954389564378,
              44.830958625259
            ],
            [
              3.7003857822671,
              44.836813194859
            ],
            [
              3.7228844822867,
              44.830986703517
            ],
            [
              3.7406494702404,
              44.838697356604
            ],
            [
              3.7526807081682,
              44.82097908395
            ],
            [
              3.7624285152172,
              44.810666360605
            ],
            [
              3.7587613653652,
              44.805576543778
            ],
            [
              3.7794867433517,
              44.79731064671
            ],
            [
              3.8032312316337,
              44.781579343618
            ],
            [
              3.8033308935883,
              44.770414179887
            ],
            [
              3.8121315885881,
              44.766032837865
            ],
            [
              3.8198835993274,
              44.772685233514
            ],
            [
              3.8301463157408,
              44.775710594028
            ],
            [
              3.841603226393,
              44.772129900378
            ],
            [
              3.8428722625525,
              44.767659869676
            ],
            [
              3.8304555521813,
              44.756161500223
            ],
            [
              3.8365590308337,
              44.747847636802
            ],
            [
              3.8625310211191,
              44.743866105932
            ],
            [
              3.8754615107331,
              44.740626944691
            ],
            [
              3.8762993159981,
              44.731800448649
            ],
            [
              3.8677916785686,
              44.728142672276
            ],
            [
              3.8615128126047,
              44.71201694644
            ],
            [
              3.8718913936808,
              44.705396507123
            ],
            [
              3.8696426954409,
              44.696962680173
            ],
            [
              3.8845476207292,
              44.697347879776
            ],
            [
              3.8732699495096,
              44.678201691122
            ],
            [
              3.882909471786,
              44.660088831897
            ],
            [
              3.8947423692172,
              44.651129115275
            ],
            [
              3.8926148288923,
              44.623030105672
            ],
            [
              3.8985315708048,
              44.613411802763
            ],
            [
              3.9083038945979,
              44.606615580322
            ],
            [
              3.90517074571,
              44.592708958301
            ],
            [
              3.9191671077331,
              44.585245092622
            ],
            [
              3.9207367872473,
              44.575746388916
            ],
            [
              3.9284831974258,
              44.569998826159
            ],
            [
              3.9451514993013,
              44.573790000095
            ],
            [
              3.9608749832371,
              44.554803732187
            ],
            [
              3.9657451606381,
              44.537497086144
            ],
            [
              3.9753657955514,
              44.523650580786
            ],
            [
              3.9766536412959,
              44.516689836275
            ],
            [
              3.9837035025138,
              44.510167633816
            ],
            [
              3.9873321701777,
              44.499162516354
            ],
            [
              3.985747412107,
              44.487924045953
            ],
            [
              3.9875441966947,
              44.473361271665
            ],
            [
              3.9981617468281,
              44.459798467391
            ],
            [
              3.9803685381006,
              44.445439528201
            ],
            [
              3.9723404746948,
              44.435190055138
            ],
            [
              3.9717196395416,
              44.426330703971
            ],
            [
              3.9611465990867,
              44.41396666902
            ],
            [
              3.9487327132043,
              44.412567267488
            ],
            [
              3.9512054176571,
              44.402087805603
            ],
            [
              3.9443586109158,
              44.399571136256
            ],
            [
              3.928309972795,
              44.405246750327
            ],
            [
              3.9079176676789,
              44.402622394702
            ],
            [
              3.9003564793452,
              44.392571240909
            ],
            [
              3.8859181180291,
              44.387999952482
            ],
            [
              3.8923994006905,
              44.381522694523
            ],
            [
              3.9110062523363,
              44.370599102941
            ],
            [
              3.9168436390416,
              44.359217742316
            ],
            [
              3.9281697506954,
              44.343854209304
            ],
            [
              3.9368786663002,
              44.33744108778
            ],
            [
              3.9491210893572,
              44.334921777321
            ],
            [
              3.9523300614322,
              44.328390007654
            ],
            [
              3.9402460617446,
              44.315418456243
            ],
            [
              3.9228602111024,
              44.30522063075
            ],
            [
              3.9362528722946,
              44.293490372509
            ],
            [
              3.9398232455058,
              44.283334544
            ],
            [
              3.9355738846832,
              44.274744298137
            ],
            [
              3.9454508454646,
              44.266865065977
            ],
            [
              3.956794913201,
              44.271797114433
            ],
            [
              3.9746483176338,
              44.260288859328
            ],
            [
              3.9719073243896,
              44.251641683621
            ],
            [
              3.9513133379367,
              44.247792762307
            ],
            [
              3.9461669523151,
              44.240169534193
            ],
            [
              3.950158512355,
              44.223544971563
            ],
            [
              3.9429654923739,
              44.203253484142
            ],
            [
              3.9370729983754,
              44.195595947902
            ],
            [
              3.9598822674885,
              44.191253623903
            ],
            [
              3.964288789173,
              44.177410315113
            ],
            [
              3.9765983159501,
              44.170098473016
            ],
            [
              3.9743738242883,
              44.164502268256
            ],
            [
              3.9526032918757,
              44.170797336987
            ],
            [
              3.9294485267929,
              44.181839944624
            ],
            [
              3.9219574771069,
              44.180320696004
            ],
            [
              3.9277400304228,
              44.171738630603
            ],
            [
              3.927270718019,
              44.161294730529
            ],
            [
              3.9119737331675,
              44.160158300398
            ],
            [
              3.9109075704101,
              44.150587101491
            ],
            [
              3.8948926937749,
              44.147795357416
            ],
            [
              3.8775632202183,
              44.132861161031
            ],
            [
              3.8621011695486,
              44.130069281932
            ],
            [
              3.8314321427191,
              44.137342309599
            ],
            [
              3.8223990436954,
              44.136197092175
            ],
            [
              3.8126533543601,
              44.128802494079
            ],
            [
              3.7969975612425,
              44.127385461306
            ],
            [
              3.7848382493422,
              44.133250032869
            ],
            [
              3.7756676373257,
              44.141877075928
            ],
            [
              3.7679675562321,
              44.14157393487
            ],
            [
              3.7622230201904,
              44.149516940162
            ],
            [
              3.7523693979032,
              44.150544498075
            ],
            [
              3.7349559744215,
              44.157010128066
            ],
            [
              3.7256274073379,
              44.164242504262
            ],
            [
              3.7025563945864,
              44.166942701712
            ],
            [
              3.6925750340144,
              44.171159275216
            ],
            [
              3.6786431948201,
              44.182760565003
            ],
            [
              3.6577912111121,
              44.173321183089
            ],
            [
              3.6496443307415,
              44.180846928062
            ],
            [
              3.6377741334314,
              44.175485314981
            ],
            [
              3.6278241155281,
              44.155028504469
            ],
            [
              3.6526725494152,
              44.145568354025
            ],
            [
              3.6358667337752,
              44.140266546314
            ],
            [
              3.6334911540563,
              44.122696230536
            ],
            [
              3.6073988183382,
              44.116029433901
            ],
            [
              3.595181405875,
              44.119266524352
            ],
            [
              3.5683752088908,
              44.122559965675
            ],
            [
              3.5464861213512,
              44.113818175271
            ],
            [
              3.5408020405971,
              44.118578938319
            ],
            [
              3.5231681435817,
              44.120516869251
            ],
            [
              3.5058482132245,
              44.126278514771
            ],
            [
              3.481403424764,
              44.124002502392
            ],
            [
              3.4713734276297,
              44.12716393223
            ],
            [
              3.4545515961192,
              44.126879446041
            ],
            [
              3.4397273942713,
              44.129098275979
            ],
            [
              3.433512295062,
              44.134421741262
            ],
            [
              3.430828214856,
              44.148000042739
            ],
            [
              3.4149305042071,
              44.153882151382
            ],
            [
              3.3916296064038,
              44.168932587934
            ],
            [
              3.3736480685005,
              44.170759472387
            ],
            [
              3.3722574402673,
              44.180111605641
            ],
            [
              3.3623083641294,
              44.188257426713
            ],
            [
              3.3577957669957,
              44.197913146727
            ],
            [
              3.3391826316079,
              44.202751760551
            ],
            [
              3.3123376202084,
              44.203071755455
            ],
            [
              3.2950065737753,
              44.206938814455
            ],
            [
              3.2847604708491,
              44.199421266759
            ],
            [
              3.2620383777632,
              44.199513868063
            ],
            [
              3.2403288585343,
              44.19083537067
            ],
            [
              3.2066091665582,
              44.189931038205
            ],
            [
              3.2227886841663,
              44.209610502596
            ],
            [
              3.2298513180005,
              44.226251406383
            ],
            [
              3.2235353426493,
              44.230170001588
            ],
            [
              3.1956785894385,
              44.240868744621
            ],
            [
              3.184224712712,
              44.247476467225
            ],
            [
              3.1638192967351,
              44.243869641892
            ],
            [
              3.1572068425878,
              44.250245796267
            ],
            [
              3.1599553535076,
              44.258596699063
            ],
            [
              3.1540845204064,
              44.273172636192
            ],
            [
              3.143570712599,
              44.274014987734
            ],
            [
              3.1383625050444,
              44.264142835763
            ],
            [
              3.1241560155353,
              44.260325302915
            ],
            [
              3.1252325845717,
              44.284463569435
            ],
            [
              3.1542553794088,
              44.308367530555
            ],
            [
              3.146528556073,
              44.320565113439
            ],
            [
              3.1517097885451,
              44.332414822245
            ],
            [
              3.1339016413735,
              44.332987029876
            ],
            [
              3.1275801635609,
              44.347699429055
            ],
            [
              3.131975205783,
              44.357172903459
            ],
            [
              3.1211237751057,
              44.361882416935
            ],
            [
              3.1225802362026,
              44.376236624007
            ],
            [
              3.1197174609736,
              44.383381712586
            ],
            [
              3.128709404669,
              44.389778090383
            ],
            [
              3.1405520936589,
              44.392614462133
            ],
            [
              3.1373105135272,
              44.408125009251
            ],
            [
              3.1267160037072,
              44.413956480342
            ],
            [
              3.1417277675188,
              44.428457291816
            ],
            [
              3.1360486132034,
              44.44476332926
            ],
            [
              3.1357398668406,
              44.454869326281
            ],
            [
              3.1246425493523,
              44.461312563753
            ],
            [
              3.117144504438,
              44.4739200421
            ],
            [
              3.1013824675044,
              44.47951694497
            ],
            [
              3.0774798083215,
              44.49407023418
            ],
            [
              3.0685440473718,
              44.503492403034
            ],
            [
              3.0765891765907,
              44.518061677636
            ],
            [
              3.0747004023127,
              44.533148056751
            ],
            [
              3.0835939007747,
              44.559017095166
            ],
            [
              3.0759029843264,
              44.572972781335
            ],
            [
              3.0604239335947,
              44.579596428744
            ],
            [
              3.0501049511157,
              44.590500887925
            ],
            [
              3.0331802950598,
              44.596905346963
            ],
            [
              3.0214179128995,
              44.608930242405
            ],
            [
              3.0123078930023,
              44.621912456139
            ],
            [
              2.981675726654,
              44.644673011362
            ],
            [
              2.981760369824,
              44.651994919411
            ],
            [
              2.9985742640224,
              44.674442966678
            ],
            [
              3.0160122019676,
              44.713692453341
            ],
            [
              3.0251405593834,
              44.716097238134
            ],
            [
              3.0391236076168,
              44.714995724501
            ],
            [
              3.0353535096078,
              44.727206782516
            ],
            [
              3.028416597051,
              44.73255744411
            ],
            [
              3.0310731845345,
              44.749413959606
            ],
            [
              3.0422427573327,
              44.75938114179
            ],
            [
              3.0503924394573,
              44.779809366102
            ],
            [
              3.0457487033386,
              44.799052030765
            ],
            [
              3.0615217992797,
              44.817172380452
            ],
            [
              3.0768587089093,
              44.824410436955
            ],
            [
              3.0714754944234,
              44.834124974928
            ],
            [
              3.0994806863618,
              44.833584813393
            ],
            [
              3.1001211680904,
              44.844373808687
            ],
            [
              3.0953252337809,
              44.85208399024
            ],
            [
              3.1050371687709,
              44.86639509115
            ],
            [
              3.1054945101096,
              44.886774806731
            ],
            [
              3.1229128915544,
              44.892733396595
            ],
            [
              3.1283803710941,
              44.903578216134
            ],
            [
              3.1410977876348,
              44.903315197313
            ],
            [
              3.1531732734567,
              44.893017216949
            ],
            [
              3.1690213470506,
              44.874091478783
            ],
            [
              3.1823170380273,
              44.863735423357
            ],
            [
              3.1991804637825,
              44.874642009283
            ],
            [
              3.2159619013053,
              44.875041006609
            ],
            [
              3.2252942367053,
              44.882045162686
            ],
            [
              3.2323433112231,
              44.891622050737
            ],
            [
              3.2269844428022,
              44.897602452938
            ],
            [
              3.2278135177439,
              44.909928387989
            ],
            [
              3.2496887102206,
              44.916235200562
            ],
            [
              3.245167534381,
              44.93480347612
            ],
            [
              3.2595403854401,
              44.942429341236
            ],
            [
              3.2615217326405,
              44.932067820131
            ],
            [
              3.2713373103277,
              44.927600109493
            ],
            [
              3.2860179111885,
              44.926382028761
            ],
            [
              3.2973059946128,
              44.935604538605
            ],
            [
              3.3379420512705,
              44.955906998959
            ],
            [
              3.3545315434466,
              44.9548199209
            ],
            [
              3.3613476310625,
              44.971408091786
            ]
          ]
        ]
      },
      "properties": {
        "code": "48",
        "nom": "Lozère",
        "population": 76633,
        "delitspour1000": "3,236",
        "nombremosque": 2,
        "parentsEtrangers": "82,6",
        "benefRsa": 1961,
        "eoliennes": 29
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.8746305174837,
              47.520424918568
            ],
            [
              2.8489012329621,
              47.53754134151
            ],
            [
              2.8451871650071,
              47.544932948997
            ],
            [
              2.8574859883322,
              47.552839253284
            ],
            [
              2.8661248696402,
              47.546368484983
            ],
            [
              2.8738950692802,
              47.55280954087
            ],
            [
              2.8907714985755,
              47.553102008094
            ],
            [
              2.9093721909559,
              47.559160555497
            ],
            [
              2.9142961197082,
              47.565971982014
            ],
            [
              2.9586515837228,
              47.55737826151
            ],
            [
              2.9643142632036,
              47.564408371297
            ],
            [
              2.9765353538906,
              47.569424295927
            ],
            [
              2.9875296099181,
              47.568963322468
            ],
            [
              3.0099789475349,
              47.560284494731
            ],
            [
              3.0256394695906,
              47.558753676789
            ],
            [
              3.03461087264,
              47.563962229793
            ],
            [
              3.0591969906863,
              47.56884905721
            ],
            [
              3.0805624202337,
              47.585489497365
            ],
            [
              3.1020032714546,
              47.587958865747
            ],
            [
              3.120738729187,
              47.578209745666
            ],
            [
              3.1162202729539,
              47.569244554269
            ],
            [
              3.1220506256694,
              47.541050819435
            ],
            [
              3.1388983089897,
              47.537834657581
            ],
            [
              3.1610555018698,
              47.524949345327
            ],
            [
              3.1651894180107,
              47.517498399368
            ],
            [
              3.1872116825743,
              47.52341602306
            ],
            [
              3.2046478507551,
              47.522967246062
            ],
            [
              3.227258607608,
              47.501297257437
            ],
            [
              3.2354836014321,
              47.489179997681
            ],
            [
              3.251061319522,
              47.48824819872
            ],
            [
              3.2786194274233,
              47.496933384766
            ],
            [
              3.2852475606666,
              47.503940436698
            ],
            [
              3.2971098501411,
              47.491879163894
            ],
            [
              3.3070499116063,
              47.494719529456
            ],
            [
              3.3463120491845,
              47.472435421254
            ],
            [
              3.3619513810285,
              47.485342328201
            ],
            [
              3.3818604374665,
              47.488897471453
            ],
            [
              3.3972879668438,
              47.508862995228
            ],
            [
              3.4269630402639,
              47.507417701239
            ],
            [
              3.4456297303673,
              47.511198743003
            ],
            [
              3.4590177645189,
              47.500770495683
            ],
            [
              3.4878750791397,
              47.493920811494
            ],
            [
              3.4901941094846,
              47.496821356122
            ],
            [
              3.4949537927697,
              47.526459212987
            ],
            [
              3.4923652081816,
              47.560128896705
            ],
            [
              3.5046096251729,
              47.557936911485
            ],
            [
              3.509300758364,
              47.552178039366
            ],
            [
              3.5135076422025,
              47.527667101917
            ],
            [
              3.5218208657468,
              47.522230076619
            ],
            [
              3.5399989778474,
              47.515656140061
            ],
            [
              3.5550836765038,
              47.50178014282
            ],
            [
              3.5806141829017,
              47.497865949973
            ],
            [
              3.5758753501346,
              47.482488154729
            ],
            [
              3.5800968902369,
              47.478574775509
            ],
            [
              3.5801093743194,
              47.464900736341
            ],
            [
              3.5870681198871,
              47.456189719915
            ],
            [
              3.595311510798,
              47.457000352478
            ],
            [
              3.6002843064236,
              47.46535750082
            ],
            [
              3.6178481856464,
              47.46475971622
            ],
            [
              3.6156845029023,
              47.457496632488
            ],
            [
              3.6604883896309,
              47.448290372222
            ],
            [
              3.6745779225073,
              47.449545490805
            ],
            [
              3.6789862107814,
              47.445536409296
            ],
            [
              3.6733668814352,
              47.435660360739
            ],
            [
              3.6837300861118,
              47.433876012809
            ],
            [
              3.7062094210682,
              47.421838077847
            ],
            [
              3.7147863473095,
              47.406836442513
            ],
            [
              3.7262902213831,
              47.398242621047
            ],
            [
              3.7405548374236,
              47.396496212065
            ],
            [
              3.7742634274104,
              47.405933574958
            ],
            [
              3.782475190451,
              47.399359950142
            ],
            [
              3.7856408128509,
              47.390542286733
            ],
            [
              3.8031761531432,
              47.387487658725
            ],
            [
              3.8184735032465,
              47.380186131386
            ],
            [
              3.8283342971606,
              47.382308415441
            ],
            [
              3.8298201650838,
              47.391646077629
            ],
            [
              3.8217810028721,
              47.402799666341
            ],
            [
              3.8456032412138,
              47.411769134174
            ],
            [
              3.8517283415933,
              47.427167259745
            ],
            [
              3.8515038925646,
              47.435582552834
            ],
            [
              3.8799100996303,
              47.429809529421
            ],
            [
              3.8923281117228,
              47.4191622459
            ],
            [
              3.8933359684125,
              47.410556196782
            ],
            [
              3.8767842133428,
              47.397282743871
            ],
            [
              3.8635262780677,
              47.395838910921
            ],
            [
              3.8599101177175,
              47.388688854649
            ],
            [
              3.8656326118794,
              47.382675860165
            ],
            [
              3.8714185455276,
              47.367349720507
            ],
            [
              3.8867653218202,
              47.364580537895
            ],
            [
              3.895717835648,
              47.374132179853
            ],
            [
              3.9087974428289,
              47.382052052892
            ],
            [
              3.9328934573278,
              47.379383639236
            ],
            [
              3.9442431429877,
              47.380215683342
            ],
            [
              3.9482193285937,
              47.393475824008
            ],
            [
              3.9706766676387,
              47.378316249635
            ],
            [
              3.9734249529811,
              47.372878988697
            ],
            [
              3.9653450094109,
              47.365233671604
            ],
            [
              3.9730885348498,
              47.33271185193
            ],
            [
              3.9992678352806,
              47.312769315752
            ],
            [
              4.0287589499002,
              47.315579997017
            ],
            [
              4.0310437574521,
              47.327153002575
            ],
            [
              4.0398534030515,
              47.327428274054
            ],
            [
              4.0501742581832,
              47.340237695425
            ],
            [
              4.0769427450309,
              47.340731753228
            ],
            [
              4.0926517884381,
              47.337435759593
            ],
            [
              4.1060805347332,
              47.339255135806
            ],
            [
              4.1134743533021,
              47.334688318727
            ],
            [
              4.1243850511743,
              47.344327328215
            ],
            [
              4.1299388379566,
              47.354003126117
            ],
            [
              4.1420339517931,
              47.353410405176
            ],
            [
              4.1248963853468,
              47.335861208174
            ],
            [
              4.1239214987083,
              47.330478638489
            ],
            [
              4.1303927866159,
              47.320210675601
            ],
            [
              4.1141074337361,
              47.307955306705
            ],
            [
              4.122836939735,
              47.303952068916
            ],
            [
              4.1145884091898,
              47.292607276243
            ],
            [
              4.1164294263749,
              47.276383540489
            ],
            [
              4.1245462831537,
              47.262131056758
            ],
            [
              4.1247217853652,
              47.251556580615
            ],
            [
              4.1354786120349,
              47.237397237251
            ],
            [
              4.1660112560791,
              47.249424576003
            ],
            [
              4.1780483980403,
              47.250291504021
            ],
            [
              4.1878705703316,
              47.244612665923
            ],
            [
              4.1912177541397,
              47.237009867039
            ],
            [
              4.2200708686147,
              47.215959778412
            ],
            [
              4.2306617272065,
              47.198946708221
            ],
            [
              4.2146280483505,
              47.183137693116
            ],
            [
              4.2082785647862,
              47.163849326423
            ],
            [
              4.2098316194928,
              47.155410810959
            ],
            [
              4.1989993858965,
              47.151177316078
            ],
            [
              4.1818950614264,
              47.150507773033
            ],
            [
              4.1373797132578,
              47.14270894322
            ],
            [
              4.1150660580207,
              47.146161181367
            ],
            [
              4.1164080703238,
              47.135392236331
            ],
            [
              4.1075949297499,
              47.12933629579
            ],
            [
              4.1159657137304,
              47.12333578884
            ],
            [
              4.1122626988576,
              47.118983845261
            ],
            [
              4.0975884447258,
              47.125732870471
            ],
            [
              4.0898927747246,
              47.116176880355
            ],
            [
              4.0614299224165,
              47.121038939248
            ],
            [
              4.0481739880777,
              47.109966250504
            ],
            [
              4.0537588244023,
              47.095036584078
            ],
            [
              4.0396752665903,
              47.086158384901
            ],
            [
              4.0387223667702,
              47.080008269353
            ],
            [
              4.0683361057481,
              47.060850648192
            ],
            [
              4.0720151548184,
              47.054495729639
            ],
            [
              4.0574940116794,
              47.038900365199
            ],
            [
              4.0606658400579,
              47.024972535492
            ],
            [
              4.0549441401071,
              47.012685678649
            ],
            [
              4.0479222180945,
              47.011126186359
            ],
            [
              4.0362502315426,
              47.001896052274
            ],
            [
              3.9951615578479,
              46.985310080182
            ],
            [
              3.9957316970127,
              46.974693752431
            ],
            [
              4.0144190745946,
              46.972477975289
            ],
            [
              4.0316347246235,
              46.984823155361
            ],
            [
              4.039402003189,
              46.985112713117
            ],
            [
              4.0438473804989,
              46.973978460165
            ],
            [
              4.0557200391713,
              46.956749646361
            ],
            [
              4.0408074643843,
              46.950628112044
            ],
            [
              4.0395312114589,
              46.941423698692
            ],
            [
              4.0450633703398,
              46.937742227613
            ],
            [
              4.0365379360006,
              46.927791049018
            ],
            [
              4.0372192814383,
              46.918152029371
            ],
            [
              4.0479296715449,
              46.899770717258
            ],
            [
              4.0635404678154,
              46.898637154276
            ],
            [
              4.0754488330482,
              46.891490462779
            ],
            [
              4.0938487569255,
              46.874735258475
            ],
            [
              4.1008830130886,
              46.859103716467
            ],
            [
              4.092715166052,
              46.858059566361
            ],
            [
              4.0753121505214,
              46.849236849001
            ],
            [
              4.0562344150701,
              46.843270576149
            ],
            [
              4.0423720361053,
              46.834119533114
            ],
            [
              4.0562525887395,
              46.822258972946
            ],
            [
              4.0627058296664,
              46.789361126398
            ],
            [
              4.0553247809739,
              46.778718863178
            ],
            [
              4.046288175844,
              46.779798308275
            ],
            [
              4.0396130376104,
              46.786197313589
            ],
            [
              4.0208649854129,
              46.777341720269
            ],
            [
              4.0038776137753,
              46.778670067159
            ],
            [
              3.9919552712787,
              46.776752031818
            ],
            [
              3.9632189412952,
              46.766027891145
            ],
            [
              3.9571797005846,
              46.756108388206
            ],
            [
              3.9429274242382,
              46.750836954649
            ],
            [
              3.9233963374575,
              46.738871738421
            ],
            [
              3.893374254748,
              46.732625851573
            ],
            [
              3.8862216978057,
              46.727368533327
            ],
            [
              3.8763512648567,
              46.732270769936
            ],
            [
              3.8697068751044,
              46.72642145442
            ],
            [
              3.8539645075768,
              46.725011529247
            ],
            [
              3.8446586531024,
              46.720905827955
            ],
            [
              3.8379042358968,
              46.712109191105
            ],
            [
              3.8261009793206,
              46.703917697238
            ],
            [
              3.8141986781304,
              46.705295808098
            ],
            [
              3.7973242309307,
              46.701499072057
            ],
            [
              3.7826230283356,
              46.736194804924
            ],
            [
              3.7508711255828,
              46.745679580207
            ],
            [
              3.7361900575316,
              46.752473902091
            ],
            [
              3.7041063465572,
              46.746923018232
            ],
            [
              3.6861311660273,
              46.741310406966
            ],
            [
              3.6620658803764,
              46.738060599572
            ],
            [
              3.6486303092589,
              46.742624134301
            ],
            [
              3.6388515179539,
              46.741431838165
            ],
            [
              3.6294223422648,
              46.749456328005
            ],
            [
              3.6201072037828,
              46.754059565827
            ],
            [
              3.6020398798222,
              46.751247280578
            ],
            [
              3.5977490685569,
              46.76202889412
            ],
            [
              3.5910525816638,
              46.762426406979
            ],
            [
              3.5806709648764,
              46.752741235279
            ],
            [
              3.5845515939144,
              46.739382605418
            ],
            [
              3.5971876767747,
              46.728401657187
            ],
            [
              3.598000652576,
              46.723983498355
            ],
            [
              3.5773137335153,
              46.714852724197
            ],
            [
              3.5503869458917,
              46.715861095646
            ],
            [
              3.548207507912,
              46.706166654281
            ],
            [
              3.5555591963708,
              46.695741177165
            ],
            [
              3.5543959772727,
              46.684731744078
            ],
            [
              3.5464728563281,
              46.678292894912
            ],
            [
              3.5300363962253,
              46.688813284083
            ],
            [
              3.5174930194471,
              46.683185185586
            ],
            [
              3.4871533162964,
              46.658256156349
            ],
            [
              3.4865354798384,
              46.65380880583
            ],
            [
              3.4572892729042,
              46.651760006926
            ],
            [
              3.4473385862025,
              46.663554460402
            ],
            [
              3.4535963621348,
              46.681987883569
            ],
            [
              3.4513122938683,
              46.689900877873
            ],
            [
              3.4329783660003,
              46.693340026098
            ],
            [
              3.4295672938027,
              46.702504791218
            ],
            [
              3.4341396009475,
              46.711907843672
            ],
            [
              3.4074108289121,
              46.711510582242
            ],
            [
              3.3877581200663,
              46.714818365449
            ],
            [
              3.3787310431309,
              46.71134766179
            ],
            [
              3.3754269760827,
              46.699410803841
            ],
            [
              3.3664449691016,
              46.691259477216
            ],
            [
              3.3467035850706,
              46.684418162056
            ],
            [
              3.3139651835149,
              46.688751611332
            ],
            [
              3.2988329664362,
              46.713590484885
            ],
            [
              3.2697959512206,
              46.71674181988
            ],
            [
              3.2551640362648,
              46.706289026218
            ],
            [
              3.2330363473519,
              46.697017662903
            ],
            [
              3.2155446436111,
              46.682892755029
            ],
            [
              3.1972601846791,
              46.679928148065
            ],
            [
              3.1631524413883,
              46.693541123032
            ],
            [
              3.1297797354937,
              46.727201530755
            ],
            [
              3.0839336875931,
              46.737632194007
            ],
            [
              3.0610780913415,
              46.749849808096
            ],
            [
              3.0490666680714,
              46.758080742151
            ],
            [
              3.0361449719957,
              46.776351034743
            ],
            [
              3.0368385012729,
              46.784399457513
            ],
            [
              3.0320629441459,
              46.794909389217
            ],
            [
              3.0374707304721,
              46.807786953663
            ],
            [
              3.0593510744061,
              46.827263935532
            ],
            [
              3.0545889127264,
              46.838765097797
            ],
            [
              3.0671018851119,
              46.847818120893
            ],
            [
              3.0680961068914,
              46.877680332057
            ],
            [
              3.0609590858964,
              46.898148846497
            ],
            [
              3.0514016137092,
              46.904955777225
            ],
            [
              3.0502954877584,
              46.910631265703
            ],
            [
              3.062210755789,
              46.927739529848
            ],
            [
              3.0648978058646,
              46.937079267447
            ],
            [
              3.0793324170792,
              46.955115150499
            ],
            [
              3.0717062160523,
              46.964340639457
            ],
            [
              3.0629299477088,
              46.981085018578
            ],
            [
              3.0655821306108,
              46.997351832387
            ],
            [
              3.0757442612943,
              47.019148176465
            ],
            [
              3.0748375004401,
              47.029815006019
            ],
            [
              3.0627453140392,
              47.04527634615
            ],
            [
              3.034525000237,
              47.056291089581
            ],
            [
              3.0232191913817,
              47.062612371142
            ],
            [
              3.0187160021753,
              47.070780884737
            ],
            [
              3.0211804845643,
              47.078232382085
            ],
            [
              3.0313737475376,
              47.092475725697
            ],
            [
              3.0280905152523,
              47.129064982977
            ],
            [
              3.0211980869457,
              47.136438093682
            ],
            [
              3.0159484799111,
              47.159093918463
            ],
            [
              3.0092852008476,
              47.179914660937
            ],
            [
              2.9972827962038,
              47.20166393803
            ],
            [
              2.9816886133559,
              47.221993356801
            ],
            [
              2.9779552353597,
              47.235552508643
            ],
            [
              2.9834001334309,
              47.259765880582
            ],
            [
              2.9739389096142,
              47.269804660206
            ],
            [
              2.9378619912637,
              47.287719383722
            ],
            [
              2.9264805753113,
              47.298618271978
            ],
            [
              2.907768482234,
              47.310991294935
            ],
            [
              2.8848764388062,
              47.316364164149
            ],
            [
              2.8765423657559,
              47.321934350315
            ],
            [
              2.8699481463917,
              47.338248316569
            ],
            [
              2.8734919391537,
              47.348396860493
            ],
            [
              2.8952492319567,
              47.372177057081
            ],
            [
              2.9008159931543,
              47.385192680229
            ],
            [
              2.9184549974743,
              47.405524882196
            ],
            [
              2.9198859465078,
              47.42134952529
            ],
            [
              2.930733371753,
              47.431324302158
            ],
            [
              2.9288970093188,
              47.444564932191
            ],
            [
              2.9209883066832,
              47.455435821811
            ],
            [
              2.9140606551793,
              47.470896834824
            ],
            [
              2.8980184987491,
              47.485252401408
            ],
            [
              2.8884586332585,
              47.50943098545
            ],
            [
              2.8746305174837,
              47.520424918568
            ]
          ]
        ]
      },
      "properties": {
        "code": "58",
        "nom": "Nièvre",
        "population": 202670,
        "delitspour1000": "3,622",
        "nombremosque": 5,
        "parentsEtrangers": "82,0",
        "benefRsa": 10905,
        "eoliennes": 23
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                2.9902257358935,
                49.210674578642
              ],
              [
                2.986692959255,
                49.218744676249
              ],
              [
                2.9977421484175,
                49.223581146472
              ],
              [
                3.0027558359081,
                49.218836179133
              ],
              [
                2.9902257358935,
                49.210674578642
              ]
            ]
          ],
          [
            [
              [
                1.7838342426767,
                49.758309270134
              ],
              [
                1.7999203021402,
                49.750609725952
              ],
              [
                1.8089729809968,
                49.75433151074
              ],
              [
                1.8204527833718,
                49.739051270304
              ],
              [
                1.8367369152631,
                49.740116319336
              ],
              [
                1.8395426143609,
                49.730914500449
              ],
              [
                1.8207632027639,
                49.722151536711
              ],
              [
                1.8371737595611,
                49.718269170518
              ],
              [
                1.8381013236399,
                49.708421968668
              ],
              [
                1.8489820073575,
                49.701631956735
              ],
              [
                1.865499402996,
                49.70397426338
              ],
              [
                1.8792381354282,
                49.698301790269
              ],
              [
                1.8938657876757,
                49.698984649558
              ],
              [
                1.9104738555541,
                49.709748892553
              ],
              [
                1.9332028186606,
                49.719633496535
              ],
              [
                1.9490957317415,
                49.700799437057
              ],
              [
                1.9599969956534,
                49.707667672637
              ],
              [
                1.964227071738,
                49.716176638285
              ],
              [
                1.9889198926498,
                49.709402970469
              ],
              [
                2.0014751956715,
                49.71333100656
              ],
              [
                2.0077539807641,
                49.708265729309
              ],
              [
                2.0267654945296,
                49.711306361766
              ],
              [
                2.0354858488082,
                49.709101413159
              ],
              [
                2.0497573943847,
                49.697721467949
              ],
              [
                2.0611437714349,
                49.692197134574
              ],
              [
                2.0501673115406,
                49.686524401131
              ],
              [
                2.0653010804938,
                49.683146860964
              ],
              [
                2.0813183676129,
                49.693482422881
              ],
              [
                2.0877171013921,
                49.690119446789
              ],
              [
                2.1034905780583,
                49.694739548769
              ],
              [
                2.1138229603813,
                49.69363624902
              ],
              [
                2.1214041672853,
                49.68807405176
              ],
              [
                2.1436950376524,
                49.694407195174
              ],
              [
                2.1564869379493,
                49.702113852562
              ],
              [
                2.1871266553278,
                49.702434613522
              ],
              [
                2.2087617463046,
                49.699756506081
              ],
              [
                2.2175604243823,
                49.702468676942
              ],
              [
                2.247165820093,
                49.701640859686
              ],
              [
                2.2498736926922,
                49.697144021683
              ],
              [
                2.2769463076105,
                49.691476057252
              ],
              [
                2.3018017866427,
                49.681077171813
              ],
              [
                2.3157228175868,
                49.68975570628
              ],
              [
                2.3320856006823,
                49.680991958513
              ],
              [
                2.3357195776342,
                49.670194582988
              ],
              [
                2.3513332231199,
                49.6634162158
              ],
              [
                2.3620706424931,
                49.666736720324
              ],
              [
                2.3731470912741,
                49.656262653184
              ],
              [
                2.3882526379825,
                49.655798604409
              ],
              [
                2.4097068690923,
                49.660994316822
              ],
              [
                2.4216356481784,
                49.657046546418
              ],
              [
                2.4456566594192,
                49.652976288486
              ],
              [
                2.4584901421278,
                49.634211696144
              ],
              [
                2.4694135852156,
                49.629117015474
              ],
              [
                2.4781157157449,
                49.620791102842
              ],
              [
                2.4892215944736,
                49.631617369662
              ],
              [
                2.5047475416297,
                49.639464567535
              ],
              [
                2.5213328607654,
                49.626993417349
              ],
              [
                2.5267251770806,
                49.629399487718
              ],
              [
                2.5470913559285,
                49.61585472181
              ],
              [
                2.5650187853668,
                49.618942322527
              ],
              [
                2.5595011033685,
                49.60560638451
              ],
              [
                2.5714988829262,
                49.597078849268
              ],
              [
                2.5805564397692,
                49.598551902534
              ],
              [
                2.5860669161311,
                49.606336034375
              ],
              [
                2.5974394042671,
                49.611412932776
              ],
              [
                2.6097479515677,
                49.608821866221
              ],
              [
                2.6240655802021,
                49.610230243392
              ],
              [
                2.6315512495142,
                49.599641079158
              ],
              [
                2.6277942663565,
                49.585396691219
              ],
              [
                2.6496341022379,
                49.571762327
              ],
              [
                2.6707176060094,
                49.581414652757
              ],
              [
                2.671244814592,
                49.598585836073
              ],
              [
                2.6745719967829,
                49.605458647174
              ],
              [
                2.6836012031895,
                49.610221916906
              ],
              [
                2.6891461156895,
                49.625604569913
              ],
              [
                2.7090829578704,
                49.625769842419
              ],
              [
                2.717722557286,
                49.623622741048
              ],
              [
                2.735669611905,
                49.625656233463
              ],
              [
                2.7575578775006,
                49.620967032176
              ],
              [
                2.7596875358898,
                49.617816192698
              ],
              [
                2.780954797057,
                49.613779313759
              ],
              [
                2.7994962764821,
                49.620589648566
              ],
              [
                2.7897672015361,
                49.638090767081
              ],
              [
                2.793605509577,
                49.655952413736
              ],
              [
                2.8138681658446,
                49.660051195246
              ],
              [
                2.8418372938007,
                49.659434814456
              ],
              [
                2.8501054632717,
                49.661734863215
              ],
              [
                2.8456390330805,
                49.670707819735
              ],
              [
                2.8539655663727,
                49.676060968853
              ],
              [
                2.8572606727357,
                49.683666684467
              ],
              [
                2.8878187574055,
                49.683329383346
              ],
              [
                2.8662035297843,
                49.702491469092
              ],
              [
                2.8842491853168,
                49.710638066104
              ],
              [
                2.8920131049684,
                49.698640812156
              ],
              [
                2.9157142028651,
                49.702669694334
              ],
              [
                2.9298715712778,
                49.688465550847
              ],
              [
                2.929804603745,
                49.676691750942
              ],
              [
                2.9404360464863,
                49.679243936743
              ],
              [
                2.9534435781073,
                49.66649717999
              ],
              [
                2.9552532997715,
                49.67884593624
              ],
              [
                2.9488409292444,
                49.692322577611
              ],
              [
                2.9709363993483,
                49.696888160979
              ],
              [
                2.9895904717799,
                49.70802401434
              ],
              [
                3.0044346383484,
                49.702703281842
              ],
              [
                3.007981175104,
                49.693392449748
              ],
              [
                3.0178423969505,
                49.690598727647
              ],
              [
                3.0261613710586,
                49.680295333003
              ],
              [
                3.0403344121757,
                49.692302385177
              ],
              [
                3.0542104980344,
                49.693657391146
              ],
              [
                3.0571255368829,
                49.707937379439
              ],
              [
                3.0629532474138,
                49.712572694325
              ],
              [
                3.0834622590288,
                49.712492975333
              ],
              [
                3.083934054211,
                49.692615286185
              ],
              [
                3.0994644018402,
                49.706367379871
              ],
              [
                3.1184045303955,
                49.705964066166
              ],
              [
                3.1255335157844,
                49.696069433869
              ],
              [
                3.1187864527405,
                49.679479989177
              ],
              [
                3.1273514963453,
                49.669560167436
              ],
              [
                3.0977803822632,
                49.656685783641
              ],
              [
                3.105375825455,
                49.650252757435
              ],
              [
                3.1127647616219,
                49.631326135242
              ],
              [
                3.0935808403797,
                49.622546795198
              ],
              [
                3.1220070868004,
                49.603737577403
              ],
              [
                3.1241709272179,
                49.596259591906
              ],
              [
                3.1398162780798,
                49.57623493299
              ],
              [
                3.1269766852551,
                49.573040797596
              ],
              [
                3.1308815178414,
                49.560954870117
              ],
              [
                3.1314748157048,
                49.543379455176
              ],
              [
                3.1265659892961,
                49.525832923061
              ],
              [
                3.1211645703147,
                49.520972777532
              ],
              [
                3.0963250899051,
                49.517898051892
              ],
              [
                3.0957755908947,
                49.513401263746
              ],
              [
                3.1157135714634,
                49.495470249313
              ],
              [
                3.1212851485729,
                49.493673600223
              ],
              [
                3.1223668133939,
                49.479725634739
              ],
              [
                3.111507578536,
                49.478133619658
              ],
              [
                3.1070840899002,
                49.4682268944
              ],
              [
                3.1537737685829,
                49.454687735882
              ],
              [
                3.1605791009846,
                49.451587071796
              ],
              [
                3.162641421643,
                49.440640583775
              ],
              [
                3.1318803099746,
                49.431774433431
              ],
              [
                3.1242876947876,
                49.431509228
              ],
              [
                3.1106058774191,
                49.438533183311
              ],
              [
                3.1002115987113,
                49.438914335705
              ],
              [
                3.0937848436209,
                49.433744170658
              ],
              [
                3.0975424327567,
                49.40939400907
              ],
              [
                3.0909145961187,
                49.390282654605
              ],
              [
                3.0953847473833,
                49.37712087468
              ],
              [
                3.0767803399143,
                49.37126184005
              ],
              [
                3.0747273511678,
                49.348968273056
              ],
              [
                3.0631119002401,
                49.345444957541
              ],
              [
                3.0574496739207,
                49.339763177491
              ],
              [
                3.0429149637046,
                49.33357220157
              ],
              [
                3.0290880762466,
                49.332568651576
              ],
              [
                3.0015090894942,
                49.340015930567
              ],
              [
                2.9930869272272,
                49.332919830663
              ],
              [
                2.9733141470747,
                49.325928985608
              ],
              [
                2.9658317712153,
                49.321018144109
              ],
              [
                2.9775408677882,
                49.312143172732
              ],
              [
                2.9743958158612,
                49.302336152604
              ],
              [
                2.9845022912805,
                49.297533071562
              ],
              [
                2.9945965377278,
                49.286553379039
              ],
              [
                3.0055759130959,
                49.292782149864
              ],
              [
                3.0145702351452,
                49.288949208489
              ],
              [
                3.0275713264634,
                49.289504284364
              ],
              [
                3.0376248562025,
                49.286391219561
              ],
              [
                3.0361477494116,
                49.278798239715
              ],
              [
                3.0268892663569,
                49.275528461066
              ],
              [
                3.0334825568838,
                49.241267868854
              ],
              [
                3.0399323397122,
                49.22616188589
              ],
              [
                3.0297209221315,
                49.225332359297
              ],
              [
                3.0216640503059,
                49.216204659573
              ],
              [
                3.0131790827414,
                49.222865738436
              ],
              [
                3.0154745880308,
                49.22954684904
              ],
              [
                3.0027814460118,
                49.235691549882
              ],
              [
                2.964764519701,
                49.232581305692
              ],
              [
                2.9624508927558,
                49.219995477274
              ],
              [
                2.9706449654112,
                49.214292631137
              ],
              [
                2.9677470468385,
                49.19164819014
              ],
              [
                2.9837256661752,
                49.184192997915
              ],
              [
                2.9921221288327,
                49.192676248441
              ],
              [
                2.998243025829,
                49.186263672994
              ],
              [
                3.0170006922748,
                49.178367998523
              ],
              [
                3.0229815457334,
                49.169940216174
              ],
              [
                3.0377737043933,
                49.170466926044
              ],
              [
                3.0350745943294,
                49.183610008704
              ],
              [
                3.0167739909071,
                49.189744487659
              ],
              [
                3.0021369671079,
                49.187948463381
              ],
              [
                3.0004940244253,
                49.213628237896
              ],
              [
                3.0187891123212,
                49.216625879159
              ],
              [
                3.0295260439912,
                49.213365706523
              ],
              [
                3.0355815792129,
                49.204875545425
              ],
              [
                3.0520068685133,
                49.206619970092
              ],
              [
                3.0640834030942,
                49.192737678861
              ],
              [
                3.0811795124662,
                49.193710914115
              ],
              [
                3.0868718810072,
                49.184455859741
              ],
              [
                3.1027163729533,
                49.197259135143
              ],
              [
                3.1168545661605,
                49.189287113624
              ],
              [
                3.1138989977692,
                49.175101247493
              ],
              [
                3.1213544037116,
                49.16156497702
              ],
              [
                3.1444075970975,
                49.16203222082
              ],
              [
                3.1396565141513,
                49.152575157098
              ],
              [
                3.124211701736,
                49.14683712186
              ],
              [
                3.1110332982117,
                49.145808870649
              ],
              [
                3.1142073015392,
                49.138321068061
              ],
              [
                3.0948631029742,
                49.133233769039
              ],
              [
                3.0885774087327,
                49.124772726183
              ],
              [
                3.0718801833502,
                49.11755332218
              ],
              [
                3.0563165652026,
                49.101913557338
              ],
              [
                3.0573553400185,
                49.093990044235
              ],
              [
                3.0483557770377,
                49.08631637936
              ],
              [
                3.0327819280137,
                49.089067566229
              ],
              [
                3.0295246754031,
                49.085932257529
              ],
              [
                3.0085155423352,
                49.091506561321
              ],
              [
                2.9912365557999,
                49.084010529465
              ],
              [
                2.9879964190903,
                49.072371853401
              ],
              [
                2.9747449093505,
                49.074788481937
              ],
              [
                2.9704735929189,
                49.088974001698
              ],
              [
                2.9445842533756,
                49.081604590578
              ],
              [
                2.9174506912143,
                49.079815930085
              ],
              [
                2.9015078822282,
                49.085373143453
              ],
              [
                2.8949485303642,
                49.077063202013
              ],
              [
                2.8830747436616,
                49.076071279686
              ],
              [
                2.8662678657136,
                49.070693981918
              ],
              [
                2.8557424844029,
                49.070260111043
              ],
              [
                2.8451483788669,
                49.084656494049
              ],
              [
                2.8360889289489,
                49.082987383853
              ],
              [
                2.8198502647929,
                49.087367188293
              ],
              [
                2.8090528505052,
                49.097537605404
              ],
              [
                2.7918697099795,
                49.090226188638
              ],
              [
                2.787073783912,
                49.07527418833
              ],
              [
                2.7604244593128,
                49.06296236294
              ],
              [
                2.7350099332564,
                49.060452516659
              ],
              [
                2.7324165014354,
                49.069856383944
              ],
              [
                2.7204862280103,
                49.074886920193
              ],
              [
                2.7061794650059,
                49.06530744517
              ],
              [
                2.6909952426863,
                49.066575827238
              ],
              [
                2.6921954156417,
                49.072098782916
              ],
              [
                2.6848013380994,
                49.081070149491
              ],
              [
                2.6676934960697,
                49.092495236068
              ],
              [
                2.6332770991263,
                49.108372635105
              ],
              [
                2.6205306041593,
                49.095141144665
              ],
              [
                2.6103345312027,
                49.09494039704
              ],
              [
                2.6072121818235,
                49.089061593648
              ],
              [
                2.5905283926735,
                49.079653961607
              ],
              [
                2.5833038141204,
                49.080706309944
              ],
              [
                2.5786858654184,
                49.091952842127
              ],
              [
                2.5581755098966,
                49.098381756011
              ],
              [
                2.5520149054273,
                49.113947081753
              ],
              [
                2.5561550654469,
                49.118627938332
              ],
              [
                2.5408087122315,
                49.122242313794
              ],
              [
                2.5329944482474,
                49.119245162084
              ],
              [
                2.5310585957685,
                49.099597191318
              ],
              [
                2.4899295573932,
                49.106359373937
              ],
              [
                2.5020961933805,
                49.118887475459
              ],
              [
                2.4992321189629,
                49.122269747269
              ],
              [
                2.4817070713716,
                49.126919463544
              ],
              [
                2.4615068862157,
                49.135936921998
              ],
              [
                2.4354018020913,
                49.133939040206
              ],
              [
                2.4409039786288,
                49.14580411257
              ],
              [
                2.4153544805398,
                49.151762413963
              ],
              [
                2.3912847262891,
                49.1493504084
              ],
              [
                2.383003341928,
                49.155913004846
              ],
              [
                2.370937678429,
                49.159173609651
              ],
              [
                2.3592998527471,
                49.147343950086
              ],
              [
                2.3462625342946,
                49.161815854345
              ],
              [
                2.3222626753655,
                49.180859736345
              ],
              [
                2.3109304401404,
                49.186404768181
              ],
              [
                2.3011998615285,
                49.183887047533
              ],
              [
                2.2994229533516,
                49.17583535835
              ],
              [
                2.2888064839605,
                49.170710377425
              ],
              [
                2.2862682227562,
                49.160277559835
              ],
              [
                2.2695453788487,
                49.156306628677
              ],
              [
                2.2622091200851,
                49.158297463054
              ],
              [
                2.2524813607229,
                49.152881479913
              ],
              [
                2.2230049777895,
                49.151869445673
              ],
              [
                2.2163546658105,
                49.154378017348
              ],
              [
                2.2354095182703,
                49.167035850124
              ],
              [
                2.2186458815721,
                49.180685270888
              ],
              [
                2.204958905762,
                49.174316445929
              ],
              [
                2.1819527991076,
                49.173675958457
              ],
              [
                2.1642743368285,
                49.166004802028
              ],
              [
                2.157743439339,
                49.172599185593
              ],
              [
                2.1645707336419,
                49.179671393215
              ],
              [
                2.1327402600127,
                49.191379169415
              ],
              [
                2.1133369040576,
                49.186543761789
              ],
              [
                2.0968798927064,
                49.189757147704
              ],
              [
                2.0915398506152,
                49.204360444701
              ],
              [
                2.0808850919985,
                49.209773929407
              ],
              [
                2.066842351201,
                49.202860855132
              ],
              [
                2.0380917348167,
                49.192136044555
              ],
              [
                2.0217858750717,
                49.188671770161
              ],
              [
                2.0045021739488,
                49.177609556506
              ],
              [
                1.996823915181,
                49.176453415327
              ],
              [
                1.9734431640564,
                49.18389441504
              ],
              [
                1.9607171629873,
                49.173517298241
              ],
              [
                1.9480171420872,
                49.170749195544
              ],
              [
                1.931476176782,
                49.17416772145
              ],
              [
                1.894536229716,
                49.166040538631
              ],
              [
                1.8852348791181,
                49.162636028533
              ],
              [
                1.877042152092,
                49.171448857663
              ],
              [
                1.8455433838221,
                49.169896687357
              ],
              [
                1.8370818782543,
                49.171698986367
              ],
              [
                1.8265758315348,
                49.179516758866
              ],
              [
                1.8143979645085,
                49.176162362964
              ],
              [
                1.7954740881562,
                49.18526277584
              ],
              [
                1.7909791787819,
                49.179854330047
              ],
              [
                1.7775554860529,
                49.184104883007
              ],
              [
                1.7551705903258,
                49.174545969477
              ],
              [
                1.7421415990654,
                49.180151725276
              ],
              [
                1.7374357068856,
                49.194603115202
              ],
              [
                1.7258249045161,
                49.195712707137
              ],
              [
                1.7155761288215,
                49.203118037941
              ],
              [
                1.7230394537953,
                49.20967924589
              ],
              [
                1.7336823766846,
                49.210958623685
              ],
              [
                1.7341091784733,
                49.221277890399
              ],
              [
                1.729662120604,
                49.22919730317
              ],
              [
                1.7043588288241,
                49.232197221792
              ],
              [
                1.699042864415,
                49.234864303276
              ],
              [
                1.7014183926808,
                49.252460272045
              ],
              [
                1.7104820360249,
                49.264465989242
              ],
              [
                1.7547213954529,
                49.270007807429
              ],
              [
                1.7643549601555,
                49.263082374151
              ],
              [
                1.7668935671793,
                49.252034345086
              ],
              [
                1.7895339086741,
                49.247870747839
              ],
              [
                1.7918059477748,
                49.256427629819
              ],
              [
                1.8026740663848,
                49.271947858898
              ],
              [
                1.7932298788283,
                49.274330698204
              ],
              [
                1.796488015906,
                49.284111717893
              ],
              [
                1.7756247174004,
                49.29969367954
              ],
              [
                1.7676000740408,
                49.315889978292
              ],
              [
                1.7727704938425,
                49.333136370328
              ],
              [
                1.7576982127616,
                49.356710604078
              ],
              [
                1.7594132403395,
                49.36814533573
              ],
              [
                1.747580532548,
                49.37311965741
              ],
              [
                1.74121389808,
                49.381720558637
              ],
              [
                1.7203128039946,
                49.39483019677
              ],
              [
                1.7401057248725,
                49.405314790651
              ],
              [
                1.7139306567281,
                49.409224915677
              ],
              [
                1.7207550257388,
                49.421790744656
              ],
              [
                1.7227989638177,
                49.433057939568
              ],
              [
                1.7325062090143,
                49.440346816415
              ],
              [
                1.7375744776703,
                49.448744348943
              ],
              [
                1.7512558538526,
                49.452315507953
              ],
              [
                1.7477144178281,
                49.459538435118
              ],
              [
                1.7663409121222,
                49.466149422943
              ],
              [
                1.7758155194349,
                49.475733185704
              ],
              [
                1.7741026603547,
                49.484110404638
              ],
              [
                1.7874800747665,
                49.493410636962
              ],
              [
                1.79022549105,
                49.503468309638
              ],
              [
                1.7718616459246,
                49.512848245546
              ],
              [
                1.7579593630441,
                49.508955782237
              ],
              [
                1.7437037722862,
                49.495260515275
              ],
              [
                1.7387428193977,
                49.499646659524
              ],
              [
                1.7254532581616,
                49.499781817922
              ],
              [
                1.7192339956449,
                49.508224831919
              ],
              [
                1.7309415306325,
                49.516127433413
              ],
              [
                1.7451293471854,
                49.531735853612
              ],
              [
                1.7446099134771,
                49.539601433398
              ],
              [
                1.727181881084,
                49.541625548343
              ],
              [
                1.7296224523484,
                49.561514722426
              ],
              [
                1.7147772726003,
                49.576403299976
              ],
              [
                1.7216170199559,
                49.588805803542
              ],
              [
                1.7094013142782,
                49.586713688098
              ],
              [
                1.7079026298891,
                49.59512101877
              ],
              [
                1.6951880268931,
                49.599725188693
              ],
              [
                1.7045833964597,
                49.60539714358
              ],
              [
                1.7216033847573,
                49.621992077342
              ],
              [
                1.7172522158626,
                49.633071609694
              ],
              [
                1.7046286111408,
                49.639706031745
              ],
              [
                1.7034537142049,
                49.645178379124
              ],
              [
                1.72256553641,
                49.661514170983
              ],
              [
                1.7251990095943,
                49.672636996929
              ],
              [
                1.7521845944048,
                49.680960782507
              ],
              [
                1.7506387449271,
                49.693804769865
              ],
              [
                1.7375343076769,
                49.700350145116
              ],
              [
                1.7214282927506,
                49.691450681445
              ],
              [
                1.7177073271231,
                49.684373173475
              ],
              [
                1.7045184506731,
                49.68092815534
              ],
              [
                1.6895744511517,
                49.694787428191
              ],
              [
                1.7119859825186,
                49.707713850498
              ],
              [
                1.7151877298233,
                49.712819627841
              ],
              [
                1.7121688003106,
                49.731959147918
              ],
              [
                1.7232343899458,
                49.730085190671
              ],
              [
                1.7409502199257,
                49.738588067086
              ],
              [
                1.7416291636029,
                49.751631106006
              ],
              [
                1.7471451565537,
                49.757054329335
              ],
              [
                1.7838342426767,
                49.758309270134
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "60",
        "nom": "Oise",
        "population": 829699,
        "delitspour1000": "4,774",
        "nombremosque": 46,
        "parentsEtrangers": "72,4",
        "benefRsa": 41911,
        "eoliennes": 260
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -0.24283300101136,
              43.584979016847
            ],
            [
              -0.23502870973138,
              43.583357698142
            ],
            [
              -0.22423605594959,
              43.590402846839
            ],
            [
              -0.21060415101777,
              43.593234551989
            ],
            [
              -0.20474309579199,
              43.583597668054
            ],
            [
              -0.184802186901,
              43.591150649921
            ],
            [
              -0.17641684233457,
              43.596401195938
            ],
            [
              -0.16044860312899,
              43.580574768792
            ],
            [
              -0.14809699734982,
              43.585796179458
            ],
            [
              -0.12891801130162,
              43.581224268549
            ],
            [
              -0.12160532728785,
              43.586239621765
            ],
            [
              -0.10893257406459,
              43.582372914877
            ],
            [
              -0.096787871742487,
              43.582404857482
            ],
            [
              -0.093425031634788,
              43.563569921638
            ],
            [
              -0.088934220496639,
              43.557203940272
            ],
            [
              -0.094969201412123,
              43.548141189515
            ],
            [
              -0.088671332923909,
              43.542057336851
            ],
            [
              -0.078385830359136,
              43.546822915717
            ],
            [
              -0.064394904836236,
              43.545147116286
            ],
            [
              -0.056174185273693,
              43.533029097587
            ],
            [
              -0.044654576654926,
              43.525299051263
            ],
            [
              -0.040135444460287,
              43.512604399189
            ],
            [
              -0.049321056921324,
              43.492468050259
            ],
            [
              -0.034676452145955,
              43.487323780123
            ],
            [
              -0.033960155813221,
              43.47488114734
            ],
            [
              -0.01781381554075,
              43.472147456522
            ],
            [
              -0.019322496256827,
              43.466531436068
            ],
            [
              -0.065757532784034,
              43.463483610747
            ],
            [
              -0.061666974828373,
              43.452452940742
            ],
            [
              -0.06902834281151,
              43.437345744695
            ],
            [
              -0.057818804728216,
              43.427453948482
            ],
            [
              -0.053836151217704,
              43.418937636756
            ],
            [
              -0.062025455126023,
              43.417738498202
            ],
            [
              -0.066460558394679,
              43.411733865017
            ],
            [
              -0.042752022413969,
              43.410434732548
            ],
            [
              -0.034594805202059,
              43.429064287986
            ],
            [
              -0.024647492248551,
              43.430441863151
            ],
            [
              -0.016529018418498,
              43.443944372555
            ],
            [
              -0.0009763127221442,
              43.444398882283
            ],
            [
              -0.004617224617132,
              43.431760209363
            ],
            [
              0.0095923341693579,
              43.422106177396
            ],
            [
              -0.0038111619353702,
              43.398395088127
            ],
            [
              0.0051784236678809,
              43.394194111086
            ],
            [
              -0.0052544098304965,
              43.373757650344
            ],
            [
              0.0057814385418996,
              43.363002124921
            ],
            [
              0.023777202110468,
              43.349140741399
            ],
            [
              0.02629551293813,
              43.341364458979
            ],
            [
              0.010318205390046,
              43.325314740811
            ],
            [
              -0.0032544572879219,
              43.332106223092
            ],
            [
              -0.024874069651994,
              43.329492363991
            ],
            [
              -0.031830518124419,
              43.31215055589
            ],
            [
              -0.046122559308833,
              43.300858489621
            ],
            [
              -0.044392167069364,
              43.285272051797
            ],
            [
              -0.023979443826928,
              43.280211811856
            ],
            [
              -0.017008526259885,
              43.270448647152
            ],
            [
              -0.02562371129178,
              43.260991374913
            ],
            [
              -0.02368673812988,
              43.254986403176
            ],
            [
              -0.037586102171492,
              43.242416639333
            ],
            [
              -0.045868384089441,
              43.23213534366
            ],
            [
              -0.049462179860127,
              43.217180833465
            ],
            [
              -0.07252723387475,
              43.224387038006
            ],
            [
              -0.06786899220915,
              43.177118135342
            ],
            [
              -0.095496012787098,
              43.177197303166
            ],
            [
              -0.097529225654089,
              43.166624966901
            ],
            [
              -0.10448219117427,
              43.166691440804
            ],
            [
              -0.11133185839731,
              43.179338583668
            ],
            [
              -0.11770126761715,
              43.180323067986
            ],
            [
              -0.12655319090013,
              43.160447394144
            ],
            [
              -0.13859988604461,
              43.149103535932
            ],
            [
              -0.14050344828237,
              43.136172128164
            ],
            [
              -0.14613588573303,
              43.128233156333
            ],
            [
              -0.16634162368399,
              43.121884731119
            ],
            [
              -0.17086902572159,
              43.113421493828
            ],
            [
              -0.19098051290629,
              43.111202819975
            ],
            [
              -0.19768215446413,
              43.098173160871
            ],
            [
              -0.18693584923102,
              43.091163577327
            ],
            [
              -0.18776795009335,
              43.083330185944
            ],
            [
              -0.19776766479894,
              43.071150876661
            ],
            [
              -0.19915084290716,
              43.064412767264
            ],
            [
              -0.18964711095987,
              43.052021067576
            ],
            [
              -0.20860119763472,
              43.039724486019
            ],
            [
              -0.2239464237385,
              43.033684092022
            ],
            [
              -0.23941952476886,
              43.039676815812
            ],
            [
              -0.25993739842031,
              43.038277556214
            ],
            [
              -0.25606972495919,
              43.022726945323
            ],
            [
              -0.26484031266067,
              43.009957412551
            ],
            [
              -0.28772006609469,
              43.005371765683
            ],
            [
              -0.29154703187727,
              42.987768864423
            ],
            [
              -0.28647438097301,
              42.960054130137
            ],
            [
              -0.27938737996819,
              42.942120483339
            ],
            [
              -0.28159219915593,
              42.933563651361
            ],
            [
              -0.29003653813459,
              42.929087564699
            ],
            [
              -0.29777949713226,
              42.930973352505
            ],
            [
              -0.30871758220081,
              42.924636095621
            ],
            [
              -0.31074643857214,
              42.919004799168
            ],
            [
              -0.3270823405503,
              42.915785217713
            ],
            [
              -0.32459076680338,
              42.905260440304
            ],
            [
              -0.31819431138324,
              42.897761911545
            ],
            [
              -0.31566756931289,
              42.884801825113
            ],
            [
              -0.30749089647919,
              42.867843636256
            ],
            [
              -0.3134440845287,
              42.84937502201
            ],
            [
              -0.32341849261751,
              42.835493251193
            ],
            [
              -0.34754374621365,
              42.835913719016
            ],
            [
              -0.35580444046932,
              42.830259858153
            ],
            [
              -0.36426705242731,
              42.816951541187
            ],
            [
              -0.38297282239921,
              42.807183511438
            ],
            [
              -0.39259734345566,
              42.799559432268
            ],
            [
              -0.40923041576119,
              42.807751018183
            ],
            [
              -0.41891986574729,
              42.805668392726
            ],
            [
              -0.4397463890216,
              42.796464192609
            ],
            [
              -0.45955289533088,
              42.805069160235
            ],
            [
              -0.47286478572541,
              42.808471966291
            ],
            [
              -0.50965055176559,
              42.825391497677
            ],
            [
              -0.52482251446902,
              42.81169739145
            ],
            [
              -0.52287827401621,
              42.799049409362
            ],
            [
              -0.52973082529989,
              42.791532352653
            ],
            [
              -0.54380966630498,
              42.793156705649
            ],
            [
              -0.55120490858256,
              42.777515930774
            ],
            [
              -0.57059337631889,
              42.782911889725
            ],
            [
              -0.56471356204106,
              42.798018672268
            ],
            [
              -0.56886605101147,
              42.806822105003
            ],
            [
              -0.57774890534764,
              42.807226210864
            ],
            [
              -0.59273144202498,
              42.803229806499
            ],
            [
              -0.60076025043393,
              42.806307889858
            ],
            [
              -0.59869384234063,
              42.81658386823
            ],
            [
              -0.603635315569,
              42.832617711388
            ],
            [
              -0.62218648418028,
              42.839974285074
            ],
            [
              -0.636155774525,
              42.853243576651
            ],
            [
              -0.64886350304795,
              42.85519927328
            ],
            [
              -0.66146517128628,
              42.863780807704
            ],
            [
              -0.66446867746396,
              42.872433683564
            ],
            [
              -0.67603386909387,
              42.882121412448
            ],
            [
              -0.69899318990985,
              42.879656093293
            ],
            [
              -0.72094493959924,
              42.888648555303
            ],
            [
              -0.72975658553822,
              42.896558450267
            ],
            [
              -0.73499495012597,
              42.912174326638
            ],
            [
              -0.72506592676777,
              42.923031036303
            ],
            [
              -0.73250802453043,
              42.928418305337
            ],
            [
              -0.73095444655538,
              42.938774411246
            ],
            [
              -0.73924496462727,
              42.947642219182
            ],
            [
              -0.75163409617224,
              42.966937274035
            ],
            [
              -0.75637833414362,
              42.96842723143
            ],
            [
              -0.78780828558188,
              42.964223855906
            ],
            [
              -0.80004531168212,
              42.958965798484
            ],
            [
              -0.81001402324725,
              42.951435944964
            ],
            [
              -0.83838735696631,
              42.953541926019
            ],
            [
              -0.86785079295099,
              42.951831318684
            ],
            [
              -0.88394140902026,
              42.955914739589
            ],
            [
              -0.8955520171068,
              42.955177348594
            ],
            [
              -0.89972551604999,
              42.961947365792
            ],
            [
              -0.91428689185959,
              42.963568880303
            ],
            [
              -0.92198470791062,
              42.955086159393
            ],
            [
              -0.94645511377364,
              42.954058399843
            ],
            [
              -0.96116019688124,
              42.960780979343
            ],
            [
              -0.97519106253852,
              42.963790655966
            ],
            [
              -0.98075578416446,
              42.970298449556
            ],
            [
              -1.000506176719,
              42.977947402167
            ],
            [
              -1.0064490904565,
              42.988992019109
            ],
            [
              -1.017553501514,
              42.994381215156
            ],
            [
              -1.0308132030489,
              42.993442586204
            ],
            [
              -1.0702604908545,
              42.997696781882
            ],
            [
              -1.0830662265877,
              43.001676885789
            ],
            [
              -1.0918797435203,
              43.011282098743
            ],
            [
              -1.1106631611518,
              43.020497969179
            ],
            [
              -1.1189184669033,
              43.019962290375
            ],
            [
              -1.1334518490705,
              43.010370716056
            ],
            [
              -1.1425678944125,
              43.01020623111
            ],
            [
              -1.1482628224762,
              43.026042549327
            ],
            [
              -1.166902843708,
              43.035572700603
            ],
            [
              -1.1807284627612,
              43.032529650113
            ],
            [
              -1.2086270779223,
              43.047643271789
            ],
            [
              -1.2129701620528,
              43.051464997176
            ],
            [
              -1.2311469598971,
              43.054494771203
            ],
            [
              -1.2471955274996,
              43.042411024375
            ],
            [
              -1.2642864994766,
              43.044562906927
            ],
            [
              -1.2695665359652,
              43.052910976258
            ],
            [
              -1.2870369506636,
              43.063010309896
            ],
            [
              -1.3085440571134,
              43.068678772612
            ],
            [
              -1.3085964987154,
              43.072598494799
            ],
            [
              -1.2986655700641,
              43.0932795305
            ],
            [
              -1.270184195429,
              43.11856931563
            ],
            [
              -1.3206170406021,
              43.112750854275
            ],
            [
              -1.332008952146,
              43.107918007228
            ],
            [
              -1.3432982771448,
              43.095380034809
            ],
            [
              -1.3418161470064,
              43.077659473659
            ],
            [
              -1.345424430823,
              43.049771385789
            ],
            [
              -1.3547517961039,
              43.028468046718
            ],
            [
              -1.4351873583595,
              43.045028123845
            ],
            [
              -1.4435857863974,
              43.049155754363
            ],
            [
              -1.4717368566926,
              43.081096614267
            ],
            [
              -1.4708680288881,
              43.091714158473
            ],
            [
              -1.4642318751712,
              43.097527189934
            ],
            [
              -1.441119428077,
              43.108354777765
            ],
            [
              -1.424133734518,
              43.12496310203
            ],
            [
              -1.4139466293353,
              43.129178212008
            ],
            [
              -1.4125668315502,
              43.137780243699
            ],
            [
              -1.4160249876204,
              43.149739804939
            ],
            [
              -1.407219151478,
              43.155624496506
            ],
            [
              -1.4033775513995,
              43.164177642842
            ],
            [
              -1.4020191004086,
              43.177869670121
            ],
            [
              -1.3838251967031,
              43.190817108926
            ],
            [
              -1.3852679894359,
              43.197620432311
            ],
            [
              -1.3833596738329,
              43.217727485119
            ],
            [
              -1.3780657425747,
              43.229613623477
            ],
            [
              -1.3827864939261,
              43.253297008751
            ],
            [
              -1.4051202210621,
              43.270440718404
            ],
            [
              -1.41320553569,
              43.27341516617
            ],
            [
              -1.427533858751,
              43.266986624133
            ],
            [
              -1.4388526778219,
              43.266517244479
            ],
            [
              -1.4665458254283,
              43.272575506034
            ],
            [
              -1.4962163703281,
              43.283459630748
            ],
            [
              -1.5053259721679,
              43.29278751177
            ],
            [
              -1.531891014245,
              43.29350768549
            ],
            [
              -1.5518199451379,
              43.291690896031
            ],
            [
              -1.5647157911934,
              43.28791347967
            ],
            [
              -1.5581453439146,
              43.276923368272
            ],
            [
              -1.5679664491027,
              43.258218706628
            ],
            [
              -1.5751249050756,
              43.249838487966
            ],
            [
              -1.599409166844,
              43.254529607491
            ],
            [
              -1.6089315872346,
              43.252129248888
            ],
            [
              -1.6224113687594,
              43.263949698914
            ],
            [
              -1.6303661025897,
              43.284448371284
            ],
            [
              -1.6226028674474,
              43.300623373179
            ],
            [
              -1.6238145722959,
              43.305851119398
            ],
            [
              -1.6354249085658,
              43.309047121538
            ],
            [
              -1.6436698253027,
              43.306683425588
            ],
            [
              -1.6548641244247,
              43.312638352013
            ],
            [
              -1.6657592290595,
              43.314078692272
            ],
            [
              -1.6841024926201,
              43.309624250299
            ],
            [
              -1.7031331583996,
              43.309207236385
            ],
            [
              -1.7135211355733,
              43.300322980778
            ],
            [
              -1.729748923648,
              43.295678859867
            ],
            [
              -1.7355452024048,
              43.310342175974
            ],
            [
              -1.741348809523,
              43.316753723039
            ],
            [
              -1.7373522231501,
              43.329700042997
            ],
            [
              -1.7465925689614,
              43.331212245199
            ],
            [
              -1.75771288788,
              43.344008101532
            ],
            [
              -1.7754875064062,
              43.343951352243
            ],
            [
              -1.7877317304935,
              43.35420208724
            ],
            [
              -1.7841561370583,
              43.36247717302
            ],
            [
              -1.7908870919282,
              43.373441337557
            ],
            [
              -1.774636662773,
              43.372994899351
            ],
            [
              -1.7620222428489,
              43.375887316087
            ],
            [
              -1.7522896327775,
              43.386696189176
            ],
            [
              -1.7378897690031,
              43.381658311189
            ],
            [
              -1.6868172953384,
              43.396262202116
            ],
            [
              -1.6814639058051,
              43.390043159813
            ],
            [
              -1.6697675811644,
              43.388034474703
            ],
            [
              -1.6607413607041,
              43.393409282177
            ],
            [
              -1.6631432214875,
              43.399286135975
            ],
            [
              -1.6577077165394,
              43.405452954463
            ],
            [
              -1.6391136986966,
              43.408363439748
            ],
            [
              -1.6255054979111,
              43.421748443502
            ],
            [
              -1.6097350227992,
              43.42673810418
            ],
            [
              -1.5973883731702,
              43.437492657411
            ],
            [
              -1.5948860178815,
              43.448345590744
            ],
            [
              -1.589536901573,
              43.449746746172
            ],
            [
              -1.5774914365789,
              43.464140403007
            ],
            [
              -1.5667182835573,
              43.481676419031
            ],
            [
              -1.5480656499135,
              43.495969105934
            ],
            [
              -1.5273009710877,
              43.523223729778
            ],
            [
              -1.524870110434,
              43.52969939564
            ],
            [
              -1.5014092597602,
              43.526946087265
            ],
            [
              -1.4864407317263,
              43.535917796619
            ],
            [
              -1.4766158822802,
              43.53918061625
            ],
            [
              -1.4610306381761,
              43.53267584155
            ],
            [
              -1.464106404649,
              43.519781645046
            ],
            [
              -1.437498587448,
              43.513057753307
            ],
            [
              -1.4237603104542,
              43.506861858957
            ],
            [
              -1.4177765658159,
              43.497766825248
            ],
            [
              -1.3865353810935,
              43.496860806076
            ],
            [
              -1.3679063796964,
              43.49490201152
            ],
            [
              -1.3274938217375,
              43.504919561322
            ],
            [
              -1.2990049402612,
              43.501646422756
            ],
            [
              -1.291185807586,
              43.498202631439
            ],
            [
              -1.266594497459,
              43.510210224082
            ],
            [
              -1.2173154229473,
              43.53073725789
            ],
            [
              -1.195611423068,
              43.546096138824
            ],
            [
              -1.1708199002333,
              43.545608985466
            ],
            [
              -1.1595115855818,
              43.538661567429
            ],
            [
              -1.1560556323662,
              43.530479015428
            ],
            [
              -1.1330248441299,
              43.520785769377
            ],
            [
              -1.1315717835651,
              43.510693826661
            ],
            [
              -1.1652255750741,
              43.508212943175
            ],
            [
              -1.1700585048391,
              43.493509086399
            ],
            [
              -1.1625734868298,
              43.491539100586
            ],
            [
              -1.1501264316056,
              43.495522100096
            ],
            [
              -1.1475771656484,
              43.487949116697
            ],
            [
              -1.1387523187543,
              43.490338882334
            ],
            [
              -1.139591150382,
              43.497427824295
            ],
            [
              -1.1195961100638,
              43.501506974386
            ],
            [
              -1.1092463381421,
              43.514126348508
            ],
            [
              -1.0887252051976,
              43.511932891362
            ],
            [
              -1.0808322166668,
              43.522864721293
            ],
            [
              -1.0726086009342,
              43.517800005846
            ],
            [
              -1.0689958539289,
              43.508450037933
            ],
            [
              -1.0524449935051,
              43.512192578437
            ],
            [
              -1.0345255535077,
              43.509488088986
            ],
            [
              -1.0234014341645,
              43.502116209812
            ],
            [
              -1.0128682325145,
              43.50521471428
            ],
            [
              -0.99338967006359,
              43.505385487649
            ],
            [
              -0.9891692817436,
              43.529704697224
            ],
            [
              -0.99913834875978,
              43.534227442337
            ],
            [
              -0.9933158275928,
              43.53975988063
            ],
            [
              -0.97582774719898,
              43.539280811782
            ],
            [
              -0.95842887322335,
              43.533480633229
            ],
            [
              -0.9462865006441,
              43.532393540517
            ],
            [
              -0.9356342065974,
              43.537880329289
            ],
            [
              -0.92658256065212,
              43.532019680462
            ],
            [
              -0.92119880363379,
              43.543435292616
            ],
            [
              -0.91145313787906,
              43.548621966575
            ],
            [
              -0.89669293487472,
              43.550486431111
            ],
            [
              -0.85563676804624,
              43.541908586996
            ],
            [
              -0.8428108491379,
              43.543414990067
            ],
            [
              -0.84267007937447,
              43.553556657096
            ],
            [
              -0.82804656881185,
              43.559963954351
            ],
            [
              -0.81602499311976,
              43.560967548653
            ],
            [
              -0.80403569022774,
              43.556297334381
            ],
            [
              -0.79416546191616,
              43.563959990597
            ],
            [
              -0.78178287297118,
              43.56416527552
            ],
            [
              -0.78244276358796,
              43.576711739301
            ],
            [
              -0.76827845568514,
              43.579485229847
            ],
            [
              -0.7500250589555,
              43.564537857863
            ],
            [
              -0.73409021010799,
              43.555745751845
            ],
            [
              -0.72886235487275,
              43.545433771178
            ],
            [
              -0.71538850529851,
              43.541111448682
            ],
            [
              -0.70754926487636,
              43.557056799782
            ],
            [
              -0.69899646958725,
              43.560965144779
            ],
            [
              -0.6880133200732,
              43.556757948034
            ],
            [
              -0.67080359304887,
              43.566718895185
            ],
            [
              -0.64318640735324,
              43.574382351643
            ],
            [
              -0.65828413899537,
              43.560563803045
            ],
            [
              -0.65350576643198,
              43.556459978666
            ],
            [
              -0.63469699256641,
              43.553174601583
            ],
            [
              -0.61569432209824,
              43.544395690015
            ],
            [
              -0.61540683919424,
              43.538593241598
            ],
            [
              -0.59633617275098,
              43.541110501269
            ],
            [
              -0.58356996740704,
              43.545809317732
            ],
            [
              -0.56707553935391,
              43.558215434064
            ],
            [
              -0.55716948921661,
              43.542797337202
            ],
            [
              -0.53325372555522,
              43.551600869729
            ],
            [
              -0.52567004034342,
              43.557198237539
            ],
            [
              -0.51059854784715,
              43.563208667442
            ],
            [
              -0.5076573284959,
              43.569230833001
            ],
            [
              -0.46643781655372,
              43.589527662306
            ],
            [
              -0.44828752670584,
              43.596092979229
            ],
            [
              -0.43177477127389,
              43.586329386204
            ],
            [
              -0.43573841309122,
              43.578719987038
            ],
            [
              -0.4473305213702,
              43.57320368242
            ],
            [
              -0.44766172416032,
              43.566800679225
            ],
            [
              -0.46343337473017,
              43.566591187727
            ],
            [
              -0.45481179766081,
              43.557808637311
            ],
            [
              -0.45203283532481,
              43.550437447846
            ],
            [
              -0.4377177064614,
              43.552811121858
            ],
            [
              -0.40603700249402,
              43.568225793838
            ],
            [
              -0.37547824639828,
              43.566100585769
            ],
            [
              -0.35315441281094,
              43.561479352225
            ],
            [
              -0.34831088646202,
              43.555511486368
            ],
            [
              -0.33596232349939,
              43.550854218394
            ],
            [
              -0.32523012233832,
              43.555876337268
            ],
            [
              -0.30369299211166,
              43.560277673965
            ],
            [
              -0.30119925309298,
              43.571304000549
            ],
            [
              -0.29076337158252,
              43.568931929625
            ],
            [
              -0.28421867458898,
              43.579397069588
            ],
            [
              -0.27639878289495,
              43.584757375032
            ],
            [
              -0.25922900106539,
              43.588885282933
            ],
            [
              -0.2541382782442,
              43.582747851696
            ],
            [
              -0.24283300101136,
              43.584979016847
            ]
          ],
          [
            [
              -0.10221657949985,
              43.358514651885
            ],
            [
              -0.090143282581254,
              43.358859352353
            ],
            [
              -0.086786928155415,
              43.336872044844
            ],
            [
              -0.096783660691415,
              43.334763115112
            ],
            [
              -0.10847946239493,
              43.337801770075
            ],
            [
              -0.11566381713828,
              43.330976950464
            ],
            [
              -0.11139515774132,
              43.315808605005
            ],
            [
              -0.10412308826692,
              43.312464324807
            ],
            [
              -0.075041474866062,
              43.307136096993
            ],
            [
              -0.070279283945182,
              43.317455182599
            ],
            [
              -0.062503063827367,
              43.346712050556
            ],
            [
              -0.065396680791735,
              43.35504948237
            ],
            [
              -0.076422702553633,
              43.364562190447
            ],
            [
              -0.09095912750824,
              43.37316253716
            ],
            [
              -0.10728332222542,
              43.370734939225
            ],
            [
              -0.10221657949985,
              43.358514651885
            ]
          ],
          [
            [
              -0.10307051080042,
              43.242819142542
            ],
            [
              -0.099320994112155,
              43.252127129181
            ],
            [
              -0.092111707678552,
              43.252065563512
            ],
            [
              -0.079840951222932,
              43.262366418014
            ],
            [
              -0.079283735332789,
              43.271656452017
            ],
            [
              -0.096173699573616,
              43.285481158687
            ],
            [
              -0.092031063800833,
              43.300469260619
            ],
            [
              -0.11185367186851,
              43.31039465909
            ],
            [
              -0.11997934072059,
              43.306250495105
            ],
            [
              -0.12616476378979,
              43.293876660784
            ],
            [
              -0.13650575288846,
              43.284504952764
            ],
            [
              -0.14061503281768,
              43.271941230583
            ],
            [
              -0.13346480059307,
              43.258458798265
            ],
            [
              -0.12215124145212,
              43.243893908194
            ],
            [
              -0.10307051080042,
              43.242819142542
            ]
          ]
        ]
      },
      "properties": {
        "code": "64",
        "nom": "Pyrénées-Atlantiques",
        "population": 687240,
        "delitspour1000": "4,084",
        "nombremosque": 7,
        "parentsEtrangers": "80,6",
        "benefRsa": 27044
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.3880744564659,
              46.219790380732
            ],
            [
              4.388291015925,
              46.247956431405
            ],
            [
              4.3920466116785,
              46.263026545141
            ],
            [
              4.4058135314858,
              46.296057806589
            ],
            [
              4.4218715520166,
              46.294954263085
            ],
            [
              4.4272337977412,
              46.302730717527
            ],
            [
              4.4397213287914,
              46.293184568466
            ],
            [
              4.4583704273073,
              46.296976724079
            ],
            [
              4.4765696377244,
              46.284237867232
            ],
            [
              4.488465816487,
              46.287994462282
            ],
            [
              4.5039940949336,
              46.267132463094
            ],
            [
              4.5372643385622,
              46.269911483268
            ],
            [
              4.5464477974735,
              46.27391839397
            ],
            [
              4.5480762222411,
              46.282864938182
            ],
            [
              4.5577956308141,
              46.294551640092
            ],
            [
              4.5698487572768,
              46.293352201901
            ],
            [
              4.5726687726239,
              46.277139335718
            ],
            [
              4.5865068000033,
              46.268694622582
            ],
            [
              4.6185580580588,
              46.264793895574
            ],
            [
              4.6220235259619,
              46.270079226484
            ],
            [
              4.6183224944202,
              46.282440402941
            ],
            [
              4.6314517777677,
              46.289015276562
            ],
            [
              4.6352614072065,
              46.299289507242
            ],
            [
              4.6546591822967,
              46.303484822155
            ],
            [
              4.6693820675804,
              46.297910092985
            ],
            [
              4.6794340760587,
              46.303994122044
            ],
            [
              4.6931098569714,
              46.302197476983
            ],
            [
              4.694515991108,
              46.292672565821
            ],
            [
              4.7075411259083,
              46.284660070276
            ],
            [
              4.7076872577415,
              46.269647917424
            ],
            [
              4.6878811178807,
              46.265311972919
            ],
            [
              4.6795502516132,
              46.258666565339
            ],
            [
              4.6883618181605,
              46.25011389523
            ],
            [
              4.7028290155173,
              46.251330439429
            ],
            [
              4.7357666167041,
              46.234244688808
            ],
            [
              4.7326605831699,
              46.227019799221
            ],
            [
              4.7206237625559,
              46.222390986056
            ],
            [
              4.7356928657613,
              46.211923783014
            ],
            [
              4.72266470068,
              46.202320489849
            ],
            [
              4.7243706747794,
              46.184304976244
            ],
            [
              4.7305257874621,
              46.178368585861
            ],
            [
              4.7597597705914,
              46.172840600415
            ],
            [
              4.7608053446831,
              46.175968780182
            ],
            [
              4.7802082627383,
              46.176676203678
            ],
            [
              4.7842423740016,
              46.168398418477
            ],
            [
              4.8023781362741,
              46.156887423266
            ],
            [
              4.7974397118441,
              46.140702262151
            ],
            [
              4.7721518838355,
              46.118071984418
            ],
            [
              4.7711001932505,
              46.110444027243
            ],
            [
              4.7503200135049,
              46.094147345645
            ],
            [
              4.7482215731583,
              46.083210841607
            ],
            [
              4.7553305070909,
              46.080178266249
            ],
            [
              4.7616829876026,
              46.06883528189
            ],
            [
              4.7551690115181,
              46.05749901174
            ],
            [
              4.7395251280198,
              46.047211663829
            ],
            [
              4.7456554596239,
              46.035244267196
            ],
            [
              4.7440006438304,
              46.018176939023
            ],
            [
              4.7486001387369,
              46.009662778088
            ],
            [
              4.7516684157566,
              45.981108689225
            ],
            [
              4.7546311649847,
              45.973618413908
            ],
            [
              4.7484568614429,
              45.962409970129
            ],
            [
              4.729097034294,
              45.949095813426
            ],
            [
              4.7361797864484,
              45.938458980345
            ],
            [
              4.754211604612,
              45.934832922052
            ],
            [
              4.7717568016091,
              45.939225276574
            ],
            [
              4.781303944214,
              45.934878026573
            ],
            [
              4.7885569134338,
              45.923963932223
            ],
            [
              4.8058607348054,
              45.921560609717
            ],
            [
              4.8107708680666,
              45.908896224362
            ],
            [
              4.810260610989,
              45.897565111704
            ],
            [
              4.8348142747029,
              45.908769920371
            ],
            [
              4.8480301401278,
              45.912976466425
            ],
            [
              4.8645060523469,
              45.904617380238
            ],
            [
              4.8738314226335,
              45.893675005333
            ],
            [
              4.8806970810747,
              45.897164902283
            ],
            [
              4.8838320029013,
              45.879991282684
            ],
            [
              4.909010250355,
              45.874107574527
            ],
            [
              4.9012146769683,
              45.860333149898
            ],
            [
              4.916118270017,
              45.845459412501
            ],
            [
              4.9214939050883,
              45.83268452568
            ],
            [
              4.918157197021,
              45.809867431045
            ],
            [
              4.9237976894917,
              45.803994890806
            ],
            [
              4.943768407874,
              45.80953114067
            ],
            [
              4.9630797552526,
              45.81097149163
            ],
            [
              4.9816090942072,
              45.805451195255
            ],
            [
              4.9993378554409,
              45.810073041206
            ],
            [
              5.0163667700796,
              45.805572507594
            ],
            [
              5.0367172235658,
              45.807850969043
            ],
            [
              5.051058929079,
              45.812167330171
            ],
            [
              5.0661170051758,
              45.809506527915
            ],
            [
              5.101068799884,
              45.813378958875
            ],
            [
              5.0893113585792,
              45.784275046022
            ],
            [
              5.0611720639467,
              45.791532339886
            ],
            [
              5.0595234976359,
              45.782545469588
            ],
            [
              5.0701066801383,
              45.765478936083
            ],
            [
              5.093096251464,
              45.766087895262
            ],
            [
              5.089379542274,
              45.74965948259
            ],
            [
              5.0945254918509,
              45.739450682898
            ],
            [
              5.1226621095427,
              45.737811043006
            ],
            [
              5.1339368816918,
              45.733254459565
            ],
            [
              5.1481741409716,
              45.718787127083
            ],
            [
              5.1592030475156,
              45.714572205586
            ],
            [
              5.1430567075695,
              45.70002864286
            ],
            [
              5.1310549291459,
              45.707712334796
            ],
            [
              5.1190903977565,
              45.699708184787
            ],
            [
              5.1048861162621,
              45.700378923523
            ],
            [
              5.1081345828339,
              45.688028491968
            ],
            [
              5.0942617281168,
              45.682878871985
            ],
            [
              5.0888320304231,
              45.67702542226
            ],
            [
              5.0773503823499,
              45.67469665728
            ],
            [
              5.0542255730691,
              45.660136259749
            ],
            [
              5.0580570584302,
              45.653246417357
            ],
            [
              5.044542399742,
              45.647272778433
            ],
            [
              5.0351462071679,
              45.637203894595
            ],
            [
              5.0436466037904,
              45.621332111414
            ],
            [
              5.0380861620656,
              45.615067173692
            ],
            [
              5.004740525885,
              45.62311302936
            ],
            [
              4.9894470341835,
              45.618525768193
            ],
            [
              5.0019895947765,
              45.61395165469
            ],
            [
              4.9979604182227,
              45.603410458278
            ],
            [
              4.9719782401764,
              45.612682039538
            ],
            [
              4.9603757463763,
              45.610073577685
            ],
            [
              4.936077557335,
              45.608840645632
            ],
            [
              4.9275304060187,
              45.605707819264
            ],
            [
              4.9014588479373,
              45.60626328191
            ],
            [
              4.8927769429114,
              45.601555646906
            ],
            [
              4.8821976306935,
              45.601571961358
            ],
            [
              4.8729020897275,
              45.595302229166
            ],
            [
              4.8597792137897,
              45.590828133064
            ],
            [
              4.8577266463597,
              45.5795777754
            ],
            [
              4.810043763556,
              45.589552751649
            ],
            [
              4.8034791539416,
              45.587537956161
            ],
            [
              4.7771292183341,
              45.587395458322
            ],
            [
              4.7820837628775,
              45.580581569271
            ],
            [
              4.8086931363098,
              45.572300631782
            ],
            [
              4.8311024088019,
              45.547770728452
            ],
            [
              4.8401221979115,
              45.543293629833
            ],
            [
              4.8644436168106,
              45.537346005002
            ],
            [
              4.8728116747039,
              45.531305267791
            ],
            [
              4.8684816431075,
              45.523437511407
            ],
            [
              4.841259965977,
              45.500603648061
            ],
            [
              4.8272424509878,
              45.49628139378
            ],
            [
              4.8120086150016,
              45.483343833967
            ],
            [
              4.7822796715264,
              45.472208128185
            ],
            [
              4.7794738263144,
              45.45503324486
            ],
            [
              4.7569316195483,
              45.455703808946
            ],
            [
              4.7399125598478,
              45.472150707566
            ],
            [
              4.7433101731851,
              45.474764896895
            ],
            [
              4.7394098424085,
              45.486593747758
            ],
            [
              4.7223405808585,
              45.494295691224
            ],
            [
              4.7002191965255,
              45.478249654895
            ],
            [
              4.6819265003418,
              45.479805150655
            ],
            [
              4.6617482375007,
              45.486752644296
            ],
            [
              4.6551392825647,
              45.486304493343
            ],
            [
              4.6506024356174,
              45.49710900433
            ],
            [
              4.6543426246071,
              45.499750539461
            ],
            [
              4.6494323830973,
              45.51712905461
            ],
            [
              4.650497265707,
              45.528252850314
            ],
            [
              4.6613903387752,
              45.536037070395
            ],
            [
              4.674488312844,
              45.550479461372
            ],
            [
              4.6837344760466,
              45.55253624936
            ],
            [
              4.6873997607785,
              45.558523925089
            ],
            [
              4.6831387073324,
              45.565644636168
            ],
            [
              4.6699440512117,
              45.570285378667
            ],
            [
              4.6585848075301,
              45.56377146269
            ],
            [
              4.6492789007153,
              45.56490904439
            ],
            [
              4.6478824204468,
              45.556792876108
            ],
            [
              4.6589149161667,
              45.542658451096
            ],
            [
              4.6447024494625,
              45.540521160907
            ],
            [
              4.636944001114,
              45.545633161464
            ],
            [
              4.6251877380181,
              45.569055361339
            ],
            [
              4.614861970331,
              45.575470989539
            ],
            [
              4.6028640886661,
              45.570404449666
            ],
            [
              4.5877499129831,
              45.570573911416
            ],
            [
              4.5686010114212,
              45.583468098473
            ],
            [
              4.5528567342845,
              45.578486287879
            ],
            [
              4.5428213574395,
              45.579838520111
            ],
            [
              4.5197664342597,
              45.573253603438
            ],
            [
              4.5047759790565,
              45.576543545812
            ],
            [
              4.5051531322954,
              45.581744190937
            ],
            [
              4.4838595815624,
              45.58624940097
            ],
            [
              4.4679838181681,
              45.586688341581
            ],
            [
              4.4744464613895,
              45.594709488515
            ],
            [
              4.454234347968,
              45.604183406335
            ],
            [
              4.4404819320115,
              45.623477104497
            ],
            [
              4.4102790829383,
              45.632049970336
            ],
            [
              4.3926780038356,
              45.653703042225
            ],
            [
              4.3916724723063,
              45.662900921407
            ],
            [
              4.3659250305716,
              45.67124520038
            ],
            [
              4.3666896828173,
              45.685045108392
            ],
            [
              4.3732834826114,
              45.691495316239
            ],
            [
              4.3655162451168,
              45.698520895445
            ],
            [
              4.384861183026,
              45.721388505488
            ],
            [
              4.3930262500122,
              45.73962196206
            ],
            [
              4.402874533303,
              45.744322736901
            ],
            [
              4.3912519410447,
              45.754931122336
            ],
            [
              4.3648245203449,
              45.749517040539
            ],
            [
              4.3502759881234,
              45.765572799369
            ],
            [
              4.3493594526122,
              45.771303606196
            ],
            [
              4.3591428768023,
              45.780984046301
            ],
            [
              4.3758660518349,
              45.78207558241
            ],
            [
              4.3779742385557,
              45.790373451489
            ],
            [
              4.3736933831237,
              45.805064418906
            ],
            [
              4.3884958699132,
              45.806032530629
            ],
            [
              4.3762210046271,
              45.824638493705
            ],
            [
              4.3835765208093,
              45.826844321257
            ],
            [
              4.3906037050232,
              45.837471017303
            ],
            [
              4.3833781565992,
              45.847457660114
            ],
            [
              4.39318791688,
              45.85490602736
            ],
            [
              4.3937657765842,
              45.865177816896
            ],
            [
              4.3830283320289,
              45.863990304997
            ],
            [
              4.3573105997855,
              45.867819950961
            ],
            [
              4.3421582115897,
              45.877180049661
            ],
            [
              4.3383805732565,
              45.886651647406
            ],
            [
              4.3239325275487,
              45.900497659693
            ],
            [
              4.3454237850592,
              45.915370885136
            ],
            [
              4.3380440076381,
              45.921621376175
            ],
            [
              4.3437483577496,
              45.931115680498
            ],
            [
              4.3225089205335,
              45.940414442089
            ],
            [
              4.3068244792521,
              45.945360003465
            ],
            [
              4.2939148881952,
              45.953401305103
            ],
            [
              4.3008529759177,
              45.957960852692
            ],
            [
              4.2980954426863,
              45.965658535725
            ],
            [
              4.288248100938,
              45.973613541516
            ],
            [
              4.2751720909979,
              45.979779420897
            ],
            [
              4.2534923621873,
              45.983659900377
            ],
            [
              4.2480467270692,
              45.986721755059
            ],
            [
              4.243469905983,
              45.997155157363
            ],
            [
              4.2762354842316,
              45.993440504556
            ],
            [
              4.291857109907,
              45.995195243694
            ],
            [
              4.3005636506591,
              45.991309437399
            ],
            [
              4.3125045374594,
              46.005590580996
            ],
            [
              4.3069346878253,
              46.012302407533
            ],
            [
              4.3060931313608,
              46.021227299478
            ],
            [
              4.2931321166524,
              46.020034909779
            ],
            [
              4.2779133191973,
              46.022570733364
            ],
            [
              4.2621612945195,
              46.035394464095
            ],
            [
              4.25428598242,
              46.052728453285
            ],
            [
              4.2804122607347,
              46.052946216253
            ],
            [
              4.2970729797104,
              46.063367890941
            ],
            [
              4.3109599658762,
              46.079190537296
            ],
            [
              4.309496394554,
              46.110310117939
            ],
            [
              4.3209316069531,
              46.121673075435
            ],
            [
              4.3215705432747,
              46.129223594176
            ],
            [
              4.3349408525378,
              46.135557948536
            ],
            [
              4.3426667635704,
              46.134431163894
            ],
            [
              4.3615871317803,
              46.138458636435
            ],
            [
              4.3703360612818,
              46.147436195393
            ],
            [
              4.3802731178293,
              46.150002449909
            ],
            [
              4.4107792053657,
              46.136621688891
            ],
            [
              4.421942123828,
              46.142446470167
            ],
            [
              4.4264818352348,
              46.152802675294
            ],
            [
              4.433065036007,
              46.157315762201
            ],
            [
              4.4385869916472,
              46.16787797859
            ],
            [
              4.4161119485317,
              46.179768496894
            ],
            [
              4.406360379933,
              46.182287943556
            ],
            [
              4.3959933191533,
              46.189137102955
            ],
            [
              4.3962361504561,
              46.195567283506
            ],
            [
              4.422617863137,
              46.202554255239
            ],
            [
              4.4218122058978,
              46.208599447092
            ],
            [
              4.4085006257224,
              46.217963185953
            ],
            [
              4.3880744564659,
              46.219790380732
            ]
          ]
        ]
      },
      "properties": {
        "code": "69",
        "nom": "Rhône",
        "population": 1883437,
        "delitspour1000": "5,914",
        "nombremosque": 92,
        "parentsEtrangers": "61,1",
        "benefRsa": 100607,
        "eoliennes": 8
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.1159657137304,
              47.12333578884
            ],
            [
              4.1268220876418,
              47.130636271215
            ],
            [
              4.1474863428302,
              47.113936096115
            ],
            [
              4.1581472848576,
              47.121236525737
            ],
            [
              4.1656108131479,
              47.119768640258
            ],
            [
              4.1795832712336,
              47.125462503318
            ],
            [
              4.1871155557993,
              47.13364213251
            ],
            [
              4.1905772100816,
              47.145082142261
            ],
            [
              4.1818950614264,
              47.150507773033
            ],
            [
              4.1989993858965,
              47.151177316078
            ],
            [
              4.2098316194928,
              47.155410810959
            ],
            [
              4.2168452275486,
              47.146672749611
            ],
            [
              4.2432777093124,
              47.14073455227
            ],
            [
              4.2411717039819,
              47.132491412618
            ],
            [
              4.2509697445122,
              47.130843482768
            ],
            [
              4.2596068200223,
              47.11432257803
            ],
            [
              4.2733576197273,
              47.10819770186
            ],
            [
              4.2855587322379,
              47.108441791717
            ],
            [
              4.2971314772637,
              47.113149658628
            ],
            [
              4.3118196731769,
              47.10394456974
            ],
            [
              4.3355221112478,
              47.103011426888
            ],
            [
              4.349381880875,
              47.096949934296
            ],
            [
              4.3439655990464,
              47.073692424539
            ],
            [
              4.3534159033672,
              47.064032857417
            ],
            [
              4.3635732332048,
              47.066374377816
            ],
            [
              4.3714566907977,
              47.079598781968
            ],
            [
              4.3829837819377,
              47.083083950889
            ],
            [
              4.4054898133936,
              47.081646388237
            ],
            [
              4.4157833418885,
              47.077861293046
            ],
            [
              4.4063488067658,
              47.07060711622
            ],
            [
              4.4062794640699,
              47.049999940669
            ],
            [
              4.4206973571364,
              47.046634951721
            ],
            [
              4.4283082865798,
              47.039783034533
            ],
            [
              4.4537506045924,
              47.04180142916
            ],
            [
              4.4681203186819,
              47.035686379298
            ],
            [
              4.4715411926743,
              47.030673058536
            ],
            [
              4.4936926700522,
              47.028369739748
            ],
            [
              4.4985631523779,
              47.02022379563
            ],
            [
              4.5122367617327,
              47.011776209649
            ],
            [
              4.5338129586369,
              47.013323154173
            ],
            [
              4.5548142345394,
              47.020051751791
            ],
            [
              4.5578215365234,
              47.016393866099
            ],
            [
              4.5558845679183,
              47.002954644765
            ],
            [
              4.547442238525,
              46.997604742791
            ],
            [
              4.5538139975314,
              46.991013803036
            ],
            [
              4.5657682538626,
              46.992256111598
            ],
            [
              4.5673645823976,
              46.98318060934
            ],
            [
              4.5736638505553,
              46.973382702092
            ],
            [
              4.5878756545429,
              46.968824868997
            ],
            [
              4.5938958784994,
              46.953670496961
            ],
            [
              4.6008999978435,
              46.949880356169
            ],
            [
              4.616769673462,
              46.948055041985
            ],
            [
              4.6232301075255,
              46.940816636137
            ],
            [
              4.6501223580743,
              46.941267826803
            ],
            [
              4.6569598053577,
              46.936764234751
            ],
            [
              4.6791588393663,
              46.929068279952
            ],
            [
              4.6645220130405,
              46.914155683564
            ],
            [
              4.6854836804585,
              46.900857518168
            ],
            [
              4.7157197734085,
              46.906915091091
            ],
            [
              4.7281282516843,
              46.919371945217
            ],
            [
              4.7445121157005,
              46.924429479612
            ],
            [
              4.755745504593,
              46.917128050876
            ],
            [
              4.7805526718426,
              46.918177501855
            ],
            [
              4.7903875415492,
              46.924514772268
            ],
            [
              4.7893325112709,
              46.932120435279
            ],
            [
              4.8171879753418,
              46.933547033621
            ],
            [
              4.820804799072,
              46.937879459731
            ],
            [
              4.8706343014911,
              46.948175221605
            ],
            [
              4.8939105794766,
              46.951169780374
            ],
            [
              4.8911121323268,
              46.95730744614
            ],
            [
              4.8960727386751,
              46.966242884867
            ],
            [
              4.9153459886512,
              46.967837298674
            ],
            [
              4.9663068500571,
              46.964645241877
            ],
            [
              4.9838246080058,
              46.959257530031
            ],
            [
              4.9955606754196,
              46.962632349404
            ],
            [
              5.0038049703297,
              46.971924471926
            ],
            [
              5.0149319080657,
              46.970429189276
            ],
            [
              5.029690591636,
              46.980415217927
            ],
            [
              5.0493062298333,
              46.981583286536
            ],
            [
              5.0762580997236,
              46.959384630758
            ],
            [
              5.091280254188,
              46.960486667311
            ],
            [
              5.1062786065898,
              46.955761650391
            ],
            [
              5.1201248077624,
              46.961029911723
            ],
            [
              5.1539020941237,
              46.967047351723
            ],
            [
              5.1662277175338,
              46.963864021823
            ],
            [
              5.1818630583666,
              46.974819779244
            ],
            [
              5.1972758420992,
              46.975291865503
            ],
            [
              5.2017813381481,
              46.983326611317
            ],
            [
              5.2122009820622,
              46.980140542671
            ],
            [
              5.222007205358,
              46.988850972047
            ],
            [
              5.2552323566327,
              46.97988727181
            ],
            [
              5.2548827302617,
              46.971636367009
            ],
            [
              5.2642267089187,
              46.959043180772
            ],
            [
              5.2601642133516,
              46.945577835787
            ],
            [
              5.2632632275105,
              46.935908986811
            ],
            [
              5.2747709784723,
              46.934849244786
            ],
            [
              5.2892527667604,
              46.941274764889
            ],
            [
              5.3084905287746,
              46.937356553152
            ],
            [
              5.3070069943341,
              46.923576065792
            ],
            [
              5.3169631943363,
              46.904052477048
            ],
            [
              5.328668893399,
              46.89427196578
            ],
            [
              5.3392796011353,
              46.889650370665
            ],
            [
              5.3519717132024,
              46.892332830917
            ],
            [
              5.3633736848055,
              46.88325738824
            ],
            [
              5.3907640809284,
              46.894366911749
            ],
            [
              5.4038429343802,
              46.8895159374
            ],
            [
              5.3998079308442,
              46.881340792386
            ],
            [
              5.4042545538339,
              46.877575497225
            ],
            [
              5.4018235507818,
              46.868321978157
            ],
            [
              5.4146973689037,
              46.862363178473
            ],
            [
              5.4376109899408,
              46.85601497291
            ],
            [
              5.4467832086153,
              46.860312646063
            ],
            [
              5.4592877768562,
              46.855245657553
            ],
            [
              5.4622983197648,
              46.838687292645
            ],
            [
              5.4567694196974,
              46.83096747796
            ],
            [
              5.4351093990058,
              46.826754678964
            ],
            [
              5.4169768776707,
              46.826815586408
            ],
            [
              5.4098269851133,
              46.832545203525
            ],
            [
              5.3888320313816,
              46.826669468844
            ],
            [
              5.3702436483657,
              46.826173086426
            ],
            [
              5.3515685001072,
              46.813567110147
            ],
            [
              5.3403852843483,
              46.82115377823
            ],
            [
              5.3285848429923,
              46.812954827447
            ],
            [
              5.33292755524,
              46.796095104705
            ],
            [
              5.3401395358873,
              46.790820312845
            ],
            [
              5.3566637043011,
              46.789799874683
            ],
            [
              5.3693431489209,
              46.778660208918
            ],
            [
              5.3804825411024,
              46.776338304427
            ],
            [
              5.3902478807842,
              46.770586580904
            ],
            [
              5.385508776198,
              46.762069728324
            ],
            [
              5.3663650761211,
              46.751279477853
            ],
            [
              5.3612646423948,
              46.731562182096
            ],
            [
              5.3910888917579,
              46.728698245923
            ],
            [
              5.3965438211421,
              46.716352209475
            ],
            [
              5.394582119336,
              46.710598146417
            ],
            [
              5.4063157263802,
              46.704025277789
            ],
            [
              5.4077513434675,
              46.695884065976
            ],
            [
              5.3953606617938,
              46.685052412753
            ],
            [
              5.4220848047125,
              46.673033412338
            ],
            [
              5.4266257666834,
              46.659810286536
            ],
            [
              5.4154976516957,
              46.654978931388
            ],
            [
              5.4411065155678,
              46.637538242564
            ],
            [
              5.4375286482801,
              46.629847215753
            ],
            [
              5.4171049356193,
              46.615454549879
            ],
            [
              5.3985819483749,
              46.612532369876
            ],
            [
              5.4134417713639,
              46.60042718918
            ],
            [
              5.3976477370739,
              46.579829483685
            ],
            [
              5.3752066392179,
              46.580159450218
            ],
            [
              5.3621183709734,
              46.577266498184
            ],
            [
              5.3676455411539,
              46.56753131305
            ],
            [
              5.3602943181469,
              46.562706895384
            ],
            [
              5.362532641349,
              46.551955983714
            ],
            [
              5.359197282296,
              46.522558128915
            ],
            [
              5.3688458099145,
              46.516135590158
            ],
            [
              5.3848165687083,
              46.51562164374
            ],
            [
              5.3946761409067,
              46.505960131498
            ],
            [
              5.405972822918,
              46.501480763488
            ],
            [
              5.4195457769452,
              46.502301459279
            ],
            [
              5.4209987260656,
              46.485402729497
            ],
            [
              5.4157407818816,
              46.473142287102
            ],
            [
              5.3975202395847,
              46.466656047847
            ],
            [
              5.388506199686,
              46.468564619365
            ],
            [
              5.3733086058294,
              46.460102440011
            ],
            [
              5.3233470518352,
              46.462506037791
            ],
            [
              5.3231451264737,
              46.456478982676
            ],
            [
              5.3105633704742,
              46.446769968334
            ],
            [
              5.2759250637615,
              46.4481213263
            ],
            [
              5.2545935888787,
              46.454117831184
            ],
            [
              5.2468319237306,
              46.459403653865
            ],
            [
              5.2350290009581,
              46.457948870166
            ],
            [
              5.2255477233319,
              46.468273543404
            ],
            [
              5.2150628404089,
              46.468359298393
            ],
            [
              5.2130427939332,
              46.481261974235
            ],
            [
              5.2066353369633,
              46.48639795185
            ],
            [
              5.2011399666188,
              46.508211405762
            ],
            [
              5.1815968667213,
              46.509758803783
            ],
            [
              5.1667923612572,
              46.514674866375
            ],
            [
              5.1664495124354,
              46.505590616989
            ],
            [
              5.141950046447,
              46.508357328146
            ],
            [
              5.1152051358154,
              46.494069043579
            ],
            [
              5.10737201939,
              46.491919458253
            ],
            [
              5.0989387475854,
              46.497166675779
            ],
            [
              5.0700061667722,
              46.485667291269
            ],
            [
              5.0523715346281,
              46.484873791529
            ],
            [
              5.0141793460298,
              46.500587565063
            ],
            [
              5.011007749262,
              46.510294840159
            ],
            [
              4.9835496952255,
              46.515392507897
            ],
            [
              4.9645994700407,
              46.513175985923
            ],
            [
              4.9491021767105,
              46.501380502462
            ],
            [
              4.9400218107181,
              46.517199374492
            ],
            [
              4.9314208893674,
              46.509211481378
            ],
            [
              4.9257189717408,
              46.497441604339
            ],
            [
              4.9155507621799,
              46.488939443669
            ],
            [
              4.9157805036687,
              46.465413333501
            ],
            [
              4.9111172894635,
              46.457732635494
            ],
            [
              4.8992958191725,
              46.450123277325
            ],
            [
              4.8918151434404,
              46.439918009678
            ],
            [
              4.8882101486247,
              46.402977057782
            ],
            [
              4.873839273104,
              46.384668143424
            ],
            [
              4.8585287407096,
              46.368018459572
            ],
            [
              4.8514544831808,
              46.356271094403
            ],
            [
              4.8534210199043,
              46.32990123356
            ],
            [
              4.8332136171616,
              46.300145048773
            ],
            [
              4.8259515552341,
              46.274785814873
            ],
            [
              4.8109975376095,
              46.259923276174
            ],
            [
              4.8115552603184,
              46.249932664612
            ],
            [
              4.8077505003904,
              46.236972415214
            ],
            [
              4.7945859355866,
              46.218311767171
            ],
            [
              4.7933904888672,
              46.204700104778
            ],
            [
              4.7802430578135,
              46.189052363162
            ],
            [
              4.7802082627383,
              46.176676203678
            ],
            [
              4.7608053446831,
              46.175968780182
            ],
            [
              4.7597597705914,
              46.172840600415
            ],
            [
              4.7305257874621,
              46.178368585861
            ],
            [
              4.7243706747794,
              46.184304976244
            ],
            [
              4.72266470068,
              46.202320489849
            ],
            [
              4.7356928657613,
              46.211923783014
            ],
            [
              4.7206237625559,
              46.222390986056
            ],
            [
              4.7326605831699,
              46.227019799221
            ],
            [
              4.7357666167041,
              46.234244688808
            ],
            [
              4.7028290155173,
              46.251330439429
            ],
            [
              4.6883618181605,
              46.25011389523
            ],
            [
              4.6795502516132,
              46.258666565339
            ],
            [
              4.6878811178807,
              46.265311972919
            ],
            [
              4.7076872577415,
              46.269647917424
            ],
            [
              4.7075411259083,
              46.284660070276
            ],
            [
              4.694515991108,
              46.292672565821
            ],
            [
              4.6931098569714,
              46.302197476983
            ],
            [
              4.6794340760587,
              46.303994122044
            ],
            [
              4.6693820675804,
              46.297910092985
            ],
            [
              4.6546591822967,
              46.303484822155
            ],
            [
              4.6352614072065,
              46.299289507242
            ],
            [
              4.6314517777677,
              46.289015276562
            ],
            [
              4.6183224944202,
              46.282440402941
            ],
            [
              4.6220235259619,
              46.270079226484
            ],
            [
              4.6185580580588,
              46.264793895574
            ],
            [
              4.5865068000033,
              46.268694622582
            ],
            [
              4.5726687726239,
              46.277139335718
            ],
            [
              4.5698487572768,
              46.293352201901
            ],
            [
              4.5577956308141,
              46.294551640092
            ],
            [
              4.5480762222411,
              46.282864938182
            ],
            [
              4.5464477974735,
              46.27391839397
            ],
            [
              4.5372643385622,
              46.269911483268
            ],
            [
              4.5039940949336,
              46.267132463094
            ],
            [
              4.488465816487,
              46.287994462282
            ],
            [
              4.4765696377244,
              46.284237867232
            ],
            [
              4.4583704273073,
              46.296976724079
            ],
            [
              4.4397213287914,
              46.293184568466
            ],
            [
              4.4272337977412,
              46.302730717527
            ],
            [
              4.4218715520166,
              46.294954263085
            ],
            [
              4.4058135314858,
              46.296057806589
            ],
            [
              4.3920466116785,
              46.263026545141
            ],
            [
              4.388291015925,
              46.247956431405
            ],
            [
              4.3880744564659,
              46.219790380732
            ],
            [
              4.3893979878582,
              46.213601360996
            ],
            [
              4.3774743279046,
              46.21020246009
            ],
            [
              4.3631781812533,
              46.200537276046
            ],
            [
              4.3706868166882,
              46.191620039558
            ],
            [
              4.3648481141165,
              46.183579311067
            ],
            [
              4.3450682105084,
              46.187441862916
            ],
            [
              4.3357117653558,
              46.181398292861
            ],
            [
              4.3270887031684,
              46.184790131282
            ],
            [
              4.3157416420975,
              46.172029804364
            ],
            [
              4.3035807058325,
              46.17426655994
            ],
            [
              4.2952828837852,
              46.172250410112
            ],
            [
              4.2824944900944,
              46.156946471815
            ],
            [
              4.2520302327293,
              46.157916681311
            ],
            [
              4.2518988744775,
              46.167097935374
            ],
            [
              4.2610250986037,
              46.178754364444
            ],
            [
              4.2525000565082,
              46.187922148658
            ],
            [
              4.2423584224526,
              46.188830459357
            ],
            [
              4.2335294499164,
              46.180026926884
            ],
            [
              4.2246872961743,
              46.177995338767
            ],
            [
              4.207903434232,
              46.194833150686
            ],
            [
              4.1854551738783,
              46.190162130834
            ],
            [
              4.1884438149961,
              46.175128955988
            ],
            [
              4.1660889760454,
              46.172928033288
            ],
            [
              4.1324158349304,
              46.177826358374
            ],
            [
              4.1040867377072,
              46.198391359055
            ],
            [
              4.0908839436933,
              46.192837097346
            ],
            [
              4.0716672927165,
              46.18807123789
            ],
            [
              4.0614051962048,
              46.188789802443
            ],
            [
              4.051960198465,
              46.181690186415
            ],
            [
              4.0308840440337,
              46.171882539683
            ],
            [
              3.9887880883959,
              46.169805261207
            ],
            [
              3.9817823975359,
              46.17634098728
            ],
            [
              3.9728332574833,
              46.193862543845
            ],
            [
              3.9725466394127,
              46.202707514639
            ],
            [
              3.933514918779,
              46.206414377073
            ],
            [
              3.9179891232598,
              46.202853033545
            ],
            [
              3.9135693350409,
              46.206918558033
            ],
            [
              3.890130972164,
              46.214487049905
            ],
            [
              3.8981917464837,
              46.226659321125
            ],
            [
              3.8966112073781,
              46.2371189424
            ],
            [
              3.9076581109201,
              46.242456213516
            ],
            [
              3.908821904601,
              46.260346940334
            ],
            [
              3.9055127902449,
              46.271596707683
            ],
            [
              3.899538631706,
              46.275908099459
            ],
            [
              3.8912386850317,
              46.28524616424
            ],
            [
              3.8974208259956,
              46.291460834216
            ],
            [
              3.9135709840696,
              46.296680727045
            ],
            [
              3.9300977722843,
              46.295820251472
            ],
            [
              3.942781911555,
              46.298925500612
            ],
            [
              3.9477193967384,
              46.303444864233
            ],
            [
              3.9481286581435,
              46.31979164002
            ],
            [
              3.9866271252361,
              46.319195576966
            ],
            [
              3.9974052217542,
              46.323291087799
            ],
            [
              3.9847410554074,
              46.32973022678
            ],
            [
              3.9886640376734,
              46.360561403255
            ],
            [
              3.9916043062641,
              46.369630419679
            ],
            [
              3.9842615206377,
              46.378286038678
            ],
            [
              3.9772206054151,
              46.399220047306
            ],
            [
              3.9886970251491,
              46.408772224942
            ],
            [
              3.9844480228975,
              46.416663389961
            ],
            [
              3.9956151474212,
              46.4286940195
            ],
            [
              3.9881422985694,
              46.435461575775
            ],
            [
              4.0055701432229,
              46.443531306791
            ],
            [
              3.997444424826,
              46.450264670788
            ],
            [
              4.0015415164833,
              46.458618151559
            ],
            [
              3.9980402829299,
              46.465463980924
            ],
            [
              3.9730562040646,
              46.477457398934
            ],
            [
              3.9565931687991,
              46.47688226209
            ],
            [
              3.9520867242123,
              46.481423383561
            ],
            [
              3.9579376111726,
              46.489753821104
            ],
            [
              3.9496133283036,
              46.492478893726
            ],
            [
              3.9375764836594,
              46.49076190287
            ],
            [
              3.9189702450008,
              46.496061208045
            ],
            [
              3.9013529089878,
              46.490636303507
            ],
            [
              3.896985633842,
              46.481471942919
            ],
            [
              3.8904665810402,
              46.481246458453
            ],
            [
              3.8649127119961,
              46.489736338385
            ],
            [
              3.860387224324,
              46.495601253328
            ],
            [
              3.8646271424558,
              46.509717077517
            ],
            [
              3.8600251576626,
              46.515222323315
            ],
            [
              3.8494709455035,
              46.513023833485
            ],
            [
              3.8397556873666,
              46.517562051932
            ],
            [
              3.8464630176371,
              46.524356398489
            ],
            [
              3.8340033360184,
              46.531134997034
            ],
            [
              3.8176755382384,
              46.525229454055
            ],
            [
              3.8113440515561,
              46.520138117834
            ],
            [
              3.8017563839321,
              46.519902255804
            ],
            [
              3.7878963661806,
              46.528042650186
            ],
            [
              3.7656008328403,
              46.537908481562
            ],
            [
              3.7546629110025,
              46.536019887064
            ],
            [
              3.7418443310505,
              46.539508443385
            ],
            [
              3.73154117491,
              46.549578224034
            ],
            [
              3.7406549316466,
              46.559049395025
            ],
            [
              3.7432893129951,
              46.567565263244
            ],
            [
              3.7323776901235,
              46.604907119963
            ],
            [
              3.7176467323502,
              46.605806262315
            ],
            [
              3.7138737202882,
              46.613995279712
            ],
            [
              3.7230250098493,
              46.622072726143
            ],
            [
              3.7227091007956,
              46.627609885471
            ],
            [
              3.7121523238188,
              46.633631635674
            ],
            [
              3.699679505,
              46.651867135799
            ],
            [
              3.6969575528098,
              46.660583034448
            ],
            [
              3.6801004977635,
              46.66852089269
            ],
            [
              3.6554716665381,
              46.687748401639
            ],
            [
              3.6513931816228,
              46.70282167235
            ],
            [
              3.637895263919,
              46.707205199291
            ],
            [
              3.6381391050479,
              46.722723829214
            ],
            [
              3.6353341554133,
              46.728514790806
            ],
            [
              3.6225898833129,
              46.740130866568
            ],
            [
              3.6294223422648,
              46.749456328005
            ],
            [
              3.6388515179539,
              46.741431838165
            ],
            [
              3.6486303092589,
              46.742624134301
            ],
            [
              3.6620658803764,
              46.738060599572
            ],
            [
              3.6861311660273,
              46.741310406966
            ],
            [
              3.7041063465572,
              46.746923018232
            ],
            [
              3.7361900575316,
              46.752473902091
            ],
            [
              3.7508711255828,
              46.745679580207
            ],
            [
              3.7826230283356,
              46.736194804924
            ],
            [
              3.7973242309307,
              46.701499072057
            ],
            [
              3.8141986781304,
              46.705295808098
            ],
            [
              3.8261009793206,
              46.703917697238
            ],
            [
              3.8379042358968,
              46.712109191105
            ],
            [
              3.8446586531024,
              46.720905827955
            ],
            [
              3.8539645075768,
              46.725011529247
            ],
            [
              3.8697068751044,
              46.72642145442
            ],
            [
              3.8763512648567,
              46.732270769936
            ],
            [
              3.8862216978057,
              46.727368533327
            ],
            [
              3.893374254748,
              46.732625851573
            ],
            [
              3.9233963374575,
              46.738871738421
            ],
            [
              3.9429274242382,
              46.750836954649
            ],
            [
              3.9571797005846,
              46.756108388206
            ],
            [
              3.9632189412952,
              46.766027891145
            ],
            [
              3.9919552712787,
              46.776752031818
            ],
            [
              4.0038776137753,
              46.778670067159
            ],
            [
              4.0208649854129,
              46.777341720269
            ],
            [
              4.0396130376104,
              46.786197313589
            ],
            [
              4.046288175844,
              46.779798308275
            ],
            [
              4.0553247809739,
              46.778718863178
            ],
            [
              4.0627058296664,
              46.789361126398
            ],
            [
              4.0562525887395,
              46.822258972946
            ],
            [
              4.0423720361053,
              46.834119533114
            ],
            [
              4.0562344150701,
              46.843270576149
            ],
            [
              4.0753121505214,
              46.849236849001
            ],
            [
              4.092715166052,
              46.858059566361
            ],
            [
              4.1008830130886,
              46.859103716467
            ],
            [
              4.0938487569255,
              46.874735258475
            ],
            [
              4.0754488330482,
              46.891490462779
            ],
            [
              4.0635404678154,
              46.898637154276
            ],
            [
              4.0479296715449,
              46.899770717258
            ],
            [
              4.0372192814383,
              46.918152029371
            ],
            [
              4.0365379360006,
              46.927791049018
            ],
            [
              4.0450633703398,
              46.937742227613
            ],
            [
              4.0395312114589,
              46.941423698692
            ],
            [
              4.0408074643843,
              46.950628112044
            ],
            [
              4.0557200391713,
              46.956749646361
            ],
            [
              4.0438473804989,
              46.973978460165
            ],
            [
              4.039402003189,
              46.985112713117
            ],
            [
              4.0316347246235,
              46.984823155361
            ],
            [
              4.0144190745946,
              46.972477975289
            ],
            [
              3.9957316970127,
              46.974693752431
            ],
            [
              3.9951615578479,
              46.985310080182
            ],
            [
              4.0362502315426,
              47.001896052274
            ],
            [
              4.0479222180945,
              47.011126186359
            ],
            [
              4.0549441401071,
              47.012685678649
            ],
            [
              4.0606658400579,
              47.024972535492
            ],
            [
              4.0574940116794,
              47.038900365199
            ],
            [
              4.0720151548184,
              47.054495729639
            ],
            [
              4.0683361057481,
              47.060850648192
            ],
            [
              4.0387223667702,
              47.080008269353
            ],
            [
              4.0396752665903,
              47.086158384901
            ],
            [
              4.0537588244023,
              47.095036584078
            ],
            [
              4.0481739880777,
              47.109966250504
            ],
            [
              4.0614299224165,
              47.121038939248
            ],
            [
              4.0898927747246,
              47.116176880355
            ],
            [
              4.0975884447258,
              47.125732870471
            ],
            [
              4.1122626988576,
              47.118983845261
            ],
            [
              4.1159657137304,
              47.12333578884
            ]
          ]
        ]
      },
      "properties": {
        "code": "71",
        "nom": "Saône-et-Loire",
        "population": 551063,
        "delitspour1000": "3,891",
        "nombremosque": 28,
        "parentsEtrangers": "78,4",
        "benefRsa": 20203,
        "eoliennes": 10
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.3198871747441,
              48.90045978209
            ],
            [
              2.3851496429397,
              48.902007785215
            ],
            [
              2.394906293421,
              48.898444039523
            ],
            [
              2.3988455271816,
              48.887109095072
            ],
            [
              2.4132702557262,
              48.872892145992
            ],
            [
              2.4163411302989,
              48.849233783552
            ],
            [
              2.4122456125626,
              48.834538914673
            ],
            [
              2.4221386362435,
              48.835797660955
            ],
            [
              2.4281301699852,
              48.841528392473
            ],
            [
              2.447699326814,
              48.844818443355
            ],
            [
              2.4634383121686,
              48.842089485269
            ],
            [
              2.4675819883673,
              48.833133318793
            ],
            [
              2.4626960627524,
              48.819059770564
            ],
            [
              2.4384475102742,
              48.818232447877
            ],
            [
              2.406031823401,
              48.827615470779
            ],
            [
              2.3909392530738,
              48.826078980076
            ],
            [
              2.363946550191,
              48.816314210034
            ],
            [
              2.3318980606376,
              48.817010929642
            ],
            [
              2.2921959226619,
              48.82714160912
            ],
            [
              2.2790519306533,
              48.832489952145
            ],
            [
              2.2727931901868,
              48.827920084226
            ],
            [
              2.2551442384175,
              48.834809549369
            ],
            [
              2.2506124417162,
              48.845554851211
            ],
            [
              2.2242191058804,
              48.853516917557
            ],
            [
              2.2317363597469,
              48.86906858161
            ],
            [
              2.2584671711142,
              48.880387263086
            ],
            [
              2.2774870298138,
              48.877968320853
            ],
            [
              2.2915068524977,
              48.8894718708
            ],
            [
              2.3198871747441,
              48.90045978209
            ]
          ]
        ]
      },
      "properties": {
        "code": "75",
        "nom": "Paris",
        "population": 2145906,
        "delitspour1000": "7,453",
        "nombremosque": 61,
        "parentsEtrangers": "53,3",
        "benefRsa": 98589,
        "eoliennes": 2
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.2005910092695,
              48.908679329899
            ],
            [
              2.1765183808683,
              48.900349435762
            ],
            [
              2.1677833601069,
              48.894417281801
            ],
            [
              2.1528705339192,
              48.873338063287
            ],
            [
              2.1532113486874,
              48.848852456588
            ],
            [
              2.1458760215967,
              48.836022751826
            ],
            [
              2.1515805126399,
              48.817021316976
            ],
            [
              2.1605570086469,
              48.813043649008
            ],
            [
              2.1766702709493,
              48.813808667803
            ],
            [
              2.1836952927536,
              48.797237542236
            ],
            [
              2.2026391702069,
              48.798400652391
            ],
            [
              2.2115088216259,
              48.788138691837
            ],
            [
              2.223319655228,
              48.785943206962
            ],
            [
              2.2265538842831,
              48.776101996393
            ],
            [
              2.2099186754479,
              48.769192527247
            ],
            [
              2.1910712604347,
              48.775122277406
            ],
            [
              2.192317263511,
              48.763285114137
            ],
            [
              2.1820123672797,
              48.757574558863
            ],
            [
              2.1680122718546,
              48.756836536461
            ],
            [
              2.1605126243942,
              48.752014252613
            ],
            [
              2.1506777536849,
              48.753710336104
            ],
            [
              2.1384211955586,
              48.74615342302
            ],
            [
              2.1349745033773,
              48.737136538566
            ],
            [
              2.1118838344903,
              48.73955835886
            ],
            [
              2.0979976816391,
              48.73436321985
            ],
            [
              2.111168267855,
              48.724231347633
            ],
            [
              2.1103286614936,
              48.71437841267
            ],
            [
              2.0969343192737,
              48.702748042418
            ],
            [
              2.0978580418958,
              48.694030740642
            ],
            [
              2.0857970428575,
              48.692525773326
            ],
            [
              2.0822157938708,
              48.68585446348
            ],
            [
              2.0456604451446,
              48.68756805233
            ],
            [
              2.0376361003153,
              48.680219226712
            ],
            [
              2.0290327276387,
              48.664236731798
            ],
            [
              2.0281927750027,
              48.657259334271
            ],
            [
              2.0112162167678,
              48.659775158953
            ],
            [
              2.0129881452639,
              48.647690003816
            ],
            [
              2.0200083770792,
              48.642090643692
            ],
            [
              2.0359612070725,
              48.641907744749
            ],
            [
              2.034456506865,
              48.632456487516
            ],
            [
              2.0458818260422,
              48.622732498273
            ],
            [
              2.0602471726215,
              48.617597733568
            ],
            [
              2.0546695680501,
              48.608729666136
            ],
            [
              2.0345020728388,
              48.603502290966
            ],
            [
              2.0361244190883,
              48.590748476784
            ],
            [
              2.017226962458,
              48.569459551945
            ],
            [
              2.0175700627197,
              48.557593755935
            ],
            [
              1.999843911355,
              48.555387687139
            ],
            [
              1.9818197824217,
              48.555550526577
            ],
            [
              1.9630289819786,
              48.560481876073
            ],
            [
              1.9527665939994,
              48.559466252882
            ],
            [
              1.9378900456787,
              48.562926567593
            ],
            [
              1.9340616493117,
              48.548106841763
            ],
            [
              1.9674208443859,
              48.533067716836
            ],
            [
              1.9532944656762,
              48.523736261273
            ],
            [
              1.9368069560798,
              48.500865304824
            ],
            [
              1.9333793220099,
              48.482145535364
            ],
            [
              1.9149199821626,
              48.473854999246
            ],
            [
              1.9163409812792,
              48.464861099393
            ],
            [
              1.9221462784913,
              48.457599361977
            ],
            [
              1.9207376717625,
              48.447750046159
            ],
            [
              1.9065549813045,
              48.445672344539
            ],
            [
              1.9064076592587,
              48.440146719021
            ],
            [
              1.8774887068591,
              48.440613561663
            ],
            [
              1.8448630603718,
              48.449360706547
            ],
            [
              1.8363839553347,
              48.466477806902
            ],
            [
              1.8014512893542,
              48.466086215218
            ],
            [
              1.7963309289573,
              48.484187938277
            ],
            [
              1.7857092863217,
              48.489936911612
            ],
            [
              1.7906352036641,
              48.497377315443
            ],
            [
              1.7786170432038,
              48.508131705459
            ],
            [
              1.7757543018107,
              48.527702827198
            ],
            [
              1.7872423006556,
              48.553746336139
            ],
            [
              1.7674104287362,
              48.559405546059
            ],
            [
              1.765322855183,
              48.569374568412
            ],
            [
              1.7458629248393,
              48.576137031624
            ],
            [
              1.7368302606076,
              48.572280930629
            ],
            [
              1.7092486767776,
              48.578026100992
            ],
            [
              1.701983609829,
              48.584999462088
            ],
            [
              1.7178604312421,
              48.606851120102
            ],
            [
              1.7148984609516,
              48.614392276576
            ],
            [
              1.689422105099,
              48.611536250362
            ],
            [
              1.6793591385148,
              48.618488989615
            ],
            [
              1.6662229993646,
              48.613704063337
            ],
            [
              1.656339110342,
              48.622053121145
            ],
            [
              1.6582325680054,
              48.627645882726
            ],
            [
              1.6495606586096,
              48.63227092599
            ],
            [
              1.6515719070139,
              48.638125076138
            ],
            [
              1.64099001993,
              48.643389970225
            ],
            [
              1.6431906740267,
              48.651291198129
            ],
            [
              1.6298476379809,
              48.648875085504
            ],
            [
              1.6156160327829,
              48.652789464959
            ],
            [
              1.6024917313048,
              48.663765432374
            ],
            [
              1.6058633277913,
              48.678762670696
            ],
            [
              1.611774872468,
              48.689473861848
            ],
            [
              1.5823151457709,
              48.696368116243
            ],
            [
              1.5795403954022,
              48.701811808781
            ],
            [
              1.5948810579854,
              48.709310708948
            ],
            [
              1.5895382120501,
              48.712468154974
            ],
            [
              1.617804540369,
              48.735942039093
            ],
            [
              1.6264035004424,
              48.748091646614
            ],
            [
              1.6026875153092,
              48.760486506237
            ],
            [
              1.5849045861802,
              48.763296867942
            ],
            [
              1.5875973267999,
              48.77360593191
            ],
            [
              1.5803992974877,
              48.777559499888
            ],
            [
              1.5756742997058,
              48.790504096814
            ],
            [
              1.5834240899801,
              48.807168828475
            ],
            [
              1.5912058811372,
              48.814866973865
            ],
            [
              1.5837280523171,
              48.832130726577
            ],
            [
              1.5941817842602,
              48.834218536506
            ],
            [
              1.5951919881928,
              48.839707334891
            ],
            [
              1.5772727483959,
              48.844358116549
            ],
            [
              1.5815974879282,
              48.854999853184
            ],
            [
              1.5780393102494,
              48.860864409827
            ],
            [
              1.5666395741734,
              48.866183647932
            ],
            [
              1.5552126063723,
              48.865075717505
            ],
            [
              1.5462328761118,
              48.872431616391
            ],
            [
              1.5595079054893,
              48.882055273516
            ],
            [
              1.5572530066459,
              48.891575743208
            ],
            [
              1.5385235335514,
              48.906685854091
            ],
            [
              1.5417973746939,
              48.91547990669
            ],
            [
              1.5386177797921,
              48.921794692396
            ],
            [
              1.5241021875366,
              48.924752145617
            ],
            [
              1.5128954188837,
              48.922585369837
            ],
            [
              1.5090139983632,
              48.936627464834
            ],
            [
              1.5015266013113,
              48.941051842112
            ],
            [
              1.5010590902862,
              48.952678048634
            ],
            [
              1.4966058957223,
              48.969344788938
            ],
            [
              1.5155485568584,
              48.976406187821
            ],
            [
              1.5079088220123,
              48.98376724437
            ],
            [
              1.497416022081,
              48.979551284823
            ],
            [
              1.4770931932159,
              48.978791319268
            ],
            [
              1.4706099672547,
              48.975306435494
            ],
            [
              1.4609202371105,
              48.986433838647
            ],
            [
              1.4787512905862,
              48.998884682872
            ],
            [
              1.4721822976397,
              49.018490972378
            ],
            [
              1.4577570076778,
              49.026294572016
            ],
            [
              1.4579533584639,
              49.03483279821
            ],
            [
              1.4474286969633,
              49.04518889808
            ],
            [
              1.4472851104304,
              49.053509472828
            ],
            [
              1.4608012364168,
              49.062739598254
            ],
            [
              1.4848766880401,
              49.0515451317
            ],
            [
              1.5032944474047,
              49.059098256736
            ],
            [
              1.5116006058281,
              49.074136193201
            ],
            [
              1.5217716034178,
              49.068546693163
            ],
            [
              1.5494725491086,
              49.072557881924
            ],
            [
              1.5570743762242,
              49.069620586462
            ],
            [
              1.5747375500157,
              49.078437534581
            ],
            [
              1.6044866350314,
              49.08303659502
            ],
            [
              1.608798807603,
              49.077894185227
            ],
            [
              1.6141564760593,
              49.071448021972
            ],
            [
              1.6292577222733,
              49.078286632616
            ],
            [
              1.6429103427093,
              49.081251769387
            ],
            [
              1.6602322098294,
              49.081089987836
            ],
            [
              1.6736890350523,
              49.078154769223
            ],
            [
              1.6971091015472,
              49.065962446366
            ],
            [
              1.6949167927313,
              49.056327234914
            ],
            [
              1.7171010524139,
              49.04903025476
            ],
            [
              1.7234517354795,
              49.044974719281
            ],
            [
              1.7356433379106,
              49.048684595888
            ],
            [
              1.7511782974558,
              49.048223015203
            ],
            [
              1.7502240292765,
              49.05410421169
            ],
            [
              1.7596223758831,
              49.063324634192
            ],
            [
              1.7777603243167,
              49.05658172354
            ],
            [
              1.7941625055309,
              49.058508218145
            ],
            [
              1.7998422239972,
              49.064555011994
            ],
            [
              1.7965480580586,
              49.072232211602
            ],
            [
              1.8239435408566,
              49.076360256056
            ],
            [
              1.8406440398067,
              49.069332222477
            ],
            [
              1.8417831494014,
              49.064654564598
            ],
            [
              1.8654659990427,
              49.05747165429
            ],
            [
              1.8606119952712,
              49.046346047943
            ],
            [
              1.8520019385024,
              49.035204042747
            ],
            [
              1.8691165460014,
              49.01823441815
            ],
            [
              1.8818941990409,
              49.019542051399
            ],
            [
              1.8832054260904,
              49.033529797441
            ],
            [
              1.9085198609511,
              49.048019307838
            ],
            [
              1.9378008717251,
              49.021772717877
            ],
            [
              1.9549423612736,
              49.024533707311
            ],
            [
              1.974053010825,
              49.019426174588
            ],
            [
              1.9918871076265,
              49.021006179043
            ],
            [
              2.0144337197287,
              49.00106609648
            ],
            [
              2.0247730275828,
              48.998756705331
            ],
            [
              2.0498584568979,
              49.003200683684
            ],
            [
              2.0644261289366,
              49.008208904929
            ],
            [
              2.0754526810069,
              49.002257622875
            ],
            [
              2.0849108992224,
              49.014674188012
            ],
            [
              2.1102443408749,
              49.008729959034
            ],
            [
              2.1212540749308,
              49.018332284915
            ],
            [
              2.127121298296,
              49.009679391237
            ],
            [
              2.1207126382788,
              48.993300321431
            ],
            [
              2.1269716643348,
              48.989140165715
            ],
            [
              2.1549353971038,
              48.985726242203
            ],
            [
              2.1732779441741,
              48.975633867858
            ],
            [
              2.1782956060357,
              48.964137829678
            ],
            [
              2.1735533368231,
              48.953158232311
            ],
            [
              2.205861118488,
              48.949738062716
            ],
            [
              2.2060210022656,
              48.94051776568
            ],
            [
              2.1989282039477,
              48.935252831528
            ],
            [
              2.2037015884478,
              48.914390956152
            ],
            [
              2.2005910092695,
              48.908679329899
            ]
          ]
        ]
      },
      "properties": {
        "code": "78",
        "nom": "Yvelines",
        "population": 1449723,
        "delitspour1000": "4,693",
        "nombremosque": 74,
        "parentsEtrangers": "54,9",
        "benefRsa": 51033,
        "eoliennes": 6
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1.990172010571,
              44.149449875043
            ],
            [
              2.0247690975029,
              44.155129610566
            ],
            [
              2.0346692572966,
              44.168505036007
            ],
            [
              2.0593781574153,
              44.17227973706
            ],
            [
              2.0521125599217,
              44.188733050645
            ],
            [
              2.0688657537344,
              44.18967491336
            ],
            [
              2.0709566347835,
              44.183377219398
            ],
            [
              2.0809187671674,
              44.17997486902
            ],
            [
              2.09438670937,
              44.187642636102
            ],
            [
              2.097443906345,
              44.175292714971
            ],
            [
              2.1069897355697,
              44.18115780471
            ],
            [
              2.1061236174793,
              44.194390921069
            ],
            [
              2.1311952915472,
              44.197318789904
            ],
            [
              2.1359451447137,
              44.200834436147
            ],
            [
              2.1524658664853,
              44.197716441976
            ],
            [
              2.1565534575573,
              44.179856851186
            ],
            [
              2.1752862522128,
              44.18101009946
            ],
            [
              2.1917781059768,
              44.16203491789
            ],
            [
              2.2069453324549,
              44.171279129819
            ],
            [
              2.2222888874803,
              44.166353715955
            ],
            [
              2.2376110882977,
              44.158624890908
            ],
            [
              2.2179314844805,
              44.150953299721
            ],
            [
              2.2117482291708,
              44.145811711989
            ],
            [
              2.1898191628429,
              44.146827430556
            ],
            [
              2.1813288285555,
              44.142041491796
            ],
            [
              2.1865229813992,
              44.137373484432
            ],
            [
              2.2241993310813,
              44.13889318997
            ],
            [
              2.2396510494767,
              44.134457723455
            ],
            [
              2.2698114264498,
              44.146492745788
            ],
            [
              2.2846972006779,
              44.145287510311
            ],
            [
              2.2978256265117,
              44.138045574105
            ],
            [
              2.2906456306459,
              44.124682487431
            ],
            [
              2.3083985987371,
              44.117666350693
            ],
            [
              2.323636723165,
              44.124682191488
            ],
            [
              2.3577422834088,
              44.101556418275
            ],
            [
              2.3892137818918,
              44.093489950159
            ],
            [
              2.4094780251355,
              44.071941155999
            ],
            [
              2.410211491953,
              44.055851343973
            ],
            [
              2.4216910951538,
              44.053752475751
            ],
            [
              2.4599332365299,
              44.051400681122
            ],
            [
              2.4634624629786,
              44.034970818942
            ],
            [
              2.4794403474462,
              44.028267176122
            ],
            [
              2.4903639922153,
              44.019634278994
            ],
            [
              2.4962620461974,
              44.007659874229
            ],
            [
              2.4942365622806,
              43.996181698121
            ],
            [
              2.5104055063181,
              43.985134825107
            ],
            [
              2.5229312446889,
              43.981284190271
            ],
            [
              2.5235804922512,
              43.970261186219
            ],
            [
              2.5163732203653,
              43.958298386865
            ],
            [
              2.4993496835219,
              43.950584469272
            ],
            [
              2.5152005962438,
              43.942348213612
            ],
            [
              2.537792383558,
              43.933736154441
            ],
            [
              2.5550000011505,
              43.921377244109
            ],
            [
              2.55049168647,
              43.896537961073
            ],
            [
              2.5574197456536,
              43.88775037126
            ],
            [
              2.5766859747192,
              43.881722459052
            ],
            [
              2.5797174724613,
              43.875557889665
            ],
            [
              2.5653065547865,
              43.867795299613
            ],
            [
              2.5641575606058,
              43.841331968769
            ],
            [
              2.5792191773895,
              43.831402035923
            ],
            [
              2.5882545103351,
              43.819281444913
            ],
            [
              2.597142701397,
              43.819036570269
            ],
            [
              2.610195199842,
              43.80025721986
            ],
            [
              2.6221233220367,
              43.791699862567
            ],
            [
              2.6292120247184,
              43.780337067422
            ],
            [
              2.6570632019432,
              43.762262495669
            ],
            [
              2.6606377392109,
              43.755995313286
            ],
            [
              2.6812996669758,
              43.743967184551
            ],
            [
              2.7120897763673,
              43.742651851512
            ],
            [
              2.7238360934081,
              43.738341374154
            ],
            [
              2.7376357664304,
              43.729149337635
            ],
            [
              2.7428405750055,
              43.729040274717
            ],
            [
              2.7815654433359,
              43.737086693682
            ],
            [
              2.7876469967185,
              43.75205519428
            ],
            [
              2.8136698449289,
              43.761715123534
            ],
            [
              2.8240413372408,
              43.761983429423
            ],
            [
              2.8329778062036,
              43.752404087944
            ],
            [
              2.8646413093077,
              43.74643610445
            ],
            [
              2.8696809314026,
              43.74040244916
            ],
            [
              2.9027878228205,
              43.742086455949
            ],
            [
              2.9211095274122,
              43.736113679897
            ],
            [
              2.9249821950236,
              43.707226227732
            ],
            [
              2.93545676901,
              43.694665082071
            ],
            [
              2.9185719132589,
              43.686154965245
            ],
            [
              2.9197550319868,
              43.680701165204
            ],
            [
              2.9159852376449,
              43.660020748267
            ],
            [
              2.907351068949,
              43.654226226268
            ],
            [
              2.8789777619922,
              43.65668899636
            ],
            [
              2.874245630292,
              43.647265454371
            ],
            [
              2.8574751278235,
              43.643382854664
            ],
            [
              2.8402202097763,
              43.642531059545
            ],
            [
              2.8292347639089,
              43.638059497972
            ],
            [
              2.8159223190021,
              43.640061402207
            ],
            [
              2.7948563475217,
              43.624431292653
            ],
            [
              2.7758876987511,
              43.623236718731
            ],
            [
              2.7672355905264,
              43.61724579423
            ],
            [
              2.7545198343058,
              43.614140577604
            ],
            [
              2.7435418358694,
              43.622064780506
            ],
            [
              2.7389591104087,
              43.630225717137
            ],
            [
              2.7240271983999,
              43.639996556656
            ],
            [
              2.7111682176412,
              43.645600379862
            ],
            [
              2.6855446430858,
              43.651325814028
            ],
            [
              2.6538560490336,
              43.649167216547
            ],
            [
              2.6432649887562,
              43.650141667656
            ],
            [
              2.6352029674453,
              43.625770020949
            ],
            [
              2.6280316578289,
              43.614603664441
            ],
            [
              2.6146368129343,
              43.599858204147
            ],
            [
              2.6275661079079,
              43.590933474599
            ],
            [
              2.631783900316,
              43.584146522163
            ],
            [
              2.6156009876353,
              43.566636032386
            ],
            [
              2.6278174705033,
              43.551599708027
            ],
            [
              2.6586451413796,
              43.525794979317
            ],
            [
              2.660197072431,
              43.503196229293
            ],
            [
              2.6549028733756,
              43.496866257055
            ],
            [
              2.6595374969188,
              43.48016534963
            ],
            [
              2.6679938118926,
              43.470045739812
            ],
            [
              2.6646131742074,
              43.463621465253
            ],
            [
              2.6477479384405,
              43.46889502584
            ],
            [
              2.6378726798682,
              43.465226848944
            ],
            [
              2.6461799185216,
              43.459128324826
            ],
            [
              2.6201477651364,
              43.441423623131
            ],
            [
              2.6093386865917,
              43.440409050201
            ],
            [
              2.6060762155363,
              43.431097353655
            ],
            [
              2.5901376675255,
              43.429563678533
            ],
            [
              2.5840785671228,
              43.425524480755
            ],
            [
              2.5657824745312,
              43.422957925066
            ],
            [
              2.5515253933938,
              43.424964573472
            ],
            [
              2.5192290328736,
              43.423576425204
            ],
            [
              2.4973981397447,
              43.433963920582
            ],
            [
              2.4968512219793,
              43.436930315048
            ],
            [
              2.473998212403,
              43.436557283321
            ],
            [
              2.4583054839336,
              43.433107071969
            ],
            [
              2.4331067416938,
              43.435174281522
            ],
            [
              2.4069710225399,
              43.424831119141
            ],
            [
              2.4036354484854,
              43.418668029268
            ],
            [
              2.3913458849515,
              43.418930753382
            ],
            [
              2.3771444963265,
              43.423399693102
            ],
            [
              2.3672075625877,
              43.423222447693
            ],
            [
              2.3510335480095,
              43.431916671343
            ],
            [
              2.3312654102165,
              43.437946745339
            ],
            [
              2.3113702716933,
              43.441548673638
            ],
            [
              2.3029806665229,
              43.447935084107
            ],
            [
              2.2820110776793,
              43.441222270986
            ],
            [
              2.2591017375848,
              43.459927734352
            ],
            [
              2.250998241094,
              43.449381408091
            ],
            [
              2.2404078352143,
              43.440294444075
            ],
            [
              2.2241702265958,
              43.431765391026
            ],
            [
              2.2229731201607,
              43.419302330127
            ],
            [
              2.2297838407245,
              43.410169660932
            ],
            [
              2.2252355421611,
              43.39729608099
            ],
            [
              2.215023822395,
              43.383508824887
            ],
            [
              2.2028556152946,
              43.386693811362
            ],
            [
              2.1877018669943,
              43.393900936651
            ],
            [
              2.171548492892,
              43.415775006477
            ],
            [
              2.1594556186559,
              43.415531590815
            ],
            [
              2.1407815147228,
              43.404247749359
            ],
            [
              2.1335608464159,
              43.40356043581
            ],
            [
              2.110382625502,
              43.39595635372
            ],
            [
              2.086671283238,
              43.398779217594
            ],
            [
              2.0727969810099,
              43.395690514022
            ],
            [
              2.066365605718,
              43.411113686999
            ],
            [
              2.0531291058482,
              43.430203153623
            ],
            [
              2.0291341188294,
              43.436895277153
            ],
            [
              2.0266994567249,
              43.44228890617
            ],
            [
              2.0148167330533,
              43.451101506091
            ],
            [
              2.0196450442889,
              43.459246554061
            ],
            [
              2.0181437836738,
              43.470348442778
            ],
            [
              2.026329099839,
              43.476357926454
            ],
            [
              2.0407872240535,
              43.477544007193
            ],
            [
              2.0478554672695,
              43.485548435659
            ],
            [
              2.0464295682875,
              43.505060766208
            ],
            [
              2.0331409505587,
              43.499554418548
            ],
            [
              2.0295771126208,
              43.505799389397
            ],
            [
              2.0150520675645,
              43.50436470619
            ],
            [
              2.0150663583376,
              43.488311172078
            ],
            [
              2.0111491389873,
              43.482981325752
            ],
            [
              1.9959856098014,
              43.480465716523
            ],
            [
              1.9832882241816,
              43.48158997494
            ],
            [
              1.9756542305068,
              43.486799753546
            ],
            [
              1.956081247189,
              43.494065020813
            ],
            [
              1.9525098902706,
              43.490444984456
            ],
            [
              1.920566595322,
              43.505137427282
            ],
            [
              1.9169505336115,
              43.50160930902
            ],
            [
              1.8989231523348,
              43.510263764802
            ],
            [
              1.8826094690289,
              43.520927860082
            ],
            [
              1.8796351793718,
              43.529956450959
            ],
            [
              1.8713729178239,
              43.534288918331
            ],
            [
              1.8804165252883,
              43.540313270153
            ],
            [
              1.8511373007758,
              43.550314794176
            ],
            [
              1.8494460587001,
              43.567545793889
            ],
            [
              1.8392257690126,
              43.575785768957
            ],
            [
              1.8247972824309,
              43.580828127504
            ],
            [
              1.8110012018522,
              43.579301198247
            ],
            [
              1.7794536447365,
              43.586979802902
            ],
            [
              1.7720884213857,
              43.592792716782
            ],
            [
              1.771151967407,
              43.600041383477
            ],
            [
              1.7639573215367,
              43.603567368823
            ],
            [
              1.7450441838395,
              43.603461248103
            ],
            [
              1.7237109073222,
              43.612393386183
            ],
            [
              1.7148670283401,
              43.619726770221
            ],
            [
              1.7044492798514,
              43.618043561506
            ],
            [
              1.6967356419966,
              43.620793211757
            ],
            [
              1.6877759862105,
              43.630703132827
            ],
            [
              1.6952537111454,
              43.643629456049
            ],
            [
              1.7072998013738,
              43.65298396886
            ],
            [
              1.7308742785873,
              43.657753133846
            ],
            [
              1.7309380903003,
              43.663381060109
            ],
            [
              1.7202812751098,
              43.674529538425
            ],
            [
              1.7206413832202,
              43.688167736192
            ],
            [
              1.6997388434768,
              43.692026379488
            ],
            [
              1.6646839110227,
              43.693794113527
            ],
            [
              1.6653481633415,
              43.701537347267
            ],
            [
              1.6825724725338,
              43.703729811006
            ],
            [
              1.7064455556732,
              43.716221313812
            ],
            [
              1.693457231213,
              43.729376621519
            ],
            [
              1.6604226968466,
              43.741832240731
            ],
            [
              1.6499126393771,
              43.751867766314
            ],
            [
              1.6557025901135,
              43.764266528178
            ],
            [
              1.6516592656224,
              43.775541217456
            ],
            [
              1.6424939757726,
              43.778367726919
            ],
            [
              1.6447839642588,
              43.800254749798
            ],
            [
              1.6265009595646,
              43.801304847266
            ],
            [
              1.5910281145229,
              43.815485724058
            ],
            [
              1.587984360995,
              43.820306547566
            ],
            [
              1.5931742214888,
              43.843135721198
            ],
            [
              1.5725785900956,
              43.848799533555
            ],
            [
              1.5554658239149,
              43.868311833996
            ],
            [
              1.556940619168,
              43.87493967977
            ],
            [
              1.5466147655792,
              43.902455958048
            ],
            [
              1.5556221518593,
              43.918317220987
            ],
            [
              1.5708677048136,
              43.918596968734
            ],
            [
              1.5735591716324,
              43.936659348381
            ],
            [
              1.5641234081233,
              43.944342393345
            ],
            [
              1.5439759556659,
              43.946273875917
            ],
            [
              1.5522938899196,
              43.963030279892
            ],
            [
              1.5667754079294,
              43.965327398668
            ],
            [
              1.5925107658824,
              43.960148587713
            ],
            [
              1.6127129703754,
              43.959062098184
            ],
            [
              1.6227242760366,
              43.960701784939
            ],
            [
              1.6185728912685,
              43.973998052574
            ],
            [
              1.6372171031939,
              43.994681793815
            ],
            [
              1.6541371392134,
              43.992982164654
            ],
            [
              1.6573554189451,
              43.999799618572
            ],
            [
              1.650323791954,
              44.009699061537
            ],
            [
              1.6558352848561,
              44.013338625134
            ],
            [
              1.6713904052082,
              44.010639234268
            ],
            [
              1.6749695709071,
              44.019930532035
            ],
            [
              1.6913899538876,
              44.02434433999
            ],
            [
              1.695441208985,
              44.035211363548
            ],
            [
              1.7042131333594,
              44.044352900247
            ],
            [
              1.6961990463285,
              44.057540195088
            ],
            [
              1.6770048722111,
              44.066524762033
            ],
            [
              1.6710889669623,
              44.05955707594
            ],
            [
              1.6629097387301,
              44.071594260537
            ],
            [
              1.6688442064971,
              44.081890546106
            ],
            [
              1.66781321718,
              44.089872352221
            ],
            [
              1.6531263175883,
              44.096444024894
            ],
            [
              1.6525632821716,
              44.107877402528
            ],
            [
              1.6589622702651,
              44.116413961732
            ],
            [
              1.6839104668449,
              44.114399346103
            ],
            [
              1.7117518419293,
              44.115687033381
            ],
            [
              1.7229444919756,
              44.114752230231
            ],
            [
              1.7455437597019,
              44.116270767653
            ],
            [
              1.7518592646477,
              44.1090128733
            ],
            [
              1.7637374055675,
              44.10794311201
            ],
            [
              1.7775240504059,
              44.096811698741
            ],
            [
              1.7881288315301,
              44.104597869321
            ],
            [
              1.7899975574722,
              44.112709077891
            ],
            [
              1.8013071852998,
              44.118932798515
            ],
            [
              1.8061178914166,
              44.126374678566
            ],
            [
              1.8255195770339,
              44.107971891978
            ],
            [
              1.8367683217244,
              44.10747617876
            ],
            [
              1.8405146939396,
              44.114290362689
            ],
            [
              1.831536101766,
              44.127438906123
            ],
            [
              1.831339725783,
              44.135292464179
            ],
            [
              1.8417040138211,
              44.143688400107
            ],
            [
              1.8500315104458,
              44.135121056377
            ],
            [
              1.8600907024275,
              44.142907120015
            ],
            [
              1.8682046066748,
              44.143166702636
            ],
            [
              1.8804297121159,
              44.138489901489
            ],
            [
              1.8953609440009,
              44.140259818801
            ],
            [
              1.9043687934515,
              44.129856314059
            ],
            [
              1.9192879071156,
              44.141387610743
            ],
            [
              1.9064697607528,
              44.150517658622
            ],
            [
              1.8943672166068,
              44.155558695048
            ],
            [
              1.9088700837322,
              44.163483988624
            ],
            [
              1.9196908773715,
              44.162942084023
            ],
            [
              1.947058190778,
              44.146705806458
            ],
            [
              1.9535388405354,
              44.150370217992
            ],
            [
              1.969523100131,
              44.142684061776
            ],
            [
              1.990172010571,
              44.149449875043
            ]
          ]
        ]
      },
      "properties": {
        "code": "81",
        "nom": "Tarn",
        "population": 391066,
        "delitspour1000": "4,224",
        "nombremosque": 17,
        "parentsEtrangers": "80,5",
        "benefRsa": 20803,
        "eoliennes": 119
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1.0640814762214,
              44.378508721439
            ],
            [
              1.0824000913579,
              44.381409106067
            ],
            [
              1.1035062544519,
              44.392306175475
            ],
            [
              1.113581510295,
              44.391324005037
            ],
            [
              1.1325463304764,
              44.393331095059
            ],
            [
              1.1347841409898,
              44.379001901251
            ],
            [
              1.1274389106322,
              44.372985753369
            ],
            [
              1.110748036109,
              44.370191952886
            ],
            [
              1.1071599922422,
              44.366598851034
            ],
            [
              1.0935259886791,
              44.365959224021
            ],
            [
              1.0912647526615,
              44.359943917324
            ],
            [
              1.0812215694247,
              44.354388321287
            ],
            [
              1.0897034112701,
              44.347776457751
            ],
            [
              1.1032236287631,
              44.346211355086
            ],
            [
              1.11341021313,
              44.338851885589
            ],
            [
              1.1079322101063,
              44.327405475254
            ],
            [
              1.1104058105295,
              44.323116166197
            ],
            [
              1.1252404136057,
              44.315439371114
            ],
            [
              1.1359881581847,
              44.317222086283
            ],
            [
              1.1460615025306,
              44.308815289657
            ],
            [
              1.1593432965978,
              44.310622218703
            ],
            [
              1.1684135504581,
              44.305176929208
            ],
            [
              1.1812474366675,
              44.30737621701
            ],
            [
              1.177527462072,
              44.292155931446
            ],
            [
              1.1845970264102,
              44.286843708109
            ],
            [
              1.2046104960799,
              44.282089030781
            ],
            [
              1.2170572336728,
              44.276902146179
            ],
            [
              1.2251735135029,
              44.27952570615
            ],
            [
              1.2500166763573,
              44.272900537029
            ],
            [
              1.2554819885111,
              44.285553546834
            ],
            [
              1.2717164107238,
              44.281701772898
            ],
            [
              1.2826505678741,
              44.290126601767
            ],
            [
              1.2984232978299,
              44.294998753146
            ],
            [
              1.3036419786638,
              44.293780222919
            ],
            [
              1.2942283079115,
              44.269931405949
            ],
            [
              1.3040561797661,
              44.262956553109
            ],
            [
              1.2842803990087,
              44.251751936818
            ],
            [
              1.2862503206206,
              44.242724546526
            ],
            [
              1.2813755448768,
              44.235788833792
            ],
            [
              1.3068776511303,
              44.226976620673
            ],
            [
              1.3205552714658,
              44.232050137303
            ],
            [
              1.3363963237902,
              44.228164291061
            ],
            [
              1.3567965742905,
              44.204018679795
            ],
            [
              1.3803669452448,
              44.224855014913
            ],
            [
              1.4223000036257,
              44.242769039907
            ],
            [
              1.4309892807553,
              44.243934249729
            ],
            [
              1.4392544450401,
              44.250013706447
            ],
            [
              1.4535590038918,
              44.254122364815
            ],
            [
              1.4531793595742,
              44.267176611688
            ],
            [
              1.4618426585284,
              44.26737925836
            ],
            [
              1.4732171576142,
              44.284206796054
            ],
            [
              1.4951437462566,
              44.271095820859
            ],
            [
              1.5087806340611,
              44.273638671169
            ],
            [
              1.5205325763103,
              44.263266971406
            ],
            [
              1.518246985951,
              44.249518712925
            ],
            [
              1.5290844067842,
              44.23537357693
            ],
            [
              1.5417602550587,
              44.227813925717
            ],
            [
              1.5752287750623,
              44.238734921684
            ],
            [
              1.5872487604831,
              44.249006343623
            ],
            [
              1.5800311670669,
              44.260206126797
            ],
            [
              1.5770804296349,
              44.270221202549
            ],
            [
              1.5632414387973,
              44.274886379262
            ],
            [
              1.5624962356347,
              44.27966878594
            ],
            [
              1.5777775117549,
              44.284496801325
            ],
            [
              1.569137976709,
              44.298392987101
            ],
            [
              1.577057905448,
              44.30164046075
            ],
            [
              1.5872383982734,
              44.298779228534
            ],
            [
              1.5932068472358,
              44.302911512641
            ],
            [
              1.6055857426408,
              44.295656355547
            ],
            [
              1.6169053391703,
              44.297530575144
            ],
            [
              1.6156752023963,
              44.278396127792
            ],
            [
              1.6332195435462,
              44.269409693186
            ],
            [
              1.6422766943958,
              44.270771530189
            ],
            [
              1.6516181749095,
              44.286809376053
            ],
            [
              1.6319878111146,
              44.293887757522
            ],
            [
              1.6370645866245,
              44.298462052118
            ],
            [
              1.6466377173529,
              44.294790204319
            ],
            [
              1.6628876357397,
              44.292814929567
            ],
            [
              1.6790543823345,
              44.300601364801
            ],
            [
              1.6994966069311,
              44.313816007339
            ],
            [
              1.7151285678926,
              44.313035587615
            ],
            [
              1.7318042883639,
              44.317812372941
            ],
            [
              1.7373556664188,
              44.326979663175
            ],
            [
              1.7553567443039,
              44.325817340987
            ],
            [
              1.768544261781,
              44.314939492971
            ],
            [
              1.7819129603983,
              44.314472615323
            ],
            [
              1.7873478934768,
              44.323581975324
            ],
            [
              1.7833453885707,
              44.327943604564
            ],
            [
              1.8059777797489,
              44.336632362484
            ],
            [
              1.8094302106683,
              44.329086072158
            ],
            [
              1.8270255325815,
              44.32426884987
            ],
            [
              1.8332009402511,
              44.335457059576
            ],
            [
              1.8456122621165,
              44.33613444695
            ],
            [
              1.8556031233321,
              44.332582043853
            ],
            [
              1.8820777699351,
              44.340068798134
            ],
            [
              1.8646156409988,
              44.327531463723
            ],
            [
              1.8595915272095,
              44.32030639381
            ],
            [
              1.8768599447034,
              44.308592044125
            ],
            [
              1.880907610265,
              44.303474113866
            ],
            [
              1.8687816562037,
              44.292038697128
            ],
            [
              1.901394263774,
              44.279116501007
            ],
            [
              1.9135901461959,
              44.281862747866
            ],
            [
              1.9365279254051,
              44.280870004094
            ],
            [
              1.9402721006208,
              44.282547245855
            ],
            [
              1.9704399951605,
              44.276438944388
            ],
            [
              1.9643948582833,
              44.261383958306
            ],
            [
              1.9598380540971,
              44.241073132563
            ],
            [
              1.946028366781,
              44.246698189197
            ],
            [
              1.9315885634669,
              44.240873980562
            ],
            [
              1.9192741712379,
              44.224712150131
            ],
            [
              1.9049085707423,
              44.210211894056
            ],
            [
              1.8906741441948,
              44.213459059749
            ],
            [
              1.8903461983645,
              44.207567652297
            ],
            [
              1.9021964370883,
              44.20432463942
            ],
            [
              1.9054139151361,
              44.192369149573
            ],
            [
              1.9110879137292,
              44.185639221414
            ],
            [
              1.9279012490701,
              44.190202994504
            ],
            [
              1.9351355599822,
              44.181360909825
            ],
            [
              1.9334983119119,
              44.174421873614
            ],
            [
              1.9415206386057,
              44.170719809307
            ],
            [
              1.9444569103612,
              44.181304253281
            ],
            [
              1.9589422232293,
              44.184503189761
            ],
            [
              1.9766780022111,
              44.179923505077
            ],
            [
              1.9730953768159,
              44.171630223821
            ],
            [
              1.9770578565103,
              44.162575740805
            ],
            [
              1.9963637896774,
              44.160088765559
            ],
            [
              1.990172010571,
              44.149449875043
            ],
            [
              1.969523100131,
              44.142684061776
            ],
            [
              1.9535388405354,
              44.150370217992
            ],
            [
              1.947058190778,
              44.146705806458
            ],
            [
              1.9196908773715,
              44.162942084023
            ],
            [
              1.9088700837322,
              44.163483988624
            ],
            [
              1.8943672166068,
              44.155558695048
            ],
            [
              1.9064697607528,
              44.150517658622
            ],
            [
              1.9192879071156,
              44.141387610743
            ],
            [
              1.9043687934515,
              44.129856314059
            ],
            [
              1.8953609440009,
              44.140259818801
            ],
            [
              1.8804297121159,
              44.138489901489
            ],
            [
              1.8682046066748,
              44.143166702636
            ],
            [
              1.8600907024275,
              44.142907120015
            ],
            [
              1.8500315104458,
              44.135121056377
            ],
            [
              1.8417040138211,
              44.143688400107
            ],
            [
              1.831339725783,
              44.135292464179
            ],
            [
              1.831536101766,
              44.127438906123
            ],
            [
              1.8405146939396,
              44.114290362689
            ],
            [
              1.8367683217244,
              44.10747617876
            ],
            [
              1.8255195770339,
              44.107971891978
            ],
            [
              1.8061178914166,
              44.126374678566
            ],
            [
              1.8013071852998,
              44.118932798515
            ],
            [
              1.7899975574722,
              44.112709077891
            ],
            [
              1.7881288315301,
              44.104597869321
            ],
            [
              1.7775240504059,
              44.096811698741
            ],
            [
              1.7637374055675,
              44.10794311201
            ],
            [
              1.7518592646477,
              44.1090128733
            ],
            [
              1.7455437597019,
              44.116270767653
            ],
            [
              1.7229444919756,
              44.114752230231
            ],
            [
              1.7117518419293,
              44.115687033381
            ],
            [
              1.6839104668449,
              44.114399346103
            ],
            [
              1.6589622702651,
              44.116413961732
            ],
            [
              1.6525632821716,
              44.107877402528
            ],
            [
              1.6531263175883,
              44.096444024894
            ],
            [
              1.66781321718,
              44.089872352221
            ],
            [
              1.6688442064971,
              44.081890546106
            ],
            [
              1.6629097387301,
              44.071594260537
            ],
            [
              1.6710889669623,
              44.05955707594
            ],
            [
              1.6770048722111,
              44.066524762033
            ],
            [
              1.6961990463285,
              44.057540195088
            ],
            [
              1.7042131333594,
              44.044352900247
            ],
            [
              1.695441208985,
              44.035211363548
            ],
            [
              1.6913899538876,
              44.02434433999
            ],
            [
              1.6749695709071,
              44.019930532035
            ],
            [
              1.6713904052082,
              44.010639234268
            ],
            [
              1.6558352848561,
              44.013338625134
            ],
            [
              1.650323791954,
              44.009699061537
            ],
            [
              1.6573554189451,
              43.999799618572
            ],
            [
              1.6541371392134,
              43.992982164654
            ],
            [
              1.6372171031939,
              43.994681793815
            ],
            [
              1.6185728912685,
              43.973998052574
            ],
            [
              1.6227242760366,
              43.960701784939
            ],
            [
              1.6127129703754,
              43.959062098184
            ],
            [
              1.5925107658824,
              43.960148587713
            ],
            [
              1.5667754079294,
              43.965327398668
            ],
            [
              1.5522938899196,
              43.963030279892
            ],
            [
              1.5439759556659,
              43.946273875917
            ],
            [
              1.5641234081233,
              43.944342393345
            ],
            [
              1.5735591716324,
              43.936659348381
            ],
            [
              1.5708677048136,
              43.918596968734
            ],
            [
              1.5556221518593,
              43.918317220987
            ],
            [
              1.5418893832299,
              43.920240096152
            ],
            [
              1.5322237985211,
              43.916798260663
            ],
            [
              1.523322371648,
              43.903596210866
            ],
            [
              1.4907868077854,
              43.888999108141
            ],
            [
              1.4874153887075,
              43.902476463
            ],
            [
              1.4746636959499,
              43.902115458383
            ],
            [
              1.4478797684927,
              43.873553033921
            ],
            [
              1.4189366610131,
              43.871354483119
            ],
            [
              1.4086084987937,
              43.882645645611
            ],
            [
              1.3764748357432,
              43.884173404419
            ],
            [
              1.3655602395576,
              43.889308653331
            ],
            [
              1.3568109611099,
              43.884187014454
            ],
            [
              1.3561495730503,
              43.874911201495
            ],
            [
              1.3483969598989,
              43.869806595654
            ],
            [
              1.3626055596172,
              43.85723191577
            ],
            [
              1.3530880218721,
              43.852965271749
            ],
            [
              1.3302991148759,
              43.851102196107
            ],
            [
              1.3194148108275,
              43.858067599047
            ],
            [
              1.2967803252843,
              43.847064583304
            ],
            [
              1.3190371584252,
              43.833403208554
            ],
            [
              1.3280309380826,
              43.83816259068
            ],
            [
              1.3479910260216,
              43.838157092318
            ],
            [
              1.3572717256247,
              43.833692361077
            ],
            [
              1.3608913400167,
              43.821076224793
            ],
            [
              1.343657906713,
              43.811021938492
            ],
            [
              1.3264754705083,
              43.808983662362
            ],
            [
              1.311084758739,
              43.800604994573
            ],
            [
              1.2826317482256,
              43.796160407479
            ],
            [
              1.277002223347,
              43.801823176343
            ],
            [
              1.2668725426848,
              43.79862205505
            ],
            [
              1.2749309209595,
              43.785827252918
            ],
            [
              1.2191983063023,
              43.770780304363
            ],
            [
              1.2107398584006,
              43.770889245129
            ],
            [
              1.2085014311128,
              43.779699543917
            ],
            [
              1.2190419704323,
              43.786056932198
            ],
            [
              1.2128576267319,
              43.792879311167
            ],
            [
              1.198420604335,
              43.79210875536
            ],
            [
              1.1795521022992,
              43.79565762568
            ],
            [
              1.173658186853,
              43.804683573686
            ],
            [
              1.1648406594297,
              43.807879467346
            ],
            [
              1.1577953167865,
              43.818384029924
            ],
            [
              1.1446014589289,
              43.822294665662
            ],
            [
              1.1260842958968,
              43.809592916434
            ],
            [
              1.1148795190323,
              43.797770816281
            ],
            [
              1.1007127244889,
              43.80711320984
            ],
            [
              1.0852689121207,
              43.814506045527
            ],
            [
              1.0581350801514,
              43.798672133086
            ],
            [
              1.0215610439646,
              43.801408908872
            ],
            [
              0.99104705807701,
              43.797042144685
            ],
            [
              0.97779031654791,
              43.786433294204
            ],
            [
              0.95599575410245,
              43.7893285138
            ],
            [
              0.95398321315289,
              43.787369767012
            ],
            [
              0.9412056241659,
              43.791966691597
            ],
            [
              0.92349730113,
              43.789986071471
            ],
            [
              0.91572276222962,
              43.78437620761
            ],
            [
              0.90615590240468,
              43.784326922501
            ],
            [
              0.89718573970385,
              43.790622983363
            ],
            [
              0.90405560048524,
              43.807937430743
            ],
            [
              0.92585656217094,
              43.831629205755
            ],
            [
              0.90843104231164,
              43.834610984174
            ],
            [
              0.89541680754323,
              43.840345297757
            ],
            [
              0.89474229882734,
              43.850280699189
            ],
            [
              0.91270345397669,
              43.848486016154
            ],
            [
              0.89943420695308,
              43.864701960809
            ],
            [
              0.88349353633238,
              43.875251771074
            ],
            [
              0.88398989930177,
              43.890841157571
            ],
            [
              0.90458454017881,
              43.899017505511
            ],
            [
              0.88826732924193,
              43.909347809317
            ],
            [
              0.87397308254597,
              43.913391733353
            ],
            [
              0.8628950716946,
              43.923285613293
            ],
            [
              0.85092679438503,
              43.921423062399
            ],
            [
              0.84005803336268,
              43.916329110519
            ],
            [
              0.82125647912065,
              43.924727166566
            ],
            [
              0.80929545624161,
              43.932301615691
            ],
            [
              0.79217468970115,
              43.926246280202
            ],
            [
              0.76994232659412,
              43.922318519181
            ],
            [
              0.76250323120863,
              43.931192014249
            ],
            [
              0.76008372909928,
              43.942238869568
            ],
            [
              0.76964774157407,
              43.951236983097
            ],
            [
              0.78484170552922,
              43.955244573648
            ],
            [
              0.79246492768911,
              43.964848615804
            ],
            [
              0.79677301050442,
              43.975618811303
            ],
            [
              0.81362540509461,
              43.982893272029
            ],
            [
              0.81656580710086,
              43.989625265229
            ],
            [
              0.8277004422891,
              43.999575095717
            ],
            [
              0.81765350525156,
              44.009614598
            ],
            [
              0.81955190581301,
              44.015944939424
            ],
            [
              0.81364104682038,
              44.024908754769
            ],
            [
              0.82240759655087,
              44.028448186779
            ],
            [
              0.83956279350669,
              44.023991248727
            ],
            [
              0.84622635198938,
              44.031735004924
            ],
            [
              0.86041116085683,
              44.040785300803
            ],
            [
              0.85051060059395,
              44.047539266803
            ],
            [
              0.84107000320885,
              44.045755333082
            ],
            [
              0.82735257549546,
              44.055250960736
            ],
            [
              0.81746969640191,
              44.054892293483
            ],
            [
              0.81059445113324,
              44.048722562007
            ],
            [
              0.77578049443384,
              44.035914254363
            ],
            [
              0.76552658101403,
              44.030150980746
            ],
            [
              0.75678947836038,
              44.039978938462
            ],
            [
              0.75520321887022,
              44.052608430258
            ],
            [
              0.74892669642136,
              44.065663890895
            ],
            [
              0.741884791205,
              44.065199235038
            ],
            [
              0.73810974125492,
              44.073027677
            ],
            [
              0.75287865013836,
              44.102267910932
            ],
            [
              0.77191199947865,
              44.113420095124
            ],
            [
              0.78623555942697,
              44.11191141984
            ],
            [
              0.79623991760453,
              44.11512942813
            ],
            [
              0.79756644493954,
              44.130381902776
            ],
            [
              0.7885669186743,
              44.144391188204
            ],
            [
              0.79610189105916,
              44.145621618679
            ],
            [
              0.82440975793604,
              44.141179876681
            ],
            [
              0.83543363467659,
              44.136365774298
            ],
            [
              0.86877875307642,
              44.126327092678
            ],
            [
              0.87944269844653,
              44.129608737841
            ],
            [
              0.88180706484803,
              44.140904730858
            ],
            [
              0.88847935271951,
              44.148766853903
            ],
            [
              0.88663373024812,
              44.16278908945
            ],
            [
              0.89034197833787,
              44.169672870511
            ],
            [
              0.88301819652021,
              44.17546628714
            ],
            [
              0.8722469128314,
              44.167867373919
            ],
            [
              0.86504004908695,
              44.173534926921
            ],
            [
              0.85357025770147,
              44.174962426044
            ],
            [
              0.85633796923916,
              44.18904677194
            ],
            [
              0.86348768257448,
              44.193325437089
            ],
            [
              0.90628814214223,
              44.190306668919
            ],
            [
              0.91176049272424,
              44.204739962363
            ],
            [
              0.92476807979815,
              44.226051714552
            ],
            [
              0.9292406197485,
              44.230246542717
            ],
            [
              0.91909475906904,
              44.238271923817
            ],
            [
              0.93302754076927,
              44.253148853876
            ],
            [
              0.9277876570183,
              44.26740561543
            ],
            [
              0.94078442423987,
              44.263651200087
            ],
            [
              0.94992392368958,
              44.276443376947
            ],
            [
              0.92396339608302,
              44.288698042192
            ],
            [
              0.91642356261815,
              44.302198002519
            ],
            [
              0.89449665699205,
              44.296740377136
            ],
            [
              0.88231719491829,
              44.308198855394
            ],
            [
              0.86962451276277,
              44.309387172973
            ],
            [
              0.87347140241339,
              44.323307233
            ],
            [
              0.89611323540437,
              44.346226535055
            ],
            [
              0.89356714925376,
              44.358037988638
            ],
            [
              0.88733582833582,
              44.366374054101
            ],
            [
              0.89821258712664,
              44.381368271621
            ],
            [
              0.91991430872905,
              44.384149659695
            ],
            [
              0.92589588135671,
              44.375628735075
            ],
            [
              0.9372881287013,
              44.368840749909
            ],
            [
              0.94141859483151,
              44.345347538009
            ],
            [
              0.95004981400894,
              44.35975578355
            ],
            [
              0.97103609486138,
              44.361280217267
            ],
            [
              0.98063192933013,
              44.358423098349
            ],
            [
              0.9973306063079,
              44.368970904934
            ],
            [
              1.004557393851,
              44.365587714321
            ],
            [
              1.0497968480895,
              44.362639367374
            ],
            [
              1.0590342432563,
              44.369063397724
            ],
            [
              1.0640814762214,
              44.378508721439
            ]
          ]
        ]
      },
      "properties": {
        "code": "82",
        "nom": "Tarn-et-Garonne",
        "population": 262316,
        "delitspour1000": "4,235",
        "nombremosque": 12,
        "parentsEtrangers": "73,2",
        "benefRsa": 12484
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                6.4341485392957,
                43.013347028571
              ],
              [
                6.4339410610688,
                43.020253759896
              ],
              [
                6.4402634234376,
                43.024842658411
              ],
              [
                6.4552030224291,
                43.02681507814
              ],
              [
                6.4702163404405,
                43.045167505797
              ],
              [
                6.5041808620696,
                43.05334030784
              ],
              [
                6.5112885804875,
                43.046821992697
              ],
              [
                6.5047119129479,
                43.043075249916
              ],
              [
                6.4895900424486,
                43.042642232202
              ],
              [
                6.4710212810966,
                43.01921193325
              ],
              [
                6.4616995826693,
                43.013939106124
              ],
              [
                6.4528033958039,
                43.016843850293
              ],
              [
                6.4391012526205,
                43.004472055456
              ],
              [
                6.4341485392957,
                43.013347028571
              ]
            ]
          ],
          [
            [
              [
                6.3970913857265,
                42.992801774792
              ],
              [
                6.3850038068943,
                42.997874748913
              ],
              [
                6.3714123570963,
                42.997142408976
              ],
              [
                6.3697195012539,
                43.001244421426
              ],
              [
                6.3824109734744,
                43.011037691381
              ],
              [
                6.4085816077235,
                43.018603831541
              ],
              [
                6.4206661963932,
                43.013709224929
              ],
              [
                6.4069003940727,
                42.997223596606
              ],
              [
                6.3970913857265,
                42.992801774792
              ]
            ]
          ],
          [
            [
              [
                6.2440741459649,
                43.020035297662
              ],
              [
                6.2484869352116,
                43.016627467203
              ],
              [
                6.2504533863157,
                42.999408277823
              ],
              [
                6.2265230585799,
                42.992424259693
              ],
              [
                6.2164360797719,
                42.991108164979
              ],
              [
                6.2086710614315,
                42.98338081093
              ],
              [
                6.1978984464393,
                42.982043008785
              ],
              [
                6.1938577077542,
                42.99020258064
              ],
              [
                6.1773879229647,
                42.996127936721
              ],
              [
                6.1645371983983,
                42.997829141178
              ],
              [
                6.1712703057401,
                43.00620484955
              ],
              [
                6.1840052925618,
                43.008256946209
              ],
              [
                6.2016813018254,
                43.000829346611
              ],
              [
                6.2117350144,
                43.002561912277
              ],
              [
                6.2204147969509,
                43.012437419854
              ],
              [
                6.2339910204288,
                43.011656466491
              ],
              [
                6.2440741459649,
                43.020035297662
              ]
            ]
          ],
          [
            [
              [
                5.753644561032,
                43.724619684068
              ],
              [
                5.7573377215236,
                43.729404491649
              ],
              [
                5.7813443482122,
                43.75565465876
              ],
              [
                5.7994711414233,
                43.7510094975
              ],
              [
                5.8236689891547,
                43.748281261866
              ],
              [
                5.8373883741505,
                43.741475701408
              ],
              [
                5.8491411431165,
                43.720249991855
              ],
              [
                5.8562015702536,
                43.723583782294
              ],
              [
                5.8818420325261,
                43.723912733964
              ],
              [
                5.9012062080264,
                43.732585527498
              ],
              [
                5.9056553842608,
                43.73943006866
              ],
              [
                5.9045048630389,
                43.748205132796
              ],
              [
                5.9102713384672,
                43.753171194098
              ],
              [
                5.9254717898681,
                43.756915940863
              ],
              [
                5.9274624705326,
                43.750175516058
              ],
              [
                5.9387607480826,
                43.748781569852
              ],
              [
                5.9416167520954,
                43.739484477929
              ],
              [
                5.9404721367951,
                43.727336566191
              ],
              [
                5.9564452967405,
                43.719398427174
              ],
              [
                5.9689233683598,
                43.719718753178
              ],
              [
                5.9854389290259,
                43.713962195587
              ],
              [
                5.9879367874284,
                43.695053963247
              ],
              [
                6.0050060172337,
                43.687791494952
              ],
              [
                6.0113923155853,
                43.675012807317
              ],
              [
                6.0217364024135,
                43.668282105491
              ],
              [
                6.0379674665934,
                43.681720531593
              ],
              [
                6.036134340046,
                43.694164170854
              ],
              [
                6.0515573709742,
                43.69771276033
              ],
              [
                6.0773213370383,
                43.707880410642
              ],
              [
                6.0883249691755,
                43.718536956452
              ],
              [
                6.1028648287186,
                43.73634476066
              ],
              [
                6.1068224665182,
                43.744774442046
              ],
              [
                6.1297015416015,
                43.736218513297
              ],
              [
                6.1406045701782,
                43.737609222261
              ],
              [
                6.1542900886483,
                43.743634530344
              ],
              [
                6.1828886896128,
                43.762179115381
              ],
              [
                6.1885229282029,
                43.776270763771
              ],
              [
                6.1981790085091,
                43.788067549065
              ],
              [
                6.2167124047513,
                43.799511004589
              ],
              [
                6.2456394199293,
                43.802388130505
              ],
              [
                6.2542153063873,
                43.800431020995
              ],
              [
                6.2599275582753,
                43.792619169018
              ],
              [
                6.2628893399061,
                43.781883089329
              ],
              [
                6.3190052476717,
                43.754524225417
              ],
              [
                6.3262750948765,
                43.746870168479
              ],
              [
                6.3569569098109,
                43.736365981576
              ],
              [
                6.3720062958543,
                43.742907961785
              ],
              [
                6.3835455827762,
                43.734431200864
              ],
              [
                6.3944177782975,
                43.746256332737
              ],
              [
                6.4134803171307,
                43.760356001175
              ],
              [
                6.414425301941,
                43.791590041073
              ],
              [
                6.4339963811669,
                43.792103469968
              ],
              [
                6.4388022466454,
                43.797746288454
              ],
              [
                6.4565443095075,
                43.793409815159
              ],
              [
                6.4692907507952,
                43.798667641521
              ],
              [
                6.4820726945377,
                43.791077242351
              ],
              [
                6.5094345210308,
                43.800712946523
              ],
              [
                6.5173862912704,
                43.806770970879
              ],
              [
                6.5247646877683,
                43.806369493557
              ],
              [
                6.5413527450184,
                43.792871075899
              ],
              [
                6.5456804964732,
                43.78223076642
              ],
              [
                6.5548615750879,
                43.784119497484
              ],
              [
                6.573625361215,
                43.792480110013
              ],
              [
                6.5869124838076,
                43.805319550919
              ],
              [
                6.5994543566539,
                43.803567314158
              ],
              [
                6.6199664600085,
                43.805500121194
              ],
              [
                6.6279270938768,
                43.791568812159
              ],
              [
                6.6363906079569,
                43.788947137136
              ],
              [
                6.6377997453195,
                43.780959269326
              ],
              [
                6.6451865352721,
                43.774643391823
              ],
              [
                6.6393082606477,
                43.769187336968
              ],
              [
                6.6514591133944,
                43.765932123349
              ],
              [
                6.6586508318757,
                43.748576829963
              ],
              [
                6.6707481333912,
                43.75154132872
              ],
              [
                6.6827124803446,
                43.757940318117
              ],
              [
                6.700791591552,
                43.754314788969
              ],
              [
                6.71941591177,
                43.738141245079
              ],
              [
                6.7535373268357,
                43.738168735403
              ],
              [
                6.7565312672423,
                43.729552266493
              ],
              [
                6.7665867654022,
                43.720214921984
              ],
              [
                6.7675809286236,
                43.711770075969
              ],
              [
                6.7742456350571,
                43.7036421103
              ],
              [
                6.7743545020716,
                43.69341005262
              ],
              [
                6.7645656155136,
                43.687414652206
              ],
              [
                6.7608002192821,
                43.666488900835
              ],
              [
                6.7773854479855,
                43.658265712539
              ],
              [
                6.7939209319799,
                43.639319022493
              ],
              [
                6.7987395361277,
                43.628182176835
              ],
              [
                6.8173210868001,
                43.628709934933
              ],
              [
                6.8349114967132,
                43.619940414631
              ],
              [
                6.8387731211541,
                43.613612526665
              ],
              [
                6.8634189982789,
                43.605129030293
              ],
              [
                6.8879290053236,
                43.607412542097
              ],
              [
                6.8932899439034,
                43.611705853916
              ],
              [
                6.9080139239391,
                43.59907465015
              ],
              [
                6.9079709869474,
                43.594116034305
              ],
              [
                6.8984263507069,
                43.581563493959
              ],
              [
                6.9071682106677,
                43.572965802144
              ],
              [
                6.9029178018107,
                43.564799455437
              ],
              [
                6.8878058162612,
                43.552743885637
              ],
              [
                6.8795407658693,
                43.535357663712
              ],
              [
                6.8833123954425,
                43.52855037557
              ],
              [
                6.8963833605182,
                43.52715725565
              ],
              [
                6.8917133536696,
                43.513038158278
              ],
              [
                6.8842288648328,
                43.502535772082
              ],
              [
                6.9042116903824,
                43.494921296825
              ],
              [
                6.9206608907656,
                43.482066631594
              ],
              [
                6.9337211159516,
                43.480065494401
              ],
              [
                6.923867212973,
                43.470960837627
              ],
              [
                6.9243598479502,
                43.464066349035
              ],
              [
                6.9179721528571,
                43.447738536354
              ],
              [
                6.9051351716853,
                43.445353094761
              ],
              [
                6.8938948378079,
                43.436171115171
              ],
              [
                6.8918037687118,
                43.428580015191
              ],
              [
                6.8745353033552,
                43.426537753906
              ],
              [
                6.8652393715348,
                43.433930920766
              ],
              [
                6.8569996758254,
                43.430477732013
              ],
              [
                6.8594727968345,
                43.418159528215
              ],
              [
                6.8550478015615,
                43.411429429877
              ],
              [
                6.8425087566418,
                43.416314042768
              ],
              [
                6.8267609493565,
                43.417371848056
              ],
              [
                6.7988544528977,
                43.413719926554
              ],
              [
                6.7896945798181,
                43.408266782123
              ],
              [
                6.7634118379603,
                43.424488987703
              ],
              [
                6.750657595625,
                43.420845307432
              ],
              [
                6.7398086751923,
                43.41288238776
              ],
              [
                6.7295091701175,
                43.397245962638
              ],
              [
                6.7263132753371,
                43.383104485014
              ],
              [
                6.7143312441348,
                43.36790258606
              ],
              [
                6.7165464557638,
                43.347490862069
              ],
              [
                6.7099603836418,
                43.344598306055
              ],
              [
                6.6816193520365,
                43.340959774178
              ],
              [
                6.6689910907851,
                43.33292829161
              ],
              [
                6.6659564383834,
                43.31821983472
              ],
              [
                6.6504467498051,
                43.30930360706
              ],
              [
                6.6286232743314,
                43.304542794427
              ],
              [
                6.6212969072192,
                43.296033806007
              ],
              [
                6.6017039878442,
                43.285829711816
              ],
              [
                6.5899812585712,
                43.28243883097
              ],
              [
                6.5838742224265,
                43.277308105612
              ],
              [
                6.585990659385,
                43.264473629553
              ],
              [
                6.5934629712796,
                43.262265776308
              ],
              [
                6.6234880954753,
                43.265350514242
              ],
              [
                6.6457979042857,
                43.273835088392
              ],
              [
                6.662121282463,
                43.264909792407
              ],
              [
                6.6695313240363,
                43.268462885728
              ],
              [
                6.6704136972555,
                43.27640069302
              ],
              [
                6.6773068762378,
                43.278794827575
              ],
              [
                6.6972545497172,
                43.265497283834
              ],
              [
                6.6904226539265,
                43.253768484541
              ],
              [
                6.676365540846,
                43.244474024822
              ],
              [
                6.6685654818411,
                43.245554369751
              ],
              [
                6.6635799631008,
                43.238043384914
              ],
              [
                6.6626657632275,
                43.217572199696
              ],
              [
                6.677034229563,
                43.197186519392
              ],
              [
                6.6593271741191,
                43.195930208865
              ],
              [
                6.6420225769795,
                43.183734132211
              ],
              [
                6.6355350265918,
                43.172509449193
              ],
              [
                6.6236623943555,
                43.165300606932
              ],
              [
                6.612901777714,
                43.165892299234
              ],
              [
                6.6004702842875,
                43.182582281991
              ],
              [
                6.5919303129754,
                43.185477402074
              ],
              [
                6.5596377554811,
                43.188632638495
              ],
              [
                6.541396288166,
                43.182330817397
              ],
              [
                6.534084190046,
                43.172782676664
              ],
              [
                6.5363193464327,
                43.165106616411
              ],
              [
                6.5228061651254,
                43.165323967594
              ],
              [
                6.5087687818106,
                43.156648567314
              ],
              [
                6.4934958461812,
                43.150223730387
              ],
              [
                6.4883740268723,
                43.152806138202
              ],
              [
                6.4641477513921,
                43.157528246642
              ],
              [
                6.4492154874295,
                43.153387552888
              ],
              [
                6.44429973474,
                43.145456324914
              ],
              [
                6.4290707640808,
                43.151423030929
              ],
              [
                6.4051586594236,
                43.148994996899
              ],
              [
                6.3875680181733,
                43.144900254259
              ],
              [
                6.3802996760452,
                43.138559151429
              ],
              [
                6.3687216170301,
                43.136975338721
              ],
              [
                6.3597633175514,
                43.119904355977
              ],
              [
                6.3598808549228,
                43.105074542854
              ],
              [
                6.368364474458,
                43.102269062044
              ],
              [
                6.3682566796679,
                43.089914872597
              ],
              [
                6.3492877575107,
                43.088191211862
              ],
              [
                6.3325137465072,
                43.091093655786
              ],
              [
                6.3253619900804,
                43.095399033313
              ],
              [
                6.3166670705359,
                43.106166407372
              ],
              [
                6.3092405738884,
                43.108321309527
              ],
              [
                6.2882203945084,
                43.108702218392
              ],
              [
                6.2881595756018,
                43.114200760657
              ],
              [
                6.274941161023,
                43.120741066834
              ],
              [
                6.2652965375011,
                43.121041023451
              ],
              [
                6.2343366461075,
                43.113014447828
              ],
              [
                6.2080853497602,
                43.116444167346
              ],
              [
                6.2007847463615,
                43.115790863382
              ],
              [
                6.1839926131867,
                43.108536285948
              ],
              [
                6.1704563295787,
                43.0997558313
              ],
              [
                6.1598371974529,
                43.088451636416
              ],
              [
                6.150270989362,
                43.064453888882
              ],
              [
                6.1499595681806,
                43.045856056649
              ],
              [
                6.1531565349953,
                43.035245722363
              ],
              [
                6.147899852463,
                43.026960466647
              ],
              [
                6.1368994767253,
                43.030616602392
              ],
              [
                6.1339953631425,
                43.036214235727
              ],
              [
                6.120936317207,
                43.032186182571
              ],
              [
                6.115004757531,
                43.034956601301
              ],
              [
                6.1025906616279,
                43.028782647513
              ],
              [
                6.0918311311331,
                43.036774442924
              ],
              [
                6.1047189809048,
                43.041956746957
              ],
              [
                6.1167138627143,
                43.039240063655
              ],
              [
                6.1281920087398,
                43.042671102624
              ],
              [
                6.1323295631249,
                43.059513879555
              ],
              [
                6.124051799264,
                43.07930650726
              ],
              [
                6.1139934267722,
                43.083654682725
              ],
              [
                6.0812978668083,
                43.087226281584
              ],
              [
                6.0608714971149,
                43.081747669597
              ],
              [
                6.0314352379439,
                43.07747526022
              ],
              [
                6.0215343544984,
                43.078256714006
              ],
              [
                6.0168236605891,
                43.083635285965
              ],
              [
                6.0234652560844,
                43.094811520978
              ],
              [
                6.0050157080904,
                43.103799383815
              ],
              [
                5.9762269212434,
                43.10757701689
              ],
              [
                5.9662730514685,
                43.1056392255
              ],
              [
                5.9412493838579,
                43.107661088538
              ],
              [
                5.9257873894364,
                43.103970758155
              ],
              [
                5.9343359139287,
                43.118604366719
              ],
              [
                5.9074795895659,
                43.118881530843
              ],
              [
                5.8890107013515,
                43.11005127935
              ],
              [
                5.8868360567952,
                43.103450269366
              ],
              [
                5.901008542602,
                43.103996533879
              ],
              [
                5.909107117861,
                43.10046149114
              ],
              [
                5.8947710571836,
                43.081712418128
              ],
              [
                5.9095702721446,
                43.079393797213
              ],
              [
                5.9170857708081,
                43.085825411251
              ],
              [
                5.9289528079791,
                43.082919298522
              ],
              [
                5.9376580436388,
                43.08457851954
              ],
              [
                5.9509582392784,
                43.076765254429
              ],
              [
                5.943881221795,
                43.066409791665
              ],
              [
                5.9366458228053,
                43.071725835864
              ],
              [
                5.9285981186439,
                43.071716453532
              ],
              [
                5.9112894628203,
                43.06578413673
              ],
              [
                5.8984664585895,
                43.07758725099
              ],
              [
                5.8871334844754,
                43.077225735618
              ],
              [
                5.8657541086125,
                43.059881682488
              ],
              [
                5.8574553138035,
                43.046618874503
              ],
              [
                5.8276382705234,
                43.05034932291
              ],
              [
                5.8167124624351,
                43.062314625126
              ],
              [
                5.7941055326647,
                43.068774233408
              ],
              [
                5.8045644441502,
                43.078283139099
              ],
              [
                5.8105134830279,
                43.092326111559
              ],
              [
                5.8127322115996,
                43.109367157095
              ],
              [
                5.8069507607973,
                43.115679350616
              ],
              [
                5.7910184437069,
                43.112424881887
              ],
              [
                5.7886661988609,
                43.116377367101
              ],
              [
                5.7744948217719,
                43.116904330683
              ],
              [
                5.7736877374351,
                43.124358146456
              ],
              [
                5.7806944360543,
                43.129636959482
              ],
              [
                5.7712544214782,
                43.138932344182
              ],
              [
                5.7522954712541,
                43.13316137548
              ],
              [
                5.7258497190477,
                43.136527153376
              ],
              [
                5.7190149570316,
                43.147273734952
              ],
              [
                5.6948752166924,
                43.143589707468
              ],
              [
                5.682675442198,
                43.160132349422
              ],
              [
                5.69476068803,
                43.168021818342
              ],
              [
                5.6924387435758,
                43.174870299627
              ],
              [
                5.6845946967002,
                43.180061736494
              ],
              [
                5.6718787324219,
                43.179269594604
              ],
              [
                5.6755238732235,
                43.187448430379
              ],
              [
                5.6785440362111,
                43.205151381308
              ],
              [
                5.6779885853928,
                43.219782421707
              ],
              [
                5.6839089702289,
                43.236054698509
              ],
              [
                5.7045113698099,
                43.241654579484
              ],
              [
                5.7012219225146,
                43.247154351727
              ],
              [
                5.7208212008281,
                43.256400976392
              ],
              [
                5.7609266571978,
                43.267341658562
              ],
              [
                5.7628530190708,
                43.281689488403
              ],
              [
                5.7498397693557,
                43.289270917912
              ],
              [
                5.7375274826841,
                43.301485634246
              ],
              [
                5.726597317218,
                43.317359044169
              ],
              [
                5.6910426284831,
                43.312958284278
              ],
              [
                5.6839238953331,
                43.318142512414
              ],
              [
                5.6898026231243,
                43.327460094948
              ],
              [
                5.6890671733902,
                43.335214833248
              ],
              [
                5.6945534562629,
                43.345183887029
              ],
              [
                5.705027698264,
                43.355914795818
              ],
              [
                5.6945703861296,
                43.364398500004
              ],
              [
                5.687876696804,
                43.391770884707
              ],
              [
                5.6827924547281,
                43.399159444446
              ],
              [
                5.6964739843579,
                43.407109089482
              ],
              [
                5.7244316232622,
                43.408874721815
              ],
              [
                5.749991875502,
                43.401514019764
              ],
              [
                5.7763908538649,
                43.408958113867
              ],
              [
                5.7865119979741,
                43.414539503422
              ],
              [
                5.7883489025455,
                43.420260743373
              ],
              [
                5.7747402610239,
                43.421127031271
              ],
              [
                5.7518507411673,
                43.434296829412
              ],
              [
                5.7269896007069,
                43.467414035784
              ],
              [
                5.7149940417722,
                43.477219688103
              ],
              [
                5.69880518873,
                43.48217276293
              ],
              [
                5.7017834464565,
                43.491036844111
              ],
              [
                5.7161474770546,
                43.504297527881
              ],
              [
                5.7128740368715,
                43.512135014249
              ],
              [
                5.7173910449164,
                43.522170386566
              ],
              [
                5.7178587079294,
                43.538171472027
              ],
              [
                5.7246167566539,
                43.55090006967
              ],
              [
                5.7093234425576,
                43.554951530142
              ],
              [
                5.6958133469034,
                43.562143451646
              ],
              [
                5.6692560322541,
                43.564729029478
              ],
              [
                5.6560255946819,
                43.57399668679
              ],
              [
                5.6559638228901,
                43.577397571807
              ],
              [
                5.6916044198774,
                43.584431461466
              ],
              [
                5.6829174125015,
                43.601216985023
              ],
              [
                5.6821412890103,
                43.61373574878
              ],
              [
                5.7002189786378,
                43.641716920694
              ],
              [
                5.7096619573307,
                43.640930014563
              ],
              [
                5.7232211277818,
                43.649628603542
              ],
              [
                5.7448021509795,
                43.65678191082
              ],
              [
                5.7686441174641,
                43.660251427186
              ],
              [
                5.7990586792783,
                43.660518853996
              ],
              [
                5.8011497267432,
                43.671379290181
              ],
              [
                5.8132476569219,
                43.688962999872
              ],
              [
                5.7928455221277,
                43.692558168421
              ],
              [
                5.7821926879745,
                43.70688883877
              ],
              [
                5.7854338746866,
                43.713506618326
              ],
              [
                5.7677160316456,
                43.722508794856
              ],
              [
                5.753644561032,
                43.724619684068
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "83",
        "nom": "Var",
        "population": 1085189,
        "delitspour1000": "6,079",
        "nombremosque": 35,
        "parentsEtrangers": "75,2",
        "benefRsa": 61019,
        "eoliennes": 24
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -2.3047881362997,
                46.709424180555
              ],
              [
                -2.2861197201191,
                46.695671725007
              ],
              [
                -2.301824987103,
                46.692466226408
              ],
              [
                -2.3141842217392,
                46.695297761013
              ],
              [
                -2.3220102228855,
                46.689370859197
              ],
              [
                -2.3339086309831,
                46.686335754046
              ],
              [
                -2.3408401190474,
                46.692270630681
              ],
              [
                -2.3628244691363,
                46.694296913939
              ],
              [
                -2.3838250010365,
                46.706769430868
              ],
              [
                -2.398720979356,
                46.717509050217
              ],
              [
                -2.3987614706025,
                46.723952024978
              ],
              [
                -2.3829626597426,
                46.732131547952
              ],
              [
                -2.3715184087159,
                46.732423656909
              ],
              [
                -2.3449955109993,
                46.726619233181
              ],
              [
                -2.3403092656153,
                46.720187286383
              ],
              [
                -2.3198084377514,
                46.719332810947
              ],
              [
                -2.3047881362997,
                46.709424180555
              ]
            ]
          ],
          [
            [
              [
                -1.1485668205651,
                47.029548900794
              ],
              [
                -1.1430542158922,
                47.024763093203
              ],
              [
                -1.1192302198915,
                47.02084423636
              ],
              [
                -1.1013313178742,
                47.014807797063
              ],
              [
                -1.0905670874918,
                47.015309933936
              ],
              [
                -1.0553989625359,
                47.005682915522
              ],
              [
                -1.015878308284,
                47.004142220601
              ],
              [
                -1.0003939465651,
                47.020801743904
              ],
              [
                -0.98191922800345,
                47.010532067769
              ],
              [
                -0.96703560973155,
                47.00506363445
              ],
              [
                -0.95844801060585,
                46.994865565004
              ],
              [
                -0.94942491382078,
                47.003340444356
              ],
              [
                -0.9432396524795,
                47.000836884271
              ],
              [
                -0.93345650243922,
                47.008909976344
              ],
              [
                -0.91933682702711,
                47.003139081865
              ],
              [
                -0.91526324300451,
                46.992360009909
              ],
              [
                -0.90409186353969,
                46.994791373478
              ],
              [
                -0.89340549580325,
                46.98446883674
              ],
              [
                -0.89196408624284,
                46.975820414303
              ],
              [
                -0.88901027465591,
                46.970996440972
              ],
              [
                -0.87712517316223,
                46.968538385199
              ],
              [
                -0.88359502600069,
                46.962369264823
              ],
              [
                -0.87713915299698,
                46.955855606347
              ],
              [
                -0.8837974960632,
                46.950388590831
              ],
              [
                -0.87298436818273,
                46.944344247689
              ],
              [
                -0.85194566174473,
                46.946506302307
              ],
              [
                -0.84069799142137,
                46.933043168191
              ],
              [
                -0.8291494586578,
                46.933361861306
              ],
              [
                -0.8226181521192,
                46.919496882097
              ],
              [
                -0.80739348796693,
                46.9198254879
              ],
              [
                -0.81977358192379,
                46.909085252586
              ],
              [
                -0.82020445366662,
                46.899619563541
              ],
              [
                -0.8321856982677,
                46.884537016923
              ],
              [
                -0.81527450811283,
                46.879362263307
              ],
              [
                -0.80837576068615,
                46.869152331931
              ],
              [
                -0.79477643718776,
                46.861061682533
              ],
              [
                -0.78164600263284,
                46.84282764491
              ],
              [
                -0.75815720893345,
                46.831399908556
              ],
              [
                -0.74431663878843,
                46.830243411726
              ],
              [
                -0.72752746779957,
                46.821938243707
              ],
              [
                -0.70883784005248,
                46.821063616454
              ],
              [
                -0.7008577279242,
                46.80862581328
              ],
              [
                -0.71783828712373,
                46.800521372146
              ],
              [
                -0.72731204616972,
                46.76763065568
              ],
              [
                -0.72079949738479,
                46.762538021138
              ],
              [
                -0.71534345605879,
                46.751789737735
              ],
              [
                -0.70417950073791,
                46.74945353126
              ],
              [
                -0.69413275947291,
                46.742731111953
              ],
              [
                -0.70015414355827,
                46.73579562558
              ],
              [
                -0.68397597689122,
                46.727863770277
              ],
              [
                -0.66888364550954,
                46.717232370405
              ],
              [
                -0.65619325740221,
                46.700774517445
              ],
              [
                -0.68073310425929,
                46.686803996225
              ],
              [
                -0.65787343587941,
                46.676827080382
              ],
              [
                -0.63771460553181,
                46.66348907218
              ],
              [
                -0.64924847916988,
                46.653283801506
              ],
              [
                -0.65717243733762,
                46.634619354943
              ],
              [
                -0.64405974899964,
                46.638024434086
              ],
              [
                -0.62619906297455,
                46.633078356802
              ],
              [
                -0.61411399285986,
                46.62039129053
              ],
              [
                -0.62702650527698,
                46.605651097302
              ],
              [
                -0.61671038535954,
                46.598536566433
              ],
              [
                -0.61190793736705,
                46.588307031212
              ],
              [
                -0.62456684878994,
                46.577400290716
              ],
              [
                -0.61785426354446,
                46.562043099519
              ],
              [
                -0.60670934401643,
                46.562331917411
              ],
              [
                -0.60213244964129,
                46.533279525109
              ],
              [
                -0.61002538912342,
                46.52729145947
              ],
              [
                -0.63352903140625,
                46.526545961083
              ],
              [
                -0.64519375671477,
                46.5085710348
              ],
              [
                -0.636581601251,
                46.506095217567
              ],
              [
                -0.62483750061224,
                46.496362745962
              ],
              [
                -0.6241822995269,
                46.48674665851
              ],
              [
                -0.6124273279524,
                46.45873668738
              ],
              [
                -0.61983716832458,
                46.452467117452
              ],
              [
                -0.61877090027571,
                46.438854562232
              ],
              [
                -0.63684012869206,
                46.432305656566
              ],
              [
                -0.6406468690526,
                46.416224664644
              ],
              [
                -0.63282952674398,
                46.403812558564
              ],
              [
                -0.63658749242212,
                46.395578228534
              ],
              [
                -0.62088828506372,
                46.390451131851
              ],
              [
                -0.61017966919341,
                46.413733221347
              ],
              [
                -0.59417281123848,
                46.410185950063
              ],
              [
                -0.58189775121858,
                46.40217377528
              ],
              [
                -0.57238400631421,
                46.400707224569
              ],
              [
                -0.56605536149932,
                46.393082159377
              ],
              [
                -0.55052040300574,
                46.393343323056
              ],
              [
                -0.53779518169029,
                46.38646382767
              ],
              [
                -0.55764744918976,
                46.363451353574
              ],
              [
                -0.57567653828611,
                46.356508034766
              ],
              [
                -0.60325195656142,
                46.361468010185
              ],
              [
                -0.60547077785894,
                46.347207865876
              ],
              [
                -0.61868206115959,
                46.339099077209
              ],
              [
                -0.63660376728023,
                46.33759543996
              ],
              [
                -0.63998183318338,
                46.322110972748
              ],
              [
                -0.64825320981615,
                46.317143530802
              ],
              [
                -0.67227364916502,
                46.316216425466
              ],
              [
                -0.69732735736344,
                46.325092837487
              ],
              [
                -0.70757171772654,
                46.317704940913
              ],
              [
                -0.7201487066083,
                46.314890543328
              ],
              [
                -0.72191414100459,
                46.302372661742
              ],
              [
                -0.73459543046899,
                46.304955701863
              ],
              [
                -0.75047134037184,
                46.304254425344
              ],
              [
                -0.75794915819339,
                46.311254056955
              ],
              [
                -0.77570312778264,
                46.318376375981
              ],
              [
                -0.78424343720168,
                46.318861879535
              ],
              [
                -0.80247564445275,
                46.325156566217
              ],
              [
                -0.80732128185426,
                46.339293680039
              ],
              [
                -0.82415169186325,
                46.335854967946
              ],
              [
                -0.83052754444062,
                46.341529547924
              ],
              [
                -0.83988457052554,
                46.340367099668
              ],
              [
                -0.84865539686859,
                46.332512597898
              ],
              [
                -0.84487598314026,
                46.3245597282
              ],
              [
                -0.8509424448661,
                46.317185675508
              ],
              [
                -0.88767597392192,
                46.326322589931
              ],
              [
                -0.89270136944125,
                46.320101412175
              ],
              [
                -0.90480272235534,
                46.313812430336
              ],
              [
                -0.93489398189823,
                46.312857623927
              ],
              [
                -0.9612310671154,
                46.323395683014
              ],
              [
                -0.9441551264402,
                46.336048129241
              ],
              [
                -0.93790474501528,
                46.353157499224
              ],
              [
                -0.92857576719505,
                46.371051399922
              ],
              [
                -0.94112049397563,
                46.367862165606
              ],
              [
                -0.95084003340185,
                46.360617622608
              ],
              [
                -0.96451829359621,
                46.365398033698
              ],
              [
                -0.97744453847283,
                46.351108789901
              ],
              [
                -0.99521192821645,
                46.350317645688
              ],
              [
                -1.013809705621,
                46.35562286986
              ],
              [
                -1.0290641764217,
                46.348867660129
              ],
              [
                -1.0525331368763,
                46.342540560637
              ],
              [
                -1.0645117099073,
                46.335517206168
              ],
              [
                -1.0807238252872,
                46.321431313321
              ],
              [
                -1.0782951920661,
                46.316911662518
              ],
              [
                -1.0997364979128,
                46.314471710528
              ],
              [
                -1.113284059211,
                46.316302232447
              ],
              [
                -1.1236420717667,
                46.321795835911
              ],
              [
                -1.1294064331873,
                46.310272328818
              ],
              [
                -1.1396042849062,
                46.311220270269
              ],
              [
                -1.1633871701049,
                46.324357697468
              ],
              [
                -1.1789816995107,
                46.319237198363
              ],
              [
                -1.2010861494985,
                46.316343408957
              ],
              [
                -1.2029476108593,
                46.31299774522
              ],
              [
                -1.1955066954319,
                46.300486336512
              ],
              [
                -1.2063790183713,
                46.288844677353
              ],
              [
                -1.2073980363312,
                46.266566974958
              ],
              [
                -1.233813730916,
                46.279787036182
              ],
              [
                -1.244708256192,
                46.287909226104
              ],
              [
                -1.2850844462619,
                46.310943853415
              ],
              [
                -1.2958949852744,
                46.323927342846
              ],
              [
                -1.3059782500842,
                46.319190709176
              ],
              [
                -1.2950494049459,
                46.30445585614
              ],
              [
                -1.298649918542,
                46.298035215363
              ],
              [
                -1.3088839142276,
                46.303381059905
              ],
              [
                -1.3209210907591,
                46.321997394722
              ],
              [
                -1.3209769583137,
                46.325733328602
              ],
              [
                -1.3456750283516,
                46.34200224737
              ],
              [
                -1.3667385250608,
                46.348611637782
              ],
              [
                -1.3817097975849,
                46.341186215234
              ],
              [
                -1.4009756575246,
                46.340508325831
              ],
              [
                -1.416442893161,
                46.346032461982
              ],
              [
                -1.4284201012028,
                46.347121382446
              ],
              [
                -1.4369731247534,
                46.341049782884
              ],
              [
                -1.4659374486589,
                46.342356465827
              ],
              [
                -1.4709432372166,
                46.346061757066
              ],
              [
                -1.4771749719261,
                46.363507201844
              ],
              [
                -1.4878020252489,
                46.38139003894
              ],
              [
                -1.5021398541908,
                46.397345234333
              ],
              [
                -1.5150666516907,
                46.40429394231
              ],
              [
                -1.5377511402152,
                46.409241650902
              ],
              [
                -1.5505012086214,
                46.405427866679
              ],
              [
                -1.5849335376726,
                46.408882191167
              ],
              [
                -1.6113093601133,
                46.413418180507
              ],
              [
                -1.6270620551428,
                46.414220589438
              ],
              [
                -1.6417221223658,
                46.419202887095
              ],
              [
                -1.6563835193513,
                46.431211054285
              ],
              [
                -1.6746027257341,
                46.439923752177
              ],
              [
                -1.7038680334017,
                46.448675063621
              ],
              [
                -1.7191201629207,
                46.459167761425
              ],
              [
                -1.7521241921725,
                46.474155783424
              ],
              [
                -1.777137894573,
                46.492758626968
              ],
              [
                -1.7912930929301,
                46.494497528609
              ],
              [
                -1.8033948364521,
                46.488967027341
              ],
              [
                -1.8123442739131,
                46.493416566185
              ],
              [
                -1.8186497595357,
                46.518682691519
              ],
              [
                -1.8419594438554,
                46.573013536296
              ],
              [
                -1.8484416100107,
                46.585884833838
              ],
              [
                -1.8560152427178,
                46.608580735068
              ],
              [
                -1.873382171513,
                46.620915463581
              ],
              [
                -1.8822873872759,
                46.630594948835
              ],
              [
                -1.8906734495839,
                46.63469093819
              ],
              [
                -1.9035668912462,
                46.647321849326
              ],
              [
                -1.9126335673886,
                46.661558294252
              ],
              [
                -1.9428758596327,
                46.692706115369
              ],
              [
                -1.9508357687079,
                46.695651008644
              ],
              [
                -1.966128703921,
                46.692012127622
              ],
              [
                -1.9784453425744,
                46.703097268015
              ],
              [
                -1.9826649825899,
                46.720311181056
              ],
              [
                -2.0002660579608,
                46.733925400032
              ],
              [
                -2.0719487138764,
                46.782812921452
              ],
              [
                -2.1171981955453,
                46.803360927686
              ],
              [
                -2.1401926882177,
                46.817151505205
              ],
              [
                -2.1445060316881,
                46.826399169492
              ],
              [
                -2.1454334062879,
                46.847809766579
              ],
              [
                -2.1489184130775,
                46.869931347964
              ],
              [
                -2.1553427446113,
                46.883151727981
              ],
              [
                -2.153823981621,
                46.890149400164
              ],
              [
                -2.1188882449818,
                46.893677330801
              ],
              [
                -2.1177998913797,
                46.908317123363
              ],
              [
                -2.1034385508171,
                46.920984664355
              ],
              [
                -2.062735433411,
                46.948064118056
              ],
              [
                -2.0447808242546,
                46.969115340219
              ],
              [
                -2.0453622399129,
                46.979481273443
              ],
              [
                -2.0273622488649,
                47.00992054926
              ],
              [
                -1.9975145183017,
                47.018754751413
              ],
              [
                -1.9804130066429,
                47.028904753758
              ],
              [
                -1.9665825427654,
                47.025684331109
              ],
              [
                -1.9505833647802,
                47.001866498175
              ],
              [
                -1.9432299989209,
                46.994708112224
              ],
              [
                -1.9176795932921,
                46.992438096929
              ],
              [
                -1.9174464224643,
                46.975080057144
              ],
              [
                -1.9076821607029,
                46.970621494787
              ],
              [
                -1.8945066250679,
                46.959854562684
              ],
              [
                -1.8773327235254,
                46.953676781185
              ],
              [
                -1.8318183862536,
                46.953809144032
              ],
              [
                -1.825350928718,
                46.946947877747
              ],
              [
                -1.8309864393011,
                46.932037153713
              ],
              [
                -1.798867236715,
                46.927389184813
              ],
              [
                -1.7752593419132,
                46.925543067978
              ],
              [
                -1.7510691760313,
                46.929599006382
              ],
              [
                -1.7454773845349,
                46.918718031948
              ],
              [
                -1.736248027152,
                46.911433999514
              ],
              [
                -1.736070743422,
                46.898731390312
              ],
              [
                -1.7310575876529,
                46.887971269499
              ],
              [
                -1.7202570590716,
                46.884561675382
              ],
              [
                -1.6897556400174,
                46.890488583077
              ],
              [
                -1.6823313121141,
                46.883880205216
              ],
              [
                -1.6645088836683,
                46.874775769301
              ],
              [
                -1.6559787949023,
                46.878451361291
              ],
              [
                -1.6348229023896,
                46.878375886291
              ],
              [
                -1.6006429567832,
                46.87197273609
              ],
              [
                -1.5806567099723,
                46.866054082449
              ],
              [
                -1.5485096132916,
                46.860078088448
              ],
              [
                -1.546480204725,
                46.87383880978
              ],
              [
                -1.5242290703417,
                46.87400553248
              ],
              [
                -1.5007655467174,
                46.883891204136
              ],
              [
                -1.5078497024447,
                46.893876762137
              ],
              [
                -1.5309120096053,
                46.909449546397
              ],
              [
                -1.5443756009763,
                46.923001220553
              ],
              [
                -1.5279316909934,
                46.923781708092
              ],
              [
                -1.5207438194861,
                46.929878324082
              ],
              [
                -1.5200531988311,
                46.939043729216
              ],
              [
                -1.530901951403,
                46.944983490916
              ],
              [
                -1.5410863770521,
                46.955931191708
              ],
              [
                -1.5535692540762,
                46.98150813307
              ],
              [
                -1.5510080622522,
                46.991142653886
              ],
              [
                -1.542669701774,
                47.003269318873
              ],
              [
                -1.5509366648366,
                47.008348909087
              ],
              [
                -1.5523193513555,
                47.018345265795
              ],
              [
                -1.5157201024778,
                47.027729878659
              ],
              [
                -1.5071112498984,
                47.036732846058
              ],
              [
                -1.4918917982953,
                47.041766785139
              ],
              [
                -1.4821269400681,
                47.037858547324
              ],
              [
                -1.4760445820058,
                47.026891881408
              ],
              [
                -1.4691768674665,
                47.001990413026
              ],
              [
                -1.460243586512,
                46.992964149783
              ],
              [
                -1.4759176820598,
                46.983821193651
              ],
              [
                -1.4719848976051,
                46.969179954536
              ],
              [
                -1.461236428386,
                46.962308344919
              ],
              [
                -1.4572099023003,
                46.954650198908
              ],
              [
                -1.4671325140377,
                46.943378459256
              ],
              [
                -1.4715698702165,
                46.933593956324
              ],
              [
                -1.4561287088222,
                46.92626936023
              ],
              [
                -1.4181553939744,
                46.943055593083
              ],
              [
                -1.3729867918168,
                46.952180820897
              ],
              [
                -1.3651258550545,
                46.956916369701
              ],
              [
                -1.3685912013826,
                46.965126922857
              ],
              [
                -1.3584324588956,
                46.973883203207
              ],
              [
                -1.3590627464638,
                46.981135337897
              ],
              [
                -1.3775056345619,
                46.990942402401
              ],
              [
                -1.3696536707446,
                46.996374692661
              ],
              [
                -1.3651713270515,
                47.006787081572
              ],
              [
                -1.3670760244076,
                47.019604066339
              ],
              [
                -1.3786187507728,
                47.030850021718
              ],
              [
                -1.3651395889351,
                47.039815208849
              ],
              [
                -1.3533918387699,
                47.039381673996
              ],
              [
                -1.3354906033185,
                47.043269269841
              ],
              [
                -1.3271459700488,
                47.037743191051
              ],
              [
                -1.3124143046091,
                47.033512233193
              ],
              [
                -1.3006089447707,
                47.034246813955
              ],
              [
                -1.294639880423,
                47.039478778925
              ],
              [
                -1.2974500810349,
                47.050827050519
              ],
              [
                -1.2866136600138,
                47.061076054511
              ],
              [
                -1.290326283889,
                47.070189965192
              ],
              [
                -1.2712120445468,
                47.083893903306
              ],
              [
                -1.2496771467986,
                47.069911651239
              ],
              [
                -1.2306516586586,
                47.064762478179
              ],
              [
                -1.2203746378854,
                47.05485937794
              ],
              [
                -1.2102060449359,
                47.050536062417
              ],
              [
                -1.2027794819453,
                47.041805126785
              ],
              [
                -1.1485668205651,
                47.029548900794
              ]
            ]
          ],
          [
            [
              [
                -2.1980717999149,
                46.951171361358
              ],
              [
                -2.2231531353026,
                46.965271208862
              ],
              [
                -2.24244506994,
                46.965359299904
              ],
              [
                -2.2543982518538,
                46.958122789992
              ],
              [
                -2.2643746646818,
                46.960876831901
              ],
              [
                -2.2867885516843,
                46.983496627052
              ],
              [
                -2.3010946565916,
                46.989047601607
              ],
              [
                -2.3020317382713,
                46.997616748051
              ],
              [
                -2.2925186097225,
                47.012555022671
              ],
              [
                -2.3042054070871,
                47.025060693728
              ],
              [
                -2.2872328794469,
                47.024618443862
              ],
              [
                -2.2770692805571,
                47.029038135374
              ],
              [
                -2.2520693981849,
                47.027966094972
              ],
              [
                -2.2401364085503,
                47.017854413154
              ],
              [
                -2.2256229438599,
                47.016854719984
              ],
              [
                -2.2183338551942,
                47.007817147562
              ],
              [
                -2.2188122423342,
                46.991712728633
              ],
              [
                -2.2317459039104,
                46.989711402141
              ],
              [
                -2.2374732061994,
                46.981958292077
              ],
              [
                -2.2307387193565,
                46.973215731503
              ],
              [
                -2.2202452222616,
                46.973677309765
              ],
              [
                -2.1571813233526,
                46.950033359583
              ],
              [
                -2.147437598884,
                46.934194164793
              ],
              [
                -2.1482142491069,
                46.913528857926
              ],
              [
                -2.1532523636713,
                46.906933207426
              ],
              [
                -2.1538987641986,
                46.895779045368
              ],
              [
                -2.1679391075448,
                46.911177523069
              ],
              [
                -2.1735282996294,
                46.925285521446
              ],
              [
                -2.1814210323948,
                46.937050160658
              ],
              [
                -2.1980717999149,
                46.951171361358
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "85",
        "nom": "Vendée",
        "population": 692705,
        "delitspour1000": "3,068",
        "nombremosque": 6,
        "parentsEtrangers": "89,8",
        "benefRsa": 11761,
        "eoliennes": 175
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              0.82343238223316,
              46.128584404123
            ],
            [
              0.84482890084101,
              46.134177697482
            ],
            [
              0.84476324651838,
              46.140138998175
            ],
            [
              0.83583470448799,
              46.143552683205
            ],
            [
              0.83232792935273,
              46.14978258083
            ],
            [
              0.82830880944013,
              46.17463258038
            ],
            [
              0.83660345797057,
              46.176812243845
            ],
            [
              0.8300284343603,
              46.185049240394
            ],
            [
              0.81877426405719,
              46.186788611869
            ],
            [
              0.81558170661796,
              46.196733704662
            ],
            [
              0.80022145185827,
              46.201475872613
            ],
            [
              0.79632554149705,
              46.210961874188
            ],
            [
              0.80727522194406,
              46.22492290773
            ],
            [
              0.81599193497797,
              46.228505649491
            ],
            [
              0.84801138948638,
              46.228750196503
            ],
            [
              0.85189930291137,
              46.2359554315
            ],
            [
              0.84547619420008,
              46.242057704191
            ],
            [
              0.84914741884707,
              46.248877865735
            ],
            [
              0.85909327756833,
              46.250447269088
            ],
            [
              0.86144940761012,
              46.261636386108
            ],
            [
              0.88533180427558,
              46.266083969894
            ],
            [
              0.89134243468083,
              46.269400518903
            ],
            [
              0.89991373468769,
              46.28264843333
            ],
            [
              0.90727538588543,
              46.285260253253
            ],
            [
              0.93266884809059,
              46.28202368613
            ],
            [
              0.93647890753638,
              46.292823836247
            ],
            [
              0.94953943004579,
              46.287180346538
            ],
            [
              0.96842001221032,
              46.286020765246
            ],
            [
              0.98951699161804,
              46.281903968854
            ],
            [
              1.005891998265,
              46.280972289235
            ],
            [
              1.004800186838,
              46.293781839531
            ],
            [
              1.0131040014919,
              46.309986658686
            ],
            [
              1.0241639515741,
              46.317662492256
            ],
            [
              1.0278959297973,
              46.332023905128
            ],
            [
              1.0269979126089,
              46.343038073772
            ],
            [
              1.0511809504649,
              46.358160517464
            ],
            [
              1.0501626260953,
              46.362782836344
            ],
            [
              1.0783369629335,
              46.358464682058
            ],
            [
              1.0969034989942,
              46.362111472187
            ],
            [
              1.1074792516558,
              46.354195343032
            ],
            [
              1.1272435422129,
              46.347839222972
            ],
            [
              1.1297577719073,
              46.360462680395
            ],
            [
              1.1492558838096,
              46.369216903606
            ],
            [
              1.1469657078228,
              46.375896349497
            ],
            [
              1.1575421056358,
              46.388738882303
            ],
            [
              1.1772787760985,
              46.383948000481
            ],
            [
              1.1841495355328,
              46.37729147918
            ],
            [
              1.191538104043,
              46.376759451936
            ],
            [
              1.2047664407221,
              46.387689428519
            ],
            [
              1.2164239770673,
              46.367784938774
            ],
            [
              1.2408646690892,
              46.367573686174
            ],
            [
              1.245105268008,
              46.37323860456
            ],
            [
              1.2603993142262,
              46.378783327264
            ],
            [
              1.2795549241983,
              46.376488887271
            ],
            [
              1.3030594710726,
              46.370991990563
            ],
            [
              1.3093599883058,
              46.378135125428
            ],
            [
              1.3204740000508,
              46.38163178083
            ],
            [
              1.3223398241302,
              46.389656273383
            ],
            [
              1.3309220641861,
              46.396705560378
            ],
            [
              1.3442929839887,
              46.401546863371
            ],
            [
              1.3560243049838,
              46.4001195921
            ],
            [
              1.3775283931369,
              46.382803331341
            ],
            [
              1.3835201223913,
              46.374755471612
            ],
            [
              1.3965775759913,
              46.371445233126
            ],
            [
              1.4048447784072,
              46.364056923495
            ],
            [
              1.4151854142983,
              46.347214822447
            ],
            [
              1.4096743646352,
              46.342805940916
            ],
            [
              1.4241467178877,
              46.335515305437
            ],
            [
              1.4457835086221,
              46.331398315254
            ],
            [
              1.4380888390142,
              46.32088703613
            ],
            [
              1.4437657610001,
              46.305896855052
            ],
            [
              1.4368962523711,
              46.299297404353
            ],
            [
              1.4265739813571,
              46.298922021214
            ],
            [
              1.4231389645279,
              46.287535272137
            ],
            [
              1.4141723776068,
              46.280489208444
            ],
            [
              1.432819785109,
              46.276117113177
            ],
            [
              1.4367552457062,
              46.27248303512
            ],
            [
              1.4230973001724,
              46.266795272783
            ],
            [
              1.4146523821885,
              46.271360510371
            ],
            [
              1.4057349259219,
              46.254101792781
            ],
            [
              1.4155339799349,
              46.253732460733
            ],
            [
              1.4197668713784,
              46.244993664165
            ],
            [
              1.3941388425989,
              46.238350039165
            ],
            [
              1.3814642425679,
              46.225377328404
            ],
            [
              1.3748978470741,
              46.215446562448
            ],
            [
              1.3952210030033,
              46.194450526459
            ],
            [
              1.3960696381811,
              46.188184833298
            ],
            [
              1.4196601094072,
              46.178050409465
            ],
            [
              1.4382046633811,
              46.17632439479
            ],
            [
              1.4527908975494,
              46.179754559157
            ],
            [
              1.4653163830511,
              46.161946388556
            ],
            [
              1.4520544208808,
              46.15334082047
            ],
            [
              1.4678745047091,
              46.151753965164
            ],
            [
              1.4840328155431,
              46.136716379374
            ],
            [
              1.5051925260546,
              46.127465281562
            ],
            [
              1.5067100584923,
              46.122106571984
            ],
            [
              1.4905027878223,
              46.111751805528
            ],
            [
              1.5015918794313,
              46.101397126546
            ],
            [
              1.504897999906,
              46.088149821066
            ],
            [
              1.5128361619698,
              46.093426566068
            ],
            [
              1.5247847438432,
              46.088797384678
            ],
            [
              1.5262511459463,
              46.079443408884
            ],
            [
              1.5434017044539,
              46.07691072874
            ],
            [
              1.5396702485746,
              46.066897128383
            ],
            [
              1.5317844788051,
              46.058158129945
            ],
            [
              1.5388726320688,
              46.043461078132
            ],
            [
              1.5491707327049,
              46.03540557368
            ],
            [
              1.5363859345527,
              46.02629511129
            ],
            [
              1.5322792937503,
              46.011284955225
            ],
            [
              1.5385506556741,
              45.997429410822
            ],
            [
              1.5631407946841,
              45.997690833415
            ],
            [
              1.5736991057427,
              45.985706156338
            ],
            [
              1.5749323906618,
              45.975997479181
            ],
            [
              1.5603334781916,
              45.963481224277
            ],
            [
              1.5533760749426,
              45.962803517251
            ],
            [
              1.5324937217054,
              45.95280415449
            ],
            [
              1.5207608432223,
              45.952449686784
            ],
            [
              1.5220002973313,
              45.942831619097
            ],
            [
              1.5094010745832,
              45.940175167016
            ],
            [
              1.5132688523674,
              45.931656338798
            ],
            [
              1.525656674414,
              45.931004244504
            ],
            [
              1.5472652293994,
              45.916829291477
            ],
            [
              1.5582767056347,
              45.922079517803
            ],
            [
              1.5666404819256,
              45.914039497609
            ],
            [
              1.5782615511454,
              45.917267246264
            ],
            [
              1.5791756702079,
              45.930409140897
            ],
            [
              1.6032095357851,
              45.931423491118
            ],
            [
              1.6087209134796,
              45.933413342676
            ],
            [
              1.6345205792016,
              45.922985835859
            ],
            [
              1.6247470869022,
              45.916131643599
            ],
            [
              1.6389237619179,
              45.903759184162
            ],
            [
              1.6417383448025,
              45.892619858859
            ],
            [
              1.6359937428864,
              45.888445648637
            ],
            [
              1.620176398313,
              45.89846776245
            ],
            [
              1.616888915489,
              45.891248673753
            ],
            [
              1.6024175499196,
              45.890903206559
            ],
            [
              1.591036942213,
              45.883073233205
            ],
            [
              1.6016302598318,
              45.877652356969
            ],
            [
              1.6072764729052,
              45.868063682495
            ],
            [
              1.6023947165161,
              45.857460609029
            ],
            [
              1.6284040273944,
              45.84764094228
            ],
            [
              1.6525034147536,
              45.84563075494
            ],
            [
              1.6579000699666,
              45.841299960463
            ],
            [
              1.6766400011982,
              45.83511315007
            ],
            [
              1.6903585111423,
              45.843610939097
            ],
            [
              1.7054286850217,
              45.843072253083
            ],
            [
              1.7148761323959,
              45.840055000467
            ],
            [
              1.732972930451,
              45.844347283718
            ],
            [
              1.7398526245127,
              45.851526413716
            ],
            [
              1.7544800457441,
              45.856281684747
            ],
            [
              1.7559833739095,
              45.866321064339
            ],
            [
              1.770771649476,
              45.868375937565
            ],
            [
              1.7773732690177,
              45.858849849245
            ],
            [
              1.7778168232688,
              45.850057589313
            ],
            [
              1.7727453683847,
              45.842824831336
            ],
            [
              1.7764808489409,
              45.832775933168
            ],
            [
              1.7891530555383,
              45.83007290926
            ],
            [
              1.808931209546,
              45.821035017177
            ],
            [
              1.8134556686181,
              45.815365685815
            ],
            [
              1.8284323808085,
              45.809811431691
            ],
            [
              1.8299936190633,
              45.821885615941
            ],
            [
              1.8409992739107,
              45.821564120274
            ],
            [
              1.8464434674253,
              45.809936550114
            ],
            [
              1.8808950397786,
              45.797711178589
            ],
            [
              1.8913705933824,
              45.774856101763
            ],
            [
              1.8971494460868,
              45.767681100542
            ],
            [
              1.8941319077424,
              45.759257284373
            ],
            [
              1.877939027566,
              45.758922660532
            ],
            [
              1.8743032041452,
              45.755758721473
            ],
            [
              1.8864061938952,
              45.745191705923
            ],
            [
              1.8751389083315,
              45.732775274201
            ],
            [
              1.8733390753593,
              45.725300512683
            ],
            [
              1.8875349222779,
              45.725088467315
            ],
            [
              1.8924057186982,
              45.718404629218
            ],
            [
              1.8814370841814,
              45.709094226518
            ],
            [
              1.8987320820414,
              45.698276663383
            ],
            [
              1.9094484810021,
              45.690490991257
            ],
            [
              1.9056316168489,
              45.677971170951
            ],
            [
              1.8799612362096,
              45.678184431517
            ],
            [
              1.8808451243487,
              45.668336436958
            ],
            [
              1.8713481385443,
              45.664653768147
            ],
            [
              1.8546009705446,
              45.672274668279
            ],
            [
              1.8500372576264,
              45.665822347467
            ],
            [
              1.8264334724666,
              45.665132148261
            ],
            [
              1.8159593866988,
              45.680423401703
            ],
            [
              1.8008873621314,
              45.674617277698
            ],
            [
              1.7921210312102,
              45.681672480893
            ],
            [
              1.7838730173434,
              45.680359832055
            ],
            [
              1.7776207954444,
              45.664089620082
            ],
            [
              1.7690073620902,
              45.656757732937
            ],
            [
              1.7577141778869,
              45.653641924326
            ],
            [
              1.7480492439205,
              45.645696988818
            ],
            [
              1.719240328502,
              45.638187017497
            ],
            [
              1.7122470870333,
              45.641342180794
            ],
            [
              1.6820375995624,
              45.625436348701
            ],
            [
              1.660197916058,
              45.607975911156
            ],
            [
              1.6581740723246,
              45.595323469002
            ],
            [
              1.6432456995656,
              45.587607167454
            ],
            [
              1.637667297551,
              45.580351955747
            ],
            [
              1.6183044806183,
              45.576721266196
            ],
            [
              1.6104620981456,
              45.57882931792
            ],
            [
              1.5929603823554,
              45.575070422688
            ],
            [
              1.5848926742786,
              45.556078684475
            ],
            [
              1.5699809777709,
              45.55557201525
            ],
            [
              1.5571983675006,
              45.549366357639
            ],
            [
              1.5220862628879,
              45.564095585467
            ],
            [
              1.5175108232266,
              45.557403975837
            ],
            [
              1.5062738329569,
              45.55097866922
            ],
            [
              1.4926610127604,
              45.549346872252
            ],
            [
              1.4922288654159,
              45.560860224865
            ],
            [
              1.4803672315751,
              45.565943103669
            ],
            [
              1.4743785744561,
              45.553660989104
            ],
            [
              1.4551430293037,
              45.553286526913
            ],
            [
              1.4502612360555,
              45.530395162772
            ],
            [
              1.4390800916948,
              45.521264513501
            ],
            [
              1.4283204410392,
              45.52963459404
            ],
            [
              1.412057555905,
              45.528083714351
            ],
            [
              1.3995865948151,
              45.518105798589
            ],
            [
              1.3895961885146,
              45.496159027417
            ],
            [
              1.3788636254268,
              45.495437141641
            ],
            [
              1.3682873148575,
              45.489362984249
            ],
            [
              1.3500777990719,
              45.466891005823
            ],
            [
              1.2870517483656,
              45.489934212885
            ],
            [
              1.2788551402607,
              45.477080434887
            ],
            [
              1.2703383373609,
              45.47767453679
            ],
            [
              1.2648159227727,
              45.469006694164
            ],
            [
              1.2644068304182,
              45.457149219751
            ],
            [
              1.2561930840569,
              45.452256338425
            ],
            [
              1.2531543680864,
              45.44422001812
            ],
            [
              1.241404347379,
              45.437356928582
            ],
            [
              1.2340044582991,
              45.439938189475
            ],
            [
              1.2359397841126,
              45.455027204041
            ],
            [
              1.2095051560814,
              45.463085709124
            ],
            [
              1.1865896978248,
              45.455294990995
            ],
            [
              1.176821674264,
              45.460816483773
            ],
            [
              1.1698569128168,
              45.47284548287
            ],
            [
              1.1619322997805,
              45.478655222263
            ],
            [
              1.1528400990405,
              45.473206153758
            ],
            [
              1.1358330938261,
              45.470862267361
            ],
            [
              1.1222297120746,
              45.477235903494
            ],
            [
              1.1197817974064,
              45.487786484259
            ],
            [
              1.1281904669439,
              45.493658303977
            ],
            [
              1.1359422598032,
              45.506405455775
            ],
            [
              1.1457588091326,
              45.51402413419
            ],
            [
              1.1672854866086,
              45.524823083995
            ],
            [
              1.1376820616141,
              45.539770878656
            ],
            [
              1.1189576898653,
              45.545495600033
            ],
            [
              1.1103275669005,
              45.544906724066
            ],
            [
              1.102063750574,
              45.536538346811
            ],
            [
              1.0803563779529,
              45.534001427359
            ],
            [
              1.0717211244419,
              45.547670879839
            ],
            [
              1.0626570130683,
              45.55481098184
            ],
            [
              1.0499960231824,
              45.556779905163
            ],
            [
              1.0378037277977,
              45.568741339867
            ],
            [
              1.0345567591098,
              45.581434783883
            ],
            [
              1.0358438821254,
              45.600210100883
            ],
            [
              1.0272245291926,
              45.601549089948
            ],
            [
              1.0233311222277,
              45.608927311851
            ],
            [
              0.99550297448107,
              45.612623406595
            ],
            [
              0.98639082241995,
              45.604483433474
            ],
            [
              0.94946947335678,
              45.611605412695
            ],
            [
              0.9345497361253,
              45.611583763759
            ],
            [
              0.91358891725371,
              45.604121198541
            ],
            [
              0.90694897832677,
              45.606159731018
            ],
            [
              0.89219082184256,
              45.601282841669
            ],
            [
              0.87111784672118,
              45.622469215895
            ],
            [
              0.86044192004144,
              45.614095447968
            ],
            [
              0.85034850719997,
              45.598529760541
            ],
            [
              0.84458421559202,
              45.58414605626
            ],
            [
              0.81738737137326,
              45.575951773802
            ],
            [
              0.81149516980134,
              45.575871443793
            ],
            [
              0.80715430183036,
              45.593808718707
            ],
            [
              0.8035616443645,
              45.59623388566
            ],
            [
              0.78037610975744,
              45.591189777647
            ],
            [
              0.77547181041453,
              45.592381049291
            ],
            [
              0.76580279197506,
              45.605608223481
            ],
            [
              0.75651312392777,
              45.611033740597
            ],
            [
              0.75152732990991,
              45.618319302192
            ],
            [
              0.7630396822677,
              45.628335552708
            ],
            [
              0.76408057719108,
              45.642085068126
            ],
            [
              0.77159487028606,
              45.649073284221
            ],
            [
              0.77584324344145,
              45.667566871982
            ],
            [
              0.7580960309786,
              45.673325335181
            ],
            [
              0.74588386989544,
              45.687257323177
            ],
            [
              0.72647929320381,
              45.694448925843
            ],
            [
              0.71649925017518,
              45.694931712962
            ],
            [
              0.70591091538187,
              45.68844025106
            ],
            [
              0.67131772964592,
              45.686263722598
            ],
            [
              0.65423210218119,
              45.688708205904
            ],
            [
              0.64758562647999,
              45.698191932838
            ],
            [
              0.64014526086376,
              45.697900282075
            ],
            [
              0.62974117909144,
              45.714569962764
            ],
            [
              0.63231701963779,
              45.720548375195
            ],
            [
              0.64541869447975,
              45.717506704978
            ],
            [
              0.64797086926532,
              45.731673285892
            ],
            [
              0.65251909415086,
              45.740470650574
            ],
            [
              0.66489522836334,
              45.739215992699
            ],
            [
              0.6690048783512,
              45.7477810265
            ],
            [
              0.69431402511169,
              45.761111812143
            ],
            [
              0.71488961170325,
              45.76312150687
            ],
            [
              0.71384817928913,
              45.772239691853
            ],
            [
              0.70577954872733,
              45.777656765807
            ],
            [
              0.71114784267474,
              45.783134079803
            ],
            [
              0.70946788355225,
              45.79858772426
            ],
            [
              0.71643340810388,
              45.805616908376
            ],
            [
              0.73629196745894,
              45.802977421961
            ],
            [
              0.74090845042694,
              45.805742765851
            ],
            [
              0.75203254465668,
              45.798240925358
            ],
            [
              0.76304658089703,
              45.796273403347
            ],
            [
              0.77213876561217,
              45.787624942999
            ],
            [
              0.7838946240077,
              45.791677820476
            ],
            [
              0.77646745103673,
              45.802378820004
            ],
            [
              0.78261227642634,
              45.811716259765
            ],
            [
              0.78222581976608,
              45.821079269049
            ],
            [
              0.80199129603081,
              45.836873754429
            ],
            [
              0.82084980641218,
              45.867229794359
            ],
            [
              0.81194225571378,
              45.872553679228
            ],
            [
              0.82737827730018,
              45.88274984149
            ],
            [
              0.8154385343037,
              45.895142245351
            ],
            [
              0.81620350646935,
              45.916343812086
            ],
            [
              0.80872660646568,
              45.923261780883
            ],
            [
              0.81211444044304,
              45.931270953369
            ],
            [
              0.82665795374138,
              45.929457739311
            ],
            [
              0.83755014577172,
              45.923064279624
            ],
            [
              0.85208289246111,
              45.924426664385
            ],
            [
              0.86291072789308,
              45.919754691776
            ],
            [
              0.8889259548768,
              45.925716576344
            ],
            [
              0.91016314124597,
              45.937100765297
            ],
            [
              0.92097504308226,
              45.937911592345
            ],
            [
              0.92271425809889,
              45.953005887095
            ],
            [
              0.94259709142132,
              45.957437972663
            ],
            [
              0.9456207917489,
              45.974325195389
            ],
            [
              0.93451017847667,
              45.976009331251
            ],
            [
              0.93155664658109,
              45.985465176482
            ],
            [
              0.93467626363191,
              45.992207854621
            ],
            [
              0.9189303920422,
              45.997050616674
            ],
            [
              0.92562039873925,
              46.010452068811
            ],
            [
              0.89403472489117,
              46.023144627301
            ],
            [
              0.88424329506274,
              46.031883014096
            ],
            [
              0.86703490010072,
              46.018431918244
            ],
            [
              0.8597496793536,
              46.017976558703
            ],
            [
              0.85844316742977,
              46.026890740983
            ],
            [
              0.85247379596105,
              46.03380871971
            ],
            [
              0.81791082620152,
              46.047881741971
            ],
            [
              0.81474748013296,
              46.057063389192
            ],
            [
              0.82690169948677,
              46.063929795286
            ],
            [
              0.82532610286627,
              46.071827815687
            ],
            [
              0.81890097305856,
              46.077410324336
            ],
            [
              0.83060131714538,
              46.089176126355
            ],
            [
              0.8344470049923,
              46.097505684097
            ],
            [
              0.83368600867603,
              46.106358910604
            ],
            [
              0.81829906044852,
              46.113037512796
            ],
            [
              0.81203762140491,
              46.125326880589
            ],
            [
              0.82343238223316,
              46.128584404123
            ]
          ]
        ]
      },
      "properties": {
        "code": "87",
        "nom": "Haute-Vienne",
        "population": 372123,
        "delitspour1000": "4,407",
        "nombremosque": 9,
        "parentsEtrangers": "70,7",
        "benefRsa": 21110,
        "eoliennes": 45
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              5.4700548229834,
              48.420926283443
            ],
            [
              5.5013062188735,
              48.410687855212
            ],
            [
              5.5138988120989,
              48.410801373756
            ],
            [
              5.5109905177343,
              48.418212368928
            ],
            [
              5.514747882228,
              48.429448820319
            ],
            [
              5.5202802354993,
              48.4323944293
            ],
            [
              5.5457010951856,
              48.435503302948
            ],
            [
              5.5505100471078,
              48.439825818053
            ],
            [
              5.5646513919581,
              48.44108713367
            ],
            [
              5.5677816804592,
              48.43289411391
            ],
            [
              5.5766485772267,
              48.43196733573
            ],
            [
              5.5802627009573,
              48.438796271493
            ],
            [
              5.5907915774722,
              48.442394094149
            ],
            [
              5.609413716223,
              48.442255097997
            ],
            [
              5.6159691492695,
              48.439744295634
            ],
            [
              5.6303138674697,
              48.451078379337
            ],
            [
              5.651580017191,
              48.457519861905
            ],
            [
              5.6429950335545,
              48.468547039375
            ],
            [
              5.6687063748658,
              48.471957503272
            ],
            [
              5.6790220293959,
              48.471386688917
            ],
            [
              5.6944133336967,
              48.463688687607
            ],
            [
              5.7132282239238,
              48.460339963131
            ],
            [
              5.7385802629757,
              48.466074051844
            ],
            [
              5.7553353805387,
              48.491743938643
            ],
            [
              5.7651504539389,
              48.49648910684
            ],
            [
              5.7768982595427,
              48.487176543887
            ],
            [
              5.7870254762515,
              48.482912835892
            ],
            [
              5.7921349615142,
              48.488626749074
            ],
            [
              5.8106518957809,
              48.497446295308
            ],
            [
              5.8288322738379,
              48.498827573065
            ],
            [
              5.8376330991699,
              48.504677571845
            ],
            [
              5.8560752248841,
              48.506846952466
            ],
            [
              5.8702652336204,
              48.499612192529
            ],
            [
              5.8881505754478,
              48.494009660474
            ],
            [
              5.9056248919799,
              48.494940877656
            ],
            [
              5.8987895419459,
              48.485437027889
            ],
            [
              5.903565424076,
              48.480244929196
            ],
            [
              5.8957451146464,
              48.474145253832
            ],
            [
              5.8868989565087,
              48.460067093041
            ],
            [
              5.8869262161534,
              48.453868391275
            ],
            [
              5.8989510241802,
              48.44671813264
            ],
            [
              5.8837809945736,
              48.436078000604
            ],
            [
              5.8553813169775,
              48.409372749757
            ],
            [
              5.876306808297,
              48.403477971488
            ],
            [
              5.8931507049176,
              48.405225079491
            ],
            [
              5.8992946023351,
              48.408901086296
            ],
            [
              5.9090618834538,
              48.42920571842
            ],
            [
              5.9054854014434,
              48.436837469809
            ],
            [
              5.931321799578,
              48.433947530649
            ],
            [
              5.9249036807074,
              48.421821938724
            ],
            [
              5.9159134402814,
              48.427549333524
            ],
            [
              5.9079547502256,
              48.417539451573
            ],
            [
              5.912360520142,
              48.410017641065
            ],
            [
              5.9276908372607,
              48.393685295978
            ],
            [
              5.9384159368281,
              48.401854636957
            ],
            [
              5.9496459908128,
              48.396363026783
            ],
            [
              5.9549403010595,
              48.387326305618
            ],
            [
              5.9446473521932,
              48.375815621136
            ],
            [
              5.9557386084989,
              48.367411409164
            ],
            [
              5.9552925836673,
              48.356661046747
            ],
            [
              5.9615617470524,
              48.349889737943
            ],
            [
              5.9769245874691,
              48.350411126259
            ],
            [
              5.9934810970028,
              48.35431264698
            ],
            [
              6.0040704358291,
              48.361480398766
            ],
            [
              6.0204877629204,
              48.361400459565
            ],
            [
              6.0252276692421,
              48.356968605294
            ],
            [
              6.0534195428738,
              48.365632203186
            ],
            [
              6.0660207269479,
              48.359283227586
            ],
            [
              6.0798196336066,
              48.363635915725
            ],
            [
              6.077040301892,
              48.379754440323
            ],
            [
              6.0902436162121,
              48.37828172168
            ],
            [
              6.0976397104948,
              48.371592388538
            ],
            [
              6.0987357768279,
              48.356390539634
            ],
            [
              6.1167293940637,
              48.354041894964
            ],
            [
              6.1235687601174,
              48.365865709861
            ],
            [
              6.1334059910595,
              48.363874406688
            ],
            [
              6.1523474077963,
              48.380968218338
            ],
            [
              6.1470839608838,
              48.404244344296
            ],
            [
              6.1496394514006,
              48.40852706658
            ],
            [
              6.1697655230123,
              48.40550733342
            ],
            [
              6.1846532761481,
              48.408326250688
            ],
            [
              6.1784179388435,
              48.397601611013
            ],
            [
              6.2320994667614,
              48.400224982495
            ],
            [
              6.2466856035967,
              48.406025685579
            ],
            [
              6.2604363256849,
              48.406396481242
            ],
            [
              6.2703000509095,
              48.426097006044
            ],
            [
              6.282250722589,
              48.424960021102
            ],
            [
              6.2870007127925,
              48.430193153316
            ],
            [
              6.3012589581199,
              48.430521011758
            ],
            [
              6.3056750349462,
              48.413192474483
            ],
            [
              6.3313219297207,
              48.404818818785
            ],
            [
              6.3638167883924,
              48.406144988026
            ],
            [
              6.3805094919312,
              48.412275972805
            ],
            [
              6.3928988161481,
              48.40874868619
            ],
            [
              6.3842531855239,
              48.394696124114
            ],
            [
              6.4040763393656,
              48.390611842844
            ],
            [
              6.4116261347654,
              48.392088549974
            ],
            [
              6.4223966243317,
              48.407281074326
            ],
            [
              6.4354978133083,
              48.412794961405
            ],
            [
              6.4503615609347,
              48.415050358236
            ],
            [
              6.4726100715082,
              48.410815690615
            ],
            [
              6.4873939781004,
              48.416310724356
            ],
            [
              6.5007526124106,
              48.414237469828
            ],
            [
              6.5337059818062,
              48.431354354086
            ],
            [
              6.5669768071184,
              48.419029968315
            ],
            [
              6.5838721083022,
              48.417225354632
            ],
            [
              6.5787210186402,
              48.426746914508
            ],
            [
              6.5686324041298,
              48.436985966474
            ],
            [
              6.5849688356706,
              48.460510143981
            ],
            [
              6.6014314599629,
              48.467010651289
            ],
            [
              6.6237018958137,
              48.473101218363
            ],
            [
              6.6353897601771,
              48.470497725311
            ],
            [
              6.6388289274108,
              48.463925312006
            ],
            [
              6.6359455272724,
              48.450120517566
            ],
            [
              6.6430248956674,
              48.444216990898
            ],
            [
              6.6482886743858,
              48.433886563346
            ],
            [
              6.6450895623568,
              48.428060807346
            ],
            [
              6.6694101740973,
              48.426054228077
            ],
            [
              6.6995035493816,
              48.413084676232
            ],
            [
              6.7180047311225,
              48.418674291054
            ],
            [
              6.7380464834766,
              48.415526621405
            ],
            [
              6.7460260333456,
              48.41165312407
            ],
            [
              6.7593385145496,
              48.41154233768
            ],
            [
              6.7688298364239,
              48.399542822268
            ],
            [
              6.7813461425445,
              48.402655850004
            ],
            [
              6.7987171845632,
              48.402805490183
            ],
            [
              6.8151664634571,
              48.398845301963
            ],
            [
              6.8229345013421,
              48.40583576746
            ],
            [
              6.8282542114529,
              48.416610381272
            ],
            [
              6.8493577670791,
              48.423972085139
            ],
            [
              6.8550428807667,
              48.432931396922
            ],
            [
              6.8659871866881,
              48.430557429526
            ],
            [
              6.8706808234281,
              48.420236891127
            ],
            [
              6.8908149017495,
              48.418096877888
            ],
            [
              6.9492097088264,
              48.457016009836
            ],
            [
              6.9528725725975,
              48.462495754828
            ],
            [
              6.9829820869226,
              48.479061512794
            ],
            [
              7.0058261141445,
              48.481196614343
            ],
            [
              7.0296277745175,
              48.492275574416
            ],
            [
              7.0505386166589,
              48.494874179012
            ],
            [
              7.0684320713508,
              48.500861399073
            ],
            [
              7.076023326101,
              48.506328209411
            ],
            [
              7.0940126241139,
              48.512948029926
            ],
            [
              7.1090047906143,
              48.511614938694
            ],
            [
              7.1231636635608,
              48.513587820739
            ],
            [
              7.1277243419227,
              48.509520733673
            ],
            [
              7.1220633124076,
              48.500699348096
            ],
            [
              7.1032227316966,
              48.499823397675
            ],
            [
              7.0936784257816,
              48.505231627961
            ],
            [
              7.093659756546,
              48.48928173768
            ],
            [
              7.1168879614082,
              48.484885772315
            ],
            [
              7.1172385701417,
              48.477545363165
            ],
            [
              7.1037570732695,
              48.44948479864
            ],
            [
              7.1092965939078,
              48.438619698319
            ],
            [
              7.0995878866837,
              48.434180387294
            ],
            [
              7.0974259260427,
              48.405647416542
            ],
            [
              7.0855246844242,
              48.398669480184
            ],
            [
              7.1040995144891,
              48.388336667304
            ],
            [
              7.10802631494,
              48.37699074362
            ],
            [
              7.0903768777228,
              48.36561626561
            ],
            [
              7.0785285569966,
              48.351070234059
            ],
            [
              7.1094055216992,
              48.343193984031
            ],
            [
              7.1219031917914,
              48.333292363952
            ],
            [
              7.1442977094519,
              48.332073998048
            ],
            [
              7.1648351203912,
              48.337682945748
            ],
            [
              7.169530852204,
              48.342300751899
            ],
            [
              7.1845441585974,
              48.336049661823
            ],
            [
              7.1772138617934,
              48.325302455967
            ],
            [
              7.1914734958352,
              48.322481635859
            ],
            [
              7.1982872111206,
              48.310471263573
            ],
            [
              7.1936148623215,
              48.302025342222
            ],
            [
              7.1724433560818,
              48.29748374247
            ],
            [
              7.1753401866571,
              48.29113941135
            ],
            [
              7.1595062808046,
              48.275287896649
            ],
            [
              7.1594844662169,
              48.268757347093
            ],
            [
              7.1469013034333,
              48.261679029333
            ],
            [
              7.1335541813458,
              48.245937002976
            ],
            [
              7.1341012954683,
              48.237762905718
            ],
            [
              7.1192521757392,
              48.212023004119
            ],
            [
              7.1077697063835,
              48.201766066981
            ],
            [
              7.1062830120448,
              48.194304277893
            ],
            [
              7.0853295403333,
              48.180875920481
            ],
            [
              7.0778429184518,
              48.171044574039
            ],
            [
              7.0776389366913,
              48.163575922596
            ],
            [
              7.0589137960953,
              48.137261832109
            ],
            [
              7.0698542106814,
              48.12947900779
            ],
            [
              7.0841613064662,
              48.129216503745
            ],
            [
              7.0839309358493,
              48.122199063245
            ],
            [
              7.0767075069295,
              48.108483024102
            ],
            [
              7.0713124724572,
              48.107574840418
            ],
            [
              7.0512918183322,
              48.082302500157
            ],
            [
              7.0374044731857,
              48.078086004732
            ],
            [
              7.0297963112116,
              48.071905133897
            ],
            [
              7.0103277742221,
              48.04187607679
            ],
            [
              7.015626999402,
              48.038015419147
            ],
            [
              7.0018860834717,
              48.022394510938
            ],
            [
              6.9894507346272,
              48.018430704581
            ],
            [
              6.9815394627032,
              48.009420740178
            ],
            [
              6.9622340751476,
              48.002888003739
            ],
            [
              6.9529150495872,
              48.002942220653
            ],
            [
              6.9433524693585,
              47.99795898043
            ],
            [
              6.9437614605246,
              47.980377674244
            ],
            [
              6.9306819263176,
              47.971928947916
            ],
            [
              6.9264902251397,
              47.957681232146
            ],
            [
              6.9194216385109,
              47.944342124554
            ],
            [
              6.9254227078109,
              47.933851589775
            ],
            [
              6.9241258314971,
              47.927416308913
            ],
            [
              6.9131760329106,
              47.919378547089
            ],
            [
              6.9259269609826,
              47.917301975801
            ],
            [
              6.9278167769039,
              47.908213591002
            ],
            [
              6.9184576050125,
              47.898407433189
            ],
            [
              6.900218945286,
              47.891194790477
            ],
            [
              6.8978126882661,
              47.884262639149
            ],
            [
              6.9063144640838,
              47.879797898356
            ],
            [
              6.9069365309395,
              47.868996625385
            ],
            [
              6.9200409082836,
              47.850285895261
            ],
            [
              6.9067918762762,
              47.845648756197
            ],
            [
              6.8975967260823,
              47.83756300421
            ],
            [
              6.8612316827823,
              47.825591254937
            ],
            [
              6.8461756187967,
              47.822942631655
            ],
            [
              6.8393097752574,
              47.824784354742
            ],
            [
              6.8235333222471,
              47.813051201983
            ],
            [
              6.7884284780797,
              47.834484214023
            ],
            [
              6.792547182043,
              47.840496971878
            ],
            [
              6.784819046343,
              47.849620144546
            ],
            [
              6.7644647560578,
              47.853457064113
            ],
            [
              6.7638501387584,
              47.857307907712
            ],
            [
              6.7377719050045,
              47.861560606022
            ],
            [
              6.7304382302284,
              47.867533474661
            ],
            [
              6.7102378561952,
              47.87576777925
            ],
            [
              6.703676313184,
              47.881417920779
            ],
            [
              6.6696633226227,
              47.89320009565
            ],
            [
              6.6455373160956,
              47.904022779887
            ],
            [
              6.6302463718081,
              47.925593409791
            ],
            [
              6.6017847198921,
              47.944400335071
            ],
            [
              6.5682839276999,
              47.93449546901
            ],
            [
              6.5421569547535,
              47.902612242352
            ],
            [
              6.5032137734313,
              47.89621857502
            ],
            [
              6.4786058641603,
              47.885521654796
            ],
            [
              6.4560181967467,
              47.906627288043
            ],
            [
              6.4604356473863,
              47.91345059658
            ],
            [
              6.4363061456524,
              47.936497632925
            ],
            [
              6.4317140631681,
              47.943817555323
            ],
            [
              6.4088868226821,
              47.943264452338
            ],
            [
              6.4053578977345,
              47.95311233294
            ],
            [
              6.394005582089,
              47.956962872907
            ],
            [
              6.3661530482729,
              47.961943699906
            ],
            [
              6.338561201971,
              47.954979185933
            ],
            [
              6.3245064287941,
              47.949279774694
            ],
            [
              6.3097604456805,
              47.949874526566
            ],
            [
              6.2964155087065,
              47.955366770009
            ],
            [
              6.2773320945343,
              47.953805619678
            ],
            [
              6.2379829343014,
              47.932840414292
            ],
            [
              6.2191727089294,
              47.93576331706
            ],
            [
              6.2052242815746,
              47.932044890248
            ],
            [
              6.2079614134083,
              47.94275319831
            ],
            [
              6.1973668542118,
              47.951792167635
            ],
            [
              6.1784443979518,
              47.954215961253
            ],
            [
              6.1679889957893,
              47.952352981729
            ],
            [
              6.160691544552,
              47.964476018183
            ],
            [
              6.1509430707726,
              47.970556896575
            ],
            [
              6.164889049648,
              47.976133645938
            ],
            [
              6.1523407152561,
              47.994505212317
            ],
            [
              6.1560578216767,
              48.006943160047
            ],
            [
              6.1317082771503,
              48.023714993889
            ],
            [
              6.1165344664207,
              48.019389790184
            ],
            [
              6.1092294655483,
              48.012467456506
            ],
            [
              6.0977392042699,
              48.01504283565
            ],
            [
              6.0809347681235,
              48.012700464881
            ],
            [
              6.0725586040075,
              48.015669051022
            ],
            [
              6.0411765157853,
              48.00442320835
            ],
            [
              6.0269197055122,
              47.990054024535
            ],
            [
              6.01816748796,
              47.984218151119
            ],
            [
              6.0235129876577,
              47.978198192556
            ],
            [
              6.0099778425991,
              47.968676110152
            ],
            [
              6.0022660129604,
              47.956088697125
            ],
            [
              5.9965110271696,
              47.957642319673
            ],
            [
              5.9707820414319,
              47.957206856313
            ],
            [
              5.9689005966348,
              47.947407535025
            ],
            [
              5.953718196208,
              47.937030199568
            ],
            [
              5.9296518654303,
              47.938718128621
            ],
            [
              5.9377200582568,
              47.950937203984
            ],
            [
              5.9595125543651,
              47.965838580885
            ],
            [
              5.9475278158692,
              47.979712022025
            ],
            [
              5.9367445426717,
              47.978874239655
            ],
            [
              5.9217138598918,
              47.970783700094
            ],
            [
              5.9180463526732,
              47.94735518647
            ],
            [
              5.9001165275304,
              47.9447526196
            ],
            [
              5.892904334004,
              47.937140708276
            ],
            [
              5.8967125895793,
              47.93202806731
            ],
            [
              5.884723650121,
              47.92604631497
            ],
            [
              5.8843813392277,
              47.929927996017
            ],
            [
              5.8681774729335,
              47.945001844942
            ],
            [
              5.8572501814427,
              47.947896775593
            ],
            [
              5.8500037784374,
              47.962832349204
            ],
            [
              5.8535568852247,
              47.96988182732
            ],
            [
              5.8428079317049,
              47.975560673709
            ],
            [
              5.8400303571214,
              47.965021610731
            ],
            [
              5.83157461884,
              47.957758607577
            ],
            [
              5.8152676018325,
              47.957389502315
            ],
            [
              5.8047728054043,
              47.94757462553
            ],
            [
              5.7872807490879,
              47.95259247623
            ],
            [
              5.7855218231866,
              47.964121795723
            ],
            [
              5.7784867170861,
              47.978010814127
            ],
            [
              5.7937953925203,
              47.991691912896
            ],
            [
              5.7920725220415,
              47.999168067269
            ],
            [
              5.7806861985966,
              48.020926500466
            ],
            [
              5.7777453585169,
              48.031364684582
            ],
            [
              5.7655790134191,
              48.030011530377
            ],
            [
              5.760967814331,
              48.036499444856
            ],
            [
              5.7405717930607,
              48.050047677774
            ],
            [
              5.7269571428765,
              48.046031922901
            ],
            [
              5.7115569131225,
              48.062317740871
            ],
            [
              5.7002829787351,
              48.066601605915
            ],
            [
              5.6967989266543,
              48.077232264437
            ],
            [
              5.6872529897198,
              48.076020625786
            ],
            [
              5.681240554071,
              48.080737903962
            ],
            [
              5.6531765949008,
              48.084054660311
            ],
            [
              5.661279627694,
              48.071989606586
            ],
            [
              5.6484946182822,
              48.071542202301
            ],
            [
              5.6456101522469,
              48.078013185532
            ],
            [
              5.6305352541093,
              48.083984386541
            ],
            [
              5.6376207282395,
              48.08950814261
            ],
            [
              5.6391561616602,
              48.098220745531
            ],
            [
              5.6476689080621,
              48.104325979371
            ],
            [
              5.6674948715307,
              48.10655476293
            ],
            [
              5.6719848282194,
              48.109532293186
            ],
            [
              5.6568796713896,
              48.120400699552
            ],
            [
              5.6647771501347,
              48.129446531023
            ],
            [
              5.6771358185034,
              48.136700695253
            ],
            [
              5.6843229216395,
              48.145045600453
            ],
            [
              5.6812577084998,
              48.15075867206
            ],
            [
              5.6856197203779,
              48.162244100792
            ],
            [
              5.6822393084,
              48.175705308685
            ],
            [
              5.6967394115037,
              48.188881566005
            ],
            [
              5.7309798544964,
              48.189696947755
            ],
            [
              5.7300325067529,
              48.197959613175
            ],
            [
              5.7108479434936,
              48.219930933286
            ],
            [
              5.6877860896924,
              48.23418028384
            ],
            [
              5.6760858494894,
              48.229203496709
            ],
            [
              5.662803981931,
              48.235339250635
            ],
            [
              5.6408971957509,
              48.242355194462
            ],
            [
              5.6494700366852,
              48.254794047252
            ],
            [
              5.6538144936044,
              48.268521890367
            ],
            [
              5.6116505645448,
              48.291813565657
            ],
            [
              5.5986557013251,
              48.283859128519
            ],
            [
              5.5883254913252,
              48.274255206421
            ],
            [
              5.5811084891926,
              48.284092151604
            ],
            [
              5.5494339172574,
              48.309212376842
            ],
            [
              5.5265112322934,
              48.331736718524
            ],
            [
              5.5223686890105,
              48.340847392047
            ],
            [
              5.5259347078446,
              48.347094088677
            ],
            [
              5.4997162811397,
              48.354181000105
            ],
            [
              5.4821531055766,
              48.356198541709
            ],
            [
              5.4699445713636,
              48.349828790224
            ],
            [
              5.4614435085698,
              48.351008535928
            ],
            [
              5.4454174870765,
              48.337548748496
            ],
            [
              5.4257979057073,
              48.331424612905
            ],
            [
              5.4182235382907,
              48.339829235793
            ],
            [
              5.418560018075,
              48.360694271013
            ],
            [
              5.4415772107617,
              48.380227039336
            ],
            [
              5.4087226430654,
              48.383102860768
            ],
            [
              5.3944755711529,
              48.391004497394
            ],
            [
              5.4193856186614,
              48.395120580966
            ],
            [
              5.4437946750486,
              48.412793552672
            ],
            [
              5.4700548229834,
              48.420926283443
            ]
          ]
        ]
      },
      "properties": {
        "code": "88",
        "nom": "Vosges",
        "population": 362397,
        "delitspour1000": "4,385",
        "nombremosque": 19,
        "parentsEtrangers": "87,4",
        "benefRsa": 19419,
        "eoliennes": 54
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.2909694895753,
              48.950965864655
            ],
            [
              2.3269728001661,
              48.945361349484
            ],
            [
              2.3355682105858,
              48.940679976956
            ],
            [
              2.3363529889891,
              48.932347933816
            ],
            [
              2.3198186224138,
              48.915937453257
            ],
            [
              2.3137528853936,
              48.914015216252
            ],
            [
              2.3198871747441,
              48.90045978209
            ],
            [
              2.2915068524977,
              48.8894718708
            ],
            [
              2.2774870298138,
              48.877968320853
            ],
            [
              2.2584671711142,
              48.880387263086
            ],
            [
              2.2317363597469,
              48.86906858161
            ],
            [
              2.2242191058804,
              48.853516917557
            ],
            [
              2.2506124417162,
              48.845554851211
            ],
            [
              2.2551442384175,
              48.834809549369
            ],
            [
              2.2727931901868,
              48.827920084226
            ],
            [
              2.2790519306533,
              48.832489952145
            ],
            [
              2.2921959226619,
              48.82714160912
            ],
            [
              2.3318980606376,
              48.817010929642
            ],
            [
              2.3186962648686,
              48.787997409568
            ],
            [
              2.3258147798433,
              48.781908297526
            ],
            [
              2.3102224901101,
              48.75695290327
            ],
            [
              2.3207207141635,
              48.74875804931
            ],
            [
              2.3132939902039,
              48.730280485815
            ],
            [
              2.3038400608379,
              48.72948996497
            ],
            [
              2.2983820323129,
              48.738637828397
            ],
            [
              2.2802904545364,
              48.733011078618
            ],
            [
              2.274821276941,
              48.740090859412
            ],
            [
              2.2853936451259,
              48.747508493238
            ],
            [
              2.275033240625,
              48.755288275593
            ],
            [
              2.2593929492592,
              48.761011671227
            ],
            [
              2.2476645497167,
              48.761102963541
            ],
            [
              2.2329743918365,
              48.766192980349
            ],
            [
              2.2265538842831,
              48.776101996393
            ],
            [
              2.223319655228,
              48.785943206962
            ],
            [
              2.2115088216259,
              48.788138691837
            ],
            [
              2.2026391702069,
              48.798400652391
            ],
            [
              2.1836952927536,
              48.797237542236
            ],
            [
              2.1766702709493,
              48.813808667803
            ],
            [
              2.1605570086469,
              48.813043649008
            ],
            [
              2.1515805126399,
              48.817021316976
            ],
            [
              2.1458760215967,
              48.836022751826
            ],
            [
              2.1532113486874,
              48.848852456588
            ],
            [
              2.1528705339192,
              48.873338063287
            ],
            [
              2.1677833601069,
              48.894417281801
            ],
            [
              2.1765183808683,
              48.900349435762
            ],
            [
              2.2005910092695,
              48.908679329899
            ],
            [
              2.231140371461,
              48.927732507513
            ],
            [
              2.2687285425666,
              48.946575058002
            ],
            [
              2.2909694895753,
              48.950965864655
            ]
          ]
        ]
      },
      "properties": {
        "code": "92",
        "nom": "Hauts-de-Seine",
        "population": 1626213,
        "delitspour1000": "4,806",
        "nombremosque": 54,
        "parentsEtrangers": "52,6",
        "benefRsa": 56116,
        "eoliennes": 5
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3.0320629441459,
              46.794909389217
            ],
            [
              3.0368385012729,
              46.784399457513
            ],
            [
              3.0361449719957,
              46.776351034743
            ],
            [
              3.0490666680714,
              46.758080742151
            ],
            [
              3.0610780913415,
              46.749849808096
            ],
            [
              3.0839336875931,
              46.737632194007
            ],
            [
              3.1297797354937,
              46.727201530755
            ],
            [
              3.1631524413883,
              46.693541123032
            ],
            [
              3.1972601846791,
              46.679928148065
            ],
            [
              3.2155446436111,
              46.682892755029
            ],
            [
              3.2330363473519,
              46.697017662903
            ],
            [
              3.2551640362648,
              46.706289026218
            ],
            [
              3.2697959512206,
              46.71674181988
            ],
            [
              3.2988329664362,
              46.713590484885
            ],
            [
              3.3139651835149,
              46.688751611332
            ],
            [
              3.3467035850706,
              46.684418162056
            ],
            [
              3.3664449691016,
              46.691259477216
            ],
            [
              3.3754269760827,
              46.699410803841
            ],
            [
              3.3787310431309,
              46.71134766179
            ],
            [
              3.3877581200663,
              46.714818365449
            ],
            [
              3.4074108289121,
              46.711510582242
            ],
            [
              3.4341396009475,
              46.711907843672
            ],
            [
              3.4295672938027,
              46.702504791218
            ],
            [
              3.4329783660003,
              46.693340026098
            ],
            [
              3.4513122938683,
              46.689900877873
            ],
            [
              3.4535963621348,
              46.681987883569
            ],
            [
              3.4473385862025,
              46.663554460402
            ],
            [
              3.4572892729042,
              46.651760006926
            ],
            [
              3.4865354798384,
              46.65380880583
            ],
            [
              3.4871533162964,
              46.658256156349
            ],
            [
              3.5174930194471,
              46.683185185586
            ],
            [
              3.5300363962253,
              46.688813284083
            ],
            [
              3.5464728563281,
              46.678292894912
            ],
            [
              3.5543959772727,
              46.684731744078
            ],
            [
              3.5555591963708,
              46.695741177165
            ],
            [
              3.548207507912,
              46.706166654281
            ],
            [
              3.5503869458917,
              46.715861095646
            ],
            [
              3.5773137335153,
              46.714852724197
            ],
            [
              3.598000652576,
              46.723983498355
            ],
            [
              3.5971876767747,
              46.728401657187
            ],
            [
              3.5845515939144,
              46.739382605418
            ],
            [
              3.5806709648764,
              46.752741235279
            ],
            [
              3.5910525816638,
              46.762426406979
            ],
            [
              3.5977490685569,
              46.76202889412
            ],
            [
              3.6020398798222,
              46.751247280578
            ],
            [
              3.6201072037828,
              46.754059565827
            ],
            [
              3.6294223422648,
              46.749456328005
            ],
            [
              3.6225898833129,
              46.740130866568
            ],
            [
              3.6353341554133,
              46.728514790806
            ],
            [
              3.6381391050479,
              46.722723829214
            ],
            [
              3.637895263919,
              46.707205199291
            ],
            [
              3.6513931816228,
              46.70282167235
            ],
            [
              3.6554716665381,
              46.687748401639
            ],
            [
              3.6801004977635,
              46.66852089269
            ],
            [
              3.6969575528098,
              46.660583034448
            ],
            [
              3.699679505,
              46.651867135799
            ],
            [
              3.7121523238188,
              46.633631635674
            ],
            [
              3.7227091007956,
              46.627609885471
            ],
            [
              3.7230250098493,
              46.622072726143
            ],
            [
              3.7138737202882,
              46.613995279712
            ],
            [
              3.7176467323502,
              46.605806262315
            ],
            [
              3.7323776901235,
              46.604907119963
            ],
            [
              3.7432893129951,
              46.567565263244
            ],
            [
              3.7406549316466,
              46.559049395025
            ],
            [
              3.73154117491,
              46.549578224034
            ],
            [
              3.7418443310505,
              46.539508443385
            ],
            [
              3.7546629110025,
              46.536019887064
            ],
            [
              3.7656008328403,
              46.537908481562
            ],
            [
              3.7878963661806,
              46.528042650186
            ],
            [
              3.8017563839321,
              46.519902255804
            ],
            [
              3.8113440515561,
              46.520138117834
            ],
            [
              3.8176755382384,
              46.525229454055
            ],
            [
              3.8340033360184,
              46.531134997034
            ],
            [
              3.8464630176371,
              46.524356398489
            ],
            [
              3.8397556873666,
              46.517562051932
            ],
            [
              3.8494709455035,
              46.513023833485
            ],
            [
              3.8600251576626,
              46.515222323315
            ],
            [
              3.8646271424558,
              46.509717077517
            ],
            [
              3.860387224324,
              46.495601253328
            ],
            [
              3.8649127119961,
              46.489736338385
            ],
            [
              3.8904665810402,
              46.481246458453
            ],
            [
              3.896985633842,
              46.481471942919
            ],
            [
              3.9013529089878,
              46.490636303507
            ],
            [
              3.9189702450008,
              46.496061208045
            ],
            [
              3.9375764836594,
              46.49076190287
            ],
            [
              3.9496133283036,
              46.492478893726
            ],
            [
              3.9579376111726,
              46.489753821104
            ],
            [
              3.9520867242123,
              46.481423383561
            ],
            [
              3.9565931687991,
              46.47688226209
            ],
            [
              3.9730562040646,
              46.477457398934
            ],
            [
              3.9980402829299,
              46.465463980924
            ],
            [
              4.0015415164833,
              46.458618151559
            ],
            [
              3.997444424826,
              46.450264670788
            ],
            [
              4.0055701432229,
              46.443531306791
            ],
            [
              3.9881422985694,
              46.435461575775
            ],
            [
              3.9956151474212,
              46.4286940195
            ],
            [
              3.9844480228975,
              46.416663389961
            ],
            [
              3.9886970251491,
              46.408772224942
            ],
            [
              3.9772206054151,
              46.399220047306
            ],
            [
              3.9842615206377,
              46.378286038678
            ],
            [
              3.9916043062641,
              46.369630419679
            ],
            [
              3.9886640376734,
              46.360561403255
            ],
            [
              3.9847410554074,
              46.32973022678
            ],
            [
              3.9974052217542,
              46.323291087799
            ],
            [
              3.9866271252361,
              46.319195576966
            ],
            [
              3.9481286581435,
              46.31979164002
            ],
            [
              3.9477193967384,
              46.303444864233
            ],
            [
              3.942781911555,
              46.298925500612
            ],
            [
              3.9300977722843,
              46.295820251472
            ],
            [
              3.9135709840696,
              46.296680727045
            ],
            [
              3.8974208259956,
              46.291460834216
            ],
            [
              3.8912386850317,
              46.28524616424
            ],
            [
              3.899538631706,
              46.275908099459
            ],
            [
              3.8880579460788,
              46.27381621963
            ],
            [
              3.8778014339102,
              46.275936357353
            ],
            [
              3.8676266829896,
              46.262530668465
            ],
            [
              3.820453152188,
              46.259693449536
            ],
            [
              3.8048855350295,
              46.254857096313
            ],
            [
              3.8064067390994,
              46.243904931901
            ],
            [
              3.802694767872,
              46.238508201577
            ],
            [
              3.7795833492069,
              46.243233638723
            ],
            [
              3.7685491775545,
              46.23948507045
            ],
            [
              3.773158056228,
              46.227368816678
            ],
            [
              3.7811487813348,
              46.223127689157
            ],
            [
              3.7931434307041,
              46.209403501526
            ],
            [
              3.7893076176261,
              46.194791199175
            ],
            [
              3.7909760971753,
              46.184421716016
            ],
            [
              3.8068202458538,
              46.165432514608
            ],
            [
              3.8078653732897,
              46.161593321821
            ],
            [
              3.7897672156925,
              46.154853997372
            ],
            [
              3.7925477636674,
              46.144965705297
            ],
            [
              3.8008429256847,
              46.132058715551
            ],
            [
              3.7934862152259,
              46.11219865371
            ],
            [
              3.8025575098561,
              46.1103266156
            ],
            [
              3.8218751675633,
              46.087982942483
            ],
            [
              3.8201222967616,
              46.080582391707
            ],
            [
              3.8069950481339,
              46.061272636728
            ],
            [
              3.8067691666001,
              46.045618391287
            ],
            [
              3.8140768170665,
              46.035278403322
            ],
            [
              3.8133908047707,
              46.027624240033
            ],
            [
              3.8248068228184,
              46.007992803975
            ],
            [
              3.8254484135633,
              45.995971307468
            ],
            [
              3.8094533891965,
              45.984528293129
            ],
            [
              3.7999111245532,
              45.983663126279
            ],
            [
              3.788905406334,
              45.97386142098
            ],
            [
              3.7780103611486,
              45.971797121415
            ],
            [
              3.7716341928381,
              45.977142201771
            ],
            [
              3.7560344687643,
              45.98282892353
            ],
            [
              3.7543128815764,
              45.972389709109
            ],
            [
              3.7412768921209,
              45.966006368026
            ],
            [
              3.7244244240682,
              45.971884240348
            ],
            [
              3.7088901699693,
              45.97378360778
            ],
            [
              3.7018783842183,
              45.966895016744
            ],
            [
              3.705814131782,
              45.956589216318
            ],
            [
              3.6938345940228,
              45.949490567902
            ],
            [
              3.6940151632959,
              45.930727869968
            ],
            [
              3.6827781689172,
              45.940490397824
            ],
            [
              3.6769360542791,
              45.949512739521
            ],
            [
              3.6767583931205,
              45.958063393878
            ],
            [
              3.669739069879,
              45.961205643752
            ],
            [
              3.6463106445608,
              45.963504863505
            ],
            [
              3.6319957322686,
              45.961061509138
            ],
            [
              3.6280248857031,
              45.978697797748
            ],
            [
              3.6224109331286,
              45.989739452473
            ],
            [
              3.6169361630777,
              45.992995963943
            ],
            [
              3.6014373069602,
              46.015081671296
            ],
            [
              3.5884708591387,
              46.024134197777
            ],
            [
              3.5702426376894,
              46.017676117086
            ],
            [
              3.5583822511587,
              46.018705024732
            ],
            [
              3.5476572029407,
              46.015536218752
            ],
            [
              3.5346332381264,
              46.022379265254
            ],
            [
              3.5207051439429,
              46.02036662552
            ],
            [
              3.4997046841734,
              46.013746955353
            ],
            [
              3.481365707913,
              46.013709267662
            ],
            [
              3.4641409824748,
              46.008169157186
            ],
            [
              3.4635021580888,
              46.017937775932
            ],
            [
              3.4714881553342,
              46.041837627611
            ],
            [
              3.459684718269,
              46.05221310179
            ],
            [
              3.4535876455667,
              46.063790238523
            ],
            [
              3.441645443805,
              46.059514040098
            ],
            [
              3.4336861041744,
              46.060791050488
            ],
            [
              3.4190243995629,
              46.074294275891
            ],
            [
              3.4045994258891,
              46.067143517788
            ],
            [
              3.3861917265904,
              46.064436835237
            ],
            [
              3.3716020587659,
              46.055056424918
            ],
            [
              3.3589678841642,
              46.053259195739
            ],
            [
              3.3320492235943,
              46.044097580518
            ],
            [
              3.3327642338523,
              46.054978015735
            ],
            [
              3.3171686018834,
              46.057273946875
            ],
            [
              3.31621925685,
              46.062006013015
            ],
            [
              3.3023909460534,
              46.065769077557
            ],
            [
              3.2659770500953,
              46.064185379521
            ],
            [
              3.2563742222901,
              46.065793261654
            ],
            [
              3.2415132669656,
              46.063283777745
            ],
            [
              3.2145121735371,
              46.074943616392
            ],
            [
              3.2062653675586,
              46.072623029145
            ],
            [
              3.1799729538988,
              46.073587187232
            ],
            [
              3.1793471377517,
              46.06657424048
            ],
            [
              3.1665370397662,
              46.06567976991
            ],
            [
              3.1281003938417,
              46.071398281325
            ],
            [
              3.113053611115,
              46.081444843541
            ],
            [
              3.1007827299491,
              46.0823388867
            ],
            [
              3.0981067932511,
              46.099091251655
            ],
            [
              3.0873696406714,
              46.113064814944
            ],
            [
              3.0687190786824,
              46.104211419129
            ],
            [
              3.0480700398036,
              46.100317519725
            ],
            [
              3.0400119843362,
              46.107430908448
            ],
            [
              3.0164727327153,
              46.102446254469
            ],
            [
              3.008738722988,
              46.115179124518
            ],
            [
              2.9937696637853,
              46.123724023278
            ],
            [
              2.9813552292011,
              46.124850975135
            ],
            [
              2.971750160512,
              46.121781028793
            ],
            [
              2.9636876604981,
              46.129608480581
            ],
            [
              2.9552280775446,
              46.148189720109
            ],
            [
              2.9532834742817,
              46.170918110503
            ],
            [
              2.9427678272194,
              46.168545924839
            ],
            [
              2.9111330198118,
              46.177437699807
            ],
            [
              2.9184707210425,
              46.182191458572
            ],
            [
              2.9201095289038,
              46.190983155
            ],
            [
              2.9095928633152,
              46.203492910244
            ],
            [
              2.913326209903,
              46.210334088438
            ],
            [
              2.9261575114081,
              46.219422710207
            ],
            [
              2.9364864054072,
              46.221708066486
            ],
            [
              2.9322690545529,
              46.230926567436
            ],
            [
              2.9371418861435,
              46.242915665181
            ],
            [
              2.921432650671,
              46.250307852218
            ],
            [
              2.9033902209214,
              46.248869408373
            ],
            [
              2.8938256672135,
              46.252089110009
            ],
            [
              2.8674848695894,
              46.250753093513
            ],
            [
              2.8557278827609,
              46.255486133208
            ],
            [
              2.8393736457481,
              46.250723197702
            ],
            [
              2.8191591437729,
              46.241791609208
            ],
            [
              2.8186251178751,
              46.232571614587
            ],
            [
              2.8229834010983,
              46.21982222559
            ],
            [
              2.8102712209548,
              46.196213517628
            ],
            [
              2.7903423928364,
              46.199084389819
            ],
            [
              2.7720925093441,
              46.209683127276
            ],
            [
              2.7574594037315,
              46.211087339564
            ],
            [
              2.7423030105892,
              46.220724340564
            ],
            [
              2.7330534561794,
              46.223321627796
            ],
            [
              2.7209140750474,
              46.211307002875
            ],
            [
              2.7305575391219,
              46.196091290973
            ],
            [
              2.7224523871244,
              46.181341584244
            ],
            [
              2.7026972724062,
              46.181002577227
            ],
            [
              2.6774533498992,
              46.172759081776
            ],
            [
              2.6712851135172,
              46.15323761723
            ],
            [
              2.6528235484183,
              46.123751794785
            ],
            [
              2.6406964171362,
              46.118476777646
            ],
            [
              2.6218275727406,
              46.122529879748
            ],
            [
              2.6013604101779,
              46.130856129746
            ],
            [
              2.5915076247582,
              46.126875128538
            ],
            [
              2.5819676480582,
              46.134724292657
            ],
            [
              2.5653725529015,
              46.143035759957
            ],
            [
              2.5654816305578,
              46.153967351319
            ],
            [
              2.5607637153191,
              46.155596225642
            ],
            [
              2.5597996290957,
              46.173366559433
            ],
            [
              2.5431674609911,
              46.175602605523
            ],
            [
              2.5286290573702,
              46.184894945309
            ],
            [
              2.5280194815711,
              46.195466990157
            ],
            [
              2.5216954121117,
              46.203035380932
            ],
            [
              2.5215270201899,
              46.21133367553
            ],
            [
              2.5154284346668,
              46.228012112114
            ],
            [
              2.5163085117785,
              46.239436870366
            ],
            [
              2.489106418147,
              46.248968644305
            ],
            [
              2.4770972903349,
              46.269361724579
            ],
            [
              2.4789445821479,
              46.281145991526
            ],
            [
              2.4430263839161,
              46.294995852237
            ],
            [
              2.4309509758156,
              46.291519676804
            ],
            [
              2.421335782135,
              46.284622030263
            ],
            [
              2.4161017112175,
              46.300056006693
            ],
            [
              2.4204656768519,
              46.310129656565
            ],
            [
              2.4113176626179,
              46.314094483732
            ],
            [
              2.3918581379529,
              46.329985164159
            ],
            [
              2.3840641130619,
              46.328908641081
            ],
            [
              2.370482749419,
              46.312629017475
            ],
            [
              2.3549060485425,
              46.325678438006
            ],
            [
              2.3345821354246,
              46.325320260055
            ],
            [
              2.3230230944721,
              46.329277448172
            ],
            [
              2.3093794648902,
              46.342006193666
            ],
            [
              2.3027460023726,
              46.354419802873
            ],
            [
              2.313389172619,
              46.356936451493
            ],
            [
              2.3233383727294,
              46.366525024524
            ],
            [
              2.3371146519405,
              46.366795923975
            ],
            [
              2.3315900743858,
              46.378176612964
            ],
            [
              2.3154715753059,
              46.375205741584
            ],
            [
              2.2850442774167,
              46.385857562809
            ],
            [
              2.2804029533754,
              46.410236476962
            ],
            [
              2.2810437278384,
              46.420403547753
            ],
            [
              2.2857604795168,
              46.453514664499
            ],
            [
              2.3054693178764,
              46.475428936804
            ],
            [
              2.3166710404192,
              46.468549195709
            ],
            [
              2.3242884285484,
              46.470978980874
            ],
            [
              2.3298217389117,
              46.479616607338
            ],
            [
              2.3241785781947,
              46.49036759012
            ],
            [
              2.3520036091655,
              46.512206845202
            ],
            [
              2.3683002910262,
              46.51843386298
            ],
            [
              2.3884650716406,
              46.518246292054
            ],
            [
              2.4131644849466,
              46.520301786364
            ],
            [
              2.4266867789218,
              46.526121661214
            ],
            [
              2.4502811792897,
              46.521935935452
            ],
            [
              2.4685711377311,
              46.526009632976
            ],
            [
              2.4829275256976,
              46.532694737487
            ],
            [
              2.4969434776113,
              46.533665788244
            ],
            [
              2.4991226886431,
              46.521292172555
            ],
            [
              2.512426202843,
              46.523880853474
            ],
            [
              2.5201385391568,
              46.530864132368
            ],
            [
              2.5275313125159,
              46.529030044988
            ],
            [
              2.5366546066244,
              46.519702557793
            ],
            [
              2.5540275760751,
              46.529387627047
            ],
            [
              2.5724916070298,
              46.533849645617
            ],
            [
              2.5835051726425,
              46.542741752192
            ],
            [
              2.6149607060411,
              46.553276455558
            ],
            [
              2.60621930247,
              46.565758488844
            ],
            [
              2.6093779370947,
              46.571328686404
            ],
            [
              2.6045186165205,
              46.579001838563
            ],
            [
              2.6023203253116,
              46.595164451357
            ],
            [
              2.5815597567024,
              46.593164438775
            ],
            [
              2.5779519369239,
              46.603788174773
            ],
            [
              2.5691010154446,
              46.609518978116
            ],
            [
              2.5859628971047,
              46.6142967727
            ],
            [
              2.5852765586441,
              46.622738638042
            ],
            [
              2.5966478585977,
              46.637215067759
            ],
            [
              2.5895802639199,
              46.648026747075
            ],
            [
              2.5673899068872,
              46.652347408754
            ],
            [
              2.5721892884417,
              46.659444642004
            ],
            [
              2.5849748988646,
              46.664165776546
            ],
            [
              2.6050484721825,
              46.668158037606
            ],
            [
              2.6095979741835,
              46.662840354764
            ],
            [
              2.6246414896646,
              46.657300692831
            ],
            [
              2.6308613163993,
              46.672294145791
            ],
            [
              2.6215530530757,
              46.678653259953
            ],
            [
              2.6234039357923,
              46.688702717854
            ],
            [
              2.6313578708624,
              46.690886867154
            ],
            [
              2.6478858990075,
              46.688908315282
            ],
            [
              2.6547293011216,
              46.696536792562
            ],
            [
              2.6777927654584,
              46.704612208045
            ],
            [
              2.6881649904859,
              46.720909610987
            ],
            [
              2.7009209254283,
              46.720959754269
            ],
            [
              2.7049698717297,
              46.739389993021
            ],
            [
              2.7287214908144,
              46.748308579779
            ],
            [
              2.7372898083354,
              46.743150242056
            ],
            [
              2.7435704821949,
              46.73008326729
            ],
            [
              2.7567441474108,
              46.724772483564
            ],
            [
              2.7585207590938,
              46.717748505775
            ],
            [
              2.7744893811293,
              46.718902897367
            ],
            [
              2.787290827625,
              46.728613960925
            ],
            [
              2.8010724027789,
              46.733722377214
            ],
            [
              2.8276177034814,
              46.735286079973
            ],
            [
              2.8448121194469,
              46.726844055458
            ],
            [
              2.845419928846,
              46.742855932849
            ],
            [
              2.8612332862758,
              46.754030340445
            ],
            [
              2.8770157582915,
              46.761468535881
            ],
            [
              2.8761691440531,
              46.768445733319
            ],
            [
              2.9098049149944,
              46.779347563944
            ],
            [
              2.9081271826571,
              46.787903599841
            ],
            [
              2.9243754548552,
              46.794523429403
            ],
            [
              2.9378161550649,
              46.795713700537
            ],
            [
              2.9529591220769,
              46.790999230159
            ],
            [
              2.959918627937,
              46.803872076205
            ],
            [
              2.977801671991,
              46.80377337386
            ],
            [
              2.9895396276531,
              46.799298294916
            ],
            [
              3.0033551657685,
              46.798122809815
            ],
            [
              3.0175184994153,
              46.799900698717
            ],
            [
              3.0320629441459,
              46.794909389217
            ]
          ]
        ]
      },
      "properties": {
        "code": "3",
        "nom": "Allier",
        "population": 335628,
        "delitspour1000": "3,948",
        "nombremosque": 7,
        "benefRsa": 19321,
        "eoliennes": 28
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                7.0671175705284,
                43.513649369297
              ],
              [
                7.0361331446075,
                43.517461440788
              ],
              [
                7.0322078648162,
                43.523439858109
              ],
              [
                7.0500971114395,
                43.522515412284
              ],
              [
                7.0708767870982,
                43.51736504678
              ],
              [
                7.0671175705284,
                43.513649369297
              ]
            ]
          ],
          [
            [
              [
                6.8874351607458,
                44.361051257141
              ],
              [
                6.9039405145375,
                44.358363946255
              ],
              [
                6.922408948521,
                44.348259672782
              ],
              [
                6.9264745551235,
                44.334748792521
              ],
              [
                6.952951586736,
                44.317556187214
              ],
              [
                6.9606383114524,
                44.308313554754
              ],
              [
                6.956527244135,
                44.298637866463
              ],
              [
                6.9746845616164,
                44.284947324084
              ],
              [
                6.9818271258487,
                44.286410035391
              ],
              [
                6.9934466941361,
                44.280889629925
              ],
              [
                6.9961055050865,
                44.274898557636
              ],
              [
                6.9971354940846,
                44.251969663265
              ],
              [
                7.008058899486,
                44.236434519326
              ],
              [
                7.0236065351574,
                44.234002611242
              ],
              [
                7.0309527146887,
                44.227886412564
              ],
              [
                7.0423543174862,
                44.225341715295
              ],
              [
                7.071038657434,
                44.232947084937
              ],
              [
                7.0875099114445,
                44.228292359933
              ],
              [
                7.0956034766404,
                44.220998156705
              ],
              [
                7.1122287931144,
                44.217330653519
              ],
              [
                7.1410513675592,
                44.200999432021
              ],
              [
                7.1597408386494,
                44.20620668921
              ],
              [
                7.1889131681001,
                44.197800735913
              ],
              [
                7.1936149196336,
                44.187096791929
              ],
              [
                7.2077263762825,
                44.179359647414
              ],
              [
                7.2188636397203,
                44.168943578203
              ],
              [
                7.2330706376379,
                44.171847122805
              ],
              [
                7.2433604024762,
                44.16824934948
              ],
              [
                7.2485713354452,
                44.158510912489
              ],
              [
                7.2627871994961,
                44.148031915773
              ],
              [
                7.2819657633804,
                44.143448609313
              ],
              [
                7.3094385951796,
                44.145823690919
              ],
              [
                7.319723239617,
                44.142984830429
              ],
              [
                7.3441996623578,
                44.145160179669
              ],
              [
                7.3486515313978,
                44.132703415252
              ],
              [
                7.3560379859181,
                44.120862631994
              ],
              [
                7.3678650497535,
                44.116393672544
              ],
              [
                7.3862710914984,
                44.123786171069
              ],
              [
                7.402623982354,
                44.120705927946
              ],
              [
                7.4269527801501,
                44.112874801646
              ],
              [
                7.4299229881381,
                44.12780018313
              ],
              [
                7.4353553212408,
                44.12936252164
              ],
              [
                7.457603190617,
                44.126386684548
              ],
              [
                7.4877154419517,
                44.135762163943
              ],
              [
                7.5057396587933,
                44.143648852302
              ],
              [
                7.520182785703,
                44.137674317137
              ],
              [
                7.537290343427,
                44.145959889905
              ],
              [
                7.5567907106942,
                44.146921089934
              ],
              [
                7.5730685064726,
                44.152945056661
              ],
              [
                7.6145604124219,
                44.149672411542
              ],
              [
                7.6272700685025,
                44.158689779815
              ],
              [
                7.6300545159959,
                44.170243633498
              ],
              [
                7.6366204125639,
                44.177106922757
              ],
              [
                7.645279607809,
                44.177974572433
              ],
              [
                7.6845890381135,
                44.174017398395
              ],
              [
                7.6809496805966,
                44.164264593246
              ],
              [
                7.6708532380976,
                44.153736855882
              ],
              [
                7.6785361248798,
                44.145890169308
              ],
              [
                7.6665952665469,
                44.130757105938
              ],
              [
                7.6745231655973,
                44.117978245486
              ],
              [
                7.687962616314,
                44.110091318983
              ],
              [
                7.6966096855559,
                44.097734290199
              ],
              [
                7.7119543533479,
                44.088263855715
              ],
              [
                7.7169378581589,
                44.081762896271
              ],
              [
                7.7140726367423,
                44.065430655301
              ],
              [
                7.7040230585525,
                44.0525085058
              ],
              [
                7.7000270318339,
                44.040791675149
              ],
              [
                7.6898090293654,
                44.039470511681
              ],
              [
                7.6782822841591,
                44.03329165478
              ],
              [
                7.6649824687263,
                44.030663880807
              ],
              [
                7.6618347607452,
                44.017913762433
              ],
              [
                7.6702052888494,
                43.998468338078
              ],
              [
                7.6485982666394,
                43.974109522666
              ],
              [
                7.6401015316808,
                43.971240651171
              ],
              [
                7.6155485013064,
                43.957789271068
              ],
              [
                7.5980648513608,
                43.957024140012
              ],
              [
                7.5836834071779,
                43.952447983137
              ],
              [
                7.5671101389754,
                43.943674367953
              ],
              [
                7.5707221149432,
                43.937343618185
              ],
              [
                7.5682448275089,
                43.923261011537
              ],
              [
                7.5599292736857,
                43.914444652084
              ],
              [
                7.5628129682105,
                43.901908468635
              ],
              [
                7.5592240305265,
                43.89729711992
              ],
              [
                7.5360791029785,
                43.892182868868
              ],
              [
                7.5105146453694,
                43.881552779572
              ],
              [
                7.5012119292534,
                43.874828789654
              ],
              [
                7.4954411094938,
                43.864355738536
              ],
              [
                7.4986078220042,
                43.846177765064
              ],
              [
                7.5073719223254,
                43.841680148497
              ],
              [
                7.5102522518857,
                43.828199938911
              ],
              [
                7.5187225864577,
                43.802507945967
              ],
              [
                7.5285185561398,
                43.790517639429
              ],
              [
                7.5247262568589,
                43.784879584787
              ],
              [
                7.5117948400642,
                43.781115025816
              ],
              [
                7.5114975854043,
                43.776215035435
              ],
              [
                7.4902105843582,
                43.767195024672
              ],
              [
                7.4759133369426,
                43.750794270454
              ],
              [
                7.4597361031449,
                43.7600397197
              ],
              [
                7.4466232736677,
                43.751857229949
              ],
              [
                7.4372257692862,
                43.751597325308
              ],
              [
                7.4122682542985,
                43.734386932687
              ],
              [
                7.4156350290343,
                43.725913755066
              ],
              [
                7.4064566044271,
                43.71962940636
              ],
              [
                7.3979400485547,
                43.718906163039
              ],
              [
                7.3832708147641,
                43.722581448981
              ],
              [
                7.3693528634512,
                43.719373461503
              ],
              [
                7.3585182511974,
                43.721877364751
              ],
              [
                7.3511879110757,
                43.715209965617
              ],
              [
                7.3361061972735,
                43.710332184327
              ],
              [
                7.3296137110006,
                43.701909777531
              ],
              [
                7.3336420480539,
                43.693576495112
              ],
              [
                7.3353474080935,
                43.67821377735
              ],
              [
                7.3263641460951,
                43.674107370674
              ],
              [
                7.3202887345678,
                43.69132875931
              ],
              [
                7.3253410136934,
                43.700155817322
              ],
              [
                7.3147161986497,
                43.70653561017
              ],
              [
                7.3068448365673,
                43.697863928793
              ],
              [
                7.308767626144,
                43.691876117984
              ],
              [
                7.2972030752041,
                43.686139330004
              ],
              [
                7.2851740716363,
                43.694320779226
              ],
              [
                7.2624291447135,
                43.69425697459
              ],
              [
                7.2418855125179,
                43.688481372627
              ],
              [
                7.230632479809,
                43.678552633914
              ],
              [
                7.2258833224864,
                43.662250909247
              ],
              [
                7.2064521654727,
                43.645639308042
              ],
              [
                7.1945395872153,
                43.657918311572
              ],
              [
                7.1792252554182,
                43.655921466708
              ],
              [
                7.1676659649864,
                43.657401670267
              ],
              [
                7.1510535551671,
                43.649273147891
              ],
              [
                7.1371975190968,
                43.634490533121
              ],
              [
                7.1264731964483,
                43.605277906363
              ],
              [
                7.1258107141258,
                43.594574055426
              ],
              [
                7.1296893171876,
                43.58264162915
              ],
              [
                7.1255471645856,
                43.575784139356
              ],
              [
                7.1392394995248,
                43.564445498702
              ],
              [
                7.1347862801153,
                43.555745223246
              ],
              [
                7.1441717937997,
                43.552949923404
              ],
              [
                7.1385034267534,
                43.545620722537
              ],
              [
                7.1191937177542,
                43.544452103717
              ],
              [
                7.1216246082512,
                43.554189213515
              ],
              [
                7.1145834190314,
                43.565833873571
              ],
              [
                7.1012671442927,
                43.5706815908
              ],
              [
                7.0843069068568,
                43.569546459525
              ],
              [
                7.0675647780773,
                43.561637194767
              ],
              [
                7.0559473239941,
                43.547965476969
              ],
              [
                7.0404439376582,
                43.541582782416
              ],
              [
                7.026613869621,
                43.548604342901
              ],
              [
                7.0148286615711,
                43.550852905577
              ],
              [
                6.9872491529001,
                43.548425210345
              ],
              [
                6.9718325905645,
                43.545451297616
              ],
              [
                6.9521643765802,
                43.53471627255
              ],
              [
                6.9383636746132,
                43.516390841291
              ],
              [
                6.9383938694233,
                43.508638111929
              ],
              [
                6.9547512806261,
                43.504724793903
              ],
              [
                6.9489893541651,
                43.493480863369
              ],
              [
                6.9488401316243,
                43.485496928717
              ],
              [
                6.9326931753823,
                43.484349210212
              ],
              [
                6.9337211159516,
                43.480065494401
              ],
              [
                6.9206608907656,
                43.482066631594
              ],
              [
                6.9042116903824,
                43.494921296825
              ],
              [
                6.8842288648328,
                43.502535772082
              ],
              [
                6.8917133536696,
                43.513038158278
              ],
              [
                6.8963833605182,
                43.52715725565
              ],
              [
                6.8833123954425,
                43.52855037557
              ],
              [
                6.8795407658693,
                43.535357663712
              ],
              [
                6.8878058162612,
                43.552743885637
              ],
              [
                6.9029178018107,
                43.564799455437
              ],
              [
                6.9071682106677,
                43.572965802144
              ],
              [
                6.8984263507069,
                43.581563493959
              ],
              [
                6.9079709869474,
                43.594116034305
              ],
              [
                6.9080139239391,
                43.59907465015
              ],
              [
                6.8932899439034,
                43.611705853916
              ],
              [
                6.8879290053236,
                43.607412542097
              ],
              [
                6.8634189982789,
                43.605129030293
              ],
              [
                6.8387731211541,
                43.613612526665
              ],
              [
                6.8349114967132,
                43.619940414631
              ],
              [
                6.8173210868001,
                43.628709934933
              ],
              [
                6.7987395361277,
                43.628182176835
              ],
              [
                6.7939209319799,
                43.639319022493
              ],
              [
                6.7773854479855,
                43.658265712539
              ],
              [
                6.7608002192821,
                43.666488900835
              ],
              [
                6.7645656155136,
                43.687414652206
              ],
              [
                6.7743545020716,
                43.69341005262
              ],
              [
                6.7742456350571,
                43.7036421103
              ],
              [
                6.7675809286236,
                43.711770075969
              ],
              [
                6.7665867654022,
                43.720214921984
              ],
              [
                6.7565312672423,
                43.729552266493
              ],
              [
                6.7535373268357,
                43.738168735403
              ],
              [
                6.71941591177,
                43.738141245079
              ],
              [
                6.700791591552,
                43.754314788969
              ],
              [
                6.6827124803446,
                43.757940318117
              ],
              [
                6.6707481333912,
                43.75154132872
              ],
              [
                6.6586508318757,
                43.748576829963
              ],
              [
                6.6514591133944,
                43.765932123349
              ],
              [
                6.6393082606477,
                43.769187336968
              ],
              [
                6.6451865352721,
                43.774643391823
              ],
              [
                6.6377997453195,
                43.780959269326
              ],
              [
                6.6363906079569,
                43.788947137136
              ],
              [
                6.6415189172738,
                43.795468116229
              ],
              [
                6.6757060249605,
                43.802450144872
              ],
              [
                6.6918092836481,
                43.804596539395
              ],
              [
                6.7078877655875,
                43.809522459717
              ],
              [
                6.7120036485147,
                43.814856991509
              ],
              [
                6.7036941981974,
                43.822822478033
              ],
              [
                6.683397147817,
                43.829205713256
              ],
              [
                6.667932367866,
                43.830922690832
              ],
              [
                6.6714829784168,
                43.84209744137
              ],
              [
                6.6779199825238,
                43.850960494829
              ],
              [
                6.6987237488398,
                43.85521351124
              ],
              [
                6.6973270734627,
                43.873654675908
              ],
              [
                6.672786517564,
                43.883154023665
              ],
              [
                6.6726434197403,
                43.888382285729
              ],
              [
                6.713400273635,
                43.880336747948
              ],
              [
                6.7455614971153,
                43.872111681853
              ],
              [
                6.7624119369307,
                43.876176098297
              ],
              [
                6.7820616049909,
                43.884511555013
              ],
              [
                6.8042888891849,
                43.910787975021
              ],
              [
                6.8307045795788,
                43.918366781633
              ],
              [
                6.8425804223119,
                43.915860800081
              ],
              [
                6.8766137882973,
                43.898595600573
              ],
              [
                6.8846101855508,
                43.889120756916
              ],
              [
                6.9072439984892,
                43.893267267097
              ],
              [
                6.9204750421499,
                43.893501745813
              ],
              [
                6.9417509022289,
                43.896969207908
              ],
              [
                6.9413897545047,
                43.904151092346
              ],
              [
                6.9262561668761,
                43.913794624111
              ],
              [
                6.9130909664835,
                43.92796078166
              ],
              [
                6.8951249345413,
                43.927824787229
              ],
              [
                6.8810110369509,
                43.93812271054
              ],
              [
                6.8710752593745,
                43.953876988543
              ],
              [
                6.8518928028242,
                43.954789473614
              ],
              [
                6.8420892292777,
                43.978955578863
              ],
              [
                6.8347064061942,
                43.98328134261
              ],
              [
                6.8360089492776,
                43.99059808047
              ],
              [
                6.797979688934,
                44.005325542478
              ],
              [
                6.7892786360536,
                44.022566063867
              ],
              [
                6.7725735140535,
                44.025507983052
              ],
              [
                6.7457111620123,
                44.043230952418
              ],
              [
                6.7490919968038,
                44.063171230737
              ],
              [
                6.7552862363418,
                44.078601877976
              ],
              [
                6.7366003752038,
                44.102257679953
              ],
              [
                6.7256652646801,
                44.109983735237
              ],
              [
                6.7185962917297,
                44.11939935968
              ],
              [
                6.7070689268616,
                44.125841526264
              ],
              [
                6.705493472047,
                44.144463669693
              ],
              [
                6.6865415403433,
                44.169255561043
              ],
              [
                6.7012407441864,
                44.17766549216
              ],
              [
                6.6988119126829,
                44.188028656227
              ],
              [
                6.706825122292,
                44.201490519651
              ],
              [
                6.7183432031881,
                44.21071438795
              ],
              [
                6.7140803603433,
                44.218225221217
              ],
              [
                6.7250111259566,
                44.226278142994
              ],
              [
                6.7239888814711,
                44.249843474444
              ],
              [
                6.7513229949306,
                44.260535556946
              ],
              [
                6.7584360313867,
                44.265106172074
              ],
              [
                6.7583202569976,
                44.274351334174
              ],
              [
                6.7629245576522,
                44.279037327194
              ],
              [
                6.7903396839453,
                44.272339782321
              ],
              [
                6.7863492911521,
                44.288497379247
              ],
              [
                6.7933368571689,
                44.296720083266
              ],
              [
                6.7960920038012,
                44.316102067874
              ],
              [
                6.8036231667248,
                44.320270996667
              ],
              [
                6.8146208258179,
                44.332052374921
              ],
              [
                6.8223981071578,
                44.33265277476
              ],
              [
                6.8396913528484,
                44.346173034543
              ],
              [
                6.8623162326859,
                44.346410702484
              ],
              [
                6.8874351607458,
                44.361051257141
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "6",
        "nom": "Alpes-Maritimes",
        "population": 1097410,
        "delitspour1000": "6,282",
        "nombremosque": 20,
        "benefRsa": 36979
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1.6884233932357,
              43.273554672371
            ],
            [
              1.7059158532707,
              43.28535876201
            ],
            [
              1.7145108961303,
              43.294682139948
            ],
            [
              1.7041377368756,
              43.302727433716
            ],
            [
              1.7264517578662,
              43.313558549895
            ],
            [
              1.7358104191158,
              43.314195591755
            ],
            [
              1.7340683428468,
              43.323055197932
            ],
            [
              1.7238177566678,
              43.329745464099
            ],
            [
              1.7477265133075,
              43.343736431882
            ],
            [
              1.7589974639475,
              43.345684622995
            ],
            [
              1.7718387739464,
              43.33715262084
            ],
            [
              1.7747537463174,
              43.34243454001
            ],
            [
              1.7861676084759,
              43.344942024922
            ],
            [
              1.7969705125778,
              43.340562392875
            ],
            [
              1.8134239904389,
              43.341407194984
            ],
            [
              1.8166266244975,
              43.349384816053
            ],
            [
              1.8042786321114,
              43.358580811058
            ],
            [
              1.8062699140989,
              43.380008857731
            ],
            [
              1.8040809598206,
              43.391685663532
            ],
            [
              1.8098202342584,
              43.394499387758
            ],
            [
              1.8226596085892,
              43.393339649196
            ],
            [
              1.8171490943613,
              43.409875231664
            ],
            [
              1.8260977029306,
              43.418114375667
            ],
            [
              1.8432501603618,
              43.419263434784
            ],
            [
              1.8474991105402,
              43.426704637628
            ],
            [
              1.8429498236949,
              43.432545015031
            ],
            [
              1.8530463693539,
              43.43726360404
            ],
            [
              1.8587392319179,
              43.443822651373
            ],
            [
              1.8718099152709,
              43.438110220087
            ],
            [
              1.8833159335668,
              43.424394923053
            ],
            [
              1.8949983598871,
              43.418625938245
            ],
            [
              1.9030812461654,
              43.406484362121
            ],
            [
              1.8960817399884,
              43.396442502675
            ],
            [
              1.9086159183678,
              43.396268158977
            ],
            [
              1.9100983791881,
              43.404056676343
            ],
            [
              1.9208426857426,
              43.413148027424
            ],
            [
              1.9190398691635,
              43.419373275391
            ],
            [
              1.9295264375351,
              43.422772232704
            ],
            [
              1.949447182419,
              43.419590573873
            ],
            [
              1.9563869984965,
              43.425420417265
            ],
            [
              1.9681332504295,
              43.417407513183
            ],
            [
              1.9751843574489,
              43.418256313419
            ],
            [
              1.9882945357382,
              43.407609041004
            ],
            [
              1.995617116255,
              43.411730410053
            ],
            [
              2.0113607846882,
              43.414767844117
            ],
            [
              2.0207368926519,
              43.423193392374
            ],
            [
              2.0312013013995,
              43.419350322258
            ],
            [
              2.0392629517166,
              43.424279689382
            ],
            [
              2.0291341188294,
              43.436895277153
            ],
            [
              2.0531291058482,
              43.430203153623
            ],
            [
              2.066365605718,
              43.411113686999
            ],
            [
              2.0727969810099,
              43.395690514022
            ],
            [
              2.086671283238,
              43.398779217594
            ],
            [
              2.110382625502,
              43.39595635372
            ],
            [
              2.1335608464159,
              43.40356043581
            ],
            [
              2.1407815147228,
              43.404247749359
            ],
            [
              2.1594556186559,
              43.415531590815
            ],
            [
              2.171548492892,
              43.415775006477
            ],
            [
              2.1877018669943,
              43.393900936651
            ],
            [
              2.2028556152946,
              43.386693811362
            ],
            [
              2.215023822395,
              43.383508824887
            ],
            [
              2.2252355421611,
              43.39729608099
            ],
            [
              2.2297838407245,
              43.410169660932
            ],
            [
              2.2229731201607,
              43.419302330127
            ],
            [
              2.2241702265958,
              43.431765391026
            ],
            [
              2.2404078352143,
              43.440294444075
            ],
            [
              2.250998241094,
              43.449381408091
            ],
            [
              2.2591017375848,
              43.459927734352
            ],
            [
              2.2820110776793,
              43.441222270986
            ],
            [
              2.3029806665229,
              43.447935084107
            ],
            [
              2.3113702716933,
              43.441548673638
            ],
            [
              2.3312654102165,
              43.437946745339
            ],
            [
              2.3510335480095,
              43.431916671343
            ],
            [
              2.3672075625877,
              43.423222447693
            ],
            [
              2.3771444963265,
              43.423399693102
            ],
            [
              2.3913458849515,
              43.418930753382
            ],
            [
              2.4036354484854,
              43.418668029268
            ],
            [
              2.4069710225399,
              43.424831119141
            ],
            [
              2.4331067416938,
              43.435174281522
            ],
            [
              2.4583054839336,
              43.433107071969
            ],
            [
              2.473998212403,
              43.436557283321
            ],
            [
              2.4968512219793,
              43.436930315048
            ],
            [
              2.4973981397447,
              43.433963920582
            ],
            [
              2.5192290328736,
              43.423576425204
            ],
            [
              2.5515253933938,
              43.424964573472
            ],
            [
              2.5657824745312,
              43.422957925066
            ],
            [
              2.5792769693109,
              43.415722124273
            ],
            [
              2.593464677472,
              43.41258881974
            ],
            [
              2.5913265352699,
              43.402780865486
            ],
            [
              2.5860594271674,
              43.398964348129
            ],
            [
              2.5692167745323,
              43.400304806184
            ],
            [
              2.5552203547235,
              43.388318929825
            ],
            [
              2.5522942769636,
              43.363891729209
            ],
            [
              2.5399656073586,
              43.346221878067
            ],
            [
              2.5551007597984,
              43.337377239875
            ],
            [
              2.5808065052268,
              43.334662344403
            ],
            [
              2.5890408539009,
              43.328703501465
            ],
            [
              2.5922057460473,
              43.317850178713
            ],
            [
              2.5973560369523,
              43.313749359729
            ],
            [
              2.6016055484715,
              43.298706095944
            ],
            [
              2.6164099655732,
              43.289440064412
            ],
            [
              2.6273790095425,
              43.288782167534
            ],
            [
              2.6317588557561,
              43.29732511202
            ],
            [
              2.6590635926054,
              43.292968989423
            ],
            [
              2.6766363183171,
              43.305455086206
            ],
            [
              2.6788510727126,
              43.314743561061
            ],
            [
              2.6935858291614,
              43.320491486093
            ],
            [
              2.6995425883767,
              43.312542380597
            ],
            [
              2.7002208941951,
              43.302210812267
            ],
            [
              2.7045208788416,
              43.299311631051
            ],
            [
              2.7002183864224,
              43.282315974529
            ],
            [
              2.7073810722808,
              43.273521745096
            ],
            [
              2.7183505585497,
              43.275460644919
            ],
            [
              2.7378850343813,
              43.267338267596
            ],
            [
              2.7525229453227,
              43.254595672077
            ],
            [
              2.7722469913722,
              43.257786542768
            ],
            [
              2.7869260612143,
              43.273513557976
            ],
            [
              2.7866092768635,
              43.285602954977
            ],
            [
              2.7917850058686,
              43.293011945881
            ],
            [
              2.8182231073238,
              43.309421905527
            ],
            [
              2.8061929608751,
              43.315954436466
            ],
            [
              2.8127141223172,
              43.323485536792
            ],
            [
              2.8327571187304,
              43.32241279802
            ],
            [
              2.8683187275794,
              43.329585760434
            ],
            [
              2.873162273491,
              43.339374603901
            ],
            [
              2.8695713933014,
              43.355981456072
            ],
            [
              2.8567239052152,
              43.370318505817
            ],
            [
              2.856190460919,
              43.374063805275
            ],
            [
              2.8678099171678,
              43.382193201942
            ],
            [
              2.8788663038272,
              43.371031957894
            ],
            [
              2.8898465644215,
              43.363912452239
            ],
            [
              2.8879060996736,
              43.354244172963
            ],
            [
              2.8918220878429,
              43.344097060191
            ],
            [
              2.8847983721595,
              43.334409274335
            ],
            [
              2.8866989626319,
              43.329898940957
            ],
            [
              2.9072731105699,
              43.321649551962
            ],
            [
              2.9212516184831,
              43.326085683565
            ],
            [
              2.9450352296993,
              43.312502335561
            ],
            [
              2.9592121788394,
              43.312556289807
            ],
            [
              2.9808455873158,
              43.317576526586
            ],
            [
              3.0043529644297,
              43.320131141473
            ],
            [
              3.0062863930421,
              43.30013353204
            ],
            [
              3.0047360120811,
              43.288533755393
            ],
            [
              3.0158720189579,
              43.276359929439
            ],
            [
              3.0273416696418,
              43.282047301001
            ],
            [
              3.0548510452049,
              43.279719592179
            ],
            [
              3.079270269083,
              43.262049045594
            ],
            [
              3.0922472050834,
              43.26057519433
            ],
            [
              3.1137077582144,
              43.253719157821
            ],
            [
              3.1234732643417,
              43.261259155076
            ],
            [
              3.1416212770887,
              43.25950707604
            ],
            [
              3.1472054984849,
              43.247002373241
            ],
            [
              3.1602360154965,
              43.243174322456
            ],
            [
              3.1761517931696,
              43.244766891665
            ],
            [
              3.1872588808739,
              43.251069218863
            ],
            [
              3.2010228712178,
              43.249170753058
            ],
            [
              3.2103074775286,
              43.235634956703
            ],
            [
              3.2270145112782,
              43.220856210927
            ],
            [
              3.2405623482295,
              43.212804132866
            ],
            [
              3.2178713301191,
              43.197502303167
            ],
            [
              3.1901982904968,
              43.175846370089
            ],
            [
              3.1683477733427,
              43.157328349219
            ],
            [
              3.140800124971,
              43.128768756296
            ],
            [
              3.1309240074639,
              43.111758439868
            ],
            [
              3.1172266379458,
              43.101836432273
            ],
            [
              3.0928818865124,
              43.06914587835
            ],
            [
              3.0838489259408,
              43.055302627989
            ],
            [
              3.0655869638129,
              43.021675065612
            ],
            [
              3.0615075416401,
              43.008829254021
            ],
            [
              3.0525103814528,
              42.990159118293
            ],
            [
              3.0428444157674,
              42.960156485478
            ],
            [
              3.0393165218276,
              42.941683366716
            ],
            [
              3.0404673397314,
              42.929700731159
            ],
            [
              3.0525504097283,
              42.926207159025
            ],
            [
              3.0605893002724,
              42.91706260281
            ],
            [
              3.0521306763747,
              42.885348067732
            ],
            [
              3.0435046945933,
              42.838148318333
            ],
            [
              2.9762275854388,
              42.87028398251
            ],
            [
              2.9476099099498,
              42.876045186727
            ],
            [
              2.9171137651734,
              42.884862018338
            ],
            [
              2.9068755886101,
              42.895812902548
            ],
            [
              2.889328681692,
              42.898015640078
            ],
            [
              2.8804294829511,
              42.901429612195
            ],
            [
              2.8652738845272,
              42.918339638726
            ],
            [
              2.8347290901868,
              42.912261020459
            ],
            [
              2.8163810040447,
              42.899966028514
            ],
            [
              2.7890562621586,
              42.895989632901
            ],
            [
              2.7621491189859,
              42.873679462463
            ],
            [
              2.7544077500563,
              42.851928276802
            ],
            [
              2.7400015255921,
              42.837576852437
            ],
            [
              2.7247452179486,
              42.833985979518
            ],
            [
              2.7149718442234,
              42.834290473477
            ],
            [
              2.697582514329,
              42.830434676604
            ],
            [
              2.6804625126818,
              42.83067010274
            ],
            [
              2.6651284701275,
              42.836640022294
            ],
            [
              2.638409069378,
              42.834083133254
            ],
            [
              2.6280426854588,
              42.836666235812
            ],
            [
              2.5979894049578,
              42.837100971919
            ],
            [
              2.5515113818666,
              42.846688734857
            ],
            [
              2.5165749669116,
              42.842410428152
            ],
            [
              2.5090056459735,
              42.849828064937
            ],
            [
              2.4524735304233,
              42.836933656549
            ],
            [
              2.414621420566,
              42.84101870126
            ],
            [
              2.382161365403,
              42.848465034543
            ],
            [
              2.3375799741132,
              42.840587818223
            ],
            [
              2.3271099265103,
              42.837443636727
            ],
            [
              2.3190761465472,
              42.823973534101
            ],
            [
              2.3321282759785,
              42.820432572345
            ],
            [
              2.3335863589133,
              42.804382336902
            ],
            [
              2.343924099398,
              42.801221485171
            ],
            [
              2.3441662158501,
              42.779756163988
            ],
            [
              2.3493581082134,
              42.769579245663
            ],
            [
              2.3521078632768,
              42.750727460273
            ],
            [
              2.3575375286794,
              42.734121070446
            ],
            [
              2.3553902933138,
              42.727008424178
            ],
            [
              2.336473961177,
              42.718505606411
            ],
            [
              2.3220073604176,
              42.707789785798
            ],
            [
              2.2886350177001,
              42.700675940528
            ],
            [
              2.2645326504794,
              42.706239710442
            ],
            [
              2.2552050371231,
              42.697458037454
            ],
            [
              2.2465143491066,
              42.681205628031
            ],
            [
              2.2390119385206,
              42.676205557799
            ],
            [
              2.2246921293803,
              42.674162457773
            ],
            [
              2.1913009680676,
              42.658154649346
            ],
            [
              2.1921070857545,
              42.651761775768
            ],
            [
              2.1762144887597,
              42.64890098195
            ],
            [
              2.1660488223224,
              42.663917432789
            ],
            [
              2.1674816196022,
              42.673376591782
            ],
            [
              2.1758766074869,
              42.682791646952
            ],
            [
              2.1678528137192,
              42.696187020903
            ],
            [
              2.1606300279024,
              42.701123613621
            ],
            [
              2.1406094709408,
              42.70582111524
            ],
            [
              2.1395519109111,
              42.71229378372
            ],
            [
              2.1270176793049,
              42.716509230656
            ],
            [
              2.0873246405121,
              42.739333179139
            ],
            [
              2.0862958088794,
              42.751796313706
            ],
            [
              2.0572091234374,
              42.756222904226
            ],
            [
              2.0423514687936,
              42.746734851366
            ],
            [
              2.0323055427977,
              42.746594223398
            ],
            [
              2.0254188897268,
              42.738989255526
            ],
            [
              2.0132744900391,
              42.738369124152
            ],
            [
              2.0079257545377,
              42.73412313884
            ],
            [
              1.9554004775238,
              42.736704442421
            ],
            [
              1.9424553009298,
              42.746581205068
            ],
            [
              1.9182615505133,
              42.757409155918
            ],
            [
              1.9081785403601,
              42.767670627363
            ],
            [
              1.9288394992032,
              42.774948091707
            ],
            [
              1.9169252616661,
              42.78350434537
            ],
            [
              1.8960901541122,
              42.808667398867
            ],
            [
              1.8692014277833,
              42.815082423983
            ],
            [
              1.8593817847995,
              42.820804962781
            ],
            [
              1.8602403431469,
              42.827608292584
            ],
            [
              1.8729010044834,
              42.83589155119
            ],
            [
              1.8750171229238,
              42.84606877232
            ],
            [
              1.8804691523907,
              42.852666520642
            ],
            [
              1.9456608008971,
              42.856195612969
            ],
            [
              1.9869222151088,
              42.871007203276
            ],
            [
              1.9810016845404,
              42.87841643035
            ],
            [
              1.9910671704996,
              42.904641621976
            ],
            [
              1.9875408762769,
              42.917157059247
            ],
            [
              1.9805767739427,
              42.929578816015
            ],
            [
              1.9338478606359,
              42.939320639064
            ],
            [
              1.936037298041,
              42.951628828023
            ],
            [
              1.9622253344282,
              42.961140470724
            ],
            [
              1.9802189219425,
              42.959688363682
            ],
            [
              1.9885860585978,
              42.953374578941
            ],
            [
              1.9991183826026,
              42.955003012434
            ],
            [
              2.0036244299195,
              42.960482549519
            ],
            [
              1.9853242058519,
              42.966955184292
            ],
            [
              1.9851357344142,
              43.004594554874
            ],
            [
              1.9695836475611,
              43.007942793079
            ],
            [
              1.9850967867381,
              43.026271388398
            ],
            [
              1.9849967014251,
              43.032613312256
            ],
            [
              1.9720976511514,
              43.046611393848
            ],
            [
              1.9586368389642,
              43.050369497515
            ],
            [
              1.9508584341989,
              43.046590894868
            ],
            [
              1.9411266925152,
              43.052718110265
            ],
            [
              1.9403303636833,
              43.061716671606
            ],
            [
              1.9452104401249,
              43.066549790409
            ],
            [
              1.9638549172992,
              43.06917736292
            ],
            [
              1.9497271826879,
              43.080024986538
            ],
            [
              1.9557697173495,
              43.085445578423
            ],
            [
              1.9506935999843,
              43.102254614249
            ],
            [
              1.9494734751292,
              43.11949081683
            ],
            [
              1.9177421166021,
              43.133234660517
            ],
            [
              1.9023329243618,
              43.130253440677
            ],
            [
              1.9018860600783,
              43.124357874809
            ],
            [
              1.8809180435515,
              43.126794944692
            ],
            [
              1.8804018658747,
              43.14153210392
            ],
            [
              1.8561936135101,
              43.145320584408
            ],
            [
              1.8520774929174,
              43.160513521664
            ],
            [
              1.8436090403584,
              43.157599334299
            ],
            [
              1.8482001028058,
              43.145922753518
            ],
            [
              1.8247771300982,
              43.145813043369
            ],
            [
              1.8069320952074,
              43.155501460795
            ],
            [
              1.7778084523523,
              43.160753591375
            ],
            [
              1.7478461517093,
              43.175363704104
            ],
            [
              1.7435831558346,
              43.182725893946
            ],
            [
              1.7354358287353,
              43.185789204059
            ],
            [
              1.7100614045293,
              43.189696294172
            ],
            [
              1.7099424228066,
              43.200585571526
            ],
            [
              1.7255156257731,
              43.211942040675
            ],
            [
              1.7208867336366,
              43.218745125508
            ],
            [
              1.7238477981704,
              43.224522495613
            ],
            [
              1.6997831731081,
              43.243827772182
            ],
            [
              1.7007546159588,
              43.260536148347
            ],
            [
              1.6884233932357,
              43.273554672371
            ]
          ]
        ]
      },
      "properties": {
        "code": "11",
        "nom": "Aude",
        "population": 375217,
        "delitspour1000": "4,667",
        "nombremosque": 27,
        "parentsEtrangers": "76,8",
        "benefRsa": 30795,
        "eoliennes": 298
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                9.2710321280228,
                41.364959299521
              ],
              [
                9.265698545799,
                41.362164776515
              ],
              [
                9.2558829669799,
                41.368794222327
              ],
              [
                9.2602446048541,
                41.373651229899
              ],
              [
                9.273659440522,
                41.369221791575
              ],
              [
                9.2710321280228,
                41.364959299521
              ]
            ]
          ],
          [
            [
              [
                8.5734085639674,
                42.381404942274
              ],
              [
                8.5869206603959,
                42.378959456159
              ],
              [
                8.6000271534091,
                42.369259196001
              ],
              [
                8.6100160917347,
                42.373593854505
              ],
              [
                8.620173521868,
                42.372270719131
              ],
              [
                8.6408019259839,
                42.37343904341
              ],
              [
                8.6504088344609,
                42.362352538519
              ],
              [
                8.6596703887356,
                42.359406967225
              ],
              [
                8.6819749384239,
                42.358462008729
              ],
              [
                8.6841460127953,
                42.347988396554
              ],
              [
                8.7110680867563,
                42.347099989534
              ],
              [
                8.7316720302666,
                42.339621254644
              ],
              [
                8.7596398204464,
                42.336385741044
              ],
              [
                8.7708826228712,
                42.324766882975
              ],
              [
                8.7900363923385,
                42.325968475102
              ],
              [
                8.8195614662278,
                42.317558938226
              ],
              [
                8.829317653756,
                42.319329187492
              ],
              [
                8.8571680345851,
                42.33098205652
              ],
              [
                8.861323814011,
                42.329932688851
              ],
              [
                8.8603278562356,
                42.305860595132
              ],
              [
                8.8627230388832,
                42.296940535735
              ],
              [
                8.8752125707216,
                42.294285417725
              ],
              [
                8.8834502030213,
                42.285533375017
              ],
              [
                8.8819527698561,
                42.271781751932
              ],
              [
                8.9047709696211,
                42.25386266404
              ],
              [
                8.9198606665679,
                42.256381499474
              ],
              [
                8.9330949432296,
                42.250429454883
              ],
              [
                8.9401229335511,
                42.23734315946
              ],
              [
                8.9449421977545,
                42.234627193685
              ],
              [
                8.9730394489482,
                42.232594210154
              ],
              [
                8.9914659319717,
                42.216578520682
              ],
              [
                9.005949397125,
                42.21225943163
              ],
              [
                9.006503816899,
                42.208845591917
              ],
              [
                9.0298228102289,
                42.204774632345
              ],
              [
                9.0456639496781,
                42.206921364452
              ],
              [
                9.0489799972866,
                42.192999281218
              ],
              [
                9.0481742273965,
                42.17452867595
              ],
              [
                9.0506091521078,
                42.170005011499
              ],
              [
                9.0652668165844,
                42.164493750913
              ],
              [
                9.0752407064093,
                42.139300614962
              ],
              [
                9.0721824483641,
                42.13021757503
              ],
              [
                9.0818499166738,
                42.125905365983
              ],
              [
                9.0933287260065,
                42.115198176966
              ],
              [
                9.1037249128773,
                42.116485479522
              ],
              [
                9.1197350918655,
                42.107770500545
              ],
              [
                9.1348228365837,
                42.086998025371
              ],
              [
                9.1286038053593,
                42.067320426142
              ],
              [
                9.1353143401725,
                42.051069844879
              ],
              [
                9.1481436742474,
                42.041006906645
              ],
              [
                9.1608223716694,
                42.026020516263
              ],
              [
                9.1883567893578,
                42.031080114757
              ],
              [
                9.1946507804892,
                42.02850787618
              ],
              [
                9.2210892092947,
                42.027600260448
              ],
              [
                9.2188803747666,
                42.007817940275
              ],
              [
                9.2152310128563,
                41.99362645942
              ],
              [
                9.2202414266448,
                41.989622976178
              ],
              [
                9.2247577651393,
                41.956511288496
              ],
              [
                9.2205772118501,
                41.945796570498
              ],
              [
                9.2154537945669,
                41.942034092005
              ],
              [
                9.2210986595967,
                41.935557312364
              ],
              [
                9.2304884424099,
                41.932319666581
              ],
              [
                9.2132785455984,
                41.917460528629
              ],
              [
                9.2183638931095,
                41.91363293029
              ],
              [
                9.2295780895386,
                41.916346158378
              ],
              [
                9.2488032375803,
                41.908857737427
              ],
              [
                9.2522076488967,
                41.898987406971
              ],
              [
                9.2349687010048,
                41.879342163418
              ],
              [
                9.2361894223697,
                41.875065331657
              ],
              [
                9.2274996918602,
                41.855587995332
              ],
              [
                9.2369134454732,
                41.846529531172
              ],
              [
                9.2596591266328,
                41.845236776196
              ],
              [
                9.2706975634074,
                41.840589457094
              ],
              [
                9.2879010801347,
                41.843877976958
              ],
              [
                9.3024960573859,
                41.844246040822
              ],
              [
                9.3090914543504,
                41.832143660252
              ],
              [
                9.3292070564302,
                41.836816297267
              ],
              [
                9.3374450741333,
                41.845088647014
              ],
              [
                9.3448285774911,
                41.845259839386
              ],
              [
                9.3556758229759,
                41.855327750137
              ],
              [
                9.3719040548729,
                41.865004853102
              ],
              [
                9.3866823823286,
                41.867188199968
              ],
              [
                9.4022711090103,
                41.858701678165
              ],
              [
                9.4020526477352,
                41.845844224512
              ],
              [
                9.4062743560399,
                41.822363784172
              ],
              [
                9.3941343569793,
                41.79894215528
              ],
              [
                9.3991901775403,
                41.790143164158
              ],
              [
                9.3981451175547,
                41.778459013889
              ],
              [
                9.4073217319481,
                41.767634723217
              ],
              [
                9.4052716742874,
                41.754575654188
              ],
              [
                9.4062185485145,
                41.73920137245
              ],
              [
                9.4013418072307,
                41.716959615945
              ],
              [
                9.4035331655531,
                41.710840198665
              ],
              [
                9.3998192801504,
                41.69279867
              ],
              [
                9.3810879581743,
                41.688001244821
              ],
              [
                9.3723006750494,
                41.678830164331
              ],
              [
                9.3757375815574,
                41.670619677977
              ],
              [
                9.3874913966427,
                41.657359062003
              ],
              [
                9.3850563977806,
                41.652970458201
              ],
              [
                9.3644131875031,
                41.636179025769
              ],
              [
                9.3547387700685,
                41.640710164794
              ],
              [
                9.3476012746477,
                41.636632418311
              ],
              [
                9.3463932296058,
                41.63002302864
              ],
              [
                9.3538271659327,
                41.626632801378
              ],
              [
                9.3463550313306,
                41.617508503613
              ],
              [
                9.3367416523086,
                41.621538780451
              ],
              [
                9.3272049926403,
                41.616356949796
              ],
              [
                9.3082365328383,
                41.628487552777
              ],
              [
                9.3060190567968,
                41.617470193836
              ],
              [
                9.2970016566957,
                41.610930362027
              ],
              [
                9.2883407858234,
                41.609067528624
              ],
              [
                9.2825337896991,
                41.596415718545
              ],
              [
                9.286354138341,
                41.589453101103
              ],
              [
                9.2956793037693,
                41.583322361133
              ],
              [
                9.3083814880745,
                41.593972713908
              ],
              [
                9.3130806771213,
                41.601132392709
              ],
              [
                9.3215169876122,
                41.604837299741
              ],
              [
                9.3350988345063,
                41.602027547324
              ],
              [
                9.3470681679656,
                41.594390886207
              ],
              [
                9.3685744931696,
                41.596897302353
              ],
              [
                9.3533825795291,
                41.577938443543
              ],
              [
                9.3481742396925,
                41.575388818689
              ],
              [
                9.3501715740997,
                41.566187005133
              ],
              [
                9.3408997155844,
                41.559241730325
              ],
              [
                9.3239922254982,
                41.557236366366
              ],
              [
                9.3023216696934,
                41.545198419563
              ],
              [
                9.2875574726946,
                41.530228755368
              ],
              [
                9.2730739945179,
                41.53019271867
              ],
              [
                9.273680106865,
                41.522517947178
              ],
              [
                9.2839183612598,
                41.520450085063
              ],
              [
                9.277308421099,
                41.506124986587
              ],
              [
                9.2688973149711,
                41.501624751876
              ],
              [
                9.2759308530555,
                41.49643713783
              ],
              [
                9.2876523998677,
                41.494831125938
              ],
              [
                9.28400186333,
                41.477572296911
              ],
              [
                9.2772722837641,
                41.475358221151
              ],
              [
                9.2777164179139,
                41.46495547492
              ],
              [
                9.2658821075424,
                41.465995458816
              ],
              [
                9.2429445240833,
                41.446705574174
              ],
              [
                9.223954267893,
                41.442488841516
              ],
              [
                9.2232723456115,
                41.436025985419
              ],
              [
                9.2288969116275,
                41.425302049177
              ],
              [
                9.2281191406951,
                41.409110517705
              ],
              [
                9.2442363338799,
                41.421181163353
              ],
              [
                9.2651023772974,
                41.428283396377
              ],
              [
                9.2591670709159,
                41.413375395828
              ],
              [
                9.2507254948685,
                41.40960553083
              ],
              [
                9.2361643789523,
                41.393559251695
              ],
              [
                9.2331233905073,
                41.386560178849
              ],
              [
                9.2214883823877,
                41.376328969738
              ],
              [
                9.2196789477427,
                41.368211945774
              ],
              [
                9.1988058887608,
                41.36588583439
              ],
              [
                9.1803693126024,
                41.366438293705
              ],
              [
                9.1743707764701,
                41.379329284074
              ],
              [
                9.1672444080415,
                41.385592973955
              ],
              [
                9.1446146072237,
                41.387183678065
              ],
              [
                9.1208631045283,
                41.39644315535
              ],
              [
                9.0938398987455,
                41.393839319394
              ],
              [
                9.0911719516959,
                41.401806079279
              ],
              [
                9.0955320043022,
                41.412431710561
              ],
              [
                9.1089222562469,
                41.419940163471
              ],
              [
                9.1043469551733,
                41.425502848125
              ],
              [
                9.120687595476,
                41.437616309687
              ],
              [
                9.1192193922242,
                41.440772011484
              ],
              [
                9.0994499566209,
                41.442768149338
              ],
              [
                9.0935832833779,
                41.449719424027
              ],
              [
                9.0822010138671,
                41.441973884937
              ],
              [
                9.0743134372406,
                41.442920620722
              ],
              [
                9.0664301578555,
                41.451023602972
              ],
              [
                9.0704320220003,
                41.466940562623
              ],
              [
                9.070286926691,
                41.475671427311
              ],
              [
                9.0508357375037,
                41.459641860478
              ],
              [
                9.0382611446596,
                41.459645198212
              ],
              [
                9.0180268691937,
                41.467748490519
              ],
              [
                9.0128843778786,
                41.476121872495
              ],
              [
                9.0021701866201,
                41.475774397275
              ],
              [
                8.9928461630234,
                41.485396435585
              ],
              [
                8.9808637276524,
                41.479330164884
              ],
              [
                8.9594585113313,
                41.492130159311
              ],
              [
                8.938402541315,
                41.48966848731
              ],
              [
                8.9317877201954,
                41.495378064347
              ],
              [
                8.9210126230225,
                41.497499034287
              ],
              [
                8.9147467175153,
                41.507208151354
              ],
              [
                8.9001835704243,
                41.508774219762
              ],
              [
                8.8846150462285,
                41.50507236925
              ],
              [
                8.8820378648188,
                41.509239075837
              ],
              [
                8.8882614285546,
                41.51707992067
              ],
              [
                8.8778100883194,
                41.523794452944
              ],
              [
                8.865369828875,
                41.520128455719
              ],
              [
                8.8450740207766,
                41.517958079633
              ],
              [
                8.8414047053005,
                41.523766509681
              ],
              [
                8.8519237231626,
                41.533233974908
              ],
              [
                8.8511877593354,
                41.540860979943
              ],
              [
                8.8416810361388,
                41.546552827363
              ],
              [
                8.8229182141785,
                41.546156093531
              ],
              [
                8.814890471805,
                41.553906217363
              ],
              [
                8.7885345993036,
                41.557735921446
              ],
              [
                8.7854932407521,
                41.563658878459
              ],
              [
                8.8019653365352,
                41.5725251973
              ],
              [
                8.7827891492503,
                41.585681206952
              ],
              [
                8.7771927389114,
                41.592763979819
              ],
              [
                8.7839015050151,
                41.59639969226
              ],
              [
                8.7909467331176,
                41.608501287066
              ],
              [
                8.7930767849103,
                41.629554383445
              ],
              [
                8.8024752261462,
                41.633221115876
              ],
              [
                8.8217796251471,
                41.630090136165
              ],
              [
                8.8265171763194,
                41.635951775657
              ],
              [
                8.8540761012096,
                41.647017321432
              ],
              [
                8.8695009599086,
                41.646091120096
              ],
              [
                8.877264032596,
                41.652454617448
              ],
              [
                8.8809129542854,
                41.668095186004
              ],
              [
                8.8928931495104,
                41.674100351332
              ],
              [
                8.9080176286522,
                41.677007881852
              ],
              [
                8.9153277451781,
                41.681282755136
              ],
              [
                8.9145075925891,
                41.689723682063
              ],
              [
                8.8856557174325,
                41.690849961129
              ],
              [
                8.8782882421796,
                41.695973169993
              ],
              [
                8.8412808961089,
                41.697551641036
              ],
              [
                8.8302939411387,
                41.706262922095
              ],
              [
                8.8127122072851,
                41.714129905775
              ],
              [
                8.7937675276411,
                41.707893097781
              ],
              [
                8.7729726652092,
                41.711919063255
              ],
              [
                8.7845453570537,
                41.731664815454
              ],
              [
                8.7800237021371,
                41.739414158852
              ],
              [
                8.7724546247573,
                41.741517274673
              ],
              [
                8.7600821507981,
                41.738380383757
              ],
              [
                8.7366474883573,
                41.729424936258
              ],
              [
                8.7254848567332,
                41.727545668736
              ],
              [
                8.7172417233064,
                41.722774526354
              ],
              [
                8.7030325607564,
                41.726005824599
              ],
              [
                8.7076082107898,
                41.73618560032
              ],
              [
                8.6976380199228,
                41.741319354452
              ],
              [
                8.6820097937715,
                41.739918393262
              ],
              [
                8.6717700658097,
                41.743688167428
              ],
              [
                8.6585643232553,
                41.741804670222
              ],
              [
                8.6597494147728,
                41.749463503232
              ],
              [
                8.6682797961785,
                41.752894642014
              ],
              [
                8.6845169668936,
                41.747002332036
              ],
              [
                8.7044093153393,
                41.757890543543
              ],
              [
                8.7131431683495,
                41.760833582026
              ],
              [
                8.7300880504804,
                41.77591212379
              ],
              [
                8.7271678055689,
                41.78708867668
              ],
              [
                8.7195718291105,
                41.788893754962
              ],
              [
                8.7212499540158,
                41.797809382794
              ],
              [
                8.7409410916134,
                41.79868785997
              ],
              [
                8.7482572136005,
                41.810823729007
              ],
              [
                8.7575460129466,
                41.80895026474
              ],
              [
                8.7712959607968,
                41.811239419914
              ],
              [
                8.7747951360312,
                41.82504031127
              ],
              [
                8.7820652523774,
                41.833703364592
              ],
              [
                8.7708086871301,
                41.836461210042
              ],
              [
                8.767794604169,
                41.845931092466
              ],
              [
                8.7841001717388,
                41.848170286407
              ],
              [
                8.7906166495097,
                41.855520792589
              ],
              [
                8.7902752705309,
                41.866416605405
              ],
              [
                8.781211947867,
                41.880668839163
              ],
              [
                8.8031333721604,
                41.891381047926
              ],
              [
                8.795661885825,
                41.908865779323
              ],
              [
                8.7858171453796,
                41.920265738823
              ],
              [
                8.7726394721087,
                41.927375596769
              ],
              [
                8.7608963047658,
                41.924460972208
              ],
              [
                8.7549076702854,
                41.932565729457
              ],
              [
                8.745110539203,
                41.933164242485
              ],
              [
                8.7399479222249,
                41.928307210691
              ],
              [
                8.7410637125693,
                41.91754841615
              ],
              [
                8.7310370715364,
                41.915900103573
              ],
              [
                8.7214300448374,
                41.909256183947
              ],
              [
                8.7032845356386,
                41.907630033647
              ],
              [
                8.6868554085917,
                41.90859416785
              ],
              [
                8.6704582677272,
                41.905060573018
              ],
              [
                8.6643099502431,
                41.907384856241
              ],
              [
                8.6414501138477,
                41.909889243142
              ],
              [
                8.6149322619016,
                41.902346804089
              ],
              [
                8.610306446193,
                41.913050837853
              ],
              [
                8.6218879056671,
                41.927827389358
              ],
              [
                8.6207764177835,
                41.939541948075
              ],
              [
                8.6089998541718,
                41.940155623683
              ],
              [
                8.6056928687397,
                41.951055349782
              ],
              [
                8.5976997733316,
                41.953238328668
              ],
              [
                8.5958844509261,
                41.9660969095
              ],
              [
                8.6148401371518,
                41.971330910765
              ],
              [
                8.6390210889529,
                41.967471298128
              ],
              [
                8.6460663299246,
                41.968130756625
              ],
              [
                8.6688781676627,
                41.982429503353
              ],
              [
                8.6684592293729,
                41.991610798065
              ],
              [
                8.6575664515333,
                41.993436712754
              ],
              [
                8.6564246405607,
                42.009345707145
              ],
              [
                8.66801032159,
                42.017457160521
              ],
              [
                8.6903254906641,
                42.027593059646
              ],
              [
                8.702011655943,
                42.026639132045
              ],
              [
                8.7253458869114,
                42.034356688034
              ],
              [
                8.7226820538602,
                42.042540055616
              ],
              [
                8.741329138221,
                42.040912179268
              ],
              [
                8.7469869224827,
                42.0502108539
              ],
              [
                8.7391608385257,
                42.062698609291
              ],
              [
                8.7198555560589,
                42.06351093709
              ],
              [
                8.7203352966306,
                42.069442470516
              ],
              [
                8.7147668279995,
                42.08153861531
              ],
              [
                8.7047370379813,
                42.087940049587
              ],
              [
                8.7102579733595,
                42.095822368407
              ],
              [
                8.7002490365492,
                42.10604379347
              ],
              [
                8.6999858627077,
                42.112515614544
              ],
              [
                8.6891550495107,
                42.115105402635
              ],
              [
                8.680727946306,
                42.105996814848
              ],
              [
                8.6712597688232,
                42.107107181013
              ],
              [
                8.6605028767172,
                42.103588496488
              ],
              [
                8.6470086216948,
                42.121131891644
              ],
              [
                8.6374753377432,
                42.11976700426
              ],
              [
                8.6256340976664,
                42.122670550852
              ],
              [
                8.6099541503765,
                42.1345450022
              ],
              [
                8.5958860094781,
                42.13025896889
              ],
              [
                8.5825175645431,
                42.129323208282
              ],
              [
                8.5849834154329,
                42.135866535707
              ],
              [
                8.5939572487287,
                42.143185234803
              ],
              [
                8.5897456767979,
                42.149152267892
              ],
              [
                8.5774733357997,
                42.156554543452
              ],
              [
                8.5901738822205,
                42.163884812642
              ],
              [
                8.5884884100621,
                42.171421188299
              ],
              [
                8.5682188559922,
                42.169017173529
              ],
              [
                8.5654392505696,
                42.177332135912
              ],
              [
                8.582879148895,
                42.178547241363
              ],
              [
                8.578217141654,
                42.188551889364
              ],
              [
                8.5813855583817,
                42.205939854644
              ],
              [
                8.5696750552871,
                42.2080024677
              ],
              [
                8.5753209823783,
                42.216374212666
              ],
              [
                8.567135591097,
                42.219577195648
              ],
              [
                8.5735416635984,
                42.225839882474
              ],
              [
                8.5703414705341,
                42.230300843511
              ],
              [
                8.5481905843276,
                42.228905913957
              ],
              [
                8.5401025407511,
                42.234990759489
              ],
              [
                8.5492440260065,
                42.239976932275
              ],
              [
                8.5561122651665,
                42.236039038782
              ],
              [
                8.5731170667844,
                42.238745320634
              ],
              [
                8.578263306898,
                42.244253414861
              ],
              [
                8.5893237006741,
                42.24450377404
              ],
              [
                8.616717053661,
                42.255458016475
              ],
              [
                8.6267416458828,
                42.25175456083
              ],
              [
                8.6418418463671,
                42.256428059655
              ],
              [
                8.6655881496278,
                42.259320114998
              ],
              [
                8.6715712790019,
                42.262059482484
              ],
              [
                8.6891048520685,
                42.263527621966
              ],
              [
                8.6901226204434,
                42.278207035825
              ],
              [
                8.6759776798364,
                42.283615563315
              ],
              [
                8.67395545525,
                42.294337194236
              ],
              [
                8.660571343066,
                42.302118549571
              ],
              [
                8.6486515166656,
                42.302732559447
              ],
              [
                8.639933499141,
                42.299416084547
              ],
              [
                8.6255148911749,
                42.312261962884
              ],
              [
                8.6103565562315,
                42.308394659111
              ],
              [
                8.5974331518027,
                42.316054932892
              ],
              [
                8.6046294578781,
                42.322652105253
              ],
              [
                8.6215546415518,
                42.327510035659
              ],
              [
                8.6279955733531,
                42.332296015498
              ],
              [
                8.6264440528796,
                42.340241753638
              ],
              [
                8.6182826215658,
                42.340701534987
              ],
              [
                8.6002435360509,
                42.352999825029
              ],
              [
                8.5915444423808,
                42.352772156997
              ],
              [
                8.5815591608145,
                42.347468214352
              ],
              [
                8.5738501697388,
                42.338412964424
              ],
              [
                8.5648822039848,
                42.333214755035
              ],
              [
                8.5533077910528,
                42.333031073096
              ],
              [
                8.5588034162198,
                42.343784641612
              ],
              [
                8.5517937879479,
                42.347375798384
              ],
              [
                8.5558846856209,
                42.364749965739
              ],
              [
                8.5432048758181,
                42.367016637162
              ],
              [
                8.547948004985,
                42.379354824713
              ],
              [
                8.5575710562857,
                42.370756414133
              ],
              [
                8.5666906484566,
                42.370692862461
              ],
              [
                8.5783059458289,
                42.375541718692
              ],
              [
                8.5734085639674,
                42.381404942274
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "2A",
        "nom": "Corse-du-Sud",
        "population": 160814,
        "delitspour1000": "4,552",
        "nombremosque": 1,
        "benefRsa": 4217
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -3.0023212314614,
                48.836321026552
              ],
              [
                -3.0114954582397,
                48.841507416855
              ],
              [
                -3.0130894554754,
                48.856420115989
              ],
              [
                -2.9941385070247,
                48.864342720942
              ],
              [
                -2.9944706950371,
                48.847148998272
              ],
              [
                -3.0023212314614,
                48.836321026552
              ]
            ]
          ],
          [
            [
              [
                -3.5838774341471,
                48.794094648663
              ],
              [
                -3.5836489264715,
                48.804750470639
              ],
              [
                -3.5657740104496,
                48.807749291796
              ],
              [
                -3.5701295554214,
                48.797239145154
              ],
              [
                -3.5838774341471,
                48.794094648663
              ]
            ]
          ],
          [
            [
              [
                -2.123706231573,
                48.604405598781
              ],
              [
                -2.1233206778661,
                48.589010661057
              ],
              [
                -2.0953428420334,
                48.574061870811
              ],
              [
                -2.0759836927373,
                48.567677401376
              ],
              [
                -2.0584396027327,
                48.570382553093
              ],
              [
                -2.0448005646409,
                48.564199345405
              ],
              [
                -2.0528368137268,
                48.552178456034
              ],
              [
                -2.0462102621812,
                48.54401497855
              ],
              [
                -2.0068973832739,
                48.566110094782
              ],
              [
                -1.9937102250766,
                48.559543529591
              ],
              [
                -1.9881857185433,
                48.545116037063
              ],
              [
                -1.9754496222253,
                48.543657395889
              ],
              [
                -1.9723506867232,
                48.534638480013
              ],
              [
                -1.9870341945665,
                48.525657088599
              ],
              [
                -1.9857586016344,
                48.510758659842
              ],
              [
                -1.9694960306022,
                48.517140952252
              ],
              [
                -1.9693306755786,
                48.523178885752
              ],
              [
                -1.9547996276675,
                48.522834435277
              ],
              [
                -1.9480341971128,
                48.538808017557
              ],
              [
                -1.9318608413625,
                48.550784221308
              ],
              [
                -1.9248689155491,
                48.542337018042
              ],
              [
                -1.9124876200229,
                48.518844225268
              ],
              [
                -1.9129126542219,
                48.506261238999
              ],
              [
                -1.9229159215127,
                48.502821809282
              ],
              [
                -1.9210223490043,
                48.494067191737
              ],
              [
                -1.9089921410274,
                48.481553778436
              ],
              [
                -1.9268777278221,
                48.476866978678
              ],
              [
                -1.9300385128498,
                48.471049819847
              ],
              [
                -1.92389559645,
                48.460904149144
              ],
              [
                -1.9271965635311,
                48.454811633545
              ],
              [
                -1.9388950796074,
                48.447010086104
              ],
              [
                -1.9486321533248,
                48.446457052298
              ],
              [
                -1.9519128567945,
                48.429178882978
              ],
              [
                -1.9367865533207,
                48.420066760934
              ],
              [
                -1.9413160061344,
                48.40170624218
              ],
              [
                -1.9299746133222,
                48.394787269034
              ],
              [
                -1.9384706143467,
                48.391387318738
              ],
              [
                -1.9459073144679,
                48.377079358432
              ],
              [
                -1.9475264159554,
                48.36840917384
              ],
              [
                -1.9429726923482,
                48.360974979856
              ],
              [
                -1.9325891625895,
                48.356417844204
              ],
              [
                -1.9306142175588,
                48.350129614504
              ],
              [
                -1.947726745655,
                48.342145767609
              ],
              [
                -1.9668942885573,
                48.342815503446
              ],
              [
                -1.9548324683895,
                48.317107151464
              ],
              [
                -1.962374670748,
                48.301567176544
              ],
              [
                -1.9580493030671,
                48.293119482067
              ],
              [
                -1.964626738859,
                48.290393925442
              ],
              [
                -1.9781518094212,
                48.301587065464
              ],
              [
                -1.9986323527079,
                48.296453899109
              ],
              [
                -2.0051168549311,
                48.291815081212
              ],
              [
                -2.0035236920698,
                48.282334281806
              ],
              [
                -2.0140966645446,
                48.279533876954
              ],
              [
                -2.0420704689088,
                48.288087509142
              ],
              [
                -2.0532895985844,
                48.29832528273
              ],
              [
                -2.0775568823449,
                48.289903770528
              ],
              [
                -2.0762648328249,
                48.283708501337
              ],
              [
                -2.0871418879077,
                48.275252371878
              ],
              [
                -2.1104651300822,
                48.27505708951
              ],
              [
                -2.107449085419,
                48.267651698764
              ],
              [
                -2.0955430005434,
                48.266588123333
              ],
              [
                -2.1101233130599,
                48.252906899023
              ],
              [
                -2.1268494196463,
                48.259766532987
              ],
              [
                -2.1501322546014,
                48.25839360564
              ],
              [
                -2.1664849227144,
                48.254229395416
              ],
              [
                -2.1869669777642,
                48.24638192508
              ],
              [
                -2.1894258950763,
                48.227987083108
              ],
              [
                -2.1844290106289,
                48.214579906463
              ],
              [
                -2.1902347220064,
                48.208457068018
              ],
              [
                -2.2028106696694,
                48.208197035535
              ],
              [
                -2.2172535424236,
                48.213154504986
              ],
              [
                -2.2280775916469,
                48.210549869785
              ],
              [
                -2.2204704380944,
                48.201704162968
              ],
              [
                -2.224740280576,
                48.170599593318
              ],
              [
                -2.2285031385077,
                48.165094160943
              ],
              [
                -2.248403953552,
                48.159026949652
              ],
              [
                -2.2482315410287,
                48.152795906922
              ],
              [
                -2.2354077873267,
                48.149287524255
              ],
              [
                -2.2358933261238,
                48.144810125141
              ],
              [
                -2.2525330938788,
                48.144250696486
              ],
              [
                -2.2579503686718,
                48.150028711573
              ],
              [
                -2.2711081340086,
                48.150412972357
              ],
              [
                -2.2837366746174,
                48.144782578012
              ],
              [
                -2.2872742268268,
                48.133743948459
              ],
              [
                -2.3077251019044,
                48.13676439212
              ],
              [
                -2.3211677406633,
                48.132808302216
              ],
              [
                -2.3210668793159,
                48.123308819376
              ],
              [
                -2.3359227288787,
                48.120916671418
              ],
              [
                -2.3447460701781,
                48.127094991305
              ],
              [
                -2.3517675890653,
                48.125103070936
              ],
              [
                -2.3578004145279,
                48.111304497547
              ],
              [
                -2.371360456597,
                48.122100498618
              ],
              [
                -2.3773815505463,
                48.141417281638
              ],
              [
                -2.3972291582243,
                48.153319669138
              ],
              [
                -2.4089089901639,
                48.168612039603
              ],
              [
                -2.4263820118443,
                48.17380491393
              ],
              [
                -2.4526021810189,
                48.170590695779
              ],
              [
                -2.455771521292,
                48.165148597924
              ],
              [
                -2.472825263631,
                48.162181355373
              ],
              [
                -2.4871953847228,
                48.16690697117
              ],
              [
                -2.4948181957429,
                48.157841194948
              ],
              [
                -2.5133396415619,
                48.159177796026
              ],
              [
                -2.5336615546786,
                48.156858555152
              ],
              [
                -2.5248053966861,
                48.145400762655
              ],
              [
                -2.5169738877375,
                48.141579701609
              ],
              [
                -2.5139753471597,
                48.132424705969
              ],
              [
                -2.5319461348412,
                48.110036370183
              ],
              [
                -2.5492973883428,
                48.099064974945
              ],
              [
                -2.5484314818503,
                48.076712424662
              ],
              [
                -2.5611588903258,
                48.074063647354
              ],
              [
                -2.5649680294934,
                48.067326967771
              ],
              [
                -2.5747803200149,
                48.070407599515
              ],
              [
                -2.5948741089285,
                48.052962379246
              ],
              [
                -2.6311896634213,
                48.035478415172
              ],
              [
                -2.6484827700487,
                48.039339123126
              ],
              [
                -2.6700531442166,
                48.047879335975
              ],
              [
                -2.6692623792498,
                48.065294794417
              ],
              [
                -2.6624064534727,
                48.081274437631
              ],
              [
                -2.6523290127164,
                48.095895168979
              ],
              [
                -2.6522994742925,
                48.119322933141
              ],
              [
                -2.6618611953763,
                48.128602238855
              ],
              [
                -2.676232684165,
                48.13181757277
              ],
              [
                -2.7023757860279,
                48.118803410715
              ],
              [
                -2.7038259010001,
                48.112884222981
              ],
              [
                -2.7538962611584,
                48.115834449394
              ],
              [
                -2.7593694385227,
                48.100796103972
              ],
              [
                -2.7670138623917,
                48.091204221723
              ],
              [
                -2.7776588825451,
                48.090357127763
              ],
              [
                -2.7776035826188,
                48.10048691545
              ],
              [
                -2.7836369454384,
                48.111001509075
              ],
              [
                -2.7819460662193,
                48.12058268646
              ],
              [
                -2.8053911962986,
                48.143570856466
              ],
              [
                -2.810396084518,
                48.146550544942
              ],
              [
                -2.8252940822223,
                48.145477166008
              ],
              [
                -2.8408288182409,
                48.141111211271
              ],
              [
                -2.8507975016535,
                48.141165856931
              ],
              [
                -2.8780282096689,
                48.150997682157
              ],
              [
                -2.8908718753323,
                48.164349300123
              ],
              [
                -2.9045601566093,
                48.163152428704
              ],
              [
                -2.9370595232974,
                48.170212391319
              ],
              [
                -2.9536595195283,
                48.170839290652
              ],
              [
                -2.9693795818166,
                48.16750185777
              ],
              [
                -2.9877221855435,
                48.15963332327
              ],
              [
                -2.9999873047788,
                48.161689403852
              ],
              [
                -3.0069243445854,
                48.170296016941
              ],
              [
                -3.0139545505673,
                48.187054490009
              ],
              [
                -3.0143391347997,
                48.197495633085
              ],
              [
                -3.0335432083231,
                48.199363200364
              ],
              [
                -3.0579899734626,
                48.206354123435
              ],
              [
                -3.069447725511,
                48.205015781894
              ],
              [
                -3.0780078103247,
                48.210884763611
              ],
              [
                -3.0937326769485,
                48.201912020928
              ],
              [
                -3.0985579022849,
                48.2061947437
              ],
              [
                -3.1163730959334,
                48.206360221126
              ],
              [
                -3.1209332769833,
                48.200289787202
              ],
              [
                -3.1322448440553,
                48.195534067802
              ],
              [
                -3.1368814843084,
                48.181926378652
              ],
              [
                -3.1302234691174,
                48.176873149256
              ],
              [
                -3.139493598382,
                48.163687258685
              ],
              [
                -3.1552524143595,
                48.158488322356
              ],
              [
                -3.1651406554986,
                48.16221680113
              ],
              [
                -3.1753690237937,
                48.158896538838
              ],
              [
                -3.1925192863564,
                48.164399923361
              ],
              [
                -3.2153410571891,
                48.153405446254
              ],
              [
                -3.2175870763432,
                48.144082867021
              ],
              [
                -3.2297322123493,
                48.149050739238
              ],
              [
                -3.2523367608786,
                48.149124466265
              ],
              [
                -3.2722431061433,
                48.140015733246
              ],
              [
                -3.2829217342699,
                48.141622679586
              ],
              [
                -3.2913303481108,
                48.15080892734
              ],
              [
                -3.2995942714721,
                48.154807439026
              ],
              [
                -3.3161719611326,
                48.154583814893
              ],
              [
                -3.3193408410471,
                48.149607771
              ],
              [
                -3.3367532869347,
                48.171124440685
              ],
              [
                -3.3497450066256,
                48.164770127615
              ],
              [
                -3.3753426707784,
                48.163459489968
              ],
              [
                -3.3850966478371,
                48.156512404714
              ],
              [
                -3.4019111259808,
                48.153969929301
              ],
              [
                -3.4170117151901,
                48.145892119985
              ],
              [
                -3.4350297559107,
                48.154353009549
              ],
              [
                -3.4384466261327,
                48.16255519616
              ],
              [
                -3.4214819897624,
                48.171909040473
              ],
              [
                -3.4234037592294,
                48.177134344133
              ],
              [
                -3.4436332619808,
                48.185786158542
              ],
              [
                -3.4520668252893,
                48.177658808383
              ],
              [
                -3.4719851811899,
                48.180424862726
              ],
              [
                -3.4795255925947,
                48.186041330675
              ],
              [
                -3.5054754633943,
                48.181145182471
              ],
              [
                -3.5331249944313,
                48.178231767076
              ],
              [
                -3.5515421718931,
                48.17976589476
              ],
              [
                -3.5648444491461,
                48.185747540872
              ],
              [
                -3.5665204885688,
                48.189856319348
              ],
              [
                -3.5448511661597,
                48.193567566849
              ],
              [
                -3.527570586586,
                48.190355744622
              ],
              [
                -3.5204667499321,
                48.192254183078
              ],
              [
                -3.5211761675408,
                48.20222775614
              ],
              [
                -3.5382138010087,
                48.20645816321
              ],
              [
                -3.5577840037219,
                48.228526452626
              ],
              [
                -3.5551186672222,
                48.240436468743
              ],
              [
                -3.5419435437528,
                48.245875539987
              ],
              [
                -3.536486648219,
                48.255723915895
              ],
              [
                -3.522134420826,
                48.255475466911
              ],
              [
                -3.5255275937903,
                48.27000310384
              ],
              [
                -3.5184464250967,
                48.282275540295
              ],
              [
                -3.5549910668372,
                48.29372122159
              ],
              [
                -3.539440261815,
                48.301793552649
              ],
              [
                -3.529338883134,
                48.314767041529
              ],
              [
                -3.5483329763946,
                48.312804529458
              ],
              [
                -3.5567909718955,
                48.335520660613
              ],
              [
                -3.5553956492655,
                48.344976478288
              ],
              [
                -3.5630739862519,
                48.348179620709
              ],
              [
                -3.5578475183772,
                48.354959103017
              ],
              [
                -3.5545851809986,
                48.377763123932
              ],
              [
                -3.5835924436067,
                48.382105775396
              ],
              [
                -3.6076360061049,
                48.388458748991
              ],
              [
                -3.600205339211,
                48.402479072861
              ],
              [
                -3.6010283126402,
                48.416679458577
              ],
              [
                -3.5904183425521,
                48.428924877493
              ],
              [
                -3.5582028373806,
                48.442500322496
              ],
              [
                -3.552303441877,
                48.451075213076
              ],
              [
                -3.5688510780452,
                48.452871261149
              ],
              [
                -3.5813853723853,
                48.464801291661
              ],
              [
                -3.5989528212618,
                48.470508920513
              ],
              [
                -3.6040617355353,
                48.496442158126
              ],
              [
                -3.5824001459593,
                48.506014419973
              ],
              [
                -3.5596958329216,
                48.526460450918
              ],
              [
                -3.5392828881973,
                48.538250804163
              ],
              [
                -3.553865623312,
                48.540923162835
              ],
              [
                -3.569989765754,
                48.556092707748
              ],
              [
                -3.594593031018,
                48.55498621307
              ],
              [
                -3.5982748260984,
                48.561427228106
              ],
              [
                -3.5847566277596,
                48.573455178897
              ],
              [
                -3.5944194127755,
                48.585529255716
              ],
              [
                -3.5998068640862,
                48.583446233326
              ],
              [
                -3.617602031901,
                48.590178332783
              ],
              [
                -3.6311578917985,
                48.589761536952
              ],
              [
                -3.6411110010381,
                48.596790826133
              ],
              [
                -3.6387107348493,
                48.610805013827
              ],
              [
                -3.6493770993209,
                48.610157007185
              ],
              [
                -3.663669588163,
                48.648820820674
              ],
              [
                -3.659150584255,
                48.65920940185
              ],
              [
                -3.6420873568022,
                48.669628149236
              ],
              [
                -3.6364032001666,
                48.681876659006
              ],
              [
                -3.6229753236901,
                48.685744443578
              ],
              [
                -3.6160667149757,
                48.682134712863
              ],
              [
                -3.6134373556063,
                48.672125467118
              ],
              [
                -3.6013474443039,
                48.668882399999
              ],
              [
                -3.5812522674227,
                48.670014971375
              ],
              [
                -3.567388863762,
                48.682875835381
              ],
              [
                -3.5821292852091,
                48.688828850154
              ],
              [
                -3.5806180997154,
                48.699098969889
              ],
              [
                -3.5848136756599,
                48.717143137778
              ],
              [
                -3.5777341572244,
                48.722922763845
              ],
              [
                -3.5535067685842,
                48.729599848138
              ],
              [
                -3.5494040209786,
                48.745685503762
              ],
              [
                -3.5660238040656,
                48.760470858523
              ],
              [
                -3.5780706522181,
                48.763052810216
              ],
              [
                -3.5850133196812,
                48.775496976258
              ],
              [
                -3.5670493902465,
                48.796072228502
              ],
              [
                -3.5544363965473,
                48.791566104085
              ],
              [
                -3.5350388142712,
                48.802711262754
              ],
              [
                -3.5452296969806,
                48.811547911235
              ],
              [
                -3.5353532236423,
                48.82420921892
              ],
              [
                -3.5132038128061,
                48.834999450202
              ],
              [
                -3.5102190015828,
                48.827666021396
              ],
              [
                -3.4947289999517,
                48.828909887366
              ],
              [
                -3.487057183946,
                48.835210739121
              ],
              [
                -3.4767632379266,
                48.836553086027
              ],
              [
                -3.4682941245516,
                48.823719601392
              ],
              [
                -3.4534252470278,
                48.815730292786
              ],
              [
                -3.4387785622141,
                48.820131082218
              ],
              [
                -3.4282482665937,
                48.816467818251
              ],
              [
                -3.4428880325615,
                48.803230031461
              ],
              [
                -3.4307313974533,
                48.797223993896
              ],
              [
                -3.3975515012589,
                48.800749976127
              ],
              [
                -3.3872194078248,
                48.805276623502
              ],
              [
                -3.3860910507659,
                48.815072936716
              ],
              [
                -3.3570227693774,
                48.819691247713
              ],
              [
                -3.3353898521234,
                48.828434255251
              ],
              [
                -3.3194282929708,
                48.838251369288
              ],
              [
                -3.2918755715046,
                48.83192266916
              ],
              [
                -3.2808810565774,
                48.84207127438
              ],
              [
                -3.2643350622758,
                48.834201312354
              ],
              [
                -3.2566001531918,
                48.846756455248
              ],
              [
                -3.2460295399846,
                48.856723818997
              ],
              [
                -3.2347983819655,
                48.861980153414
              ],
              [
                -3.2317564057757,
                48.867411825697
              ],
              [
                -3.2197255192397,
                48.866460220845
              ],
              [
                -3.2109738852073,
                48.854759740147
              ],
              [
                -3.213490950434,
                48.839397397676
              ],
              [
                -3.2028272774671,
                48.834506769958
              ],
              [
                -3.2082284171302,
                48.813122349364
              ],
              [
                -3.1967385179982,
                48.818392675983
              ],
              [
                -3.1981611200673,
                48.826345008208
              ],
              [
                -3.1827618229112,
                48.842557979967
              ],
              [
                -3.1738028697326,
                48.841865128735
              ],
              [
                -3.1679437352953,
                48.853146277189
              ],
              [
                -3.1581455962223,
                48.851635850053
              ],
              [
                -3.1344876629569,
                48.858231534485
              ],
              [
                -3.1298367560421,
                48.864364228073
              ],
              [
                -3.1139737119264,
                48.866740453944
              ],
              [
                -3.0840007772064,
                48.86569562865
              ],
              [
                -3.0934910357785,
                48.858795039139
              ],
              [
                -3.08514880221,
                48.849384963641
              ],
              [
                -3.0891457935409,
                48.832182747832
              ],
              [
                -3.0791514131107,
                48.830043840648
              ],
              [
                -3.0921032112249,
                48.808980389522
              ],
              [
                -3.0983841004526,
                48.804282774149
              ],
              [
                -3.0973562864529,
                48.794283712041
              ],
              [
                -3.1017797073776,
                48.783225484414
              ],
              [
                -3.1197930438538,
                48.773596321728
              ],
              [
                -3.1213980681585,
                48.76526963585
              ],
              [
                -3.0996365562548,
                48.768295722963
              ],
              [
                -3.0934962179147,
                48.791936271699
              ],
              [
                -3.0854700296144,
                48.810358940308
              ],
              [
                -3.0704323241691,
                48.82090011268
              ],
              [
                -3.0620643799616,
                48.822425000014
              ],
              [
                -3.0523569695531,
                48.815396803409
              ],
              [
                -3.0386386693612,
                48.819468829234
              ],
              [
                -3.0136302920911,
                48.822137752778
              ],
              [
                -3.0079489411489,
                48.818123335033
              ],
              [
                -3.0167821898089,
                48.812113151135
              ],
              [
                -3.0061398616086,
                48.800293214921
              ],
              [
                -3.0208405402882,
                48.797138467927
              ],
              [
                -3.0249972636159,
                48.79144626786
              ],
              [
                -3.0416526216166,
                48.789013531286
              ],
              [
                -3.0436410172513,
                48.78335094785
              ],
              [
                -3.0239926616859,
                48.783128253914
              ],
              [
                -3.029395265054,
                48.775914254773
              ],
              [
                -3.0159167403363,
                48.767132102258
              ],
              [
                -2.9895062091921,
                48.762638625905
              ],
              [
                -2.9645414610051,
                48.762494954478
              ],
              [
                -2.9554262913638,
                48.769189501689
              ],
              [
                -2.943654576565,
                48.758338397641
              ],
              [
                -2.9286347276991,
                48.75442692542
              ],
              [
                -2.9416450538097,
                48.745308799004
              ],
              [
                -2.9341631909275,
                48.737092242062
              ],
              [
                -2.9479075153591,
                48.727211276245
              ],
              [
                -2.9417423433506,
                48.719207198957
              ],
              [
                -2.9320923985813,
                48.717491879688
              ],
              [
                -2.924759508377,
                48.707466143849
              ],
              [
                -2.8896153283211,
                48.696372886916
              ],
              [
                -2.8800886489961,
                48.67427144179
              ],
              [
                -2.8582441777909,
                48.672308279455
              ],
              [
                -2.8413210056003,
                48.663352340555
              ],
              [
                -2.8377644227621,
                48.656020055446
              ],
              [
                -2.826930459455,
                48.650565045336
              ],
              [
                -2.8241580988201,
                48.634957454331
              ],
              [
                -2.8153839194992,
                48.609899617724
              ],
              [
                -2.8251563129905,
                48.600303982104
              ],
              [
                -2.8196028872168,
                48.593528075876
              ],
              [
                -2.8065963669984,
                48.591904974754
              ],
              [
                -2.7979631859761,
                48.586577481759
              ],
              [
                -2.7793122045816,
                48.584979704743
              ],
              [
                -2.7726822658829,
                48.570547123262
              ],
              [
                -2.7495486430359,
                48.562166461746
              ],
              [
                -2.7179738137973,
                48.555376234837
              ],
              [
                -2.7247921572497,
                48.546914137203
              ],
              [
                -2.7145653373375,
                48.529029220355
              ],
              [
                -2.6984370712725,
                48.505683606103
              ],
              [
                -2.6847820858607,
                48.498049171075
              ],
              [
                -2.6788699666557,
                48.503939642438
              ],
              [
                -2.6765221053678,
                48.519145534995
              ],
              [
                -2.6815598011872,
                48.531128876642
              ],
              [
                -2.6684668905712,
                48.534818647729
              ],
              [
                -2.6579857250454,
                48.526362123163
              ],
              [
                -2.642926445977,
                48.523601503198
              ],
              [
                -2.6302809106614,
                48.52608884558
              ],
              [
                -2.6312659297144,
                48.538126147652
              ],
              [
                -2.6055582106646,
                48.551823337566
              ],
              [
                -2.5686751748086,
                48.578559898858
              ],
              [
                -2.5502739246772,
                48.598731144351
              ],
              [
                -2.5315182573984,
                48.597242979825
              ],
              [
                -2.4966511974711,
                48.608236480629
              ],
              [
                -2.4734313644435,
                48.62252003118
              ],
              [
                -2.4680102449695,
                48.635312407707
              ],
              [
                -2.4802182452475,
                48.637139971893
              ],
              [
                -2.4680059332961,
                48.649583903116
              ],
              [
                -2.4533521947478,
                48.647603843479
              ],
              [
                -2.4356296138472,
                48.652182703457
              ],
              [
                -2.412172534292,
                48.641490561257
              ],
              [
                -2.3963208098213,
                48.642746205929
              ],
              [
                -2.3851972631136,
                48.652775428242
              ],
              [
                -2.3566430746048,
                48.658128435538
              ],
              [
                -2.329817993644,
                48.67243374983
              ],
              [
                -2.3268351903798,
                48.682690335758
              ],
              [
                -2.3128614051641,
                48.68092479798
              ],
              [
                -2.3095552780235,
                48.670248867579
              ],
              [
                -2.2873086848415,
                48.668048684079
              ],
              [
                -2.2986303647576,
                48.650777277754
              ],
              [
                -2.3082350519385,
                48.645115878008
              ],
              [
                -2.3116350377043,
                48.63457311919
              ],
              [
                -2.3378377315034,
                48.620070642052
              ],
              [
                -2.3093749578841,
                48.615045727598
              ],
              [
                -2.2998911449283,
                48.624056828509
              ],
              [
                -2.2873445575751,
                48.627320739303
              ],
              [
                -2.284514633269,
                48.632393122516
              ],
              [
                -2.2590733904716,
                48.644952415823
              ],
              [
                -2.255746903771,
                48.636212558848
              ],
              [
                -2.2433554764628,
                48.623384047682
              ],
              [
                -2.2457501789659,
                48.615403901289
              ],
              [
                -2.2286025908783,
                48.607719393152
              ],
              [
                -2.2239525410226,
                48.595382260905
              ],
              [
                -2.2128021263747,
                48.591597817216
              ],
              [
                -2.201779275207,
                48.583790688394
              ],
              [
                -2.1915532278103,
                48.592304252064
              ],
              [
                -2.194714982728,
                48.599920578374
              ],
              [
                -2.1821193547183,
                48.599174901368
              ],
              [
                -2.187757441748,
                48.587190961848
              ],
              [
                -2.1867457952027,
                48.581332257121
              ],
              [
                -2.1734764888765,
                48.577917159837
              ],
              [
                -2.1642654103968,
                48.578892956541
              ],
              [
                -2.1567074926763,
                48.587698435173
              ],
              [
                -2.1695676200346,
                48.596654644275
              ],
              [
                -2.1658043885874,
                48.604235347881
              ],
              [
                -2.142859606356,
                48.611174051597
              ],
              [
                -2.123706231573,
                48.604405598781
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "22",
        "nom": "Côtes-d'Armor",
        "population": 603640,
        "delitspour1000": "4,006",
        "nombremosque": 7,
        "parentsEtrangers": "85,8",
        "benefRsa": 20208,
        "eoliennes": 233
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.1677843748983,
              46.424069192575
            ],
            [
              2.1851558899005,
              46.423284068779
            ],
            [
              2.1975676755012,
              46.428294141001
            ],
            [
              2.2206403396014,
              46.423664161021
            ],
            [
              2.2334148941606,
              46.423384135542
            ],
            [
              2.2498765158587,
              46.426366967242
            ],
            [
              2.2810437278384,
              46.420403547753
            ],
            [
              2.2804029533754,
              46.410236476962
            ],
            [
              2.2850442774167,
              46.385857562809
            ],
            [
              2.3154715753059,
              46.375205741584
            ],
            [
              2.3315900743858,
              46.378176612964
            ],
            [
              2.3371146519405,
              46.366795923975
            ],
            [
              2.3233383727294,
              46.366525024524
            ],
            [
              2.313389172619,
              46.356936451493
            ],
            [
              2.3027460023726,
              46.354419802873
            ],
            [
              2.3093794648902,
              46.342006193666
            ],
            [
              2.3230230944721,
              46.329277448172
            ],
            [
              2.3345821354246,
              46.325320260055
            ],
            [
              2.3549060485425,
              46.325678438006
            ],
            [
              2.370482749419,
              46.312629017475
            ],
            [
              2.3840641130619,
              46.328908641081
            ],
            [
              2.3918581379529,
              46.329985164159
            ],
            [
              2.4113176626179,
              46.314094483732
            ],
            [
              2.4204656768519,
              46.310129656565
            ],
            [
              2.4161017112175,
              46.300056006693
            ],
            [
              2.421335782135,
              46.284622030263
            ],
            [
              2.4309509758156,
              46.291519676804
            ],
            [
              2.4430263839161,
              46.294995852237
            ],
            [
              2.4789445821479,
              46.281145991526
            ],
            [
              2.4770972903349,
              46.269361724579
            ],
            [
              2.489106418147,
              46.248968644305
            ],
            [
              2.5163085117785,
              46.239436870366
            ],
            [
              2.5154284346668,
              46.228012112114
            ],
            [
              2.5215270201899,
              46.21133367553
            ],
            [
              2.5216954121117,
              46.203035380932
            ],
            [
              2.5280194815711,
              46.195466990157
            ],
            [
              2.5286290573702,
              46.184894945309
            ],
            [
              2.5431674609911,
              46.175602605523
            ],
            [
              2.5597996290957,
              46.173366559433
            ],
            [
              2.5607637153191,
              46.155596225642
            ],
            [
              2.5654816305578,
              46.153967351319
            ],
            [
              2.5653725529015,
              46.143035759957
            ],
            [
              2.5586220340193,
              46.138326224613
            ],
            [
              2.5582364865104,
              46.130701979674
            ],
            [
              2.5490488887945,
              46.114075006608
            ],
            [
              2.5518219731636,
              46.103970633498
            ],
            [
              2.5518715312783,
              46.083457348801
            ],
            [
              2.5570843667475,
              46.069300275416
            ],
            [
              2.5631828961231,
              46.064609781776
            ],
            [
              2.5716988273144,
              46.048689825423
            ],
            [
              2.6025059601172,
              46.033461930485
            ],
            [
              2.5944202186196,
              45.989440975795
            ],
            [
              2.6074695080176,
              45.979108641029
            ],
            [
              2.6107853057918,
              45.971228071516
            ],
            [
              2.5891875589674,
              45.960437573506
            ],
            [
              2.5651781859373,
              45.95661981464
            ],
            [
              2.5657173898648,
              45.946856793309
            ],
            [
              2.5516381183225,
              45.941261078502
            ],
            [
              2.5509536719711,
              45.935761668247
            ],
            [
              2.5415060022687,
              45.921531781083
            ],
            [
              2.5550056008106,
              45.912509471984
            ],
            [
              2.5376912527467,
              45.900845994537
            ],
            [
              2.5262086816167,
              45.896727274022
            ],
            [
              2.5099613389201,
              45.88666598071
            ],
            [
              2.4922279879729,
              45.864029936278
            ],
            [
              2.4797383951775,
              45.864541357011
            ],
            [
              2.4706137918202,
              45.872335245229
            ],
            [
              2.4425827259717,
              45.863008040763
            ],
            [
              2.4478280089908,
              45.84581866569
            ],
            [
              2.436436952669,
              45.846998827989
            ],
            [
              2.4265364620605,
              45.834771343876
            ],
            [
              2.4013447310476,
              45.837577232244
            ],
            [
              2.388014020679,
              45.827373168854
            ],
            [
              2.4006773662183,
              45.817289546629
            ],
            [
              2.4185991219253,
              45.798475127769
            ],
            [
              2.4274035046023,
              45.794285013202
            ],
            [
              2.4329800874586,
              45.783888824912
            ],
            [
              2.4341123429169,
              45.769852817869
            ],
            [
              2.4414259075117,
              45.762378997013
            ],
            [
              2.4547914690758,
              45.761151249494
            ],
            [
              2.4751529321011,
              45.747688866007
            ],
            [
              2.4846414659033,
              45.748521466442
            ],
            [
              2.4921294160933,
              45.7376701128
            ],
            [
              2.4820705176845,
              45.735861168354
            ],
            [
              2.4647133674467,
              45.716651528694
            ],
            [
              2.4514675970093,
              45.708074449863
            ],
            [
              2.4444684061256,
              45.707496260407
            ],
            [
              2.4358494505048,
              45.699272327908
            ],
            [
              2.4102499185539,
              45.709637609084
            ],
            [
              2.3864265340174,
              45.707941738844
            ],
            [
              2.3663330806295,
              45.712986903093
            ],
            [
              2.3491879213954,
              45.70948075492
            ],
            [
              2.3446927322808,
              45.70516284581
            ],
            [
              2.3482860389568,
              45.695041172912
            ],
            [
              2.3377583954679,
              45.680602150879
            ],
            [
              2.3264208053504,
              45.677235435121
            ],
            [
              2.3209490355497,
              45.668590940988
            ],
            [
              2.3088188102277,
              45.676010357627
            ],
            [
              2.2894491157149,
              45.665747280433
            ],
            [
              2.2733468046127,
              45.664008285608
            ],
            [
              2.269031167198,
              45.678331971197
            ],
            [
              2.2727104741081,
              45.68771311379
            ],
            [
              2.2381374375847,
              45.693288512748
            ],
            [
              2.22185607331,
              45.698063513429
            ],
            [
              2.2152594548892,
              45.70559122122
            ],
            [
              2.2053248238341,
              45.698652355768
            ],
            [
              2.1939610907282,
              45.699043520529
            ],
            [
              2.2023233791081,
              45.718576123482
            ],
            [
              2.1829873189316,
              45.720620530116
            ],
            [
              2.1743482380562,
              45.732214123162
            ],
            [
              2.1609106642829,
              45.735837745361
            ],
            [
              2.1579966688387,
              45.723417414039
            ],
            [
              2.1470164213187,
              45.722680175219
            ],
            [
              2.1338040836422,
              45.735431951224
            ],
            [
              2.121289380837,
              45.732394845856
            ],
            [
              2.1135480150858,
              45.72477688612
            ],
            [
              2.0970008847403,
              45.734189542977
            ],
            [
              2.0846954513364,
              45.72804494641
            ],
            [
              2.0813955940415,
              45.747110632445
            ],
            [
              2.0584205289931,
              45.754477689431
            ],
            [
              2.0552938288374,
              45.762792466152
            ],
            [
              2.0469321372785,
              45.763895555756
            ],
            [
              2.0356697732675,
              45.755049147734
            ],
            [
              2.0176099912597,
              45.755493973695
            ],
            [
              2.0016539166162,
              45.748070146272
            ],
            [
              1.995966765675,
              45.729461814793
            ],
            [
              1.9885167886234,
              45.721351415511
            ],
            [
              1.9778905956463,
              45.724334588008
            ],
            [
              1.9565811212045,
              45.724758955489
            ],
            [
              1.9483824754413,
              45.721672179566
            ],
            [
              1.941713024542,
              45.711346901084
            ],
            [
              1.9155154483473,
              45.711251861462
            ],
            [
              1.9084767843547,
              45.70333633169
            ],
            [
              1.8987320820414,
              45.698276663383
            ],
            [
              1.8814370841814,
              45.709094226518
            ],
            [
              1.8924057186982,
              45.718404629218
            ],
            [
              1.8875349222779,
              45.725088467315
            ],
            [
              1.8733390753593,
              45.725300512683
            ],
            [
              1.8751389083315,
              45.732775274201
            ],
            [
              1.8864061938952,
              45.745191705923
            ],
            [
              1.8743032041452,
              45.755758721473
            ],
            [
              1.877939027566,
              45.758922660532
            ],
            [
              1.8941319077424,
              45.759257284373
            ],
            [
              1.8971494460868,
              45.767681100542
            ],
            [
              1.8913705933824,
              45.774856101763
            ],
            [
              1.8808950397786,
              45.797711178589
            ],
            [
              1.8464434674253,
              45.809936550114
            ],
            [
              1.8409992739107,
              45.821564120274
            ],
            [
              1.8299936190633,
              45.821885615941
            ],
            [
              1.8284323808085,
              45.809811431691
            ],
            [
              1.8134556686181,
              45.815365685815
            ],
            [
              1.808931209546,
              45.821035017177
            ],
            [
              1.7891530555383,
              45.83007290926
            ],
            [
              1.7764808489409,
              45.832775933168
            ],
            [
              1.7727453683847,
              45.842824831336
            ],
            [
              1.7778168232688,
              45.850057589313
            ],
            [
              1.7773732690177,
              45.858849849245
            ],
            [
              1.770771649476,
              45.868375937565
            ],
            [
              1.7559833739095,
              45.866321064339
            ],
            [
              1.7544800457441,
              45.856281684747
            ],
            [
              1.7398526245127,
              45.851526413716
            ],
            [
              1.732972930451,
              45.844347283718
            ],
            [
              1.7148761323959,
              45.840055000467
            ],
            [
              1.7054286850217,
              45.843072253083
            ],
            [
              1.6903585111423,
              45.843610939097
            ],
            [
              1.6766400011982,
              45.83511315007
            ],
            [
              1.6579000699666,
              45.841299960463
            ],
            [
              1.6525034147536,
              45.84563075494
            ],
            [
              1.6284040273944,
              45.84764094228
            ],
            [
              1.6023947165161,
              45.857460609029
            ],
            [
              1.6072764729052,
              45.868063682495
            ],
            [
              1.6016302598318,
              45.877652356969
            ],
            [
              1.591036942213,
              45.883073233205
            ],
            [
              1.6024175499196,
              45.890903206559
            ],
            [
              1.616888915489,
              45.891248673753
            ],
            [
              1.620176398313,
              45.89846776245
            ],
            [
              1.6359937428864,
              45.888445648637
            ],
            [
              1.6417383448025,
              45.892619858859
            ],
            [
              1.6389237619179,
              45.903759184162
            ],
            [
              1.6247470869022,
              45.916131643599
            ],
            [
              1.6345205792016,
              45.922985835859
            ],
            [
              1.6087209134796,
              45.933413342676
            ],
            [
              1.6032095357851,
              45.931423491118
            ],
            [
              1.5791756702079,
              45.930409140897
            ],
            [
              1.5782615511454,
              45.917267246264
            ],
            [
              1.5666404819256,
              45.914039497609
            ],
            [
              1.5582767056347,
              45.922079517803
            ],
            [
              1.5472652293994,
              45.916829291477
            ],
            [
              1.525656674414,
              45.931004244504
            ],
            [
              1.5132688523674,
              45.931656338798
            ],
            [
              1.5094010745832,
              45.940175167016
            ],
            [
              1.5220002973313,
              45.942831619097
            ],
            [
              1.5207608432223,
              45.952449686784
            ],
            [
              1.5324937217054,
              45.95280415449
            ],
            [
              1.5533760749426,
              45.962803517251
            ],
            [
              1.5603334781916,
              45.963481224277
            ],
            [
              1.5749323906618,
              45.975997479181
            ],
            [
              1.5736991057427,
              45.985706156338
            ],
            [
              1.5631407946841,
              45.997690833415
            ],
            [
              1.5385506556741,
              45.997429410822
            ],
            [
              1.5322792937503,
              46.011284955225
            ],
            [
              1.5363859345527,
              46.02629511129
            ],
            [
              1.5491707327049,
              46.03540557368
            ],
            [
              1.5388726320688,
              46.043461078132
            ],
            [
              1.5317844788051,
              46.058158129945
            ],
            [
              1.5396702485746,
              46.066897128383
            ],
            [
              1.5434017044539,
              46.07691072874
            ],
            [
              1.5262511459463,
              46.079443408884
            ],
            [
              1.5247847438432,
              46.088797384678
            ],
            [
              1.5128361619698,
              46.093426566068
            ],
            [
              1.504897999906,
              46.088149821066
            ],
            [
              1.5015918794313,
              46.101397126546
            ],
            [
              1.4905027878223,
              46.111751805528
            ],
            [
              1.5067100584923,
              46.122106571984
            ],
            [
              1.5051925260546,
              46.127465281562
            ],
            [
              1.4840328155431,
              46.136716379374
            ],
            [
              1.4678745047091,
              46.151753965164
            ],
            [
              1.4520544208808,
              46.15334082047
            ],
            [
              1.4653163830511,
              46.161946388556
            ],
            [
              1.4527908975494,
              46.179754559157
            ],
            [
              1.4382046633811,
              46.17632439479
            ],
            [
              1.4196601094072,
              46.178050409465
            ],
            [
              1.3960696381811,
              46.188184833298
            ],
            [
              1.3952210030033,
              46.194450526459
            ],
            [
              1.3748978470741,
              46.215446562448
            ],
            [
              1.3814642425679,
              46.225377328404
            ],
            [
              1.3941388425989,
              46.238350039165
            ],
            [
              1.4197668713784,
              46.244993664165
            ],
            [
              1.4155339799349,
              46.253732460733
            ],
            [
              1.4057349259219,
              46.254101792781
            ],
            [
              1.4146523821885,
              46.271360510371
            ],
            [
              1.4230973001724,
              46.266795272783
            ],
            [
              1.4367552457062,
              46.27248303512
            ],
            [
              1.432819785109,
              46.276117113177
            ],
            [
              1.4141723776068,
              46.280489208444
            ],
            [
              1.4231389645279,
              46.287535272137
            ],
            [
              1.4265739813571,
              46.298922021214
            ],
            [
              1.4368962523711,
              46.299297404353
            ],
            [
              1.4437657610001,
              46.305896855052
            ],
            [
              1.4380888390142,
              46.32088703613
            ],
            [
              1.4457835086221,
              46.331398315254
            ],
            [
              1.4241467178877,
              46.335515305437
            ],
            [
              1.4096743646352,
              46.342805940916
            ],
            [
              1.4151854142983,
              46.347214822447
            ],
            [
              1.438395055125,
              46.35797267046
            ],
            [
              1.4353743494744,
              46.363842198651
            ],
            [
              1.454349713379,
              46.376048839285
            ],
            [
              1.4629367778167,
              46.375349793186
            ],
            [
              1.472558559102,
              46.383431643989
            ],
            [
              1.4775958033831,
              46.393726557044
            ],
            [
              1.4929348173659,
              46.398340551367
            ],
            [
              1.5059901336348,
              46.409908716453
            ],
            [
              1.5112050540502,
              46.419594596972
            ],
            [
              1.5223067601736,
              46.426527695424
            ],
            [
              1.5350519004493,
              46.424455702933
            ],
            [
              1.5439680356057,
              46.41688301363
            ],
            [
              1.5461946362171,
              46.39346025752
            ],
            [
              1.5524923075161,
              46.394133824944
            ],
            [
              1.5696914589532,
              46.405498471105
            ],
            [
              1.5920388571659,
              46.407311867592
            ],
            [
              1.6092554805936,
              46.423123163216
            ],
            [
              1.6226552998913,
              46.418260263211
            ],
            [
              1.6143064140463,
              46.405295947029
            ],
            [
              1.628836514357,
              46.388247455127
            ],
            [
              1.6448463938248,
              46.386816749434
            ],
            [
              1.6611836253306,
              46.403538218482
            ],
            [
              1.683606447426,
              46.418176923275
            ],
            [
              1.6974668292376,
              46.406604886198
            ],
            [
              1.7091312929501,
              46.393353821969
            ],
            [
              1.7277096608449,
              46.388955998124
            ],
            [
              1.7392119982362,
              46.401254100808
            ],
            [
              1.7505449826939,
              46.405586225701
            ],
            [
              1.7493159438657,
              46.41093389201
            ],
            [
              1.757375741134,
              46.423616429483
            ],
            [
              1.7566658918784,
              46.441292317573
            ],
            [
              1.7475915839665,
              46.450017868926
            ],
            [
              1.7548573832317,
              46.452214535934
            ],
            [
              1.7983753858278,
              46.45481310551
            ],
            [
              1.8031391002694,
              46.44691836733
            ],
            [
              1.816812388337,
              46.439524938483
            ],
            [
              1.8195044214246,
              46.430033877555
            ],
            [
              1.8383647496008,
              46.42728517531
            ],
            [
              1.8580375856722,
              46.433476804112
            ],
            [
              1.8834368991965,
              46.432557252889
            ],
            [
              1.89071589528,
              46.441493184019
            ],
            [
              1.902458213237,
              46.43780452052
            ],
            [
              1.909180451657,
              46.443498226347
            ],
            [
              1.9195527692358,
              46.440208070783
            ],
            [
              1.9243017577068,
              46.431903065788
            ],
            [
              1.943869602507,
              46.433996659264
            ],
            [
              1.9542508363527,
              46.438125703166
            ],
            [
              1.9781042758382,
              46.439772572322
            ],
            [
              1.9930771581051,
              46.430917343442
            ],
            [
              2.0203464671203,
              46.429421918521
            ],
            [
              2.0294174309506,
              46.424588797264
            ],
            [
              2.0741962684953,
              46.419842978652
            ],
            [
              2.0803472806508,
              46.411894246794
            ],
            [
              2.0889457684929,
              46.40889961632
            ],
            [
              2.1083721397147,
              46.413528592759
            ],
            [
              2.1126347187417,
              46.420825387231
            ],
            [
              2.1296807376318,
              46.419867872386
            ],
            [
              2.1677843748983,
              46.424069192575
            ]
          ]
        ]
      },
      "properties": {
        "code": "23",
        "nom": "Creuse",
        "population": 115995,
        "delitspour1000": "4,69",
        "nombremosque": 6,
        "parentsEtrangers": "87,0",
        "benefRsa": 4695,
        "eoliennes": 38
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              6.8069985618205,
              47.562799803717
            ],
            [
              6.8166586737681,
              47.547922521723
            ],
            [
              6.8306374300492,
              47.546435598922
            ],
            [
              6.8609495446484,
              47.54768917383
            ],
            [
              6.8834722552899,
              47.555330303305
            ],
            [
              6.8898311184149,
              47.550073165239
            ],
            [
              6.905664579088,
              47.547365295196
            ],
            [
              6.9158698594302,
              47.532559539947
            ],
            [
              6.9096754139357,
              47.52550620551
            ],
            [
              6.9257104433272,
              47.519421414109
            ],
            [
              6.9364864190664,
              47.51791308047
            ],
            [
              6.9455168398509,
              47.512373779013
            ],
            [
              6.9450210386148,
              47.498968686861
            ],
            [
              6.9488572709436,
              47.485705936519
            ],
            [
              6.9419807180899,
              47.485348691209
            ],
            [
              6.9341220005611,
              47.493635099659
            ],
            [
              6.9229860562893,
              47.500158491026
            ],
            [
              6.9076127100416,
              47.494548688313
            ],
            [
              6.9152779168352,
              47.4785684289
            ],
            [
              6.9075782646775,
              47.47324112034
            ],
            [
              6.9172302764556,
              47.468867148315
            ],
            [
              6.9323197706656,
              47.455818472268
            ],
            [
              6.9405416785725,
              47.433371743667
            ],
            [
              6.9416788459902,
              47.415801742321
            ],
            [
              6.9383781843469,
              47.40602166291
            ],
            [
              6.9175655645227,
              47.405540786123
            ],
            [
              6.9112807218549,
              47.38565149684
            ],
            [
              6.8853957543304,
              47.374599512338
            ],
            [
              6.879373150364,
              47.358392623936
            ],
            [
              6.8841847374511,
              47.3526166115
            ],
            [
              6.9041310992201,
              47.359488916109
            ],
            [
              6.9173061224044,
              47.356174450746
            ],
            [
              6.9518221878337,
              47.359328514583
            ],
            [
              6.965171933991,
              47.359200577804
            ],
            [
              6.9832539854825,
              47.36354366283
            ],
            [
              6.9942194881531,
              47.36305702277
            ],
            [
              7.0161749063041,
              47.371834506375
            ],
            [
              7.0497154850674,
              47.360716488473
            ],
            [
              7.0522936553288,
              47.351141950572
            ],
            [
              7.0622006908671,
              47.344160388368
            ],
            [
              7.0567509457312,
              47.334567036162
            ],
            [
              7.046205749818,
              47.326977520793
            ],
            [
              7.0305707210719,
              47.327027697793
            ],
            [
              7.0097278540447,
              47.324358772262
            ],
            [
              7.0161408458867,
              47.313601433148
            ],
            [
              7.0096883964596,
              47.303186330915
            ],
            [
              6.9931211498575,
              47.295543502651
            ],
            [
              6.9812664414026,
              47.295770810067
            ],
            [
              6.9665305332962,
              47.292330424797
            ],
            [
              6.9535590397913,
              47.292421856494
            ],
            [
              6.9428919076225,
              47.287757217086
            ],
            [
              6.9514761981406,
              47.262184061527
            ],
            [
              6.9494136431571,
              47.256679318602
            ],
            [
              6.9549768276348,
              47.242939913873
            ],
            [
              6.92627289824,
              47.224555534793
            ],
            [
              6.882438308572,
              47.201615407729
            ],
            [
              6.8639146913869,
              47.180098699893
            ],
            [
              6.842629723754,
              47.172148165798
            ],
            [
              6.8448535375183,
              47.166384939791
            ],
            [
              6.8583444229594,
              47.164385451371
            ],
            [
              6.8397865674492,
              47.150904795437
            ],
            [
              6.8285734250859,
              47.146341618703
            ],
            [
              6.8065486361803,
              47.130890441394
            ],
            [
              6.7637802260904,
              47.11979466278
            ],
            [
              6.7399139141665,
              47.108051829732
            ],
            [
              6.7456764991255,
              47.100307622017
            ],
            [
              6.741092234341,
              47.091067967613
            ],
            [
              6.7178304547913,
              47.088938283348
            ],
            [
              6.7072576746821,
              47.083033793266
            ],
            [
              6.7060095703295,
              47.074995419181
            ],
            [
              6.6916197499771,
              47.06668927851
            ],
            [
              6.7110513317725,
              47.054327711875
            ],
            [
              6.7140545270618,
              47.049123354928
            ],
            [
              6.6996564781858,
              47.039039389615
            ],
            [
              6.6784719863524,
              47.035382104925
            ],
            [
              6.6616206647392,
              47.028224476267
            ],
            [
              6.6535911062238,
              47.021768824963
            ],
            [
              6.6403417206763,
              47.002761124116
            ],
            [
              6.6186789138725,
              46.992038806738
            ],
            [
              6.5931797938133,
              46.991770812154
            ],
            [
              6.5665618384639,
              46.980637338846
            ],
            [
              6.5402076242225,
              46.974399049863
            ],
            [
              6.5188066665015,
              46.970856252967
            ],
            [
              6.5052194745754,
              46.965849252067
            ],
            [
              6.4966857222781,
              46.974183469608
            ],
            [
              6.4751246015727,
              46.959379453348
            ],
            [
              6.4553435306172,
              46.940428600583
            ],
            [
              6.4326749210724,
              46.928605526908
            ],
            [
              6.4579026529923,
              46.900855622932
            ],
            [
              6.4645775075825,
              46.890210535442
            ],
            [
              6.4598785157573,
              46.851147058563
            ],
            [
              6.4423415130938,
              46.83123675481
            ],
            [
              6.4401683842133,
              46.819062942975
            ],
            [
              6.4310019605487,
              46.812409973106
            ],
            [
              6.4382925253712,
              46.799581903269
            ],
            [
              6.4586515580207,
              46.788759687976
            ],
            [
              6.4381050836181,
              46.76175121989
            ],
            [
              6.425911183574,
              46.754801119874
            ],
            [
              6.3950745702138,
              46.748243117811
            ],
            [
              6.389642803755,
              46.735238377227
            ],
            [
              6.3716294362082,
              46.7247505128
            ],
            [
              6.360238313501,
              46.72308030451
            ],
            [
              6.3448015815423,
              46.711867719342
            ],
            [
              6.3154917743371,
              46.702442903244
            ],
            [
              6.2993568333135,
              46.694896443468
            ],
            [
              6.2853255009979,
              46.691242033962
            ],
            [
              6.2704880921381,
              46.683194022336
            ],
            [
              6.2691722926715,
              46.678150632899
            ],
            [
              6.241610701122,
              46.660314779707
            ],
            [
              6.2087769308549,
              46.636617811577
            ],
            [
              6.1849170570186,
              46.622901021419
            ],
            [
              6.1755349630813,
              46.614142709958
            ],
            [
              6.1644651641931,
              46.610011824492
            ],
            [
              6.1273332783823,
              46.590310133888
            ],
            [
              6.1107441527986,
              46.576314209592
            ],
            [
              6.138105886557,
              46.557659570871
            ],
            [
              6.1304724861572,
              46.553996454277
            ],
            [
              6.0999852394696,
              46.573743234661
            ],
            [
              6.0684058243104,
              46.595948015094
            ],
            [
              6.048568006745,
              46.607719222344
            ],
            [
              6.0583329740831,
              46.617540436267
            ],
            [
              6.059175829941,
              46.626107264362
            ],
            [
              6.0868627501584,
              46.638891197306
            ],
            [
              6.1000606874326,
              46.643454999445
            ],
            [
              6.1037735049255,
              46.649817333346
            ],
            [
              6.0868868292495,
              46.672260499977
            ],
            [
              6.0698260174554,
              46.68952765456
            ],
            [
              6.1129539816939,
              46.707747239466
            ],
            [
              6.1399453541345,
              46.725268446673
            ],
            [
              6.1802695893085,
              46.741573475312
            ],
            [
              6.185266031767,
              46.755141237425
            ],
            [
              6.2026525525133,
              46.762301114569
            ],
            [
              6.2033299339615,
              46.772681384866
            ],
            [
              6.1757183241917,
              46.800243889843
            ],
            [
              6.1632378106454,
              46.8035284185
            ],
            [
              6.1492882107225,
              46.815883590897
            ],
            [
              6.1517911997737,
              46.820664742672
            ],
            [
              6.1341958499901,
              46.826068437822
            ],
            [
              6.1171190503892,
              46.834244144073
            ],
            [
              6.1056795866935,
              46.845204396114
            ],
            [
              6.0910071683215,
              46.841859266961
            ],
            [
              6.083730222322,
              46.851419391489
            ],
            [
              6.0680701595708,
              46.858460884974
            ],
            [
              6.047894270997,
              46.848624239765
            ],
            [
              6.0340130724844,
              46.849595790775
            ],
            [
              6.0242086777367,
              46.855468333834
            ],
            [
              6.0327205442291,
              46.870907067247
            ],
            [
              6.0206735851666,
              46.868100918927
            ],
            [
              6.0057824486729,
              46.873054533872
            ],
            [
              6.0117200011004,
              46.888923174244
            ],
            [
              6.0084286313536,
              46.895557865571
            ],
            [
              6.0000629144227,
              46.896186112826
            ],
            [
              5.9856936195365,
              46.906145471864
            ],
            [
              5.9826064111012,
              46.919753175451
            ],
            [
              5.9967921639228,
              46.933125372159
            ],
            [
              5.9791301258507,
              46.939766948061
            ],
            [
              5.9640762528681,
              46.948078452327
            ],
            [
              5.9664518538116,
              46.954037738921
            ],
            [
              5.9797832488435,
              46.963475945672
            ],
            [
              5.9729390451722,
              46.969276607259
            ],
            [
              5.975687606681,
              46.975854084501
            ],
            [
              5.968563083074,
              46.980447122058
            ],
            [
              5.9435872836268,
              46.98054854349
            ],
            [
              5.9150032244474,
              46.987308302623
            ],
            [
              5.9110757735042,
              46.999497387461
            ],
            [
              5.8866227813731,
              46.996969338727
            ],
            [
              5.8817587510691,
              47.005044433844
            ],
            [
              5.860805619626,
              47.007715477722
            ],
            [
              5.851748315587,
              47.006597305776
            ],
            [
              5.8286441496403,
              47.012164289689
            ],
            [
              5.8150520653853,
              47.020342660386
            ],
            [
              5.8158126764714,
              47.045781800815
            ],
            [
              5.7971469497964,
              47.042338209885
            ],
            [
              5.7986375638838,
              47.029686865519
            ],
            [
              5.7876490641526,
              47.020606883184
            ],
            [
              5.7702296182778,
              47.017932649609
            ],
            [
              5.7631634923394,
              47.0113904386
            ],
            [
              5.7495148138703,
              47.017244251927
            ],
            [
              5.740849651432,
              47.031142758696
            ],
            [
              5.7501649678587,
              47.033703118269
            ],
            [
              5.7527262522254,
              47.039795055844
            ],
            [
              5.7369607236255,
              47.049807327539
            ],
            [
              5.7456009113306,
              47.057249694456
            ],
            [
              5.763387093616,
              47.044835754153
            ],
            [
              5.7847160202443,
              47.054913241204
            ],
            [
              5.7799376281429,
              47.063610139342
            ],
            [
              5.7786114797683,
              47.074770571137
            ],
            [
              5.7685434263991,
              47.089434266457
            ],
            [
              5.7722519645683,
              47.09486136967
            ],
            [
              5.7832195551564,
              47.09624366995
            ],
            [
              5.8021492858263,
              47.11066783822
            ],
            [
              5.8054098436194,
              47.119258761879
            ],
            [
              5.8160829426973,
              47.134334882294
            ],
            [
              5.827306328055,
              47.14254911162
            ],
            [
              5.8137909740309,
              47.149846839884
            ],
            [
              5.811472582346,
              47.169050988739
            ],
            [
              5.7860744752117,
              47.177558359026
            ],
            [
              5.7863565246694,
              47.188084219493
            ],
            [
              5.7807437061122,
              47.192980985477
            ],
            [
              5.7615854800525,
              47.193348494233
            ],
            [
              5.7607076259684,
              47.20217591141
            ],
            [
              5.7543562182361,
              47.20614572363
            ],
            [
              5.7398278721209,
              47.201038563789
            ],
            [
              5.7378824016408,
              47.208883832309
            ],
            [
              5.7204115628525,
              47.219933082046
            ],
            [
              5.7171076564753,
              47.23299564261
            ],
            [
              5.7196574167089,
              47.240393529584
            ],
            [
              5.6987272452696,
              47.265000343118
            ],
            [
              5.7145347872316,
              47.270255852947
            ],
            [
              5.7322991145862,
              47.263567350231
            ],
            [
              5.7468354803137,
              47.270612231992
            ],
            [
              5.7727997630254,
              47.278395213513
            ],
            [
              5.7839090511043,
              47.290625059339
            ],
            [
              5.7979865070798,
              47.289348636043
            ],
            [
              5.8059637234122,
              47.292980800624
            ],
            [
              5.8440112265694,
              47.300024805332
            ],
            [
              5.853145246542,
              47.310929356036
            ],
            [
              5.8838943313054,
              47.321588272893
            ],
            [
              5.8935058005458,
              47.310802954862
            ],
            [
              5.9021382948584,
              47.315173587478
            ],
            [
              5.8967089702137,
              47.32907312258
            ],
            [
              5.9088438658603,
              47.331310058375
            ],
            [
              5.9264399732294,
              47.327072561708
            ],
            [
              5.9269718960405,
              47.344840931073
            ],
            [
              5.9413734265811,
              47.344257225785
            ],
            [
              5.965730648432,
              47.337146093441
            ],
            [
              5.9736802174701,
              47.331638462475
            ],
            [
              5.989361863815,
              47.327626814323
            ],
            [
              5.9958846761029,
              47.339347508631
            ],
            [
              6.0235520408948,
              47.332344827388
            ],
            [
              6.0344506439514,
              47.341001809342
            ],
            [
              6.0541055119785,
              47.350726964455
            ],
            [
              6.0667977679461,
              47.349372658626
            ],
            [
              6.080044218278,
              47.354032012422
            ],
            [
              6.081531751757,
              47.371453831253
            ],
            [
              6.0996954915,
              47.37185711117
            ],
            [
              6.1024898732853,
              47.361317553574
            ],
            [
              6.1129616996199,
              47.361336476943
            ],
            [
              6.1197535163538,
              47.36647237422
            ],
            [
              6.1067175485251,
              47.37707499365
            ],
            [
              6.1041786160535,
              47.382405041571
            ],
            [
              6.1166555407966,
              47.393181051924
            ],
            [
              6.1380274381798,
              47.386676134179
            ],
            [
              6.1522419359909,
              47.386118440979
            ],
            [
              6.1728423123163,
              47.397819475189
            ],
            [
              6.1844071214655,
              47.402015080619
            ],
            [
              6.1716477183347,
              47.407945159887
            ],
            [
              6.1711805474595,
              47.412159811785
            ],
            [
              6.1909197990926,
              47.414870283514
            ],
            [
              6.199286591994,
              47.421840616519
            ],
            [
              6.2004449826992,
              47.429141415445
            ],
            [
              6.2243682111045,
              47.420036070169
            ],
            [
              6.2320650830845,
              47.414168026391
            ],
            [
              6.2427351187986,
              47.414011465779
            ],
            [
              6.2343557323534,
              47.424627410052
            ],
            [
              6.2394366632237,
              47.431480940273
            ],
            [
              6.2502936711408,
              47.424810091085
            ],
            [
              6.2623300177143,
              47.430571634935
            ],
            [
              6.263541283654,
              47.446111559058
            ],
            [
              6.2801775945428,
              47.443969681745
            ],
            [
              6.2874793153018,
              47.460968303918
            ],
            [
              6.301210989282,
              47.467928704375
            ],
            [
              6.2997539670346,
              47.484054799832
            ],
            [
              6.3025025471738,
              47.49152594453
            ],
            [
              6.3194622093461,
              47.485540598389
            ],
            [
              6.3343498677298,
              47.505967983253
            ],
            [
              6.3486273758899,
              47.50583494217
            ],
            [
              6.3679499207552,
              47.513495557164
            ],
            [
              6.3928349464655,
              47.50771882024
            ],
            [
              6.3934832135071,
              47.516131364904
            ],
            [
              6.401371649444,
              47.520299009193
            ],
            [
              6.4176535676023,
              47.521078457963
            ],
            [
              6.4289058446505,
              47.5169888253
            ],
            [
              6.4634948199838,
              47.512115441106
            ],
            [
              6.4710619701094,
              47.487165902965
            ],
            [
              6.4958967399913,
              47.505688006585
            ],
            [
              6.5183718363402,
              47.496502507822
            ],
            [
              6.5345414999605,
              47.506051508274
            ],
            [
              6.5535649973603,
              47.492794483415
            ],
            [
              6.5582025092095,
              47.496814917298
            ],
            [
              6.5735160004641,
              47.495489902695
            ],
            [
              6.5836439355223,
              47.509982562088
            ],
            [
              6.5790621014857,
              47.525057917341
            ],
            [
              6.5682850532602,
              47.532964684568
            ],
            [
              6.5817732561311,
              47.539866618089
            ],
            [
              6.5988011068119,
              47.539872720756
            ],
            [
              6.6169076495322,
              47.53096352473
            ],
            [
              6.6291213056858,
              47.531097345326
            ],
            [
              6.6467168301981,
              47.53526431792
            ],
            [
              6.6701763034189,
              47.557900781795
            ],
            [
              6.6534994623075,
              47.571313940022
            ],
            [
              6.6600556699688,
              47.579897594928
            ],
            [
              6.6695710506909,
              47.572516821504
            ],
            [
              6.6888656429718,
              47.570804123782
            ],
            [
              6.6855610050528,
              47.562955417822
            ],
            [
              6.7154115299407,
              47.552914539148
            ],
            [
              6.7265473440888,
              47.560466513522
            ],
            [
              6.7409937123091,
              47.55431305009
            ],
            [
              6.7528882401679,
              47.543521415366
            ],
            [
              6.7683735285688,
              47.537749840769
            ],
            [
              6.7809665391051,
              47.536412762244
            ],
            [
              6.7974982776923,
              47.549516322215
            ],
            [
              6.8012316005384,
              47.562361681719
            ],
            [
              6.8069985618205,
              47.562799803717
            ]
          ]
        ]
      },
      "properties": {
        "code": "25",
        "nom": "Doubs",
        "population": 545209,
        "delitspour1000": "5,422",
        "nombremosque": 32,
        "parentsEtrangers": "73,1",
        "benefRsa": 25328,
        "eoliennes": 77
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -5.0725232805168,
                48.482730316788
              ],
              [
                -5.056629077213,
                48.476824762454
              ],
              [
                -5.0569734591597,
                48.468162401798
              ],
              [
                -5.0403484938817,
                48.465113540285
              ],
              [
                -5.0582321919735,
                48.45095691003
              ],
              [
                -5.0824009760553,
                48.448344776816
              ],
              [
                -5.0925866419522,
                48.439891473019
              ],
              [
                -5.1059118332911,
                48.436349765333
              ],
              [
                -5.1091921689816,
                48.443357330074
              ],
              [
                -5.0980583552481,
                48.44608363557
              ],
              [
                -5.0924748133511,
                48.45196258108
              ],
              [
                -5.1016868570136,
                48.455914476174
              ],
              [
                -5.138001239929,
                48.448413926313
              ],
              [
                -5.1331652198509,
                48.457205597014
              ],
              [
                -5.1113600694265,
                48.465174516755
              ],
              [
                -5.1035961538682,
                48.472329242796
              ],
              [
                -5.0840283627307,
                48.472702685549
              ],
              [
                -5.0719207191004,
                48.478208180718
              ],
              [
                -5.0725232805168,
                48.482730316788
              ]
            ]
          ],
          [
            [
              [
                -3.9918110541337,
                48.736399873504
              ],
              [
                -4.0090123106371,
                48.742838902418
              ],
              [
                -4.0221693339987,
                48.738491540178
              ],
              [
                -4.0397770750633,
                48.746526903072
              ],
              [
                -4.0218503360744,
                48.75230874743
              ],
              [
                -4.0062293732672,
                48.752081933672
              ],
              [
                -3.9899103155008,
                48.742601867881
              ],
              [
                -3.9918110541337,
                48.736399873504
              ]
            ]
          ],
          [
            [
              [
                -3.659150584255,
                48.65920940185
              ],
              [
                -3.663669588163,
                48.648820820674
              ],
              [
                -3.6493770993209,
                48.610157007185
              ],
              [
                -3.6387107348493,
                48.610805013827
              ],
              [
                -3.6411110010381,
                48.596790826133
              ],
              [
                -3.6311578917985,
                48.589761536952
              ],
              [
                -3.617602031901,
                48.590178332783
              ],
              [
                -3.5998068640862,
                48.583446233326
              ],
              [
                -3.5944194127755,
                48.585529255716
              ],
              [
                -3.5847566277596,
                48.573455178897
              ],
              [
                -3.5982748260984,
                48.561427228106
              ],
              [
                -3.594593031018,
                48.55498621307
              ],
              [
                -3.569989765754,
                48.556092707748
              ],
              [
                -3.553865623312,
                48.540923162835
              ],
              [
                -3.5392828881973,
                48.538250804163
              ],
              [
                -3.5596958329216,
                48.526460450918
              ],
              [
                -3.5824001459593,
                48.506014419973
              ],
              [
                -3.6040617355353,
                48.496442158126
              ],
              [
                -3.5989528212618,
                48.470508920513
              ],
              [
                -3.5813853723853,
                48.464801291661
              ],
              [
                -3.5688510780452,
                48.452871261149
              ],
              [
                -3.552303441877,
                48.451075213076
              ],
              [
                -3.5582028373806,
                48.442500322496
              ],
              [
                -3.5904183425521,
                48.428924877493
              ],
              [
                -3.6010283126402,
                48.416679458577
              ],
              [
                -3.600205339211,
                48.402479072861
              ],
              [
                -3.6076360061049,
                48.388458748991
              ],
              [
                -3.5835924436067,
                48.382105775396
              ],
              [
                -3.5545851809986,
                48.377763123932
              ],
              [
                -3.5578475183772,
                48.354959103017
              ],
              [
                -3.5630739862519,
                48.348179620709
              ],
              [
                -3.5553956492655,
                48.344976478288
              ],
              [
                -3.5567909718955,
                48.335520660613
              ],
              [
                -3.5483329763946,
                48.312804529458
              ],
              [
                -3.529338883134,
                48.314767041529
              ],
              [
                -3.539440261815,
                48.301793552649
              ],
              [
                -3.5549910668372,
                48.29372122159
              ],
              [
                -3.5184464250967,
                48.282275540295
              ],
              [
                -3.5255275937903,
                48.27000310384
              ],
              [
                -3.522134420826,
                48.255475466911
              ],
              [
                -3.536486648219,
                48.255723915895
              ],
              [
                -3.5419435437528,
                48.245875539987
              ],
              [
                -3.5551186672222,
                48.240436468743
              ],
              [
                -3.5577840037219,
                48.228526452626
              ],
              [
                -3.5382138010087,
                48.20645816321
              ],
              [
                -3.5211761675408,
                48.20222775614
              ],
              [
                -3.5204667499321,
                48.192254183078
              ],
              [
                -3.527570586586,
                48.190355744622
              ],
              [
                -3.5448511661597,
                48.193567566849
              ],
              [
                -3.5665204885688,
                48.189856319348
              ],
              [
                -3.5648444491461,
                48.185747540872
              ],
              [
                -3.5887976018415,
                48.177551526513
              ],
              [
                -3.6139791801575,
                48.173120656414
              ],
              [
                -3.6283770645618,
                48.168722735788
              ],
              [
                -3.6492583398969,
                48.157979020909
              ],
              [
                -3.674318492501,
                48.152007895332
              ],
              [
                -3.6958369472591,
                48.152210034266
              ],
              [
                -3.6976881208724,
                48.145440974014
              ],
              [
                -3.7155085226945,
                48.142222040551
              ],
              [
                -3.7145405932136,
                48.13309642163
              ],
              [
                -3.7239460533949,
                48.124448552469
              ],
              [
                -3.7321436369252,
                48.10908636822
              ],
              [
                -3.7312089824467,
                48.096180843537
              ],
              [
                -3.714330743355,
                48.09867748042
              ],
              [
                -3.6950209143267,
                48.093496452723
              ],
              [
                -3.7045007865138,
                48.084033549136
              ],
              [
                -3.6981708601505,
                48.062309578332
              ],
              [
                -3.681252565867,
                48.059662962291
              ],
              [
                -3.6740316896394,
                48.052542330421
              ],
              [
                -3.6699585843591,
                48.038677972582
              ],
              [
                -3.6630037279929,
                48.032958681418
              ],
              [
                -3.6626106443191,
                48.020081882521
              ],
              [
                -3.648298178547,
                48.00652115334
              ],
              [
                -3.6402383243638,
                47.985243231924
              ],
              [
                -3.6260905858765,
                47.983395998762
              ],
              [
                -3.597022456242,
                47.990376379813
              ],
              [
                -3.5813599037456,
                47.986684519919
              ],
              [
                -3.5713437259039,
                47.990798397019
              ],
              [
                -3.5552770706373,
                47.993097499035
              ],
              [
                -3.5453723043591,
                47.986809935779
              ],
              [
                -3.5301798668878,
                47.986085977382
              ],
              [
                -3.5239545453258,
                47.978418090667
              ],
              [
                -3.5123375160856,
                47.974726350867
              ],
              [
                -3.4974433504273,
                47.976723266584
              ],
              [
                -3.4848075655298,
                47.966893667479
              ],
              [
                -3.4765536705495,
                47.948683440009
              ],
              [
                -3.4627053347206,
                47.948470411684
              ],
              [
                -3.4610225610804,
                47.956353860402
              ],
              [
                -3.4464490225429,
                47.958113761822
              ],
              [
                -3.4198881980685,
                47.969805904784
              ],
              [
                -3.40309336092,
                47.966157747888
              ],
              [
                -3.3926235122244,
                47.954453922013
              ],
              [
                -3.4006612737574,
                47.945673642404
              ],
              [
                -3.3993689255171,
                47.938118221587
              ],
              [
                -3.3880788564101,
                47.929294929579
              ],
              [
                -3.3927592435554,
                47.918966029049
              ],
              [
                -3.4066134343769,
                47.917158573639
              ],
              [
                -3.4094061517392,
                47.910167984279
              ],
              [
                -3.4042654612097,
                47.899284280429
              ],
              [
                -3.4022418579457,
                47.884370909157
              ],
              [
                -3.4034384481233,
                47.870110288463
              ],
              [
                -3.4233565217862,
                47.869037428106
              ],
              [
                -3.4491763130993,
                47.861897696527
              ],
              [
                -3.448891523943,
                47.850730641023
              ],
              [
                -3.4575097495258,
                47.837091361261
              ],
              [
                -3.476393603685,
                47.834031263106
              ],
              [
                -3.488378728186,
                47.823693797324
              ],
              [
                -3.5134134850293,
                47.834523896952
              ],
              [
                -3.5230056259837,
                47.849146782891
              ],
              [
                -3.5396500367876,
                47.836179727509
              ],
              [
                -3.5415735087146,
                47.82278037201
              ],
              [
                -3.5212461653116,
                47.798868078426
              ],
              [
                -3.53051730308,
                47.791502368376
              ],
              [
                -3.5323062304549,
                47.768279004853
              ],
              [
                -3.5386367482063,
                47.762638930067
              ],
              [
                -3.562529511804,
                47.768194082971
              ],
              [
                -3.5791169851307,
                47.767577448995
              ],
              [
                -3.6173276112343,
                47.769506747908
              ],
              [
                -3.6430519534915,
                47.774569329599
              ],
              [
                -3.646811655721,
                47.778818400263
              ],
              [
                -3.670099651884,
                47.782985158502
              ],
              [
                -3.6808379137393,
                47.776793307824
              ],
              [
                -3.7178229109781,
                47.796523695646
              ],
              [
                -3.7218026363467,
                47.803803079831
              ],
              [
                -3.7325477346747,
                47.802927405811
              ],
              [
                -3.7613726155759,
                47.790506218278
              ],
              [
                -3.788240857282,
                47.791328352432
              ],
              [
                -3.8005952117561,
                47.787633600944
              ],
              [
                -3.8206986898906,
                47.797035412496
              ],
              [
                -3.837052356745,
                47.797206603832
              ],
              [
                -3.84572557896,
                47.792371643861
              ],
              [
                -3.8536850991125,
                47.803179888577
              ],
              [
                -3.8653715148318,
                47.809482207573
              ],
              [
                -3.8923444805786,
                47.83181405626
              ],
              [
                -3.9024789053236,
                47.835940597569
              ],
              [
                -3.8953330854301,
                47.851456671771
              ],
              [
                -3.9059288131014,
                47.856053716976
              ],
              [
                -3.9115007019574,
                47.870280163635
              ],
              [
                -3.9291853857822,
                47.870728950257
              ],
              [
                -3.9318150077372,
                47.879930376283
              ],
              [
                -3.9474946202184,
                47.88711050561
              ],
              [
                -3.952255270628,
                47.894973629627
              ],
              [
                -3.9727069922104,
                47.897113244288
              ],
              [
                -3.9900268008062,
                47.88273500359
              ],
              [
                -3.9835079713108,
                47.860854249073
              ],
              [
                -3.9771079749911,
                47.853797355451
              ],
              [
                -4.0255592139411,
                47.850394911361
              ],
              [
                -4.0415835187416,
                47.845999773961
              ],
              [
                -4.0552180992409,
                47.858290390712
              ],
              [
                -4.0777227188397,
                47.86652861959
              ],
              [
                -4.0973424184599,
                47.861927402731
              ],
              [
                -4.1033355392797,
                47.870935392975
              ],
              [
                -4.1175131185531,
                47.876648114661
              ],
              [
                -4.1153436191058,
                47.884957075533
              ],
              [
                -4.1240918215755,
                47.887653785645
              ],
              [
                -4.1350139887829,
                47.899319953681
              ],
              [
                -4.1413175344589,
                47.900932795588
              ],
              [
                -4.1443044504617,
                47.910760442169
              ],
              [
                -4.139164438695,
                47.922460714167
              ],
              [
                -4.1265483456658,
                47.930694608117
              ],
              [
                -4.1181705270283,
                47.930255946671
              ],
              [
                -4.1097870557359,
                47.936359164606
              ],
              [
                -4.1095097330885,
                47.949458350167
              ],
              [
                -4.1003241613862,
                47.951228624348
              ],
              [
                -4.0942945270601,
                47.962343568462
              ],
              [
                -4.1016356245061,
                47.971599102005
              ],
              [
                -4.1154592154278,
                47.951971718394
              ],
              [
                -4.1108035376299,
                47.93643845362
              ],
              [
                -4.116939924733,
                47.931723171212
              ],
              [
                -4.1308490218786,
                47.931268820614
              ],
              [
                -4.1387273378666,
                47.924044327709
              ],
              [
                -4.148590650053,
                47.911784831299
              ],
              [
                -4.1456111634641,
                47.897377929735
              ],
              [
                -4.137658347962,
                47.894582683745
              ],
              [
                -4.1214402638717,
                47.882431174872
              ],
              [
                -4.1220414405413,
                47.877480552794
              ],
              [
                -4.112806039055,
                47.864208198344
              ],
              [
                -4.1260449628811,
                47.86417449959
              ],
              [
                -4.1384513988378,
                47.860372058344
              ],
              [
                -4.1635564220902,
                47.849182582249
              ],
              [
                -4.1751052516171,
                47.875971241102
              ],
              [
                -4.1956480316546,
                47.876129863398
              ],
              [
                -4.1917255768507,
                47.867926519042
              ],
              [
                -4.1985842210014,
                47.859767014899
              ],
              [
                -4.1893682915433,
                47.851394814187
              ],
              [
                -4.1779637170825,
                47.83448054667
              ],
              [
                -4.169637218528,
                47.835983578473
              ],
              [
                -4.1594079263305,
                47.831817813505
              ],
              [
                -4.1665272788102,
                47.81562709526
              ],
              [
                -4.1747961215709,
                47.807720089743
              ],
              [
                -4.1923054697265,
                47.797375930596
              ],
              [
                -4.2102829763554,
                47.801668247082
              ],
              [
                -4.2165941479178,
                47.793145496317
              ],
              [
                -4.2324661582068,
                47.793694342897
              ],
              [
                -4.2692270352914,
                47.79098118891
              ],
              [
                -4.278552033431,
                47.793791055402
              ],
              [
                -4.2908690668838,
                47.792683316384
              ],
              [
                -4.2985421674237,
                47.800459408076
              ],
              [
                -4.3121953465001,
                47.800898472176
              ],
              [
                -4.32359993608,
                47.79785495381
              ],
              [
                -4.3422494923384,
                47.799673907649
              ],
              [
                -4.3624139097032,
                47.795739457012
              ],
              [
                -4.3740484540088,
                47.798476085782
              ],
              [
                -4.3716114594948,
                47.80777876035
              ],
              [
                -4.3792556925789,
                47.822050720065
              ],
              [
                -4.3498041232485,
                47.83078661588
              ],
              [
                -4.3461614463077,
                47.83907388297
              ],
              [
                -4.3476870805612,
                47.849005072333
              ],
              [
                -4.3600577158752,
                47.879146436114
              ],
              [
                -4.362571617849,
                47.889266575343
              ],
              [
                -4.3790334776921,
                47.914084532462
              ],
              [
                -4.395188435554,
                47.934409652937
              ],
              [
                -4.4098520632839,
                47.946650557322
              ],
              [
                -4.4246099459353,
                47.963758867166
              ],
              [
                -4.453576704456,
                47.981777636275
              ],
              [
                -4.4746789421278,
                47.985624949661
              ],
              [
                -4.4826653312528,
                47.993824490611
              ],
              [
                -4.4980779158615,
                48.001183040607
              ],
              [
                -4.5148540490952,
                48.006067730047
              ],
              [
                -4.5256147363767,
                48.006067667043
              ],
              [
                -4.5368798410152,
                48.012491630575
              ],
              [
                -4.550096166671,
                48.011713275225
              ],
              [
                -4.5647501712087,
                47.999835676162
              ],
              [
                -4.5907349278799,
                48.006794478828
              ],
              [
                -4.597265482557,
                48.014881869407
              ],
              [
                -4.6171479742083,
                48.016679284353
              ],
              [
                -4.6329587586753,
                48.029369004938
              ],
              [
                -4.6460824443153,
                48.023304150678
              ],
              [
                -4.6997885692117,
                48.027891769892
              ],
              [
                -4.7105952929249,
                48.033199609507
              ],
              [
                -4.7230336277547,
                48.033599672317
              ],
              [
                -4.7319575291791,
                48.040893238655
              ],
              [
                -4.7124639734808,
                48.042115186074
              ],
              [
                -4.7068549694099,
                48.04999288348
              ],
              [
                -4.7163398904359,
                48.062467036521
              ],
              [
                -4.6737632606973,
                48.060829548571
              ],
              [
                -4.6682276450746,
                48.070200865274
              ],
              [
                -4.6329864019536,
                48.067656900838
              ],
              [
                -4.6212551186766,
                48.06856975321
              ],
              [
                -4.6055567322678,
                48.075144649057
              ],
              [
                -4.5906529113319,
                48.077103152291
              ],
              [
                -4.580146507137,
                48.081908477448
              ],
              [
                -4.5536216450504,
                48.077132543168
              ],
              [
                -4.545646174971,
                48.07845960521
              ],
              [
                -4.5400399621897,
                48.087861184127
              ],
              [
                -4.5144760304168,
                48.088898642776
              ],
              [
                -4.4885983026016,
                48.086473104323
              ],
              [
                -4.4666979200231,
                48.099143817185
              ],
              [
                -4.4569858789134,
                48.096458543946
              ],
              [
                -4.4350684487573,
                48.097322911408
              ],
              [
                -4.4102497059559,
                48.106021543169
              ],
              [
                -4.3936094931243,
                48.10445280582
              ],
              [
                -4.3743959355115,
                48.110158765816
              ],
              [
                -4.3635604323207,
                48.109200301918
              ],
              [
                -4.3549482349925,
                48.10371581043
              ],
              [
                -4.3069930438711,
                48.089185950308
              ],
              [
                -4.2969336649841,
                48.092193155485
              ],
              [
                -4.2842966027835,
                48.107888922651
              ],
              [
                -4.2815394753097,
                48.115546749849
              ],
              [
                -4.2837291765835,
                48.126894213088
              ],
              [
                -4.2717328773485,
                48.133054000516
              ],
              [
                -4.2730799332603,
                48.154184060675
              ],
              [
                -4.2928384902475,
                48.157399085257
              ],
              [
                -4.2962454405426,
                48.165147430098
              ],
              [
                -4.2920632378488,
                48.176348134139
              ],
              [
                -4.3011254537871,
                48.191446905092
              ],
              [
                -4.3154011317845,
                48.201590197777
              ],
              [
                -4.3320245514115,
                48.206252420086
              ],
              [
                -4.3674265719653,
                48.205181125133
              ],
              [
                -4.3764153744941,
                48.217120653017
              ],
              [
                -4.4112371701961,
                48.222749021747
              ],
              [
                -4.4255159659909,
                48.229011423558
              ],
              [
                -4.4317441689243,
                48.228335889789
              ],
              [
                -4.4483574330506,
                48.23563427883
              ],
              [
                -4.4890285598601,
                48.236209131048
              ],
              [
                -4.5016483626877,
                48.230419925672
              ],
              [
                -4.4973006895694,
                48.216773771782
              ],
              [
                -4.5107514965186,
                48.211453602973
              ],
              [
                -4.5193408676193,
                48.192342464231
              ],
              [
                -4.5401709706587,
                48.180690252545
              ],
              [
                -4.5396785660795,
                48.170248926865
              ],
              [
                -4.5541303580757,
                48.16777550723
              ],
              [
                -4.5539161923019,
                48.178980950953
              ],
              [
                -4.5496466530638,
                48.194260085865
              ],
              [
                -4.5534516878496,
                48.211909181419
              ],
              [
                -4.5640287889751,
                48.2322275804
              ],
              [
                -4.5542366911133,
                48.23943709268
              ],
              [
                -4.5445091004672,
                48.241191020822
              ],
              [
                -4.5438454031052,
                48.24868513629
              ],
              [
                -4.5581833509587,
                48.259044482063
              ],
              [
                -4.5637622836517,
                48.255323260533
              ],
              [
                -4.583579355652,
                48.25247718648
              ],
              [
                -4.619781824787,
                48.263922349139
              ],
              [
                -4.6151754712281,
                48.272608618322
              ],
              [
                -4.6191913673877,
                48.277687013901
              ],
              [
                -4.6010351660969,
                48.283328561972
              ],
              [
                -4.5920264733919,
                48.274941952167
              ],
              [
                -4.580510315487,
                48.276821723646
              ],
              [
                -4.5666092529207,
                48.286340243106
              ],
              [
                -4.5722318933548,
                48.298194529125
              ],
              [
                -4.5710033055979,
                48.305488131525
              ],
              [
                -4.5793535371685,
                48.317985114981
              ],
              [
                -4.5711366097909,
                48.33004851178
              ],
              [
                -4.5554096260061,
                48.33827322458
              ],
              [
                -4.534438235185,
                48.341855622806
              ],
              [
                -4.5323989547456,
                48.339322193963
              ],
              [
                -4.5453284966877,
                48.324530697141
              ],
              [
                -4.5483564279774,
                48.309091985071
              ],
              [
                -4.5562724762849,
                48.299755550421
              ],
              [
                -4.5526899421663,
                48.294333094321
              ],
              [
                -4.5409637873526,
                48.290218695274
              ],
              [
                -4.5357701963006,
                48.283943449529
              ],
              [
                -4.5217946962434,
                48.291114428525
              ],
              [
                -4.5076962523209,
                48.310708704249
              ],
              [
                -4.5010461586171,
                48.306105555944
              ],
              [
                -4.5129990628384,
                48.297069816261
              ],
              [
                -4.5087664864786,
                48.284364971679
              ],
              [
                -4.5026015309363,
                48.280860913221
              ],
              [
                -4.4835588084111,
                48.284044560152
              ],
              [
                -4.4550522136348,
                48.292771537786
              ],
              [
                -4.4237773421087,
                48.291670034681
              ],
              [
                -4.4121223635074,
                48.277431878755
              ],
              [
                -4.4068502675656,
                48.275442332471
              ],
              [
                -4.3844939336359,
                48.275154271566
              ],
              [
                -4.3660839325116,
                48.278010699726
              ],
              [
                -4.3536674198016,
                48.285793291692
              ],
              [
                -4.3380117997035,
                48.285592492129
              ],
              [
                -4.3080337336786,
                48.297163385068
              ],
              [
                -4.2716752810253,
                48.295919896917
              ],
              [
                -4.2739347422518,
                48.288971383308
              ],
              [
                -4.2845412149644,
                48.287650160728
              ],
              [
                -4.2848570831774,
                48.275913907112
              ],
              [
                -4.2595853011287,
                48.266201814358
              ],
              [
                -4.2459294480179,
                48.250780318991
              ],
              [
                -4.2317540680499,
                48.250320630547
              ],
              [
                -4.2172932355759,
                48.257615458861
              ],
              [
                -4.2080204243049,
                48.242451425248
              ],
              [
                -4.1866606053631,
                48.246536490853
              ],
              [
                -4.1970338862768,
                48.25110791887
              ],
              [
                -4.2079834304869,
                48.251025418009
              ],
              [
                -4.2167166565259,
                48.260995596762
              ],
              [
                -4.2406571035438,
                48.254405816531
              ],
              [
                -4.2484420972136,
                48.264631942186
              ],
              [
                -4.2794340183855,
                48.277816418923
              ],
              [
                -4.2586639746308,
                48.280337796407
              ],
              [
                -4.2339831734409,
                48.291663390615
              ],
              [
                -4.2255916464459,
                48.287775626172
              ],
              [
                -4.1940779875676,
                48.293672058984
              ],
              [
                -4.1927914320833,
                48.297566770216
              ],
              [
                -4.2230912040754,
                48.296296136643
              ],
              [
                -4.2607589991265,
                48.308808886841
              ],
              [
                -4.2717767417457,
                48.3085010698
              ],
              [
                -4.2817306872962,
                48.314149062808
              ],
              [
                -4.2931983397824,
                48.311355653833
              ],
              [
                -4.3065317325636,
                48.314942350033
              ],
              [
                -4.3311785781541,
                48.314620491654
              ],
              [
                -4.3098044941465,
                48.327389890558
              ],
              [
                -4.3054717563496,
                48.333012617327
              ],
              [
                -4.2909479256962,
                48.341952755975
              ],
              [
                -4.2780004139394,
                48.344874608976
              ],
              [
                -4.2976741907114,
                48.355911039667
              ],
              [
                -4.3050740561368,
                48.354482380706
              ],
              [
                -4.3021566253156,
                48.34377489677
              ],
              [
                -4.3293983637326,
                48.340226102871
              ],
              [
                -4.3353494088236,
                48.351018718927
              ],
              [
                -4.3446175802594,
                48.343247421931
              ],
              [
                -4.36648632172,
                48.344799621581
              ],
              [
                -4.3678442897223,
                48.334697246192
              ],
              [
                -4.375252431501,
                48.326306182232
              ],
              [
                -4.4046736898418,
                48.323603347031
              ],
              [
                -4.4163170599611,
                48.324328314613
              ],
              [
                -4.4212814557887,
                48.332856308545
              ],
              [
                -4.4474594889754,
                48.325680817565
              ],
              [
                -4.4496265161822,
                48.337161490176
              ],
              [
                -4.4395482007447,
                48.341279779512
              ],
              [
                -4.4417567491006,
                48.353615394797
              ],
              [
                -4.4290271772091,
                48.365619656485
              ],
              [
                -4.4186091872326,
                48.368726808201
              ],
              [
                -4.4075253481222,
                48.380534385031
              ],
              [
                -4.3961298593091,
                48.38650177881
              ],
              [
                -4.3680405113101,
                48.39489925028
              ],
              [
                -4.3593237104943,
                48.402144960849
              ],
              [
                -4.3428458694475,
                48.403365443241
              ],
              [
                -4.3093501979999,
                48.419403653203
              ],
              [
                -4.2819732297193,
                48.434393207398
              ],
              [
                -4.287872061805,
                48.436420644162
              ],
              [
                -4.3094694139567,
                48.423596726474
              ],
              [
                -4.3381817157191,
                48.414949405527
              ],
              [
                -4.3459483488972,
                48.409663044293
              ],
              [
                -4.3686013125106,
                48.40664262085
              ],
              [
                -4.3688602316922,
                48.401485058956
              ],
              [
                -4.386795431773,
                48.397755391732
              ],
              [
                -4.4029442647564,
                48.390018662486
              ],
              [
                -4.4249472968664,
                48.397600203449
              ],
              [
                -4.4333536489875,
                48.396593913062
              ],
              [
                -4.4352859423298,
                48.383389050061
              ],
              [
                -4.4427532805509,
                48.381948489599
              ],
              [
                -4.4563561684304,
                48.387015703714
              ],
              [
                -4.4672858436945,
                48.381010080426
              ],
              [
                -4.4818709168706,
                48.382521354469
              ],
              [
                -4.5070284331619,
                48.375177195361
              ],
              [
                -4.5380169478944,
                48.357124862764
              ],
              [
                -4.5496694490256,
                48.362001489858
              ],
              [
                -4.556901556146,
                48.356967518117
              ],
              [
                -4.5949382473261,
                48.34398230697
              ],
              [
                -4.6087240373586,
                48.337888380763
              ],
              [
                -4.6281010576136,
                48.337509419286
              ],
              [
                -4.6392496162242,
                48.34493297515
              ],
              [
                -4.6542388141476,
                48.346681343342
              ],
              [
                -4.6671754786758,
                48.352722054618
              ],
              [
                -4.6805572523649,
                48.355611191009
              ],
              [
                -4.6994701606877,
                48.350825211906
              ],
              [
                -4.7031861104856,
                48.345439143685
              ],
              [
                -4.6984198568155,
                48.339008917129
              ],
              [
                -4.7076075032169,
                48.33225949433
              ],
              [
                -4.7268279563533,
                48.330101611584
              ],
              [
                -4.7412649297657,
                48.331137342157
              ],
              [
                -4.755151556882,
                48.328314605498
              ],
              [
                -4.7723051357322,
                48.3291788831
              ],
              [
                -4.7713352334093,
                48.348321487205
              ],
              [
                -4.7807548157661,
                48.358363845243
              ],
              [
                -4.7609158756256,
                48.372752574444
              ],
              [
                -4.7744540702471,
                48.389384734113
              ],
              [
                -4.7736837961353,
                48.404922524224
              ],
              [
                -4.7856134234263,
                48.409183783259
              ],
              [
                -4.7933430625428,
                48.416235956794
              ],
              [
                -4.7829693643734,
                48.437550153141
              ],
              [
                -4.7762887997551,
                48.44323010222
              ],
              [
                -4.7783033027269,
                48.449337151566
              ],
              [
                -4.7691821477998,
                48.462065352166
              ],
              [
                -4.7590583232069,
                48.470397966682
              ],
              [
                -4.7754782499743,
                48.491000702902
              ],
              [
                -4.7769978959372,
                48.503117217189
              ],
              [
                -4.7669531385337,
                48.508949694964
              ],
              [
                -4.7677505482026,
                48.51549084169
              ],
              [
                -4.7579904988592,
                48.520934810502
              ],
              [
                -4.7635828540663,
                48.531059613438
              ],
              [
                -4.750430701927,
                48.531787625414
              ],
              [
                -4.7505716364016,
                48.543395532423
              ],
              [
                -4.7369401487605,
                48.549669633203
              ],
              [
                -4.730581474956,
                48.556345629552
              ],
              [
                -4.7073251262934,
                48.554495469139
              ],
              [
                -4.7006100061104,
                48.565257293095
              ],
              [
                -4.6897329488655,
                48.571618302905
              ],
              [
                -4.6751613492295,
                48.568639591535
              ],
              [
                -4.6464232548783,
                48.572118967627
              ],
              [
                -4.6306065814165,
                48.577693340766
              ],
              [
                -4.5960814216726,
                48.570438077138
              ],
              [
                -4.5920294451056,
                48.55696728141
              ],
              [
                -4.5801834734014,
                48.562246351219
              ],
              [
                -4.5940875669374,
                48.574670422845
              ],
              [
                -4.6091605495172,
                48.575417985689
              ],
              [
                -4.6036696454798,
                48.58297905273
              ],
              [
                -4.6076603107424,
                48.596461551968
              ],
              [
                -4.5983162877184,
                48.608011192206
              ],
              [
                -4.5856196284358,
                48.601472677829
              ],
              [
                -4.5907770172397,
                48.594023648193
              ],
              [
                -4.5627715925875,
                48.598170219165
              ],
              [
                -4.5565167429014,
                48.594799473824
              ],
              [
                -4.5455036785654,
                48.59783340549
              ],
              [
                -4.550782271051,
                48.604094317015
              ],
              [
                -4.5608700909489,
                48.603771154268
              ],
              [
                -4.5686991918772,
                48.611035547029
              ],
              [
                -4.5614793437335,
                48.623801490681
              ],
              [
                -4.5503434319799,
                48.625018824991
              ],
              [
                -4.5395490492214,
                48.631187796313
              ],
              [
                -4.5261586212295,
                48.629376007091
              ],
              [
                -4.5217973483906,
                48.634384422626
              ],
              [
                -4.5018039011118,
                48.621186293816
              ],
              [
                -4.4931187647023,
                48.625414896892
              ],
              [
                -4.4782291949973,
                48.623557861673
              ],
              [
                -4.4627692950062,
                48.627100958455
              ],
              [
                -4.4469441913165,
                48.638394323748
              ],
              [
                -4.4149997798362,
                48.637926090365
              ],
              [
                -4.4271033577354,
                48.652260777339
              ],
              [
                -4.3980441994007,
                48.65561478084
              ],
              [
                -4.3801425974664,
                48.661666859925
              ],
              [
                -4.3514034206459,
                48.676113294185
              ],
              [
                -4.3303269284446,
                48.67546160978
              ],
              [
                -4.3291124062469,
                48.667907661004
              ],
              [
                -4.3102390806509,
                48.667931594289
              ],
              [
                -4.2954040446046,
                48.660446563113
              ],
              [
                -4.3160685361226,
                48.640188688849
              ],
              [
                -4.3115885562964,
                48.635172589811
              ],
              [
                -4.298511346251,
                48.632640665789
              ],
              [
                -4.2727657939881,
                48.649579657653
              ],
              [
                -4.2464935655648,
                48.649709559996
              ],
              [
                -4.2333860393386,
                48.652097867975
              ],
              [
                -4.2236435014343,
                48.648213897066
              ],
              [
                -4.1945007324424,
                48.652175738687
              ],
              [
                -4.1987860401536,
                48.655717939248
              ],
              [
                -4.2202278931,
                48.656147005163
              ],
              [
                -4.2096068963467,
                48.670447739532
              ],
              [
                -4.1968161610499,
                48.677628282862
              ],
              [
                -4.1869344610679,
                48.686462205394
              ],
              [
                -4.174061395128,
                48.686024655247
              ],
              [
                -4.1624191936583,
                48.689758295612
              ],
              [
                -4.1270367426342,
                48.694927216255
              ],
              [
                -4.0837978480658,
                48.692972814592
              ],
              [
                -4.0709024391013,
                48.684670504996
              ],
              [
                -4.0575875391344,
                48.689258711554
              ],
              [
                -4.05679529974,
                48.700628181133
              ],
              [
                -4.0429161757203,
                48.70265342713
              ],
              [
                -4.0353886405866,
                48.713928688184
              ],
              [
                -4.02495529368,
                48.710629584547
              ],
              [
                -4.0124824530698,
                48.714491395544
              ],
              [
                -4.0005364915392,
                48.711631523299
              ],
              [
                -3.9834371521249,
                48.726305142899
              ],
              [
                -3.9668077805805,
                48.71962530493
              ],
              [
                -3.9735238414907,
                48.71002461021
              ],
              [
                -3.9757432893007,
                48.699031896983
              ],
              [
                -3.9682004772062,
                48.689973835843
              ],
              [
                -3.9691581056971,
                48.676107684525
              ],
              [
                -3.9531383099577,
                48.672136886316
              ],
              [
                -3.9494659266738,
                48.652861005926
              ],
              [
                -3.9300674451266,
                48.657839171964
              ],
              [
                -3.9211812871032,
                48.674928420785
              ],
              [
                -3.9114347331104,
                48.669960338823
              ],
              [
                -3.8911113303248,
                48.670896023532
              ],
              [
                -3.8925732849232,
                48.666174874576
              ],
              [
                -3.9061966723813,
                48.660822813734
              ],
              [
                -3.8977104457356,
                48.646355840782
              ],
              [
                -3.8895679902243,
                48.641662476434
              ],
              [
                -3.8607964112541,
                48.632586859442
              ],
              [
                -3.8566651166084,
                48.62062982347
              ],
              [
                -3.845545208747,
                48.626964725465
              ],
              [
                -3.856272170722,
                48.650755902177
              ],
              [
                -3.8564327756426,
                48.660929312176
              ],
              [
                -3.8644147711301,
                48.670330901442
              ],
              [
                -3.8466397296643,
                48.673108945878
              ],
              [
                -3.854473660269,
                48.686373923346
              ],
              [
                -3.8482610894395,
                48.695947065984
              ],
              [
                -3.8323743274237,
                48.711935659748
              ],
              [
                -3.8207715463903,
                48.701352197337
              ],
              [
                -3.8189371050949,
                48.715169992971
              ],
              [
                -3.8056106339945,
                48.711019729093
              ],
              [
                -3.7874929573455,
                48.701796106506
              ],
              [
                -3.7761094548232,
                48.702861922085
              ],
              [
                -3.7657078018764,
                48.708879271701
              ],
              [
                -3.733246519616,
                48.707417936626
              ],
              [
                -3.7169828876846,
                48.702516263423
              ],
              [
                -3.7024279161749,
                48.690384977451
              ],
              [
                -3.6884703928375,
                48.691672302665
              ],
              [
                -3.678008056564,
                48.6868131946
              ],
              [
                -3.6609393047034,
                48.694072404612
              ],
              [
                -3.6451676792066,
                48.690710877885
              ],
              [
                -3.6547881028975,
                48.682400406377
              ],
              [
                -3.6427952345386,
                48.672116059351
              ],
              [
                -3.6554581627922,
                48.664723869854
              ],
              [
                -3.659150584255,
                48.65920940185
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "29",
        "nom": "Finistère",
        "population": 917179,
        "delitspour1000": "3,571",
        "nombremosque": 7,
        "parentsEtrangers": "85,9",
        "benefRsa": 28868,
        "eoliennes": 97
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3.3736480685005,
              44.170759472387
            ],
            [
              3.3916296064038,
              44.168932587934
            ],
            [
              3.4149305042071,
              44.153882151382
            ],
            [
              3.430828214856,
              44.148000042739
            ],
            [
              3.433512295062,
              44.134421741262
            ],
            [
              3.4397273942713,
              44.129098275979
            ],
            [
              3.4545515961192,
              44.126879446041
            ],
            [
              3.4713734276297,
              44.12716393223
            ],
            [
              3.481403424764,
              44.124002502392
            ],
            [
              3.5058482132245,
              44.126278514771
            ],
            [
              3.5231681435817,
              44.120516869251
            ],
            [
              3.5408020405971,
              44.118578938319
            ],
            [
              3.5464861213512,
              44.113818175271
            ],
            [
              3.5683752088908,
              44.122559965675
            ],
            [
              3.595181405875,
              44.119266524352
            ],
            [
              3.6073988183382,
              44.116029433901
            ],
            [
              3.6334911540563,
              44.122696230536
            ],
            [
              3.6358667337752,
              44.140266546314
            ],
            [
              3.6526725494152,
              44.145568354025
            ],
            [
              3.6278241155281,
              44.155028504469
            ],
            [
              3.6377741334314,
              44.175485314981
            ],
            [
              3.6496443307415,
              44.180846928062
            ],
            [
              3.6577912111121,
              44.173321183089
            ],
            [
              3.6786431948201,
              44.182760565003
            ],
            [
              3.6925750340144,
              44.171159275216
            ],
            [
              3.7025563945864,
              44.166942701712
            ],
            [
              3.7256274073379,
              44.164242504262
            ],
            [
              3.7349559744215,
              44.157010128066
            ],
            [
              3.7523693979032,
              44.150544498075
            ],
            [
              3.7622230201904,
              44.149516940162
            ],
            [
              3.7679675562321,
              44.14157393487
            ],
            [
              3.7756676373257,
              44.141877075928
            ],
            [
              3.7848382493422,
              44.133250032869
            ],
            [
              3.7969975612425,
              44.127385461306
            ],
            [
              3.8126533543601,
              44.128802494079
            ],
            [
              3.8223990436954,
              44.136197092175
            ],
            [
              3.8314321427191,
              44.137342309599
            ],
            [
              3.8621011695486,
              44.130069281932
            ],
            [
              3.8775632202183,
              44.132861161031
            ],
            [
              3.8948926937749,
              44.147795357416
            ],
            [
              3.9109075704101,
              44.150587101491
            ],
            [
              3.9119737331675,
              44.160158300398
            ],
            [
              3.927270718019,
              44.161294730529
            ],
            [
              3.9277400304228,
              44.171738630603
            ],
            [
              3.9219574771069,
              44.180320696004
            ],
            [
              3.9294485267929,
              44.181839944624
            ],
            [
              3.9526032918757,
              44.170797336987
            ],
            [
              3.9743738242883,
              44.164502268256
            ],
            [
              3.9765983159501,
              44.170098473016
            ],
            [
              3.964288789173,
              44.177410315113
            ],
            [
              3.9598822674885,
              44.191253623903
            ],
            [
              3.9370729983754,
              44.195595947902
            ],
            [
              3.9429654923739,
              44.203253484142
            ],
            [
              3.950158512355,
              44.223544971563
            ],
            [
              3.9461669523151,
              44.240169534193
            ],
            [
              3.9513133379367,
              44.247792762307
            ],
            [
              3.9719073243896,
              44.251641683621
            ],
            [
              3.9746483176338,
              44.260288859328
            ],
            [
              3.956794913201,
              44.271797114433
            ],
            [
              3.9454508454646,
              44.266865065977
            ],
            [
              3.9355738846832,
              44.274744298137
            ],
            [
              3.9398232455058,
              44.283334544
            ],
            [
              3.9362528722946,
              44.293490372509
            ],
            [
              3.9228602111024,
              44.30522063075
            ],
            [
              3.9402460617446,
              44.315418456243
            ],
            [
              3.9523300614322,
              44.328390007654
            ],
            [
              3.9491210893572,
              44.334921777321
            ],
            [
              3.9368786663002,
              44.33744108778
            ],
            [
              3.9281697506954,
              44.343854209304
            ],
            [
              3.9168436390416,
              44.359217742316
            ],
            [
              3.9110062523363,
              44.370599102941
            ],
            [
              3.8923994006905,
              44.381522694523
            ],
            [
              3.8859181180291,
              44.387999952482
            ],
            [
              3.9003564793452,
              44.392571240909
            ],
            [
              3.9079176676789,
              44.402622394702
            ],
            [
              3.928309972795,
              44.405246750327
            ],
            [
              3.9443586109158,
              44.399571136256
            ],
            [
              3.9512054176571,
              44.402087805603
            ],
            [
              3.9487327132043,
              44.412567267488
            ],
            [
              3.9611465990867,
              44.41396666902
            ],
            [
              3.9717196395416,
              44.426330703971
            ],
            [
              3.9723404746948,
              44.435190055138
            ],
            [
              3.9803685381006,
              44.445439528201
            ],
            [
              3.9981617468281,
              44.459798467391
            ],
            [
              4.0149839677909,
              44.454514219975
            ],
            [
              4.0232058055546,
              44.445573236432
            ],
            [
              4.0375991394785,
              44.445719563488
            ],
            [
              4.0464583808932,
              44.432710617884
            ],
            [
              4.0354823981467,
              44.420090870159
            ],
            [
              4.0561040289114,
              44.414335993335
            ],
            [
              4.0684449498584,
              44.405111736232
            ],
            [
              4.0572129135807,
              44.393348439495
            ],
            [
              4.042468868612,
              44.39464402593
            ],
            [
              4.0437442732688,
              44.384550041392
            ],
            [
              4.0529853320038,
              44.378694159984
            ],
            [
              4.055527118005,
              44.365626171449
            ],
            [
              4.0531535999561,
              44.340968920772
            ],
            [
              4.0450038312264,
              44.333082309228
            ],
            [
              4.0368667860025,
              44.330771008666
            ],
            [
              4.0514566440182,
              44.317321809072
            ],
            [
              4.0716270663138,
              44.327298770679
            ],
            [
              4.1039911697566,
              44.333899706452
            ],
            [
              4.1125125628157,
              44.329490022147
            ],
            [
              4.1267455749949,
              44.337730884113
            ],
            [
              4.1403038447089,
              44.327883881292
            ],
            [
              4.1428714788751,
              44.313351716383
            ],
            [
              4.1548869300058,
              44.31262212801
            ],
            [
              4.1777450676527,
              44.317879259785
            ],
            [
              4.1867071328058,
              44.29968541211
            ],
            [
              4.2164426737394,
              44.288852848267
            ],
            [
              4.2413235022379,
              44.270096446791
            ],
            [
              4.2588499153827,
              44.264783733394
            ],
            [
              4.2766179866697,
              44.274252680812
            ],
            [
              4.2894096266193,
              44.293214668623
            ],
            [
              4.2884228053033,
              44.31465771441
            ],
            [
              4.3044280048901,
              44.315234251029
            ],
            [
              4.3217239441113,
              44.323989000128
            ],
            [
              4.3360706324228,
              44.339519174094
            ],
            [
              4.3667775642003,
              44.339497579716
            ],
            [
              4.3865260888309,
              44.346613937013
            ],
            [
              4.3941986915176,
              44.345264673242
            ],
            [
              4.4032085466386,
              44.333895573125
            ],
            [
              4.3907812808888,
              44.303051306396
            ],
            [
              4.3934072251798,
              44.293647114857
            ],
            [
              4.3989780395897,
              44.288943564372
            ],
            [
              4.4218910835178,
              44.287324346637
            ],
            [
              4.440502356745,
              44.284053476816
            ],
            [
              4.4506900513602,
              44.297286859459
            ],
            [
              4.4492717103226,
              44.304914782152
            ],
            [
              4.4508857895897,
              44.33261714008
            ],
            [
              4.4576163712243,
              44.341635623893
            ],
            [
              4.4761161664256,
              44.34074524217
            ],
            [
              4.484186331178,
              44.337581914669
            ],
            [
              4.503538697272,
              44.340187516986
            ],
            [
              4.5178637257565,
              44.329927083074
            ],
            [
              4.5326208269687,
              44.32252533633
            ],
            [
              4.5444682300368,
              44.320766940178
            ],
            [
              4.5586960480886,
              44.302442886009
            ],
            [
              4.574187229155,
              44.300751054654
            ],
            [
              4.5869814679806,
              44.29459622458
            ],
            [
              4.6068181251102,
              44.290471804776
            ],
            [
              4.6181459353598,
              44.278542099268
            ],
            [
              4.6288231783395,
              44.284004141308
            ],
            [
              4.6380600403344,
              44.282964739036
            ],
            [
              4.641962943496,
              44.273967232879
            ],
            [
              4.649227423465,
              44.270359808636
            ],
            [
              4.6540654761357,
              44.254338013491
            ],
            [
              4.6748406183712,
              44.238546852911
            ],
            [
              4.6784293717396,
              44.229429565698
            ],
            [
              4.6726658505102,
              44.216806161879
            ],
            [
              4.6836408656973,
              44.21242318348
            ],
            [
              4.7018165185921,
              44.216070926665
            ],
            [
              4.7097117846241,
              44.206925388326
            ],
            [
              4.7038951813692,
              44.197612213118
            ],
            [
              4.7220708851492,
              44.18742070852
            ],
            [
              4.716116694835,
              44.165974548714
            ],
            [
              4.7188928038989,
              44.14449718833
            ],
            [
              4.714318820751,
              44.137803248451
            ],
            [
              4.7070128409472,
              44.113682146579
            ],
            [
              4.7074595244331,
              44.103669745076
            ],
            [
              4.7196389371655,
              44.086665255194
            ],
            [
              4.7301353625795,
              44.079023395797
            ],
            [
              4.7548761078409,
              44.088309298713
            ],
            [
              4.7608553902053,
              44.08629669027
            ],
            [
              4.7578505502116,
              44.077246976599
            ],
            [
              4.7879793576032,
              44.065056776494
            ],
            [
              4.7891182892738,
              44.052194281428
            ],
            [
              4.8080065523425,
              44.03972336512
            ],
            [
              4.8161675560385,
              44.032144996975
            ],
            [
              4.8211405354756,
              44.016469245528
            ],
            [
              4.8376764645108,
              44.014939590778
            ],
            [
              4.8433326670726,
              44.009857987382
            ],
            [
              4.8455501032842,
              43.99667711759
            ],
            [
              4.8421001716407,
              43.98647354378
            ],
            [
              4.8319955437626,
              43.985260560051
            ],
            [
              4.8159834779277,
              43.988799971648
            ],
            [
              4.8101475881401,
              43.97704355585
            ],
            [
              4.8150547522655,
              43.967622474105
            ],
            [
              4.8079998756015,
              43.960029108371
            ],
            [
              4.7971529885585,
              43.956718817037
            ],
            [
              4.7790210940203,
              43.937894495052
            ],
            [
              4.7687787417118,
              43.933801125308
            ],
            [
              4.7499895249126,
              43.932181836453
            ],
            [
              4.7390596940896,
              43.92406219253
            ],
            [
              4.740099224056,
              43.919678920657
            ],
            [
              4.7233684641772,
              43.905955710295
            ],
            [
              4.7037984091552,
              43.899340665402
            ],
            [
              4.7074943864756,
              43.895550919044
            ],
            [
              4.6905462810021,
              43.883898624023
            ],
            [
              4.6654377200626,
              43.875147404443
            ],
            [
              4.6566482355099,
              43.874778903832
            ],
            [
              4.6419145398807,
              43.867476572594
            ],
            [
              4.6480099815874,
              43.851229485733
            ],
            [
              4.6586664103492,
              43.853223756034
            ],
            [
              4.6662999036899,
              43.844776915402
            ],
            [
              4.6501517057694,
              43.839124011369
            ],
            [
              4.6424185010062,
              43.831436886337
            ],
            [
              4.6522767209675,
              43.816837884851
            ],
            [
              4.6548263114857,
              43.806405017468
            ],
            [
              4.6512435847762,
              43.782275197677
            ],
            [
              4.628818965927,
              43.759861933174
            ],
            [
              4.6227171190074,
              43.743237369574
            ],
            [
              4.6136350841342,
              43.729822310127
            ],
            [
              4.6130400270515,
              43.714296691071
            ],
            [
              4.6276618120935,
              43.690542678491
            ],
            [
              4.605829920983,
              43.686077262962
            ],
            [
              4.5930346527266,
              43.68745966779
            ],
            [
              4.5818853309897,
              43.696368547688
            ],
            [
              4.5527787964313,
              43.701311173254
            ],
            [
              4.5398466434949,
              43.707275616451
            ],
            [
              4.5243901803315,
              43.702131030559
            ],
            [
              4.503671690096,
              43.702259072675
            ],
            [
              4.4872344746833,
              43.699241399194
            ],
            [
              4.4756623542909,
              43.684463819817
            ],
            [
              4.4754954502059,
              43.671096153803
            ],
            [
              4.4559475264435,
              43.667415945459
            ],
            [
              4.4475788905709,
              43.659307469452
            ],
            [
              4.4382159894455,
              43.644374718144
            ],
            [
              4.4270341666761,
              43.620674791673
            ],
            [
              4.4397478336794,
              43.610672750672
            ],
            [
              4.4545256952964,
              43.611392063023
            ],
            [
              4.4668068635081,
              43.615205555036
            ],
            [
              4.4752352404423,
              43.60829610571
            ],
            [
              4.4606813332323,
              43.589260601197
            ],
            [
              4.4433120956209,
              43.583144847291
            ],
            [
              4.4255399426089,
              43.585225258609
            ],
            [
              4.404416776755,
              43.574366053053
            ],
            [
              4.4153195295637,
              43.572538108822
            ],
            [
              4.4093533628433,
              43.561126852889
            ],
            [
              4.3870870214048,
              43.560476830234
            ],
            [
              4.3806768784461,
              43.55254245629
            ],
            [
              4.3651362956496,
              43.547157120782
            ],
            [
              4.3536437981366,
              43.547390132754
            ],
            [
              4.3341326001686,
              43.535451246643
            ],
            [
              4.3164310144209,
              43.544330277799
            ],
            [
              4.3095227640281,
              43.543279128578
            ],
            [
              4.3227085568643,
              43.530446651372
            ],
            [
              4.3204919293874,
              43.525482210334
            ],
            [
              4.3051324465276,
              43.520794514252
            ],
            [
              4.2976898160141,
              43.515329111265
            ],
            [
              4.271416895472,
              43.508069960206
            ],
            [
              4.2586419540305,
              43.507538672405
            ],
            [
              4.2381030309066,
              43.497625146899
            ],
            [
              4.2311705681976,
              43.47675937846
            ],
            [
              4.2302808850321,
              43.460183661653
            ],
            [
              4.1938625454583,
              43.465779027827
            ],
            [
              4.1656196762668,
              43.471915156827
            ],
            [
              4.137670466646,
              43.481949459061
            ],
            [
              4.1231397009828,
              43.494201235057
            ],
            [
              4.1165892511326,
              43.508710525509
            ],
            [
              4.1298746993328,
              43.522765554616
            ],
            [
              4.1382579687386,
              43.526105311289
            ],
            [
              4.1391197547669,
              43.531898260949
            ],
            [
              4.12162781658,
              43.547075001142
            ],
            [
              4.1010404384135,
              43.554370958977
            ],
            [
              4.1092403057439,
              43.568563266822
            ],
            [
              4.0959106624216,
              43.581523957869
            ],
            [
              4.0989142083254,
              43.585240546919
            ],
            [
              4.1144625451015,
              43.587296649227
            ],
            [
              4.1502119605016,
              43.585561607838
            ],
            [
              4.1552059961506,
              43.599475266219
            ],
            [
              4.1687609627909,
              43.609028138017
            ],
            [
              4.1910110238358,
              43.639234913391
            ],
            [
              4.1944474773799,
              43.65430735698
            ],
            [
              4.1713727433509,
              43.680600684543
            ],
            [
              4.1635687299349,
              43.686542859988
            ],
            [
              4.1636282950784,
              43.70657354555
            ],
            [
              4.1517881596493,
              43.717345625893
            ],
            [
              4.1447760175493,
              43.733889286988
            ],
            [
              4.1306521100217,
              43.74099338313
            ],
            [
              4.1140327584688,
              43.737742196664
            ],
            [
              4.1076547111226,
              43.744374099083
            ],
            [
              4.0936667866111,
              43.75246912537
            ],
            [
              4.0929650066929,
              43.762334159626
            ],
            [
              4.0855565937042,
              43.767491135323
            ],
            [
              4.0740348683194,
              43.767824009854
            ],
            [
              4.0539990747233,
              43.772209820497
            ],
            [
              4.0535254469216,
              43.785607465957
            ],
            [
              4.0237520570046,
              43.803317034121
            ],
            [
              4.0020412274265,
              43.811466287649
            ],
            [
              3.9743510107924,
              43.801475760403
            ],
            [
              3.9612080559067,
              43.805734910291
            ],
            [
              3.9617168853344,
              43.816779178746
            ],
            [
              3.9691754635094,
              43.823599650333
            ],
            [
              3.9793506370893,
              43.842882414799
            ],
            [
              3.9590304240826,
              43.843640723232
            ],
            [
              3.9578947740968,
              43.854375717801
            ],
            [
              3.9406937567898,
              43.853353596339
            ],
            [
              3.9221023508338,
              43.858379360183
            ],
            [
              3.9192427620703,
              43.86633319658
            ],
            [
              3.9173912408729,
              43.885588669063
            ],
            [
              3.8971005838609,
              43.877591714061
            ],
            [
              3.8625507527171,
              43.876941409624
            ],
            [
              3.8520982532517,
              43.872258127824
            ],
            [
              3.838000525505,
              43.870736523523
            ],
            [
              3.8308032102239,
              43.866420388072
            ],
            [
              3.8223595629257,
              43.874817167469
            ],
            [
              3.8060703531318,
              43.877906781933
            ],
            [
              3.7995411771349,
              43.890816352208
            ],
            [
              3.8160599235706,
              43.906903774151
            ],
            [
              3.8249029799143,
              43.919298658233
            ],
            [
              3.8271147893385,
              43.935831173484
            ],
            [
              3.8203070382416,
              43.942407548895
            ],
            [
              3.7957694715927,
              43.942541286137
            ],
            [
              3.7889697272882,
              43.950103854855
            ],
            [
              3.7873312270452,
              43.966868530499
            ],
            [
              3.7547927950616,
              43.969527164685
            ],
            [
              3.7437633928395,
              43.966603906206
            ],
            [
              3.7269915449947,
              43.969012654585
            ],
            [
              3.7172714103966,
              43.96098126934
            ],
            [
              3.7068835657127,
              43.96137743879
            ],
            [
              3.6822324562632,
              43.946862256351
            ],
            [
              3.6830143040518,
              43.937357933704
            ],
            [
              3.6724593534735,
              43.911602863609
            ],
            [
              3.66894822031,
              43.910390556955
            ],
            [
              3.6467661529193,
              43.914593850368
            ],
            [
              3.6463988237395,
              43.900956043784
            ],
            [
              3.6326798807854,
              43.89958564047
            ],
            [
              3.6389855801748,
              43.916190301943
            ],
            [
              3.623586076068,
              43.916974081399
            ],
            [
              3.6004803925986,
              43.893113127839
            ],
            [
              3.601127090479,
              43.888106697474
            ],
            [
              3.5907814278887,
              43.883786684839
            ],
            [
              3.5754482226841,
              43.865520441109
            ],
            [
              3.5838874048945,
              43.858770150895
            ],
            [
              3.5994165900688,
              43.854725318734
            ],
            [
              3.5778592558387,
              43.843915506292
            ],
            [
              3.5608183961616,
              43.856092339403
            ],
            [
              3.5537670157108,
              43.851757367586
            ],
            [
              3.5307033912378,
              43.855622902101
            ],
            [
              3.5236533319403,
              43.864744516037
            ],
            [
              3.5232838820633,
              43.88592647767
            ],
            [
              3.5133407872323,
              43.896202538664
            ],
            [
              3.4963834950416,
              43.890291758592
            ],
            [
              3.4793880701921,
              43.888311318601
            ],
            [
              3.4627186279115,
              43.872050177712
            ],
            [
              3.4351765269299,
              43.862954436093
            ],
            [
              3.4237878122134,
              43.872967817811
            ],
            [
              3.4319532718491,
              43.877525493585
            ],
            [
              3.435601748933,
              43.893601991439
            ],
            [
              3.440272342306,
              43.896836575963
            ],
            [
              3.4244530269452,
              43.911595449326
            ],
            [
              3.4010238544621,
              43.915044422349
            ],
            [
              3.3927874913122,
              43.910912953071
            ],
            [
              3.3678750326495,
              43.914132443473
            ],
            [
              3.3583619084885,
              43.913829285364
            ],
            [
              3.352251546542,
              43.929514741403
            ],
            [
              3.3515992526365,
              43.938039511601
            ],
            [
              3.3559729058863,
              43.946168277298
            ],
            [
              3.375642576892,
              43.956010269674
            ],
            [
              3.377150274913,
              43.966974047152
            ],
            [
              3.3986628813639,
              43.96772840409
            ],
            [
              3.405494605868,
              43.970269140751
            ],
            [
              3.4186990670418,
              43.993551717916
            ],
            [
              3.4391614779864,
              43.998039885422
            ],
            [
              3.4458276845789,
              44.004545160927
            ],
            [
              3.4507554815828,
              44.020787284367
            ],
            [
              3.4211719681434,
              44.038539216118
            ],
            [
              3.4008249480855,
              44.04327053535
            ],
            [
              3.3901444043775,
              44.053499221204
            ],
            [
              3.3787941195253,
              44.053878388731
            ],
            [
              3.3681320672501,
              44.057846083439
            ],
            [
              3.3518362676391,
              44.050202625217
            ],
            [
              3.336999456788,
              44.062128764148
            ],
            [
              3.3289141381381,
              44.079025392535
            ],
            [
              3.3183631084637,
              44.079075467836
            ],
            [
              3.3084740785456,
              44.070424721299
            ],
            [
              3.2989038258849,
              44.068666494351
            ],
            [
              3.2905869474847,
              44.071706686695
            ],
            [
              3.2628340569911,
              44.094041936297
            ],
            [
              3.2930621216086,
              44.105349947563
            ],
            [
              3.3079202652498,
              44.105128295087
            ],
            [
              3.3210491311873,
              44.109480171818
            ],
            [
              3.3243954092282,
              44.115339574608
            ],
            [
              3.3202959752665,
              44.135904522063
            ],
            [
              3.330219307158,
              44.142163857886
            ],
            [
              3.3360552391264,
              44.158673278061
            ],
            [
              3.3736480685005,
              44.170759472387
            ]
          ]
        ]
      },
      "properties": {
        "code": "30",
        "nom": "Gard",
        "population": 751457,
        "delitspour1000": "5,171",
        "nombremosque": 36,
        "parentsEtrangers": "71,9",
        "benefRsa": 59720,
        "eoliennes": 6
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1.0257391846007,
              45.574691325999
            ],
            [
              -0.9265369418124,
              45.496125598327
            ],
            [
              -0.86478824719039,
              45.45340715699
            ],
            [
              -0.84106252140245,
              45.435085891321
            ],
            [
              -0.77402172439234,
              45.369205086916
            ],
            [
              -0.75267677056313,
              45.321765614981
            ],
            [
              -0.72330389155964,
              45.327001191386
            ],
            [
              -0.70605381890013,
              45.326431930108
            ],
            [
              -0.64430238229481,
              45.322043706272
            ],
            [
              -0.63524007842555,
              45.318180463174
            ],
            [
              -0.61254068812542,
              45.323483267446
            ],
            [
              -0.59448704800865,
              45.321142619731
            ],
            [
              -0.59384140912013,
              45.334752513163
            ],
            [
              -0.58715999202323,
              45.34284134679
            ],
            [
              -0.56896376359337,
              45.331518881016
            ],
            [
              -0.57387553346356,
              45.310853327057
            ],
            [
              -0.56766890429358,
              45.297602332116
            ],
            [
              -0.55951096673761,
              45.297707318107
            ],
            [
              -0.53198594402906,
              45.291776015129
            ],
            [
              -0.51359037103072,
              45.286335703137
            ],
            [
              -0.48621599817858,
              45.293567761177
            ],
            [
              -0.47115623286141,
              45.292085013202
            ],
            [
              -0.42920073566456,
              45.279476537055
            ],
            [
              -0.41865787206374,
              45.273412127304
            ],
            [
              -0.4160766862435,
              45.266934268035
            ],
            [
              -0.42407240046137,
              45.252657562754
            ],
            [
              -0.42378065552637,
              45.24780411835
            ],
            [
              -0.40743510510465,
              45.247110601421
            ],
            [
              -0.4051131393976,
              45.239822566668
            ],
            [
              -0.41307417339743,
              45.224872704015
            ],
            [
              -0.41750318373793,
              45.204015328884
            ],
            [
              -0.40530728794629,
              45.192687924297
            ],
            [
              -0.39736284181851,
              45.177932331672
            ],
            [
              -0.39406943883391,
              45.164782442033
            ],
            [
              -0.3834007878771,
              45.143333546122
            ],
            [
              -0.37970141341998,
              45.155350139515
            ],
            [
              -0.36356477709128,
              45.170262130456
            ],
            [
              -0.34234235630155,
              45.167553726185
            ],
            [
              -0.32063917436396,
              45.156167113292
            ],
            [
              -0.32010139495923,
              45.148665259477
            ],
            [
              -0.31346140209586,
              45.138238479526
            ],
            [
              -0.30793572307717,
              45.14957651168
            ],
            [
              -0.27536296309773,
              45.141404399887
            ],
            [
              -0.27248948660547,
              45.135305765658
            ],
            [
              -0.25508555252633,
              45.114082615598
            ],
            [
              -0.24567378372971,
              45.10820936272
            ],
            [
              -0.23115054546009,
              45.109122514539
            ],
            [
              -0.21825321623573,
              45.106431791317
            ],
            [
              -0.20972108355095,
              45.100229274355
            ],
            [
              -0.18920334297908,
              45.094460899135
            ],
            [
              -0.16192349951658,
              45.092487275945
            ],
            [
              -0.15295984730846,
              45.088810634907
            ],
            [
              -0.13530078778524,
              45.094092068749
            ],
            [
              -0.12497497516228,
              45.106821892717
            ],
            [
              -0.11323589772113,
              45.115011646159
            ],
            [
              -0.10116314238,
              45.119704095829
            ],
            [
              -0.087698819507144,
              45.121385343046
            ],
            [
              -0.083962269829361,
              45.115833537322
            ],
            [
              -0.058364016937835,
              45.09903723166
            ],
            [
              -0.047688949706731,
              45.097503236881
            ],
            [
              -0.040197554246423,
              45.102379906641
            ],
            [
              -0.020622596045142,
              45.115154002497
            ],
            [
              -0.0035550868294509,
              45.119565426221
            ],
            [
              0.021023704339745,
              45.118020097877
            ],
            [
              0.046062765840233,
              45.113225257483
            ],
            [
              0.060262257593117,
              45.098088622342
            ],
            [
              0.064755925305126,
              45.082017574721
            ],
            [
              0.073291450340814,
              45.070124430762
            ],
            [
              0.065721176012138,
              45.060521385337
            ],
            [
              0.054236225192005,
              45.039326370258
            ],
            [
              0.04364304777729,
              45.032727630944
            ],
            [
              0.035853106594622,
              45.011148382915
            ],
            [
              0.040107702552529,
              44.993500073196
            ],
            [
              0.033385476085785,
              44.980626698129
            ],
            [
              0.027060227717503,
              44.975375195215
            ],
            [
              0.01765322000624,
              44.980541068271
            ],
            [
              0.0092278596756798,
              44.962772057093
            ],
            [
              0.0073841338995166,
              44.947297148555
            ],
            [
              0.01821427303543,
              44.928027514889
            ],
            [
              0.034364629036925,
              44.915470221895
            ],
            [
              0.013321330144365,
              44.885523222829
            ],
            [
              0.0022608102109646,
              44.878505514648
            ],
            [
              0.0000049343835534158,
              44.870853719864
            ],
            [
              -0.007339802937758,
              44.86841238029
            ],
            [
              -0.015443810938192,
              44.857712121687
            ],
            [
              -0.033078198207228,
              44.855598888537
            ],
            [
              -0.034209540087703,
              44.852109918912
            ],
            [
              -0.0038738929006231,
              44.846691521098
            ],
            [
              0.0088805210557711,
              44.845963057545
            ],
            [
              0.021068730009597,
              44.849764251415
            ],
            [
              0.03739904504781,
              44.839909766296
            ],
            [
              0.039465724707109,
              44.827491118364
            ],
            [
              0.046918911914787,
              44.824577015785
            ],
            [
              0.061980823617039,
              44.825724847157
            ],
            [
              0.075753601678977,
              44.819691250591
            ],
            [
              0.083027055631579,
              44.831222961027
            ],
            [
              0.098021195945006,
              44.83286857884
            ],
            [
              0.10851421483567,
              44.828158653021
            ],
            [
              0.1126443072455,
              44.819064166843
            ],
            [
              0.12621182714146,
              44.828280775462
            ],
            [
              0.17042739802804,
              44.826682447294
            ],
            [
              0.19289670858098,
              44.821030025695
            ],
            [
              0.20930247571485,
              44.841088001383
            ],
            [
              0.22239699370187,
              44.844614779064
            ],
            [
              0.24175586953546,
              44.856896621192
            ],
            [
              0.23764871759625,
              44.869244743449
            ],
            [
              0.24408021243543,
              44.871832513417
            ],
            [
              0.25542730507554,
              44.867493927629
            ],
            [
              0.27498244856928,
              44.867663151563
            ],
            [
              0.28515427203214,
              44.864559374292
            ],
            [
              0.30800194052489,
              44.851728215429
            ],
            [
              0.31506020240148,
              44.845415778491
            ],
            [
              0.30028764362365,
              44.83780450023
            ],
            [
              0.30072874268204,
              44.829865184548
            ],
            [
              0.28405377486658,
              44.823658404053
            ],
            [
              0.26372618640876,
              44.826279352937
            ],
            [
              0.26597731108046,
              44.81259127317
            ],
            [
              0.27439801350506,
              44.796085877199
            ],
            [
              0.27422143591466,
              44.783396630933
            ],
            [
              0.28204980793737,
              44.773092230688
            ],
            [
              0.2973266430349,
              44.762287404122
            ],
            [
              0.28997766936396,
              44.757436143357
            ],
            [
              0.2557380828744,
              44.750109827852
            ],
            [
              0.24497879080163,
              44.752533581344
            ],
            [
              0.23847417869066,
              44.763350350246
            ],
            [
              0.22648867551785,
              44.764390535693
            ],
            [
              0.21552946146511,
              44.751053988796
            ],
            [
              0.2124165944403,
              44.7378483172
            ],
            [
              0.21679531034635,
              44.724068141039
            ],
            [
              0.20173077932943,
              44.722436212695
            ],
            [
              0.19416213999304,
              44.743135745847
            ],
            [
              0.18453078260015,
              44.749030776434
            ],
            [
              0.1761170331154,
              44.737358325159
            ],
            [
              0.16437464319642,
              44.735787120442
            ],
            [
              0.15300650688512,
              44.730478405576
            ],
            [
              0.14132400855573,
              44.73741784111
            ],
            [
              0.13939526267633,
              44.723198863501
            ],
            [
              0.1333985499063,
              44.704635060611
            ],
            [
              0.11781050325666,
              44.712797670467
            ],
            [
              0.10938058387031,
              44.710567046765
            ],
            [
              0.10058285885421,
              44.701166710208
            ],
            [
              0.10990509355722,
              44.684237827918
            ],
            [
              0.13372887707313,
              44.682445068839
            ],
            [
              0.12997902491486,
              44.672922900417
            ],
            [
              0.14155378137428,
              44.665533720013
            ],
            [
              0.16217516115648,
              44.670522207234
            ],
            [
              0.17725174403444,
              44.667337011292
            ],
            [
              0.18267131110117,
              44.661072863157
            ],
            [
              0.1671456092699,
              44.643736652511
            ],
            [
              0.16521090547081,
              44.632857082377
            ],
            [
              0.15503219600441,
              44.631810031274
            ],
            [
              0.13735197971124,
              44.636065032992
            ],
            [
              0.13926344006112,
              44.627142000076
            ],
            [
              0.15360179170808,
              44.616118295385
            ],
            [
              0.15085689358311,
              44.60864082157
            ],
            [
              0.1373372611031,
              44.607840943333
            ],
            [
              0.1128359692313,
              44.590802448417
            ],
            [
              0.08223436773711,
              44.58404269552
            ],
            [
              0.08613970807219,
              44.575792139257
            ],
            [
              0.076411026162087,
              44.563581490059
            ],
            [
              0.070718098981858,
              44.549329474287
            ],
            [
              0.040571405832228,
              44.55305031743
            ],
            [
              0.024614259705386,
              44.547446705923
            ],
            [
              0.020979912029289,
              44.54145425276
            ],
            [
              0.0037569284895941,
              44.550564348949
            ],
            [
              -0.00079249578691194,
              44.547722784938
            ],
            [
              0.01334553500528,
              44.537163334695
            ],
            [
              0.016481929542729,
              44.528534052288
            ],
            [
              0.0096113783907688,
              44.521914927246
            ],
            [
              0.0013468507521223,
              44.520759088806
            ],
            [
              -0.015451041784187,
              44.505060157212
            ],
            [
              -0.017062403595954,
              44.494903785526
            ],
            [
              -0.013787425872077,
              44.480606498118
            ],
            [
              -0.012959450871031,
              44.461397567566
            ],
            [
              0.0048804193883298,
              44.456825604584
            ],
            [
              0.0075287126228942,
              44.447142316513
            ],
            [
              -0.0039997054231598,
              44.439884875461
            ],
            [
              -0.010556161472033,
              44.418543300826
            ],
            [
              -0.0022393758929779,
              44.405073205548
            ],
            [
              0.018236013333469,
              44.389881558425
            ],
            [
              0.010450408812354,
              44.381757604422
            ],
            [
              0.023592604670749,
              44.377462953453
            ],
            [
              0.014719662755562,
              44.366614690633
            ],
            [
              0.0061041022665394,
              44.367204827263
            ],
            [
              -0.0028509397317395,
              44.372960212166
            ],
            [
              -0.015317067489138,
              44.369328607193
            ],
            [
              -0.029972201019216,
              44.360520411846
            ],
            [
              -0.05445031981898,
              44.359340828755
            ],
            [
              -0.069564374036526,
              44.352946676151
            ],
            [
              -0.078824287140477,
              44.353695916086
            ],
            [
              -0.085757092678548,
              44.337609909237
            ],
            [
              -0.066571544598853,
              44.322441599368
            ],
            [
              -0.059508198107722,
              44.321373644807
            ],
            [
              -0.034812310736151,
              44.296722165589
            ],
            [
              -0.043397497716851,
              44.293567041696
            ],
            [
              -0.037144435788299,
              44.286361634851
            ],
            [
              -0.036178032292543,
              44.27143072373
            ],
            [
              -0.053367001946939,
              44.265767485647
            ],
            [
              -0.066629618842389,
              44.24675515416
            ],
            [
              -0.074379548540375,
              44.252405291475
            ],
            [
              -0.088056000728359,
              44.238856352832
            ],
            [
              -0.10806059061507,
              44.231019627038
            ],
            [
              -0.12013349102654,
              44.23674944888
            ],
            [
              -0.14068987994571,
              44.226407410087
            ],
            [
              -0.16643741766591,
              44.241645590082
            ],
            [
              -0.17860760723789,
              44.2611943855
            ],
            [
              -0.19411725898218,
              44.269043048465
            ],
            [
              -0.21034767075068,
              44.264363706221
            ],
            [
              -0.22600423316361,
              44.264780559078
            ],
            [
              -0.22831083561736,
              44.256035609777
            ],
            [
              -0.22344689401412,
              44.205894666457
            ],
            [
              -0.27211613275808,
              44.193811119459
            ],
            [
              -0.31133583014507,
              44.203267204497
            ],
            [
              -0.36355937684689,
              44.206118084951
            ],
            [
              -0.38961480789413,
              44.209483805167
            ],
            [
              -0.39624532160833,
              44.237695069388
            ],
            [
              -0.38338244369272,
              44.28631351989
            ],
            [
              -0.40138354559817,
              44.28661085634
            ],
            [
              -0.43055425810778,
              44.303197247509
            ],
            [
              -0.43184427971363,
              44.322572401547
            ],
            [
              -0.47887406741702,
              44.324890920135
            ],
            [
              -0.51763677862963,
              44.33911198566
            ],
            [
              -0.52810065622233,
              44.36465502296
            ],
            [
              -0.56093019811086,
              44.374948937011
            ],
            [
              -0.57096912038279,
              44.382529698119
            ],
            [
              -0.62764253659841,
              44.398144928692
            ],
            [
              -0.62546864287445,
              44.412430951205
            ],
            [
              -0.62877226637636,
              44.443190607827
            ],
            [
              -0.64059457790927,
              44.449412230615
            ],
            [
              -0.67602939990072,
              44.457335453756
            ],
            [
              -0.68193304268744,
              44.44410640147
            ],
            [
              -0.69742786925964,
              44.442347313001
            ],
            [
              -0.7297300625887,
              44.44819829522
            ],
            [
              -0.73069769860506,
              44.446371235394
            ],
            [
              -0.77242389124687,
              44.440065390557
            ],
            [
              -0.7802786587589,
              44.428106299184
            ],
            [
              -0.79305700578868,
              44.42869424449
            ],
            [
              -0.81732088301594,
              44.420355607201
            ],
            [
              -0.84527368904245,
              44.418975448762
            ],
            [
              -0.91201620353572,
              44.436936326703
            ],
            [
              -0.91916781947707,
              44.44334265377
            ],
            [
              -0.96958186494299,
              44.429381693784
            ],
            [
              -1.0291608887845,
              44.422698057254
            ],
            [
              -1.0074549340123,
              44.437655941822
            ],
            [
              -0.99245903926265,
              44.461505914268
            ],
            [
              -0.98069956650209,
              44.482879743899
            ],
            [
              -0.98773451899223,
              44.50830163679
            ],
            [
              -0.99115222944795,
              44.511852855415
            ],
            [
              -1.0110724710714,
              44.51028357283
            ],
            [
              -1.0266322776735,
              44.507125804732
            ],
            [
              -1.0851653037793,
              44.532195517275
            ],
            [
              -1.1064937386386,
              44.502776393766
            ],
            [
              -1.1924838562811,
              44.481212260344
            ],
            [
              -1.253891222702,
              44.4676023661
            ],
            [
              -1.2514054654401,
              44.488888404232
            ],
            [
              -1.2517305863129,
              44.514867670196
            ],
            [
              -1.2602783216327,
              44.539507595489
            ],
            [
              -1.2586139358279,
              44.547133110681
            ],
            [
              -1.2461650749779,
              44.555849447953
            ],
            [
              -1.2273970193025,
              44.575752956082
            ],
            [
              -1.2121340085435,
              44.599510356389
            ],
            [
              -1.2035045639686,
              44.622709327857
            ],
            [
              -1.2041701334268,
              44.639985478462
            ],
            [
              -1.1915382411984,
              44.660725704553
            ],
            [
              -1.1812338744214,
              44.663558241644
            ],
            [
              -1.1617591419762,
              44.663377214551
            ],
            [
              -1.1451668567782,
              44.657629640598
            ],
            [
              -1.1407629554469,
              44.647227205689
            ],
            [
              -1.1249301480769,
              44.647338816899
            ],
            [
              -1.0964256367438,
              44.642110349796
            ],
            [
              -1.0807927355826,
              44.640599156622
            ],
            [
              -1.0660182544594,
              44.646890617223
            ],
            [
              -1.0512011570705,
              44.64425984978
            ],
            [
              -1.0446037563356,
              44.647779187283
            ],
            [
              -1.0053643374777,
              44.648038204976
            ],
            [
              -1.0053044562958,
              44.653801414632
            ],
            [
              -1.0185713848822,
              44.663911777968
            ],
            [
              -1.0233052011919,
              44.67502570927
            ],
            [
              -1.0311721058558,
              44.679029191881
            ],
            [
              -1.0500276213214,
              44.682042500572
            ],
            [
              -1.0581367463265,
              44.689048034983
            ],
            [
              -1.0363621413125,
              44.693561606338
            ],
            [
              -1.1066726573125,
              44.742647003652
            ],
            [
              -1.1172405050661,
              44.74399979558
            ],
            [
              -1.1253669244585,
              44.751000559984
            ],
            [
              -1.1459514198999,
              44.761417104514
            ],
            [
              -1.1605278218204,
              44.774666943417
            ],
            [
              -1.1685739214819,
              44.771765177553
            ],
            [
              -1.1781124391023,
              44.755804073252
            ],
            [
              -1.1722002204463,
              44.75098728783
            ],
            [
              -1.1900688471739,
              44.737019677101
            ],
            [
              -1.1907716197354,
              44.730920109354
            ],
            [
              -1.2044873682995,
              44.721540106397
            ],
            [
              -1.2043808938438,
              44.717662887525
            ],
            [
              -1.2229595098338,
              44.709573193593
            ],
            [
              -1.2223114975031,
              44.706127753166
            ],
            [
              -1.232623680779,
              44.693018645313
            ],
            [
              -1.2374176561525,
              44.682509258925
            ],
            [
              -1.2401079698599,
              44.666522122287
            ],
            [
              -1.2389445057575,
              44.65778181354
            ],
            [
              -1.2462021095773,
              44.641862728761
            ],
            [
              -1.243112422171,
              44.628827562622
            ],
            [
              -1.2521476349475,
              44.617729855339
            ],
            [
              -1.2604466153424,
              44.625669343024
            ],
            [
              -1.2617334302552,
              44.647702967991
            ],
            [
              -1.2517253975532,
              44.712016422613
            ],
            [
              -1.2370231185304,
              44.784935738577
            ],
            [
              -1.2229034640823,
              44.864823398854
            ],
            [
              -1.2041233648852,
              44.989780904848
            ],
            [
              -1.198002213805,
              45.042704797651
            ],
            [
              -1.1942059965634,
              45.069162896565
            ],
            [
              -1.190236703195,
              45.107603378458
            ],
            [
              -1.1808856453533,
              45.176064250108
            ],
            [
              -1.162337441751,
              45.297868794558
            ],
            [
              -1.1593599360818,
              45.35068807983
            ],
            [
              -1.1605559384718,
              45.41068706862
            ],
            [
              -1.1515035839053,
              45.439471708886
            ],
            [
              -1.1514532239497,
              45.451854900963
            ],
            [
              -1.1570373594316,
              45.470365660883
            ],
            [
              -1.1546170989864,
              45.480167705968
            ],
            [
              -1.1364252810856,
              45.51099225066
            ],
            [
              -1.1228634483366,
              45.520418190565
            ],
            [
              -1.1002940973715,
              45.541645700905
            ],
            [
              -1.0961094287101,
              45.55676823678
            ],
            [
              -1.0912045614296,
              45.562411237009
            ],
            [
              -1.0605909878371,
              45.571952807583
            ],
            [
              -1.0257391846007,
              45.574691325999
            ]
          ]
        ]
      },
      "properties": {
        "code": "33",
        "nom": "Gironde",
        "population": 1636391,
        "delitspour1000": "5,266",
        "nombremosque": 33,
        "parentsEtrangers": "73,9",
        "benefRsa": 75181
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1.3266590555808,
              47.18622172903
            ],
            [
              1.3384941532316,
              47.195546392092
            ],
            [
              1.3509355101116,
              47.193629685188
            ],
            [
              1.3714156982392,
              47.199695063562
            ],
            [
              1.3929690077143,
              47.208780068888
            ],
            [
              1.4096473915549,
              47.217686976966
            ],
            [
              1.4241008207975,
              47.229542520761
            ],
            [
              1.4484610302548,
              47.237964303177
            ],
            [
              1.4812668431106,
              47.239111870913
            ],
            [
              1.5066547480792,
              47.233090096391
            ],
            [
              1.5341048464341,
              47.22131901442
            ],
            [
              1.5342093559326,
              47.227571968439
            ],
            [
              1.5267604611835,
              47.239844802952
            ],
            [
              1.5262985893122,
              47.252885963483
            ],
            [
              1.559536313325,
              47.261391048668
            ],
            [
              1.5910427299459,
              47.25746067104
            ],
            [
              1.5945108544507,
              47.259821278203
            ],
            [
              1.5924268223064,
              47.276675700148
            ],
            [
              1.6211827652436,
              47.273049423747
            ],
            [
              1.6283172724894,
              47.275016709136
            ],
            [
              1.6504332606527,
              47.273166503913
            ],
            [
              1.6491231803883,
              47.262026117084
            ],
            [
              1.6658922565042,
              47.257784932818
            ],
            [
              1.6780281328466,
              47.261370562275
            ],
            [
              1.7058330808156,
              47.265880798196
            ],
            [
              1.7156838074978,
              47.276819032313
            ],
            [
              1.7397876612013,
              47.25490642364
            ],
            [
              1.7761040879175,
              47.231384294553
            ],
            [
              1.7909602935711,
              47.23150058466
            ],
            [
              1.795817994896,
              47.237820414105
            ],
            [
              1.808833111076,
              47.233878017291
            ],
            [
              1.8280274987279,
              47.22226987561
            ],
            [
              1.8390806604309,
              47.219303670107
            ],
            [
              1.8374260291198,
              47.198516443136
            ],
            [
              1.8421034231416,
              47.178025700878
            ],
            [
              1.8225543314818,
              47.173442805215
            ],
            [
              1.810837828747,
              47.164258858399
            ],
            [
              1.8100596781046,
              47.156159044775
            ],
            [
              1.7745852665449,
              47.130846854619
            ],
            [
              1.7909839952944,
              47.125662872073
            ],
            [
              1.7936682439251,
              47.12090793503
            ],
            [
              1.8226516325045,
              47.12207545041
            ],
            [
              1.8390424455761,
              47.11697467405
            ],
            [
              1.8470711123844,
              47.110756679752
            ],
            [
              1.882724430627,
              47.101952271787
            ],
            [
              1.9019778224018,
              47.10778397148
            ],
            [
              1.9260996730031,
              47.106523038938
            ],
            [
              1.9664192725479,
              47.125416660655
            ],
            [
              1.9815904431899,
              47.123440729154
            ],
            [
              1.9966588335085,
              47.126994721746
            ],
            [
              1.9992352868587,
              47.115486844081
            ],
            [
              2.0094058333809,
              47.111603739978
            ],
            [
              2.003321032879,
              47.094178638571
            ],
            [
              2.0303104954162,
              47.096817560051
            ],
            [
              2.0245359187373,
              47.104448305394
            ],
            [
              2.0483748335746,
              47.106018202845
            ],
            [
              2.0532618114789,
              47.103623707949
            ],
            [
              2.0499678265899,
              47.091426327267
            ],
            [
              2.0538795396561,
              47.086532442778
            ],
            [
              2.0556685174481,
              47.071693505089
            ],
            [
              2.041032928343,
              47.062834748226
            ],
            [
              2.03373739539,
              47.049867783207
            ],
            [
              2.0333809485515,
              47.042849839903
            ],
            [
              2.0514916798709,
              47.040017881632
            ],
            [
              2.0683404264664,
              47.026024262623
            ],
            [
              2.0933825983882,
              47.015734925131
            ],
            [
              2.1088506754911,
              46.997189278307
            ],
            [
              2.0958019671543,
              46.989651377194
            ],
            [
              2.0882457712698,
              46.960442164094
            ],
            [
              2.0702081853584,
              46.954419351516
            ],
            [
              2.0707025283968,
              46.933998270256
            ],
            [
              2.0965786279245,
              46.918806815272
            ],
            [
              2.1103224158986,
              46.913257304755
            ],
            [
              2.1216654778987,
              46.916293198614
            ],
            [
              2.1460170187651,
              46.917505413
            ],
            [
              2.154833099738,
              46.911627673453
            ],
            [
              2.1390331265564,
              46.888570687718
            ],
            [
              2.126206500563,
              46.879866015754
            ],
            [
              2.1141987370392,
              46.880427985511
            ],
            [
              2.1069526908981,
              46.872136681281
            ],
            [
              2.087079297321,
              46.86752997115
            ],
            [
              2.096467901154,
              46.855247357856
            ],
            [
              2.0782512303935,
              46.838923780247
            ],
            [
              2.06876776215,
              46.834582910689
            ],
            [
              2.0480782837609,
              46.835794883155
            ],
            [
              2.0488037102017,
              46.829451815206
            ],
            [
              2.0779477252387,
              46.806715037904
            ],
            [
              2.1032191381843,
              46.795434158849
            ],
            [
              2.116644294623,
              46.779716267822
            ],
            [
              2.1153566557228,
              46.77436037191
            ],
            [
              2.0929871989079,
              46.754972007435
            ],
            [
              2.0765727696581,
              46.7522371017
            ],
            [
              2.0657565358489,
              46.742495745033
            ],
            [
              2.0927323443423,
              46.72429296012
            ],
            [
              2.0869647803577,
              46.712584352345
            ],
            [
              2.1181050846152,
              46.710264176432
            ],
            [
              2.1310053622523,
              46.705564874051
            ],
            [
              2.1407192440195,
              46.695550975141
            ],
            [
              2.1554415063015,
              46.692156551695
            ],
            [
              2.1478278174915,
              46.681978091826
            ],
            [
              2.1366685342935,
              46.677280643876
            ],
            [
              2.1431297127939,
              46.663534742579
            ],
            [
              2.1617076323527,
              46.654560152419
            ],
            [
              2.1794468252209,
              46.642530278058
            ],
            [
              2.1894415234234,
              46.64213853449
            ],
            [
              2.1842458340693,
              46.63176333554
            ],
            [
              2.1833732167287,
              46.622147237746
            ],
            [
              2.1874862145218,
              46.616720480096
            ],
            [
              2.1856314742498,
              46.602862444429
            ],
            [
              2.176954649399,
              46.601681136912
            ],
            [
              2.1671401783505,
              46.588981814794
            ],
            [
              2.1732113440402,
              46.566183161063
            ],
            [
              2.1586963030001,
              46.557344452138
            ],
            [
              2.1776855709349,
              46.530652297328
            ],
            [
              2.1818798754785,
              46.522515787074
            ],
            [
              2.1834172727147,
              46.508977916004
            ],
            [
              2.1946310547888,
              46.506068691823
            ],
            [
              2.204377139976,
              46.490605163166
            ],
            [
              2.2043920861378,
              46.484397237754
            ],
            [
              2.1937480421876,
              46.470798947226
            ],
            [
              2.156536738392,
              46.461524399253
            ],
            [
              2.1516663532134,
              46.45796285489
            ],
            [
              2.1543506339543,
              46.434080534798
            ],
            [
              2.1677843748983,
              46.424069192575
            ],
            [
              2.1296807376318,
              46.419867872386
            ],
            [
              2.1126347187417,
              46.420825387231
            ],
            [
              2.1083721397147,
              46.413528592759
            ],
            [
              2.0889457684929,
              46.40889961632
            ],
            [
              2.0803472806508,
              46.411894246794
            ],
            [
              2.0741962684953,
              46.419842978652
            ],
            [
              2.0294174309506,
              46.424588797264
            ],
            [
              2.0203464671203,
              46.429421918521
            ],
            [
              1.9930771581051,
              46.430917343442
            ],
            [
              1.9781042758382,
              46.439772572322
            ],
            [
              1.9542508363527,
              46.438125703166
            ],
            [
              1.943869602507,
              46.433996659264
            ],
            [
              1.9243017577068,
              46.431903065788
            ],
            [
              1.9195527692358,
              46.440208070783
            ],
            [
              1.909180451657,
              46.443498226347
            ],
            [
              1.902458213237,
              46.43780452052
            ],
            [
              1.89071589528,
              46.441493184019
            ],
            [
              1.8834368991965,
              46.432557252889
            ],
            [
              1.8580375856722,
              46.433476804112
            ],
            [
              1.8383647496008,
              46.42728517531
            ],
            [
              1.8195044214246,
              46.430033877555
            ],
            [
              1.816812388337,
              46.439524938483
            ],
            [
              1.8031391002694,
              46.44691836733
            ],
            [
              1.7983753858278,
              46.45481310551
            ],
            [
              1.7548573832317,
              46.452214535934
            ],
            [
              1.7475915839665,
              46.450017868926
            ],
            [
              1.7566658918784,
              46.441292317573
            ],
            [
              1.757375741134,
              46.423616429483
            ],
            [
              1.7493159438657,
              46.41093389201
            ],
            [
              1.7505449826939,
              46.405586225701
            ],
            [
              1.7392119982362,
              46.401254100808
            ],
            [
              1.7277096608449,
              46.388955998124
            ],
            [
              1.7091312929501,
              46.393353821969
            ],
            [
              1.6974668292376,
              46.406604886198
            ],
            [
              1.683606447426,
              46.418176923275
            ],
            [
              1.6611836253306,
              46.403538218482
            ],
            [
              1.6448463938248,
              46.386816749434
            ],
            [
              1.628836514357,
              46.388247455127
            ],
            [
              1.6143064140463,
              46.405295947029
            ],
            [
              1.6226552998913,
              46.418260263211
            ],
            [
              1.6092554805936,
              46.423123163216
            ],
            [
              1.5920388571659,
              46.407311867592
            ],
            [
              1.5696914589532,
              46.405498471105
            ],
            [
              1.5524923075161,
              46.394133824944
            ],
            [
              1.5461946362171,
              46.39346025752
            ],
            [
              1.5439680356057,
              46.41688301363
            ],
            [
              1.5350519004493,
              46.424455702933
            ],
            [
              1.5223067601736,
              46.426527695424
            ],
            [
              1.5112050540502,
              46.419594596972
            ],
            [
              1.5059901336348,
              46.409908716453
            ],
            [
              1.4929348173659,
              46.398340551367
            ],
            [
              1.4775958033831,
              46.393726557044
            ],
            [
              1.472558559102,
              46.383431643989
            ],
            [
              1.4629367778167,
              46.375349793186
            ],
            [
              1.454349713379,
              46.376048839285
            ],
            [
              1.4353743494744,
              46.363842198651
            ],
            [
              1.438395055125,
              46.35797267046
            ],
            [
              1.4151854142983,
              46.347214822447
            ],
            [
              1.4048447784072,
              46.364056923495
            ],
            [
              1.3965775759913,
              46.371445233126
            ],
            [
              1.3835201223913,
              46.374755471612
            ],
            [
              1.3775283931369,
              46.382803331341
            ],
            [
              1.3560243049838,
              46.4001195921
            ],
            [
              1.3442929839887,
              46.401546863371
            ],
            [
              1.3309220641861,
              46.396705560378
            ],
            [
              1.3223398241302,
              46.389656273383
            ],
            [
              1.3204740000508,
              46.38163178083
            ],
            [
              1.3093599883058,
              46.378135125428
            ],
            [
              1.3030594710726,
              46.370991990563
            ],
            [
              1.2795549241983,
              46.376488887271
            ],
            [
              1.2603993142262,
              46.378783327264
            ],
            [
              1.245105268008,
              46.37323860456
            ],
            [
              1.2408646690892,
              46.367573686174
            ],
            [
              1.2164239770673,
              46.367784938774
            ],
            [
              1.2047664407221,
              46.387689428519
            ],
            [
              1.191538104043,
              46.376759451936
            ],
            [
              1.1841495355328,
              46.37729147918
            ],
            [
              1.1772787760985,
              46.383948000481
            ],
            [
              1.1950871576883,
              46.40275403272
            ],
            [
              1.1944727430082,
              46.410351612296
            ],
            [
              1.2126877519811,
              46.432217971701
            ],
            [
              1.2011339896178,
              46.432466167404
            ],
            [
              1.1834055129956,
              46.429224899772
            ],
            [
              1.1860178766023,
              46.441083576014
            ],
            [
              1.1689192762458,
              46.446308575491
            ],
            [
              1.1516072310249,
              46.449233517349
            ],
            [
              1.1355078072574,
              46.470884241481
            ],
            [
              1.1529790996043,
              46.472957682593
            ],
            [
              1.1405271083029,
              46.485634879486
            ],
            [
              1.1349675834984,
              46.495262876365
            ],
            [
              1.1491485689753,
              46.502205353076
            ],
            [
              1.1459288779804,
              46.506400874677
            ],
            [
              1.1082856489547,
              46.531510215741
            ],
            [
              1.0875973131695,
              46.538168324634
            ],
            [
              1.0722975106803,
              46.53723511351
            ],
            [
              1.0206003685537,
              46.537099020664
            ],
            [
              1.0219575008089,
              46.55371655484
            ],
            [
              1.0147685045818,
              46.567759565973
            ],
            [
              0.98723321911375,
              46.565560099789
            ],
            [
              0.98209440694689,
              46.572640353042
            ],
            [
              0.96266777384523,
              46.574297702094
            ],
            [
              0.94202235129636,
              46.580794785364
            ],
            [
              0.9371898310662,
              46.586044724789
            ],
            [
              0.93775566335965,
              46.594420109118
            ],
            [
              0.91586544594852,
              46.596627887059
            ],
            [
              0.90986438287983,
              46.603447081391
            ],
            [
              0.90693650860659,
              46.615213230068
            ],
            [
              0.89430329614895,
              46.625732175305
            ],
            [
              0.89638293110539,
              46.633451946341
            ],
            [
              0.90652086468369,
              46.647752216163
            ],
            [
              0.91742860399951,
              46.65036307159
            ],
            [
              0.9067032428601,
              46.665571264221
            ],
            [
              0.91071057116932,
              46.677183661606
            ],
            [
              0.90215945171528,
              46.67919114553
            ],
            [
              0.92474929055741,
              46.692789633606
            ],
            [
              0.9249533025609,
              46.699991021542
            ],
            [
              0.91455153531157,
              46.710120272246
            ],
            [
              0.90104236961312,
              46.736090564754
            ],
            [
              0.88777529605684,
              46.737902736858
            ],
            [
              0.86746898682573,
              46.748216404838
            ],
            [
              0.88463522122144,
              46.754869575055
            ],
            [
              0.92470385464529,
              46.757126310086
            ],
            [
              0.93489233835098,
              46.750480745419
            ],
            [
              0.94370834746443,
              46.738598233751
            ],
            [
              0.95637654222944,
              46.737086924375
            ],
            [
              0.96794806660049,
              46.749171655021
            ],
            [
              0.98881316105931,
              46.761544268841
            ],
            [
              1.0116874509333,
              46.757807396767
            ],
            [
              1.0095223234417,
              46.768669105136
            ],
            [
              1.0003489879518,
              46.7821498087
            ],
            [
              1.0025317272091,
              46.794546933361
            ],
            [
              0.98724249153202,
              46.798191913732
            ],
            [
              0.98747609997385,
              46.804582753115
            ],
            [
              1.00670501168,
              46.814944965284
            ],
            [
              1.0105192309502,
              46.837379188645
            ],
            [
              1.0194707995534,
              46.849359367962
            ],
            [
              1.0192412205018,
              46.858760128319
            ],
            [
              1.0243995096079,
              46.870470878361
            ],
            [
              1.0252142173309,
              46.880107454131
            ],
            [
              1.0331387373787,
              46.9051217043
            ],
            [
              1.0375841279898,
              46.941252795031
            ],
            [
              1.0586238621418,
              46.951476334354
            ],
            [
              1.0635391807284,
              46.957306774009
            ],
            [
              1.058208899214,
              46.971670307482
            ],
            [
              1.0498710967753,
              46.980639407512
            ],
            [
              1.055781061832,
              46.985319706164
            ],
            [
              1.0559227392471,
              46.995938541629
            ],
            [
              1.070212174847,
              47.000595894054
            ],
            [
              1.0776156285907,
              47.015837897863
            ],
            [
              1.08506655771,
              47.020688086638
            ],
            [
              1.1102627168966,
              47.029236511374
            ],
            [
              1.1158296252981,
              47.024572387317
            ],
            [
              1.13826224584,
              47.030521714593
            ],
            [
              1.1614195391536,
              47.03962293091
            ],
            [
              1.1818586160475,
              47.040820071391
            ],
            [
              1.1955888714496,
              47.029530708071
            ],
            [
              1.2230663921608,
              47.024312137086
            ],
            [
              1.2320899400978,
              47.014142864447
            ],
            [
              1.2578242876804,
              47.026398912616
            ],
            [
              1.2730582525963,
              47.03783145755
            ],
            [
              1.2745356677731,
              47.049592096735
            ],
            [
              1.2912050782264,
              47.065574010099
            ],
            [
              1.2937745970093,
              47.072255183314
            ],
            [
              1.3126429957289,
              47.073774055065
            ],
            [
              1.3167792884814,
              47.084924278833
            ],
            [
              1.3116728530869,
              47.096977073662
            ],
            [
              1.3188337884266,
              47.104913182924
            ],
            [
              1.3324391688613,
              47.099590072851
            ],
            [
              1.3445235375283,
              47.101628681302
            ],
            [
              1.3557232970181,
              47.108787198703
            ],
            [
              1.3653663291974,
              47.12204955205
            ],
            [
              1.3608270853598,
              47.139644881154
            ],
            [
              1.3513009666821,
              47.152248772091
            ],
            [
              1.3467098477255,
              47.168763035526
            ],
            [
              1.3403616834348,
              47.176714426828
            ],
            [
              1.3266590555808,
              47.18622172903
            ]
          ]
        ]
      },
      "properties": {
        "code": "36",
        "nom": "Indre",
        "population": 218707,
        "delitspour1000": "4,22",
        "nombremosque": 2,
        "parentsEtrangers": "80,7",
        "benefRsa": 10741,
        "eoliennes": 109
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              5.62374290574,
              45.613268446443
            ],
            [
              5.6230208703548,
              45.604282743022
            ],
            [
              5.6430941654947,
              45.585618310022
            ],
            [
              5.6470817885996,
              45.57674261279
            ],
            [
              5.654825526768,
              45.570120639284
            ],
            [
              5.6695358905937,
              45.563199459718
            ],
            [
              5.6803575756273,
              45.545047248369
            ],
            [
              5.6717082687622,
              45.536571381592
            ],
            [
              5.6768487183882,
              45.530475432419
            ],
            [
              5.6901528196542,
              45.52805100678
            ],
            [
              5.6912470759998,
              45.521805232869
            ],
            [
              5.7011064065529,
              45.517546444671
            ],
            [
              5.70267342317,
              45.511196354762
            ],
            [
              5.7125099215192,
              45.505293631893
            ],
            [
              5.7190481261138,
              45.484227733066
            ],
            [
              5.7363772302151,
              45.472535799856
            ],
            [
              5.7322100567066,
              45.453663170363
            ],
            [
              5.7400977980128,
              45.437349988759
            ],
            [
              5.7597939416716,
              45.435261281179
            ],
            [
              5.7634821589831,
              45.439042972367
            ],
            [
              5.7820850307141,
              45.440656144128
            ],
            [
              5.7979610020218,
              45.437123711786
            ],
            [
              5.8074478270167,
              45.426270640914
            ],
            [
              5.8232128524879,
              45.421771370243
            ],
            [
              5.8542752462907,
              45.4165640969
            ],
            [
              5.8607139660775,
              45.40924651344
            ],
            [
              5.8797333382597,
              45.406797743306
            ],
            [
              5.8917060274354,
              45.398132080514
            ],
            [
              5.8910055457909,
              45.392211292218
            ],
            [
              5.909612774011,
              45.390400457607
            ],
            [
              5.9149860828604,
              45.401349288339
            ],
            [
              5.9281112911718,
              45.415471973855
            ],
            [
              5.9226358813528,
              45.416818739345
            ],
            [
              5.9090423346643,
              45.408294208274
            ],
            [
              5.9016155577899,
              45.418032646134
            ],
            [
              5.904472446297,
              45.432574848416
            ],
            [
              5.9149042257548,
              45.436653554682
            ],
            [
              5.9254150387228,
              45.464593457788
            ],
            [
              5.9164085799586,
              45.47667987803
            ],
            [
              5.9533584581804,
              45.484809788706
            ],
            [
              5.9661721637538,
              45.492307753934
            ],
            [
              5.9821187808525,
              45.487027235821
            ],
            [
              5.9893333893021,
              45.476074199105
            ],
            [
              6.0109047885983,
              45.47320486613
            ],
            [
              6.0085163401932,
              45.453848085956
            ],
            [
              6.0497526230096,
              45.437920464227
            ],
            [
              6.0658423549871,
              45.444139208117
            ],
            [
              6.0909753082569,
              45.444016847857
            ],
            [
              6.0974519491338,
              45.432105098885
            ],
            [
              6.1212683533751,
              45.438895252904
            ],
            [
              6.1324998100528,
              45.433380436097
            ],
            [
              6.1436456530595,
              45.414580875157
            ],
            [
              6.154793328964,
              45.409336349236
            ],
            [
              6.1774532351909,
              45.393156916781
            ],
            [
              6.1860529913803,
              45.374350928001
            ],
            [
              6.1916877134761,
              45.369022157094
            ],
            [
              6.1802922696297,
              45.360409426774
            ],
            [
              6.1803709629809,
              45.35492081739
            ],
            [
              6.19476040795,
              45.352244568075
            ],
            [
              6.1900932248736,
              45.342443964482
            ],
            [
              6.1844454256034,
              45.317952251676
            ],
            [
              6.1632323695733,
              45.312838044143
            ],
            [
              6.1417688736284,
              45.299001113002
            ],
            [
              6.1319486525639,
              45.288285309432
            ],
            [
              6.1325841534867,
              45.272593012456
            ],
            [
              6.1393900781341,
              45.266560839079
            ],
            [
              6.1385812548773,
              45.256050997267
            ],
            [
              6.1257002517953,
              45.244269262231
            ],
            [
              6.1272723570602,
              45.233317943724
            ],
            [
              6.1416935060053,
              45.22232847567
            ],
            [
              6.1378211482236,
              45.213333187699
            ],
            [
              6.1594722938627,
              45.202449604679
            ],
            [
              6.1619191434924,
              45.188405431434
            ],
            [
              6.143792292215,
              45.154552997649
            ],
            [
              6.1613170230868,
              45.151005539601
            ],
            [
              6.1690294070189,
              45.154126275916
            ],
            [
              6.1759369876508,
              45.162361766933
            ],
            [
              6.1892075903757,
              45.163730483941
            ],
            [
              6.2159372176736,
              45.152163633175
            ],
            [
              6.2183492305743,
              45.145333575225
            ],
            [
              6.2277116468863,
              45.142717265929
            ],
            [
              6.2489312476442,
              45.149662771802
            ],
            [
              6.2653779890371,
              45.139607411594
            ],
            [
              6.2605698000566,
              45.12684420383
            ],
            [
              6.2544936755586,
              45.120790168264
            ],
            [
              6.2438305270389,
              45.117298075856
            ],
            [
              6.2293919345611,
              45.108749945384
            ],
            [
              6.2296723977867,
              45.100589085615
            ],
            [
              6.2358324302027,
              45.087232804381
            ],
            [
              6.2401205372094,
              45.06771808518
            ],
            [
              6.2200802599652,
              45.065369735518
            ],
            [
              6.2063284689408,
              45.026782796498
            ],
            [
              6.2039233205269,
              45.012471073947
            ],
            [
              6.2517609476002,
              44.996700081455
            ],
            [
              6.2696990840472,
              44.998351365091
            ],
            [
              6.2970615457571,
              45.003365126963
            ],
            [
              6.3182021615637,
              45.003859362058
            ],
            [
              6.3196573396763,
              44.994552811249
            ],
            [
              6.3148100239742,
              44.980185922577
            ],
            [
              6.3285012874077,
              44.969714464603
            ],
            [
              6.3229180108318,
              44.953019839313
            ],
            [
              6.3290046114892,
              44.947315364095
            ],
            [
              6.3588423781754,
              44.941280796729
            ],
            [
              6.3546140951868,
              44.923593300625
            ],
            [
              6.3581697523493,
              44.893778946012
            ],
            [
              6.3507779137165,
              44.881204072575
            ],
            [
              6.3553625022057,
              44.854775353601
            ],
            [
              6.3363165518362,
              44.848370410548
            ],
            [
              6.3192302042703,
              44.85684625453
            ],
            [
              6.302850434726,
              44.873257969643
            ],
            [
              6.2882129459689,
              44.874030533685
            ],
            [
              6.2677561761734,
              44.86950109295
            ],
            [
              6.2581706057376,
              44.86248397325
            ],
            [
              6.2505425682344,
              44.852650516632
            ],
            [
              6.2243745547304,
              44.852494101643
            ],
            [
              6.1963759402105,
              44.858978334449
            ],
            [
              6.1852192319643,
              44.853991864943
            ],
            [
              6.1684510585274,
              44.852242761941
            ],
            [
              6.1490188906075,
              44.858169876067
            ],
            [
              6.1362266704196,
              44.864071820118
            ],
            [
              6.1283556561161,
              44.861901713563
            ],
            [
              6.1166725017074,
              44.849313192704
            ],
            [
              6.1007058869493,
              44.84257849233
            ],
            [
              6.0965156572897,
              44.837489421771
            ],
            [
              6.0653153781591,
              44.82268480473
            ],
            [
              6.0563403817425,
              44.815907431783
            ],
            [
              6.0402065129253,
              44.827867468677
            ],
            [
              6.0302157011626,
              44.838097758941
            ],
            [
              6.0159331963205,
              44.835485814051
            ],
            [
              6.0048600553234,
              44.820439050514
            ],
            [
              5.9961657258664,
              44.81787407617
            ],
            [
              5.9781993713916,
              44.818035522208
            ],
            [
              5.9495216336669,
              44.804527356596
            ],
            [
              5.9537639530916,
              44.799525853781
            ],
            [
              5.9777793189449,
              44.790983738721
            ],
            [
              5.9801493330969,
              44.781181602373
            ],
            [
              5.9555147438847,
              44.772449015839
            ],
            [
              5.952471613503,
              44.76214052281
            ],
            [
              5.937984096823,
              44.763046424986
            ],
            [
              5.926812494728,
              44.757135657325
            ],
            [
              5.9152344231519,
              44.754703072251
            ],
            [
              5.900149637893,
              44.758315310611
            ],
            [
              5.8888310649581,
              44.748803778249
            ],
            [
              5.8794954997212,
              44.747015345674
            ],
            [
              5.8652264931802,
              44.75155204943
            ],
            [
              5.8503937497009,
              44.750747373326
            ],
            [
              5.8371577512142,
              44.757677183301
            ],
            [
              5.8270981940509,
              44.759688387455
            ],
            [
              5.8277714297865,
              44.740086051546
            ],
            [
              5.8177724444737,
              44.730405199263
            ],
            [
              5.8087945811456,
              44.712101632921
            ],
            [
              5.8014699513145,
              44.706777613167
            ],
            [
              5.7853372486465,
              44.700829290463
            ],
            [
              5.7590439457666,
              44.696067584965
            ],
            [
              5.7451471861505,
              44.703727044556
            ],
            [
              5.7417693832802,
              44.710673317311
            ],
            [
              5.7193970657268,
              44.71376644489
            ],
            [
              5.7052357370695,
              44.727685225843
            ],
            [
              5.6987857560456,
              44.722066559691
            ],
            [
              5.6682357861974,
              44.724714054907
            ],
            [
              5.6470991996242,
              44.724099914037
            ],
            [
              5.64398076712,
              44.73157541049
            ],
            [
              5.6315009068421,
              44.738825957841
            ],
            [
              5.6270859672246,
              44.752460799598
            ],
            [
              5.5899964337532,
              44.761617860814
            ],
            [
              5.5838826069396,
              44.765344858953
            ],
            [
              5.5820961321703,
              44.777685016074
            ],
            [
              5.549761645604,
              44.794528877128
            ],
            [
              5.5441427350362,
              44.788922680811
            ],
            [
              5.5555318108241,
              44.771216999345
            ],
            [
              5.5196122819033,
              44.777219870899
            ],
            [
              5.4949322129004,
              44.782627931299
            ],
            [
              5.4641599003636,
              44.792424769087
            ],
            [
              5.4624328748393,
              44.802667812109
            ],
            [
              5.4760803164135,
              44.809091676298
            ],
            [
              5.4767736644408,
              44.81563556844
            ],
            [
              5.4837019416551,
              44.823184908254
            ],
            [
              5.4637975227518,
              44.825974431714
            ],
            [
              5.46606801453,
              44.841221723762
            ],
            [
              5.4750760812667,
              44.867592273489
            ],
            [
              5.4700479073037,
              44.879032502847
            ],
            [
              5.480315188243,
              44.896807670531
            ],
            [
              5.4836507785865,
              44.922286913688
            ],
            [
              5.4776757133828,
              44.966755171398
            ],
            [
              5.4887669714919,
              44.990232279193
            ],
            [
              5.4884745473303,
              45.001223430328
            ],
            [
              5.4804589043527,
              45.013865278458
            ],
            [
              5.4836143274431,
              45.022786934023
            ],
            [
              5.4648455525186,
              45.045940903248
            ],
            [
              5.4662213802966,
              45.053689072581
            ],
            [
              5.4773643019715,
              45.072025935069
            ],
            [
              5.4888280786289,
              45.073091937552
            ],
            [
              5.482960100439,
              45.083814042465
            ],
            [
              5.4683829045497,
              45.088079984604
            ],
            [
              5.4594178157294,
              45.084353288913
            ],
            [
              5.4497902629449,
              45.070787231096
            ],
            [
              5.4257108891404,
              45.05626514626
            ],
            [
              5.417792323715,
              45.048366081468
            ],
            [
              5.4088425346067,
              45.044735225212
            ],
            [
              5.3963847959196,
              45.044550028371
            ],
            [
              5.3972657713912,
              45.038330859223
            ],
            [
              5.3883853627744,
              45.036136409191
            ],
            [
              5.3713895477672,
              45.0438762945
            ],
            [
              5.3507319764542,
              45.047203142466
            ],
            [
              5.3437881719066,
              45.051703899543
            ],
            [
              5.3431185907943,
              45.059779112552
            ],
            [
              5.334790878464,
              45.060805233498
            ],
            [
              5.3170937420842,
              45.051443710709
            ],
            [
              5.3074190056319,
              45.053404696516
            ],
            [
              5.300644528137,
              45.062587690778
            ],
            [
              5.2918748938355,
              45.063928670512
            ],
            [
              5.2662685281178,
              45.059810966567
            ],
            [
              5.2483439776641,
              45.060930865772
            ],
            [
              5.2445180483487,
              45.066971920702
            ],
            [
              5.2303765814513,
              45.072500129754
            ],
            [
              5.2261246446959,
              45.07918137092
            ],
            [
              5.2082043994035,
              45.084220045879
            ],
            [
              5.1833351592236,
              45.084878534921
            ],
            [
              5.1793794005119,
              45.083305819247
            ],
            [
              5.1634833877489,
              45.06599819123
            ],
            [
              5.1472802619812,
              45.072549217506
            ],
            [
              5.1427276255164,
              45.080729882348
            ],
            [
              5.1564001681613,
              45.082786398559
            ],
            [
              5.1628941235196,
              45.098426086784
            ],
            [
              5.176310666063,
              45.107822675236
            ],
            [
              5.187438201276,
              45.120832355985
            ],
            [
              5.1868446925336,
              45.145105728237
            ],
            [
              5.191700424762,
              45.154576461872
            ],
            [
              5.1885746038112,
              45.171264013519
            ],
            [
              5.1770838978704,
              45.179740593678
            ],
            [
              5.1694383011753,
              45.195274384832
            ],
            [
              5.1648771309633,
              45.198450714497
            ],
            [
              5.1674873703836,
              45.210221645731
            ],
            [
              5.1787087939172,
              45.216974316616
            ],
            [
              5.2017183191657,
              45.217400467946
            ],
            [
              5.1853651989338,
              45.230539570693
            ],
            [
              5.1765458684418,
              45.248399245404
            ],
            [
              5.156679209512,
              45.247392718624
            ],
            [
              5.1519149109729,
              45.255479740823
            ],
            [
              5.1414710173246,
              45.245014424938
            ],
            [
              5.1314466193386,
              45.243321691602
            ],
            [
              5.1214293208332,
              45.248334945441
            ],
            [
              5.1256631119512,
              45.26209101661
            ],
            [
              5.1301773389705,
              45.267206969934
            ],
            [
              5.131354968047,
              45.286531093128
            ],
            [
              5.1385253524837,
              45.296216167914
            ],
            [
              5.1255573347389,
              45.298558532685
            ],
            [
              5.1122870741104,
              45.289758183582
            ],
            [
              5.0910708813282,
              45.286770048947
            ],
            [
              5.0754112531922,
              45.281827518251
            ],
            [
              5.0591074575312,
              45.313623430692
            ],
            [
              5.0543224224169,
              45.319051008949
            ],
            [
              5.0205931755981,
              45.319428254027
            ],
            [
              5.0096495089118,
              45.342230172467
            ],
            [
              4.9891862167152,
              45.344042230781
            ],
            [
              4.9595854101246,
              45.328918743796
            ],
            [
              4.9475761450964,
              45.328597843474
            ],
            [
              4.9284388130601,
              45.322951204602
            ],
            [
              4.9012637445124,
              45.310007436763
            ],
            [
              4.8802416809906,
              45.2974566456
            ],
            [
              4.8586100908504,
              45.29851618808
            ],
            [
              4.8588038161436,
              45.308951945608
            ],
            [
              4.8004899840029,
              45.298357121937
            ],
            [
              4.7889343017658,
              45.306705287082
            ],
            [
              4.7699315539545,
              45.31601418313
            ],
            [
              4.7605327238034,
              45.327460400164
            ],
            [
              4.7613883954348,
              45.34062730957
            ],
            [
              4.7736174113958,
              45.347659425308
            ],
            [
              4.7725568146969,
              45.35486629718
            ],
            [
              4.75599963206,
              45.365674921417
            ],
            [
              4.7590198368357,
              45.381472173695
            ],
            [
              4.7558093145859,
              45.396415196441
            ],
            [
              4.7441167394752,
              45.40888118257
            ],
            [
              4.7447605342399,
              45.421331112612
            ],
            [
              4.7586674371295,
              45.431094261452
            ],
            [
              4.7604638818845,
              45.437086232502
            ],
            [
              4.7555294013359,
              45.447048576794
            ],
            [
              4.7569316195483,
              45.455703808946
            ],
            [
              4.7794738263144,
              45.45503324486
            ],
            [
              4.7822796715264,
              45.472208128185
            ],
            [
              4.8120086150016,
              45.483343833967
            ],
            [
              4.8272424509878,
              45.49628139378
            ],
            [
              4.841259965977,
              45.500603648061
            ],
            [
              4.8684816431075,
              45.523437511407
            ],
            [
              4.8728116747039,
              45.531305267791
            ],
            [
              4.8644436168106,
              45.537346005002
            ],
            [
              4.8401221979115,
              45.543293629833
            ],
            [
              4.8311024088019,
              45.547770728452
            ],
            [
              4.8086931363098,
              45.572300631782
            ],
            [
              4.7820837628775,
              45.580581569271
            ],
            [
              4.7771292183341,
              45.587395458322
            ],
            [
              4.8034791539416,
              45.587537956161
            ],
            [
              4.810043763556,
              45.589552751649
            ],
            [
              4.8577266463597,
              45.5795777754
            ],
            [
              4.8597792137897,
              45.590828133064
            ],
            [
              4.8729020897275,
              45.595302229166
            ],
            [
              4.8821976306935,
              45.601571961358
            ],
            [
              4.8927769429114,
              45.601555646906
            ],
            [
              4.9014588479373,
              45.60626328191
            ],
            [
              4.9275304060187,
              45.605707819264
            ],
            [
              4.936077557335,
              45.608840645632
            ],
            [
              4.9603757463763,
              45.610073577685
            ],
            [
              4.9719782401764,
              45.612682039538
            ],
            [
              4.9979604182227,
              45.603410458278
            ],
            [
              5.0019895947765,
              45.61395165469
            ],
            [
              4.9894470341835,
              45.618525768193
            ],
            [
              5.004740525885,
              45.62311302936
            ],
            [
              5.0380861620656,
              45.615067173692
            ],
            [
              5.0436466037904,
              45.621332111414
            ],
            [
              5.0351462071679,
              45.637203894595
            ],
            [
              5.044542399742,
              45.647272778433
            ],
            [
              5.0580570584302,
              45.653246417357
            ],
            [
              5.0542255730691,
              45.660136259749
            ],
            [
              5.0773503823499,
              45.67469665728
            ],
            [
              5.0888320304231,
              45.67702542226
            ],
            [
              5.0942617281168,
              45.682878871985
            ],
            [
              5.1081345828339,
              45.688028491968
            ],
            [
              5.1048861162621,
              45.700378923523
            ],
            [
              5.1190903977565,
              45.699708184787
            ],
            [
              5.1310549291459,
              45.707712334796
            ],
            [
              5.1430567075695,
              45.70002864286
            ],
            [
              5.1592030475156,
              45.714572205586
            ],
            [
              5.1481741409716,
              45.718787127083
            ],
            [
              5.1339368816918,
              45.733254459565
            ],
            [
              5.1226621095427,
              45.737811043006
            ],
            [
              5.0945254918509,
              45.739450682898
            ],
            [
              5.089379542274,
              45.74965948259
            ],
            [
              5.093096251464,
              45.766087895262
            ],
            [
              5.0701066801383,
              45.765478936083
            ],
            [
              5.0595234976359,
              45.782545469588
            ],
            [
              5.0611720639467,
              45.791532339886
            ],
            [
              5.0893113585792,
              45.784275046022
            ],
            [
              5.101068799884,
              45.813378958875
            ],
            [
              5.1053919214171,
              45.808445750031
            ],
            [
              5.1255392074782,
              45.811063396795
            ],
            [
              5.144195778805,
              45.804567863303
            ],
            [
              5.1603637371378,
              45.802317387422
            ],
            [
              5.1768968364449,
              45.793483254007
            ],
            [
              5.1868098252677,
              45.782095115203
            ],
            [
              5.1915300805363,
              45.771685350507
            ],
            [
              5.207617133438,
              45.771815785907
            ],
            [
              5.2210769334942,
              45.768454465895
            ],
            [
              5.2668936711139,
              45.789370837093
            ],
            [
              5.2755531587012,
              45.800893358874
            ],
            [
              5.2889098684539,
              45.811121994364
            ],
            [
              5.2906249297479,
              45.82025986169
            ],
            [
              5.2998813382234,
              45.837302685138
            ],
            [
              5.3023555890522,
              45.847951056741
            ],
            [
              5.3091756488254,
              45.854928769742
            ],
            [
              5.3299682585029,
              45.864099868222
            ],
            [
              5.3409187894804,
              45.880509325682
            ],
            [
              5.3538322357139,
              45.883269928025
            ],
            [
              5.3709067264231,
              45.874977947139
            ],
            [
              5.3809175288182,
              45.867234143477
            ],
            [
              5.4131348727477,
              45.852221679893
            ],
            [
              5.4190015048359,
              45.839680703571
            ],
            [
              5.4349408141813,
              45.831093859803
            ],
            [
              5.4204751251472,
              45.818886657011
            ],
            [
              5.4226465285427,
              45.80712278512
            ],
            [
              5.4574039982348,
              45.780996812177
            ],
            [
              5.4825307295084,
              45.754588985706
            ],
            [
              5.5185478356945,
              45.730162983976
            ],
            [
              5.5272754539149,
              45.715508313157
            ],
            [
              5.5455440844452,
              45.713591599398
            ],
            [
              5.5534381913285,
              45.708998294688
            ],
            [
              5.5551113922636,
              45.700261621079
            ],
            [
              5.5711688852413,
              45.696872630898
            ],
            [
              5.5757522584809,
              45.691788289582
            ],
            [
              5.5703089690672,
              45.684426548161
            ],
            [
              5.5612096882931,
              45.686985356436
            ],
            [
              5.5559860481255,
              45.697625399596
            ],
            [
              5.5459463129561,
              45.697327503887
            ],
            [
              5.5451745235973,
              45.687400229628
            ],
            [
              5.5527423750432,
              45.679883465959
            ],
            [
              5.5541553698377,
              45.671790582993
            ],
            [
              5.563862776993,
              45.674167414335
            ],
            [
              5.5867550088025,
              45.665298168862
            ],
            [
              5.6030721965565,
              45.647684669153
            ],
            [
              5.6069435560033,
              45.635482392274
            ],
            [
              5.62374290574,
              45.613268446443
            ]
          ]
        ]
      },
      "properties": {
        "code": "38",
        "nom": "Isère",
        "population": 1277513,
        "delitspour1000": "4,657",
        "nombremosque": 56,
        "parentsEtrangers": "71,7",
        "benefRsa": 45331,
        "eoliennes": 7
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.047974391829,
              49.405644080344
            ],
            [
              4.067790651363,
              49.404938668274
            ],
            [
              4.0769173085895,
              49.401608188468
            ],
            [
              4.127096507032,
              49.401834316204
            ],
            [
              4.1431643234921,
              49.404190679456
            ],
            [
              4.1602201761661,
              49.401354902869
            ],
            [
              4.1763877307951,
              49.402223567335
            ],
            [
              4.1889969306987,
              49.398696021682
            ],
            [
              4.2013129683942,
              49.383787503175
            ],
            [
              4.2136709405145,
              49.387863154745
            ],
            [
              4.2342646077827,
              49.382310421792
            ],
            [
              4.2483151328731,
              49.382258801676
            ],
            [
              4.256035403346,
              49.367327772537
            ],
            [
              4.2771414688738,
              49.351460543702
            ],
            [
              4.2909328608358,
              49.346996088671
            ],
            [
              4.306756434583,
              49.327793732979
            ],
            [
              4.3308898833462,
              49.327153563894
            ],
            [
              4.346164956886,
              49.322252652692
            ],
            [
              4.3541597048245,
              49.316159444899
            ],
            [
              4.3756202848697,
              49.323806086041
            ],
            [
              4.3798375537257,
              49.313524957456
            ],
            [
              4.3934273749267,
              49.297089478883
            ],
            [
              4.407821521981,
              49.291118004752
            ],
            [
              4.4087339424872,
              49.28682410881
            ],
            [
              4.4225790423555,
              49.28064407894
            ],
            [
              4.4395351977871,
              49.280104950333
            ],
            [
              4.4472224785571,
              49.272534237553
            ],
            [
              4.4595009291629,
              49.277578709194
            ],
            [
              4.483839660863,
              49.280776737133
            ],
            [
              4.5154382721205,
              49.281908082456
            ],
            [
              4.5439876891761,
              49.288338157289
            ],
            [
              4.5558067039812,
              49.295114977855
            ],
            [
              4.5758736299471,
              49.29664842129
            ],
            [
              4.5836875668693,
              49.290902386143
            ],
            [
              4.5999803719844,
              49.288620424164
            ],
            [
              4.5897027741099,
              49.280136261725
            ],
            [
              4.5949756349039,
              49.270730016157
            ],
            [
              4.6058897640524,
              49.269704979896
            ],
            [
              4.610977783894,
              49.257053476907
            ],
            [
              4.6197163924279,
              49.246982665539
            ],
            [
              4.6251026499282,
              49.235653385532
            ],
            [
              4.6355140198289,
              49.232137951902
            ],
            [
              4.6455930103559,
              49.233149066031
            ],
            [
              4.6529134947007,
              49.238445863889
            ],
            [
              4.6703773904455,
              49.241513314897
            ],
            [
              4.686077487107,
              49.256757207882
            ],
            [
              4.7038118989835,
              49.255402005625
            ],
            [
              4.7151852750396,
              49.250404067194
            ],
            [
              4.7268218559424,
              49.252321445059
            ],
            [
              4.7442321422207,
              49.24147380968
            ],
            [
              4.7705584082231,
              49.244565572813
            ],
            [
              4.7888855791891,
              49.241342953304
            ],
            [
              4.8154203920087,
              49.249858555376
            ],
            [
              4.817715778612,
              49.242205770543
            ],
            [
              4.8269452277158,
              49.235758179516
            ],
            [
              4.868279158254,
              49.228510768771
            ],
            [
              4.8627552830681,
              49.241544470381
            ],
            [
              4.8782338575919,
              49.251565556752
            ],
            [
              4.8809209460769,
              49.261836038124
            ],
            [
              4.8942089005946,
              49.259072498398
            ],
            [
              4.9150358909471,
              49.265080531868
            ],
            [
              4.9215805432385,
              49.252112852003
            ],
            [
              4.9480408332939,
              49.24200784976
            ],
            [
              4.9509906730069,
              49.236867621535
            ],
            [
              4.9707577303293,
              49.236057819576
            ],
            [
              4.9780197007677,
              49.218934349355
            ],
            [
              4.9933759888652,
              49.209623589511
            ],
            [
              4.9800989235827,
              49.201778447188
            ],
            [
              4.9612735061957,
              49.197357841175
            ],
            [
              4.9397607283221,
              49.185070922974
            ],
            [
              4.9518030048523,
              49.17410342245
            ],
            [
              4.9641467046697,
              49.155815258864
            ],
            [
              4.9744041550883,
              49.133401842133
            ],
            [
              4.9921245963475,
              49.11490357014
            ],
            [
              4.9971847406275,
              49.104388466914
            ],
            [
              4.9964435329322,
              49.088167396547
            ],
            [
              4.9987054204657,
              49.076825011639
            ],
            [
              5.0059210382911,
              49.059419471656
            ],
            [
              4.9989434268935,
              49.049325301102
            ],
            [
              4.9686781571867,
              49.024739554079
            ],
            [
              4.9879031118833,
              49.027173298772
            ],
            [
              5.0076786336645,
              49.034951097618
            ],
            [
              5.0168141501855,
              49.03311869177
            ],
            [
              5.0363201841563,
              49.024335269381
            ],
            [
              5.037405311049,
              49.013961987093
            ],
            [
              5.0314289970476,
              49.006049726631
            ],
            [
              5.0326726595298,
              48.993738798801
            ],
            [
              5.0138368996792,
              48.995057601112
            ],
            [
              5.0035249328583,
              48.990778639869
            ],
            [
              4.9953582630028,
              48.982598875386
            ],
            [
              5.0099740972008,
              48.981850212238
            ],
            [
              5.0185199565787,
              48.977850483841
            ],
            [
              5.0277871563726,
              48.979273703253
            ],
            [
              5.0379027924329,
              48.97301158464
            ],
            [
              5.0301259805396,
              48.956903400655
            ],
            [
              5.0221528907788,
              48.947843989911
            ],
            [
              5.0152565779293,
              48.946097194214
            ],
            [
              5.0125842536926,
              48.936930774568
            ],
            [
              5.0002863602302,
              48.938258416319
            ],
            [
              4.9864012071849,
              48.931900243092
            ],
            [
              4.9583091196936,
              48.92707697787
            ],
            [
              4.9500350999827,
              48.930504233541
            ],
            [
              4.9362133507501,
              48.922138732999
            ],
            [
              4.9306078351393,
              48.91156065039
            ],
            [
              4.9157932199583,
              48.899796792981
            ],
            [
              4.9247005594818,
              48.891358244298
            ],
            [
              4.9130004169396,
              48.872143960831
            ],
            [
              4.9235108698405,
              48.861781460145
            ],
            [
              4.9280074084913,
              48.850029718286
            ],
            [
              4.9374937134933,
              48.847656242069
            ],
            [
              4.9356977655096,
              48.84062704651
            ],
            [
              4.9175085829673,
              48.835656479322
            ],
            [
              4.8887582394372,
              48.817210656662
            ],
            [
              4.896834961025,
              48.808710054979
            ],
            [
              4.8885820531146,
              48.80061371657
            ],
            [
              4.9022122643085,
              48.795455877483
            ],
            [
              4.9345688695301,
              48.790356335664
            ],
            [
              4.9379720566238,
              48.782494039612
            ],
            [
              4.949699806049,
              48.769988673569
            ],
            [
              4.9781495668786,
              48.75318087664
            ],
            [
              4.9820099984545,
              48.745127607624
            ],
            [
              4.99012310123,
              48.741834690164
            ],
            [
              5.0092185303043,
              48.741388830169
            ],
            [
              5.0073174936125,
              48.718017504748
            ],
            [
              5.0138967480706,
              48.710777200681
            ],
            [
              4.9972572781159,
              48.709595801806
            ],
            [
              5.0064063680002,
              48.699074659527
            ],
            [
              4.9884305305447,
              48.684422113631
            ],
            [
              4.9799035987368,
              48.687678909338
            ],
            [
              4.9525016444095,
              48.687323710714
            ],
            [
              4.9380905948576,
              48.675373788178
            ],
            [
              4.9112642126649,
              48.688711618117
            ],
            [
              4.8947952029835,
              48.678239949162
            ],
            [
              4.8751555454739,
              48.673597544142
            ],
            [
              4.8677704316646,
              48.667199526094
            ],
            [
              4.8545946407648,
              48.667084070993
            ],
            [
              4.8492500093844,
              48.671703473063
            ],
            [
              4.8227138769297,
              48.674027663375
            ],
            [
              4.8170779053142,
              48.680410704957
            ],
            [
              4.8027860885586,
              48.67717464256
            ],
            [
              4.7816199789859,
              48.669167975689
            ],
            [
              4.7718774865053,
              48.651876998827
            ],
            [
              4.8035841145337,
              48.654112128461
            ],
            [
              4.8419600326075,
              48.649667066507
            ],
            [
              4.8390577648134,
              48.645828673089
            ],
            [
              4.8505796211344,
              48.639913553347
            ],
            [
              4.849252010252,
              48.623630835615
            ],
            [
              4.8573704786259,
              48.614521343498
            ],
            [
              4.8315866479508,
              48.613114969287
            ],
            [
              4.821640739731,
              48.605634962009
            ],
            [
              4.8153984893093,
              48.60583019114
            ],
            [
              4.8056185398661,
              48.5983337293
            ],
            [
              4.791276481592,
              48.596401821127
            ],
            [
              4.7829695979364,
              48.598515896769
            ],
            [
              4.7691959833394,
              48.588691008545
            ],
            [
              4.7714535459794,
              48.572312382983
            ],
            [
              4.7760215384285,
              48.565958485774
            ],
            [
              4.7963249237962,
              48.568038915631
            ],
            [
              4.7834717130013,
              48.553474163913
            ],
            [
              4.7949392728324,
              48.539818265749
            ],
            [
              4.7991801924007,
              48.529782574269
            ],
            [
              4.7594097463137,
              48.535046912068
            ],
            [
              4.7235453621618,
              48.541477539056
            ],
            [
              4.7062785729089,
              48.53731860077
            ],
            [
              4.674926655985,
              48.537627672373
            ],
            [
              4.6701871837802,
              48.531884547152
            ],
            [
              4.6664723305305,
              48.537848153413
            ],
            [
              4.6501058876602,
              48.539851665814
            ],
            [
              4.635006842873,
              48.544471761508
            ],
            [
              4.6433629775436,
              48.553661794112
            ],
            [
              4.614026636402,
              48.553807130722
            ],
            [
              4.5819165021596,
              48.548308425178
            ],
            [
              4.5764888695536,
              48.549380112108
            ],
            [
              4.558952067243,
              48.543423837159
            ],
            [
              4.5483407438051,
              48.536257532883
            ],
            [
              4.5454110284704,
              48.525251319435
            ],
            [
              4.527473253111,
              48.52851621731
            ],
            [
              4.5271682681994,
              48.539952175108
            ],
            [
              4.5128701947233,
              48.546205419913
            ],
            [
              4.495278072804,
              48.538721815695
            ],
            [
              4.4681334829337,
              48.547190841194
            ],
            [
              4.4397307460444,
              48.549838664093
            ],
            [
              4.4030698243769,
              48.561257615364
            ],
            [
              4.3901744592,
              48.568873706499
            ],
            [
              4.3466748581423,
              48.597900553214
            ],
            [
              4.3306292643128,
              48.601957578075
            ],
            [
              4.3159081092834,
              48.615575295825
            ],
            [
              4.3317535766786,
              48.630271825369
            ],
            [
              4.3213683561975,
              48.646557197418
            ],
            [
              4.328384590545,
              48.656293909625
            ],
            [
              4.3228237057942,
              48.663846740382
            ],
            [
              4.3313089768531,
              48.669347763471
            ],
            [
              4.3337403788619,
              48.677131749929
            ],
            [
              4.3228942536686,
              48.683655839321
            ],
            [
              4.3268934225152,
              48.692095347687
            ],
            [
              4.325595348106,
              48.700276115124
            ],
            [
              4.3168987605976,
              48.706788155574
            ],
            [
              4.2954502821302,
              48.71316787475
            ],
            [
              4.2746079757227,
              48.710223232536
            ],
            [
              4.2635447030767,
              48.706272237985
            ],
            [
              4.2522555833693,
              48.708649150455
            ],
            [
              4.243107416189,
              48.716672523486
            ],
            [
              4.2330460828895,
              48.702313111437
            ],
            [
              4.1663812775746,
              48.707450693316
            ],
            [
              4.1489989828731,
              48.700578702023
            ],
            [
              4.1310882168837,
              48.686150324648
            ],
            [
              4.0802597224157,
              48.701069005389
            ],
            [
              4.0711835399108,
              48.694054472144
            ],
            [
              4.062814690952,
              48.682083671037
            ],
            [
              4.069033575877,
              48.679219709831
            ],
            [
              4.0559095644888,
              48.667079455181
            ],
            [
              4.031910392606,
              48.659949305712
            ],
            [
              4.0016901213002,
              48.663879061457
            ],
            [
              3.9864667097568,
              48.655065441589
            ],
            [
              3.9772253692371,
              48.632212799757
            ],
            [
              3.9684589928355,
              48.631668191665
            ],
            [
              3.9566174890079,
              48.619832027888
            ],
            [
              3.9492334056715,
              48.603429775124
            ],
            [
              3.9224268020071,
              48.606188091008
            ],
            [
              3.9084175961476,
              48.601748446727
            ],
            [
              3.906019097288,
              48.587843887488
            ],
            [
              3.9000598866521,
              48.574841427631
            ],
            [
              3.872571886765,
              48.58047880831
            ],
            [
              3.8542522481108,
              48.577217582885
            ],
            [
              3.8633324584851,
              48.56683550947
            ],
            [
              3.8586785303501,
              48.560338100981
            ],
            [
              3.8607919136421,
              48.533881448292
            ],
            [
              3.8530877821454,
              48.52551470793
            ],
            [
              3.8227434690573,
              48.516108006569
            ],
            [
              3.7978791431203,
              48.526691558139
            ],
            [
              3.7729993342911,
              48.529364971622
            ],
            [
              3.7671672471403,
              48.527708777816
            ],
            [
              3.7551991529879,
              48.533121903662
            ],
            [
              3.7308793763501,
              48.537466056167
            ],
            [
              3.7037465439415,
              48.533583335371
            ],
            [
              3.6843293088108,
              48.538886474309
            ],
            [
              3.6640934922177,
              48.536345606252
            ],
            [
              3.6504099319879,
              48.539240577921
            ],
            [
              3.6436337985267,
              48.536259310367
            ],
            [
              3.6340228482888,
              48.541297392553
            ],
            [
              3.6296285027063,
              48.559945728765
            ],
            [
              3.6324727467146,
              48.568227415825
            ],
            [
              3.6277119530337,
              48.575651578852
            ],
            [
              3.6157265911113,
              48.578622489749
            ],
            [
              3.6042499806083,
              48.572452740303
            ],
            [
              3.592707193337,
              48.587635090318
            ],
            [
              3.5825908612546,
              48.585460442144
            ],
            [
              3.579891852417,
              48.60486066415
            ],
            [
              3.5636022180892,
              48.606783841249
            ],
            [
              3.5632103097074,
              48.613224566217
            ],
            [
              3.555613758785,
              48.620285576065
            ],
            [
              3.54590310832,
              48.62806547987
            ],
            [
              3.5190643678345,
              48.633470112339
            ],
            [
              3.517498400146,
              48.643365240313
            ],
            [
              3.5031842529874,
              48.645682506087
            ],
            [
              3.4881941587649,
              48.644795887875
            ],
            [
              3.4765733783329,
              48.637329365265
            ],
            [
              3.4531162175594,
              48.633955984329
            ],
            [
              3.4604317354595,
              48.653009078358
            ],
            [
              3.4408489379625,
              48.66307286353
            ],
            [
              3.442691260176,
              48.672503410154
            ],
            [
              3.4545564374114,
              48.682119139778
            ],
            [
              3.4658318000834,
              48.68619250912
            ],
            [
              3.4769690535178,
              48.699355347111
            ],
            [
              3.4643082791983,
              48.707378088042
            ],
            [
              3.4674670222781,
              48.735224220894
            ],
            [
              3.440606129229,
              48.738701946832
            ],
            [
              3.4358103469779,
              48.753571711386
            ],
            [
              3.4256695187547,
              48.755689536249
            ],
            [
              3.4129613380945,
              48.753902050854
            ],
            [
              3.398657955437,
              48.755599281994
            ],
            [
              3.4008409030783,
              48.767256400859
            ],
            [
              3.4094228096892,
              48.783835572028
            ],
            [
              3.4282111529646,
              48.77948500421
            ],
            [
              3.442160723333,
              48.784353883555
            ],
            [
              3.4412895824977,
              48.800919300185
            ],
            [
              3.4104778884203,
              48.803943061752
            ],
            [
              3.4041649646308,
              48.80927626878
            ],
            [
              3.4166176467147,
              48.817828781451
            ],
            [
              3.4321664968613,
              48.812257770946
            ],
            [
              3.4544121814945,
              48.813840024336
            ],
            [
              3.4703815627087,
              48.8208976637
            ],
            [
              3.4809287140503,
              48.81218578416
            ],
            [
              3.4872017702391,
              48.815190371551
            ],
            [
              3.4849684486637,
              48.825009748881
            ],
            [
              3.4895742506519,
              48.839868156733
            ],
            [
              3.4851833721947,
              48.851910309574
            ],
            [
              3.4824053162481,
              48.864936735752
            ],
            [
              3.5021501743028,
              48.871150830362
            ],
            [
              3.5130610888281,
              48.894727857657
            ],
            [
              3.5285709402165,
              48.912136556763
            ],
            [
              3.5600217013966,
              48.917012631384
            ],
            [
              3.5698140355022,
              48.914705628852
            ],
            [
              3.5738483617762,
              48.921481440361
            ],
            [
              3.5744445323994,
              48.939019123403
            ],
            [
              3.5888878773911,
              48.943911238057
            ],
            [
              3.6015957850168,
              48.944073853473
            ],
            [
              3.599065082592,
              48.953591820396
            ],
            [
              3.5915529898914,
              48.960375393399
            ],
            [
              3.5991756397826,
              48.964609029368
            ],
            [
              3.6206544521613,
              48.965944000018
            ],
            [
              3.6245368960791,
              48.983751500727
            ],
            [
              3.6398926774554,
              49.00408594583
            ],
            [
              3.6651641207485,
              49.00563190452
            ],
            [
              3.6749656325958,
              49.01172340253
            ],
            [
              3.6774822917619,
              49.019164672527
            ],
            [
              3.6639547486924,
              49.037308063462
            ],
            [
              3.6463115589695,
              49.040590166906
            ],
            [
              3.6168408971943,
              49.03648384631
            ],
            [
              3.6150194069679,
              49.033500653248
            ],
            [
              3.5866997575834,
              49.035795925017
            ],
            [
              3.5877081614204,
              49.059394624683
            ],
            [
              3.6121535124357,
              49.073560405492
            ],
            [
              3.6237954864751,
              49.07086508435
            ],
            [
              3.6328719662821,
              49.072789507462
            ],
            [
              3.6390507423115,
              49.081304273735
            ],
            [
              3.6323412082709,
              49.086509017267
            ],
            [
              3.6200761430148,
              49.107193914382
            ],
            [
              3.6000114843908,
              49.120690012831
            ],
            [
              3.6103949951775,
              49.127543924136
            ],
            [
              3.6222521277141,
              49.151105463129
            ],
            [
              3.6468735208387,
              49.149122623512
            ],
            [
              3.6718043158638,
              49.150635019402
            ],
            [
              3.6838376358892,
              49.154490943481
            ],
            [
              3.6980322212385,
              49.149827639227
            ],
            [
              3.7016210668145,
              49.143083232127
            ],
            [
              3.717012841182,
              49.147040324959
            ],
            [
              3.7395699991233,
              49.156933396134
            ],
            [
              3.7487678456403,
              49.157085133246
            ],
            [
              3.7511423125744,
              49.177699202357
            ],
            [
              3.7372236091373,
              49.177961424911
            ],
            [
              3.7254385553209,
              49.173515978138
            ],
            [
              3.7043892552675,
              49.181369443488
            ],
            [
              3.7005485386662,
              49.187713241443
            ],
            [
              3.7048747423107,
              49.198590103722
            ],
            [
              3.6971034744514,
              49.205472659609
            ],
            [
              3.6839084510227,
              49.197534060825
            ],
            [
              3.6817942876508,
              49.205339977618
            ],
            [
              3.6584058277856,
              49.211000985544
            ],
            [
              3.6516085786612,
              49.221427253663
            ],
            [
              3.6650221926501,
              49.223221302369
            ],
            [
              3.6763725147129,
              49.23836076616
            ],
            [
              3.6707785326799,
              49.239600299061
            ],
            [
              3.6637955993399,
              49.253525125811
            ],
            [
              3.6552490233788,
              49.262847772673
            ],
            [
              3.665132135016,
              49.269657535247
            ],
            [
              3.6516094181931,
              49.278360707849
            ],
            [
              3.65731758003,
              49.291021541771
            ],
            [
              3.642801871342,
              49.296041342828
            ],
            [
              3.6383343630554,
              49.301354552133
            ],
            [
              3.646957318637,
              49.315152582535
            ],
            [
              3.6601616875884,
              49.318370386543
            ],
            [
              3.6703327730544,
              49.325093317382
            ],
            [
              3.6891002697559,
              49.326851228803
            ],
            [
              3.7012926310034,
              49.332508221298
            ],
            [
              3.7216167324835,
              49.335726303503
            ],
            [
              3.7422206958499,
              49.336511221485
            ],
            [
              3.7410811711412,
              49.347585887018
            ],
            [
              3.757818074306,
              49.347604354229
            ],
            [
              3.7744622374234,
              49.354639933222
            ],
            [
              3.7824442652216,
              49.352774492617
            ],
            [
              3.8015158122099,
              49.358931233427
            ],
            [
              3.8237576514434,
              49.356987454563
            ],
            [
              3.832778633819,
              49.350261851859
            ],
            [
              3.852156449301,
              49.344654924149
            ],
            [
              3.858709482185,
              49.353737278746
            ],
            [
              3.847475808218,
              49.364589335554
            ],
            [
              3.8573468410854,
              49.369945638107
            ],
            [
              3.8591446800636,
              49.381351797144
            ],
            [
              3.8787822154646,
              49.385857165921
            ],
            [
              3.9150740342564,
              49.399385348956
            ],
            [
              3.9244391664348,
              49.406179032892
            ],
            [
              3.9318314213659,
              49.403084958448
            ],
            [
              3.9424137621592,
              49.391461051265
            ],
            [
              3.9613104389204,
              49.377346727516
            ],
            [
              3.9913652648133,
              49.378222344205
            ],
            [
              4.0051087823761,
              49.369208523497
            ],
            [
              4.0127326189721,
              49.358302701884
            ],
            [
              4.0354977852679,
              49.359905945249
            ],
            [
              4.0399063933123,
              49.397393331324
            ],
            [
              4.047974391829,
              49.405644080344
            ]
          ]
        ]
      },
      "properties": {
        "code": "51",
        "nom": "Marne",
        "population": 566659,
        "delitspour1000": "6,041",
        "nombremosque": 23,
        "parentsEtrangers": "76,7",
        "benefRsa": 30079,
        "eoliennes": 469
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.6701871837802,
              48.531884547152
            ],
            [
              4.674926655985,
              48.537627672373
            ],
            [
              4.7062785729089,
              48.53731860077
            ],
            [
              4.7235453621618,
              48.541477539056
            ],
            [
              4.7594097463137,
              48.535046912068
            ],
            [
              4.7991801924007,
              48.529782574269
            ],
            [
              4.7949392728324,
              48.539818265749
            ],
            [
              4.7834717130013,
              48.553474163913
            ],
            [
              4.7963249237962,
              48.568038915631
            ],
            [
              4.7760215384285,
              48.565958485774
            ],
            [
              4.7714535459794,
              48.572312382983
            ],
            [
              4.7691959833394,
              48.588691008545
            ],
            [
              4.7829695979364,
              48.598515896769
            ],
            [
              4.791276481592,
              48.596401821127
            ],
            [
              4.8056185398661,
              48.5983337293
            ],
            [
              4.8153984893093,
              48.60583019114
            ],
            [
              4.821640739731,
              48.605634962009
            ],
            [
              4.8315866479508,
              48.613114969287
            ],
            [
              4.8573704786259,
              48.614521343498
            ],
            [
              4.849252010252,
              48.623630835615
            ],
            [
              4.8505796211344,
              48.639913553347
            ],
            [
              4.8390577648134,
              48.645828673089
            ],
            [
              4.8419600326075,
              48.649667066507
            ],
            [
              4.8035841145337,
              48.654112128461
            ],
            [
              4.7718774865053,
              48.651876998827
            ],
            [
              4.7816199789859,
              48.669167975689
            ],
            [
              4.8027860885586,
              48.67717464256
            ],
            [
              4.8170779053142,
              48.680410704957
            ],
            [
              4.8227138769297,
              48.674027663375
            ],
            [
              4.8492500093844,
              48.671703473063
            ],
            [
              4.8545946407648,
              48.667084070993
            ],
            [
              4.8677704316646,
              48.667199526094
            ],
            [
              4.8751555454739,
              48.673597544142
            ],
            [
              4.8947952029835,
              48.678239949162
            ],
            [
              4.9112642126649,
              48.688711618117
            ],
            [
              4.9380905948576,
              48.675373788178
            ],
            [
              4.9525016444095,
              48.687323710714
            ],
            [
              4.9799035987368,
              48.687678909338
            ],
            [
              4.9884305305447,
              48.684422113631
            ],
            [
              5.00253386288,
              48.682649301611
            ],
            [
              5.009009914557,
              48.678478877095
            ],
            [
              5.0107948326909,
              48.66815377214
            ],
            [
              4.9928144614844,
              48.658268690283
            ],
            [
              4.9973040687938,
              48.645702938649
            ],
            [
              5.0059214536855,
              48.634032872105
            ],
            [
              4.9946733915383,
              48.621828125073
            ],
            [
              4.9993220353425,
              48.614834346517
            ],
            [
              5.0141406619653,
              48.610210642074
            ],
            [
              5.032285807066,
              48.613298149197
            ],
            [
              5.0496885465717,
              48.631263178487
            ],
            [
              5.057748743685,
              48.629697103631
            ],
            [
              5.0595301156357,
              48.616290659601
            ],
            [
              5.0708911117702,
              48.595728770674
            ],
            [
              5.0956903941282,
              48.5928776962
            ],
            [
              5.1183529904094,
              48.595513437496
            ],
            [
              5.1178988434688,
              48.584955479137
            ],
            [
              5.1253365040036,
              48.582072896761
            ],
            [
              5.1343988410875,
              48.570822530063
            ],
            [
              5.1456350510637,
              48.562002271713
            ],
            [
              5.1650732858833,
              48.565344025751
            ],
            [
              5.1773515414927,
              48.554640660839
            ],
            [
              5.1968197773229,
              48.546680209836
            ],
            [
              5.1964658539815,
              48.538008021362
            ],
            [
              5.2084203909164,
              48.538609056785
            ],
            [
              5.2122544924731,
              48.528791624388
            ],
            [
              5.2321658494928,
              48.528593921213
            ],
            [
              5.2584002030652,
              48.521467536863
            ],
            [
              5.2962066847075,
              48.508405436941
            ],
            [
              5.3056222693629,
              48.515916893633
            ],
            [
              5.3142188294347,
              48.510272559977
            ],
            [
              5.3285067550225,
              48.507151257772
            ],
            [
              5.3307631034545,
              48.501876323935
            ],
            [
              5.3441897585249,
              48.491037218931
            ],
            [
              5.349473975465,
              48.481986800328
            ],
            [
              5.3679128362749,
              48.476989959693
            ],
            [
              5.3760194684462,
              48.472138911611
            ],
            [
              5.4008849824238,
              48.470480292577
            ],
            [
              5.406292964183,
              48.464887796388
            ],
            [
              5.403697425014,
              48.453393807796
            ],
            [
              5.4084984885085,
              48.445792781768
            ],
            [
              5.425033919482,
              48.436346493291
            ],
            [
              5.4396656658458,
              48.43454967262
            ],
            [
              5.445940857304,
              48.424569152476
            ],
            [
              5.4700548229834,
              48.420926283443
            ],
            [
              5.4437946750486,
              48.412793552672
            ],
            [
              5.4193856186614,
              48.395120580966
            ],
            [
              5.3944755711529,
              48.391004497394
            ],
            [
              5.4087226430654,
              48.383102860768
            ],
            [
              5.4415772107617,
              48.380227039336
            ],
            [
              5.418560018075,
              48.360694271013
            ],
            [
              5.4182235382907,
              48.339829235793
            ],
            [
              5.4257979057073,
              48.331424612905
            ],
            [
              5.4454174870765,
              48.337548748496
            ],
            [
              5.4614435085698,
              48.351008535928
            ],
            [
              5.4699445713636,
              48.349828790224
            ],
            [
              5.4821531055766,
              48.356198541709
            ],
            [
              5.4997162811397,
              48.354181000105
            ],
            [
              5.5259347078446,
              48.347094088677
            ],
            [
              5.5223686890105,
              48.340847392047
            ],
            [
              5.5265112322934,
              48.331736718524
            ],
            [
              5.5494339172574,
              48.309212376842
            ],
            [
              5.5811084891926,
              48.284092151604
            ],
            [
              5.5883254913252,
              48.274255206421
            ],
            [
              5.5986557013251,
              48.283859128519
            ],
            [
              5.6116505645448,
              48.291813565657
            ],
            [
              5.6538144936044,
              48.268521890367
            ],
            [
              5.6494700366852,
              48.254794047252
            ],
            [
              5.6408971957509,
              48.242355194462
            ],
            [
              5.662803981931,
              48.235339250635
            ],
            [
              5.6760858494894,
              48.229203496709
            ],
            [
              5.6877860896924,
              48.23418028384
            ],
            [
              5.7108479434936,
              48.219930933286
            ],
            [
              5.7300325067529,
              48.197959613175
            ],
            [
              5.7309798544964,
              48.189696947755
            ],
            [
              5.6967394115037,
              48.188881566005
            ],
            [
              5.6822393084,
              48.175705308685
            ],
            [
              5.6856197203779,
              48.162244100792
            ],
            [
              5.6812577084998,
              48.15075867206
            ],
            [
              5.6843229216395,
              48.145045600453
            ],
            [
              5.6771358185034,
              48.136700695253
            ],
            [
              5.6647771501347,
              48.129446531023
            ],
            [
              5.6568796713896,
              48.120400699552
            ],
            [
              5.6719848282194,
              48.109532293186
            ],
            [
              5.6674948715307,
              48.10655476293
            ],
            [
              5.6476689080621,
              48.104325979371
            ],
            [
              5.6391561616602,
              48.098220745531
            ],
            [
              5.6376207282395,
              48.08950814261
            ],
            [
              5.6305352541093,
              48.083984386541
            ],
            [
              5.6456101522469,
              48.078013185532
            ],
            [
              5.6484946182822,
              48.071542202301
            ],
            [
              5.661279627694,
              48.071989606586
            ],
            [
              5.6531765949008,
              48.084054660311
            ],
            [
              5.681240554071,
              48.080737903962
            ],
            [
              5.6872529897198,
              48.076020625786
            ],
            [
              5.6967989266543,
              48.077232264437
            ],
            [
              5.7002829787351,
              48.066601605915
            ],
            [
              5.7115569131225,
              48.062317740871
            ],
            [
              5.7269571428765,
              48.046031922901
            ],
            [
              5.7405717930607,
              48.050047677774
            ],
            [
              5.760967814331,
              48.036499444856
            ],
            [
              5.7655790134191,
              48.030011530377
            ],
            [
              5.7777453585169,
              48.031364684582
            ],
            [
              5.7806861985966,
              48.020926500466
            ],
            [
              5.7920725220415,
              47.999168067269
            ],
            [
              5.7937953925203,
              47.991691912896
            ],
            [
              5.7784867170861,
              47.978010814127
            ],
            [
              5.7855218231866,
              47.964121795723
            ],
            [
              5.7872807490879,
              47.95259247623
            ],
            [
              5.8047728054043,
              47.94757462553
            ],
            [
              5.8152676018325,
              47.957389502315
            ],
            [
              5.83157461884,
              47.957758607577
            ],
            [
              5.8400303571214,
              47.965021610731
            ],
            [
              5.8428079317049,
              47.975560673709
            ],
            [
              5.8535568852247,
              47.96988182732
            ],
            [
              5.8500037784374,
              47.962832349204
            ],
            [
              5.8572501814427,
              47.947896775593
            ],
            [
              5.8681774729335,
              47.945001844942
            ],
            [
              5.8843813392277,
              47.929927996017
            ],
            [
              5.884723650121,
              47.92604631497
            ],
            [
              5.8908642780035,
              47.910826243984
            ],
            [
              5.8864978594033,
              47.902632285568
            ],
            [
              5.8699685694528,
              47.900702448936
            ],
            [
              5.8483941586096,
              47.904328585027
            ],
            [
              5.8366827972636,
              47.885275579155
            ],
            [
              5.8214368328201,
              47.868177826395
            ],
            [
              5.8279856830918,
              47.851868490955
            ],
            [
              5.8053544944686,
              47.8473671425
            ],
            [
              5.7981943193063,
              47.852395843864
            ],
            [
              5.7611967718111,
              47.859337964072
            ],
            [
              5.7534185985443,
              47.851779809209
            ],
            [
              5.7441242763236,
              47.848675592832
            ],
            [
              5.7460508220444,
              47.823594453292
            ],
            [
              5.7326324521832,
              47.817595239681
            ],
            [
              5.7038164823427,
              47.822615914257
            ],
            [
              5.6902845680034,
              47.818602061169
            ],
            [
              5.6816907857215,
              47.801918539863
            ],
            [
              5.6766127410923,
              47.77915552638
            ],
            [
              5.6799723393154,
              47.76996692113
            ],
            [
              5.7050877892064,
              47.769109267163
            ],
            [
              5.7092177964874,
              47.763723848917
            ],
            [
              5.7061546847262,
              47.752289930468
            ],
            [
              5.7092157661502,
              47.744819092275
            ],
            [
              5.6933389025912,
              47.737536114776
            ],
            [
              5.6848345035363,
              47.722252488703
            ],
            [
              5.6841172724944,
              47.711966308717
            ],
            [
              5.6934923219824,
              47.703700407172
            ],
            [
              5.6946315676577,
              47.691589089204
            ],
            [
              5.6900715815111,
              47.684834261118
            ],
            [
              5.6602009774384,
              47.684701035958
            ],
            [
              5.6534107674437,
              47.677377804161
            ],
            [
              5.6350433227815,
              47.676729768703
            ],
            [
              5.616916763571,
              47.673506825098
            ],
            [
              5.6060928986115,
              47.675206717975
            ],
            [
              5.5966797529575,
              47.671689689304
            ],
            [
              5.580075717534,
              47.703119392386
            ],
            [
              5.5672035170122,
              47.705232598617
            ],
            [
              5.5429630425056,
              47.685875666578
            ],
            [
              5.529779465589,
              47.672825610683
            ],
            [
              5.5167714113012,
              47.673665697151
            ],
            [
              5.482566377733,
              47.684598405046
            ],
            [
              5.4725699801331,
              47.67672271053
            ],
            [
              5.4460337073379,
              47.670773959543
            ],
            [
              5.4357130635739,
              47.670913872806
            ],
            [
              5.4265210893308,
              47.675523872509
            ],
            [
              5.406340329799,
              47.673403498326
            ],
            [
              5.3984746606309,
              47.649083223607
            ],
            [
              5.4055917713039,
              47.647770719631
            ],
            [
              5.3868465136168,
              47.635662494107
            ],
            [
              5.3727580571009,
              47.618659790315
            ],
            [
              5.3740797661661,
              47.604538026913
            ],
            [
              5.3706351264232,
              47.604807955246
            ],
            [
              5.3547654342374,
              47.591360294209
            ],
            [
              5.3421656596273,
              47.597942682873
            ],
            [
              5.3399999375357,
              47.609069594411
            ],
            [
              5.3223487268523,
              47.612359526718
            ],
            [
              5.2998385942923,
              47.604925842894
            ],
            [
              5.2948617680378,
              47.599202098387
            ],
            [
              5.2787977512377,
              47.590248985309
            ],
            [
              5.2779061705699,
              47.581361382365
            ],
            [
              5.2529216592041,
              47.576950536437
            ],
            [
              5.248821805001,
              47.588304655805
            ],
            [
              5.2395818018336,
              47.595735741686
            ],
            [
              5.243335051832,
              47.603257395562
            ],
            [
              5.2586017166912,
              47.622216096373
            ],
            [
              5.2505435136739,
              47.622148375054
            ],
            [
              5.2392323119847,
              47.616130998734
            ],
            [
              5.2275663747918,
              47.630461719186
            ],
            [
              5.2153019734879,
              47.638776585158
            ],
            [
              5.1885815533539,
              47.6495597478
            ],
            [
              5.1735955223689,
              47.652564238401
            ],
            [
              5.173772704787,
              47.661555338996
            ],
            [
              5.1793190058471,
              47.679306571337
            ],
            [
              5.1613411137784,
              47.679935696643
            ],
            [
              5.1562768819218,
              47.668257175471
            ],
            [
              5.133419901405,
              47.650731358395
            ],
            [
              5.1088743414175,
              47.649687715835
            ],
            [
              5.1017263997014,
              47.659487818247
            ],
            [
              5.0849169001544,
              47.657252517205
            ],
            [
              5.0703648554737,
              47.666753831482
            ],
            [
              5.0578872394562,
              47.668305887057
            ],
            [
              5.0435832118687,
              47.676513563124
            ],
            [
              5.0606448449781,
              47.694789812394
            ],
            [
              5.0327945086565,
              47.692333079799
            ],
            [
              5.0327622841789,
              47.704076235268
            ],
            [
              5.0262670139789,
              47.709492979045
            ],
            [
              5.0043602413418,
              47.700727315079
            ],
            [
              4.9922739605095,
              47.688315326064
            ],
            [
              4.9791098413952,
              47.687763691185
            ],
            [
              4.9541385723681,
              47.701462932734
            ],
            [
              4.9570644317617,
              47.709406346338
            ],
            [
              4.9707033208711,
              47.727636116595
            ],
            [
              4.9598932138082,
              47.7544709258
            ],
            [
              4.9589919593761,
              47.761870409828
            ],
            [
              4.9496050950308,
              47.765006218324
            ],
            [
              4.9307398007716,
              47.761333148797
            ],
            [
              4.9177846968097,
              47.766614764213
            ],
            [
              4.9183050956308,
              47.777311690703
            ],
            [
              4.9564491304036,
              47.790048428128
            ],
            [
              4.9635761932156,
              47.795106816319
            ],
            [
              4.9824009031204,
              47.800350982387
            ],
            [
              4.9906198706093,
              47.80709265473
            ],
            [
              4.9941266376295,
              47.819746154602
            ],
            [
              4.9846956660801,
              47.828868610045
            ],
            [
              4.968346106104,
              47.831938726344
            ],
            [
              4.9622497289156,
              47.839702282837
            ],
            [
              4.9603856383733,
              47.857358065698
            ],
            [
              4.9540995648128,
              47.866767159307
            ],
            [
              4.9282357147154,
              47.871123778488
            ],
            [
              4.9281731599986,
              47.88687190538
            ],
            [
              4.9190920876169,
              47.894720464516
            ],
            [
              4.9018509649222,
              47.921284863427
            ],
            [
              4.8944964422884,
              47.922840464291
            ],
            [
              4.8693396784934,
              47.917449806377
            ],
            [
              4.8569990661674,
              47.89585146766
            ],
            [
              4.8340343917843,
              47.906781834116
            ],
            [
              4.8289423070305,
              47.915124211024
            ],
            [
              4.8467200449818,
              47.924483556015
            ],
            [
              4.8501722212977,
              47.929537855578
            ],
            [
              4.8660942377505,
              47.940508774674
            ],
            [
              4.8520473231564,
              47.956242158653
            ],
            [
              4.8410086317455,
              47.96075264808
            ],
            [
              4.8194243190699,
              47.960358692838
            ],
            [
              4.7865152517638,
              47.964201285162
            ],
            [
              4.7946342537178,
              47.983195170106
            ],
            [
              4.8091903248533,
              47.990087845923
            ],
            [
              4.7932639090855,
              47.996944239514
            ],
            [
              4.7890783024494,
              48.007828564444
            ],
            [
              4.7492966391968,
              48.004246587867
            ],
            [
              4.7199460898645,
              48.008895516311
            ],
            [
              4.7042329730873,
              48.020235152969
            ],
            [
              4.7081090656877,
              48.033381710979
            ],
            [
              4.7231559379207,
              48.045761135825
            ],
            [
              4.7104233134929,
              48.050518372019
            ],
            [
              4.7049737222143,
              48.059501984835
            ],
            [
              4.6888239532498,
              48.073129641608
            ],
            [
              4.6630026548561,
              48.076568913128
            ],
            [
              4.6698729996872,
              48.083179022032
            ],
            [
              4.6775039000903,
              48.081183188145
            ],
            [
              4.70448915836,
              48.088648110098
            ],
            [
              4.7084978779355,
              48.100127110314
            ],
            [
              4.7173906235138,
              48.102240831629
            ],
            [
              4.7279191203142,
              48.110798678754
            ],
            [
              4.7306077491836,
              48.118209937253
            ],
            [
              4.7480573376326,
              48.114659190057
            ],
            [
              4.7700375513937,
              48.113905506245
            ],
            [
              4.7901089375058,
              48.107760048044
            ],
            [
              4.8193111695308,
              48.103187863222
            ],
            [
              4.8356166412878,
              48.108554335428
            ],
            [
              4.8292931885425,
              48.129605655891
            ],
            [
              4.8500985100978,
              48.141757077316
            ],
            [
              4.8400698771028,
              48.14993356126
            ],
            [
              4.8354546176062,
              48.161726961473
            ],
            [
              4.8379503188033,
              48.168960575543
            ],
            [
              4.8496405146309,
              48.175773175663
            ],
            [
              4.8470897748332,
              48.180100963639
            ],
            [
              4.863174195777,
              48.201977664765
            ],
            [
              4.8578182606197,
              48.211650129465
            ],
            [
              4.8417279551891,
              48.214355583043
            ],
            [
              4.8352494219065,
              48.221060786024
            ],
            [
              4.8538598270306,
              48.228760631994
            ],
            [
              4.8432103541774,
              48.247114695633
            ],
            [
              4.858914512777,
              48.263637676715
            ],
            [
              4.842391580845,
              48.283573662617
            ],
            [
              4.8508437809167,
              48.296248449436
            ],
            [
              4.8418181838439,
              48.297799554181
            ],
            [
              4.8265149985509,
              48.306013188598
            ],
            [
              4.8230501940522,
              48.315038323492
            ],
            [
              4.8142122475715,
              48.323012708298
            ],
            [
              4.8299689053064,
              48.33468012692
            ],
            [
              4.8413463624966,
              48.33955694951
            ],
            [
              4.8302192144231,
              48.34533428475
            ],
            [
              4.8097376980768,
              48.347358004429
            ],
            [
              4.7943304441028,
              48.356648380458
            ],
            [
              4.7786528009155,
              48.356182880224
            ],
            [
              4.7547237556079,
              48.366678135197
            ],
            [
              4.7601875463212,
              48.387613359286
            ],
            [
              4.745028000658,
              48.392439532205
            ],
            [
              4.7161010006502,
              48.394852352273
            ],
            [
              4.7083518456451,
              48.401178797061
            ],
            [
              4.6772465666729,
              48.433105127596
            ],
            [
              4.6703471289995,
              48.445806459269
            ],
            [
              4.6357265412862,
              48.456922766059
            ],
            [
              4.6268310932286,
              48.468076675502
            ],
            [
              4.6534621243174,
              48.470552171278
            ],
            [
              4.6526058839457,
              48.48113680994
            ],
            [
              4.6587946227005,
              48.492212538939
            ],
            [
              4.6569743792742,
              48.499860087897
            ],
            [
              4.6613583252217,
              48.509394194514
            ],
            [
              4.6688521451916,
              48.510496690653
            ],
            [
              4.6764590707999,
              48.51915350384
            ],
            [
              4.6701871837802,
              48.531884547152
            ]
          ]
        ]
      },
      "properties": {
        "code": "52",
        "nom": "Haute-Marne",
        "population": 171798,
        "delitspour1000": "5,215",
        "nombremosque": 12,
        "parentsEtrangers": "88,0",
        "benefRsa": 8308,
        "eoliennes": 222
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              7.6352815933424,
              49.054164206261
            ],
            [
              7.6347067798308,
              49.042968049126
            ],
            [
              7.6095521813444,
              49.021232064924
            ],
            [
              7.6070069675937,
              49.009582660743
            ],
            [
              7.5912105405094,
              48.993725181781
            ],
            [
              7.5923311699453,
              48.984840647736
            ],
            [
              7.5814073737185,
              48.971055051207
            ],
            [
              7.5845194350486,
              48.966802481603
            ],
            [
              7.5783945474788,
              48.957398722989
            ],
            [
              7.5638233195762,
              48.950986666617
            ],
            [
              7.5515367574775,
              48.941156060195
            ],
            [
              7.5561387855791,
              48.935832330647
            ],
            [
              7.5382559219775,
              48.932957199392
            ],
            [
              7.5264265489155,
              48.941454002925
            ],
            [
              7.4970804391444,
              48.946222036435
            ],
            [
              7.4851638896843,
              48.950681558293
            ],
            [
              7.4795692942305,
              48.961387563595
            ],
            [
              7.4598257062034,
              48.962370134773
            ],
            [
              7.4468936659225,
              48.965782537324
            ],
            [
              7.4328807123666,
              48.961903080414
            ],
            [
              7.3974785888727,
              48.956445019387
            ],
            [
              7.3923145989853,
              48.952710432441
            ],
            [
              7.3692017508039,
              48.952248088014
            ],
            [
              7.3588755232568,
              48.957605614769
            ],
            [
              7.3481359942558,
              48.950943288743
            ],
            [
              7.3268481155836,
              48.943375583481
            ],
            [
              7.3024327595623,
              48.956470709066
            ],
            [
              7.2912378631909,
              48.956648934867
            ],
            [
              7.2930505096429,
              48.973419993633
            ],
            [
              7.2360269811716,
              48.987815168101
            ],
            [
              7.2093166954507,
              48.980085251586
            ],
            [
              7.1959774900033,
              48.993604617056
            ],
            [
              7.1837071637212,
              48.994778146642
            ],
            [
              7.1820536883691,
              49.001652364499
            ],
            [
              7.1742389780393,
              49.006366626799
            ],
            [
              7.1305056457022,
              49.004589914922
            ],
            [
              7.1217983031735,
              49.022563807628
            ],
            [
              7.1194733208385,
              49.037217568319
            ],
            [
              7.1076635795824,
              49.044108799815
            ],
            [
              7.1016822948106,
              49.063729853332
            ],
            [
              7.1133119786809,
              49.064743743706
            ],
            [
              7.1109699086532,
              49.071857597144
            ],
            [
              7.099344679949,
              49.077884925649
            ],
            [
              7.0836427264995,
              49.068670075954
            ],
            [
              7.075924326097,
              49.073990440304
            ],
            [
              7.0681059794001,
              49.066882709203
            ],
            [
              7.0691514510543,
              49.059900238733
            ],
            [
              7.078657018389,
              49.050358924621
            ],
            [
              7.0549121231097,
              49.030621881695
            ],
            [
              7.0486721401747,
              49.018354464191
            ],
            [
              7.0530006492118,
              49.007294818857
            ],
            [
              7.0383544772138,
              48.996876342628
            ],
            [
              7.0308791462263,
              48.985879416935
            ],
            [
              7.0386891228621,
              48.978013606454
            ],
            [
              7.0272582662636,
              48.966569743904
            ],
            [
              7.0322221462463,
              48.956048112426
            ],
            [
              6.9892133372901,
              48.959924389065
            ],
            [
              6.9870095302313,
              48.94802708526
            ],
            [
              6.9788871720834,
              48.933339809497
            ],
            [
              6.9608225491544,
              48.925350724448
            ],
            [
              6.9443993368701,
              48.934799489837
            ],
            [
              6.9403717864006,
              48.923927611506
            ],
            [
              6.9555419328142,
              48.922322591857
            ],
            [
              6.9646057074582,
              48.904524234117
            ],
            [
              6.9546718652654,
              48.893890344954
            ],
            [
              6.975494884565,
              48.890110019382
            ],
            [
              6.988917207927,
              48.89118951936
            ],
            [
              7.0075363595826,
              48.884346968873
            ],
            [
              7.027124762811,
              48.87980451616
            ],
            [
              7.0453494187227,
              48.871545317597
            ],
            [
              7.0502424744293,
              48.859345451322
            ],
            [
              7.0811617503575,
              48.864104213578
            ],
            [
              7.0957199498063,
              48.86214489256
            ],
            [
              7.0981114372419,
              48.852855904629
            ],
            [
              7.086621157518,
              48.847742486374
            ],
            [
              7.0632994003369,
              48.848333990496
            ],
            [
              7.0513316660602,
              48.846282688184
            ],
            [
              7.0545137622898,
              48.833284733109
            ],
            [
              7.047118425674,
              48.820636260338
            ],
            [
              7.0545188301304,
              48.813706942265
            ],
            [
              7.0620072040144,
              48.815696701106
            ],
            [
              7.0793070341102,
              48.814035537355
            ],
            [
              7.0891249837893,
              48.806773609574
            ],
            [
              7.0671939837244,
              48.799480443377
            ],
            [
              7.0778230489578,
              48.785578755501
            ],
            [
              7.088164004341,
              48.792490993201
            ],
            [
              7.1119119024702,
              48.799016252531
            ],
            [
              7.1267966008006,
              48.801145146585
            ],
            [
              7.1237748421701,
              48.811766606831
            ],
            [
              7.1466649693409,
              48.821305779987
            ],
            [
              7.1412706412176,
              48.833893909532
            ],
            [
              7.1494099127444,
              48.844705787321
            ],
            [
              7.176453943453,
              48.842122985818
            ],
            [
              7.1846205353403,
              48.850245274866
            ],
            [
              7.1958804788997,
              48.840394252637
            ],
            [
              7.1878318748607,
              48.833929235992
            ],
            [
              7.2184018592488,
              48.819517440754
            ],
            [
              7.2286555719709,
              48.828037920731
            ],
            [
              7.2429785271147,
              48.817621212373
            ],
            [
              7.2476583699836,
              48.809648890138
            ],
            [
              7.2620309927567,
              48.79776134254
            ],
            [
              7.2704862686317,
              48.80253459505
            ],
            [
              7.2912262536697,
              48.79399286624
            ],
            [
              7.2915436633852,
              48.784503341757
            ],
            [
              7.3119029263128,
              48.767297153936
            ],
            [
              7.310850516241,
              48.763203502383
            ],
            [
              7.2959177661537,
              48.755313013071
            ],
            [
              7.2913587067135,
              48.749108901893
            ],
            [
              7.2868804652201,
              48.73134428749
            ],
            [
              7.2669990444847,
              48.714369494768
            ],
            [
              7.2639424681146,
              48.698645442814
            ],
            [
              7.2601696827824,
              48.694299105119
            ],
            [
              7.2424838370011,
              48.691546869433
            ],
            [
              7.2619865507141,
              48.669979110411
            ],
            [
              7.2646008192286,
              48.662701785192
            ],
            [
              7.2827944470062,
              48.660285985831
            ],
            [
              7.3043497385752,
              48.660204189323
            ],
            [
              7.2830506425965,
              48.638110598789
            ],
            [
              7.2732932655448,
              48.633307766643
            ],
            [
              7.2784275025088,
              48.625198796078
            ],
            [
              7.2707781932556,
              48.612499029537
            ],
            [
              7.2583999271862,
              48.602642740722
            ],
            [
              7.262812241024,
              48.590281985422
            ],
            [
              7.2562785812957,
              48.588671113482
            ],
            [
              7.2193103198617,
              48.553769990292
            ],
            [
              7.2013562980103,
              48.552699958758
            ],
            [
              7.1924857120791,
              48.548794699192
            ],
            [
              7.1871006559345,
              48.537271133109
            ],
            [
              7.1718405152913,
              48.532420201995
            ],
            [
              7.16790822618,
              48.527591999423
            ],
            [
              7.1470879758322,
              48.52694525177
            ],
            [
              7.1275722617052,
              48.53133186834
            ],
            [
              7.1143777486324,
              48.537386238774
            ],
            [
              7.0921481404601,
              48.529042689021
            ],
            [
              7.079355782655,
              48.536418650433
            ],
            [
              7.0838688655777,
              48.545333410649
            ],
            [
              7.0587215908209,
              48.550707821185
            ],
            [
              7.0457587140991,
              48.560077038512
            ],
            [
              7.0342434542577,
              48.580419323444
            ],
            [
              7.0227697690835,
              48.579001250365
            ],
            [
              6.9945079286004,
              48.595965468544
            ],
            [
              7.0166944370945,
              48.603987344415
            ],
            [
              7.009348893232,
              48.611859349561
            ],
            [
              6.9981294531006,
              48.608177592563
            ],
            [
              6.982883801257,
              48.611865580209
            ],
            [
              6.9607924698341,
              48.608281747074
            ],
            [
              6.9408851853137,
              48.616504527069
            ],
            [
              6.934223182594,
              48.633326978431
            ],
            [
              6.9197114217307,
              48.633757996621
            ],
            [
              6.9186178721451,
              48.626091118856
            ],
            [
              6.9051215287099,
              48.61984323664
            ],
            [
              6.8930299222558,
              48.625054004779
            ],
            [
              6.8636010223891,
              48.620311412276
            ],
            [
              6.8440506942674,
              48.62597329524
            ],
            [
              6.8416184753145,
              48.635214145468
            ],
            [
              6.8260372985364,
              48.644824072245
            ],
            [
              6.8064304816622,
              48.64669342932
            ],
            [
              6.782490099168,
              48.642723209857
            ],
            [
              6.7639559352142,
              48.65209681876
            ],
            [
              6.759386613539,
              48.658938160507
            ],
            [
              6.7615835198083,
              48.667499128523
            ],
            [
              6.7546715595276,
              48.669387591641
            ],
            [
              6.7338519526537,
              48.663759342524
            ],
            [
              6.7260796976867,
              48.664416940975
            ],
            [
              6.7123854189742,
              48.673242237639
            ],
            [
              6.6922783279755,
              48.672838766601
            ],
            [
              6.6879197306857,
              48.683630505731
            ],
            [
              6.6753365501083,
              48.672527902585
            ],
            [
              6.6665029232238,
              48.671163093077
            ],
            [
              6.6764059809526,
              48.688850403381
            ],
            [
              6.6705060090375,
              48.697671809743
            ],
            [
              6.6584083727001,
              48.70804275227
            ],
            [
              6.642417314704,
              48.702520410672
            ],
            [
              6.6222369528028,
              48.713108861684
            ],
            [
              6.606561214873,
              48.716842476495
            ],
            [
              6.5989711260034,
              48.715692430866
            ],
            [
              6.5948285125576,
              48.729217443998
            ],
            [
              6.5793907825643,
              48.735108086284
            ],
            [
              6.5642548290865,
              48.749951230242
            ],
            [
              6.5541471102694,
              48.754088057977
            ],
            [
              6.5379208335892,
              48.751011291365
            ],
            [
              6.5154955892583,
              48.752018850786
            ],
            [
              6.4896213967751,
              48.765572109235
            ],
            [
              6.4656072950901,
              48.767668917366
            ],
            [
              6.4536071617557,
              48.765889394207
            ],
            [
              6.4509923494912,
              48.779453424429
            ],
            [
              6.4405959623809,
              48.781451291737
            ],
            [
              6.4335148612716,
              48.789039690367
            ],
            [
              6.3944359664073,
              48.775269090599
            ],
            [
              6.3890646192107,
              48.780411556798
            ],
            [
              6.3714175361042,
              48.784638840315
            ],
            [
              6.3550354567534,
              48.781656974575
            ],
            [
              6.3538300330436,
              48.798815071833
            ],
            [
              6.3493139281181,
              48.807885903195
            ],
            [
              6.3330191957468,
              48.813906369716
            ],
            [
              6.3306501267109,
              48.823308805141
            ],
            [
              6.3100797932181,
              48.82110876293
            ],
            [
              6.3159597313233,
              48.829880545286
            ],
            [
              6.3259847078115,
              48.82554417097
            ],
            [
              6.335297537139,
              48.826122687614
            ],
            [
              6.3389481809645,
              48.834053669143
            ],
            [
              6.3033320048009,
              48.838180107597
            ],
            [
              6.2913932698219,
              48.842510406727
            ],
            [
              6.2854720614818,
              48.849428578085
            ],
            [
              6.2994301917746,
              48.851691482549
            ],
            [
              6.2973761453173,
              48.866211903942
            ],
            [
              6.3117190662122,
              48.86382643255
            ],
            [
              6.3105234382938,
              48.873495122663
            ],
            [
              6.2982668306514,
              48.880723838846
            ],
            [
              6.3029672702425,
              48.886686860966
            ],
            [
              6.3269152576698,
              48.896613037099
            ],
            [
              6.3272620439057,
              48.905195596255
            ],
            [
              6.309473407905,
              48.912439205021
            ],
            [
              6.3045009353332,
              48.918642956423
            ],
            [
              6.2883427176889,
              48.916408318952
            ],
            [
              6.2877963569274,
              48.927683814703
            ],
            [
              6.2773099040757,
              48.928048628655
            ],
            [
              6.2719751529819,
              48.933137468562
            ],
            [
              6.251302571332,
              48.926691899361
            ],
            [
              6.2366101793887,
              48.924897404316
            ],
            [
              6.2266542346335,
              48.927310788457
            ],
            [
              6.2257308062106,
              48.93693156726
            ],
            [
              6.2039209729506,
              48.937668068392
            ],
            [
              6.1771249569164,
              48.934926691997
            ],
            [
              6.1623292084787,
              48.94287438802
            ],
            [
              6.1487103503012,
              48.937772978227
            ],
            [
              6.1322069836653,
              48.934718252101
            ],
            [
              6.1245893566756,
              48.94145628878
            ],
            [
              6.1246128621713,
              48.956895204277
            ],
            [
              6.1185450391289,
              48.958918826648
            ],
            [
              6.1169037215408,
              48.967828571265
            ],
            [
              6.0936938964985,
              48.967589301522
            ],
            [
              6.0813267187984,
              48.963048293929
            ],
            [
              6.0739482568241,
              48.969478639579
            ],
            [
              6.0737826614724,
              48.980635404683
            ],
            [
              6.0619283896793,
              48.977906059174
            ],
            [
              6.0434337487251,
              48.977474356417
            ],
            [
              6.0468830607035,
              48.995729066839
            ],
            [
              6.0450035747376,
              49.01041495307
            ],
            [
              6.0186479063776,
              49.024958351084
            ],
            [
              6.0165389874431,
              49.035287465653
            ],
            [
              6.004800366036,
              49.034902514221
            ],
            [
              5.9851287189917,
              49.041918757214
            ],
            [
              5.9715696879425,
              49.040954914459
            ],
            [
              5.9595168540519,
              49.047119309838
            ],
            [
              5.9532043099409,
              49.054006949201
            ],
            [
              5.9510283819008,
              49.064561763405
            ],
            [
              5.9353969045754,
              49.071851388062
            ],
            [
              5.9398033845286,
              49.085828436344
            ],
            [
              5.9246765466348,
              49.092125059986
            ],
            [
              5.9315992811089,
              49.109106235426
            ],
            [
              5.9973972069234,
              49.107839996346
            ],
            [
              6.0049185226691,
              49.113986517555
            ],
            [
              5.9954710033768,
              49.121625385708
            ],
            [
              5.9910062735317,
              49.135587048488
            ],
            [
              5.9813793558344,
              49.144452574544
            ],
            [
              6.0120419184564,
              49.154327146881
            ],
            [
              6.0261444602533,
              49.165119073785
            ],
            [
              6.0022439786632,
              49.185007177599
            ],
            [
              5.9828372221568,
              49.186517850118
            ],
            [
              5.9822673554929,
              49.195513428251
            ],
            [
              5.9876300396259,
              49.209616849127
            ],
            [
              6.0035212458748,
              49.208969773422
            ],
            [
              6.0095705639519,
              49.221790166976
            ],
            [
              6.0209907541813,
              49.223355889171
            ],
            [
              6.0293909262549,
              49.230472752632
            ],
            [
              6.0253226190536,
              49.243252852673
            ],
            [
              6.0273394946232,
              49.252606188824
            ],
            [
              6.0066599302753,
              49.260280179085
            ],
            [
              6.0056295013464,
              49.274131163886
            ],
            [
              6.0009437369965,
              49.281486944737
            ],
            [
              6.0063890612557,
              49.307960359999
            ],
            [
              5.9971191836138,
              49.31424140241
            ],
            [
              5.9852221215853,
              49.30516988712
            ],
            [
              5.944010745274,
              49.334643541614
            ],
            [
              5.9433902263433,
              49.340292380259
            ],
            [
              5.961300724503,
              49.347783910873
            ],
            [
              5.9561223859101,
              49.361591418656
            ],
            [
              5.9418694339375,
              49.362524823511
            ],
            [
              5.9285345092754,
              49.375503600542
            ],
            [
              5.9330996232261,
              49.384143038614
            ],
            [
              5.9295648718633,
              49.401892423859
            ],
            [
              5.9119203012586,
              49.407714315408
            ],
            [
              5.9270260582338,
              49.424664846592
            ],
            [
              5.9422868054224,
              49.430349715627
            ],
            [
              5.9308876511318,
              49.446283457547
            ],
            [
              5.9426197449697,
              49.452891944313
            ],
            [
              5.9351888249367,
              49.469574525054
            ],
            [
              5.9366368899289,
              49.477654604854
            ],
            [
              5.9279359592393,
              49.483237223515
            ],
            [
              5.9028821330724,
              49.483789799428
            ],
            [
              5.8934039932125,
              49.496912378689
            ],
            [
              5.9131228430979,
              49.501930487596
            ],
            [
              5.9275784580358,
              49.498222137522
            ],
            [
              5.9399396363651,
              49.500973515404
            ],
            [
              5.9543549817944,
              49.494028742166
            ],
            [
              5.971776634974,
              49.491320067133
            ],
            [
              5.9719898548951,
              49.472772002285
            ],
            [
              5.9786602517285,
              49.461213469236
            ],
            [
              5.9876579090328,
              49.462416479402
            ],
            [
              5.9889818118401,
              49.453330179107
            ],
            [
              6.0020130392406,
              49.455793300138
            ],
            [
              6.0091636987779,
              49.452599066022
            ],
            [
              6.0276447821787,
              49.455537293057
            ],
            [
              6.0278782998684,
              49.448439296043
            ],
            [
              6.0423723359911,
              49.447931072295
            ],
            [
              6.0556220526452,
              49.465361612667
            ],
            [
              6.0767052592775,
              49.46365936715
            ],
            [
              6.0812989556481,
              49.458562227473
            ],
            [
              6.100815774202,
              49.452882062635
            ],
            [
              6.0978706962197,
              49.464052470472
            ],
            [
              6.1020780284711,
              49.470224838021
            ],
            [
              6.123492261297,
              49.473753515732
            ],
            [
              6.1276565854344,
              49.492885551721
            ],
            [
              6.1438043282733,
              49.487268725521
            ],
            [
              6.1569057576806,
              49.497456281788
            ],
            [
              6.1568855820521,
              49.504390732514
            ],
            [
              6.1807922123531,
              49.507740334246
            ],
            [
              6.1972680839746,
              49.505966578682
            ],
            [
              6.2564100413301,
              49.510019040716
            ],
            [
              6.2624116466706,
              49.504724182106
            ],
            [
              6.2790979434498,
              49.503352708062
            ],
            [
              6.2801065837471,
              49.494398595906
            ],
            [
              6.296575175601,
              49.480106832386
            ],
            [
              6.3124934980479,
              49.480320202438
            ],
            [
              6.3328228233983,
              49.469493947419
            ],
            [
              6.3424701827187,
              49.469156069631
            ],
            [
              6.3643965239711,
              49.459484546704
            ],
            [
              6.3666661689717,
              49.466918673264
            ],
            [
              6.3919596630846,
              49.464460320335
            ],
            [
              6.4076179611636,
              49.467512030949
            ],
            [
              6.4190855362062,
              49.474939817898
            ],
            [
              6.4301197078959,
              49.476400021233
            ],
            [
              6.4395895671046,
              49.467281846999
            ],
            [
              6.4700838654605,
              49.462767275295
            ],
            [
              6.4848977061868,
              49.452470714368
            ],
            [
              6.4976632893167,
              49.450631546424
            ],
            [
              6.5211006756686,
              49.437323933302
            ],
            [
              6.5329615902039,
              49.435378532848
            ],
            [
              6.5522345196475,
              49.423348210636
            ],
            [
              6.5543620111982,
              49.418383989553
            ],
            [
              6.5387980688369,
              49.412379406349
            ],
            [
              6.5405242817617,
              49.401146060427
            ],
            [
              6.563422078373,
              49.388145785152
            ],
            [
              6.5735876662047,
              49.389262822438
            ],
            [
              6.5870798611261,
              49.384936563478
            ],
            [
              6.5838802640069,
              49.368622950588
            ],
            [
              6.5943948345255,
              49.371471620308
            ],
            [
              6.6018563427332,
              49.366781011608
            ],
            [
              6.5901277643478,
              49.354745146744
            ],
            [
              6.5746297463216,
              49.358198402077
            ],
            [
              6.5647703710395,
              49.353135042025
            ],
            [
              6.5658428249466,
              49.347352742548
            ],
            [
              6.5775969804184,
              49.335791607751
            ],
            [
              6.5854756155499,
              49.336044725988
            ],
            [
              6.5955861357867,
              49.330270386186
            ],
            [
              6.5890778127358,
              49.321999253582
            ],
            [
              6.6175388153446,
              49.302170593505
            ],
            [
              6.6253921808815,
              49.302190458195
            ],
            [
              6.6514018837136,
              49.285835177653
            ],
            [
              6.6532277371416,
              49.281116548287
            ],
            [
              6.6691862190043,
              49.28060241979
            ],
            [
              6.6678733285273,
              49.271339809982
            ],
            [
              6.6603276651427,
              49.260969691767
            ],
            [
              6.6644435280076,
              49.254561265753
            ],
            [
              6.6780682329599,
              49.255060668414
            ],
            [
              6.6905439593201,
              49.247633562325
            ],
            [
              6.6850959557718,
              49.243739619385
            ],
            [
              6.6895788875244,
              49.23533441597
            ],
            [
              6.6881350897594,
              49.224445656782
            ],
            [
              6.6948030976287,
              49.215906501206
            ],
            [
              6.7196514167899,
              49.221375153791
            ],
            [
              6.7314966006431,
              49.20608920408
            ],
            [
              6.7113631909174,
              49.188538133309
            ],
            [
              6.7209675062078,
              49.174994832853
            ],
            [
              6.7385082593927,
              49.163661266785
            ],
            [
              6.7538830937062,
              49.166177108553
            ],
            [
              6.7845561626242,
              49.168086631556
            ],
            [
              6.8345431950591,
              49.151466537904
            ],
            [
              6.8438548055183,
              49.15565290074
            ],
            [
              6.8472694874904,
              49.173487755725
            ],
            [
              6.8609474837466,
              49.178714159357
            ],
            [
              6.8586496056677,
              49.185917677931
            ],
            [
              6.8501173934685,
              49.193288221075
            ],
            [
              6.8521803808921,
              49.198655232881
            ],
            [
              6.8353839856274,
              49.211325473776
            ],
            [
              6.8574425399538,
              49.22227794129
            ],
            [
              6.8713243769655,
              49.215472554027
            ],
            [
              6.8941557656397,
              49.210197254284
            ],
            [
              6.9274131306062,
              49.22252636726
            ],
            [
              6.9354186816977,
              49.222156163157
            ],
            [
              6.9517226192342,
              49.207276845843
            ],
            [
              6.9592812915983,
              49.203004868528
            ],
            [
              6.9746332903262,
              49.209818267623
            ],
            [
              6.9915096208719,
              49.201388059087
            ],
            [
              7.0107084883023,
              49.18812033997
            ],
            [
              7.0213917004814,
              49.19318400175
            ],
            [
              7.0343248906795,
              49.189680638467
            ],
            [
              7.0276496496344,
              49.170085953929
            ],
            [
              7.0316782380222,
              49.158253423186
            ],
            [
              7.046140831118,
              49.138567199151
            ],
            [
              7.0446219986815,
              49.121956113571
            ],
            [
              7.0497586225268,
              49.114534084003
            ],
            [
              7.0660953113297,
              49.114582077807
            ],
            [
              7.0719376289158,
              49.124748468913
            ],
            [
              7.0888751761417,
              49.130224426302
            ],
            [
              7.080281154448,
              49.148775932362
            ],
            [
              7.097848351075,
              49.155036981759
            ],
            [
              7.1132659041816,
              49.151694619011
            ],
            [
              7.1040602508156,
              49.146084305838
            ],
            [
              7.1048549037238,
              49.13866216876
            ],
            [
              7.1264458967451,
              49.141725132417
            ],
            [
              7.1315891478523,
              49.131823319707
            ],
            [
              7.1567251793676,
              49.121307004318
            ],
            [
              7.1632202862747,
              49.126662546105
            ],
            [
              7.183939320312,
              49.130726755298
            ],
            [
              7.201238747366,
              49.116722335367
            ],
            [
              7.2066365546017,
              49.123479912557
            ],
            [
              7.215896311218,
              49.125515370531
            ],
            [
              7.2477915881698,
              49.127982310796
            ],
            [
              7.2663442843038,
              49.122591822505
            ],
            [
              7.2808871054318,
              49.124111468981
            ],
            [
              7.2862671647554,
              49.117590655005
            ],
            [
              7.2986472513209,
              49.11745641594
            ],
            [
              7.3141283440236,
              49.137565746438
            ],
            [
              7.3298410268211,
              49.14494179986
            ],
            [
              7.3453334573199,
              49.143232164996
            ],
            [
              7.3628465402247,
              49.14521647821
            ],
            [
              7.36507550268,
              49.171836295674
            ],
            [
              7.3813683009451,
              49.175274386224
            ],
            [
              7.4450649197879,
              49.184305403345
            ],
            [
              7.4399501767781,
              49.180796023353
            ],
            [
              7.44067166015,
              49.170483311686
            ],
            [
              7.4552262580683,
              49.166323401106
            ],
            [
              7.4911929165521,
              49.169804496794
            ],
            [
              7.4954431400151,
              49.156591826787
            ],
            [
              7.5061698187802,
              49.154030900401
            ],
            [
              7.4921500560289,
              49.141863362811
            ],
            [
              7.5046995077445,
              49.124892105953
            ],
            [
              7.5154385998767,
              49.121168712175
            ],
            [
              7.5241643855785,
              49.105491018786
            ],
            [
              7.5390064229804,
              49.092067833166
            ],
            [
              7.5631836706272,
              49.080458461345
            ],
            [
              7.570389598272,
              49.079863764095
            ],
            [
              7.5999843985962,
              49.083327857216
            ],
            [
              7.6311909357346,
              49.06938180187
            ],
            [
              7.6352815933424,
              49.054164206261
            ]
          ]
        ]
      },
      "properties": {
        "code": "57",
        "nom": "Moselle",
        "population": 1049155,
        "delitspour1000": "4,893",
        "nombremosque": 54,
        "parentsEtrangers": "70,7",
        "benefRsa": 48831,
        "eoliennes": 149
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -0.10307051080042,
                43.242819142542
              ],
              [
                -0.12215124145212,
                43.243893908194
              ],
              [
                -0.13346480059307,
                43.258458798265
              ],
              [
                -0.14061503281768,
                43.271941230583
              ],
              [
                -0.13650575288846,
                43.284504952764
              ],
              [
                -0.12616476378979,
                43.293876660784
              ],
              [
                -0.11997934072059,
                43.306250495105
              ],
              [
                -0.11185367186851,
                43.31039465909
              ],
              [
                -0.092031063800833,
                43.300469260619
              ],
              [
                -0.096173699573616,
                43.285481158687
              ],
              [
                -0.079283735332789,
                43.271656452017
              ],
              [
                -0.079840951222932,
                43.262366418014
              ],
              [
                -0.092111707678552,
                43.252065563512
              ],
              [
                -0.099320994112155,
                43.252127129181
              ],
              [
                -0.10307051080042,
                43.242819142542
              ]
            ]
          ],
          [
            [
              [
                -0.10221657949985,
                43.358514651885
              ],
              [
                -0.10728332222542,
                43.370734939225
              ],
              [
                -0.09095912750824,
                43.37316253716
              ],
              [
                -0.076422702553633,
                43.364562190447
              ],
              [
                -0.065396680791735,
                43.35504948237
              ],
              [
                -0.062503063827367,
                43.346712050556
              ],
              [
                -0.070279283945182,
                43.317455182599
              ],
              [
                -0.075041474866062,
                43.307136096993
              ],
              [
                -0.10412308826692,
                43.312464324807
              ],
              [
                -0.11139515774132,
                43.315808605005
              ],
              [
                -0.11566381713828,
                43.330976950464
              ],
              [
                -0.10847946239493,
                43.337801770075
              ],
              [
                -0.096783660691415,
                43.334763115112
              ],
              [
                -0.086786928155415,
                43.336872044844
              ],
              [
                -0.090143282581254,
                43.358859352353
              ],
              [
                -0.10221657949985,
                43.358514651885
              ]
            ]
          ],
          [
            [
              [
                -0.096787871742487,
                43.582404857482
              ],
              [
                -0.093725408004603,
                43.590665521785
              ],
              [
                -0.077224332192671,
                43.590162839246
              ],
              [
                -0.073973166600852,
                43.606313465142
              ],
              [
                -0.060819613194467,
                43.605876348063
              ],
              [
                -0.049064276300278,
                43.609311711216
              ],
              [
                -0.033362276277096,
                43.604019201984
              ],
              [
                -0.015780753160441,
                43.60465468589
              ],
              [
                -0.0071754285102898,
                43.592181218787
              ],
              [
                -0.0019274798181687,
                43.565543989436
              ],
              [
                0.008922500968011,
                43.560545653739
              ],
              [
                0.015777190923584,
                43.543393094225
              ],
              [
                0.029584696596704,
                43.544893836857
              ],
              [
                0.04068648874415,
                43.53624672667
              ],
              [
                0.050527067832305,
                43.521660780518
              ],
              [
                0.056077967868575,
                43.518802653832
              ],
              [
                0.080575938618543,
                43.5238620587
              ],
              [
                0.082006430447501,
                43.517040049154
              ],
              [
                0.089680554933274,
                43.510421819008
              ],
              [
                0.10121503618691,
                43.511119695631
              ],
              [
                0.11212679596727,
                43.517368187417
              ],
              [
                0.13046411439385,
                43.494899078652
              ],
              [
                0.12731729425974,
                43.491328012923
              ],
              [
                0.13086227203695,
                43.47402211026
              ],
              [
                0.14001339320212,
                43.465236063128
              ],
              [
                0.14931283060095,
                43.465365866999
              ],
              [
                0.15316225021931,
                43.457393528332
              ],
              [
                0.16245656915563,
                43.449913175464
              ],
              [
                0.16599819263146,
                43.44273819974
              ],
              [
                0.13798471501664,
                43.432919034375
              ],
              [
                0.13200756108454,
                43.424113141856
              ],
              [
                0.13955189436855,
                43.420706325824
              ],
              [
                0.15385938168359,
                43.399322164419
              ],
              [
                0.17380124314994,
                43.402772158304
              ],
              [
                0.18202989360661,
                43.371184440461
              ],
              [
                0.1978811390958,
                43.375042930949
              ],
              [
                0.20634629905454,
                43.367775935375
              ],
              [
                0.22224451423552,
                43.370057642279
              ],
              [
                0.23258112809587,
                43.3677705561
              ],
              [
                0.24810289190962,
                43.375623419655
              ],
              [
                0.27725309717219,
                43.385794488276
              ],
              [
                0.29864354305631,
                43.388394571404
              ],
              [
                0.30085815491505,
                43.371751195377
              ],
              [
                0.31540993388053,
                43.367351512373
              ],
              [
                0.32769192775763,
                43.370952743497
              ],
              [
                0.32814824529468,
                43.351598750607
              ],
              [
                0.33183860488216,
                43.343161276986
              ],
              [
                0.35337637383718,
                43.348937194979
              ],
              [
                0.3649863802754,
                43.349636508692
              ],
              [
                0.38833876240281,
                43.356210125031
              ],
              [
                0.39513691309348,
                43.348269610242
              ],
              [
                0.39533106011154,
                43.33453809565
              ],
              [
                0.41351647060317,
                43.329094431545
              ],
              [
                0.44303285893116,
                43.327927935026
              ],
              [
                0.44503785342914,
                43.340295366177
              ],
              [
                0.47297636945035,
                43.332476899091
              ],
              [
                0.4822377048054,
                43.332043999821
              ],
              [
                0.49802785598653,
                43.326175934266
              ],
              [
                0.50432581846829,
                43.333505576201
              ],
              [
                0.53690931523756,
                43.328873193442
              ],
              [
                0.54744742610882,
                43.329318832245
              ],
              [
                0.57177754315704,
                43.319707730296
              ],
              [
                0.58460116890689,
                43.319818584652
              ],
              [
                0.60680735564582,
                43.310884111508
              ],
              [
                0.63572028728275,
                43.30007447295
              ],
              [
                0.63277803508742,
                43.292685230456
              ],
              [
                0.61628244214367,
                43.289038643093
              ],
              [
                0.61387902278172,
                43.277095258174
              ],
              [
                0.59665991362366,
                43.273771599239
              ],
              [
                0.56867744444427,
                43.2523553295
              ],
              [
                0.55156853219931,
                43.236101604682
              ],
              [
                0.57284091276067,
                43.233109036267
              ],
              [
                0.57405142839834,
                43.221293040934
              ],
              [
                0.57095143144365,
                43.217817968649
              ],
              [
                0.55187873558352,
                43.209143617489
              ],
              [
                0.54486825398118,
                43.213313956316
              ],
              [
                0.5360494053215,
                43.207675534159
              ],
              [
                0.51738555808232,
                43.212375668519
              ],
              [
                0.50575903800693,
                43.199712594677
              ],
              [
                0.52156679074663,
                43.192045832358
              ],
              [
                0.5117130202616,
                43.185834450618
              ],
              [
                0.48781456429823,
                43.1652534382
              ],
              [
                0.47556676361983,
                43.161266907641
              ],
              [
                0.44199364903152,
                43.131273015364
              ],
              [
                0.45364006237795,
                43.127183340895
              ],
              [
                0.46394357774072,
                43.117819842257
              ],
              [
                0.45321684463749,
                43.111242340548
              ],
              [
                0.47874657576465,
                43.112254042885
              ],
              [
                0.48904307495584,
                43.115172560924
              ],
              [
                0.49120181777745,
                43.108676185943
              ],
              [
                0.50309993149961,
                43.105591554426
              ],
              [
                0.50283874034225,
                43.095683308821
              ],
              [
                0.51515952535238,
                43.092685541999
              ],
              [
                0.53226474570564,
                43.093864376016
              ],
              [
                0.53313934061594,
                43.082799134312
              ],
              [
                0.54675860271803,
                43.081266468806
              ],
              [
                0.56385629257295,
                43.074244482997
              ],
              [
                0.55733218070377,
                43.066399536427
              ],
              [
                0.56062473412263,
                43.057989762064
              ],
              [
                0.56265072205432,
                43.035782450371
              ],
              [
                0.54716673279983,
                43.044852534963
              ],
              [
                0.53793151601541,
                43.041642574512
              ],
              [
                0.53593289967487,
                43.018155073443
              ],
              [
                0.52714594818695,
                43.008608157216
              ],
              [
                0.53097432359112,
                43.003272172104
              ],
              [
                0.54120823099024,
                43.001719653069
              ],
              [
                0.56300575907043,
                43.017466178093
              ],
              [
                0.57338249614077,
                43.021321483533
              ],
              [
                0.59181936224605,
                43.022370102684
              ],
              [
                0.59322764879205,
                43.035314438925
              ],
              [
                0.60824975068702,
                43.033562771594
              ],
              [
                0.61647463978452,
                43.020639129815
              ],
              [
                0.61555885843815,
                43.011935174103
              ],
              [
                0.62741914892273,
                43.000102900277
              ],
              [
                0.62353209429204,
                42.99194182479
              ],
              [
                0.61435800035415,
                42.987385706196
              ],
              [
                0.61882997147207,
                42.972416005847
              ],
              [
                0.63578281368147,
                42.968895271217
              ],
              [
                0.64553925526757,
                42.962068215879
              ],
              [
                0.6419918691301,
                42.952874553687
              ],
              [
                0.63547614456196,
                42.951513177726
              ],
              [
                0.6284641738259,
                42.943755211687
              ],
              [
                0.60605980482582,
                42.934364734106
              ],
              [
                0.59659339346133,
                42.920626304869
              ],
              [
                0.59596170540963,
                42.910759596222
              ],
              [
                0.58654053371816,
                42.899760984166
              ],
              [
                0.57818681564011,
                42.881942709356
              ],
              [
                0.57593507190118,
                42.870827720703
              ],
              [
                0.55981185857482,
                42.861306396565
              ],
              [
                0.55001672827569,
                42.863667944351
              ],
              [
                0.53694778531801,
                42.862514068997
              ],
              [
                0.52376097943905,
                42.867834470742
              ],
              [
                0.50275281250409,
                42.87051186101
              ],
              [
                0.49593627279002,
                42.874762438069
              ],
              [
                0.4772965729446,
                42.878104684919
              ],
              [
                0.47257284328773,
                42.870780602409
              ],
              [
                0.47238152873403,
                42.858935155117
              ],
              [
                0.46024258056156,
                42.85175053483
              ],
              [
                0.46749911892543,
                42.846747069682
              ],
              [
                0.45889719229199,
                42.8281424602
              ],
              [
                0.45658819283386,
                42.809828070413
              ],
              [
                0.46354501855397,
                42.804496892674
              ],
              [
                0.46304643293284,
                42.79089501716
              ],
              [
                0.45597802846716,
                42.780556135531
              ],
              [
                0.45587314187027,
                42.76989031175
              ],
              [
                0.46339965657335,
                42.759619682779
              ],
              [
                0.46269535976379,
                42.739214297937
              ],
              [
                0.45509925797433,
                42.728057163284
              ],
              [
                0.48458374009353,
                42.710274868309
              ],
              [
                0.47775091439859,
                42.699986776967
              ],
              [
                0.4569304439913,
                42.692659152242
              ],
              [
                0.42927703134928,
                42.690747961457
              ],
              [
                0.41768712562607,
                42.694690592529
              ],
              [
                0.40299065005461,
                42.696059426702
              ],
              [
                0.39581531433956,
                42.701201620227
              ],
              [
                0.39262638361543,
                42.713119210116
              ],
              [
                0.37690233423878,
                42.714106032311
              ],
              [
                0.35962761747241,
                42.723390903669
              ],
              [
                0.34787523241281,
                42.714178824464
              ],
              [
                0.32622532527099,
                42.70523860909
              ],
              [
                0.32346895203404,
                42.687137830773
              ],
              [
                0.30020270310415,
                42.676589164672
              ],
              [
                0.29282363337494,
                42.674921018438
              ],
              [
                0.2708685107087,
                42.690720979232
              ],
              [
                0.25988644276717,
                42.715814801568
              ],
              [
                0.24551694384626,
                42.718157572312
              ],
              [
                0.22667904852621,
                42.71735448054
              ],
              [
                0.20599010678325,
                42.729295965395
              ],
              [
                0.17572555256904,
                42.736480431547
              ],
              [
                0.16221088087725,
                42.725842127347
              ],
              [
                0.13657205436584,
                42.722323517883
              ],
              [
                0.12571277884722,
                42.713756931075
              ],
              [
                0.11125293791766,
                42.710207845076
              ],
              [
                0.09041936171393,
                42.717129184641
              ],
              [
                0.074772566655186,
                42.71209248746
              ],
              [
                0.07070990768266,
                42.703944107872
              ],
              [
                0.059212546233425,
                42.698966385505
              ],
              [
                0.045947391455758,
                42.696775443692
              ],
              [
                0.025181280051534,
                42.70192424896
              ],
              [
                0.016464909509703,
                42.701787746946
              ],
              [
                0.015303686021275,
                42.695195339428
              ],
              [
                0.0023539316821015,
                42.686187697005
              ],
              [
                -0.010639973713379,
                42.684384118141
              ],
              [
                -0.047756997997488,
                42.693359114812
              ],
              [
                -0.059811467964507,
                42.693423542148
              ],
              [
                -0.064563524867416,
                42.699353820033
              ],
              [
                -0.064009731971453,
                42.71008519182
              ],
              [
                -0.068842258409049,
                42.717900891138
              ],
              [
                -0.076501338605961,
                42.71719782154
              ],
              [
                -0.090397506206375,
                42.721077862832
              ],
              [
                -0.10611325502758,
                42.720827214056
              ],
              [
                -0.11070680415594,
                42.725007544931
              ],
              [
                -0.11080028926285,
                42.734997384695
              ],
              [
                -0.13666638793598,
                42.764142081631
              ],
              [
                -0.14599661532545,
                42.768469484558
              ],
              [
                -0.15421201073912,
                42.780770439999
              ],
              [
                -0.15458058004585,
                42.792740754923
              ],
              [
                -0.15972393675618,
                42.797400992971
              ],
              [
                -0.17849266806523,
                42.785358654901
              ],
              [
                -0.18922389776922,
                42.787796189656
              ],
              [
                -0.20156585632457,
                42.796277921886
              ],
              [
                -0.21390456164229,
                42.79603817418
              ],
              [
                -0.23844648846147,
                42.808173013415
              ],
              [
                -0.23886476296826,
                42.818111155727
              ],
              [
                -0.24336198692263,
                42.823142763211
              ],
              [
                -0.25801362447912,
                42.821008365204
              ],
              [
                -0.26733811029813,
                42.826009234187
              ],
              [
                -0.27668600069509,
                42.835470786436
              ],
              [
                -0.30547392531847,
                42.84123285236
              ],
              [
                -0.3134440845287,
                42.84937502201
              ],
              [
                -0.30749089647919,
                42.867843636256
              ],
              [
                -0.31566756931289,
                42.884801825113
              ],
              [
                -0.31819431138324,
                42.897761911545
              ],
              [
                -0.32459076680338,
                42.905260440304
              ],
              [
                -0.3270823405503,
                42.915785217713
              ],
              [
                -0.31074643857214,
                42.919004799168
              ],
              [
                -0.30871758220081,
                42.924636095621
              ],
              [
                -0.29777949713226,
                42.930973352505
              ],
              [
                -0.29003653813459,
                42.929087564699
              ],
              [
                -0.28159219915593,
                42.933563651361
              ],
              [
                -0.27938737996819,
                42.942120483339
              ],
              [
                -0.28647438097301,
                42.960054130137
              ],
              [
                -0.29154703187727,
                42.987768864423
              ],
              [
                -0.28772006609469,
                43.005371765683
              ],
              [
                -0.26484031266067,
                43.009957412551
              ],
              [
                -0.25606972495919,
                43.022726945323
              ],
              [
                -0.25993739842031,
                43.038277556214
              ],
              [
                -0.23941952476886,
                43.039676815812
              ],
              [
                -0.2239464237385,
                43.033684092022
              ],
              [
                -0.20860119763472,
                43.039724486019
              ],
              [
                -0.18964711095987,
                43.052021067576
              ],
              [
                -0.19915084290716,
                43.064412767264
              ],
              [
                -0.19776766479894,
                43.071150876661
              ],
              [
                -0.18776795009335,
                43.083330185944
              ],
              [
                -0.18693584923102,
                43.091163577327
              ],
              [
                -0.19768215446413,
                43.098173160871
              ],
              [
                -0.19098051290629,
                43.111202819975
              ],
              [
                -0.17086902572159,
                43.113421493828
              ],
              [
                -0.16634162368399,
                43.121884731119
              ],
              [
                -0.14613588573303,
                43.128233156333
              ],
              [
                -0.14050344828237,
                43.136172128164
              ],
              [
                -0.13859988604461,
                43.149103535932
              ],
              [
                -0.12655319090013,
                43.160447394144
              ],
              [
                -0.11770126761715,
                43.180323067986
              ],
              [
                -0.11133185839731,
                43.179338583668
              ],
              [
                -0.10448219117427,
                43.166691440804
              ],
              [
                -0.097529225654089,
                43.166624966901
              ],
              [
                -0.095496012787098,
                43.177197303166
              ],
              [
                -0.06786899220915,
                43.177118135342
              ],
              [
                -0.07252723387475,
                43.224387038006
              ],
              [
                -0.049462179860127,
                43.217180833465
              ],
              [
                -0.045868384089441,
                43.23213534366
              ],
              [
                -0.037586102171492,
                43.242416639333
              ],
              [
                -0.02368673812988,
                43.254986403176
              ],
              [
                -0.02562371129178,
                43.260991374913
              ],
              [
                -0.017008526259885,
                43.270448647152
              ],
              [
                -0.023979443826928,
                43.280211811856
              ],
              [
                -0.044392167069364,
                43.285272051797
              ],
              [
                -0.046122559308833,
                43.300858489621
              ],
              [
                -0.031830518124419,
                43.31215055589
              ],
              [
                -0.024874069651994,
                43.329492363991
              ],
              [
                -0.0032544572879219,
                43.332106223092
              ],
              [
                0.010318205390046,
                43.325314740811
              ],
              [
                0.02629551293813,
                43.341364458979
              ],
              [
                0.023777202110468,
                43.349140741399
              ],
              [
                0.0057814385418996,
                43.363002124921
              ],
              [
                -0.0052544098304965,
                43.373757650344
              ],
              [
                0.0051784236678809,
                43.394194111086
              ],
              [
                -0.0038111619353702,
                43.398395088127
              ],
              [
                0.0095923341693579,
                43.422106177396
              ],
              [
                -0.004617224617132,
                43.431760209363
              ],
              [
                -0.0009763127221442,
                43.444398882283
              ],
              [
                -0.016529018418498,
                43.443944372555
              ],
              [
                -0.024647492248551,
                43.430441863151
              ],
              [
                -0.034594805202059,
                43.429064287986
              ],
              [
                -0.042752022413969,
                43.410434732548
              ],
              [
                -0.066460558394679,
                43.411733865017
              ],
              [
                -0.062025455126023,
                43.417738498202
              ],
              [
                -0.053836151217704,
                43.418937636756
              ],
              [
                -0.057818804728216,
                43.427453948482
              ],
              [
                -0.06902834281151,
                43.437345744695
              ],
              [
                -0.061666974828373,
                43.452452940742
              ],
              [
                -0.065757532784034,
                43.463483610747
              ],
              [
                -0.019322496256827,
                43.466531436068
              ],
              [
                -0.01781381554075,
                43.472147456522
              ],
              [
                -0.033960155813221,
                43.47488114734
              ],
              [
                -0.034676452145955,
                43.487323780123
              ],
              [
                -0.049321056921324,
                43.492468050259
              ],
              [
                -0.040135444460287,
                43.512604399189
              ],
              [
                -0.044654576654926,
                43.525299051263
              ],
              [
                -0.056174185273693,
                43.533029097587
              ],
              [
                -0.064394904836236,
                43.545147116286
              ],
              [
                -0.078385830359136,
                43.546822915717
              ],
              [
                -0.088671332923909,
                43.542057336851
              ],
              [
                -0.094969201412123,
                43.548141189515
              ],
              [
                -0.088934220496639,
                43.557203940272
              ],
              [
                -0.093425031634788,
                43.563569921638
              ],
              [
                -0.096787871742487,
                43.582404857482
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "65",
        "nom": "Hautes-Pyrénées",
        "population": 229788,
        "delitspour1000": "4,165",
        "nombremosque": 4,
        "parentsEtrangers": "80,3",
        "benefRsa": 10849
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.1660488223224,
              42.663917432789
            ],
            [
              2.1762144887597,
              42.64890098195
            ],
            [
              2.1921070857545,
              42.651761775768
            ],
            [
              2.1913009680676,
              42.658154649346
            ],
            [
              2.2246921293803,
              42.674162457773
            ],
            [
              2.2390119385206,
              42.676205557799
            ],
            [
              2.2465143491066,
              42.681205628031
            ],
            [
              2.2552050371231,
              42.697458037454
            ],
            [
              2.2645326504794,
              42.706239710442
            ],
            [
              2.2886350177001,
              42.700675940528
            ],
            [
              2.3220073604176,
              42.707789785798
            ],
            [
              2.336473961177,
              42.718505606411
            ],
            [
              2.3553902933138,
              42.727008424178
            ],
            [
              2.3575375286794,
              42.734121070446
            ],
            [
              2.3521078632768,
              42.750727460273
            ],
            [
              2.3493581082134,
              42.769579245663
            ],
            [
              2.3441662158501,
              42.779756163988
            ],
            [
              2.343924099398,
              42.801221485171
            ],
            [
              2.3335863589133,
              42.804382336902
            ],
            [
              2.3321282759785,
              42.820432572345
            ],
            [
              2.3190761465472,
              42.823973534101
            ],
            [
              2.3271099265103,
              42.837443636727
            ],
            [
              2.3375799741132,
              42.840587818223
            ],
            [
              2.382161365403,
              42.848465034543
            ],
            [
              2.414621420566,
              42.84101870126
            ],
            [
              2.4524735304233,
              42.836933656549
            ],
            [
              2.5090056459735,
              42.849828064937
            ],
            [
              2.5165749669116,
              42.842410428152
            ],
            [
              2.5515113818666,
              42.846688734857
            ],
            [
              2.5979894049578,
              42.837100971919
            ],
            [
              2.6280426854588,
              42.836666235812
            ],
            [
              2.638409069378,
              42.834083133254
            ],
            [
              2.6651284701275,
              42.836640022294
            ],
            [
              2.6804625126818,
              42.83067010274
            ],
            [
              2.697582514329,
              42.830434676604
            ],
            [
              2.7149718442234,
              42.834290473477
            ],
            [
              2.7247452179486,
              42.833985979518
            ],
            [
              2.7400015255921,
              42.837576852437
            ],
            [
              2.7544077500563,
              42.851928276802
            ],
            [
              2.7621491189859,
              42.873679462463
            ],
            [
              2.7890562621586,
              42.895989632901
            ],
            [
              2.8163810040447,
              42.899966028514
            ],
            [
              2.8347290901868,
              42.912261020459
            ],
            [
              2.8652738845272,
              42.918339638726
            ],
            [
              2.8804294829511,
              42.901429612195
            ],
            [
              2.889328681692,
              42.898015640078
            ],
            [
              2.9068755886101,
              42.895812902548
            ],
            [
              2.9171137651734,
              42.884862018338
            ],
            [
              2.9476099099498,
              42.876045186727
            ],
            [
              2.9762275854388,
              42.87028398251
            ],
            [
              3.0435046945933,
              42.838148318333
            ],
            [
              3.0393825874565,
              42.799198332564
            ],
            [
              3.0383147458062,
              42.759308922454
            ],
            [
              3.0388174616362,
              42.731854400073
            ],
            [
              3.0406344364503,
              42.717464171238
            ],
            [
              3.0354235158251,
              42.678251792455
            ],
            [
              3.0348448670056,
              42.660160793544
            ],
            [
              3.0374210506027,
              42.623834642628
            ],
            [
              3.045187180481,
              42.594580128259
            ],
            [
              3.0461959546139,
              42.571621030681
            ],
            [
              3.0496015552694,
              42.550136565972
            ],
            [
              3.0602531213169,
              42.535571457297
            ],
            [
              3.0820579326701,
              42.531560165182
            ],
            [
              3.0884117662963,
              42.525558183092
            ],
            [
              3.1091343781376,
              42.52489266491
            ],
            [
              3.1245047496547,
              42.521008572288
            ],
            [
              3.1335799243959,
              42.512937683655
            ],
            [
              3.125266856012,
              42.502379897222
            ],
            [
              3.1329574003536,
              42.49727637329
            ],
            [
              3.1301915393708,
              42.485333147407
            ],
            [
              3.1331140504699,
              42.48125657057
            ],
            [
              3.1530079401401,
              42.477772687552
            ],
            [
              3.1556347078439,
              42.469619425916
            ],
            [
              3.1624454541281,
              42.464978147207
            ],
            [
              3.1598953323874,
              42.451898657834
            ],
            [
              3.1673878928116,
              42.448419269998
            ],
            [
              3.1747892794105,
              42.438690536519
            ],
            [
              3.1732186031539,
              42.434878294177
            ],
            [
              3.1254604409588,
              42.43468722687
            ],
            [
              3.1207651976703,
              42.438008576998
            ],
            [
              3.1072768828596,
              42.432657662278
            ],
            [
              3.0983029144992,
              42.424928915316
            ],
            [
              3.0854409735932,
              42.425513473771
            ],
            [
              3.0573242267829,
              42.446987385415
            ],
            [
              3.049039409754,
              42.455546175261
            ],
            [
              3.0407584675862,
              42.473141908956
            ],
            [
              3.0198010082741,
              42.470624644994
            ],
            [
              3.0125732677896,
              42.466522275456
            ],
            [
              3.0009203931282,
              42.472988789014
            ],
            [
              2.9822385301958,
              42.471613793793
            ],
            [
              2.9689921527615,
              42.465793999445
            ],
            [
              2.9470281255671,
              42.481796599661
            ],
            [
              2.9302139076263,
              42.472078747973
            ],
            [
              2.924484683878,
              42.458390971344
            ],
            [
              2.9070949041858,
              42.456950703603
            ],
            [
              2.8920092453183,
              42.461788700333
            ],
            [
              2.8809448199987,
              42.461172866792
            ],
            [
              2.8727897478221,
              42.467059299379
            ],
            [
              2.8635181251783,
              42.463685068523
            ],
            [
              2.859676897177,
              42.454572528495
            ],
            [
              2.8414158207066,
              42.458521817354
            ],
            [
              2.8331700496324,
              42.452804895516
            ],
            [
              2.8271034160314,
              42.439240137443
            ],
            [
              2.8162952658984,
              42.439738013572
            ],
            [
              2.8031487735856,
              42.428224313103
            ],
            [
              2.7992434851571,
              42.41857137482
            ],
            [
              2.790949549749,
              42.417857916921
            ],
            [
              2.7769704769536,
              42.411950189136
            ],
            [
              2.7685368540735,
              42.413430586275
            ],
            [
              2.7531989854034,
              42.4253608469
            ],
            [
              2.7166720712852,
              42.42122422902
            ],
            [
              2.6945491255524,
              42.407887936979
            ],
            [
              2.6750400951504,
              42.404970989064
            ],
            [
              2.6714146160115,
              42.387529592347
            ],
            [
              2.6556398345705,
              42.388430725188
            ],
            [
              2.660742170663,
              42.378153731227
            ],
            [
              2.6611097557922,
              42.365961556824
            ],
            [
              2.6727185269299,
              42.359466239204
            ],
            [
              2.6760751446233,
              42.351638965741
            ],
            [
              2.6717703199325,
              42.341177323897
            ],
            [
              2.6559403579543,
              42.341429322911
            ],
            [
              2.6495298474086,
              42.344118258289
            ],
            [
              2.6178064323289,
              42.345509163763
            ],
            [
              2.5778257714722,
              42.357932340651
            ],
            [
              2.562716553225,
              42.357067819912
            ],
            [
              2.5573378574231,
              42.354085820691
            ],
            [
              2.5521719085286,
              42.343319051918
            ],
            [
              2.5400380013544,
              42.333755393653
            ],
            [
              2.5257604063048,
              42.33364688988
            ],
            [
              2.5003055444058,
              42.342908435218
            ],
            [
              2.4829568103055,
              42.33964742313
            ],
            [
              2.4675348479811,
              42.358923606052
            ],
            [
              2.4514681079426,
              42.369203663978
            ],
            [
              2.4454040688981,
              42.36956863647
            ],
            [
              2.4334669708382,
              42.377094638841
            ],
            [
              2.4354845211197,
              42.388880175068
            ],
            [
              2.4288205957426,
              42.394335723771
            ],
            [
              2.4167902772506,
              42.392360477665
            ],
            [
              2.3954903182362,
              42.395121661663
            ],
            [
              2.3899700764621,
              42.398051030902
            ],
            [
              2.3688351288602,
              42.401165613258
            ],
            [
              2.3491768366401,
              42.406745263737
            ],
            [
              2.3341179227016,
              42.415651766886
            ],
            [
              2.3253087868373,
              42.417410301027
            ],
            [
              2.3141335755949,
              42.427849671933
            ],
            [
              2.3069588238745,
              42.428790247213
            ],
            [
              2.2923849967665,
              42.422976621044
            ],
            [
              2.2738581951916,
              42.432690437335
            ],
            [
              2.2578039229724,
              42.438353537568
            ],
            [
              2.2466730218158,
              42.429496737389
            ],
            [
              2.2222325344186,
              42.424592964147
            ],
            [
              2.2010574502093,
              42.41629050573
            ],
            [
              2.1563381691629,
              42.423423821077
            ],
            [
              2.1313502035542,
              42.413484336715
            ],
            [
              2.114896793444,
              42.394507212494
            ],
            [
              2.1167685660168,
              42.383217532845
            ],
            [
              2.0916681165323,
              42.374166894014
            ],
            [
              2.0835932961683,
              42.362697401901
            ],
            [
              2.0723509915714,
              42.364576641156
            ],
            [
              2.0580021628496,
              42.357888067271
            ],
            [
              2.0234953451252,
              42.355223026123
            ],
            [
              2.0169227090321,
              42.348686254163
            ],
            [
              1.9859078905071,
              42.362044583467
            ],
            [
              1.980584938619,
              42.369405890793
            ],
            [
              1.971695415142,
              42.373783371899
            ],
            [
              1.9620597540182,
              42.389876289524
            ],
            [
              1.9607755007324,
              42.403677303718
            ],
            [
              1.9558602817575,
              42.412475519734
            ],
            [
              1.9582805852121,
              42.424047755767
            ],
            [
              1.9417250599361,
              42.429661230654
            ],
            [
              1.9430207870612,
              42.44411210649
            ],
            [
              1.9353061351198,
              42.453582304437
            ],
            [
              1.9166392735781,
              42.446308629241
            ],
            [
              1.8982681400083,
              42.450058731348
            ],
            [
              1.8854483131238,
              42.449295307775
            ],
            [
              1.8814265985157,
              42.459710961468
            ],
            [
              1.8683724263794,
              42.465058824645
            ],
            [
              1.8585449890657,
              42.462680550073
            ],
            [
              1.8469943350858,
              42.470704743694
            ],
            [
              1.8433611119041,
              42.477147183315
            ],
            [
              1.8346241667293,
              42.481783546447
            ],
            [
              1.8064207841596,
              42.488451645516
            ],
            [
              1.7950029277753,
              42.486845300346
            ],
            [
              1.7632599240148,
              42.486844325683
            ],
            [
              1.7461600305578,
              42.49532036127
            ],
            [
              1.7298892803686,
              42.495327493594
            ],
            [
              1.7258285070201,
              42.503605106587
            ],
            [
              1.7256472450279,
              42.515034379784
            ],
            [
              1.7343746270315,
              42.535650381024
            ],
            [
              1.7359462939386,
              42.548521333167
            ],
            [
              1.7411151935539,
              42.557100349798
            ],
            [
              1.7498796321903,
              42.563587757319
            ],
            [
              1.7699598880127,
              42.564819783733
            ],
            [
              1.7861298758203,
              42.573623487381
            ],
            [
              1.800999700616,
              42.572397819345
            ],
            [
              1.8192693018026,
              42.579501861534
            ],
            [
              1.8334569182939,
              42.582739817452
            ],
            [
              1.8557145165105,
              42.58322866123
            ],
            [
              1.8744788516217,
              42.581409515841
            ],
            [
              1.8742279234244,
              42.590001308736
            ],
            [
              1.8870814211318,
              42.590550434192
            ],
            [
              1.8919813333501,
              42.594979956731
            ],
            [
              1.9001799629623,
              42.614904331366
            ],
            [
              1.9072909860169,
              42.608629529051
            ],
            [
              1.9259284809876,
              42.609220331127
            ],
            [
              1.9361237389211,
              42.605411191977
            ],
            [
              1.9521185022163,
              42.618306812711
            ],
            [
              1.971753608035,
              42.616917738425
            ],
            [
              2.0005161638086,
              42.661563048952
            ],
            [
              2.0187871252783,
              42.653420586628
            ],
            [
              2.02903411415,
              42.652957529531
            ],
            [
              2.0545205344694,
              42.663059183043
            ],
            [
              2.0828721186904,
              42.665789382917
            ],
            [
              2.0958625941193,
              42.664104385792
            ],
            [
              2.1114677613897,
              42.66602438992
            ],
            [
              2.1198625508162,
              42.669463714715
            ],
            [
              2.1326197747187,
              42.668366655895
            ],
            [
              2.1512958319248,
              42.661961816254
            ],
            [
              2.1660488223224,
              42.663917432789
            ]
          ],
          [
            [
              1.9573803578127,
              42.461623243383
            ],
            [
              1.9597995518936,
              42.453259523701
            ],
            [
              1.9813423517482,
              42.44752541931
            ],
            [
              2.0000647814638,
              42.446778541763
            ],
            [
              2.0126872587813,
              42.448344388962
            ],
            [
              2.0109536138017,
              42.454821453137
            ],
            [
              1.9966046469832,
              42.461186871026
            ],
            [
              1.9872946716006,
              42.469685808584
            ],
            [
              1.9865299951704,
              42.475761010873
            ],
            [
              1.9960839145775,
              42.484654179931
            ],
            [
              1.994441965458,
              42.488880134829
            ],
            [
              1.978759694687,
              42.494769707784
            ],
            [
              1.9761955998207,
              42.48559878431
            ],
            [
              1.9613838814667,
              42.472523712782
            ],
            [
              1.9573803578127,
              42.461623243383
            ]
          ]
        ]
      },
      "properties": {
        "code": "66",
        "nom": "Pyrénées-Orientales",
        "population": 482765,
        "delitspour1000": "5,91",
        "nombremosque": 23,
        "parentsEtrangers": "73,8",
        "benefRsa": 47061,
        "eoliennes": 70
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              6.8025153780901,
              45.778372017386
            ],
            [
              6.8056204903279,
              45.748466106619
            ],
            [
              6.8163461892763,
              45.739522550345
            ],
            [
              6.8111853327597,
              45.73065029663
            ],
            [
              6.8291131566154,
              45.702831040263
            ],
            [
              6.8416918546486,
              45.699758351852
            ],
            [
              6.8477523743945,
              45.689376465007
            ],
            [
              6.8685964773289,
              45.680965439806
            ],
            [
              6.8902954111672,
              45.677517341592
            ],
            [
              6.902535284798,
              45.680938868689
            ],
            [
              6.9065054377295,
              45.674559463369
            ],
            [
              6.9021374596942,
              45.663743226821
            ],
            [
              6.916286202296,
              45.660011360485
            ],
            [
              6.9171852994011,
              45.650736949397
            ],
            [
              6.9346140944773,
              45.647093928516
            ],
            [
              6.9666799933754,
              45.654063379324
            ],
            [
              6.9781568498682,
              45.645387517519
            ],
            [
              7.0006916861839,
              45.639899750138
            ],
            [
              7.0010463175509,
              45.634973208788
            ],
            [
              6.9860084427011,
              45.62249039449
            ],
            [
              6.9855421261112,
              45.611104948043
            ],
            [
              6.9778473846393,
              45.589884647121
            ],
            [
              6.9806967751279,
              45.583323625828
            ],
            [
              6.9953839096018,
              45.575637066048
            ],
            [
              6.9902484226223,
              45.561160964462
            ],
            [
              6.9945093026684,
              45.546677250256
            ],
            [
              6.9915104141831,
              45.531278684704
            ],
            [
              7.0038437540042,
              45.520940469575
            ],
            [
              7.0003315139353,
              45.504414498664
            ],
            [
              7.0224771388388,
              45.49654847936
            ],
            [
              7.0520544291456,
              45.496249516678
            ],
            [
              7.0563572963814,
              45.489623728606
            ],
            [
              7.0458504949934,
              45.478351199929
            ],
            [
              7.0542268132495,
              45.472184344708
            ],
            [
              7.0694234895786,
              45.473884402143
            ],
            [
              7.0997838903912,
              45.469437750641
            ],
            [
              7.1014832194722,
              45.453864029477
            ],
            [
              7.1146156812434,
              45.44172035079
            ],
            [
              7.1135165884052,
              45.434196485823
            ],
            [
              7.133848390776,
              45.426564709361
            ],
            [
              7.1475131268884,
              45.423992978267
            ],
            [
              7.1568873923079,
              45.417008078104
            ],
            [
              7.1842712160815,
              45.407484371382
            ],
            [
              7.17760043192,
              45.389026414715
            ],
            [
              7.1631811300703,
              45.381359810968
            ],
            [
              7.1588996003487,
              45.370751749811
            ],
            [
              7.1616596669718,
              45.362453596128
            ],
            [
              7.1528797089116,
              45.353816021836
            ],
            [
              7.1377390432018,
              45.350806280555
            ],
            [
              7.1321131760816,
              45.341125471296
            ],
            [
              7.1347132782574,
              45.331243465627
            ],
            [
              7.1263435309097,
              45.326945010527
            ],
            [
              7.1106925788126,
              45.326508796443
            ],
            [
              7.1123728281041,
              45.315170813723
            ],
            [
              7.1191793002337,
              45.306692568243
            ],
            [
              7.1229875004603,
              45.294328291286
            ],
            [
              7.136423190274,
              45.28079819697
            ],
            [
              7.1320835823333,
              45.266961258797
            ],
            [
              7.1375927179634,
              45.255692980709
            ],
            [
              7.1255846631745,
              45.244603122115
            ],
            [
              7.110603877569,
              45.246346626842
            ],
            [
              7.1065115700157,
              45.239258758095
            ],
            [
              7.0854359176976,
              45.225881604766
            ],
            [
              7.0796007060118,
              45.21424401107
            ],
            [
              7.0673372003279,
              45.210085201319
            ],
            [
              7.051179744193,
              45.225334821034
            ],
            [
              7.0427614515596,
              45.225313603097
            ],
            [
              7.030358820074,
              45.217845107982
            ],
            [
              7.0199925125511,
              45.215876575558
            ],
            [
              7.0003751823587,
              45.217602834471
            ],
            [
              6.989138474737,
              45.210260447293
            ],
            [
              6.9687620809781,
              45.208057678984
            ],
            [
              6.9538358712227,
              45.184628781446
            ],
            [
              6.954195544259,
              45.179612452986
            ],
            [
              6.9429030634006,
              45.176188730221
            ],
            [
              6.9430641979885,
              45.17091084008
            ],
            [
              6.9302666782212,
              45.170963141126
            ],
            [
              6.9069600182339,
              45.166613555832
            ],
            [
              6.8916010947342,
              45.167033325671
            ],
            [
              6.8858238474503,
              45.154195417179
            ],
            [
              6.8975633895087,
              45.143009081491
            ],
            [
              6.894376782376,
              45.137373356732
            ],
            [
              6.8747634572978,
              45.13563631718
            ],
            [
              6.8498552798828,
              45.127164544748
            ],
            [
              6.8422856917908,
              45.135528185554
            ],
            [
              6.8122710919045,
              45.148356968553
            ],
            [
              6.8013961759487,
              45.150083145112
            ],
            [
              6.7796480661915,
              45.158028591752
            ],
            [
              6.7679412928181,
              45.159739939943
            ],
            [
              6.7498942015229,
              45.142719026683
            ],
            [
              6.7392628844294,
              45.136771576844
            ],
            [
              6.7270392227155,
              45.138490660871
            ],
            [
              6.7121080083373,
              45.144648738816
            ],
            [
              6.6862136559676,
              45.139756066415
            ],
            [
              6.6800685047784,
              45.140123130573
            ],
            [
              6.6739820186998,
              45.129496472881
            ],
            [
              6.6657100684593,
              45.122605577556
            ],
            [
              6.6299923020059,
              45.109324963809
            ],
            [
              6.6155462835465,
              45.121477875934
            ],
            [
              6.5906851893034,
              45.119010630972
            ],
            [
              6.5765291374542,
              45.123092743765
            ],
            [
              6.5630788445458,
              45.11303936929
            ],
            [
              6.556730767951,
              45.104079390462
            ],
            [
              6.5297109874994,
              45.0985691169
            ],
            [
              6.5104239586159,
              45.108998029314
            ],
            [
              6.4995275838537,
              45.10159781596
            ],
            [
              6.4812555476638,
              45.094138953543
            ],
            [
              6.4893378703322,
              45.068353912844
            ],
            [
              6.4862360357216,
              45.056075754404
            ],
            [
              6.4728883939578,
              45.056011644618
            ],
            [
              6.4535295918669,
              45.051837207667
            ],
            [
              6.4433986588263,
              45.055077367768
            ],
            [
              6.4385899722107,
              45.06258734764
            ],
            [
              6.4002434258614,
              45.063261148419
            ],
            [
              6.3939107242634,
              45.0618177275
            ],
            [
              6.365073385182,
              45.071290490956
            ],
            [
              6.3735310876429,
              45.084151804143
            ],
            [
              6.3629265157708,
              45.104492035764
            ],
            [
              6.3344836229796,
              45.122836461323
            ],
            [
              6.3312951655489,
              45.118123866818
            ],
            [
              6.3019155994585,
              45.108954317068
            ],
            [
              6.2862810761543,
              45.110160822134
            ],
            [
              6.275502630793,
              45.115299423706
            ],
            [
              6.2605698000566,
              45.12684420383
            ],
            [
              6.2653779890371,
              45.139607411594
            ],
            [
              6.2489312476442,
              45.149662771802
            ],
            [
              6.2277116468863,
              45.142717265929
            ],
            [
              6.2183492305743,
              45.145333575225
            ],
            [
              6.2159372176736,
              45.152163633175
            ],
            [
              6.1892075903757,
              45.163730483941
            ],
            [
              6.1759369876508,
              45.162361766933
            ],
            [
              6.1690294070189,
              45.154126275916
            ],
            [
              6.1613170230868,
              45.151005539601
            ],
            [
              6.143792292215,
              45.154552997649
            ],
            [
              6.1619191434924,
              45.188405431434
            ],
            [
              6.1594722938627,
              45.202449604679
            ],
            [
              6.1378211482236,
              45.213333187699
            ],
            [
              6.1416935060053,
              45.22232847567
            ],
            [
              6.1272723570602,
              45.233317943724
            ],
            [
              6.1257002517953,
              45.244269262231
            ],
            [
              6.1385812548773,
              45.256050997267
            ],
            [
              6.1393900781341,
              45.266560839079
            ],
            [
              6.1325841534867,
              45.272593012456
            ],
            [
              6.1319486525639,
              45.288285309432
            ],
            [
              6.1417688736284,
              45.299001113002
            ],
            [
              6.1632323695733,
              45.312838044143
            ],
            [
              6.1844454256034,
              45.317952251676
            ],
            [
              6.1900932248736,
              45.342443964482
            ],
            [
              6.19476040795,
              45.352244568075
            ],
            [
              6.1803709629809,
              45.35492081739
            ],
            [
              6.1802922696297,
              45.360409426774
            ],
            [
              6.1916877134761,
              45.369022157094
            ],
            [
              6.1860529913803,
              45.374350928001
            ],
            [
              6.1774532351909,
              45.393156916781
            ],
            [
              6.154793328964,
              45.409336349236
            ],
            [
              6.1436456530595,
              45.414580875157
            ],
            [
              6.1324998100528,
              45.433380436097
            ],
            [
              6.1212683533751,
              45.438895252904
            ],
            [
              6.0974519491338,
              45.432105098885
            ],
            [
              6.0909753082569,
              45.444016847857
            ],
            [
              6.0658423549871,
              45.444139208117
            ],
            [
              6.0497526230096,
              45.437920464227
            ],
            [
              6.0085163401932,
              45.453848085956
            ],
            [
              6.0109047885983,
              45.47320486613
            ],
            [
              5.9893333893021,
              45.476074199105
            ],
            [
              5.9821187808525,
              45.487027235821
            ],
            [
              5.9661721637538,
              45.492307753934
            ],
            [
              5.9533584581804,
              45.484809788706
            ],
            [
              5.9164085799586,
              45.47667987803
            ],
            [
              5.9254150387228,
              45.464593457788
            ],
            [
              5.9149042257548,
              45.436653554682
            ],
            [
              5.904472446297,
              45.432574848416
            ],
            [
              5.9016155577899,
              45.418032646134
            ],
            [
              5.9090423346643,
              45.408294208274
            ],
            [
              5.9226358813528,
              45.416818739345
            ],
            [
              5.9281112911718,
              45.415471973855
            ],
            [
              5.9149860828604,
              45.401349288339
            ],
            [
              5.909612774011,
              45.390400457607
            ],
            [
              5.8910055457909,
              45.392211292218
            ],
            [
              5.8917060274354,
              45.398132080514
            ],
            [
              5.8797333382597,
              45.406797743306
            ],
            [
              5.8607139660775,
              45.40924651344
            ],
            [
              5.8542752462907,
              45.4165640969
            ],
            [
              5.8232128524879,
              45.421771370243
            ],
            [
              5.8074478270167,
              45.426270640914
            ],
            [
              5.7979610020218,
              45.437123711786
            ],
            [
              5.7820850307141,
              45.440656144128
            ],
            [
              5.7634821589831,
              45.439042972367
            ],
            [
              5.7597939416716,
              45.435261281179
            ],
            [
              5.7400977980128,
              45.437349988759
            ],
            [
              5.7322100567066,
              45.453663170363
            ],
            [
              5.7363772302151,
              45.472535799856
            ],
            [
              5.7190481261138,
              45.484227733066
            ],
            [
              5.7125099215192,
              45.505293631893
            ],
            [
              5.70267342317,
              45.511196354762
            ],
            [
              5.7011064065529,
              45.517546444671
            ],
            [
              5.6912470759998,
              45.521805232869
            ],
            [
              5.6901528196542,
              45.52805100678
            ],
            [
              5.6768487183882,
              45.530475432419
            ],
            [
              5.6717082687622,
              45.536571381592
            ],
            [
              5.6803575756273,
              45.545047248369
            ],
            [
              5.6695358905937,
              45.563199459718
            ],
            [
              5.654825526768,
              45.570120639284
            ],
            [
              5.6470817885996,
              45.57674261279
            ],
            [
              5.6430941654947,
              45.585618310022
            ],
            [
              5.6230208703548,
              45.604282743022
            ],
            [
              5.62374290574,
              45.613268446443
            ],
            [
              5.6316405396857,
              45.611235124481
            ],
            [
              5.63695250778,
              45.621748588886
            ],
            [
              5.6477926027919,
              45.625976358255
            ],
            [
              5.6494123534067,
              45.633717345947
            ],
            [
              5.6595309557102,
              45.638684549186
            ],
            [
              5.6781236866235,
              45.638357085735
            ],
            [
              5.6891819695463,
              45.648501735062
            ],
            [
              5.6836656752065,
              45.661963741678
            ],
            [
              5.6870609549933,
              45.668165449596
            ],
            [
              5.7083299077507,
              45.683061321171
            ],
            [
              5.7095817238803,
              45.688831038827
            ],
            [
              5.7010131804821,
              45.702100635418
            ],
            [
              5.7041230572664,
              45.71092263715
            ],
            [
              5.731324731181,
              45.710147544457
            ],
            [
              5.7487624468829,
              45.705295781585
            ],
            [
              5.7566806620664,
              45.708732566891
            ],
            [
              5.7647990972785,
              45.720294878543
            ],
            [
              5.7761557592359,
              45.729222522213
            ],
            [
              5.769838038757,
              45.741303252362
            ],
            [
              5.7819015428617,
              45.743102925582
            ],
            [
              5.78384046603,
              45.751243783283
            ],
            [
              5.7810286020002,
              45.764959536359
            ],
            [
              5.7868561787325,
              45.822955096586
            ],
            [
              5.7972199717522,
              45.831056078142
            ],
            [
              5.8036631085125,
              45.8555780918
            ],
            [
              5.8110472403323,
              45.86737240276
            ],
            [
              5.8285400922457,
              45.909292435972
            ],
            [
              5.8280692455776,
              45.920463972756
            ],
            [
              5.8228776189031,
              45.931847125431
            ],
            [
              5.8312301905898,
              45.93845768164
            ],
            [
              5.8338184185592,
              45.934970601362
            ],
            [
              5.8620989101739,
              45.932404159946
            ],
            [
              5.8657991558687,
              45.918937453236
            ],
            [
              5.8639923119064,
              45.91291531862
            ],
            [
              5.8690875747611,
              45.886862766807
            ],
            [
              5.8789441454491,
              45.859760508659
            ],
            [
              5.8734239275564,
              45.838095623667
            ],
            [
              5.885974070148,
              45.825946919494
            ],
            [
              5.9040441845732,
              45.822537840692
            ],
            [
              5.9050582571409,
              45.812373466241
            ],
            [
              5.9132162270288,
              45.804144272156
            ],
            [
              5.9291687016039,
              45.806877104785
            ],
            [
              5.9343721217477,
              45.814546466017
            ],
            [
              5.9485604331472,
              45.810231609396
            ],
            [
              5.962555366063,
              45.812244082539
            ],
            [
              5.9580636779393,
              45.794917062293
            ],
            [
              5.9809611163682,
              45.795572407777
            ],
            [
              5.9817452205322,
              45.781181334315
            ],
            [
              5.9739307725691,
              45.774949967496
            ],
            [
              5.9799040564948,
              45.768947575193
            ],
            [
              5.9948982938721,
              45.762200067217
            ],
            [
              6.0030607762844,
              45.749527422399
            ],
            [
              6.0128515303017,
              45.748779570749
            ],
            [
              6.0429455303571,
              45.739097577502
            ],
            [
              6.0527207239128,
              45.748591921085
            ],
            [
              6.075611236583,
              45.740895531828
            ],
            [
              6.1034234367071,
              45.745054554666
            ],
            [
              6.10083299013,
              45.754193544969
            ],
            [
              6.1030748256953,
              45.763440195159
            ],
            [
              6.1213793615519,
              45.752668593025
            ],
            [
              6.127906969992,
              45.751705434989
            ],
            [
              6.1428994551112,
              45.761510794776
            ],
            [
              6.166239206486,
              45.755718187246
            ],
            [
              6.1658270830033,
              45.748885953864
            ],
            [
              6.1783786439507,
              45.747151190679
            ],
            [
              6.1774190342075,
              45.73984323683
            ],
            [
              6.1822462092731,
              45.733462501646
            ],
            [
              6.1955441986055,
              45.732330085742
            ],
            [
              6.1897113419661,
              45.704157323687
            ],
            [
              6.194319616118,
              45.69377532381
            ],
            [
              6.2101473452824,
              45.700435055747
            ],
            [
              6.2142854320211,
              45.70898418313
            ],
            [
              6.2316514090283,
              45.702752717419
            ],
            [
              6.2294539875219,
              45.698955738755
            ],
            [
              6.2321247087245,
              45.682198985672
            ],
            [
              6.2396772877158,
              45.685403937323
            ],
            [
              6.2581175179422,
              45.683589370573
            ],
            [
              6.2615739463633,
              45.686710375059
            ],
            [
              6.2845906644549,
              45.686263012136
            ],
            [
              6.304826552827,
              45.693294753292
            ],
            [
              6.3217085573825,
              45.693010739733
            ],
            [
              6.3306327026354,
              45.695531215444
            ],
            [
              6.3412653460463,
              45.711817142353
            ],
            [
              6.3461832674281,
              45.723712467269
            ],
            [
              6.3532689092111,
              45.732692357268
            ],
            [
              6.3476791353535,
              45.737171260988
            ],
            [
              6.3703157001433,
              45.752890460838
            ],
            [
              6.3746675709662,
              45.765706703413
            ],
            [
              6.3685361711613,
              45.772502949818
            ],
            [
              6.3838464157156,
              45.77805544991
            ],
            [
              6.4060893301056,
              45.792858964549
            ],
            [
              6.4236722928903,
              45.802445106386
            ],
            [
              6.4308630057153,
              45.820291359592
            ],
            [
              6.44991867018,
              45.837297648186
            ],
            [
              6.4447971832877,
              45.845503082677
            ],
            [
              6.4537054607938,
              45.855988611698
            ],
            [
              6.4507523478857,
              45.861321149515
            ],
            [
              6.4622841966095,
              45.868917313995
            ],
            [
              6.4705662340798,
              45.884693898827
            ],
            [
              6.4937202988951,
              45.890001524891
            ],
            [
              6.5013594369083,
              45.895225636351
            ],
            [
              6.5090901071588,
              45.90863369291
            ],
            [
              6.5217033041707,
              45.901408007254
            ],
            [
              6.5420070408069,
              45.895244356655
            ],
            [
              6.5586799070897,
              45.893088111068
            ],
            [
              6.5729131150536,
              45.877552911542
            ],
            [
              6.5660557823526,
              45.870781832548
            ],
            [
              6.5684340912693,
              45.863169115697
            ],
            [
              6.53578724053,
              45.862051424861
            ],
            [
              6.5447591516362,
              45.84852772759
            ],
            [
              6.5522340049487,
              45.826883589526
            ],
            [
              6.5655501855053,
              45.815706295722
            ],
            [
              6.5772135491969,
              45.816573014665
            ],
            [
              6.5867081199374,
              45.799816217231
            ],
            [
              6.6017276524694,
              45.795417388915
            ],
            [
              6.6190023478384,
              45.795615397056
            ],
            [
              6.6375825199704,
              45.800130775105
            ],
            [
              6.6611884798758,
              45.798909195187
            ],
            [
              6.6742384781969,
              45.783087871713
            ],
            [
              6.6888265186739,
              45.771350450683
            ],
            [
              6.6898122556586,
              45.761850928673
            ],
            [
              6.6861426830802,
              45.755325335528
            ],
            [
              6.6986994083749,
              45.743258811518
            ],
            [
              6.6977838465696,
              45.736581877151
            ],
            [
              6.6904098405906,
              45.72713731311
            ],
            [
              6.7039483182394,
              45.726700235782
            ],
            [
              6.7119449400281,
              45.72291946053
            ],
            [
              6.742221719645,
              45.748767103181
            ],
            [
              6.7555088957599,
              45.766356186055
            ],
            [
              6.7693332671849,
              45.770136455435
            ],
            [
              6.7863107039258,
              45.771491435411
            ],
            [
              6.8025153780901,
              45.778372017386
            ]
          ]
        ]
      },
      "properties": {
        "code": "73",
        "nom": "Savoie",
        "population": 439750,
        "delitspour1000": "4,287",
        "nombremosque": 24,
        "parentsEtrangers": "74,5",
        "benefRsa": 10665
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              6.8025153780901,
              45.778372017386
            ],
            [
              6.7863107039258,
              45.771491435411
            ],
            [
              6.7693332671849,
              45.770136455435
            ],
            [
              6.7555088957599,
              45.766356186055
            ],
            [
              6.742221719645,
              45.748767103181
            ],
            [
              6.7119449400281,
              45.72291946053
            ],
            [
              6.7039483182394,
              45.726700235782
            ],
            [
              6.6904098405906,
              45.72713731311
            ],
            [
              6.6977838465696,
              45.736581877151
            ],
            [
              6.6986994083749,
              45.743258811518
            ],
            [
              6.6861426830802,
              45.755325335528
            ],
            [
              6.6898122556586,
              45.761850928673
            ],
            [
              6.6888265186739,
              45.771350450683
            ],
            [
              6.6742384781969,
              45.783087871713
            ],
            [
              6.6611884798758,
              45.798909195187
            ],
            [
              6.6375825199704,
              45.800130775105
            ],
            [
              6.6190023478384,
              45.795615397056
            ],
            [
              6.6017276524694,
              45.795417388915
            ],
            [
              6.5867081199374,
              45.799816217231
            ],
            [
              6.5772135491969,
              45.816573014665
            ],
            [
              6.5655501855053,
              45.815706295722
            ],
            [
              6.5522340049487,
              45.826883589526
            ],
            [
              6.5447591516362,
              45.84852772759
            ],
            [
              6.53578724053,
              45.862051424861
            ],
            [
              6.5684340912693,
              45.863169115697
            ],
            [
              6.5660557823526,
              45.870781832548
            ],
            [
              6.5729131150536,
              45.877552911542
            ],
            [
              6.5586799070897,
              45.893088111068
            ],
            [
              6.5420070408069,
              45.895244356655
            ],
            [
              6.5217033041707,
              45.901408007254
            ],
            [
              6.5090901071588,
              45.90863369291
            ],
            [
              6.5013594369083,
              45.895225636351
            ],
            [
              6.4937202988951,
              45.890001524891
            ],
            [
              6.4705662340798,
              45.884693898827
            ],
            [
              6.4622841966095,
              45.868917313995
            ],
            [
              6.4507523478857,
              45.861321149515
            ],
            [
              6.4537054607938,
              45.855988611698
            ],
            [
              6.4447971832877,
              45.845503082677
            ],
            [
              6.44991867018,
              45.837297648186
            ],
            [
              6.4308630057153,
              45.820291359592
            ],
            [
              6.4236722928903,
              45.802445106386
            ],
            [
              6.4060893301056,
              45.792858964549
            ],
            [
              6.3838464157156,
              45.77805544991
            ],
            [
              6.3685361711613,
              45.772502949818
            ],
            [
              6.3746675709662,
              45.765706703413
            ],
            [
              6.3703157001433,
              45.752890460838
            ],
            [
              6.3476791353535,
              45.737171260988
            ],
            [
              6.3532689092111,
              45.732692357268
            ],
            [
              6.3461832674281,
              45.723712467269
            ],
            [
              6.3412653460463,
              45.711817142353
            ],
            [
              6.3306327026354,
              45.695531215444
            ],
            [
              6.3217085573825,
              45.693010739733
            ],
            [
              6.304826552827,
              45.693294753292
            ],
            [
              6.2845906644549,
              45.686263012136
            ],
            [
              6.2615739463633,
              45.686710375059
            ],
            [
              6.2581175179422,
              45.683589370573
            ],
            [
              6.2396772877158,
              45.685403937323
            ],
            [
              6.2321247087245,
              45.682198985672
            ],
            [
              6.2294539875219,
              45.698955738755
            ],
            [
              6.2316514090283,
              45.702752717419
            ],
            [
              6.2142854320211,
              45.70898418313
            ],
            [
              6.2101473452824,
              45.700435055747
            ],
            [
              6.194319616118,
              45.69377532381
            ],
            [
              6.1897113419661,
              45.704157323687
            ],
            [
              6.1955441986055,
              45.732330085742
            ],
            [
              6.1822462092731,
              45.733462501646
            ],
            [
              6.1774190342075,
              45.73984323683
            ],
            [
              6.1783786439507,
              45.747151190679
            ],
            [
              6.1658270830033,
              45.748885953864
            ],
            [
              6.166239206486,
              45.755718187246
            ],
            [
              6.1428994551112,
              45.761510794776
            ],
            [
              6.127906969992,
              45.751705434989
            ],
            [
              6.1213793615519,
              45.752668593025
            ],
            [
              6.1030748256953,
              45.763440195159
            ],
            [
              6.10083299013,
              45.754193544969
            ],
            [
              6.1034234367071,
              45.745054554666
            ],
            [
              6.075611236583,
              45.740895531828
            ],
            [
              6.0527207239128,
              45.748591921085
            ],
            [
              6.0429455303571,
              45.739097577502
            ],
            [
              6.0128515303017,
              45.748779570749
            ],
            [
              6.0030607762844,
              45.749527422399
            ],
            [
              5.9948982938721,
              45.762200067217
            ],
            [
              5.9799040564948,
              45.768947575193
            ],
            [
              5.9739307725691,
              45.774949967496
            ],
            [
              5.9817452205322,
              45.781181334315
            ],
            [
              5.9809611163682,
              45.795572407777
            ],
            [
              5.9580636779393,
              45.794917062293
            ],
            [
              5.962555366063,
              45.812244082539
            ],
            [
              5.9485604331472,
              45.810231609396
            ],
            [
              5.9343721217477,
              45.814546466017
            ],
            [
              5.9291687016039,
              45.806877104785
            ],
            [
              5.9132162270288,
              45.804144272156
            ],
            [
              5.9050582571409,
              45.812373466241
            ],
            [
              5.9040441845732,
              45.822537840692
            ],
            [
              5.885974070148,
              45.825946919494
            ],
            [
              5.8734239275564,
              45.838095623667
            ],
            [
              5.8789441454491,
              45.859760508659
            ],
            [
              5.8690875747611,
              45.886862766807
            ],
            [
              5.8639923119064,
              45.91291531862
            ],
            [
              5.8657991558687,
              45.918937453236
            ],
            [
              5.8620989101739,
              45.932404159946
            ],
            [
              5.8338184185592,
              45.934970601362
            ],
            [
              5.8312301905898,
              45.93845768164
            ],
            [
              5.8348857517832,
              45.952142646336
            ],
            [
              5.8340245954563,
              45.972027470458
            ],
            [
              5.8096421985755,
              45.989802335637
            ],
            [
              5.8108134884026,
              46.010299483158
            ],
            [
              5.8081197991624,
              46.017745086291
            ],
            [
              5.810562662367,
              46.02975964769
            ],
            [
              5.8074048290847,
              46.045210926206
            ],
            [
              5.81359839149,
              46.05821377467
            ],
            [
              5.8076225368064,
              46.063936778242
            ],
            [
              5.8114122153566,
              46.078423852566
            ],
            [
              5.8222056291049,
              46.090775206158
            ],
            [
              5.8239656351235,
              46.103644576638
            ],
            [
              5.8323048867913,
              46.105997561521
            ],
            [
              5.8556665715056,
              46.086765119478
            ],
            [
              5.8831800937075,
              46.084913020889
            ],
            [
              5.8914369022611,
              46.088481128574
            ],
            [
              5.8929021260405,
              46.099062263942
            ],
            [
              5.8853202303568,
              46.107902197456
            ],
            [
              5.894036131075,
              46.119471054626
            ],
            [
              5.9063997635853,
              46.122367542906
            ],
            [
              5.9222720779069,
              46.131479275113
            ],
            [
              5.9462067394343,
              46.127948221814
            ],
            [
              5.9560670913359,
              46.132090234706
            ],
            [
              5.9738400143027,
              46.131738683687
            ],
            [
              5.985316810497,
              46.143308955951
            ],
            [
              6.0154641822919,
              46.14263396477
            ],
            [
              6.0325422068392,
              46.138442041928
            ],
            [
              6.0455233066288,
              46.139907692935
            ],
            [
              6.0520405063454,
              46.151402707103
            ],
            [
              6.0749307059736,
              46.148890032198
            ],
            [
              6.0924619318286,
              46.151762719587
            ],
            [
              6.0992749757816,
              46.144011566226
            ],
            [
              6.1212014573294,
              46.142667449845
            ],
            [
              6.1266206934256,
              46.140460017961
            ],
            [
              6.1449190514584,
              46.144832823407
            ],
            [
              6.1521154179752,
              46.151723946513
            ],
            [
              6.1750725341517,
              46.158152598893
            ],
            [
              6.1882037288084,
              46.168463284904
            ],
            [
              6.1859715813532,
              46.17817796673
            ],
            [
              6.2142469629058,
              46.193786616278
            ],
            [
              6.2320741324515,
              46.205470235677
            ],
            [
              6.2517782250825,
              46.207041486005
            ],
            [
              6.2773739792759,
              46.215611853153
            ],
            [
              6.2956514854587,
              46.226054581929
            ],
            [
              6.3101113416345,
              46.243699660222
            ],
            [
              6.3085403196879,
              46.255105885559
            ],
            [
              6.2846173713146,
              46.255153793328
            ],
            [
              6.2676297299719,
              46.247839951429
            ],
            [
              6.2489222724698,
              46.263081361726
            ],
            [
              6.2377992707926,
              46.27733692078
            ],
            [
              6.2416291134625,
              46.284546843215
            ],
            [
              6.2510763599071,
              46.287643543433
            ],
            [
              6.2479330373852,
              46.306039208714
            ],
            [
              6.2579242025762,
              46.321777857151
            ],
            [
              6.2799144692268,
              46.351093458251
            ],
            [
              6.2941843558993,
              46.360850723006
            ],
            [
              6.3151995925349,
              46.368778204186
            ],
            [
              6.3269980708935,
              46.371129282074
            ],
            [
              6.3446806034485,
              46.370015245292
            ],
            [
              6.3622839851983,
              46.347951295821
            ],
            [
              6.3900333913921,
              46.340163012448
            ],
            [
              6.3983362100039,
              46.349291951337
            ],
            [
              6.4115883259384,
              46.358394900258
            ],
            [
              6.4285826466732,
              46.359525351148
            ],
            [
              6.4690640820175,
              46.372614566115
            ],
            [
              6.4803914973041,
              46.379314208071
            ],
            [
              6.4828511368319,
              46.391927295245
            ],
            [
              6.4923970036831,
              46.398425207982
            ],
            [
              6.5090399003785,
              46.404031878903
            ],
            [
              6.5205788239548,
              46.404192806741
            ],
            [
              6.5451764296884,
              46.394724620294
            ],
            [
              6.5635602532757,
              46.398365633599
            ],
            [
              6.6350579346881,
              46.405772458657
            ],
            [
              6.6573993573272,
              46.404992488925
            ],
            [
              6.6926864378952,
              46.408081546332
            ],
            [
              6.7228648390107,
              46.407550427288
            ],
            [
              6.7575049437368,
              46.402602205639
            ],
            [
              6.7602536639409,
              46.400376898866
            ],
            [
              6.7956711769373,
              46.393088744604
            ],
            [
              6.8048287544258,
              46.393585002703
            ],
            [
              6.8062164014005,
              46.380373005422
            ],
            [
              6.7939579093873,
              46.368622978764
            ],
            [
              6.7725993243998,
              46.361732287023
            ],
            [
              6.770609037987,
              46.35489449301
            ],
            [
              6.7826406405392,
              46.336879446577
            ],
            [
              6.7981214712964,
              46.330137581736
            ],
            [
              6.7999297623371,
              46.320468909994
            ],
            [
              6.8066438150253,
              46.320654870777
            ],
            [
              6.8256057085651,
              46.310849078308
            ],
            [
              6.8373080183838,
              46.2966869808
            ],
            [
              6.8498971155566,
              46.290147597499
            ],
            [
              6.8589051093538,
              46.29059983934
            ],
            [
              6.8645108313675,
              46.282986228855
            ],
            [
              6.8538982253794,
              46.253760812247
            ],
            [
              6.8434127149042,
              46.248726167204
            ],
            [
              6.8337455798703,
              46.237035791813
            ],
            [
              6.8216770780521,
              46.227691939843
            ],
            [
              6.8036633001539,
              46.204343363963
            ],
            [
              6.811228200568,
              46.185029674216
            ],
            [
              6.8042020707473,
              46.172106439303
            ],
            [
              6.7919797022783,
              46.162225177301
            ],
            [
              6.7903942029832,
              46.154717927724
            ],
            [
              6.7985330883924,
              46.136103390553
            ],
            [
              6.8147295130574,
              46.129695648917
            ],
            [
              6.8355075060867,
              46.132440109797
            ],
            [
              6.8517868611599,
              46.126417177303
            ],
            [
              6.889761788112,
              46.124847684892
            ],
            [
              6.8983839799267,
              46.122561981532
            ],
            [
              6.892714778239,
              46.104316525882
            ],
            [
              6.8838438298784,
              46.095796864191
            ],
            [
              6.8914506010215,
              46.084495302772
            ],
            [
              6.8900687536716,
              46.076513214339
            ],
            [
              6.8798946308492,
              46.067793789027
            ],
            [
              6.8726328572469,
              46.052044575345
            ],
            [
              6.8883272243818,
              46.043120600357
            ],
            [
              6.9085295179521,
              46.050234188897
            ],
            [
              6.9245114290077,
              46.065194793319
            ],
            [
              6.93736936958,
              46.06456312426
            ],
            [
              6.9357975763722,
              46.0551192491
            ],
            [
              6.9515100788266,
              46.049953446394
            ],
            [
              6.9629078952786,
              46.030531973099
            ],
            [
              6.9786083663027,
              46.021144644415
            ],
            [
              6.9846771340211,
              46.006542009406
            ],
            [
              6.9947247398586,
              46.000742003948
            ],
            [
              7.0036316590852,
              46.000553586437
            ],
            [
              7.0182519988272,
              45.984185305283
            ],
            [
              7.0182419263004,
              45.975567599113
            ],
            [
              7.00940894593,
              45.9697604902
            ],
            [
              7.0179746222284,
              45.960347157061
            ],
            [
              7.037615499193,
              45.95430091758
            ],
            [
              7.0371021493003,
              45.941929380484
            ],
            [
              7.0438913499404,
              45.922087093613
            ],
            [
              7.0218109199059,
              45.916452271309
            ],
            [
              7.0031479175286,
              45.89782755209
            ],
            [
              7.0033187238325,
              45.882408678251
            ],
            [
              6.9917249963735,
              45.868198790757
            ],
            [
              6.9716946211702,
              45.867044015003
            ],
            [
              6.9510356268261,
              45.859535060133
            ],
            [
              6.93960886416,
              45.846732871084
            ],
            [
              6.9230835545726,
              45.847274071201
            ],
            [
              6.9092120399646,
              45.842522379483
            ],
            [
              6.895098526216,
              45.842600014196
            ],
            [
              6.8821969778683,
              45.849520488181
            ],
            [
              6.873610959862,
              45.845496843566
            ],
            [
              6.8698438889527,
              45.825642650285
            ],
            [
              6.8538394187733,
              45.837147031314
            ],
            [
              6.8379652390991,
              45.839496400234
            ],
            [
              6.8180784499339,
              45.834974269868
            ],
            [
              6.8142564432708,
              45.826836338335
            ],
            [
              6.8039854950068,
              45.815846165391
            ],
            [
              6.8127223620905,
              45.80801890935
            ],
            [
              6.8115834715922,
              45.798399188776
            ],
            [
              6.8039839343781,
              45.789307695607
            ],
            [
              6.8025153780901,
              45.778372017386
            ]
          ]
        ]
      },
      "properties": {
        "code": "74",
        "nom": "Haute-Savoie",
        "population": 835206,
        "delitspour1000": "4,137",
        "nombremosque": 41,
        "parentsEtrangers": "67,9",
        "benefRsa": 15672
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.5716535034613,
              48.69201676557
            ],
            [
              2.5752212918029,
              48.700368420834
            ],
            [
              2.5688027048808,
              48.707221723749
            ],
            [
              2.5852087623188,
              48.727242137595
            ],
            [
              2.5946737376888,
              48.73180600135
            ],
            [
              2.5858204453665,
              48.741487637605
            ],
            [
              2.6023411143901,
              48.753730034287
            ],
            [
              2.5982969429908,
              48.760480074814
            ],
            [
              2.6136517425679,
              48.76102540673
            ],
            [
              2.6070020497727,
              48.774401216878
            ],
            [
              2.5919605754401,
              48.77140075822
            ],
            [
              2.5856046173972,
              48.778627130829
            ],
            [
              2.5997697108259,
              48.794974135979
            ],
            [
              2.5918208872821,
              48.798135558654
            ],
            [
              2.5922736733656,
              48.807437551952
            ],
            [
              2.5964557305467,
              48.810889137262
            ],
            [
              2.5925149932073,
              48.825870860417
            ],
            [
              2.5738417937528,
              48.853385173417
            ],
            [
              2.5831356251086,
              48.857208607635
            ],
            [
              2.5873261857605,
              48.865005030869
            ],
            [
              2.568109258403,
              48.865942675094
            ],
            [
              2.5702348571309,
              48.876463404921
            ],
            [
              2.5594064582352,
              48.885336254164
            ],
            [
              2.5626276286343,
              48.888883251177
            ],
            [
              2.5838172328399,
              48.895410384217
            ],
            [
              2.5912646006622,
              48.906830121884
            ],
            [
              2.5906912334031,
              48.923361520931
            ],
            [
              2.6025997962059,
              48.929356607315
            ],
            [
              2.5893050070876,
              48.953758253881
            ],
            [
              2.5634346213721,
              48.977732430334
            ],
            [
              2.5797962900593,
              48.984984703781
            ],
            [
              2.5803194899487,
              48.991593137352
            ],
            [
              2.5716715859606,
              48.999841685023
            ],
            [
              2.5657856607901,
              49.012397786393
            ],
            [
              2.5530565519414,
              49.009816202999
            ],
            [
              2.5490471496408,
              49.014720763277
            ],
            [
              2.5378177402082,
              49.016609544367
            ],
            [
              2.5350135962143,
              49.023809402045
            ],
            [
              2.5476478248421,
              49.023448531806
            ],
            [
              2.5662405882259,
              49.030110020073
            ],
            [
              2.573590385495,
              49.043134042541
            ],
            [
              2.5882844530991,
              49.046956449876
            ],
            [
              2.5847224172596,
              49.052600876728
            ],
            [
              2.5741880306468,
              49.054149235821
            ],
            [
              2.5715472662178,
              49.059825274601
            ],
            [
              2.5905283926735,
              49.079653961607
            ],
            [
              2.6072121818235,
              49.089061593648
            ],
            [
              2.6103345312027,
              49.09494039704
            ],
            [
              2.6205306041593,
              49.095141144665
            ],
            [
              2.6332770991263,
              49.108372635105
            ],
            [
              2.6676934960697,
              49.092495236068
            ],
            [
              2.6848013380994,
              49.081070149491
            ],
            [
              2.6921954156417,
              49.072098782916
            ],
            [
              2.6909952426863,
              49.066575827238
            ],
            [
              2.7061794650059,
              49.06530744517
            ],
            [
              2.7204862280103,
              49.074886920193
            ],
            [
              2.7324165014354,
              49.069856383944
            ],
            [
              2.7350099332564,
              49.060452516659
            ],
            [
              2.7604244593128,
              49.06296236294
            ],
            [
              2.787073783912,
              49.07527418833
            ],
            [
              2.7918697099795,
              49.090226188638
            ],
            [
              2.8090528505052,
              49.097537605404
            ],
            [
              2.8198502647929,
              49.087367188293
            ],
            [
              2.8360889289489,
              49.082987383853
            ],
            [
              2.8451483788669,
              49.084656494049
            ],
            [
              2.8557424844029,
              49.070260111043
            ],
            [
              2.8662678657136,
              49.070693981918
            ],
            [
              2.8830747436616,
              49.076071279686
            ],
            [
              2.8949485303642,
              49.077063202013
            ],
            [
              2.9015078822282,
              49.085373143453
            ],
            [
              2.9174506912143,
              49.079815930085
            ],
            [
              2.9445842533756,
              49.081604590578
            ],
            [
              2.9704735929189,
              49.088974001698
            ],
            [
              2.9747449093505,
              49.074788481937
            ],
            [
              2.9879964190903,
              49.072371853401
            ],
            [
              2.9912365557999,
              49.084010529465
            ],
            [
              3.0085155423352,
              49.091506561321
            ],
            [
              3.0295246754031,
              49.085932257529
            ],
            [
              3.0327819280137,
              49.089067566229
            ],
            [
              3.0483557770377,
              49.08631637936
            ],
            [
              3.0573553400185,
              49.093990044235
            ],
            [
              3.0563165652026,
              49.101913557338
            ],
            [
              3.0718801833502,
              49.11755332218
            ],
            [
              3.0824299640326,
              49.112379413059
            ],
            [
              3.1019450875262,
              49.108665530931
            ],
            [
              3.1288977237115,
              49.106712873662
            ],
            [
              3.1495286898189,
              49.100548046274
            ],
            [
              3.1652303376546,
              49.099653555336
            ],
            [
              3.1568687392365,
              49.086717381412
            ],
            [
              3.1586170058803,
              49.080495794822
            ],
            [
              3.1686703252618,
              49.076194127358
            ],
            [
              3.1818447950388,
              49.062073630984
            ],
            [
              3.1821650688322,
              49.052356876125
            ],
            [
              3.1902026447151,
              49.046494471584
            ],
            [
              3.181201109614,
              49.042312878135
            ],
            [
              3.1765330296567,
              49.030276306398
            ],
            [
              3.1610292917102,
              49.024224133711
            ],
            [
              3.17167852308,
              49.014125889695
            ],
            [
              3.1836516708558,
              49.011250532749
            ],
            [
              3.2071647717724,
              49.000687121653
            ],
            [
              3.209314063913,
              48.993783774365
            ],
            [
              3.2291741613167,
              48.988415153607
            ],
            [
              3.2312127851282,
              48.976752849115
            ],
            [
              3.2441973984858,
              48.976835025435
            ],
            [
              3.2518324140274,
              48.972363565032
            ],
            [
              3.2571425774493,
              48.95706258101
            ],
            [
              3.2678473977393,
              48.938330226813
            ],
            [
              3.2845265117579,
              48.940530948472
            ],
            [
              3.3047535824542,
              48.948766966256
            ],
            [
              3.3128748032908,
              48.933619620679
            ],
            [
              3.3130772332366,
              48.921215462331
            ],
            [
              3.3302940034825,
              48.908707002968
            ],
            [
              3.3441215555428,
              48.915424399696
            ],
            [
              3.3535717935992,
              48.915243105496
            ],
            [
              3.3663458595763,
              48.922834212302
            ],
            [
              3.3722055688435,
              48.921684863018
            ],
            [
              3.3755045418202,
              48.907223979388
            ],
            [
              3.3690076856012,
              48.893887842681
            ],
            [
              3.3828118491401,
              48.888743663179
            ],
            [
              3.3804785922907,
              48.874760520568
            ],
            [
              3.3897420595655,
              48.871208088242
            ],
            [
              3.4058488876005,
              48.875883179755
            ],
            [
              3.4032208419263,
              48.864690111949
            ],
            [
              3.4209735309142,
              48.863949992871
            ],
            [
              3.4522351731222,
              48.856295042236
            ],
            [
              3.4453621334286,
              48.843227318178
            ],
            [
              3.4619058634763,
              48.837795469902
            ],
            [
              3.4704665824993,
              48.850999590899
            ],
            [
              3.4851833721947,
              48.851910309574
            ],
            [
              3.4895742506519,
              48.839868156733
            ],
            [
              3.4849684486637,
              48.825009748881
            ],
            [
              3.4872017702391,
              48.815190371551
            ],
            [
              3.4809287140503,
              48.81218578416
            ],
            [
              3.4703815627087,
              48.8208976637
            ],
            [
              3.4544121814945,
              48.813840024336
            ],
            [
              3.4321664968613,
              48.812257770946
            ],
            [
              3.4166176467147,
              48.817828781451
            ],
            [
              3.4041649646308,
              48.80927626878
            ],
            [
              3.4104778884203,
              48.803943061752
            ],
            [
              3.4412895824977,
              48.800919300185
            ],
            [
              3.442160723333,
              48.784353883555
            ],
            [
              3.4282111529646,
              48.77948500421
            ],
            [
              3.4094228096892,
              48.783835572028
            ],
            [
              3.4008409030783,
              48.767256400859
            ],
            [
              3.398657955437,
              48.755599281994
            ],
            [
              3.4129613380945,
              48.753902050854
            ],
            [
              3.4256695187547,
              48.755689536249
            ],
            [
              3.4358103469779,
              48.753571711386
            ],
            [
              3.440606129229,
              48.738701946832
            ],
            [
              3.4674670222781,
              48.735224220894
            ],
            [
              3.4643082791983,
              48.707378088042
            ],
            [
              3.4769690535178,
              48.699355347111
            ],
            [
              3.4658318000834,
              48.68619250912
            ],
            [
              3.4545564374114,
              48.682119139778
            ],
            [
              3.442691260176,
              48.672503410154
            ],
            [
              3.4408489379625,
              48.66307286353
            ],
            [
              3.4604317354595,
              48.653009078358
            ],
            [
              3.4531162175594,
              48.633955984329
            ],
            [
              3.4765733783329,
              48.637329365265
            ],
            [
              3.4881941587649,
              48.644795887875
            ],
            [
              3.5031842529874,
              48.645682506087
            ],
            [
              3.517498400146,
              48.643365240313
            ],
            [
              3.5190643678345,
              48.633470112339
            ],
            [
              3.54590310832,
              48.62806547987
            ],
            [
              3.555613758785,
              48.620285576065
            ],
            [
              3.5346181421224,
              48.61035282902
            ],
            [
              3.5038548704771,
              48.604807329745
            ],
            [
              3.5158639136582,
              48.589782150903
            ],
            [
              3.4975057114927,
              48.589954752513
            ],
            [
              3.4852747034173,
              48.580426868353
            ],
            [
              3.4733827141903,
              48.576873863934
            ],
            [
              3.4655215727904,
              48.570484758136
            ],
            [
              3.4722961348839,
              48.564913176785
            ],
            [
              3.4797700428722,
              48.550623337178
            ],
            [
              3.4796007670225,
              48.544709319952
            ],
            [
              3.4591848626137,
              48.530740265696
            ],
            [
              3.4382278519026,
              48.52833381057
            ],
            [
              3.4234477695307,
              48.533559074894
            ],
            [
              3.4142391071512,
              48.533468273095
            ],
            [
              3.4053967325678,
              48.528014902933
            ],
            [
              3.4239142947726,
              48.514360214193
            ],
            [
              3.4346387383078,
              48.490251682592
            ],
            [
              3.4203545971629,
              48.491547248944
            ],
            [
              3.3883584814447,
              48.480409204068
            ],
            [
              3.3964392758484,
              48.463728690957
            ],
            [
              3.4064837519344,
              48.45245201333
            ],
            [
              3.40356672054,
              48.438738834692
            ],
            [
              3.3969885214937,
              48.434567766893
            ],
            [
              3.3919585015834,
              48.424317659764
            ],
            [
              3.4118359218741,
              48.421315638566
            ],
            [
              3.4136232739227,
              48.414339744235
            ],
            [
              3.4220792964886,
              48.413341016525
            ],
            [
              3.4147888224632,
              48.390268550005
            ],
            [
              3.4020328168494,
              48.389706108674
            ],
            [
              3.3833174045242,
              48.39970411104
            ],
            [
              3.3672130286667,
              48.394317453742
            ],
            [
              3.3627847824483,
              48.382576787133
            ],
            [
              3.3651606301927,
              48.372282482296
            ],
            [
              3.3301099431753,
              48.372120787109
            ],
            [
              3.3093216217612,
              48.376980286994
            ],
            [
              3.3051586355523,
              48.372876819681
            ],
            [
              3.2823921646024,
              48.377519839785
            ],
            [
              3.2647767822592,
              48.374621237382
            ],
            [
              3.254386262298,
              48.36502005161
            ],
            [
              3.2328491246009,
              48.370332895515
            ],
            [
              3.2018505500023,
              48.364015470529
            ],
            [
              3.184946555144,
              48.368130335436
            ],
            [
              3.1796714598679,
              48.375720974057
            ],
            [
              3.1677335139977,
              48.371858146235
            ],
            [
              3.1398647131279,
              48.372599662445
            ],
            [
              3.1182555596676,
              48.366787852825
            ],
            [
              3.1034153439925,
              48.349510589018
            ],
            [
              3.0878411046476,
              48.358744110403
            ],
            [
              3.0605482162395,
              48.357442256262
            ],
            [
              3.0495229427782,
              48.360116755131
            ],
            [
              3.0374902381586,
              48.343151149375
            ],
            [
              3.0365741164856,
              48.326028248436
            ],
            [
              3.0158983096866,
              48.307897241414
            ],
            [
              3.0272557445779,
              48.300368070976
            ],
            [
              3.0204148507047,
              48.293439730003
            ],
            [
              3.0297705668286,
              48.285841287403
            ],
            [
              3.0249639004196,
              48.27600913901
            ],
            [
              3.0436326434308,
              48.272020556142
            ],
            [
              3.047565503762,
              48.249696163103
            ],
            [
              3.0314619630164,
              48.248879164003
            ],
            [
              3.0185660113854,
              48.235192866522
            ],
            [
              3.0189243879447,
              48.231774581507
            ],
            [
              3.0051596957479,
              48.207665501246
            ],
            [
              2.9889655342085,
              48.209014778073
            ],
            [
              2.9744704228122,
              48.205550154363
            ],
            [
              2.9695465243882,
              48.193835497978
            ],
            [
              2.9507427471906,
              48.190202971569
            ],
            [
              2.9347445709406,
              48.178820824383
            ],
            [
              2.9363157566366,
              48.163391744884
            ],
            [
              2.8680328112418,
              48.156437148324
            ],
            [
              2.8590064128033,
              48.147350198136
            ],
            [
              2.8411949308256,
              48.137851473794
            ],
            [
              2.8252530440464,
              48.133557427837
            ],
            [
              2.8208840113898,
              48.129664483454
            ],
            [
              2.800901925208,
              48.133251589253
            ],
            [
              2.7986329461926,
              48.150165448323
            ],
            [
              2.8099458960087,
              48.161299706681
            ],
            [
              2.7989459318842,
              48.168273053171
            ],
            [
              2.7801683123181,
              48.167546718776
            ],
            [
              2.7686108994112,
              48.163611325806
            ],
            [
              2.7462653547317,
              48.163802955575
            ],
            [
              2.7414632024764,
              48.159773038474
            ],
            [
              2.7535738017345,
              48.15321134405
            ],
            [
              2.7551942761197,
              48.145652570311
            ],
            [
              2.7291489796899,
              48.139089867843
            ],
            [
              2.706543037587,
              48.124819235783
            ],
            [
              2.6722231385896,
              48.124202437622
            ],
            [
              2.6634137701321,
              48.122204261229
            ],
            [
              2.6463713950461,
              48.136008390021
            ],
            [
              2.6397379446435,
              48.138858805822
            ],
            [
              2.6026896280919,
              48.131484417095
            ],
            [
              2.5775724874953,
              48.132078323329
            ],
            [
              2.570559954027,
              48.140815681604
            ],
            [
              2.5381145999136,
              48.140650823762
            ],
            [
              2.5215119811805,
              48.127298388836
            ],
            [
              2.4903125823631,
              48.126606179474
            ],
            [
              2.4648664700171,
              48.129109351924
            ],
            [
              2.4510870610062,
              48.123458807719
            ],
            [
              2.4442532323658,
              48.131467936811
            ],
            [
              2.4560832798883,
              48.13581808329
            ],
            [
              2.4783726996912,
              48.156743847
            ],
            [
              2.4832322969696,
              48.164516406945
            ],
            [
              2.5063243383023,
              48.156437563961
            ],
            [
              2.5168430223759,
              48.166807571121
            ],
            [
              2.5097551291606,
              48.177877699501
            ],
            [
              2.5129645377339,
              48.192979126229
            ],
            [
              2.5229439829919,
              48.200517077101
            ],
            [
              2.5141133764865,
              48.214377652405
            ],
            [
              2.5061863934974,
              48.238532108957
            ],
            [
              2.4866525872998,
              48.240104494021
            ],
            [
              2.4690928288345,
              48.255276432625
            ],
            [
              2.4501643446939,
              48.250036886561
            ],
            [
              2.4319323869166,
              48.255142430662
            ],
            [
              2.4237621901031,
              48.260296420297
            ],
            [
              2.4178314142956,
              48.278395078542
            ],
            [
              2.4231698173532,
              48.289315551296
            ],
            [
              2.4207604700518,
              48.299253278858
            ],
            [
              2.4048123966273,
              48.314593139765
            ],
            [
              2.4026629385658,
              48.320717653248
            ],
            [
              2.3936432426131,
              48.330904514522
            ],
            [
              2.3931765378081,
              48.336276224436
            ],
            [
              2.4255757157654,
              48.337245759129
            ],
            [
              2.4353486081157,
              48.353601096257
            ],
            [
              2.4298336175359,
              48.362012190971
            ],
            [
              2.4514612247762,
              48.374939569978
            ],
            [
              2.4692109816408,
              48.376449111258
            ],
            [
              2.4831342533299,
              48.3845692019
            ],
            [
              2.4738364829183,
              48.390477944369
            ],
            [
              2.482624887353,
              48.395570153778
            ],
            [
              2.5028806697968,
              48.392912777413
            ],
            [
              2.5216189750667,
              48.404932194382
            ],
            [
              2.5345790748515,
              48.404237036735
            ],
            [
              2.5337277804202,
              48.411251130404
            ],
            [
              2.5173761430247,
              48.427459754888
            ],
            [
              2.507657612825,
              48.429542119039
            ],
            [
              2.5047511155697,
              48.439445122154
            ],
            [
              2.5059355570193,
              48.461154873319
            ],
            [
              2.5018876471506,
              48.484768503419
            ],
            [
              2.51013182468,
              48.488378773705
            ],
            [
              2.5153562670148,
              48.504445668033
            ],
            [
              2.5090104505562,
              48.508140380735
            ],
            [
              2.4995247172499,
              48.525234550232
            ],
            [
              2.5108180265073,
              48.543116722691
            ],
            [
              2.5009750387696,
              48.546173070367
            ],
            [
              2.5135771377389,
              48.558215669745
            ],
            [
              2.5217689565415,
              48.573224776701
            ],
            [
              2.5182811370135,
              48.58325330677
            ],
            [
              2.5333428613649,
              48.589171157103
            ],
            [
              2.5407668344973,
              48.595495914424
            ],
            [
              2.5437805754235,
              48.603850686248
            ],
            [
              2.5406937585523,
              48.611406555087
            ],
            [
              2.5258420647199,
              48.613103319179
            ],
            [
              2.5268345542116,
              48.621417058075
            ],
            [
              2.5182318429154,
              48.627093611305
            ],
            [
              2.527421313864,
              48.633371245833
            ],
            [
              2.5424110527051,
              48.635473450172
            ],
            [
              2.5411152047382,
              48.651544369478
            ],
            [
              2.5336146170614,
              48.657334981367
            ],
            [
              2.5448201812671,
              48.67118451862
            ],
            [
              2.5594038933391,
              48.672390641128
            ],
            [
              2.5853737107586,
              48.679395351685
            ],
            [
              2.5787732945151,
              48.690249491839
            ],
            [
              2.5716535034613,
              48.69201676557
            ]
          ]
        ]
      },
      "properties": {
        "code": "77",
        "nom": "Seine-et-Marne",
        "population": 1428636,
        "delitspour1000": "5,066",
        "nombremosque": 66,
        "parentsEtrangers": "59,2",
        "benefRsa": 66414,
        "eoliennes": 15
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                4.8881212108854,
                44.331685388726
              ],
              [
                4.8953267719183,
                44.338062405104
              ],
              [
                4.8796465016088,
                44.345346527391
              ],
              [
                4.8753963679389,
                44.351439190756
              ],
              [
                4.8906819005659,
                44.359781959098
              ],
              [
                4.893168871015,
                44.367875394193
              ],
              [
                4.9066213399272,
                44.374101025322
              ],
              [
                4.9117660520568,
                44.387044638589
              ],
              [
                4.9123797297803,
                44.399635600585
              ],
              [
                4.9185138149855,
                44.407786865574
              ],
              [
                4.960461816094,
                44.420048061058
              ],
              [
                4.9704375593477,
                44.431367227183
              ],
              [
                4.9786944339347,
                44.423476108149
              ],
              [
                4.9886124577738,
                44.423200264487
              ],
              [
                5.0010692090687,
                44.412604850416
              ],
              [
                5.0187537016752,
                44.415979484324
              ],
              [
                5.0133020500209,
                44.405342926953
              ],
              [
                5.0157545106617,
                44.392767137778
              ],
              [
                5.033568721145,
                44.391088844502
              ],
              [
                5.0450881649883,
                44.38222013058
              ],
              [
                5.0708829483659,
                44.383226380932
              ],
              [
                5.0715584929227,
                44.377495636793
              ],
              [
                5.0520104655825,
                44.364657329845
              ],
              [
                5.0270113166527,
                44.362835272711
              ],
              [
                5.0217054810597,
                44.35674765583
              ],
              [
                5.0232851187488,
                44.345948323084
              ],
              [
                5.0090024588591,
                44.333764157861
              ],
              [
                5.0133769832902,
                44.326136687081
              ],
              [
                4.9931295821934,
                44.314275867797
              ],
              [
                4.9872903058308,
                44.293201871035
              ],
              [
                4.9785833048477,
                44.29746123871
              ],
              [
                4.9592140611525,
                44.300383345912
              ],
              [
                4.9451171535381,
                44.304930431589
              ],
              [
                4.9221541568202,
                44.308786460381
              ],
              [
                4.8895301353902,
                44.304153097826
              ],
              [
                4.8898043773976,
                44.314637092176
              ],
              [
                4.8816338901906,
                44.324867070891
              ],
              [
                4.8881212108854,
                44.331685388726
              ]
            ]
          ],
          [
            [
              [
                5.4987864391759,
                44.115716677493
              ],
              [
                5.5004101782252,
                44.100309962271
              ],
              [
                5.4980104773442,
                44.075202443076
              ],
              [
                5.5026418941828,
                44.063441793308
              ],
              [
                5.5244779560573,
                44.065033134176
              ],
              [
                5.5445930536793,
                44.069889333019
              ],
              [
                5.5436554275499,
                44.028707716695
              ],
              [
                5.5359794964897,
                44.013564481821
              ],
              [
                5.5286721373202,
                44.007561924547
              ],
              [
                5.5180561246763,
                43.990111257336
              ],
              [
                5.5122177562468,
                43.967076459311
              ],
              [
                5.5086491450188,
                43.959838865745
              ],
              [
                5.5120856887653,
                43.945999673026
              ],
              [
                5.5300236696842,
                43.945681187106
              ],
              [
                5.544991757257,
                43.939050342721
              ],
              [
                5.5676329697344,
                43.942636398699
              ],
              [
                5.5815171504923,
                43.923012828568
              ],
              [
                5.5815816010178,
                43.914993881433
              ],
              [
                5.5943330349272,
                43.916834690324
              ],
              [
                5.6071265063482,
                43.913769245956
              ],
              [
                5.5872056049016,
                43.885602478647
              ],
              [
                5.5856680158538,
                43.880240950982
              ],
              [
                5.5737718992297,
                43.866506510483
              ],
              [
                5.5497272595744,
                43.851223396544
              ],
              [
                5.5445261679646,
                43.818200447294
              ],
              [
                5.560920757582,
                43.821128997597
              ],
              [
                5.5673334240775,
                43.827074591963
              ],
              [
                5.5781958409725,
                43.829917377655
              ],
              [
                5.6035105078995,
                43.827530390778
              ],
              [
                5.6194025670079,
                43.829836525507
              ],
              [
                5.6544489060016,
                43.825111794533
              ],
              [
                5.6564369559568,
                43.819373716522
              ],
              [
                5.6671662984008,
                43.809913206473
              ],
              [
                5.6744832277441,
                43.807357748245
              ],
              [
                5.6845562685945,
                43.788504206527
              ],
              [
                5.6899129939668,
                43.785198190799
              ],
              [
                5.7135531559885,
                43.781117492473
              ],
              [
                5.7160627756459,
                43.757395207135
              ],
              [
                5.7273375065728,
                43.750258725263
              ],
              [
                5.7491578816916,
                43.740549346376
              ],
              [
                5.7573377215236,
                43.729404491649
              ],
              [
                5.753644561032,
                43.724619684068
              ],
              [
                5.7369472103627,
                43.716001963372
              ],
              [
                5.717354712484,
                43.693529264235
              ],
              [
                5.7052262694701,
                43.68992224271
              ],
              [
                5.6740837847517,
                43.693549049408
              ],
              [
                5.6617688277429,
                43.68741371217
              ],
              [
                5.6477480083622,
                43.675796063103
              ],
              [
                5.6069435191164,
                43.658685905188
              ],
              [
                5.5715633026355,
                43.661926831918
              ],
              [
                5.531450358273,
                43.659006908614
              ],
              [
                5.501441938421,
                43.666384037435
              ],
              [
                5.4880843830452,
                43.667642960946
              ],
              [
                5.4504516868099,
                43.679592990188
              ],
              [
                5.4392420964385,
                43.681108002682
              ],
              [
                5.4067240637555,
                43.695692422657
              ],
              [
                5.3410175350117,
                43.719699231195
              ],
              [
                5.3185027538528,
                43.735019633067
              ],
              [
                5.3079611019532,
                43.737953312927
              ],
              [
                5.2752691891284,
                43.741282660027
              ],
              [
                5.2557136505654,
                43.746560624881
              ],
              [
                5.2299865865365,
                43.747672957377
              ],
              [
                5.1879758806349,
                43.735099650014
              ],
              [
                5.173316741506,
                43.73880497047
              ],
              [
                5.051485003572,
                43.787879982851
              ],
              [
                5.0436368200957,
                43.795946325458
              ],
              [
                5.0411586397996,
                43.81094844339
              ],
              [
                5.0295347837616,
                43.827795588837
              ],
              [
                4.9960851928823,
                43.846061248978
              ],
              [
                4.9764746261986,
                43.865607815252
              ],
              [
                4.9663043874778,
                43.871649158077
              ],
              [
                4.9458230474417,
                43.87806829886
              ],
              [
                4.9258565482814,
                43.886489100284
              ],
              [
                4.9023179459346,
                43.887928969008
              ],
              [
                4.8852309492488,
                43.900149336786
              ],
              [
                4.8530923908916,
                43.91138477146
              ],
              [
                4.8217298557922,
                43.912828839936
              ],
              [
                4.7390596940896,
                43.92406219253
              ],
              [
                4.7499895249126,
                43.932181836453
              ],
              [
                4.7687787417118,
                43.933801125308
              ],
              [
                4.7790210940203,
                43.937894495052
              ],
              [
                4.7971529885585,
                43.956718817037
              ],
              [
                4.8079998756015,
                43.960029108371
              ],
              [
                4.8150547522655,
                43.967622474105
              ],
              [
                4.8101475881401,
                43.97704355585
              ],
              [
                4.8159834779277,
                43.988799971648
              ],
              [
                4.8319955437626,
                43.985260560051
              ],
              [
                4.8421001716407,
                43.98647354378
              ],
              [
                4.8455501032842,
                43.99667711759
              ],
              [
                4.8433326670726,
                44.009857987382
              ],
              [
                4.8376764645108,
                44.014939590778
              ],
              [
                4.8211405354756,
                44.016469245528
              ],
              [
                4.8161675560385,
                44.032144996975
              ],
              [
                4.8080065523425,
                44.03972336512
              ],
              [
                4.7891182892738,
                44.052194281428
              ],
              [
                4.7879793576032,
                44.065056776494
              ],
              [
                4.7578505502116,
                44.077246976599
              ],
              [
                4.7608553902053,
                44.08629669027
              ],
              [
                4.7548761078409,
                44.088309298713
              ],
              [
                4.7301353625795,
                44.079023395797
              ],
              [
                4.7196389371655,
                44.086665255194
              ],
              [
                4.7074595244331,
                44.103669745076
              ],
              [
                4.7070128409472,
                44.113682146579
              ],
              [
                4.714318820751,
                44.137803248451
              ],
              [
                4.7188928038989,
                44.14449718833
              ],
              [
                4.716116694835,
                44.165974548714
              ],
              [
                4.7220708851492,
                44.18742070852
              ],
              [
                4.7038951813692,
                44.197612213118
              ],
              [
                4.7097117846241,
                44.206925388326
              ],
              [
                4.7018165185921,
                44.216070926665
              ],
              [
                4.6836408656973,
                44.21242318348
              ],
              [
                4.6726658505102,
                44.216806161879
              ],
              [
                4.6784293717396,
                44.229429565698
              ],
              [
                4.6748406183712,
                44.238546852911
              ],
              [
                4.6540654761357,
                44.254338013491
              ],
              [
                4.649227423465,
                44.270359808636
              ],
              [
                4.653662160064,
                44.297280366126
              ],
              [
                4.6506111734968,
                44.329803148128
              ],
              [
                4.6790253292764,
                44.320490236187
              ],
              [
                4.7130169314739,
                44.320649375535
              ],
              [
                4.7201274920268,
                44.326711236709
              ],
              [
                4.7622549890071,
                44.325381622419
              ],
              [
                4.7825467521402,
                44.315582694107
              ],
              [
                4.7991000020835,
                44.303525444918
              ],
              [
                4.8028804076615,
                44.296593704504
              ],
              [
                4.8052938582648,
                44.268723026395
              ],
              [
                4.8126620207402,
                44.257716427139
              ],
              [
                4.814089831112,
                44.232314781729
              ],
              [
                4.8266501157802,
                44.228322408488
              ],
              [
                4.845368774508,
                44.241470533268
              ],
              [
                4.8737563985909,
                44.25925825523
              ],
              [
                4.8961483935046,
                44.264431155121
              ],
              [
                4.9060631576886,
                44.260294315057
              ],
              [
                4.9175325402435,
                44.260348620291
              ],
              [
                4.935492573713,
                44.264314996193
              ],
              [
                4.9598922113805,
                44.274192870866
              ],
              [
                4.9814530758182,
                44.284833474448
              ],
              [
                5.0069116240356,
                44.287554489231
              ],
              [
                5.0218466033995,
                44.295587171529
              ],
              [
                5.0384946811178,
                44.299637776655
              ],
              [
                5.0605607254823,
                44.308137131551
              ],
              [
                5.0765144884036,
                44.284084169033
              ],
              [
                5.1078521429136,
                44.280398126188
              ],
              [
                5.1214657621262,
                44.287480229186
              ],
              [
                5.1546757377907,
                44.301844910979
              ],
              [
                5.1524172226752,
                44.307683682999
              ],
              [
                5.1664353137721,
                44.314853482023
              ],
              [
                5.1726899918655,
                44.309391119528
              ],
              [
                5.1672051148975,
                44.292050008972
              ],
              [
                5.1497309928727,
                44.282086494235
              ],
              [
                5.1474716753569,
                44.269525185767
              ],
              [
                5.1571364176122,
                44.267225182448
              ],
              [
                5.1615500254058,
                44.245588822308
              ],
              [
                5.1516592069293,
                44.23772859876
              ],
              [
                5.1548996659898,
                44.230941470835
              ],
              [
                5.1735074807317,
                44.2219664549
              ],
              [
                5.2046138867346,
                44.215116852293
              ],
              [
                5.2381459551442,
                44.213233409548
              ],
              [
                5.2404514324301,
                44.230825507739
              ],
              [
                5.2565049248197,
                44.230055506
              ],
              [
                5.2911909842134,
                44.214933969307
              ],
              [
                5.3037098672957,
                44.206011143284
              ],
              [
                5.3183111725222,
                44.209869282984
              ],
              [
                5.336784174291,
                44.20389269414
              ],
              [
                5.3540415690827,
                44.213431973111
              ],
              [
                5.3845269211658,
                44.20104933819
              ],
              [
                5.3830416498971,
                44.198796138337
              ],
              [
                5.3864765223646,
                44.1769070133
              ],
              [
                5.3832371388833,
                44.155284811726
              ],
              [
                5.3905234132549,
                44.1534251894
              ],
              [
                5.4357509062277,
                44.152249650949
              ],
              [
                5.4369459430348,
                44.142812945214
              ],
              [
                5.4472805287304,
                44.135994708471
              ],
              [
                5.4492512328028,
                44.124591922526
              ],
              [
                5.4547151332789,
                44.119226133429
              ],
              [
                5.4987864391759,
                44.115716677493
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "84",
        "nom": "Vaucluse",
        "population": 561941,
        "delitspour1000": "5,068",
        "nombremosque": 34,
        "parentsEtrangers": "65,4",
        "benefRsa": 31774,
        "eoliennes": 6
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -0.1021158452812,
              47.06480003115
            ],
            [
              -0.10150441660145,
              47.08326376928
            ],
            [
              -0.098719424061378,
              47.090117668474
            ],
            [
              -0.085909274640678,
              47.101010256779
            ],
            [
              -0.060661742719366,
              47.09514763055
            ],
            [
              -0.044169213959146,
              47.093239781385
            ],
            [
              -0.03562437381954,
              47.086261232309
            ],
            [
              -0.029234974929682,
              47.095257561848
            ],
            [
              -0.026535185584925,
              47.105798471803
            ],
            [
              -0.039289561029516,
              47.108055925394
            ],
            [
              -0.040856468682482,
              47.112928627112
            ],
            [
              -0.034011786935389,
              47.127334734816
            ],
            [
              -0.010739414334867,
              47.15751215065
            ],
            [
              0.019016376976915,
              47.175754285742
            ],
            [
              0.036501918227681,
              47.160445278183
            ],
            [
              0.049480342584696,
              47.168623012343
            ],
            [
              0.053830055961677,
              47.16373374848
            ],
            [
              0.066259497472021,
              47.143215902043
            ],
            [
              0.078395363975684,
              47.146334786534
            ],
            [
              0.08087053131906,
              47.137653029292
            ],
            [
              0.076371249669326,
              47.123931049218
            ],
            [
              0.084648249403316,
              47.118377760157
            ],
            [
              0.10471937462849,
              47.120793812241
            ],
            [
              0.11111857810383,
              47.129423259303
            ],
            [
              0.12372359489648,
              47.128315822721
            ],
            [
              0.12716663727095,
              47.119967203103
            ],
            [
              0.13613099833731,
              47.121578754741
            ],
            [
              0.13471650901781,
              47.107872599064
            ],
            [
              0.16126718554822,
              47.100796179607
            ],
            [
              0.16598416316105,
              47.107162285602
            ],
            [
              0.18145709966147,
              47.1143899519
            ],
            [
              0.18811779784912,
              47.100828164294
            ],
            [
              0.20095307158529,
              47.091257976623
            ],
            [
              0.17421802926902,
              47.071274231914
            ],
            [
              0.17997285846393,
              47.059187917933
            ],
            [
              0.19167202519639,
              47.064672009871
            ],
            [
              0.20799449312514,
              47.053230964732
            ],
            [
              0.23175079195616,
              47.064054806793
            ],
            [
              0.24245135321948,
              47.0711879788
            ],
            [
              0.26165981464917,
              47.070051249456
            ],
            [
              0.26761937550861,
              47.067504197075
            ],
            [
              0.26201563438582,
              47.057459330927
            ],
            [
              0.26770726634947,
              47.04388297477
            ],
            [
              0.29822992579073,
              47.053922293608
            ],
            [
              0.30698465850517,
              47.048723481399
            ],
            [
              0.30969866315285,
              47.025643877461
            ],
            [
              0.29867667498614,
              47.019599341054
            ],
            [
              0.30543065376719,
              47.012362068122
            ],
            [
              0.30884587377182,
              46.999441441774
            ],
            [
              0.30178521378569,
              46.984422127588
            ],
            [
              0.3083994154951,
              46.978149875297
            ],
            [
              0.30073879657193,
              46.973829519846
            ],
            [
              0.30507546032998,
              46.965176268239
            ],
            [
              0.3052147303369,
              46.952805098497
            ],
            [
              0.31123243685649,
              46.937837015115
            ],
            [
              0.32483667041576,
              46.930652041087
            ],
            [
              0.33952563735758,
              46.936623468454
            ],
            [
              0.34773843894922,
              46.936585764264
            ],
            [
              0.35524351132264,
              46.94418585304
            ],
            [
              0.36651145250788,
              46.949554418002
            ],
            [
              0.38814626546636,
              46.943624533306
            ],
            [
              0.40666063754734,
              46.936647597652
            ],
            [
              0.41852515647745,
              46.937386922114
            ],
            [
              0.43870457627727,
              46.929578354733
            ],
            [
              0.44480103455716,
              46.941152434129
            ],
            [
              0.47662392866394,
              46.949861808585
            ],
            [
              0.5061349705044,
              46.959245419792
            ],
            [
              0.52812896085369,
              46.955971768907
            ],
            [
              0.53928916139484,
              46.960219024665
            ],
            [
              0.57084220800147,
              46.95593925543
            ],
            [
              0.59834824957594,
              46.956764146875
            ],
            [
              0.60155821661783,
              46.959107207652
            ],
            [
              0.60117289544287,
              46.973094369377
            ],
            [
              0.59353374575945,
              46.977612888014
            ],
            [
              0.57382203251682,
              46.983340464589
            ],
            [
              0.57368515665648,
              46.995531274591
            ],
            [
              0.56695306996355,
              47.002266858023
            ],
            [
              0.59055093530507,
              47.006722651596
            ],
            [
              0.6188740376389,
              47.007464569411
            ],
            [
              0.62124367303806,
              46.997106205666
            ],
            [
              0.63621078823085,
              46.985451013839
            ],
            [
              0.64769454368947,
              46.98827539877
            ],
            [
              0.65646206458496,
              46.985434564914
            ],
            [
              0.66162129594461,
              46.978086547342
            ],
            [
              0.68216214670066,
              46.977079806439
            ],
            [
              0.69256676799124,
              46.974304310533
            ],
            [
              0.6961893847553,
              46.956809748541
            ],
            [
              0.70624788531743,
              46.937157219193
            ],
            [
              0.70357222720342,
              46.930055655547
            ],
            [
              0.7043177119069,
              46.903295922263
            ],
            [
              0.72673737636298,
              46.886740909711
            ],
            [
              0.73368489987152,
              46.876040130575
            ],
            [
              0.74733084634723,
              46.869392079962
            ],
            [
              0.75088169303492,
              46.863524734354
            ],
            [
              0.77198838768839,
              46.860643073187
            ],
            [
              0.76879944678165,
              46.85074613749
            ],
            [
              0.79032856900623,
              46.852389283147
            ],
            [
              0.79645898941075,
              46.848962468561
            ],
            [
              0.78680224354972,
              46.840463346525
            ],
            [
              0.79509333742744,
              46.832522249054
            ],
            [
              0.80932023680023,
              46.827853568236
            ],
            [
              0.81032866524034,
              46.813064718291
            ],
            [
              0.8153195131751,
              46.805707876783
            ],
            [
              0.81190192936381,
              46.794504364542
            ],
            [
              0.81628357550664,
              46.787769101729
            ],
            [
              0.82943649435874,
              46.783579432834
            ],
            [
              0.83064773545714,
              46.775361466403
            ],
            [
              0.84293807311317,
              46.764275112746
            ],
            [
              0.85444929067001,
              46.759970797231
            ],
            [
              0.86746898682573,
              46.748216404838
            ],
            [
              0.88777529605684,
              46.737902736858
            ],
            [
              0.90104236961312,
              46.736090564754
            ],
            [
              0.91455153531157,
              46.710120272246
            ],
            [
              0.9249533025609,
              46.699991021542
            ],
            [
              0.92474929055741,
              46.692789633606
            ],
            [
              0.90215945171528,
              46.67919114553
            ],
            [
              0.91071057116932,
              46.677183661606
            ],
            [
              0.9067032428601,
              46.665571264221
            ],
            [
              0.91742860399951,
              46.65036307159
            ],
            [
              0.90652086468369,
              46.647752216163
            ],
            [
              0.89638293110539,
              46.633451946341
            ],
            [
              0.89430329614895,
              46.625732175305
            ],
            [
              0.90693650860659,
              46.615213230068
            ],
            [
              0.90986438287983,
              46.603447081391
            ],
            [
              0.91586544594852,
              46.596627887059
            ],
            [
              0.93775566335965,
              46.594420109118
            ],
            [
              0.9371898310662,
              46.586044724789
            ],
            [
              0.94202235129636,
              46.580794785364
            ],
            [
              0.96266777384523,
              46.574297702094
            ],
            [
              0.98209440694689,
              46.572640353042
            ],
            [
              0.98723321911375,
              46.565560099789
            ],
            [
              1.0147685045818,
              46.567759565973
            ],
            [
              1.0219575008089,
              46.55371655484
            ],
            [
              1.0206003685537,
              46.537099020664
            ],
            [
              1.0722975106803,
              46.53723511351
            ],
            [
              1.0875973131695,
              46.538168324634
            ],
            [
              1.1082856489547,
              46.531510215741
            ],
            [
              1.1459288779804,
              46.506400874677
            ],
            [
              1.1491485689753,
              46.502205353076
            ],
            [
              1.1349675834984,
              46.495262876365
            ],
            [
              1.1405271083029,
              46.485634879486
            ],
            [
              1.1529790996043,
              46.472957682593
            ],
            [
              1.1355078072574,
              46.470884241481
            ],
            [
              1.1516072310249,
              46.449233517349
            ],
            [
              1.1689192762458,
              46.446308575491
            ],
            [
              1.1860178766023,
              46.441083576014
            ],
            [
              1.1834055129956,
              46.429224899772
            ],
            [
              1.2011339896178,
              46.432466167404
            ],
            [
              1.2126877519811,
              46.432217971701
            ],
            [
              1.1944727430082,
              46.410351612296
            ],
            [
              1.1950871576883,
              46.40275403272
            ],
            [
              1.1772787760985,
              46.383948000481
            ],
            [
              1.1575421056358,
              46.388738882303
            ],
            [
              1.1469657078228,
              46.375896349497
            ],
            [
              1.1492558838096,
              46.369216903606
            ],
            [
              1.1297577719073,
              46.360462680395
            ],
            [
              1.1272435422129,
              46.347839222972
            ],
            [
              1.1074792516558,
              46.354195343032
            ],
            [
              1.0969034989942,
              46.362111472187
            ],
            [
              1.0783369629335,
              46.358464682058
            ],
            [
              1.0501626260953,
              46.362782836344
            ],
            [
              1.0511809504649,
              46.358160517464
            ],
            [
              1.0269979126089,
              46.343038073772
            ],
            [
              1.0278959297973,
              46.332023905128
            ],
            [
              1.0241639515741,
              46.317662492256
            ],
            [
              1.0131040014919,
              46.309986658686
            ],
            [
              1.004800186838,
              46.293781839531
            ],
            [
              1.005891998265,
              46.280972289235
            ],
            [
              0.98951699161804,
              46.281903968854
            ],
            [
              0.96842001221032,
              46.286020765246
            ],
            [
              0.94953943004579,
              46.287180346538
            ],
            [
              0.93647890753638,
              46.292823836247
            ],
            [
              0.93266884809059,
              46.28202368613
            ],
            [
              0.90727538588543,
              46.285260253253
            ],
            [
              0.89991373468769,
              46.28264843333
            ],
            [
              0.89134243468083,
              46.269400518903
            ],
            [
              0.88533180427558,
              46.266083969894
            ],
            [
              0.86144940761012,
              46.261636386108
            ],
            [
              0.85909327756833,
              46.250447269088
            ],
            [
              0.84914741884707,
              46.248877865735
            ],
            [
              0.84547619420008,
              46.242057704191
            ],
            [
              0.85189930291137,
              46.2359554315
            ],
            [
              0.84801138948638,
              46.228750196503
            ],
            [
              0.81599193497797,
              46.228505649491
            ],
            [
              0.80727522194406,
              46.22492290773
            ],
            [
              0.79632554149705,
              46.210961874188
            ],
            [
              0.80022145185827,
              46.201475872613
            ],
            [
              0.81558170661796,
              46.196733704662
            ],
            [
              0.81877426405719,
              46.186788611869
            ],
            [
              0.8300284343603,
              46.185049240394
            ],
            [
              0.83660345797057,
              46.176812243845
            ],
            [
              0.82830880944013,
              46.17463258038
            ],
            [
              0.83232792935273,
              46.14978258083
            ],
            [
              0.83583470448799,
              46.143552683205
            ],
            [
              0.84476324651838,
              46.140138998175
            ],
            [
              0.84482890084101,
              46.134177697482
            ],
            [
              0.82343238223316,
              46.128584404123
            ],
            [
              0.80950911017317,
              46.138205095419
            ],
            [
              0.79723911297019,
              46.132639294005
            ],
            [
              0.78015791208515,
              46.132146290713
            ],
            [
              0.74690845812273,
              46.13857389875
            ],
            [
              0.72824604972602,
              46.135008535887
            ],
            [
              0.71255307137588,
              46.139591492141
            ],
            [
              0.70686980526733,
              46.129123201938
            ],
            [
              0.68406230433217,
              46.119342506974
            ],
            [
              0.67872102383007,
              46.109815633487
            ],
            [
              0.68744642686547,
              46.09725447452
            ],
            [
              0.66024943539143,
              46.095741994227
            ],
            [
              0.64162895001642,
              46.091806988364
            ],
            [
              0.60864943901766,
              46.089686935991
            ],
            [
              0.60336939853311,
              46.076715104382
            ],
            [
              0.58195736273612,
              46.081085942693
            ],
            [
              0.57497704528704,
              46.078997260944
            ],
            [
              0.56286748151915,
              46.089562263774
            ],
            [
              0.55404215344677,
              46.090457947519
            ],
            [
              0.53997555504991,
              46.085555478485
            ],
            [
              0.53455840721242,
              46.098043670735
            ],
            [
              0.5241097133275,
              46.105775893022
            ],
            [
              0.51986518761971,
              46.114752002752
            ],
            [
              0.50518002031707,
              46.118542047617
            ],
            [
              0.50641325848251,
              46.131948964394
            ],
            [
              0.49249289349163,
              46.135952486986
            ],
            [
              0.48812311162237,
              46.129266401439
            ],
            [
              0.4723029396892,
              46.130130657707
            ],
            [
              0.46686874789163,
              46.116811499669
            ],
            [
              0.45778373543798,
              46.108156518233
            ],
            [
              0.4432615785848,
              46.101569497166
            ],
            [
              0.44583614455469,
              46.088877982546
            ],
            [
              0.46137039761349,
              46.087540071105
            ],
            [
              0.4759565784283,
              46.082695886727
            ],
            [
              0.4744775570274,
              46.073920139993
            ],
            [
              0.4806482933821,
              46.065427190061
            ],
            [
              0.47033221964969,
              46.064649751647
            ],
            [
              0.456335744104,
              46.05436034237
            ],
            [
              0.44508390693692,
              46.050797203062
            ],
            [
              0.43294150558574,
              46.051538293862
            ],
            [
              0.4138031271991,
              46.049008552161
            ],
            [
              0.40265671681574,
              46.062677433561
            ],
            [
              0.39105259889106,
              46.06634832653
            ],
            [
              0.37755887765857,
              46.063144903028
            ],
            [
              0.36229114045965,
              46.064386429332
            ],
            [
              0.31756830797163,
              46.064922145215
            ],
            [
              0.29728743780505,
              46.059247091762
            ],
            [
              0.27981131515865,
              46.060966386389
            ],
            [
              0.25057745385649,
              46.079935391655
            ],
            [
              0.23955572940534,
              46.081364339961
            ],
            [
              0.21971878996587,
              46.094314452313
            ],
            [
              0.19735466096157,
              46.095552773268
            ],
            [
              0.19262927263694,
              46.098933851977
            ],
            [
              0.19107765237521,
              46.11227527396
            ],
            [
              0.20237951061306,
              46.119279009225
            ],
            [
              0.2018092072724,
              46.129754117364
            ],
            [
              0.21491801591412,
              46.138843669228
            ],
            [
              0.22035828616308,
              46.1580034483
            ],
            [
              0.20783017713254,
              46.160423639072
            ],
            [
              0.19353824374893,
              46.158263900755
            ],
            [
              0.18705728777419,
              46.148510013671
            ],
            [
              0.17369264350006,
              46.150820100144
            ],
            [
              0.17187491952089,
              46.155825313679
            ],
            [
              0.15511396402513,
              46.157168309485
            ],
            [
              0.14858178702491,
              46.170754883108
            ],
            [
              0.14150062749168,
              46.178461342361
            ],
            [
              0.13198267243093,
              46.183135976347
            ],
            [
              0.1076941551618,
              46.186125599706
            ],
            [
              0.11235410051379,
              46.194182682319
            ],
            [
              0.11283807416044,
              46.210933542389
            ],
            [
              0.12846561471163,
              46.224425597548
            ],
            [
              0.14344146274523,
              46.230117287533
            ],
            [
              0.12883941444788,
              46.267230714602
            ],
            [
              0.15955991939526,
              46.266565295373
            ],
            [
              0.17231902804727,
              46.278600348745
            ],
            [
              0.16010487265191,
              46.293575540652
            ],
            [
              0.1566889252778,
              46.30102675136
            ],
            [
              0.16956926180366,
              46.310184245255
            ],
            [
              0.16750491307184,
              46.319640838973
            ],
            [
              0.1773663837498,
              46.328109476662
            ],
            [
              0.15696594789895,
              46.342749890175
            ],
            [
              0.13759390428408,
              46.34939598469
            ],
            [
              0.12431046658269,
              46.348417353796
            ],
            [
              0.11938637219542,
              46.341683662991
            ],
            [
              0.097806188009644,
              46.330796245295
            ],
            [
              0.096556122495943,
              46.323300741122
            ],
            [
              0.078219163246238,
              46.304936271825
            ],
            [
              0.037600794669074,
              46.325531520268
            ],
            [
              0.015822426904369,
              46.326087725559
            ],
            [
              0.020814625641771,
              46.341581452892
            ],
            [
              0.029654722943721,
              46.349187779313
            ],
            [
              0.018140433711669,
              46.351916657132
            ],
            [
              0.013851448575042,
              46.357056060846
            ],
            [
              0.03421632320898,
              46.373492198897
            ],
            [
              0.024929711629679,
              46.377803568274
            ],
            [
              0.020691642160083,
              46.387999786284
            ],
            [
              0.012313423320613,
              46.391542761358
            ],
            [
              -0.0089995059831326,
              46.391059106508
            ],
            [
              -0.008462131351587,
              46.404211582162
            ],
            [
              -0.01709682647281,
              46.411324090947
            ],
            [
              -0.017267903219501,
              46.420571361409
            ],
            [
              -0.011299842729886,
              46.423234996333
            ],
            [
              -0.014215068419588,
              46.43496627092
            ],
            [
              -0.010452603768933,
              46.448815640542
            ],
            [
              -0.019240825523021,
              46.45326940061
            ],
            [
              -0.012309936983718,
              46.470694301504
            ],
            [
              -0.0147038329685,
              46.475070919352
            ],
            [
              -0.043263414910959,
              46.472480781549
            ],
            [
              -0.043096282305571,
              46.480540367739
            ],
            [
              -0.03768276034177,
              46.486369591935
            ],
            [
              -0.038663793231564,
              46.499850365018
            ],
            [
              -0.030042966556621,
              46.509886050511
            ],
            [
              -0.031353862470829,
              46.524980950312
            ],
            [
              -0.0071548726332798,
              46.523276162065
            ],
            [
              0.0066767347091348,
              46.542776485683
            ],
            [
              0.0078888975201096,
              46.547976850066
            ],
            [
              -0.010081331385269,
              46.55476373186
            ],
            [
              -0.0080246690020487,
              46.567542169166
            ],
            [
              0.013193816349821,
              46.580601363601
            ],
            [
              0.04271943974108,
              46.591167098917
            ],
            [
              0.025985672947266,
              46.594744304871
            ],
            [
              0.022869415210482,
              46.614212991204
            ],
            [
              0.0086376816041532,
              46.611042528745
            ],
            [
              -0.0015291158138491,
              46.612094811535
            ],
            [
              -0.011504647867234,
              46.619225449023
            ],
            [
              -0.0034753268094469,
              46.628465995571
            ],
            [
              0.00079199952682919,
              46.643059891449
            ],
            [
              -0.0051596381211071,
              46.644693509564
            ],
            [
              -0.016769502261875,
              46.639683917044
            ],
            [
              -0.026546668122694,
              46.628860828743
            ],
            [
              -0.048554239116896,
              46.624036740061
            ],
            [
              -0.06581020011429,
              46.622820182052
            ],
            [
              -0.066516499784791,
              46.631355491397
            ],
            [
              -0.053503349825477,
              46.642925357759
            ],
            [
              -0.033389346094041,
              46.653650870862
            ],
            [
              -0.040432926147215,
              46.663720449527
            ],
            [
              -0.027263569610362,
              46.672769820346
            ],
            [
              -0.0069453835536775,
              46.682909890873
            ],
            [
              -0.018074675303488,
              46.68920619588
            ],
            [
              -0.015905232889201,
              46.69645111762
            ],
            [
              -0.0022220315694828,
              46.697677781874
            ],
            [
              0.0035005282225884,
              46.70449977489
            ],
            [
              0.00070427201408919,
              46.716233316592
            ],
            [
              0.013658351118858,
              46.723878714539
            ],
            [
              0.038264246176312,
              46.731576891243
            ],
            [
              0.0052491993449492,
              46.754983815346
            ],
            [
              -0.014979708609134,
              46.756370135791
            ],
            [
              -0.022489321390634,
              46.769401195255
            ],
            [
              -0.020014707607482,
              46.780091542003
            ],
            [
              -0.022325255854886,
              46.789610800849
            ],
            [
              -0.0058047959610808,
              46.803001163225
            ],
            [
              0.0063273731096121,
              46.80887449674
            ],
            [
              0.0079945005666118,
              46.814308798391
            ],
            [
              -0.0049859027667166,
              46.820133350695
            ],
            [
              -0.020317395826605,
              46.812649391939
            ],
            [
              -0.031269858833015,
              46.820253423832
            ],
            [
              -0.043579946911906,
              46.820841880314
            ],
            [
              -0.045691184857316,
              46.832105032831
            ],
            [
              -0.034476788697424,
              46.832068104573
            ],
            [
              -0.024098544835817,
              46.842138530857
            ],
            [
              -0.0077688625346289,
              46.847469231476
            ],
            [
              0.00058777867426307,
              46.846064672081
            ],
            [
              0.017292616841451,
              46.835873251042
            ],
            [
              0.034227651110925,
              46.853945893049
            ],
            [
              0.025614185916897,
              46.852872967775
            ],
            [
              0.0033943277746572,
              46.861558000916
            ],
            [
              -0.0045912553699726,
              46.868685024543
            ],
            [
              -0.015861375676026,
              46.872819691909
            ],
            [
              -0.035440392494862,
              46.874845887223
            ],
            [
              -0.0090362987061244,
              46.907476003456
            ],
            [
              -0.015596199247951,
              46.926872752288
            ],
            [
              -0.021623264746082,
              46.935524083976
            ],
            [
              -0.03112429319608,
              46.942393495684
            ],
            [
              -0.032900669453543,
              46.951402897075
            ],
            [
              -0.04165290373487,
              46.95761184927
            ],
            [
              -0.046252831416631,
              46.966516800883
            ],
            [
              -0.033322474168538,
              46.979923297248
            ],
            [
              -0.038157188607888,
              46.989388181961
            ],
            [
              -0.054669107397809,
              46.994648276801
            ],
            [
              -0.066769676059753,
              46.993878488228
            ],
            [
              -0.086278760754682,
              46.985300228378
            ],
            [
              -0.0936517630819,
              47.010210823666
            ],
            [
              -0.078636707416496,
              47.009897920861
            ],
            [
              -0.087697741698477,
              47.023132400885
            ],
            [
              -0.092407566675428,
              47.042369227401
            ],
            [
              -0.1021158452812,
              47.06480003115
            ]
          ]
        ]
      },
      "properties": {
        "code": "86",
        "nom": "Vienne",
        "population": 439332,
        "delitspour1000": "4,215",
        "nombremosque": 5,
        "parentsEtrangers": "78,1",
        "benefRsa": 25290,
        "eoliennes": 127
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.3318980606376,
              48.817010929642
            ],
            [
              2.363946550191,
              48.816314210034
            ],
            [
              2.3909392530738,
              48.826078980076
            ],
            [
              2.406031823401,
              48.827615470779
            ],
            [
              2.4384475102742,
              48.818232447877
            ],
            [
              2.4626960627524,
              48.819059770564
            ],
            [
              2.4675819883673,
              48.833133318793
            ],
            [
              2.4634383121686,
              48.842089485269
            ],
            [
              2.447699326814,
              48.844818443355
            ],
            [
              2.4281301699852,
              48.841528392473
            ],
            [
              2.4221386362435,
              48.835797660955
            ],
            [
              2.4122456125626,
              48.834538914673
            ],
            [
              2.4163411302989,
              48.849233783552
            ],
            [
              2.4476549109644,
              48.852094875028
            ],
            [
              2.4815382130314,
              48.861405371284
            ],
            [
              2.4956483361361,
              48.860848724102
            ],
            [
              2.5313232159076,
              48.844429712931
            ],
            [
              2.5709791871159,
              48.8226801571
            ],
            [
              2.574124871997,
              48.8129126037
            ],
            [
              2.5922736733656,
              48.807437551952
            ],
            [
              2.5918208872821,
              48.798135558654
            ],
            [
              2.5997697108259,
              48.794974135979
            ],
            [
              2.5856046173972,
              48.778627130829
            ],
            [
              2.5919605754401,
              48.77140075822
            ],
            [
              2.6070020497727,
              48.774401216878
            ],
            [
              2.6136517425679,
              48.76102540673
            ],
            [
              2.5982969429908,
              48.760480074814
            ],
            [
              2.6023411143901,
              48.753730034287
            ],
            [
              2.5858204453665,
              48.741487637605
            ],
            [
              2.5946737376888,
              48.73180600135
            ],
            [
              2.5852087623188,
              48.727242137595
            ],
            [
              2.5688027048808,
              48.707221723749
            ],
            [
              2.5752212918029,
              48.700368420834
            ],
            [
              2.5716535034613,
              48.69201676557
            ],
            [
              2.5538613239117,
              48.688326690701
            ],
            [
              2.5415977449297,
              48.700126122289
            ],
            [
              2.5310726121952,
              48.699783892783
            ],
            [
              2.5292660759678,
              48.706797944381
            ],
            [
              2.5208681543244,
              48.712797146805
            ],
            [
              2.5157481028347,
              48.728940405475
            ],
            [
              2.5035392718422,
              48.735213292289
            ],
            [
              2.4787749913062,
              48.727641481468
            ],
            [
              2.4673873361332,
              48.728713712543
            ],
            [
              2.4549382750663,
              48.722660904777
            ],
            [
              2.4401207414166,
              48.725433667155
            ],
            [
              2.414130346149,
              48.717993666318
            ],
            [
              2.4110164293164,
              48.726050022829
            ],
            [
              2.3867469186816,
              48.719975201473
            ],
            [
              2.3707040678761,
              48.720171411205
            ],
            [
              2.3693515629708,
              48.746067457624
            ],
            [
              2.3546796298175,
              48.738565068167
            ],
            [
              2.3207207141635,
              48.74875804931
            ],
            [
              2.3102224901101,
              48.75695290327
            ],
            [
              2.3258147798433,
              48.781908297526
            ],
            [
              2.3186962648686,
              48.787997409568
            ],
            [
              2.3318980606376,
              48.817010929642
            ]
          ]
        ]
      },
      "properties": {
        "code": "94",
        "nom": "Val-de-Marne",
        "population": 1407972,
        "delitspour1000": "5,732",
        "nombremosque": 77,
        "parentsEtrangers": "45,2",
        "benefRsa": 87340,
        "eoliennes": 1
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.7802082627383,
              46.176676203678
            ],
            [
              4.7802430578135,
              46.189052363162
            ],
            [
              4.7933904888672,
              46.204700104778
            ],
            [
              4.7945859355866,
              46.218311767171
            ],
            [
              4.8077505003904,
              46.236972415214
            ],
            [
              4.8115552603184,
              46.249932664612
            ],
            [
              4.8109975376095,
              46.259923276174
            ],
            [
              4.8259515552341,
              46.274785814873
            ],
            [
              4.8332136171616,
              46.300145048773
            ],
            [
              4.8534210199043,
              46.32990123356
            ],
            [
              4.8514544831808,
              46.356271094403
            ],
            [
              4.8585287407096,
              46.368018459572
            ],
            [
              4.873839273104,
              46.384668143424
            ],
            [
              4.8882101486247,
              46.402977057782
            ],
            [
              4.8918151434404,
              46.439918009678
            ],
            [
              4.8992958191725,
              46.450123277325
            ],
            [
              4.9111172894635,
              46.457732635494
            ],
            [
              4.9157805036687,
              46.465413333501
            ],
            [
              4.9155507621799,
              46.488939443669
            ],
            [
              4.9257189717408,
              46.497441604339
            ],
            [
              4.9314208893674,
              46.509211481378
            ],
            [
              4.9400218107181,
              46.517199374492
            ],
            [
              4.9491021767105,
              46.501380502462
            ],
            [
              4.9645994700407,
              46.513175985923
            ],
            [
              4.9835496952255,
              46.515392507897
            ],
            [
              5.011007749262,
              46.510294840159
            ],
            [
              5.0141793460298,
              46.500587565063
            ],
            [
              5.0523715346281,
              46.484873791529
            ],
            [
              5.0700061667722,
              46.485667291269
            ],
            [
              5.0989387475854,
              46.497166675779
            ],
            [
              5.10737201939,
              46.491919458253
            ],
            [
              5.1152051358154,
              46.494069043579
            ],
            [
              5.141950046447,
              46.508357328146
            ],
            [
              5.1664495124354,
              46.505590616989
            ],
            [
              5.1667923612572,
              46.514674866375
            ],
            [
              5.1815968667213,
              46.509758803783
            ],
            [
              5.2011399666188,
              46.508211405762
            ],
            [
              5.2066353369633,
              46.48639795185
            ],
            [
              5.2130427939332,
              46.481261974235
            ],
            [
              5.2150628404089,
              46.468359298393
            ],
            [
              5.2255477233319,
              46.468273543404
            ],
            [
              5.2350290009581,
              46.457948870166
            ],
            [
              5.2468319237306,
              46.459403653865
            ],
            [
              5.2545935888787,
              46.454117831184
            ],
            [
              5.2759250637615,
              46.4481213263
            ],
            [
              5.3105633704742,
              46.446769968334
            ],
            [
              5.3194882695109,
              46.430803499916
            ],
            [
              5.308978572395,
              46.424532251303
            ],
            [
              5.3076872001929,
              46.416819197974
            ],
            [
              5.3147703161373,
              46.40947618293
            ],
            [
              5.331272401231,
              46.399499315198
            ],
            [
              5.3525084400185,
              46.397586033829
            ],
            [
              5.3552221284773,
              46.39375452396
            ],
            [
              5.3770431289975,
              46.381355482485
            ],
            [
              5.362996992744,
              46.370926983475
            ],
            [
              5.3770544086974,
              46.364113395825
            ],
            [
              5.3734648101425,
              46.352234450451
            ],
            [
              5.3819165667023,
              46.345255630996
            ],
            [
              5.3992759574473,
              46.339521371463
            ],
            [
              5.4042931339536,
              46.332813029221
            ],
            [
              5.4046508439018,
              46.310338173994
            ],
            [
              5.4102335548466,
              46.30910668308
            ],
            [
              5.4170861726833,
              46.339534237431
            ],
            [
              5.4278984240109,
              46.342210714821
            ],
            [
              5.4377949533958,
              46.315109371064
            ],
            [
              5.4669175767252,
              46.323267248222
            ],
            [
              5.4753005499335,
              46.315382606424
            ],
            [
              5.4674037740958,
              46.295566233017
            ],
            [
              5.4595129521644,
              46.290471994825
            ],
            [
              5.4568426743662,
              46.274473574235
            ],
            [
              5.4730515403718,
              46.265066548043
            ],
            [
              5.4995891133957,
              46.268200455579
            ],
            [
              5.5129432911698,
              46.264539612518
            ],
            [
              5.5420340189851,
              46.270203795698
            ],
            [
              5.5581274576637,
              46.282173642648
            ],
            [
              5.5663641424008,
              46.294050213862
            ],
            [
              5.5855011829326,
              46.292557783319
            ],
            [
              5.59838931795,
              46.298445925032
            ],
            [
              5.610460815624,
              46.324211378495
            ],
            [
              5.6176441912887,
              46.329091983869
            ],
            [
              5.6305151249665,
              46.330204693001
            ],
            [
              5.6419584041921,
              46.342028332374
            ],
            [
              5.6493445080392,
              46.339494512081
            ],
            [
              5.6529045876259,
              46.323214774673
            ],
            [
              5.6684418272398,
              46.324369189279
            ],
            [
              5.6833466019044,
              46.316276892818
            ],
            [
              5.6845768021754,
              46.310927537001
            ],
            [
              5.714721806193,
              46.308772354673
            ],
            [
              5.7192939761881,
              46.293462363342
            ],
            [
              5.7160491956243,
              46.279915646538
            ],
            [
              5.720112401844,
              46.265870062788
            ],
            [
              5.7251818400017,
              46.260731935709
            ],
            [
              5.7459179055539,
              46.266368533117
            ],
            [
              5.7656471814788,
              46.268294754652
            ],
            [
              5.8212837267024,
              46.262094109034
            ],
            [
              5.8498475590074,
              46.262067170418
            ],
            [
              5.8643332109856,
              46.271208875005
            ],
            [
              5.8702488945181,
              46.265305590806
            ],
            [
              5.8791206347196,
              46.269936490689
            ],
            [
              5.8946251190123,
              46.286607913138
            ],
            [
              5.9089357776791,
              46.283950786541
            ],
            [
              5.9185184907402,
              46.307199659115
            ],
            [
              5.9259040835744,
              46.313765646046
            ],
            [
              5.9414094008927,
              46.309446510031
            ],
            [
              5.9535602724875,
              46.326531457174
            ],
            [
              5.9729173069373,
              46.346856233903
            ],
            [
              5.9868463201736,
              46.364869353266
            ],
            [
              6.0106565375389,
              46.374508650286
            ],
            [
              6.0295137846375,
              46.38681604791
            ],
            [
              6.0480752195777,
              46.405125490757
            ],
            [
              6.0551820813125,
              46.414801045991
            ],
            [
              6.064005627331,
              46.416222559892
            ],
            [
              6.0981978560935,
              46.40879028923
            ],
            [
              6.1036883616715,
              46.401582285672
            ],
            [
              6.1185950336377,
              46.396898818592
            ],
            [
              6.1361111289261,
              46.387666375676
            ],
            [
              6.1475847015617,
              46.379107190355
            ],
            [
              6.1613607860611,
              46.375593565193
            ],
            [
              6.1697363568789,
              46.367935207296
            ],
            [
              6.1593091645661,
              46.357523011537
            ],
            [
              6.1487581545338,
              46.342474971902
            ],
            [
              6.1390806928978,
              46.339163368564
            ],
            [
              6.1378901936654,
              46.333067215087
            ],
            [
              6.1195873808544,
              46.308278314384
            ],
            [
              6.1193736282587,
              46.294908886937
            ],
            [
              6.1026435450234,
              46.285062924822
            ],
            [
              6.1039528249604,
              46.279569729649
            ],
            [
              6.115111632378,
              46.266272590393
            ],
            [
              6.1201369325078,
              46.264754303732
            ],
            [
              6.1242461802512,
              46.251015651206
            ],
            [
              6.1092500751152,
              46.23976163763
            ],
            [
              6.1017586374755,
              46.237488846141
            ],
            [
              6.0881868913623,
              46.246811272397
            ],
            [
              6.0696791670011,
              46.241028403834
            ],
            [
              6.0634398141038,
              46.245647436254
            ],
            [
              6.0450945249842,
              46.231703564592
            ],
            [
              6.0331263207186,
              46.23799437917
            ],
            [
              6.017063845784,
              46.23176216947
            ],
            [
              6.0072895259931,
              46.22536977425
            ],
            [
              5.9717812659904,
              46.211519488185
            ],
            [
              5.9636784095618,
              46.196964390659
            ],
            [
              5.9921631932798,
              46.186617405804
            ],
            [
              5.9952918547587,
              46.18299442988
            ],
            [
              5.9832528438156,
              46.171486546085
            ],
            [
              5.9560670913359,
              46.132090234706
            ],
            [
              5.9462067394343,
              46.127948221814
            ],
            [
              5.9222720779069,
              46.131479275113
            ],
            [
              5.9063997635853,
              46.122367542906
            ],
            [
              5.894036131075,
              46.119471054626
            ],
            [
              5.8853202303568,
              46.107902197456
            ],
            [
              5.8929021260405,
              46.099062263942
            ],
            [
              5.8914369022611,
              46.088481128574
            ],
            [
              5.8831800937075,
              46.084913020889
            ],
            [
              5.8556665715056,
              46.086765119478
            ],
            [
              5.8323048867913,
              46.105997561521
            ],
            [
              5.8239656351235,
              46.103644576638
            ],
            [
              5.8222056291049,
              46.090775206158
            ],
            [
              5.8114122153566,
              46.078423852566
            ],
            [
              5.8076225368064,
              46.063936778242
            ],
            [
              5.81359839149,
              46.05821377467
            ],
            [
              5.8074048290847,
              46.045210926206
            ],
            [
              5.810562662367,
              46.02975964769
            ],
            [
              5.8081197991624,
              46.017745086291
            ],
            [
              5.8108134884026,
              46.010299483158
            ],
            [
              5.8096421985755,
              45.989802335637
            ],
            [
              5.8340245954563,
              45.972027470458
            ],
            [
              5.8348857517832,
              45.952142646336
            ],
            [
              5.8312301905898,
              45.93845768164
            ],
            [
              5.8228776189031,
              45.931847125431
            ],
            [
              5.8280692455776,
              45.920463972756
            ],
            [
              5.8285400922457,
              45.909292435972
            ],
            [
              5.8110472403323,
              45.86737240276
            ],
            [
              5.8036631085125,
              45.8555780918
            ],
            [
              5.7972199717522,
              45.831056078142
            ],
            [
              5.7868561787325,
              45.822955096586
            ],
            [
              5.7810286020002,
              45.764959536359
            ],
            [
              5.78384046603,
              45.751243783283
            ],
            [
              5.7819015428617,
              45.743102925582
            ],
            [
              5.769838038757,
              45.741303252362
            ],
            [
              5.7761557592359,
              45.729222522213
            ],
            [
              5.7647990972785,
              45.720294878543
            ],
            [
              5.7566806620664,
              45.708732566891
            ],
            [
              5.7487624468829,
              45.705295781585
            ],
            [
              5.731324731181,
              45.710147544457
            ],
            [
              5.7041230572664,
              45.71092263715
            ],
            [
              5.7010131804821,
              45.702100635418
            ],
            [
              5.7095817238803,
              45.688831038827
            ],
            [
              5.7083299077507,
              45.683061321171
            ],
            [
              5.6870609549933,
              45.668165449596
            ],
            [
              5.6836656752065,
              45.661963741678
            ],
            [
              5.6891819695463,
              45.648501735062
            ],
            [
              5.6781236866235,
              45.638357085735
            ],
            [
              5.6595309557102,
              45.638684549186
            ],
            [
              5.6494123534067,
              45.633717345947
            ],
            [
              5.6477926027919,
              45.625976358255
            ],
            [
              5.63695250778,
              45.621748588886
            ],
            [
              5.6316405396857,
              45.611235124481
            ],
            [
              5.62374290574,
              45.613268446443
            ],
            [
              5.6069435560033,
              45.635482392274
            ],
            [
              5.6030721965565,
              45.647684669153
            ],
            [
              5.5867550088025,
              45.665298168862
            ],
            [
              5.563862776993,
              45.674167414335
            ],
            [
              5.5541553698377,
              45.671790582993
            ],
            [
              5.5527423750432,
              45.679883465959
            ],
            [
              5.5451745235973,
              45.687400229628
            ],
            [
              5.5459463129561,
              45.697327503887
            ],
            [
              5.5559860481255,
              45.697625399596
            ],
            [
              5.5612096882931,
              45.686985356436
            ],
            [
              5.5703089690672,
              45.684426548161
            ],
            [
              5.5757522584809,
              45.691788289582
            ],
            [
              5.5711688852413,
              45.696872630898
            ],
            [
              5.5551113922636,
              45.700261621079
            ],
            [
              5.5534381913285,
              45.708998294688
            ],
            [
              5.5455440844452,
              45.713591599398
            ],
            [
              5.5272754539149,
              45.715508313157
            ],
            [
              5.5185478356945,
              45.730162983976
            ],
            [
              5.4825307295084,
              45.754588985706
            ],
            [
              5.4574039982348,
              45.780996812177
            ],
            [
              5.4226465285427,
              45.80712278512
            ],
            [
              5.4204751251472,
              45.818886657011
            ],
            [
              5.4349408141813,
              45.831093859803
            ],
            [
              5.4190015048359,
              45.839680703571
            ],
            [
              5.4131348727477,
              45.852221679893
            ],
            [
              5.3809175288182,
              45.867234143477
            ],
            [
              5.3709067264231,
              45.874977947139
            ],
            [
              5.3538322357139,
              45.883269928025
            ],
            [
              5.3409187894804,
              45.880509325682
            ],
            [
              5.3299682585029,
              45.864099868222
            ],
            [
              5.3091756488254,
              45.854928769742
            ],
            [
              5.3023555890522,
              45.847951056741
            ],
            [
              5.2998813382234,
              45.837302685138
            ],
            [
              5.2906249297479,
              45.82025986169
            ],
            [
              5.2889098684539,
              45.811121994364
            ],
            [
              5.2755531587012,
              45.800893358874
            ],
            [
              5.2668936711139,
              45.789370837093
            ],
            [
              5.2210769334942,
              45.768454465895
            ],
            [
              5.207617133438,
              45.771815785907
            ],
            [
              5.1915300805363,
              45.771685350507
            ],
            [
              5.1868098252677,
              45.782095115203
            ],
            [
              5.1768968364449,
              45.793483254007
            ],
            [
              5.1603637371378,
              45.802317387422
            ],
            [
              5.144195778805,
              45.804567863303
            ],
            [
              5.1255392074782,
              45.811063396795
            ],
            [
              5.1053919214171,
              45.808445750031
            ],
            [
              5.101068799884,
              45.813378958875
            ],
            [
              5.0661170051758,
              45.809506527915
            ],
            [
              5.051058929079,
              45.812167330171
            ],
            [
              5.0367172235658,
              45.807850969043
            ],
            [
              5.0163667700796,
              45.805572507594
            ],
            [
              4.9993378554409,
              45.810073041206
            ],
            [
              4.9816090942072,
              45.805451195255
            ],
            [
              4.9630797552526,
              45.81097149163
            ],
            [
              4.943768407874,
              45.80953114067
            ],
            [
              4.9237976894917,
              45.803994890806
            ],
            [
              4.918157197021,
              45.809867431045
            ],
            [
              4.9214939050883,
              45.83268452568
            ],
            [
              4.916118270017,
              45.845459412501
            ],
            [
              4.9012146769683,
              45.860333149898
            ],
            [
              4.909010250355,
              45.874107574527
            ],
            [
              4.8838320029013,
              45.879991282684
            ],
            [
              4.8806970810747,
              45.897164902283
            ],
            [
              4.8738314226335,
              45.893675005333
            ],
            [
              4.8645060523469,
              45.904617380238
            ],
            [
              4.8480301401278,
              45.912976466425
            ],
            [
              4.8348142747029,
              45.908769920371
            ],
            [
              4.810260610989,
              45.897565111704
            ],
            [
              4.8107708680666,
              45.908896224362
            ],
            [
              4.8058607348054,
              45.921560609717
            ],
            [
              4.7885569134338,
              45.923963932223
            ],
            [
              4.781303944214,
              45.934878026573
            ],
            [
              4.7717568016091,
              45.939225276574
            ],
            [
              4.754211604612,
              45.934832922052
            ],
            [
              4.7361797864484,
              45.938458980345
            ],
            [
              4.729097034294,
              45.949095813426
            ],
            [
              4.7484568614429,
              45.962409970129
            ],
            [
              4.7546311649847,
              45.973618413908
            ],
            [
              4.7516684157566,
              45.981108689225
            ],
            [
              4.7486001387369,
              46.009662778088
            ],
            [
              4.7440006438304,
              46.018176939023
            ],
            [
              4.7456554596239,
              46.035244267196
            ],
            [
              4.7395251280198,
              46.047211663829
            ],
            [
              4.7551690115181,
              46.05749901174
            ],
            [
              4.7616829876026,
              46.06883528189
            ],
            [
              4.7553305070909,
              46.080178266249
            ],
            [
              4.7482215731583,
              46.083210841607
            ],
            [
              4.7503200135049,
              46.094147345645
            ],
            [
              4.7711001932505,
              46.110444027243
            ],
            [
              4.7721518838355,
              46.118071984418
            ],
            [
              4.7974397118441,
              46.140702262151
            ],
            [
              4.8023781362741,
              46.156887423266
            ],
            [
              4.7842423740016,
              46.168398418477
            ],
            [
              4.7802082627383,
              46.176676203678
            ]
          ]
        ]
      },
      "properties": {
        "code": "1",
        "nom": "Ain",
        "population": 657856,
        "delitspour1000": "3,399",
        "nombremosque": 20,
        "benefRsa": 15269,
        "eoliennes": 15
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.981675726654,
              44.644673011362
            ],
            [
              3.0123078930023,
              44.621912456139
            ],
            [
              3.0214179128995,
              44.608930242405
            ],
            [
              3.0331802950598,
              44.596905346963
            ],
            [
              3.0501049511157,
              44.590500887925
            ],
            [
              3.0604239335947,
              44.579596428744
            ],
            [
              3.0759029843264,
              44.572972781335
            ],
            [
              3.0835939007747,
              44.559017095166
            ],
            [
              3.0747004023127,
              44.533148056751
            ],
            [
              3.0765891765907,
              44.518061677636
            ],
            [
              3.0685440473718,
              44.503492403034
            ],
            [
              3.0774798083215,
              44.49407023418
            ],
            [
              3.1013824675044,
              44.47951694497
            ],
            [
              3.117144504438,
              44.4739200421
            ],
            [
              3.1246425493523,
              44.461312563753
            ],
            [
              3.1357398668406,
              44.454869326281
            ],
            [
              3.1360486132034,
              44.44476332926
            ],
            [
              3.1417277675188,
              44.428457291816
            ],
            [
              3.1267160037072,
              44.413956480342
            ],
            [
              3.1373105135272,
              44.408125009251
            ],
            [
              3.1405520936589,
              44.392614462133
            ],
            [
              3.128709404669,
              44.389778090383
            ],
            [
              3.1197174609736,
              44.383381712586
            ],
            [
              3.1225802362026,
              44.376236624007
            ],
            [
              3.1211237751057,
              44.361882416935
            ],
            [
              3.131975205783,
              44.357172903459
            ],
            [
              3.1275801635609,
              44.347699429055
            ],
            [
              3.1339016413735,
              44.332987029876
            ],
            [
              3.1517097885451,
              44.332414822245
            ],
            [
              3.146528556073,
              44.320565113439
            ],
            [
              3.1542553794088,
              44.308367530555
            ],
            [
              3.1252325845717,
              44.284463569435
            ],
            [
              3.1241560155353,
              44.260325302915
            ],
            [
              3.1383625050444,
              44.264142835763
            ],
            [
              3.143570712599,
              44.274014987734
            ],
            [
              3.1540845204064,
              44.273172636192
            ],
            [
              3.1599553535076,
              44.258596699063
            ],
            [
              3.1572068425878,
              44.250245796267
            ],
            [
              3.1638192967351,
              44.243869641892
            ],
            [
              3.184224712712,
              44.247476467225
            ],
            [
              3.1956785894385,
              44.240868744621
            ],
            [
              3.2235353426493,
              44.230170001588
            ],
            [
              3.2298513180005,
              44.226251406383
            ],
            [
              3.2227886841663,
              44.209610502596
            ],
            [
              3.2066091665582,
              44.189931038205
            ],
            [
              3.2403288585343,
              44.19083537067
            ],
            [
              3.2620383777632,
              44.199513868063
            ],
            [
              3.2847604708491,
              44.199421266759
            ],
            [
              3.2950065737753,
              44.206938814455
            ],
            [
              3.3123376202084,
              44.203071755455
            ],
            [
              3.3391826316079,
              44.202751760551
            ],
            [
              3.3577957669957,
              44.197913146727
            ],
            [
              3.3623083641294,
              44.188257426713
            ],
            [
              3.3722574402673,
              44.180111605641
            ],
            [
              3.3736480685005,
              44.170759472387
            ],
            [
              3.3360552391264,
              44.158673278061
            ],
            [
              3.330219307158,
              44.142163857886
            ],
            [
              3.3202959752665,
              44.135904522063
            ],
            [
              3.3243954092282,
              44.115339574608
            ],
            [
              3.3210491311873,
              44.109480171818
            ],
            [
              3.3079202652498,
              44.105128295087
            ],
            [
              3.2930621216086,
              44.105349947563
            ],
            [
              3.2628340569911,
              44.094041936297
            ],
            [
              3.2905869474847,
              44.071706686695
            ],
            [
              3.2989038258849,
              44.068666494351
            ],
            [
              3.3084740785456,
              44.070424721299
            ],
            [
              3.3183631084637,
              44.079075467836
            ],
            [
              3.3289141381381,
              44.079025392535
            ],
            [
              3.336999456788,
              44.062128764148
            ],
            [
              3.3518362676391,
              44.050202625217
            ],
            [
              3.3681320672501,
              44.057846083439
            ],
            [
              3.3787941195253,
              44.053878388731
            ],
            [
              3.3901444043775,
              44.053499221204
            ],
            [
              3.4008249480855,
              44.04327053535
            ],
            [
              3.4211719681434,
              44.038539216118
            ],
            [
              3.4507554815828,
              44.020787284367
            ],
            [
              3.4458276845789,
              44.004545160927
            ],
            [
              3.4391614779864,
              43.998039885422
            ],
            [
              3.4186990670418,
              43.993551717916
            ],
            [
              3.405494605868,
              43.970269140751
            ],
            [
              3.3986628813639,
              43.96772840409
            ],
            [
              3.377150274913,
              43.966974047152
            ],
            [
              3.375642576892,
              43.956010269674
            ],
            [
              3.3559729058863,
              43.946168277298
            ],
            [
              3.3515992526365,
              43.938039511601
            ],
            [
              3.352251546542,
              43.929514741403
            ],
            [
              3.3583619084885,
              43.913829285364
            ],
            [
              3.3402696244377,
              43.901731512332
            ],
            [
              3.3425730593674,
              43.894194468074
            ],
            [
              3.3200536423899,
              43.891011308338
            ],
            [
              3.2954140626944,
              43.895074688924
            ],
            [
              3.2868926131443,
              43.893275030194
            ],
            [
              3.2750747460719,
              43.89835141161
            ],
            [
              3.2631323492161,
              43.89279670985
            ],
            [
              3.2603747807982,
              43.883002113882
            ],
            [
              3.2542327682907,
              43.876752165048
            ],
            [
              3.2430727112119,
              43.875182911547
            ],
            [
              3.2362244765786,
              43.866546056431
            ],
            [
              3.2344573700191,
              43.857562353474
            ],
            [
              3.2401209483517,
              43.849238408157
            ],
            [
              3.2510907532261,
              43.842076507358
            ],
            [
              3.249039481107,
              43.829579698488
            ],
            [
              3.232134595149,
              43.826137964263
            ],
            [
              3.2226912752071,
              43.817777799078
            ],
            [
              3.2031142100103,
              43.813872681737
            ],
            [
              3.1776878112357,
              43.813838787452
            ],
            [
              3.1580394141965,
              43.817584693294
            ],
            [
              3.1507104327409,
              43.813766023316
            ],
            [
              3.1291898204361,
              43.816936067597
            ],
            [
              3.1083746364905,
              43.828954358627
            ],
            [
              3.0871396285341,
              43.835566967207
            ],
            [
              3.064904986059,
              43.835565364534
            ],
            [
              3.0598597029877,
              43.831644678221
            ],
            [
              3.0602887615428,
              43.809425149222
            ],
            [
              3.0494059238588,
              43.803825230583
            ],
            [
              3.0555494622909,
              43.792897708039
            ],
            [
              3.0570585259457,
              43.78263654452
            ],
            [
              3.0657566296832,
              43.774015383743
            ],
            [
              3.0758854246252,
              43.768517815391
            ],
            [
              3.0684631726792,
              43.756809234459
            ],
            [
              3.0565260080024,
              43.755307021132
            ],
            [
              3.053062578971,
              43.746187838209
            ],
            [
              3.056150266167,
              43.717771930329
            ],
            [
              3.069161341669,
              43.70684228008
            ],
            [
              3.062401328145,
              43.702474103432
            ],
            [
              3.0607467059948,
              43.693073684319
            ],
            [
              3.0437823067902,
              43.696691915948
            ],
            [
              3.0334550457498,
              43.692056102371
            ],
            [
              3.0194605989081,
              43.695490521581
            ],
            [
              3.0094758861697,
              43.701898593858
            ],
            [
              2.985354094531,
              43.708518052513
            ],
            [
              2.9598110947795,
              43.699399971151
            ],
            [
              2.9465514566703,
              43.69294074079
            ],
            [
              2.93545676901,
              43.694665082071
            ],
            [
              2.9249821950236,
              43.707226227732
            ],
            [
              2.9211095274122,
              43.736113679897
            ],
            [
              2.9027878228205,
              43.742086455949
            ],
            [
              2.8696809314026,
              43.74040244916
            ],
            [
              2.8646413093077,
              43.74643610445
            ],
            [
              2.8329778062036,
              43.752404087944
            ],
            [
              2.8240413372408,
              43.761983429423
            ],
            [
              2.8136698449289,
              43.761715123534
            ],
            [
              2.7876469967185,
              43.75205519428
            ],
            [
              2.7815654433359,
              43.737086693682
            ],
            [
              2.7428405750055,
              43.729040274717
            ],
            [
              2.7376357664304,
              43.729149337635
            ],
            [
              2.7238360934081,
              43.738341374154
            ],
            [
              2.7120897763673,
              43.742651851512
            ],
            [
              2.6812996669758,
              43.743967184551
            ],
            [
              2.6606377392109,
              43.755995313286
            ],
            [
              2.6570632019432,
              43.762262495669
            ],
            [
              2.6292120247184,
              43.780337067422
            ],
            [
              2.6221233220367,
              43.791699862567
            ],
            [
              2.610195199842,
              43.80025721986
            ],
            [
              2.597142701397,
              43.819036570269
            ],
            [
              2.5882545103351,
              43.819281444913
            ],
            [
              2.5792191773895,
              43.831402035923
            ],
            [
              2.5641575606058,
              43.841331968769
            ],
            [
              2.5653065547865,
              43.867795299613
            ],
            [
              2.5797174724613,
              43.875557889665
            ],
            [
              2.5766859747192,
              43.881722459052
            ],
            [
              2.5574197456536,
              43.88775037126
            ],
            [
              2.55049168647,
              43.896537961073
            ],
            [
              2.5550000011505,
              43.921377244109
            ],
            [
              2.537792383558,
              43.933736154441
            ],
            [
              2.5152005962438,
              43.942348213612
            ],
            [
              2.4993496835219,
              43.950584469272
            ],
            [
              2.5163732203653,
              43.958298386865
            ],
            [
              2.5235804922512,
              43.970261186219
            ],
            [
              2.5229312446889,
              43.981284190271
            ],
            [
              2.5104055063181,
              43.985134825107
            ],
            [
              2.4942365622806,
              43.996181698121
            ],
            [
              2.4962620461974,
              44.007659874229
            ],
            [
              2.4903639922153,
              44.019634278994
            ],
            [
              2.4794403474462,
              44.028267176122
            ],
            [
              2.4634624629786,
              44.034970818942
            ],
            [
              2.4599332365299,
              44.051400681122
            ],
            [
              2.4216910951538,
              44.053752475751
            ],
            [
              2.410211491953,
              44.055851343973
            ],
            [
              2.4094780251355,
              44.071941155999
            ],
            [
              2.3892137818918,
              44.093489950159
            ],
            [
              2.3577422834088,
              44.101556418275
            ],
            [
              2.323636723165,
              44.124682191488
            ],
            [
              2.3083985987371,
              44.117666350693
            ],
            [
              2.2906456306459,
              44.124682487431
            ],
            [
              2.2978256265117,
              44.138045574105
            ],
            [
              2.2846972006779,
              44.145287510311
            ],
            [
              2.2698114264498,
              44.146492745788
            ],
            [
              2.2396510494767,
              44.134457723455
            ],
            [
              2.2241993310813,
              44.13889318997
            ],
            [
              2.1865229813992,
              44.137373484432
            ],
            [
              2.1813288285555,
              44.142041491796
            ],
            [
              2.1898191628429,
              44.146827430556
            ],
            [
              2.2117482291708,
              44.145811711989
            ],
            [
              2.2179314844805,
              44.150953299721
            ],
            [
              2.2376110882977,
              44.158624890908
            ],
            [
              2.2222888874803,
              44.166353715955
            ],
            [
              2.2069453324549,
              44.171279129819
            ],
            [
              2.1917781059768,
              44.16203491789
            ],
            [
              2.1752862522128,
              44.18101009946
            ],
            [
              2.1565534575573,
              44.179856851186
            ],
            [
              2.1524658664853,
              44.197716441976
            ],
            [
              2.1359451447137,
              44.200834436147
            ],
            [
              2.1311952915472,
              44.197318789904
            ],
            [
              2.1061236174793,
              44.194390921069
            ],
            [
              2.1069897355697,
              44.18115780471
            ],
            [
              2.097443906345,
              44.175292714971
            ],
            [
              2.09438670937,
              44.187642636102
            ],
            [
              2.0809187671674,
              44.17997486902
            ],
            [
              2.0709566347835,
              44.183377219398
            ],
            [
              2.0688657537344,
              44.18967491336
            ],
            [
              2.0521125599217,
              44.188733050645
            ],
            [
              2.0593781574153,
              44.17227973706
            ],
            [
              2.0346692572966,
              44.168505036007
            ],
            [
              2.0247690975029,
              44.155129610566
            ],
            [
              1.990172010571,
              44.149449875043
            ],
            [
              1.9963637896774,
              44.160088765559
            ],
            [
              1.9770578565103,
              44.162575740805
            ],
            [
              1.9730953768159,
              44.171630223821
            ],
            [
              1.9766780022111,
              44.179923505077
            ],
            [
              1.9589422232293,
              44.184503189761
            ],
            [
              1.9444569103612,
              44.181304253281
            ],
            [
              1.9415206386057,
              44.170719809307
            ],
            [
              1.9334983119119,
              44.174421873614
            ],
            [
              1.9351355599822,
              44.181360909825
            ],
            [
              1.9279012490701,
              44.190202994504
            ],
            [
              1.9110879137292,
              44.185639221414
            ],
            [
              1.9054139151361,
              44.192369149573
            ],
            [
              1.9021964370883,
              44.20432463942
            ],
            [
              1.8903461983645,
              44.207567652297
            ],
            [
              1.8906741441948,
              44.213459059749
            ],
            [
              1.9049085707423,
              44.210211894056
            ],
            [
              1.9192741712379,
              44.224712150131
            ],
            [
              1.9315885634669,
              44.240873980562
            ],
            [
              1.946028366781,
              44.246698189197
            ],
            [
              1.9598380540971,
              44.241073132563
            ],
            [
              1.9643948582833,
              44.261383958306
            ],
            [
              1.9704399951605,
              44.276438944388
            ],
            [
              1.9402721006208,
              44.282547245855
            ],
            [
              1.9365279254051,
              44.280870004094
            ],
            [
              1.9135901461959,
              44.281862747866
            ],
            [
              1.901394263774,
              44.279116501007
            ],
            [
              1.8687816562037,
              44.292038697128
            ],
            [
              1.880907610265,
              44.303474113866
            ],
            [
              1.8768599447034,
              44.308592044125
            ],
            [
              1.8595915272095,
              44.32030639381
            ],
            [
              1.8646156409988,
              44.327531463723
            ],
            [
              1.8820777699351,
              44.340068798134
            ],
            [
              1.8907241899993,
              44.351737032539
            ],
            [
              1.9052533999665,
              44.350549277067
            ],
            [
              1.9112348353677,
              44.356651948333
            ],
            [
              1.9068476903719,
              44.364871814968
            ],
            [
              1.8933692462979,
              44.371571831531
            ],
            [
              1.8907941991097,
              44.380202764676
            ],
            [
              1.8744004614259,
              44.391430087278
            ],
            [
              1.8710314852874,
              44.397745172704
            ],
            [
              1.8731907138082,
              44.424140758608
            ],
            [
              1.8505242685611,
              44.43550406388
            ],
            [
              1.846648081567,
              44.444555574418
            ],
            [
              1.8526845623673,
              44.459216811677
            ],
            [
              1.8470020898658,
              44.470160068683
            ],
            [
              1.8396044963184,
              44.475903822342
            ],
            [
              1.843001484365,
              44.482382092855
            ],
            [
              1.8615767786303,
              44.487320895101
            ],
            [
              1.8799586666095,
              44.483837662409
            ],
            [
              1.885217137804,
              44.486107475987
            ],
            [
              1.8841752229262,
              44.498432886597
            ],
            [
              1.8902857028608,
              44.505886029507
            ],
            [
              1.9061154790499,
              44.499870727249
            ],
            [
              1.9098594387895,
              44.48674631631
            ],
            [
              1.9191710997594,
              44.49312202364
            ],
            [
              1.9115825451404,
              44.499801458356
            ],
            [
              1.9129865180384,
              44.505157692579
            ],
            [
              1.9314639777047,
              44.506396941037
            ],
            [
              1.9366297490837,
              44.515148261474
            ],
            [
              1.9562278200819,
              44.518455813836
            ],
            [
              1.970563904818,
              44.528891720337
            ],
            [
              1.9751753123345,
              44.537145405011
            ],
            [
              1.9885549262677,
              44.551182134401
            ],
            [
              2.0050084589377,
              44.557033268559
            ],
            [
              2.0203127144902,
              44.555504531802
            ],
            [
              2.0340696763027,
              44.560760159834
            ],
            [
              2.0335854594418,
              44.56881637632
            ],
            [
              2.0573689651355,
              44.582909160412
            ],
            [
              2.0728206231132,
              44.577955873938
            ],
            [
              2.0830462458941,
              44.587423324047
            ],
            [
              2.1015590079994,
              44.572436798295
            ],
            [
              2.1104955943486,
              44.571376544464
            ],
            [
              2.124513418981,
              44.576347447406
            ],
            [
              2.1346205537833,
              44.569849513532
            ],
            [
              2.1538128201085,
              44.57189782658
            ],
            [
              2.1676095238949,
              44.590329078654
            ],
            [
              2.1844862284485,
              44.59021904525
            ],
            [
              2.1936051879406,
              44.58591143442
            ],
            [
              2.2017928242867,
              44.596049696004
            ],
            [
              2.1954101381218,
              44.600778595174
            ],
            [
              2.2108934010391,
              44.606090357773
            ],
            [
              2.2074728028173,
              44.61552895784
            ],
            [
              2.2198705673093,
              44.623646052351
            ],
            [
              2.214541909482,
              44.627080806224
            ],
            [
              2.2084152778134,
              44.643842310283
            ],
            [
              2.2286252203003,
              44.655106676519
            ],
            [
              2.2508940527358,
              44.651888047018
            ],
            [
              2.264931023707,
              44.660757552111
            ],
            [
              2.270930098105,
              44.660401110765
            ],
            [
              2.2910368223518,
              44.666581877606
            ],
            [
              2.3045868091932,
              44.662449015977
            ],
            [
              2.3267908312388,
              44.669693386939
            ],
            [
              2.3365579816568,
              44.661275985276
            ],
            [
              2.3327786804308,
              44.650617960529
            ],
            [
              2.3485386057121,
              44.641480228035
            ],
            [
              2.3659976814652,
              44.641342211431
            ],
            [
              2.3782230201151,
              44.650161602045
            ],
            [
              2.397445965387,
              44.646186044198
            ],
            [
              2.4055217700154,
              44.647179554811
            ],
            [
              2.435000853128,
              44.638874930003
            ],
            [
              2.4521635947204,
              44.648220759561
            ],
            [
              2.4680389011889,
              44.642888409527
            ],
            [
              2.4689992507151,
              44.649641593651
            ],
            [
              2.4831874702259,
              44.650342597738
            ],
            [
              2.4901340080947,
              44.656773198157
            ],
            [
              2.4875777637021,
              44.666984218285
            ],
            [
              2.4989152190022,
              44.687020949858
            ],
            [
              2.5188863490069,
              44.699027751647
            ],
            [
              2.5184594908124,
              44.703480601801
            ],
            [
              2.556122557476,
              44.721283990134
            ],
            [
              2.5519407104745,
              44.732804630794
            ],
            [
              2.5544909688881,
              44.73964379349
            ],
            [
              2.5516388576146,
              44.750719684187
            ],
            [
              2.5585893921439,
              44.757924931793
            ],
            [
              2.5627129322914,
              44.775756861827
            ],
            [
              2.5735477024072,
              44.784962589937
            ],
            [
              2.5865648469617,
              44.783990021983
            ],
            [
              2.5993613568997,
              44.792806185507
            ],
            [
              2.6013884128515,
              44.807574409416
            ],
            [
              2.5971356193221,
              44.819510989142
            ],
            [
              2.6074714184,
              44.824096540945
            ],
            [
              2.6117393110443,
              44.83131246301
            ],
            [
              2.6026823497168,
              44.843163111324
            ],
            [
              2.6180527448461,
              44.854603721987
            ],
            [
              2.6239295606463,
              44.866918027702
            ],
            [
              2.6366080646644,
              44.872551413571
            ],
            [
              2.6490646425252,
              44.869728369056
            ],
            [
              2.6562270538803,
              44.87241580597
            ],
            [
              2.6581063096255,
              44.882979115397
            ],
            [
              2.6812975408382,
              44.907392758521
            ],
            [
              2.6938913636929,
              44.902938090171
            ],
            [
              2.7064336715881,
              44.907221617118
            ],
            [
              2.7135929831216,
              44.92713012749
            ],
            [
              2.7240210839286,
              44.924576074703
            ],
            [
              2.731006631595,
              44.936748083927
            ],
            [
              2.738258109046,
              44.941219492647
            ],
            [
              2.7603927919273,
              44.927510072233
            ],
            [
              2.7735403561236,
              44.915091091551
            ],
            [
              2.776087665079,
              44.908963934931
            ],
            [
              2.7718590507401,
              44.902271109449
            ],
            [
              2.7783381480633,
              44.887718357993
            ],
            [
              2.77049124514,
              44.875537836385
            ],
            [
              2.7684108734637,
              44.863858763976
            ],
            [
              2.772707056045,
              44.855545795389
            ],
            [
              2.801957650579,
              44.873625756087
            ],
            [
              2.8220333243708,
              44.87157004877
            ],
            [
              2.8496519747355,
              44.871490231757
            ],
            [
              2.8596736604077,
              44.874465473337
            ],
            [
              2.8559650223393,
              44.855179951014
            ],
            [
              2.8705082792207,
              44.827398299766
            ],
            [
              2.8795747424774,
              44.803006111318
            ],
            [
              2.8897062556822,
              44.788417083385
            ],
            [
              2.9040726320859,
              44.783702886621
            ],
            [
              2.920189595566,
              44.794308778558
            ],
            [
              2.9345177106412,
              44.779201871895
            ],
            [
              2.9348220891073,
              44.766338409064
            ],
            [
              2.9208013627531,
              44.765729671884
            ],
            [
              2.9172495539886,
              44.756714098966
            ],
            [
              2.9284555057549,
              44.755160769806
            ],
            [
              2.9335256868255,
              44.750101514437
            ],
            [
              2.9322008334785,
              44.738182515263
            ],
            [
              2.9232670003911,
              44.728642526112
            ],
            [
              2.9350900117718,
              44.705004788991
            ],
            [
              2.9338731506521,
              44.697402037726
            ],
            [
              2.9408965571355,
              44.683818918865
            ],
            [
              2.9392865668235,
              44.677756455026
            ],
            [
              2.9487265645702,
              44.672978048006
            ],
            [
              2.9637576145748,
              44.654316787673
            ],
            [
              2.9729967000843,
              44.645719145774
            ],
            [
              2.981675726654,
              44.644673011362
            ]
          ]
        ]
      },
      "properties": {
        "code": "12",
        "nom": "Aveyron",
        "population": 279554,
        "delitspour1000": "3,227",
        "nombremosque": 11,
        "parentsEtrangers": "83,3",
        "benefRsa": 8436,
        "eoliennes": 162
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.7390596940896,
              43.92406219253
            ],
            [
              4.8217298557922,
              43.912828839936
            ],
            [
              4.8530923908916,
              43.91138477146
            ],
            [
              4.8852309492488,
              43.900149336786
            ],
            [
              4.9023179459346,
              43.887928969008
            ],
            [
              4.9258565482814,
              43.886489100284
            ],
            [
              4.9458230474417,
              43.87806829886
            ],
            [
              4.9663043874778,
              43.871649158077
            ],
            [
              4.9764746261986,
              43.865607815252
            ],
            [
              4.9960851928823,
              43.846061248978
            ],
            [
              5.0295347837616,
              43.827795588837
            ],
            [
              5.0411586397996,
              43.81094844339
            ],
            [
              5.0436368200957,
              43.795946325458
            ],
            [
              5.051485003572,
              43.787879982851
            ],
            [
              5.173316741506,
              43.73880497047
            ],
            [
              5.1879758806349,
              43.735099650014
            ],
            [
              5.2299865865365,
              43.747672957377
            ],
            [
              5.2557136505654,
              43.746560624881
            ],
            [
              5.2752691891284,
              43.741282660027
            ],
            [
              5.3079611019532,
              43.737953312927
            ],
            [
              5.3185027538528,
              43.735019633067
            ],
            [
              5.3410175350117,
              43.719699231195
            ],
            [
              5.4067240637555,
              43.695692422657
            ],
            [
              5.4392420964385,
              43.681108002682
            ],
            [
              5.4504516868099,
              43.679592990188
            ],
            [
              5.4880843830452,
              43.667642960946
            ],
            [
              5.501441938421,
              43.666384037435
            ],
            [
              5.531450358273,
              43.659006908614
            ],
            [
              5.5715633026355,
              43.661926831918
            ],
            [
              5.6069435191164,
              43.658685905188
            ],
            [
              5.6477480083622,
              43.675796063103
            ],
            [
              5.6617688277429,
              43.68741371217
            ],
            [
              5.6740837847517,
              43.693549049408
            ],
            [
              5.7052262694701,
              43.68992224271
            ],
            [
              5.717354712484,
              43.693529264235
            ],
            [
              5.7369472103627,
              43.716001963372
            ],
            [
              5.753644561032,
              43.724619684068
            ],
            [
              5.7677160316456,
              43.722508794856
            ],
            [
              5.7854338746866,
              43.713506618326
            ],
            [
              5.7821926879745,
              43.70688883877
            ],
            [
              5.7928455221277,
              43.692558168421
            ],
            [
              5.8132476569219,
              43.688962999872
            ],
            [
              5.8011497267432,
              43.671379290181
            ],
            [
              5.7990586792783,
              43.660518853996
            ],
            [
              5.7686441174641,
              43.660251427186
            ],
            [
              5.7448021509795,
              43.65678191082
            ],
            [
              5.7232211277818,
              43.649628603542
            ],
            [
              5.7096619573307,
              43.640930014563
            ],
            [
              5.7002189786378,
              43.641716920694
            ],
            [
              5.6821412890103,
              43.61373574878
            ],
            [
              5.6829174125015,
              43.601216985023
            ],
            [
              5.6916044198774,
              43.584431461466
            ],
            [
              5.6559638228901,
              43.577397571807
            ],
            [
              5.6560255946819,
              43.57399668679
            ],
            [
              5.6692560322541,
              43.564729029478
            ],
            [
              5.6958133469034,
              43.562143451646
            ],
            [
              5.7093234425576,
              43.554951530142
            ],
            [
              5.7246167566539,
              43.55090006967
            ],
            [
              5.7178587079294,
              43.538171472027
            ],
            [
              5.7173910449164,
              43.522170386566
            ],
            [
              5.7128740368715,
              43.512135014249
            ],
            [
              5.7161474770546,
              43.504297527881
            ],
            [
              5.7017834464565,
              43.491036844111
            ],
            [
              5.69880518873,
              43.48217276293
            ],
            [
              5.7149940417722,
              43.477219688103
            ],
            [
              5.7269896007069,
              43.467414035784
            ],
            [
              5.7518507411673,
              43.434296829412
            ],
            [
              5.7747402610239,
              43.421127031271
            ],
            [
              5.7883489025455,
              43.420260743373
            ],
            [
              5.7865119979741,
              43.414539503422
            ],
            [
              5.7763908538649,
              43.408958113867
            ],
            [
              5.749991875502,
              43.401514019764
            ],
            [
              5.7244316232622,
              43.408874721815
            ],
            [
              5.6964739843579,
              43.407109089482
            ],
            [
              5.6827924547281,
              43.399159444446
            ],
            [
              5.687876696804,
              43.391770884707
            ],
            [
              5.6945703861296,
              43.364398500004
            ],
            [
              5.705027698264,
              43.355914795818
            ],
            [
              5.6945534562629,
              43.345183887029
            ],
            [
              5.6890671733902,
              43.335214833248
            ],
            [
              5.6898026231243,
              43.327460094948
            ],
            [
              5.6839238953331,
              43.318142512414
            ],
            [
              5.6910426284831,
              43.312958284278
            ],
            [
              5.726597317218,
              43.317359044169
            ],
            [
              5.7375274826841,
              43.301485634246
            ],
            [
              5.7498397693557,
              43.289270917912
            ],
            [
              5.7628530190708,
              43.281689488403
            ],
            [
              5.7609266571978,
              43.267341658562
            ],
            [
              5.7208212008281,
              43.256400976392
            ],
            [
              5.7012219225146,
              43.247154351727
            ],
            [
              5.7045113698099,
              43.241654579484
            ],
            [
              5.6839089702289,
              43.236054698509
            ],
            [
              5.6779885853928,
              43.219782421707
            ],
            [
              5.6785440362111,
              43.205151381308
            ],
            [
              5.6755238732235,
              43.187448430379
            ],
            [
              5.6718787324219,
              43.179269594604
            ],
            [
              5.6525593241702,
              43.187314477783
            ],
            [
              5.6383239998299,
              43.189173766465
            ],
            [
              5.6172174635767,
              43.18371473989
            ],
            [
              5.6098389569561,
              43.17368424192
            ],
            [
              5.6146454833389,
              43.169198477141
            ],
            [
              5.6008948125683,
              43.162545513212
            ],
            [
              5.5714983888382,
              43.173555880459
            ],
            [
              5.5479598370767,
              43.196023901121
            ],
            [
              5.5499085142965,
              43.207367827769
            ],
            [
              5.5369302937792,
              43.214490076169
            ],
            [
              5.515763799187,
              43.203024207623
            ],
            [
              5.5100611678748,
              43.197689816439
            ],
            [
              5.5002885068597,
              43.196790895695
            ],
            [
              5.4865704178615,
              43.204153568372
            ],
            [
              5.468036914708,
              43.20872417933
            ],
            [
              5.4461877784161,
              43.210231385585
            ],
            [
              5.4256485097191,
              43.203784469675
            ],
            [
              5.4033807899197,
              43.21282384953
            ],
            [
              5.3910567260333,
              43.212122982456
            ],
            [
              5.3636491661807,
              43.207122106689
            ],
            [
              5.3456097729721,
              43.212497555779
            ],
            [
              5.3425610087084,
              43.216257869717
            ],
            [
              5.3483543737945,
              43.2298241001
            ],
            [
              5.3725248187308,
              43.245077650705
            ],
            [
              5.3760469740713,
              43.255156912202
            ],
            [
              5.3673730695884,
              43.268907750223
            ],
            [
              5.3456836221411,
              43.282366222064
            ],
            [
              5.3629864899254,
              43.301953092747
            ],
            [
              5.3644147192511,
              43.312140169969
            ],
            [
              5.3620502253915,
              43.321960251309
            ],
            [
              5.3556316786832,
              43.322291979926
            ],
            [
              5.352523739124,
              43.332822871057
            ],
            [
              5.3431794461906,
              43.339096162428
            ],
            [
              5.341020887781,
              43.344783149914
            ],
            [
              5.3173099513093,
              43.355086515457
            ],
            [
              5.3110302222328,
              43.360879997437
            ],
            [
              5.2885149096553,
              43.357326659023
            ],
            [
              5.2703975054675,
              43.344652472176
            ],
            [
              5.2576422113991,
              43.33785745169
            ],
            [
              5.2405905351428,
              43.331712598278
            ],
            [
              5.2213339498192,
              43.328358899356
            ],
            [
              5.1954386570072,
              43.33000275989
            ],
            [
              5.1774744273398,
              43.333616525803
            ],
            [
              5.164476902229,
              43.327323286526
            ],
            [
              5.1482109690618,
              43.326418360361
            ],
            [
              5.1351880880297,
              43.329222149882
            ],
            [
              5.094781174269,
              43.329270900706
            ],
            [
              5.0815876357356,
              43.327877052599
            ],
            [
              5.0627982236147,
              43.331087967135
            ],
            [
              5.0539150268677,
              43.324272099372
            ],
            [
              5.0410400764889,
              43.327284831154
            ],
            [
              5.0190482280859,
              43.342937227035
            ],
            [
              5.0242965902058,
              43.355559161915
            ],
            [
              5.017109588485,
              43.35694335664
            ],
            [
              5.0113980677591,
              43.368027586573
            ],
            [
              5.0001976645609,
              43.377116357469
            ],
            [
              4.9876375735659,
              43.391776901932
            ],
            [
              5.0010677938316,
              43.398215886274
            ],
            [
              4.9955040109031,
              43.403478734365
            ],
            [
              4.9776421775338,
              43.407406133291
            ],
            [
              4.9730488082218,
              43.420933908913
            ],
            [
              4.9677103819671,
              43.426100332678
            ],
            [
              4.9494334707518,
              43.429224186554
            ],
            [
              4.941216499844,
              43.428254043212
            ],
            [
              4.931376951317,
              43.433157232314
            ],
            [
              4.9106063278399,
              43.427280115584
            ],
            [
              4.9063533880916,
              43.419737286499
            ],
            [
              4.8865877345916,
              43.412880564295
            ],
            [
              4.8839595728386,
              43.419515397166
            ],
            [
              4.8674100871644,
              43.432331363619
            ],
            [
              4.8773029521565,
              43.410513204152
            ],
            [
              4.8668499113366,
              43.404678136755
            ],
            [
              4.8291209117113,
              43.428264665696
            ],
            [
              4.8244333765645,
              43.424394088096
            ],
            [
              4.8614681256348,
              43.40076989363
            ],
            [
              4.8500135070493,
              43.398836197988
            ],
            [
              4.8416101405785,
              43.404034087959
            ],
            [
              4.8358563366124,
              43.398039616459
            ],
            [
              4.8551101946662,
              43.387877558547
            ],
            [
              4.8494840577119,
              43.380013571741
            ],
            [
              4.8569334158861,
              43.370915251699
            ],
            [
              4.8667320208315,
              43.369607444541
            ],
            [
              4.8737024584045,
              43.360848282232
            ],
            [
              4.9022342998273,
              43.370982432633
            ],
            [
              4.89252239213,
              43.360161697152
            ],
            [
              4.8550448910375,
              43.332618516509
            ],
            [
              4.8481410380544,
              43.333670742325
            ],
            [
              4.8330340586153,
              43.32986959949
            ],
            [
              4.8231844261886,
              43.335956971178
            ],
            [
              4.8019643201692,
              43.343146781855
            ],
            [
              4.783356801758,
              43.347189434094
            ],
            [
              4.7595949251318,
              43.349936079368
            ],
            [
              4.7177423514311,
              43.350306361918
            ],
            [
              4.7057078087084,
              43.34779914182
            ],
            [
              4.6617842756445,
              43.346410527778
            ],
            [
              4.6380886588302,
              43.351001855561
            ],
            [
              4.6131027136534,
              43.353767124036
            ],
            [
              4.5858024446726,
              43.360071509782
            ],
            [
              4.5627978195916,
              43.372134942713
            ],
            [
              4.5556106083519,
              43.38282276464
            ],
            [
              4.5627131374003,
              43.392243971332
            ],
            [
              4.5735916278341,
              43.393577384459
            ],
            [
              4.5876982001696,
              43.400712121388
            ],
            [
              4.5929514695631,
              43.409939117692
            ],
            [
              4.5880988195327,
              43.422705156053
            ],
            [
              4.5731686445663,
              43.437345552555
            ],
            [
              4.5549165862997,
              43.446213275115
            ],
            [
              4.5347167281545,
              43.451636616845
            ],
            [
              4.5163610320847,
              43.454715093233
            ],
            [
              4.4652664495204,
              43.457150995927
            ],
            [
              4.4417093298303,
              43.45488352219
            ],
            [
              4.4334372492981,
              43.451162718975
            ],
            [
              4.4045865202341,
              43.448120304403
            ],
            [
              4.3820421638572,
              43.45227212971
            ],
            [
              4.3037217279638,
              43.456772476562
            ],
            [
              4.2302808850321,
              43.460183661653
            ],
            [
              4.2311705681976,
              43.47675937846
            ],
            [
              4.2381030309066,
              43.497625146899
            ],
            [
              4.2586419540305,
              43.507538672405
            ],
            [
              4.271416895472,
              43.508069960206
            ],
            [
              4.2976898160141,
              43.515329111265
            ],
            [
              4.3051324465276,
              43.520794514252
            ],
            [
              4.3204919293874,
              43.525482210334
            ],
            [
              4.3227085568643,
              43.530446651372
            ],
            [
              4.3095227640281,
              43.543279128578
            ],
            [
              4.3164310144209,
              43.544330277799
            ],
            [
              4.3341326001686,
              43.535451246643
            ],
            [
              4.3536437981366,
              43.547390132754
            ],
            [
              4.3651362956496,
              43.547157120782
            ],
            [
              4.3806768784461,
              43.55254245629
            ],
            [
              4.3870870214048,
              43.560476830234
            ],
            [
              4.4093533628433,
              43.561126852889
            ],
            [
              4.4153195295637,
              43.572538108822
            ],
            [
              4.404416776755,
              43.574366053053
            ],
            [
              4.4255399426089,
              43.585225258609
            ],
            [
              4.4433120956209,
              43.583144847291
            ],
            [
              4.4606813332323,
              43.589260601197
            ],
            [
              4.4752352404423,
              43.60829610571
            ],
            [
              4.4668068635081,
              43.615205555036
            ],
            [
              4.4545256952964,
              43.611392063023
            ],
            [
              4.4397478336794,
              43.610672750672
            ],
            [
              4.4270341666761,
              43.620674791673
            ],
            [
              4.4382159894455,
              43.644374718144
            ],
            [
              4.4475788905709,
              43.659307469452
            ],
            [
              4.4559475264435,
              43.667415945459
            ],
            [
              4.4754954502059,
              43.671096153803
            ],
            [
              4.4756623542909,
              43.684463819817
            ],
            [
              4.4872344746833,
              43.699241399194
            ],
            [
              4.503671690096,
              43.702259072675
            ],
            [
              4.5243901803315,
              43.702131030559
            ],
            [
              4.5398466434949,
              43.707275616451
            ],
            [
              4.5527787964313,
              43.701311173254
            ],
            [
              4.5818853309897,
              43.696368547688
            ],
            [
              4.5930346527266,
              43.68745966779
            ],
            [
              4.605829920983,
              43.686077262962
            ],
            [
              4.6276618120935,
              43.690542678491
            ],
            [
              4.6130400270515,
              43.714296691071
            ],
            [
              4.6136350841342,
              43.729822310127
            ],
            [
              4.6227171190074,
              43.743237369574
            ],
            [
              4.628818965927,
              43.759861933174
            ],
            [
              4.6512435847762,
              43.782275197677
            ],
            [
              4.6548263114857,
              43.806405017468
            ],
            [
              4.6522767209675,
              43.816837884851
            ],
            [
              4.6424185010062,
              43.831436886337
            ],
            [
              4.6501517057694,
              43.839124011369
            ],
            [
              4.6662999036899,
              43.844776915402
            ],
            [
              4.6586664103492,
              43.853223756034
            ],
            [
              4.6480099815874,
              43.851229485733
            ],
            [
              4.6419145398807,
              43.867476572594
            ],
            [
              4.6566482355099,
              43.874778903832
            ],
            [
              4.6654377200626,
              43.875147404443
            ],
            [
              4.6905462810021,
              43.883898624023
            ],
            [
              4.7074943864756,
              43.895550919044
            ],
            [
              4.7037984091552,
              43.899340665402
            ],
            [
              4.7233684641772,
              43.905955710295
            ],
            [
              4.740099224056,
              43.919678920657
            ],
            [
              4.7390596940896,
              43.92406219253
            ]
          ],
          [
            [
              5.0178344377819,
              43.469321193939
            ],
            [
              5.0379847941255,
              43.470644268033
            ],
            [
              5.0518847547722,
              43.463560167399
            ],
            [
              5.0549690681399,
              43.453888162229
            ],
            [
              5.054121512102,
              43.442021912977
            ],
            [
              5.0611076258685,
              43.423140307283
            ],
            [
              5.0580213222048,
              43.409552133438
            ],
            [
              5.0595684339306,
              43.403974555677
            ],
            [
              5.0703896715519,
              43.400202593118
            ],
            [
              5.0927056002731,
              43.400605101589
            ],
            [
              5.1107078769979,
              43.404127256643
            ],
            [
              5.125776538992,
              43.400015717235
            ],
            [
              5.137266271971,
              43.400185493623
            ],
            [
              5.157534521038,
              43.409584196466
            ],
            [
              5.1828633330337,
              43.426606568488
            ],
            [
              5.1912661780646,
              43.434900512668
            ],
            [
              5.2084471015655,
              43.445433671054
            ],
            [
              5.2203482060614,
              43.449162229641
            ],
            [
              5.2301377569344,
              43.465078044372
            ],
            [
              5.2225521040856,
              43.483465085916
            ],
            [
              5.2175169994984,
              43.488096323996
            ],
            [
              5.2027922080577,
              43.491173117668
            ],
            [
              5.1908429897818,
              43.486475795117
            ],
            [
              5.1824609658291,
              43.479350075479
            ],
            [
              5.1651054168282,
              43.471767081519
            ],
            [
              5.1496876211759,
              43.457026659088
            ],
            [
              5.1321152674543,
              43.473849327421
            ],
            [
              5.123114322047,
              43.48615366834
            ],
            [
              5.1135686679163,
              43.504660978563
            ],
            [
              5.1182092489503,
              43.518012416527
            ],
            [
              5.1086615060731,
              43.525496816445
            ],
            [
              5.0893141543062,
              43.525515609476
            ],
            [
              5.0643299415117,
              43.527830813843
            ],
            [
              5.046434902166,
              43.522138786534
            ],
            [
              5.0322512284114,
              43.5397685648
            ],
            [
              5.0309517808795,
              43.54682479433
            ],
            [
              5.0219939854938,
              43.55615613908
            ],
            [
              5.0132835978236,
              43.554448679869
            ],
            [
              5.0122049007424,
              43.545526935893
            ],
            [
              5.0154635062157,
              43.529718860201
            ],
            [
              5.0024501091134,
              43.512784134911
            ],
            [
              4.9985487475088,
              43.49810611091
            ],
            [
              5.0029037494134,
              43.486740851749
            ],
            [
              5.000857898128,
              43.474094351803
            ],
            [
              5.006004863914,
              43.469664960408
            ],
            [
              5.0178344377819,
              43.469321193939
            ]
          ]
        ]
      },
      "properties": {
        "code": "13",
        "nom": "Bouches-du-Rhône",
        "population": 2048070,
        "delitspour1000": "6,504",
        "nombremosque": 108,
        "parentsEtrangers": "63,1",
        "benefRsa": 157554,
        "eoliennes": 45
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -0.10293827139676,
              45.969661749473
            ],
            [
              -0.076526504806034,
              45.979591875696
            ],
            [
              -0.061817492849519,
              45.97981583828
            ],
            [
              -0.058379400073131,
              45.986819835737
            ],
            [
              -0.041431040823061,
              45.993479981508
            ],
            [
              -0.047909394991127,
              46.004369371945
            ],
            [
              -0.045516919542785,
              46.020621309221
            ],
            [
              -0.030671681755728,
              46.022419811007
            ],
            [
              -0.021452263306336,
              46.028868685941
            ],
            [
              -0.034215014394908,
              46.04087077555
            ],
            [
              -0.039426462914628,
              46.052929212798
            ],
            [
              -0.031992658836249,
              46.055920972242
            ],
            [
              -0.0046192097725446,
              46.055231392311
            ],
            [
              0.005154213384114,
              46.059484760402
            ],
            [
              0.019270346192872,
              46.052845061857
            ],
            [
              0.030782887340704,
              46.063431768817
            ],
            [
              0.058009181840751,
              46.072806629731
            ],
            [
              0.052853231614337,
              46.081428910066
            ],
            [
              0.072966989619956,
              46.093897575854
            ],
            [
              0.099656827636359,
              46.092922343594
            ],
            [
              0.092967733676126,
              46.101452150467
            ],
            [
              0.11501750524535,
              46.096150652847
            ],
            [
              0.13569050232961,
              46.104048921953
            ],
            [
              0.146110421707,
              46.095880994017
            ],
            [
              0.16136056171432,
              46.08978488217
            ],
            [
              0.17137846819894,
              46.08293290492
            ],
            [
              0.17785052333068,
              46.08330677274
            ],
            [
              0.19735466096157,
              46.095552773268
            ],
            [
              0.21971878996587,
              46.094314452313
            ],
            [
              0.23955572940534,
              46.081364339961
            ],
            [
              0.25057745385649,
              46.079935391655
            ],
            [
              0.27981131515865,
              46.060966386389
            ],
            [
              0.29728743780505,
              46.059247091762
            ],
            [
              0.31756830797163,
              46.064922145215
            ],
            [
              0.36229114045965,
              46.064386429332
            ],
            [
              0.37755887765857,
              46.063144903028
            ],
            [
              0.39105259889106,
              46.06634832653
            ],
            [
              0.40265671681574,
              46.062677433561
            ],
            [
              0.4138031271991,
              46.049008552161
            ],
            [
              0.43294150558574,
              46.051538293862
            ],
            [
              0.44508390693692,
              46.050797203062
            ],
            [
              0.456335744104,
              46.05436034237
            ],
            [
              0.47033221964969,
              46.064649751647
            ],
            [
              0.4806482933821,
              46.065427190061
            ],
            [
              0.4744775570274,
              46.073920139993
            ],
            [
              0.4759565784283,
              46.082695886727
            ],
            [
              0.46137039761349,
              46.087540071105
            ],
            [
              0.44583614455469,
              46.088877982546
            ],
            [
              0.4432615785848,
              46.101569497166
            ],
            [
              0.45778373543798,
              46.108156518233
            ],
            [
              0.46686874789163,
              46.116811499669
            ],
            [
              0.4723029396892,
              46.130130657707
            ],
            [
              0.48812311162237,
              46.129266401439
            ],
            [
              0.49249289349163,
              46.135952486986
            ],
            [
              0.50641325848251,
              46.131948964394
            ],
            [
              0.50518002031707,
              46.118542047617
            ],
            [
              0.51986518761971,
              46.114752002752
            ],
            [
              0.5241097133275,
              46.105775893022
            ],
            [
              0.53455840721242,
              46.098043670735
            ],
            [
              0.53997555504991,
              46.085555478485
            ],
            [
              0.55404215344677,
              46.090457947519
            ],
            [
              0.56286748151915,
              46.089562263774
            ],
            [
              0.57497704528704,
              46.078997260944
            ],
            [
              0.58195736273612,
              46.081085942693
            ],
            [
              0.60336939853311,
              46.076715104382
            ],
            [
              0.60864943901766,
              46.089686935991
            ],
            [
              0.64162895001642,
              46.091806988364
            ],
            [
              0.66024943539143,
              46.095741994227
            ],
            [
              0.68744642686547,
              46.09725447452
            ],
            [
              0.67872102383007,
              46.109815633487
            ],
            [
              0.68406230433217,
              46.119342506974
            ],
            [
              0.70686980526733,
              46.129123201938
            ],
            [
              0.71255307137588,
              46.139591492141
            ],
            [
              0.72824604972602,
              46.135008535887
            ],
            [
              0.74690845812273,
              46.13857389875
            ],
            [
              0.78015791208515,
              46.132146290713
            ],
            [
              0.79723911297019,
              46.132639294005
            ],
            [
              0.80950911017317,
              46.138205095419
            ],
            [
              0.82343238223316,
              46.128584404123
            ],
            [
              0.81203762140491,
              46.125326880589
            ],
            [
              0.81829906044852,
              46.113037512796
            ],
            [
              0.83368600867603,
              46.106358910604
            ],
            [
              0.8344470049923,
              46.097505684097
            ],
            [
              0.83060131714538,
              46.089176126355
            ],
            [
              0.81890097305856,
              46.077410324336
            ],
            [
              0.82532610286627,
              46.071827815687
            ],
            [
              0.82690169948677,
              46.063929795286
            ],
            [
              0.81474748013296,
              46.057063389192
            ],
            [
              0.81791082620152,
              46.047881741971
            ],
            [
              0.85247379596105,
              46.03380871971
            ],
            [
              0.85844316742977,
              46.026890740983
            ],
            [
              0.8597496793536,
              46.017976558703
            ],
            [
              0.86703490010072,
              46.018431918244
            ],
            [
              0.88424329506274,
              46.031883014096
            ],
            [
              0.89403472489117,
              46.023144627301
            ],
            [
              0.92562039873925,
              46.010452068811
            ],
            [
              0.9189303920422,
              45.997050616674
            ],
            [
              0.93467626363191,
              45.992207854621
            ],
            [
              0.93155664658109,
              45.985465176482
            ],
            [
              0.93451017847667,
              45.976009331251
            ],
            [
              0.9456207917489,
              45.974325195389
            ],
            [
              0.94259709142132,
              45.957437972663
            ],
            [
              0.92271425809889,
              45.953005887095
            ],
            [
              0.92097504308226,
              45.937911592345
            ],
            [
              0.91016314124597,
              45.937100765297
            ],
            [
              0.8889259548768,
              45.925716576344
            ],
            [
              0.86291072789308,
              45.919754691776
            ],
            [
              0.85208289246111,
              45.924426664385
            ],
            [
              0.83755014577172,
              45.923064279624
            ],
            [
              0.82665795374138,
              45.929457739311
            ],
            [
              0.81211444044304,
              45.931270953369
            ],
            [
              0.80872660646568,
              45.923261780883
            ],
            [
              0.81620350646935,
              45.916343812086
            ],
            [
              0.8154385343037,
              45.895142245351
            ],
            [
              0.82737827730018,
              45.88274984149
            ],
            [
              0.81194225571378,
              45.872553679228
            ],
            [
              0.82084980641218,
              45.867229794359
            ],
            [
              0.80199129603081,
              45.836873754429
            ],
            [
              0.78222581976608,
              45.821079269049
            ],
            [
              0.78261227642634,
              45.811716259765
            ],
            [
              0.77646745103673,
              45.802378820004
            ],
            [
              0.7838946240077,
              45.791677820476
            ],
            [
              0.77213876561217,
              45.787624942999
            ],
            [
              0.76304658089703,
              45.796273403347
            ],
            [
              0.75203254465668,
              45.798240925358
            ],
            [
              0.74090845042694,
              45.805742765851
            ],
            [
              0.73629196745894,
              45.802977421961
            ],
            [
              0.71643340810388,
              45.805616908376
            ],
            [
              0.70946788355225,
              45.79858772426
            ],
            [
              0.71114784267474,
              45.783134079803
            ],
            [
              0.70577954872733,
              45.777656765807
            ],
            [
              0.71384817928913,
              45.772239691853
            ],
            [
              0.71488961170325,
              45.76312150687
            ],
            [
              0.69431402511169,
              45.761111812143
            ],
            [
              0.6690048783512,
              45.7477810265
            ],
            [
              0.66489522836334,
              45.739215992699
            ],
            [
              0.65251909415086,
              45.740470650574
            ],
            [
              0.64797086926532,
              45.731673285892
            ],
            [
              0.64541869447975,
              45.717506704978
            ],
            [
              0.63231701963779,
              45.720548375195
            ],
            [
              0.62974117909144,
              45.714569962764
            ],
            [
              0.62850817361747,
              45.706676529807
            ],
            [
              0.61532184885031,
              45.695046916735
            ],
            [
              0.60343704553547,
              45.693199195309
            ],
            [
              0.60069801255267,
              45.672144513707
            ],
            [
              0.58895693225232,
              45.666618927718
            ],
            [
              0.56781134452759,
              45.651022517126
            ],
            [
              0.57544701362335,
              45.640928641003
            ],
            [
              0.56121500762338,
              45.63273994743
            ],
            [
              0.54683683001201,
              45.635076073339
            ],
            [
              0.53553993475933,
              45.633365219553
            ],
            [
              0.53542184055499,
              45.642857423245
            ],
            [
              0.52922550873209,
              45.644017077379
            ],
            [
              0.51575470053391,
              45.634304936504
            ],
            [
              0.50682033461012,
              45.619445608837
            ],
            [
              0.49964719571994,
              45.61725840045
            ],
            [
              0.51559326392688,
              45.600376147073
            ],
            [
              0.51292546811965,
              45.58606906763
            ],
            [
              0.49857645241005,
              45.564777127559
            ],
            [
              0.50649651854355,
              45.553883504146
            ],
            [
              0.49626305489216,
              45.544487211484
            ],
            [
              0.4795284728018,
              45.53881551596
            ],
            [
              0.46573556287174,
              45.539620525876
            ],
            [
              0.45880881752177,
              45.525848169906
            ],
            [
              0.43279367905213,
              45.502460755433
            ],
            [
              0.43174334891146,
              45.485591802493
            ],
            [
              0.42370088241242,
              45.483249687319
            ],
            [
              0.40982218818341,
              45.49237033937
            ],
            [
              0.39094372803835,
              45.485293893562
            ],
            [
              0.38169806860726,
              45.486257002114
            ],
            [
              0.3766229628371,
              45.476044095153
            ],
            [
              0.36357698312745,
              45.475994167559
            ],
            [
              0.35653476026362,
              45.465475438899
            ],
            [
              0.33286092152216,
              45.459406476728
            ],
            [
              0.33082431658907,
              45.444453188001
            ],
            [
              0.30785039372442,
              45.460866796551
            ],
            [
              0.30267332511178,
              45.459062975769
            ],
            [
              0.30194134811497,
              45.445955831374
            ],
            [
              0.31787770256526,
              45.436660724831
            ],
            [
              0.31181848118158,
              45.432816179763
            ],
            [
              0.29440078145291,
              45.434184730704
            ],
            [
              0.27814648421058,
              45.427300520629
            ],
            [
              0.27061444567416,
              45.420406763477
            ],
            [
              0.26780372409671,
              45.408430729454
            ],
            [
              0.25031149908812,
              45.373412622458
            ],
            [
              0.24932846319757,
              45.363045050054
            ],
            [
              0.25696762585075,
              45.353063874184
            ],
            [
              0.26490932583048,
              45.33452939729
            ],
            [
              0.26921890468579,
              45.315814949459
            ],
            [
              0.26660296978794,
              45.297745901318
            ],
            [
              0.24739239545183,
              45.289485714699
            ],
            [
              0.23643629318698,
              45.29266355845
            ],
            [
              0.22003424892432,
              45.289805400589
            ],
            [
              0.20484689543031,
              45.264012329551
            ],
            [
              0.18817622900968,
              45.259794647126
            ],
            [
              0.17509716606769,
              45.260270315446
            ],
            [
              0.1722959209832,
              45.251711979864
            ],
            [
              0.17460141923617,
              45.239293180311
            ],
            [
              0.15657015052375,
              45.225855753722
            ],
            [
              0.14699338969177,
              45.224911449741
            ],
            [
              0.14548278729734,
              45.214442736387
            ],
            [
              0.12772944996689,
              45.209114796347
            ],
            [
              0.11486582285595,
              45.214042420393
            ],
            [
              0.096190225093676,
              45.227859522857
            ],
            [
              0.075727933465365,
              45.220263293742
            ],
            [
              0.062925519022323,
              45.221997931334
            ],
            [
              0.053427894812278,
              45.226854908396
            ],
            [
              0.038446114457259,
              45.216205521194
            ],
            [
              0.033349583596485,
              45.208000365146
            ],
            [
              0.0077522704233095,
              45.202019009121
            ],
            [
              0.0043360783093975,
              45.191628193392
            ],
            [
              -0.0022343196868311,
              45.195323554796
            ],
            [
              -0.0016531463017523,
              45.206316027453
            ],
            [
              0.0058232248211972,
              45.221412369619
            ],
            [
              0.0034276727151617,
              45.228718895198
            ],
            [
              -0.0095281142937434,
              45.227892977577
            ],
            [
              -0.025962209154688,
              45.232355357021
            ],
            [
              -0.036468365384134,
              45.238687865732
            ],
            [
              -0.044174427296423,
              45.247891898888
            ],
            [
              -0.088508317571876,
              45.246349835321
            ],
            [
              -0.11538026933891,
              45.248074633391
            ],
            [
              -0.11274164369573,
              45.254439864332
            ],
            [
              -0.11315820930109,
              45.269505992776
            ],
            [
              -0.10878357122157,
              45.28895254882
            ],
            [
              -0.12410073848607,
              45.293943265834
            ],
            [
              -0.13795225280646,
              45.292880437681
            ],
            [
              -0.1570027661118,
              45.304647680565
            ],
            [
              -0.17904526078995,
              45.308204691711
            ],
            [
              -0.19242059438335,
              45.306448509013
            ],
            [
              -0.21266841634205,
              45.31348461391
            ],
            [
              -0.22669700728525,
              45.321429446775
            ],
            [
              -0.23775101076552,
              45.314120359398
            ],
            [
              -0.25647479995809,
              45.309492525237
            ],
            [
              -0.26063823207321,
              45.298627031974
            ],
            [
              -0.27426295791554,
              45.302511468662
            ],
            [
              -0.2838487723318,
              45.309946617502
            ],
            [
              -0.28265983370323,
              45.317445416359
            ],
            [
              -0.28873639979921,
              45.329102689082
            ],
            [
              -0.27790150964647,
              45.347755096327
            ],
            [
              -0.2638385171848,
              45.340186739415
            ],
            [
              -0.25029908206598,
              45.341342342169
            ],
            [
              -0.24626907786219,
              45.353992412797
            ],
            [
              -0.25204550211244,
              45.357692731336
            ],
            [
              -0.28089985115869,
              45.359872524401
            ],
            [
              -0.29260206864406,
              45.36916309663
            ],
            [
              -0.30980400438703,
              45.37371724884
            ],
            [
              -0.31039530848299,
              45.386080139957
            ],
            [
              -0.29160703012116,
              45.387199902774
            ],
            [
              -0.27987455471692,
              45.391660929625
            ],
            [
              -0.27816376234535,
              45.398722581495
            ],
            [
              -0.26053747331488,
              45.401563971678
            ],
            [
              -0.24975703940313,
              45.408745402272
            ],
            [
              -0.23406024586768,
              45.409198691634
            ],
            [
              -0.23454234837219,
              45.419089093003
            ],
            [
              -0.24985313103838,
              45.417654768777
            ],
            [
              -0.25296301436591,
              45.422623088805
            ],
            [
              -0.24858219466218,
              45.43829622206
            ],
            [
              -0.26396058504239,
              45.448560617344
            ],
            [
              -0.26742796692728,
              45.455680296664
            ],
            [
              -0.27731043139055,
              45.459095081138
            ],
            [
              -0.28619795426743,
              45.456366484089
            ],
            [
              -0.28090368498526,
              45.474222779726
            ],
            [
              -0.26474489084668,
              45.470684661678
            ],
            [
              -0.25810562061945,
              45.487311671981
            ],
            [
              -0.24615075997527,
              45.491638915068
            ],
            [
              -0.24199917926696,
              45.502762015032
            ],
            [
              -0.25319354734665,
              45.501287202981
            ],
            [
              -0.26738468810567,
              45.509080707575
            ],
            [
              -0.25602828746258,
              45.51530840403
            ],
            [
              -0.25502307893398,
              45.521182965101
            ],
            [
              -0.26615421676893,
              45.525064860454
            ],
            [
              -0.28127750697165,
              45.525568387243
            ],
            [
              -0.2901454607883,
              45.530634360623
            ],
            [
              -0.29790634677759,
              45.528118388403
            ],
            [
              -0.32161606249361,
              45.534964864877
            ],
            [
              -0.32115948505163,
              45.542266927364
            ],
            [
              -0.30810278413318,
              45.549318031389
            ],
            [
              -0.2967204538074,
              45.564495522853
            ],
            [
              -0.31592174831236,
              45.578714867447
            ],
            [
              -0.32864794524113,
              45.584757523734
            ],
            [
              -0.34144303284896,
              45.586978040849
            ],
            [
              -0.3446188253208,
              45.594188494078
            ],
            [
              -0.35877316216011,
              45.597858313948
            ],
            [
              -0.37592461589022,
              45.608916349769
            ],
            [
              -0.3870866140304,
              45.625883962975
            ],
            [
              -0.39958220145148,
              45.620391094216
            ],
            [
              -0.41648532750286,
              45.617243376201
            ],
            [
              -0.43199190568977,
              45.624602844354
            ],
            [
              -0.42511918847294,
              45.630848552406
            ],
            [
              -0.40893222648837,
              45.656182506299
            ],
            [
              -0.39473380554576,
              45.653333645217
            ],
            [
              -0.38253774771373,
              45.659797375177
            ],
            [
              -0.41054507661905,
              45.683374341793
            ],
            [
              -0.42292034518264,
              45.685591002989
            ],
            [
              -0.41583934649791,
              45.698098364271
            ],
            [
              -0.42212652174802,
              45.709466163286
            ],
            [
              -0.41958834610446,
              45.721636409401
            ],
            [
              -0.40109121552996,
              45.734759859379
            ],
            [
              -0.415834780924,
              45.741668774387
            ],
            [
              -0.46000475927654,
              45.73927130291
            ],
            [
              -0.46177341555077,
              45.751290622612
            ],
            [
              -0.44882045148802,
              45.765926269772
            ],
            [
              -0.4273102965247,
              45.764072837255
            ],
            [
              -0.41365840203171,
              45.77142904832
            ],
            [
              -0.40217606349986,
              45.77301538502
            ],
            [
              -0.40038791129214,
              45.78666760956
            ],
            [
              -0.3658914331052,
              45.780312439395
            ],
            [
              -0.34034402768082,
              45.78695742909
            ],
            [
              -0.3212870680561,
              45.784501745786
            ],
            [
              -0.31027375308555,
              45.786921880303
            ],
            [
              -0.29853199953722,
              45.79426425579
            ],
            [
              -0.29481326175584,
              45.805289163403
            ],
            [
              -0.27312050329822,
              45.806560389612
            ],
            [
              -0.23871849150901,
              45.80303325017
            ],
            [
              -0.24280682786681,
              45.796908094859
            ],
            [
              -0.22174636217252,
              45.775742008413
            ],
            [
              -0.19917945642312,
              45.780124503215
            ],
            [
              -0.19100869192932,
              45.789894531994
            ],
            [
              -0.17074859900197,
              45.788593968326
            ],
            [
              -0.15605561945411,
              45.779515353962
            ],
            [
              -0.14831865256012,
              45.789092405305
            ],
            [
              -0.15309093676867,
              45.795738007974
            ],
            [
              -0.1358753369401,
              45.819926614425
            ],
            [
              -0.14022670568372,
              45.836483315707
            ],
            [
              -0.14468370051164,
              45.839670298572
            ],
            [
              -0.1357548963919,
              45.847293151618
            ],
            [
              -0.1250616653059,
              45.848652979567
            ],
            [
              -0.12209653110803,
              45.860104482401
            ],
            [
              -0.1153965183234,
              45.870664008952
            ],
            [
              -0.12652415818975,
              45.872654668721
            ],
            [
              -0.1305981319385,
              45.877988712202
            ],
            [
              -0.13095839648048,
              45.891010092778
            ],
            [
              -0.14666764833709,
              45.897775359622
            ],
            [
              -0.13166455296459,
              45.911022429568
            ],
            [
              -0.14315134669297,
              45.916935514246
            ],
            [
              -0.14900550606153,
              45.925178208408
            ],
            [
              -0.14047966196185,
              45.931516453162
            ],
            [
              -0.12981041355537,
              45.927130462589
            ],
            [
              -0.11167871901616,
              45.929821591238
            ],
            [
              -0.097039255426737,
              45.928384092976
            ],
            [
              -0.098302168337653,
              45.935062439996
            ],
            [
              -0.08644912706636,
              45.949990132787
            ],
            [
              -0.09327322578952,
              45.959833948001
            ],
            [
              -0.10317443011582,
              45.960579405475
            ],
            [
              -0.10293827139676,
              45.969661749473
            ]
          ]
        ]
      },
      "properties": {
        "code": "16",
        "nom": "Charente",
        "population": 351718,
        "delitspour1000": "4,998",
        "nombremosque": 6,
        "parentsEtrangers": "81,2",
        "benefRsa": 20110,
        "eoliennes": 87
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.2392136694694,
              47.620975607683
            ],
            [
              2.2862326072553,
              47.628965936616
            ],
            [
              2.3284801497791,
              47.607120727401
            ],
            [
              2.3424454034671,
              47.605276604314
            ],
            [
              2.3560053519371,
              47.593806918478
            ],
            [
              2.3730196534104,
              47.585528165617
            ],
            [
              2.393101394459,
              47.591906545009
            ],
            [
              2.4026835806557,
              47.599501873767
            ],
            [
              2.4373445977447,
              47.609775401769
            ],
            [
              2.4484422329071,
              47.606257520017
            ],
            [
              2.4525445186872,
              47.596764185392
            ],
            [
              2.4913556166844,
              47.568297226273
            ],
            [
              2.5079023998009,
              47.568183660166
            ],
            [
              2.5410842063882,
              47.574066312921
            ],
            [
              2.5632062660237,
              47.572136704018
            ],
            [
              2.5729212924557,
              47.560019824163
            ],
            [
              2.5998977203117,
              47.553688422022
            ],
            [
              2.6167379402622,
              47.540898035379
            ],
            [
              2.6120137313394,
              47.526383600079
            ],
            [
              2.6558735526076,
              47.510394947544
            ],
            [
              2.6584604288768,
              47.487901045881
            ],
            [
              2.6844365639414,
              47.482968445146
            ],
            [
              2.7193587311998,
              47.492167545335
            ],
            [
              2.7274128318664,
              47.49888751122
            ],
            [
              2.7220501940056,
              47.505337122249
            ],
            [
              2.7222672045739,
              47.521369480718
            ],
            [
              2.7272199325343,
              47.535946546253
            ],
            [
              2.7326153907893,
              47.538041988135
            ],
            [
              2.7418945281053,
              47.526856023445
            ],
            [
              2.7501051267783,
              47.52399432216
            ],
            [
              2.7680289965473,
              47.523523680368
            ],
            [
              2.7912248331378,
              47.506907437916
            ],
            [
              2.7980078225933,
              47.496783569863
            ],
            [
              2.8064811348488,
              47.494455723983
            ],
            [
              2.8253522908075,
              47.497211236062
            ],
            [
              2.8346869746123,
              47.508909835805
            ],
            [
              2.8470793076985,
              47.513667879934
            ],
            [
              2.8746305174837,
              47.520424918568
            ],
            [
              2.8884586332585,
              47.50943098545
            ],
            [
              2.8980184987491,
              47.485252401408
            ],
            [
              2.9140606551793,
              47.470896834824
            ],
            [
              2.9209883066832,
              47.455435821811
            ],
            [
              2.9288970093188,
              47.444564932191
            ],
            [
              2.930733371753,
              47.431324302158
            ],
            [
              2.9198859465078,
              47.42134952529
            ],
            [
              2.9184549974743,
              47.405524882196
            ],
            [
              2.9008159931543,
              47.385192680229
            ],
            [
              2.8952492319567,
              47.372177057081
            ],
            [
              2.8734919391537,
              47.348396860493
            ],
            [
              2.8699481463917,
              47.338248316569
            ],
            [
              2.8765423657559,
              47.321934350315
            ],
            [
              2.8848764388062,
              47.316364164149
            ],
            [
              2.907768482234,
              47.310991294935
            ],
            [
              2.9264805753113,
              47.298618271978
            ],
            [
              2.9378619912637,
              47.287719383722
            ],
            [
              2.9739389096142,
              47.269804660206
            ],
            [
              2.9834001334309,
              47.259765880582
            ],
            [
              2.9779552353597,
              47.235552508643
            ],
            [
              2.9816886133559,
              47.221993356801
            ],
            [
              2.9972827962038,
              47.20166393803
            ],
            [
              3.0092852008476,
              47.179914660937
            ],
            [
              3.0159484799111,
              47.159093918463
            ],
            [
              3.0211980869457,
              47.136438093682
            ],
            [
              3.0280905152523,
              47.129064982977
            ],
            [
              3.0313737475376,
              47.092475725697
            ],
            [
              3.0211804845643,
              47.078232382085
            ],
            [
              3.0187160021753,
              47.070780884737
            ],
            [
              3.0232191913817,
              47.062612371142
            ],
            [
              3.034525000237,
              47.056291089581
            ],
            [
              3.0627453140392,
              47.04527634615
            ],
            [
              3.0748375004401,
              47.029815006019
            ],
            [
              3.0757442612943,
              47.019148176465
            ],
            [
              3.0655821306108,
              46.997351832387
            ],
            [
              3.0629299477088,
              46.981085018578
            ],
            [
              3.0717062160523,
              46.964340639457
            ],
            [
              3.0793324170792,
              46.955115150499
            ],
            [
              3.0648978058646,
              46.937079267447
            ],
            [
              3.062210755789,
              46.927739529848
            ],
            [
              3.0502954877584,
              46.910631265703
            ],
            [
              3.0514016137092,
              46.904955777225
            ],
            [
              3.0609590858964,
              46.898148846497
            ],
            [
              3.0680961068914,
              46.877680332057
            ],
            [
              3.0671018851119,
              46.847818120893
            ],
            [
              3.0545889127264,
              46.838765097797
            ],
            [
              3.0593510744061,
              46.827263935532
            ],
            [
              3.0374707304721,
              46.807786953663
            ],
            [
              3.0320629441459,
              46.794909389217
            ],
            [
              3.0175184994153,
              46.799900698717
            ],
            [
              3.0033551657685,
              46.798122809815
            ],
            [
              2.9895396276531,
              46.799298294916
            ],
            [
              2.977801671991,
              46.80377337386
            ],
            [
              2.959918627937,
              46.803872076205
            ],
            [
              2.9529591220769,
              46.790999230159
            ],
            [
              2.9378161550649,
              46.795713700537
            ],
            [
              2.9243754548552,
              46.794523429403
            ],
            [
              2.9081271826571,
              46.787903599841
            ],
            [
              2.9098049149944,
              46.779347563944
            ],
            [
              2.8761691440531,
              46.768445733319
            ],
            [
              2.8770157582915,
              46.761468535881
            ],
            [
              2.8612332862758,
              46.754030340445
            ],
            [
              2.845419928846,
              46.742855932849
            ],
            [
              2.8448121194469,
              46.726844055458
            ],
            [
              2.8276177034814,
              46.735286079973
            ],
            [
              2.8010724027789,
              46.733722377214
            ],
            [
              2.787290827625,
              46.728613960925
            ],
            [
              2.7744893811293,
              46.718902897367
            ],
            [
              2.7585207590938,
              46.717748505775
            ],
            [
              2.7567441474108,
              46.724772483564
            ],
            [
              2.7435704821949,
              46.73008326729
            ],
            [
              2.7372898083354,
              46.743150242056
            ],
            [
              2.7287214908144,
              46.748308579779
            ],
            [
              2.7049698717297,
              46.739389993021
            ],
            [
              2.7009209254283,
              46.720959754269
            ],
            [
              2.6881649904859,
              46.720909610987
            ],
            [
              2.6777927654584,
              46.704612208045
            ],
            [
              2.6547293011216,
              46.696536792562
            ],
            [
              2.6478858990075,
              46.688908315282
            ],
            [
              2.6313578708624,
              46.690886867154
            ],
            [
              2.6234039357923,
              46.688702717854
            ],
            [
              2.6215530530757,
              46.678653259953
            ],
            [
              2.6308613163993,
              46.672294145791
            ],
            [
              2.6246414896646,
              46.657300692831
            ],
            [
              2.6095979741835,
              46.662840354764
            ],
            [
              2.6050484721825,
              46.668158037606
            ],
            [
              2.5849748988646,
              46.664165776546
            ],
            [
              2.5721892884417,
              46.659444642004
            ],
            [
              2.5673899068872,
              46.652347408754
            ],
            [
              2.5895802639199,
              46.648026747075
            ],
            [
              2.5966478585977,
              46.637215067759
            ],
            [
              2.5852765586441,
              46.622738638042
            ],
            [
              2.5859628971047,
              46.6142967727
            ],
            [
              2.5691010154446,
              46.609518978116
            ],
            [
              2.5779519369239,
              46.603788174773
            ],
            [
              2.5815597567024,
              46.593164438775
            ],
            [
              2.6023203253116,
              46.595164451357
            ],
            [
              2.6045186165205,
              46.579001838563
            ],
            [
              2.6093779370947,
              46.571328686404
            ],
            [
              2.60621930247,
              46.565758488844
            ],
            [
              2.6149607060411,
              46.553276455558
            ],
            [
              2.5835051726425,
              46.542741752192
            ],
            [
              2.5724916070298,
              46.533849645617
            ],
            [
              2.5540275760751,
              46.529387627047
            ],
            [
              2.5366546066244,
              46.519702557793
            ],
            [
              2.5275313125159,
              46.529030044988
            ],
            [
              2.5201385391568,
              46.530864132368
            ],
            [
              2.512426202843,
              46.523880853474
            ],
            [
              2.4991226886431,
              46.521292172555
            ],
            [
              2.4969434776113,
              46.533665788244
            ],
            [
              2.4829275256976,
              46.532694737487
            ],
            [
              2.4685711377311,
              46.526009632976
            ],
            [
              2.4502811792897,
              46.521935935452
            ],
            [
              2.4266867789218,
              46.526121661214
            ],
            [
              2.4131644849466,
              46.520301786364
            ],
            [
              2.3884650716406,
              46.518246292054
            ],
            [
              2.3683002910262,
              46.51843386298
            ],
            [
              2.3520036091655,
              46.512206845202
            ],
            [
              2.3241785781947,
              46.49036759012
            ],
            [
              2.3298217389117,
              46.479616607338
            ],
            [
              2.3242884285484,
              46.470978980874
            ],
            [
              2.3166710404192,
              46.468549195709
            ],
            [
              2.3054693178764,
              46.475428936804
            ],
            [
              2.2857604795168,
              46.453514664499
            ],
            [
              2.2810437278384,
              46.420403547753
            ],
            [
              2.2498765158587,
              46.426366967242
            ],
            [
              2.2334148941606,
              46.423384135542
            ],
            [
              2.2206403396014,
              46.423664161021
            ],
            [
              2.1975676755012,
              46.428294141001
            ],
            [
              2.1851558899005,
              46.423284068779
            ],
            [
              2.1677843748983,
              46.424069192575
            ],
            [
              2.1543506339543,
              46.434080534798
            ],
            [
              2.1516663532134,
              46.45796285489
            ],
            [
              2.156536738392,
              46.461524399253
            ],
            [
              2.1937480421876,
              46.470798947226
            ],
            [
              2.2043920861378,
              46.484397237754
            ],
            [
              2.204377139976,
              46.490605163166
            ],
            [
              2.1946310547888,
              46.506068691823
            ],
            [
              2.1834172727147,
              46.508977916004
            ],
            [
              2.1818798754785,
              46.522515787074
            ],
            [
              2.1776855709349,
              46.530652297328
            ],
            [
              2.1586963030001,
              46.557344452138
            ],
            [
              2.1732113440402,
              46.566183161063
            ],
            [
              2.1671401783505,
              46.588981814794
            ],
            [
              2.176954649399,
              46.601681136912
            ],
            [
              2.1856314742498,
              46.602862444429
            ],
            [
              2.1874862145218,
              46.616720480096
            ],
            [
              2.1833732167287,
              46.622147237746
            ],
            [
              2.1842458340693,
              46.63176333554
            ],
            [
              2.1894415234234,
              46.64213853449
            ],
            [
              2.1794468252209,
              46.642530278058
            ],
            [
              2.1617076323527,
              46.654560152419
            ],
            [
              2.1431297127939,
              46.663534742579
            ],
            [
              2.1366685342935,
              46.677280643876
            ],
            [
              2.1478278174915,
              46.681978091826
            ],
            [
              2.1554415063015,
              46.692156551695
            ],
            [
              2.1407192440195,
              46.695550975141
            ],
            [
              2.1310053622523,
              46.705564874051
            ],
            [
              2.1181050846152,
              46.710264176432
            ],
            [
              2.0869647803577,
              46.712584352345
            ],
            [
              2.0927323443423,
              46.72429296012
            ],
            [
              2.0657565358489,
              46.742495745033
            ],
            [
              2.0765727696581,
              46.7522371017
            ],
            [
              2.0929871989079,
              46.754972007435
            ],
            [
              2.1153566557228,
              46.77436037191
            ],
            [
              2.116644294623,
              46.779716267822
            ],
            [
              2.1032191381843,
              46.795434158849
            ],
            [
              2.0779477252387,
              46.806715037904
            ],
            [
              2.0488037102017,
              46.829451815206
            ],
            [
              2.0480782837609,
              46.835794883155
            ],
            [
              2.06876776215,
              46.834582910689
            ],
            [
              2.0782512303935,
              46.838923780247
            ],
            [
              2.096467901154,
              46.855247357856
            ],
            [
              2.087079297321,
              46.86752997115
            ],
            [
              2.1069526908981,
              46.872136681281
            ],
            [
              2.1141987370392,
              46.880427985511
            ],
            [
              2.126206500563,
              46.879866015754
            ],
            [
              2.1390331265564,
              46.888570687718
            ],
            [
              2.154833099738,
              46.911627673453
            ],
            [
              2.1460170187651,
              46.917505413
            ],
            [
              2.1216654778987,
              46.916293198614
            ],
            [
              2.1103224158986,
              46.913257304755
            ],
            [
              2.0965786279245,
              46.918806815272
            ],
            [
              2.0707025283968,
              46.933998270256
            ],
            [
              2.0702081853584,
              46.954419351516
            ],
            [
              2.0882457712698,
              46.960442164094
            ],
            [
              2.0958019671543,
              46.989651377194
            ],
            [
              2.1088506754911,
              46.997189278307
            ],
            [
              2.0933825983882,
              47.015734925131
            ],
            [
              2.0683404264664,
              47.026024262623
            ],
            [
              2.0514916798709,
              47.040017881632
            ],
            [
              2.0333809485515,
              47.042849839903
            ],
            [
              2.03373739539,
              47.049867783207
            ],
            [
              2.041032928343,
              47.062834748226
            ],
            [
              2.0556685174481,
              47.071693505089
            ],
            [
              2.0538795396561,
              47.086532442778
            ],
            [
              2.0499678265899,
              47.091426327267
            ],
            [
              2.0532618114789,
              47.103623707949
            ],
            [
              2.0483748335746,
              47.106018202845
            ],
            [
              2.0245359187373,
              47.104448305394
            ],
            [
              2.0303104954162,
              47.096817560051
            ],
            [
              2.003321032879,
              47.094178638571
            ],
            [
              2.0094058333809,
              47.111603739978
            ],
            [
              1.9992352868587,
              47.115486844081
            ],
            [
              1.9966588335085,
              47.126994721746
            ],
            [
              1.9815904431899,
              47.123440729154
            ],
            [
              1.9664192725479,
              47.125416660655
            ],
            [
              1.9260996730031,
              47.106523038938
            ],
            [
              1.9019778224018,
              47.10778397148
            ],
            [
              1.882724430627,
              47.101952271787
            ],
            [
              1.8470711123844,
              47.110756679752
            ],
            [
              1.8390424455761,
              47.11697467405
            ],
            [
              1.8226516325045,
              47.12207545041
            ],
            [
              1.7936682439251,
              47.12090793503
            ],
            [
              1.7909839952944,
              47.125662872073
            ],
            [
              1.7745852665449,
              47.130846854619
            ],
            [
              1.8100596781046,
              47.156159044775
            ],
            [
              1.810837828747,
              47.164258858399
            ],
            [
              1.8225543314818,
              47.173442805215
            ],
            [
              1.8421034231416,
              47.178025700878
            ],
            [
              1.8374260291198,
              47.198516443136
            ],
            [
              1.8390806604309,
              47.219303670107
            ],
            [
              1.8554771463285,
              47.220408539843
            ],
            [
              1.8730037897341,
              47.205321851446
            ],
            [
              1.9040735282719,
              47.21565260341
            ],
            [
              1.9165889099228,
              47.23598497734
            ],
            [
              1.9095263383315,
              47.243785095414
            ],
            [
              1.8921795193447,
              47.248757134971
            ],
            [
              1.8947740713773,
              47.25465935032
            ],
            [
              1.9192847375192,
              47.262801635486
            ],
            [
              1.9208433275773,
              47.270611257517
            ],
            [
              1.940821280268,
              47.290134431824
            ],
            [
              1.9843693739698,
              47.272419168025
            ],
            [
              1.9970357988971,
              47.265441117762
            ],
            [
              2.0152524004983,
              47.268686204602
            ],
            [
              2.0243923249491,
              47.265113534019
            ],
            [
              2.0490401397125,
              47.277096154222
            ],
            [
              2.0792023479058,
              47.285353997825
            ],
            [
              2.1050441471964,
              47.286127872964
            ],
            [
              2.1116829740227,
              47.279440115357
            ],
            [
              2.1255611204397,
              47.27974000395
            ],
            [
              2.1389909128822,
              47.285488920835
            ],
            [
              2.1436173455205,
              47.295974694853
            ],
            [
              2.1574168952372,
              47.299624646068
            ],
            [
              2.1515600335357,
              47.310724869692
            ],
            [
              2.1347341636016,
              47.321166463789
            ],
            [
              2.1282008666343,
              47.331734654456
            ],
            [
              2.1194608009184,
              47.333517001812
            ],
            [
              2.1270551026723,
              47.345270764823
            ],
            [
              2.1144918710749,
              47.36692542774
            ],
            [
              2.0970820446785,
              47.366800476362
            ],
            [
              2.1016941167317,
              47.386778966526
            ],
            [
              2.1170289477053,
              47.410106890952
            ],
            [
              2.119309590853,
              47.419331050353
            ],
            [
              2.1353270972844,
              47.40667755823
            ],
            [
              2.1415496288578,
              47.408217168994
            ],
            [
              2.1889033879188,
              47.437791396549
            ],
            [
              2.205223396824,
              47.405248760368
            ],
            [
              2.230393378435,
              47.407523699971
            ],
            [
              2.2436971815592,
              47.414820973003
            ],
            [
              2.2478931361182,
              47.441404079512
            ],
            [
              2.2426426681234,
              47.449181276556
            ],
            [
              2.245113016333,
              47.458650536234
            ],
            [
              2.2382244406468,
              47.47233936616
            ],
            [
              2.247743712549,
              47.491730515969
            ],
            [
              2.2349948952765,
              47.496352865596
            ],
            [
              2.2178759551605,
              47.495347099954
            ],
            [
              2.1987854144886,
              47.487050674796
            ],
            [
              2.2035282111491,
              47.501132746028
            ],
            [
              2.2129903924266,
              47.512971674694
            ],
            [
              2.2151623441422,
              47.521608405306
            ],
            [
              2.1956376610434,
              47.548570165214
            ],
            [
              2.1759088045695,
              47.548589959601
            ],
            [
              2.1478658766709,
              47.551695731236
            ],
            [
              2.1312639734154,
              47.550273375562
            ],
            [
              2.1237852546989,
              47.559973699827
            ],
            [
              2.127061059636,
              47.571762789308
            ],
            [
              2.1195727339039,
              47.582947092529
            ],
            [
              2.1279740267329,
              47.584694404081
            ],
            [
              2.1569348129666,
              47.600730394597
            ],
            [
              2.1939871114356,
              47.606718012679
            ],
            [
              2.208375321719,
              47.610512497337
            ],
            [
              2.2212298775902,
              47.616732581892
            ],
            [
              2.2392136694694,
              47.620975607683
            ]
          ]
        ]
      },
      "properties": {
        "code": "18",
        "nom": "Cher",
        "population": 300933,
        "delitspour1000": "3,865",
        "nombremosque": 9,
        "parentsEtrangers": "82,8",
        "benefRsa": 18755,
        "eoliennes": 92
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              9.4022711090103,
              41.858701678165
            ],
            [
              9.3866823823286,
              41.867188199968
            ],
            [
              9.3719040548729,
              41.865004853102
            ],
            [
              9.3556758229759,
              41.855327750137
            ],
            [
              9.3448285774911,
              41.845259839386
            ],
            [
              9.3374450741333,
              41.845088647014
            ],
            [
              9.3292070564302,
              41.836816297267
            ],
            [
              9.3090914543504,
              41.832143660252
            ],
            [
              9.3024960573859,
              41.844246040822
            ],
            [
              9.2879010801347,
              41.843877976958
            ],
            [
              9.2706975634074,
              41.840589457094
            ],
            [
              9.2596591266328,
              41.845236776196
            ],
            [
              9.2369134454732,
              41.846529531172
            ],
            [
              9.2274996918602,
              41.855587995332
            ],
            [
              9.2361894223697,
              41.875065331657
            ],
            [
              9.2349687010048,
              41.879342163418
            ],
            [
              9.2522076488967,
              41.898987406971
            ],
            [
              9.2488032375803,
              41.908857737427
            ],
            [
              9.2295780895386,
              41.916346158378
            ],
            [
              9.2183638931095,
              41.91363293029
            ],
            [
              9.2132785455984,
              41.917460528629
            ],
            [
              9.2304884424099,
              41.932319666581
            ],
            [
              9.2210986595967,
              41.935557312364
            ],
            [
              9.2154537945669,
              41.942034092005
            ],
            [
              9.2205772118501,
              41.945796570498
            ],
            [
              9.2247577651393,
              41.956511288496
            ],
            [
              9.2202414266448,
              41.989622976178
            ],
            [
              9.2152310128563,
              41.99362645942
            ],
            [
              9.2188803747666,
              42.007817940275
            ],
            [
              9.2210892092947,
              42.027600260448
            ],
            [
              9.1946507804892,
              42.02850787618
            ],
            [
              9.1883567893578,
              42.031080114757
            ],
            [
              9.1608223716694,
              42.026020516263
            ],
            [
              9.1481436742474,
              42.041006906645
            ],
            [
              9.1353143401725,
              42.051069844879
            ],
            [
              9.1286038053593,
              42.067320426142
            ],
            [
              9.1348228365837,
              42.086998025371
            ],
            [
              9.1197350918655,
              42.107770500545
            ],
            [
              9.1037249128773,
              42.116485479522
            ],
            [
              9.0933287260065,
              42.115198176966
            ],
            [
              9.0818499166738,
              42.125905365983
            ],
            [
              9.0721824483641,
              42.13021757503
            ],
            [
              9.0752407064093,
              42.139300614962
            ],
            [
              9.0652668165844,
              42.164493750913
            ],
            [
              9.0506091521078,
              42.170005011499
            ],
            [
              9.0481742273965,
              42.17452867595
            ],
            [
              9.0489799972866,
              42.192999281218
            ],
            [
              9.0456639496781,
              42.206921364452
            ],
            [
              9.0298228102289,
              42.204774632345
            ],
            [
              9.006503816899,
              42.208845591917
            ],
            [
              9.005949397125,
              42.21225943163
            ],
            [
              8.9914659319717,
              42.216578520682
            ],
            [
              8.9730394489482,
              42.232594210154
            ],
            [
              8.9449421977545,
              42.234627193685
            ],
            [
              8.9401229335511,
              42.23734315946
            ],
            [
              8.9330949432296,
              42.250429454883
            ],
            [
              8.9198606665679,
              42.256381499474
            ],
            [
              8.9047709696211,
              42.25386266404
            ],
            [
              8.8819527698561,
              42.271781751932
            ],
            [
              8.8834502030213,
              42.285533375017
            ],
            [
              8.8752125707216,
              42.294285417725
            ],
            [
              8.8627230388832,
              42.296940535735
            ],
            [
              8.8603278562356,
              42.305860595132
            ],
            [
              8.861323814011,
              42.329932688851
            ],
            [
              8.8571680345851,
              42.33098205652
            ],
            [
              8.829317653756,
              42.319329187492
            ],
            [
              8.8195614662278,
              42.317558938226
            ],
            [
              8.7900363923385,
              42.325968475102
            ],
            [
              8.7708826228712,
              42.324766882975
            ],
            [
              8.7596398204464,
              42.336385741044
            ],
            [
              8.7316720302666,
              42.339621254644
            ],
            [
              8.7110680867563,
              42.347099989534
            ],
            [
              8.6841460127953,
              42.347988396554
            ],
            [
              8.6819749384239,
              42.358462008729
            ],
            [
              8.6596703887356,
              42.359406967225
            ],
            [
              8.6504088344609,
              42.362352538519
            ],
            [
              8.6408019259839,
              42.37343904341
            ],
            [
              8.620173521868,
              42.372270719131
            ],
            [
              8.6100160917347,
              42.373593854505
            ],
            [
              8.6000271534091,
              42.369259196001
            ],
            [
              8.5869206603959,
              42.378959456159
            ],
            [
              8.5734085639674,
              42.381404942274
            ],
            [
              8.5851262589954,
              42.38532518234
            ],
            [
              8.5962264339561,
              42.382398762993
            ],
            [
              8.6088441270775,
              42.386379953813
            ],
            [
              8.6022869184152,
              42.400052818571
            ],
            [
              8.6116794771433,
              42.403198147717
            ],
            [
              8.6040429146329,
              42.409512946067
            ],
            [
              8.6084169092937,
              42.416822082277
            ],
            [
              8.6234377365159,
              42.421017847031
            ],
            [
              8.6393957751413,
              42.418519567154
            ],
            [
              8.6481082467376,
              42.413119060137
            ],
            [
              8.6552300368167,
              42.415734337364
            ],
            [
              8.6622200218125,
              42.426291905255
            ],
            [
              8.6604105317327,
              42.43528280362
            ],
            [
              8.6482384069515,
              42.442410807187
            ],
            [
              8.6673206951673,
              42.444883992318
            ],
            [
              8.6681439215198,
              42.45801303094
            ],
            [
              8.6798297088815,
              42.468113820166
            ],
            [
              8.6747918186735,
              42.476242956982
            ],
            [
              8.6492357429259,
              42.474309683953
            ],
            [
              8.6499303561134,
              42.478239958239
            ],
            [
              8.6645502904805,
              42.49299417376
            ],
            [
              8.6665085981229,
              42.515223865295
            ],
            [
              8.6748420529906,
              42.518622461042
            ],
            [
              8.6837613976392,
              42.516458985462
            ],
            [
              8.6968431485562,
              42.525583517795
            ],
            [
              8.7104476758167,
              42.523703513378
            ],
            [
              8.7186158657164,
              42.528245234685
            ],
            [
              8.7102577314429,
              42.535723624921
            ],
            [
              8.7189524706419,
              42.540795175147
            ],
            [
              8.7166314657641,
              42.549267771155
            ],
            [
              8.7228204001293,
              42.556778273386
            ],
            [
              8.7150751347624,
              42.564027675983
            ],
            [
              8.7109904635305,
              42.576605764442
            ],
            [
              8.7243185035707,
              42.584093673826
            ],
            [
              8.7238415443762,
              42.574014082285
            ],
            [
              8.7270436936434,
              42.561604463168
            ],
            [
              8.7381119728326,
              42.567802968518
            ],
            [
              8.7482834774653,
              42.568188079066
            ],
            [
              8.7604416501391,
              42.558569577855
            ],
            [
              8.7686425102482,
              42.555811440464
            ],
            [
              8.7878053378926,
              42.558171123435
            ],
            [
              8.8040993857369,
              42.569855594773
            ],
            [
              8.8001589769082,
              42.582398778056
            ],
            [
              8.8110798196196,
              42.589684742309
            ],
            [
              8.8089320042038,
              42.599059599401
            ],
            [
              8.8235352570811,
              42.606187366031
            ],
            [
              8.8343194896775,
              42.601743500142
            ],
            [
              8.8509405731086,
              42.610583827688
            ],
            [
              8.8649682002014,
              42.607999881244
            ],
            [
              8.8750416263931,
              42.613164920347
            ],
            [
              8.8740939946005,
              42.61817996916
            ],
            [
              8.8865269490476,
              42.628966058933
            ],
            [
              8.9024157028959,
              42.627137432183
            ],
            [
              8.9111573343141,
              42.628894923509
            ],
            [
              8.9181350468671,
              42.63690550884
            ],
            [
              8.9342605989761,
              42.638771827731
            ],
            [
              8.9417828616377,
              42.634096039449
            ],
            [
              8.9593730520526,
              42.635081802897
            ],
            [
              8.9751743716955,
              42.640043666233
            ],
            [
              9.0006328532992,
              42.641910192535
            ],
            [
              9.0103777713521,
              42.64035209136
            ],
            [
              9.0206940431911,
              42.644272763711
            ],
            [
              9.0275141840511,
              42.652054428408
            ],
            [
              9.0460037274368,
              42.655043174979
            ],
            [
              9.0593208042615,
              42.660064628209
            ],
            [
              9.0618252740801,
              42.665092965545
            ],
            [
              9.0538043881836,
              42.679958481466
            ],
            [
              9.0590940519087,
              42.694609453744
            ],
            [
              9.0717031140713,
              42.693409341766
            ],
            [
              9.0797009215888,
              42.700848589665
            ],
            [
              9.0857643447586,
              42.714609110752
            ],
            [
              9.0998800974121,
              42.715246309427
            ],
            [
              9.1059770511843,
              42.724097520742
            ],
            [
              9.1180072147931,
              42.726542533128
            ],
            [
              9.1245519786652,
              42.731631049124
            ],
            [
              9.1360558292684,
              42.730227284287
            ],
            [
              9.1668856039546,
              42.736563616418
            ],
            [
              9.1881552949011,
              42.727336456562
            ],
            [
              9.2038452583029,
              42.726315061736
            ],
            [
              9.217626725491,
              42.734222269857
            ],
            [
              9.2245533152652,
              42.731999951427
            ],
            [
              9.2340050664169,
              42.719909789956
            ],
            [
              9.2544476997124,
              42.718515074833
            ],
            [
              9.2593250469014,
              42.700693050085
            ],
            [
              9.2679444804296,
              42.699476951561
            ],
            [
              9.2788565397074,
              42.689592099364
            ],
            [
              9.2844265705845,
              42.676336087658
            ],
            [
              9.2933224871581,
              42.674311357318
            ],
            [
              9.30315826849,
              42.684062221748
            ],
            [
              9.3141769700085,
              42.688991839255
            ],
            [
              9.3225981035062,
              42.698103422439
            ],
            [
              9.321292738335,
              42.713400038631
            ],
            [
              9.3395766319473,
              42.728992493133
            ],
            [
              9.3444782391532,
              42.737810423971
            ],
            [
              9.339181189137,
              42.757928245104
            ],
            [
              9.339659755223,
              42.776011504848
            ],
            [
              9.3425627879236,
              42.79480205062
            ],
            [
              9.337774931012,
              42.804079605712
            ],
            [
              9.3251300488766,
              42.813332647957
            ],
            [
              9.3205438024435,
              42.823408404009
            ],
            [
              9.3110153694299,
              42.834679311095
            ],
            [
              9.3321782165813,
              42.85847260124
            ],
            [
              9.3401543659189,
              42.86548579796
            ],
            [
              9.3298530724956,
              42.872395584774
            ],
            [
              9.3262689644171,
              42.890315517283
            ],
            [
              9.322223463327,
              42.899632639571
            ],
            [
              9.353819146522,
              42.916234653141
            ],
            [
              9.3560395125976,
              42.927003469485
            ],
            [
              9.3488941969672,
              42.92930970895
            ],
            [
              9.358603665308,
              42.94633937258
            ],
            [
              9.348145476117,
              42.951797488202
            ],
            [
              9.3457582466882,
              42.957906943631
            ],
            [
              9.3505448749339,
              42.968270031649
            ],
            [
              9.3421423687094,
              42.978546347423
            ],
            [
              9.3443683003848,
              42.985532557301
            ],
            [
              9.3408728322382,
              42.994464747166
            ],
            [
              9.3590415875145,
              43.006725446764
            ],
            [
              9.3728616504645,
              43.004763668513
            ],
            [
              9.3799389872775,
              43.008193389796
            ],
            [
              9.4044764187179,
              43.005823769617
            ],
            [
              9.4136701217893,
              43.006531836354
            ],
            [
              9.4217576417904,
              43.011724041684
            ],
            [
              9.4308368621636,
              43.00272384855
            ],
            [
              9.4385130519137,
              43.000490605004
            ],
            [
              9.4512650406387,
              42.990315238432
            ],
            [
              9.4635582980189,
              42.986401078046
            ],
            [
              9.4540973301679,
              42.97307892537
            ],
            [
              9.4518764864235,
              42.964359720868
            ],
            [
              9.4576350561474,
              42.949057159617
            ],
            [
              9.4693212926991,
              42.936145296863
            ],
            [
              9.4676737905393,
              42.926807351234
            ],
            [
              9.4707921080556,
              42.918530856678
            ],
            [
              9.4738686210525,
              42.891155154693
            ],
            [
              9.4775278660748,
              42.881442813563
            ],
            [
              9.4730668331504,
              42.874128797384
            ],
            [
              9.4823148818977,
              42.864255300645
            ],
            [
              9.484277260776,
              42.850189178286
            ],
            [
              9.4807429643273,
              42.840776037517
            ],
            [
              9.485811781953,
              42.832427261458
            ],
            [
              9.4923850966255,
              42.805100268638
            ],
            [
              9.4910751495896,
              42.794970088426
            ],
            [
              9.472078926813,
              42.77053370509
            ],
            [
              9.4671143214594,
              42.760859543836
            ],
            [
              9.4685604695043,
              42.753048544421
            ],
            [
              9.4613913735206,
              42.740659678567
            ],
            [
              9.4624714977006,
              42.735628024208
            ],
            [
              9.4561949971884,
              42.718039470181
            ],
            [
              9.4523972721956,
              42.693495855424
            ],
            [
              9.4463872288639,
              42.686125087855
            ],
            [
              9.446189061428,
              42.673577272426
            ],
            [
              9.4491942129664,
              42.662239762642
            ],
            [
              9.4621969995941,
              42.640114758488
            ],
            [
              9.4748795080564,
              42.624836118132
            ],
            [
              9.5162152677147,
              42.583012642887
            ],
            [
              9.5256781330523,
              42.569008285145
            ],
            [
              9.5331957398235,
              42.545946562512
            ],
            [
              9.5344917834473,
              42.523982588056
            ],
            [
              9.5300256352103,
              42.5032908319
            ],
            [
              9.5312290693099,
              42.484415645223
            ],
            [
              9.5404627433876,
              42.461067005301
            ],
            [
              9.5408346733534,
              42.440809257975
            ],
            [
              9.5433951533138,
              42.428490625529
            ],
            [
              9.5386315892536,
              42.414019694859
            ],
            [
              9.532672682662,
              42.379967969125
            ],
            [
              9.5333702262866,
              42.366280319789
            ],
            [
              9.5372430000044,
              42.345209511976
            ],
            [
              9.5425016250046,
              42.328804455976
            ],
            [
              9.5504574617621,
              42.313214942216
            ],
            [
              9.5588293070416,
              42.285264635275
            ],
            [
              9.5592262719626,
              42.271734300663
            ],
            [
              9.5532133692255,
              42.241433836434
            ],
            [
              9.5528601939832,
              42.221257653469
            ],
            [
              9.557438978743,
              42.203166452024
            ],
            [
              9.5544087218137,
              42.157011398157
            ],
            [
              9.5566017971818,
              42.142142733245
            ],
            [
              9.5499796055405,
              42.104165986623
            ],
            [
              9.5313440713912,
              42.087489423987
            ],
            [
              9.5029309613993,
              42.055616031082
            ],
            [
              9.4647863427601,
              42.010954139334
            ],
            [
              9.4415478574917,
              41.98974926191
            ],
            [
              9.424255411615,
              41.971535954188
            ],
            [
              9.4125689541656,
              41.952475652276
            ],
            [
              9.4120532345487,
              41.939726645644
            ],
            [
              9.4149982681884,
              41.925108109695
            ],
            [
              9.4112475276506,
              41.91100050268
            ],
            [
              9.4020635619458,
              41.897155488353
            ],
            [
              9.3970189665487,
              41.874613277788
            ],
            [
              9.4022711090103,
              41.858701678165
            ]
          ]
        ]
      },
      "properties": {
        "code": "2B",
        "nom": "Haute-Corse",
        "population": 182887,
        "delitspour1000": "4,156",
        "nombremosque": 1,
        "benefRsa": 6146,
        "eoliennes": 30
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                4.1159657137304,
                47.12333578884
              ],
              [
                4.1075949297499,
                47.12933629579
              ],
              [
                4.1164080703238,
                47.135392236331
              ],
              [
                4.1150660580207,
                47.146161181367
              ],
              [
                4.1373797132578,
                47.14270894322
              ],
              [
                4.1818950614264,
                47.150507773033
              ],
              [
                4.1905772100816,
                47.145082142261
              ],
              [
                4.1871155557993,
                47.13364213251
              ],
              [
                4.1795832712336,
                47.125462503318
              ],
              [
                4.1656108131479,
                47.119768640258
              ],
              [
                4.1581472848576,
                47.121236525737
              ],
              [
                4.1474863428302,
                47.113936096115
              ],
              [
                4.1268220876418,
                47.130636271215
              ],
              [
                4.1159657137304,
                47.12333578884
              ]
            ]
          ],
          [
            [
              [
                4.293421363566,
                47.925673564836
              ],
              [
                4.3019415557721,
                47.939722254759
              ],
              [
                4.2989694743195,
                47.948924996538
              ],
              [
                4.3090008707807,
                47.961170613198
              ],
              [
                4.3512833685724,
                47.956581571066
              ],
              [
                4.3984909856202,
                47.963428889937
              ],
              [
                4.414350314367,
                47.968207562341
              ],
              [
                4.4275882888072,
                47.965925181567
              ],
              [
                4.4394035883612,
                47.958224025114
              ],
              [
                4.4490036624777,
                47.957151982179
              ],
              [
                4.482135195437,
                47.963341049725
              ],
              [
                4.4908013666212,
                47.967274674022
              ],
              [
                4.515002575681,
                47.966197509296
              ],
              [
                4.5315674205603,
                47.969936101313
              ],
              [
                4.5533088051577,
                47.967682340931
              ],
              [
                4.5599635426905,
                47.971424167131
              ],
              [
                4.5552078832713,
                47.985773515235
              ],
              [
                4.5455985119462,
                47.98829443758
              ],
              [
                4.5358174351871,
                48.001208262
              ],
              [
                4.5494258066747,
                48.004460028844
              ],
              [
                4.554850985404,
                48.012319062721
              ],
              [
                4.5673230090597,
                48.018739473733
              ],
              [
                4.5718848731394,
                48.025129476681
              ],
              [
                4.5826732520974,
                48.029462774305
              ],
              [
                4.612404633886,
                48.030241950581
              ],
              [
                4.624062923698,
                48.025381425202
              ],
              [
                4.6396252873332,
                48.025481582141
              ],
              [
                4.6633228351061,
                48.020020302718
              ],
              [
                4.6732270680042,
                48.015055346449
              ],
              [
                4.6985527278028,
                48.023678273472
              ],
              [
                4.7042329730873,
                48.020235152969
              ],
              [
                4.7199460898645,
                48.008895516311
              ],
              [
                4.7492966391968,
                48.004246587867
              ],
              [
                4.7890783024494,
                48.007828564444
              ],
              [
                4.7932639090855,
                47.996944239514
              ],
              [
                4.8091903248533,
                47.990087845923
              ],
              [
                4.7946342537178,
                47.983195170106
              ],
              [
                4.7865152517638,
                47.964201285162
              ],
              [
                4.8194243190699,
                47.960358692838
              ],
              [
                4.8410086317455,
                47.96075264808
              ],
              [
                4.8520473231564,
                47.956242158653
              ],
              [
                4.8660942377505,
                47.940508774674
              ],
              [
                4.8501722212977,
                47.929537855578
              ],
              [
                4.8467200449818,
                47.924483556015
              ],
              [
                4.8289423070305,
                47.915124211024
              ],
              [
                4.8340343917843,
                47.906781834116
              ],
              [
                4.8569990661674,
                47.89585146766
              ],
              [
                4.8693396784934,
                47.917449806377
              ],
              [
                4.8944964422884,
                47.922840464291
              ],
              [
                4.9018509649222,
                47.921284863427
              ],
              [
                4.9190920876169,
                47.894720464516
              ],
              [
                4.9281731599986,
                47.88687190538
              ],
              [
                4.9282357147154,
                47.871123778488
              ],
              [
                4.9540995648128,
                47.866767159307
              ],
              [
                4.9603856383733,
                47.857358065698
              ],
              [
                4.9622497289156,
                47.839702282837
              ],
              [
                4.968346106104,
                47.831938726344
              ],
              [
                4.9846956660801,
                47.828868610045
              ],
              [
                4.9941266376295,
                47.819746154602
              ],
              [
                4.9906198706093,
                47.80709265473
              ],
              [
                4.9824009031204,
                47.800350982387
              ],
              [
                4.9635761932156,
                47.795106816319
              ],
              [
                4.9564491304036,
                47.790048428128
              ],
              [
                4.9183050956308,
                47.777311690703
              ],
              [
                4.9177846968097,
                47.766614764213
              ],
              [
                4.9307398007716,
                47.761333148797
              ],
              [
                4.9496050950308,
                47.765006218324
              ],
              [
                4.9589919593761,
                47.761870409828
              ],
              [
                4.9598932138082,
                47.7544709258
              ],
              [
                4.9707033208711,
                47.727636116595
              ],
              [
                4.9570644317617,
                47.709406346338
              ],
              [
                4.9541385723681,
                47.701462932734
              ],
              [
                4.9791098413952,
                47.687763691185
              ],
              [
                4.9922739605095,
                47.688315326064
              ],
              [
                5.0043602413418,
                47.700727315079
              ],
              [
                5.0262670139789,
                47.709492979045
              ],
              [
                5.0327622841789,
                47.704076235268
              ],
              [
                5.0327945086565,
                47.692333079799
              ],
              [
                5.0606448449781,
                47.694789812394
              ],
              [
                5.0435832118687,
                47.676513563124
              ],
              [
                5.0578872394562,
                47.668305887057
              ],
              [
                5.0703648554737,
                47.666753831482
              ],
              [
                5.0849169001544,
                47.657252517205
              ],
              [
                5.1017263997014,
                47.659487818247
              ],
              [
                5.1088743414175,
                47.649687715835
              ],
              [
                5.133419901405,
                47.650731358395
              ],
              [
                5.1562768819218,
                47.668257175471
              ],
              [
                5.1613411137784,
                47.679935696643
              ],
              [
                5.1793190058471,
                47.679306571337
              ],
              [
                5.173772704787,
                47.661555338996
              ],
              [
                5.1735955223689,
                47.652564238401
              ],
              [
                5.1885815533539,
                47.6495597478
              ],
              [
                5.2153019734879,
                47.638776585158
              ],
              [
                5.2275663747918,
                47.630461719186
              ],
              [
                5.2392323119847,
                47.616130998734
              ],
              [
                5.2505435136739,
                47.622148375054
              ],
              [
                5.2586017166912,
                47.622216096373
              ],
              [
                5.243335051832,
                47.603257395562
              ],
              [
                5.2395818018336,
                47.595735741686
              ],
              [
                5.248821805001,
                47.588304655805
              ],
              [
                5.2529216592041,
                47.576950536437
              ],
              [
                5.2779061705699,
                47.581361382365
              ],
              [
                5.2787977512377,
                47.590248985309
              ],
              [
                5.2948617680378,
                47.599202098387
              ],
              [
                5.2998385942923,
                47.604925842894
              ],
              [
                5.3223487268523,
                47.612359526718
              ],
              [
                5.3399999375357,
                47.609069594411
              ],
              [
                5.3421656596273,
                47.597942682873
              ],
              [
                5.3547654342374,
                47.591360294209
              ],
              [
                5.3706351264232,
                47.604807955246
              ],
              [
                5.3740797661661,
                47.604538026913
              ],
              [
                5.3852312899053,
                47.601140823119
              ],
              [
                5.3894441218081,
                47.595134574398
              ],
              [
                5.3998073050821,
                47.597157046249
              ],
              [
                5.4258591112658,
                47.632112058174
              ],
              [
                5.4402012469891,
                47.630289549893
              ],
              [
                5.4504095136146,
                47.621578316965
              ],
              [
                5.4708011189814,
                47.60840488799
              ],
              [
                5.4775419389678,
                47.608718587184
              ],
              [
                5.4869927565051,
                47.572614727121
              ],
              [
                5.4807298338814,
                47.565874912272
              ],
              [
                5.4912891652093,
                47.563383076133
              ],
              [
                5.4968620391106,
                47.544147146933
              ],
              [
                5.4864051857621,
                47.526968522713
              ],
              [
                5.471682031464,
                47.527457320728
              ],
              [
                5.4666287960975,
                47.518366663603
              ],
              [
                5.4470648391405,
                47.496253627466
              ],
              [
                5.436098919597,
                47.491551861741
              ],
              [
                5.4308254578991,
                47.496179401634
              ],
              [
                5.3992090935811,
                47.499013426534
              ],
              [
                5.3871419907654,
                47.480832872709
              ],
              [
                5.4076480358261,
                47.477230704271
              ],
              [
                5.3765928972898,
                47.464682433056
              ],
              [
                5.3798420907935,
                47.45069028099
              ],
              [
                5.4064942956926,
                47.461823832729
              ],
              [
                5.4224114151039,
                47.44902392543
              ],
              [
                5.4391961289873,
                47.450105287536
              ],
              [
                5.4402995308717,
                47.445125187341
              ],
              [
                5.4310568615775,
                47.435650590036
              ],
              [
                5.4304266875956,
                47.421353865918
              ],
              [
                5.4445415221439,
                47.404097440444
              ],
              [
                5.4349837698486,
                47.400482033333
              ],
              [
                5.4323534928092,
                47.392856521473
              ],
              [
                5.4513935817246,
                47.384063497064
              ],
              [
                5.4806794026958,
                47.394073385973
              ],
              [
                5.4969499668019,
                47.390119714582
              ],
              [
                5.4879992758037,
                47.362006675506
              ],
              [
                5.4947014198327,
                47.345394219096
              ],
              [
                5.4927880062007,
                47.332077409303
              ],
              [
                5.4871633423792,
                47.326514768548
              ],
              [
                5.4771934620117,
                47.329779404199
              ],
              [
                5.4722371211403,
                47.316973006824
              ],
              [
                5.5019256795616,
                47.313102520209
              ],
              [
                5.5185372800929,
                47.304183948833
              ],
              [
                5.5047334725938,
                47.285657287521
              ],
              [
                5.4882803585045,
                47.289348426854
              ],
              [
                5.4878159593397,
                47.273607055705
              ],
              [
                5.482780235717,
                47.259970325137
              ],
              [
                5.4859533367952,
                47.250970971888
              ],
              [
                5.4825740827176,
                47.239584618039
              ],
              [
                5.4740109657345,
                47.231084915051
              ],
              [
                5.4766297124338,
                47.216024943562
              ],
              [
                5.4643347179423,
                47.206861190005
              ],
              [
                5.4457479059859,
                47.199576602968
              ],
              [
                5.4566619914314,
                47.190796043521
              ],
              [
                5.4592047704778,
                47.183435455006
              ],
              [
                5.4486451687776,
                47.159661416687
              ],
              [
                5.4382559628791,
                47.152333633289
              ],
              [
                5.4399772978447,
                47.144398007823
              ],
              [
                5.4283101123348,
                47.136621644761
              ],
              [
                5.4153451965153,
                47.132557909226
              ],
              [
                5.4087066418684,
                47.126073355091
              ],
              [
                5.4106582397347,
                47.11409272206
              ],
              [
                5.3982339482394,
                47.105510493744
              ],
              [
                5.3978169308403,
                47.100525534369
              ],
              [
                5.3860185078759,
                47.081977874128
              ],
              [
                5.3725869511884,
                47.077870600995
              ],
              [
                5.3594637559913,
                47.080823441413
              ],
              [
                5.3451278583998,
                47.076663031205
              ],
              [
                5.3325351466998,
                47.076541416218
              ],
              [
                5.3221915911089,
                47.072984795346
              ],
              [
                5.3121920596159,
                47.059984591599
              ],
              [
                5.3024269639937,
                47.061073135449
              ],
              [
                5.2829162271068,
                47.045235150189
              ],
              [
                5.2753306362771,
                47.026929459444
              ],
              [
                5.3100551650812,
                47.018024391224
              ],
              [
                5.297162784202,
                47.001432869677
              ],
              [
                5.2788707280445,
                46.999021373722
              ],
              [
                5.2719597499439,
                46.989340780889
              ],
              [
                5.2552323566327,
                46.97988727181
              ],
              [
                5.222007205358,
                46.988850972047
              ],
              [
                5.2122009820622,
                46.980140542671
              ],
              [
                5.2017813381481,
                46.983326611317
              ],
              [
                5.1972758420992,
                46.975291865503
              ],
              [
                5.1818630583666,
                46.974819779244
              ],
              [
                5.1662277175338,
                46.963864021823
              ],
              [
                5.1539020941237,
                46.967047351723
              ],
              [
                5.1201248077624,
                46.961029911723
              ],
              [
                5.1062786065898,
                46.955761650391
              ],
              [
                5.091280254188,
                46.960486667311
              ],
              [
                5.0762580997236,
                46.959384630758
              ],
              [
                5.0493062298333,
                46.981583286536
              ],
              [
                5.029690591636,
                46.980415217927
              ],
              [
                5.0149319080657,
                46.970429189276
              ],
              [
                5.0038049703297,
                46.971924471926
              ],
              [
                4.9955606754196,
                46.962632349404
              ],
              [
                4.9838246080058,
                46.959257530031
              ],
              [
                4.9663068500571,
                46.964645241877
              ],
              [
                4.9153459886512,
                46.967837298674
              ],
              [
                4.8960727386751,
                46.966242884867
              ],
              [
                4.8911121323268,
                46.95730744614
              ],
              [
                4.8939105794766,
                46.951169780374
              ],
              [
                4.8706343014911,
                46.948175221605
              ],
              [
                4.820804799072,
                46.937879459731
              ],
              [
                4.8171879753418,
                46.933547033621
              ],
              [
                4.7893325112709,
                46.932120435279
              ],
              [
                4.7903875415492,
                46.924514772268
              ],
              [
                4.7805526718426,
                46.918177501855
              ],
              [
                4.755745504593,
                46.917128050876
              ],
              [
                4.7445121157005,
                46.924429479612
              ],
              [
                4.7281282516843,
                46.919371945217
              ],
              [
                4.7157197734085,
                46.906915091091
              ],
              [
                4.6854836804585,
                46.900857518168
              ],
              [
                4.6645220130405,
                46.914155683564
              ],
              [
                4.6791588393663,
                46.929068279952
              ],
              [
                4.6569598053577,
                46.936764234751
              ],
              [
                4.6501223580743,
                46.941267826803
              ],
              [
                4.6232301075255,
                46.940816636137
              ],
              [
                4.616769673462,
                46.948055041985
              ],
              [
                4.6008999978435,
                46.949880356169
              ],
              [
                4.5938958784994,
                46.953670496961
              ],
              [
                4.5878756545429,
                46.968824868997
              ],
              [
                4.5736638505553,
                46.973382702092
              ],
              [
                4.5673645823976,
                46.98318060934
              ],
              [
                4.5657682538626,
                46.992256111598
              ],
              [
                4.5538139975314,
                46.991013803036
              ],
              [
                4.547442238525,
                46.997604742791
              ],
              [
                4.5558845679183,
                47.002954644765
              ],
              [
                4.5578215365234,
                47.016393866099
              ],
              [
                4.5548142345394,
                47.020051751791
              ],
              [
                4.5338129586369,
                47.013323154173
              ],
              [
                4.5122367617327,
                47.011776209649
              ],
              [
                4.4985631523779,
                47.02022379563
              ],
              [
                4.4936926700522,
                47.028369739748
              ],
              [
                4.4715411926743,
                47.030673058536
              ],
              [
                4.4681203186819,
                47.035686379298
              ],
              [
                4.4537506045924,
                47.04180142916
              ],
              [
                4.4283082865798,
                47.039783034533
              ],
              [
                4.4206973571364,
                47.046634951721
              ],
              [
                4.4062794640699,
                47.049999940669
              ],
              [
                4.4063488067658,
                47.07060711622
              ],
              [
                4.4157833418885,
                47.077861293046
              ],
              [
                4.4054898133936,
                47.081646388237
              ],
              [
                4.3829837819377,
                47.083083950889
              ],
              [
                4.3714566907977,
                47.079598781968
              ],
              [
                4.3635732332048,
                47.066374377816
              ],
              [
                4.3534159033672,
                47.064032857417
              ],
              [
                4.3439655990464,
                47.073692424539
              ],
              [
                4.349381880875,
                47.096949934296
              ],
              [
                4.3355221112478,
                47.103011426888
              ],
              [
                4.3118196731769,
                47.10394456974
              ],
              [
                4.2971314772637,
                47.113149658628
              ],
              [
                4.2855587322379,
                47.108441791717
              ],
              [
                4.2733576197273,
                47.10819770186
              ],
              [
                4.2596068200223,
                47.11432257803
              ],
              [
                4.2509697445122,
                47.130843482768
              ],
              [
                4.2411717039819,
                47.132491412618
              ],
              [
                4.2432777093124,
                47.14073455227
              ],
              [
                4.2168452275486,
                47.146672749611
              ],
              [
                4.2098316194928,
                47.155410810959
              ],
              [
                4.2082785647862,
                47.163849326423
              ],
              [
                4.2146280483505,
                47.183137693116
              ],
              [
                4.2306617272065,
                47.198946708221
              ],
              [
                4.2200708686147,
                47.215959778412
              ],
              [
                4.1912177541397,
                47.237009867039
              ],
              [
                4.1878705703316,
                47.244612665923
              ],
              [
                4.1780483980403,
                47.250291504021
              ],
              [
                4.1660112560791,
                47.249424576003
              ],
              [
                4.1354786120349,
                47.237397237251
              ],
              [
                4.1247217853652,
                47.251556580615
              ],
              [
                4.1245462831537,
                47.262131056758
              ],
              [
                4.1164294263749,
                47.276383540489
              ],
              [
                4.1145884091898,
                47.292607276243
              ],
              [
                4.122836939735,
                47.303952068916
              ],
              [
                4.1141074337361,
                47.307955306705
              ],
              [
                4.1303927866159,
                47.320210675601
              ],
              [
                4.1239214987083,
                47.330478638489
              ],
              [
                4.1248963853468,
                47.335861208174
              ],
              [
                4.1420339517931,
                47.353410405176
              ],
              [
                4.1299388379566,
                47.354003126117
              ],
              [
                4.1243850511743,
                47.344327328215
              ],
              [
                4.1134743533021,
                47.334688318727
              ],
              [
                4.1060805347332,
                47.339255135806
              ],
              [
                4.1076656259598,
                47.345914946222
              ],
              [
                4.0979411189686,
                47.35534975233
              ],
              [
                4.1059989277452,
                47.362739565985
              ],
              [
                4.1066314424005,
                47.375866245463
              ],
              [
                4.0879063679202,
                47.372327483396
              ],
              [
                4.0800982417729,
                47.376100519928
              ],
              [
                4.076217185536,
                47.383480206705
              ],
              [
                4.0893960395146,
                47.391514100923
              ],
              [
                4.0883019049265,
                47.396524552433
              ],
              [
                4.0669171960011,
                47.4010746983
              ],
              [
                4.0660574486622,
                47.407250411398
              ],
              [
                4.0840420381257,
                47.420205763943
              ],
              [
                4.0837377590715,
                47.433167159236
              ],
              [
                4.0868811850708,
                47.440047425464
              ],
              [
                4.098632645676,
                47.430467101809
              ],
              [
                4.119422572118,
                47.4435543317
              ],
              [
                4.1302384089683,
                47.47136897141
              ],
              [
                4.1172300361255,
                47.479171921881
              ],
              [
                4.1194715157186,
                47.488629204748
              ],
              [
                4.1123998387742,
                47.503493470873
              ],
              [
                4.115506867574,
                47.515006715898
              ],
              [
                4.1382576488316,
                47.519937045083
              ],
              [
                4.1498240051344,
                47.532199170987
              ],
              [
                4.1459574437946,
                47.539592193245
              ],
              [
                4.1626364264347,
                47.553276326624
              ],
              [
                4.17512941132,
                47.553259149829
              ],
              [
                4.1864656579161,
                47.56972399776
              ],
              [
                4.1871532602349,
                47.581813369291
              ],
              [
                4.1971614419031,
                47.598027677294
              ],
              [
                4.2130600290023,
                47.607557117092
              ],
              [
                4.2123839956801,
                47.627967476689
              ],
              [
                4.2239953304579,
                47.632638188244
              ],
              [
                4.2273012263527,
                47.64140122463
              ],
              [
                4.2491192448708,
                47.659488252158
              ],
              [
                4.2261470792391,
                47.670704885971
              ],
              [
                4.2266142169595,
                47.678209364711
              ],
              [
                4.2430575387781,
                47.677941167167
              ],
              [
                4.2515508653348,
                47.6821426642
              ],
              [
                4.2684282857929,
                47.681413465978
              ],
              [
                4.2722767556566,
                47.686500050892
              ],
              [
                4.2618319572728,
                47.694230622506
              ],
              [
                4.2675045906304,
                47.705472895566
              ],
              [
                4.2496038685001,
                47.715439608463
              ],
              [
                4.2404739373478,
                47.717343411719
              ],
              [
                4.2394621052188,
                47.72442226378
              ],
              [
                4.2581695167841,
                47.727399313731
              ],
              [
                4.2634454695839,
                47.742335646049
              ],
              [
                4.28109760531,
                47.738129038962
              ],
              [
                4.28965009284,
                47.733173292755
              ],
              [
                4.3021591344662,
                47.741463998395
              ],
              [
                4.3103438146779,
                47.751717410306
              ],
              [
                4.3272829796155,
                47.753407951941
              ],
              [
                4.3324690785962,
                47.756513140603
              ],
              [
                4.3303640093148,
                47.773237825007
              ],
              [
                4.3346307974616,
                47.778473219282
              ],
              [
                4.3272273544323,
                47.785662261899
              ],
              [
                4.3403007872795,
                47.799562997605
              ],
              [
                4.3206382167009,
                47.811099221491
              ],
              [
                4.3250090174552,
                47.819031413937
              ],
              [
                4.3232258596381,
                47.832017055085
              ],
              [
                4.3287631926776,
                47.840693773361
              ],
              [
                4.324395709202,
                47.847155648804
              ],
              [
                4.3008005675725,
                47.848768970558
              ],
              [
                4.2886856732776,
                47.846408481652
              ],
              [
                4.2730539993782,
                47.839246861583
              ],
              [
                4.2609955625806,
                47.843854295691
              ],
              [
                4.2645564699717,
                47.873707495084
              ],
              [
                4.2473760643948,
                47.873765353106
              ],
              [
                4.2470482432436,
                47.877638329975
              ],
              [
                4.2685683333715,
                47.888372607038
              ],
              [
                4.2773336988144,
                47.8994494369
              ],
              [
                4.2989793260024,
                47.900526985379
              ],
              [
                4.3111459638235,
                47.903921001729
              ],
              [
                4.3033132488763,
                47.92172991059
              ],
              [
                4.293421363566,
                47.925673564836
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "21",
        "nom": "Côte-d'Or",
        "population": 535078,
        "delitspour1000": "4,226",
        "nombremosque": 24,
        "parentsEtrangers": "77,1",
        "benefRsa": 18694,
        "eoliennes": 148
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.8004899840029,
              45.298357121937
            ],
            [
              4.8588038161436,
              45.308951945608
            ],
            [
              4.8586100908504,
              45.29851618808
            ],
            [
              4.8802416809906,
              45.2974566456
            ],
            [
              4.9012637445124,
              45.310007436763
            ],
            [
              4.9284388130601,
              45.322951204602
            ],
            [
              4.9475761450964,
              45.328597843474
            ],
            [
              4.9595854101246,
              45.328918743796
            ],
            [
              4.9891862167152,
              45.344042230781
            ],
            [
              5.0096495089118,
              45.342230172467
            ],
            [
              5.0205931755981,
              45.319428254027
            ],
            [
              5.0543224224169,
              45.319051008949
            ],
            [
              5.0591074575312,
              45.313623430692
            ],
            [
              5.0754112531922,
              45.281827518251
            ],
            [
              5.0910708813282,
              45.286770048947
            ],
            [
              5.1122870741104,
              45.289758183582
            ],
            [
              5.1255573347389,
              45.298558532685
            ],
            [
              5.1385253524837,
              45.296216167914
            ],
            [
              5.131354968047,
              45.286531093128
            ],
            [
              5.1301773389705,
              45.267206969934
            ],
            [
              5.1256631119512,
              45.26209101661
            ],
            [
              5.1214293208332,
              45.248334945441
            ],
            [
              5.1314466193386,
              45.243321691602
            ],
            [
              5.1414710173246,
              45.245014424938
            ],
            [
              5.1519149109729,
              45.255479740823
            ],
            [
              5.156679209512,
              45.247392718624
            ],
            [
              5.1765458684418,
              45.248399245404
            ],
            [
              5.1853651989338,
              45.230539570693
            ],
            [
              5.2017183191657,
              45.217400467946
            ],
            [
              5.1787087939172,
              45.216974316616
            ],
            [
              5.1674873703836,
              45.210221645731
            ],
            [
              5.1648771309633,
              45.198450714497
            ],
            [
              5.1694383011753,
              45.195274384832
            ],
            [
              5.1770838978704,
              45.179740593678
            ],
            [
              5.1885746038112,
              45.171264013519
            ],
            [
              5.191700424762,
              45.154576461872
            ],
            [
              5.1868446925336,
              45.145105728237
            ],
            [
              5.187438201276,
              45.120832355985
            ],
            [
              5.176310666063,
              45.107822675236
            ],
            [
              5.1628941235196,
              45.098426086784
            ],
            [
              5.1564001681613,
              45.082786398559
            ],
            [
              5.1427276255164,
              45.080729882348
            ],
            [
              5.1472802619812,
              45.072549217506
            ],
            [
              5.1634833877489,
              45.06599819123
            ],
            [
              5.1793794005119,
              45.083305819247
            ],
            [
              5.1833351592236,
              45.084878534921
            ],
            [
              5.2082043994035,
              45.084220045879
            ],
            [
              5.2261246446959,
              45.07918137092
            ],
            [
              5.2303765814513,
              45.072500129754
            ],
            [
              5.2445180483487,
              45.066971920702
            ],
            [
              5.2483439776641,
              45.060930865772
            ],
            [
              5.2662685281178,
              45.059810966567
            ],
            [
              5.2918748938355,
              45.063928670512
            ],
            [
              5.300644528137,
              45.062587690778
            ],
            [
              5.3074190056319,
              45.053404696516
            ],
            [
              5.3170937420842,
              45.051443710709
            ],
            [
              5.334790878464,
              45.060805233498
            ],
            [
              5.3431185907943,
              45.059779112552
            ],
            [
              5.3437881719066,
              45.051703899543
            ],
            [
              5.3507319764542,
              45.047203142466
            ],
            [
              5.3713895477672,
              45.0438762945
            ],
            [
              5.3883853627744,
              45.036136409191
            ],
            [
              5.3972657713912,
              45.038330859223
            ],
            [
              5.3963847959196,
              45.044550028371
            ],
            [
              5.4088425346067,
              45.044735225212
            ],
            [
              5.417792323715,
              45.048366081468
            ],
            [
              5.4257108891404,
              45.05626514626
            ],
            [
              5.4497902629449,
              45.070787231096
            ],
            [
              5.4594178157294,
              45.084353288913
            ],
            [
              5.4683829045497,
              45.088079984604
            ],
            [
              5.482960100439,
              45.083814042465
            ],
            [
              5.4888280786289,
              45.073091937552
            ],
            [
              5.4773643019715,
              45.072025935069
            ],
            [
              5.4662213802966,
              45.053689072581
            ],
            [
              5.4648455525186,
              45.045940903248
            ],
            [
              5.4836143274431,
              45.022786934023
            ],
            [
              5.4804589043527,
              45.013865278458
            ],
            [
              5.4884745473303,
              45.001223430328
            ],
            [
              5.4887669714919,
              44.990232279193
            ],
            [
              5.4776757133828,
              44.966755171398
            ],
            [
              5.4836507785865,
              44.922286913688
            ],
            [
              5.480315188243,
              44.896807670531
            ],
            [
              5.4700479073037,
              44.879032502847
            ],
            [
              5.4750760812667,
              44.867592273489
            ],
            [
              5.46606801453,
              44.841221723762
            ],
            [
              5.4637975227518,
              44.825974431714
            ],
            [
              5.4837019416551,
              44.823184908254
            ],
            [
              5.4767736644408,
              44.81563556844
            ],
            [
              5.4760803164135,
              44.809091676298
            ],
            [
              5.4624328748393,
              44.802667812109
            ],
            [
              5.4641599003636,
              44.792424769087
            ],
            [
              5.4949322129004,
              44.782627931299
            ],
            [
              5.5196122819033,
              44.777219870899
            ],
            [
              5.5555318108241,
              44.771216999345
            ],
            [
              5.5441427350362,
              44.788922680811
            ],
            [
              5.549761645604,
              44.794528877128
            ],
            [
              5.5820961321703,
              44.777685016074
            ],
            [
              5.5838826069396,
              44.765344858953
            ],
            [
              5.5899964337532,
              44.761617860814
            ],
            [
              5.6270859672246,
              44.752460799598
            ],
            [
              5.6315009068421,
              44.738825957841
            ],
            [
              5.64398076712,
              44.73157541049
            ],
            [
              5.6470991996242,
              44.724099914037
            ],
            [
              5.6682357861974,
              44.724714054907
            ],
            [
              5.6987857560456,
              44.722066559691
            ],
            [
              5.7052357370695,
              44.727685225843
            ],
            [
              5.7193970657268,
              44.71376644489
            ],
            [
              5.7417693832802,
              44.710673317311
            ],
            [
              5.7451471861505,
              44.703727044556
            ],
            [
              5.7590439457666,
              44.696067584965
            ],
            [
              5.7853372486465,
              44.700829290463
            ],
            [
              5.8014699513145,
              44.706777613167
            ],
            [
              5.8271099006445,
              44.700288039663
            ],
            [
              5.8294720463131,
              44.692425284611
            ],
            [
              5.8246351320469,
              44.685278024621
            ],
            [
              5.8064797515589,
              44.677181975541
            ],
            [
              5.790623681681,
              44.653292905762
            ],
            [
              5.7666311555846,
              44.655266851559
            ],
            [
              5.7539428619931,
              44.662710867987
            ],
            [
              5.748257168346,
              44.655090844363
            ],
            [
              5.7511047252626,
              44.648611621404
            ],
            [
              5.7360131774513,
              44.649510358823
            ],
            [
              5.7358025109287,
              44.640415596891
            ],
            [
              5.7260329075738,
              44.639393999475
            ],
            [
              5.6853621391431,
              44.649596345809
            ],
            [
              5.6593597358465,
              44.650663453626
            ],
            [
              5.6544567435058,
              44.655393257217
            ],
            [
              5.6417177024073,
              44.65107732796
            ],
            [
              5.6403729488497,
              44.633825447345
            ],
            [
              5.6496311179712,
              44.617884606434
            ],
            [
              5.6475161192881,
              44.612808143065
            ],
            [
              5.6274790368545,
              44.594965971046
            ],
            [
              5.6257986898421,
              44.586275880643
            ],
            [
              5.6070190339977,
              44.568348424227
            ],
            [
              5.5997980521304,
              44.553941311612
            ],
            [
              5.5972525723721,
              44.543273639184
            ],
            [
              5.6149927369414,
              44.532817987531
            ],
            [
              5.630802337654,
              44.531597332459
            ],
            [
              5.6531427146706,
              44.514831250068
            ],
            [
              5.6645041823511,
              44.501894932126
            ],
            [
              5.6526558596497,
              44.499962952183
            ],
            [
              5.6297876074114,
              44.501187043038
            ],
            [
              5.618123153083,
              44.474812657295
            ],
            [
              5.6036448321495,
              44.465542446118
            ],
            [
              5.5792007067241,
              44.471686064395
            ],
            [
              5.5703041972283,
              44.476754841678
            ],
            [
              5.5624010016641,
              44.474853738869
            ],
            [
              5.5447008857046,
              44.482537575762
            ],
            [
              5.5133416154223,
              44.491125991349
            ],
            [
              5.479877306738,
              44.491243239804
            ],
            [
              5.4735960069771,
              44.498568843239
            ],
            [
              5.4653894088793,
              44.500459334817
            ],
            [
              5.4569314822479,
              44.496235778443
            ],
            [
              5.463575747607,
              44.480512627606
            ],
            [
              5.4587312484193,
              44.466142946772
            ],
            [
              5.4640494490072,
              44.457360043051
            ],
            [
              5.4644276220352,
              44.447890408205
            ],
            [
              5.496803836369,
              44.438493779503
            ],
            [
              5.4936153322299,
              44.428217653577
            ],
            [
              5.4865672289608,
              44.429310933518
            ],
            [
              5.4764846044694,
              44.419722107544
            ],
            [
              5.4507590745848,
              44.430873511581
            ],
            [
              5.4338571063313,
              44.433224179441
            ],
            [
              5.4185330627929,
              44.424944615913
            ],
            [
              5.422756262532,
              44.416770650479
            ],
            [
              5.434309527365,
              44.409479438419
            ],
            [
              5.4430201938352,
              44.391233871018
            ],
            [
              5.4423608910239,
              44.381659799255
            ],
            [
              5.4351137570212,
              44.37707693084
            ],
            [
              5.4344154288602,
              44.36962646376
            ],
            [
              5.4629658844687,
              44.367052180352
            ],
            [
              5.4679488171996,
              44.352676849629
            ],
            [
              5.4824720338071,
              44.349605960068
            ],
            [
              5.4930701487957,
              44.337173950444
            ],
            [
              5.5135447821727,
              44.347485508397
            ],
            [
              5.5398634524253,
              44.342637263297
            ],
            [
              5.5373655887575,
              44.333521248655
            ],
            [
              5.5491114786402,
              44.330396465555
            ],
            [
              5.5738598675994,
              44.333940368466
            ],
            [
              5.5868917748447,
              44.332226010325
            ],
            [
              5.6171385399181,
              44.332478533509
            ],
            [
              5.6269152221035,
              44.334764537726
            ],
            [
              5.6315982014425,
              44.32830571643
            ],
            [
              5.6138324838557,
              44.316139439714
            ],
            [
              5.6079060792589,
              44.306669558882
            ],
            [
              5.6377527896773,
              44.29968895608
            ],
            [
              5.6332659808825,
              44.282119871541
            ],
            [
              5.6467811994158,
              44.267088776629
            ],
            [
              5.6752912853484,
              44.275852112257
            ],
            [
              5.6865615009091,
              44.266921017987
            ],
            [
              5.6753443584242,
              44.258110993228
            ],
            [
              5.6726438758953,
              44.245665214395
            ],
            [
              5.6813154432669,
              44.232890979586
            ],
            [
              5.6760207339785,
              44.212146588276
            ],
            [
              5.6864432188187,
              44.197157935534
            ],
            [
              5.6760358633349,
              44.191428664539
            ],
            [
              5.6515861240321,
              44.189572730485
            ],
            [
              5.6522929653271,
              44.185411124162
            ],
            [
              5.6436911140627,
              44.172641360479
            ],
            [
              5.6469586945897,
              44.166287300205
            ],
            [
              5.6626325073952,
              44.16686426058
            ],
            [
              5.6827091460673,
              44.163217461898
            ],
            [
              5.6786089032088,
              44.146091300526
            ],
            [
              5.6679496679388,
              44.148873764468
            ],
            [
              5.65770809672,
              44.147529418679
            ],
            [
              5.6311399737751,
              44.150576623162
            ],
            [
              5.6395945851702,
              44.167581524
            ],
            [
              5.6162160024561,
              44.181069460057
            ],
            [
              5.6020555143928,
              44.191496296599
            ],
            [
              5.5962058311559,
              44.187648959302
            ],
            [
              5.5761919645305,
              44.188037035508
            ],
            [
              5.5643706424911,
              44.170901794193
            ],
            [
              5.5829754941585,
              44.157625987959
            ],
            [
              5.5695065039633,
              44.148099520416
            ],
            [
              5.5513311325106,
              44.149791971653
            ],
            [
              5.5439848356077,
              44.136389912148
            ],
            [
              5.5329588653579,
              44.130053463575
            ],
            [
              5.5197573760784,
              44.126616149895
            ],
            [
              5.5048243383524,
              44.116270397321
            ],
            [
              5.4987864391759,
              44.115716677493
            ],
            [
              5.4547151332789,
              44.119226133429
            ],
            [
              5.4492512328028,
              44.124591922526
            ],
            [
              5.4472805287304,
              44.135994708471
            ],
            [
              5.4369459430348,
              44.142812945214
            ],
            [
              5.4357509062277,
              44.152249650949
            ],
            [
              5.3905234132549,
              44.1534251894
            ],
            [
              5.3832371388833,
              44.155284811726
            ],
            [
              5.3864765223646,
              44.1769070133
            ],
            [
              5.3830416498971,
              44.198796138337
            ],
            [
              5.3845269211658,
              44.20104933819
            ],
            [
              5.3540415690827,
              44.213431973111
            ],
            [
              5.336784174291,
              44.20389269414
            ],
            [
              5.3183111725222,
              44.209869282984
            ],
            [
              5.3037098672957,
              44.206011143284
            ],
            [
              5.2911909842134,
              44.214933969307
            ],
            [
              5.2565049248197,
              44.230055506
            ],
            [
              5.2404514324301,
              44.230825507739
            ],
            [
              5.2381459551442,
              44.213233409548
            ],
            [
              5.2046138867346,
              44.215116852293
            ],
            [
              5.1735074807317,
              44.2219664549
            ],
            [
              5.1548996659898,
              44.230941470835
            ],
            [
              5.1516592069293,
              44.23772859876
            ],
            [
              5.1615500254058,
              44.245588822308
            ],
            [
              5.1571364176122,
              44.267225182448
            ],
            [
              5.1474716753569,
              44.269525185767
            ],
            [
              5.1497309928727,
              44.282086494235
            ],
            [
              5.1672051148975,
              44.292050008972
            ],
            [
              5.1726899918655,
              44.309391119528
            ],
            [
              5.1664353137721,
              44.314853482023
            ],
            [
              5.1524172226752,
              44.307683682999
            ],
            [
              5.1546757377907,
              44.301844910979
            ],
            [
              5.1214657621262,
              44.287480229186
            ],
            [
              5.1078521429136,
              44.280398126188
            ],
            [
              5.0765144884036,
              44.284084169033
            ],
            [
              5.0605607254823,
              44.308137131551
            ],
            [
              5.0384946811178,
              44.299637776655
            ],
            [
              5.0218466033995,
              44.295587171529
            ],
            [
              5.0069116240356,
              44.287554489231
            ],
            [
              4.9814530758182,
              44.284833474448
            ],
            [
              4.9598922113805,
              44.274192870866
            ],
            [
              4.935492573713,
              44.264314996193
            ],
            [
              4.9175325402435,
              44.260348620291
            ],
            [
              4.9060631576886,
              44.260294315057
            ],
            [
              4.8961483935046,
              44.264431155121
            ],
            [
              4.8737563985909,
              44.25925825523
            ],
            [
              4.845368774508,
              44.241470533268
            ],
            [
              4.8266501157802,
              44.228322408488
            ],
            [
              4.814089831112,
              44.232314781729
            ],
            [
              4.8126620207402,
              44.257716427139
            ],
            [
              4.8052938582648,
              44.268723026395
            ],
            [
              4.8028804076615,
              44.296593704504
            ],
            [
              4.7991000020835,
              44.303525444918
            ],
            [
              4.7825467521402,
              44.315582694107
            ],
            [
              4.7622549890071,
              44.325381622419
            ],
            [
              4.7201274920268,
              44.326711236709
            ],
            [
              4.7130169314739,
              44.320649375535
            ],
            [
              4.6790253292764,
              44.320490236187
            ],
            [
              4.6506111734968,
              44.329803148128
            ],
            [
              4.6477668446587,
              44.345435285153
            ],
            [
              4.6489499333936,
              44.372462429925
            ],
            [
              4.6643671500128,
              44.398945267903
            ],
            [
              4.6631054744838,
              44.408193195403
            ],
            [
              4.6673737105298,
              44.430666189382
            ],
            [
              4.6717207236289,
              44.435575882037
            ],
            [
              4.6913719125076,
              44.441606696117
            ],
            [
              4.698652294125,
              44.452309460148
            ],
            [
              4.7008111306551,
              44.465407726847
            ],
            [
              4.6989569043439,
              44.481695990615
            ],
            [
              4.6899681580394,
              44.491246162058
            ],
            [
              4.6882016564414,
              44.505507967849
            ],
            [
              4.6920539362507,
              44.51524108867
            ],
            [
              4.7070188782854,
              44.530840235114
            ],
            [
              4.7007051593196,
              44.539464850247
            ],
            [
              4.6936369285978,
              44.542226212664
            ],
            [
              4.692360716096,
              44.555893769347
            ],
            [
              4.7024424953059,
              44.559506257526
            ],
            [
              4.7070086082734,
              44.566482686877
            ],
            [
              4.704913461242,
              44.573681746966
            ],
            [
              4.7102689640623,
              44.581547275665
            ],
            [
              4.7411702191089,
              44.588775228937
            ],
            [
              4.7443818072321,
              44.600825092206
            ],
            [
              4.7404056572708,
              44.602387973218
            ],
            [
              4.7577811710486,
              44.626516889843
            ],
            [
              4.7580893573914,
              44.630738870999
            ],
            [
              4.7740878762465,
              44.644269863496
            ],
            [
              4.7790663353968,
              44.654696002617
            ],
            [
              4.7790828544927,
              44.679075999709
            ],
            [
              4.7811112562781,
              44.682832714353
            ],
            [
              4.7735508584443,
              44.696632002958
            ],
            [
              4.770855857691,
              44.707768588968
            ],
            [
              4.7628468154066,
              44.720092999967
            ],
            [
              4.7642764416701,
              44.730364078236
            ],
            [
              4.7606865181698,
              44.744264950792
            ],
            [
              4.7693074766224,
              44.760200259528
            ],
            [
              4.7618527499152,
              44.769443231368
            ],
            [
              4.7662167518672,
              44.778624979068
            ],
            [
              4.7793490242908,
              44.783077055445
            ],
            [
              4.7930647721326,
              44.791879535102
            ],
            [
              4.7972592039065,
              44.804483283812
            ],
            [
              4.8105773806198,
              44.816126997605
            ],
            [
              4.8225169914307,
              44.8170913827
            ],
            [
              4.8198499279442,
              44.834752945224
            ],
            [
              4.8216552765179,
              44.838672317135
            ],
            [
              4.8413382097893,
              44.84259184781
            ],
            [
              4.8447690858272,
              44.858253583993
            ],
            [
              4.859459415011,
              44.871750531479
            ],
            [
              4.8606144550499,
              44.880768615779
            ],
            [
              4.8547290164414,
              44.889766730461
            ],
            [
              4.8579025066681,
              44.903157789874
            ],
            [
              4.8681634355011,
              44.909105291004
            ],
            [
              4.8715218455763,
              44.917803545397
            ],
            [
              4.8865943991285,
              44.936652444616
            ],
            [
              4.8756803593057,
              44.955208796322
            ],
            [
              4.8601662520968,
              44.965858918892
            ],
            [
              4.8525687570978,
              44.97953099948
            ],
            [
              4.8512610496739,
              44.994680465822
            ],
            [
              4.8376738035274,
              45.004788192487
            ],
            [
              4.8374888293491,
              45.01172602474
            ],
            [
              4.8452075327059,
              45.022221191798
            ],
            [
              4.8403350665335,
              45.035786029276
            ],
            [
              4.8540950735802,
              45.046251864428
            ],
            [
              4.8607510889061,
              45.055427319327
            ],
            [
              4.8526119061642,
              45.064235228454
            ],
            [
              4.8316486153953,
              45.070271179144
            ],
            [
              4.8276625497628,
              45.077965908196
            ],
            [
              4.8318453392336,
              45.089670350352
            ],
            [
              4.8273592772733,
              45.102145788995
            ],
            [
              4.8178949574706,
              45.108198810951
            ],
            [
              4.8044870212281,
              45.121943298284
            ],
            [
              4.8044647656159,
              45.133193548442
            ],
            [
              4.8244322155718,
              45.148380577457
            ],
            [
              4.8291002542524,
              45.156686506903
            ],
            [
              4.8124687887878,
              45.164479213676
            ],
            [
              4.8090522119215,
              45.170900439855
            ],
            [
              4.8120267158556,
              45.179930757264
            ],
            [
              4.8048124767787,
              45.194033858288
            ],
            [
              4.8125634562269,
              45.204310887013
            ],
            [
              4.8025808522447,
              45.226933954961
            ],
            [
              4.8022063840458,
              45.248326201056
            ],
            [
              4.8102641176881,
              45.263906311762
            ],
            [
              4.8094568553149,
              45.289844279254
            ],
            [
              4.8004899840029,
              45.298357121937
            ]
          ],
          [
            [
              4.8881212108854,
              44.331685388726
            ],
            [
              4.8816338901906,
              44.324867070891
            ],
            [
              4.8898043773976,
              44.314637092176
            ],
            [
              4.8895301353902,
              44.304153097826
            ],
            [
              4.9221541568202,
              44.308786460381
            ],
            [
              4.9451171535381,
              44.304930431589
            ],
            [
              4.9592140611525,
              44.300383345912
            ],
            [
              4.9785833048477,
              44.29746123871
            ],
            [
              4.9872903058308,
              44.293201871035
            ],
            [
              4.9931295821934,
              44.314275867797
            ],
            [
              5.0133769832902,
              44.326136687081
            ],
            [
              5.0090024588591,
              44.333764157861
            ],
            [
              5.0232851187488,
              44.345948323084
            ],
            [
              5.0217054810597,
              44.35674765583
            ],
            [
              5.0270113166527,
              44.362835272711
            ],
            [
              5.0520104655825,
              44.364657329845
            ],
            [
              5.0715584929227,
              44.377495636793
            ],
            [
              5.0708829483659,
              44.383226380932
            ],
            [
              5.0450881649883,
              44.38222013058
            ],
            [
              5.033568721145,
              44.391088844502
            ],
            [
              5.0157545106617,
              44.392767137778
            ],
            [
              5.0133020500209,
              44.405342926953
            ],
            [
              5.0187537016752,
              44.415979484324
            ],
            [
              5.0010692090687,
              44.412604850416
            ],
            [
              4.9886124577738,
              44.423200264487
            ],
            [
              4.9786944339347,
              44.423476108149
            ],
            [
              4.9704375593477,
              44.431367227183
            ],
            [
              4.960461816094,
              44.420048061058
            ],
            [
              4.9185138149855,
              44.407786865574
            ],
            [
              4.9123797297803,
              44.399635600585
            ],
            [
              4.9117660520568,
              44.387044638589
            ],
            [
              4.9066213399272,
              44.374101025322
            ],
            [
              4.893168871015,
              44.367875394193
            ],
            [
              4.8906819005659,
              44.359781959098
            ],
            [
              4.8753963679389,
              44.351439190756
            ],
            [
              4.8796465016088,
              44.345346527391
            ],
            [
              4.8953267719183,
              44.338062405104
            ],
            [
              4.8881212108854,
              44.331685388726
            ]
          ]
        ]
      },
      "properties": {
        "code": "26",
        "nom": "Drôme",
        "population": 517709,
        "delitspour1000": "5,13",
        "nombremosque": 13,
        "parentsEtrangers": "75,4",
        "benefRsa": 20688,
        "eoliennes": 89
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              0.95398321315289,
              43.787369767012
            ],
            [
              0.95599575410245,
              43.7893285138
            ],
            [
              0.97779031654791,
              43.786433294204
            ],
            [
              0.99104705807701,
              43.797042144685
            ],
            [
              1.0215610439646,
              43.801408908872
            ],
            [
              1.0581350801514,
              43.798672133086
            ],
            [
              1.0852689121207,
              43.814506045527
            ],
            [
              1.1007127244889,
              43.80711320984
            ],
            [
              1.1148795190323,
              43.797770816281
            ],
            [
              1.1260842958968,
              43.809592916434
            ],
            [
              1.1446014589289,
              43.822294665662
            ],
            [
              1.1577953167865,
              43.818384029924
            ],
            [
              1.1648406594297,
              43.807879467346
            ],
            [
              1.173658186853,
              43.804683573686
            ],
            [
              1.1795521022992,
              43.79565762568
            ],
            [
              1.198420604335,
              43.79210875536
            ],
            [
              1.2128576267319,
              43.792879311167
            ],
            [
              1.2190419704323,
              43.786056932198
            ],
            [
              1.2085014311128,
              43.779699543917
            ],
            [
              1.2107398584006,
              43.770889245129
            ],
            [
              1.2191983063023,
              43.770780304363
            ],
            [
              1.2749309209595,
              43.785827252918
            ],
            [
              1.2668725426848,
              43.79862205505
            ],
            [
              1.277002223347,
              43.801823176343
            ],
            [
              1.2826317482256,
              43.796160407479
            ],
            [
              1.311084758739,
              43.800604994573
            ],
            [
              1.3264754705083,
              43.808983662362
            ],
            [
              1.343657906713,
              43.811021938492
            ],
            [
              1.3608913400167,
              43.821076224793
            ],
            [
              1.3572717256247,
              43.833692361077
            ],
            [
              1.3479910260216,
              43.838157092318
            ],
            [
              1.3280309380826,
              43.83816259068
            ],
            [
              1.3190371584252,
              43.833403208554
            ],
            [
              1.2967803252843,
              43.847064583304
            ],
            [
              1.3194148108275,
              43.858067599047
            ],
            [
              1.3302991148759,
              43.851102196107
            ],
            [
              1.3530880218721,
              43.852965271749
            ],
            [
              1.3626055596172,
              43.85723191577
            ],
            [
              1.3483969598989,
              43.869806595654
            ],
            [
              1.3561495730503,
              43.874911201495
            ],
            [
              1.3568109611099,
              43.884187014454
            ],
            [
              1.3655602395576,
              43.889308653331
            ],
            [
              1.3764748357432,
              43.884173404419
            ],
            [
              1.4086084987937,
              43.882645645611
            ],
            [
              1.4189366610131,
              43.871354483119
            ],
            [
              1.4478797684927,
              43.873553033921
            ],
            [
              1.4746636959499,
              43.902115458383
            ],
            [
              1.4874153887075,
              43.902476463
            ],
            [
              1.4907868077854,
              43.888999108141
            ],
            [
              1.523322371648,
              43.903596210866
            ],
            [
              1.5322237985211,
              43.916798260663
            ],
            [
              1.5418893832299,
              43.920240096152
            ],
            [
              1.5556221518593,
              43.918317220987
            ],
            [
              1.5466147655792,
              43.902455958048
            ],
            [
              1.556940619168,
              43.87493967977
            ],
            [
              1.5554658239149,
              43.868311833996
            ],
            [
              1.5725785900956,
              43.848799533555
            ],
            [
              1.5931742214888,
              43.843135721198
            ],
            [
              1.587984360995,
              43.820306547566
            ],
            [
              1.5910281145229,
              43.815485724058
            ],
            [
              1.6265009595646,
              43.801304847266
            ],
            [
              1.6447839642588,
              43.800254749798
            ],
            [
              1.6424939757726,
              43.778367726919
            ],
            [
              1.6516592656224,
              43.775541217456
            ],
            [
              1.6557025901135,
              43.764266528178
            ],
            [
              1.6499126393771,
              43.751867766314
            ],
            [
              1.6604226968466,
              43.741832240731
            ],
            [
              1.693457231213,
              43.729376621519
            ],
            [
              1.7064455556732,
              43.716221313812
            ],
            [
              1.6825724725338,
              43.703729811006
            ],
            [
              1.6653481633415,
              43.701537347267
            ],
            [
              1.6646839110227,
              43.693794113527
            ],
            [
              1.6997388434768,
              43.692026379488
            ],
            [
              1.7206413832202,
              43.688167736192
            ],
            [
              1.7202812751098,
              43.674529538425
            ],
            [
              1.7309380903003,
              43.663381060109
            ],
            [
              1.7308742785873,
              43.657753133846
            ],
            [
              1.7072998013738,
              43.65298396886
            ],
            [
              1.6952537111454,
              43.643629456049
            ],
            [
              1.6877759862105,
              43.630703132827
            ],
            [
              1.6967356419966,
              43.620793211757
            ],
            [
              1.7044492798514,
              43.618043561506
            ],
            [
              1.7148670283401,
              43.619726770221
            ],
            [
              1.7237109073222,
              43.612393386183
            ],
            [
              1.7450441838395,
              43.603461248103
            ],
            [
              1.7639573215367,
              43.603567368823
            ],
            [
              1.771151967407,
              43.600041383477
            ],
            [
              1.7720884213857,
              43.592792716782
            ],
            [
              1.7794536447365,
              43.586979802902
            ],
            [
              1.8110012018522,
              43.579301198247
            ],
            [
              1.8247972824309,
              43.580828127504
            ],
            [
              1.8392257690126,
              43.575785768957
            ],
            [
              1.8494460587001,
              43.567545793889
            ],
            [
              1.8511373007758,
              43.550314794176
            ],
            [
              1.8804165252883,
              43.540313270153
            ],
            [
              1.8713729178239,
              43.534288918331
            ],
            [
              1.8796351793718,
              43.529956450959
            ],
            [
              1.8826094690289,
              43.520927860082
            ],
            [
              1.8989231523348,
              43.510263764802
            ],
            [
              1.9169505336115,
              43.50160930902
            ],
            [
              1.920566595322,
              43.505137427282
            ],
            [
              1.9525098902706,
              43.490444984456
            ],
            [
              1.956081247189,
              43.494065020813
            ],
            [
              1.9756542305068,
              43.486799753546
            ],
            [
              1.9832882241816,
              43.48158997494
            ],
            [
              1.9959856098014,
              43.480465716523
            ],
            [
              2.0111491389873,
              43.482981325752
            ],
            [
              2.0150663583376,
              43.488311172078
            ],
            [
              2.0150520675645,
              43.50436470619
            ],
            [
              2.0295771126208,
              43.505799389397
            ],
            [
              2.0331409505587,
              43.499554418548
            ],
            [
              2.0464295682875,
              43.505060766208
            ],
            [
              2.0478554672695,
              43.485548435659
            ],
            [
              2.0407872240535,
              43.477544007193
            ],
            [
              2.026329099839,
              43.476357926454
            ],
            [
              2.0181437836738,
              43.470348442778
            ],
            [
              2.0196450442889,
              43.459246554061
            ],
            [
              2.0148167330533,
              43.451101506091
            ],
            [
              2.0266994567249,
              43.44228890617
            ],
            [
              2.0291341188294,
              43.436895277153
            ],
            [
              2.0392629517166,
              43.424279689382
            ],
            [
              2.0312013013995,
              43.419350322258
            ],
            [
              2.0207368926519,
              43.423193392374
            ],
            [
              2.0113607846882,
              43.414767844117
            ],
            [
              1.995617116255,
              43.411730410053
            ],
            [
              1.9882945357382,
              43.407609041004
            ],
            [
              1.9751843574489,
              43.418256313419
            ],
            [
              1.9681332504295,
              43.417407513183
            ],
            [
              1.9563869984965,
              43.425420417265
            ],
            [
              1.949447182419,
              43.419590573873
            ],
            [
              1.9295264375351,
              43.422772232704
            ],
            [
              1.9190398691635,
              43.419373275391
            ],
            [
              1.9208426857426,
              43.413148027424
            ],
            [
              1.9100983791881,
              43.404056676343
            ],
            [
              1.9086159183678,
              43.396268158977
            ],
            [
              1.8960817399884,
              43.396442502675
            ],
            [
              1.9030812461654,
              43.406484362121
            ],
            [
              1.8949983598871,
              43.418625938245
            ],
            [
              1.8833159335668,
              43.424394923053
            ],
            [
              1.8718099152709,
              43.438110220087
            ],
            [
              1.8587392319179,
              43.443822651373
            ],
            [
              1.8530463693539,
              43.43726360404
            ],
            [
              1.8429498236949,
              43.432545015031
            ],
            [
              1.8474991105402,
              43.426704637628
            ],
            [
              1.8432501603618,
              43.419263434784
            ],
            [
              1.8260977029306,
              43.418114375667
            ],
            [
              1.8171490943613,
              43.409875231664
            ],
            [
              1.8226596085892,
              43.393339649196
            ],
            [
              1.8098202342584,
              43.394499387758
            ],
            [
              1.8040809598206,
              43.391685663532
            ],
            [
              1.8062699140989,
              43.380008857731
            ],
            [
              1.8042786321114,
              43.358580811058
            ],
            [
              1.8166266244975,
              43.349384816053
            ],
            [
              1.8134239904389,
              43.341407194984
            ],
            [
              1.7969705125778,
              43.340562392875
            ],
            [
              1.7861676084759,
              43.344942024922
            ],
            [
              1.7747537463174,
              43.34243454001
            ],
            [
              1.7718387739464,
              43.33715262084
            ],
            [
              1.7589974639475,
              43.345684622995
            ],
            [
              1.7477265133075,
              43.343736431882
            ],
            [
              1.7238177566678,
              43.329745464099
            ],
            [
              1.7340683428468,
              43.323055197932
            ],
            [
              1.7358104191158,
              43.314195591755
            ],
            [
              1.7264517578662,
              43.313558549895
            ],
            [
              1.7041377368756,
              43.302727433716
            ],
            [
              1.7145108961303,
              43.294682139948
            ],
            [
              1.7059158532707,
              43.28535876201
            ],
            [
              1.6884233932357,
              43.273554672371
            ],
            [
              1.6747945841516,
              43.281217375945
            ],
            [
              1.6511699036493,
              43.261453377454
            ],
            [
              1.6544432045959,
              43.243397466774
            ],
            [
              1.645770021445,
              43.238244686015
            ],
            [
              1.6350793089726,
              43.237097080522
            ],
            [
              1.6372059284418,
              43.255118083766
            ],
            [
              1.6077158113675,
              43.259782896611
            ],
            [
              1.6027224834357,
              43.265697706589
            ],
            [
              1.582534553172,
              43.269206223464
            ],
            [
              1.5706398172062,
              43.275025288273
            ],
            [
              1.5675664926871,
              43.26946856203
            ],
            [
              1.5733236732311,
              43.261898277797
            ],
            [
              1.5650041733714,
              43.253338249235
            ],
            [
              1.5475224661578,
              43.275676101165
            ],
            [
              1.5408341500542,
              43.278241916645
            ],
            [
              1.5187269099082,
              43.269170803809
            ],
            [
              1.515842696407,
              43.277334746321
            ],
            [
              1.5175886435955,
              43.28941666797
            ],
            [
              1.4945235196899,
              43.28616026132
            ],
            [
              1.4948363091727,
              43.277383380686
            ],
            [
              1.4902005691709,
              43.271813366627
            ],
            [
              1.4894344483981,
              43.258421325801
            ],
            [
              1.5044650994084,
              43.249435574042
            ],
            [
              1.4924444217221,
              43.23683384611
            ],
            [
              1.5042641108015,
              43.232828183557
            ],
            [
              1.5046030382736,
              43.221311626744
            ],
            [
              1.4845403775247,
              43.226159128131
            ],
            [
              1.4754984723227,
              43.216993665989
            ],
            [
              1.4637155449058,
              43.210599069418
            ],
            [
              1.4479262267221,
              43.212886885879
            ],
            [
              1.4320931895049,
              43.217733181789
            ],
            [
              1.4176056708049,
              43.227835861321
            ],
            [
              1.4211403100532,
              43.239540512069
            ],
            [
              1.426740704298,
              43.246137007285
            ],
            [
              1.4253583153188,
              43.256567003232
            ],
            [
              1.4114785641312,
              43.270268095135
            ],
            [
              1.3991452364303,
              43.279219254827
            ],
            [
              1.3821820496301,
              43.288209689415
            ],
            [
              1.3654495190749,
              43.305648938837
            ],
            [
              1.3730180033309,
              43.310144051632
            ],
            [
              1.3436471719609,
              43.315601482419
            ],
            [
              1.3239671313317,
              43.309397473097
            ],
            [
              1.3180293057672,
              43.295887855993
            ],
            [
              1.3049961387762,
              43.298199122183
            ],
            [
              1.3031093227975,
              43.289392265627
            ],
            [
              1.2933090414018,
              43.285338357056
            ],
            [
              1.3002670800627,
              43.275247427926
            ],
            [
              1.297105154879,
              43.262429239143
            ],
            [
              1.308342979857,
              43.26452945905
            ],
            [
              1.3306707599026,
              43.26255993939
            ],
            [
              1.3318823817822,
              43.248841488087
            ],
            [
              1.3425518883338,
              43.243934888934
            ],
            [
              1.3526468627747,
              43.244477126922
            ],
            [
              1.3751545701023,
              43.237788626735
            ],
            [
              1.3832321285424,
              43.229485899343
            ],
            [
              1.3743783770211,
              43.213744940483
            ],
            [
              1.3683616310962,
              43.209188972055
            ],
            [
              1.3597470188554,
              43.212992778605
            ],
            [
              1.3501665928962,
              43.211422546187
            ],
            [
              1.3319533192479,
              43.204100657505
            ],
            [
              1.3296086619392,
              43.197016168606
            ],
            [
              1.3146017705234,
              43.189991031341
            ],
            [
              1.3057212109254,
              43.194910946327
            ],
            [
              1.2962794920389,
              43.189284921512
            ],
            [
              1.2740710371613,
              43.196228567239
            ],
            [
              1.2598525778452,
              43.186193409625
            ],
            [
              1.249008854834,
              43.183613057961
            ],
            [
              1.243676771125,
              43.189552631539
            ],
            [
              1.2321332146768,
              43.188266462242
            ],
            [
              1.227478061976,
              43.183896662483
            ],
            [
              1.2273716929066,
              43.173498807023
            ],
            [
              1.2139839741893,
              43.162432965623
            ],
            [
              1.2189521888506,
              43.153166413007
            ],
            [
              1.242570863495,
              43.148989839584
            ],
            [
              1.2689269278789,
              43.146541731541
            ],
            [
              1.3020119564183,
              43.123980856737
            ],
            [
              1.2914480700483,
              43.124111701013
            ],
            [
              1.2808504202672,
              43.111411090679
            ],
            [
              1.2657154335863,
              43.109508722946
            ],
            [
              1.2596485927641,
              43.104685971583
            ],
            [
              1.2618548870607,
              43.091652541667
            ],
            [
              1.2458835539875,
              43.088211645242
            ],
            [
              1.2236270656509,
              43.086060227758
            ],
            [
              1.2105886400872,
              43.107083348603
            ],
            [
              1.2087319295319,
              43.117953707641
            ],
            [
              1.1751638222406,
              43.140929852583
            ],
            [
              1.162309627544,
              43.132290389403
            ],
            [
              1.1454230371574,
              43.137827610533
            ],
            [
              1.1407937490565,
              43.144479439169
            ],
            [
              1.1240759431443,
              43.156907297953
            ],
            [
              1.1092766083352,
              43.144140969511
            ],
            [
              1.0898394045755,
              43.141813573354
            ],
            [
              1.0879769348772,
              43.133821692876
            ],
            [
              1.0589174294916,
              43.141827188986
            ],
            [
              1.0495535976405,
              43.139648199291
            ],
            [
              1.0525728726409,
              43.125370252262
            ],
            [
              1.0388407127951,
              43.100549312823
            ],
            [
              1.0286490780477,
              43.10064941233
            ],
            [
              1.0116824594973,
              43.10949847032
            ],
            [
              0.99233974680337,
              43.098016624836
            ],
            [
              0.99463979120919,
              43.08723599851
            ],
            [
              1.0052486980698,
              43.080665473937
            ],
            [
              0.98517130914337,
              43.067052731075
            ],
            [
              0.98289171246668,
              43.054014096139
            ],
            [
              0.9867359607193,
              43.027502633538
            ],
            [
              1.0087764976587,
              43.018093076585
            ],
            [
              1.0189442034638,
              43.010383740373
            ],
            [
              1.0070475401309,
              43.007586533836
            ],
            [
              0.99509676944565,
              42.990833670804
            ],
            [
              0.98119899892685,
              42.979140304836
            ],
            [
              0.97909520433636,
              42.97443264493
            ],
            [
              0.95022483505411,
              42.966074638893
            ],
            [
              0.92766324080075,
              42.966687423421
            ],
            [
              0.90858243386613,
              42.958371438839
            ],
            [
              0.87491501083315,
              42.957776717926
            ],
            [
              0.87641147743677,
              42.949423501558
            ],
            [
              0.88530949146611,
              42.936032646016
            ],
            [
              0.87603944818218,
              42.926912994605
            ],
            [
              0.85692609397602,
              42.92549163353
            ],
            [
              0.83241568886004,
              42.919991647217
            ],
            [
              0.82612266137771,
              42.914278721023
            ],
            [
              0.83343292351349,
              42.90746882166
            ],
            [
              0.84292190702011,
              42.88857840842
            ],
            [
              0.8357013230124,
              42.876375875541
            ],
            [
              0.83982835962524,
              42.866771431936
            ],
            [
              0.84821359551544,
              42.865329650272
            ],
            [
              0.85047744231586,
              42.855146931618
            ],
            [
              0.85988322052693,
              42.838298291523
            ],
            [
              0.85830081521383,
              42.825718092689
            ],
            [
              0.83173917183391,
              42.829191852348
            ],
            [
              0.80892253420252,
              42.83905419597
            ],
            [
              0.80148779146423,
              42.840484414153
            ],
            [
              0.78649531003324,
              42.835955315429
            ],
            [
              0.76764000650777,
              42.838976644913
            ],
            [
              0.7473099643863,
              42.846684787469
            ],
            [
              0.73505963063002,
              42.854313618439
            ],
            [
              0.70837530047161,
              42.861402691964
            ],
            [
              0.6920253593771,
              42.855311818756
            ],
            [
              0.67832018690678,
              42.855018835179
            ],
            [
              0.67858428353724,
              42.847952666471
            ],
            [
              0.65901722875138,
              42.838528619974
            ],
            [
              0.66114635943904,
              42.830368839742
            ],
            [
              0.66851393517847,
              42.820350298964
            ],
            [
              0.66536072645848,
              42.81251191916
            ],
            [
              0.66968713590557,
              42.802095308449
            ],
            [
              0.65863120147728,
              42.79715230059
            ],
            [
              0.65128983839552,
              42.786597088659
            ],
            [
              0.64453806422139,
              42.783076412205
            ],
            [
              0.66423575825517,
              42.77167292571
            ],
            [
              0.65032149143529,
              42.764168646382
            ],
            [
              0.6474308997985,
              42.751662642856
            ],
            [
              0.6599389520435,
              42.752435876543
            ],
            [
              0.66957491088554,
              42.73276995512
            ],
            [
              0.67901232540769,
              42.722784338644
            ],
            [
              0.67386650187529,
              42.716586587301
            ],
            [
              0.68225754178415,
              42.708965425559
            ],
            [
              0.67377451374064,
              42.699841320693
            ],
            [
              0.67057744875758,
              42.68989270234
            ],
            [
              0.6379005478724,
              42.693041495792
            ],
            [
              0.60764322210758,
              42.699058087497
            ],
            [
              0.59731972324609,
              42.705833174186
            ],
            [
              0.58683088027779,
              42.694999039016
            ],
            [
              0.53028817299211,
              42.702427361485
            ],
            [
              0.51254065154989,
              42.692054778891
            ],
            [
              0.49187864436583,
              42.695004250993
            ],
            [
              0.47775091439859,
              42.699986776967
            ],
            [
              0.48458374009353,
              42.710274868309
            ],
            [
              0.45509925797433,
              42.728057163284
            ],
            [
              0.46269535976379,
              42.739214297937
            ],
            [
              0.46339965657335,
              42.759619682779
            ],
            [
              0.45587314187027,
              42.76989031175
            ],
            [
              0.45597802846716,
              42.780556135531
            ],
            [
              0.46304643293284,
              42.79089501716
            ],
            [
              0.46354501855397,
              42.804496892674
            ],
            [
              0.45658819283386,
              42.809828070413
            ],
            [
              0.45889719229199,
              42.8281424602
            ],
            [
              0.46749911892543,
              42.846747069682
            ],
            [
              0.46024258056156,
              42.85175053483
            ],
            [
              0.47238152873403,
              42.858935155117
            ],
            [
              0.47257284328773,
              42.870780602409
            ],
            [
              0.4772965729446,
              42.878104684919
            ],
            [
              0.49593627279002,
              42.874762438069
            ],
            [
              0.50275281250409,
              42.87051186101
            ],
            [
              0.52376097943905,
              42.867834470742
            ],
            [
              0.53694778531801,
              42.862514068997
            ],
            [
              0.55001672827569,
              42.863667944351
            ],
            [
              0.55981185857482,
              42.861306396565
            ],
            [
              0.57593507190118,
              42.870827720703
            ],
            [
              0.57818681564011,
              42.881942709356
            ],
            [
              0.58654053371816,
              42.899760984166
            ],
            [
              0.59596170540963,
              42.910759596222
            ],
            [
              0.59659339346133,
              42.920626304869
            ],
            [
              0.60605980482582,
              42.934364734106
            ],
            [
              0.6284641738259,
              42.943755211687
            ],
            [
              0.63547614456196,
              42.951513177726
            ],
            [
              0.6419918691301,
              42.952874553687
            ],
            [
              0.64553925526757,
              42.962068215879
            ],
            [
              0.63578281368147,
              42.968895271217
            ],
            [
              0.61882997147207,
              42.972416005847
            ],
            [
              0.61435800035415,
              42.987385706196
            ],
            [
              0.62353209429204,
              42.99194182479
            ],
            [
              0.62741914892273,
              43.000102900277
            ],
            [
              0.61555885843815,
              43.011935174103
            ],
            [
              0.61647463978452,
              43.020639129815
            ],
            [
              0.60824975068702,
              43.033562771594
            ],
            [
              0.59322764879205,
              43.035314438925
            ],
            [
              0.59181936224605,
              43.022370102684
            ],
            [
              0.57338249614077,
              43.021321483533
            ],
            [
              0.56300575907043,
              43.017466178093
            ],
            [
              0.54120823099024,
              43.001719653069
            ],
            [
              0.53097432359112,
              43.003272172104
            ],
            [
              0.52714594818695,
              43.008608157216
            ],
            [
              0.53593289967487,
              43.018155073443
            ],
            [
              0.53793151601541,
              43.041642574512
            ],
            [
              0.54716673279983,
              43.044852534963
            ],
            [
              0.56265072205432,
              43.035782450371
            ],
            [
              0.56062473412263,
              43.057989762064
            ],
            [
              0.55733218070377,
              43.066399536427
            ],
            [
              0.56385629257295,
              43.074244482997
            ],
            [
              0.54675860271803,
              43.081266468806
            ],
            [
              0.53313934061594,
              43.082799134312
            ],
            [
              0.53226474570564,
              43.093864376016
            ],
            [
              0.51515952535238,
              43.092685541999
            ],
            [
              0.50283874034225,
              43.095683308821
            ],
            [
              0.50309993149961,
              43.105591554426
            ],
            [
              0.49120181777745,
              43.108676185943
            ],
            [
              0.48904307495584,
              43.115172560924
            ],
            [
              0.47874657576465,
              43.112254042885
            ],
            [
              0.45321684463749,
              43.111242340548
            ],
            [
              0.46394357774072,
              43.117819842257
            ],
            [
              0.45364006237795,
              43.127183340895
            ],
            [
              0.44199364903152,
              43.131273015364
            ],
            [
              0.47556676361983,
              43.161266907641
            ],
            [
              0.48781456429823,
              43.1652534382
            ],
            [
              0.5117130202616,
              43.185834450618
            ],
            [
              0.52156679074663,
              43.192045832358
            ],
            [
              0.50575903800693,
              43.199712594677
            ],
            [
              0.51738555808232,
              43.212375668519
            ],
            [
              0.5360494053215,
              43.207675534159
            ],
            [
              0.54486825398118,
              43.213313956316
            ],
            [
              0.55187873558352,
              43.209143617489
            ],
            [
              0.57095143144365,
              43.217817968649
            ],
            [
              0.57405142839834,
              43.221293040934
            ],
            [
              0.57284091276067,
              43.233109036267
            ],
            [
              0.55156853219931,
              43.236101604682
            ],
            [
              0.56867744444427,
              43.2523553295
            ],
            [
              0.59665991362366,
              43.273771599239
            ],
            [
              0.61387902278172,
              43.277095258174
            ],
            [
              0.61628244214367,
              43.289038643093
            ],
            [
              0.63277803508742,
              43.292685230456
            ],
            [
              0.63572028728275,
              43.30007447295
            ],
            [
              0.60680735564582,
              43.310884111508
            ],
            [
              0.61164971750279,
              43.315672040435
            ],
            [
              0.6403899420411,
              43.313603215987
            ],
            [
              0.65594157716311,
              43.317209345572
            ],
            [
              0.67459265828107,
              43.329462065074
            ],
            [
              0.68846452213444,
              43.348635644515
            ],
            [
              0.70149158494103,
              43.363211323272
            ],
            [
              0.71759514468103,
              43.376630888106
            ],
            [
              0.73372913377447,
              43.370969737188
            ],
            [
              0.7424090889166,
              43.381171623223
            ],
            [
              0.7536672758696,
              43.389391478236
            ],
            [
              0.7455611636499,
              43.394988327949
            ],
            [
              0.75493220542167,
              43.404707761175
            ],
            [
              0.75685645463448,
              43.416304606902
            ],
            [
              0.77041800802568,
              43.416807868024
            ],
            [
              0.80629384070281,
              43.399976375671
            ],
            [
              0.82054281206295,
              43.416977745341
            ],
            [
              0.84439490646175,
              43.409881816372
            ],
            [
              0.85708387069282,
              43.413664270558
            ],
            [
              0.88587636963959,
              43.410119159702
            ],
            [
              0.89488718520061,
              43.405278333753
            ],
            [
              0.9172450320849,
              43.406330772017
            ],
            [
              0.92813841182763,
              43.400051143491
            ],
            [
              0.92728982929501,
              43.395065415639
            ],
            [
              0.94690393719388,
              43.385227634105
            ],
            [
              0.9643354461405,
              43.386475688121
            ],
            [
              0.96889532385851,
              43.377505448343
            ],
            [
              0.96704401774383,
              43.369243666794
            ],
            [
              0.97808742391735,
              43.362652674981
            ],
            [
              0.99273377183994,
              43.366836344849
            ],
            [
              0.99603506916127,
              43.376323141154
            ],
            [
              1.0024980717613,
              43.377077953713
            ],
            [
              1.0004954309787,
              43.394675681898
            ],
            [
              0.99378962134181,
              43.407985716071
            ],
            [
              1.0064477416868,
              43.413446283533
            ],
            [
              1.0227865920007,
              43.411704797199
            ],
            [
              1.0270756402527,
              43.422021491448
            ],
            [
              1.0190310880081,
              43.430794816384
            ],
            [
              1.0321580538976,
              43.440850601169
            ],
            [
              1.041677576659,
              43.460631092291
            ],
            [
              1.0181793389093,
              43.473339800901
            ],
            [
              1.0342416533446,
              43.486967069232
            ],
            [
              1.0518569593378,
              43.486989810023
            ],
            [
              1.0490652414962,
              43.496079707162
            ],
            [
              1.0583051703195,
              43.50393760881
            ],
            [
              1.0686626800876,
              43.50518270917
            ],
            [
              1.0537619809022,
              43.519325958016
            ],
            [
              1.057814537653,
              43.526978281381
            ],
            [
              1.0706548233598,
              43.525097799354
            ],
            [
              1.0752142960181,
              43.528665325069
            ],
            [
              1.062991524881,
              43.536351541765
            ],
            [
              1.0718152477827,
              43.546351934215
            ],
            [
              1.090647778803,
              43.545213379737
            ],
            [
              1.0970866504119,
              43.533225237216
            ],
            [
              1.1160176909943,
              43.542396080826
            ],
            [
              1.1187659978289,
              43.550199830301
            ],
            [
              1.1270278918133,
              43.554525848407
            ],
            [
              1.154381666835,
              43.557575517171
            ],
            [
              1.1640344113755,
              43.572394557106
            ],
            [
              1.1744603559311,
              43.570458971268
            ],
            [
              1.1877658986478,
              43.572127229623
            ],
            [
              1.1993465049049,
              43.569181658607
            ],
            [
              1.2013345895525,
              43.594811115002
            ],
            [
              1.1809590675405,
              43.614001931607
            ],
            [
              1.1645586777573,
              43.604117607613
            ],
            [
              1.1581456500501,
              43.605620994942
            ],
            [
              1.142717410719,
              43.621712627679
            ],
            [
              1.1475429353324,
              43.634179386013
            ],
            [
              1.1408379592619,
              43.641090593699
            ],
            [
              1.1299647474843,
              43.642552070278
            ],
            [
              1.1148268465874,
              43.636718439712
            ],
            [
              1.0877006936386,
              43.644975118867
            ],
            [
              1.1013884804279,
              43.660558286338
            ],
            [
              1.0918859960698,
              43.664198335525
            ],
            [
              1.0748275070777,
              43.661707820714
            ],
            [
              1.0509872612369,
              43.677824827007
            ],
            [
              1.051930517635,
              43.6826544873
            ],
            [
              1.0641543709197,
              43.702773708282
            ],
            [
              1.0535624785619,
              43.705514594953
            ],
            [
              1.0392984925465,
              43.713023759869
            ],
            [
              1.0293767746744,
              43.710082126492
            ],
            [
              1.0221593597894,
              43.720732789944
            ],
            [
              1.0062375007282,
              43.729209551289
            ],
            [
              0.98725506130363,
              43.742396528141
            ],
            [
              0.99282512949901,
              43.745927081194
            ],
            [
              0.97537553776456,
              43.759491586314
            ],
            [
              0.96756902853695,
              43.762801007674
            ],
            [
              0.96014553598417,
              43.77119309678
            ],
            [
              0.95398321315289,
              43.787369767012
            ]
          ]
        ]
      },
      "properties": {
        "code": "31",
        "nom": "Haute-Garonne",
        "population": 1415757,
        "delitspour1000": "5,251",
        "nombremosque": 32,
        "parentsEtrangers": "66,3",
        "benefRsa": 73807,
        "eoliennes": 30
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              0.076043349292038,
              43.983138573142
            ],
            [
              0.11437379986584,
              43.988072909373
            ],
            [
              0.12640607961248,
              44.000336105676
            ],
            [
              0.14095408748334,
              43.994676380228
            ],
            [
              0.13863539397759,
              43.974770669617
            ],
            [
              0.1591211137839,
              43.973860568873
            ],
            [
              0.1664085585548,
              43.984223372919
            ],
            [
              0.16676088451462,
              43.996830517368
            ],
            [
              0.17916093410135,
              44.000666040326
            ],
            [
              0.18957139217537,
              44.014641613964
            ],
            [
              0.20117969184465,
              44.013668593318
            ],
            [
              0.20507206540087,
              44.0190292233
            ],
            [
              0.22470969223381,
              44.019169028731
            ],
            [
              0.2352015335306,
              44.008471438234
            ],
            [
              0.24748889809396,
              44.00473345675
            ],
            [
              0.2658680614134,
              44.003716751224
            ],
            [
              0.27345979290764,
              43.998815616074
            ],
            [
              0.30409114391986,
              43.993060809869
            ],
            [
              0.31793784234407,
              43.994973643102
            ],
            [
              0.31653852185538,
              44.01011924115
            ],
            [
              0.32938687327154,
              44.008324743136
            ],
            [
              0.35756641014151,
              44.01637714562
            ],
            [
              0.36491171606437,
              44.015152271393
            ],
            [
              0.3713725918443,
              44.008155096613
            ],
            [
              0.38151930823563,
              44.006373305954
            ],
            [
              0.39446082314246,
              44.019984410354
            ],
            [
              0.41750846361679,
              44.026970327067
            ],
            [
              0.44244380213487,
              44.028759139019
            ],
            [
              0.44887562692766,
              44.042594787048
            ],
            [
              0.4595157527797,
              44.055234750337
            ],
            [
              0.47876888458351,
              44.054521013697
            ],
            [
              0.48581828489735,
              44.058598437153
            ],
            [
              0.5060519145443,
              44.056124874168
            ],
            [
              0.51214276226031,
              44.063296138178
            ],
            [
              0.52207386686065,
              44.05696870158
            ],
            [
              0.53902463781127,
              44.053724078487
            ],
            [
              0.5650766725315,
              44.05922317624
            ],
            [
              0.57588134690602,
              44.075909688882
            ],
            [
              0.59797769402997,
              44.078224550392
            ],
            [
              0.60470426269225,
              44.071956240981
            ],
            [
              0.62788687147415,
              44.060631087503
            ],
            [
              0.63165143580682,
              44.049501421006
            ],
            [
              0.65460048123679,
              44.04130753634
            ],
            [
              0.65507583677567,
              44.031189052143
            ],
            [
              0.66659683472462,
              44.025144272763
            ],
            [
              0.6795846041912,
              44.029344422988
            ],
            [
              0.6797359030614,
              44.03717928063
            ],
            [
              0.68766619417171,
              44.045887421074
            ],
            [
              0.69448178843623,
              44.045585512479
            ],
            [
              0.7078014441272,
              44.058016144097
            ],
            [
              0.71736742842423,
              44.057565048253
            ],
            [
              0.73670154232703,
              44.061550959213
            ],
            [
              0.741884791205,
              44.065199235038
            ],
            [
              0.74892669642136,
              44.065663890895
            ],
            [
              0.75520321887022,
              44.052608430258
            ],
            [
              0.75678947836038,
              44.039978938462
            ],
            [
              0.76552658101403,
              44.030150980746
            ],
            [
              0.77578049443384,
              44.035914254363
            ],
            [
              0.81059445113324,
              44.048722562007
            ],
            [
              0.81746969640191,
              44.054892293483
            ],
            [
              0.82735257549546,
              44.055250960736
            ],
            [
              0.84107000320885,
              44.045755333082
            ],
            [
              0.85051060059395,
              44.047539266803
            ],
            [
              0.86041116085683,
              44.040785300803
            ],
            [
              0.84622635198938,
              44.031735004924
            ],
            [
              0.83956279350669,
              44.023991248727
            ],
            [
              0.82240759655087,
              44.028448186779
            ],
            [
              0.81364104682038,
              44.024908754769
            ],
            [
              0.81955190581301,
              44.015944939424
            ],
            [
              0.81765350525156,
              44.009614598
            ],
            [
              0.8277004422891,
              43.999575095717
            ],
            [
              0.81656580710086,
              43.989625265229
            ],
            [
              0.81362540509461,
              43.982893272029
            ],
            [
              0.79677301050442,
              43.975618811303
            ],
            [
              0.79246492768911,
              43.964848615804
            ],
            [
              0.78484170552922,
              43.955244573648
            ],
            [
              0.76964774157407,
              43.951236983097
            ],
            [
              0.76008372909928,
              43.942238869568
            ],
            [
              0.76250323120863,
              43.931192014249
            ],
            [
              0.76994232659412,
              43.922318519181
            ],
            [
              0.79217468970115,
              43.926246280202
            ],
            [
              0.80929545624161,
              43.932301615691
            ],
            [
              0.82125647912065,
              43.924727166566
            ],
            [
              0.84005803336268,
              43.916329110519
            ],
            [
              0.85092679438503,
              43.921423062399
            ],
            [
              0.8628950716946,
              43.923285613293
            ],
            [
              0.87397308254597,
              43.913391733353
            ],
            [
              0.88826732924193,
              43.909347809317
            ],
            [
              0.90458454017881,
              43.899017505511
            ],
            [
              0.88398989930177,
              43.890841157571
            ],
            [
              0.88349353633238,
              43.875251771074
            ],
            [
              0.89943420695308,
              43.864701960809
            ],
            [
              0.91270345397669,
              43.848486016154
            ],
            [
              0.89474229882734,
              43.850280699189
            ],
            [
              0.89541680754323,
              43.840345297757
            ],
            [
              0.90843104231164,
              43.834610984174
            ],
            [
              0.92585656217094,
              43.831629205755
            ],
            [
              0.90405560048524,
              43.807937430743
            ],
            [
              0.89718573970385,
              43.790622983363
            ],
            [
              0.90615590240468,
              43.784326922501
            ],
            [
              0.91572276222962,
              43.78437620761
            ],
            [
              0.92349730113,
              43.789986071471
            ],
            [
              0.9412056241659,
              43.791966691597
            ],
            [
              0.95398321315289,
              43.787369767012
            ],
            [
              0.96014553598417,
              43.77119309678
            ],
            [
              0.96756902853695,
              43.762801007674
            ],
            [
              0.97537553776456,
              43.759491586314
            ],
            [
              0.99282512949901,
              43.745927081194
            ],
            [
              0.98725506130363,
              43.742396528141
            ],
            [
              1.0062375007282,
              43.729209551289
            ],
            [
              1.0221593597894,
              43.720732789944
            ],
            [
              1.0293767746744,
              43.710082126492
            ],
            [
              1.0392984925465,
              43.713023759869
            ],
            [
              1.0535624785619,
              43.705514594953
            ],
            [
              1.0641543709197,
              43.702773708282
            ],
            [
              1.051930517635,
              43.6826544873
            ],
            [
              1.0509872612369,
              43.677824827007
            ],
            [
              1.0748275070777,
              43.661707820714
            ],
            [
              1.0918859960698,
              43.664198335525
            ],
            [
              1.1013884804279,
              43.660558286338
            ],
            [
              1.0877006936386,
              43.644975118867
            ],
            [
              1.1148268465874,
              43.636718439712
            ],
            [
              1.1299647474843,
              43.642552070278
            ],
            [
              1.1408379592619,
              43.641090593699
            ],
            [
              1.1475429353324,
              43.634179386013
            ],
            [
              1.142717410719,
              43.621712627679
            ],
            [
              1.1581456500501,
              43.605620994942
            ],
            [
              1.1645586777573,
              43.604117607613
            ],
            [
              1.1809590675405,
              43.614001931607
            ],
            [
              1.2013345895525,
              43.594811115002
            ],
            [
              1.1993465049049,
              43.569181658607
            ],
            [
              1.1877658986478,
              43.572127229623
            ],
            [
              1.1744603559311,
              43.570458971268
            ],
            [
              1.1640344113755,
              43.572394557106
            ],
            [
              1.154381666835,
              43.557575517171
            ],
            [
              1.1270278918133,
              43.554525848407
            ],
            [
              1.1187659978289,
              43.550199830301
            ],
            [
              1.1160176909943,
              43.542396080826
            ],
            [
              1.0970866504119,
              43.533225237216
            ],
            [
              1.090647778803,
              43.545213379737
            ],
            [
              1.0718152477827,
              43.546351934215
            ],
            [
              1.062991524881,
              43.536351541765
            ],
            [
              1.0752142960181,
              43.528665325069
            ],
            [
              1.0706548233598,
              43.525097799354
            ],
            [
              1.057814537653,
              43.526978281381
            ],
            [
              1.0537619809022,
              43.519325958016
            ],
            [
              1.0686626800876,
              43.50518270917
            ],
            [
              1.0583051703195,
              43.50393760881
            ],
            [
              1.0490652414962,
              43.496079707162
            ],
            [
              1.0518569593378,
              43.486989810023
            ],
            [
              1.0342416533446,
              43.486967069232
            ],
            [
              1.0181793389093,
              43.473339800901
            ],
            [
              1.041677576659,
              43.460631092291
            ],
            [
              1.0321580538976,
              43.440850601169
            ],
            [
              1.0190310880081,
              43.430794816384
            ],
            [
              1.0270756402527,
              43.422021491448
            ],
            [
              1.0227865920007,
              43.411704797199
            ],
            [
              1.0064477416868,
              43.413446283533
            ],
            [
              0.99378962134181,
              43.407985716071
            ],
            [
              1.0004954309787,
              43.394675681898
            ],
            [
              1.0024980717613,
              43.377077953713
            ],
            [
              0.99603506916127,
              43.376323141154
            ],
            [
              0.99273377183994,
              43.366836344849
            ],
            [
              0.97808742391735,
              43.362652674981
            ],
            [
              0.96704401774383,
              43.369243666794
            ],
            [
              0.96889532385851,
              43.377505448343
            ],
            [
              0.9643354461405,
              43.386475688121
            ],
            [
              0.94690393719388,
              43.385227634105
            ],
            [
              0.92728982929501,
              43.395065415639
            ],
            [
              0.92813841182763,
              43.400051143491
            ],
            [
              0.9172450320849,
              43.406330772017
            ],
            [
              0.89488718520061,
              43.405278333753
            ],
            [
              0.88587636963959,
              43.410119159702
            ],
            [
              0.85708387069282,
              43.413664270558
            ],
            [
              0.84439490646175,
              43.409881816372
            ],
            [
              0.82054281206295,
              43.416977745341
            ],
            [
              0.80629384070281,
              43.399976375671
            ],
            [
              0.77041800802568,
              43.416807868024
            ],
            [
              0.75685645463448,
              43.416304606902
            ],
            [
              0.75493220542167,
              43.404707761175
            ],
            [
              0.7455611636499,
              43.394988327949
            ],
            [
              0.7536672758696,
              43.389391478236
            ],
            [
              0.7424090889166,
              43.381171623223
            ],
            [
              0.73372913377447,
              43.370969737188
            ],
            [
              0.71759514468103,
              43.376630888106
            ],
            [
              0.70149158494103,
              43.363211323272
            ],
            [
              0.68846452213444,
              43.348635644515
            ],
            [
              0.67459265828107,
              43.329462065074
            ],
            [
              0.65594157716311,
              43.317209345572
            ],
            [
              0.6403899420411,
              43.313603215987
            ],
            [
              0.61164971750279,
              43.315672040435
            ],
            [
              0.60680735564582,
              43.310884111508
            ],
            [
              0.58460116890689,
              43.319818584652
            ],
            [
              0.57177754315704,
              43.319707730296
            ],
            [
              0.54744742610882,
              43.329318832245
            ],
            [
              0.53690931523756,
              43.328873193442
            ],
            [
              0.50432581846829,
              43.333505576201
            ],
            [
              0.49802785598653,
              43.326175934266
            ],
            [
              0.4822377048054,
              43.332043999821
            ],
            [
              0.47297636945035,
              43.332476899091
            ],
            [
              0.44503785342914,
              43.340295366177
            ],
            [
              0.44303285893116,
              43.327927935026
            ],
            [
              0.41351647060317,
              43.329094431545
            ],
            [
              0.39533106011154,
              43.33453809565
            ],
            [
              0.39513691309348,
              43.348269610242
            ],
            [
              0.38833876240281,
              43.356210125031
            ],
            [
              0.3649863802754,
              43.349636508692
            ],
            [
              0.35337637383718,
              43.348937194979
            ],
            [
              0.33183860488216,
              43.343161276986
            ],
            [
              0.32814824529468,
              43.351598750607
            ],
            [
              0.32769192775763,
              43.370952743497
            ],
            [
              0.31540993388053,
              43.367351512373
            ],
            [
              0.30085815491505,
              43.371751195377
            ],
            [
              0.29864354305631,
              43.388394571404
            ],
            [
              0.27725309717219,
              43.385794488276
            ],
            [
              0.24810289190962,
              43.375623419655
            ],
            [
              0.23258112809587,
              43.3677705561
            ],
            [
              0.22224451423552,
              43.370057642279
            ],
            [
              0.20634629905454,
              43.367775935375
            ],
            [
              0.1978811390958,
              43.375042930949
            ],
            [
              0.18202989360661,
              43.371184440461
            ],
            [
              0.17380124314994,
              43.402772158304
            ],
            [
              0.15385938168359,
              43.399322164419
            ],
            [
              0.13955189436855,
              43.420706325824
            ],
            [
              0.13200756108454,
              43.424113141856
            ],
            [
              0.13798471501664,
              43.432919034375
            ],
            [
              0.16599819263146,
              43.44273819974
            ],
            [
              0.16245656915563,
              43.449913175464
            ],
            [
              0.15316225021931,
              43.457393528332
            ],
            [
              0.14931283060095,
              43.465365866999
            ],
            [
              0.14001339320212,
              43.465236063128
            ],
            [
              0.13086227203695,
              43.47402211026
            ],
            [
              0.12731729425974,
              43.491328012923
            ],
            [
              0.13046411439385,
              43.494899078652
            ],
            [
              0.11212679596727,
              43.517368187417
            ],
            [
              0.10121503618691,
              43.511119695631
            ],
            [
              0.089680554933274,
              43.510421819008
            ],
            [
              0.082006430447501,
              43.517040049154
            ],
            [
              0.080575938618543,
              43.5238620587
            ],
            [
              0.056077967868575,
              43.518802653832
            ],
            [
              0.050527067832305,
              43.521660780518
            ],
            [
              0.04068648874415,
              43.53624672667
            ],
            [
              0.029584696596704,
              43.544893836857
            ],
            [
              0.015777190923584,
              43.543393094225
            ],
            [
              0.008922500968011,
              43.560545653739
            ],
            [
              -0.0019274798181687,
              43.565543989436
            ],
            [
              -0.0071754285102898,
              43.592181218787
            ],
            [
              -0.015780753160441,
              43.60465468589
            ],
            [
              -0.033362276277096,
              43.604019201984
            ],
            [
              -0.049064276300278,
              43.609311711216
            ],
            [
              -0.060819613194467,
              43.605876348063
            ],
            [
              -0.073973166600852,
              43.606313465142
            ],
            [
              -0.077224332192671,
              43.590162839246
            ],
            [
              -0.093725408004603,
              43.590665521785
            ],
            [
              -0.096787871742487,
              43.582404857482
            ],
            [
              -0.10893257406459,
              43.582372914877
            ],
            [
              -0.12160532728785,
              43.586239621765
            ],
            [
              -0.12891801130162,
              43.581224268549
            ],
            [
              -0.14809699734982,
              43.585796179458
            ],
            [
              -0.16044860312899,
              43.580574768792
            ],
            [
              -0.17641684233457,
              43.596401195938
            ],
            [
              -0.184802186901,
              43.591150649921
            ],
            [
              -0.20474309579199,
              43.583597668054
            ],
            [
              -0.21060415101777,
              43.593234551989
            ],
            [
              -0.22423605594959,
              43.590402846839
            ],
            [
              -0.23502870973138,
              43.583357698142
            ],
            [
              -0.24283300101136,
              43.584979016847
            ],
            [
              -0.24817456316599,
              43.596658468765
            ],
            [
              -0.25464414089565,
              43.597079809878
            ],
            [
              -0.24720389403099,
              43.615953419221
            ],
            [
              -0.27771465346131,
              43.616200527152
            ],
            [
              -0.27959903054407,
              43.618177993348
            ],
            [
              -0.28211623210758,
              43.643073606308
            ],
            [
              -0.26375883992318,
              43.635925825532
            ],
            [
              -0.26183976696696,
              43.650333852662
            ],
            [
              -0.24316590125334,
              43.654503914277
            ],
            [
              -0.24428632111807,
              43.663120354346
            ],
            [
              -0.23973638497448,
              43.671241337499
            ],
            [
              -0.25205576868858,
              43.672170366192
            ],
            [
              -0.2558968586296,
              43.679785947966
            ],
            [
              -0.23910090516212,
              43.693946573483
            ],
            [
              -0.24762242324933,
              43.705844967214
            ],
            [
              -0.2466519035345,
              43.710665978321
            ],
            [
              -0.23258474697029,
              43.714085612502
            ],
            [
              -0.20579650925303,
              43.727838442023
            ],
            [
              -0.19413824181548,
              43.737015033058
            ],
            [
              -0.19556855881059,
              43.7457681747
            ],
            [
              -0.20605854570527,
              43.750073485568
            ],
            [
              -0.21894685864266,
              43.750867558794
            ],
            [
              -0.2079131727813,
              43.760637827854
            ],
            [
              -0.21546661332355,
              43.76954000666
            ],
            [
              -0.21332509113417,
              43.779092079667
            ],
            [
              -0.2221263729722,
              43.7870916829
            ],
            [
              -0.2188522220883,
              43.796306415322
            ],
            [
              -0.22713966573848,
              43.808271178562
            ],
            [
              -0.21750272546627,
              43.809928705523
            ],
            [
              -0.1924617341874,
              43.810177903112
            ],
            [
              -0.19725593392987,
              43.830783419213
            ],
            [
              -0.18812879425044,
              43.832561081629
            ],
            [
              -0.19596777254111,
              43.845942065138
            ],
            [
              -0.20890830935508,
              43.857673133295
            ],
            [
              -0.19855770088986,
              43.862412899782
            ],
            [
              -0.19099207269451,
              43.875181537803
            ],
            [
              -0.20122569807121,
              43.885467279461
            ],
            [
              -0.22061963081894,
              43.890378123713
            ],
            [
              -0.23393612239966,
              43.89106512629
            ],
            [
              -0.23443742350753,
              43.899009673945
            ],
            [
              -0.22763125359284,
              43.91098862727
            ],
            [
              -0.21627342564629,
              43.907357211722
            ],
            [
              -0.19978663649385,
              43.915110256293
            ],
            [
              -0.19202409075549,
              43.92603943497
            ],
            [
              -0.18485270811369,
              43.928769857675
            ],
            [
              -0.17911005329495,
              43.937915232082
            ],
            [
              -0.16562386692845,
              43.927613195924
            ],
            [
              -0.15391959185065,
              43.93232464077
            ],
            [
              -0.15333166658385,
              43.939048046681
            ],
            [
              -0.13513697119614,
              43.936297912997
            ],
            [
              -0.12594278448243,
              43.944421563926
            ],
            [
              -0.10225702569033,
              43.927416125055
            ],
            [
              -0.094989077566145,
              43.933381091475
            ],
            [
              -0.098329558338237,
              43.942427984825
            ],
            [
              -0.087028531609323,
              43.947486286421
            ],
            [
              -0.073597153405665,
              43.94504601184
            ],
            [
              -0.059361902039375,
              43.960939386464
            ],
            [
              -0.046527042815464,
              43.96103952555
            ],
            [
              -0.040802721725543,
              43.968964305724
            ],
            [
              -0.036164948567139,
              43.983735934778
            ],
            [
              -0.021769333384946,
              43.973056989329
            ],
            [
              0.0014399806732066,
              43.95988700889
            ],
            [
              0.0067349635456505,
              43.953740495039
            ],
            [
              0.0042089573126749,
              43.943424094056
            ],
            [
              -0.015361195667702,
              43.934152340481
            ],
            [
              -0.013582447289478,
              43.923649897266
            ],
            [
              -0.001843455443284,
              43.921420036587
            ],
            [
              0.017439868557199,
              43.910847797935
            ],
            [
              0.032551602220287,
              43.900191508039
            ],
            [
              0.045526933200978,
              43.902166309486
            ],
            [
              0.059162622123353,
              43.897893022976
            ],
            [
              0.07664444687609,
              43.916470177201
            ],
            [
              0.068507868767624,
              43.937809939581
            ],
            [
              0.057457862003849,
              43.949050217484
            ],
            [
              0.055307282518792,
              43.957963073751
            ],
            [
              0.067025223808929,
              43.967876773509
            ],
            [
              0.067532020801164,
              43.974192750661
            ],
            [
              0.076043349292038,
              43.983138573142
            ]
          ]
        ]
      },
      "properties": {
        "code": "32",
        "nom": "Gers",
        "population": 191819,
        "delitspour1000": "3,295",
        "nombremosque": 10,
        "parentsEtrangers": "80,5",
        "benefRsa": 7245
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3.3583619084885,
              43.913829285364
            ],
            [
              3.3678750326495,
              43.914132443473
            ],
            [
              3.3927874913122,
              43.910912953071
            ],
            [
              3.4010238544621,
              43.915044422349
            ],
            [
              3.4244530269452,
              43.911595449326
            ],
            [
              3.440272342306,
              43.896836575963
            ],
            [
              3.435601748933,
              43.893601991439
            ],
            [
              3.4319532718491,
              43.877525493585
            ],
            [
              3.4237878122134,
              43.872967817811
            ],
            [
              3.4351765269299,
              43.862954436093
            ],
            [
              3.4627186279115,
              43.872050177712
            ],
            [
              3.4793880701921,
              43.888311318601
            ],
            [
              3.4963834950416,
              43.890291758592
            ],
            [
              3.5133407872323,
              43.896202538664
            ],
            [
              3.5232838820633,
              43.88592647767
            ],
            [
              3.5236533319403,
              43.864744516037
            ],
            [
              3.5307033912378,
              43.855622902101
            ],
            [
              3.5537670157108,
              43.851757367586
            ],
            [
              3.5608183961616,
              43.856092339403
            ],
            [
              3.5778592558387,
              43.843915506292
            ],
            [
              3.5994165900688,
              43.854725318734
            ],
            [
              3.5838874048945,
              43.858770150895
            ],
            [
              3.5754482226841,
              43.865520441109
            ],
            [
              3.5907814278887,
              43.883786684839
            ],
            [
              3.601127090479,
              43.888106697474
            ],
            [
              3.6004803925986,
              43.893113127839
            ],
            [
              3.623586076068,
              43.916974081399
            ],
            [
              3.6389855801748,
              43.916190301943
            ],
            [
              3.6326798807854,
              43.89958564047
            ],
            [
              3.6463988237395,
              43.900956043784
            ],
            [
              3.6467661529193,
              43.914593850368
            ],
            [
              3.66894822031,
              43.910390556955
            ],
            [
              3.6724593534735,
              43.911602863609
            ],
            [
              3.6830143040518,
              43.937357933704
            ],
            [
              3.6822324562632,
              43.946862256351
            ],
            [
              3.7068835657127,
              43.96137743879
            ],
            [
              3.7172714103966,
              43.96098126934
            ],
            [
              3.7269915449947,
              43.969012654585
            ],
            [
              3.7437633928395,
              43.966603906206
            ],
            [
              3.7547927950616,
              43.969527164685
            ],
            [
              3.7873312270452,
              43.966868530499
            ],
            [
              3.7889697272882,
              43.950103854855
            ],
            [
              3.7957694715927,
              43.942541286137
            ],
            [
              3.8203070382416,
              43.942407548895
            ],
            [
              3.8271147893385,
              43.935831173484
            ],
            [
              3.8249029799143,
              43.919298658233
            ],
            [
              3.8160599235706,
              43.906903774151
            ],
            [
              3.7995411771349,
              43.890816352208
            ],
            [
              3.8060703531318,
              43.877906781933
            ],
            [
              3.8223595629257,
              43.874817167469
            ],
            [
              3.8308032102239,
              43.866420388072
            ],
            [
              3.838000525505,
              43.870736523523
            ],
            [
              3.8520982532517,
              43.872258127824
            ],
            [
              3.8625507527171,
              43.876941409624
            ],
            [
              3.8971005838609,
              43.877591714061
            ],
            [
              3.9173912408729,
              43.885588669063
            ],
            [
              3.9192427620703,
              43.86633319658
            ],
            [
              3.9221023508338,
              43.858379360183
            ],
            [
              3.9406937567898,
              43.853353596339
            ],
            [
              3.9578947740968,
              43.854375717801
            ],
            [
              3.9590304240826,
              43.843640723232
            ],
            [
              3.9793506370893,
              43.842882414799
            ],
            [
              3.9691754635094,
              43.823599650333
            ],
            [
              3.9617168853344,
              43.816779178746
            ],
            [
              3.9612080559067,
              43.805734910291
            ],
            [
              3.9743510107924,
              43.801475760403
            ],
            [
              4.0020412274265,
              43.811466287649
            ],
            [
              4.0237520570046,
              43.803317034121
            ],
            [
              4.0535254469216,
              43.785607465957
            ],
            [
              4.0539990747233,
              43.772209820497
            ],
            [
              4.0740348683194,
              43.767824009854
            ],
            [
              4.0855565937042,
              43.767491135323
            ],
            [
              4.0929650066929,
              43.762334159626
            ],
            [
              4.0936667866111,
              43.75246912537
            ],
            [
              4.1076547111226,
              43.744374099083
            ],
            [
              4.1140327584688,
              43.737742196664
            ],
            [
              4.1306521100217,
              43.74099338313
            ],
            [
              4.1447760175493,
              43.733889286988
            ],
            [
              4.1517881596493,
              43.717345625893
            ],
            [
              4.1636282950784,
              43.70657354555
            ],
            [
              4.1635687299349,
              43.686542859988
            ],
            [
              4.1713727433509,
              43.680600684543
            ],
            [
              4.1944474773799,
              43.65430735698
            ],
            [
              4.1910110238358,
              43.639234913391
            ],
            [
              4.1687609627909,
              43.609028138017
            ],
            [
              4.1552059961506,
              43.599475266219
            ],
            [
              4.1502119605016,
              43.585561607838
            ],
            [
              4.1144625451015,
              43.587296649227
            ],
            [
              4.0989142083254,
              43.585240546919
            ],
            [
              4.0959106624216,
              43.581523957869
            ],
            [
              4.1092403057439,
              43.568563266822
            ],
            [
              4.1010404384135,
              43.554370958977
            ],
            [
              4.0663488317709,
              43.557386111903
            ],
            [
              4.0422520205905,
              43.556806676461
            ],
            [
              4.0111840974253,
              43.552446664056
            ],
            [
              3.9685717523119,
              43.540018483347
            ],
            [
              3.9071379792073,
              43.516845546072
            ],
            [
              3.8864007764545,
              43.50687119465
            ],
            [
              3.8496233013451,
              43.485360022977
            ],
            [
              3.8288196651265,
              43.469199818772
            ],
            [
              3.818529911629,
              43.458313042789
            ],
            [
              3.7966687778375,
              43.441038572476
            ],
            [
              3.7745683768076,
              43.431420834377
            ],
            [
              3.7462817628759,
              43.423623789327
            ],
            [
              3.724920103348,
              43.41579312533
            ],
            [
              3.7230347632352,
              43.401095090434
            ],
            [
              3.715556142844,
              43.400687731567
            ],
            [
              3.6919033474737,
              43.391883457324
            ],
            [
              3.6621617057021,
              43.392279948911
            ],
            [
              3.6460792124476,
              43.384774104561
            ],
            [
              3.6196750009169,
              43.367836073788
            ],
            [
              3.6026227584831,
              43.355428335099
            ],
            [
              3.5661200382653,
              43.325717677552
            ],
            [
              3.5273014618389,
              43.28981147991
            ],
            [
              3.5109592064433,
              43.273328841308
            ],
            [
              3.4683351175459,
              43.276598485695
            ],
            [
              3.4303317007418,
              43.290210436006
            ],
            [
              3.4034831432597,
              43.287900440396
            ],
            [
              3.3869052230084,
              43.284136091601
            ],
            [
              3.3438584522568,
              43.270394222721
            ],
            [
              3.2871141319468,
              43.241993153845
            ],
            [
              3.2634509331275,
              43.228864618646
            ],
            [
              3.2405623482295,
              43.212804132866
            ],
            [
              3.2270145112782,
              43.220856210927
            ],
            [
              3.2103074775286,
              43.235634956703
            ],
            [
              3.2010228712178,
              43.249170753058
            ],
            [
              3.1872588808739,
              43.251069218863
            ],
            [
              3.1761517931696,
              43.244766891665
            ],
            [
              3.1602360154965,
              43.243174322456
            ],
            [
              3.1472054984849,
              43.247002373241
            ],
            [
              3.1416212770887,
              43.25950707604
            ],
            [
              3.1234732643417,
              43.261259155076
            ],
            [
              3.1137077582144,
              43.253719157821
            ],
            [
              3.0922472050834,
              43.26057519433
            ],
            [
              3.079270269083,
              43.262049045594
            ],
            [
              3.0548510452049,
              43.279719592179
            ],
            [
              3.0273416696418,
              43.282047301001
            ],
            [
              3.0158720189579,
              43.276359929439
            ],
            [
              3.0047360120811,
              43.288533755393
            ],
            [
              3.0062863930421,
              43.30013353204
            ],
            [
              3.0043529644297,
              43.320131141473
            ],
            [
              2.9808455873158,
              43.317576526586
            ],
            [
              2.9592121788394,
              43.312556289807
            ],
            [
              2.9450352296993,
              43.312502335561
            ],
            [
              2.9212516184831,
              43.326085683565
            ],
            [
              2.9072731105699,
              43.321649551962
            ],
            [
              2.8866989626319,
              43.329898940957
            ],
            [
              2.8847983721595,
              43.334409274335
            ],
            [
              2.8918220878429,
              43.344097060191
            ],
            [
              2.8879060996736,
              43.354244172963
            ],
            [
              2.8898465644215,
              43.363912452239
            ],
            [
              2.8788663038272,
              43.371031957894
            ],
            [
              2.8678099171678,
              43.382193201942
            ],
            [
              2.856190460919,
              43.374063805275
            ],
            [
              2.8567239052152,
              43.370318505817
            ],
            [
              2.8695713933014,
              43.355981456072
            ],
            [
              2.873162273491,
              43.339374603901
            ],
            [
              2.8683187275794,
              43.329585760434
            ],
            [
              2.8327571187304,
              43.32241279802
            ],
            [
              2.8127141223172,
              43.323485536792
            ],
            [
              2.8061929608751,
              43.315954436466
            ],
            [
              2.8182231073238,
              43.309421905527
            ],
            [
              2.7917850058686,
              43.293011945881
            ],
            [
              2.7866092768635,
              43.285602954977
            ],
            [
              2.7869260612143,
              43.273513557976
            ],
            [
              2.7722469913722,
              43.257786542768
            ],
            [
              2.7525229453227,
              43.254595672077
            ],
            [
              2.7378850343813,
              43.267338267596
            ],
            [
              2.7183505585497,
              43.275460644919
            ],
            [
              2.7073810722808,
              43.273521745096
            ],
            [
              2.7002183864224,
              43.282315974529
            ],
            [
              2.7045208788416,
              43.299311631051
            ],
            [
              2.7002208941951,
              43.302210812267
            ],
            [
              2.6995425883767,
              43.312542380597
            ],
            [
              2.6935858291614,
              43.320491486093
            ],
            [
              2.6788510727126,
              43.314743561061
            ],
            [
              2.6766363183171,
              43.305455086206
            ],
            [
              2.6590635926054,
              43.292968989423
            ],
            [
              2.6317588557561,
              43.29732511202
            ],
            [
              2.6273790095425,
              43.288782167534
            ],
            [
              2.6164099655732,
              43.289440064412
            ],
            [
              2.6016055484715,
              43.298706095944
            ],
            [
              2.5973560369523,
              43.313749359729
            ],
            [
              2.5922057460473,
              43.317850178713
            ],
            [
              2.5890408539009,
              43.328703501465
            ],
            [
              2.5808065052268,
              43.334662344403
            ],
            [
              2.5551007597984,
              43.337377239875
            ],
            [
              2.5399656073586,
              43.346221878067
            ],
            [
              2.5522942769636,
              43.363891729209
            ],
            [
              2.5552203547235,
              43.388318929825
            ],
            [
              2.5692167745323,
              43.400304806184
            ],
            [
              2.5860594271674,
              43.398964348129
            ],
            [
              2.5913265352699,
              43.402780865486
            ],
            [
              2.593464677472,
              43.41258881974
            ],
            [
              2.5792769693109,
              43.415722124273
            ],
            [
              2.5657824745312,
              43.422957925066
            ],
            [
              2.5840785671228,
              43.425524480755
            ],
            [
              2.5901376675255,
              43.429563678533
            ],
            [
              2.6060762155363,
              43.431097353655
            ],
            [
              2.6093386865917,
              43.440409050201
            ],
            [
              2.6201477651364,
              43.441423623131
            ],
            [
              2.6461799185216,
              43.459128324826
            ],
            [
              2.6378726798682,
              43.465226848944
            ],
            [
              2.6477479384405,
              43.46889502584
            ],
            [
              2.6646131742074,
              43.463621465253
            ],
            [
              2.6679938118926,
              43.470045739812
            ],
            [
              2.6595374969188,
              43.48016534963
            ],
            [
              2.6549028733756,
              43.496866257055
            ],
            [
              2.660197072431,
              43.503196229293
            ],
            [
              2.6586451413796,
              43.525794979317
            ],
            [
              2.6278174705033,
              43.551599708027
            ],
            [
              2.6156009876353,
              43.566636032386
            ],
            [
              2.631783900316,
              43.584146522163
            ],
            [
              2.6275661079079,
              43.590933474599
            ],
            [
              2.6146368129343,
              43.599858204147
            ],
            [
              2.6280316578289,
              43.614603664441
            ],
            [
              2.6352029674453,
              43.625770020949
            ],
            [
              2.6432649887562,
              43.650141667656
            ],
            [
              2.6538560490336,
              43.649167216547
            ],
            [
              2.6855446430858,
              43.651325814028
            ],
            [
              2.7111682176412,
              43.645600379862
            ],
            [
              2.7240271983999,
              43.639996556656
            ],
            [
              2.7389591104087,
              43.630225717137
            ],
            [
              2.7435418358694,
              43.622064780506
            ],
            [
              2.7545198343058,
              43.614140577604
            ],
            [
              2.7672355905264,
              43.61724579423
            ],
            [
              2.7758876987511,
              43.623236718731
            ],
            [
              2.7948563475217,
              43.624431292653
            ],
            [
              2.8159223190021,
              43.640061402207
            ],
            [
              2.8292347639089,
              43.638059497972
            ],
            [
              2.8402202097763,
              43.642531059545
            ],
            [
              2.8574751278235,
              43.643382854664
            ],
            [
              2.874245630292,
              43.647265454371
            ],
            [
              2.8789777619922,
              43.65668899636
            ],
            [
              2.907351068949,
              43.654226226268
            ],
            [
              2.9159852376449,
              43.660020748267
            ],
            [
              2.9197550319868,
              43.680701165204
            ],
            [
              2.9185719132589,
              43.686154965245
            ],
            [
              2.93545676901,
              43.694665082071
            ],
            [
              2.9465514566703,
              43.69294074079
            ],
            [
              2.9598110947795,
              43.699399971151
            ],
            [
              2.985354094531,
              43.708518052513
            ],
            [
              3.0094758861697,
              43.701898593858
            ],
            [
              3.0194605989081,
              43.695490521581
            ],
            [
              3.0334550457498,
              43.692056102371
            ],
            [
              3.0437823067902,
              43.696691915948
            ],
            [
              3.0607467059948,
              43.693073684319
            ],
            [
              3.062401328145,
              43.702474103432
            ],
            [
              3.069161341669,
              43.70684228008
            ],
            [
              3.056150266167,
              43.717771930329
            ],
            [
              3.053062578971,
              43.746187838209
            ],
            [
              3.0565260080024,
              43.755307021132
            ],
            [
              3.0684631726792,
              43.756809234459
            ],
            [
              3.0758854246252,
              43.768517815391
            ],
            [
              3.0657566296832,
              43.774015383743
            ],
            [
              3.0570585259457,
              43.78263654452
            ],
            [
              3.0555494622909,
              43.792897708039
            ],
            [
              3.0494059238588,
              43.803825230583
            ],
            [
              3.0602887615428,
              43.809425149222
            ],
            [
              3.0598597029877,
              43.831644678221
            ],
            [
              3.064904986059,
              43.835565364534
            ],
            [
              3.0871396285341,
              43.835566967207
            ],
            [
              3.1083746364905,
              43.828954358627
            ],
            [
              3.1291898204361,
              43.816936067597
            ],
            [
              3.1507104327409,
              43.813766023316
            ],
            [
              3.1580394141965,
              43.817584693294
            ],
            [
              3.1776878112357,
              43.813838787452
            ],
            [
              3.2031142100103,
              43.813872681737
            ],
            [
              3.2226912752071,
              43.817777799078
            ],
            [
              3.232134595149,
              43.826137964263
            ],
            [
              3.249039481107,
              43.829579698488
            ],
            [
              3.2510907532261,
              43.842076507358
            ],
            [
              3.2401209483517,
              43.849238408157
            ],
            [
              3.2344573700191,
              43.857562353474
            ],
            [
              3.2362244765786,
              43.866546056431
            ],
            [
              3.2430727112119,
              43.875182911547
            ],
            [
              3.2542327682907,
              43.876752165048
            ],
            [
              3.2603747807982,
              43.883002113882
            ],
            [
              3.2631323492161,
              43.89279670985
            ],
            [
              3.2750747460719,
              43.89835141161
            ],
            [
              3.2868926131443,
              43.893275030194
            ],
            [
              3.2954140626944,
              43.895074688924
            ],
            [
              3.3200536423899,
              43.891011308338
            ],
            [
              3.3425730593674,
              43.894194468074
            ],
            [
              3.3402696244377,
              43.901731512332
            ],
            [
              3.3583619084885,
              43.913829285364
            ]
          ]
        ]
      },
      "properties": {
        "code": "34",
        "nom": "Hérault",
        "population": 1188973,
        "delitspour1000": "5,915",
        "nombremosque": 46,
        "parentsEtrangers": "67,5",
        "benefRsa": 77371,
        "eoliennes": 155
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              3.8974083668223,
              45.357080180177
            ],
            [
              3.9024783688026,
              45.339149111199
            ],
            [
              3.9083091415165,
              45.333317123265
            ],
            [
              3.9169353286998,
              45.339403551718
            ],
            [
              3.9229587665269,
              45.357997266653
            ],
            [
              3.9353040641977,
              45.358999775526
            ],
            [
              3.9373971009283,
              45.37201719016
            ],
            [
              3.9487771439236,
              45.375106507164
            ],
            [
              3.9622933080873,
              45.369902404517
            ],
            [
              3.9796493264091,
              45.375136167919
            ],
            [
              3.9926142993354,
              45.359099048768
            ],
            [
              4.0026131235678,
              45.355899588451
            ],
            [
              4.0160566045972,
              45.328623259374
            ],
            [
              4.0248202212879,
              45.342887748714
            ],
            [
              4.0432641754966,
              45.355941617091
            ],
            [
              4.053660453716,
              45.352664525343
            ],
            [
              4.062387978027,
              45.339100276272
            ],
            [
              4.0708092191247,
              45.336053711997
            ],
            [
              4.077685114194,
              45.346159204789
            ],
            [
              4.0778214653417,
              45.354983989389
            ],
            [
              4.0992156423607,
              45.359516453522
            ],
            [
              4.1213626119659,
              45.369089362178
            ],
            [
              4.1540232523863,
              45.364602527186
            ],
            [
              4.1582492082396,
              45.372767383985
            ],
            [
              4.1444492305075,
              45.384149823118
            ],
            [
              4.1779313879208,
              45.392600323214
            ],
            [
              4.1933063287861,
              45.384562302303
            ],
            [
              4.2031703045307,
              45.383587778452
            ],
            [
              4.2264286102871,
              45.389719679931
            ],
            [
              4.2445262165178,
              45.384912858435
            ],
            [
              4.2570898487259,
              45.372515018116
            ],
            [
              4.271304361305,
              45.374642941524
            ],
            [
              4.2716927610221,
              45.359824226654
            ],
            [
              4.2847457415048,
              45.356573698914
            ],
            [
              4.2954570761374,
              45.359956219084
            ],
            [
              4.2931596416647,
              45.366252532086
            ],
            [
              4.3082004946916,
              45.370982390408
            ],
            [
              4.3317565838798,
              45.368289462149
            ],
            [
              4.3504424731325,
              45.358204385933
            ],
            [
              4.359714005021,
              45.349582617041
            ],
            [
              4.3665395777096,
              45.336998573027
            ],
            [
              4.3540593156553,
              45.332022340962
            ],
            [
              4.3318936969792,
              45.318070065193
            ],
            [
              4.3512148784724,
              45.304143454091
            ],
            [
              4.3690682180887,
              45.300293499679
            ],
            [
              4.3612845231222,
              45.292316661278
            ],
            [
              4.3587598303903,
              45.28390361935
            ],
            [
              4.3497179288925,
              45.280278452779
            ],
            [
              4.3625476382457,
              45.264397172969
            ],
            [
              4.377876082312,
              45.261966899556
            ],
            [
              4.3864148938233,
              45.255609582426
            ],
            [
              4.391529499389,
              45.264207721996
            ],
            [
              4.4185710642575,
              45.275422301103
            ],
            [
              4.4366003162274,
              45.272738948048
            ],
            [
              4.4488366631474,
              45.257555737167
            ],
            [
              4.4685891000785,
              45.247320406429
            ],
            [
              4.4831320440493,
              45.236445615502
            ],
            [
              4.489606977621,
              45.224787864759
            ],
            [
              4.4877709310323,
              45.216341441865
            ],
            [
              4.4811266509979,
              45.215740791502
            ],
            [
              4.4803647181332,
              45.195328463561
            ],
            [
              4.4725368542422,
              45.179407156759
            ],
            [
              4.4592634434439,
              45.175018271087
            ],
            [
              4.4580688071034,
              45.168373285647
            ],
            [
              4.4473475059375,
              45.157049559665
            ],
            [
              4.4432601992355,
              45.14083118833
            ],
            [
              4.4459864977865,
              45.133580616944
            ],
            [
              4.457292871748,
              45.119021852513
            ],
            [
              4.4377435075056,
              45.121669278229
            ],
            [
              4.4370065836344,
              45.109530343216
            ],
            [
              4.4270606178391,
              45.116418502371
            ],
            [
              4.4316895547309,
              45.128307683233
            ],
            [
              4.4227050210625,
              45.137284079421
            ],
            [
              4.4115999169283,
              45.140814831397
            ],
            [
              4.3991767622718,
              45.140092398517
            ],
            [
              4.3765615334121,
              45.144361359671
            ],
            [
              4.3702243365423,
              45.130296355428
            ],
            [
              4.3793754194623,
              45.122983730837
            ],
            [
              4.3888401749693,
              45.122863564048
            ],
            [
              4.400228531564,
              45.116002199184
            ],
            [
              4.3995705528059,
              45.104758184855
            ],
            [
              4.3842146497902,
              45.101478325023
            ],
            [
              4.3753034115222,
              45.10482784163
            ],
            [
              4.3598684623641,
              45.099648200079
            ],
            [
              4.3642255307444,
              45.092650063289
            ],
            [
              4.3576519784305,
              45.086911103409
            ],
            [
              4.3587771551575,
              45.080795413456
            ],
            [
              4.3464253885296,
              45.074635356183
            ],
            [
              4.3492762702278,
              45.065000396792
            ],
            [
              4.3647163026964,
              45.054514848088
            ],
            [
              4.3780184377404,
              45.054268232044
            ],
            [
              4.3817210801496,
              45.041578104264
            ],
            [
              4.3788076747538,
              45.036179566224
            ],
            [
              4.35676149337,
              45.033641652456
            ],
            [
              4.3519275289554,
              45.027736342466
            ],
            [
              4.3146755888177,
              45.030787606494
            ],
            [
              4.2972444541478,
              45.017019865124
            ],
            [
              4.2898240145874,
              44.997302372073
            ],
            [
              4.2982695452624,
              44.989167246444
            ],
            [
              4.3132330668007,
              44.979575161458
            ],
            [
              4.3116688879015,
              44.971778239838
            ],
            [
              4.2915838185243,
              44.967389275782
            ],
            [
              4.2745372987383,
              44.96596145392
            ],
            [
              4.2579311130587,
              44.959979531121
            ],
            [
              4.2499552025599,
              44.960567429204
            ],
            [
              4.2359884866829,
              44.970400050285
            ],
            [
              4.2217077816979,
              44.961339674715
            ],
            [
              4.220666560271,
              44.944801368362
            ],
            [
              4.2158514034226,
              44.932720359535
            ],
            [
              4.1899363021143,
              44.91419296439
            ],
            [
              4.1828755040663,
              44.905432217467
            ],
            [
              4.179950040218,
              44.887303097557
            ],
            [
              4.1682848434293,
              44.882764822524
            ],
            [
              4.1561572312146,
              44.873329080776
            ],
            [
              4.1350704806238,
              44.874641562484
            ],
            [
              4.1177586522935,
              44.878155973537
            ],
            [
              4.1036755693091,
              44.876969598315
            ],
            [
              4.0801862657178,
              44.868327782609
            ],
            [
              4.0396337860339,
              44.873161160486
            ],
            [
              4.0287308003672,
              44.866167427344
            ],
            [
              4.0250473774012,
              44.850201171515
            ],
            [
              4.0201700534284,
              44.84572335446
            ],
            [
              4.0283799355707,
              44.834081856378
            ],
            [
              4.0165879015814,
              44.83087972993
            ],
            [
              4.0134026694452,
              44.836820914772
            ],
            [
              4.0042371196017,
              44.836053356709
            ],
            [
              4.0059233163163,
              44.827253766785
            ],
            [
              3.9956955079053,
              44.821280942908
            ],
            [
              3.9897119503368,
              44.833515968039
            ],
            [
              3.9800025103294,
              44.830097794485
            ],
            [
              3.9885657294166,
              44.815261634325
            ],
            [
              3.9817003652052,
              44.811765152168
            ],
            [
              3.9668397720926,
              44.820478207899
            ],
            [
              3.96317818398,
              44.829753498309
            ],
            [
              3.9455779540045,
              44.824415193964
            ],
            [
              3.9369998892174,
              44.795419028511
            ],
            [
              3.9277704016304,
              44.792980070287
            ],
            [
              3.9181323077203,
              44.775611923372
            ],
            [
              3.924795349749,
              44.769623984123
            ],
            [
              3.8998742192715,
              44.763530139601
            ],
            [
              3.8965835164906,
              44.75601085378
            ],
            [
              3.8694563032288,
              44.754798141303
            ],
            [
              3.8625310211191,
              44.743866105932
            ],
            [
              3.8365590308337,
              44.747847636802
            ],
            [
              3.8304555521813,
              44.756161500223
            ],
            [
              3.8428722625525,
              44.767659869676
            ],
            [
              3.841603226393,
              44.772129900378
            ],
            [
              3.8301463157408,
              44.775710594028
            ],
            [
              3.8198835993274,
              44.772685233514
            ],
            [
              3.8121315885881,
              44.766032837865
            ],
            [
              3.8033308935883,
              44.770414179887
            ],
            [
              3.8032312316337,
              44.781579343618
            ],
            [
              3.7794867433517,
              44.79731064671
            ],
            [
              3.7587613653652,
              44.805576543778
            ],
            [
              3.7624285152172,
              44.810666360605
            ],
            [
              3.7526807081682,
              44.82097908395
            ],
            [
              3.7406494702404,
              44.838697356604
            ],
            [
              3.7228844822867,
              44.830986703517
            ],
            [
              3.7003857822671,
              44.836813194859
            ],
            [
              3.6954389564378,
              44.830958625259
            ],
            [
              3.6663623630222,
              44.828776303359
            ],
            [
              3.6574209674758,
              44.836124356852
            ],
            [
              3.6718167758914,
              44.843382404889
            ],
            [
              3.6704785324924,
              44.850864431429
            ],
            [
              3.6595224161061,
              44.86994134545
            ],
            [
              3.6423461696965,
              44.878382169756
            ],
            [
              3.6264517258551,
              44.880253899231
            ],
            [
              3.6147496711134,
              44.874174272057
            ],
            [
              3.6082755428246,
              44.879331218549
            ],
            [
              3.5943335024029,
              44.875433384901
            ],
            [
              3.5980348128056,
              44.859504969144
            ],
            [
              3.5920659249124,
              44.84796463101
            ],
            [
              3.5893552432085,
              44.832194014444
            ],
            [
              3.578465058473,
              44.825696757681
            ],
            [
              3.5745418907117,
              44.83460991769
            ],
            [
              3.5638205108562,
              44.832831791958
            ],
            [
              3.5615758039452,
              44.824976812591
            ],
            [
              3.5397515164373,
              44.828176022752
            ],
            [
              3.5056459727137,
              44.823846663279
            ],
            [
              3.4948664240388,
              44.815405935965
            ],
            [
              3.4909959832186,
              44.808474309867
            ],
            [
              3.478809589921,
              44.809446829457
            ],
            [
              3.4757711698307,
              44.815370629667
            ],
            [
              3.4570624789322,
              44.833777540962
            ],
            [
              3.4541989644633,
              44.844563734203
            ],
            [
              3.4416742987403,
              44.854449680958
            ],
            [
              3.4386254407679,
              44.86082184192
            ],
            [
              3.4357050101121,
              44.88024496334
            ],
            [
              3.417976841379,
              44.900767433551
            ],
            [
              3.4192590880448,
              44.908428284947
            ],
            [
              3.413833246774,
              44.919859843574
            ],
            [
              3.4149529202982,
              44.932631887178
            ],
            [
              3.412832200087,
              44.944841611062
            ],
            [
              3.4045530849223,
              44.956400427592
            ],
            [
              3.3969930219391,
              44.958577768116
            ],
            [
              3.3863677767162,
              44.952742049715
            ],
            [
              3.3713550433617,
              44.960250089988
            ],
            [
              3.3703185777492,
              44.96998390468
            ],
            [
              3.3613476310625,
              44.971408091786
            ],
            [
              3.3479469309243,
              44.973870126914
            ],
            [
              3.3467980934789,
              44.987464025416
            ],
            [
              3.3647931807115,
              44.990591133341
            ],
            [
              3.3699091459722,
              45.000582005192
            ],
            [
              3.3499123069278,
              45.002602305465
            ],
            [
              3.3471935905944,
              45.014231193671
            ],
            [
              3.3304042236218,
              45.007897658864
            ],
            [
              3.3249714515026,
              45.009790053743
            ],
            [
              3.3371714464095,
              45.025342171262
            ],
            [
              3.3288068281374,
              45.030050111422
            ],
            [
              3.3105771648058,
              45.021342574102
            ],
            [
              3.2972465044248,
              45.036076897709
            ],
            [
              3.3029161653541,
              45.049161419106
            ],
            [
              3.2994724846322,
              45.060984807099
            ],
            [
              3.3104512763056,
              45.074436414647
            ],
            [
              3.3142746628813,
              45.085018914722
            ],
            [
              3.2973357212079,
              45.091373605878
            ],
            [
              3.2874533238806,
              45.089020664877
            ],
            [
              3.2833105792233,
              45.103764571827
            ],
            [
              3.3009895867502,
              45.106847159996
            ],
            [
              3.3052413548281,
              45.101245881124
            ],
            [
              3.3257877003029,
              45.095136110288
            ],
            [
              3.3481183205186,
              45.100925761005
            ],
            [
              3.3507814869451,
              45.109389356926
            ],
            [
              3.3458478444637,
              45.112462558565
            ],
            [
              3.3317703613755,
              45.110834170392
            ],
            [
              3.3013698906648,
              45.124093455888
            ],
            [
              3.2880281118685,
              45.120420821148
            ],
            [
              3.2771175354501,
              45.14173327527
            ],
            [
              3.2801638710759,
              45.152748540438
            ],
            [
              3.2689188872567,
              45.153868408758
            ],
            [
              3.2624392836968,
              45.168271591934
            ],
            [
              3.2609061148723,
              45.182035396308
            ],
            [
              3.27095870007,
              45.189800103738
            ],
            [
              3.2724807359217,
              45.209183446862
            ],
            [
              3.2395682874116,
              45.216949421531
            ],
            [
              3.2353557133346,
              45.220087359774
            ],
            [
              3.2344165982605,
              45.237646801741
            ],
            [
              3.2235618386219,
              45.248407587484
            ],
            [
              3.2269226623347,
              45.255474618741
            ],
            [
              3.2224601549298,
              45.27197325761
            ],
            [
              3.212582002963,
              45.280633967095
            ],
            [
              3.204971349739,
              45.281991626537
            ],
            [
              3.1863759697233,
              45.277320274896
            ],
            [
              3.1677609638906,
              45.270173275741
            ],
            [
              3.1691531773693,
              45.282263132859
            ],
            [
              3.1597095041841,
              45.295152377879
            ],
            [
              3.1400139343437,
              45.288451588095
            ],
            [
              3.1180031054796,
              45.284189993561
            ],
            [
              3.105148500604,
              45.290981303514
            ],
            [
              3.0822533822787,
              45.289688544009
            ],
            [
              3.0873008074715,
              45.300090176966
            ],
            [
              3.1034918787719,
              45.300169117338
            ],
            [
              3.1138141353708,
              45.309094157134
            ],
            [
              3.1118263845109,
              45.313740635219
            ],
            [
              3.0986731324196,
              45.316890142915
            ],
            [
              3.0906259135448,
              45.322941615477
            ],
            [
              3.1021782492329,
              45.32829200366
            ],
            [
              3.1134170784877,
              45.327179037948
            ],
            [
              3.1132110011179,
              45.340720132847
            ],
            [
              3.1035006854512,
              45.3543721973
            ],
            [
              3.1243903879717,
              45.350839197184
            ],
            [
              3.1418440537677,
              45.353463066252
            ],
            [
              3.1509333921975,
              45.35117485202
            ],
            [
              3.1680632131655,
              45.352256205748
            ],
            [
              3.184746858378,
              45.363770092966
            ],
            [
              3.1944913481393,
              45.362570404448
            ],
            [
              3.2018988030737,
              45.371105286733
            ],
            [
              3.2186572489279,
              45.36948993409
            ],
            [
              3.2209136476108,
              45.378918658473
            ],
            [
              3.2365752879669,
              45.394473576099
            ],
            [
              3.2500841563678,
              45.389044204681
            ],
            [
              3.2718909747067,
              45.392762734491
            ],
            [
              3.2906906080245,
              45.401824597103
            ],
            [
              3.2956970631794,
              45.418111657039
            ],
            [
              3.3106626355534,
              45.417352619698
            ],
            [
              3.3243232056899,
              45.40642823764
            ],
            [
              3.333673054382,
              45.405812794289
            ],
            [
              3.3409855357626,
              45.414654053148
            ],
            [
              3.3408514694448,
              45.421358421192
            ],
            [
              3.3589872444879,
              45.421013675007
            ],
            [
              3.3759878802138,
              45.404482132477
            ],
            [
              3.3895002199315,
              45.402409047019
            ],
            [
              3.4061079085888,
              45.403388343793
            ],
            [
              3.4200366594148,
              45.397225547231
            ],
            [
              3.4307265352749,
              45.401799869217
            ],
            [
              3.4567491182322,
              45.400282212799
            ],
            [
              3.4769133999367,
              45.418251783397
            ],
            [
              3.5013193341285,
              45.427582294546
            ],
            [
              3.5051637048024,
              45.412966539276
            ],
            [
              3.5209053900855,
              45.402409376819
            ],
            [
              3.5482813829238,
              45.398606670195
            ],
            [
              3.5630784498473,
              45.394913393775
            ],
            [
              3.5705803547836,
              45.402095477198
            ],
            [
              3.5790230806783,
              45.385627478313
            ],
            [
              3.5880448340552,
              45.375224123083
            ],
            [
              3.5853554957634,
              45.369159103591
            ],
            [
              3.5903439892848,
              45.357222362784
            ],
            [
              3.6032776845893,
              45.350787096604
            ],
            [
              3.6077869899808,
              45.34376244024
            ],
            [
              3.618619523185,
              45.337709571841
            ],
            [
              3.6258417624803,
              45.338345687886
            ],
            [
              3.6408772711819,
              45.348436898172
            ],
            [
              3.6442124864011,
              45.356129442181
            ],
            [
              3.6631634885681,
              45.361580190594
            ],
            [
              3.667215400603,
              45.376197053107
            ],
            [
              3.6798572026821,
              45.38418849136
            ],
            [
              3.6905091154115,
              45.373352652913
            ],
            [
              3.7046866335921,
              45.368500802746
            ],
            [
              3.6998935179885,
              45.35946388081
            ],
            [
              3.7196851785893,
              45.360848568164
            ],
            [
              3.7408397385734,
              45.353034889768
            ],
            [
              3.7605343425153,
              45.354693042752
            ],
            [
              3.7722592400978,
              45.349189155916
            ],
            [
              3.7826175271352,
              45.354912800951
            ],
            [
              3.7899914752805,
              45.353880391851
            ],
            [
              3.7855713393306,
              45.369279292494
            ],
            [
              3.799851297461,
              45.390417363783
            ],
            [
              3.8083112095965,
              45.391610156832
            ],
            [
              3.8217289223052,
              45.38101993984
            ],
            [
              3.8352820447204,
              45.376045308773
            ],
            [
              3.8376831375527,
              45.36571035068
            ],
            [
              3.8504123377727,
              45.364998489678
            ],
            [
              3.8569484852367,
              45.359322343572
            ],
            [
              3.8783000989899,
              45.354952490372
            ],
            [
              3.8921536549569,
              45.354049640821
            ],
            [
              3.8974083668223,
              45.357080180177
            ]
          ]
        ]
      },
      "properties": {
        "code": "43",
        "nom": "Haute-Loire",
        "population": 227489,
        "delitspour1000": "3,284",
        "nombremosque": 3,
        "parentsEtrangers": "89,8",
        "benefRsa": 5577,
        "eoliennes": 41
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1.1485668205651,
              47.029548900794
            ],
            [
              -1.2027794819453,
              47.041805126785
            ],
            [
              -1.2102060449359,
              47.050536062417
            ],
            [
              -1.2203746378854,
              47.05485937794
            ],
            [
              -1.2306516586586,
              47.064762478179
            ],
            [
              -1.2496771467986,
              47.069911651239
            ],
            [
              -1.2712120445468,
              47.083893903306
            ],
            [
              -1.290326283889,
              47.070189965192
            ],
            [
              -1.2866136600138,
              47.061076054511
            ],
            [
              -1.2974500810349,
              47.050827050519
            ],
            [
              -1.294639880423,
              47.039478778925
            ],
            [
              -1.3006089447707,
              47.034246813955
            ],
            [
              -1.3124143046091,
              47.033512233193
            ],
            [
              -1.3271459700488,
              47.037743191051
            ],
            [
              -1.3354906033185,
              47.043269269841
            ],
            [
              -1.3533918387699,
              47.039381673996
            ],
            [
              -1.3651395889351,
              47.039815208849
            ],
            [
              -1.3786187507728,
              47.030850021718
            ],
            [
              -1.3670760244076,
              47.019604066339
            ],
            [
              -1.3651713270515,
              47.006787081572
            ],
            [
              -1.3696536707446,
              46.996374692661
            ],
            [
              -1.3775056345619,
              46.990942402401
            ],
            [
              -1.3590627464638,
              46.981135337897
            ],
            [
              -1.3584324588956,
              46.973883203207
            ],
            [
              -1.3685912013826,
              46.965126922857
            ],
            [
              -1.3651258550545,
              46.956916369701
            ],
            [
              -1.3729867918168,
              46.952180820897
            ],
            [
              -1.4181553939744,
              46.943055593083
            ],
            [
              -1.4561287088222,
              46.92626936023
            ],
            [
              -1.4715698702165,
              46.933593956324
            ],
            [
              -1.4671325140377,
              46.943378459256
            ],
            [
              -1.4572099023003,
              46.954650198908
            ],
            [
              -1.461236428386,
              46.962308344919
            ],
            [
              -1.4719848976051,
              46.969179954536
            ],
            [
              -1.4759176820598,
              46.983821193651
            ],
            [
              -1.460243586512,
              46.992964149783
            ],
            [
              -1.4691768674665,
              47.001990413026
            ],
            [
              -1.4760445820058,
              47.026891881408
            ],
            [
              -1.4821269400681,
              47.037858547324
            ],
            [
              -1.4918917982953,
              47.041766785139
            ],
            [
              -1.5071112498984,
              47.036732846058
            ],
            [
              -1.5157201024778,
              47.027729878659
            ],
            [
              -1.5523193513555,
              47.018345265795
            ],
            [
              -1.5509366648366,
              47.008348909087
            ],
            [
              -1.542669701774,
              47.003269318873
            ],
            [
              -1.5510080622522,
              46.991142653886
            ],
            [
              -1.5535692540762,
              46.98150813307
            ],
            [
              -1.5410863770521,
              46.955931191708
            ],
            [
              -1.530901951403,
              46.944983490916
            ],
            [
              -1.5200531988311,
              46.939043729216
            ],
            [
              -1.5207438194861,
              46.929878324082
            ],
            [
              -1.5279316909934,
              46.923781708092
            ],
            [
              -1.5443756009763,
              46.923001220553
            ],
            [
              -1.5309120096053,
              46.909449546397
            ],
            [
              -1.5078497024447,
              46.893876762137
            ],
            [
              -1.5007655467174,
              46.883891204136
            ],
            [
              -1.5242290703417,
              46.87400553248
            ],
            [
              -1.546480204725,
              46.87383880978
            ],
            [
              -1.5485096132916,
              46.860078088448
            ],
            [
              -1.5806567099723,
              46.866054082449
            ],
            [
              -1.6006429567832,
              46.87197273609
            ],
            [
              -1.6348229023896,
              46.878375886291
            ],
            [
              -1.6559787949023,
              46.878451361291
            ],
            [
              -1.6645088836683,
              46.874775769301
            ],
            [
              -1.6823313121141,
              46.883880205216
            ],
            [
              -1.6897556400174,
              46.890488583077
            ],
            [
              -1.7202570590716,
              46.884561675382
            ],
            [
              -1.7310575876529,
              46.887971269499
            ],
            [
              -1.736070743422,
              46.898731390312
            ],
            [
              -1.736248027152,
              46.911433999514
            ],
            [
              -1.7454773845349,
              46.918718031948
            ],
            [
              -1.7510691760313,
              46.929599006382
            ],
            [
              -1.7752593419132,
              46.925543067978
            ],
            [
              -1.798867236715,
              46.927389184813
            ],
            [
              -1.8309864393011,
              46.932037153713
            ],
            [
              -1.825350928718,
              46.946947877747
            ],
            [
              -1.8318183862536,
              46.953809144032
            ],
            [
              -1.8773327235254,
              46.953676781185
            ],
            [
              -1.8945066250679,
              46.959854562684
            ],
            [
              -1.9076821607029,
              46.970621494787
            ],
            [
              -1.9174464224643,
              46.975080057144
            ],
            [
              -1.9176795932921,
              46.992438096929
            ],
            [
              -1.9432299989209,
              46.994708112224
            ],
            [
              -1.9505833647802,
              47.001866498175
            ],
            [
              -1.9665825427654,
              47.025684331109
            ],
            [
              -1.9804130066429,
              47.028904753758
            ],
            [
              -1.9836243655281,
              47.029504869904
            ],
            [
              -1.9994147476381,
              47.055759178647
            ],
            [
              -2.0047214099724,
              47.061460656046
            ],
            [
              -2.0326019810958,
              47.073513335424
            ],
            [
              -2.0485561088483,
              47.086376045155
            ],
            [
              -2.0534773710676,
              47.094116874449
            ],
            [
              -2.1048560377353,
              47.108515739095
            ],
            [
              -2.1555855165283,
              47.112834108468
            ],
            [
              -2.1776795960367,
              47.12206186156
            ],
            [
              -2.2150136613872,
              47.124060089367
            ],
            [
              -2.2268201491195,
              47.130935989866
            ],
            [
              -2.2478150673853,
              47.134022011309
            ],
            [
              -2.2295324163069,
              47.144165117321
            ],
            [
              -2.226128166772,
              47.152275320793
            ],
            [
              -2.2009562728096,
              47.158406462933
            ],
            [
              -2.1802049236481,
              47.15593411267
            ],
            [
              -2.1670631605228,
              47.166180557901
            ],
            [
              -2.1582127475069,
              47.196892590523
            ],
            [
              -2.158373708365,
              47.209050666096
            ],
            [
              -2.1738613907116,
              47.22651156379
            ],
            [
              -2.1778607172174,
              47.236097279547
            ],
            [
              -2.1705455113596,
              47.239751522169
            ],
            [
              -2.1699895453995,
              47.268472297219
            ],
            [
              -2.1873608303178,
              47.280622361747
            ],
            [
              -2.2055602597095,
              47.271131396915
            ],
            [
              -2.2246437954795,
              47.264387061764
            ],
            [
              -2.2278604253303,
              47.256330261162
            ],
            [
              -2.2466914047133,
              47.255988431427
            ],
            [
              -2.2698952747426,
              47.2395647121
            ],
            [
              -2.3016402822796,
              47.236400031931
            ],
            [
              -2.3396017707663,
              47.255188453422
            ],
            [
              -2.3420962103188,
              47.261755983579
            ],
            [
              -2.3552895948005,
              47.27180855813
            ],
            [
              -2.369753264591,
              47.277438171535
            ],
            [
              -2.3983798514521,
              47.281448757206
            ],
            [
              -2.4203808400315,
              47.276443831705
            ],
            [
              -2.4253877232221,
              47.270964820355
            ],
            [
              -2.4165529129684,
              47.25871256282
            ],
            [
              -2.4475351836802,
              47.261757701956
            ],
            [
              -2.4555210807153,
              47.268139889592
            ],
            [
              -2.4823147658717,
              47.272979613928
            ],
            [
              -2.4990968218639,
              47.280741885999
            ],
            [
              -2.5442517230455,
              47.290107777197
            ],
            [
              -2.5449696912262,
              47.297963681667
            ],
            [
              -2.5272130839477,
              47.301551808752
            ],
            [
              -2.5138083846226,
              47.298375734085
            ],
            [
              -2.5049900911185,
              47.31367062334
            ],
            [
              -2.5029144731486,
              47.328754801261
            ],
            [
              -2.5067783337792,
              47.341390242029
            ],
            [
              -2.5211591992751,
              47.358811002331
            ],
            [
              -2.531174634228,
              47.365374694297
            ],
            [
              -2.5413465599302,
              47.366005665021
            ],
            [
              -2.5589448655806,
              47.374566485616
            ],
            [
              -2.5455487941567,
              47.381126464909
            ],
            [
              -2.5341061627856,
              47.382961282244
            ],
            [
              -2.5224115438117,
              47.392240089144
            ],
            [
              -2.5000251663727,
              47.404398607793
            ],
            [
              -2.4912351567464,
              47.404811002726
            ],
            [
              -2.4826825430466,
              47.412264457829
            ],
            [
              -2.4726723242774,
              47.416083446873
            ],
            [
              -2.4583086370333,
              47.412128481989
            ],
            [
              -2.4347120386263,
              47.41323956486
            ],
            [
              -2.4330361363116,
              47.416778409153
            ],
            [
              -2.450864597211,
              47.425323844427
            ],
            [
              -2.4523914498156,
              47.43382171003
            ],
            [
              -2.4482478800515,
              47.441292494065
            ],
            [
              -2.4584933200854,
              47.44812333026
            ],
            [
              -2.453436631799,
              47.46207522621
            ],
            [
              -2.4400824549861,
              47.465780511452
            ],
            [
              -2.4230228667484,
              47.477116356867
            ],
            [
              -2.4168260809589,
              47.462051729438
            ],
            [
              -2.399942719827,
              47.455985214433
            ],
            [
              -2.390389834657,
              47.456871614258
            ],
            [
              -2.3822957470498,
              47.462247958261
            ],
            [
              -2.3709692377347,
              47.463384419512
            ],
            [
              -2.3540439813745,
              47.454520038759
            ],
            [
              -2.3463648513449,
              47.457849990711
            ],
            [
              -2.3239404651198,
              47.459700150766
            ],
            [
              -2.3128644981072,
              47.464470727328
            ],
            [
              -2.3131411665125,
              47.485904562585
            ],
            [
              -2.3036714616823,
              47.49241124835
            ],
            [
              -2.2991228743726,
              47.500477533121
            ],
            [
              -2.2965137349146,
              47.51605888675
            ],
            [
              -2.2808336661554,
              47.509587432992
            ],
            [
              -2.2629685042061,
              47.512911091518
            ],
            [
              -2.2585200153358,
              47.504528170665
            ],
            [
              -2.2656383885011,
              47.501780337272
            ],
            [
              -2.2442830226806,
              47.493603343394
            ],
            [
              -2.2199696788078,
              47.505419526219
            ],
            [
              -2.2066684734953,
              47.510040688056
            ],
            [
              -2.184622799181,
              47.511938749674
            ],
            [
              -2.1837606578958,
              47.501791543642
            ],
            [
              -2.192163736718,
              47.496643815865
            ],
            [
              -2.1833018888491,
              47.491696689458
            ],
            [
              -2.1636756892187,
              47.49050097161
            ],
            [
              -2.1541860993449,
              47.496364966339
            ],
            [
              -2.1529569093967,
              47.510962718509
            ],
            [
              -2.1560180197876,
              47.522028206659
            ],
            [
              -2.1073294072614,
              47.531054380017
            ],
            [
              -2.0985591504874,
              47.533957226787
            ],
            [
              -2.096829841083,
              47.540329954312
            ],
            [
              -2.1038869792414,
              47.549970178949
            ],
            [
              -2.096507139486,
              47.572369157778
            ],
            [
              -2.1037228936324,
              47.589435010495
            ],
            [
              -2.0992687458213,
              47.597488169512
            ],
            [
              -2.0869236114994,
              47.602779172942
            ],
            [
              -2.0849952925543,
              47.621229400095
            ],
            [
              -2.0970339249479,
              47.631356309182
            ],
            [
              -2.0898837230131,
              47.642709543195
            ],
            [
              -2.0747327428802,
              47.651662965349
            ],
            [
              -2.0579097595869,
              47.649483633439
            ],
            [
              -2.050624831954,
              47.651134018014
            ],
            [
              -2.0430946586878,
              47.665696588636
            ],
            [
              -2.0357277498302,
              47.668540889438
            ],
            [
              -2.013316284318,
              47.666003385464
            ],
            [
              -2.0093555248025,
              47.671376463587
            ],
            [
              -1.9852234827543,
              47.683232805983
            ],
            [
              -1.9743811536912,
              47.693936715593
            ],
            [
              -1.9690592974,
              47.688368806165
            ],
            [
              -1.9691960862159,
              47.677526213073
            ],
            [
              -1.9538162196423,
              47.672268062701
            ],
            [
              -1.9363036351526,
              47.686649217665
            ],
            [
              -1.8918360415668,
              47.696328031406
            ],
            [
              -1.880073341802,
              47.695308629028
            ],
            [
              -1.8640156728505,
              47.706980966313
            ],
            [
              -1.8415465142207,
              47.705493872656
            ],
            [
              -1.8250533703276,
              47.708269117018
            ],
            [
              -1.8028599988222,
              47.702303252055
            ],
            [
              -1.7724177137179,
              47.698454240157
            ],
            [
              -1.754669062014,
              47.70617243813
            ],
            [
              -1.7354918450583,
              47.704030670166
            ],
            [
              -1.7292404629403,
              47.699070332406
            ],
            [
              -1.7133831145586,
              47.699308113335
            ],
            [
              -1.7051414585604,
              47.709321517725
            ],
            [
              -1.6863422821157,
              47.713034371357
            ],
            [
              -1.6641158381935,
              47.711144721547
            ],
            [
              -1.6548173400684,
              47.712589314425
            ],
            [
              -1.6454761757831,
              47.721464189516
            ],
            [
              -1.6381811285931,
              47.72231109575
            ],
            [
              -1.6390681088186,
              47.731229794449
            ],
            [
              -1.6355680463584,
              47.74265760999
            ],
            [
              -1.6260806089052,
              47.756571625148
            ],
            [
              -1.6163542814852,
              47.764155016463
            ],
            [
              -1.5981151893439,
              47.766615164584
            ],
            [
              -1.5934046637562,
              47.776049297939
            ],
            [
              -1.5519088415196,
              47.784014922086
            ],
            [
              -1.5280610367888,
              47.785843354761
            ],
            [
              -1.5202764448571,
              47.793620421696
            ],
            [
              -1.5042386939766,
              47.800947509052
            ],
            [
              -1.4928971911208,
              47.798439989341
            ],
            [
              -1.4684440000216,
              47.8059033883
            ],
            [
              -1.4669179596494,
              47.809780189045
            ],
            [
              -1.4818132504128,
              47.831893536154
            ],
            [
              -1.4628972269562,
              47.833557723029
            ],
            [
              -1.435426799044,
              47.83115216305
            ],
            [
              -1.4249168477477,
              47.832841359769
            ],
            [
              -1.4178500987788,
              47.827486856246
            ],
            [
              -1.3904289319245,
              47.828276258082
            ],
            [
              -1.3813446142742,
              47.822668998285
            ],
            [
              -1.3772932944092,
              47.812713141041
            ],
            [
              -1.3631664145966,
              47.801683607753
            ],
            [
              -1.3528652871869,
              47.797688995885
            ],
            [
              -1.3184870728098,
              47.792334376846
            ],
            [
              -1.2458850128779,
              47.776717450948
            ],
            [
              -1.2481701318392,
              47.763142394181
            ],
            [
              -1.2384524343117,
              47.755370176059
            ],
            [
              -1.2417265849441,
              47.742858014742
            ],
            [
              -1.2557221546099,
              47.742562297607
            ],
            [
              -1.2545837696673,
              47.733639711665
            ],
            [
              -1.2401834259633,
              47.726226363324
            ],
            [
              -1.2154225040771,
              47.718814964502
            ],
            [
              -1.1951247655948,
              47.721548781739
            ],
            [
              -1.1962168921655,
              47.709901315898
            ],
            [
              -1.1749255752394,
              47.694565156541
            ],
            [
              -1.1762377379005,
              47.683666204204
            ],
            [
              -1.1829291341983,
              47.66947490014
            ],
            [
              -1.1682996694516,
              47.660770013296
            ],
            [
              -1.1599410529377,
              47.660445255957
            ],
            [
              -1.1570762807876,
              47.635142948103
            ],
            [
              -1.1423383902321,
              47.62570440861
            ],
            [
              -1.1382005303391,
              47.619457993878
            ],
            [
              -1.1006504053381,
              47.620681819299
            ],
            [
              -1.089809516174,
              47.611397470687
            ],
            [
              -1.0564923836184,
              47.601481852962
            ],
            [
              -1.0475491196756,
              47.603183225277
            ],
            [
              -1.0254734250509,
              47.599322620735
            ],
            [
              -1.0068589445508,
              47.589057173366
            ],
            [
              -1.0222793698559,
              47.576022698104
            ],
            [
              -1.0325559818837,
              47.572469886138
            ],
            [
              -1.0459081759245,
              47.563351957166
            ],
            [
              -1.0753456782126,
              47.567573975026
            ],
            [
              -1.1093120205118,
              47.565174597558
            ],
            [
              -1.1274999354642,
              47.569742736207
            ],
            [
              -1.1724173944719,
              47.573007499646
            ],
            [
              -1.1783486783423,
              47.548022920882
            ],
            [
              -1.1524509517215,
              47.542456932846
            ],
            [
              -1.1658977332461,
              47.533633563364
            ],
            [
              -1.1670894674193,
              47.523469091666
            ],
            [
              -1.1612362268542,
              47.52238486075
            ],
            [
              -1.1572382827497,
              47.510080183398
            ],
            [
              -1.140588663811,
              47.504692689951
            ],
            [
              -1.1118786005754,
              47.501174450819
            ],
            [
              -1.0726112535239,
              47.502111250421
            ],
            [
              -1.0389970910658,
              47.505255272767
            ],
            [
              -1.0268648640358,
              47.5003865002
            ],
            [
              -1.0086224906369,
              47.48911227584
            ],
            [
              -0.96748096263572,
              47.492796746016
            ],
            [
              -0.95914434624296,
              47.481625087845
            ],
            [
              -0.96746458627544,
              47.474455765188
            ],
            [
              -0.96687816676134,
              47.468215265345
            ],
            [
              -0.95422867483307,
              47.453907688972
            ],
            [
              -0.9540938849527,
              47.442875714801
            ],
            [
              -0.94853780130136,
              47.424848031912
            ],
            [
              -0.9606621441787,
              47.422477025273
            ],
            [
              -0.94914242982501,
              47.401623840241
            ],
            [
              -0.9508541294691,
              47.389265193007
            ],
            [
              -0.94643916329696,
              47.386464290023
            ],
            [
              -0.9674439712091,
              47.376798464598
            ],
            [
              -0.97486734238539,
              47.371383508034
            ],
            [
              -0.99430574522555,
              47.370712972962
            ],
            [
              -1.0178734478092,
              47.365057767936
            ],
            [
              -1.0450486975769,
              47.365466380448
            ],
            [
              -1.0574262200656,
              47.369170186345
            ],
            [
              -1.0787354054843,
              47.370793512665
            ],
            [
              -1.1119532943908,
              47.365936084266
            ],
            [
              -1.1376804248884,
              47.368243058298
            ],
            [
              -1.1676120647818,
              47.365883544326
            ],
            [
              -1.1765084306564,
              47.362101321316
            ],
            [
              -1.1876584713548,
              47.352352194789
            ],
            [
              -1.2084104790552,
              47.349457943211
            ],
            [
              -1.2286377810408,
              47.349925437284
            ],
            [
              -1.2612179241979,
              47.340856335692
            ],
            [
              -1.2801801086452,
              47.341119585894
            ],
            [
              -1.2998795136684,
              47.337619971759
            ],
            [
              -1.3187731044337,
              47.323336099427
            ],
            [
              -1.3308194081938,
              47.319396979635
            ],
            [
              -1.3541992398083,
              47.304153763744
            ],
            [
              -1.3443772279311,
              47.300910306344
            ],
            [
              -1.3278029846392,
              47.302945553464
            ],
            [
              -1.3051137160456,
              47.303168883061
            ],
            [
              -1.2946071704543,
              47.300999778811
            ],
            [
              -1.2765544420058,
              47.288508608744
            ],
            [
              -1.2794390101129,
              47.274415207416
            ],
            [
              -1.2733134508748,
              47.265391994663
            ],
            [
              -1.260408442414,
              47.258036142558
            ],
            [
              -1.2541720389343,
              47.249855480379
            ],
            [
              -1.2385771004628,
              47.24210028514
            ],
            [
              -1.2283677873261,
              47.239714645098
            ],
            [
              -1.2128496586385,
              47.24694698722
            ],
            [
              -1.2080715603628,
              47.25267682188
            ],
            [
              -1.1977244611046,
              47.250583767734
            ],
            [
              -1.1809721535433,
              47.240809376935
            ],
            [
              -1.1880508684465,
              47.2197922638
            ],
            [
              -1.1764040824717,
              47.214519763812
            ],
            [
              -1.1804900757451,
              47.207325903559
            ],
            [
              -1.1754240835306,
              47.200592163959
            ],
            [
              -1.1746946005099,
              47.190464465945
            ],
            [
              -1.1634036702522,
              47.185469232552
            ],
            [
              -1.1632136426884,
              47.17778687953
            ],
            [
              -1.1758721240253,
              47.163117819885
            ],
            [
              -1.1881724970889,
              47.158419543902
            ],
            [
              -1.2039490870349,
              47.146949890513
            ],
            [
              -1.2352480566999,
              47.138778896633
            ],
            [
              -1.2315747658211,
              47.122945286891
            ],
            [
              -1.2258467897311,
              47.11943849553
            ],
            [
              -1.2311522270542,
              47.109953407431
            ],
            [
              -1.2479716560946,
              47.100110089689
            ],
            [
              -1.2156175224974,
              47.098457006772
            ],
            [
              -1.2025045606441,
              47.095938796905
            ],
            [
              -1.170288934637,
              47.092959055135
            ],
            [
              -1.1619003785415,
              47.079572227316
            ],
            [
              -1.1539529642236,
              47.071889442774
            ],
            [
              -1.1426855537542,
              47.067863069799
            ],
            [
              -1.1154595616826,
              47.062560153317
            ],
            [
              -1.1205246215866,
              47.053223354371
            ],
            [
              -1.1170479432483,
              47.04013518647
            ],
            [
              -1.1485668205651,
              47.029548900794
            ]
          ]
        ]
      },
      "properties": {
        "code": "44",
        "nom": "Loire-Atlantique",
        "population": 1445171,
        "delitspour1000": "4,384",
        "nombremosque": 20,
        "parentsEtrangers": "76,1",
        "benefRsa": 57292,
        "eoliennes": 208
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1.4482602497483,
              45.019314041206
            ],
            [
              1.4619826792135,
              45.01370224029
            ],
            [
              1.4735839270693,
              45.017999050409
            ],
            [
              1.4800566881166,
              45.026797710108
            ],
            [
              1.502854716064,
              45.038440870886
            ],
            [
              1.519580065039,
              45.041030205266
            ],
            [
              1.5357229531152,
              45.046275852749
            ],
            [
              1.5410691079459,
              45.042887041934
            ],
            [
              1.5437040262063,
              45.030761580224
            ],
            [
              1.5520446044451,
              45.028473138039
            ],
            [
              1.5690421523205,
              45.038712199205
            ],
            [
              1.5763101796546,
              45.040696470827
            ],
            [
              1.5893103965749,
              45.036412402334
            ],
            [
              1.614739866204,
              45.033019699506
            ],
            [
              1.629956153879,
              45.033567269253
            ],
            [
              1.6509774862668,
              45.025013308842
            ],
            [
              1.6544545546621,
              45.017019229934
            ],
            [
              1.6714097923126,
              45.004301541515
            ],
            [
              1.6843750926429,
              45.002660865737
            ],
            [
              1.6871611264702,
              44.996380368243
            ],
            [
              1.702662490825,
              44.987825577102
            ],
            [
              1.7110326508746,
              44.967296262803
            ],
            [
              1.7215459564475,
              44.968065955731
            ],
            [
              1.7428395473269,
              44.959830662128
            ],
            [
              1.7506187292807,
              44.954961208829
            ],
            [
              1.7536701932124,
              44.940576173829
            ],
            [
              1.7685216398013,
              44.93111838651
            ],
            [
              1.7749276020491,
              44.923721627249
            ],
            [
              1.7828574905171,
              44.929652757246
            ],
            [
              1.78487170617,
              44.937317933441
            ],
            [
              1.8008914101993,
              44.924210099081
            ],
            [
              1.8085816314758,
              44.927710042504
            ],
            [
              1.8239145074647,
              44.927683348732
            ],
            [
              1.836439246631,
              44.937455442607
            ],
            [
              1.8443484053735,
              44.938030495187
            ],
            [
              1.8510527260352,
              44.946245504656
            ],
            [
              1.8670322636063,
              44.952926075187
            ],
            [
              1.8874095971943,
              44.956563455989
            ],
            [
              1.8926655050781,
              44.964882977079
            ],
            [
              1.9081575006047,
              44.978423183181
            ],
            [
              1.9280650601996,
              44.97871493763
            ],
            [
              1.9390669995572,
              44.973222308505
            ],
            [
              1.9407166400253,
              44.95513995704
            ],
            [
              1.9509915581584,
              44.953151935667
            ],
            [
              1.9557698918802,
              44.958318624648
            ],
            [
              1.9740981161193,
              44.966632803845
            ],
            [
              1.9853569537575,
              44.974450164818
            ],
            [
              2.0068687915517,
              44.976629769782
            ],
            [
              2.0454327798436,
              44.983664804729
            ],
            [
              2.0525667664657,
              44.976478188068
            ],
            [
              2.0629079799591,
              44.9765045515
            ],
            [
              2.0806940542158,
              44.953285983552
            ],
            [
              2.0763195910268,
              44.940561879615
            ],
            [
              2.0786238601329,
              44.932494333995
            ],
            [
              2.0892278644094,
              44.928560463933
            ],
            [
              2.1081047030139,
              44.910638688547
            ],
            [
              2.0864901589829,
              44.901079919432
            ],
            [
              2.0837359232769,
              44.887424173789
            ],
            [
              2.0942097640089,
              44.872012369152
            ],
            [
              2.1165704984568,
              44.850184202716
            ],
            [
              2.1227770243941,
              44.847633102732
            ],
            [
              2.1398031019064,
              44.823820670465
            ],
            [
              2.1658208187006,
              44.812904212621
            ],
            [
              2.1652929430823,
              44.799560342182
            ],
            [
              2.1716374993124,
              44.790027108976
            ],
            [
              2.1667022088341,
              44.772651831571
            ],
            [
              2.1493997719958,
              44.769790512407
            ],
            [
              2.1534957604021,
              44.753104006989
            ],
            [
              2.1522238006118,
              44.736723151663
            ],
            [
              2.1479681938401,
              44.723035803571
            ],
            [
              2.1336852541707,
              44.70956475715
            ],
            [
              2.1301318075949,
              44.698486086307
            ],
            [
              2.1386637746726,
              44.692880659597
            ],
            [
              2.1552964923022,
              44.70023944349
            ],
            [
              2.1630322730753,
              44.690215331586
            ],
            [
              2.1791523780579,
              44.674446124105
            ],
            [
              2.1655660362222,
              44.661375303382
            ],
            [
              2.1740442827509,
              44.653644834741
            ],
            [
              2.1687595856936,
              44.647386736712
            ],
            [
              2.1694164789558,
              44.638069782843
            ],
            [
              2.1908059572226,
              44.628252004845
            ],
            [
              2.2074728028173,
              44.61552895784
            ],
            [
              2.2108934010391,
              44.606090357773
            ],
            [
              2.1954101381218,
              44.600778595174
            ],
            [
              2.2017928242867,
              44.596049696004
            ],
            [
              2.1936051879406,
              44.58591143442
            ],
            [
              2.1844862284485,
              44.59021904525
            ],
            [
              2.1676095238949,
              44.590329078654
            ],
            [
              2.1538128201085,
              44.57189782658
            ],
            [
              2.1346205537833,
              44.569849513532
            ],
            [
              2.124513418981,
              44.576347447406
            ],
            [
              2.1104955943486,
              44.571376544464
            ],
            [
              2.1015590079994,
              44.572436798295
            ],
            [
              2.0830462458941,
              44.587423324047
            ],
            [
              2.0728206231132,
              44.577955873938
            ],
            [
              2.0573689651355,
              44.582909160412
            ],
            [
              2.0335854594418,
              44.56881637632
            ],
            [
              2.0340696763027,
              44.560760159834
            ],
            [
              2.0203127144902,
              44.555504531802
            ],
            [
              2.0050084589377,
              44.557033268559
            ],
            [
              1.9885549262677,
              44.551182134401
            ],
            [
              1.9751753123345,
              44.537145405011
            ],
            [
              1.970563904818,
              44.528891720337
            ],
            [
              1.9562278200819,
              44.518455813836
            ],
            [
              1.9366297490837,
              44.515148261474
            ],
            [
              1.9314639777047,
              44.506396941037
            ],
            [
              1.9129865180384,
              44.505157692579
            ],
            [
              1.9115825451404,
              44.499801458356
            ],
            [
              1.9191710997594,
              44.49312202364
            ],
            [
              1.9098594387895,
              44.48674631631
            ],
            [
              1.9061154790499,
              44.499870727249
            ],
            [
              1.8902857028608,
              44.505886029507
            ],
            [
              1.8841752229262,
              44.498432886597
            ],
            [
              1.885217137804,
              44.486107475987
            ],
            [
              1.8799586666095,
              44.483837662409
            ],
            [
              1.8615767786303,
              44.487320895101
            ],
            [
              1.843001484365,
              44.482382092855
            ],
            [
              1.8396044963184,
              44.475903822342
            ],
            [
              1.8470020898658,
              44.470160068683
            ],
            [
              1.8526845623673,
              44.459216811677
            ],
            [
              1.846648081567,
              44.444555574418
            ],
            [
              1.8505242685611,
              44.43550406388
            ],
            [
              1.8731907138082,
              44.424140758608
            ],
            [
              1.8710314852874,
              44.397745172704
            ],
            [
              1.8744004614259,
              44.391430087278
            ],
            [
              1.8907941991097,
              44.380202764676
            ],
            [
              1.8933692462979,
              44.371571831531
            ],
            [
              1.9068476903719,
              44.364871814968
            ],
            [
              1.9112348353677,
              44.356651948333
            ],
            [
              1.9052533999665,
              44.350549277067
            ],
            [
              1.8907241899993,
              44.351737032539
            ],
            [
              1.8820777699351,
              44.340068798134
            ],
            [
              1.8556031233321,
              44.332582043853
            ],
            [
              1.8456122621165,
              44.33613444695
            ],
            [
              1.8332009402511,
              44.335457059576
            ],
            [
              1.8270255325815,
              44.32426884987
            ],
            [
              1.8094302106683,
              44.329086072158
            ],
            [
              1.8059777797489,
              44.336632362484
            ],
            [
              1.7833453885707,
              44.327943604564
            ],
            [
              1.7873478934768,
              44.323581975324
            ],
            [
              1.7819129603983,
              44.314472615323
            ],
            [
              1.768544261781,
              44.314939492971
            ],
            [
              1.7553567443039,
              44.325817340987
            ],
            [
              1.7373556664188,
              44.326979663175
            ],
            [
              1.7318042883639,
              44.317812372941
            ],
            [
              1.7151285678926,
              44.313035587615
            ],
            [
              1.6994966069311,
              44.313816007339
            ],
            [
              1.6790543823345,
              44.300601364801
            ],
            [
              1.6628876357397,
              44.292814929567
            ],
            [
              1.6466377173529,
              44.294790204319
            ],
            [
              1.6370645866245,
              44.298462052118
            ],
            [
              1.6319878111146,
              44.293887757522
            ],
            [
              1.6516181749095,
              44.286809376053
            ],
            [
              1.6422766943958,
              44.270771530189
            ],
            [
              1.6332195435462,
              44.269409693186
            ],
            [
              1.6156752023963,
              44.278396127792
            ],
            [
              1.6169053391703,
              44.297530575144
            ],
            [
              1.6055857426408,
              44.295656355547
            ],
            [
              1.5932068472358,
              44.302911512641
            ],
            [
              1.5872383982734,
              44.298779228534
            ],
            [
              1.577057905448,
              44.30164046075
            ],
            [
              1.569137976709,
              44.298392987101
            ],
            [
              1.5777775117549,
              44.284496801325
            ],
            [
              1.5624962356347,
              44.27966878594
            ],
            [
              1.5632414387973,
              44.274886379262
            ],
            [
              1.5770804296349,
              44.270221202549
            ],
            [
              1.5800311670669,
              44.260206126797
            ],
            [
              1.5872487604831,
              44.249006343623
            ],
            [
              1.5752287750623,
              44.238734921684
            ],
            [
              1.5417602550587,
              44.227813925717
            ],
            [
              1.5290844067842,
              44.23537357693
            ],
            [
              1.518246985951,
              44.249518712925
            ],
            [
              1.5205325763103,
              44.263266971406
            ],
            [
              1.5087806340611,
              44.273638671169
            ],
            [
              1.4951437462566,
              44.271095820859
            ],
            [
              1.4732171576142,
              44.284206796054
            ],
            [
              1.4618426585284,
              44.26737925836
            ],
            [
              1.4531793595742,
              44.267176611688
            ],
            [
              1.4535590038918,
              44.254122364815
            ],
            [
              1.4392544450401,
              44.250013706447
            ],
            [
              1.4309892807553,
              44.243934249729
            ],
            [
              1.4223000036257,
              44.242769039907
            ],
            [
              1.3803669452448,
              44.224855014913
            ],
            [
              1.3567965742905,
              44.204018679795
            ],
            [
              1.3363963237902,
              44.228164291061
            ],
            [
              1.3205552714658,
              44.232050137303
            ],
            [
              1.3068776511303,
              44.226976620673
            ],
            [
              1.2813755448768,
              44.235788833792
            ],
            [
              1.2862503206206,
              44.242724546526
            ],
            [
              1.2842803990087,
              44.251751936818
            ],
            [
              1.3040561797661,
              44.262956553109
            ],
            [
              1.2942283079115,
              44.269931405949
            ],
            [
              1.3036419786638,
              44.293780222919
            ],
            [
              1.2984232978299,
              44.294998753146
            ],
            [
              1.2826505678741,
              44.290126601767
            ],
            [
              1.2717164107238,
              44.281701772898
            ],
            [
              1.2554819885111,
              44.285553546834
            ],
            [
              1.2500166763573,
              44.272900537029
            ],
            [
              1.2251735135029,
              44.27952570615
            ],
            [
              1.2170572336728,
              44.276902146179
            ],
            [
              1.2046104960799,
              44.282089030781
            ],
            [
              1.1845970264102,
              44.286843708109
            ],
            [
              1.177527462072,
              44.292155931446
            ],
            [
              1.1812474366675,
              44.30737621701
            ],
            [
              1.1684135504581,
              44.305176929208
            ],
            [
              1.1593432965978,
              44.310622218703
            ],
            [
              1.1460615025306,
              44.308815289657
            ],
            [
              1.1359881581847,
              44.317222086283
            ],
            [
              1.1252404136057,
              44.315439371114
            ],
            [
              1.1104058105295,
              44.323116166197
            ],
            [
              1.1079322101063,
              44.327405475254
            ],
            [
              1.11341021313,
              44.338851885589
            ],
            [
              1.1032236287631,
              44.346211355086
            ],
            [
              1.0897034112701,
              44.347776457751
            ],
            [
              1.0812215694247,
              44.354388321287
            ],
            [
              1.0912647526615,
              44.359943917324
            ],
            [
              1.0935259886791,
              44.365959224021
            ],
            [
              1.1071599922422,
              44.366598851034
            ],
            [
              1.110748036109,
              44.370191952886
            ],
            [
              1.1274389106322,
              44.372985753369
            ],
            [
              1.1347841409898,
              44.379001901251
            ],
            [
              1.1325463304764,
              44.393331095059
            ],
            [
              1.113581510295,
              44.391324005037
            ],
            [
              1.1035062544519,
              44.392306175475
            ],
            [
              1.0824000913579,
              44.381409106067
            ],
            [
              1.0640814762214,
              44.378508721439
            ],
            [
              1.060916410464,
              44.388148956247
            ],
            [
              1.0514192493927,
              44.392094511982
            ],
            [
              1.0613076444275,
              44.401878633002
            ],
            [
              1.060811266939,
              44.416585968472
            ],
            [
              1.0574845114814,
              44.427673212448
            ],
            [
              1.0452829749205,
              44.434328842652
            ],
            [
              1.0333318174167,
              44.432217983065
            ],
            [
              1.0247167778907,
              44.442988405687
            ],
            [
              1.0209838507254,
              44.456237710915
            ],
            [
              1.0238896841162,
              44.464106031022
            ],
            [
              1.0230093439874,
              44.475437273235
            ],
            [
              1.009020244264,
              44.480044617987
            ],
            [
              1.0168410499751,
              44.492627682886
            ],
            [
              1.0162275856839,
              44.505873805304
            ],
            [
              0.99607013748509,
              44.526838106147
            ],
            [
              0.98177646477517,
              44.543949619625
            ],
            [
              0.99391928143422,
              44.549540779914
            ],
            [
              1.0102474849887,
              44.545187788418
            ],
            [
              1.0131652927005,
              44.53612981948
            ],
            [
              1.0347007735952,
              44.555410813413
            ],
            [
              1.0463221422077,
              44.562091748647
            ],
            [
              1.0716939661813,
              44.567841711875
            ],
            [
              1.0751420531924,
              44.577325705506
            ],
            [
              1.0915723340934,
              44.57129825478
            ],
            [
              1.10321434571,
              44.571734741055
            ],
            [
              1.1023480014998,
              44.583112143598
            ],
            [
              1.0954254371464,
              44.590239410789
            ],
            [
              1.1076423221767,
              44.604047948788
            ],
            [
              1.137389330558,
              44.623916068209
            ],
            [
              1.1537948801342,
              44.639408990845
            ],
            [
              1.1467257654642,
              44.651942998392
            ],
            [
              1.1466756285759,
              44.670346129862
            ],
            [
              1.1631824196253,
              44.674246913408
            ],
            [
              1.1691224492748,
              44.680201458326
            ],
            [
              1.1814922279959,
              44.68312050249
            ],
            [
              1.1922298707031,
              44.682144673083
            ],
            [
              1.2245513454404,
              44.684265469136
            ],
            [
              1.2404413959773,
              44.692803925964
            ],
            [
              1.2433609148288,
              44.703747365953
            ],
            [
              1.2482656593079,
              44.707708989465
            ],
            [
              1.2637980717141,
              44.710685806718
            ],
            [
              1.2704127081354,
              44.722361774434
            ],
            [
              1.2877769806729,
              44.714784618791
            ],
            [
              1.2997473700748,
              44.733876961929
            ],
            [
              1.3005234409238,
              44.743067753612
            ],
            [
              1.3160471856435,
              44.740370070289
            ],
            [
              1.322816677032,
              44.765133167519
            ],
            [
              1.313412866091,
              44.766040449212
            ],
            [
              1.2962430489699,
              44.777811462978
            ],
            [
              1.3042790767232,
              44.788545534304
            ],
            [
              1.2996402457488,
              44.796921415066
            ],
            [
              1.3281059944688,
              44.806531488239
            ],
            [
              1.3368655384896,
              44.806071554052
            ],
            [
              1.3641055003826,
              44.811568223737
            ],
            [
              1.3606303391154,
              44.826748288149
            ],
            [
              1.3614088604034,
              44.840796241728
            ],
            [
              1.3699846242068,
              44.846449057072
            ],
            [
              1.3771449543322,
              44.84182444963
            ],
            [
              1.3861021387482,
              44.847434745656
            ],
            [
              1.4019376918366,
              44.849449622068
            ],
            [
              1.4048297845553,
              44.862526244834
            ],
            [
              1.4181797898114,
              44.870598618527
            ],
            [
              1.4310840691639,
              44.871280603185
            ],
            [
              1.4419256468077,
              44.877575693392
            ],
            [
              1.4398567374008,
              44.888947217313
            ],
            [
              1.4216345306166,
              44.896767467267
            ],
            [
              1.4135387322774,
              44.911821580701
            ],
            [
              1.4246258313763,
              44.919694190356
            ],
            [
              1.4423501795316,
              44.916547257696
            ],
            [
              1.4365133670875,
              44.93225069741
            ],
            [
              1.4364044047008,
              44.940615131261
            ],
            [
              1.4207336396897,
              44.955116554897
            ],
            [
              1.414587951063,
              44.977794038016
            ],
            [
              1.4133042325955,
              44.999381814036
            ],
            [
              1.4092638730924,
              45.006004469319
            ],
            [
              1.4281821749303,
              45.009219883472
            ],
            [
              1.4482602497483,
              45.019314041206
            ]
          ]
        ]
      },
      "properties": {
        "code": "46",
        "nom": "Lot",
        "population": 174670,
        "delitspour1000": "3,309",
        "nombremosque": 5,
        "parentsEtrangers": "82,8",
        "benefRsa": 7158,
        "eoliennes": 3
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1.2458850128779,
              47.776717450948
            ],
            [
              -1.238247803597,
              47.809992506553
            ],
            [
              -1.2123936791168,
              47.801614932637
            ],
            [
              -1.211020674252,
              47.797590442857
            ],
            [
              -1.1964430977541,
              47.790525850178
            ],
            [
              -1.1661316638423,
              47.779847686645
            ],
            [
              -1.1426274244343,
              47.774444264523
            ],
            [
              -1.1395632656858,
              47.782090893545
            ],
            [
              -1.1226290875254,
              47.784177435943
            ],
            [
              -1.0999118538236,
              47.78196877901
            ],
            [
              -1.0808081623002,
              47.77465323808
            ],
            [
              -1.0676786814704,
              47.772967184248
            ],
            [
              -1.0471541464566,
              47.773610259565
            ],
            [
              -1.0299400242941,
              47.770642704508
            ],
            [
              -1.0271760427282,
              47.776528987713
            ],
            [
              -1.0121899767546,
              47.768268724518
            ],
            [
              -0.97865617651258,
              47.761986093612
            ],
            [
              -0.97381310959824,
              47.765679681831
            ],
            [
              -0.95086406782493,
              47.76808514016
            ],
            [
              -0.95795711853403,
              47.779464165284
            ],
            [
              -0.97098655185006,
              47.785631119633
            ],
            [
              -0.96068479660974,
              47.796072166491
            ],
            [
              -0.95202871311581,
              47.796497117235
            ],
            [
              -0.93519158606661,
              47.785628696413
            ],
            [
              -0.92510315616484,
              47.787711986556
            ],
            [
              -0.89576734363074,
              47.774695581088
            ],
            [
              -0.88959193745726,
              47.763900903741
            ],
            [
              -0.88129295704945,
              47.763545668743
            ],
            [
              -0.83929831407277,
              47.752275868891
            ],
            [
              -0.83998386052541,
              47.76814434186
            ],
            [
              -0.81606753396371,
              47.771224388477
            ],
            [
              -0.81124732846711,
              47.764243961382
            ],
            [
              -0.79320782142054,
              47.761714153438
            ],
            [
              -0.78387499393012,
              47.750578958527
            ],
            [
              -0.75930412026962,
              47.753754360928
            ],
            [
              -0.75310835971653,
              47.743657730113
            ],
            [
              -0.74171045035285,
              47.73810919909
            ],
            [
              -0.72935416928341,
              47.742485565283
            ],
            [
              -0.71546315772953,
              47.741095766362
            ],
            [
              -0.71032851231712,
              47.744605212491
            ],
            [
              -0.68746167144854,
              47.739103006695
            ],
            [
              -0.67141413936749,
              47.741116096202
            ],
            [
              -0.6537825399855,
              47.733379704738
            ],
            [
              -0.64643982360156,
              47.736853397067
            ],
            [
              -0.62748017249068,
              47.737810514748
            ],
            [
              -0.61081969714626,
              47.735690584502
            ],
            [
              -0.58478471927851,
              47.758351695043
            ],
            [
              -0.57293736768549,
              47.752842443065
            ],
            [
              -0.56037613327226,
              47.757430997013
            ],
            [
              -0.53285885316803,
              47.750822279532
            ],
            [
              -0.51943177897947,
              47.764156235038
            ],
            [
              -0.51795384079201,
              47.770177433845
            ],
            [
              -0.52210308579569,
              47.783980077243
            ],
            [
              -0.50966167262766,
              47.786039294546
            ],
            [
              -0.48247706487956,
              47.775994183566
            ],
            [
              -0.46041737238756,
              47.765093407664
            ],
            [
              -0.45459213036525,
              47.756480807741
            ],
            [
              -0.44074447771005,
              47.764123856192
            ],
            [
              -0.42445123600415,
              47.766490856811
            ],
            [
              -0.41003563438819,
              47.765211772614
            ],
            [
              -0.41501631226949,
              47.776297777206
            ],
            [
              -0.40949181286586,
              47.778639806662
            ],
            [
              -0.39630612257633,
              47.770754023167
            ],
            [
              -0.38561021034855,
              47.769114398933
            ],
            [
              -0.38170083144601,
              47.760566513867
            ],
            [
              -0.37988199972814,
              47.751114916558
            ],
            [
              -0.37263899192078,
              47.743859320297
            ],
            [
              -0.37492261789305,
              47.738946783268
            ],
            [
              -0.35849844840348,
              47.732083694905
            ],
            [
              -0.35988584661972,
              47.722646870915
            ],
            [
              -0.36582157233392,
              47.716119600548
            ],
            [
              -0.35245292343886,
              47.714134336409
            ],
            [
              -0.334071412114,
              47.72116141568
            ],
            [
              -0.31161563979599,
              47.717455540853
            ],
            [
              -0.28670550188632,
              47.71932399093
            ],
            [
              -0.27306766551386,
              47.713197049548
            ],
            [
              -0.24758339198338,
              47.706703696744
            ],
            [
              -0.23306044236303,
              47.707562902473
            ],
            [
              -0.20237724749556,
              47.725243273377
            ],
            [
              -0.193745472526,
              47.723226403392
            ],
            [
              -0.18231918647758,
              47.712901316612
            ],
            [
              -0.18165434555472,
              47.706039135515
            ],
            [
              -0.20031552112238,
              47.694692207342
            ],
            [
              -0.21550112973611,
              47.695890633051
            ],
            [
              -0.22019670030717,
              47.689623181347
            ],
            [
              -0.19505943365123,
              47.661376068174
            ],
            [
              -0.19315208570794,
              47.647919424013
            ],
            [
              -0.17123970337477,
              47.644974449849
            ],
            [
              -0.15952557421716,
              47.64648643878
            ],
            [
              -0.14951423955432,
              47.638152293611
            ],
            [
              -0.13503949950058,
              47.632521704537
            ],
            [
              -0.12630392349164,
              47.637206815768
            ],
            [
              -0.11331543555609,
              47.635027101972
            ],
            [
              -0.11482086091719,
              47.642983338229
            ],
            [
              -0.10759735671751,
              47.655283303327
            ],
            [
              -0.094598120211807,
              47.662259952753
            ],
            [
              -0.092016973776158,
              47.650975769952
            ],
            [
              -0.07182717748956,
              47.658009370506
            ],
            [
              -0.051484728490695,
              47.648440465421
            ],
            [
              -0.024425581635307,
              47.646338441701
            ],
            [
              -0.0200217526275,
              47.637114273453
            ],
            [
              -0.0045114795481094,
              47.647685622756
            ],
            [
              0.0052143482003776,
              47.640007970982
            ],
            [
              0.017034808401921,
              47.621104843447
            ],
            [
              0.037731156984854,
              47.617755071618
            ],
            [
              0.051656847144079,
              47.60651153657
            ],
            [
              0.064852910303496,
              47.604386057354
            ],
            [
              0.072757700964096,
              47.607779497472
            ],
            [
              0.09703338042594,
              47.604785657917
            ],
            [
              0.11568780138148,
              47.605729170813
            ],
            [
              0.1226270100427,
              47.596902341854
            ],
            [
              0.13138185271472,
              47.596242407775
            ],
            [
              0.13996010175599,
              47.586619930035
            ],
            [
              0.15160658198455,
              47.581530335722
            ],
            [
              0.16192950279651,
              47.594141382277
            ],
            [
              0.17380051201584,
              47.59335138679
            ],
            [
              0.18492347945562,
              47.59951888853
            ],
            [
              0.18583053478704,
              47.616541172133
            ],
            [
              0.20190440390773,
              47.615577460258
            ],
            [
              0.20177337945804,
              47.610581878091
            ],
            [
              0.22412414705184,
              47.612294541765
            ],
            [
              0.23000044283917,
              47.608397360802
            ],
            [
              0.23453049018557,
              47.57797744555
            ],
            [
              0.21510267043528,
              47.569975576615
            ],
            [
              0.20150198535452,
              47.544324126228
            ],
            [
              0.19334553772128,
              47.539118278038
            ],
            [
              0.2034912870347,
              47.533330991541
            ],
            [
              0.20807030184987,
              47.526432351568
            ],
            [
              0.22491484141757,
              47.52709951065
            ],
            [
              0.22008941253351,
              47.511490494119
            ],
            [
              0.22010664406864,
              47.50195233416
            ],
            [
              0.2007181726014,
              47.484545277708
            ],
            [
              0.18979781155354,
              47.460723327297
            ],
            [
              0.18093772230519,
              47.453404824236
            ],
            [
              0.18527899163678,
              47.424736116162
            ],
            [
              0.18138204745888,
              47.417824739063
            ],
            [
              0.15385793095636,
              47.398727021572
            ],
            [
              0.16948959021545,
              47.395646092388
            ],
            [
              0.16798792612309,
              47.386934066036
            ],
            [
              0.18296214025622,
              47.38033044936
            ],
            [
              0.15845843664067,
              47.366157059648
            ],
            [
              0.14165395029201,
              47.361961427199
            ],
            [
              0.14796868770319,
              47.348447803202
            ],
            [
              0.13898837615883,
              47.33824318552
            ],
            [
              0.13125388952305,
              47.33409233868
            ],
            [
              0.11745694898776,
              47.332342613969
            ],
            [
              0.11786940351405,
              47.325601141531
            ],
            [
              0.10924461524906,
              47.313421774727
            ],
            [
              0.099012801986365,
              47.308139754432
            ],
            [
              0.078978960154079,
              47.282822156056
            ],
            [
              0.082848635358157,
              47.274168568836
            ],
            [
              0.074829460019472,
              47.248048474897
            ],
            [
              0.072492820219795,
              47.220509854465
            ],
            [
              0.053277684947378,
              47.197182170708
            ],
            [
              0.066596690426524,
              47.189796025762
            ],
            [
              0.063052886223431,
              47.175281799504
            ],
            [
              0.053830055961677,
              47.16373374848
            ],
            [
              0.049480342584696,
              47.168623012343
            ],
            [
              0.036501918227681,
              47.160445278183
            ],
            [
              0.019016376976915,
              47.175754285742
            ],
            [
              -0.010739414334867,
              47.15751215065
            ],
            [
              -0.034011786935389,
              47.127334734816
            ],
            [
              -0.040856468682482,
              47.112928627112
            ],
            [
              -0.039289561029516,
              47.108055925394
            ],
            [
              -0.026535185584925,
              47.105798471803
            ],
            [
              -0.029234974929682,
              47.095257561848
            ],
            [
              -0.03562437381954,
              47.086261232309
            ],
            [
              -0.044169213959146,
              47.093239781385
            ],
            [
              -0.060661742719366,
              47.09514763055
            ],
            [
              -0.085909274640678,
              47.101010256779
            ],
            [
              -0.098719424061378,
              47.090117668474
            ],
            [
              -0.10150441660145,
              47.08326376928
            ],
            [
              -0.1021158452812,
              47.06480003115
            ],
            [
              -0.12837866130337,
              47.054429041651
            ],
            [
              -0.13712137046296,
              47.058426719475
            ],
            [
              -0.13678317485552,
              47.063924090649
            ],
            [
              -0.14766123249005,
              47.069855194532
            ],
            [
              -0.16599081567743,
              47.064596744766
            ],
            [
              -0.17848280781104,
              47.069769863363
            ],
            [
              -0.15947412139976,
              47.085935164317
            ],
            [
              -0.14555986138819,
              47.091366857483
            ],
            [
              -0.14125473717962,
              47.103745079728
            ],
            [
              -0.15721241252843,
              47.101780345038
            ],
            [
              -0.18483829723431,
              47.108333434925
            ],
            [
              -0.18649707382196,
              47.101547033466
            ],
            [
              -0.20607508372803,
              47.09328538537
            ],
            [
              -0.24153284082639,
              47.1057275119
            ],
            [
              -0.25537525933525,
              47.100286155299
            ],
            [
              -0.2879249468488,
              47.101438124536
            ],
            [
              -0.29895654468625,
              47.099250384298
            ],
            [
              -0.3142510524831,
              47.091338121731
            ],
            [
              -0.34146522078634,
              47.087332841605
            ],
            [
              -0.34498093726671,
              47.09177095779
            ],
            [
              -0.35741871073042,
              47.094026201074
            ],
            [
              -0.38345929253299,
              47.087697613397
            ],
            [
              -0.39631090043786,
              47.087753100169
            ],
            [
              -0.40078196900616,
              47.070768258647
            ],
            [
              -0.40931478414398,
              47.06628937151
            ],
            [
              -0.4258514883367,
              47.072734496606
            ],
            [
              -0.44613773080732,
              47.067564764094
            ],
            [
              -0.46425258388436,
              47.067574344676
            ],
            [
              -0.47634088284647,
              47.054361886701
            ],
            [
              -0.48553786553274,
              47.065209021486
            ],
            [
              -0.47635021862705,
              47.072140351589
            ],
            [
              -0.46404370608664,
              47.074916254874
            ],
            [
              -0.46269595640292,
              47.081925505658
            ],
            [
              -0.49533635185918,
              47.082386236675
            ],
            [
              -0.54271238339803,
              47.068832410093
            ],
            [
              -0.55953163443577,
              47.061883133164
            ],
            [
              -0.55518423855519,
              47.056996280386
            ],
            [
              -0.55557809674432,
              47.043528672476
            ],
            [
              -0.54222220688058,
              47.035131514655
            ],
            [
              -0.54565906541349,
              47.029239134682
            ],
            [
              -0.56225950470765,
              47.030666495232
            ],
            [
              -0.56546953385811,
              47.019423404938
            ],
            [
              -0.57652952670071,
              47.017027138711
            ],
            [
              -0.58641245768408,
              47.009979798203
            ],
            [
              -0.59549628776894,
              46.997955261938
            ],
            [
              -0.61997935748009,
              46.993321083755
            ],
            [
              -0.62971403267853,
              46.996851004876
            ],
            [
              -0.64422463763986,
              46.995602969044
            ],
            [
              -0.67605096460406,
              47.000124265341
            ],
            [
              -0.68018770038511,
              46.987658683517
            ],
            [
              -0.69637193445308,
              46.994704963523
            ],
            [
              -0.71305373485382,
              46.986070538333
            ],
            [
              -0.72790285494931,
              46.994993345811
            ],
            [
              -0.74336287533223,
              47.000701964828
            ],
            [
              -0.74760195615967,
              46.991449795286
            ],
            [
              -0.76195094831376,
              46.992143526967
            ],
            [
              -0.7738784459047,
              47.004247669583
            ],
            [
              -0.78757295549441,
              47.005134613994
            ],
            [
              -0.80044123437384,
              46.994429249843
            ],
            [
              -0.82697025806846,
              46.992404409034
            ],
            [
              -0.83853635765488,
              46.985503699072
            ],
            [
              -0.85591689102216,
              46.979079840933
            ],
            [
              -0.84915622076396,
              46.973775579115
            ],
            [
              -0.85764337306558,
              46.969397597368
            ],
            [
              -0.8797290084417,
              46.975803771985
            ],
            [
              -0.89196408624284,
              46.975820414303
            ],
            [
              -0.89340549580325,
              46.98446883674
            ],
            [
              -0.90409186353969,
              46.994791373478
            ],
            [
              -0.91526324300451,
              46.992360009909
            ],
            [
              -0.91933682702711,
              47.003139081865
            ],
            [
              -0.93345650243922,
              47.008909976344
            ],
            [
              -0.9432396524795,
              47.000836884271
            ],
            [
              -0.94942491382078,
              47.003340444356
            ],
            [
              -0.95844801060585,
              46.994865565004
            ],
            [
              -0.96703560973155,
              47.00506363445
            ],
            [
              -0.98191922800345,
              47.010532067769
            ],
            [
              -1.0003939465651,
              47.020801743904
            ],
            [
              -1.015878308284,
              47.004142220601
            ],
            [
              -1.0553989625359,
              47.005682915522
            ],
            [
              -1.0905670874918,
              47.015309933936
            ],
            [
              -1.1013313178742,
              47.014807797063
            ],
            [
              -1.1192302198915,
              47.02084423636
            ],
            [
              -1.1430542158922,
              47.024763093203
            ],
            [
              -1.1485668205651,
              47.029548900794
            ],
            [
              -1.1170479432483,
              47.04013518647
            ],
            [
              -1.1205246215866,
              47.053223354371
            ],
            [
              -1.1154595616826,
              47.062560153317
            ],
            [
              -1.1426855537542,
              47.067863069799
            ],
            [
              -1.1539529642236,
              47.071889442774
            ],
            [
              -1.1619003785415,
              47.079572227316
            ],
            [
              -1.170288934637,
              47.092959055135
            ],
            [
              -1.2025045606441,
              47.095938796905
            ],
            [
              -1.2156175224974,
              47.098457006772
            ],
            [
              -1.2479716560946,
              47.100110089689
            ],
            [
              -1.2311522270542,
              47.109953407431
            ],
            [
              -1.2258467897311,
              47.11943849553
            ],
            [
              -1.2315747658211,
              47.122945286891
            ],
            [
              -1.2352480566999,
              47.138778896633
            ],
            [
              -1.2039490870349,
              47.146949890513
            ],
            [
              -1.1881724970889,
              47.158419543902
            ],
            [
              -1.1758721240253,
              47.163117819885
            ],
            [
              -1.1632136426884,
              47.17778687953
            ],
            [
              -1.1634036702522,
              47.185469232552
            ],
            [
              -1.1746946005099,
              47.190464465945
            ],
            [
              -1.1754240835306,
              47.200592163959
            ],
            [
              -1.1804900757451,
              47.207325903559
            ],
            [
              -1.1764040824717,
              47.214519763812
            ],
            [
              -1.1880508684465,
              47.2197922638
            ],
            [
              -1.1809721535433,
              47.240809376935
            ],
            [
              -1.1977244611046,
              47.250583767734
            ],
            [
              -1.2080715603628,
              47.25267682188
            ],
            [
              -1.2128496586385,
              47.24694698722
            ],
            [
              -1.2283677873261,
              47.239714645098
            ],
            [
              -1.2385771004628,
              47.24210028514
            ],
            [
              -1.2541720389343,
              47.249855480379
            ],
            [
              -1.260408442414,
              47.258036142558
            ],
            [
              -1.2733134508748,
              47.265391994663
            ],
            [
              -1.2794390101129,
              47.274415207416
            ],
            [
              -1.2765544420058,
              47.288508608744
            ],
            [
              -1.2946071704543,
              47.300999778811
            ],
            [
              -1.3051137160456,
              47.303168883061
            ],
            [
              -1.3278029846392,
              47.302945553464
            ],
            [
              -1.3443772279311,
              47.300910306344
            ],
            [
              -1.3541992398083,
              47.304153763744
            ],
            [
              -1.3308194081938,
              47.319396979635
            ],
            [
              -1.3187731044337,
              47.323336099427
            ],
            [
              -1.2998795136684,
              47.337619971759
            ],
            [
              -1.2801801086452,
              47.341119585894
            ],
            [
              -1.2612179241979,
              47.340856335692
            ],
            [
              -1.2286377810408,
              47.349925437284
            ],
            [
              -1.2084104790552,
              47.349457943211
            ],
            [
              -1.1876584713548,
              47.352352194789
            ],
            [
              -1.1765084306564,
              47.362101321316
            ],
            [
              -1.1676120647818,
              47.365883544326
            ],
            [
              -1.1376804248884,
              47.368243058298
            ],
            [
              -1.1119532943908,
              47.365936084266
            ],
            [
              -1.0787354054843,
              47.370793512665
            ],
            [
              -1.0574262200656,
              47.369170186345
            ],
            [
              -1.0450486975769,
              47.365466380448
            ],
            [
              -1.0178734478092,
              47.365057767936
            ],
            [
              -0.99430574522555,
              47.370712972962
            ],
            [
              -0.97486734238539,
              47.371383508034
            ],
            [
              -0.9674439712091,
              47.376798464598
            ],
            [
              -0.94643916329696,
              47.386464290023
            ],
            [
              -0.9508541294691,
              47.389265193007
            ],
            [
              -0.94914242982501,
              47.401623840241
            ],
            [
              -0.9606621441787,
              47.422477025273
            ],
            [
              -0.94853780130136,
              47.424848031912
            ],
            [
              -0.9540938849527,
              47.442875714801
            ],
            [
              -0.95422867483307,
              47.453907688972
            ],
            [
              -0.96687816676134,
              47.468215265345
            ],
            [
              -0.96746458627544,
              47.474455765188
            ],
            [
              -0.95914434624296,
              47.481625087845
            ],
            [
              -0.96748096263572,
              47.492796746016
            ],
            [
              -1.0086224906369,
              47.48911227584
            ],
            [
              -1.0268648640358,
              47.5003865002
            ],
            [
              -1.0389970910658,
              47.505255272767
            ],
            [
              -1.0726112535239,
              47.502111250421
            ],
            [
              -1.1118786005754,
              47.501174450819
            ],
            [
              -1.140588663811,
              47.504692689951
            ],
            [
              -1.1572382827497,
              47.510080183398
            ],
            [
              -1.1612362268542,
              47.52238486075
            ],
            [
              -1.1670894674193,
              47.523469091666
            ],
            [
              -1.1658977332461,
              47.533633563364
            ],
            [
              -1.1524509517215,
              47.542456932846
            ],
            [
              -1.1783486783423,
              47.548022920882
            ],
            [
              -1.1724173944719,
              47.573007499646
            ],
            [
              -1.1274999354642,
              47.569742736207
            ],
            [
              -1.1093120205118,
              47.565174597558
            ],
            [
              -1.0753456782126,
              47.567573975026
            ],
            [
              -1.0459081759245,
              47.563351957166
            ],
            [
              -1.0325559818837,
              47.572469886138
            ],
            [
              -1.0222793698559,
              47.576022698104
            ],
            [
              -1.0068589445508,
              47.589057173366
            ],
            [
              -1.0254734250509,
              47.599322620735
            ],
            [
              -1.0475491196756,
              47.603183225277
            ],
            [
              -1.0564923836184,
              47.601481852962
            ],
            [
              -1.089809516174,
              47.611397470687
            ],
            [
              -1.1006504053381,
              47.620681819299
            ],
            [
              -1.1382005303391,
              47.619457993878
            ],
            [
              -1.1423383902321,
              47.62570440861
            ],
            [
              -1.1570762807876,
              47.635142948103
            ],
            [
              -1.1599410529377,
              47.660445255957
            ],
            [
              -1.1682996694516,
              47.660770013296
            ],
            [
              -1.1829291341983,
              47.66947490014
            ],
            [
              -1.1762377379005,
              47.683666204204
            ],
            [
              -1.1749255752394,
              47.694565156541
            ],
            [
              -1.1962168921655,
              47.709901315898
            ],
            [
              -1.1951247655948,
              47.721548781739
            ],
            [
              -1.2154225040771,
              47.718814964502
            ],
            [
              -1.2401834259633,
              47.726226363324
            ],
            [
              -1.2545837696673,
              47.733639711665
            ],
            [
              -1.2557221546099,
              47.742562297607
            ],
            [
              -1.2417265849441,
              47.742858014742
            ],
            [
              -1.2384524343117,
              47.755370176059
            ],
            [
              -1.2481701318392,
              47.763142394181
            ],
            [
              -1.2458850128779,
              47.776717450948
            ]
          ]
        ]
      },
      "properties": {
        "code": "49",
        "nom": "Maine-et-Loire",
        "population": 820713,
        "delitspour1000": "3,459",
        "nombremosque": 13,
        "parentsEtrangers": "81,6",
        "benefRsa": 29828,
        "eoliennes": 80
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -1.1196198636379,
              49.355568981262
            ],
            [
              -1.1134683084454,
              49.32794259286
            ],
            [
              -1.114799377366,
              49.325241838469
            ],
            [
              -1.139541028092,
              49.309870906862
            ],
            [
              -1.1400992472453,
              49.297391637898
            ],
            [
              -1.1287283915965,
              49.284366097517
            ],
            [
              -1.1359174141965,
              49.270327053301
            ],
            [
              -1.1134205603101,
              49.26132609411
            ],
            [
              -1.0929054280943,
              49.244207033565
            ],
            [
              -1.0705285280238,
              49.231351483459
            ],
            [
              -1.0465002285724,
              49.221145430593
            ],
            [
              -1.0397982304764,
              49.221748483848
            ],
            [
              -1.0226746076439,
              49.203388961893
            ],
            [
              -1.008331107792,
              49.204438040158
            ],
            [
              -0.97546102084932,
              49.192158499386
            ],
            [
              -0.95553394445393,
              49.199703849463
            ],
            [
              -0.94860370794048,
              49.209215453018
            ],
            [
              -0.93236757629277,
              49.218052232902
            ],
            [
              -0.92058243337368,
              49.221066573082
            ],
            [
              -0.90623307934633,
              49.210246263522
            ],
            [
              -0.90087557977357,
              49.202972349148
            ],
            [
              -0.91014418669767,
              49.193477773729
            ],
            [
              -0.90789248124883,
              49.187581183412
            ],
            [
              -0.92159698525926,
              49.177808099261
            ],
            [
              -0.9265375261167,
              49.164211141303
            ],
            [
              -0.93733782641062,
              49.161524007485
            ],
            [
              -0.94993136874348,
              49.167723933697
            ],
            [
              -0.95853549196422,
              49.161275765491
            ],
            [
              -0.93840915692818,
              49.153796870908
            ],
            [
              -0.93145227164953,
              49.145575300849
            ],
            [
              -0.9082130769048,
              49.139124090468
            ],
            [
              -0.88659533008778,
              49.129429507194
            ],
            [
              -0.89145000590453,
              49.121820735408
            ],
            [
              -0.91341262847177,
              49.11485491887
            ],
            [
              -0.91831266671293,
              49.104996360271
            ],
            [
              -0.8940645097255,
              49.116755302973
            ],
            [
              -0.87845403715793,
              49.117103390716
            ],
            [
              -0.87686430714386,
              49.097973354467
            ],
            [
              -0.8799621463578,
              49.088797464537
            ],
            [
              -0.87038334838631,
              49.078308430052
            ],
            [
              -0.86909257922002,
              49.067901172753
            ],
            [
              -0.87639081528478,
              49.054815856126
            ],
            [
              -0.89165406184456,
              49.046294477679
            ],
            [
              -0.88451826383679,
              49.038716825208
            ],
            [
              -0.87366526623988,
              49.04018664407
            ],
            [
              -0.86415044765934,
              49.034443715562
            ],
            [
              -0.8694559276149,
              49.027025688892
            ],
            [
              -0.88557803424434,
              49.022391313255
            ],
            [
              -0.89542446041669,
              49.01170524167
            ],
            [
              -0.90511414763453,
              49.011393751389
            ],
            [
              -0.91364777549745,
              48.990960439638
            ],
            [
              -0.94272995383286,
              48.967706984878
            ],
            [
              -0.95573198276557,
              48.966231366504
            ],
            [
              -0.97080529201629,
              48.957263015587
            ],
            [
              -0.99989884101366,
              48.949851065815
            ],
            [
              -1.0136550949671,
              48.954124357442
            ],
            [
              -1.0284088946,
              48.962309074946
            ],
            [
              -1.0565254473082,
              48.958444860029
            ],
            [
              -1.0553210379068,
              48.950810391987
            ],
            [
              -1.0635567972721,
              48.946352358841
            ],
            [
              -1.0664754230573,
              48.930105576078
            ],
            [
              -1.0755194629225,
              48.921508411991
            ],
            [
              -1.035972876941,
              48.923542402676
            ],
            [
              -1.0226211199025,
              48.927085647197
            ],
            [
              -1.0137636502161,
              48.92248637784
            ],
            [
              -1.0137364005261,
              48.915956453501
            ],
            [
              -1.0285110025673,
              48.901166391516
            ],
            [
              -1.049194357089,
              48.89403173962
            ],
            [
              -1.0591714116763,
              48.877258889935
            ],
            [
              -1.0711778612927,
              48.870286148434
            ],
            [
              -1.0985223309161,
              48.876085328616
            ],
            [
              -1.1028797262918,
              48.865101716154
            ],
            [
              -1.1217118082419,
              48.855308135365
            ],
            [
              -1.121155943427,
              48.848217447571
            ],
            [
              -1.1326244563768,
              48.848849041978
            ],
            [
              -1.141691137149,
              48.837551886611
            ],
            [
              -1.1553830397846,
              48.836458793132
            ],
            [
              -1.1595014604014,
              48.82620729012
            ],
            [
              -1.1535636085327,
              48.82219908382
            ],
            [
              -1.1305025921914,
              48.820021269129
            ],
            [
              -1.1023003567829,
              48.814263957252
            ],
            [
              -1.0946891771954,
              48.800719344414
            ],
            [
              -1.1003652053547,
              48.789674356188
            ],
            [
              -1.0894717560428,
              48.781518523516
            ],
            [
              -1.0587396460307,
              48.773348161227
            ],
            [
              -1.0455588935307,
              48.782604961546
            ],
            [
              -1.0239195312287,
              48.7759589758
            ],
            [
              -1.0102372535173,
              48.774014961547
            ],
            [
              -0.99144565445194,
              48.778122680489
            ],
            [
              -0.97100455303881,
              48.772828521949
            ],
            [
              -0.96705625172907,
              48.786081268022
            ],
            [
              -0.94544441248664,
              48.784659596649
            ],
            [
              -0.94033611373863,
              48.778571609871
            ],
            [
              -0.91919344396431,
              48.770846161014
            ],
            [
              -0.89348419949129,
              48.766124342405
            ],
            [
              -0.88440205541888,
              48.756990387555
            ],
            [
              -0.87084306832372,
              48.755747083772
            ],
            [
              -0.85387405368382,
              48.763877386403
            ],
            [
              -0.83586390210947,
              48.75907014808
            ],
            [
              -0.84094105476412,
              48.752223582274
            ],
            [
              -0.85184627231567,
              48.746156709397
            ],
            [
              -0.84922855934838,
              48.730753766159
            ],
            [
              -0.8371029642378,
              48.728366490518
            ],
            [
              -0.81789729577365,
              48.731176402389
            ],
            [
              -0.80858726177931,
              48.725531855933
            ],
            [
              -0.79895320122087,
              48.710188025931
            ],
            [
              -0.77233757952528,
              48.698807486761
            ],
            [
              -0.76471221845477,
              48.693078451377
            ],
            [
              -0.73732101904671,
              48.686666601324
            ],
            [
              -0.73736197894019,
              48.679558842566
            ],
            [
              -0.75377161787902,
              48.674518089003
            ],
            [
              -0.77335752529712,
              48.65747247994
            ],
            [
              -0.76290626959504,
              48.631905426948
            ],
            [
              -0.74221354045865,
              48.627267255804
            ],
            [
              -0.75261576309569,
              48.6176193763
            ],
            [
              -0.75174820294264,
              48.601317444612
            ],
            [
              -0.76572444871462,
              48.592514292438
            ],
            [
              -0.77410365336681,
              48.575687422281
            ],
            [
              -0.77416870927632,
              48.562676617257
            ],
            [
              -0.78883096952577,
              48.553140378287
            ],
            [
              -0.80570967878668,
              48.549877678589
            ],
            [
              -0.82695764917946,
              48.532235760061
            ],
            [
              -0.84766933110894,
              48.52112650275
            ],
            [
              -0.86036021134895,
              48.501458584456
            ],
            [
              -0.8774635305069,
              48.499620464013
            ],
            [
              -0.89624907070572,
              48.495083815873
            ],
            [
              -0.91847065624527,
              48.500394275132
            ],
            [
              -0.92236046915212,
              48.512389219916
            ],
            [
              -0.93371106054579,
              48.51502659051
            ],
            [
              -0.95637394558324,
              48.516620056723
            ],
            [
              -0.96425363340344,
              48.510812466339
            ],
            [
              -0.96235389107925,
              48.503667410902
            ],
            [
              -0.97225823660351,
              48.494600246107
            ],
            [
              -1.0039961840993,
              48.489172448089
            ],
            [
              -1.0514451903751,
              48.509308794368
            ],
            [
              -1.0605497194866,
              48.515346429697
            ],
            [
              -1.0701643748629,
              48.508492017418
            ],
            [
              -1.0969039995416,
              48.512091635194
            ],
            [
              -1.1160901264748,
              48.5212333284
            ],
            [
              -1.1305430206604,
              48.52170348713
            ],
            [
              -1.1470767776101,
              48.517498765375
            ],
            [
              -1.156893190635,
              48.521285821117
            ],
            [
              -1.1699074343327,
              48.531104413062
            ],
            [
              -1.1889294040247,
              48.52889285086
            ],
            [
              -1.2065516679372,
              48.542206088249
            ],
            [
              -1.2158216927154,
              48.538229063528
            ],
            [
              -1.2363206998987,
              48.538079953318
            ],
            [
              -1.254274261097,
              48.543267861594
            ],
            [
              -1.2722481273192,
              48.533920331809
            ],
            [
              -1.2695738148732,
              48.524058079717
            ],
            [
              -1.2792034941015,
              48.509166405754
            ],
            [
              -1.2882493476535,
              48.506990431124
            ],
            [
              -1.3025661285534,
              48.498856875187
            ],
            [
              -1.3263369028651,
              48.498742404871
            ],
            [
              -1.3302853407871,
              48.489791631794
            ],
            [
              -1.3393694052252,
              48.490940085021
            ],
            [
              -1.3450398581682,
              48.484912379925
            ],
            [
              -1.3463506846745,
              48.471670546758
            ],
            [
              -1.3772457352191,
              48.458282754255
            ],
            [
              -1.3966756078064,
              48.462052879811
            ],
            [
              -1.4064842226881,
              48.460604032661
            ],
            [
              -1.429135838213,
              48.462552562467
            ],
            [
              -1.4403529299447,
              48.471140825579
            ],
            [
              -1.4362528107594,
              48.478368227471
            ],
            [
              -1.4492603238973,
              48.486102161685
            ],
            [
              -1.4670861806686,
              48.48806705044
            ],
            [
              -1.4731862558468,
              48.485386403307
            ],
            [
              -1.4899417997599,
              48.489372357681
            ],
            [
              -1.4956406657121,
              48.508898642535
            ],
            [
              -1.512277768964,
              48.524834117063
            ],
            [
              -1.519564216371,
              48.540018024898
            ],
            [
              -1.5332855375208,
              48.548003700235
            ],
            [
              -1.5291705857729,
              48.560522846907
            ],
            [
              -1.5189218496777,
              48.56659037005
            ],
            [
              -1.5286423474284,
              48.579179736848
            ],
            [
              -1.5429927154595,
              48.580446827686
            ],
            [
              -1.544011006159,
              48.587389031303
            ],
            [
              -1.5396520439432,
              48.599920146805
            ],
            [
              -1.5484855009897,
              48.607366445795
            ],
            [
              -1.5651218255326,
              48.614397614273
            ],
            [
              -1.5710894380361,
              48.626441149011
            ],
            [
              -1.5435452019954,
              48.63121633289
            ],
            [
              -1.5186481112506,
              48.627241443224
            ],
            [
              -1.515647973505,
              48.618249181032
            ],
            [
              -1.4875143099536,
              48.619683118162
            ],
            [
              -1.477188590376,
              48.618737838556
            ],
            [
              -1.453119129556,
              48.624675630959
            ],
            [
              -1.4491592197362,
              48.623416514324
            ],
            [
              -1.4258419389759,
              48.637559262748
            ],
            [
              -1.4045832706425,
              48.643393783536
            ],
            [
              -1.3788928672818,
              48.641984051038
            ],
            [
              -1.3574234034789,
              48.635483059321
            ],
            [
              -1.3667902778659,
              48.646816772419
            ],
            [
              -1.37779618361,
              48.650980830291
            ],
            [
              -1.3931922892245,
              48.650524483715
            ],
            [
              -1.4008843035347,
              48.658912226981
            ],
            [
              -1.3868080886343,
              48.674230077774
            ],
            [
              -1.3885017122394,
              48.680611484574
            ],
            [
              -1.4109326754602,
              48.671499504878
            ],
            [
              -1.432437001637,
              48.666381359345
            ],
            [
              -1.4385827533746,
              48.656898441113
            ],
            [
              -1.4453056482617,
              48.655010677863
            ],
            [
              -1.4492892816983,
              48.670001538869
            ],
            [
              -1.4812544062845,
              48.684374376408
            ],
            [
              -1.49696844753,
              48.683669583998
            ],
            [
              -1.5049509038692,
              48.687406798864
            ],
            [
              -1.5184702088038,
              48.716306146704
            ],
            [
              -1.5319277117459,
              48.731023322195
            ],
            [
              -1.5601753647368,
              48.735462626987
            ],
            [
              -1.5701538176576,
              48.742946555279
            ],
            [
              -1.5744027818825,
              48.75185050251
            ],
            [
              -1.5701442959905,
              48.772302111897
            ],
            [
              -1.569890517616,
              48.800224865932
            ],
            [
              -1.5744376405691,
              48.821682401355
            ],
            [
              -1.5941495509687,
              48.834715454159
            ],
            [
              -1.6013201144142,
              48.836516012355
            ],
            [
              -1.5871738726074,
              48.846459450942
            ],
            [
              -1.5763822718035,
              48.866233003819
            ],
            [
              -1.5643455142932,
              48.921580374553
            ],
            [
              -1.5541181922605,
              48.900139698967
            ],
            [
              -1.5434258848416,
              48.931027158992
            ],
            [
              -1.5495360815231,
              48.940185428234
            ],
            [
              -1.5621107504118,
              48.941016659011
            ],
            [
              -1.5620295824626,
              48.981625893318
            ],
            [
              -1.5589869081576,
              48.991685570978
            ],
            [
              -1.5607098810567,
              49.001480959204
            ],
            [
              -1.5507932300727,
              49.003261996562
            ],
            [
              -1.557066459398,
              49.011560491842
            ],
            [
              -1.5561478071951,
              49.024690465146
            ],
            [
              -1.5398053590488,
              49.034164591611
            ],
            [
              -1.5238615597683,
              49.027357230532
            ],
            [
              -1.5093830641249,
              49.026943233212
            ],
            [
              -1.5417948391641,
              49.041162160828
            ],
            [
              -1.5471012949252,
              49.037292772502
            ],
            [
              -1.5592318014493,
              49.038274352716
            ],
            [
              -1.5688741957393,
              49.032047703421
            ],
            [
              -1.5785771444366,
              49.015283279183
            ],
            [
              -1.5745849862677,
              49.002853692658
            ],
            [
              -1.5816167633561,
              49.002704056487
            ],
            [
              -1.5937891730984,
              49.022503424284
            ],
            [
              -1.60295018717,
              49.05577884026
            ],
            [
              -1.5980958444687,
              49.058557083517
            ],
            [
              -1.5984218569282,
              49.074513497125
            ],
            [
              -1.6094145764943,
              49.079281603206
            ],
            [
              -1.6104318663509,
              49.092932611918
            ],
            [
              -1.6069307277516,
              49.110034240873
            ],
            [
              -1.5999863662105,
              49.118513175091
            ],
            [
              -1.5947459734594,
              49.131928426288
            ],
            [
              -1.5810718554805,
              49.131674948757
            ],
            [
              -1.595825359512,
              49.14283223955
            ],
            [
              -1.5988469663686,
              49.169200419634
            ],
            [
              -1.6073215295119,
              49.196647120312
            ],
            [
              -1.6051478142522,
              49.2037447513
            ],
            [
              -1.6107491510687,
              49.214490949154
            ],
            [
              -1.5717703394133,
              49.223972136229
            ],
            [
              -1.5892944345851,
              49.233427410876
            ],
            [
              -1.6169953390227,
              49.231610466351
            ],
            [
              -1.625063732473,
              49.225074527383
            ],
            [
              -1.6316846090167,
              49.21422230031
            ],
            [
              -1.6425012327523,
              49.223929836401
            ],
            [
              -1.6541603483738,
              49.241445046412
            ],
            [
              -1.6609046758189,
              49.259266389315
            ],
            [
              -1.6782177867605,
              49.280142766374
            ],
            [
              -1.6657720735083,
              49.28188131922
            ],
            [
              -1.6652075172903,
              49.286811899007
            ],
            [
              -1.6831075465952,
              49.28762544819
            ],
            [
              -1.6968629360079,
              49.302727209961
            ],
            [
              -1.71117197286,
              49.325094637036
            ],
            [
              -1.7012938138615,
              49.323915736806
            ],
            [
              -1.6968290117754,
              49.331720453626
            ],
            [
              -1.7176162414367,
              49.326352117431
            ],
            [
              -1.7247902425762,
              49.327450118599
            ],
            [
              -1.7411588494555,
              49.344037789167
            ],
            [
              -1.7647272242817,
              49.363551606894
            ],
            [
              -1.7787957331797,
              49.371673062007
            ],
            [
              -1.807044519245,
              49.371886570418
            ],
            [
              -1.8198270387403,
              49.39048194081
            ],
            [
              -1.8233630709264,
              49.405355197016
            ],
            [
              -1.8234334633411,
              49.438806741795
            ],
            [
              -1.8267734462966,
              49.452912708931
            ],
            [
              -1.8365701217503,
              49.468323740242
            ],
            [
              -1.8434098400699,
              49.47077677688
            ],
            [
              -1.8464190476711,
              49.498628821215
            ],
            [
              -1.8526021717957,
              49.510385793698
            ],
            [
              -1.8686791471828,
              49.51237165287
            ],
            [
              -1.8819330190767,
              49.51931183704
            ],
            [
              -1.8865295575919,
              49.527614954968
            ],
            [
              -1.8856912059509,
              49.540389845217
            ],
            [
              -1.8599268014835,
              49.550652196301
            ],
            [
              -1.8410258496794,
              49.571733495111
            ],
            [
              -1.8423657220792,
              49.603652142802
            ],
            [
              -1.8451382307403,
              49.619088241649
            ],
            [
              -1.8550073982962,
              49.642822194747
            ],
            [
              -1.8602959828965,
              49.650193854758
            ],
            [
              -1.8734840432458,
              49.657595242746
            ],
            [
              -1.8983983394389,
              49.665827626044
            ],
            [
              -1.9178223549036,
              49.665792917579
            ],
            [
              -1.9291066373282,
              49.671817058684
            ],
            [
              -1.9461839215553,
              49.67434359255
            ],
            [
              -1.9362755021206,
              49.687906388906
            ],
            [
              -1.9364332705437,
              49.693755561379
            ],
            [
              -1.9472733176655,
              49.704928806767
            ],
            [
              -1.9422468301096,
              49.725557927402
            ],
            [
              -1.9165182727857,
              49.724881008941
            ],
            [
              -1.8977813504921,
              49.71710162128
            ],
            [
              -1.8901181931224,
              49.707153428958
            ],
            [
              -1.8765349678081,
              49.706745540386
            ],
            [
              -1.8524826023107,
              49.716941279614
            ],
            [
              -1.8395312458249,
              49.711552803104
            ],
            [
              -1.8276334422186,
              49.693428126343
            ],
            [
              -1.815242405587,
              49.68887201482
            ],
            [
              -1.7903115872551,
              49.687062757357
            ],
            [
              -1.7626785199869,
              49.678675842585
            ],
            [
              -1.7353787740872,
              49.677475890784
            ],
            [
              -1.7226166267492,
              49.67923539334
            ],
            [
              -1.7044356804014,
              49.670860552488
            ],
            [
              -1.6867469480741,
              49.673066182254
            ],
            [
              -1.6783904494577,
              49.661233437166
            ],
            [
              -1.6501443485293,
              49.65679900284
            ],
            [
              -1.6328523940131,
              49.660641576202
            ],
            [
              -1.6240147652106,
              49.644053113794
            ],
            [
              -1.6151605571489,
              49.643460933616
            ],
            [
              -1.595585416435,
              49.654476141253
            ],
            [
              -1.5790665683374,
              49.654108869195
            ],
            [
              -1.5657216224347,
              49.6564319962
            ],
            [
              -1.5415760950278,
              49.65411200539
            ],
            [
              -1.5142668707452,
              49.659906363431
            ],
            [
              -1.4951992999355,
              49.668112324564
            ],
            [
              -1.485803063592,
              49.669315434164
            ],
            [
              -1.4848705161427,
              49.677760491652
            ],
            [
              -1.4753944670543,
              49.681673759847
            ],
            [
              -1.4759879580656,
              49.692012655502
            ],
            [
              -1.4710843353661,
              49.69667139043
            ],
            [
              -1.4551125681652,
              49.691211562863
            ],
            [
              -1.4211648985369,
              49.703706318223
            ],
            [
              -1.4112493611078,
              49.70195420522
            ],
            [
              -1.3858209704338,
              49.706235946931
            ],
            [
              -1.369566553066,
              49.706887557051
            ],
            [
              -1.3498383478638,
              49.702068404407
            ],
            [
              -1.2878440862449,
              49.692595816605
            ],
            [
              -1.2679291968387,
              49.695329176599
            ],
            [
              -1.2715087649217,
              49.683652851227
            ],
            [
              -1.2538864036932,
              49.663805883133
            ],
            [
              -1.2391801151651,
              49.652402684434
            ],
            [
              -1.2432478604805,
              49.648200405198
            ],
            [
              -1.2295967339571,
              49.624653776103
            ],
            [
              -1.228866448748,
              49.607863429808
            ],
            [
              -1.2542854436666,
              49.612658345594
            ],
            [
              -1.2662660112701,
              49.592845791028
            ],
            [
              -1.2612384503723,
              49.586657015411
            ],
            [
              -1.267613440608,
              49.581953814631
            ],
            [
              -1.2840568870782,
              49.587252068376
            ],
            [
              -1.2962280470313,
              49.583173183623
            ],
            [
              -1.3042001777054,
              49.573677338992
            ],
            [
              -1.3091731760214,
              49.552609580003
            ],
            [
              -1.3063595679898,
              49.538928237908
            ],
            [
              -1.2895246997246,
              49.51798179534
            ],
            [
              -1.2699260332883,
              49.500541701878
            ],
            [
              -1.2583845422005,
              49.48641032582
            ],
            [
              -1.2233382850858,
              49.455809055808
            ],
            [
              -1.182532110005,
              49.424017594444
            ],
            [
              -1.1679979463415,
              49.408031770721
            ],
            [
              -1.1617337685799,
              49.39174984514
            ],
            [
              -1.1732751083089,
              49.37843798982
            ],
            [
              -1.1775396285395,
              49.36489675323
            ],
            [
              -1.1639466580138,
              49.366660471267
            ],
            [
              -1.1388379041632,
              49.358414252281
            ],
            [
              -1.1360493392017,
              49.354043408557
            ],
            [
              -1.1196198636379,
              49.355568981262
            ]
          ]
        ]
      },
      "properties": {
        "code": "50",
        "nom": "Manche",
        "population": 495093,
        "delitspour1000": "3,482",
        "nombremosque": 5,
        "parentsEtrangers": "90,7",
        "benefRsa": 13992,
        "eoliennes": 70
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                -2.8828361523996,
                47.342633634283
              ],
              [
                -2.8688242027986,
                47.347187413333
              ],
              [
                -2.8640228377349,
                47.337396656922
              ],
              [
                -2.8851980047361,
                47.332204587601
              ],
              [
                -2.8828361523996,
                47.342633634283
              ]
            ]
          ],
          [
            [
              [
                -3.4217929168617,
                47.619997807491
              ],
              [
                -3.4406778342018,
                47.627446574356
              ],
              [
                -3.4456273600808,
                47.623593013481
              ],
              [
                -3.4619142000708,
                47.620345627722
              ],
              [
                -3.4742467748166,
                47.62698825448
              ],
              [
                -3.4852053906539,
                47.628378526095
              ],
              [
                -3.5076315908068,
                47.640599145055
              ],
              [
                -3.5132724400036,
                47.65091255248
              ],
              [
                -3.4969409139507,
                47.653807933351
              ],
              [
                -3.4442954529884,
                47.643499781555
              ],
              [
                -3.4294853772433,
                47.642297272249
              ],
              [
                -3.416338603723,
                47.630594308492
              ],
              [
                -3.4217929168617,
                47.619997807491
              ]
            ]
          ],
          [
            [
              [
                -2.9481489550955,
                47.373337178232
              ],
              [
                -2.9612329905761,
                47.384142688142
              ],
              [
                -2.9734969970118,
                47.385337455258
              ],
              [
                -2.970456003478,
                47.393456515554
              ],
              [
                -2.9497473196772,
                47.391474918453
              ],
              [
                -2.9539501005726,
                47.383664372021
              ],
              [
                -2.9481489550955,
                47.373337178232
              ]
            ]
          ],
          [
            [
              [
                -3.2354396164691,
                47.323814198525
              ],
              [
                -3.248052686952,
                47.330283433793
              ],
              [
                -3.2635566742404,
                47.352257081813
              ],
              [
                -3.2592142344527,
                47.357022204229
              ],
              [
                -3.2618219738531,
                47.371275182462
              ],
              [
                -3.248770867599,
                47.379576261704
              ],
              [
                -3.244700982732,
                47.387618359758
              ],
              [
                -3.2340762447846,
                47.379338291339
              ],
              [
                -3.2175838675208,
                47.378419150942
              ],
              [
                -3.2163525094804,
                47.373608898918
              ],
              [
                -3.201274824189,
                47.372213510615
              ],
              [
                -3.1935193838263,
                47.365912324522
              ],
              [
                -3.1730081240198,
                47.361684567685
              ],
              [
                -3.1558604781053,
                47.361080830931
              ],
              [
                -3.1534877470215,
                47.348344091052
              ],
              [
                -3.1451698763814,
                47.342292073717
              ],
              [
                -3.1396470634137,
                47.329840381841
              ],
              [
                -3.1150509508386,
                47.324060678234
              ],
              [
                -3.1110236087502,
                47.319021634193
              ],
              [
                -3.0931245649635,
                47.315130772229
              ],
              [
                -3.0741240211962,
                47.317262323892
              ],
              [
                -3.0633503439268,
                47.315039283837
              ],
              [
                -3.0678622487088,
                47.296750488818
              ],
              [
                -3.0759226223168,
                47.286048310497
              ],
              [
                -3.0865518494098,
                47.283069445657
              ],
              [
                -3.1058963104173,
                47.284999245839
              ],
              [
                -3.1228947190822,
                47.290552518526
              ],
              [
                -3.1362157348542,
                47.287982908601
              ],
              [
                -3.1512588812655,
                47.293806467377
              ],
              [
                -3.1762732030367,
                47.300368448324
              ],
              [
                -3.1856628166084,
                47.295185330833
              ],
              [
                -3.2021408353701,
                47.296875977003
              ],
              [
                -3.2171954083953,
                47.293469947191
              ],
              [
                -3.2284492482294,
                47.301290747295
              ],
              [
                -3.2387014326446,
                47.303636953067
              ],
              [
                -3.2490903024394,
                47.316119926971
              ],
              [
                -3.2354396164691,
                47.323814198525
              ]
            ]
          ],
          [
            [
              [
                -2.8595623119286,
                47.559896175763
              ],
              [
                -2.8615807977029,
                47.568279187496
              ],
              [
                -2.853851482193,
                47.594134904148
              ],
              [
                -2.8447503210418,
                47.601281769517
              ],
              [
                -2.8379117800168,
                47.591917276455
              ],
              [
                -2.8501830550859,
                47.586592024447
              ],
              [
                -2.8542598282248,
                47.575141694299
              ],
              [
                -2.8533540092104,
                47.567483466314
              ],
              [
                -2.8595623119286,
                47.559896175763
              ]
            ]
          ],
          [
            [
              [
                -2.80960545819,
                47.578258375141
              ],
              [
                -2.8090954794976,
                47.592251881558
              ],
              [
                -2.7939896392391,
                47.601731628428
              ],
              [
                -2.7868109056903,
                47.595356275895
              ],
              [
                -2.7994090459784,
                47.588534826736
              ],
              [
                -2.80960545819,
                47.578258375141
              ]
            ]
          ],
          [
            [
              [
                -2.2872742268268,
                48.133743948459
              ],
              [
                -2.2841739522363,
                48.132920802061
              ],
              [
                -2.2555468160419,
                48.109432687524
              ],
              [
                -2.2524855674325,
                48.080525225239
              ],
              [
                -2.2579421965793,
                48.072970095118
              ],
              [
                -2.2523228001164,
                48.063237453261
              ],
              [
                -2.2343109537123,
                48.063175476394
              ],
              [
                -2.2332416975112,
                48.075625301872
              ],
              [
                -2.220490188337,
                48.079702639699
              ],
              [
                -2.1873992697308,
                48.080984858316
              ],
              [
                -2.1809330551359,
                48.083170741696
              ],
              [
                -2.1684835856701,
                48.076205340153
              ],
              [
                -2.1732539089072,
                48.062569615512
              ],
              [
                -2.1937224368739,
                48.05137685593
              ],
              [
                -2.221628555571,
                48.050885101825
              ],
              [
                -2.2418773650688,
                48.052836855884
              ],
              [
                -2.2686124960674,
                48.035169047477
              ],
              [
                -2.2795921573725,
                48.026359055809
              ],
              [
                -2.2808005622425,
                48.015636920079
              ],
              [
                -2.2755284368087,
                48.009854335432
              ],
              [
                -2.289084836122,
                48.001031912939
              ],
              [
                -2.2817928808654,
                47.990960728676
              ],
              [
                -2.2395885283037,
                47.99946545839
              ],
              [
                -2.2154773943836,
                47.993019365044
              ],
              [
                -2.2039507169895,
                47.993962577836
              ],
              [
                -2.1934375448781,
                47.990558846207
              ],
              [
                -2.1843552422385,
                47.982080329852
              ],
              [
                -2.1741360719446,
                47.979763196453
              ],
              [
                -2.1534958198305,
                47.983345608933
              ],
              [
                -2.1368695680738,
                47.979118421446
              ],
              [
                -2.1197720456499,
                47.960945697028
              ],
              [
                -2.1046668060842,
                47.951972939517
              ],
              [
                -2.0998204082893,
                47.937385608143
              ],
              [
                -2.0790902252501,
                47.919526032935
              ],
              [
                -2.0820218168079,
                47.908921655539
              ],
              [
                -2.1049849771455,
                47.898412563328
              ],
              [
                -2.1120117364518,
                47.8918547475
              ],
              [
                -2.116305330268,
                47.880956896621
              ],
              [
                -2.0973437025111,
                47.864834365215
              ],
              [
                -2.1087497149234,
                47.848456505838
              ],
              [
                -2.0901567411003,
                47.843759080606
              ],
              [
                -2.0742831600581,
                47.851162116274
              ],
              [
                -2.0559308187502,
                47.868247240806
              ],
              [
                -2.0509255834937,
                47.857131573978
              ],
              [
                -2.0530803268714,
                47.849236872553
              ],
              [
                -2.0479926743089,
                47.83629928796
              ],
              [
                -2.0357552590146,
                47.832300691003
              ],
              [
                -2.0495290789647,
                47.816920875592
              ],
              [
                -2.0675461006082,
                47.8132690401
              ],
              [
                -2.0722637386177,
                47.790436610757
              ],
              [
                -2.0941380218382,
                47.779900928589
              ],
              [
                -2.1088453868798,
                47.778777053192
              ],
              [
                -2.1156184419733,
                47.770321364228
              ],
              [
                -2.1281904903395,
                47.761376495367
              ],
              [
                -2.0847183154253,
                47.762715505001
              ],
              [
                -2.079533401352,
                47.750210413121
              ],
              [
                -2.0607591270552,
                47.74930009798
              ],
              [
                -2.0587066183582,
                47.738252293297
              ],
              [
                -2.0897138561506,
                47.738564040617
              ],
              [
                -2.1086098878283,
                47.736177595411
              ],
              [
                -2.1086460549724,
                47.731041275814
              ],
              [
                -2.1314707538757,
                47.722240320619
              ],
              [
                -2.1193465009818,
                47.710633585881
              ],
              [
                -2.1241280958969,
                47.689731038422
              ],
              [
                -2.1226672706492,
                47.682670445653
              ],
              [
                -2.104366334765,
                47.67679465737
              ],
              [
                -2.1031817950263,
                47.669812920473
              ],
              [
                -2.1143461676916,
                47.649477188598
              ],
              [
                -2.1116920444994,
                47.64565993511
              ],
              [
                -2.0947855127512,
                47.645134778838
              ],
              [
                -2.0970339249479,
                47.631356309182
              ],
              [
                -2.0849952925543,
                47.621229400095
              ],
              [
                -2.0869236114994,
                47.602779172942
              ],
              [
                -2.0992687458213,
                47.597488169512
              ],
              [
                -2.1037228936324,
                47.589435010495
              ],
              [
                -2.096507139486,
                47.572369157778
              ],
              [
                -2.1038869792414,
                47.549970178949
              ],
              [
                -2.096829841083,
                47.540329954312
              ],
              [
                -2.0985591504874,
                47.533957226787
              ],
              [
                -2.1073294072614,
                47.531054380017
              ],
              [
                -2.1560180197876,
                47.522028206659
              ],
              [
                -2.1529569093967,
                47.510962718509
              ],
              [
                -2.1541860993449,
                47.496364966339
              ],
              [
                -2.1636756892187,
                47.49050097161
              ],
              [
                -2.1833018888491,
                47.491696689458
              ],
              [
                -2.192163736718,
                47.496643815865
              ],
              [
                -2.1837606578958,
                47.501791543642
              ],
              [
                -2.184622799181,
                47.511938749674
              ],
              [
                -2.2066684734953,
                47.510040688056
              ],
              [
                -2.2199696788078,
                47.505419526219
              ],
              [
                -2.2442830226806,
                47.493603343394
              ],
              [
                -2.2656383885011,
                47.501780337272
              ],
              [
                -2.2585200153358,
                47.504528170665
              ],
              [
                -2.2629685042061,
                47.512911091518
              ],
              [
                -2.2808336661554,
                47.509587432992
              ],
              [
                -2.2965137349146,
                47.51605888675
              ],
              [
                -2.2991228743726,
                47.500477533121
              ],
              [
                -2.3036714616823,
                47.49241124835
              ],
              [
                -2.3131411665125,
                47.485904562585
              ],
              [
                -2.3128644981072,
                47.464470727328
              ],
              [
                -2.3239404651198,
                47.459700150766
              ],
              [
                -2.3463648513449,
                47.457849990711
              ],
              [
                -2.3540439813745,
                47.454520038759
              ],
              [
                -2.3709692377347,
                47.463384419512
              ],
              [
                -2.3822957470498,
                47.462247958261
              ],
              [
                -2.390389834657,
                47.456871614258
              ],
              [
                -2.399942719827,
                47.455985214433
              ],
              [
                -2.4168260809589,
                47.462051729438
              ],
              [
                -2.4230228667484,
                47.477116356867
              ],
              [
                -2.4400824549861,
                47.465780511452
              ],
              [
                -2.453436631799,
                47.46207522621
              ],
              [
                -2.4584933200854,
                47.44812333026
              ],
              [
                -2.4658187901467,
                47.449802093033
              ],
              [
                -2.4804252485529,
                47.441963079767
              ],
              [
                -2.4905571762189,
                47.446452306175
              ],
              [
                -2.4991358587812,
                47.457540137914
              ],
              [
                -2.4889918501409,
                47.468033929888
              ],
              [
                -2.4895613282298,
                47.474318089711
              ],
              [
                -2.5014022305882,
                47.490518823453
              ],
              [
                -2.4937203667699,
                47.496719184685
              ],
              [
                -2.4812257438732,
                47.495301802155
              ],
              [
                -2.4674495382969,
                47.482627274277
              ],
              [
                -2.4566319282751,
                47.48649918664
              ],
              [
                -2.4412197566934,
                47.496237972727
              ],
              [
                -2.4544840297362,
                47.50695431439
              ],
              [
                -2.4661501788359,
                47.511698006916
              ],
              [
                -2.5001329684216,
                47.51780353312
              ],
              [
                -2.5051621326726,
                47.523097091258
              ],
              [
                -2.5177342670181,
                47.526379124102
              ],
              [
                -2.5371281384206,
                47.525677358637
              ],
              [
                -2.544757222766,
                47.518191712712
              ],
              [
                -2.556172145179,
                47.512844753872
              ],
              [
                -2.5731695264161,
                47.516856993244
              ],
              [
                -2.594864559507,
                47.517692920359
              ],
              [
                -2.6058118321704,
                47.515243886611
              ],
              [
                -2.6094941252327,
                47.509770061639
              ],
              [
                -2.6219180968561,
                47.504642830448
              ],
              [
                -2.6238853198868,
                47.513043352663
              ],
              [
                -2.6115717799579,
                47.514953612529
              ],
              [
                -2.6022307930281,
                47.523493027492
              ],
              [
                -2.5867175567864,
                47.525970484889
              ],
              [
                -2.5735934948752,
                47.545947890549
              ],
              [
                -2.559927969571,
                47.551149229397
              ],
              [
                -2.5868524345501,
                47.563522718081
              ],
              [
                -2.5830102351203,
                47.544093495058
              ],
              [
                -2.5950997790228,
                47.533860071767
              ],
              [
                -2.6043518130413,
                47.531033499908
              ],
              [
                -2.6174572174627,
                47.544577945836
              ],
              [
                -2.6197613863302,
                47.553120080253
              ],
              [
                -2.6293614142026,
                47.549477103692
              ],
              [
                -2.620896300155,
                47.543139537592
              ],
              [
                -2.6260111378038,
                47.530694282534
              ],
              [
                -2.650778878004,
                47.53796758253
              ],
              [
                -2.6490453148391,
                47.52871403441
              ],
              [
                -2.6323971230563,
                47.529969402681
              ],
              [
                -2.6362963357436,
                47.518311441107
              ],
              [
                -2.6643333232928,
                47.518636731497
              ],
              [
                -2.6663632444305,
                47.509807344989
              ],
              [
                -2.6812726425683,
                47.495823503923
              ],
              [
                -2.7155101291894,
                47.505556661277
              ],
              [
                -2.7383720762509,
                47.503971748148
              ],
              [
                -2.7464594152624,
                47.499178906102
              ],
              [
                -2.7686807216999,
                47.497547306973
              ],
              [
                -2.783917445629,
                47.494171291195
              ],
              [
                -2.8002084412293,
                47.487274625086
              ],
              [
                -2.820249819976,
                47.488552319653
              ],
              [
                -2.8494633183276,
                47.498714210554
              ],
              [
                -2.8473220606719,
                47.511542665428
              ],
              [
                -2.8715617319757,
                47.532180194766
              ],
              [
                -2.8867515062552,
                47.537761181776
              ],
              [
                -2.9053147509137,
                47.53836615706
              ],
              [
                -2.9127507683233,
                47.542221258931
              ],
              [
                -2.9100056010075,
                47.552847850571
              ],
              [
                -2.8785251291933,
                47.563822706213
              ],
              [
                -2.875192617398,
                47.554282188137
              ],
              [
                -2.8584601857729,
                47.550660977545
              ],
              [
                -2.8388802388699,
                47.543739594278
              ],
              [
                -2.8209970086207,
                47.542598073556
              ],
              [
                -2.8136150628512,
                47.552976639704
              ],
              [
                -2.8053232688367,
                47.55465217436
              ],
              [
                -2.7983285187393,
                47.54772871596
              ],
              [
                -2.7763664183452,
                47.54637816599
              ],
              [
                -2.7800781208128,
                47.539995170204
              ],
              [
                -2.7656825016343,
                47.536349797594
              ],
              [
                -2.7305342531378,
                47.542486209183
              ],
              [
                -2.7342085766345,
                47.552059689
              ],
              [
                -2.7271201608591,
                47.565348713813
              ],
              [
                -2.718401891601,
                47.567486439417
              ],
              [
                -2.7138081735774,
                47.579402737983
              ],
              [
                -2.7019511829809,
                47.589922636913
              ],
              [
                -2.690000413435,
                47.589629645348
              ],
              [
                -2.6893684783836,
                47.605475178645
              ],
              [
                -2.6858183369351,
                47.617254810447
              ],
              [
                -2.6923951849212,
                47.626672194763
              ],
              [
                -2.7101869757185,
                47.639250695814
              ],
              [
                -2.7157627565643,
                47.632178418633
              ],
              [
                -2.7087829379002,
                47.628877096117
              ],
              [
                -2.7068583824059,
                47.619857356802
              ],
              [
                -2.6956189709006,
                47.610971810996
              ],
              [
                -2.7134014552926,
                47.594743232814
              ],
              [
                -2.7210591260306,
                47.603107177713
              ],
              [
                -2.7399219165437,
                47.603861056383
              ],
              [
                -2.7785759216065,
                47.619153949607
              ],
              [
                -2.77515576774,
                47.625162720347
              ],
              [
                -2.75071220553,
                47.62190984793
              ],
              [
                -2.7577479466431,
                47.632011754392
              ],
              [
                -2.7805769364965,
                47.626366080093
              ],
              [
                -2.783280446908,
                47.620929683472
              ],
              [
                -2.7980253950261,
                47.619354324535
              ],
              [
                -2.8119448383424,
                47.622321040814
              ],
              [
                -2.8312640065467,
                47.613981447085
              ],
              [
                -2.8511519612038,
                47.619021300579
              ],
              [
                -2.8683761763816,
                47.607049879913
              ],
              [
                -2.8655555799982,
                47.59730655576
              ],
              [
                -2.8851907493108,
                47.603861505908
              ],
              [
                -2.8912992832039,
                47.600251055152
              ],
              [
                -2.8902083733527,
                47.583759203505
              ],
              [
                -2.8954479129042,
                47.581494812737
              ],
              [
                -2.9055871359278,
                47.590412052268
              ],
              [
                -2.9147145838282,
                47.591080951176
              ],
              [
                -2.9320958563755,
                47.600389216902
              ],
              [
                -2.9391279484845,
                47.594030070059
              ],
              [
                -2.9468653824632,
                47.607515814492
              ],
              [
                -2.9492053452579,
                47.620300052943
              ],
              [
                -2.9472179938322,
                47.629097718661
              ],
              [
                -2.9588456568534,
                47.627387400625
              ],
              [
                -2.9578927965981,
                47.637888099776
              ],
              [
                -2.9766375186447,
                47.658576868252
              ],
              [
                -2.9821003804206,
                47.655171781052
              ],
              [
                -2.97168067816,
                47.64505595394
              ],
              [
                -2.9744526066848,
                47.639664006057
              ],
              [
                -2.9632708243662,
                47.631813523307
              ],
              [
                -2.9607067176234,
                47.61463764084
              ],
              [
                -2.9653352276132,
                47.601579987203
              ],
              [
                -2.9551759826158,
                47.598617982077
              ],
              [
                -2.9649822013031,
                47.588704907763
              ],
              [
                -2.9559516107688,
                47.579651698895
              ],
              [
                -2.9478608265496,
                47.57934478607
              ],
              [
                -2.9428107767728,
                47.568756823617
              ],
              [
                -2.9288355514896,
                47.55474379968
              ],
              [
                -2.9390911128082,
                47.554097415693
              ],
              [
                -2.9514873302492,
                47.558895907795
              ],
              [
                -2.9684287009143,
                47.557175629484
              ],
              [
                -2.9751788529976,
                47.576068937045
              ],
              [
                -2.9862715309049,
                47.577092614249
              ],
              [
                -3.0046872395881,
                47.566465469116
              ],
              [
                -3.0123216393668,
                47.580152522478
              ],
              [
                -3.0228793917846,
                47.578112102992
              ],
              [
                -3.0519734636492,
                47.577936411937
              ],
              [
                -3.0618814356091,
                47.572517204359
              ],
              [
                -3.0738615376547,
                47.571102118951
              ],
              [
                -3.0948867588573,
                47.564687759616
              ],
              [
                -3.1065771207183,
                47.574439681659
              ],
              [
                -3.1035813029521,
                47.583875926358
              ],
              [
                -3.1304124258813,
                47.595994049527
              ],
              [
                -3.1294822782995,
                47.574986035076
              ],
              [
                -3.1232563342713,
                47.569508296346
              ],
              [
                -3.1283264863235,
                47.561155649925
              ],
              [
                -3.1333592273144,
                47.544057720215
              ],
              [
                -3.1329801809192,
                47.532940856127
              ],
              [
                -3.1265682020102,
                47.515571637729
              ],
              [
                -3.1202689791131,
                47.510193523895
              ],
              [
                -3.1187883281895,
                47.498761676631
              ],
              [
                -3.1099177580575,
                47.490095631431
              ],
              [
                -3.0945017004357,
                47.48241159014
              ],
              [
                -3.0996239283661,
                47.472659280146
              ],
              [
                -3.1178050237851,
                47.478792204713
              ],
              [
                -3.1310768433146,
                47.473341925849
              ],
              [
                -3.1438097827755,
                47.483493521766
              ],
              [
                -3.1517804898255,
                47.50387573681
              ],
              [
                -3.1510830738294,
                47.513240426176
              ],
              [
                -3.1573094190216,
                47.527777147938
              ],
              [
                -3.1424420706013,
                47.533350052758
              ],
              [
                -3.1345818841427,
                47.548823597227
              ],
              [
                -3.1383935427929,
                47.559327887066
              ],
              [
                -3.1397514157784,
                47.579595849581
              ],
              [
                -3.1494412574588,
                47.596336414351
              ],
              [
                -3.1583739612277,
                47.606980561921
              ],
              [
                -3.1926562041934,
                47.621875108037
              ],
              [
                -3.2097600334006,
                47.640692378745
              ],
              [
                -3.2048348154766,
                47.652288439195
              ],
              [
                -3.2081335587952,
                47.663424134982
              ],
              [
                -3.1905086423658,
                47.682544307053
              ],
              [
                -3.1622880424494,
                47.680141384504
              ],
              [
                -3.1644539365533,
                47.694638292784
              ],
              [
                -3.1439930291641,
                47.706565141662
              ],
              [
                -3.1280609317144,
                47.705557196606
              ],
              [
                -3.1314171963644,
                47.71283707385
              ],
              [
                -3.1259673333102,
                47.723460801941
              ],
              [
                -3.1493565548036,
                47.721594210494
              ],
              [
                -3.1493499098343,
                47.737340126654
              ],
              [
                -3.161008055411,
                47.73537887244
              ],
              [
                -3.1600761495593,
                47.728976621526
              ],
              [
                -3.1746363055634,
                47.723148407528
              ],
              [
                -3.1687810020071,
                47.704127663223
              ],
              [
                -3.1946342296882,
                47.703535994129
              ],
              [
                -3.2010030764507,
                47.697371761748
              ],
              [
                -3.1903765047009,
                47.690890020276
              ],
              [
                -3.2065278804508,
                47.672945488888
              ],
              [
                -3.2189237367916,
                47.666402207114
              ],
              [
                -3.2109296431363,
                47.655062695853
              ],
              [
                -3.2149712295324,
                47.645222611468
              ],
              [
                -3.2432568168208,
                47.662982381585
              ],
              [
                -3.2708992101864,
                47.678872772241
              ],
              [
                -3.2878626003796,
                47.700955753699
              ],
              [
                -3.2955569268779,
                47.702972616608
              ],
              [
                -3.3111004799677,
                47.701542726074
              ],
              [
                -3.3175576788183,
                47.705105920031
              ],
              [
                -3.3442241173144,
                47.70835964689
              ],
              [
                -3.3540324221035,
                47.703281447243
              ],
              [
                -3.3639383928461,
                47.709062757079
              ],
              [
                -3.3427340330799,
                47.719388265728
              ],
              [
                -3.3490788842555,
                47.72931295062
              ],
              [
                -3.3361476227018,
                47.728997614093
              ],
              [
                -3.3215796590588,
                47.73525260819
              ],
              [
                -3.3135540007668,
                47.746463211784
              ],
              [
                -3.3057310772884,
                47.74833039532
              ],
              [
                -3.2978560945981,
                47.768548758802
              ],
              [
                -3.3167640619277,
                47.756496417246
              ],
              [
                -3.3238312586273,
                47.747889296245
              ],
              [
                -3.333042362653,
                47.743689149171
              ],
              [
                -3.3514545169925,
                47.744278722575
              ],
              [
                -3.355826445994,
                47.732409734807
              ],
              [
                -3.3671634315263,
                47.725361124789
              ],
              [
                -3.3675668898422,
                47.717947229619
              ],
              [
                -3.3871098313882,
                47.701882463104
              ],
              [
                -3.4304385578708,
                47.703212158669
              ],
              [
                -3.4527575847414,
                47.695339093038
              ],
              [
                -3.4638578995064,
                47.705832814713
              ],
              [
                -3.4794942914613,
                47.714386099732
              ],
              [
                -3.5002201860967,
                47.731474104191
              ],
              [
                -3.5051314690212,
                47.745702801045
              ],
              [
                -3.5209645860149,
                47.757667759682
              ],
              [
                -3.5300214665675,
                47.774211050848
              ],
              [
                -3.5299340818516,
                47.782467704588
              ],
              [
                -3.517378148747,
                47.805037826205
              ],
              [
                -3.5359136532779,
                47.816437273679
              ],
              [
                -3.5382920395566,
                47.836022619198
              ],
              [
                -3.5240076654732,
                47.844903373787
              ],
              [
                -3.5230056259837,
                47.849146782891
              ],
              [
                -3.5134134850293,
                47.834523896952
              ],
              [
                -3.488378728186,
                47.823693797324
              ],
              [
                -3.476393603685,
                47.834031263106
              ],
              [
                -3.4575097495258,
                47.837091361261
              ],
              [
                -3.448891523943,
                47.850730641023
              ],
              [
                -3.4491763130993,
                47.861897696527
              ],
              [
                -3.4233565217862,
                47.869037428106
              ],
              [
                -3.4034384481233,
                47.870110288463
              ],
              [
                -3.4022418579457,
                47.884370909157
              ],
              [
                -3.4042654612097,
                47.899284280429
              ],
              [
                -3.4094061517392,
                47.910167984279
              ],
              [
                -3.4066134343769,
                47.917158573639
              ],
              [
                -3.3927592435554,
                47.918966029049
              ],
              [
                -3.3880788564101,
                47.929294929579
              ],
              [
                -3.3993689255171,
                47.938118221587
              ],
              [
                -3.4006612737574,
                47.945673642404
              ],
              [
                -3.3926235122244,
                47.954453922013
              ],
              [
                -3.40309336092,
                47.966157747888
              ],
              [
                -3.4198881980685,
                47.969805904784
              ],
              [
                -3.4464490225429,
                47.958113761822
              ],
              [
                -3.4610225610804,
                47.956353860402
              ],
              [
                -3.4627053347206,
                47.948470411684
              ],
              [
                -3.4765536705495,
                47.948683440009
              ],
              [
                -3.4848075655298,
                47.966893667479
              ],
              [
                -3.4974433504273,
                47.976723266584
              ],
              [
                -3.5123375160856,
                47.974726350867
              ],
              [
                -3.5239545453258,
                47.978418090667
              ],
              [
                -3.5301798668878,
                47.986085977382
              ],
              [
                -3.5453723043591,
                47.986809935779
              ],
              [
                -3.5552770706373,
                47.993097499035
              ],
              [
                -3.5713437259039,
                47.990798397019
              ],
              [
                -3.5813599037456,
                47.986684519919
              ],
              [
                -3.597022456242,
                47.990376379813
              ],
              [
                -3.6260905858765,
                47.983395998762
              ],
              [
                -3.6402383243638,
                47.985243231924
              ],
              [
                -3.648298178547,
                48.00652115334
              ],
              [
                -3.6626106443191,
                48.020081882521
              ],
              [
                -3.6630037279929,
                48.032958681418
              ],
              [
                -3.6699585843591,
                48.038677972582
              ],
              [
                -3.6740316896394,
                48.052542330421
              ],
              [
                -3.681252565867,
                48.059662962291
              ],
              [
                -3.6981708601505,
                48.062309578332
              ],
              [
                -3.7045007865138,
                48.084033549136
              ],
              [
                -3.6950209143267,
                48.093496452723
              ],
              [
                -3.714330743355,
                48.09867748042
              ],
              [
                -3.7312089824467,
                48.096180843537
              ],
              [
                -3.7321436369252,
                48.10908636822
              ],
              [
                -3.7239460533949,
                48.124448552469
              ],
              [
                -3.7145405932136,
                48.13309642163
              ],
              [
                -3.7155085226945,
                48.142222040551
              ],
              [
                -3.6976881208724,
                48.145440974014
              ],
              [
                -3.6958369472591,
                48.152210034266
              ],
              [
                -3.674318492501,
                48.152007895332
              ],
              [
                -3.6492583398969,
                48.157979020909
              ],
              [
                -3.6283770645618,
                48.168722735788
              ],
              [
                -3.6139791801575,
                48.173120656414
              ],
              [
                -3.5887976018415,
                48.177551526513
              ],
              [
                -3.5648444491461,
                48.185747540872
              ],
              [
                -3.5515421718931,
                48.17976589476
              ],
              [
                -3.5331249944313,
                48.178231767076
              ],
              [
                -3.5054754633943,
                48.181145182471
              ],
              [
                -3.4795255925947,
                48.186041330675
              ],
              [
                -3.4719851811899,
                48.180424862726
              ],
              [
                -3.4520668252893,
                48.177658808383
              ],
              [
                -3.4436332619808,
                48.185786158542
              ],
              [
                -3.4234037592294,
                48.177134344133
              ],
              [
                -3.4214819897624,
                48.171909040473
              ],
              [
                -3.4384466261327,
                48.16255519616
              ],
              [
                -3.4350297559107,
                48.154353009549
              ],
              [
                -3.4170117151901,
                48.145892119985
              ],
              [
                -3.4019111259808,
                48.153969929301
              ],
              [
                -3.3850966478371,
                48.156512404714
              ],
              [
                -3.3753426707784,
                48.163459489968
              ],
              [
                -3.3497450066256,
                48.164770127615
              ],
              [
                -3.3367532869347,
                48.171124440685
              ],
              [
                -3.3193408410471,
                48.149607771
              ],
              [
                -3.3161719611326,
                48.154583814893
              ],
              [
                -3.2995942714721,
                48.154807439026
              ],
              [
                -3.2913303481108,
                48.15080892734
              ],
              [
                -3.2829217342699,
                48.141622679586
              ],
              [
                -3.2722431061433,
                48.140015733246
              ],
              [
                -3.2523367608786,
                48.149124466265
              ],
              [
                -3.2297322123493,
                48.149050739238
              ],
              [
                -3.2175870763432,
                48.144082867021
              ],
              [
                -3.2153410571891,
                48.153405446254
              ],
              [
                -3.1925192863564,
                48.164399923361
              ],
              [
                -3.1753690237937,
                48.158896538838
              ],
              [
                -3.1651406554986,
                48.16221680113
              ],
              [
                -3.1552524143595,
                48.158488322356
              ],
              [
                -3.139493598382,
                48.163687258685
              ],
              [
                -3.1302234691174,
                48.176873149256
              ],
              [
                -3.1368814843084,
                48.181926378652
              ],
              [
                -3.1322448440553,
                48.195534067802
              ],
              [
                -3.1209332769833,
                48.200289787202
              ],
              [
                -3.1163730959334,
                48.206360221126
              ],
              [
                -3.0985579022849,
                48.2061947437
              ],
              [
                -3.0937326769485,
                48.201912020928
              ],
              [
                -3.0780078103247,
                48.210884763611
              ],
              [
                -3.069447725511,
                48.205015781894
              ],
              [
                -3.0579899734626,
                48.206354123435
              ],
              [
                -3.0335432083231,
                48.199363200364
              ],
              [
                -3.0143391347997,
                48.197495633085
              ],
              [
                -3.0139545505673,
                48.187054490009
              ],
              [
                -3.0069243445854,
                48.170296016941
              ],
              [
                -2.9999873047788,
                48.161689403852
              ],
              [
                -2.9877221855435,
                48.15963332327
              ],
              [
                -2.9693795818166,
                48.16750185777
              ],
              [
                -2.9536595195283,
                48.170839290652
              ],
              [
                -2.9370595232974,
                48.170212391319
              ],
              [
                -2.9045601566093,
                48.163152428704
              ],
              [
                -2.8908718753323,
                48.164349300123
              ],
              [
                -2.8780282096689,
                48.150997682157
              ],
              [
                -2.8507975016535,
                48.141165856931
              ],
              [
                -2.8408288182409,
                48.141111211271
              ],
              [
                -2.8252940822223,
                48.145477166008
              ],
              [
                -2.810396084518,
                48.146550544942
              ],
              [
                -2.8053911962986,
                48.143570856466
              ],
              [
                -2.7819460662193,
                48.12058268646
              ],
              [
                -2.7836369454384,
                48.111001509075
              ],
              [
                -2.7776035826188,
                48.10048691545
              ],
              [
                -2.7776588825451,
                48.090357127763
              ],
              [
                -2.7670138623917,
                48.091204221723
              ],
              [
                -2.7593694385227,
                48.100796103972
              ],
              [
                -2.7538962611584,
                48.115834449394
              ],
              [
                -2.7038259010001,
                48.112884222981
              ],
              [
                -2.7023757860279,
                48.118803410715
              ],
              [
                -2.676232684165,
                48.13181757277
              ],
              [
                -2.6618611953763,
                48.128602238855
              ],
              [
                -2.6522994742925,
                48.119322933141
              ],
              [
                -2.6523290127164,
                48.095895168979
              ],
              [
                -2.6624064534727,
                48.081274437631
              ],
              [
                -2.6692623792498,
                48.065294794417
              ],
              [
                -2.6700531442166,
                48.047879335975
              ],
              [
                -2.6484827700487,
                48.039339123126
              ],
              [
                -2.6311896634213,
                48.035478415172
              ],
              [
                -2.5948741089285,
                48.052962379246
              ],
              [
                -2.5747803200149,
                48.070407599515
              ],
              [
                -2.5649680294934,
                48.067326967771
              ],
              [
                -2.5611588903258,
                48.074063647354
              ],
              [
                -2.5484314818503,
                48.076712424662
              ],
              [
                -2.5492973883428,
                48.099064974945
              ],
              [
                -2.5319461348412,
                48.110036370183
              ],
              [
                -2.5139753471597,
                48.132424705969
              ],
              [
                -2.5169738877375,
                48.141579701609
              ],
              [
                -2.5248053966861,
                48.145400762655
              ],
              [
                -2.5336615546786,
                48.156858555152
              ],
              [
                -2.5133396415619,
                48.159177796026
              ],
              [
                -2.4948181957429,
                48.157841194948
              ],
              [
                -2.4871953847228,
                48.16690697117
              ],
              [
                -2.472825263631,
                48.162181355373
              ],
              [
                -2.455771521292,
                48.165148597924
              ],
              [
                -2.4526021810189,
                48.170590695779
              ],
              [
                -2.4263820118443,
                48.17380491393
              ],
              [
                -2.4089089901639,
                48.168612039603
              ],
              [
                -2.3972291582243,
                48.153319669138
              ],
              [
                -2.3773815505463,
                48.141417281638
              ],
              [
                -2.371360456597,
                48.122100498618
              ],
              [
                -2.3578004145279,
                48.111304497547
              ],
              [
                -2.3517675890653,
                48.125103070936
              ],
              [
                -2.3447460701781,
                48.127094991305
              ],
              [
                -2.3359227288787,
                48.120916671418
              ],
              [
                -2.3210668793159,
                48.123308819376
              ],
              [
                -2.3211677406633,
                48.132808302216
              ],
              [
                -2.3077251019044,
                48.13676439212
              ],
              [
                -2.2872742268268,
                48.133743948459
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "56",
        "nom": "Morbihan",
        "population": 764161,
        "delitspour1000": "4,278",
        "nombremosque": 13,
        "parentsEtrangers": "84,8",
        "benefRsa": 23137,
        "eoliennes": 133
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                2.9997373566796,
                50.129567254562
              ],
              [
                2.9981750084232,
                50.142325834593
              ],
              [
                3.0038797607882,
                50.150247376424
              ],
              [
                3.0307228794651,
                50.151323930137
              ],
              [
                3.0403136602665,
                50.153319276217
              ],
              [
                3.0402613840731,
                50.159656660224
              ],
              [
                3.0496571002838,
                50.166029152388
              ],
              [
                3.0601901808976,
                50.16739301914
              ],
              [
                3.0610482264708,
                50.174049535934
              ],
              [
                3.0924970770113,
                50.170475585351
              ],
              [
                3.0956630380692,
                50.162593087522
              ],
              [
                3.0736181108485,
                50.148650234735
              ],
              [
                3.068139305836,
                50.147348548695
              ],
              [
                3.072792245694,
                50.131181945966
              ],
              [
                3.0576280219398,
                50.132169774021
              ],
              [
                3.0370332545299,
                50.130314244079
              ],
              [
                3.0272922164531,
                50.11854767383
              ],
              [
                3.0106307501246,
                50.112806167451
              ],
              [
                2.9993197857067,
                50.118846169655
              ],
              [
                2.9997373566796,
                50.129567254562
              ]
            ]
          ],
          [
            [
              [
                2.0677049871716,
                51.006501514321
              ],
              [
                2.0845036256491,
                51.008606935608
              ],
              [
                2.1097045642696,
                51.003827598587
              ],
              [
                2.123515723481,
                51.014167524083
              ],
              [
                2.141504907246,
                51.022058874598
              ],
              [
                2.1673459399511,
                51.020738207739
              ],
              [
                2.1794228227752,
                51.015102619912
              ],
              [
                2.1925606242518,
                51.027436640575
              ],
              [
                2.1918294890846,
                51.034714947113
              ],
              [
                2.2140013975448,
                51.031681877805
              ],
              [
                2.2590224218691,
                51.043489703657
              ],
              [
                2.33185815699,
                51.056055745767
              ],
              [
                2.3478325752445,
                51.059829634673
              ],
              [
                2.3666467120992,
                51.051919789329
              ],
              [
                2.3782653998463,
                51.049621895931
              ],
              [
                2.3995855035021,
                51.050957305507
              ],
              [
                2.4247543344414,
                51.055621633327
              ],
              [
                2.493466611121,
                51.073071375079
              ],
              [
                2.5430346061346,
                51.08854370897
              ],
              [
                2.5463210173049,
                51.088403186427
              ],
              [
                2.5599258259302,
                51.068918893476
              ],
              [
                2.5759795456357,
                51.013756325485
              ],
              [
                2.5739982943322,
                51.003521991804
              ],
              [
                2.5828043639126,
                50.997122436344
              ],
              [
                2.6012847777962,
                50.991283503211
              ],
              [
                2.6105921963125,
                50.976188347149
              ],
              [
                2.6326801578862,
                50.946008766454
              ],
              [
                2.618276709243,
                50.938761685952
              ],
              [
                2.5902216021403,
                50.919275360805
              ],
              [
                2.5940974354529,
                50.914372170977
              ],
              [
                2.6075324528985,
                50.91240659974
              ],
              [
                2.606318820361,
                50.899326241848
              ],
              [
                2.6071625262549,
                50.873044043565
              ],
              [
                2.6103262931744,
                50.86206244973
              ],
              [
                2.5995904644573,
                50.853332534722
              ],
              [
                2.62561536399,
                50.836560386715
              ],
              [
                2.6272629250997,
                50.82765105936
              ],
              [
                2.6349756592043,
                50.812755500853
              ],
              [
                2.6588971217623,
                50.814991778582
              ],
              [
                2.6690258101157,
                50.822462129386
              ],
              [
                2.6862350086644,
                50.813429813967
              ],
              [
                2.7174754083298,
                50.81360682515
              ],
              [
                2.7256398356216,
                50.808632188358
              ],
              [
                2.7219265518167,
                50.80159438836
              ],
              [
                2.7267028013485,
                50.792383411441
              ],
              [
                2.7375118592303,
                50.78266728319
              ],
              [
                2.7621515823535,
                50.770756585159
              ],
              [
                2.7584298773759,
                50.762930766426
              ],
              [
                2.766042090907,
                50.754519108704
              ],
              [
                2.7819457247827,
                50.75109080132
              ],
              [
                2.7867390132952,
                50.73381741121
              ],
              [
                2.7956557413456,
                50.724609008419
              ],
              [
                2.8132739834573,
                50.716945833429
              ],
              [
                2.8483860974407,
                50.722914959975
              ],
              [
                2.8547299617705,
                50.714708513206
              ],
              [
                2.8701715745294,
                50.702911374608
              ],
              [
                2.8856496977648,
                50.706611021367
              ],
              [
                2.8983786417092,
                50.694238803855
              ],
              [
                2.9109065606969,
                50.694496081053
              ],
              [
                2.9115936515323,
                50.703838778555
              ],
              [
                2.9223300662476,
                50.702801451402
              ],
              [
                2.9307796391097,
                50.716242999992
              ],
              [
                2.9298687861194,
                50.722758772582
              ],
              [
                2.94083706877,
                50.733174957645
              ],
              [
                2.9380690677647,
                50.744343927761
              ],
              [
                2.9582403353503,
                50.753446186612
              ],
              [
                2.9696585316524,
                50.749612772394
              ],
              [
                2.9810878439876,
                50.756657678787
              ],
              [
                3.0120929242906,
                50.769111505719
              ],
              [
                3.0352750082458,
                50.770239418325
              ],
              [
                3.0408824825472,
                50.775687536871
              ],
              [
                3.06137703349,
                50.78050174847
              ],
              [
                3.0798956714209,
                50.773020869115
              ],
              [
                3.0886989124978,
                50.773371114027
              ],
              [
                3.1067964329954,
                50.783860810329
              ],
              [
                3.1147669732972,
                50.794187883435
              ],
              [
                3.1249879535977,
                50.786437758612
              ],
              [
                3.150782780111,
                50.790127285347
              ],
              [
                3.1520005085792,
                50.782343293233
              ],
              [
                3.1927531897582,
                50.738373224845
              ],
              [
                3.199134285231,
                50.734731836468
              ],
              [
                3.1912265002692,
                50.724409127208
              ],
              [
                3.2040720746712,
                50.719906646456
              ],
              [
                3.2155072743961,
                50.712360639713
              ],
              [
                3.241487162382,
                50.712571336251
              ],
              [
                3.2583766477988,
                50.700646817566
              ],
              [
                3.2601205467889,
                50.693081234565
              ],
              [
                3.2548022328174,
                50.686810753613
              ],
              [
                3.2624274548591,
                50.678483478988
              ],
              [
                3.2445871588055,
                50.670121319421
              ],
              [
                3.2411021613015,
                50.657785417847
              ],
              [
                3.2485035849353,
                50.638041219835
              ],
              [
                3.2590208715028,
                50.630050536375
              ],
              [
                3.2612681066709,
                50.618847521451
              ],
              [
                3.2702967597615,
                50.610812998689
              ],
              [
                3.2775041513186,
                50.592959787705
              ],
              [
                3.2761648131069,
                50.581684874378
              ],
              [
                3.2817196163381,
                50.577326666545
              ],
              [
                3.2759630544617,
                50.55853838771
              ],
              [
                3.2801733532291,
                50.541649764418
              ],
              [
                3.286524369777,
                50.527577616965
              ],
              [
                3.3076292824013,
                50.519351863345
              ],
              [
                3.323616112777,
                50.515711801855
              ],
              [
                3.3288583317067,
                50.50812409632
              ],
              [
                3.3373216704386,
                50.508504351312
              ],
              [
                3.3626639420755,
                50.503101853968
              ],
              [
                3.3738958439101,
                50.491827615781
              ],
              [
                3.3892974635465,
                50.496497126168
              ],
              [
                3.407610572795,
                50.499030496362
              ],
              [
                3.4322501535667,
                50.507122357013
              ],
              [
                3.4500814758008,
                50.507357727388
              ],
              [
                3.4537383652366,
                50.519240260247
              ],
              [
                3.4739635797806,
                50.533563546234
              ],
              [
                3.4879746720951,
                50.52951223764
              ],
              [
                3.5037708157723,
                50.529588132251
              ],
              [
                3.5194053990883,
                50.522871548274
              ],
              [
                3.5150527088294,
                50.511939927188
              ],
              [
                3.4963266702785,
                50.498685062833
              ],
              [
                3.5007741945714,
                50.487265476296
              ],
              [
                3.5260686365645,
                50.494642317404
              ],
              [
                3.5677678220335,
                50.50061221444
              ],
              [
                3.5853427353378,
                50.490537930282
              ],
              [
                3.599870886857,
                50.493844386319
              ],
              [
                3.6130169489409,
                50.492471225596
              ],
              [
                3.6321845132975,
                50.476505259546
              ],
              [
                3.6436495207902,
                50.463210909782
              ],
              [
                3.6559966661463,
                50.461231295862
              ],
              [
                3.6642184659463,
                50.453173761167
              ],
              [
                3.6601071896526,
                50.444370502961
              ],
              [
                3.668845812022,
                50.436854602212
              ],
              [
                3.6737092904608,
                50.404244897722
              ],
              [
                3.6723742999474,
                50.387652477303
              ],
              [
                3.6582667947641,
                50.371343895257
              ],
              [
                3.6672786748469,
                50.360267800167
              ],
              [
                3.6673721160869,
                50.350556515727
              ],
              [
                3.6738427984215,
                50.342705763301
              ],
              [
                3.673675944671,
                50.334926016155
              ],
              [
                3.6936219005906,
                50.322150381532
              ],
              [
                3.6944326025128,
                50.31582844934
              ],
              [
                3.7104309751894,
                50.303169731959
              ],
              [
                3.715608900178,
                50.312633964487
              ],
              [
                3.7317343457441,
                50.311904528305
              ],
              [
                3.7300326908857,
                50.323304085674
              ],
              [
                3.7362234073766,
                50.343257031302
              ],
              [
                3.7474052053778,
                50.350928242159
              ],
              [
                3.7637003867935,
                50.348299279201
              ],
              [
                3.7681168487951,
                50.352409081215
              ],
              [
                3.7987851177737,
                50.351332715036
              ],
              [
                3.8136651517092,
                50.353164952183
              ],
              [
                3.8213860471348,
                50.34573984572
              ],
              [
                3.8297546113897,
                50.352692404719
              ],
              [
                3.8527555972491,
                50.351593778606
              ],
              [
                3.859478039848,
                50.342899076611
              ],
              [
                3.8722862224421,
                50.337941906562
              ],
              [
                3.8884496083778,
                50.338223421367
              ],
              [
                3.8897942621496,
                50.329975796406
              ],
              [
                3.900403191897,
                50.327566131768
              ],
              [
                3.9144701316434,
                50.330369968091
              ],
              [
                3.9678985736842,
                50.350369100061
              ],
              [
                3.9844697338671,
                50.341952511071
              ],
              [
                3.9939041267958,
                50.348610880809
              ],
              [
                4.0151639433994,
                50.351813784602
              ],
              [
                4.0273772077583,
                50.357488651634
              ],
              [
                4.0376171727107,
                50.342950902805
              ],
              [
                4.0488085710308,
                50.339599664463
              ],
              [
                4.0781900466276,
                50.320551562698
              ],
              [
                4.0788865919897,
                50.309752500483
              ],
              [
                4.0971684837089,
                50.313208013214
              ],
              [
                4.1129089634483,
                50.302069970791
              ],
              [
                4.1199595939074,
                50.300055788038
              ],
              [
                4.1259410717892,
                50.286248262682
              ],
              [
                4.1243582297602,
                50.27361214025
              ],
              [
                4.1366519411801,
                50.274295837487
              ],
              [
                4.1362790115355,
                50.256814562298
              ],
              [
                4.156126246964,
                50.256602008528
              ],
              [
                4.1676077441772,
                50.259698659168
              ],
              [
                4.168471823828,
                50.266121912654
              ],
              [
                4.1511739326664,
                50.278121082001
              ],
              [
                4.1626744412235,
                50.288919839989
              ],
              [
                4.1766755914931,
                50.284428214863
              ],
              [
                4.1812834659039,
                50.274457878757
              ],
              [
                4.2032487281264,
                50.274520097558
              ],
              [
                4.2218515438762,
                50.256954384753
              ],
              [
                4.220693486293,
                50.252205490042
              ],
              [
                4.2042939009819,
                50.240013765122
              ],
              [
                4.183902128366,
                50.232827043151
              ],
              [
                4.1719488669029,
                50.218984966036
              ],
              [
                4.1507645008475,
                50.213105993648
              ],
              [
                4.1601187752999,
                50.202535082465
              ],
              [
                4.1539072088435,
                50.181618869284
              ],
              [
                4.1493941237622,
                50.174782630132
              ],
              [
                4.155121374967,
                50.166769949356
              ],
              [
                4.1482848043049,
                50.158154635639
              ],
              [
                4.1377525142419,
                50.151611885511
              ],
              [
                4.1269212643952,
                50.1350091215
              ],
              [
                4.1437071184687,
                50.129042962388
              ],
              [
                4.1524471048199,
                50.128949591969
              ],
              [
                4.1575165386038,
                50.13552668129
              ],
              [
                4.1798368402129,
                50.133557548619
              ],
              [
                4.1916907010978,
                50.134590962587
              ],
              [
                4.1998869422006,
                50.131081113334
              ],
              [
                4.2031708176335,
                50.114887331607
              ],
              [
                4.1975019481259,
                50.109173730897
              ],
              [
                4.2014079092014,
                50.101540479066
              ],
              [
                4.2164449121556,
                50.091114252843
              ],
              [
                4.2275402024598,
                50.079679049395
              ],
              [
                4.2279959931456,
                50.066368977978
              ],
              [
                4.1910964802776,
                50.049491958302
              ],
              [
                4.1774115449025,
                50.045996982435
              ],
              [
                4.1622238052451,
                50.04898215278
              ],
              [
                4.1363736079231,
                50.02046124405
              ],
              [
                4.1354830598469,
                50.015042091472
              ],
              [
                4.1456765100433,
                50.003909005549
              ],
              [
                4.1617434156239,
                50.000912769341
              ],
              [
                4.1577077947817,
                49.988248872528
              ],
              [
                4.1408937784816,
                49.978756019427
              ],
              [
                4.1179287973935,
                49.974919108478
              ],
              [
                4.1058130261731,
                49.970940857631
              ],
              [
                4.0871312705912,
                49.969186662527
              ],
              [
                4.0799276782823,
                49.979949416328
              ],
              [
                4.056692226036,
                49.985425206851
              ],
              [
                4.0341643738028,
                49.985781916263
              ],
              [
                4.0311215546918,
                49.982065248058
              ],
              [
                4.0130394041535,
                49.985655767091
              ],
              [
                4.0053732887086,
                49.996060963365
              ],
              [
                3.9803763291718,
                49.995376985087
              ],
              [
                3.9826510699201,
                50.007823221699
              ],
              [
                3.9947358775819,
                50.025531899045
              ],
              [
                3.9895513362491,
                50.041342464177
              ],
              [
                3.9823165297208,
                50.044427671748
              ],
              [
                3.9720781548097,
                50.036886901329
              ],
              [
                3.9476783843585,
                50.029332476806
              ],
              [
                3.9235013844547,
                50.026426186034
              ],
              [
                3.8872950947152,
                50.009688102532
              ],
              [
                3.8841725748413,
                50.031969058907
              ],
              [
                3.875028793388,
                50.036565310409
              ],
              [
                3.8472336977049,
                50.040835753367
              ],
              [
                3.8355345612274,
                50.04827145407
              ],
              [
                3.817954914493,
                50.039777091767
              ],
              [
                3.7975218734852,
                50.048397031901
              ],
              [
                3.7779259836508,
                50.04598461089
              ],
              [
                3.7748221566437,
                50.049621297844
              ],
              [
                3.746377151928,
                50.05360784438
              ],
              [
                3.715179065814,
                50.069271974234
              ],
              [
                3.7097065568657,
                50.066002995981
              ],
              [
                3.6657534268377,
                50.054728589348
              ],
              [
                3.6581930744086,
                50.049288710212
              ],
              [
                3.6623149163568,
                50.039113396425
              ],
              [
                3.6559364272446,
                50.032766895838
              ],
              [
                3.6435742036855,
                50.037676397171
              ],
              [
                3.634228731506,
                50.035845179765
              ],
              [
                3.6136426641355,
                50.025414972618
              ],
              [
                3.6064945304366,
                50.034889729152
              ],
              [
                3.5919692496516,
                50.044080924344
              ],
              [
                3.5615293457173,
                50.053934264068
              ],
              [
                3.5448478994272,
                50.053794847201
              ],
              [
                3.530720808825,
                50.044704432129
              ],
              [
                3.5286124821151,
                50.038180178229
              ],
              [
                3.5071388293519,
                50.035778426858
              ],
              [
                3.491357082465,
                50.018859654652
              ],
              [
                3.447055807483,
                50.022439728958
              ],
              [
                3.4135732928197,
                50.027585361882
              ],
              [
                3.39890478116,
                50.034755671377
              ],
              [
                3.3915828503565,
                50.031901508452
              ],
              [
                3.3748529135519,
                50.03640787562
              ],
              [
                3.3529876206925,
                50.035860226771
              ],
              [
                3.3371076812771,
                50.017403207904
              ],
              [
                3.3293480229819,
                50.015985397797
              ],
              [
                3.3105782894546,
                50.019566104726
              ],
              [
                3.2870975810209,
                50.014184796787
              ],
              [
                3.2801699122715,
                50.015181662188
              ],
              [
                3.2707157754254,
                50.029466123914
              ],
              [
                3.2581174425539,
                50.031770179151
              ],
              [
                3.2579143291896,
                50.024436966985
              ],
              [
                3.230541907493,
                50.031311385584
              ],
              [
                3.2174797791605,
                50.023182479597
              ],
              [
                3.1821975358417,
                50.012335988197
              ],
              [
                3.172704445659,
                50.011996744997
              ],
              [
                3.1669872524317,
                50.020986574845
              ],
              [
                3.1473452809305,
                50.018694443245
              ],
              [
                3.1499355040833,
                50.027268946371
              ],
              [
                3.1330669935014,
                50.024184312563
              ],
              [
                3.1175554073514,
                50.027668937858
              ],
              [
                3.1143107112887,
                50.038705130854
              ],
              [
                3.0943607194207,
                50.046412147816
              ],
              [
                3.0902522256658,
                50.053740556167
              ],
              [
                3.0837597873036,
                50.057784115124
              ],
              [
                3.0947639429408,
                50.08315717515
              ],
              [
                3.103357990306,
                50.078515963926
              ],
              [
                3.1141266539474,
                50.092155896514
              ],
              [
                3.1122784696822,
                50.103585864572
              ],
              [
                3.1046050204131,
                50.104630832207
              ],
              [
                3.1048504324589,
                50.115284559105
              ],
              [
                3.0957569190573,
                50.124200836276
              ],
              [
                3.1167908296377,
                50.131878113225
              ],
              [
                3.1344677195638,
                50.143226643999
              ],
              [
                3.1171760626407,
                50.146848185602
              ],
              [
                3.1021859277944,
                50.163315233096
              ],
              [
                3.1160318036349,
                50.167760693437
              ],
              [
                3.1289515731297,
                50.165559358488
              ],
              [
                3.1459010655896,
                50.178593862371
              ],
              [
                3.1561306993598,
                50.183717847228
              ],
              [
                3.1439103476899,
                50.189642434468
              ],
              [
                3.1431797234806,
                50.196397021207
              ],
              [
                3.1313977241108,
                50.19940043586
              ],
              [
                3.1423441660024,
                50.217665434614
              ],
              [
                3.1788781200699,
                50.219071541378
              ],
              [
                3.1883563131291,
                50.228672622554
              ],
              [
                3.1733764823825,
                50.241107671262
              ],
              [
                3.1509313322274,
                50.244917489418
              ],
              [
                3.1473350950287,
                50.260157614658
              ],
              [
                3.1336836466157,
                50.266233105789
              ],
              [
                3.1079119709181,
                50.268425891288
              ],
              [
                3.0805007705843,
                50.273504657872
              ],
              [
                3.0635760360422,
                50.27199084082
              ],
              [
                3.0515016064575,
                50.27664751931
              ],
              [
                3.0361767491925,
                50.26932263362
              ],
              [
                3.0185656735087,
                50.264396215861
              ],
              [
                3.0121734874327,
                50.268066705162
              ],
              [
                3.0178023561002,
                50.284584345639
              ],
              [
                3.0346104900049,
                50.28767572777
              ],
              [
                3.051092647048,
                50.282358192083
              ],
              [
                3.0555494146184,
                50.289439559934
              ],
              [
                3.0477515546427,
                50.293712166205
              ],
              [
                3.0657667023368,
                50.30506171807
              ],
              [
                3.0843056557162,
                50.311188090006
              ],
              [
                3.060360059225,
                50.328916284683
              ],
              [
                3.0624992429842,
                50.331425511861
              ],
              [
                3.0466910035393,
                50.345812651487
              ],
              [
                3.0387289093833,
                50.344018915107
              ],
              [
                3.0134557467176,
                50.359406970324
              ],
              [
                2.9930742529593,
                50.379842467514
              ],
              [
                2.9948606981276,
                50.387693367205
              ],
              [
                2.9823221459456,
                50.401497580279
              ],
              [
                3.0040858569527,
                50.401221379948
              ],
              [
                3.0277906377309,
                50.408997082838
              ],
              [
                3.0311802013236,
                50.41556573757
              ],
              [
                3.0516833830057,
                50.425798956162
              ],
              [
                3.0685193795963,
                50.425646240885
              ],
              [
                3.0763846740602,
                50.440611459145
              ],
              [
                3.067602845566,
                50.446378740401
              ],
              [
                3.0671591865506,
                50.458422919396
              ],
              [
                3.054363830499,
                50.45508812622
              ],
              [
                3.0376708990112,
                50.441638303122
              ],
              [
                3.0163412126251,
                50.442095390207
              ],
              [
                3.0172647358513,
                50.450251494975
              ],
              [
                3.0067353137984,
                50.457303639032
              ],
              [
                3.0163918982129,
                50.468288163807
              ],
              [
                3.0207995748428,
                50.481765465725
              ],
              [
                3.0083469740982,
                50.492761850628
              ],
              [
                2.9952182848207,
                50.496520325135
              ],
              [
                2.9647153709588,
                50.512774432069
              ],
              [
                2.9500479192408,
                50.512310718123
              ],
              [
                2.9263708206209,
                50.507715815336
              ],
              [
                2.9104248018046,
                50.499877363101
              ],
              [
                2.8989518656364,
                50.50155422527
              ],
              [
                2.8807796671222,
                50.514806261782
              ],
              [
                2.8856728826002,
                50.523504811946
              ],
              [
                2.88696882249,
                50.537656078324
              ],
              [
                2.8626192429586,
                50.530038123241
              ],
              [
                2.8259782428075,
                50.525388445491
              ],
              [
                2.8123436718256,
                50.528965098217
              ],
              [
                2.8001884917574,
                50.527606020054
              ],
              [
                2.8003840748793,
                50.541133459245
              ],
              [
                2.7944735844558,
                50.549202608036
              ],
              [
                2.8134410941335,
                50.549346599603
              ],
              [
                2.8156564700094,
                50.566015235103
              ],
              [
                2.8081602790831,
                50.579168536917
              ],
              [
                2.789077205407,
                50.583061414779
              ],
              [
                2.8051589952281,
                50.603274806983
              ],
              [
                2.8315295482256,
                50.620198187701
              ],
              [
                2.8424345578212,
                50.629053197628
              ],
              [
                2.8554088044265,
                50.626344370403
              ],
              [
                2.8697307651834,
                50.631091501486
              ],
              [
                2.8404536962986,
                50.656563664654
              ],
              [
                2.8176900288707,
                50.663542784253
              ],
              [
                2.8091936432834,
                50.66937021299
              ],
              [
                2.7944016461523,
                50.662281487826
              ],
              [
                2.7843303812521,
                50.665336178523
              ],
              [
                2.7690451498338,
                50.663677249792
              ],
              [
                2.7606231977666,
                50.65566909504
              ],
              [
                2.7660140488084,
                50.649084330586
              ],
              [
                2.792453026888,
                50.633660709096
              ],
              [
                2.7836385564225,
                50.628974520703
              ],
              [
                2.7662368278023,
                50.633978473132
              ],
              [
                2.7525470598349,
                50.627871821503
              ],
              [
                2.7574019374071,
                50.618239253538
              ],
              [
                2.7389944409639,
                50.609312586306
              ],
              [
                2.7256457425012,
                50.614117517935
              ],
              [
                2.7138477071241,
                50.631287759925
              ],
              [
                2.6951714253322,
                50.628777147169
              ],
              [
                2.6796086872197,
                50.635881548523
              ],
              [
                2.6713252680731,
                50.624897679981
              ],
              [
                2.6432637984507,
                50.622538891146
              ],
              [
                2.6305174773984,
                50.617950612122
              ],
              [
                2.6187020079043,
                50.625387070701
              ],
              [
                2.6146029991412,
                50.63483165872
              ],
              [
                2.6014509843383,
                50.630982301962
              ],
              [
                2.5866136171855,
                50.63809533857
              ],
              [
                2.5771123247595,
                50.630515881201
              ],
              [
                2.5675793518958,
                50.63350383388
              ],
              [
                2.5646474035872,
                50.624520915665
              ],
              [
                2.5446909385658,
                50.627052828456
              ],
              [
                2.5370464089913,
                50.630452808867
              ],
              [
                2.5336259405944,
                50.643088503328
              ],
              [
                2.5178793853475,
                50.636196188234
              ],
              [
                2.4959475460851,
                50.631241346564
              ],
              [
                2.4731826335051,
                50.638594729172
              ],
              [
                2.450393683766,
                50.653848317696
              ],
              [
                2.4289823077906,
                50.657014753058
              ],
              [
                2.4216447837905,
                50.665648032069
              ],
              [
                2.4077967160894,
                50.672761136245
              ],
              [
                2.403207137341,
                50.681661074344
              ],
              [
                2.3831088136149,
                50.671830667341
              ],
              [
                2.3734967590549,
                50.672686259803
              ],
              [
                2.3635246352107,
                50.684644535221
              ],
              [
                2.3835589594468,
                50.700594961815
              ],
              [
                2.3646807333256,
                50.709688003623
              ],
              [
                2.3532149006029,
                50.718576368559
              ],
              [
                2.3510123214671,
                50.72493600472
              ],
              [
                2.3417443842668,
                50.728371653952
              ],
              [
                2.340896635375,
                50.740909944404
              ],
              [
                2.3637001788546,
                50.751426607185
              ],
              [
                2.3757116439919,
                50.752657506667
              ],
              [
                2.4114789545178,
                50.766734253763
              ],
              [
                2.39943967738,
                50.774785379947
              ],
              [
                2.3815803265761,
                50.778425196679
              ],
              [
                2.3759825201851,
                50.784049988105
              ],
              [
                2.3640699861255,
                50.780008610544
              ],
              [
                2.365339332959,
                50.774994005899
              ],
              [
                2.3360059042222,
                50.781401849549
              ],
              [
                2.3143201736514,
                50.782709198866
              ],
              [
                2.3122515475191,
                50.787201126855
              ],
              [
                2.2885798648188,
                50.777033183243
              ],
              [
                2.2879341077768,
                50.787014241693
              ],
              [
                2.2685135062806,
                50.7836778486
              ],
              [
                2.2524934267759,
                50.788116598458
              ],
              [
                2.2291945320425,
                50.806876941585
              ],
              [
                2.2134332677342,
                50.810547736996
              ],
              [
                2.2081216691324,
                50.83808638546
              ],
              [
                2.202416332208,
                50.843383830402
              ],
              [
                2.183908800499,
                50.88345339411
              ],
              [
                2.1705678194396,
                50.901136583485
              ],
              [
                2.1643359527502,
                50.91568908854
              ],
              [
                2.1514006960928,
                50.930635192021
              ],
              [
                2.149731162093,
                50.940557944834
              ],
              [
                2.1378094970506,
                50.951370068775
              ],
              [
                2.1353308750233,
                50.9642594986
              ],
              [
                2.1257142237163,
                50.968466052869
              ],
              [
                2.12132145655,
                50.979602196075
              ],
              [
                2.0975976295242,
                50.998430767335
              ],
              [
                2.0802392516851,
                51.006169093773
              ],
              [
                2.0677049871716,
                51.006501514321
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "59",
        "nom": "Nord",
        "population": 2607746,
        "delitspour1000": "7,137",
        "nombremosque": 111,
        "parentsEtrangers": "76,4",
        "benefRsa": 211499,
        "eoliennes": 90
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              5.884723650121,
              47.92604631497
            ],
            [
              5.8967125895793,
              47.93202806731
            ],
            [
              5.892904334004,
              47.937140708276
            ],
            [
              5.9001165275304,
              47.9447526196
            ],
            [
              5.9180463526732,
              47.94735518647
            ],
            [
              5.9217138598918,
              47.970783700094
            ],
            [
              5.9367445426717,
              47.978874239655
            ],
            [
              5.9475278158692,
              47.979712022025
            ],
            [
              5.9595125543651,
              47.965838580885
            ],
            [
              5.9377200582568,
              47.950937203984
            ],
            [
              5.9296518654303,
              47.938718128621
            ],
            [
              5.953718196208,
              47.937030199568
            ],
            [
              5.9689005966348,
              47.947407535025
            ],
            [
              5.9707820414319,
              47.957206856313
            ],
            [
              5.9965110271696,
              47.957642319673
            ],
            [
              6.0022660129604,
              47.956088697125
            ],
            [
              6.0099778425991,
              47.968676110152
            ],
            [
              6.0235129876577,
              47.978198192556
            ],
            [
              6.01816748796,
              47.984218151119
            ],
            [
              6.0269197055122,
              47.990054024535
            ],
            [
              6.0411765157853,
              48.00442320835
            ],
            [
              6.0725586040075,
              48.015669051022
            ],
            [
              6.0809347681235,
              48.012700464881
            ],
            [
              6.0977392042699,
              48.01504283565
            ],
            [
              6.1092294655483,
              48.012467456506
            ],
            [
              6.1165344664207,
              48.019389790184
            ],
            [
              6.1317082771503,
              48.023714993889
            ],
            [
              6.1560578216767,
              48.006943160047
            ],
            [
              6.1523407152561,
              47.994505212317
            ],
            [
              6.164889049648,
              47.976133645938
            ],
            [
              6.1509430707726,
              47.970556896575
            ],
            [
              6.160691544552,
              47.964476018183
            ],
            [
              6.1679889957893,
              47.952352981729
            ],
            [
              6.1784443979518,
              47.954215961253
            ],
            [
              6.1973668542118,
              47.951792167635
            ],
            [
              6.2079614134083,
              47.94275319831
            ],
            [
              6.2052242815746,
              47.932044890248
            ],
            [
              6.2191727089294,
              47.93576331706
            ],
            [
              6.2379829343014,
              47.932840414292
            ],
            [
              6.2773320945343,
              47.953805619678
            ],
            [
              6.2964155087065,
              47.955366770009
            ],
            [
              6.3097604456805,
              47.949874526566
            ],
            [
              6.3245064287941,
              47.949279774694
            ],
            [
              6.338561201971,
              47.954979185933
            ],
            [
              6.3661530482729,
              47.961943699906
            ],
            [
              6.394005582089,
              47.956962872907
            ],
            [
              6.4053578977345,
              47.95311233294
            ],
            [
              6.4088868226821,
              47.943264452338
            ],
            [
              6.4317140631681,
              47.943817555323
            ],
            [
              6.4363061456524,
              47.936497632925
            ],
            [
              6.4604356473863,
              47.91345059658
            ],
            [
              6.4560181967467,
              47.906627288043
            ],
            [
              6.4786058641603,
              47.885521654796
            ],
            [
              6.5032137734313,
              47.89621857502
            ],
            [
              6.5421569547535,
              47.902612242352
            ],
            [
              6.5682839276999,
              47.93449546901
            ],
            [
              6.6017847198921,
              47.944400335071
            ],
            [
              6.6302463718081,
              47.925593409791
            ],
            [
              6.6455373160956,
              47.904022779887
            ],
            [
              6.6696633226227,
              47.89320009565
            ],
            [
              6.703676313184,
              47.881417920779
            ],
            [
              6.7102378561952,
              47.87576777925
            ],
            [
              6.7304382302284,
              47.867533474661
            ],
            [
              6.7377719050045,
              47.861560606022
            ],
            [
              6.7638501387584,
              47.857307907712
            ],
            [
              6.7644647560578,
              47.853457064113
            ],
            [
              6.784819046343,
              47.849620144546
            ],
            [
              6.792547182043,
              47.840496971878
            ],
            [
              6.7884284780797,
              47.834484214023
            ],
            [
              6.8235333222471,
              47.813051201983
            ],
            [
              6.8114632537081,
              47.796039586722
            ],
            [
              6.8001141025198,
              47.788278130542
            ],
            [
              6.7847015099183,
              47.781435523033
            ],
            [
              6.7650773666903,
              47.758942674682
            ],
            [
              6.7576409592057,
              47.747875183778
            ],
            [
              6.7609908394016,
              47.721624696265
            ],
            [
              6.7725696975861,
              47.71163527482
            ],
            [
              6.7700786247337,
              47.703397657298
            ],
            [
              6.7802047028747,
              47.689678427078
            ],
            [
              6.7761038578289,
              47.668575486147
            ],
            [
              6.7809826657994,
              47.658367488334
            ],
            [
              6.7983482668023,
              47.642094496848
            ],
            [
              6.8003237426455,
              47.634676896824
            ],
            [
              6.7893058560475,
              47.627261850283
            ],
            [
              6.7804100915452,
              47.625260533149
            ],
            [
              6.7776052176551,
              47.615637994811
            ],
            [
              6.7870628393266,
              47.61005162672
            ],
            [
              6.7916275066824,
              47.59618242703
            ],
            [
              6.8022943569297,
              47.586021530191
            ],
            [
              6.818819342882,
              47.576713126905
            ],
            [
              6.8069985618205,
              47.562799803717
            ],
            [
              6.8012316005384,
              47.562361681719
            ],
            [
              6.7974982776923,
              47.549516322215
            ],
            [
              6.7809665391051,
              47.536412762244
            ],
            [
              6.7683735285688,
              47.537749840769
            ],
            [
              6.7528882401679,
              47.543521415366
            ],
            [
              6.7409937123091,
              47.55431305009
            ],
            [
              6.7265473440888,
              47.560466513522
            ],
            [
              6.7154115299407,
              47.552914539148
            ],
            [
              6.6855610050528,
              47.562955417822
            ],
            [
              6.6888656429718,
              47.570804123782
            ],
            [
              6.6695710506909,
              47.572516821504
            ],
            [
              6.6600556699688,
              47.579897594928
            ],
            [
              6.6534994623075,
              47.571313940022
            ],
            [
              6.6701763034189,
              47.557900781795
            ],
            [
              6.6467168301981,
              47.53526431792
            ],
            [
              6.6291213056858,
              47.531097345326
            ],
            [
              6.6169076495322,
              47.53096352473
            ],
            [
              6.5988011068119,
              47.539872720756
            ],
            [
              6.5817732561311,
              47.539866618089
            ],
            [
              6.5682850532602,
              47.532964684568
            ],
            [
              6.5790621014857,
              47.525057917341
            ],
            [
              6.5836439355223,
              47.509982562088
            ],
            [
              6.5735160004641,
              47.495489902695
            ],
            [
              6.5582025092095,
              47.496814917298
            ],
            [
              6.5535649973603,
              47.492794483415
            ],
            [
              6.5345414999605,
              47.506051508274
            ],
            [
              6.5183718363402,
              47.496502507822
            ],
            [
              6.4958967399913,
              47.505688006585
            ],
            [
              6.4710619701094,
              47.487165902965
            ],
            [
              6.4634948199838,
              47.512115441106
            ],
            [
              6.4289058446505,
              47.5169888253
            ],
            [
              6.4176535676023,
              47.521078457963
            ],
            [
              6.401371649444,
              47.520299009193
            ],
            [
              6.3934832135071,
              47.516131364904
            ],
            [
              6.3928349464655,
              47.50771882024
            ],
            [
              6.3679499207552,
              47.513495557164
            ],
            [
              6.3486273758899,
              47.50583494217
            ],
            [
              6.3343498677298,
              47.505967983253
            ],
            [
              6.3194622093461,
              47.485540598389
            ],
            [
              6.3025025471738,
              47.49152594453
            ],
            [
              6.2997539670346,
              47.484054799832
            ],
            [
              6.301210989282,
              47.467928704375
            ],
            [
              6.2874793153018,
              47.460968303918
            ],
            [
              6.2801775945428,
              47.443969681745
            ],
            [
              6.263541283654,
              47.446111559058
            ],
            [
              6.2623300177143,
              47.430571634935
            ],
            [
              6.2502936711408,
              47.424810091085
            ],
            [
              6.2394366632237,
              47.431480940273
            ],
            [
              6.2343557323534,
              47.424627410052
            ],
            [
              6.2427351187986,
              47.414011465779
            ],
            [
              6.2320650830845,
              47.414168026391
            ],
            [
              6.2243682111045,
              47.420036070169
            ],
            [
              6.2004449826992,
              47.429141415445
            ],
            [
              6.199286591994,
              47.421840616519
            ],
            [
              6.1909197990926,
              47.414870283514
            ],
            [
              6.1711805474595,
              47.412159811785
            ],
            [
              6.1716477183347,
              47.407945159887
            ],
            [
              6.1844071214655,
              47.402015080619
            ],
            [
              6.1728423123163,
              47.397819475189
            ],
            [
              6.1522419359909,
              47.386118440979
            ],
            [
              6.1380274381798,
              47.386676134179
            ],
            [
              6.1166555407966,
              47.393181051924
            ],
            [
              6.1041786160535,
              47.382405041571
            ],
            [
              6.1067175485251,
              47.37707499365
            ],
            [
              6.1197535163538,
              47.36647237422
            ],
            [
              6.1129616996199,
              47.361336476943
            ],
            [
              6.1024898732853,
              47.361317553574
            ],
            [
              6.0996954915,
              47.37185711117
            ],
            [
              6.081531751757,
              47.371453831253
            ],
            [
              6.080044218278,
              47.354032012422
            ],
            [
              6.0667977679461,
              47.349372658626
            ],
            [
              6.0541055119785,
              47.350726964455
            ],
            [
              6.0344506439514,
              47.341001809342
            ],
            [
              6.0235520408948,
              47.332344827388
            ],
            [
              5.9958846761029,
              47.339347508631
            ],
            [
              5.989361863815,
              47.327626814323
            ],
            [
              5.9736802174701,
              47.331638462475
            ],
            [
              5.965730648432,
              47.337146093441
            ],
            [
              5.9413734265811,
              47.344257225785
            ],
            [
              5.9269718960405,
              47.344840931073
            ],
            [
              5.9264399732294,
              47.327072561708
            ],
            [
              5.9088438658603,
              47.331310058375
            ],
            [
              5.8967089702137,
              47.32907312258
            ],
            [
              5.9021382948584,
              47.315173587478
            ],
            [
              5.8935058005458,
              47.310802954862
            ],
            [
              5.8838943313054,
              47.321588272893
            ],
            [
              5.853145246542,
              47.310929356036
            ],
            [
              5.8440112265694,
              47.300024805332
            ],
            [
              5.8059637234122,
              47.292980800624
            ],
            [
              5.7979865070798,
              47.289348636043
            ],
            [
              5.7839090511043,
              47.290625059339
            ],
            [
              5.7727997630254,
              47.278395213513
            ],
            [
              5.7468354803137,
              47.270612231992
            ],
            [
              5.7322991145862,
              47.263567350231
            ],
            [
              5.7145347872316,
              47.270255852947
            ],
            [
              5.6987272452696,
              47.265000343118
            ],
            [
              5.6759872373979,
              47.275744771312
            ],
            [
              5.6656763893789,
              47.266318822653
            ],
            [
              5.6526839162171,
              47.264756696834
            ],
            [
              5.6423010293029,
              47.256186141259
            ],
            [
              5.6231633057099,
              47.255208344134
            ],
            [
              5.6021061150272,
              47.260527974194
            ],
            [
              5.5911669138029,
              47.259080466452
            ],
            [
              5.5815498545659,
              47.253139353829
            ],
            [
              5.5744646217084,
              47.254058031035
            ],
            [
              5.572094259787,
              47.268439485834
            ],
            [
              5.5547357528367,
              47.27999843245
            ],
            [
              5.5341715527281,
              47.283450701838
            ],
            [
              5.5325465734396,
              47.290563051661
            ],
            [
              5.5232748679603,
              47.305475313171
            ],
            [
              5.5185372800929,
              47.304183948833
            ],
            [
              5.5019256795616,
              47.313102520209
            ],
            [
              5.4722371211403,
              47.316973006824
            ],
            [
              5.4771934620117,
              47.329779404199
            ],
            [
              5.4871633423792,
              47.326514768548
            ],
            [
              5.4927880062007,
              47.332077409303
            ],
            [
              5.4947014198327,
              47.345394219096
            ],
            [
              5.4879992758037,
              47.362006675506
            ],
            [
              5.4969499668019,
              47.390119714582
            ],
            [
              5.4806794026958,
              47.394073385973
            ],
            [
              5.4513935817246,
              47.384063497064
            ],
            [
              5.4323534928092,
              47.392856521473
            ],
            [
              5.4349837698486,
              47.400482033333
            ],
            [
              5.4445415221439,
              47.404097440444
            ],
            [
              5.4304266875956,
              47.421353865918
            ],
            [
              5.4310568615775,
              47.435650590036
            ],
            [
              5.4402995308717,
              47.445125187341
            ],
            [
              5.4391961289873,
              47.450105287536
            ],
            [
              5.4224114151039,
              47.44902392543
            ],
            [
              5.4064942956926,
              47.461823832729
            ],
            [
              5.3798420907935,
              47.45069028099
            ],
            [
              5.3765928972898,
              47.464682433056
            ],
            [
              5.4076480358261,
              47.477230704271
            ],
            [
              5.3871419907654,
              47.480832872709
            ],
            [
              5.3992090935811,
              47.499013426534
            ],
            [
              5.4308254578991,
              47.496179401634
            ],
            [
              5.436098919597,
              47.491551861741
            ],
            [
              5.4470648391405,
              47.496253627466
            ],
            [
              5.4666287960975,
              47.518366663603
            ],
            [
              5.471682031464,
              47.527457320728
            ],
            [
              5.4864051857621,
              47.526968522713
            ],
            [
              5.4968620391106,
              47.544147146933
            ],
            [
              5.4912891652093,
              47.563383076133
            ],
            [
              5.4807298338814,
              47.565874912272
            ],
            [
              5.4869927565051,
              47.572614727121
            ],
            [
              5.4775419389678,
              47.608718587184
            ],
            [
              5.4708011189814,
              47.60840488799
            ],
            [
              5.4504095136146,
              47.621578316965
            ],
            [
              5.4402012469891,
              47.630289549893
            ],
            [
              5.4258591112658,
              47.632112058174
            ],
            [
              5.3998073050821,
              47.597157046249
            ],
            [
              5.3894441218081,
              47.595134574398
            ],
            [
              5.3852312899053,
              47.601140823119
            ],
            [
              5.3740797661661,
              47.604538026913
            ],
            [
              5.3727580571009,
              47.618659790315
            ],
            [
              5.3868465136168,
              47.635662494107
            ],
            [
              5.4055917713039,
              47.647770719631
            ],
            [
              5.3984746606309,
              47.649083223607
            ],
            [
              5.406340329799,
              47.673403498326
            ],
            [
              5.4265210893308,
              47.675523872509
            ],
            [
              5.4357130635739,
              47.670913872806
            ],
            [
              5.4460337073379,
              47.670773959543
            ],
            [
              5.4725699801331,
              47.67672271053
            ],
            [
              5.482566377733,
              47.684598405046
            ],
            [
              5.5167714113012,
              47.673665697151
            ],
            [
              5.529779465589,
              47.672825610683
            ],
            [
              5.5429630425056,
              47.685875666578
            ],
            [
              5.5672035170122,
              47.705232598617
            ],
            [
              5.580075717534,
              47.703119392386
            ],
            [
              5.5966797529575,
              47.671689689304
            ],
            [
              5.6060928986115,
              47.675206717975
            ],
            [
              5.616916763571,
              47.673506825098
            ],
            [
              5.6350433227815,
              47.676729768703
            ],
            [
              5.6534107674437,
              47.677377804161
            ],
            [
              5.6602009774384,
              47.684701035958
            ],
            [
              5.6900715815111,
              47.684834261118
            ],
            [
              5.6946315676577,
              47.691589089204
            ],
            [
              5.6934923219824,
              47.703700407172
            ],
            [
              5.6841172724944,
              47.711966308717
            ],
            [
              5.6848345035363,
              47.722252488703
            ],
            [
              5.6933389025912,
              47.737536114776
            ],
            [
              5.7092157661502,
              47.744819092275
            ],
            [
              5.7061546847262,
              47.752289930468
            ],
            [
              5.7092177964874,
              47.763723848917
            ],
            [
              5.7050877892064,
              47.769109267163
            ],
            [
              5.6799723393154,
              47.76996692113
            ],
            [
              5.6766127410923,
              47.77915552638
            ],
            [
              5.6816907857215,
              47.801918539863
            ],
            [
              5.6902845680034,
              47.818602061169
            ],
            [
              5.7038164823427,
              47.822615914257
            ],
            [
              5.7326324521832,
              47.817595239681
            ],
            [
              5.7460508220444,
              47.823594453292
            ],
            [
              5.7441242763236,
              47.848675592832
            ],
            [
              5.7534185985443,
              47.851779809209
            ],
            [
              5.7611967718111,
              47.859337964072
            ],
            [
              5.7981943193063,
              47.852395843864
            ],
            [
              5.8053544944686,
              47.8473671425
            ],
            [
              5.8279856830918,
              47.851868490955
            ],
            [
              5.8214368328201,
              47.868177826395
            ],
            [
              5.8366827972636,
              47.885275579155
            ],
            [
              5.8483941586096,
              47.904328585027
            ],
            [
              5.8699685694528,
              47.900702448936
            ],
            [
              5.8864978594033,
              47.902632285568
            ],
            [
              5.8908642780035,
              47.910826243984
            ],
            [
              5.884723650121,
              47.92604631497
            ]
          ]
        ]
      },
      "properties": {
        "code": "70",
        "nom": "Haute-Saône",
        "population": 234601,
        "delitspour1000": "3,956",
        "nombremosque": 13,
        "parentsEtrangers": "88,0",
        "benefRsa": 7888,
        "eoliennes": 17
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -0.054527208218665,
              48.382004461206
            ],
            [
              -0.050692623162423,
              48.375201195715
            ],
            [
              -0.035753099663116,
              48.384874683334
            ],
            [
              -0.022054721991416,
              48.388059994444
            ],
            [
              -0.020363541559243,
              48.393656323667
            ],
            [
              -0.0025641291874286,
              48.397311951396
            ],
            [
              0.0065863250934992,
              48.388521385965
            ],
            [
              0.020992722816365,
              48.380200925309
            ],
            [
              0.062489872264419,
              48.382213868151
            ],
            [
              0.056684859958794,
              48.393974400454
            ],
            [
              0.067826622948661,
              48.406115400716
            ],
            [
              0.083580132468228,
              48.411137710408
            ],
            [
              0.09917046478078,
              48.41034986941
            ],
            [
              0.11624768586472,
              48.435555660302
            ],
            [
              0.15131906064063,
              48.437226845695
            ],
            [
              0.15811789380158,
              48.4440164218
            ],
            [
              0.15610127663516,
              48.454794932825
            ],
            [
              0.16965670000587,
              48.449364042801
            ],
            [
              0.16972375424173,
              48.461776714307
            ],
            [
              0.18125494041244,
              48.464965078387
            ],
            [
              0.18981304225187,
              48.461891344828
            ],
            [
              0.21823241324651,
              48.473790546424
            ],
            [
              0.22939338887665,
              48.472578001816
            ],
            [
              0.25857807031538,
              48.476710383834
            ],
            [
              0.26286221529839,
              48.482954540393
            ],
            [
              0.27593434536664,
              48.479055127239
            ],
            [
              0.29585588821524,
              48.480174860693
            ],
            [
              0.31789727296834,
              48.471938210212
            ],
            [
              0.32727632603609,
              48.471072305832
            ],
            [
              0.33874148490671,
              48.461599536909
            ],
            [
              0.35578465090337,
              48.458217063582
            ],
            [
              0.36395632992898,
              48.451631721658
            ],
            [
              0.36771793204951,
              48.438272682172
            ],
            [
              0.38066015166482,
              48.425411796164
            ],
            [
              0.38150787714381,
              48.417547978808
            ],
            [
              0.37172370405574,
              48.410451667862
            ],
            [
              0.37537215739256,
              48.395740224588
            ],
            [
              0.37386118731698,
              48.386969757082
            ],
            [
              0.37865893857906,
              48.383227765197
            ],
            [
              0.38255236381737,
              48.359498801305
            ],
            [
              0.38828549911199,
              48.349122009475
            ],
            [
              0.38047838823167,
              48.341797530871
            ],
            [
              0.38260989677575,
              48.333828412247
            ],
            [
              0.3954029644226,
              48.320549965535
            ],
            [
              0.4062203560807,
              48.314621149851
            ],
            [
              0.41599578567133,
              48.321625198799
            ],
            [
              0.42688901989368,
              48.315425242308
            ],
            [
              0.43133408375288,
              48.306638667446
            ],
            [
              0.44279870606142,
              48.304629310064
            ],
            [
              0.46361198113469,
              48.305016107657
            ],
            [
              0.48050090924161,
              48.298592258919
            ],
            [
              0.48757654033176,
              48.307795859172
            ],
            [
              0.50702990447062,
              48.295832610533
            ],
            [
              0.49455647283795,
              48.28681567575
            ],
            [
              0.51293004449183,
              48.266874483366
            ],
            [
              0.5303000454288,
              48.265496730429
            ],
            [
              0.53848634108307,
              48.256987820339
            ],
            [
              0.53597049486367,
              48.249844560134
            ],
            [
              0.55013843367508,
              48.249395520335
            ],
            [
              0.56099429972633,
              48.245949063769
            ],
            [
              0.57919146406902,
              48.24436440048
            ],
            [
              0.63315984470535,
              48.245553870078
            ],
            [
              0.63190429889268,
              48.254754506701
            ],
            [
              0.64070530481206,
              48.261221689671
            ],
            [
              0.65315095543918,
              48.263702677988
            ],
            [
              0.67547108490982,
              48.254740726504
            ],
            [
              0.68321966914477,
              48.2485882228
            ],
            [
              0.7165758658899,
              48.212094515686
            ],
            [
              0.72363045806996,
              48.19813955141
            ],
            [
              0.73014979083029,
              48.200521766169
            ],
            [
              0.73782963836386,
              48.189069627691
            ],
            [
              0.75566254080812,
              48.181981836049
            ],
            [
              0.76407918474979,
              48.181599665308
            ],
            [
              0.79765841643139,
              48.19445496608
            ],
            [
              0.79562670501545,
              48.188043105362
            ],
            [
              0.80835901939965,
              48.18611890903
            ],
            [
              0.82688955262783,
              48.175387000045
            ],
            [
              0.83683452952269,
              48.167352245345
            ],
            [
              0.86198925121346,
              48.166816904566
            ],
            [
              0.88249645680993,
              48.161766033434
            ],
            [
              0.91161206421963,
              48.148858532659
            ],
            [
              0.91379809767445,
              48.135125048448
            ],
            [
              0.89396954177329,
              48.135535055964
            ],
            [
              0.87293551592102,
              48.133408559058
            ],
            [
              0.852575219489,
              48.133602174445
            ],
            [
              0.8553701454218,
              48.122620579792
            ],
            [
              0.84121734187884,
              48.103059710788
            ],
            [
              0.83268783335498,
              48.098453526315
            ],
            [
              0.81435131408069,
              48.098801731111
            ],
            [
              0.81516825904503,
              48.093730753222
            ],
            [
              0.83920278539184,
              48.09125189303
            ],
            [
              0.84483764311698,
              48.086647072566
            ],
            [
              0.84302009925743,
              48.072638200667
            ],
            [
              0.83460349982458,
              48.070147734707
            ],
            [
              0.80118315423495,
              48.071513555954
            ],
            [
              0.79653309159021,
              48.05267766261
            ],
            [
              0.79747785616118,
              48.037556987677
            ],
            [
              0.80877017226921,
              48.031993621434
            ],
            [
              0.82520263947346,
              48.03005952554
            ],
            [
              0.83672342747852,
              48.034558745892
            ],
            [
              0.84155459904793,
              48.029673676539
            ],
            [
              0.84052666414894,
              48.021048593652
            ],
            [
              0.83170184588828,
              48.006116934727
            ],
            [
              0.83237463233502,
              47.996592094392
            ],
            [
              0.82622288599262,
              47.991475839362
            ],
            [
              0.82428057343045,
              47.982142037161
            ],
            [
              0.84529238839341,
              47.954438931698
            ],
            [
              0.84579379193831,
              47.941403140329
            ],
            [
              0.83727689039754,
              47.937246716219
            ],
            [
              0.8171459594605,
              47.934467914387
            ],
            [
              0.81211830142135,
              47.928939306036
            ],
            [
              0.80913428200565,
              47.91066247592
            ],
            [
              0.81721753097407,
              47.892418803105
            ],
            [
              0.81018749426364,
              47.890393591962
            ],
            [
              0.79799062776542,
              47.898193546758
            ],
            [
              0.79002362904946,
              47.912210300533
            ],
            [
              0.78051199587287,
              47.910375216053
            ],
            [
              0.77032460906914,
              47.902009083915
            ],
            [
              0.75986941585155,
              47.898224938894
            ],
            [
              0.75733759497489,
              47.884473514914
            ],
            [
              0.76468938104337,
              47.866582667793
            ],
            [
              0.75916076452252,
              47.859222188506
            ],
            [
              0.77401870735853,
              47.851208382865
            ],
            [
              0.77457358541314,
              47.839684494777
            ],
            [
              0.76841445732376,
              47.831101351932
            ],
            [
              0.7588569724751,
              47.833536394698
            ],
            [
              0.74540046495951,
              47.825663057103
            ],
            [
              0.7397212944059,
              47.814678933449
            ],
            [
              0.72484407861962,
              47.798889067315
            ],
            [
              0.71263236035006,
              47.790038970809
            ],
            [
              0.69798475487217,
              47.788889445319
            ],
            [
              0.68931761050545,
              47.779996503151
            ],
            [
              0.70344170542358,
              47.769940283327
            ],
            [
              0.69688004266227,
              47.764225211
            ],
            [
              0.67564997055176,
              47.768962404729
            ],
            [
              0.63937932915856,
              47.751572315523
            ],
            [
              0.62683335231018,
              47.751793159891
            ],
            [
              0.61066958830157,
              47.732034198596
            ],
            [
              0.61159726872368,
              47.728134311986
            ],
            [
              0.59409530653203,
              47.723105855723
            ],
            [
              0.58052041667909,
              47.712330763793
            ],
            [
              0.59297009308968,
              47.703590911989
            ],
            [
              0.59557114412559,
              47.688312714504
            ],
            [
              0.60418662363095,
              47.685607124967
            ],
            [
              0.61443245110168,
              47.694215472574
            ],
            [
              0.61480416648326,
              47.68275087954
            ],
            [
              0.60463738045699,
              47.679968482062
            ],
            [
              0.58772733155021,
              47.669617061038
            ],
            [
              0.55947922009731,
              47.665994923777
            ],
            [
              0.54289746214182,
              47.656203651505
            ],
            [
              0.51325174134335,
              47.652863992861
            ],
            [
              0.4996666538331,
              47.645272415067
            ],
            [
              0.4797670863672,
              47.64329220016
            ],
            [
              0.47607646347987,
              47.648011563852
            ],
            [
              0.45662804145159,
              47.638826353606
            ],
            [
              0.45518198840301,
              47.627017225989
            ],
            [
              0.44993291932188,
              47.619329777154
            ],
            [
              0.42390524684004,
              47.617824451176
            ],
            [
              0.39702255940301,
              47.638927008339
            ],
            [
              0.38107503178189,
              47.639064909321
            ],
            [
              0.36465430735157,
              47.626011449659
            ],
            [
              0.36480446047317,
              47.620165400372
            ],
            [
              0.37905609046241,
              47.610779501442
            ],
            [
              0.39442325665444,
              47.594393009892
            ],
            [
              0.40216586206278,
              47.579002216555
            ],
            [
              0.3789542711163,
              47.569104805534
            ],
            [
              0.36673611607068,
              47.573457923574
            ],
            [
              0.33958580258874,
              47.579472315073
            ],
            [
              0.33844630799321,
              47.585030259946
            ],
            [
              0.32325488038268,
              47.592888415747
            ],
            [
              0.29001704748145,
              47.597728628188
            ],
            [
              0.27799234011686,
              47.597381208395
            ],
            [
              0.26784211805055,
              47.608672360211
            ],
            [
              0.25925309972834,
              47.612253732911
            ],
            [
              0.23768269638563,
              47.610966183961
            ],
            [
              0.23000044283917,
              47.608397360802
            ],
            [
              0.22412414705184,
              47.612294541765
            ],
            [
              0.20177337945804,
              47.610581878091
            ],
            [
              0.20190440390773,
              47.615577460258
            ],
            [
              0.18583053478704,
              47.616541172133
            ],
            [
              0.18492347945562,
              47.59951888853
            ],
            [
              0.17380051201584,
              47.59335138679
            ],
            [
              0.16192950279651,
              47.594141382277
            ],
            [
              0.15160658198455,
              47.581530335722
            ],
            [
              0.13996010175599,
              47.586619930035
            ],
            [
              0.13138185271472,
              47.596242407775
            ],
            [
              0.1226270100427,
              47.596902341854
            ],
            [
              0.11568780138148,
              47.605729170813
            ],
            [
              0.09703338042594,
              47.604785657917
            ],
            [
              0.072757700964096,
              47.607779497472
            ],
            [
              0.064852910303496,
              47.604386057354
            ],
            [
              0.051656847144079,
              47.60651153657
            ],
            [
              0.037731156984854,
              47.617755071618
            ],
            [
              0.017034808401921,
              47.621104843447
            ],
            [
              0.0052143482003776,
              47.640007970982
            ],
            [
              -0.0045114795481094,
              47.647685622756
            ],
            [
              -0.0200217526275,
              47.637114273453
            ],
            [
              -0.024425581635307,
              47.646338441701
            ],
            [
              -0.051484728490695,
              47.648440465421
            ],
            [
              -0.07182717748956,
              47.658009370506
            ],
            [
              -0.092016973776158,
              47.650975769952
            ],
            [
              -0.094598120211807,
              47.662259952753
            ],
            [
              -0.10759735671751,
              47.655283303327
            ],
            [
              -0.11482086091719,
              47.642983338229
            ],
            [
              -0.11331543555609,
              47.635027101972
            ],
            [
              -0.12630392349164,
              47.637206815768
            ],
            [
              -0.13503949950058,
              47.632521704537
            ],
            [
              -0.14951423955432,
              47.638152293611
            ],
            [
              -0.15952557421716,
              47.64648643878
            ],
            [
              -0.17123970337477,
              47.644974449849
            ],
            [
              -0.19315208570794,
              47.647919424013
            ],
            [
              -0.19505943365123,
              47.661376068174
            ],
            [
              -0.22019670030717,
              47.689623181347
            ],
            [
              -0.21550112973611,
              47.695890633051
            ],
            [
              -0.20031552112238,
              47.694692207342
            ],
            [
              -0.18165434555472,
              47.706039135515
            ],
            [
              -0.18231918647758,
              47.712901316612
            ],
            [
              -0.193745472526,
              47.723226403392
            ],
            [
              -0.20237724749556,
              47.725243273377
            ],
            [
              -0.23306044236303,
              47.707562902473
            ],
            [
              -0.24758339198338,
              47.706703696744
            ],
            [
              -0.27306766551386,
              47.713197049548
            ],
            [
              -0.28670550188632,
              47.71932399093
            ],
            [
              -0.31161563979599,
              47.717455540853
            ],
            [
              -0.334071412114,
              47.72116141568
            ],
            [
              -0.35245292343886,
              47.714134336409
            ],
            [
              -0.36582157233392,
              47.716119600548
            ],
            [
              -0.35988584661972,
              47.722646870915
            ],
            [
              -0.35849844840348,
              47.732083694905
            ],
            [
              -0.37492261789305,
              47.738946783268
            ],
            [
              -0.37263899192078,
              47.743859320297
            ],
            [
              -0.37988199972814,
              47.751114916558
            ],
            [
              -0.38170083144601,
              47.760566513867
            ],
            [
              -0.37543576577617,
              47.767237167837
            ],
            [
              -0.38147760525139,
              47.786129408041
            ],
            [
              -0.38122838050963,
              47.792974054995
            ],
            [
              -0.38820603603123,
              47.80532030035
            ],
            [
              -0.414118882442,
              47.802731937353
            ],
            [
              -0.43408545821236,
              47.809053389034
            ],
            [
              -0.43942499951109,
              47.824281702194
            ],
            [
              -0.44786007819229,
              47.832213642746
            ],
            [
              -0.42563530757757,
              47.847489705141
            ],
            [
              -0.41098778873327,
              47.846630416002
            ],
            [
              -0.40939113351912,
              47.858366946342
            ],
            [
              -0.3872660252858,
              47.859739635974
            ],
            [
              -0.37438932327927,
              47.858307471285
            ],
            [
              -0.3788466950942,
              47.868934787031
            ],
            [
              -0.37075137340683,
              47.868853350787
            ],
            [
              -0.3706707137534,
              47.88002559388
            ],
            [
              -0.38041106002285,
              47.888964997664
            ],
            [
              -0.4051663591631,
              47.892931533534
            ],
            [
              -0.40607763263706,
              47.909197015178
            ],
            [
              -0.39681530411423,
              47.916433066634
            ],
            [
              -0.39703835031919,
              47.925921819319
            ],
            [
              -0.3848413318954,
              47.930840124314
            ],
            [
              -0.36752020190308,
              47.931197626205
            ],
            [
              -0.31986817303288,
              47.935438690786
            ],
            [
              -0.31141456732932,
              47.940444969262
            ],
            [
              -0.29532155103221,
              47.944002411272
            ],
            [
              -0.2814765494518,
              47.953761084393
            ],
            [
              -0.28263301139993,
              47.958189109934
            ],
            [
              -0.30225291512622,
              47.966115961547
            ],
            [
              -0.29930930691471,
              47.976935145751
            ],
            [
              -0.31333160110472,
              47.99465849192
            ],
            [
              -0.31601085437105,
              48.005260812236
            ],
            [
              -0.33992953904496,
              48.011599545784
            ],
            [
              -0.33355338496219,
              48.027356287335
            ],
            [
              -0.34166494063899,
              48.043097320969
            ],
            [
              -0.33035959801771,
              48.050372889432
            ],
            [
              -0.32050830389774,
              48.045287679368
            ],
            [
              -0.2844545766571,
              48.054198181443
            ],
            [
              -0.28587607811837,
              48.058484368836
            ],
            [
              -0.27020842826682,
              48.064637061516
            ],
            [
              -0.230940827834,
              48.05898089869
            ],
            [
              -0.22496049794856,
              48.074002462195
            ],
            [
              -0.23796701443346,
              48.0860434952
            ],
            [
              -0.23212346857464,
              48.099757288689
            ],
            [
              -0.21717909954452,
              48.106955022454
            ],
            [
              -0.2164161471053,
              48.113730301099
            ],
            [
              -0.22296781426035,
              48.122764359521
            ],
            [
              -0.24693727730225,
              48.127907263555
            ],
            [
              -0.2538122759814,
              48.136527342697
            ],
            [
              -0.23752546842672,
              48.148401403805
            ],
            [
              -0.24991841798874,
              48.157104182132
            ],
            [
              -0.22988186623387,
              48.169441185432
            ],
            [
              -0.1989564039091,
              48.178277021985
            ],
            [
              -0.16448310789664,
              48.199775347991
            ],
            [
              -0.14683427015184,
              48.205007193398
            ],
            [
              -0.15314844358679,
              48.214724452095
            ],
            [
              -0.14529030231048,
              48.222176695242
            ],
            [
              -0.15800139020414,
              48.23895585297
            ],
            [
              -0.16310127335978,
              48.255543211147
            ],
            [
              -0.15367605629457,
              48.267851501569
            ],
            [
              -0.1482659497626,
              48.28554294614
            ],
            [
              -0.1389339103484,
              48.298361232987
            ],
            [
              -0.14839294289625,
              48.31389543324
            ],
            [
              -0.15461485409923,
              48.313988129598
            ],
            [
              -0.15790591784961,
              48.323708778172
            ],
            [
              -0.15623906965009,
              48.338781363599
            ],
            [
              -0.1478027384538,
              48.34409605281
            ],
            [
              -0.13226516791641,
              48.343676185358
            ],
            [
              -0.13798494654337,
              48.361357214921
            ],
            [
              -0.11594956511513,
              48.368996253948
            ],
            [
              -0.11215911205637,
              48.374246979232
            ],
            [
              -0.092866414336842,
              48.374573991173
            ],
            [
              -0.077814182838297,
              48.369009222222
            ],
            [
              -0.070093071461379,
              48.377065238354
            ],
            [
              -0.054527208218665,
              48.382004461206
            ]
          ]
        ]
      },
      "properties": {
        "code": "72",
        "nom": "Sarthe",
        "population": 566993,
        "delitspour1000": "4,589",
        "nombremosque": 13,
        "parentsEtrangers": "79,6",
        "benefRsa": 26390,
        "eoliennes": 27
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1.3796981484469,
              50.065011890414
            ],
            [
              1.3926951404215,
              50.075508103188
            ],
            [
              1.4326447913126,
              50.094349140281
            ],
            [
              1.453882566018,
              50.110330912339
            ],
            [
              1.4680459164898,
              50.139076728869
            ],
            [
              1.4787103407902,
              50.164913871979
            ],
            [
              1.4916774164956,
              50.184158850335
            ],
            [
              1.5124437978409,
              50.201705539893
            ],
            [
              1.5484363732305,
              50.215217479179
            ],
            [
              1.5596528651115,
              50.210793191325
            ],
            [
              1.5742402203094,
              50.196012165606
            ],
            [
              1.5961920997106,
              50.185502423396
            ],
            [
              1.6115742742922,
              50.191033491192
            ],
            [
              1.6274144143455,
              50.190005124318
            ],
            [
              1.6733457641263,
              50.174680696998
            ],
            [
              1.683793928232,
              50.183026098336
            ],
            [
              1.6708368854438,
              50.194260444795
            ],
            [
              1.6696347125474,
              50.205895817898
            ],
            [
              1.6626657206518,
              50.213594131613
            ],
            [
              1.6392011577592,
              50.217276772482
            ],
            [
              1.6225007114324,
              50.215151972316
            ],
            [
              1.5920617370787,
              50.248546445422
            ],
            [
              1.590272985914,
              50.255949292147
            ],
            [
              1.562292530548,
              50.255765909795
            ],
            [
              1.549313280898,
              50.260403925768
            ],
            [
              1.5403360598397,
              50.273870926874
            ],
            [
              1.5379421306702,
              50.282668408098
            ],
            [
              1.5509359402445,
              50.350318813543
            ],
            [
              1.5558295005084,
              50.361315709087
            ],
            [
              1.5713313280132,
              50.35845215301
            ],
            [
              1.612966033514,
              50.360292820052
            ],
            [
              1.6242577191597,
              50.366290636763
            ],
            [
              1.6339779035367,
              50.355381211519
            ],
            [
              1.6415398327353,
              50.352148667835
            ],
            [
              1.6451677016651,
              50.339220671372
            ],
            [
              1.6625434941435,
              50.337979860428
            ],
            [
              1.6707113970685,
              50.331006763813
            ],
            [
              1.6923870872348,
              50.342950152932
            ],
            [
              1.7281096719156,
              50.345887124721
            ],
            [
              1.7394496425691,
              50.354575794945
            ],
            [
              1.7592988596181,
              50.358044379758
            ],
            [
              1.7617125620618,
              50.361608996652
            ],
            [
              1.7787814318994,
              50.361867050627
            ],
            [
              1.8050936385045,
              50.359561131664
            ],
            [
              1.8335611982716,
              50.34494650473
            ],
            [
              1.8509931504967,
              50.343311587216
            ],
            [
              1.8540618817278,
              50.339097567191
            ],
            [
              1.8725567657952,
              50.332205089553
            ],
            [
              1.8759260198078,
              50.321745552866
            ],
            [
              1.892312392029,
              50.318431455473
            ],
            [
              1.8992385043566,
              50.313422021051
            ],
            [
              1.9180034741311,
              50.311782390973
            ],
            [
              1.938135743335,
              50.330102333677
            ],
            [
              1.9465507617787,
              50.331439621554
            ],
            [
              1.958008929329,
              50.326104677818
            ],
            [
              1.9638648247932,
              50.318095334956
            ],
            [
              1.9587947410604,
              50.312264690911
            ],
            [
              1.9364013540805,
              50.301469956543
            ],
            [
              1.9461358093263,
              50.287429874266
            ],
            [
              1.9643650275496,
              50.28847427511
            ],
            [
              1.9714273752663,
              50.282203794604
            ],
            [
              1.9942735539682,
              50.275664927673
            ],
            [
              2.0146735240532,
              50.26745451465
            ],
            [
              2.0264488530055,
              50.269549689324
            ],
            [
              2.0374720057313,
              50.266749373432
            ],
            [
              2.0471265348839,
              50.258004632192
            ],
            [
              2.0651693499299,
              50.255847696405
            ],
            [
              2.0778174949883,
              50.248821238775
            ],
            [
              2.064084929272,
              50.236244609931
            ],
            [
              2.0684863184493,
              50.22713547454
            ],
            [
              2.0845158657118,
              50.216516470398
            ],
            [
              2.0857123807737,
              50.20101021638
            ],
            [
              2.0957428065948,
              50.205543068689
            ],
            [
              2.114564443101,
              50.202861068434
            ],
            [
              2.1225857198719,
              50.210701687542
            ],
            [
              2.1366725730598,
              50.206542939153
            ],
            [
              2.1462496087203,
              50.198450332057
            ],
            [
              2.1497656380182,
              50.190426518694
            ],
            [
              2.1602481759659,
              50.195073119583
            ],
            [
              2.1741422745707,
              50.208896128118
            ],
            [
              2.1933281148673,
              50.209773250719
            ],
            [
              2.2225947536365,
              50.217809274907
            ],
            [
              2.2260581644339,
              50.212066850615
            ],
            [
              2.2532892806224,
              50.21468395736
            ],
            [
              2.2738268657578,
              50.226839891366
            ],
            [
              2.3012089116446,
              50.212867703297
            ],
            [
              2.3312494867596,
              50.222628702737
            ],
            [
              2.3175919368313,
              50.235075063165
            ],
            [
              2.3340601777872,
              50.238585583732
            ],
            [
              2.35507097303,
              50.216740269224
            ],
            [
              2.3611650521748,
              50.207027979791
            ],
            [
              2.370200687702,
              50.209772132708
            ],
            [
              2.3813992863809,
              50.225585799382
            ],
            [
              2.3921124689493,
              50.226080977662
            ],
            [
              2.4054763326709,
              50.234025879167
            ],
            [
              2.4194026288195,
              50.228084991282
            ],
            [
              2.4390306462363,
              50.227441475995
            ],
            [
              2.451782406941,
              50.230302364541
            ],
            [
              2.4611364848371,
              50.216723315306
            ],
            [
              2.4733136300465,
              50.209287796303
            ],
            [
              2.4852099859931,
              50.20779361481
            ],
            [
              2.4949893147919,
              50.199775603505
            ],
            [
              2.4956847502342,
              50.194560205258
            ],
            [
              2.4678564124169,
              50.186775790139
            ],
            [
              2.4508250196615,
              50.178517294258
            ],
            [
              2.4388530632566,
              50.181050438517
            ],
            [
              2.4148416899699,
              50.173106837647
            ],
            [
              2.4145338031959,
              50.164167221732
            ],
            [
              2.4032721760692,
              50.164802764181
            ],
            [
              2.3993505082333,
              50.157022748395
            ],
            [
              2.3897770350831,
              50.155486617449
            ],
            [
              2.3924780109113,
              50.144711608067
            ],
            [
              2.3830005723033,
              50.129048635249
            ],
            [
              2.3754526661687,
              50.109255035264
            ],
            [
              2.3931345350154,
              50.108161793497
            ],
            [
              2.4145064882364,
              50.10064599248
            ],
            [
              2.4209183396901,
              50.087653623551
            ],
            [
              2.4296522410241,
              50.088185800536
            ],
            [
              2.4501309494095,
              50.124395709785
            ],
            [
              2.4766795936249,
              50.136996746156
            ],
            [
              2.5149437196775,
              50.140815184745
            ],
            [
              2.5162474690159,
              50.134072265494
            ],
            [
              2.5323309219873,
              50.115072822921
            ],
            [
              2.5442437625709,
              50.119819097471
            ],
            [
              2.5513298839509,
              50.14160902898
            ],
            [
              2.5683716243263,
              50.135959316203
            ],
            [
              2.5781796823809,
              50.139890067967
            ],
            [
              2.5792419784879,
              50.128560135785
            ],
            [
              2.5704043870206,
              50.122779169444
            ],
            [
              2.5760156090129,
              50.113414591723
            ],
            [
              2.5980877105058,
              50.108648110659
            ],
            [
              2.6201126526627,
              50.111121522698
            ],
            [
              2.6334093914058,
              50.100839241322
            ],
            [
              2.6511272240456,
              50.095256173844
            ],
            [
              2.657169930789,
              50.099962196692
            ],
            [
              2.6720751553542,
              50.093629975857
            ],
            [
              2.6918326163969,
              50.091724194798
            ],
            [
              2.7046211552392,
              50.081210881091
            ],
            [
              2.7187063567642,
              50.088088516
            ],
            [
              2.7030514584422,
              50.095695432002
            ],
            [
              2.6972338002842,
              50.103665319899
            ],
            [
              2.7067890881945,
              50.108716923871
            ],
            [
              2.7101893900381,
              50.12023749817
            ],
            [
              2.7088192697755,
              50.128494700802
            ],
            [
              2.7295757423117,
              50.125952130979
            ],
            [
              2.7498103547084,
              50.112664006365
            ],
            [
              2.7633418535672,
              50.109960659774
            ],
            [
              2.7808963608043,
              50.111154461184
            ],
            [
              2.7778733322316,
              50.100510270846
            ],
            [
              2.7818371709104,
              50.09110708434
            ],
            [
              2.7746080431577,
              50.079625122566
            ],
            [
              2.7606613237401,
              50.069501982709
            ],
            [
              2.7637488651508,
              50.060596655646
            ],
            [
              2.74255220997,
              50.04822779049
            ],
            [
              2.7523973247116,
              50.040097252225
            ],
            [
              2.7631257218207,
              50.038635576415
            ],
            [
              2.7759503707983,
              50.042977159111
            ],
            [
              2.785654805565,
              50.051165039384
            ],
            [
              2.7926868513373,
              50.048670383144
            ],
            [
              2.8075496584953,
              50.062411099279
            ],
            [
              2.8223390134434,
              50.067524040363
            ],
            [
              2.8315161794119,
              50.063304080954
            ],
            [
              2.8381219175582,
              50.071826365585
            ],
            [
              2.8578492252845,
              50.077395819826
            ],
            [
              2.8553253050783,
              50.062432743603
            ],
            [
              2.8639425759373,
              50.060666985732
            ],
            [
              2.8765326366049,
              50.042182018727
            ],
            [
              2.8640766024256,
              50.032456974449
            ],
            [
              2.8521925480239,
              50.031430981058
            ],
            [
              2.8565849315278,
              50.023449604731
            ],
            [
              2.8724995109934,
              50.020975633738
            ],
            [
              2.8781360015419,
              50.028993132265
            ],
            [
              2.8963079801904,
              50.03702692
            ],
            [
              2.9171578680801,
              50.035709004905
            ],
            [
              2.919242314665,
              50.047297561448
            ],
            [
              2.9328926262549,
              50.043366480523
            ],
            [
              2.9484148787346,
              50.05341982418
            ],
            [
              2.961934435566,
              50.050048626864
            ],
            [
              2.9715289204271,
              50.041363376189
            ],
            [
              2.9922542514471,
              50.05086801359
            ],
            [
              2.9958629004892,
              50.049591917627
            ],
            [
              3.0144351371487,
              50.059149196751
            ],
            [
              3.0320823088719,
              50.05773936258
            ],
            [
              3.0744852263827,
              50.045299545153
            ],
            [
              3.0902522256658,
              50.053740556167
            ],
            [
              3.0943607194207,
              50.046412147816
            ],
            [
              3.1143107112887,
              50.038705130854
            ],
            [
              3.1175554073514,
              50.027668937858
            ],
            [
              3.1330669935014,
              50.024184312563
            ],
            [
              3.1499355040833,
              50.027268946371
            ],
            [
              3.1473452809305,
              50.018694443245
            ],
            [
              3.1669872524317,
              50.020986574845
            ],
            [
              3.172704445659,
              50.011996744997
            ],
            [
              3.1653855582686,
              50.000427219052
            ],
            [
              3.1556881495132,
              49.991750609531
            ],
            [
              3.1702006684636,
              49.991848969299
            ],
            [
              3.1763602229291,
              49.98539730495
            ],
            [
              3.1909782312477,
              49.987719749243
            ],
            [
              3.2030417908111,
              49.983944858799
            ],
            [
              3.1926711034973,
              49.977463804614
            ],
            [
              3.1650294679034,
              49.965384204344
            ],
            [
              3.1681121612169,
              49.961469923767
            ],
            [
              3.1594430924158,
              49.952194432987
            ],
            [
              3.1646455871314,
              49.947433021908
            ],
            [
              3.1361255836602,
              49.935545270461
            ],
            [
              3.1179658743974,
              49.914273685764
            ],
            [
              3.1239474460408,
              49.900973643485
            ],
            [
              3.1166840949989,
              49.895801064082
            ],
            [
              3.1201986642742,
              49.884180430147
            ],
            [
              3.111125884803,
              49.878003935064
            ],
            [
              3.0926931837466,
              49.870741785027
            ],
            [
              3.084777965137,
              49.878613471836
            ],
            [
              3.0738011805401,
              49.875716243718
            ],
            [
              3.0844607531572,
              49.845704891547
            ],
            [
              3.0775195238849,
              49.839932891306
            ],
            [
              3.0570207765314,
              49.832841513803
            ],
            [
              3.0607265435498,
              49.820095909989
            ],
            [
              3.078691331267,
              49.822411623962
            ],
            [
              3.0849494837127,
              49.817971090003
            ],
            [
              3.0801432084513,
              49.808726951807
            ],
            [
              3.0859735751004,
              49.792013991644
            ],
            [
              3.0613242130166,
              49.781248445384
            ],
            [
              3.0741875690948,
              49.766667414361
            ],
            [
              3.097868494334,
              49.766630038579
            ],
            [
              3.0937478217821,
              49.757050185226
            ],
            [
              3.1005489122622,
              49.735129745503
            ],
            [
              3.1106774189989,
              49.728269876831
            ],
            [
              3.1199591291385,
              49.713690772364
            ],
            [
              3.1184045303955,
              49.705964066166
            ],
            [
              3.0994644018402,
              49.706367379871
            ],
            [
              3.083934054211,
              49.692615286185
            ],
            [
              3.0834622590288,
              49.712492975333
            ],
            [
              3.0629532474138,
              49.712572694325
            ],
            [
              3.0571255368829,
              49.707937379439
            ],
            [
              3.0542104980344,
              49.693657391146
            ],
            [
              3.0403344121757,
              49.692302385177
            ],
            [
              3.0261613710586,
              49.680295333003
            ],
            [
              3.0178423969505,
              49.690598727647
            ],
            [
              3.007981175104,
              49.693392449748
            ],
            [
              3.0044346383484,
              49.702703281842
            ],
            [
              2.9895904717799,
              49.70802401434
            ],
            [
              2.9709363993483,
              49.696888160979
            ],
            [
              2.9488409292444,
              49.692322577611
            ],
            [
              2.9552532997715,
              49.67884593624
            ],
            [
              2.9534435781073,
              49.66649717999
            ],
            [
              2.9404360464863,
              49.679243936743
            ],
            [
              2.929804603745,
              49.676691750942
            ],
            [
              2.9298715712778,
              49.688465550847
            ],
            [
              2.9157142028651,
              49.702669694334
            ],
            [
              2.8920131049684,
              49.698640812156
            ],
            [
              2.8842491853168,
              49.710638066104
            ],
            [
              2.8662035297843,
              49.702491469092
            ],
            [
              2.8878187574055,
              49.683329383346
            ],
            [
              2.8572606727357,
              49.683666684467
            ],
            [
              2.8539655663727,
              49.676060968853
            ],
            [
              2.8456390330805,
              49.670707819735
            ],
            [
              2.8501054632717,
              49.661734863215
            ],
            [
              2.8418372938007,
              49.659434814456
            ],
            [
              2.8138681658446,
              49.660051195246
            ],
            [
              2.793605509577,
              49.655952413736
            ],
            [
              2.7897672015361,
              49.638090767081
            ],
            [
              2.7994962764821,
              49.620589648566
            ],
            [
              2.780954797057,
              49.613779313759
            ],
            [
              2.7596875358898,
              49.617816192698
            ],
            [
              2.7575578775006,
              49.620967032176
            ],
            [
              2.735669611905,
              49.625656233463
            ],
            [
              2.717722557286,
              49.623622741048
            ],
            [
              2.7090829578704,
              49.625769842419
            ],
            [
              2.6891461156895,
              49.625604569913
            ],
            [
              2.6836012031895,
              49.610221916906
            ],
            [
              2.6745719967829,
              49.605458647174
            ],
            [
              2.671244814592,
              49.598585836073
            ],
            [
              2.6707176060094,
              49.581414652757
            ],
            [
              2.6496341022379,
              49.571762327
            ],
            [
              2.6277942663565,
              49.585396691219
            ],
            [
              2.6315512495142,
              49.599641079158
            ],
            [
              2.6240655802021,
              49.610230243392
            ],
            [
              2.6097479515677,
              49.608821866221
            ],
            [
              2.5974394042671,
              49.611412932776
            ],
            [
              2.5860669161311,
              49.606336034375
            ],
            [
              2.5805564397692,
              49.598551902534
            ],
            [
              2.5714988829262,
              49.597078849268
            ],
            [
              2.5595011033685,
              49.60560638451
            ],
            [
              2.5650187853668,
              49.618942322527
            ],
            [
              2.5470913559285,
              49.61585472181
            ],
            [
              2.5267251770806,
              49.629399487718
            ],
            [
              2.5213328607654,
              49.626993417349
            ],
            [
              2.5047475416297,
              49.639464567535
            ],
            [
              2.4892215944736,
              49.631617369662
            ],
            [
              2.4781157157449,
              49.620791102842
            ],
            [
              2.4694135852156,
              49.629117015474
            ],
            [
              2.4584901421278,
              49.634211696144
            ],
            [
              2.4456566594192,
              49.652976288486
            ],
            [
              2.4216356481784,
              49.657046546418
            ],
            [
              2.4097068690923,
              49.660994316822
            ],
            [
              2.3882526379825,
              49.655798604409
            ],
            [
              2.3731470912741,
              49.656262653184
            ],
            [
              2.3620706424931,
              49.666736720324
            ],
            [
              2.3513332231199,
              49.6634162158
            ],
            [
              2.3357195776342,
              49.670194582988
            ],
            [
              2.3320856006823,
              49.680991958513
            ],
            [
              2.3157228175868,
              49.68975570628
            ],
            [
              2.3018017866427,
              49.681077171813
            ],
            [
              2.2769463076105,
              49.691476057252
            ],
            [
              2.2498736926922,
              49.697144021683
            ],
            [
              2.247165820093,
              49.701640859686
            ],
            [
              2.2175604243823,
              49.702468676942
            ],
            [
              2.2087617463046,
              49.699756506081
            ],
            [
              2.1871266553278,
              49.702434613522
            ],
            [
              2.1564869379493,
              49.702113852562
            ],
            [
              2.1436950376524,
              49.694407195174
            ],
            [
              2.1214041672853,
              49.68807405176
            ],
            [
              2.1138229603813,
              49.69363624902
            ],
            [
              2.1034905780583,
              49.694739548769
            ],
            [
              2.0877171013921,
              49.690119446789
            ],
            [
              2.0813183676129,
              49.693482422881
            ],
            [
              2.0653010804938,
              49.683146860964
            ],
            [
              2.0501673115406,
              49.686524401131
            ],
            [
              2.0611437714349,
              49.692197134574
            ],
            [
              2.0497573943847,
              49.697721467949
            ],
            [
              2.0354858488082,
              49.709101413159
            ],
            [
              2.0267654945296,
              49.711306361766
            ],
            [
              2.0077539807641,
              49.708265729309
            ],
            [
              2.0014751956715,
              49.71333100656
            ],
            [
              1.9889198926498,
              49.709402970469
            ],
            [
              1.964227071738,
              49.716176638285
            ],
            [
              1.9599969956534,
              49.707667672637
            ],
            [
              1.9490957317415,
              49.700799437057
            ],
            [
              1.9332028186606,
              49.719633496535
            ],
            [
              1.9104738555541,
              49.709748892553
            ],
            [
              1.8938657876757,
              49.698984649558
            ],
            [
              1.8792381354282,
              49.698301790269
            ],
            [
              1.865499402996,
              49.70397426338
            ],
            [
              1.8489820073575,
              49.701631956735
            ],
            [
              1.8381013236399,
              49.708421968668
            ],
            [
              1.8371737595611,
              49.718269170518
            ],
            [
              1.8207632027639,
              49.722151536711
            ],
            [
              1.8395426143609,
              49.730914500449
            ],
            [
              1.8367369152631,
              49.740116319336
            ],
            [
              1.8204527833718,
              49.739051270304
            ],
            [
              1.8089729809968,
              49.75433151074
            ],
            [
              1.7999203021402,
              49.750609725952
            ],
            [
              1.7838342426767,
              49.758309270134
            ],
            [
              1.7844715649337,
              49.763592975395
            ],
            [
              1.7728042654107,
              49.77686925316
            ],
            [
              1.7579284899296,
              49.780795399895
            ],
            [
              1.7544750364007,
              49.790629917937
            ],
            [
              1.7373867941575,
              49.808209452072
            ],
            [
              1.7270527964865,
              49.828882279702
            ],
            [
              1.724683271591,
              49.845733284166
            ],
            [
              1.7195139243408,
              49.85406661499
            ],
            [
              1.7187325614081,
              49.865708141834
            ],
            [
              1.7117443909555,
              49.87371820092
            ],
            [
              1.7124562091314,
              49.886444322648
            ],
            [
              1.6932946158985,
              49.895601909585
            ],
            [
              1.6784507673607,
              49.918130568802
            ],
            [
              1.6182113660007,
              49.937822139755
            ],
            [
              1.5940092903756,
              49.949050560009
            ],
            [
              1.5738903148936,
              49.973921649667
            ],
            [
              1.5582578959792,
              49.979389381286
            ],
            [
              1.5465597760538,
              49.987581734127
            ],
            [
              1.5270192186573,
              49.996530516122
            ],
            [
              1.5180154200133,
              50.00910228757
            ],
            [
              1.50103897683,
              50.018915984928
            ],
            [
              1.4933105925107,
              50.017740486409
            ],
            [
              1.4734926140971,
              50.033599846345
            ],
            [
              1.4564566095824,
              50.037832374442
            ],
            [
              1.455286116297,
              50.056298876577
            ],
            [
              1.4591516246417,
              50.062494643478
            ],
            [
              1.4464534693489,
              50.069324171563
            ],
            [
              1.4235940727692,
              50.070851596042
            ],
            [
              1.4088347782602,
              50.057247377816
            ],
            [
              1.3924400221587,
              50.060056426893
            ],
            [
              1.3796981484469,
              50.065011890414
            ]
          ]
        ]
      },
      "properties": {
        "code": "80",
        "nom": "Somme",
        "population": 568748,
        "delitspour1000": "5,757",
        "nombremosque": 18,
        "parentsEtrangers": "85,3",
        "benefRsa": 35558,
        "eoliennes": 747
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              2.2265538842831,
              48.776101996393
            ],
            [
              2.2329743918365,
              48.766192980349
            ],
            [
              2.2476645497167,
              48.761102963541
            ],
            [
              2.2593929492592,
              48.761011671227
            ],
            [
              2.275033240625,
              48.755288275593
            ],
            [
              2.2853936451259,
              48.747508493238
            ],
            [
              2.274821276941,
              48.740090859412
            ],
            [
              2.2802904545364,
              48.733011078618
            ],
            [
              2.2983820323129,
              48.738637828397
            ],
            [
              2.3038400608379,
              48.72948996497
            ],
            [
              2.3132939902039,
              48.730280485815
            ],
            [
              2.3207207141635,
              48.74875804931
            ],
            [
              2.3546796298175,
              48.738565068167
            ],
            [
              2.3693515629708,
              48.746067457624
            ],
            [
              2.3707040678761,
              48.720171411205
            ],
            [
              2.3867469186816,
              48.719975201473
            ],
            [
              2.4110164293164,
              48.726050022829
            ],
            [
              2.414130346149,
              48.717993666318
            ],
            [
              2.4401207414166,
              48.725433667155
            ],
            [
              2.4549382750663,
              48.722660904777
            ],
            [
              2.4673873361332,
              48.728713712543
            ],
            [
              2.4787749913062,
              48.727641481468
            ],
            [
              2.5035392718422,
              48.735213292289
            ],
            [
              2.5157481028347,
              48.728940405475
            ],
            [
              2.5208681543244,
              48.712797146805
            ],
            [
              2.5292660759678,
              48.706797944381
            ],
            [
              2.5310726121952,
              48.699783892783
            ],
            [
              2.5415977449297,
              48.700126122289
            ],
            [
              2.5538613239117,
              48.688326690701
            ],
            [
              2.5716535034613,
              48.69201676557
            ],
            [
              2.5787732945151,
              48.690249491839
            ],
            [
              2.5853737107586,
              48.679395351685
            ],
            [
              2.5594038933391,
              48.672390641128
            ],
            [
              2.5448201812671,
              48.67118451862
            ],
            [
              2.5336146170614,
              48.657334981367
            ],
            [
              2.5411152047382,
              48.651544369478
            ],
            [
              2.5424110527051,
              48.635473450172
            ],
            [
              2.527421313864,
              48.633371245833
            ],
            [
              2.5182318429154,
              48.627093611305
            ],
            [
              2.5268345542116,
              48.621417058075
            ],
            [
              2.5258420647199,
              48.613103319179
            ],
            [
              2.5406937585523,
              48.611406555087
            ],
            [
              2.5437805754235,
              48.603850686248
            ],
            [
              2.5407668344973,
              48.595495914424
            ],
            [
              2.5333428613649,
              48.589171157103
            ],
            [
              2.5182811370135,
              48.58325330677
            ],
            [
              2.5217689565415,
              48.573224776701
            ],
            [
              2.5135771377389,
              48.558215669745
            ],
            [
              2.5009750387696,
              48.546173070367
            ],
            [
              2.5108180265073,
              48.543116722691
            ],
            [
              2.4995247172499,
              48.525234550232
            ],
            [
              2.5090104505562,
              48.508140380735
            ],
            [
              2.5153562670148,
              48.504445668033
            ],
            [
              2.51013182468,
              48.488378773705
            ],
            [
              2.5018876471506,
              48.484768503419
            ],
            [
              2.5059355570193,
              48.461154873319
            ],
            [
              2.5047511155697,
              48.439445122154
            ],
            [
              2.507657612825,
              48.429542119039
            ],
            [
              2.5173761430247,
              48.427459754888
            ],
            [
              2.5337277804202,
              48.411251130404
            ],
            [
              2.5345790748515,
              48.404237036735
            ],
            [
              2.5216189750667,
              48.404932194382
            ],
            [
              2.5028806697968,
              48.392912777413
            ],
            [
              2.482624887353,
              48.395570153778
            ],
            [
              2.4738364829183,
              48.390477944369
            ],
            [
              2.4831342533299,
              48.3845692019
            ],
            [
              2.4692109816408,
              48.376449111258
            ],
            [
              2.4514612247762,
              48.374939569978
            ],
            [
              2.4298336175359,
              48.362012190971
            ],
            [
              2.4353486081157,
              48.353601096257
            ],
            [
              2.4255757157654,
              48.337245759129
            ],
            [
              2.3931765378081,
              48.336276224436
            ],
            [
              2.3936432426131,
              48.330904514522
            ],
            [
              2.4026629385658,
              48.320717653248
            ],
            [
              2.3968534009104,
              48.314755298076
            ],
            [
              2.3698159145392,
              48.308674310979
            ],
            [
              2.3401550939779,
              48.320230117587
            ],
            [
              2.3276948002521,
              48.333053330653
            ],
            [
              2.312593571187,
              48.330803657009
            ],
            [
              2.2954192911964,
              48.30822244035
            ],
            [
              2.2668923386489,
              48.314682212687
            ],
            [
              2.2535700132149,
              48.300243238173
            ],
            [
              2.2451518848313,
              48.298393903395
            ],
            [
              2.2494360700816,
              48.314580917036
            ],
            [
              2.2380428382036,
              48.316370632568
            ],
            [
              2.2463103911083,
              48.329962148865
            ],
            [
              2.2295923211201,
              48.329229232055
            ],
            [
              2.2239137900476,
              48.336416239958
            ],
            [
              2.2022520441218,
              48.344598562828
            ],
            [
              2.1982097691131,
              48.337062877906
            ],
            [
              2.185025102223,
              48.32642865382
            ],
            [
              2.1813553737363,
              48.313746373001
            ],
            [
              2.1690253806915,
              48.312695907539
            ],
            [
              2.1543896262247,
              48.315910610694
            ],
            [
              2.1557084788588,
              48.304493813388
            ],
            [
              2.1637483268763,
              48.298436908006
            ],
            [
              2.1361199958338,
              48.299247999325
            ],
            [
              2.1105944678389,
              48.29694441258
            ],
            [
              2.1137168991141,
              48.307245161467
            ],
            [
              2.1061680366894,
              48.307635584746
            ],
            [
              2.0815050149817,
              48.293603520661
            ],
            [
              2.0527138226636,
              48.295470353999
            ],
            [
              2.0493770399482,
              48.289753273514
            ],
            [
              2.0228447716486,
              48.288080340461
            ],
            [
              2.007237571012,
              48.284688606385
            ],
            [
              1.9940901445311,
              48.286584124472
            ],
            [
              1.9752915866555,
              48.287202034779
            ],
            [
              1.9666102219583,
              48.295582043441
            ],
            [
              1.959225976988,
              48.308685064463
            ],
            [
              1.9694413393402,
              48.314231718094
            ],
            [
              1.9744919508382,
              48.323163720082
            ],
            [
              1.9823432207772,
              48.328312555088
            ],
            [
              1.9749473035511,
              48.335258065972
            ],
            [
              1.9733953665973,
              48.352974683353
            ],
            [
              1.9868237281778,
              48.362141015776
            ],
            [
              1.9763108383484,
              48.380270715947
            ],
            [
              1.966381938603,
              48.38158815854
            ],
            [
              1.9765710225943,
              48.399385315775
            ],
            [
              1.9619541453882,
              48.404321075988
            ],
            [
              1.9427703207411,
              48.405651458209
            ],
            [
              1.9305611178719,
              48.404126689728
            ],
            [
              1.925889401548,
              48.412741750651
            ],
            [
              1.9378068315128,
              48.426390003183
            ],
            [
              1.9428970138616,
              48.441083787001
            ],
            [
              1.9331248410711,
              48.442264032481
            ],
            [
              1.9304234130097,
              48.453403326926
            ],
            [
              1.9221462784913,
              48.457599361977
            ],
            [
              1.9163409812792,
              48.464861099393
            ],
            [
              1.9149199821626,
              48.473854999246
            ],
            [
              1.9333793220099,
              48.482145535364
            ],
            [
              1.9368069560798,
              48.500865304824
            ],
            [
              1.9532944656762,
              48.523736261273
            ],
            [
              1.9674208443859,
              48.533067716836
            ],
            [
              1.9340616493117,
              48.548106841763
            ],
            [
              1.9378900456787,
              48.562926567593
            ],
            [
              1.9527665939994,
              48.559466252882
            ],
            [
              1.9630289819786,
              48.560481876073
            ],
            [
              1.9818197824217,
              48.555550526577
            ],
            [
              1.999843911355,
              48.555387687139
            ],
            [
              2.0175700627197,
              48.557593755935
            ],
            [
              2.017226962458,
              48.569459551945
            ],
            [
              2.0361244190883,
              48.590748476784
            ],
            [
              2.0345020728388,
              48.603502290966
            ],
            [
              2.0546695680501,
              48.608729666136
            ],
            [
              2.0602471726215,
              48.617597733568
            ],
            [
              2.0458818260422,
              48.622732498273
            ],
            [
              2.034456506865,
              48.632456487516
            ],
            [
              2.0359612070725,
              48.641907744749
            ],
            [
              2.0200083770792,
              48.642090643692
            ],
            [
              2.0129881452639,
              48.647690003816
            ],
            [
              2.0112162167678,
              48.659775158953
            ],
            [
              2.0281927750027,
              48.657259334271
            ],
            [
              2.0290327276387,
              48.664236731798
            ],
            [
              2.0376361003153,
              48.680219226712
            ],
            [
              2.0456604451446,
              48.68756805233
            ],
            [
              2.0822157938708,
              48.68585446348
            ],
            [
              2.0857970428575,
              48.692525773326
            ],
            [
              2.0978580418958,
              48.694030740642
            ],
            [
              2.0969343192737,
              48.702748042418
            ],
            [
              2.1103286614936,
              48.71437841267
            ],
            [
              2.111168267855,
              48.724231347633
            ],
            [
              2.0979976816391,
              48.73436321985
            ],
            [
              2.1118838344903,
              48.73955835886
            ],
            [
              2.1349745033773,
              48.737136538566
            ],
            [
              2.1384211955586,
              48.74615342302
            ],
            [
              2.1506777536849,
              48.753710336104
            ],
            [
              2.1605126243942,
              48.752014252613
            ],
            [
              2.1680122718546,
              48.756836536461
            ],
            [
              2.1820123672797,
              48.757574558863
            ],
            [
              2.192317263511,
              48.763285114137
            ],
            [
              2.1910712604347,
              48.775122277406
            ],
            [
              2.2099186754479,
              48.769192527247
            ],
            [
              2.2265538842831,
              48.776101996393
            ]
          ]
        ]
      },
      "properties": {
        "code": "91",
        "nom": "Essonne",
        "population": 1306118,
        "delitspour1000": "5,576",
        "nombremosque": 54,
        "parentsEtrangers": "49,5",
        "benefRsa": 62699,
        "eoliennes": 18
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              1.7043588288241,
              49.232197221792
            ],
            [
              1.729662120604,
              49.22919730317
            ],
            [
              1.7341091784733,
              49.221277890399
            ],
            [
              1.7336823766846,
              49.210958623685
            ],
            [
              1.7230394537953,
              49.20967924589
            ],
            [
              1.7155761288215,
              49.203118037941
            ],
            [
              1.7258249045161,
              49.195712707137
            ],
            [
              1.7374357068856,
              49.194603115202
            ],
            [
              1.7421415990654,
              49.180151725276
            ],
            [
              1.7551705903258,
              49.174545969477
            ],
            [
              1.7775554860529,
              49.184104883007
            ],
            [
              1.7909791787819,
              49.179854330047
            ],
            [
              1.7954740881562,
              49.18526277584
            ],
            [
              1.8143979645085,
              49.176162362964
            ],
            [
              1.8265758315348,
              49.179516758866
            ],
            [
              1.8370818782543,
              49.171698986367
            ],
            [
              1.8455433838221,
              49.169896687357
            ],
            [
              1.877042152092,
              49.171448857663
            ],
            [
              1.8852348791181,
              49.162636028533
            ],
            [
              1.894536229716,
              49.166040538631
            ],
            [
              1.931476176782,
              49.17416772145
            ],
            [
              1.9480171420872,
              49.170749195544
            ],
            [
              1.9607171629873,
              49.173517298241
            ],
            [
              1.9734431640564,
              49.18389441504
            ],
            [
              1.996823915181,
              49.176453415327
            ],
            [
              2.0045021739488,
              49.177609556506
            ],
            [
              2.0217858750717,
              49.188671770161
            ],
            [
              2.0380917348167,
              49.192136044555
            ],
            [
              2.066842351201,
              49.202860855132
            ],
            [
              2.0808850919985,
              49.209773929407
            ],
            [
              2.0915398506152,
              49.204360444701
            ],
            [
              2.0968798927064,
              49.189757147704
            ],
            [
              2.1133369040576,
              49.186543761789
            ],
            [
              2.1327402600127,
              49.191379169415
            ],
            [
              2.1645707336419,
              49.179671393215
            ],
            [
              2.157743439339,
              49.172599185593
            ],
            [
              2.1642743368285,
              49.166004802028
            ],
            [
              2.1819527991076,
              49.173675958457
            ],
            [
              2.204958905762,
              49.174316445929
            ],
            [
              2.2186458815721,
              49.180685270888
            ],
            [
              2.2354095182703,
              49.167035850124
            ],
            [
              2.2163546658105,
              49.154378017348
            ],
            [
              2.2230049777895,
              49.151869445673
            ],
            [
              2.2524813607229,
              49.152881479913
            ],
            [
              2.2622091200851,
              49.158297463054
            ],
            [
              2.2695453788487,
              49.156306628677
            ],
            [
              2.2862682227562,
              49.160277559835
            ],
            [
              2.2888064839605,
              49.170710377425
            ],
            [
              2.2994229533516,
              49.17583535835
            ],
            [
              2.3011998615285,
              49.183887047533
            ],
            [
              2.3109304401404,
              49.186404768181
            ],
            [
              2.3222626753655,
              49.180859736345
            ],
            [
              2.3462625342946,
              49.161815854345
            ],
            [
              2.3592998527471,
              49.147343950086
            ],
            [
              2.370937678429,
              49.159173609651
            ],
            [
              2.383003341928,
              49.155913004846
            ],
            [
              2.3912847262891,
              49.1493504084
            ],
            [
              2.4153544805398,
              49.151762413963
            ],
            [
              2.4409039786288,
              49.14580411257
            ],
            [
              2.4354018020913,
              49.133939040206
            ],
            [
              2.4615068862157,
              49.135936921998
            ],
            [
              2.4817070713716,
              49.126919463544
            ],
            [
              2.4992321189629,
              49.122269747269
            ],
            [
              2.5020961933805,
              49.118887475459
            ],
            [
              2.4899295573932,
              49.106359373937
            ],
            [
              2.5310585957685,
              49.099597191318
            ],
            [
              2.5329944482474,
              49.119245162084
            ],
            [
              2.5408087122315,
              49.122242313794
            ],
            [
              2.5561550654469,
              49.118627938332
            ],
            [
              2.5520149054273,
              49.113947081753
            ],
            [
              2.5581755098966,
              49.098381756011
            ],
            [
              2.5786858654184,
              49.091952842127
            ],
            [
              2.5833038141204,
              49.080706309944
            ],
            [
              2.5905283926735,
              49.079653961607
            ],
            [
              2.5715472662178,
              49.059825274601
            ],
            [
              2.5741880306468,
              49.054149235821
            ],
            [
              2.5847224172596,
              49.052600876728
            ],
            [
              2.5882844530991,
              49.046956449876
            ],
            [
              2.573590385495,
              49.043134042541
            ],
            [
              2.5662405882259,
              49.030110020073
            ],
            [
              2.5476478248421,
              49.023448531806
            ],
            [
              2.5350135962143,
              49.023809402045
            ],
            [
              2.5378177402082,
              49.016609544367
            ],
            [
              2.5490471496408,
              49.014720763277
            ],
            [
              2.5530565519414,
              49.009816202999
            ],
            [
              2.5477767707755,
              49.004873251062
            ],
            [
              2.5322372193717,
              49.005223735412
            ],
            [
              2.5183558101117,
              48.9894603436
            ],
            [
              2.514763879623,
              48.982255621303
            ],
            [
              2.5004037138928,
              48.97770409094
            ],
            [
              2.4960054328348,
              48.972719183153
            ],
            [
              2.4673269472292,
              48.964338344645
            ],
            [
              2.4594885661379,
              48.955049018342
            ],
            [
              2.427263253996,
              48.959221267075
            ],
            [
              2.4073892851778,
              48.956124752826
            ],
            [
              2.3943172032158,
              48.962558403369
            ],
            [
              2.3845399502938,
              48.970995811739
            ],
            [
              2.3664463375656,
              48.974178481395
            ],
            [
              2.3536647251325,
              48.965815537603
            ],
            [
              2.3277204556376,
              48.959659512979
            ],
            [
              2.3061147975372,
              48.96262002844
            ],
            [
              2.2989606605183,
              48.966304083093
            ],
            [
              2.2882536989787,
              48.958347222268
            ],
            [
              2.2909694895753,
              48.950965864655
            ],
            [
              2.2687285425666,
              48.946575058002
            ],
            [
              2.231140371461,
              48.927732507513
            ],
            [
              2.2005910092695,
              48.908679329899
            ],
            [
              2.2037015884478,
              48.914390956152
            ],
            [
              2.1989282039477,
              48.935252831528
            ],
            [
              2.2060210022656,
              48.94051776568
            ],
            [
              2.205861118488,
              48.949738062716
            ],
            [
              2.1735533368231,
              48.953158232311
            ],
            [
              2.1782956060357,
              48.964137829678
            ],
            [
              2.1732779441741,
              48.975633867858
            ],
            [
              2.1549353971038,
              48.985726242203
            ],
            [
              2.1269716643348,
              48.989140165715
            ],
            [
              2.1207126382788,
              48.993300321431
            ],
            [
              2.127121298296,
              49.009679391237
            ],
            [
              2.1212540749308,
              49.018332284915
            ],
            [
              2.1102443408749,
              49.008729959034
            ],
            [
              2.0849108992224,
              49.014674188012
            ],
            [
              2.0754526810069,
              49.002257622875
            ],
            [
              2.0644261289366,
              49.008208904929
            ],
            [
              2.0498584568979,
              49.003200683684
            ],
            [
              2.0247730275828,
              48.998756705331
            ],
            [
              2.0144337197287,
              49.00106609648
            ],
            [
              1.9918871076265,
              49.021006179043
            ],
            [
              1.974053010825,
              49.019426174588
            ],
            [
              1.9549423612736,
              49.024533707311
            ],
            [
              1.9378008717251,
              49.021772717877
            ],
            [
              1.9085198609511,
              49.048019307838
            ],
            [
              1.8832054260904,
              49.033529797441
            ],
            [
              1.8818941990409,
              49.019542051399
            ],
            [
              1.8691165460014,
              49.01823441815
            ],
            [
              1.8520019385024,
              49.035204042747
            ],
            [
              1.8606119952712,
              49.046346047943
            ],
            [
              1.8654659990427,
              49.05747165429
            ],
            [
              1.8417831494014,
              49.064654564598
            ],
            [
              1.8406440398067,
              49.069332222477
            ],
            [
              1.8239435408566,
              49.076360256056
            ],
            [
              1.7965480580586,
              49.072232211602
            ],
            [
              1.7998422239972,
              49.064555011994
            ],
            [
              1.7941625055309,
              49.058508218145
            ],
            [
              1.7777603243167,
              49.05658172354
            ],
            [
              1.7596223758831,
              49.063324634192
            ],
            [
              1.7502240292765,
              49.05410421169
            ],
            [
              1.7511782974558,
              49.048223015203
            ],
            [
              1.7356433379106,
              49.048684595888
            ],
            [
              1.7234517354795,
              49.044974719281
            ],
            [
              1.7171010524139,
              49.04903025476
            ],
            [
              1.6949167927313,
              49.056327234914
            ],
            [
              1.6971091015472,
              49.065962446366
            ],
            [
              1.6736890350523,
              49.078154769223
            ],
            [
              1.6602322098294,
              49.081089987836
            ],
            [
              1.6429103427093,
              49.081251769387
            ],
            [
              1.6292577222733,
              49.078286632616
            ],
            [
              1.6141564760593,
              49.071448021972
            ],
            [
              1.608798807603,
              49.077894185227
            ],
            [
              1.6233233619235,
              49.086078404348
            ],
            [
              1.6175344667245,
              49.093996514155
            ],
            [
              1.6470910019222,
              49.124713530921
            ],
            [
              1.655781502711,
              49.130164014177
            ],
            [
              1.6539225302112,
              49.145440634177
            ],
            [
              1.6643792614171,
              49.153368483471
            ],
            [
              1.6670168601861,
              49.178613559023
            ],
            [
              1.6769552220057,
              49.202542812736
            ],
            [
              1.6757423007191,
              49.21186711807
            ],
            [
              1.7040643483737,
              49.229320472612
            ],
            [
              1.7043588288241,
              49.232197221792
            ]
          ]
        ]
      },
      "properties": {
        "code": "95",
        "nom": "Val-d'Oise",
        "population": 1251804,
        "delitspour1000": "5,694",
        "nombremosque": 93,
        "parentsEtrangers": "45,3",
        "benefRsa": 75976
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              0.84121734187884,
              48.103059710788
            ],
            [
              0.87588406994719,
              48.109431789526
            ],
            [
              0.87953340865691,
              48.103766091457
            ],
            [
              0.89092552763253,
              48.1020639314
            ],
            [
              0.9134263403037,
              48.104182844216
            ],
            [
              0.92827598961367,
              48.111241883119
            ],
            [
              0.9440254583075,
              48.112374564256
            ],
            [
              0.95027967602923,
              48.099272840487
            ],
            [
              0.96380313397324,
              48.105817316238
            ],
            [
              0.97663931936622,
              48.115724784112
            ],
            [
              0.99732449699185,
              48.127497016586
            ],
            [
              1.0125453389282,
              48.132548568904
            ],
            [
              1.0387317998475,
              48.131649399394
            ],
            [
              1.0480934527364,
              48.123771227771
            ],
            [
              1.0456237157271,
              48.117628433242
            ],
            [
              1.0054939100059,
              48.105937851842
            ],
            [
              0.99095370477199,
              48.096913623013
            ],
            [
              0.99582229412804,
              48.088965411212
            ],
            [
              1.0085530094424,
              48.083129568505
            ],
            [
              1.0260040377603,
              48.088407330285
            ],
            [
              1.0391700983591,
              48.085226440702
            ],
            [
              1.062762876896,
              48.086519538865
            ],
            [
              1.0789221885919,
              48.077372354362
            ],
            [
              1.1122373595415,
              48.080729190483
            ],
            [
              1.1078389363408,
              48.060200512892
            ],
            [
              1.1160430723906,
              48.052056204007
            ],
            [
              1.1212375728931,
              48.039115084086
            ],
            [
              1.1439345576956,
              48.028735559399
            ],
            [
              1.1666237189768,
              48.02739081669
            ],
            [
              1.1617657870615,
              48.019743054486
            ],
            [
              1.1713894713748,
              48.008912137747
            ],
            [
              1.1887835728432,
              47.996402727816
            ],
            [
              1.1953377175618,
              47.982930982211
            ],
            [
              1.2026239491606,
              47.977380600309
            ],
            [
              1.1914910887426,
              47.97196645317
            ],
            [
              1.2096457784621,
              47.968499657803
            ],
            [
              1.2214243846787,
              47.975155961261
            ],
            [
              1.23681790535,
              47.978786770626
            ],
            [
              1.2555641114657,
              47.975813257954
            ],
            [
              1.2682429066838,
              47.961539855732
            ],
            [
              1.2808769164174,
              47.965881275401
            ],
            [
              1.2995800371183,
              47.968162406715
            ],
            [
              1.3086295485933,
              47.953852019595
            ],
            [
              1.3374782196309,
              47.957609442464
            ],
            [
              1.3461734269522,
              47.96510327364
            ],
            [
              1.3654467424096,
              47.954742350646
            ],
            [
              1.3869437457769,
              47.981278643463
            ],
            [
              1.4068814381118,
              47.970098373982
            ],
            [
              1.4155113167124,
              47.970526434738
            ],
            [
              1.4372396921872,
              47.976496003088
            ],
            [
              1.4400648998212,
              47.983577682058
            ],
            [
              1.4343282577157,
              47.993965039742
            ],
            [
              1.4326050370739,
              48.008521704213
            ],
            [
              1.4396507125902,
              48.011948352447
            ],
            [
              1.4551865518073,
              48.008603442779
            ],
            [
              1.4817241132483,
              47.996075097984
            ],
            [
              1.4985767885221,
              47.985269949845
            ],
            [
              1.5201239288128,
              47.982274877947
            ],
            [
              1.5278386618546,
              47.98142842725
            ],
            [
              1.5392433825958,
              47.989061054814
            ],
            [
              1.5480894335486,
              47.986536085028
            ],
            [
              1.564819969277,
              47.989771960096
            ],
            [
              1.566720736488,
              47.974271645772
            ],
            [
              1.5604532940332,
              47.967890043333
            ],
            [
              1.5489488432055,
              47.967093353804
            ],
            [
              1.5467703394398,
              47.960513836986
            ],
            [
              1.5519690270563,
              47.953083873543
            ],
            [
              1.5350827818869,
              47.949867405352
            ],
            [
              1.5252355885738,
              47.941659567787
            ],
            [
              1.5254275303694,
              47.929115430213
            ],
            [
              1.541140496152,
              47.925446558629
            ],
            [
              1.5571642493219,
              47.918539042734
            ],
            [
              1.5814172497993,
              47.901925983899
            ],
            [
              1.582706838203,
              47.887113978677
            ],
            [
              1.5881310268697,
              47.876427415582
            ],
            [
              1.5657838326052,
              47.855000613144
            ],
            [
              1.5540228244566,
              47.845851197251
            ],
            [
              1.5350922901417,
              47.839542960878
            ],
            [
              1.5313766719936,
              47.828606391634
            ],
            [
              1.5337021961131,
              47.82052774558
            ],
            [
              1.5532872216846,
              47.810093357413
            ],
            [
              1.5705394566163,
              47.796589231895
            ],
            [
              1.5712455140145,
              47.78696332111
            ],
            [
              1.5478792778272,
              47.769763406546
            ],
            [
              1.5984630147815,
              47.739649217972
            ],
            [
              1.5928848485256,
              47.728483789961
            ],
            [
              1.6085971255235,
              47.733932392641
            ],
            [
              1.6217876830374,
              47.743816785167
            ],
            [
              1.6266315647697,
              47.760974414138
            ],
            [
              1.6554331078549,
              47.747191269103
            ],
            [
              1.6811364365915,
              47.739154365803
            ],
            [
              1.6973462178645,
              47.738389810232
            ],
            [
              1.7133188398603,
              47.729302822049
            ],
            [
              1.7128707755202,
              47.722059583197
            ],
            [
              1.7280581298649,
              47.699564531009
            ],
            [
              1.7193741272844,
              47.695921227771
            ],
            [
              1.7291985316751,
              47.687247949392
            ],
            [
              1.7376379018467,
              47.671236702621
            ],
            [
              1.7399435814608,
              47.661534026638
            ],
            [
              1.7488611522012,
              47.655778522219
            ],
            [
              1.7723174416126,
              47.653286190839
            ],
            [
              1.7798792408489,
              47.649454925502
            ],
            [
              1.778817037647,
              47.642839048521
            ],
            [
              1.7905220094023,
              47.637003267191
            ],
            [
              1.7985929573362,
              47.638435053478
            ],
            [
              1.8095352075606,
              47.652202157028
            ],
            [
              1.8429034319191,
              47.65684472991
            ],
            [
              1.8447911540901,
              47.665038871909
            ],
            [
              1.8436948478365,
              47.686349616092
            ],
            [
              1.8555867935147,
              47.689547886166
            ],
            [
              1.8645999396589,
              47.68706666871
            ],
            [
              1.8612058217535,
              47.679452429884
            ],
            [
              1.8701081524394,
              47.674181880335
            ],
            [
              1.8873127958992,
              47.678926059608
            ],
            [
              1.9139173358433,
              47.674618695582
            ],
            [
              1.9341226017816,
              47.679412588312
            ],
            [
              1.9424243352344,
              47.675629891174
            ],
            [
              1.939467766209,
              47.668143455788
            ],
            [
              1.9434347157105,
              47.660085085099
            ],
            [
              1.9608339734141,
              47.659444437434
            ],
            [
              1.983093163548,
              47.66545077466
            ],
            [
              1.9877778685346,
              47.66168736219
            ],
            [
              2.0015805166183,
              47.668676084697
            ],
            [
              1.9977468648774,
              47.674758027403
            ],
            [
              2.0080511541807,
              47.677306025655
            ],
            [
              2.0248434521895,
              47.675418359736
            ],
            [
              2.0386823095057,
              47.677164038675
            ],
            [
              2.0664644343611,
              47.674308887146
            ],
            [
              2.0735518824881,
              47.681834637553
            ],
            [
              2.0907502031475,
              47.677960430287
            ],
            [
              2.1076708613189,
              47.676510157534
            ],
            [
              2.1129574971142,
              47.670135104119
            ],
            [
              2.1271060591147,
              47.678525559507
            ],
            [
              2.1395626868162,
              47.677378413927
            ],
            [
              2.148508982167,
              47.670422872397
            ],
            [
              2.1746024427554,
              47.672463395154
            ],
            [
              2.2025518712273,
              47.678142759644
            ],
            [
              2.2060845860018,
              47.676392655536
            ],
            [
              2.2054546574629,
              47.662316663494
            ],
            [
              2.2131772586443,
              47.661784720609
            ],
            [
              2.2199543001822,
              47.667862812619
            ],
            [
              2.2325658218639,
              47.653034738696
            ],
            [
              2.2409806461446,
              47.637347215529
            ],
            [
              2.2392136694694,
              47.620975607683
            ],
            [
              2.2212298775902,
              47.616732581892
            ],
            [
              2.208375321719,
              47.610512497337
            ],
            [
              2.1939871114356,
              47.606718012679
            ],
            [
              2.1569348129666,
              47.600730394597
            ],
            [
              2.1279740267329,
              47.584694404081
            ],
            [
              2.1195727339039,
              47.582947092529
            ],
            [
              2.127061059636,
              47.571762789308
            ],
            [
              2.1237852546989,
              47.559973699827
            ],
            [
              2.1312639734154,
              47.550273375562
            ],
            [
              2.1478658766709,
              47.551695731236
            ],
            [
              2.1759088045695,
              47.548589959601
            ],
            [
              2.1956376610434,
              47.548570165214
            ],
            [
              2.2151623441422,
              47.521608405306
            ],
            [
              2.2129903924266,
              47.512971674694
            ],
            [
              2.2035282111491,
              47.501132746028
            ],
            [
              2.1987854144886,
              47.487050674796
            ],
            [
              2.2178759551605,
              47.495347099954
            ],
            [
              2.2349948952765,
              47.496352865596
            ],
            [
              2.247743712549,
              47.491730515969
            ],
            [
              2.2382244406468,
              47.47233936616
            ],
            [
              2.245113016333,
              47.458650536234
            ],
            [
              2.2426426681234,
              47.449181276556
            ],
            [
              2.2478931361182,
              47.441404079512
            ],
            [
              2.2436971815592,
              47.414820973003
            ],
            [
              2.230393378435,
              47.407523699971
            ],
            [
              2.205223396824,
              47.405248760368
            ],
            [
              2.1889033879188,
              47.437791396549
            ],
            [
              2.1415496288578,
              47.408217168994
            ],
            [
              2.1353270972844,
              47.40667755823
            ],
            [
              2.119309590853,
              47.419331050353
            ],
            [
              2.1170289477053,
              47.410106890952
            ],
            [
              2.1016941167317,
              47.386778966526
            ],
            [
              2.0970820446785,
              47.366800476362
            ],
            [
              2.1144918710749,
              47.36692542774
            ],
            [
              2.1270551026723,
              47.345270764823
            ],
            [
              2.1194608009184,
              47.333517001812
            ],
            [
              2.1282008666343,
              47.331734654456
            ],
            [
              2.1347341636016,
              47.321166463789
            ],
            [
              2.1515600335357,
              47.310724869692
            ],
            [
              2.1574168952372,
              47.299624646068
            ],
            [
              2.1436173455205,
              47.295974694853
            ],
            [
              2.1389909128822,
              47.285488920835
            ],
            [
              2.1255611204397,
              47.27974000395
            ],
            [
              2.1116829740227,
              47.279440115357
            ],
            [
              2.1050441471964,
              47.286127872964
            ],
            [
              2.0792023479058,
              47.285353997825
            ],
            [
              2.0490401397125,
              47.277096154222
            ],
            [
              2.0243923249491,
              47.265113534019
            ],
            [
              2.0152524004983,
              47.268686204602
            ],
            [
              1.9970357988971,
              47.265441117762
            ],
            [
              1.9843693739698,
              47.272419168025
            ],
            [
              1.940821280268,
              47.290134431824
            ],
            [
              1.9208433275773,
              47.270611257517
            ],
            [
              1.9192847375192,
              47.262801635486
            ],
            [
              1.8947740713773,
              47.25465935032
            ],
            [
              1.8921795193447,
              47.248757134971
            ],
            [
              1.9095263383315,
              47.243785095414
            ],
            [
              1.9165889099228,
              47.23598497734
            ],
            [
              1.9040735282719,
              47.21565260341
            ],
            [
              1.8730037897341,
              47.205321851446
            ],
            [
              1.8554771463285,
              47.220408539843
            ],
            [
              1.8390806604309,
              47.219303670107
            ],
            [
              1.8280274987279,
              47.22226987561
            ],
            [
              1.808833111076,
              47.233878017291
            ],
            [
              1.795817994896,
              47.237820414105
            ],
            [
              1.7909602935711,
              47.23150058466
            ],
            [
              1.7761040879175,
              47.231384294553
            ],
            [
              1.7397876612013,
              47.25490642364
            ],
            [
              1.7156838074978,
              47.276819032313
            ],
            [
              1.7058330808156,
              47.265880798196
            ],
            [
              1.6780281328466,
              47.261370562275
            ],
            [
              1.6658922565042,
              47.257784932818
            ],
            [
              1.6491231803883,
              47.262026117084
            ],
            [
              1.6504332606527,
              47.273166503913
            ],
            [
              1.6283172724894,
              47.275016709136
            ],
            [
              1.6211827652436,
              47.273049423747
            ],
            [
              1.5924268223064,
              47.276675700148
            ],
            [
              1.5945108544507,
              47.259821278203
            ],
            [
              1.5910427299459,
              47.25746067104
            ],
            [
              1.559536313325,
              47.261391048668
            ],
            [
              1.5262985893122,
              47.252885963483
            ],
            [
              1.5267604611835,
              47.239844802952
            ],
            [
              1.5342093559326,
              47.227571968439
            ],
            [
              1.5341048464341,
              47.22131901442
            ],
            [
              1.5066547480792,
              47.233090096391
            ],
            [
              1.4812668431106,
              47.239111870913
            ],
            [
              1.4484610302548,
              47.237964303177
            ],
            [
              1.4241008207975,
              47.229542520761
            ],
            [
              1.4096473915549,
              47.217686976966
            ],
            [
              1.3929690077143,
              47.208780068888
            ],
            [
              1.3714156982392,
              47.199695063562
            ],
            [
              1.3509355101116,
              47.193629685188
            ],
            [
              1.3384941532316,
              47.195546392092
            ],
            [
              1.3266590555808,
              47.18622172903
            ],
            [
              1.3207067619298,
              47.197369985665
            ],
            [
              1.3035444606594,
              47.21808940042
            ],
            [
              1.2993170053838,
              47.228397320295
            ],
            [
              1.276905457535,
              47.254710464825
            ],
            [
              1.267990228328,
              47.256180486566
            ],
            [
              1.2578196138819,
              47.265801868366
            ],
            [
              1.2433159275112,
              47.273852150481
            ],
            [
              1.24364362042,
              47.283660533825
            ],
            [
              1.2390417763896,
              47.288850417214
            ],
            [
              1.2235301310757,
              47.294084329417
            ],
            [
              1.194477009301,
              47.283004865615
            ],
            [
              1.173207188077,
              47.285311232893
            ],
            [
              1.1620591370279,
              47.272198707934
            ],
            [
              1.1397902849331,
              47.286814484138
            ],
            [
              1.135251812267,
              47.291670039949
            ],
            [
              1.1081216639749,
              47.298401557587
            ],
            [
              1.0989538173396,
              47.311708795136
            ],
            [
              1.094858720305,
              47.328533130173
            ],
            [
              1.1058529658238,
              47.330900276906
            ],
            [
              1.1134093236717,
              47.347668695458
            ],
            [
              1.1228285926711,
              47.354020163768
            ],
            [
              1.1063733834643,
              47.371523980706
            ],
            [
              1.1132042812047,
              47.37976090763
            ],
            [
              1.1215527616821,
              47.38243543645
            ],
            [
              1.1235227723843,
              47.391947304044
            ],
            [
              1.1141829636491,
              47.39769599266
            ],
            [
              1.1074768932722,
              47.411321717533
            ],
            [
              1.0851384560263,
              47.429997142026
            ],
            [
              1.112710681243,
              47.428740433958
            ],
            [
              1.1323602092461,
              47.446466593613
            ],
            [
              1.1165370699599,
              47.459929727006
            ],
            [
              1.1128845724069,
              47.465918778816
            ],
            [
              1.0890903403387,
              47.462245859685
            ],
            [
              1.0944974842895,
              47.469320976705
            ],
            [
              1.086392690701,
              47.478320264631
            ],
            [
              1.0787858151265,
              47.494205595711
            ],
            [
              1.0677367798519,
              47.503935667418
            ],
            [
              1.071355851475,
              47.513646383207
            ],
            [
              1.0675584935427,
              47.522555271866
            ],
            [
              1.0452962339787,
              47.531486300724
            ],
            [
              1.047676643153,
              47.537405035214
            ],
            [
              1.0763324091565,
              47.563220588685
            ],
            [
              1.0698195534437,
              47.570506173528
            ],
            [
              1.0609100314345,
              47.57272466662
            ],
            [
              1.0520622429768,
              47.586840480214
            ],
            [
              1.0333513708963,
              47.60700704889
            ],
            [
              1.0239945252392,
              47.60883620849
            ],
            [
              1.0147866969862,
              47.604706536619
            ],
            [
              0.99702027543332,
              47.583970079496
            ],
            [
              0.98714887279053,
              47.59440347313
            ],
            [
              0.98550979500274,
              47.613118059669
            ],
            [
              0.99178650747969,
              47.620027809173
            ],
            [
              0.97098286285028,
              47.621898454134
            ],
            [
              0.96427671081529,
              47.629106510599
            ],
            [
              0.95623413460239,
              47.626222507135
            ],
            [
              0.93161832908705,
              47.628716338549
            ],
            [
              0.91900574324429,
              47.632611664588
            ],
            [
              0.90650366666187,
              47.617479969654
            ],
            [
              0.89698859328434,
              47.615113730164
            ],
            [
              0.89893247520029,
              47.604701943576
            ],
            [
              0.86389183389428,
              47.599630367943
            ],
            [
              0.85204426207512,
              47.619034516955
            ],
            [
              0.86215461167367,
              47.631049074596
            ],
            [
              0.84591633650506,
              47.647754456871
            ],
            [
              0.85949720357351,
              47.669203548914
            ],
            [
              0.86060587060998,
              47.688018990953
            ],
            [
              0.83496748767096,
              47.677165233877
            ],
            [
              0.81177915779572,
              47.682216116035
            ],
            [
              0.80507281346721,
              47.679159503352
            ],
            [
              0.78320042893704,
              47.68235568446
            ],
            [
              0.75180535085054,
              47.69184823689
            ],
            [
              0.73531585422423,
              47.695402804796
            ],
            [
              0.71214799110974,
              47.681812388507
            ],
            [
              0.7010053464739,
              47.684579696017
            ],
            [
              0.67477571238337,
              47.699006801159
            ],
            [
              0.65425850667632,
              47.684183192207
            ],
            [
              0.64390482458794,
              47.685067251567
            ],
            [
              0.65412732285932,
              47.698799689193
            ],
            [
              0.64301435028574,
              47.702551786936
            ],
            [
              0.64137067498854,
              47.70843381755
            ],
            [
              0.63086800399034,
              47.709346222795
            ],
            [
              0.61443245110168,
              47.694215472574
            ],
            [
              0.60418662363095,
              47.685607124967
            ],
            [
              0.59557114412559,
              47.688312714504
            ],
            [
              0.59297009308968,
              47.703590911989
            ],
            [
              0.58052041667909,
              47.712330763793
            ],
            [
              0.59409530653203,
              47.723105855723
            ],
            [
              0.61159726872368,
              47.728134311986
            ],
            [
              0.61066958830157,
              47.732034198596
            ],
            [
              0.62683335231018,
              47.751793159891
            ],
            [
              0.63937932915856,
              47.751572315523
            ],
            [
              0.67564997055176,
              47.768962404729
            ],
            [
              0.69688004266227,
              47.764225211
            ],
            [
              0.70344170542358,
              47.769940283327
            ],
            [
              0.68931761050545,
              47.779996503151
            ],
            [
              0.69798475487217,
              47.788889445319
            ],
            [
              0.71263236035006,
              47.790038970809
            ],
            [
              0.72484407861962,
              47.798889067315
            ],
            [
              0.7397212944059,
              47.814678933449
            ],
            [
              0.74540046495951,
              47.825663057103
            ],
            [
              0.7588569724751,
              47.833536394698
            ],
            [
              0.76841445732376,
              47.831101351932
            ],
            [
              0.77457358541314,
              47.839684494777
            ],
            [
              0.77401870735853,
              47.851208382865
            ],
            [
              0.75916076452252,
              47.859222188506
            ],
            [
              0.76468938104337,
              47.866582667793
            ],
            [
              0.75733759497489,
              47.884473514914
            ],
            [
              0.75986941585155,
              47.898224938894
            ],
            [
              0.77032460906914,
              47.902009083915
            ],
            [
              0.78051199587287,
              47.910375216053
            ],
            [
              0.79002362904946,
              47.912210300533
            ],
            [
              0.79799062776542,
              47.898193546758
            ],
            [
              0.81018749426364,
              47.890393591962
            ],
            [
              0.81721753097407,
              47.892418803105
            ],
            [
              0.80913428200565,
              47.91066247592
            ],
            [
              0.81211830142135,
              47.928939306036
            ],
            [
              0.8171459594605,
              47.934467914387
            ],
            [
              0.83727689039754,
              47.937246716219
            ],
            [
              0.84579379193831,
              47.941403140329
            ],
            [
              0.84529238839341,
              47.954438931698
            ],
            [
              0.82428057343045,
              47.982142037161
            ],
            [
              0.82622288599262,
              47.991475839362
            ],
            [
              0.83237463233502,
              47.996592094392
            ],
            [
              0.83170184588828,
              48.006116934727
            ],
            [
              0.84052666414894,
              48.021048593652
            ],
            [
              0.84155459904793,
              48.029673676539
            ],
            [
              0.83672342747852,
              48.034558745892
            ],
            [
              0.82520263947346,
              48.03005952554
            ],
            [
              0.80877017226921,
              48.031993621434
            ],
            [
              0.79747785616118,
              48.037556987677
            ],
            [
              0.79653309159021,
              48.05267766261
            ],
            [
              0.80118315423495,
              48.071513555954
            ],
            [
              0.83460349982458,
              48.070147734707
            ],
            [
              0.84302009925743,
              48.072638200667
            ],
            [
              0.84483764311698,
              48.086647072566
            ],
            [
              0.83920278539184,
              48.09125189303
            ],
            [
              0.81516825904503,
              48.093730753222
            ],
            [
              0.81435131408069,
              48.098801731111
            ],
            [
              0.83268783335498,
              48.098453526315
            ],
            [
              0.84121734187884,
              48.103059710788
            ]
          ]
        ]
      },
      "properties": {
        "code": "41",
        "nom": "Loir-et-Cher",
        "population": 329357,
        "delitspour1000": "3,947",
        "nombremosque": 10,
        "parentsEtrangers": "74,7",
        "benefRsa": 15657,
        "eoliennes": 7
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -0.86036021134895,
              48.501458584456
            ],
            [
              -0.84610706675308,
              48.498284307396
            ],
            [
              -0.83778925434372,
              48.485178672867
            ],
            [
              -0.82728981063172,
              48.476292220178
            ],
            [
              -0.8184585148882,
              48.474291742186
            ],
            [
              -0.81322359143068,
              48.455083144137
            ],
            [
              -0.79918376742683,
              48.458939062295
            ],
            [
              -0.79756269441243,
              48.465280274577
            ],
            [
              -0.77787285065978,
              48.465413522216
            ],
            [
              -0.7785859196383,
              48.453255439492
            ],
            [
              -0.77453904965747,
              48.44327891903
            ],
            [
              -0.75727715268855,
              48.436552496914
            ],
            [
              -0.73527798039621,
              48.445048872201
            ],
            [
              -0.71509947351088,
              48.448950147648
            ],
            [
              -0.7197695922356,
              48.454578580623
            ],
            [
              -0.73585755427354,
              48.461124917636
            ],
            [
              -0.73034187148783,
              48.472703026439
            ],
            [
              -0.71121688547931,
              48.470742279355
            ],
            [
              -0.70206934477695,
              48.467207668633
            ],
            [
              -0.68799234091834,
              48.469431032111
            ],
            [
              -0.68585273590642,
              48.475468439014
            ],
            [
              -0.66895705284659,
              48.486137900646
            ],
            [
              -0.66371844747552,
              48.484471551488
            ],
            [
              -0.65363074848858,
              48.459545681615
            ],
            [
              -0.6540003356242,
              48.444278312957
            ],
            [
              -0.6175695455761,
              48.458960402325
            ],
            [
              -0.59533674901115,
              48.472630277201
            ],
            [
              -0.57152013702789,
              48.469152972446
            ],
            [
              -0.55171796610154,
              48.473119783464
            ],
            [
              -0.54510290980273,
              48.482691035747
            ],
            [
              -0.5304424347088,
              48.495164970256
            ],
            [
              -0.50506155077299,
              48.505798828433
            ],
            [
              -0.4884950789804,
              48.501617721865
            ],
            [
              -0.47820460128897,
              48.501565713987
            ],
            [
              -0.47060334996255,
              48.509716651644
            ],
            [
              -0.46226332328429,
              48.512709151203
            ],
            [
              -0.43075652123879,
              48.51181625112
            ],
            [
              -0.42497181740742,
              48.507282953736
            ],
            [
              -0.41273449116925,
              48.506498004612
            ],
            [
              -0.3991855056689,
              48.510158714286
            ],
            [
              -0.39345664138171,
              48.501835194106
            ],
            [
              -0.3676233888649,
              48.492944315138
            ],
            [
              -0.36723974711241,
              48.487748985001
            ],
            [
              -0.35349558279054,
              48.483897081468
            ],
            [
              -0.35582138041634,
              48.495673571366
            ],
            [
              -0.34337759223335,
              48.500849863584
            ],
            [
              -0.32023332020671,
              48.522923755915
            ],
            [
              -0.30280889062491,
              48.517340998248
            ],
            [
              -0.27823048075492,
              48.506986142113
            ],
            [
              -0.27155353070113,
              48.507447568195
            ],
            [
              -0.26580141522268,
              48.522782191648
            ],
            [
              -0.25395512234333,
              48.525985631213
            ],
            [
              -0.24176789393774,
              48.536388956089
            ],
            [
              -0.24635660161985,
              48.542620878313
            ],
            [
              -0.26180425334438,
              48.54789519181
            ],
            [
              -0.24264015511442,
              48.567994064435
            ],
            [
              -0.23435242732491,
              48.562336320193
            ],
            [
              -0.22107424204732,
              48.560317301834
            ],
            [
              -0.20694246592994,
              48.562946447212
            ],
            [
              -0.19398633186269,
              48.554824404734
            ],
            [
              -0.1899589987284,
              48.548884398226
            ],
            [
              -0.16937878261294,
              48.536973156988
            ],
            [
              -0.14460271813847,
              48.527754338423
            ],
            [
              -0.14501210976342,
              48.521000343601
            ],
            [
              -0.15568415360731,
              48.520496772763
            ],
            [
              -0.16634047065468,
              48.51558387484
            ],
            [
              -0.1720909995602,
              48.502134649047
            ],
            [
              -0.15856042497356,
              48.496817021301
            ],
            [
              -0.14958860992305,
              48.479781866844
            ],
            [
              -0.15336586853021,
              48.476724917968
            ],
            [
              -0.14871763477939,
              48.458069224847
            ],
            [
              -0.12454179598033,
              48.449239552758
            ],
            [
              -0.10641182796282,
              48.447519773766
            ],
            [
              -0.073006901765828,
              48.450527118516
            ],
            [
              -0.072707535476378,
              48.456927943118
            ],
            [
              -0.051890589271637,
              48.453255414135
            ],
            [
              -0.049909790963035,
              48.447628170085
            ],
            [
              -0.057355677768335,
              48.42850299559
            ],
            [
              -0.053012801512314,
              48.412716132961
            ],
            [
              -0.05669039654505,
              48.398915618731
            ],
            [
              -0.052691042298523,
              48.392979240085
            ],
            [
              -0.054527208218665,
              48.382004461206
            ],
            [
              -0.070093071461379,
              48.377065238354
            ],
            [
              -0.077814182838297,
              48.369009222222
            ],
            [
              -0.092866414336842,
              48.374573991173
            ],
            [
              -0.11215911205637,
              48.374246979232
            ],
            [
              -0.11594956511513,
              48.368996253948
            ],
            [
              -0.13798494654337,
              48.361357214921
            ],
            [
              -0.13226516791641,
              48.343676185358
            ],
            [
              -0.1478027384538,
              48.34409605281
            ],
            [
              -0.15623906965009,
              48.338781363599
            ],
            [
              -0.15790591784961,
              48.323708778172
            ],
            [
              -0.15461485409923,
              48.313988129598
            ],
            [
              -0.14839294289625,
              48.31389543324
            ],
            [
              -0.1389339103484,
              48.298361232987
            ],
            [
              -0.1482659497626,
              48.28554294614
            ],
            [
              -0.15367605629457,
              48.267851501569
            ],
            [
              -0.16310127335978,
              48.255543211147
            ],
            [
              -0.15800139020414,
              48.23895585297
            ],
            [
              -0.14529030231048,
              48.222176695242
            ],
            [
              -0.15314844358679,
              48.214724452095
            ],
            [
              -0.14683427015184,
              48.205007193398
            ],
            [
              -0.16448310789664,
              48.199775347991
            ],
            [
              -0.1989564039091,
              48.178277021985
            ],
            [
              -0.22988186623387,
              48.169441185432
            ],
            [
              -0.24991841798874,
              48.157104182132
            ],
            [
              -0.23752546842672,
              48.148401403805
            ],
            [
              -0.2538122759814,
              48.136527342697
            ],
            [
              -0.24693727730225,
              48.127907263555
            ],
            [
              -0.22296781426035,
              48.122764359521
            ],
            [
              -0.2164161471053,
              48.113730301099
            ],
            [
              -0.21717909954452,
              48.106955022454
            ],
            [
              -0.23212346857464,
              48.099757288689
            ],
            [
              -0.23796701443346,
              48.0860434952
            ],
            [
              -0.22496049794856,
              48.074002462195
            ],
            [
              -0.230940827834,
              48.05898089869
            ],
            [
              -0.27020842826682,
              48.064637061516
            ],
            [
              -0.28587607811837,
              48.058484368836
            ],
            [
              -0.2844545766571,
              48.054198181443
            ],
            [
              -0.32050830389774,
              48.045287679368
            ],
            [
              -0.33035959801771,
              48.050372889432
            ],
            [
              -0.34166494063899,
              48.043097320969
            ],
            [
              -0.33355338496219,
              48.027356287335
            ],
            [
              -0.33992953904496,
              48.011599545784
            ],
            [
              -0.31601085437105,
              48.005260812236
            ],
            [
              -0.31333160110472,
              47.99465849192
            ],
            [
              -0.29930930691471,
              47.976935145751
            ],
            [
              -0.30225291512622,
              47.966115961547
            ],
            [
              -0.28263301139993,
              47.958189109934
            ],
            [
              -0.2814765494518,
              47.953761084393
            ],
            [
              -0.29532155103221,
              47.944002411272
            ],
            [
              -0.31141456732932,
              47.940444969262
            ],
            [
              -0.31986817303288,
              47.935438690786
            ],
            [
              -0.36752020190308,
              47.931197626205
            ],
            [
              -0.3848413318954,
              47.930840124314
            ],
            [
              -0.39703835031919,
              47.925921819319
            ],
            [
              -0.39681530411423,
              47.916433066634
            ],
            [
              -0.40607763263706,
              47.909197015178
            ],
            [
              -0.4051663591631,
              47.892931533534
            ],
            [
              -0.38041106002285,
              47.888964997664
            ],
            [
              -0.3706707137534,
              47.88002559388
            ],
            [
              -0.37075137340683,
              47.868853350787
            ],
            [
              -0.3788466950942,
              47.868934787031
            ],
            [
              -0.37438932327927,
              47.858307471285
            ],
            [
              -0.3872660252858,
              47.859739635974
            ],
            [
              -0.40939113351912,
              47.858366946342
            ],
            [
              -0.41098778873327,
              47.846630416002
            ],
            [
              -0.42563530757757,
              47.847489705141
            ],
            [
              -0.44786007819229,
              47.832213642746
            ],
            [
              -0.43942499951109,
              47.824281702194
            ],
            [
              -0.43408545821236,
              47.809053389034
            ],
            [
              -0.414118882442,
              47.802731937353
            ],
            [
              -0.38820603603123,
              47.80532030035
            ],
            [
              -0.38122838050963,
              47.792974054995
            ],
            [
              -0.38147760525139,
              47.786129408041
            ],
            [
              -0.37543576577617,
              47.767237167837
            ],
            [
              -0.38170083144601,
              47.760566513867
            ],
            [
              -0.38561021034855,
              47.769114398933
            ],
            [
              -0.39630612257633,
              47.770754023167
            ],
            [
              -0.40949181286586,
              47.778639806662
            ],
            [
              -0.41501631226949,
              47.776297777206
            ],
            [
              -0.41003563438819,
              47.765211772614
            ],
            [
              -0.42445123600415,
              47.766490856811
            ],
            [
              -0.44074447771005,
              47.764123856192
            ],
            [
              -0.45459213036525,
              47.756480807741
            ],
            [
              -0.46041737238756,
              47.765093407664
            ],
            [
              -0.48247706487956,
              47.775994183566
            ],
            [
              -0.50966167262766,
              47.786039294546
            ],
            [
              -0.52210308579569,
              47.783980077243
            ],
            [
              -0.51795384079201,
              47.770177433845
            ],
            [
              -0.51943177897947,
              47.764156235038
            ],
            [
              -0.53285885316803,
              47.750822279532
            ],
            [
              -0.56037613327226,
              47.757430997013
            ],
            [
              -0.57293736768549,
              47.752842443065
            ],
            [
              -0.58478471927851,
              47.758351695043
            ],
            [
              -0.61081969714626,
              47.735690584502
            ],
            [
              -0.62748017249068,
              47.737810514748
            ],
            [
              -0.64643982360156,
              47.736853397067
            ],
            [
              -0.6537825399855,
              47.733379704738
            ],
            [
              -0.67141413936749,
              47.741116096202
            ],
            [
              -0.68746167144854,
              47.739103006695
            ],
            [
              -0.71032851231712,
              47.744605212491
            ],
            [
              -0.71546315772953,
              47.741095766362
            ],
            [
              -0.72935416928341,
              47.742485565283
            ],
            [
              -0.74171045035285,
              47.73810919909
            ],
            [
              -0.75310835971653,
              47.743657730113
            ],
            [
              -0.75930412026962,
              47.753754360928
            ],
            [
              -0.78387499393012,
              47.750578958527
            ],
            [
              -0.79320782142054,
              47.761714153438
            ],
            [
              -0.81124732846711,
              47.764243961382
            ],
            [
              -0.81606753396371,
              47.771224388477
            ],
            [
              -0.83998386052541,
              47.76814434186
            ],
            [
              -0.83929831407277,
              47.752275868891
            ],
            [
              -0.88129295704945,
              47.763545668743
            ],
            [
              -0.88959193745726,
              47.763900903741
            ],
            [
              -0.89576734363074,
              47.774695581088
            ],
            [
              -0.92510315616484,
              47.787711986556
            ],
            [
              -0.93519158606661,
              47.785628696413
            ],
            [
              -0.95202871311581,
              47.796497117235
            ],
            [
              -0.96068479660974,
              47.796072166491
            ],
            [
              -0.97098655185006,
              47.785631119633
            ],
            [
              -0.95795711853403,
              47.779464165284
            ],
            [
              -0.95086406782493,
              47.76808514016
            ],
            [
              -0.97381310959824,
              47.765679681831
            ],
            [
              -0.97865617651258,
              47.761986093612
            ],
            [
              -1.0121899767546,
              47.768268724518
            ],
            [
              -1.0271760427282,
              47.776528987713
            ],
            [
              -1.0299400242941,
              47.770642704508
            ],
            [
              -1.0471541464566,
              47.773610259565
            ],
            [
              -1.0676786814704,
              47.772967184248
            ],
            [
              -1.0808081623002,
              47.77465323808
            ],
            [
              -1.0999118538236,
              47.78196877901
            ],
            [
              -1.1226290875254,
              47.784177435943
            ],
            [
              -1.1395632656858,
              47.782090893545
            ],
            [
              -1.1426274244343,
              47.774444264523
            ],
            [
              -1.1661316638423,
              47.779847686645
            ],
            [
              -1.1964430977541,
              47.790525850178
            ],
            [
              -1.211020674252,
              47.797590442857
            ],
            [
              -1.2123936791168,
              47.801614932637
            ],
            [
              -1.238247803597,
              47.809992506553
            ],
            [
              -1.2327236903989,
              47.820244561296
            ],
            [
              -1.2206362424472,
              47.820389620636
            ],
            [
              -1.2139544800221,
              47.844315066059
            ],
            [
              -1.222737283795,
              47.852599843541
            ],
            [
              -1.2164918382318,
              47.857201283697
            ],
            [
              -1.2030225271774,
              47.856844148885
            ],
            [
              -1.1891865993477,
              47.867976952617
            ],
            [
              -1.1969660077971,
              47.8789391525
            ],
            [
              -1.1966306275523,
              47.889267731968
            ],
            [
              -1.1762242926663,
              47.897399695909
            ],
            [
              -1.1754814134505,
              47.910385737305
            ],
            [
              -1.1663141666181,
              47.923578184002
            ],
            [
              -1.1671197731246,
              47.93471641408
            ],
            [
              -1.1595152529454,
              47.939219922042
            ],
            [
              -1.1613811233462,
              47.952310307885
            ],
            [
              -1.1539896711831,
              47.96581664419
            ],
            [
              -1.1343829543823,
              47.969309177864
            ],
            [
              -1.1260752876073,
              47.973307719181
            ],
            [
              -1.1228021186321,
              47.986671335599
            ],
            [
              -1.1026780017247,
              47.989064346633
            ],
            [
              -1.0908100123801,
              47.98774338815
            ],
            [
              -1.0709659537463,
              47.981801155317
            ],
            [
              -1.045096271371,
              47.987097320485
            ],
            [
              -1.021259621245,
              47.994939309553
            ],
            [
              -1.0168893967587,
              48.003728266054
            ],
            [
              -1.0182078693094,
              48.012308274749
            ],
            [
              -1.0330632921231,
              48.031188955766
            ],
            [
              -1.0277969380856,
              48.044863699713
            ],
            [
              -1.033830641773,
              48.05209341916
            ],
            [
              -1.0232901182126,
              48.068911872714
            ],
            [
              -1.0406036968359,
              48.078179883231
            ],
            [
              -1.0496111794277,
              48.089801151393
            ],
            [
              -1.0527563302629,
              48.10735152844
            ],
            [
              -1.0591416106668,
              48.125077547289
            ],
            [
              -1.0602880762288,
              48.15011075649
            ],
            [
              -1.0738876778536,
              48.159661823604
            ],
            [
              -1.079605768256,
              48.183480590178
            ],
            [
              -1.0747741638529,
              48.198201373069
            ],
            [
              -1.0873107692067,
              48.209806916083
            ],
            [
              -1.0806036422976,
              48.21949752801
            ],
            [
              -1.0865989301563,
              48.227529635817
            ],
            [
              -1.1000554557338,
              48.25927761239
            ],
            [
              -1.0930408735111,
              48.281855553692
            ],
            [
              -1.0820920155689,
              48.298391604095
            ],
            [
              -1.0592143517314,
              48.312093827381
            ],
            [
              -1.0450195990474,
              48.32772872854
            ],
            [
              -1.0558182376068,
              48.340683235403
            ],
            [
              -1.0598919207817,
              48.350532594956
            ],
            [
              -1.0590029395387,
              48.358994435228
            ],
            [
              -1.0646292178107,
              48.368281337083
            ],
            [
              -1.053937234376,
              48.383986913354
            ],
            [
              -1.0681208970941,
              48.404716742704
            ],
            [
              -1.0783677064971,
              48.413230711968
            ],
            [
              -1.0779200339792,
              48.421477186884
            ],
            [
              -1.0827926455739,
              48.433057379747
            ],
            [
              -1.0793641401546,
              48.443271982049
            ],
            [
              -1.0654330620157,
              48.451695678779
            ],
            [
              -1.0639645498148,
              48.466954435587
            ],
            [
              -1.0741061961301,
              48.473896847289
            ],
            [
              -1.0782957929126,
              48.481154857966
            ],
            [
              -1.075717285974,
              48.499392729686
            ],
            [
              -1.0701643748629,
              48.508492017418
            ],
            [
              -1.0605497194866,
              48.515346429697
            ],
            [
              -1.0514451903751,
              48.509308794368
            ],
            [
              -1.0039961840993,
              48.489172448089
            ],
            [
              -0.97225823660351,
              48.494600246107
            ],
            [
              -0.96235389107925,
              48.503667410902
            ],
            [
              -0.96425363340344,
              48.510812466339
            ],
            [
              -0.95637394558324,
              48.516620056723
            ],
            [
              -0.93371106054579,
              48.51502659051
            ],
            [
              -0.92236046915212,
              48.512389219916
            ],
            [
              -0.91847065624527,
              48.500394275132
            ],
            [
              -0.89624907070572,
              48.495083815873
            ],
            [
              -0.8774635305069,
              48.499620464013
            ],
            [
              -0.86036021134895,
              48.501458584456
            ]
          ]
        ]
      },
      "properties": {
        "code": "53",
        "nom": "Mayenne",
        "population": 306538,
        "delitspour1000": "3,677",
        "nombremosque": 4,
        "parentsEtrangers": "83,9",
        "benefRsa": 7657,
        "eoliennes": 22
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                5.4555586843759,
                49.480931549678
              ],
              [
                5.449526774142,
                49.484736738817
              ],
              [
                5.429907860027,
                49.48819079003
              ],
              [
                5.4420070555476,
                49.503619271582
              ],
              [
                5.4644956014283,
                49.490309403279
              ],
              [
                5.4555586843759,
                49.480931549678
              ]
            ]
          ],
          [
            [
              [
                5.4709047865596,
                49.497208726991
              ],
              [
                5.4801260050649,
                49.5042048476
              ],
              [
                5.505773753281,
                49.507560932221
              ],
              [
                5.5327282010479,
                49.512980369896
              ],
              [
                5.556050367567,
                49.52925089646
              ],
              [
                5.5934514158621,
                49.521909724278
              ],
              [
                5.6002800743504,
                49.517481440665
              ],
              [
                5.6036245138856,
                49.508662087223
              ],
              [
                5.6115035973004,
                49.506452254144
              ],
              [
                5.6213594702283,
                49.519543514982
              ],
              [
                5.6159222684657,
                49.527126195574
              ],
              [
                5.6203426845973,
                49.533164237559
              ],
              [
                5.6368006429332,
                49.545044778804
              ],
              [
                5.659773917278,
                49.552868947481
              ],
              [
                5.6940713831155,
                49.544034542904
              ],
              [
                5.7012603687509,
                49.539519718091
              ],
              [
                5.7152828377125,
                49.539335201991
              ],
              [
                5.7325199993123,
                49.544461313993
              ],
              [
                5.7388640222945,
                49.539320264271
              ],
              [
                5.7573750958904,
                49.542824262522
              ],
              [
                5.7561520187018,
                49.557012366016
              ],
              [
                5.7752184924057,
                49.562644003065
              ],
              [
                5.7939345464924,
                49.551240108366
              ],
              [
                5.817598826725,
                49.54647885103
              ],
              [
                5.8192303780619,
                49.537378081769
              ],
              [
                5.8369587698802,
                49.542475474291
              ],
              [
                5.845880698823,
                49.529954308627
              ],
              [
                5.8344148247219,
                49.52630284488
              ],
              [
                5.8437151332678,
                49.516116312314
              ],
              [
                5.8624510369037,
                49.501642325338
              ],
              [
                5.869086517445,
                49.498778421143
              ],
              [
                5.8934039932125,
                49.496912378689
              ],
              [
                5.9028821330724,
                49.483789799428
              ],
              [
                5.9279359592393,
                49.483237223515
              ],
              [
                5.9366368899289,
                49.477654604854
              ],
              [
                5.9351888249367,
                49.469574525054
              ],
              [
                5.9426197449697,
                49.452891944313
              ],
              [
                5.9308876511318,
                49.446283457547
              ],
              [
                5.9422868054224,
                49.430349715627
              ],
              [
                5.9270260582338,
                49.424664846592
              ],
              [
                5.9119203012586,
                49.407714315408
              ],
              [
                5.9295648718633,
                49.401892423859
              ],
              [
                5.9330996232261,
                49.384143038614
              ],
              [
                5.9285345092754,
                49.375503600542
              ],
              [
                5.9418694339375,
                49.362524823511
              ],
              [
                5.9561223859101,
                49.361591418656
              ],
              [
                5.961300724503,
                49.347783910873
              ],
              [
                5.9433902263433,
                49.340292380259
              ],
              [
                5.944010745274,
                49.334643541614
              ],
              [
                5.9852221215853,
                49.30516988712
              ],
              [
                5.9971191836138,
                49.31424140241
              ],
              [
                6.0063890612557,
                49.307960359999
              ],
              [
                6.0009437369965,
                49.281486944737
              ],
              [
                6.0056295013464,
                49.274131163886
              ],
              [
                6.0066599302753,
                49.260280179085
              ],
              [
                6.0273394946232,
                49.252606188824
              ],
              [
                6.0253226190536,
                49.243252852673
              ],
              [
                6.0293909262549,
                49.230472752632
              ],
              [
                6.0209907541813,
                49.223355889171
              ],
              [
                6.0095705639519,
                49.221790166976
              ],
              [
                6.0035212458748,
                49.208969773422
              ],
              [
                5.9876300396259,
                49.209616849127
              ],
              [
                5.9822673554929,
                49.195513428251
              ],
              [
                5.9828372221568,
                49.186517850118
              ],
              [
                6.0022439786632,
                49.185007177599
              ],
              [
                6.0261444602533,
                49.165119073785
              ],
              [
                6.0120419184564,
                49.154327146881
              ],
              [
                5.9813793558344,
                49.144452574544
              ],
              [
                5.9910062735317,
                49.135587048488
              ],
              [
                5.9954710033768,
                49.121625385708
              ],
              [
                6.0049185226691,
                49.113986517555
              ],
              [
                5.9973972069234,
                49.107839996346
              ],
              [
                5.9315992811089,
                49.109106235426
              ],
              [
                5.9246765466348,
                49.092125059986
              ],
              [
                5.9398033845286,
                49.085828436344
              ],
              [
                5.9353969045754,
                49.071851388062
              ],
              [
                5.9510283819008,
                49.064561763405
              ],
              [
                5.9532043099409,
                49.054006949201
              ],
              [
                5.9595168540519,
                49.047119309838
              ],
              [
                5.9715696879425,
                49.040954914459
              ],
              [
                5.9851287189917,
                49.041918757214
              ],
              [
                6.004800366036,
                49.034902514221
              ],
              [
                6.0165389874431,
                49.035287465653
              ],
              [
                6.0186479063776,
                49.024958351084
              ],
              [
                6.0450035747376,
                49.01041495307
              ],
              [
                6.0468830607035,
                48.995729066839
              ],
              [
                6.0434337487251,
                48.977474356417
              ],
              [
                6.0619283896793,
                48.977906059174
              ],
              [
                6.0737826614724,
                48.980635404683
              ],
              [
                6.0739482568241,
                48.969478639579
              ],
              [
                6.0813267187984,
                48.963048293929
              ],
              [
                6.0936938964985,
                48.967589301522
              ],
              [
                6.1169037215408,
                48.967828571265
              ],
              [
                6.1185450391289,
                48.958918826648
              ],
              [
                6.1246128621713,
                48.956895204277
              ],
              [
                6.1245893566756,
                48.94145628878
              ],
              [
                6.1322069836653,
                48.934718252101
              ],
              [
                6.1487103503012,
                48.937772978227
              ],
              [
                6.1623292084787,
                48.94287438802
              ],
              [
                6.1771249569164,
                48.934926691997
              ],
              [
                6.2039209729506,
                48.937668068392
              ],
              [
                6.2257308062106,
                48.93693156726
              ],
              [
                6.2266542346335,
                48.927310788457
              ],
              [
                6.2366101793887,
                48.924897404316
              ],
              [
                6.251302571332,
                48.926691899361
              ],
              [
                6.2719751529819,
                48.933137468562
              ],
              [
                6.2773099040757,
                48.928048628655
              ],
              [
                6.2877963569274,
                48.927683814703
              ],
              [
                6.2883427176889,
                48.916408318952
              ],
              [
                6.3045009353332,
                48.918642956423
              ],
              [
                6.309473407905,
                48.912439205021
              ],
              [
                6.3272620439057,
                48.905195596255
              ],
              [
                6.3269152576698,
                48.896613037099
              ],
              [
                6.3029672702425,
                48.886686860966
              ],
              [
                6.2982668306514,
                48.880723838846
              ],
              [
                6.3105234382938,
                48.873495122663
              ],
              [
                6.3117190662122,
                48.86382643255
              ],
              [
                6.2973761453173,
                48.866211903942
              ],
              [
                6.2994301917746,
                48.851691482549
              ],
              [
                6.2854720614818,
                48.849428578085
              ],
              [
                6.2913932698219,
                48.842510406727
              ],
              [
                6.3033320048009,
                48.838180107597
              ],
              [
                6.3389481809645,
                48.834053669143
              ],
              [
                6.335297537139,
                48.826122687614
              ],
              [
                6.3259847078115,
                48.82554417097
              ],
              [
                6.3159597313233,
                48.829880545286
              ],
              [
                6.3100797932181,
                48.82110876293
              ],
              [
                6.3306501267109,
                48.823308805141
              ],
              [
                6.3330191957468,
                48.813906369716
              ],
              [
                6.3493139281181,
                48.807885903195
              ],
              [
                6.3538300330436,
                48.798815071833
              ],
              [
                6.3550354567534,
                48.781656974575
              ],
              [
                6.3714175361042,
                48.784638840315
              ],
              [
                6.3890646192107,
                48.780411556798
              ],
              [
                6.3944359664073,
                48.775269090599
              ],
              [
                6.4335148612716,
                48.789039690367
              ],
              [
                6.4405959623809,
                48.781451291737
              ],
              [
                6.4509923494912,
                48.779453424429
              ],
              [
                6.4536071617557,
                48.765889394207
              ],
              [
                6.4656072950901,
                48.767668917366
              ],
              [
                6.4896213967751,
                48.765572109235
              ],
              [
                6.5154955892583,
                48.752018850786
              ],
              [
                6.5379208335892,
                48.751011291365
              ],
              [
                6.5541471102694,
                48.754088057977
              ],
              [
                6.5642548290865,
                48.749951230242
              ],
              [
                6.5793907825643,
                48.735108086284
              ],
              [
                6.5948285125576,
                48.729217443998
              ],
              [
                6.5989711260034,
                48.715692430866
              ],
              [
                6.606561214873,
                48.716842476495
              ],
              [
                6.6222369528028,
                48.713108861684
              ],
              [
                6.642417314704,
                48.702520410672
              ],
              [
                6.6584083727001,
                48.70804275227
              ],
              [
                6.6705060090375,
                48.697671809743
              ],
              [
                6.6764059809526,
                48.688850403381
              ],
              [
                6.6665029232238,
                48.671163093077
              ],
              [
                6.6753365501083,
                48.672527902585
              ],
              [
                6.6879197306857,
                48.683630505731
              ],
              [
                6.6922783279755,
                48.672838766601
              ],
              [
                6.7123854189742,
                48.673242237639
              ],
              [
                6.7260796976867,
                48.664416940975
              ],
              [
                6.7338519526537,
                48.663759342524
              ],
              [
                6.7546715595276,
                48.669387591641
              ],
              [
                6.7615835198083,
                48.667499128523
              ],
              [
                6.759386613539,
                48.658938160507
              ],
              [
                6.7639559352142,
                48.65209681876
              ],
              [
                6.782490099168,
                48.642723209857
              ],
              [
                6.8064304816622,
                48.64669342932
              ],
              [
                6.8260372985364,
                48.644824072245
              ],
              [
                6.8416184753145,
                48.635214145468
              ],
              [
                6.8440506942674,
                48.62597329524
              ],
              [
                6.8636010223891,
                48.620311412276
              ],
              [
                6.8930299222558,
                48.625054004779
              ],
              [
                6.9051215287099,
                48.61984323664
              ],
              [
                6.9186178721451,
                48.626091118856
              ],
              [
                6.9197114217307,
                48.633757996621
              ],
              [
                6.934223182594,
                48.633326978431
              ],
              [
                6.9408851853137,
                48.616504527069
              ],
              [
                6.9607924698341,
                48.608281747074
              ],
              [
                6.982883801257,
                48.611865580209
              ],
              [
                6.9981294531006,
                48.608177592563
              ],
              [
                7.009348893232,
                48.611859349561
              ],
              [
                7.0166944370945,
                48.603987344415
              ],
              [
                6.9945079286004,
                48.595965468544
              ],
              [
                7.0227697690835,
                48.579001250365
              ],
              [
                7.0342434542577,
                48.580419323444
              ],
              [
                7.0457587140991,
                48.560077038512
              ],
              [
                7.0587215908209,
                48.550707821185
              ],
              [
                7.0838688655777,
                48.545333410649
              ],
              [
                7.079355782655,
                48.536418650433
              ],
              [
                7.064491666109,
                48.523396874848
              ],
              [
                7.0828568146092,
                48.514699536554
              ],
              [
                7.0995889522962,
                48.516674991314
              ],
              [
                7.1231636635608,
                48.513587820739
              ],
              [
                7.1090047906143,
                48.511614938694
              ],
              [
                7.0940126241139,
                48.512948029926
              ],
              [
                7.076023326101,
                48.506328209411
              ],
              [
                7.0684320713508,
                48.500861399073
              ],
              [
                7.0505386166589,
                48.494874179012
              ],
              [
                7.0296277745175,
                48.492275574416
              ],
              [
                7.0058261141445,
                48.481196614343
              ],
              [
                6.9829820869226,
                48.479061512794
              ],
              [
                6.9528725725975,
                48.462495754828
              ],
              [
                6.9492097088264,
                48.457016009836
              ],
              [
                6.8908149017495,
                48.418096877888
              ],
              [
                6.8706808234281,
                48.420236891127
              ],
              [
                6.8659871866881,
                48.430557429526
              ],
              [
                6.8550428807667,
                48.432931396922
              ],
              [
                6.8493577670791,
                48.423972085139
              ],
              [
                6.8282542114529,
                48.416610381272
              ],
              [
                6.8229345013421,
                48.40583576746
              ],
              [
                6.8151664634571,
                48.398845301963
              ],
              [
                6.7987171845632,
                48.402805490183
              ],
              [
                6.7813461425445,
                48.402655850004
              ],
              [
                6.7688298364239,
                48.399542822268
              ],
              [
                6.7593385145496,
                48.41154233768
              ],
              [
                6.7460260333456,
                48.41165312407
              ],
              [
                6.7380464834766,
                48.415526621405
              ],
              [
                6.7180047311225,
                48.418674291054
              ],
              [
                6.6995035493816,
                48.413084676232
              ],
              [
                6.6694101740973,
                48.426054228077
              ],
              [
                6.6450895623568,
                48.428060807346
              ],
              [
                6.6482886743858,
                48.433886563346
              ],
              [
                6.6430248956674,
                48.444216990898
              ],
              [
                6.6359455272724,
                48.450120517566
              ],
              [
                6.6388289274108,
                48.463925312006
              ],
              [
                6.6353897601771,
                48.470497725311
              ],
              [
                6.6237018958137,
                48.473101218363
              ],
              [
                6.6014314599629,
                48.467010651289
              ],
              [
                6.5849688356706,
                48.460510143981
              ],
              [
                6.5686324041298,
                48.436985966474
              ],
              [
                6.5787210186402,
                48.426746914508
              ],
              [
                6.5838721083022,
                48.417225354632
              ],
              [
                6.5669768071184,
                48.419029968315
              ],
              [
                6.5337059818062,
                48.431354354086
              ],
              [
                6.5007526124106,
                48.414237469828
              ],
              [
                6.4873939781004,
                48.416310724356
              ],
              [
                6.4726100715082,
                48.410815690615
              ],
              [
                6.4503615609347,
                48.415050358236
              ],
              [
                6.4354978133083,
                48.412794961405
              ],
              [
                6.4223966243317,
                48.407281074326
              ],
              [
                6.4116261347654,
                48.392088549974
              ],
              [
                6.4040763393656,
                48.390611842844
              ],
              [
                6.3842531855239,
                48.394696124114
              ],
              [
                6.3928988161481,
                48.40874868619
              ],
              [
                6.3805094919312,
                48.412275972805
              ],
              [
                6.3638167883924,
                48.406144988026
              ],
              [
                6.3313219297207,
                48.404818818785
              ],
              [
                6.3056750349462,
                48.413192474483
              ],
              [
                6.3012589581199,
                48.430521011758
              ],
              [
                6.2870007127925,
                48.430193153316
              ],
              [
                6.282250722589,
                48.424960021102
              ],
              [
                6.2703000509095,
                48.426097006044
              ],
              [
                6.2604363256849,
                48.406396481242
              ],
              [
                6.2466856035967,
                48.406025685579
              ],
              [
                6.2320994667614,
                48.400224982495
              ],
              [
                6.1784179388435,
                48.397601611013
              ],
              [
                6.1846532761481,
                48.408326250688
              ],
              [
                6.1697655230123,
                48.40550733342
              ],
              [
                6.1496394514006,
                48.40852706658
              ],
              [
                6.1470839608838,
                48.404244344296
              ],
              [
                6.1523474077963,
                48.380968218338
              ],
              [
                6.1334059910595,
                48.363874406688
              ],
              [
                6.1235687601174,
                48.365865709861
              ],
              [
                6.1167293940637,
                48.354041894964
              ],
              [
                6.0987357768279,
                48.356390539634
              ],
              [
                6.0976397104948,
                48.371592388538
              ],
              [
                6.0902436162121,
                48.37828172168
              ],
              [
                6.077040301892,
                48.379754440323
              ],
              [
                6.0798196336066,
                48.363635915725
              ],
              [
                6.0660207269479,
                48.359283227586
              ],
              [
                6.0534195428738,
                48.365632203186
              ],
              [
                6.0252276692421,
                48.356968605294
              ],
              [
                6.0204877629204,
                48.361400459565
              ],
              [
                6.0040704358291,
                48.361480398766
              ],
              [
                5.9934810970028,
                48.35431264698
              ],
              [
                5.9769245874691,
                48.350411126259
              ],
              [
                5.9615617470524,
                48.349889737943
              ],
              [
                5.9552925836673,
                48.356661046747
              ],
              [
                5.9557386084989,
                48.367411409164
              ],
              [
                5.9446473521932,
                48.375815621136
              ],
              [
                5.9549403010595,
                48.387326305618
              ],
              [
                5.9496459908128,
                48.396363026783
              ],
              [
                5.9384159368281,
                48.401854636957
              ],
              [
                5.9276908372607,
                48.393685295978
              ],
              [
                5.912360520142,
                48.410017641065
              ],
              [
                5.9079547502256,
                48.417539451573
              ],
              [
                5.9159134402814,
                48.427549333524
              ],
              [
                5.9249036807074,
                48.421821938724
              ],
              [
                5.931321799578,
                48.433947530649
              ],
              [
                5.9054854014434,
                48.436837469809
              ],
              [
                5.9090618834538,
                48.42920571842
              ],
              [
                5.8992946023351,
                48.408901086296
              ],
              [
                5.8931507049176,
                48.405225079491
              ],
              [
                5.876306808297,
                48.403477971488
              ],
              [
                5.8553813169775,
                48.409372749757
              ],
              [
                5.8837809945736,
                48.436078000604
              ],
              [
                5.8989510241802,
                48.44671813264
              ],
              [
                5.8869262161534,
                48.453868391275
              ],
              [
                5.8868989565087,
                48.460067093041
              ],
              [
                5.8957451146464,
                48.474145253832
              ],
              [
                5.903565424076,
                48.480244929196
              ],
              [
                5.8987895419459,
                48.485437027889
              ],
              [
                5.9056248919799,
                48.494940877656
              ],
              [
                5.8881505754478,
                48.494009660474
              ],
              [
                5.8702652336204,
                48.499612192529
              ],
              [
                5.8560752248841,
                48.506846952466
              ],
              [
                5.8376330991699,
                48.504677571845
              ],
              [
                5.8288322738379,
                48.498827573065
              ],
              [
                5.8106518957809,
                48.497446295308
              ],
              [
                5.7921349615142,
                48.488626749074
              ],
              [
                5.7870254762515,
                48.482912835892
              ],
              [
                5.7768982595427,
                48.487176543887
              ],
              [
                5.7651504539389,
                48.49648910684
              ],
              [
                5.7609662294293,
                48.52146440042
              ],
              [
                5.7658870483069,
                48.533064172251
              ],
              [
                5.7762186102909,
                48.540358194465
              ],
              [
                5.7487980070214,
                48.550794013931
              ],
              [
                5.7289813936323,
                48.553482766838
              ],
              [
                5.7160927170236,
                48.561427704017
              ],
              [
                5.7133909280655,
                48.580633914406
              ],
              [
                5.7186586267961,
                48.590336037196
              ],
              [
                5.7449446353949,
                48.59760810301
              ],
              [
                5.7508939363362,
                48.60435531676
              ],
              [
                5.7617668080948,
                48.609385983796
              ],
              [
                5.7899108688634,
                48.607183289583
              ],
              [
                5.7978629491703,
                48.614140527407
              ],
              [
                5.8034464649874,
                48.625044943187
              ],
              [
                5.7928425418669,
                48.628881800661
              ],
              [
                5.7650555278122,
                48.618738666337
              ],
              [
                5.7589073121033,
                48.623064755659
              ],
              [
                5.7587943125677,
                48.633333318632
              ],
              [
                5.7453411751335,
                48.652188473858
              ],
              [
                5.7489234552466,
                48.666845498402
              ],
              [
                5.7449546309017,
                48.671376744383
              ],
              [
                5.7577079817005,
                48.695698365644
              ],
              [
                5.7645998533316,
                48.701077345045
              ],
              [
                5.7532860425682,
                48.707629127448
              ],
              [
                5.7433964151471,
                48.719132845609
              ],
              [
                5.7190304851466,
                48.732910201575
              ],
              [
                5.7140322252736,
                48.74453250271
              ],
              [
                5.7225320793816,
                48.75054311343
              ],
              [
                5.7418899317187,
                48.752796251199
              ],
              [
                5.7454508518575,
                48.7591715078
              ],
              [
                5.7345765159204,
                48.762470074618
              ],
              [
                5.7402526900702,
                48.772653171357
              ],
              [
                5.7677316301665,
                48.790144107477
              ],
              [
                5.7764535835103,
                48.790063196124
              ],
              [
                5.7760724568851,
                48.799304400849
              ],
              [
                5.7624107009942,
                48.793904761305
              ],
              [
                5.7582107493249,
                48.801231737647
              ],
              [
                5.7688497312157,
                48.804380155142
              ],
              [
                5.7809715229168,
                48.826744937358
              ],
              [
                5.7764540952381,
                48.845996974224
              ],
              [
                5.777382891632,
                48.854799655253
              ],
              [
                5.7693983140107,
                48.859591245064
              ],
              [
                5.7651257923273,
                48.872901896096
              ],
              [
                5.7865879005287,
                48.878200931864
              ],
              [
                5.7910638167022,
                48.886617414305
              ],
              [
                5.7658511937734,
                48.898087211088
              ],
              [
                5.756763722368,
                48.909025501035
              ],
              [
                5.7471576051222,
                48.915256169028
              ],
              [
                5.7548704959194,
                48.923130556578
              ],
              [
                5.7722355093631,
                48.923368769939
              ],
              [
                5.7834460663889,
                48.928343945749
              ],
              [
                5.7884138282863,
                48.938958746993
              ],
              [
                5.8002412939446,
                48.946747950612
              ],
              [
                5.8170917504069,
                48.94245227407
              ],
              [
                5.8244645880921,
                48.943442178987
              ],
              [
                5.847786801066,
                48.953264300995
              ],
              [
                5.8491607138617,
                48.961230178453
              ],
              [
                5.8341235480692,
                48.969974695101
              ],
              [
                5.8314671206296,
                48.975953669332
              ],
              [
                5.8048442530907,
                48.983422282743
              ],
              [
                5.8152285781504,
                48.996792653954
              ],
              [
                5.823066886852,
                49.023109313982
              ],
              [
                5.8329572889288,
                49.032939140591
              ],
              [
                5.8512804641196,
                49.038747826624
              ],
              [
                5.8541770017029,
                49.042417479249
              ],
              [
                5.8405856637237,
                49.049806402229
              ],
              [
                5.8339740565779,
                49.061281270018
              ],
              [
                5.8359263040642,
                49.066061327622
              ],
              [
                5.8069799653918,
                49.062668225081
              ],
              [
                5.808384531367,
                49.07581346302
              ],
              [
                5.8186397920456,
                49.086174532975
              ],
              [
                5.8178531085916,
                49.111039703115
              ],
              [
                5.787536478922,
                49.11190925136
              ],
              [
                5.77129837631,
                49.1060985541
              ],
              [
                5.763355475447,
                49.107883685671
              ],
              [
                5.7514861304145,
                49.118034072539
              ],
              [
                5.7485084008696,
                49.125097780709
              ],
              [
                5.7586163242507,
                49.13379436445
              ],
              [
                5.7559778074416,
                49.142386932173
              ],
              [
                5.7598050647708,
                49.150056653954
              ],
              [
                5.7444704661452,
                49.156592214671
              ],
              [
                5.7523664479466,
                49.169587511064
              ],
              [
                5.7655387967261,
                49.19808965245
              ],
              [
                5.759610377382,
                49.201595438988
              ],
              [
                5.7328471971944,
                49.195144019762
              ],
              [
                5.7207710048867,
                49.209316499461
              ],
              [
                5.7190401384103,
                49.215984343847
              ],
              [
                5.7287202865672,
                49.224974142792
              ],
              [
                5.7396882874913,
                49.230544433819
              ],
              [
                5.7209344315189,
                49.247702217971
              ],
              [
                5.7275416658093,
                49.257326151379
              ],
              [
                5.7307030579638,
                49.26979101327
              ],
              [
                5.7476673712908,
                49.27734118484
              ],
              [
                5.7605243714721,
                49.280113175962
              ],
              [
                5.7682837886189,
                49.299774662553
              ],
              [
                5.7625541109014,
                49.316331285314
              ],
              [
                5.7480554425091,
                49.30969399658
              ],
              [
                5.7317845374066,
                49.311341425415
              ],
              [
                5.7214060430502,
                49.324564797482
              ],
              [
                5.7215151564884,
                49.331720093152
              ],
              [
                5.7364841101137,
                49.338481141248
              ],
              [
                5.7383532097012,
                49.352038191977
              ],
              [
                5.7317020867514,
                49.361033630233
              ],
              [
                5.7126486382194,
                49.363529657584
              ],
              [
                5.7120154235208,
                49.375470790236
              ],
              [
                5.707046906808,
                49.384304406361
              ],
              [
                5.6873585119582,
                49.393882117734
              ],
              [
                5.6922657101867,
                49.398717675598
              ],
              [
                5.6881476509551,
                49.415106452884
              ],
              [
                5.6750941710188,
                49.421510379665
              ],
              [
                5.6462557146746,
                49.4308589046
              ],
              [
                5.6345659385699,
                49.437435167221
              ],
              [
                5.6164257051012,
                49.431453523025
              ],
              [
                5.6057310949754,
                49.420033783651
              ],
              [
                5.5988818064063,
                49.423734244404
              ],
              [
                5.5894271257641,
                49.419594210679
              ],
              [
                5.5703578501466,
                49.423371359284
              ],
              [
                5.5548450982669,
                49.419300559145
              ],
              [
                5.5291033545043,
                49.407372744181
              ],
              [
                5.5011451781038,
                49.408543065874
              ],
              [
                5.494008073344,
                49.390335958227
              ],
              [
                5.4803245524719,
                49.382342078555
              ],
              [
                5.473993913648,
                49.388944897178
              ],
              [
                5.4745327306186,
                49.397525657215
              ],
              [
                5.4635049812044,
                49.39647290549
              ],
              [
                5.4486679523763,
                49.401649163761
              ],
              [
                5.4731806585299,
                49.410749283903
              ],
              [
                5.4763732125581,
                49.403509568043
              ],
              [
                5.4941915581904,
                49.407161457028
              ],
              [
                5.5056637654641,
                49.419355999431
              ],
              [
                5.5004857413601,
                49.424540384288
              ],
              [
                5.4790716129114,
                49.425819978762
              ],
              [
                5.4711378140755,
                49.428494758719
              ],
              [
                5.4813250792623,
                49.444230871282
              ],
              [
                5.4617806899826,
                49.452686968431
              ],
              [
                5.4712052072361,
                49.460891938103
              ],
              [
                5.4644145982443,
                49.470301196738
              ],
              [
                5.4671925095702,
                49.476925743962
              ],
              [
                5.4824184576199,
                49.493663519084
              ],
              [
                5.4709047865596,
                49.497208726991
              ]
            ]
          ]
        ]
      },
      "properties": {
        "code": "54",
        "nom": "Meurthe-et-Moselle",
        "population": 732590,
        "delitspour1000": "5,834",
        "nombremosque": 36,
        "parentsEtrangers": "74,7",
        "benefRsa": 41166,
        "eoliennes": 57
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -0.89196408624284,
              46.975820414303
            ],
            [
              -0.8797290084417,
              46.975803771985
            ],
            [
              -0.85764337306558,
              46.969397597368
            ],
            [
              -0.84915622076396,
              46.973775579115
            ],
            [
              -0.85591689102216,
              46.979079840933
            ],
            [
              -0.83853635765488,
              46.985503699072
            ],
            [
              -0.82697025806846,
              46.992404409034
            ],
            [
              -0.80044123437384,
              46.994429249843
            ],
            [
              -0.78757295549441,
              47.005134613994
            ],
            [
              -0.7738784459047,
              47.004247669583
            ],
            [
              -0.76195094831376,
              46.992143526967
            ],
            [
              -0.74760195615967,
              46.991449795286
            ],
            [
              -0.74336287533223,
              47.000701964828
            ],
            [
              -0.72790285494931,
              46.994993345811
            ],
            [
              -0.71305373485382,
              46.986070538333
            ],
            [
              -0.69637193445308,
              46.994704963523
            ],
            [
              -0.68018770038511,
              46.987658683517
            ],
            [
              -0.67605096460406,
              47.000124265341
            ],
            [
              -0.64422463763986,
              46.995602969044
            ],
            [
              -0.62971403267853,
              46.996851004876
            ],
            [
              -0.61997935748009,
              46.993321083755
            ],
            [
              -0.59549628776894,
              46.997955261938
            ],
            [
              -0.58641245768408,
              47.009979798203
            ],
            [
              -0.57652952670071,
              47.017027138711
            ],
            [
              -0.56546953385811,
              47.019423404938
            ],
            [
              -0.56225950470765,
              47.030666495232
            ],
            [
              -0.54565906541349,
              47.029239134682
            ],
            [
              -0.54222220688058,
              47.035131514655
            ],
            [
              -0.55557809674432,
              47.043528672476
            ],
            [
              -0.55518423855519,
              47.056996280386
            ],
            [
              -0.55953163443577,
              47.061883133164
            ],
            [
              -0.54271238339803,
              47.068832410093
            ],
            [
              -0.49533635185918,
              47.082386236675
            ],
            [
              -0.46269595640292,
              47.081925505658
            ],
            [
              -0.46404370608664,
              47.074916254874
            ],
            [
              -0.47635021862705,
              47.072140351589
            ],
            [
              -0.48553786553274,
              47.065209021486
            ],
            [
              -0.47634088284647,
              47.054361886701
            ],
            [
              -0.46425258388436,
              47.067574344676
            ],
            [
              -0.44613773080732,
              47.067564764094
            ],
            [
              -0.4258514883367,
              47.072734496606
            ],
            [
              -0.40931478414398,
              47.06628937151
            ],
            [
              -0.40078196900616,
              47.070768258647
            ],
            [
              -0.39631090043786,
              47.087753100169
            ],
            [
              -0.38345929253299,
              47.087697613397
            ],
            [
              -0.35741871073042,
              47.094026201074
            ],
            [
              -0.34498093726671,
              47.09177095779
            ],
            [
              -0.34146522078634,
              47.087332841605
            ],
            [
              -0.3142510524831,
              47.091338121731
            ],
            [
              -0.29895654468625,
              47.099250384298
            ],
            [
              -0.2879249468488,
              47.101438124536
            ],
            [
              -0.25537525933525,
              47.100286155299
            ],
            [
              -0.24153284082639,
              47.1057275119
            ],
            [
              -0.20607508372803,
              47.09328538537
            ],
            [
              -0.18649707382196,
              47.101547033466
            ],
            [
              -0.18483829723431,
              47.108333434925
            ],
            [
              -0.15721241252843,
              47.101780345038
            ],
            [
              -0.14125473717962,
              47.103745079728
            ],
            [
              -0.14555986138819,
              47.091366857483
            ],
            [
              -0.15947412139976,
              47.085935164317
            ],
            [
              -0.17848280781104,
              47.069769863363
            ],
            [
              -0.16599081567743,
              47.064596744766
            ],
            [
              -0.14766123249005,
              47.069855194532
            ],
            [
              -0.13678317485552,
              47.063924090649
            ],
            [
              -0.13712137046296,
              47.058426719475
            ],
            [
              -0.12837866130337,
              47.054429041651
            ],
            [
              -0.1021158452812,
              47.06480003115
            ],
            [
              -0.092407566675428,
              47.042369227401
            ],
            [
              -0.087697741698477,
              47.023132400885
            ],
            [
              -0.078636707416496,
              47.009897920861
            ],
            [
              -0.0936517630819,
              47.010210823666
            ],
            [
              -0.086278760754682,
              46.985300228378
            ],
            [
              -0.066769676059753,
              46.993878488228
            ],
            [
              -0.054669107397809,
              46.994648276801
            ],
            [
              -0.038157188607888,
              46.989388181961
            ],
            [
              -0.033322474168538,
              46.979923297248
            ],
            [
              -0.046252831416631,
              46.966516800883
            ],
            [
              -0.04165290373487,
              46.95761184927
            ],
            [
              -0.032900669453543,
              46.951402897075
            ],
            [
              -0.03112429319608,
              46.942393495684
            ],
            [
              -0.021623264746082,
              46.935524083976
            ],
            [
              -0.015596199247951,
              46.926872752288
            ],
            [
              -0.0090362987061244,
              46.907476003456
            ],
            [
              -0.035440392494862,
              46.874845887223
            ],
            [
              -0.015861375676026,
              46.872819691909
            ],
            [
              -0.0045912553699726,
              46.868685024543
            ],
            [
              0.0033943277746572,
              46.861558000916
            ],
            [
              0.025614185916897,
              46.852872967775
            ],
            [
              0.034227651110925,
              46.853945893049
            ],
            [
              0.017292616841451,
              46.835873251042
            ],
            [
              0.00058777867426307,
              46.846064672081
            ],
            [
              -0.0077688625346289,
              46.847469231476
            ],
            [
              -0.024098544835817,
              46.842138530857
            ],
            [
              -0.034476788697424,
              46.832068104573
            ],
            [
              -0.045691184857316,
              46.832105032831
            ],
            [
              -0.043579946911906,
              46.820841880314
            ],
            [
              -0.031269858833015,
              46.820253423832
            ],
            [
              -0.020317395826605,
              46.812649391939
            ],
            [
              -0.0049859027667166,
              46.820133350695
            ],
            [
              0.0079945005666118,
              46.814308798391
            ],
            [
              0.0063273731096121,
              46.80887449674
            ],
            [
              -0.0058047959610808,
              46.803001163225
            ],
            [
              -0.022325255854886,
              46.789610800849
            ],
            [
              -0.020014707607482,
              46.780091542003
            ],
            [
              -0.022489321390634,
              46.769401195255
            ],
            [
              -0.014979708609134,
              46.756370135791
            ],
            [
              0.0052491993449492,
              46.754983815346
            ],
            [
              0.038264246176312,
              46.731576891243
            ],
            [
              0.013658351118858,
              46.723878714539
            ],
            [
              0.00070427201408919,
              46.716233316592
            ],
            [
              0.0035005282225884,
              46.70449977489
            ],
            [
              -0.0022220315694828,
              46.697677781874
            ],
            [
              -0.015905232889201,
              46.69645111762
            ],
            [
              -0.018074675303488,
              46.68920619588
            ],
            [
              -0.0069453835536775,
              46.682909890873
            ],
            [
              -0.027263569610362,
              46.672769820346
            ],
            [
              -0.040432926147215,
              46.663720449527
            ],
            [
              -0.033389346094041,
              46.653650870862
            ],
            [
              -0.053503349825477,
              46.642925357759
            ],
            [
              -0.066516499784791,
              46.631355491397
            ],
            [
              -0.06581020011429,
              46.622820182052
            ],
            [
              -0.048554239116896,
              46.624036740061
            ],
            [
              -0.026546668122694,
              46.628860828743
            ],
            [
              -0.016769502261875,
              46.639683917044
            ],
            [
              -0.0051596381211071,
              46.644693509564
            ],
            [
              0.00079199952682919,
              46.643059891449
            ],
            [
              -0.0034753268094469,
              46.628465995571
            ],
            [
              -0.011504647867234,
              46.619225449023
            ],
            [
              -0.0015291158138491,
              46.612094811535
            ],
            [
              0.0086376816041532,
              46.611042528745
            ],
            [
              0.022869415210482,
              46.614212991204
            ],
            [
              0.025985672947266,
              46.594744304871
            ],
            [
              0.04271943974108,
              46.591167098917
            ],
            [
              0.013193816349821,
              46.580601363601
            ],
            [
              -0.0080246690020487,
              46.567542169166
            ],
            [
              -0.010081331385269,
              46.55476373186
            ],
            [
              0.0078888975201096,
              46.547976850066
            ],
            [
              0.0066767347091348,
              46.542776485683
            ],
            [
              -0.0071548726332798,
              46.523276162065
            ],
            [
              -0.031353862470829,
              46.524980950312
            ],
            [
              -0.030042966556621,
              46.509886050511
            ],
            [
              -0.038663793231564,
              46.499850365018
            ],
            [
              -0.03768276034177,
              46.486369591935
            ],
            [
              -0.043096282305571,
              46.480540367739
            ],
            [
              -0.043263414910959,
              46.472480781549
            ],
            [
              -0.0147038329685,
              46.475070919352
            ],
            [
              -0.012309936983718,
              46.470694301504
            ],
            [
              -0.019240825523021,
              46.45326940061
            ],
            [
              -0.010452603768933,
              46.448815640542
            ],
            [
              -0.014215068419588,
              46.43496627092
            ],
            [
              -0.011299842729886,
              46.423234996333
            ],
            [
              -0.017267903219501,
              46.420571361409
            ],
            [
              -0.01709682647281,
              46.411324090947
            ],
            [
              -0.008462131351587,
              46.404211582162
            ],
            [
              -0.0089995059831326,
              46.391059106508
            ],
            [
              0.012313423320613,
              46.391542761358
            ],
            [
              0.020691642160083,
              46.387999786284
            ],
            [
              0.024929711629679,
              46.377803568274
            ],
            [
              0.03421632320898,
              46.373492198897
            ],
            [
              0.013851448575042,
              46.357056060846
            ],
            [
              0.018140433711669,
              46.351916657132
            ],
            [
              0.029654722943721,
              46.349187779313
            ],
            [
              0.020814625641771,
              46.341581452892
            ],
            [
              0.015822426904369,
              46.326087725559
            ],
            [
              0.037600794669074,
              46.325531520268
            ],
            [
              0.078219163246238,
              46.304936271825
            ],
            [
              0.096556122495943,
              46.323300741122
            ],
            [
              0.097806188009644,
              46.330796245295
            ],
            [
              0.11938637219542,
              46.341683662991
            ],
            [
              0.12431046658269,
              46.348417353796
            ],
            [
              0.13759390428408,
              46.34939598469
            ],
            [
              0.15696594789895,
              46.342749890175
            ],
            [
              0.1773663837498,
              46.328109476662
            ],
            [
              0.16750491307184,
              46.319640838973
            ],
            [
              0.16956926180366,
              46.310184245255
            ],
            [
              0.1566889252778,
              46.30102675136
            ],
            [
              0.16010487265191,
              46.293575540652
            ],
            [
              0.17231902804727,
              46.278600348745
            ],
            [
              0.15955991939526,
              46.266565295373
            ],
            [
              0.12883941444788,
              46.267230714602
            ],
            [
              0.14344146274523,
              46.230117287533
            ],
            [
              0.12846561471163,
              46.224425597548
            ],
            [
              0.11283807416044,
              46.210933542389
            ],
            [
              0.11235410051379,
              46.194182682319
            ],
            [
              0.1076941551618,
              46.186125599706
            ],
            [
              0.13198267243093,
              46.183135976347
            ],
            [
              0.14150062749168,
              46.178461342361
            ],
            [
              0.14858178702491,
              46.170754883108
            ],
            [
              0.15511396402513,
              46.157168309485
            ],
            [
              0.17187491952089,
              46.155825313679
            ],
            [
              0.17369264350006,
              46.150820100144
            ],
            [
              0.18705728777419,
              46.148510013671
            ],
            [
              0.19353824374893,
              46.158263900755
            ],
            [
              0.20783017713254,
              46.160423639072
            ],
            [
              0.22035828616308,
              46.1580034483
            ],
            [
              0.21491801591412,
              46.138843669228
            ],
            [
              0.2018092072724,
              46.129754117364
            ],
            [
              0.20237951061306,
              46.119279009225
            ],
            [
              0.19107765237521,
              46.11227527396
            ],
            [
              0.19262927263694,
              46.098933851977
            ],
            [
              0.19735466096157,
              46.095552773268
            ],
            [
              0.17785052333068,
              46.08330677274
            ],
            [
              0.17137846819894,
              46.08293290492
            ],
            [
              0.16136056171432,
              46.08978488217
            ],
            [
              0.146110421707,
              46.095880994017
            ],
            [
              0.13569050232961,
              46.104048921953
            ],
            [
              0.11501750524535,
              46.096150652847
            ],
            [
              0.092967733676126,
              46.101452150467
            ],
            [
              0.099656827636359,
              46.092922343594
            ],
            [
              0.072966989619956,
              46.093897575854
            ],
            [
              0.052853231614337,
              46.081428910066
            ],
            [
              0.058009181840751,
              46.072806629731
            ],
            [
              0.030782887340704,
              46.063431768817
            ],
            [
              0.019270346192872,
              46.052845061857
            ],
            [
              0.005154213384114,
              46.059484760402
            ],
            [
              -0.0046192097725446,
              46.055231392311
            ],
            [
              -0.031992658836249,
              46.055920972242
            ],
            [
              -0.039426462914628,
              46.052929212798
            ],
            [
              -0.034215014394908,
              46.04087077555
            ],
            [
              -0.021452263306336,
              46.028868685941
            ],
            [
              -0.030671681755728,
              46.022419811007
            ],
            [
              -0.045516919542785,
              46.020621309221
            ],
            [
              -0.047909394991127,
              46.004369371945
            ],
            [
              -0.041431040823061,
              45.993479981508
            ],
            [
              -0.058379400073131,
              45.986819835737
            ],
            [
              -0.061817492849519,
              45.97981583828
            ],
            [
              -0.076526504806034,
              45.979591875696
            ],
            [
              -0.10293827139676,
              45.969661749473
            ],
            [
              -0.11825537562169,
              45.977295666275
            ],
            [
              -0.13674372413356,
              45.979321738143
            ],
            [
              -0.1381655475646,
              45.996522364247
            ],
            [
              -0.14484799981657,
              46.004301838334
            ],
            [
              -0.15864227863317,
              46.005122868308
            ],
            [
              -0.16351612159351,
              46.0156815515
            ],
            [
              -0.17165503581654,
              46.022702457579
            ],
            [
              -0.17199310246057,
              46.032911911506
            ],
            [
              -0.18915119266407,
              46.026632693161
            ],
            [
              -0.19462397530732,
              46.044377815765
            ],
            [
              -0.19397032130938,
              46.052259455885
            ],
            [
              -0.21296746528856,
              46.044099888449
            ],
            [
              -0.24701672265606,
              46.050662124407
            ],
            [
              -0.27303437704336,
              46.057314973181
            ],
            [
              -0.27383258474697,
              46.063997336294
            ],
            [
              -0.28173533346848,
              46.079749241708
            ],
            [
              -0.29309092784249,
              46.086641055838
            ],
            [
              -0.30554820744046,
              46.07802294449
            ],
            [
              -0.33810658635017,
              46.081216936149
            ],
            [
              -0.36272909605538,
              46.075544897813
            ],
            [
              -0.36384259880349,
              46.085330716338
            ],
            [
              -0.37196093059879,
              46.09346371161
            ],
            [
              -0.3827817863336,
              46.097704874713
            ],
            [
              -0.40050701973614,
              46.084717679031
            ],
            [
              -0.42251050183753,
              46.093904665982
            ],
            [
              -0.42245553906339,
              46.11330667026
            ],
            [
              -0.44523811078555,
              46.09937460024
            ],
            [
              -0.48252680272727,
              46.105971450103
            ],
            [
              -0.50608855949266,
              46.106705947745
            ],
            [
              -0.51639254125142,
              46.112455143164
            ],
            [
              -0.50956456180604,
              46.11753489863
            ],
            [
              -0.52814542553638,
              46.129919733784
            ],
            [
              -0.52689484335871,
              46.136320589162
            ],
            [
              -0.5469966913401,
              46.144227728675
            ],
            [
              -0.56576607178003,
              46.14277979352
            ],
            [
              -0.57854191376581,
              46.147964728014
            ],
            [
              -0.58301947933376,
              46.13965578451
            ],
            [
              -0.59663977568508,
              46.142454838947
            ],
            [
              -0.61141326190191,
              46.141394464113
            ],
            [
              -0.61995852602441,
              46.137750014696
            ],
            [
              -0.63340377004308,
              46.148021432495
            ],
            [
              -0.62172307334118,
              46.153595038548
            ],
            [
              -0.6068472739828,
              46.152812882032
            ],
            [
              -0.61301775941075,
              46.161520115387
            ],
            [
              -0.62840807624552,
              46.160982525804
            ],
            [
              -0.65512973407564,
              46.169822454406
            ],
            [
              -0.6685811095351,
              46.184773926666
            ],
            [
              -0.69112782600009,
              46.179803971686
            ],
            [
              -0.69496204815513,
              46.186993853879
            ],
            [
              -0.68488269799084,
              46.200453773272
            ],
            [
              -0.68633055847296,
              46.213695310884
            ],
            [
              -0.69072219675464,
              46.219205893342
            ],
            [
              -0.75146594839162,
              46.24507099292
            ],
            [
              -0.75300754445052,
              46.253380618964
            ],
            [
              -0.73588991828554,
              46.266879576358
            ],
            [
              -0.73679404300403,
              46.271401492755
            ],
            [
              -0.74980855251367,
              46.286849521089
            ],
            [
              -0.75535362968417,
              46.302140849356
            ],
            [
              -0.75047134037184,
              46.304254425344
            ],
            [
              -0.73459543046899,
              46.304955701863
            ],
            [
              -0.72191414100459,
              46.302372661742
            ],
            [
              -0.7201487066083,
              46.314890543328
            ],
            [
              -0.70757171772654,
              46.317704940913
            ],
            [
              -0.69732735736344,
              46.325092837487
            ],
            [
              -0.67227364916502,
              46.316216425466
            ],
            [
              -0.64825320981615,
              46.317143530802
            ],
            [
              -0.63998183318338,
              46.322110972748
            ],
            [
              -0.63660376728023,
              46.33759543996
            ],
            [
              -0.61868206115959,
              46.339099077209
            ],
            [
              -0.60547077785894,
              46.347207865876
            ],
            [
              -0.60325195656142,
              46.361468010185
            ],
            [
              -0.57567653828611,
              46.356508034766
            ],
            [
              -0.55764744918976,
              46.363451353574
            ],
            [
              -0.53779518169029,
              46.38646382767
            ],
            [
              -0.55052040300574,
              46.393343323056
            ],
            [
              -0.56605536149932,
              46.393082159377
            ],
            [
              -0.57238400631421,
              46.400707224569
            ],
            [
              -0.58189775121858,
              46.40217377528
            ],
            [
              -0.59417281123848,
              46.410185950063
            ],
            [
              -0.61017966919341,
              46.413733221347
            ],
            [
              -0.62088828506372,
              46.390451131851
            ],
            [
              -0.63658749242212,
              46.395578228534
            ],
            [
              -0.63282952674398,
              46.403812558564
            ],
            [
              -0.6406468690526,
              46.416224664644
            ],
            [
              -0.63684012869206,
              46.432305656566
            ],
            [
              -0.61877090027571,
              46.438854562232
            ],
            [
              -0.61983716832458,
              46.452467117452
            ],
            [
              -0.6124273279524,
              46.45873668738
            ],
            [
              -0.6241822995269,
              46.48674665851
            ],
            [
              -0.62483750061224,
              46.496362745962
            ],
            [
              -0.636581601251,
              46.506095217567
            ],
            [
              -0.64519375671477,
              46.5085710348
            ],
            [
              -0.63352903140625,
              46.526545961083
            ],
            [
              -0.61002538912342,
              46.52729145947
            ],
            [
              -0.60213244964129,
              46.533279525109
            ],
            [
              -0.60670934401643,
              46.562331917411
            ],
            [
              -0.61785426354446,
              46.562043099519
            ],
            [
              -0.62456684878994,
              46.577400290716
            ],
            [
              -0.61190793736705,
              46.588307031212
            ],
            [
              -0.61671038535954,
              46.598536566433
            ],
            [
              -0.62702650527698,
              46.605651097302
            ],
            [
              -0.61411399285986,
              46.62039129053
            ],
            [
              -0.62619906297455,
              46.633078356802
            ],
            [
              -0.64405974899964,
              46.638024434086
            ],
            [
              -0.65717243733762,
              46.634619354943
            ],
            [
              -0.64924847916988,
              46.653283801506
            ],
            [
              -0.63771460553181,
              46.66348907218
            ],
            [
              -0.65787343587941,
              46.676827080382
            ],
            [
              -0.68073310425929,
              46.686803996225
            ],
            [
              -0.65619325740221,
              46.700774517445
            ],
            [
              -0.66888364550954,
              46.717232370405
            ],
            [
              -0.68397597689122,
              46.727863770277
            ],
            [
              -0.70015414355827,
              46.73579562558
            ],
            [
              -0.69413275947291,
              46.742731111953
            ],
            [
              -0.70417950073791,
              46.74945353126
            ],
            [
              -0.71534345605879,
              46.751789737735
            ],
            [
              -0.72079949738479,
              46.762538021138
            ],
            [
              -0.72731204616972,
              46.76763065568
            ],
            [
              -0.71783828712373,
              46.800521372146
            ],
            [
              -0.7008577279242,
              46.80862581328
            ],
            [
              -0.70883784005248,
              46.821063616454
            ],
            [
              -0.72752746779957,
              46.821938243707
            ],
            [
              -0.74431663878843,
              46.830243411726
            ],
            [
              -0.75815720893345,
              46.831399908556
            ],
            [
              -0.78164600263284,
              46.84282764491
            ],
            [
              -0.79477643718776,
              46.861061682533
            ],
            [
              -0.80837576068615,
              46.869152331931
            ],
            [
              -0.81527450811283,
              46.879362263307
            ],
            [
              -0.8321856982677,
              46.884537016923
            ],
            [
              -0.82020445366662,
              46.899619563541
            ],
            [
              -0.81977358192379,
              46.909085252586
            ],
            [
              -0.80739348796693,
              46.9198254879
            ],
            [
              -0.8226181521192,
              46.919496882097
            ],
            [
              -0.8291494586578,
              46.933361861306
            ],
            [
              -0.84069799142137,
              46.933043168191
            ],
            [
              -0.85194566174473,
              46.946506302307
            ],
            [
              -0.87298436818273,
              46.944344247689
            ],
            [
              -0.8837974960632,
              46.950388590831
            ],
            [
              -0.87713915299698,
              46.955855606347
            ],
            [
              -0.88359502600069,
              46.962369264823
            ],
            [
              -0.87712517316223,
              46.968538385199
            ],
            [
              -0.88901027465591,
              46.970996440972
            ],
            [
              -0.89196408624284,
              46.975820414303
            ]
          ]
        ]
      },
      "properties": {
        "code": "79",
        "nom": "Deux-Sèvres",
        "population": 374481,
        "delitspour1000": "3,824",
        "nombremosque": 7,
        "parentsEtrangers": "83,9",
        "benefRsa": 13524,
        "eoliennes": 195
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              6.8235333222471,
              47.813051201983
            ],
            [
              6.8393097752574,
              47.824784354742
            ],
            [
              6.8461756187967,
              47.822942631655
            ],
            [
              6.8428287756472,
              47.812907241333
            ],
            [
              6.8634725871955,
              47.785157366739
            ],
            [
              6.9020863665368,
              47.776444142208
            ],
            [
              6.9093369154314,
              47.77760076122
            ],
            [
              6.9239978247236,
              47.770469646258
            ],
            [
              6.9425641239541,
              47.768619181766
            ],
            [
              6.9695414653395,
              47.753879554684
            ],
            [
              6.9881803418573,
              47.747811458115
            ],
            [
              7.01596189708,
              47.743229997088
            ],
            [
              7.0194654399739,
              47.736016178013
            ],
            [
              7.0374215531951,
              47.721642148751
            ],
            [
              7.0264858913282,
              47.701000683113
            ],
            [
              7.037634862268,
              47.694693941352
            ],
            [
              7.0388002853848,
              47.687529274973
            ],
            [
              7.0480463579808,
              47.682334383614
            ],
            [
              7.0389466543978,
              47.67798172185
            ],
            [
              7.0463174145898,
              47.671501618284
            ],
            [
              7.034430907222,
              47.661170659864
            ],
            [
              7.0391920612816,
              47.650571191522
            ],
            [
              7.0188657902769,
              47.650671462441
            ],
            [
              7.0180362046747,
              47.642567039669
            ],
            [
              7.0049789727138,
              47.619379272859
            ],
            [
              7.0105070572794,
              47.605019604987
            ],
            [
              7.005954748644,
              47.602446062879
            ],
            [
              7.0254214022991,
              47.592680119451
            ],
            [
              7.0405190481699,
              47.600960102238
            ],
            [
              7.0565070741684,
              47.598940564806
            ],
            [
              7.0647156944354,
              47.601059487848
            ],
            [
              7.0772390032257,
              47.598070781665
            ],
            [
              7.0862717367469,
              47.592564849851
            ],
            [
              7.0863359637946,
              47.585555682513
            ],
            [
              7.0941662771964,
              47.57363395328
            ],
            [
              7.1002437787202,
              47.572348664104
            ],
            [
              7.1084182862429,
              47.563026634543
            ],
            [
              7.1062174347916,
              47.551377585032
            ],
            [
              7.1192027781559,
              47.547271468
            ],
            [
              7.132779554549,
              47.539650016902
            ],
            [
              7.1398015507652,
              47.526066534747
            ],
            [
              7.1378734301853,
              47.510602312684
            ],
            [
              7.1303408019013,
              47.503028568514
            ],
            [
              7.1110611805129,
              47.494903561041
            ],
            [
              7.0917092821703,
              47.494843569895
            ],
            [
              7.0792767593811,
              47.488864894845
            ],
            [
              7.0615862317267,
              47.49474656879
            ],
            [
              7.0365664433325,
              47.497498840015
            ],
            [
              7.0243941161552,
              47.504209745968
            ],
            [
              7.0002031778313,
              47.499396645969
            ],
            [
              6.9831161167318,
              47.49330200911
            ],
            [
              6.9886863518423,
              47.486681305933
            ],
            [
              6.9880828045822,
              47.474451283009
            ],
            [
              6.9917666987937,
              47.466419458718
            ],
            [
              7.0002998100994,
              47.462967214339
            ],
            [
              6.9989680532301,
              47.452297949929
            ],
            [
              6.9890632753006,
              47.447650094342
            ],
            [
              6.9700019113149,
              47.44688361894
            ],
            [
              6.9663672609906,
              47.437927724299
            ],
            [
              6.9573594450338,
              47.433602748363
            ],
            [
              6.9405416785725,
              47.433371743667
            ],
            [
              6.9323197706656,
              47.455818472268
            ],
            [
              6.9172302764556,
              47.468867148315
            ],
            [
              6.9075782646775,
              47.47324112034
            ],
            [
              6.9152779168352,
              47.4785684289
            ],
            [
              6.9076127100416,
              47.494548688313
            ],
            [
              6.9229860562893,
              47.500158491026
            ],
            [
              6.9341220005611,
              47.493635099659
            ],
            [
              6.9419807180899,
              47.485348691209
            ],
            [
              6.9488572709436,
              47.485705936519
            ],
            [
              6.9450210386148,
              47.498968686861
            ],
            [
              6.9455168398509,
              47.512373779013
            ],
            [
              6.9364864190664,
              47.51791308047
            ],
            [
              6.9257104433272,
              47.519421414109
            ],
            [
              6.9096754139357,
              47.52550620551
            ],
            [
              6.9158698594302,
              47.532559539947
            ],
            [
              6.905664579088,
              47.547365295196
            ],
            [
              6.8898311184149,
              47.550073165239
            ],
            [
              6.8834722552899,
              47.555330303305
            ],
            [
              6.8609495446484,
              47.54768917383
            ],
            [
              6.8306374300492,
              47.546435598922
            ],
            [
              6.8166586737681,
              47.547922521723
            ],
            [
              6.8069985618205,
              47.562799803717
            ],
            [
              6.818819342882,
              47.576713126905
            ],
            [
              6.8022943569297,
              47.586021530191
            ],
            [
              6.7916275066824,
              47.59618242703
            ],
            [
              6.7870628393266,
              47.61005162672
            ],
            [
              6.7776052176551,
              47.615637994811
            ],
            [
              6.7804100915452,
              47.625260533149
            ],
            [
              6.7893058560475,
              47.627261850283
            ],
            [
              6.8003237426455,
              47.634676896824
            ],
            [
              6.7983482668023,
              47.642094496848
            ],
            [
              6.7809826657994,
              47.658367488334
            ],
            [
              6.7761038578289,
              47.668575486147
            ],
            [
              6.7802047028747,
              47.689678427078
            ],
            [
              6.7700786247337,
              47.703397657298
            ],
            [
              6.7725696975861,
              47.71163527482
            ],
            [
              6.7609908394016,
              47.721624696265
            ],
            [
              6.7576409592057,
              47.747875183778
            ],
            [
              6.7650773666903,
              47.758942674682
            ],
            [
              6.7847015099183,
              47.781435523033
            ],
            [
              6.8001141025198,
              47.788278130542
            ],
            [
              6.8114632537081,
              47.796039586722
            ],
            [
              6.8235333222471,
              47.813051201983
            ]
          ]
        ]
      },
      "properties": {
        "code": "90",
        "nom": "Territoire de Belfort",
        "population": 140120,
        "delitspour1000": "6,109",
        "nombremosque": 9,
        "parentsEtrangers": "69,6",
        "benefRsa": 8547,
        "eoliennes": 1
      }
    }
  ]
}